import React from "react";
import yt from  "../img/Youtube.png"
import tw from  "../img/Twitter.png"
import tt from  "../img/titok.png"
import sc from  "../img/SoundCloud.png"
import ld from  "../img/Linkedin.png"
import ins from "../img/Instagram.png"
import fb from  "../img/facebook.png"

export default class Footer extends React.Component{
    render() {
        return (
            <footer>
                <div className="rsocial">
                    <a href="https://www.facebook.com/SavetheChildrenMexico"            target={"_blank"}><img src={fb} alt=""/></a>
                    <a href="https://twitter.com/SaveChildrenMx"                        target={"_blank"}><img src={tw} alt=""/></a>
                    <a href="https://www.youtube.com/user/SavetheChildrenMex"           target={"_blank"}><img src={yt} alt=""/></a>
                    <a href="https://www.instagram.com/savethechildren_mx/"             target={"_blank"}><img src={ins} alt=""/></a>
                    <a href="https://www.linkedin.com/company/savethechildrenmexico/"   target={"_blank"}><img src={ld} alt=""/></a>
                    <a href="https://soundcloud.com/save-the-children-mx"               target={"_blank"}><img src={sc} alt=""/></a>
                    <a href="https://www.tiktok.com/@savethechildren_mx?lang=es"        target={"_blank"}><img src={tt} alt=""/></a>
                </div>
                <div className="address">
                    <p>Contacto Save the Children</p>
                    <p>Av. Francisco Sosa 26 | Villa Coyoacán, 04000 | Ciudad de México | 56 3103 3066 </p>
                    <p>mail: atencion.donantes@savethechildren.org</p>
                    <p><a href="https://new-page.savethechildrenmx.com/aviso-de-privacidad-para-ley-anti-lavado/" target={"_blank"}>Aviso de Privacidad para Ley Anti-Lavado</a> |
                        <a href="https://www.savethechildren.mx/aviso-de-privacidad-donantes/" target={"_blank"}>Aviso de Privacidad</a></p>
                </div>
            </footer>
        );
    }
}