import React from "react";
import cargando from "../img/cargador_blanco.gif";

export default class Validacion3D extends React.Component{
    validate3D(){
        var formData = new FormData();
        var datas = JSON.parse(localStorage.getItem("result"))

        formData.append("id",datas.payment_intent.id)
        formData.append("contact_id",datas.contact_id)
        formData.append("deal_id",datas.deal_id)
        formData.append("is_lead",datas.is_lead)
        formData.append("was_lead",datas.was_lead)
        formData.append("register",datas.register)
        formData.append("email",document.getElementById("email").value)

        fetch("https://payment-center.savethechildrenmx.org/Navidad/verify3D.php",{
            method:'post',
            body:formData
        }).then(resp=>{return resp.text()}).then(txt=>{
            document.querySelector(".validacion_container").classList.add("hide")
            if (txt.localeCompare("3D validation failure")==0){
                document.querySelector(".error-container").classList.remove("hide")
                document.querySelector(".error-container .error").textContent = "3D validation failure"
            }else{
                document.querySelector(".success-container").classList.remove("hide")
            }

        })
    }

    render() {
        return (
            <div className="validacion_container hide">
                <img src={cargando} alt=""/>
                <h2 className="legend-47">Procesando solicitud con el banco.</h2>
                <button className="d-none validate legend-48" onClick={this.validate3D}>Validar</button>
            </div>
        );
    }
}