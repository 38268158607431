import React from "react";

function isValid(element){
    element.classList.add("is-valid")
    element.classList.remove("is-invalid")
}

function isInvalid(element){
    element.classList.remove("is-valid")
    element.classList.add("is-invalid")
}


function checkValidForm(element){

    var flag = true;
    element.querySelectorAll(".input-group").forEach(elem=>{
        if (elem.classList.contains("is-invalid") || elem.querySelector("input,select").value.length == 0){
            flag=false;
        }
    })

    if (flag){
        element.querySelector(".button-group button").removeAttribute("disabled")
        element.querySelector(".button-group button").classList.remove("disabled")
    }else{
        element.querySelector(".button-group button").setAttribute("disabled","disabled")
        element.querySelector(".button-group button").classList.add("disabled")
    }
}

export default class Invoicing extends React.Component{

    setRFC(evt){
        evt.target.value = evt.target.value.toUpperCase()

        var rfc_regex = new RegExp("^[A-Z0-9]{12,13}$");
        if (rfc_regex.test(evt.target.value)){
            isValid(evt.target.parentElement)
        }else{
            isInvalid(evt.target.parentElement)
        }
        checkValidForm(evt.target.parentElement.parentElement)
    }
    setRazonSocial(evt){
        evt.target.value = evt.target.value.toUpperCase()

        var data = evt.target.value;

        var name_regex = new RegExp("^\.{3,}$");
        if (name_regex.test(evt.target.value)){
            isValid(evt.target.parentElement)
        }else{
            isInvalid(evt.target.parentElement)
        }

        checkValidForm(evt.target.parentElement.parentElement)
    }

    verifyCP(evt){
        var cp_regex = new RegExp("^[0-9]{5}$");

        if (cp_regex.test(evt.target.value)){
            isValid(evt.target.parentElement)
        }else{
            isInvalid(evt.target.parentElement)
        }

        checkValidForm(evt.target.parentElement.parentElement)
    }

    sendInvoice(evt){

        var datas = JSON.parse(localStorage.getItem("result"));

        var formData = new FormData();
        formData.append("deal", datas.deal_id)
        formData.append("contact", datas.contact_id)
        formData.append("is_lead", datas.is_lead)
        formData.append("was_lead", datas.was_lead)


        document.querySelectorAll(".invoicing-container input,.invoicing-container select").forEach(element=>{
            formData.append(element.id,element.value)
        })

        fetch("https://payment-center.savethechildrenmx.org/Navidad/saveInvoicing.php",{
            method:'POST',
            body: formData
        }).then(resp=>{
            return resp.text()
        }).then(txt=>{
            localStorage.clear()
            window.location.href = "https://apoyo.savethechildren.mx/gracias-por-iluminar";
        })
    }
    render() {
        return (
            <div className="invoicing-container hide">
                <div className="card">
                    <div className="invoicing-header">
                        Datos de facturación
                    </div>
                    <div className="input-group">
                        <label htmlFor="">Email</label>
                        <input type="text" id="invoicing_email"/>
                    </div>
                    <div className="input-group">
                        <label htmlFor="">RFC: </label>
                        <input type="text" id="RFC" onKeyUp={this.setRFC}/>
                    </div>
                    <div className="input-group">
                        <label htmlFor="">Razón Social:</label>
                        <input type="text" id="razon_social" onKeyUp={this.setRazonSocial}/>
                    </div>
                    <div className="input-group">
                        <label htmlFor="">Régimen Fiscal:</label>
                        <select name="" id="regimen">

                        </select>
                    </div>
                    <div className="input-group">
                        <label htmlFor="">Código Postal</label>
                        <input type="number" id="CP" onKeyUp={this.verifyCP}/>
                    </div>
                    <div className="button-group">
                        <button className={"send_invoice"} onClick={this.sendInvoice}>Enviar</button>
                    </div>
                </div>
            </div>
        );
    }
}