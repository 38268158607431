import React from "react";


export default class Buttons extends React.Component{
    constructor() {
        super();
        setTimeout(()=>{
            document.querySelectorAll(".price").forEach(element=>{

            })

            document.querySelectorAll(".button-container>div:first-child").forEach(element=>{
                element.addEventListener("click",evt=>{

                    document.querySelectorAll(".dialog").forEach(element=>{
                        element.classList.add("d-none")
                    })

                    document.querySelectorAll(".svg-container g.prebuy").forEach(element=>{
                        if (!element.classList.contains("in-car")){
                            element.classList.remove("prebuy")
                            element.classList.add("white")
                        }
                    })

                    var target = evt.target
                    while(!target.classList.contains("button-container")){
                        target = target.parentElement
                    }
                    target.querySelector(".dialog").classList.remove("d-none")
                })
            })

            document.querySelectorAll(".dialog .info").forEach(element=>{
                element.addEventListener("click",evt=>{
                    document.querySelector(".info_container").classList.remove("hide")
                    document.querySelector(".info_container .instrucciones_header").textContent =
                        evt.target.parentElement.querySelector(".item").textContent
                    document.querySelector(".info_container .slide-1").innerHTML =
                        evt.target.getAttribute("value")
                })
            })
        },2000)
    }
    render() {
        return (
            <>
                <div className="button-container paredes_btn">
                    <div value="paredes"></div>
                    <div className="pulse"></div>
                    <div className="dialog right d-none">
                        <div className="item legend-34" value="tapete">Reacondicionamiento de espacio.</div>
                        <div className="price">Donativo libre</div>
                        <button value="0" className="buy-item" item="izquierda">Añadir al carrito</button>
                    </div>
                </div>
                <div className="button-container tapete_btn">
                    <div value="tapete"></div>
                    <div className="pulse"></div>
                    <div className="dialog right d-none">
                        <div className="item legend-34" value="tapete">Tapete de foami de colores</div>
                        <div className="price">Donativo libre</div>
                        <button value="0" className="buy-item" item="tapete">Añadir al carrito</button>
                    </div>
                </div>
                <div className="button-container estante_btn">
                    <div value="estante"></div>
                    <div className="pulse"></div>
                    <div className="dialog right d-none">
                        <div className="item legend-35" value="estantes">Estantes</div>
                        <div className="price">Donativo libre</div>
                        <button value="0" className="buy-item" item="estante">Añadir al carrito</button>
                    </div>
                </div>
                <div className="button-container ositos_btn">
                    <div value="osito"></div>
                    <div className="pulse"></div>
                    <div className="dialog right d-none">
                        <div className="item legend-36" value="peluches">Peluches para contención emocional</div>
                        <div className="price">Donativo libre</div>
                        <button value="0" className="buy-item" item="ositos">Añadir al carrito</button>
                    </div>
                </div>
                <div className="button-container bloques_btn">
                    <div value="bloques"></div>
                    <div className="pulse"></div>
                    <div className="dialog right d-none">
                        <div className="info">i</div>
                        <div className="item" value="juguetes">Juguetes didacticos</div>
                        <div className="price">$425.00</div>
                        <button value="425" className="buy-item" item="bloques">Añadir al carrito</button>
                    </div>
                </div>
                <div className="button-container empleado_btn">
                    <div value="empleado"></div>
                    <div className="pulse"></div>
                    <div className="dialog right d-none">
                        <div className="info">i</div>
                        <div className="item" value="especialista">Especialista en cuidados a la niñez</div>
                        <div className="price">$2,500.00</div>
                        <button value="2500" className="buy-item" item="empleado">Añadir al carrito</button>
                    </div>
                </div>
                <div className="button-container oso-nieve_btn">
                    <div value="oso-nieve"></div>
                    <div className="pulse"></div>
                    <div className="dialog right d-none">
                        <div className="item legend-37" value="peluches">Peluches para contención emocional</div>
                        <div className="price">Donativo libre</div>
                        <button value="0" className="buy-item" item="oso-nieve">Añadir al carrito</button>
                    </div>
                </div>
                <div className="button-container abaco_btn">
                    <div value="abaco"></div>
                    <div className="pulse"></div>
                    <div className="dialog right d-none">
                        <div className="item legend-38" values="juguetes">Juguetes didácticos</div>
                        <div className="price">Donativo libre</div>
                        <button value="0" className="buy-item" item="abaco">Añadir al carrito</button>
                    </div>
                </div>
                <div className="button-container estante-2_btn">
                    <div value="rincon-de-lectura"></div>
                    <div className="pulse"></div>
                    <div className="dialog left d-none">
                        <div className="info">i</div>
                        <div className="item" value="rincon_de_lectura">Rincón de lectura</div>
                        <div className="price">$150.00</div>
                        <button value="150" className="buy-item" item="estante-2">Añadir al carrito</button>
                    </div>
                </div>
                <div className="button-container tapetes_btn">
                    <div value="tapetes"></div>
                    <div className="pulse"></div>
                    <div className="dialog right d-none">
                        <div className="item legend-39" value="aro">Aro de gimnasia</div>
                        <div className="price">Donativo libre</div>
                        <button value="0" className="buy-item" item="tapetes">Añadir al carrito</button>
                    </div>
                </div>
                <div className="button-container kit-deportivo_btn">
                    <div value="kit-deportivo"></div>
                    <div className="pulse"></div>
                    <div className="dialog left d-none">
                        <div className="info">i</div>
                        <div className="item" value="deportivo">Material Deportivo</div>
                        <div className="price">$1,500.00</div>
                        <button value="1500" className="buy-item" item="kit-deportivo">Añadir al carrito</button>
                    </div>
                </div>
                <div className="button-container estante-3_btn">
                    <div value="estante-3"></div>
                    <div className="pulse"></div>
                    <div className="dialog right d-none">
                        <div className="item legend-40" value="estantes">Libreros y estantes</div>
                        <div className="price">Donativo libre</div>
                        <button value="0" className="buy-item" item="estante-3">Añadir al carrito</button>
                    </div>
                </div>
                <div className="button-container pizarron_btn">
                    <div value="pizarron"></div>
                    <div className="pulse"></div>
                    <div className="dialog left d-none">
                        <div className="item legend-41" value="pizarron">Pizarrón infantil</div>
                        <div className="price">Donativo libre</div>
                        <button value="0" className="buy-item" item="pizarron">Añadir al carrito</button>
                    </div>
                </div>
                <div className="button-container mesa_btn">
                    <div value="mesa"></div>
                    <div className="pulse"></div>
                    <div className="dialog left d-none">
                        <div className="info">i</div>
                        <div className="item" value="mesa">Mesa de trabajo</div>
                        <div className="price">$1,000.00</div>
                        <button value="1000" className="buy-item" item="mesa">Añadir al carrito</button>
                    </div>
                </div>
            </>
        );
    }
}