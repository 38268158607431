import React from "react";
import tapete from "../img/items/aros_100.svg";
import estante from "../img/items/estante_2_100.svg";
import osito from "../img/items/peluche_100.svg";
import bloques from "../img/items/cubos_100.svg";
import empleado from "../img/items/promotora_100.svg";
import oso_nieve from "../img/items/peluche_2_100.svg";
import abaco from "../img/items/abaco_100.svg";
import estante_2 from "../img/items/librero_y_libros_100.svg";
import tapetes from "../img/items/aros_100.svg";
import kit_deportivo from "../img/items/kit_deportivo_100.svg";
import estante_3 from "../img/items/elementos -22.svg";
import pizarron from "../img/items/pizarron_100.svg";
import mesa from "../img/items/mesa_de_dibujo_100.svg";

const url = new URLSearchParams(window.location.search);

function mxnformat(data){
    return Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(data) + " MXN"
}

function usdformat(data){
    return Intl.NumberFormat('es-MX',{style:'currency',currency:'USD'}).format(data)
}

function selectIcon(item){
    switch (item){
        case 'tapete':
            return tapete;
        case 'izquierda':
            return tapete;
        case 'estante':
            return estante;
        case 'ositos':
            return osito;
        case 'bloques':
            return bloques;
        case 'empleado':
            return empleado;
        case 'oso-nieve':
            return oso_nieve;
        case 'abaco':
            return abaco;
        case 'estante_2':
            return estante_2;
        case 'tapetes':
            return tapetes;
        case 'kit-deportivo':
            return kit_deportivo;
        case 'estante-3':
            return estante_3;
        case 'pizarron':
            return pizarron;
        case 'mesa':
            return mesa;
    }
}

function total(){
    var total = 0;
    var items = document.querySelectorAll(".car .items .item")

    document.querySelector(".items_counter").textContent = items.length


    items.forEach(element=>{
        var valor =Number(element.querySelector(".total-item").getAttribute("value"));
        var cantidad = element.querySelector(".quantity").textContent
        total += valor * cantidad;
    })

    if (url.get("lang").localeCompare("es") == 0){
        document.querySelector(".cash").textContent = mxnformat(total)
    }else{
        document.querySelector(".cash").textContent = usdformat(total)
    }
    document.querySelector(".cash").setAttribute("value",total)
}


export default class MontoLibre extends React.Component{

    close(){
        document.querySelector(".monto-libre").classList.add("hide")
    }

    createItemML(evt){

        if (evt.target.parentElement.parentElement.querySelector("input").value.length > 0){
            var h3 = evt.target.parentElement.parentElement.querySelector("h3")

            if (document.querySelectorAll(".car .items .item").length == 0){
                document.querySelector(".car .items").innerHTML=""
            }

            var item = document.createElement("item")
            item.classList.add("item")
            item.classList.add("prebuy")
            item.classList.add(h3.textContent.replaceAll(" ","_"))

            var image = document.createElement("div")
            image.classList.add("image")

            var img = document.createElement("img")
            img.src = selectIcon(evt.target.getAttribute("item"))

            image.appendChild(img)

            item.appendChild(image)


            var description = document.createElement("div")

            var name = document.createElement("div")
            name.classList.add("name")
            var text = h3.textContent
            text = text.split(":")
            name.textContent = text[1];

            var cantidad = document.createElement("div")
            cantidad.classList.add("cantidad")

            var span = document.createElement("span")
            span.textContent= "Cantidad: "

            var quantity = document.createElement("div")
            quantity.classList.add("quantity")
            quantity.textContent="1"

            var price = document.createElement("div")
            price.classList.add("price")

            var total_item = document.createElement("div")
            total_item.classList.add("total-item")
            total_item.setAttribute("value",evt.target.parentElement.parentElement.querySelector("input").value)
            if (url.get("lang").localeCompare("en")==0){
                total_item.textContent = usdformat(evt.target.parentElement.parentElement.querySelector("input").value)
            }else{
                total_item.textContent = mxnformat(evt.target.parentElement.parentElement.querySelector("input").value)
            }
            price.appendChild(total_item)

            cantidad.appendChild(span)
            cantidad.appendChild(quantity)
            description.appendChild(name)
            description.appendChild(cantidad)
            description.classList.add("description")


            item.appendChild(description)
            item.appendChild(price)

            document.querySelector(".car .items").appendChild(item)
            document.querySelector(".monto-libre").classList.add("hide")

            document.querySelector(".car").style.display="flex"

            total()

            document.querySelector("."+evt.target.getAttribute("item")).classList.add("in-car")
        }else{
            alert("La cantidad de donativo debe ser mayor que 0")
        }


    }

    checkAmount(evt){
        var amount = Number(evt.target.value)
        if (url.get("lang").localeCompare("es")==0){
            if (amount >= 100){
                document.querySelector(".legend-19").removeAttribute("disabled")
                document.querySelector(".legend-19").classList.remove("disabled")
                document.querySelector(".error").textContent=""
            }else{
                document.querySelector(".legend-19").setAttribute("disabled","disabled")
                document.querySelector(".legend-19").classList.add("disabled")
                document.querySelector(".error").textContent="El monto debe ser mayor o igual a 100.00 MXN"
            }
        }else{
            if (amount >= 5){
                document.querySelector(".legend-19").removeAttribute("disabled")
                document.querySelector(".legend-19").classList.remove("disabled")
                document.querySelector(".error").textContent=""
            }else{
                document.querySelector(".legend-19").setAttribute("disabled","disabled")
                document.querySelector(".legend-19").classList.add("disabled")
                document.querySelector(".error").textContent="Amount must be greater then 5.00 USD"
            }
        }
    }

    render() {
        return (
            <div className="monto-libre hide">
                <div className="monto-libre_container">
                    <h2 className="legend-16">Monto Libre</h2>
                    <h3>Concepto</h3>
                    <div className="form-group">
                        <label htmlFor="" className="legend-17">Monto</label>
                        <input type="text" id="monto-libre" onKeyUp={this.checkAmount}/>
                        <div className="error"></div>
                    </div>
                    <div className="buttons">
                        <button onClick={this.close} className="legend-18">Cancelar</button>
                        <button onClick={this.createItemML} className="legend-19">Aceptar</button>
                    </div>
                </div>
            </div>
        );
    }
}