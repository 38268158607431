import React from "react";

export default class Instructions extends React.Component{

    openInstructions(){
        document.querySelector(".instrucciones").classList.remove("hide")
        document.querySelector(".slide-1").classList.remove("d-none")
        document.querySelector(".slide-2").classList.add("d-none")
    }
    render() {
        return (
            <div className="container instructions">
                <button onClick={this.openInstructions} className="legend-4">
                    Instrucciones
                </button>
            </div>
        );
    }
}