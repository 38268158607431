import React from "react";


export default class Intrucciones extends React.Component{
    slide2(){
        document.querySelector(".slide-1").classList.add("d-none")
        document.querySelector(".slide-2").classList.remove("d-none")
    }

    close(){
        document.querySelector(".instrucciones").classList.add("hide")
    }
    render() {
        return (
            <div className="instrucciones">
                <div className="container">
                    <div className="tools">
                        <div className="close" onClick={this.close}>
                            <span className="legend-14">Cerrar</span> <span className="cross">X</span>
                        </div>
                    </div>
                    <div className="instrucciones_header legend-5">
                        Instrucciones
                    </div>
                    <div className="slides">
                        <div className="slide-1">
                            <p className="legend-6">Un espacio seguro es un <b>lugar en el que niñas y niños pueden recuperarse</b> después de vivir eventos adversos o emergencias. Estos espacios los equipamos con juguetes y material artístico y didáctico; además la niñez está bajo el cuidado de staff capacitado que le brinda apoyo socioemocional y educativo <b>en lo que su vida vuelve a la normalidad</b>.</p>
                            <p className="legend-7">Necesitamos equipar 10 espacios seguros que protegerán a la niñez en Guerrero. </p>
                            <p className="legend-8">¿Nos ayudas a llegar a la meta? </p>
                            <button onClick={this.slide2} className="legend-9">Continuar</button>
                        </div>

                        <div className="slide-2 d-none">
                            <p>
                                <ol>
                                    <li className="legend-10"><b>Selecciona</b>, uno a uno, los elementos del espacio seguro que quieras comprar. ¡Mira cómo se iluminan al elegirlos!</li>
                                    <li className="legend-11"><b>Dirígete al carrito de compras para confirmar y pagar.</b> Completa tus datos en el formulario que te guiará paso a paso para finalizar tu compra.</li>
                                    <li className="legend-12">Y ¡listo! <b>La ayuda llegará a los espacios seguros para la niñez</b>. También, si lo deseas, podrás obtener un recibo deducible.</li>
                                </ol>
                            </p>
                            <button onClick={this.close} className="legend-13">Ilumina su futuro ahora</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}