import React from "react";

export default class Error extends React.Component{

    close(){
        document.querySelector(".error-container").classList.add("hide")
    }

    redirect(){
        window.location.href="https://apoyo.savethechildren.mx/error-pago"
    }
    render() {
        return (
            <div className="error-container hide">
                <h2>¡OH NO!</h2>
                <h3 className="legend-49">TUVIMOS UN PROBLEMA AL PROCESAR TU PAGO.</h3>
                <h4 className="legend-50">Mensaje de error:</h4>
                <h3 className=" error">
                    Error
                </h3>

                <div className="buttons">
                    <button className="legend-51" onClick={this.close}>Reintentar</button>
                    <button className="legend-52" onClick={this.redirect}>Terminar intento</button>
                </div>
            </div>
        );
    }
}