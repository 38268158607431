import './css/App.css';
import EspacioSeguro from "./Components/EspacioSeguro";
import start from "./plugins/EspacioSeguro";
import Buttons from "./Components/Buttons";
import Header from "./Components/Header";
import Meta from "./Components/Meta";
import Payment from "./Components/Payment";
import Intrucciones from "./Components/Intrucciones";
import Instructions from "./Components/Instructions";
import Info from "./Components/Info";
import MontoLibre from "./Components/MontoLibre";
import Success from "./Components/Success";
import Error from "./Components/Error";
import Loading from "./Components/Loading";
import Validacion3D from "./Components/validacion3D";
import Invoicing from "./Components/invoicing";
import Footer from "./Components/Footer";

function App() {

    setTimeout(start,1500);

    return (
        <div className="App">
            <Header/>
            <div className="banner"></div>
            <div className="container">
                <MontoLibre/>
                <Intrucciones/>
                <Instructions/>
                <Meta/>
                <EspacioSeguro/>
                <Buttons/>
                <Payment/>
                <Info/>
                <Success/>
                <Error/>
                <Loading/>
                <Validacion3D/>
                <Invoicing/>
                <Footer/>
            </div>
        </div>
    );
}

export default App;
