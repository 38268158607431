import React from "react";
import pagos from "../img/pagos.svg";

const url = new URLSearchParams(window.location.search);
function isValid(evt){
    var element= evt.target
    element.parentElement.classList.add("is-valid")
    element.parentElement.classList.remove("is-invalid")

    document.querySelectorAll(".error").forEach(error=>{
        error.textContent = ""
    })
}

function isInvalid(evt){
    var element= evt.target
    element.parentElement.classList.remove("is-valid")
    element.parentElement.classList.add("is-invalid")
}

function validate(evt){
    var container = evt.target;
    var flag = true;

    while(!container.classList.contains("payment_datas")){
        container = container.parentElement
    }

    container.querySelectorAll("input[type='text']").forEach(element=>{
        if(element.parentElement.classList.contains("is-invalid") || !element.parentElement.classList.contains("is-valid")){
            flag = false;
        }
    })

    if (!flag){
        container.parentElement.querySelector("button").classList.add("disabled")
        container.parentElement.querySelector("button").setAttribute("disabled","disabled")
    }else{
        container.parentElement.querySelector("button").classList.remove("disabled")
        container.parentElement.querySelector("button").removeAttribute("disabled")
    }
}

function sendItems(register){
    var formData = new FormData();
    formData.append("register",register)


    var items = []
    var server_items = JSON.parse(localStorage.getItem("items"))


    document.querySelectorAll(".items .item").forEach(element=>{

        if (url.get("lang").localeCompare("es")==0){
            var item = server_items.filter((entry)=>{
                return entry.es_name.includes(element.querySelector(".name").textContent)
            })
        }else{
            var item = server_items.filter((entry)=>{
                return entry.en_name.includes(element.querySelector(".name").textContent)
            })
        }


         var aux = {
            item_id: item.length > 0 ? item[0].item_id : -1,
            nombre: element.querySelector(".name").textContent,
            cantidad : element.querySelector(".quantity").textContent,
            precio_unitario: element.querySelector(".total-item").getAttribute("value")
         }

         items.push(aux)
    })

    formData.append("items",JSON.stringify(items))
    formData.append("currency",document.getElementById("currency").value)

    fetch("https://payment-center.savethechildrenmx.org/Navidad/items.php",{
        method:"post",
        body:formData
    }).then(resp=>{return resp.text()}).then(txt=>{
        console.log(txt)
    })
}

export default class Payment extends React.Component{
    sendPayment(evt){
        document.querySelector(".cargando_container").classList.remove("hide")
        var formData = new FormData();
        var search = new URLSearchParams(window.location.search);

        document.querySelectorAll(".payment_container input").forEach(element=>{
            formData.append(element.id,element.value)
        })

        if (search.get("utm_campaign") != null){
            formData.append("utm_campaign",search.get("utm_campaign"))
        }
        if (search.get("utm_source") != null){
            formData.append("utm_source",search.get("utm_source"))
        }
        if (search.get("utm_medium") != null){
            formData.append("utm_medium",search.get("utm_medium"))
        }
        if (search.get("utm_term") != null){
            formData.append("utm_term",search.get("utm_term"))
        }
        if (search.get("utm_content") != null){
            formData.append("utm_content",search.get("utm_content"))
        }

        fetch("https://payment-center.savethechildrenmx.org/Navidad/payment.php",{
            method:'post',
            body:formData
        }).then(resp=>{return resp.text()}).then(txt=>{
            document.querySelector(".cargando_container").classList.add("hide")
            localStorage.setItem("result",txt)
            var json = JSON.parse(txt)
            if (json.payment_intent != null){
                if(json.payment_intent.status.localeCompare("succeeded") == 0){
                    document.querySelector(".success-container").classList.remove("hide")
                }

                if(json.payment_intent.status.localeCompare("requires_action") == 0){
                    window.open(json.payment_intent.next_action.redirect_to_url.url,"3D validation","width="+window.innerWidth+",height="+window.innerHeight);
                    document.querySelector(".validacion_container").classList.remove("hide")
                    setTimeout(()=>{
                        document.querySelector(".validate").classList.remove("d-none")
                    },3000)
                }

                sendItems(json.register)

            }else{
                document.querySelector(".error-container").classList.remove("hide")
                document.querySelector(".error-container .error").textContent = json.error
            }
        })
    }

    step2(evt){
        document.querySelector(".payment_step_1").classList.add("d-none")
        document.querySelector(".payment_step_2").classList.remove("d-none")

        var formData = new FormData();
        var search = new URLSearchParams(window.location.search);

        document.querySelectorAll(".payment_container .payment_step_1 input").forEach(element=>{
            formData.append(element.id,element.value)
        })

        if (search.get("utm_campaign") != null){
            formData.append("utm_campaign",search.get("utm_campaign"))
        }
        if (search.get("utm_source") != null){
            formData.append("utm_source",search.get("utm_source"))
        }
        if (search.get("utm_medium") != null){
            formData.append("utm_medium",search.get("utm_medium"))
        }
        if (search.get("utm_term") != null){
            formData.append("utm_term",search.get("utm_term"))
        }
        if (search.get("utm_content") != null){
            formData.append("utm_content",search.get("utm_content"))
        }

        fetch("https://payment-center.savethechildrenmx.org/Navidad/Lead.php",{
            method:'post',
            body:formData
        }).then(resp=>{return resp.text()}).then(txt=>{console.log(txt)})
    }

    dateFormat(evt){
        var date = evt.target.value
        var aux_date = evt.target
        var date_regex = new RegExp("^[0-9]{2}\\/[0-9]{2}$")
        var element = aux_date

        while(!element.classList.contains("payment_datas")){
            element = element.parentElement
        }

        if(evt.key.localeCompare("Backspace") == 0){
            if (date.length == 3){
                date = date[0]+date[1]
            }
        }else{
            if (date.length == 2){
                date = date + "/"
            }

            if (date.length == 3 && !date.includes("/")){
                date = date[0]+date[1]+"/"+date[2]
            }
        }

        evt.target.value = date

        if (date_regex.test(date)){
            isValid(evt)
        }else{
            isInvalid(evt)
            if (url.get("lang").localeCompare("en") == 0){
                element.querySelector(".error").textContent = "Error: Expiration date must have mm/yy format"
            }else{
                element.querySelector(".error").textContent = "Error: La fecha de expiracion debe tener el formato mm/yy"
            }
        }
        validate(evt)
    }

    manageFocus(evt){
        var element= evt.target
        element.parentElement.classList.add("active")
    }

    manageBlur(evt){
        var element= evt.target

        if (element.value.length == 0){
            element.parentElement.classList.remove("active")
        }
    }

    handleName(evt){
        var name =evt.target
        var name_regex = new RegExp("^[a-zA-ZáéíóúÁÉÍÓÚñÑ]{1,}( [a-zA-ZáéíóúÁÉÍÓÚñÑ]{1,})*$")

        var element = name

        while(!element.classList.contains("payment_datas")){
            element = element.parentElement
        }


        if (name_regex.test(name.value)){
            isValid(evt)
        }else{
            isInvalid(evt)
            if (url.get("lang").localeCompare("en") == 0){
                element.querySelector(".error").textContent = "Error: The name cannot have a space at the end and must not have numbers"
            }else{
                element.querySelector(".error").textContent = "Error: el nombre no puede tener espacio al final y no debe contener números"
            }
        }
        validate(evt)

    }

    handleEmail(evt){
        var email_regex = new RegExp('^[\\w-\.]+@([\\w-]+\.)+[\\w-]{2,4}$');
        var email = evt.target
        var element = email

        while(!element.classList.contains("payment_datas")){
            element = element.parentElement
        }

        if (email_regex.test(email.value)){
            isValid(evt)
        }else{
            isInvalid(evt)
            if (url.get("lang").localeCompare("en") == 0){
                element.querySelector(".error").textContent = "Error: The email isn't a valid email"
            }else{
                element.querySelector(".error").textContent = "Error: el correo no parece ser un email valido"
            }
        }

        validate(evt)
    }

    handlePhone(evt){
        var phone_regex = new RegExp("^(\\+[0-9]{2,3}){0,1}[0-9]{10}$");
        var phone = evt.target
        var element = phone

        while(!element.classList.contains("payment_datas")){
            element = element.parentElement
        }

        if (phone_regex.test(phone.value)){
            isValid(evt)
        }else{
            isInvalid(evt)
            if (url.get("lang").localeCompare("en") == 0) {
                element.querySelector(".error").textContent = "Error: The phone number must have only numbers and only 10 digits"
            }else{
                element.querySelector(".error").textContent = "Error: el número telefónico debe contener únicamente números y debe de contener únicamente 10 dígitos"
            }
        }

        validate(evt)
    }

    handleCardNumber(evt){
        var card_regex = new RegExp("^[0-9]{15,16}$")
        var card = evt.target
        var element = card

        while(!element.classList.contains("payment_datas")){
            element = element.parentElement
        }


        if (card_regex.test(card.value)){
            isValid(evt)
        }else{
            isInvalid(evt)
            if (url.get("lang").localeCompare("en") == 0){
                element.querySelector(".error").textContent = "Error: The card number must have only numbers and only 15 or 16 digits"
            }else{
                element.querySelector(".error").textContent = "Error: el número de tarjerta debe contener únicamente números y debe de contener únicamente 15 ó 16 dígitos"
            }
        }

        validate(evt)
    }

    handleCVV(evt){
        var cvv = evt.target
        var cvv_regex = new RegExp("^[0-9]{3,4}$")
        var element = cvv

        while(!element.classList.contains("payment_datas")){
            element = element.parentElement
        }

        if (cvv_regex.test(cvv.value)){
            isValid(evt)
        }else{
            isInvalid(evt)

            if (url.get("lang").localeCompare("en") == 0) {
                element.querySelector(".error").textContent = "Error: CVV must contains between 3 and 4 numbers."
            }else{
                element.querySelector(".error").textContent = "Error: El cvv es una clave entre 3 y 4 numeros";
            }
        }

        validate(evt)
    }

    render() {
        return (
            <div className="payment_container d-none">
                <h1 className="legend-20">Procesador de Pagos</h1>
                <h3>0.00</h3>
                <div className="payment_step_1">
                    <div className="payment_header">
                        <div className="number">1</div>
                        <div className="text legend-21">DATOS</div>
                    </div>
                    <div className="payment_datas">
                        <div className="middle-form">
                            <div className="form-group">
                                <label htmlFor="" className="legend-22">Email:</label>
                                <input type="text" id="email" onFocus={this.manageFocus} onBlur={this.manageBlur} onKeyUp={this.handleEmail}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="" className="legend-23">Teléfono</label>
                                <input type="text" id="phone" onFocus={this.manageFocus} onBlur={this.manageBlur} onKeyUp={this.handlePhone}/>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="" className="legend-24">Nombre(s):</label>
                            <input type="text" id="name" onFocus={this.manageFocus} onBlur={this.manageBlur} onKeyUp={this.handleName.bind(this)}/>
                        </div>
                        <div className="middle-form">
                            <div className="form-group">
                                <label htmlFor="" className="legend-25">Apellido paterno:</label>
                                <input type="text" id="last_name_1" onFocus={this.manageFocus} onBlur={this.manageBlur} onKeyUp={this.handleName.bind(this)}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="" className="legend-26">Apellido materno:</label>
                                <input type="text" id="last_name_2" onFocus={this.manageFocus} onBlur={this.manageBlur} onKeyUp={this.handleName.bind(this)}/>
                            </div>
                        </div>
                        <div className="error"></div>
                    </div>
                    <div className="payment_button">
                        <button onClick={this.step2} className="legend-27">Continuar</button>
                    </div>
                </div>
                <div className="payment_step_2 d-none">
                    <div className="payment_header">
                        <div className="number">2</div>
                        <div className="text legend-28">DATOS BANCARIOS</div>
                    </div>
                    <div className="payment_datas">
                        <div className="form-group">
                            <label htmlFor="" className="legend-29">Titular: </label>
                            <input type="text" onFocus={this.manageFocus} onBlur={this.manageBlur} onKeyUp={this.handleName} id="titular"/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="" className="legend-30">Número de tarjeta:</label>
                            <input type="text" id="tarjeta" onFocus={this.manageFocus} onBlur={this.manageBlur} onKeyUp={this.handleCardNumber}/>
                        </div>
                        <div className="middle-form">
                            <div className="form-group">
                                <label htmlFor="" className="legend-31">Fecha de expiración (mm/aa):</label>
                                <input type="text" onKeyUp={this.dateFormat} onFocus={this.manageFocus} onBlur={this.manageBlur} id="fecha_expiracion"/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">CVC/CVV:</label>
                                <input type="text" id="cvv" onFocus={this.manageFocus} onBlur={this.manageBlur} onKeyUp={this.handleCVV}/>
                                <input type="hidden" id="currency" value="mxn"/>
                                <input type="hidden" id="amount"/>
                            </div>
                        </div>
                        <div className="form-group">
                            <img src={pagos} className="pagos" alt=""/>
                        </div>
                        <div className="form-group-chk">
                            <input type="checkbox" name="" id="aviso_privacidad" checked/>
                            <label htmlFor="" className="legend-32">He leído el <a href="https://www.savethechildren.mx/aviso-de-privacidad-donantes/">AVISO de privacidad</a> de Save the Children México</label>
                        </div>
                        <div className="error"></div>
                        <div className="payment_button">
                            <button onClick={this.sendPayment} className="legend-33">Finalizar y pagar</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}