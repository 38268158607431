const url = new URLSearchParams(window.location.search);
var items = null;

function mxnformat(data){
    return Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(data) + " MXN"
}

function usdformat(data){
    return Intl.NumberFormat('es-MX',{style:'currency',currency:'USD'}).format(data)
}


export default function start(){
    if (url.get("lang") == null){
        window.location.href = "https://iluminasufuturo.mx/?lang=es"
    }
    window.scroll({top:0,behavior:"smooth"})
    getNavidadItems();
    translate();

    setTimeout(()=>{
        init_tapete()
        init_ositos()
        init_bloques()
        init_estante()
        init_empleado()
        init_oso_nieve()
        init_abaco()
        init_estante_2()
        init_tapetes()
        init_kit_deportivo()
        init_estante_3()
        init_pizarron()
        init_mesa()
        init_paredes();
    },2000)

    setTimeout(()=>{
        document.querySelector(".cargando_container").classList.add("hide")
        document.body.classList.remove("lock")
        fetchTotal()
        setInterval(fetchTotal,30000);
    },3000)
}




function fetchTotal(){
    fetch("https://payment-center.savethechildrenmx.org/Helpers/getNavidadTotal.php",{
        method:'get',
    }).then(resp=>{return resp.text()}).then(txt=>{
        var total = Number(txt);
        var percent = ((total * 100)/3000000).toFixed(2)

        document.querySelector(".progress-bar").style.width= percent+"%"
        document.querySelector(".percent").textContent = mxnformat(total)  + "("+percent+" %)" ;
    })
}

function translate(){
    if (url.get("lang").localeCompare("en")==0) {
        document.querySelector(".legend-1").textContent = "No items yet!"
        document.querySelector(".legend-2").textContent = "Total:"
        document.querySelector(".legend-3").textContent = "Pay"
        document.querySelector(".legend-4").textContent = "Instructions"
        document.querySelector(".legend-5").textContent = "Instructions"
        document.querySelector(".legend-6").innerHTML = "A <b>safe space is a place where girls and boys can recover</b> after experiencing adverse events or emergencies. We furnish these spaces <b>with toys and artistic and educational materials</b>. Additionally, the children are under the care of trained staff who provide them with <b>socio-emotional and educational support until their lives return to normal</b>."
        document.querySelector(".legend-7").textContent = "We need to equip 10 safe spaces that will protect the children in Guerrero. "
        document.querySelector(".legend-8").innerHTML = "<b>Will you help us reach our goal?</b>"
        document.querySelector(".legend-9").textContent = "Next"
        document.querySelector(".legend-10").innerHTML = "<b>Select</b>, one by one, the elements of the safe space you want to purchase. Watch how they light up as you choose them! "
        document.querySelector(".legend-11").innerHTML = "<b>Go to the shopping cart to confirm and pay</b>. Fill in your details in the form that will guide you step by step to complete your purchase. "
        document.querySelector(".legend-12").innerHTML="You're done! <b>The assistance will reach the safe spaces for children.</b> Also, if you wish, you can obtain a deductible receipt. "
        document.querySelector(".legend-13").textContent="Start shopping "
        document.querySelector(".legend-14").textContent="Close"
        document.querySelector(".legend-15").textContent="Help us reach our goal of $3,000,000.00 MXN!"
        document.querySelector(".legend-16").textContent="Free amount"
        document.querySelector(".legend-17").textContent="Amount:"
        document.querySelector(".legend-18").textContent="Cancel"
        document.querySelector(".legend-19").textContent="Continue"
        document.querySelector(".legend-20").textContent="Payment processor"
        document.querySelector(".legend-21").textContent="DATAS"
        document.querySelector(".legend-22").textContent="Email"
        document.querySelector(".legend-23").textContent="Phone"
        document.querySelector(".legend-24").textContent="Name(s)"
        document.querySelector(".legend-25").textContent="Last Name"
        document.querySelector(".legend-26").parentElement.querySelector("input").setAttribute("disabled","disabled")
        document.querySelector(".legend-26").parentElement.classList.add("is-valid")
        document.querySelector(".legend-26").parentElement.classList.add("d-none")
        document.querySelector(".legend-27").textContent="Continue"
        document.querySelector(".legend-28").textContent="Bank Datas"
        document.querySelector(".legend-29").textContent="Cardholder name"
        document.querySelector(".legend-30").textContent="Card number"
        document.querySelector(".legend-31").textContent="Expiration date (mm/yy)"
        document.querySelector(".legend-32").innerHTML="I have read and accept the <a href=\"https://www.savethechildren.mx/aviso-de-privacidad-donantes/\">privacy policy</a>"
        document.querySelector(".legend-33").textContent="Pay and Finish"
        document.querySelector(".legend-34").textContent="Foam mat"
        document.querySelector(".legend-35").textContent="Shelves"
        document.querySelector(".legend-36").textContent="Stuffed animals for emotional support"
        document.querySelector(".legend-37").textContent="Stuffed animals for emotional support"
        document.querySelector(".legend-38").textContent="Educational toys"
        document.querySelector(".legend-39").textContent="Gymnastics hoop"
        document.querySelector(".legend-40").textContent="Books and shelves"
        document.querySelector(".legend-41").textContent="blackboard"
        document.querySelectorAll(".price").forEach(element=>{
            if(element.textContent.localeCompare("Donativo libre") == 0){
                element.textContent = "Free donation"
            }
        })

        document.querySelectorAll(".buy-item").forEach(element=>{
            element.textContent = "Add to cart"
        })

        document.querySelector(".legend-42").textContent="Please wait"
        document.querySelector(".legend-43").textContent="Successful purchase"
        document.querySelector(".legend-44").textContent="Thanks for helping children!"
        document.querySelector(".legend-45").textContent="I want an invoice"
        document.querySelector(".legend-46").textContent="End purchase"
        document.querySelector(".legend-47").textContent="Processing"
        document.querySelector(".legend-48").textContent="Verify"
        document.querySelector(".legend-49").textContent="WE HAVE A PROBLEM WITH YOUR PURCHASE"
        document.querySelector(".legend-50").textContent="Error message:"
        document.querySelector(".legend-51").textContent="Retry"
        document.querySelector(".legend-52").textContent="Finish"
        // document.querySelector(".legend-").textContent=""
    }
}

function getNavidadItems(){
    fetch("https://api.savethechildrenmx.org/Navidad/getNavidadItems.php",{
        method:'get',
        body: null
    }).then(resp=>{return resp.text()}).then(txt=>{localStorage.setItem("items",txt);items = JSON.parse(txt)})
}
//

function init_paredes(){
    const tapete =  document.querySelector("#izquierda");
    var location = tapete.getBoundingClientRect();

    const tapete_btn = document.querySelector(".paredes_btn")

    tapete_btn.style.top = (location.y + location.height/2)+"px";
    tapete_btn.style.left = (location.x + (location.width/2.19))+"px";
    tapete_btn.style.opacity = 1;

    tapete_btn.addEventListener("click",evt=>{
        document.querySelector("#izquierda").classList.remove("white")
        document.querySelector("#izquierda").classList.add("prebuy")
    })
}
function init_tapete(){
    const tapete =  document.querySelector(".tapete");
    var location = tapete.getBoundingClientRect();

    const tapete_btn = document.querySelector(".tapete_btn")

    tapete_btn.style.top = (location.y + location.height/2)+"px";
    tapete_btn.style.left = (location.x + (location.width/2.19))+"px";
    tapete_btn.style.opacity = 1;

    tapete_btn.addEventListener("click",evt=>{
        document.querySelector(".tapete").classList.remove("white")
        document.querySelector(".tapete").classList.add("prebuy")
    })
}

function init_ositos(){
    const tapete =  document.querySelector(".ositos");
    var location = tapete.getBoundingClientRect();

    const tapete_btn = document.querySelector(".ositos_btn")
    tapete_btn.style.top = (location.y + location.height/3)+"px";
    tapete_btn.style.left = (location.x + (location.width/5))+"px";
    tapete_btn.style.opacity = 1;



    tapete_btn.addEventListener("click",evt=>{
        document.querySelector(".ositos").classList.remove("white")
        document.querySelector(".ositos").classList.add("prebuy")
    })
}

function init_bloques(){
    const tapete =  document.querySelector(".bloques");
    var location = tapete.getBoundingClientRect();

    const tapete_btn = document.querySelector(".bloques_btn")
    tapete_btn.style.top = (location.y + location.height/2)+"px";
    tapete_btn.style.left = (location.x + (location.width/3))+"px";
    tapete_btn.style.opacity = 1;

    var item = items.filter((entry)=>{
        return entry.es_name.includes("Juguetes")
    })

    if (item.length > 0){
        if (url.get("lang").localeCompare("es") == 0){
            tapete_btn.querySelector(".item").textContent = item[0].es_name
            tapete_btn.querySelector(".price").textContent = mxnformat(item[0].mx_amount)
            tapete_btn.querySelector(".buy-item").setAttribute("value",item[0].mx_amount)
            tapete_btn.querySelector(".info").setAttribute("value",item[0].es_desc)
        }else{
            tapete_btn.querySelector(".item").textContent = item[0].en_name
            tapete_btn.querySelector(".price").textContent = usdformat(item[0].usd_amount)
            tapete_btn.querySelector(".buy-item").setAttribute("value",item[0].usd_amount)
            tapete_btn.querySelector(".info").setAttribute("value",item[0].en_desc)
        }
    }

    tapete_btn.addEventListener("click",evt=>{
        document.querySelector(".bloques").classList.remove("white")
        document.querySelector(".bloques").classList.add("prebuy")
    })
}

function init_estante(){
    const tapete =  document.querySelector(".estante");
    var location = tapete.getBoundingClientRect();

    const tapete_btn = document.querySelector(".estante_btn")
    tapete_btn.style.top = (location.y + location.height/3)+"px";
    tapete_btn.style.left = (location.x + (location.width/5))+"px";
    tapete_btn.style.opacity = 1;

    tapete_btn.addEventListener("click",evt=>{
        document.querySelector(".estante").classList.remove("white")
        document.querySelector(".estante").classList.add("prebuy")
    })
}
function init_empleado(){
    const tapete =  document.querySelector(".empleado");
    var location = tapete.getBoundingClientRect();

    const tapete_btn = document.querySelector(".empleado_btn")
    tapete_btn.style.top = (location.y + location.height/3)+"px";
    tapete_btn.style.left = (location.x + (location.width/2))+"px";
    tapete_btn.style.opacity = 1;

    var item = items.filter((entry)=>{
        return entry.es_name.includes("Especialista")
    })

    if (item.length > 0){
        if (url.get("lang").localeCompare("es") == 0){
            tapete_btn.querySelector(".item").textContent = item[0].es_name
            tapete_btn.querySelector(".price").textContent = mxnformat(item[0].mx_amount)
            tapete_btn.querySelector(".buy-item").setAttribute("value",item[0].mx_amount)
            tapete_btn.querySelector(".info").setAttribute("value",item[0].es_desc)
        }else{
            tapete_btn.querySelector(".item").textContent = item[0].en_name
            tapete_btn.querySelector(".price").textContent = usdformat(item[0].usd_amount)
            tapete_btn.querySelector(".buy-item").setAttribute("value",item[0].usd_amount)
            tapete_btn.querySelector(".info").setAttribute("value",item[0].en_desc)
        }
    }

    tapete_btn.addEventListener("click",evt=>{
        document.querySelector(".empleado").classList.remove("white")
        document.querySelector(".empleado").classList.add("prebuy")
    })
}

function init_oso_nieve(){
    const tapete =  document.querySelector(".oso-nieve");
    var location = tapete.getBoundingClientRect();

    const tapete_btn = document.querySelector(".oso-nieve_btn")
    tapete_btn.style.top = (location.y + location.height/3)+"px";
    tapete_btn.style.left = (location.x + (location.width/2))+"px";
    tapete_btn.style.opacity = 1;

    tapete_btn.addEventListener("click",evt=>{
        document.querySelector(".oso-nieve").classList.remove("white")
        document.querySelector(".oso-nieve").classList.add("prebuy")
    })
}

function init_abaco(){
    const tapete =  document.querySelector(".abaco");
    var location = tapete.getBoundingClientRect();

    const tapete_btn = document.querySelector(".abaco_btn")
    tapete_btn.style.top = (location.y + location.height/3)+"px";
    tapete_btn.style.left = (location.x + (location.width/2))+"px";
    tapete_btn.style.opacity = 1;

    tapete_btn.addEventListener("click",evt=>{
        document.querySelector(".abaco").classList.remove("white")
        document.querySelector(".abaco").classList.add("prebuy")
    })
}

function init_estante_2(){
    const tapete =  document.querySelector(".estante-2");
    var location = tapete.getBoundingClientRect();

    const tapete_btn = document.querySelector(".estante-2_btn")
    tapete_btn.style.top = (location.y + location.height/3)+"px";
    tapete_btn.style.left = (location.x + (location.width/2))+"px";
    tapete_btn.style.opacity = 1;

    var item = items.filter((entry)=>{
        return entry.es_name.includes("Rincón ")
    })

    if (item.length > 0){
        if (url.get("lang").localeCompare("es") == 0){
            tapete_btn.querySelector(".item").textContent = item[0].es_name
            tapete_btn.querySelector(".price").textContent = mxnformat(item[0].mx_amount)
            tapete_btn.querySelector(".buy-item").setAttribute("value",item[0].mx_amount)
            tapete_btn.querySelector(".info").setAttribute("value",item[0].es_desc)
        }else{
            tapete_btn.querySelector(".item").textContent = item[0].en_name
            tapete_btn.querySelector(".price").textContent = usdformat(item[0].usd_amount)
            tapete_btn.querySelector(".buy-item").setAttribute("value",item[0].usd_amount)
            tapete_btn.querySelector(".info").setAttribute("value",item[0].en_desc)
        }
    }

    tapete_btn.addEventListener("click",evt=>{
        document.querySelector(".estante-2").classList.remove("white")
        document.querySelector(".estante-2").classList.add("prebuy")
    })
}

function init_tapetes(){
    const tapete =  document.querySelector(".tapetes");
    var location = tapete.getBoundingClientRect();

    const tapete_btn = document.querySelector(".tapetes_btn")
    tapete_btn.style.top = (location.y + location.height/3)+"px";
    tapete_btn.style.left = (location.x + (location.width/2))+"px";
    tapete_btn.style.opacity = 1;

    tapete_btn.addEventListener("click",evt=>{
        document.querySelector(".tapetes").classList.remove("white")
        document.querySelector(".tapetes").classList.add("prebuy")
    })
}

function init_kit_deportivo(){
    const tapete =  document.querySelector(".kit-deportivo");
    var location = tapete.getBoundingClientRect();

    const tapete_btn = document.querySelector(".kit-deportivo_btn")
    tapete_btn.style.top = (location.y + location.height/3)+"px";
    tapete_btn.style.left = (location.x + (location.width/2))+"px";
    tapete_btn.style.opacity = 1;

    var item = items.filter((entry)=>{
        return entry.es_name.includes("Material")
    })

    if (item.length > 0){
        if (url.get("lang").localeCompare("es") == 0){
            tapete_btn.querySelector(".item").textContent = item[0].es_name
            tapete_btn.querySelector(".price").textContent = mxnformat(item[0].mx_amount)
            tapete_btn.querySelector(".buy-item").setAttribute("value",item[0].mx_amount)
            tapete_btn.querySelector(".info").setAttribute("value",item[0].es_desc)
        }else{
            tapete_btn.querySelector(".item").textContent = item[0].en_name
            tapete_btn.querySelector(".price").textContent = usdformat(item[0].usd_amount)
            tapete_btn.querySelector(".buy-item").setAttribute("value",item[0].usd_amount)
            tapete_btn.querySelector(".info").setAttribute("value",item[0].en_desc)
        }
    }

    tapete_btn.addEventListener("click",evt=>{
        document.querySelector(".kit-deportivo").classList.remove("white")
        document.querySelector(".kit-deportivo").classList.add("prebuy")
    })
}

function init_estante_3(){
    const tapete =  document.querySelector(".estante-3");
    var location = tapete.getBoundingClientRect();

    const tapete_btn = document.querySelector(".estante-3_btn")
    tapete_btn.style.top = (location.y + location.height/3)+"px";
    tapete_btn.style.left = (location.x + (location.width/2))+"px";
    tapete_btn.style.opacity = 1;

    tapete_btn.addEventListener("click",evt=>{
        document.querySelector(".estante-3").classList.remove("white")
        document.querySelector(".estante-3").classList.add("prebuy")
    })
}

function init_pizarron(){
    const tapete =  document.querySelector(".pizarron");
    var location = tapete.getBoundingClientRect();

    const tapete_btn = document.querySelector(".pizarron_btn")
    tapete_btn.style.top = (location.y + location.height/3)+"px";
    tapete_btn.style.left = (location.x + (location.width/2))+"px";
    tapete_btn.style.opacity = 1;

    tapete_btn.addEventListener("click",evt=>{
        document.querySelector(".pizarron").classList.remove("white")
        document.querySelector(".pizarron").classList.add("prebuy")
    })
}

function init_mesa(){
    const tapete =  document.querySelector(".mesa");
    var location = tapete.getBoundingClientRect();

    const tapete_btn = document.querySelector(".mesa_btn")
    tapete_btn.style.top = (location.y + location.height/3)+"px";
    tapete_btn.style.left = (location.x + (location.width/2))+"px";
    tapete_btn.style.opacity = 1;

    var item = items.filter((entry)=>{
        return entry.es_name.includes("Mesa")
    })

    if (item.length > 0){
        if (url.get("lang").localeCompare("es") == 0){
            tapete_btn.querySelector(".item").textContent = item[0].es_name
            tapete_btn.querySelector(".price").textContent = mxnformat(item[0].mx_amount)
            tapete_btn.querySelector(".buy-item").setAttribute("value",item[0].mx_amount)
            tapete_btn.querySelector(".info").setAttribute("value",item[0].es_desc)
        }else{
            tapete_btn.querySelector(".item").textContent = item[0].en_name
            tapete_btn.querySelector(".price").textContent = usdformat(item[0].usd_amount)
            tapete_btn.querySelector(".buy-item").setAttribute("value",item[0].usd_amount)
            tapete_btn.querySelector(".info").setAttribute("value",item[0].en_desc)
        }
    }

    tapete_btn.addEventListener("click",evt=>{
        document.querySelector(".mesa").classList.remove("white")
        document.querySelector(".mesa").classList.add("prebuy")
    })
}