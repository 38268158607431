import React from "react";
import logo from "../img/logo.png";
import compra from "../img/bolsa de compra.svg";
import tapete from "../img/items/aros_100.svg";
import estante from "../img/items/estante_2_100.svg";
import osito from "../img/items/peluche_100.svg";
import bloques from "../img/items/cubos_100.svg";
import empleado from "../img/items/promotora_100.svg";
import oso_nieve from "../img/items/peluche_2_100.svg";
import abaco from "../img/items/abaco_100.svg";
import estante_2 from "../img/items/librero_y_libros_100.svg";
import tapetes from "../img/items/aros_100.svg";
import kit_deportivo from "../img/items/kit_deportivo_100.svg";
import estante_3 from "../img/items/elementos -22.svg";
import pizarron from "../img/items/pizarron_100.svg";
import mesa from "../img/items/mesa_de_dibujo_100.svg";

const url = new URLSearchParams(window.location.search);
function mxnformat(data){
    return Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(data) + " MXN"
}

function usdformat(data){
    return Intl.NumberFormat('es-MX',{style:'currency',currency:'USD'}).format(data)
}

export default class Header extends React.Component{

    recalculateTotal(){
        var total = 0;
        var items = document.querySelectorAll(".car .items .item")

        document.querySelector(".items_counter").textContent = items.length

        items.forEach(element=>{
            var valor =Number(element.querySelector(".total-item").getAttribute("value"));
            var cantidad = element.querySelector(".quantity").textContent
            total += valor * cantidad;
        })

        if (url.get("lang").localeCompare("es") == 0){
            document.querySelector(".cash").textContent = mxnformat(total)
        }else{
            document.querySelector(".cash").textContent = usdformat(total)
        }
        document.querySelector(".cash").setAttribute("value",total)
    }

    selectIcon(item){
        switch (item){
            case 'tapete':
                return tapete;
            case 'izquierda':
                return tapete;
            case 'estante':
                return estante;
            case 'ositos':
                return osito;
            case 'bloques':
                return bloques;
            case 'empleado':
                return empleado;
            case 'oso-nieve':
                return oso_nieve;
            case 'abaco':
                return abaco;
            case 'estante_2':
                return estante_2;
            case 'tapetes':
                return tapetes;
            case 'kit-deportivo':
                return kit_deportivo;
            case 'estante-3':
                return estante_3;
            case 'pizarron':
                return pizarron;
            case 'mesa':
                return mesa;
        }
    }

    createItem(evt){
        document.querySelector(".total_btn-container button").removeAttribute("disabled")
        document.querySelector(".total_btn-container button").classList.remove("disabled")

        if (document.querySelectorAll(".items .item").length == 0){
            document.querySelector(".items").innerHTML = ""
        }
        var item = document.createElement("div")
        var image = document.createElement("div")
        var description = document.createElement("div")
        var name = document.createElement("div")
        var cantidad = document.createElement("div")
        var quantity = document.createElement("div")
        var span = document.createElement("span")
        var price=  document.createElement("div")
        var controls= document.createElement("div")
        var plus = document.createElement("div")
        var minus = document.createElement("div")
        var total_item = document.createElement("div")
        var img = document.createElement("img")

        item.classList.add("item")
        item.classList.add(evt.target.parentElement.querySelector(".item").getAttribute("value"))

        image.classList.add("image")
        img.src = this.selectIcon(evt.target.getAttribute("item"))

        image.appendChild(img)

        description.classList.add("description")

        name.classList.add("name")
        name.textContent = evt.target.parentElement.querySelector(".item").textContent

        cantidad.classList.add("cantidad")

        quantity.classList.add("quantity")
        quantity.textContent=1

        if (url.get("lang").localeCompare("es")==0){
            span.textContent = "Cantidad: "
        }else{
            span.textContent = "Number of items: "
        }
        cantidad.appendChild(span)
        cantidad.appendChild(quantity)

        description.appendChild(name)
        description.appendChild(cantidad)

        price.classList.add("price")

        controls.classList.add("controls")
        plus.classList.add("plus")
        plus.textContent="+"
        plus.setAttribute("value",evt.target.getAttribute("value"))
        plus.addEventListener("click",evt=>{
            var target = evt.target;
            var cantidad = target.parentElement.parentElement.parentElement.querySelector(".quantity")
            var total_item = target.parentElement.parentElement.parentElement.querySelector(".total-item")

            cantidad.textContent = Number(cantidad.textContent)+1
            if (url.get("lang").localeCompare("es")==0){
                total_item.textContent = mxnformat(total_item.getAttribute("value")*cantidad.textContent)
            }else{
                total_item.textContent = usdformat(total_item.getAttribute("value")*cantidad.textContent)
            }

            this.recalculateTotal()

        })

        minus.classList.add("minus")
        minus.textContent="-"
        minus.setAttribute("value",evt.target.getAttribute("value"))

        minus.addEventListener("click",evt=>{
            var target = evt.target;
            var cantidad = target.parentElement.parentElement.parentElement.querySelector(".quantity")
            var total_item = target.parentElement.parentElement.parentElement.querySelector(".total-item")

            cantidad.textContent = Number(cantidad.textContent)-1
            if (url.get("lang").localeCompare("es")==0){
                total_item.textContent = mxnformat(total_item.getAttribute("value")*cantidad.textContent)
            }else{
                total_item.textContent = usdformat(total_item.getAttribute("value")*cantidad.textContent)
            }

            if (cantidad.textContent <=0){
                target.parentElement.parentElement.parentElement.remove()
                if (document.querySelectorAll(".car .item").length == 0){
                    document.querySelector(".total_btn-container button").setAttribute("disabled","disabled")
                    document.querySelector(".total_btn-container button").classList.add("disabled")
                }else{
                    document.querySelector(".total_btn-container button").removeAttribute("disabled")
                    document.querySelector(".total_btn-container button").classList.remove("disabled")
                }
            }

            this.recalculateTotal()

        })

        controls.appendChild(minus)
        controls.appendChild(plus)

        price.appendChild(controls)

        total_item.classList.add("total-item")
        if (url.get("lang").localeCompare("es")==0){
            total_item.textContent = mxnformat(evt.target.getAttribute("value"))
        }else{
            total_item.textContent = usdformat(evt.target.getAttribute("value"))
        }
        total_item.setAttribute("value",evt.target.getAttribute("value"))
        price.appendChild(total_item)

        item.appendChild(image)
        item.appendChild(description)
        item.appendChild(price)

        document.querySelector(".items").appendChild(item)

        this.recalculateTotal()
    }

    showAmount(evt){
        document.querySelector(".monto-libre").classList.remove("hide")
        if (url.get("lang").localeCompare("es")==0) {
            document.querySelector(".monto-libre_container h3").textContent = "Concepto: " + evt.target.parentElement.querySelector(".item").textContent
        }else{
            document.querySelector(".monto-libre_container h3").textContent = "Concept: " + evt.target.parentElement.querySelector(".item").textContent
        }

        document.querySelector(".legend-19").setAttribute("disabled","disabled")
        document.querySelector(".legend-19").classList.add("disabled")
    }

    constructor() {
        super();
        setTimeout(()=>{
            document.querySelectorAll(".buy-item").forEach(element=>{
                element.addEventListener("click",evt=>{
                    var item = document.querySelector(".car-container ."+evt.target.parentElement.querySelector(".item").getAttribute("value"))

                    if (evt.target.getAttribute("value") > 0){
                        document.querySelector("."+evt.target.getAttribute("item")).classList.add("in-car")
                        if (item != null){
                            var cantidad = item.querySelector(".quantity")
                            var total_item = item.querySelector(".total-item")

                            cantidad.textContent = Number(cantidad.textContent)+1
                            total_item.textContent = total_item.getAttribute("value") * cantidad.textContent
                            this.recalculateTotal()

                        }else{
                            this.createItem(evt)
                        }

                        document.querySelector(".car").style.display="flex"
                    }else{
                        document.querySelector(".legend-19").setAttribute("item",evt.target.getAttribute("item"))
                        this.showAmount(evt);
                    }

                    document.querySelectorAll(".dialog").forEach(d=>{
                        d.classList.add('d-none')
                    })
                })
            })

        },1000)
    }

    payment_process(evt){
        document.querySelector(".svg-container").classList.add("d-none")
        document.querySelectorAll(".button-container").forEach(element=>{
            element.classList.add("d-none")
        })
        document.querySelector(".payment_container").classList.remove("d-none")
        if (url.get("lang").localeCompare("en") == 0){
            document.getElementById("currency").value = "USD"
            document.querySelector(".payment_container h3").textContent = usdformat(document.querySelector(".cash").getAttribute("value"))
        }else{
            document.querySelector(".payment_container h3").textContent = mxnformat(document.querySelector(".cash").getAttribute("value"))
        }
        document.querySelector(".payment_container h3").setAttribute("value",document.querySelector(".cash").getAttribute("value"))
        document.getElementById("amount").value=document.querySelector(".cash").getAttribute("value")
        document.querySelector(".car").style.display=""

        document.querySelectorAll(".payment_container button").forEach(element=>{
            element.classList.add("disabled")
            element.setAttribute("disabled","disabled")
        })
    }

    showCar(evt){
        document.querySelector(".car").style.display="flex"
    }

    closeCar(evt){
        document.querySelector(".car").style.display=""
    }
    render() {
        return (
            <header>
                <a href=""><img src={logo} alt=""/></a>
                <div className="car-container">
                    <div className="image">
                        <div className="items_counter">0</div>
                        <img src={compra} alt="" onClick={this.showCar}/>
                    </div>
                    <div className="car">
                        <div className="car-tools" onClick={this.closeCar}>
                            X
                        </div>
                        <div className="items legend-1">
                            No hay items aun
                        </div>
                        <div className="total">
                            <div className="legend-2">Total a pagar: </div>
                            <div className="cash">$0.00</div>
                        </div>
                        <div className="total_btn-container">
                            <button onClick={this.payment_process} className="legend-3">Proceder al pago</button>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}