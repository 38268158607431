import React from "react";

export default class Meta extends React.Component{
    render() {
        return (
            <div className="meta">
                <h1 className="help legend-15">AYÚDANOS A LLEGAR A LA META DE $3,000,000.00 MXN</h1>
                <div className="meta-container">
                    <div className="progress">
                        <div className="progress-bar" style={{width:'0%'}}></div>
                        <div className="percent">$0.00</div>
                    </div>
                </div>
            </div>
        );
    }
}