import React from "react";


export default class EspacioSeguro extends React.Component{
    render(){
        return (
            <div className="svg-container ">
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
                     viewBox="0 0 1055 964.4" >
                    <g>
                        <g id="izquierda" className="izquierda white">
                            <polygon className="st1" points="236,182 0,111 0,651 236,522"/>
                            <polygon className="st1" points="836,179 1060,116 1200,522 856,651"/>
                            <polygon className="st1" points="236,182 836,179 856,651 236,522"/>
                            <polygon className="st1" points="236,182 836,179 576,0 536,0"/>
                            <polygon className="st15" points="586,202 786,202 786,606 606,606"/>
                            <polygon className="st15" points="320,202 480,202 490,406 300,506"/>

                            <path className="st7" d="M784.7,339.9l-51.1,1.3l-2.4-54.6l51.7-3v-8l-52,3.6l-2.5-58.1h-5.4l2.5,58.5l-65.9,4.6L657,222h-5.4l2.7,62.5 l-56.3,3.9v6l56.6-3.3l2.3,52.1l-57.1,1.5v6l57.3-1.7l2.5,58.8h5.4l-2.6-59l66.1-1.9l2.6,60h5.4l-2.6-60.2l50.8-1.5V339.9z M662.2,343.1l-2.3-52.3l66-3.8l2.4,54.4L662.2,343.1z"/>
                            <path className="st8" d="M788.4,201.6l-199.2,5.1l5.3,209.9l199.2-5.1L788.4,201.6z M601.5,410.2l-5.1-199c0,0,27.5,14.4,93.6,11.7 s90.2-17.9,90.2-17.9l6.1,200.4L601.5,410.2z"/>

                            <path className="st7" d="M481.9,352.4l-49.9,0.8l-2.6-50.9l50.7-0.8v-6.3l-51,0.8l-3.5-68.7h-7.1l3.5,68.9l-53.6,0.9l-3.4-69.7h-7.1 l3.4,69.9l-43.6,0.7v6.3l44-0.7l2.5,50.9l-44.7,0.7v6.3l45-0.7l3.3,66h7.1l-3.3-66.1l53.7-0.9l3.1,62h7.1l-3.1-62.2l49.6-0.8 V352.4z M371.1,354.2l-2.5-50.9l53.6-0.9l2.6,50.9L371.1,354.2z"/>
                            <path className="st7" d="M485,209l-176.9,3.6l6.3,223.3l177.8-13.4L485,209z M315.3,221.5c0,0,55.4,8.9,87.5,8 c33.9-0.9,76.8-13.4,76.8-13.4l6.3,202l-164.8,12.4L315.3,221.5z"/>
                        </g>

                        <g className="white tapete">
                            <polygon className="st0" points="982.1,700.8 89.7,710.6 263,552.5 814.2,546.3 	"/>
                            <polygon className="st1" points="982.1,719.6 89.7,730.3 89.7,710.6 982.1,700.8 	"/>
                            <polygon className="st2" points="881.2,609.7 733.8,611.5 707,548 816.9,547.2 	"/>
                            <polygon className="st3" points="733.8,611.5 548,615 544.4,549.8 707.9,548 	"/>
                            <polygon className="st4" points="548,615.9 366.7,618.6 392.6,551.6 544.4,549.8 	"/>
                            <polygon className="st5" points="367.5,615.9 190.7,618.6 263.9,551.6 392.6,551.6 	"/>
                            <polygon className="st3" points="982.6,701.7 769.5,703.5 733.8,612.4 881.2,609.7 	"/>
                            <polygon className="st4" points="770,703.9 551.6,705.3 548,615 733.8,612.4 	"/>
                            <polygon className="st2" points="550.7,705.3 329.1,708 367.5,615.9 548,615 	"/>
                            <polygon className="st3" points="329.6,708 89.3,711.5 192.5,616.8 367.5,615.9 	"/>
                        </g>

                        <g className="navidad-1">
                            <path className="st6" d="M833.4,178.7c0,0-115.2-80.4-147.4-102.7S540.4,1.8,540.4,1.8S427,65.2,405.5,76.8S236.7,182.2,236.7,182.2"/>
                            <path className="st9" d="M1022.8,167.9c0,0-79.1,79.1-185.4,10.3c0,0-11.6,26.8-53.2,8.5c0,0-41.3-21-41.5-71c0,0-19.2,16.5-43.3-2.2 c0,0-17-12.5-13.4-37.5c0,0-48.2,27.7-92.9-5.4c0,0-53.5-37.5-48.2-68.8"/>
                            <path className="st9" d="M540.9,2.2c0,0-2.7,36.6-55.8,68.3c0,0-55.8,28.1-78.2,4c0,0,13,8.5-7.6,45.1c0,0-9.8,25-55.4,22.3 c0,0-13.8-1.3-22.8-12.1c0,0,3.1,0.4-7.6,24.6c0,0-6.3,26.8-31.3,36.6c0,0-23.2,13.4-47.3-7.1c0,0-46.5,50-84.9,57.2 c0,0-71.5,19.7-99.2-27.7"/>
                            <line className="st10" x1="745.4" y1="119.3" x2="320.2" y2="129.1"/>
                            <polyline className="st6" points="1053.2,117 833.4,178.7 849.5,514.6 	"/>
                            <polyline className="st6" points="39.3,122.4 236.7,182.2 245.6,514.6 	"/>
                            <polyline className="st6" points="844.6,520.4 245.2,520.4 33.4,633.8 	"/>
                        </g>

                        <g className="estante white">
		<path d="M855.1,593.5c-0.7-1.5-1.9-2.4-3-3.4c-1.8-1.7-3.7-3.3-5.5-4.9c-1.3-1.1-2.5-2.2-3.9-3.1c-1.4-0.9-2.9-1.6-4.3-2.5
			c-1-0.6-2.1-1.3-3.1-2c-1.1-0.8-1.6-1.8-1.4-3.2c0.2-1,0.4-1.9,0.7-2.9c-0.1-1-0.1-2-0.2-2.9c-0.4-5.5-0.8-10.9-1.1-16.4
			c-0.3-4.7-0.6-9.3-0.9-14c-0.3-4.8-0.6-9.6-0.9-14.3c-0.3-4.7-0.6-9.4-0.9-14.2c-0.3-4.8-0.6-9.6-0.9-14.3
			c-0.3-4.9-0.6-9.8-0.9-14.7c-0.2-3.4-0.4-6.7-0.6-10.1c-0.4-6.1-0.7-12.1-1.1-18.2c0-0.5-0.1-1-0.2-1.5c-0.1-0.7-0.1-1.5,0.4-2.2
			c0.2-0.1,0.5-0.2,0.8-0.3c0.4,0.3,0.8,0.6,1.2,1c0.9,0,1.7,0,2.6,0c3.4,0,6.8,0,10.1,0c0.8,0,1.6,0.1,2.3,0.3
			c0.9-0.1,1.8-0.2,2.7-0.3c0.3-0.3,0.2-0.6,0.2-0.9c0-0.6,0-1.2,0-1.8c0.1-1.7,0.7-3.1,2.3-4c1,0,1.8,0.5,2.5,1.2
			c0.4,0.4,0.7,0.8,1.1,1.3c0.4,0.5,1,0.9,1.6,1.2c1.5,0.6,4.9,1.1,6.8,0.9c0.2-0.6-0.1-1.3,0-2c0.1-0.7,0.2-1.5,0.3-2.2
			c0.1-0.7,0.3-1.3,0.5-2c0.2-0.7,0.5-1.4,0.7-2.1c0.2-0.6,0.5-1.2,0.7-1.8c-0.4-0.6-0.9-0.5-1.4-0.6c-1.9-0.5-3.2-1.7-4.1-3.4
			c-0.8-1.6-0.5-2.8,0.9-3.9c1-0.8,2.1-1.3,3.4-1.4c2.6-0.3,5.3-0.2,7.8,0.6c0.7,0.2,1.4,0.5,2.2,0.5c0-0.2,0.1-0.4,0-0.5
			c-0.2-0.3-0.4-0.6-0.6-0.9c-0.7-1.1-1-2.2-0.9-3.5c0.1-1.7,0.3-3.4,0.6-5.1c0.4-1.8,1.2-3.5,2.6-4.8c1.1-1.1,2-2.3,2.7-3.7
			c0.2-0.5,0.4-1,0.6-1.5c-0.2-0.6-0.5-1.2-0.6-1.8c-0.8-2.6,0-4.3,2.5-5.2c0.6-0.2,1.2-0.4,1.8-0.4c2-0.1,3.5,1.2,3.9,3.2
			c0.1,0.5,0.1,1.1,0.1,1.7c1,0.4,1.9,0.2,3,0.2c0.1-0.6,0.1-1,0.2-1.5c0.1-1.2,0.8-2,2-2.4c2.3-0.9,4.3,0,5.2,2.3
			c0.4,1.2,0.5,2.4,0.3,3.6c-0.1,0.9-0.7,1.7-1.2,2.3c0.1,1.2,0.3,2.4,0.4,3.6c0.1,2.7-0.2,5.3-0.8,7.9c-0.5,2.3-1.6,4.2-3.4,5.8
			c-0.3,0.3-0.6,0.6-1,0.9c0.2,0.3,0.3,0.6,0.5,0.8c0.9,1.1,1.9,2.1,2.8,3.2c0.9,1,1.2,2.2,1,3.6c-0.4,4.9-2.4,9.1-5.6,12.8
			c-1,1.2-2.1,2.2-3.2,3.3c-0.3,0.3-0.6,0.6-0.9,1c0.2,0.3,0.4,0.5,0.6,0.7c1.6,1.2,3.2,2.5,4.8,3.7c0.8,0.6,1.7,1.2,2.6,1.8
			c4.3,2.7,8.9,5,13.6,6.8c4.5,1.7,9,3.5,13.6,5.3c3.7,1.5,7.3,3.2,10.7,5.3c1.5,1,3.1,1.7,4.8,2.2c1.2,0.3,2.3,0.8,3.4,1.3
			c1.4,0.7,2.8,1.3,4.1,2.2c4.9,3.1,10,5.7,15.2,8.4c2.8,1.4,5.5,2.9,8.2,4.6c10.2,6.5,20.9,12.1,31.8,17.5
			c10.6,5.3,21.2,10.6,31.8,15.9c0.8,0.4,1.6,0.7,2.7,1.2c0.1,0.1,0.6,0.4,0.9,0.8c2,2.2,4.3,3.8,6.6,5.5c1.7,1.2,3.4,2.5,4.9,3.9
			c0.5,0.5,1.2,0.9,1.9,1.3c0.1,0.1,0.1,0.4,0.2,0.7c-0.2,0.4-0.5,0.8-0.7,1.3c-0.1,0.4-0.2,0.8-0.3,1.3c-0.4,0.3-0.8,0.5-1.3,0.9
			c0,0.5,0,0.9,0,1.4c0,1.5,0,2.9,0,4.4c0,0.8,0,1.6,0.2,2.4c0.1,0.3,0.1,0.7,0.1,1.1c0,1.4,0,2.8-0.1,4.2c-0.2,6.3-0.5,12.6-0.7,19
			c-0.2,5.4-0.4,10.8-0.6,16.2c-0.3,9.2-0.6,18.4-1,27.6c-0.3,7.7-0.6,15.3-0.9,23c-0.3,8.5-0.6,17.1-0.9,25.6
			c-0.3,7.7-0.6,15.5-0.9,23.2c-0.2,4.4-0.4,8.8-0.6,13.2c-0.1,2.1-0.3,4.2-0.4,6.2c0.1,0.4,0.3,0.8,0.4,1.1c-0.1,0.6-0.4,0.9-0.9,1
			c-0.2,0.1-0.5,0.1-0.7,0.1c-11.8,0.1-23.6,0.2-35.4,0.3c-2.4,0-4.8-0.2-7.2-0.3c-0.3,0-0.6-0.1-0.8-0.1c-4.1-2.5-8-5-12-7.4
			c-0.9-0.6-2-1-3.3-1.6c0.2,0.1,0.1,0.1,0,0c-3.3-3.4-7.1-6-10.8-8.9c-2.2-1.8-4.5-3.4-6.7-5.2c-6-4.9-12-9.7-18-14.7
			c-2.2-1.8-4.3-3.9-6.5-5.7c-1.3-1.1-2.6-2.2-4-3.2c-1.3-0.9-2.3-2-2.9-3.5c-0.4-0.9-1-1.7-1.6-2.4c-2.8-3.1-5.6-6.2-8.7-9
			c-1.5-1.4-3.1-2.6-4.7-3.9c-2.4-1.9-4.9-3.7-7.3-5.7c-3.3-2.6-6.6-5.1-10-7.5c-2-1.4-3.9-3-5.6-4.7c-5.8-5.8-11.6-11.5-17.4-17.3
			c-2.8-2.8-5.4-5.9-8-8.9c-2.9-3.4-5.8-6.8-8.7-10.2C857.3,595.2,856.4,594.2,855.1,593.5z M1037.3,714c0-0.7,0.1-1.2,0.1-1.6
			c0.1-5.3,0.2-10.6,0.4-15.8c0.3-7.6,0.6-15.2,0.9-22.8c0.3-8.4,0.6-16.8,0.9-25.2c0.3-7.5,0.6-15.1,0.9-22.6
			c0.3-7.8,0.6-15.6,0.9-23.4c0.2-6,0.5-12,0.7-18c0.2-4.3,0.3-8.6,0.5-12.9c0.2-6,0.6-12,0.3-18c0-0.8,0-1.6,0-2.4
			c0-1.2,0.1-2.3,0.1-3.5c0-0.4,0.1-0.7-0.2-1c-0.1,0-0.1-0.1-0.2-0.1c-2,0-3.9,0-5.9,0c-1.3,0.4-2.6,0.3-3.8,0.3
			c-4.5,0.2-9,0.4-13.4,0.5c-5.8,0.2-11.5,0.4-17.3,0.6c-0.3,0-0.6,0-0.9,0c-0.3,0.1-0.5,0.2-0.8,0.3c0,1.6,0,3,0,4.5
			c-0.1,6-0.1,12-0.2,18c-0.2,13.8-0.5,27.6-0.8,41.4c-0.3,13.6-0.5,27.1-0.8,40.7c-0.3,16.5-0.6,33-0.9,49.5
			c-0.1,3.6-0.1,7.2-0.2,10.9c0,0.5-0.1,1,0.4,1.4c0.6,0,1.3,0,1.9,0c2-0.1,3.9-0.2,5.9-0.2c7.7-0.1,15.3-0.2,23-0.3
			c2.3,0,4.5,0,6.8-0.1C1036.1,714.1,1036.6,714.1,1037.3,714z M855.7,591.3c1.4,0.7,2.5,1.8,3.5,2.8c0.6,0.7,1.3,1.3,1.9,2
			c2.7,3.1,5.3,6.3,8,9.4c2.7,3.2,5.6,6.4,8.6,9.4c5.4,5.3,10.7,10.6,16.1,16c1.8,1.8,3.7,3.4,5.8,4.8c3.6,2.5,7,5.1,10.4,7.9
			c1.8,1.5,3.6,2.9,5.5,4.3c5.6,4,10.2,9,14.8,14c0.7,0.8,1.4,1.7,1.9,2.7c0.5,1.2,1.4,2.1,2.4,2.9c1.3,1,2.6,2,3.9,3.1
			c2.8,2.5,5.6,5,8.4,7.4c5.2,4.3,10.5,8.5,15.7,12.8c2.3,1.9,4.8,3.7,7.1,5.5c2.6,2,5.1,4,7.7,6c0.6,0.5,1.2,1,1.8,1.6
			c1,1.1,2.2,1.9,3.5,2.7c3.5,2,6.9,4,10.3,6c0.5,0.3,1,0.6,1.6,0.9c0.2,0.1,0.4,0.1,0.7,0.2c0-0.5,0.1-0.9,0.1-1.4
			c0.2-8.3,0.4-16.7,0.5-25c0.3-16.8,0.6-33.5,0.9-50.3c0.2-13.7,0.5-27.4,0.7-41.1c0.2-10.9,0.5-21.8,0.7-32.8
			c0.1-5.3,0.1-10.7,0.7-16c-0.6-0.3-1.1-0.7-1.7-1c-10.7-6-21.3-11.9-32-17.9c-2.5-1.4-4.9-2.8-7.3-4.3c-1-0.6-2-1.2-3.1-1.7
			c-0.7-0.3-1.4-0.7-2.1-1.1c-2.6-1.4-5.2-2.8-7.8-4.2c-0.9-0.5-1.8-1-2.8-1.5c-1.6-0.8-3.2-1.6-4.8-2.4c-2.4-1.3-4.8-2.7-7.2-4
			c-26.3-14.8-52.6-29.5-78.8-44.3c-5.9-3.3-11.8-6.7-17.8-10.1c-0.8-0.5-1.6-0.9-2.2-1.6c-0.5-0.6-0.6-0.6-1.9-1
			c0,0.2-0.1,0.5-0.1,0.7c0.3,5.3,0.7,10.5,1,15.8c0.3,4.6,0.6,9.2,0.9,13.8c0.3,5,0.6,10,0.9,15.1c0.3,4.8,0.6,9.6,0.9,14.3
			c0.2,3.1,0.4,6.1,0.6,9.2c0.3,4.9,0.6,9.8,0.9,14.7c0.3,5.1,0.6,10.2,0.9,15.3c0.4,6.3,0.7,12.5,1.1,18.8c0.1,1.8,0.2,3.5-0.4,5.3
			c0.4,0.4,0.7,0.8,1.1,1c1,0.6,2,1.3,3,1.8c2.8,1.4,5.4,3.2,7.7,5.4c1.7,1.6,3.6,3.2,5.4,4.8C854.2,589.1,855.2,590,855.7,591.3z
			 M1033,533.6c-1.8-0.9-3.2-1.6-4.5-2.3c-10.3-5.2-20.6-10.3-31-15.5c-5.5-2.8-11.1-5.6-16.6-8.5c-5.6-2.9-10.9-6.3-16.2-9.5
			c-2-1.2-4.1-2.4-6.2-3.6c-5.8-3.1-11.7-6-17.3-9.5c-2.1-1.3-4.4-2.5-6.9-3.1c-1.7-0.5-3.3-1.2-4.9-2.2c-3.6-2.3-7.5-4.1-11.6-5.7
			c-4.6-1.8-9.3-3.6-13.9-5.4c-3-1.2-5.9-2.5-8.6-4.1c-3.1-1.7-6.1-3.6-8.9-5.9c-0.9-0.8-2-1.5-3-2.2c-0.3-0.2-0.6-0.3-1-0.5
			c-0.4,0.3-0.8,0.6-1.1,1c-1.3,1.3-2.7,2.5-4.2,3.6c-0.5,0.4-1.1,0.7-1.8,0.9c-2,0.6-4,1.3-6.1,1.5c-0.2,0-0.5,0.1-0.7,0.2
			c-0.2,0.2-0.3,0.3-0.5,0.4c-1.6,1.6-4.1,1.5-5.8-0.6c-1-1.2-1.6-2.6-1.7-4.2c0-0.5-0.1-0.9-0.1-1.4c-0.8-0.1-1.6-0.1-2.3-0.2
			c-0.8-0.1-1.6-0.4-2.3-0.5c-0.9-0.2-1.8-0.3-2.7-0.5c-0.3,0.1-0.5,0.2-0.7,0.2c-1.5,0.3-2.8-0.2-3.6-1.5c-0.3-0.5-0.4-1-0.7-1.5
			c-0.1-0.3-0.3-0.6-0.4-0.9c-0.2-0.1-0.2-0.1-0.3-0.1c-1.5,0.1-3.1,0-4.6-0.3c-0.4-0.1-0.9,0-1.3,0c-1.5,0-2.9,0-4.4,0
			c-1.2,0-2.5,0-3.7,0c-0.1,0-0.3,0.2-0.5,0.3c0.3,0.2,0.6,0.4,0.8,0.6c1.5,0.9,3,1.8,4.5,2.6c10.2,5.7,20.4,11.4,30.6,17.1
			c28.4,16,56.9,31.9,85.3,47.9c1.4,0.8,2.8,1.6,4.2,2.4c3.1,1.7,6.2,3.2,9.3,4.9c8.9,4.9,17.8,9.9,26.7,14.8c2.4,1.3,4.7,2.6,7.1,4
			c0.5,0,1.1-0.1,1.6-0.1c3.5-0.2,7-0.5,10.5-0.6c9.4-0.4,18.8-0.8,28.2-1.2c0.6,0,1.2-0.1,1.8-0.2c0.4-0.1,0.7-0.4,0.6-1
			c-1.3-1.4-2.8-2.6-4.4-3.8C1037.4,537.5,1034.9,535.7,1033,533.6z M869.8,460.7c0.1,0.1,0.3,0.2,0.4,0.3c1.3-0.3,2.5-0.8,3.6-1.4
			c0.9-0.5,1.7-1,2.6-1.5c2-1.2,4.1-2.3,5.3-4.4c0.1-0.1,0.2-0.2,0.2-0.2c2.1-1.2,3.8-2.9,5.4-4.5c2.9-3,4.6-6.6,5.3-10.7
			c0.2-1,0-1.8-0.4-2.7c-0.3-0.6-0.7-1.2-1.1-1.7c-0.7-1-1.5-1.9-2.3-2.9c-0.2-0.2-0.4-0.4-0.7-0.6c-0.4,0.2-0.7,0.4-1,0.6
			c-1.2,0.7-2.4,1.2-3.8,1.1c-0.3,0-0.6,0-0.9,0.1c-1.3,0.3-2.6,0.1-3.8-0.2c-1-0.3-2-0.7-3-1c-0.1,0-0.2,0-0.4,0
			c-0.2,0.2-0.5,0.3-0.7,0.5c-0.7,0.2-1.4,0.3-2.2,0.5c-1,0.2-1.9,0.6-2.6,1.3c-3,2.5-5.2,5.5-6,9.4c-0.3,1.6-0.6,3.3,0,4.9
			c0.1,0.3,0.1,0.6,0.1,0.9c0,0.2-0.1,0.5-0.2,0.6c-0.6,0.4-1.1,0.4-1.7,0.4c-2-0.1-3.9-0.1-5.9-0.5c-0.3-0.1-0.7-0.2-1.1,0.2
			c-0.1,0.5,0.1,1.1,0.1,1.7c0,0.6,0,1.1,0,1.7c0,0.6-0.4,1.2-0.1,1.8c1.1,0.2,2.2,0.4,3.3,0.7c1,0.2,2.1,0.2,3.1-0.1
			c0.9-1.6,2.2-2.8,3.8-3.7c1.8-0.3,3.5-0.2,5.1,0.7c1.1,0.7,2.2,1.3,2.6,2.7c-0.2,0.2-0.3,0.4-0.4,0.5c-1.4,0.1-2-1.2-3.2-1.6
			c0,0.7,0.4,1.2,0.5,1.8c0.1,0.5,0.2,1.1,0.2,1.6c0,0.6,0,1.2,0,1.8C870,459.4,869.9,460,869.8,460.7z M877.9,410.8
			c-0.1,1.7-1,3.1-2.1,4.4c-0.3,0.3-0.6,0.7-0.8,1c-0.7,0.9-1.2,1.9-1.4,2.9c0,0.1,0.1,0.3,0.1,0.5c0.2-0.1,0.4-0.2,0.5-0.3
			c2-1.5,4.2-1.9,6.6-1.8c1.2,0,2.3,0.4,3.2,1.3c2.7,2.6,3.9,5.6,2.9,9.3c0,0.1,0,0.3,0,0.5c1.7-0.9,3.1-2,4-3.2
			c0.5-0.8,0.9-1.6,1.2-2.5c0.7-3,1-6.1,0.8-9.1c-0.2-2.4-1.5-3.9-3.7-4.7c-0.5-0.2-1.1-0.3-1.6-0.3c-2.5-0.2-4.9,0-7.3,0.6
			C879.3,409.6,878.6,410,877.9,410.8z M882.1,430.1c0.9-0.4,1.6-0.9,2.2-1.4c0.8-0.7,1.1-1.6,1-2.6c-0.2-2-0.9-3.8-2.3-5.3
			c-0.8-0.9-1.8-1.3-2.9-1.3c-1.4,0-2.7,0.3-3.9,0.8c-1,0.4-1.5,1.2-1.8,2.2c-0.3,1.2-0.2,2.3,0.1,3.4c0.2,0.8,0.5,1.6,0.8,2.4
			C877.8,429.7,879.5,430.1,882.1,430.1z M868.2,457.9c0-0.9-0.2-1.9-0.5-2.7c-0.4-1-1.3-1.4-2.3-1.5c-1-0.1-1.6,0.3-2.1,1.1
			c-0.9,1.5-0.9,3.1-0.4,4.8c0.2,0.8,0.7,1.6,1.3,2.2c1,1,2.3,0.9,3.1-0.4c0.2-0.4,0.4-0.8,0.5-1.2
			C867.9,459.5,868.1,458.7,868.2,457.9z M859.7,431.5c0.4,1.5,1.3,2.5,2.6,3c0.9,0.3,1.9,0.6,2.8,0.9c0.6-0.6,1.1-1.2,1.7-1.7
			c0.6-0.6,1.2-1.2,1.9-1.7c0.6-0.5,1.3-0.8,2-1.3c-0.1-0.1-0.1-0.2-0.2-0.2c-2.6-0.9-5.3-0.9-8-0.6
			C861.4,430,860.4,430.4,859.7,431.5z M850.2,445.3c-0.1,0.3-0.2,0.5-0.2,0.7c0,1.8,0,3.6,0,5.3c0,0.6,0.2,1.2,0.4,1.8
			c0.2,0.7,0.6,1.2,1.5,1.3c0.8-0.2,1.1-0.7,1.3-1.5C853.6,449.9,852.8,447.3,850.2,445.3z M883.2,406.8c0.1-0.3,0.1-0.5,0.2-0.7
			c0-0.3,0-0.6,0-0.9c-0.2-1.1-1.1-1.9-2.2-1.8c-1.1,0.1-2.1,0.5-2.8,1.4c-0.2,1.7-0.1,2.1,0.7,3.2
			C880.3,407.5,881.8,407.3,883.2,406.8z M893.6,409.4c0.5-0.4,0.5-1.1,0.5-1.7c-0.1-1.3-0.2-2.6-1.5-3.3c-1-0.2-1.8-0.1-2.5,0.6
			c-0.3,0.7-0.5,1.4-0.3,2C891.1,407.8,892.3,408.6,893.6,409.4z"/>
                            <path className="st11" d="M1037.3,714c-0.7,0-1.2,0.1-1.6,0.1c-2.3,0-4.5,0-6.8,0.1c-7.7,0.1-15.3,0.2-23,0.3c-2,0-3.9,0.2-5.9,0.2
			c-0.7,0-1.3,0-1.9,0c-0.5-0.5-0.4-1-0.4-1.4c0.1-3.6,0.1-7.2,0.2-10.9c0.3-16.5,0.6-33,0.9-49.5c0.3-13.6,0.5-27.1,0.8-40.7
			c0.3-13.8,0.5-27.6,0.8-41.4c0.1-6,0.2-12,0.2-18c0-1.5,0-2.9,0-4.5c0.2-0.1,0.5-0.2,0.8-0.3c0.3-0.1,0.6,0,0.9,0
			c5.8-0.2,11.5-0.4,17.3-0.6c4.5-0.2,9-0.4,13.4-0.5c1.3,0,2.6,0.1,3.8-0.3c2,0,3.9,0,5.9,0c0,0,0.1,0.1,0.2,0.1
			c0.3,0.3,0.2,0.7,0.2,1c0,1.2-0.1,2.3-0.1,3.5c0,0.8-0.1,1.6,0,2.4c0.3,6-0.1,12-0.3,18c-0.2,4.3-0.3,8.6-0.5,12.9
			c-0.2,6-0.5,12-0.7,18c-0.3,7.8-0.6,15.6-0.9,23.4c-0.3,7.5-0.6,15.1-0.9,22.6c-0.3,8.4-0.6,16.8-0.9,25.2
			c-0.3,7.6-0.6,15.2-0.9,22.8c-0.2,5.3-0.3,10.6-0.4,15.8C1037.3,712.9,1037.3,713.3,1037.3,714z"/>
                            <path className="st11" d="M855.7,591.3c-0.6-1.3-1.5-2.2-2.5-3.1c-1.8-1.6-3.6-3.1-5.4-4.8c-2.3-2.2-4.9-3.9-7.7-5.4
			c-1-0.5-2-1.2-3-1.8c-0.4-0.3-0.7-0.7-1.1-1c0.6-1.8,0.4-3.6,0.4-5.3c-0.3-6.3-0.7-12.5-1.1-18.8c-0.3-5.1-0.6-10.2-0.9-15.3
			c-0.3-4.9-0.6-9.8-0.9-14.7c-0.2-3.1-0.4-6.1-0.6-9.2c-0.3-4.8-0.6-9.6-0.9-14.3c-0.3-5-0.6-10-0.9-15.1
			c-0.3-4.6-0.6-9.2-0.9-13.8c-0.3-5.3-0.7-10.5-1-15.8c0-0.2,0.1-0.5,0.1-0.7c1.3,0.4,1.4,0.4,1.9,1c0.6,0.7,1.4,1.2,2.2,1.6
			c5.9,3.4,11.8,6.8,17.8,10.1c26.3,14.8,52.6,29.5,78.8,44.3c2.4,1.4,4.8,2.7,7.2,4c1.6,0.8,3.2,1.6,4.8,2.4c0.9,0.5,1.8,1,2.8,1.5
			c2.6,1.4,5.2,2.8,7.8,4.2c0.7,0.4,1.4,0.7,2.1,1.1c1.1,0.5,2.1,1.1,3.1,1.7c2.4,1.5,4.8,2.9,7.3,4.3c10.7,6,21.3,11.9,32,17.9
			c0.6,0.3,1.2,0.7,1.7,1c-0.6,5.4-0.6,10.7-0.7,16c-0.2,10.9-0.5,21.8-0.7,32.8c-0.3,13.7-0.5,27.4-0.7,41.1
			c-0.3,16.8-0.6,33.5-0.9,50.3c-0.2,8.3-0.3,16.7-0.5,25c0,0.4-0.1,0.8-0.1,1.4c-0.3-0.1-0.6-0.1-0.7-0.2c-0.5-0.3-1.1-0.6-1.6-0.9
			c-3.4-2-6.9-4-10.3-6c-1.3-0.7-2.5-1.6-3.5-2.7c-0.5-0.6-1.1-1.1-1.8-1.6c-2.5-2-5.1-4-7.7-6c-2.4-1.8-4.8-3.7-7.1-5.5
			c-5.3-4.2-10.5-8.5-15.7-12.8c-2.9-2.4-5.6-5-8.4-7.4c-1.2-1.1-2.5-2.1-3.9-3.1c-1.1-0.8-1.9-1.7-2.4-2.9c-0.4-1-1.1-1.9-1.9-2.7
			c-4.6-5.1-9.2-10.1-14.8-14c-1.9-1.3-3.7-2.8-5.5-4.3c-3.4-2.7-6.8-5.4-10.4-7.9c-2.1-1.4-4-3.1-5.8-4.8
			c-5.3-5.3-10.7-10.7-16.1-16c-3-3-5.9-6.1-8.6-9.4c-2.6-3.2-5.3-6.3-8-9.4c-0.6-0.7-1.2-1.4-1.9-2
			C858.2,593.1,857.2,592,855.7,591.3z M987.9,699.9c0-0.8-0.1-1.4-0.1-2.1c0.1-3.3,0.3-6.6,0.5-9.9c0.1-3.9,0.2-7.7,0.2-11.6
			c0-3.8-0.1-7.6-0.3-11.4c-0.1-2.8-0.3-5.5-0.3-8.3c-0.1-4-0.2-8.1-0.3-12.1c-0.1-1.7-0.2-3.3-0.3-5c-0.4-6.4-0.4-12.9-0.4-19.3
			c0-6.7,0.3-13.4,0.8-20.1c0.1-1.8,0.2-3.7,0.5-5.5c0.5-4.2,0.8-8.4,0.7-12.7c0-3.1-0.1-6.1,0-9.2c0-1.6,0.1-3.2,0.4-4.8
			c0.6-3.2,0.5-6.4,0.3-9.5c0-0.1,0-0.2,0-0.4c-0.2-0.7-0.5-1.4-0.6-2.1c-0.2-0.7-0.3-1.4-0.4-2.1c-0.5-0.1-0.9-0.2-1.3-0.4
			c-0.4-0.2-0.7-0.4-1.1-0.6c-11.4-6.7-22.7-13.5-34.1-20.2c-28.3-16.8-56.6-33.5-84.9-50.3c-9-5.3-18.1-10.6-27.2-15.9
			c-0.8-0.5-1.7-1-2.6-1.4c-0.4-0.2-0.8-0.3-1.3-0.4c-0.1,0.3-0.1,0.6-0.1,0.8c-0.3,3.6,0,7.1,0.6,10.6c0.4,2.4,0.8,4.7,0.9,7.1
			c0.2,2.9,0.3,5.8,0.4,8.6c0.2,3.5,0.3,7,0.3,10.5c0,3.6-0.1,7.1,0.3,10.7c0.4,3.8,0.5,7.6,0.8,11.4c0.2,2.9,0.5,5.9,0.7,8.8
			c0.1,1.5,0.3,2.9,0.4,4.4c0.2,3.2,0.3,6.5,0.4,9.8c0.1,2.6,0.3,5.3,0.7,7.9c0.4,2.5,0.5,5,0.7,7.5c0.2,2.6,0.4,5.3,0.6,7.9
			c0.1,0.8,0.2,1.6,0.3,2.4c0.7,0.6,1.4,1.3,2.1,1.9c1.9,1.5,3.9,3,5.8,4.5c2.1,1.6,4.2,3.4,6.1,5.3c2.3,2.2,4.6,4.4,6.9,6.6
			c3.2,2.9,6.2,6,9.2,9.1c2.2,2.3,4.5,4.5,6.9,6.6c5.5,4.9,11.1,9.7,16.6,14.6c2.9,2.5,5.8,4.9,8.9,7.1c2.6,1.8,5.3,3.6,7.9,5.4
			c4.9,3.3,9.5,6.9,13.8,11c4.4,4.2,8.7,8.4,13.1,12.7c4.5,4.5,9.2,8.8,14.1,13c1.9,1.6,3.7,3.2,5.6,4.8c1.4,1.2,2.8,2.4,4.3,3.5
			c4.7,3.3,9,7.1,12.9,11.2c2.4,2.5,4.9,5,7.5,7.4c0.4,0.3,0.7,0.7,1.1,1c0.4,0.4,0.8,0.9,1.1,1.5c0,0.1,0.2,0.1,0.4,0.2
			C987.5,700.1,987.7,700,987.9,699.9z"/>
                            <path className="st12" d="M1033,533.6c1.9,2.1,4.4,3.8,6.8,5.7c1.6,1.2,3.1,2.4,4.4,3.8c0.1,0.6-0.2,0.9-0.6,1
			c-0.6,0.1-1.2,0.2-1.8,0.2c-9.4,0.4-18.8,0.7-28.2,1.2c-3.5,0.2-7,0.4-10.5,0.6c-0.5,0-1.1,0.1-1.6,0.1c-2.4-1.3-4.8-2.6-7.1-4
			c-8.9-4.9-17.8-9.9-26.7-14.8c-3.1-1.7-6.2-3.3-9.3-4.9c-1.4-0.8-2.8-1.6-4.2-2.4c-28.4-16-56.9-31.9-85.3-47.9
			c-10.2-5.7-20.4-11.4-30.6-17.1c-1.5-0.8-3-1.7-4.5-2.6c-0.3-0.1-0.5-0.3-0.8-0.6c0.2-0.1,0.4-0.3,0.5-0.3c1.2,0,2.5,0,3.7,0
			c1.5,0,2.9,0,4.4,0c0.4,0,0.9,0,1.3,0c1.5,0.3,3,0.4,4.6,0.3c0.1,0,0.1,0,0.3,0.1c0.1,0.2,0.3,0.6,0.4,0.9c0.2,0.5,0.4,1,0.7,1.5
			c0.8,1.3,2.1,1.8,3.6,1.5c0.2-0.1,0.5-0.1,0.7-0.2c0.9,0.2,1.8,0.3,2.7,0.5c0.8,0.2,1.5,0.4,2.3,0.5c0.7,0.1,1.5,0.1,2.3,0.2
			c0.1,0.5,0.1,1,0.1,1.4c0.1,1.6,0.7,3,1.7,4.2c1.7,2.1,4.2,2.2,5.8,0.6c0.1-0.1,0.3-0.2,0.5-0.4c0.2,0,0.4-0.1,0.7-0.2
			c2.1-0.2,4.1-0.9,6.1-1.5c0.7-0.2,1.2-0.5,1.8-0.9c1.4-1.1,2.9-2.3,4.2-3.6c0.3-0.3,0.7-0.6,1.1-1c0.4,0.2,0.7,0.3,1,0.5
			c1,0.7,2,1.4,3,2.2c2.7,2.3,5.8,4.2,8.9,5.9c2.8,1.6,5.7,2.9,8.6,4.1c4.6,1.8,9.3,3.6,13.9,5.4c4,1.6,7.9,3.4,11.6,5.7
			c1.5,1,3.1,1.7,4.9,2.2c2.5,0.7,4.7,1.8,6.9,3.1c5.6,3.5,11.5,6.4,17.3,9.5c2.1,1.1,4.2,2.3,6.2,3.6c5.4,3.3,10.6,6.6,16.2,9.5
			c5.5,2.9,11,5.7,16.6,8.5c10.3,5.2,20.6,10.4,31,15.5C1029.8,532.1,1031.2,532.7,1033,533.6z"/>
                            <path className="st13" d="M869.8,460.7c0.1-0.6,0.2-1.2,0.2-1.9c0-0.6,0-1.2,0-1.8c0-0.5-0.2-1.1-0.2-1.6c-0.1-0.6-0.5-1.1-0.5-1.8
			c1.2,0.4,1.8,1.7,3.2,1.6c0.1-0.2,0.3-0.3,0.4-0.5c-0.4-1.4-1.5-2.1-2.6-2.7c-1.6-1-3.4-1-5.1-0.7c-1.6,0.9-2.9,2.1-3.8,3.7
			c-1,0.3-2,0.3-3.1,0.1c-1.1-0.3-2.2-0.4-3.3-0.7c-0.3-0.7,0.1-1.2,0.1-1.8c0-0.6,0-1.1,0-1.7c0-0.6-0.2-1.2-0.1-1.7
			c0.4-0.4,0.8-0.2,1.1-0.2c1.9,0.4,3.9,0.4,5.9,0.5c0.5,0,1.1,0,1.7-0.4c0.1-0.2,0.2-0.4,0.2-0.6c0-0.3,0-0.6-0.1-0.9
			c-0.6-1.6-0.3-3.3,0-4.9c0.8-3.9,3-6.9,6-9.4c0.8-0.6,1.7-1,2.6-1.3c0.7-0.2,1.4-0.3,2.2-0.5c0.2-0.2,0.5-0.4,0.7-0.5
			c0.1,0,0.3,0,0.4,0c1,0.3,2,0.7,3,1c1.2,0.3,2.5,0.5,3.8,0.2c0.3-0.1,0.6-0.1,0.9-0.1c1.4,0.1,2.7-0.3,3.8-1.1
			c0.3-0.2,0.6-0.4,1-0.6c0.2,0.2,0.5,0.3,0.7,0.6c0.8,0.9,1.6,1.9,2.3,2.9c0.4,0.5,0.8,1.1,1.1,1.7c0.5,0.8,0.6,1.7,0.4,2.7
			c-0.8,4.1-2.4,7.7-5.3,10.7c-1.6,1.7-3.3,3.3-5.4,4.5c0,0-0.1,0.1-0.2,0.2c-1.3,2.1-3.3,3.2-5.3,4.4c-0.8,0.5-1.7,1-2.6,1.5
			c-1.1,0.6-2.3,1.2-3.6,1.4C870.1,460.9,869.9,460.8,869.8,460.7z"/>
                            <path className="st13" d="M877.9,410.8c0.7-0.8,1.5-1.2,2.3-1.4c2.4-0.6,4.8-0.8,7.3-0.6c0.5,0.1,1.1,0.2,1.6,0.3
			c2.3,0.8,3.6,2.3,3.7,4.7c0.2,3.1-0.1,6.1-0.8,9.1c-0.2,0.9-0.6,1.7-1.2,2.5c-0.9,1.2-2.3,2.4-4,3.2c0-0.2-0.1-0.3,0-0.5
			c1-3.7-0.3-6.7-2.9-9.3c-0.9-0.8-2-1.2-3.2-1.3c-2.4-0.1-4.6,0.3-6.6,1.8c-0.1,0.1-0.3,0.2-0.5,0.3c0-0.2-0.1-0.4-0.1-0.5
			c0.2-1.1,0.7-2.1,1.4-2.9c0.3-0.3,0.5-0.7,0.8-1C876.9,413.9,877.7,412.5,877.9,410.8z M885.6,416.2c-0.3,0.6-0.3,1.1,0.4,1.4
			c0.7,0.1,1-0.3,1.2-1c-0.1-0.2-0.2-0.4-0.3-0.6C886.3,415.7,886,415.8,885.6,416.2z M881.2,415.9c0.1-0.5,0-0.7-0.5-1
			c-0.2,0.1-0.4,0.2-0.7,0.3c0,0.3,0,0.4,0,0.6C880.4,416.3,880.7,416.3,881.2,415.9z"/>
                            <path className="st13" d="M882.1,430.1c-2.7,0.1-4.4-0.4-6.8-1.8c-0.2-0.7-0.6-1.5-0.8-2.4c-0.3-1.1-0.4-2.3-0.1-3.4
			c0.3-1,0.8-1.8,1.8-2.2c1.2-0.6,2.6-0.8,3.9-0.8c1.2,0,2.2,0.4,2.9,1.3c1.4,1.5,2.1,3.3,2.3,5.3c0.1,1.1-0.3,1.9-1,2.6
			C883.7,429.2,883,429.7,882.1,430.1z M876.8,428.7c0.6-0.1,1.2-0.1,1.7-0.2c0.8,0.4,1.6,1,2.4,0.1c0-0.5-0.4-0.6-0.7-0.8
			c-0.3-0.2-0.5-0.4-0.2-0.8c0.4-0.1,0.8-0.1,1.2-0.2c1.5-0.4,1.9-1.8,0.9-3.1c-0.2-0.2-0.3-0.4-0.5-0.5c-0.8-0.6-1.7-1-2.6-1.2
			c-1-0.2-2,0.3-2.5,1.1c-0.4,0.8-0.3,1.8,0.4,2.5c0.3,0.4,0.7,0.7,1.1,1c-0.1,0.3-0.2,0.5-0.2,0.6c-0.4,0.4-1.1,0.2-1.3,0.9
			C876.5,428.2,876.6,428.4,876.8,428.7z"/>
                            <path className="st13" d="M868.2,457.9c-0.1,0.8-0.3,1.6-0.5,2.3c-0.1,0.4-0.3,0.8-0.5,1.2c-0.8,1.2-2,1.4-3.1,0.4
			c-0.6-0.6-1.1-1.3-1.3-2.2c-0.5-1.6-0.5-3.3,0.4-4.8c0.5-0.8,1.2-1.2,2.1-1.1c1,0.1,1.9,0.5,2.3,1.5
			C868,456.1,868.2,457,868.2,457.9z"/>
                            <path className="st13" d="M859.7,431.5c0.7-1.1,1.8-1.6,2.9-1.7c2.7-0.3,5.4-0.3,8,0.6c0.1,0,0.1,0.1,0.2,0.2c-0.7,0.4-1.4,0.8-2,1.3
			c-0.7,0.5-1.3,1.1-1.9,1.7c-0.6,0.5-1.1,1.1-1.7,1.7c-1-0.3-1.9-0.5-2.8-0.9C861,434,860.1,433,859.7,431.5z"/>
                            <path className="st13" d="M850.2,445.3c2.6,2,3.4,4.6,2.9,7.6c-0.1,0.8-0.5,1.4-1.3,1.5c-0.9-0.1-1.3-0.7-1.5-1.3
			c-0.2-0.6-0.4-1.2-0.4-1.8c0-1.8,0-3.6,0-5.3C849.9,445.8,850,445.6,850.2,445.3z"/>
                            <path className="st13" d="M883.2,406.8c-1.4,0.5-2.9,0.6-4.2,1.2c-0.8-1.1-0.9-1.5-0.7-3.2c0.7-1,1.7-1.3,2.8-1.4
			c1.1-0.1,2,0.6,2.2,1.8c0,0.3,0.1,0.6,0,0.9C883.3,406.3,883.2,406.5,883.2,406.8z"/>
                            <path className="st13" d="M893.6,409.4c-1.3-0.8-2.5-1.6-3.9-2.5c-0.1-0.6,0.1-1.3,0.3-2c0.7-0.7,1.6-0.8,2.5-0.6
			c1.3,0.8,1.4,2,1.5,3.3C894.2,408.3,894.2,409,893.6,409.4z"/>
                            <path d="M987.9,699.9c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2-0.1-0.4-0.1-0.4-0.2c-0.2-0.6-0.6-1.1-1.1-1.5c-0.4-0.3-0.7-0.7-1.1-1
			c-2.6-2.4-5.1-4.8-7.5-7.4c-3.9-4.1-8.2-7.9-12.9-11.2c-1.5-1.1-2.9-2.3-4.3-3.5c-1.9-1.6-3.7-3.2-5.6-4.8
			c-4.9-4.1-9.5-8.5-14.1-13c-4.3-4.3-8.7-8.5-13.1-12.7c-4.3-4.1-8.9-7.7-13.8-11c-2.7-1.8-5.3-3.5-7.9-5.4
			c-3.1-2.2-6.1-4.6-8.9-7.1c-5.5-4.9-11-9.8-16.6-14.6c-2.4-2.1-4.7-4.3-6.9-6.6c-3-3.1-6-6.2-9.2-9.1c-2.3-2.2-4.6-4.4-6.9-6.6
			c-1.9-1.9-4-3.6-6.1-5.3c-1.9-1.5-3.9-3-5.8-4.5c-0.7-0.6-1.4-1.2-2.1-1.9c-0.1-0.8-0.3-1.6-0.3-2.4c-0.2-2.6-0.4-5.3-0.6-7.9
			c-0.2-2.5-0.3-5-0.7-7.5c-0.4-2.6-0.6-5.2-0.7-7.9c-0.1-3.3-0.2-6.5-0.4-9.8c-0.1-1.5-0.3-2.9-0.4-4.4c-0.2-2.9-0.5-5.9-0.7-8.8
			c-0.3-3.8-0.4-7.6-0.8-11.4c-0.3-3.6-0.3-7.1-0.3-10.7c0-3.5-0.1-7-0.3-10.5c-0.2-2.9-0.3-5.8-0.4-8.6c-0.1-2.4-0.5-4.8-0.9-7.1
			c-0.6-3.5-1-7.1-0.6-10.6c0-0.2,0.1-0.5,0.1-0.8c0.5,0.1,0.9,0.2,1.3,0.4c0.9,0.4,1.7,0.9,2.6,1.4c9.1,5.3,18.1,10.6,27.2,15.9
			c28.3,16.7,56.6,33.5,84.9,50.3c11.4,6.7,22.7,13.5,34.1,20.2c0.4,0.2,0.7,0.5,1.1,0.6c0.4,0.2,0.8,0.2,1.3,0.4
			c0.1,0.7,0.2,1.4,0.4,2.1c0.2,0.7,0.4,1.4,0.6,2.1c0,0.1,0,0.2,0,0.4c0.1,3.2,0.3,6.4-0.3,9.5c-0.3,1.6-0.4,3.2-0.4,4.8
			c-0.1,3.1,0,6.1,0,9.2c0,4.2-0.2,8.5-0.7,12.7c-0.2,1.8-0.3,3.7-0.5,5.5c-0.5,6.7-0.8,13.4-0.8,20.1c0,6.4,0,12.9,0.4,19.3
			c0.1,1.7,0.3,3.3,0.3,5c0.1,4,0.2,8.1,0.3,12.1c0.1,2.8,0.3,5.5,0.3,8.3c0.1,3.8,0.2,7.6,0.3,11.4c0,3.9-0.1,7.7-0.2,11.6
			c-0.1,3.3-0.3,6.6-0.5,9.9C987.8,698.4,987.9,699.1,987.9,699.9z M910.5,630.3c1.3,0.9,2.5,1.7,3.7,2.5c4.9,3.3,9.5,6.9,13.8,11
			c4.3,4.2,8.7,8.4,12.9,12.6c4.6,4.6,9.3,9,14.1,13.2c2,1.7,4,3.5,6,5.2c1.2,1,2.4,2.1,3.7,3c5.1,3.5,9.6,7.7,13.8,12.2
			c2.4,2.6,5,5,7.6,7.5c0.1,0.1,0.3,0.1,0.4,0.2c0.4-0.4,0.3-0.8,0.3-1.2c0.1-5.8,0.3-11.5,0.4-17.3c0-4.4,0-8.8-0.2-13.3
			c-0.2-3.9-0.3-7.8-0.4-11.8c-0.1-3.7-0.2-7.4-0.4-11c-0.3-5.2-0.6-10.4-0.6-15.6c0-0.7,0-1.4,0.1-2c0.3-2.3,0.3-4.5,0.2-6.8
			c-0.1-3.1,0-6.3-0.1-9.4c0-0.2,0-0.5,0-0.7c0.2-2.8,0.3-5.5,0.5-8.3c0.2-2.3,0.4-4.5,0.6-6.8c0.3-3.4,0.6-6.7,0.6-10.1
			c0-3.4,0-6.9,0-10.3c0-1.5,0-3.1,0.3-4.6c0.6-2.9,0.6-5.9,0.6-8.8c0-0.8-0.2-1.6-0.3-2.4c-0.2-0.7-0.4-1.4-0.7-2.2
			c-25.7-15.1-51.3-30.4-77.1-45.5c-0.1,0-0.2,0-0.3,0c-0.1,0.1-0.2,0.2-0.2,0.3C910.1,549.9,910.3,590,910.5,630.3z M836.9,465.8
			c-0.5,0.5-0.4,0.8-0.4,1.2c0,3.4,0.5,6.7,1.1,10c0.4,2,0.6,4,0.7,6c0.1,1.9,0.2,3.8,0.3,5.7c0.2,3.1,0.3,6.2,0.5,9.4
			c0.1,1.3,0.2,2.6,0.2,3.9c0,4.9,0.2,9.7,0.5,14.5c0.2,3.1,0.4,6.1,0.6,9.2c0.2,2.5,0.4,5,0.6,7.5c0.1,1.6,0.3,3.2,0.4,4.8
			c0.1,2.5,0.2,4.9,0.3,7.4c0.1,3.4,0.2,6.9,0.8,10.3c0.2,1.4,0.4,2.8,0.4,4.2c0.1,3.8,0.5,7.6,0.8,11.4c0,0.5,0.2,1,0.3,1.4
			c0.4,0.3,0.7,0.6,1,0.8c2.1,1.6,4.1,3.1,6.2,4.6c2.5,1.8,4.8,3.8,7,6c1.6,1.5,3.2,3.1,4.8,4.6c3.9,3.6,7.8,7.3,11.4,11.2
			c2,2.1,4.2,4.1,6.4,6.1c5.7,5,11.3,10,17,15c2.4,2.2,5,4.2,7.6,6.1c0.3,0.2,0.6,0.5,1,0.4c0.1-0.1,0.2-0.2,0.2-0.3
			c-0.1-40-0.3-79.9-0.5-119.9c-0.2-0.2-0.4-0.3-0.6-0.5C882.9,493.1,859.8,479.6,836.9,465.8z M909.1,629c0-0.4,0.1-0.9,0.1-1.3
			c0-2.1,0-4.2-0.1-6.3c-0.1-20.8-0.2-41.6-0.4-62.4c-0.1-14.9-0.2-29.8-0.3-44.7c0-1.7,0.1-3.3,0.1-5c0-0.3,0-0.6,0-1
			c-0.3-0.1-0.5-0.1-0.7-0.1c-0.3,0.5-0.2,0.9-0.2,1.3c0,2.2,0.1,4.4,0.1,6.6c0.1,36.6,0.2,73.3,0.3,109.9c0,0.6,0,1.2,0,1.8
			c0,0.3,0.1,0.6,0.2,0.9C908.7,628.9,908.8,628.9,909.1,629z"/>
                            <path d="M885.6,416.2c0.4-0.4,0.8-0.5,1.3-0.2c0.1,0.2,0.2,0.4,0.3,0.6c-0.1,0.7-0.5,1.1-1.2,1
			C885.3,417.2,885.3,416.7,885.6,416.2z"/>
                            <path d="M881.2,415.9c-0.5,0.4-0.9,0.3-1.2-0.1c0-0.2,0-0.3,0-0.6c0.2-0.1,0.5-0.2,0.7-0.3C881.3,415.2,881.4,415.5,881.2,415.9z"
                            />
                            <path d="M876.8,428.7c-0.2-0.3-0.3-0.5-0.4-0.6c0.2-0.7,0.8-0.5,1.3-0.9c0-0.1,0.1-0.3,0.2-0.6c-0.4-0.4-0.8-0.7-1.1-1
			c-0.7-0.8-0.8-1.8-0.4-2.5c0.5-0.8,1.6-1.4,2.5-1.1c1,0.2,1.9,0.6,2.6,1.2c0.2,0.2,0.4,0.3,0.5,0.5c0.9,1.2,0.5,2.6-0.9,3.1
			c-0.4,0.1-0.8,0.2-1.2,0.2c-0.2,0.4,0,0.6,0.2,0.8c0.3,0.2,0.7,0.3,0.7,0.8c-0.8,0.9-1.6,0.3-2.4-0.1
			C878,428.6,877.4,428.6,876.8,428.7z M880.8,424.8c-0.7-0.8-1.6-1.3-2.7-1.4c-0.1,0-0.2,0.1-0.4,0.2c0,0.2,0.1,0.5,0.1,0.7
			c0.6,0.6,1.3,0.8,2,0.9C880.1,425.3,880.4,425.3,880.8,424.8z"/>
                            <path className="st14" d="M910.5,630.3c-0.2-40.2-0.4-80.4-0.7-120.5c0-0.1,0.1-0.2,0.2-0.3c0.1,0,0.3-0.1,0.3,0
			c25.8,15.1,51.5,30.4,77.1,45.5c0.3,0.8,0.6,1.5,0.7,2.2c0.2,0.8,0.3,1.6,0.3,2.4c0,2.9,0.1,5.9-0.6,8.8c-0.3,1.5-0.3,3.1-0.3,4.6
			c0,3.4,0,6.9,0,10.3c0,3.4-0.3,6.7-0.6,10.1c-0.2,2.3-0.4,4.5-0.6,6.8c-0.2,2.8-0.3,5.5-0.5,8.3c0,0.2,0,0.5,0,0.7
			c0,3.1,0,6.3,0.1,9.4c0,2.3,0.1,4.5-0.2,6.8c-0.1,0.7-0.1,1.3-0.1,2c0,5.2,0.3,10.4,0.6,15.6c0.2,3.7,0.3,7.4,0.4,11
			c0.1,3.9,0.2,7.8,0.4,11.8c0.2,4.4,0.2,8.8,0.2,13.3c0,5.8-0.2,11.5-0.4,17.3c0,0.4,0.1,0.9-0.3,1.2c-0.1-0.1-0.3-0.1-0.4-0.2
			c-2.6-2.4-5.2-4.9-7.6-7.5c-4.2-4.5-8.7-8.6-13.8-12.2c-1.3-0.9-2.5-2-3.7-3c-2-1.7-4-3.5-6-5.2c-4.9-4.2-9.6-8.6-14.1-13.2
			c-4.3-4.3-8.6-8.4-12.9-12.6c-4.3-4.1-8.9-7.7-13.8-11C913,631.9,911.9,631.2,910.5,630.3z M925.9,570.7c-0.1-0.4-0.1-0.9-0.2-1.4
			c-0.4-1.8-1.7-2.8-3.4-3.1c-0.7-0.1-1.4,0.1-1.9,0.5c-0.4,0.3-0.7,0.7-0.9,1.1c-1.1,2.3-1.1,4.6,0.2,6.8c1.3,2.2,3.6,2.2,5.1,0.1
			C925.5,573.6,925.8,572.2,925.9,570.7z"/>
                            <path className="st14" d="M836.9,465.8c22.9,13.8,45.9,27.3,68.9,40.9c0.2,0.1,0.4,0.3,0.6,0.5c0.2,40,0.3,79.9,0.5,119.9
			c0,0.1-0.1,0.2-0.2,0.3c-0.4,0.1-0.7-0.2-1-0.4c-2.6-1.9-5.2-3.9-7.6-6.1c-5.7-5-11.3-10-17-15c-2.2-1.9-4.4-3.9-6.4-6.1
			c-3.7-3.9-7.5-7.6-11.4-11.2c-1.6-1.5-3.2-3-4.8-4.6c-2.2-2.1-4.5-4.1-7-6c-2.1-1.5-4.1-3.1-6.2-4.6c-0.3-0.3-0.7-0.5-1-0.8
			c-0.1-0.5-0.2-0.9-0.3-1.4c-0.3-3.8-0.7-7.6-0.8-11.4c-0.1-1.4-0.2-2.8-0.4-4.2c-0.6-3.4-0.7-6.8-0.8-10.3
			c-0.1-2.5-0.1-4.9-0.3-7.4c-0.1-1.6-0.3-3.2-0.4-4.8c-0.2-2.5-0.4-5-0.6-7.5c-0.2-3.1-0.4-6.1-0.6-9.2c-0.3-4.8-0.5-9.7-0.5-14.5
			c0-1.3-0.1-2.6-0.2-3.9c-0.2-3.1-0.3-6.2-0.5-9.4c-0.1-1.9-0.2-3.8-0.3-5.7c-0.1-2-0.3-4-0.7-6c-0.6-3.3-1.1-6.7-1.1-10
			C836.6,466.6,836.5,466.3,836.9,465.8z M891.7,549.8c-0.2,0.2-0.4,0.4-0.6,0.6c-2.2,1.9-2.4,4.4-1.7,7c0.3,1.1,1,1.9,2.2,2.3
			c1.8,0.6,3.6-0.3,4.3-2.1c0.6-1.9,0.5-3.7-0.3-5.5c-0.4-0.9-1.1-1.6-2-2.1C893,549.7,892.5,549.5,891.7,549.8z"/>
                            <path className="st15" d="M909.1,629c-0.3-0.1-0.4-0.1-0.7-0.1c-0.1-0.3-0.2-0.6-0.2-0.9c0-0.6,0-1.2,0-1.8
			c-0.1-36.6-0.2-73.3-0.3-109.9c0-2.2-0.1-4.4-0.1-6.6c0-0.4-0.1-0.9,0.2-1.3c0.2,0,0.4,0.1,0.7,0.1c0,0.4,0,0.7,0,1
			c0,1.7-0.1,3.3-0.1,5c0.1,14.9,0.2,29.8,0.3,44.7c0.1,20.8,0.2,41.6,0.4,62.4c0,2.1,0,4.2,0.1,6.3
			C909.2,628.1,909.2,628.5,909.1,629z"/>
                            <path className="st13" d="M880.8,424.8c-0.3,0.5-0.6,0.5-0.9,0.4c-0.7-0.1-1.4-0.3-2-0.9c0-0.2-0.1-0.5-0.1-0.7
			c0.2-0.1,0.3-0.2,0.4-0.2C879.1,423.5,880,424,880.8,424.8z"/>
                            <path d="M925.9,570.7c-0.1,1.5-0.4,2.9-1.2,4.1c-1.5,2.1-3.8,2.1-5.1-0.1c-1.3-2.2-1.3-4.5-0.2-6.8c0.2-0.4,0.6-0.8,0.9-1.1
			c0.5-0.5,1.2-0.7,1.9-0.5c1.7,0.4,3,1.3,3.4,3.1C925.8,569.8,925.9,570.3,925.9,570.7z M922.2,574.8c0.7-0.1,1.1-0.6,1.4-1.1
			c0.5-0.8,0.7-1.7,0.8-2.6c0.1-2-0.6-2.9-2.8-3.4c-1.5,0.9-1.6,2.5-1.5,4C920.3,572.9,920.7,574.3,922.2,574.8z"/>
                            <path d="M891.7,549.8c0.8-0.3,1.3-0.1,1.8,0.2c0.9,0.5,1.6,1.2,2,2.1c0.8,1.8,0.9,3.7,0.3,5.5c-0.6,1.8-2.4,2.7-4.3,2.1
			c-1.1-0.4-1.9-1.2-2.2-2.3c-0.6-2.6-0.5-5.1,1.7-7C891.3,550.2,891.5,550,891.7,549.8z M892.4,551.2c-0.2,0.1-0.3,0.1-0.4,0.2
			c-1.5,1.3-2,4.1-1.2,5.9c0.4,0.8,1,1.2,1.9,1.1c0.8-0.1,1.3-0.5,1.6-1.3c0.5-1.5,0.5-3-0.2-4.4
			C893.8,551.9,893.3,551.4,892.4,551.2z"/>
                            <path className="st16" d="M922.2,574.8c-1.5-0.5-1.9-1.8-2-3.2c-0.2-1.5,0-3.1,1.5-4c2.2,0.5,2.9,1.4,2.8,3.4
			c-0.1,0.9-0.3,1.8-0.8,2.6C923.3,574.2,922.9,574.6,922.2,574.8z"/>
                            <path className="st16" d="M892.4,551.2c0.8,0.1,1.3,0.7,1.7,1.4c0.7,1.4,0.7,2.9,0.2,4.4c-0.3,0.8-0.8,1.2-1.6,1.3
			c-0.9,0.1-1.6-0.3-1.9-1.1c-0.8-1.8-0.3-4.6,1.2-5.9C892.1,551.3,892.3,551.3,892.4,551.2z"/>
	</g>
                        <g className="ositos white">
		<path d="M963.4,495.7c-0.1,0.1-0.2,0.1-0.3,0.2c-1.1,1.1-2.3,2.1-3.6,3.1c-0.5,0.3-0.9,0.7-1.4,1.1c-0.9,0.7-1.9,1.2-3,1.6
			c-0.2,0-0.3,0.1-0.5,0.2c-0.3,0.1-0.5,0.2-0.7,0.4c-0.7,0.6-1.5,1-2.3,1.3c-0.5,0.1-0.9,0.2-1.4,0.2c-2.1,0.1-4-0.5-5.5-2
			c-1.2-1.2-1.9-2.7-2.3-4.4c-0.2-0.8-0.1-1.7,0.2-2.5c0.3-0.8,0.3-0.7-0.4-1c-0.4-0.2-0.8-0.4-1.1-0.7c-0.7-0.5-1.4-1.1-2.1-1.6
			c-0.1-0.1-0.2-0.1-0.3-0.2c-0.2-0.2-0.5-0.2-0.8-0.1c-0.4,0.2-0.8,0.3-1.2,0.4c-1.1,0.3-2.2,0.3-3.3,0c0,0-0.1,0-0.1,0
			c-0.4-0.1-0.7-0.1-1,0.2c-0.5,0.3-1,0.5-1.5,0.6c-0.5,0.1-1.1,0.1-1.6,0c-1.8-0.3-3.3-1.1-4.5-2.5c-0.7-0.8-1.4-1.7-1.7-2.8
			c-0.3-1-0.5-2-0.3-3c0.3-1.5,0.9-2.8,2-3.8c0.5-0.5,1.1-0.9,1.8-1.2c0.3-0.2,0.7-0.3,1-0.5c1.4-0.9,2.8-1,4.3-0.4
			c0.4,0.2,0.8,0.4,1.2,0.6c0.1,0.1,0.2,0.2,0.3,0.2c0,0,0.2,0,0.2-0.1c0-0.1,0.1-0.2,0.1-0.3c0.1-0.7,0.2-1.5,0.4-2.2
			c0,0,0-0.1,0-0.2c-0.1,0-0.2-0.1-0.3,0c-0.3,0.2-0.6,0.1-0.9,0.1c-0.8-0.1-1.6-0.4-2.3-0.7c-0.9-0.4-1.4-1.1-1.6-2
			c-0.4-1.4-0.1-2.8,0.4-4.1c0.7-1.6,1.7-2.9,3.1-3.9c1.1-0.7,2.2-1.5,3.3-2.2c0.4-0.2,0.7-0.5,1.1-0.7c0.1-0.1,0.2-0.1,0.2-0.2
			c0,0,0-0.1,0-0.1c0,0,0-0.1-0.1-0.1c-0.1,0-0.3,0-0.4,0c-0.3,0-0.6,0.1-0.9,0.1c-1.2,0.1-2.3-0.2-3.3-0.8
			c-0.3-0.2-0.5-0.4-0.8-0.5c-0.1-0.1-0.3-0.1-0.4-0.2c-0.2,0.1-0.2,0.3-0.2,0.4c-0.1,0.7-0.3,1.3-0.4,2c-0.2,0.9-0.4,1.7-0.8,2.6
			c-0.4,0.9-0.9,1.8-1.6,2.5c-0.8,1-1.7,1.8-2.7,2.6c-0.8,0.6-1.7,1.1-2.8,1.3c-0.7,0.1-1.3,0.2-2,0.3c-1.8,0.2-3.6,0.6-5.3,1.2
			c-0.5,0.2-0.8,0.5-0.9,0.9c-0.3,0.7-0.6,1.4-1.1,2.1c-1.3,1.8-3,2.7-5.2,2.9c-1.1,0.1-2.2-0.2-3.1-0.8c-1.6-1.2-2.9-2.7-3.7-4.5
			c-0.3-0.7-0.6-1.4-1-2.1c-0.5-1.2-0.9-2.5-1.2-3.9c-0.3-1.4-0.4-2.7-0.5-4.1c0-0.1,0-0.2,0-0.3c0-0.1-0.1-0.3-0.2-0.3
			c-0.9-0.4-1.5-1.1-2.2-1.7c-0.1-0.1-0.2-0.3-0.5-0.4c0,0-0.1,0.1-0.2,0.1c-1.8,1.5-3.9,2.4-6.2,2.4c-0.5,0-1,0-1.5-0.1
			c-0.6,0-1.2,0-1.8,0c-1.2,0.2-2.3,0.1-3.5-0.4c-1.5-0.6-2.7-1.4-3.7-2.6c-0.9-1.1-1.4-2.3-1.6-3.6c-0.2-1.1-0.3-2.2-0.3-3.3
			c0.1-1.9,0.4-3.8,1.2-5.5c0.1-0.3,0.3-0.5,0.4-0.8c0.3-0.6,0.7-1.2,1-1.8c0.2-0.4,0.5-0.7,0.7-1c0.2-0.2,0.3-0.5,0.4-0.8
			c0.1-0.4,0.3-0.8,0.5-1.2c1-2,2.6-3.2,4.7-3.8c0.8-0.2,1.6-0.3,2.4-0.3c0.7,0,1.4,0.2,2.1,0.5c1.6,0.8,2.5,2.1,2.8,3.8
			c0.2,0.8,0.4,1.5,0.7,2.3c0,0.1,0.1,0.2,0.2,0.3c0.1,0,0.1,0,0.1,0c0,0,0.1,0,0.1-0.1c0.6-0.7,1.2-1.4,1.7-2c0,0,0-0.1,0-0.2
			c-0.1-0.1-0.2-0.2-0.3-0.2c-1.5-0.7-2.8-2.1-3.2-3.7c-0.4-1.4-0.1-2.7,0.9-3.8c0.4-0.5,0.8-0.8,1.3-1.2c0.9-0.6,1.8-1.1,2.9-1.4
			c1.1-0.4,2.2-0.8,3.3-1.2c1.4-0.5,2.6-1.2,3.8-2c0,0,0-0.1,0.1-0.1c0.1-0.2-0.1-0.3-0.2-0.4c-0.5-0.5-1.1-1-1.5-1.6
			c-0.9-1.2-1.4-2.5-1.9-3.9c-0.4-1.1-0.6-2.2-0.7-3.4c-0.3-2.6,0.4-4.9,2.2-6.9c0.6-0.6,1.2-1.2,1.9-1.8c0.5-0.5,1-1,1.5-1.5
			c1.2-1.2,2-2.6,2.5-4.2c0.2-0.5,0.3-1.1,0.5-1.6c0-0.1,0-0.1,0.1-0.2c0-0.2,0-0.3-0.3-0.4c-0.2,0-0.3-0.1-0.5-0.1
			c-1.3-0.3-2.2-1.2-2.6-2.5c-0.3-0.7-0.3-1.4-0.3-2.2c0.1-1.5,0.3-3,0.8-4.5c0.5-1.7,1.3-3.2,2.3-4.6c0.5-0.7,1-1.2,1.7-1.6
			c1.1-0.6,2.2-0.8,3.5-0.6c0.7,0.1,1.3,0.5,1.9,0.9c0.8,0.7,1.3,1.5,1.6,2.5c0.4,1.1,0.5,2.2,0.4,3.4c0,0.2,0,0.4-0.1,0.6
			c0,0.3,0.1,0.4,0.4,0.4c0.2,0,0.4,0,0.6-0.1c0.4-0.1,0.8-0.1,1.2-0.1c1.7,0,3.3,0.2,5,0.6c0.1,0,0.1,0,0.3,0
			c0-0.1,0.1-0.2,0.1-0.3c0-0.2,0-0.4,0-0.6c0-0.8,0.2-1.6,0.6-2.3c0.8-1.2,1.7-2.2,3-2.9c0.8-0.4,1.6-0.7,2.5-0.8
			c0.5-0.1,0.9-0.1,1.4-0.2c1.4-0.1,2.7,0.2,3.8,1c1.2,0.9,2,2.1,2.6,3.5c0.7,1.8,1,3.7,0.8,5.7c-0.2,2.1-1,4-2.5,5.6
			c-0.8,0.9-1.8,1.6-3.1,1.8c-0.1,0-0.1,0-0.2,0.1c-0.1,0.1-0.2,0.2-0.2,0.3c0.1,0.3,0.1,0.5,0.2,0.8c0.4,1.5,0.7,3.1,0.9,4.6
			c0.1,0.8,0.2,1.5,0.4,2.3c0.3,2.1,0.4,4.1,0.1,6.2c-0.3,1.8-0.8,3.5-1.7,5.1c-0.4,0.7-0.3,0.6,0,1.2c0.5,1.1,1,2.2,1.5,3.3
			c0.6,1.3,1.3,2.7,1.9,4c0.6,1.4,1.3,2.8,1.9,4.2c0.1,0.2,0.1,0.3,0.2,0.5c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.2-0.2,0.3-0.3
			c0.2-0.2,0.2-0.3,0.1-0.6c-0.7-1.7-0.6-3.5-0.1-5.2c0.5-1.8,1.6-3.1,3.3-3.9c0.4-0.2,0.9-0.3,1.4-0.4c1.4-0.2,2.6,0.2,3.7,1
			c1.2,1,2,2.1,2.5,3.5c0.2,0.5,0.2,1,0.4,1.6c0.1,0.5,0.1,0.6,0.7,0.6c1.9,0.2,3.7,0.7,5.5,1.4c0.4,0.2,0.8,0.3,1.2,0.5
			c0.6,0.2,1.1,0.4,1.6,0.7c0.2,0.1,0.4,0.3,0.7,0.2c0.2,0,0.4-0.1,0.6-0.2c0.3-0.3,0.7-0.5,1-0.8c0.8-0.5,1.6-0.8,2.5-1
			c0.2-0.1,0.5-0.1,0.7-0.2c1.1-0.3,2.1-0.4,3.2-0.4c1.4,0,2.8,0.3,4,1.1c1.5,1.1,2.3,2.5,2.3,4.4c0,0.8-0.2,1.6-0.6,2.3
			c-0.2,0.5-0.5,0.9-0.8,1.4c-0.5,0.8-1.1,1.5-1.6,2.3c-0.3,0.4-0.3,0.7-0.2,1.1c0.4,0.9,0.6,1.9,0.8,2.9c0,0.1,0,0.2,0,0.3
			c0.1,1.4,0.1,2.7-0.2,4.1c-0.2,0.7-0.4,1.3-0.5,2c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0.1,0.4c0,0,0.1,0.1,0.1,0.1
			c1.3,0.9,2,2.2,2.5,3.6c0.2,0.6,0.2,1.2,0.1,1.8c0,0.2-0.1,0.4,0.1,0.6c0.1,0,0.2-0.1,0.4-0.1c0.4-0.2,0.9-0.3,1.4-0.4
			c1.7-0.4,3.9,0.3,5.1,1.8c1.2,1.5,1.3,3.3,0.4,5c-0.4,0.7-1,1.3-1.6,1.8c-0.1,0.1-0.3,0.2-0.4,0.4c0.1,0.1,0.2,0.1,0.3,0.2
			c0.3,0.1,0.6,0.2,0.8,0.4c1,0.5,1.6,1.3,2,2.3c0.3,0.7,0.3,1.5,0,2.2c-0.2,0.4-0.4,0.8-0.7,1.1c-0.1,0.1-0.2,0.2-0.2,0.4
			c0.1,0,0.1,0.1,0.2,0.1c0.9,0.3,1.5,1,1.9,1.9c0.1,0.1,0.1,0.2,0.2,0.4c0.1,0.3,0.2,0.3,0.5,0.1c0.1-0.1,0.2-0.2,0.3-0.3
			c1-0.9,2.1-1.4,3.4-1.4c1.2,0,2.2,0.5,3,1.5c0.4,0.4,0.6,0.9,0.8,1.4c0.1,0.2,0.1,0.4,0.3,0.5c0.1,0,0.2-0.1,0.3-0.1
			c0.3-0.2,0.5-0.5,0.8-0.7c1.1-0.8,2.3-0.9,3.5-0.5c0.4,0.2,0.9,0.4,1.3,0.6c1,0.6,1.6,1.5,1.8,2.7c0.2,0.8,0.2,1.6,0.1,2.4
			c0,0.5,0.1,0.6,0.5,0.4c2-1,4.4,0.1,5.3,1.9c0.3,0.6,0.4,1.3,0.5,1.9c0,0.2,0,0.4,0.1,0.6c0.1,0,0.2,0,0.3-0.1
			c0.4-0.3,0.8-0.5,1.2-0.7c1.7-0.7,3.6-0.3,4.8,1.3c0.6,0.8,1,1.7,1.1,2.7c0.1,0.6,0.1,1.1,0,1.7c0,0.2-0.1,0.4,0,0.6
			c0.1,0,0.2,0.1,0.3,0.1c0.2,0,0.3,0,0.5,0c1.3,0,2.3,0.5,3.1,1.5c0.7,0.9,1,1.9,1.1,3c0,1.1-0.1,2.1-0.6,3.1
			c-0.1,0.1-0.1,0.2-0.1,0.4c0.2,0,0.3,0.1,0.5,0.1c0.6,0.1,1.2,0.2,1.7,0.5c1.5,0.8,2.3,2,2.3,3.6c0,0.4-0.1,0.8-0.1,1.2
			c0,0.3,0,0.4,0.4,0.4c0.2,0,0.5,0,0.7,0c1.3,0.1,2.2,0.8,2.9,1.9c0.4,0.8,0.6,1.6,0.7,2.5c0,0.7,0,1.3-0.1,2
			c-0.1,0.2-0.1,0.5-0.3,0.7c-0.2,0.3-0.1,0.5,0.1,0.8c0.2,0.2,0.4,0.5,0.7,0.7c0.3,0.3,0.7,0.6,1,0.9c0.3,0.3,0.5,0.6,0.6,1
			c0.2,0.7,0,1.3-0.4,1.9c-0.3,0.4-0.7,0.7-1.1,0.9c-0.7,0.4-1.4,0.6-2.2,0.7c-1.1,0.2-2.2,0.2-3.3,0c-0.9-0.2-1.8-0.5-2.5-1
			c-0.6-0.4-1.2-0.8-1.8-1.2c-1.4-1-3-1.8-4.6-2.6c-1.2-0.6-2.3-1.2-3.4-1.9c-0.7-0.4-1.4-0.8-2.2-1c-0.8-0.2-1.5-0.5-2.3-0.8
			c-0.1,0-0.1,0-0.2,0c-0.3,0.2-0.5,0.5-0.7,0.8c-0.2,0.3-0.5,0.7-0.7,1c-0.7,0.9-1.7,1.4-2.8,1.5c-1.5,0.1-3,0-4.5-0.7
			c-1.4-0.7-2.6-1.6-3.5-2.9c-0.3-0.4-0.5-0.8-0.6-1.2c-0.1-0.2-0.1-0.4-0.2-0.6c-0.1-0.2-0.2-0.3-0.4-0.2c0,0-0.1,0-0.1,0
			c-1.3,0.3-2.6,0.3-4,0c-0.7-0.1-0.7-0.1-0.8,0.6c0,0.3-0.1,0.6-0.1,0.9c-0.1,0.7-0.3,1.3-0.8,1.9c-0.4,0.5-0.8,1-1.2,1.4
			c-0.8,0.8-1.8,1.2-2.9,1.4c-0.9,0.2-1.9,0.2-2.8,0.1c-1.9-0.1-3.7-0.5-5.5-1.1c-0.4-0.1-0.7-0.3-1-0.5c-0.6-0.4-1.1-0.9-1.3-1.5
			c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.2-0.2-0.2-0.4-0.2c-0.1,0-0.1,0.1-0.2,0.1c-0.3,0.2-0.6,0.2-0.9,0.2c-0.6,0-1.3,0-1.9,0
			c-0.5,0-0.9-0.2-1.3-0.4c-0.8-0.3-1.5-0.7-2.1-1.2c-1-0.8-1.6-1.9-1.6-3.2c0-0.1,0-0.3,0-0.4c0-1.9,0-3.8,0.3-5.7
			c0.1-0.6,0.2-1.2,0.3-1.9c0.2-1.1,0.4-2.1,0.5-3.2c0.2-1.3,0.2-2.5,0.2-3.8c0-0.2,0-0.5-0.1-0.7c-0.1-0.8-0.3-1.7-0.3-2.5
			C963.6,496,963.6,495.8,963.4,495.7z M959,484.6c0.1,0,0.2,0,0.3-0.1c0.2-0.2,0.5-0.3,0.7-0.5c0.5-0.3,0.9-0.7,1.4-1
			c0.5-0.4,1.1-0.6,1.7-0.7c1-0.2,2.3,0.1,2.8,1.5c0.2,0.4,0.3,0.8,0.3,1.2c0.1,1.3,0.1,2.5-0.1,3.7c-0.1,0.4-0.2,0.7-0.2,1.1
			c-0.1,0.6-0.2,1.2-0.3,1.9c-0.1,1.5-0.1,3,0.1,4.4c0,0.3,0,0.5,0.1,0.8c0.1,0.7,0.2,1.3,0.3,2c0.3,1.1,0.7,2.2,1.2,3.3
			c0.8,1.7,1.5,3.4,2.1,5.1c0.3,0.9,0.7,1.7,1.2,2.5c0.4,0.7,0.7,1.4,0.9,2.2c0.3,0.9,0.5,1.9,0.8,2.8c0.1,0.3,0.1,0.7,0,1
			c-0.1,0.1-0.1,0.3-0.1,0.5c0.1,0.7,0.3,1.3,0.6,2c0.2,0.3,0.4,0.5,0.6,0.7c0.5,0.3,1,0.5,1.5,0.6c0,0,0.1,0,0.1-0.1
			c0-0.1,0-0.3,0-0.4c0-0.5,0.1-1,0.2-1.5c0.4-0.9,1.3-1.4,2.2-1.1c0.5,0.2,0.9,0.5,1.3,0.9c0.3,0.5,0.5,1,0.6,1.6
			c0,0.3,0,0.5,0,0.8c0,0.3,0.1,0.4,0.4,0.4c0.5,0,0.9,0,1.4,0c0.1,0,0.1,0,0.2,0c0.1,0,0.2-0.1,0.2-0.3c0-0.1,0-0.1,0-0.2
			c-0.2-0.8-0.3-1.6-0.3-2.5c0-0.2,0-0.5,0.1-0.7c0.2-0.7,0.8-1.1,1.6-1.1c0.5,0,0.9,0.3,1.3,0.6c0.1,0.1,0.3,0.3,0.4,0.4
			c0.1,0.1,0.1,0.1,0.3,0.1c0-0.1,0-0.2,0.1-0.3c0-0.4,0-0.8,0.1-1.1c0.1-1.1,0-2.2-0.2-3.2c-0.1-0.7-0.1-1.4-0.2-2.1
			c0-0.2,0-0.4,0.1-0.6c0.1-0.7,1-0.9,1.4-0.5c0.2,0.2,0.4,0.4,0.4,0.7c0.1,0.3,0.2,0.6,0.2,0.9c0.1,1.2,0.4,2.3,0.4,3.5
			c0,0.1,0,0.2,0,0.2c0,0.1,0.1,0.2,0.3,0.3c0.1,0,0.2,0,0.3,0c0.7,0,1.5,0,2.2-0.1c0.4,0,0.8-0.1,1.2-0.2c0.6-0.1,0.6-0.2,0.5-0.8
			c-0.2-1.3-0.3-2.5-0.2-3.8c0-0.3,0.1-0.6,0.1-1c0.1-0.3,0.2-0.6,0.4-0.9c0.2-0.2,0.5-0.2,0.6,0.1c0,0.1,0.1,0.2,0.1,0.3
			c0.1,0.3,0.1,0.6,0.1,0.8c0.2,1.4,0.4,2.8,0.7,4.2c0.1,0.3,0.2,0.5,0.3,0.8c0.1,0.2,0.2,0.4,0.2,0.7c0,1.3,0.6,2.3,1.5,3.1
			c0.1,0.1,0.1,0.1,0.3,0c0-0.2,0-0.5,0-0.7c0-0.5,0-0.9,0.3-1.4c0.5-0.8,1.3-1.1,2.2-0.7c0.5,0.2,0.8,0.5,1.1,0.9
			c0.5,0.7,0.7,1.4,0.8,2.2c0,0.4,0,0.7,0.1,1.1c0,0.1,0,0.2,0.1,0.3c0,0.1,0.1,0.1,0.2,0.1c0.1,0,0.2-0.1,0.2-0.1
			c0-0.1,0-0.2,0-0.3c0-0.3-0.1-0.6-0.1-1c0-0.3-0.1-0.6-0.1-1c0-0.2,0.1-0.5,0.1-0.7c0.2-0.7,0.7-1.1,1.4-1.2c0.4-0.1,0.8,0,1.2,0
			c0.3,0.1,0.4,0,0.5-0.4c0-0.3,0.1-0.7,0.1-1c0-0.3,0-0.6,0-0.9c0-0.4,0.1-0.9,0.3-1.3c0.1-0.3,0.3-0.5,0.5-0.7
			c0.4-0.3,0.8-0.2,1.1,0.2c0.1,0.1,0.2,0.3,0.2,0.4c0.1,0.2,0.1,0.4,0.1,0.6c0,1.1,0,2.2-0.2,3.4c-0.1,0.5,0,0.6,0.4,0.8
			c0.1,0,0.2,0.1,0.3,0.1c0.7,0.2,1.3,0.4,2,0.6c0.8,0.2,1.6,0.6,2.3,1c1.4,0.9,2.9,1.7,4.4,2.4c1.4,0.7,2.7,1.5,3.9,2.3
			c0.5,0.3,0.9,0.7,1.4,0.9c0.4,0.2,0.8,0.5,1.3,0.6c1.5,0.5,3,0.4,4.5-0.1c0.1,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.1,0.3-0.2
			c0.2-0.1,0.2-0.3,0-0.5c-0.2-0.2-0.4-0.4-0.6-0.6c-0.5-0.3-0.9-0.7-1.3-1.2c-0.5-0.6-1.1-1.1-1.8-1.6c-0.7-0.5-1.3-1-2-1.5
			c-1-0.8-1.8-1.8-2.3-2.9c-0.3-0.6-0.6-1.1-0.9-1.6c-0.3-0.4-0.6-0.8-0.9-1.2c-1.1-1.4-2.1-2.8-3-4.4c-1-1.8-2.2-3.5-3.4-5.2
			c-0.9-1.2-1.9-2.4-3.1-3.3c-1.5-1.2-3.1-2.5-4.6-3.7c-0.2-0.1-0.3-0.3-0.5-0.4c-1.6-1.1-3.3-2.1-5-2.9c-1.1-0.5-2.1-1-3.1-1.7
			c-0.5-0.4-1-0.8-1.5-1.2c-0.7-0.5-1.4-1-2.2-1.3c-0.3-0.2-0.7-0.3-1.1-0.4c-1.5-0.3-3-0.5-4.6-0.5c-0.4,0-0.8,0-1.1,0
			c-1.2,0-2.3-0.1-3.5-0.3c-1.8-0.3-3.3-1.1-4.5-2.6c-0.1-0.1-0.2-0.3-0.3-0.4c-0.3-0.3-0.5-0.6-0.6-1c-0.3-0.8-0.3-1.6-0.4-2.3
			c-0.1-1-0.2-2-0.3-2.9c-0.2-1.4-0.5-2.8-0.8-4.2c-0.3-1.1-0.7-2.2-1.4-3.1c-0.8-1.2-1.7-2.3-2.7-3.3c-0.4-0.5-0.8-0.9-1.3-1.3
			c-0.6-0.5-1.2-0.9-1.9-1.1c-0.7-0.2-1.3-0.4-2-0.7c-0.8-0.3-1.6-0.6-2.4-0.8c-0.2-0.1-0.5-0.2-0.7-0.3c-0.2-0.1-0.4-0.2-0.7-0.2
			c-1,0.1-1.9,0.3-2.8,0.6c-1.5,0.4-2.8,1.2-3.7,2.6c0,0.1-0.1,0.1-0.1,0.2c-1,1.5-2,3-3,4.5c-1,1.5-1.8,3.1-2.4,4.8
			c-0.5,1.5-0.8,3-0.7,4.5c0.1,1.9,1,3.2,2.5,4.2c0.9,0.6,1.9,0.9,3,0.9c0.7,0,1.4,0,2.1,0c0.5,0,0.9-0.1,1.3-0.2
			c0.3-0.1,0.6-0.3,0.7-0.5c0.1-0.1,0.2-0.2,0.2-0.3c0.5-0.8,0.8-1.7,0.8-2.6c0-0.2,0-0.4,0-0.6c0-0.1,0-0.3,0.1-0.4
			c0.1-0.3,0.3-0.5,0.6-0.5c0.3,0,0.6,0.2,0.6,0.6c0,0.1,0,0.3,0,0.4c0,0.8-0.1,1.5-0.4,2.2C959.1,484.1,959,484.3,959,484.6z
			 M915.3,471.9c0.2,0,0.4-0.1,0.6-0.2c0.7-0.3,1.4-0.5,2.2-0.7c1-0.2,2.1-0.4,3.1-0.5c1.2-0.1,2.4-0.5,3.3-1.4
			c0.6-0.6,1.3-1.2,1.9-1.8c0.8-0.9,1.4-1.9,1.7-3.1c0.4-1.5,0.7-3,1-4.5c0-0.3,0-0.6-0.2-0.8c-0.1-0.1-0.2-0.2-0.3-0.3
			c-1.6-1.8-2.5-3.9-3.1-6.2c-0.3-1.4-0.5-2.9-0.7-4.3c-0.1-0.4-0.1-0.8-0.2-1.2c0-0.3,0-0.6,0.1-0.9c0.2-0.6,1-0.8,1.5-0.3
			c0.2,0.2,0.3,0.3,0.4,0.6c0.1,0.2,0.1,0.4,0.1,0.6c0.1,0.8,0.3,1.7,0.4,2.5c0.2,1.2,0.3,2.4,0.7,3.5c0.5,1.5,1.2,3,2.2,4.2
			c0.4,0.5,0.9,1,1.3,1.4c0.7,0.6,1.4,1.2,2.2,1.6c0.6,0.3,1.2,0.4,1.8,0.4c0.4,0,0.6-0.1,0.3-0.6c0,0,0-0.1,0-0.1
			c-0.6-1.3-1-2.7-1.1-4.1c-0.1-1.3-0.1-2.6,0-3.9c0.1-1.5,0.3-3,0.7-4.5c0.2-1.1,0.6-2.1,1-3.1c0.3-0.6,0.6-1.2,1-1.7
			c0.2-0.3,0.2-0.6,0.1-0.9c-0.1-0.3-0.2-0.6-0.4-0.8c-0.8-2-1.7-4-2.6-5.9c-0.4-0.8-0.8-1.7-1.1-2.5c-0.3-0.8-0.6-1.7-1-2.5
			c-0.1-0.2-0.1-0.5-0.3-0.7c-0.2,0-0.3,0.1-0.4,0.3c-0.3,0.3-0.6,0.7-0.9,1.1c-0.9,1-1.8,1.9-2.9,2.6c-2.2,1.4-4.6,2.1-7.2,2.3
			c-1.7,0.1-3.3-0.2-5-0.6c-2.7-0.6-5.3-1.5-7.9-2.7c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0.2c0,0.2,0,0.4,0,0.6
			c0,1.3-0.2,2.7-0.6,3.9c-0.6,1.6-1.5,2.9-2.7,4c-0.6,0.6-1.3,1.1-2.1,1.5c-0.7,0.5-1.5,0.9-2.2,1.3c-0.6,0.4-1.3,0.8-1.8,1.3
			c-0.7,0.7-1.3,1.4-2,2.1c-0.4,0.5-0.7,1-0.9,1.6c-0.3,0.9-0.5,1.9-0.6,2.8c-0.1,0.5,0,1.1,0.1,1.6c0.3,1.3,0.6,2.7,1.1,4
			c0.5,1.6,1.4,2.9,2.6,4c0.1,0.1,0.2,0.2,0.4,0.2c0-0.1,0.1-0.2,0.1-0.3c0.1-0.4,0.2-0.8,0.4-1.2c0.4-1,1.1-1.7,1.9-2.3
			c0.5-0.4,1-0.7,1.6-0.9c0.2-0.1,0.4-0.3,0.5-0.5c0.1-0.4,0.4-0.7,0.6-1c0.7-0.8,1.5-1.4,2.4-1.8c1-0.4,2-0.7,3-0.9
			c1.5-0.2,2.9,0.1,4.1,1c1.3,1,2.4,2.2,3.2,3.6c0.8,1.4,1.4,2.8,2.1,4.2c0.1,0.2,0.1,0.3,0.1,0.5c0.1,0.7-0.5,1.1-1.1,0.9
			c-0.3-0.1-0.5-0.3-0.6-0.5c-0.2-0.3-0.4-0.7-0.6-1c-0.6-1.1-1.1-2.2-1.7-3.3c-0.6-1.2-1.5-2.1-2.5-2.9c-0.7-0.6-1.5-0.8-2.4-0.8
			c-1,0-2,0.2-2.9,0.6c-0.5,0.2-0.9,0.4-1.3,0.7c-0.1,0-0.2,0.1-0.1,0.3c0.2,0,0.3,0,0.5,0c0.5-0.1,0.9,0,1.4,0
			c1.2,0.1,2.3,0.6,3.2,1.3c0.5,0.4,1,0.9,1.4,1.5c0.8,1.1,1.4,2.4,1.9,3.7c0.6,1.8,1,3.6,1.2,5.5c0.1,1.4,0.1,2.8,0,4.2
			C915.2,471.5,915.2,471.7,915.3,471.9z M954.7,461.1c-0.3,0-0.4,0.2-0.5,0.3c-0.6,0.5-1.3,0.8-2,0.9c-1,0.2-2,0.2-3.1,0.1
			c-0.2,0-0.5-0.1-0.7-0.1c-1.2-0.3-2.3-0.9-3.4-1.6c-1.1-0.8-1.8-2-2-3.3c-0.1-0.9,0-1.7,0.3-2.4c0.6-1.2,1.6-1.9,2.9-2.1
			c0.8-0.1,1.6-0.2,2.4-0.3c0.4,0,0.7,0,1.1,0c1.1,0,2.2,0.2,3.3,0.5c1.7,0.5,2.9,1.6,3.8,3.1c0.2,0.3,0.3,0.6,0.4,0.9
			c0,0.1,0.1,0.2,0.1,0.3c0.3,0,0.4-0.2,0.6-0.2c0.9-0.4,1.9-0.8,3-0.7c0.8,0,1.6,0.3,2.2,0.8c0.7,0.5,1.2,1.2,1.5,1.9
			c0.2,0.4,0.3,0.9,0.4,1.3c0,0.1,0,0.3,0.2,0.4c0,0,0.1,0,0.1,0c0.1-0.1,0.2-0.2,0.2-0.2c0.6-0.8,1.5-1.3,2.4-1.6
			c0.5-0.1,0.9-0.2,1.4-0.3c0.2,0,0.4-0.1,0.5-0.4c0-0.1,0-0.1,0.1-0.2c0.4-1.3,0.7-2.7,0.6-4.1c0-0.1,0-0.2,0-0.2
			c0-1.1-0.2-2.1-0.6-3.1c-0.2-0.6-0.2-0.7-0.8-0.9c-0.2-0.1-0.3-0.3-0.3-0.5c0-0.2,0.1-0.3,0.1-0.5c0.1-0.1,0.2-0.3,0.3-0.4
			c1.1-1.2,2-2.6,2.9-4.1c0.2-0.3,0.3-0.6,0.4-0.9c0.4-1.3,0.2-2.4-0.8-3.4c-0.6-0.6-1.3-0.9-2.2-1c-1-0.1-2-0.1-3,0.1
			c-0.5,0.1-1,0.2-1.4,0.4c-1,0.2-1.9,0.7-2.7,1.3c-0.2,0.1-0.3,0.2-0.5,0.4c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0.1-0.5,0.2-0.8,0.1
			c-0.1,0-0.2,0-0.3,0c-0.2,0.1-0.3,0-0.5-0.1c-0.7-0.3-1.4-0.6-2.2-0.9c-1-0.4-1.9-0.7-2.9-1.1c-1.2-0.4-2.5-0.6-3.7-0.9
			c-0.4-0.1-0.7-0.1-1.1,0.2c-0.2,0.2-0.5,0.1-0.6-0.2c-0.1-0.1-0.1-0.3-0.2-0.5c0-0.2-0.1-0.3-0.1-0.5c-0.4-1.3-0.8-2.5-1.1-3.8
			c0-0.1-0.1-0.3-0.2-0.4c-0.4-0.5-0.8-0.9-1.3-1.3c-0.7-0.4-1.4-0.6-2.2-0.4c-0.7,0.2-1.3,0.5-1.8,0.9c-0.5,0.5-0.9,1-1.1,1.6
			c-0.4,1.2-0.7,2.3-0.3,3.6c0.2,0.5,0.2,1,0.2,1.5c0,0.3,0,0.7,0,1c0,0.4-0.1,0.7-0.4,0.9c-0.5,0.4-0.8,0.9-1.2,1.4
			c0,0-0.1,0.1-0.1,0.1c0,0.2-0.2,0.3-0.3,0.4c-0.1,0.1-0.2,0.2-0.3,0.4c-0.1,0.2-0.2,0.4-0.4,0.6c-0.3,0.3-0.4,0.6-0.6,1
			c-0.6,1.3-0.9,2.7-1.1,4.2c-0.1,0.8-0.2,1.7-0.3,2.5c-0.1,1-0.2,2.1-0.1,3.1c0,0.8,0.2,1.5,0.3,2.3c0.2,0.9,0.6,1.7,0.9,2.4
			c0.1,0.3,0.3,0.5,0.4,0.7c0.2,0.4,0.5,0.7,0.8,1.1c0.2,0.3,0.3,0.6,0.6,0.8c0.4,0.5,0.9,1,1.3,1.4c0.6,0.6,1.3,1.2,2.1,1.7
			c1.1,0.7,2.3,1.3,3.4,1.9c0.5,0.3,1.1,0.5,1.7,0.5c0.5,0.1,1,0.1,1.5,0.2c0.2,0,0.4,0.1,0.6,0.1c0.4,0.1,0.6,0,0.8-0.3
			c0.1-0.2,0.3-0.4,0.4-0.6c0.2-0.3,0.4-0.6,0.6-0.9c0.7-1.1,1.5-2.1,2.7-2.8c0.2-0.1,0.3-0.3,0.3-0.6c-0.1-0.5-0.1-1-0.2-1.5
			C954.7,461.2,954.7,461.2,954.7,461.1z M910.6,414.4c0-0.1-0.1-0.2-0.1-0.3c-0.2-0.3-0.3-0.6-0.5-0.9c-0.5-1-0.3-1.9,0.3-2.8
			c0.6-0.8,1.4-1.2,2.4-1.1c0.4,0,0.7,0.1,1.1,0.2c0.8,0.3,1.3,0.8,1.6,1.6c0.2,0.6,0.2,1.2,0.2,1.7c0,0.1,0,0.3,0,0.4
			c0,0.2,0.1,0.3,0.3,0.4c0.2,0.1,0.5,0.1,0.7,0.1c1,0.1,2,0.4,2.9,0.9c0.6,0.3,0.7,0.2,0.6-0.4c0-0.1,0-0.1,0-0.2
			c0-0.8,0.3-1.5,1-1.9c0.3-0.2,0.7-0.3,1.1-0.4c1-0.2,2.3,0.2,3,1.3c0.5,0.8,0.7,1.6,0.6,2.5c0,0.7-0.4,1.3-1.1,1.6
			c-0.2,0.1-0.5,0.2-0.7,0.3c-0.1,0-0.1,0.1-0.2,0.1c0.1,0.2,0.2,0.3,0.4,0.5c0.6,0.7,1.3,1.4,1.8,2.1c1.2,1.7,1.8,3.6,1.7,5.6
			c0,1.2-0.4,2.4-1,3.4c-0.7,1.1-1.5,2.1-2.5,2.9c-0.1,0.1-0.3,0.2-0.4,0.4c0,0-0.1,0.1-0.1,0.2c0.2,0,0.3,0,0.4-0.1
			c1.5-0.5,2.9-1.2,4-2.3c0.9-0.8,1.6-1.7,2.4-2.7c1.1-1.5,2-3,2.5-4.8c0.2-0.8,0.5-1.5,0.5-2.3c0.1-1.4,0.1-2.9-0.1-4.3
			c-0.1-0.8-0.2-1.5-0.3-2.3c-0.2-1.2-0.4-2.4-0.6-3.5c-0.3-1.6-0.8-3.1-1.3-4.7c-0.4-1.1-0.8-2.2-1.4-3.3c-0.7-1.2-1.6-2.1-2.8-2.7
			c-0.3-0.1-0.5-0.2-0.8-0.4c-1.1-0.4-2.1-0.8-3.3-1c-1.4-0.3-2.9-0.3-4.4-0.3c-0.3,0-0.5,0-0.8-0.2c-0.3-0.2-0.5-0.2-0.8-0.1
			c-0.9,0.3-1.6,0.7-2.3,1.4c-0.4,0.4-0.9,0.9-1.3,1.4c-1,1.2-1.8,2.5-2.3,4.1c-0.2,0.7-0.4,1.4-0.6,2c-0.5,1.8-1.3,3.4-2.7,4.8
			c-0.7,0.7-1.4,1.4-2.2,2.1c-0.5,0.5-1,1-1.5,1.5c-0.8,0.9-1.5,1.9-1.7,3.1c-0.2,0.8-0.2,1.6-0.1,2.4c0.1,1.5,0.6,2.8,1.1,4.2
			c0.3,0.8,0.8,1.6,1.3,2.3c0.4,0.5,0.8,1,1.3,1.4c0,0,0.1,0,0.3,0.1c-0.1-0.2-0.1-0.3-0.1-0.4c-0.1-0.4-0.3-0.7-0.4-1.1
			c-0.5-1.7-0.6-3.5-0.6-5.2c0-0.6,0.1-1.2,0.3-1.7c0.3-1,0.9-1.9,1.6-2.7c0.9-1,1.9-1.8,3.1-2.5
			C910.3,414.7,910.5,414.7,910.6,414.4z M956,499.4c0.2,0,0.3-0.2,0.5-0.3c0.7-0.6,1.3-1.1,2-1.7c1-0.8,2-1.7,2.9-2.6
			c0.4-0.4,0.7-0.8,1.1-1.2c0.2-0.3,0.4-0.6,0.7-0.8c0.2-0.1,0.2-0.2,0.2-0.4c0-0.1,0-0.3,0-0.4c0.1-0.9,0.2-1.9,0.4-2.8
			c0.3-1.3,0.4-2.6,0.3-3.9c0-0.2,0-0.5-0.1-0.7c-0.1-0.3-0.3-0.5-0.7-0.4c-0.3,0.1-0.5,0.2-0.7,0.3c-0.4,0.2-0.8,0.5-1.2,0.8
			c-0.7,0.5-1.5,1-2.2,1.3c-1.3,0.6-2.6,1.1-4,1.1c-0.8,0-1.5,0-2.3,0c-0.8,0-1.6-0.2-2.3-0.5c-1.9-0.7-3.2-1.9-4.2-3.6
			c-0.3-0.5-0.5-1-0.6-1.6c-0.3-1.5-0.2-3,0.1-4.5c0.3-1.5,0.9-2.8,1.5-4.2c0.5-1.1,1-2,1.6-3c0.1-0.2,0.2-0.4,0.3-0.5
			c0.1-0.2,0-0.3-0.2-0.4c-0.2,0-0.5-0.1-0.7-0.1c-1.1-0.1-2.1-0.3-3.1-0.8c-0.2-0.1-0.4-0.2-0.7-0.3c-0.4-0.2-0.7-0.4-1.1-0.5
			c-0.3-0.1-0.5-0.1-0.7,0.1c-0.1,0.1-0.3,0.2-0.4,0.3c-0.6,0.5-1.2,1-1.8,1.6c-1,1-2.1,2-2.9,3.1c-0.2,0.3-0.5,0.7-0.8,1
			c-0.1,0.1-0.2,0.2-0.3,0.4c-0.4,0.9-0.8,1.8-1,2.7c-0.2,1-0.3,2-0.4,2.9c0,0.3-0.1,0.7-0.2,0.9c-0.1,0.3-0.1,0.6,0,0.9
			c0.4,0.9,0.6,1.9,0.5,2.9c0,0.7-0.1,1.4-0.3,2.1c-0.2,0.9-0.6,1.8-1.1,2.7c-0.1,0.1-0.1,0.2-0.1,0.3c0.1,0,0.1,0.1,0.1,0.1
			c0.1,0,0.2,0,0.3,0c0.8,0,1.6-0.2,2.4-0.6c0.5-0.2,0.9-0.5,1.3-0.8c0.2-0.2,0.4-0.3,0.6-0.5c0.4-0.2,0.8-0.1,1.1,0.2
			c0.3,0.3,0.3,0.7,0.1,1.1c0,0.1-0.1,0.2-0.2,0.3c-0.2,0.2-0.1,0.4,0.1,0.5c0.8,0.5,1.6,1.1,2.5,1.6c0.3,0.2,0.7,0.3,1.1,0.4
			c0.4,0.1,0.7,0,1-0.3c0.4-0.4,1-0.8,1.5-1.1c0.3-0.2,0.6-0.4,0.9-0.6c0.8-0.6,1.8-0.8,2.8-0.6c0.6,0.1,1.1,0.2,1.6,0.4
			c1.3,0.5,2.5,1.3,3.4,2.3c0.6,0.7,1,1.4,1.3,2.2c0.3,1.1,0.3,2.2,0.1,3.3c-0.1,0.4-0.2,0.8-0.3,1.3
			C955.9,499.1,955.8,499.3,956,499.4z M900.9,463.8C900.8,463.8,900.8,463.8,900.9,463.8c0,0.9,0.1,1.7,0.1,2.6
			c0.1,0.8,0.2,1.6,0.3,2.4c0.1,0.5,0.2,0.9,0.3,1.4c0.1,0.3,0.2,0.5,0.4,0.7c0.3,0.3,0.5,0.7,0.7,1.1c0.1,0.3,0.2,0.5,0.3,0.8
			c0.2,0.5,0.4,1,0.6,1.5c0.6,1.1,1.5,2,2.5,2.7c0.6,0.5,1.3,0.6,2,0.6c1.6-0.2,2.8-0.9,3.7-2.2c0.3-0.5,0.6-1.1,0.8-1.7
			c0.5-1.5,0.6-3.1,0.6-4.7c0-1.1-0.1-2.2-0.3-3.3c-0.2-1.3-0.6-2.6-1-3.8c-0.5-1.2-1-2.2-1.9-3.2c-0.6-0.6-1.2-1.1-2.1-1.3
			c-0.7-0.2-1.3-0.2-2,0c-0.5,0.1-0.9,0.2-1.4,0.4c-0.8,0.3-1.5,0.6-2.1,1.1c-0.6,0.5-1,1-1.2,1.8c-0.1,0.5-0.3,1.1-0.3,1.7
			C900.8,462.9,900.9,463.4,900.9,463.8z M914.6,415.4c-0.9,0-1.3,0-2,0.3c-1.7,0.7-3.3,1.7-4.5,3.1c-0.8,1-1.3,2.1-1.2,3.4
			c0,1.1,0.1,2.2,0.2,3.3c0.3,1.8,1,3.3,2.2,4.7c0.2,0.2,0.4,0.4,0.6,0.5c0.3,0.1,0.6,0.3,1,0.4c2.3,0.9,4.6,1.6,7,1.9
			c0.1,0,0.3,0,0.5,0c0.8,0,1.5-0.2,2.2-0.6c0.3-0.2,0.7-0.4,1-0.7c0.6-0.5,1.2-0.9,1.8-1.4c0.6-0.5,1.1-1.1,1.5-1.7
			c0.7-1,1-2,0.9-3.2c-0.1-1.5-0.5-2.8-1.3-4c-1.1-1.6-2.4-2.9-4-4.1C918.8,416.2,916.8,415.5,914.6,415.4z M890,453.1
			c0-1,0-2-0.3-3c-0.2-1.1-0.6-2.1-1.2-3.1c-0.7-1.1-1.6-1.8-2.8-2c-1.3-0.2-2.4,0.1-3.3,0.9c-0.7,0.6-1.1,1.4-1.4,2.2
			c-0.2,0.5-0.3,1-0.5,1.6c-0.2,0.7-0.5,1.3-1,1.7c-0.3,0.2-0.3,0.5-0.4,0.8c-0.1,1.2-0.2,2.4,0,3.6c0.1,0.8,0.2,1.5,0.5,2.2
			c0.2,0.5,0.4,0.9,0.7,1.4c1,1.4,2.4,2.2,4.1,2.5c0.7,0.1,1.4,0,2-0.3c0.7-0.4,1.3-0.9,1.8-1.5c0.7-0.9,1.1-1.9,1.4-2.9
			C889.9,455.9,890,454.5,890,453.1z M949.2,491.8c-0.4-0.3-0.4-0.3-0.8-0.2c-0.2,0.1-0.4,0.1-0.5,0.2c-0.3,0.2-0.7,0.3-1.1,0.4
			c-0.3,0-0.4,0.2-0.6,0.4c-0.6,0.5-1,1.1-1.3,1.8c-0.4,0.8-0.6,1.6-0.4,2.5c0.2,0.8,0.5,1.5,0.9,2.1c0.8,1.3,1.9,2.1,3.5,2.4
			c0.7,0.1,1.3,0.1,2,0c1.3-0.3,2.1-1,2.7-2.2c0.3-0.7,0.5-1.4,0.6-2.1c0.2-1.3-0.1-2.5-1-3.5c-0.9-1-2.1-1.6-3.4-1.9
			C949.6,491.6,949.4,491.6,949.2,491.8z M933.7,484.3c-0.1-1-0.3-2-1-2.8c-1-1.2-2.2-1.7-3.7-1.3c-0.4,0.1-0.8,0.2-1.3,0.3
			c-0.5,0.1-0.9,0.3-1.3,0.6c-0.7,0.6-1.2,1.3-1.5,2.2c-0.2,0.7-0.4,1.3-0.3,2c0.1,0.7,0.3,1.3,0.7,1.9c0.4,0.7,0.9,1.3,1.5,1.8
			c0.8,0.7,1.7,1.1,2.8,1.3c0.8,0.1,1.6-0.1,2.2-0.8c0.2-0.2,0.4-0.5,0.6-0.7C933.2,487.4,933.6,485.9,933.7,484.3z M905.8,431.2
			c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0.1-0.3,0.2-0.4,0.4c-0.7,0.7-1.5,1.2-2.5,1.6c-0.9,0.4-1.8,0.8-2.7,1.1c-0.8,0.3-1.7,0.6-2.6,1
			c-0.8,0.3-1.6,0.8-2.2,1.4c-0.9,0.8-1.1,1.8-0.6,2.8c0.3,0.8,0.9,1.4,1.6,1.8c0.1,0.1,0.3,0.1,0.5,0.2c0.4,0.1,0.9,0.2,1.3,0.3
			c0.3,0.1,0.5,0,0.8-0.2c0.7-0.4,1.4-0.8,2.1-1.2c0.4-0.3,0.8-0.5,1.2-0.8c1.1-0.8,2.1-1.8,2.7-3.1c0.8-1.6,1.1-3.3,1.2-5.1
			C905.9,431.4,905.9,431.3,905.8,431.2z M941.8,466.8c-0.1-0.1-0.2-0.2-0.3-0.3c-1.1-0.7-2-1.7-2.9-2.6c-0.1-0.1-0.2-0.2-0.2-0.2
			c-0.2-0.1-0.3-0.2-0.5,0c-0.1,0.1-0.2,0.1-0.3,0.2c-1.1,0.8-2.1,1.6-3.2,2.3c-0.4,0.3-0.8,0.6-1.2,0.9c-1.2,0.9-1.9,2.1-2.4,3.4
			c-0.3,0.8-0.4,1.7-0.1,2.5c0.1,0.4,0.3,0.7,0.7,0.8c0.9,0.4,1.9,0.7,2.9,0.3c0.1,0,0.3-0.1,0.4-0.1c0.2,0,0.4-0.1,0.5-0.3
			c0.1-0.2,0.2-0.3,0.3-0.5c0.4-0.7,0.8-1.4,1.3-2c0.6-0.8,1.3-1.4,2-2.1c0.8-0.8,1.7-1.5,2.7-2.1
			C941.6,467,941.6,466.9,941.8,466.8z M950.5,460.6c0.2,0,0.5,0,0.7,0c0.8,0,1.5-0.2,2.1-0.7c0.2-0.2,0.5-0.3,0.7-0.5
			c0.4-0.2,0.7-0.4,1.1-0.5c0.2,0,0.3-0.2,0.3-0.4c0-0.6,0-1.3-0.4-1.8c-0.7-1-1.6-1.8-2.8-2.2c-1.2-0.4-2.5-0.6-3.8-0.7
			c-0.4,0-0.8-0.1-1.2-0.1c-0.2,0-0.4,0-0.6,0.1c-0.4,0.1-0.8,0.3-1.1,0.5c-0.6,0.3-0.9,0.8-1,1.5c-0.1,0.5,0,1,0.1,1.5
			c0.2,0.8,0.8,1.4,1.5,1.9C947.5,460.1,948.9,460.7,950.5,460.6z M882.8,443.1c0.1,0,0.2,0,0.2,0c0.2-0.1,0.5-0.1,0.7-0.2
			c1.7-0.3,3.3-0.1,4.7,0.9c1,0.6,1.7,1.5,2.2,2.5c1.2,2.1,1.6,4.4,1.6,6.8c0,1.2-0.1,2.4-0.3,3.6c-0.4,2-1.1,3.9-2.5,5.5
			c-0.1,0.1-0.1,0.1-0.1,0.3c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.2,0c0.9-0.1,1.8-0.4,2.6-0.8c0.8-0.4,1.6-0.9,2.3-1.4
			c0.1,0,0.1-0.1,0.1-0.2c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1-0.3-0.3-0.6-0.4-0.9c-0.7-1.7-1.1-3.5-1.4-5.3c-0.1-0.5-0.1-1-0.1-1.5
			c0-1,0.1-2,0.4-3c0.1-0.2,0.1-0.5,0.2-0.7c0.1-0.2,0-0.4-0.1-0.6c-0.2-0.3-0.3-0.6-0.5-0.9c-0.4-0.9-0.8-1.8-1-2.8
			c0-0.2-0.1-0.3-0.1-0.5c-0.5-1.7-2-2.8-3.6-2.8c-0.9,0-1.7,0.2-2.6,0.5c-0.9,0.3-1.6,0.8-2.2,1.5
			C883,442.7,882.8,442.8,882.8,443.1z M977,466.1c-0.2,0-0.6,0.1-1,0.2c-1.2,0.4-2.2,1.2-3.2,2c-0.2,0.2-0.2,0.3,0,0.5
			c0.3,0.4,0.7,0.9,1,1.3c0.6,0.8,1,1.6,1.3,2.6c0.2,0.7,0.3,0.7,1,0.5c0.1,0,0.1,0,0.2-0.1c1.1-0.5,2.2-1.1,3.1-2
			c0.3-0.3,0.6-0.7,0.8-1.1c0.5-1,0.3-2.1-0.5-2.9C979,466.5,978.1,466.1,977,466.1z M965.1,515.4c0-0.2,0.1-0.4,0.1-0.5
			c0-0.3,0.1-0.6,0.2-0.9c0.3-0.8,0.9-1.3,1.8-1.3c0.9-0.1,1.6,0.3,2.1,1.2c0.2,0.3,0.3,0.7,0.3,1c0,0.7,0.1,1.4-0.2,2
			c0,0,0,0.1,0,0.1c0.1,0,0.2,0.1,0.2,0.1c0.2,0,0.4-0.1,0.7-0.2c0.6-0.2,0.7-0.4,0.6-1c0-0.1-0.1-0.3-0.1-0.4
			c-0.1-0.2-0.2-0.4-0.2-0.6c-0.2-0.5-0.3-1.1-0.5-1.6c-0.3-1-0.7-2-1.3-2.8c-0.4-0.5-0.6-1.1-0.9-1.7c-0.4-1-0.8-2.1-1.2-3.1
			c-0.4-0.8-0.7-1.7-1.1-2.5c0-0.1-0.1-0.2-0.3-0.2c0,0.1-0.1,0.3-0.1,0.4c0,1.5-0.3,3-0.4,4.4c0,0.3-0.1,0.6-0.1,0.9
			c0,1.6-0.1,3.2-0.1,4.8c0,0.4,0,0.8,0.1,1.1c0,0.2,0.1,0.5,0.2,0.7C964.9,515.2,965,515.3,965.1,515.4z M937.6,398.7
			c0-1.1-0.2-2.3-0.5-3.4c-0.3-0.9-0.7-1.7-1.3-2.4c-0.6-0.7-1.3-1.2-2.2-1.4c-0.4-0.1-0.9-0.1-1.3,0c-0.4,0-0.8,0.1-1.2,0.2
			c-0.5,0.1-0.9,0.2-1.3,0.4c-1.1,0.5-1.9,1.3-2.6,2.3c-0.5,0.8-0.6,1.7-0.4,2.6c0,0.1,0.1,0.3,0.3,0.3c0.1,0,0.1-0.1,0.1-0.4
			c0-0.1,0-0.2,0-0.3c0-0.7,0.2-1.3,0.6-1.8c0.2-0.2,0.4-0.4,0.6-0.6c1-0.7,2.2-1.1,3.4-1.1c0.6,0,1.1,0.1,1.6,0.3
			c0.9,0.4,1.6,1,2.1,1.8c0.6,1.1,0.9,2.3,0.9,3.6c0,0.8-0.2,1.5-0.6,2.1c-0.8,1.1-1.6,2-2.9,2.6c-0.2,0.1-0.3,0.2-0.2,0.5
			c0.1,0.2,0.2,0.5,0.2,0.7c0.2,0.4,0.3,0.5,0.8,0.4c0.7-0.1,1.2-0.4,1.7-0.9c0.5-0.4,0.9-1,1.2-1.5
			C937.4,401.5,937.7,400.2,937.6,398.7z M907.4,399.6c0,0.2,0,0.4,0,0.6c0,0.2,0.1,0.4,0.1,0.6c0.4,1.3,1.2,1.5,2.3,1.4
			c0.3,0,0.4-0.3,0.5-0.6c0.1-0.2,0-0.3-0.2-0.5c-0.3-0.3-0.5-0.7-0.6-1.1c-0.1-0.5-0.2-1-0.1-1.5c0.1-1,0.3-1.9,0.5-2.8
			c0.1-0.5,0.2-1,0.4-1.5c0.2-0.5,0.4-1,0.8-1.4c0.6-0.6,1.3-0.7,2.1-0.4c0.7,0.3,1.2,0.7,1.5,1.3c0.3,0.5,0.5,1,0.6,1.5
			c0.1,0.3,0.1,0.6,0.2,0.9c0,0.1,0.1,0.2,0.1,0.2c0.2,0.1,0.3,0,0.5-0.1c0.1-0.1,0.1-0.2,0.1-0.3c-0.1-0.4-0.2-0.9-0.2-1.3
			c-0.1-0.6-0.2-1.2-0.4-1.8c-0.2-0.8-0.6-1.5-1.2-2.1c-0.4-0.4-0.9-0.6-1.5-0.6c-0.9,0-1.7,0.3-2.4,1c-0.2,0.2-0.4,0.4-0.5,0.7
			C908.3,394.2,907.5,396.8,907.4,399.6z M934.5,398.8c0-0.3-0.1-0.6-0.1-0.9c-0.1-0.6-0.3-1.2-0.6-1.8c-0.5-0.8-1.2-1.2-2.1-1.2
			c-0.9,0-1.7,0.3-2.4,0.8c-0.4,0.3-0.6,0.8-0.5,1.3c0.1,0.3,0.2,0.7,0.2,1c0.1,0.3,0.2,0.5,0.4,0.7c0.9,0.7,1.6,1.7,2.2,2.7
			c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.2,0.3,0.2,0.5,0.1c0.2-0.1,0.5-0.3,0.7-0.4c0.4-0.4,0.8-0.8,1.1-1.2
			C934.4,399.8,934.5,399.3,934.5,398.8z M972.6,463.8c0-0.1,0-0.3-0.1-0.4c-0.2-0.8-0.6-1.5-1.1-2.2c-0.5-0.6-1.2-0.9-2-0.8
			c-1.5,0.1-2.6,0.8-3.2,2.2c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.6-0.2,0.7,0.4,0.8c1.2,0.3,2.2,0.9,3.1,1.7c0.3,0.3,0.6,0.5,0.8,0.8
			c0.1,0.1,0.2,0.2,0.3,0.3c0.2,0.2,0.3,0.2,0.5,0c0.4-0.4,0.8-0.9,1.1-1.4C972.5,464.6,972.6,464.3,972.6,463.8z M956.4,461.5
			C956.4,461.5,956.4,461.5,956.4,461.5c0,0.2,0,0.5,0,0.7c0,0.2,0.1,0.3,0.3,0.2c0.1,0,0.2-0.1,0.3-0.1c1.1-0.2,2.2-0.4,3.4-0.3
			c0.5,0,1,0.1,1.5,0.2c0.3,0.1,0.6,0.2,0.9,0.3c0.2,0.1,0.3,0,0.3-0.2c0-0.1,0-0.2,0-0.3c0-0.4,0-0.8-0.1-1.2
			c-0.1-0.6-0.3-1.2-0.6-1.7c-0.5-0.8-1.3-1.2-2.3-1.1c-1.2,0.2-2.2,0.7-3.1,1.4c-0.4,0.4-0.7,0.8-0.7,1.4
			C956.4,461.1,956.4,461.3,956.4,461.5z M997.7,490.7c0.2-0.1,0.2-0.3,0.3-0.5c0.3-1.1,0.3-2.2,0.2-3.3c0-0.3-0.1-0.5-0.2-0.8
			c-0.1-0.4-0.3-0.7-0.7-0.9c-0.2-0.1-0.4-0.2-0.5-0.3c-0.9-0.5-1.8-0.4-2.6,0.2c-0.6,0.4-1.1,1-1.5,1.5c-0.1,0.1-0.1,0.2-0.2,0.3
			c-0.1,0.2-0.1,0.3,0.1,0.4c0.1,0.1,0.3,0.2,0.4,0.3c0.6,0.4,1.2,0.7,1.7,1c0.1,0.5,0.1,0.5,0.5,0.8c0.6,0.4,1.2,0.7,1.9,1
			C997.3,490.6,997.5,490.7,997.7,490.7z M977.6,475.1c-0.3,0-0.5,0-0.8,0c-0.2,0-0.5,0.1-0.7,0.1c-0.2,0-0.2,0.1-0.2,0.3
			c0,0.1,0,0.2,0,0.3c0.1,0.5,0.2,0.9,0.3,1.4c0.1,0.9,0.2,1.7,0.3,2.6c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.3,0.2
			c0.1,0,0.2,0,0.3,0c0.8-0.2,1.6-0.4,2.3-0.9c0.5-0.3,0.9-0.7,1.2-1.1c0.4-0.7,0.4-1.4-0.1-2c-0.3-0.4-0.6-0.7-1-0.8
			C979,475.2,978.3,475.1,977.6,475.1z M1011.3,500.2c0.1-0.1,0.2-0.1,0.2-0.2c0.3-0.3,0.4-0.6,0.5-0.9c0.3-0.8,0.2-1.6,0.2-2.4
			c-0.1-0.6-0.3-1.1-0.7-1.6c-0.5-0.7-1.2-1-2-0.9c-1.1,0.1-2,0.7-2.6,1.7c-0.2,0.3-0.2,0.5,0.2,0.7c0.2,0.2,0.5,0.4,0.8,0.6
			c1.1,0.9,2.2,1.7,3.2,2.7C1011.1,500.1,1011.2,500.2,1011.3,500.2z M981.8,486c0.1,0,0.3,0,0.4,0c0.2,0,0.3-0.1,0.3-0.3
			c0.1-0.4,0.2-0.8,0.2-1.3c0-0.6-0.2-1.1-0.4-1.7c-0.3-0.8-0.9-1.2-1.8-1.2c-0.6,0-1.2,0.1-1.8,0.3c-0.5,0.1-0.9,0.4-1.4,0.3
			c0,0,0,0-0.1,0c-0.3,0-0.4,0.2-0.4,0.5c0,0.2,0.1,0.5,0.1,0.7c0,0.5,0.3,0.8,0.6,1.1C978.8,485.5,980.3,485.9,981.8,486z
			 M1017.1,503.9c0-0.8-0.2-1.5-0.5-2.1c-0.5-0.8-1.2-1.1-2.1-1c-0.6,0.1-1.1,0.3-1.6,0.6c-0.4,0.2-0.5,0.4-0.1,0.8
			c1,1.3,1.9,2.7,2.8,4.1c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.3,0.2,0.4,0c0.2-0.2,0.4-0.4,0.5-0.6
			C1017,505.4,1017.1,504.6,1017.1,503.9z M985.8,486c1.3,0,2.5,0.2,3.7,0.4c0.2,0,0.4,0.1,0.6,0.1c0.3,0,0.4-0.1,0.4-0.4
			c0-0.2,0-0.5,0-0.7c-0.1-0.6-0.2-1.2-0.6-1.8c-0.4-0.6-1.1-0.9-1.8-0.8c-0.7,0-1.3,0.3-1.8,0.7c-0.6,0.5-1.1,1-1.5,1.7
			c-0.1,0.1-0.2,0.3-0.2,0.5c-0.1,0.2,0,0.3,0.2,0.4c0.1,0,0.2,0,0.2,0C985.2,486,985.5,486,985.8,486z M1000,491.8
			c1.5,0.8,3,1.6,4.3,2.7c0,0,0.1,0,0.2,0.1c0,0,0,0,0.1,0c0,0,0.1,0,0.1-0.1c0,0,0-0.1,0.1-0.2c0-0.8,0-1.5-0.2-2.2
			c-0.4-1.1-1.5-1.8-2.6-1.5c-0.7,0.2-1.3,0.6-1.8,1.1C1000,491.7,1000,491.7,1000,491.8z M1025.2,518.7c0-0.1,0.1-0.2,0.1-0.3
			c0.1-0.8,0-1.6-0.2-2.4c-0.1-0.2-0.1-0.4-0.2-0.5c-0.3-0.5-0.8-0.8-1.4-0.9c-0.4,0-0.7,0-1.1,0.1c-0.3,0-0.6,0.1-0.9,0.2
			c-0.2,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.2,0.3c0.3,0.5,0.7,0.9,1.1,1.2c0.7,0.6,1.5,1.1,2.2,1.7
			C1025,518.6,1025.1,518.6,1025.2,518.7z M911.4,398.8C911.4,398.8,911.4,398.8,911.4,398.8c0,0.1,0,0.2,0,0.3c0,0.2,0,0.4,0.2,0.4
			c0.2,0,0.3-0.1,0.4-0.2c0.6-0.7,1.2-1.4,1.9-1.9c0.2-0.1,0.3-0.3,0.2-0.6c-0.1-0.7-0.3-1.5-0.7-2.1c-0.1-0.2-0.3-0.4-0.4-0.5
			c-0.3-0.3-0.6-0.2-0.8,0.2c-0.1,0.2-0.2,0.5-0.2,0.7c-0.2,0.8-0.3,1.6-0.5,2.4C911.4,398,911.4,398.4,911.4,398.8z M1017.1,508.8
			c0,0.2,0.1,0.4,0.2,0.5c0.9,1.2,1.7,2.3,2.6,3.5c0,0,0,0.1,0.1,0.1c0.2,0.2,0.3,0.2,0.5,0c0.2-0.2,0.3-0.5,0.3-0.8
			c0-0.3,0-0.6,0-1c0-0.9-0.5-1.6-1.3-2c-0.7-0.4-1.5-0.4-2.3-0.3C1017.3,508.7,1017.2,508.8,1017.1,508.8z M968.1,517
			C968.1,516.9,968.2,516.9,968.1,517c0-0.7,0-1.4,0-2c0-0.2,0-0.4-0.1-0.6c-0.1-0.3-0.3-0.4-0.6-0.5c-0.4-0.1-0.7,0.1-0.9,0.5
			c-0.3,0.6-0.2,1.1-0.2,1.7c0,0.2,0.1,0.3,0.2,0.4c0.4,0.3,0.9,0.5,1.4,0.5C968,517,968.1,517,968.1,517z M998.9,518.8
			c0-0.6-0.1-1.2-0.3-1.7c-0.1-0.3-0.3-0.5-0.4-0.7c-0.5-0.5-0.8-0.4-1,0.2c-0.2,0.6-0.2,1.2,0,1.8c0,0.1,0,0.1,0.1,0.2
			c0.1,0.2,0.2,0.3,0.3,0.4c0.3,0.1,0.7,0.2,1,0.3c0.3,0.1,0.4-0.1,0.4-0.3C998.9,519,998.9,518.9,998.9,518.8z M976.4,519.3
			C976.4,519.3,976.4,519.3,976.4,519.3c0,0.1,0,0.2,0,0.3c0,0.2,0.1,0.3,0.3,0.4c0.3,0.1,0.6,0.1,0.8,0.1c0.2,0,0.3-0.1,0.3-0.3
			c0-0.4,0-0.8-0.2-1.2c-0.1-0.2-0.2-0.4-0.5-0.6c-0.3-0.2-0.5-0.1-0.6,0.2C976.5,518.6,976.5,518.9,976.4,519.3z M982.7,519.7
			c0.4-0.2,0.9-0.5,1-0.8c0-0.1,0.1-0.3,0-0.4c-0.1-0.3-0.2-0.7-0.4-1c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.3-0.3-0.4-0.2
			c-0.2,0.1-0.2,0.2-0.2,0.4c0,0.7,0,1.4,0.1,2.1C982.7,519.5,982.7,519.6,982.7,519.7z M1001.9,518.9c0.1,0,0.2-0.1,0.2-0.1
			c0.5-0.5,0.8-1.1,1.1-1.7c0.1-0.2,0-0.3-0.1-0.4c-0.3-0.2-0.6-0.2-0.9-0.1c-0.2,0-0.3,0.1-0.3,0.3c0,0.1-0.1,0.2-0.1,0.3
			c0,0.5,0,1,0,1.5C1001.8,518.8,1001.8,518.9,1001.9,518.9z M924.8,413.2c-0.3-0.1-0.7-0.1-1,0c-0.1,0.2-0.2,0.4-0.3,0.6
			c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.1,0.4c0.2,0,0.3,0.1,0.5,0.1c0.3,0,0.6-0.2,0.6-0.5
			C924.8,413.8,924.9,413.5,924.8,413.2z M914.2,410.4c-0.3-0.1-0.4-0.1-0.6-0.1c-0.2,0-0.4,0.2-0.5,0.4c-0.1,0.2-0.1,0.4,0.1,0.5
			c0.1,0.1,0.3,0.3,0.4,0.4c0,0,0.2,0.1,0.2,0c0.2-0.1,0.4-0.2,0.4-0.4C914.2,410.9,914.2,410.7,914.2,410.4z"/>
                            <path className="st17" d="M959,484.6c0-0.3,0.1-0.5,0.1-0.6c0.3-0.7,0.5-1.5,0.4-2.2c0-0.1,0-0.3,0-0.4c-0.1-0.3-0.3-0.6-0.6-0.6
			c-0.3,0-0.5,0.2-0.6,0.5c0,0.1-0.1,0.3-0.1,0.4c0,0.2,0,0.4,0,0.6c-0.1,0.9-0.4,1.8-0.8,2.6c-0.1,0.1-0.1,0.2-0.2,0.3
			c-0.2,0.2-0.4,0.4-0.7,0.5c-0.4,0.1-0.9,0.2-1.3,0.2c-0.7,0-1.4,0-2.1,0c-1.1-0.1-2.1-0.3-3-0.9c-1.5-1-2.4-2.4-2.5-4.2
			c-0.1-1.6,0.2-3.1,0.7-4.5c0.6-1.7,1.4-3.3,2.4-4.8c1-1.5,2-3,3-4.5c0-0.1,0.1-0.1,0.1-0.2c0.9-1.3,2.1-2.1,3.7-2.6
			c0.9-0.3,1.9-0.5,2.8-0.6c0.3,0,0.5,0,0.7,0.2c0.2,0.1,0.4,0.2,0.7,0.3c0.8,0.2,1.6,0.5,2.4,0.8c0.7,0.2,1.3,0.5,2,0.7
			c0.7,0.2,1.4,0.6,1.9,1.1c0.4,0.4,0.9,0.8,1.3,1.3c1,1.1,1.9,2.2,2.7,3.3c0.7,1,1.1,2,1.4,3.1c0.3,1.4,0.6,2.8,0.8,4.2
			c0.1,1,0.2,2,0.3,2.9c0.1,0.8,0.2,1.6,0.4,2.3c0.1,0.4,0.3,0.7,0.6,1c0.1,0.1,0.2,0.2,0.3,0.4c1.2,1.4,2.7,2.3,4.5,2.6
			c1.1,0.2,2.3,0.2,3.5,0.3c0.4,0,0.8,0,1.1,0c1.6-0.1,3.1,0.2,4.6,0.5c0.4,0.1,0.7,0.2,1.1,0.4c0.8,0.3,1.5,0.8,2.2,1.3
			c0.5,0.4,1,0.8,1.5,1.2c1,0.7,2,1.2,3.1,1.7c1.8,0.8,3.5,1.8,5,2.9c0.2,0.1,0.3,0.2,0.5,0.4c1.5,1.2,3.1,2.5,4.6,3.7
			c1.2,1,2.2,2.1,3.1,3.3c1.2,1.7,2.4,3.4,3.4,5.2c0.9,1.5,1.9,3,3,4.4c0.3,0.4,0.6,0.8,0.9,1.2c0.4,0.5,0.7,1,0.9,1.6
			c0.5,1.2,1.3,2.1,2.3,2.9c0.6,0.5,1.3,1,2,1.5c0.7,0.5,1.3,1,1.8,1.6c0.4,0.4,0.8,0.8,1.3,1.2c0.2,0.2,0.4,0.4,0.6,0.6
			c0.1,0.1,0.1,0.3,0,0.5c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.3,0.1-0.4,0.2c-1.5,0.5-3,0.6-4.5,0.1c-0.4-0.2-0.9-0.4-1.3-0.6
			c-0.5-0.3-1-0.6-1.4-0.9c-1.3-0.9-2.6-1.7-3.9-2.3c-1.5-0.7-3-1.5-4.4-2.4c-0.7-0.4-1.5-0.8-2.3-1c-0.7-0.2-1.3-0.4-2-0.6
			c-0.1,0-0.2-0.1-0.3-0.1c-0.4-0.2-0.5-0.2-0.4-0.8c0.2-1.1,0.2-2.2,0.2-3.4c0-0.2,0-0.4-0.1-0.6c0-0.2-0.1-0.3-0.2-0.4
			c-0.3-0.4-0.7-0.4-1.1-0.2c-0.3,0.2-0.4,0.4-0.5,0.7c-0.1,0.4-0.2,0.8-0.3,1.3c0,0.3,0,0.6,0,0.9c0,0.3,0,0.7-0.1,1
			c0,0.4-0.1,0.4-0.5,0.4c-0.4-0.1-0.8-0.1-1.2,0c-0.7,0.1-1.2,0.5-1.4,1.2c-0.1,0.2-0.1,0.4-0.1,0.7c0,0.3,0,0.6,0.1,1
			c0,0.3,0.1,0.6,0.1,1c0,0.1,0,0.2,0,0.3c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.3
			c0-0.4,0-0.7-0.1-1.1c-0.1-0.8-0.3-1.5-0.8-2.2c-0.3-0.4-0.7-0.7-1.1-0.9c-0.9-0.4-1.7-0.1-2.2,0.7c-0.2,0.4-0.3,0.9-0.3,1.4
			c0,0.2,0,0.5,0,0.7c-0.1,0.1-0.2,0-0.3,0c-0.9-0.8-1.5-1.9-1.5-3.1c0-0.3-0.1-0.5-0.2-0.7c-0.2-0.2-0.2-0.5-0.3-0.8
			c-0.4-1.4-0.6-2.8-0.7-4.2c0-0.3-0.1-0.6-0.1-0.8c0-0.1,0-0.2-0.1-0.3c-0.1-0.3-0.4-0.4-0.6-0.1c-0.2,0.2-0.3,0.6-0.4,0.9
			c-0.1,0.3-0.1,0.6-0.1,1c-0.1,1.3,0,2.5,0.2,3.8c0.1,0.6,0.1,0.7-0.5,0.8c-0.4,0.1-0.8,0.2-1.2,0.2c-0.7,0-1.5,0.1-2.2,0.1
			c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2-0.1-0.3-0.3c0-0.1,0-0.2,0-0.2c0-1.2-0.3-2.3-0.4-3.5c0-0.3-0.1-0.6-0.2-0.9
			c-0.1-0.3-0.2-0.5-0.4-0.7c-0.5-0.4-1.3-0.3-1.4,0.5c0,0.2-0.1,0.4-0.1,0.6c0,0.7,0.1,1.4,0.2,2.1c0.1,1.1,0.2,2.2,0.2,3.2
			c0,0.4,0,0.8-0.1,1.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.3-0.4-0.4c-0.4-0.4-0.8-0.6-1.3-0.6
			c-0.7-0.1-1.4,0.4-1.6,1.1c-0.1,0.2-0.1,0.5-0.1,0.7c0,0.8,0.1,1.7,0.3,2.5c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2-0.2,0.3
			c-0.1,0-0.1,0-0.2,0c-0.5,0-0.9,0-1.4,0c-0.3,0-0.4-0.1-0.4-0.4c0-0.3,0-0.5,0-0.8c0-0.6-0.2-1.2-0.6-1.6
			c-0.3-0.5-0.7-0.8-1.3-0.9c-0.9-0.3-1.9,0.1-2.2,1.1c-0.2,0.5-0.3,1-0.2,1.5c0,0.1,0,0.3,0,0.4c-0.1,0-0.1,0.1-0.1,0.1
			c-0.5-0.1-1-0.3-1.5-0.6c-0.3-0.2-0.5-0.4-0.6-0.7c-0.3-0.6-0.5-1.3-0.6-2c0-0.2,0-0.3,0.1-0.5c0.2-0.3,0.1-0.7,0-1
			c-0.3-0.9-0.5-1.9-0.8-2.8c-0.2-0.8-0.5-1.5-0.9-2.2c-0.5-0.8-0.9-1.6-1.2-2.5c-0.6-1.7-1.3-3.4-2.1-5.1c-0.5-1-0.9-2.1-1.2-3.3
			c-0.1-0.6-0.2-1.3-0.3-2c0-0.3,0-0.5-0.1-0.8c-0.2-1.5-0.2-3-0.1-4.4c0-0.6,0.2-1.2,0.3-1.9c0.1-0.4,0.1-0.7,0.2-1.1
			c0.3-1.2,0.2-2.5,0.1-3.7c0-0.4-0.1-0.8-0.3-1.2c-0.6-1.4-1.9-1.7-2.8-1.5c-0.6,0.1-1.2,0.4-1.7,0.7c-0.5,0.3-0.9,0.7-1.4,1
			c-0.2,0.2-0.5,0.3-0.7,0.5C959.2,484.5,959.1,484.5,959,484.6z M957.7,472.4c-0.5,0-0.9,0.4-1,0.9c-0.1,0.6,0.3,1.3,0.9,1.6
			c0.6,0.3,1.2,0.2,1.6-0.3c0.6-0.7,0.4-1.8-0.5-2C958.4,472.5,958,472.4,957.7,472.4z M951.3,480.7c-0.3,0-0.6,0.2-0.7,0.5
			c-0.2,0.5,0.1,1.1,0.5,1.2c0.4,0.1,0.9-0.2,1-0.7C952.2,481.3,951.8,480.8,951.3,480.7z M947.9,481.2c0,0.4,0.3,0.8,0.7,0.8
			c0.4,0,0.8-0.3,0.8-0.7c0-0.4-0.4-0.8-0.8-0.8C948.3,480.5,948,480.8,947.9,481.2z"/>
                            <path className="st18" d="M915.3,471.9c-0.2-0.2-0.1-0.3-0.1-0.5c0.2-1.4,0.1-2.8,0-4.2c-0.2-1.9-0.5-3.7-1.2-5.5
			c-0.5-1.3-1.1-2.6-1.9-3.7c-0.4-0.6-0.9-1.1-1.4-1.5c-0.9-0.8-2-1.2-3.2-1.3c-0.5,0-0.9-0.1-1.4,0c-0.2,0-0.3,0-0.5,0
			c0-0.2,0.1-0.2,0.1-0.3c0.4-0.2,0.9-0.5,1.3-0.7c0.9-0.3,1.9-0.5,2.9-0.6c0.9,0,1.7,0.2,2.4,0.8c1,0.8,1.9,1.8,2.5,2.9
			c0.6,1.1,1.1,2.2,1.7,3.3c0.2,0.4,0.4,0.7,0.6,1c0.1,0.2,0.3,0.4,0.6,0.5c0.7,0.2,1.2-0.2,1.1-0.9c0-0.2-0.1-0.3-0.1-0.5
			c-0.6-1.4-1.3-2.8-2.1-4.2c-0.8-1.4-1.9-2.6-3.2-3.6c-1.2-0.9-2.6-1.3-4.1-1c-1,0.2-2,0.4-3,0.9c-0.9,0.4-1.7,1.1-2.4,1.8
			c-0.3,0.3-0.5,0.6-0.6,1c-0.1,0.3-0.3,0.4-0.5,0.5c-0.6,0.3-1.1,0.5-1.6,0.9c-0.8,0.6-1.5,1.4-1.9,2.3c-0.2,0.4-0.3,0.8-0.4,1.2
			c0,0.1-0.1,0.2-0.1,0.3c-0.2,0-0.3-0.1-0.4-0.2c-1.3-1.1-2.1-2.4-2.6-4c-0.4-1.3-0.8-2.6-1.1-4c-0.1-0.5-0.2-1.1-0.1-1.6
			c0.1-1,0.3-1.9,0.6-2.8c0.2-0.6,0.5-1.2,0.9-1.6c0.6-0.7,1.3-1.4,2-2.1c0.5-0.5,1.2-0.9,1.8-1.3c0.7-0.4,1.5-0.9,2.2-1.3
			c0.7-0.5,1.4-1,2.1-1.5c1.3-1.1,2.1-2.5,2.7-4c0.5-1.3,0.7-2.6,0.6-3.9c0-0.2,0-0.4,0-0.6c0-0.1,0.1-0.2,0.3-0.2
			c0.1,0,0.2,0.1,0.3,0.1c2.5,1.2,5.1,2.1,7.9,2.7c1.6,0.3,3.3,0.6,5,0.6c2.6-0.1,5-0.8,7.2-2.3c1.1-0.7,2-1.6,2.9-2.6
			c0.3-0.3,0.6-0.7,0.9-1.1c0.1-0.1,0.2-0.3,0.4-0.3c0.2,0.2,0.2,0.4,0.3,0.7c0.3,0.8,0.6,1.7,1,2.5c0.3,0.8,0.7,1.7,1.1,2.5
			c0.9,1.9,1.7,3.9,2.6,5.9c0.1,0.3,0.2,0.6,0.4,0.8c0.2,0.3,0.1,0.6-0.1,0.9c-0.4,0.6-0.7,1.1-1,1.7c-0.5,1-0.8,2-1,3.1
			c-0.3,1.5-0.5,3-0.7,4.5c-0.1,1.3-0.1,2.6,0,3.9c0.1,1.4,0.5,2.8,1.1,4.1c0,0,0,0.1,0,0.1c0.3,0.5,0.1,0.6-0.3,0.6
			c-0.6,0-1.2-0.1-1.8-0.4c-0.8-0.4-1.5-1-2.2-1.6c-0.5-0.4-0.9-0.9-1.3-1.4c-1-1.2-1.7-2.7-2.2-4.2c-0.4-1.2-0.5-2.3-0.7-3.5
			c-0.1-0.8-0.3-1.7-0.4-2.5c0-0.2-0.1-0.4-0.1-0.6c-0.1-0.2-0.2-0.4-0.4-0.6c-0.5-0.4-1.3-0.2-1.5,0.3c-0.1,0.3-0.2,0.6-0.1,0.9
			c0.1,0.4,0.1,0.8,0.2,1.2c0.2,1.4,0.4,2.9,0.7,4.3c0.5,2.3,1.5,4.4,3.1,6.2c0.1,0.1,0.2,0.2,0.3,0.3c0.2,0.2,0.3,0.5,0.2,0.8
			c-0.2,1.5-0.5,3-1,4.5c-0.4,1.2-0.9,2.2-1.7,3.1c-0.6,0.6-1.2,1.2-1.9,1.8c-0.9,0.9-2,1.3-3.3,1.4c-1,0.1-2.1,0.3-3.1,0.5
			c-0.7,0.2-1.5,0.4-2.2,0.7C915.7,471.8,915.6,471.9,915.3,471.9z"/>
                            <path className="st19" d="M954.7,461.1c0,0.1,0.1,0.1,0.1,0.1c0.1,0.5,0.1,1,0.2,1.5c0,0.3,0,0.4-0.3,0.6c-1.1,0.7-2,1.7-2.7,2.8
			c-0.2,0.3-0.4,0.6-0.6,0.9c-0.1,0.2-0.3,0.4-0.4,0.6c-0.2,0.3-0.4,0.5-0.8,0.3c-0.2-0.1-0.4-0.1-0.6-0.1c-0.5-0.1-1-0.2-1.5-0.2
			c-0.6-0.1-1.2-0.2-1.7-0.5c-1.1-0.6-2.3-1.2-3.4-1.9c-0.8-0.5-1.5-1-2.1-1.7c-0.4-0.5-0.9-0.9-1.3-1.4c-0.2-0.2-0.4-0.5-0.6-0.8
			c-0.2-0.4-0.5-0.8-0.8-1.1c-0.2-0.2-0.3-0.5-0.4-0.7c-0.4-0.8-0.7-1.6-0.9-2.4c-0.2-0.7-0.3-1.5-0.3-2.3c0-1,0-2.1,0.1-3.1
			c0.1-0.8,0.2-1.7,0.3-2.5c0.2-1.4,0.6-2.8,1.1-4.2c0.2-0.3,0.3-0.7,0.6-1c0.1-0.2,0.2-0.4,0.4-0.6c0.1-0.1,0.2-0.3,0.3-0.4
			c0.1-0.1,0.2-0.2,0.3-0.4c0-0.1,0-0.1,0.1-0.1c0.4-0.4,0.7-1,1.2-1.4c0.3-0.2,0.5-0.5,0.4-0.9c0-0.3,0-0.7,0-1c0-0.5,0-1-0.2-1.5
			c-0.4-1.2-0.1-2.4,0.3-3.6c0.2-0.6,0.6-1.2,1.1-1.6c0.5-0.5,1.1-0.8,1.8-0.9c0.8-0.2,1.5-0.1,2.2,0.4c0.5,0.3,0.9,0.8,1.3,1.3
			c0.1,0.1,0.1,0.2,0.2,0.4c0.4,1.3,0.8,2.5,1.1,3.8c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0.1,0.3,0.2,0.5c0.1,0.2,0.4,0.3,0.6,0.2
			c0.3-0.3,0.7-0.3,1.1-0.2c1.3,0.2,2.5,0.4,3.7,0.9c1,0.3,1.9,0.7,2.9,1.1c0.7,0.3,1.4,0.6,2.2,0.9c0.2,0.1,0.3,0.1,0.5,0.1
			c0.1,0,0.2,0,0.3,0c0.3,0.1,0.5,0,0.8-0.1c0.1-0.1,0.3-0.1,0.4-0.2c0.2-0.1,0.3-0.2,0.5-0.4c0.8-0.6,1.7-1,2.7-1.3
			c0.5-0.1,1-0.2,1.4-0.4c1-0.2,2-0.3,3-0.1c0.8,0.1,1.6,0.5,2.2,1c1,1,1.2,2.1,0.8,3.4c-0.1,0.3-0.3,0.6-0.4,0.9
			c-0.8,1.4-1.8,2.8-2.9,4.1c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.1,0.3-0.1,0.5c0,0.2,0.1,0.4,0.3,0.5c0.7,0.2,0.6,0.3,0.8,0.9
			c0.4,1,0.6,2.1,0.6,3.1c0,0.1,0,0.2,0,0.2c0.1,1.4-0.2,2.8-0.6,4.1c0,0.1,0,0.1-0.1,0.2c-0.1,0.2-0.2,0.4-0.5,0.4
			c-0.5,0-0.9,0.1-1.4,0.3c-1,0.3-1.8,0.8-2.4,1.6c-0.1,0.1-0.2,0.2-0.2,0.2c0,0-0.1,0-0.1,0c-0.2-0.1-0.1-0.3-0.2-0.4
			c-0.1-0.4-0.2-0.9-0.4-1.3c-0.3-0.8-0.8-1.4-1.5-1.9c-0.7-0.5-1.4-0.7-2.2-0.8c-1.1,0-2,0.3-3,0.7c-0.2,0.1-0.4,0.2-0.6,0.2
			c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.3-0.2-0.7-0.4-0.9c-0.9-1.4-2.1-2.5-3.8-3.1c-1.1-0.3-2.1-0.5-3.3-0.5c-0.4,0-0.7,0-1.1,0
			c-0.8,0.1-1.6,0.2-2.4,0.3c-1.3,0.2-2.4,0.9-2.9,2.1c-0.4,0.8-0.5,1.6-0.3,2.4c0.2,1.4,0.9,2.5,2,3.3c1,0.8,2.1,1.3,3.4,1.6
			c0.2,0.1,0.5,0.1,0.7,0.1c1,0.1,2.1,0.1,3.1-0.1c0.7-0.1,1.4-0.4,2-0.9C954.3,461.3,954.4,461.1,954.7,461.1z M969.7,442.7
			c0-0.9-0.3-1.6-0.8-2.3c-0.4-0.6-1-0.9-1.7-0.9c-0.2,0-0.4,0-0.6,0c-1.1,0.2-2.1,0.5-3.1,1.1c-0.3,0.2-0.5,0.4-0.7,0.6
			c-0.1,0.1-0.2,0.3-0.2,0.4c-0.1,0.4,0.2,0.6,0.5,0.6c0.1,0,0.3-0.1,0.4-0.1c0.8-0.3,1.5-0.5,2.3-0.6c0.4-0.1,0.7-0.1,1.1-0.1
			c0.3,0,0.5,0.1,0.6,0.3c0.3,0.5,0.4,1,0.2,1.5c-0.1,0.2-0.1,0.4-0.2,0.6c-0.4,0.8-0.8,1.5-1.3,2.2c-0.1,0.2-0.2,0.3-0.3,0.5
			c-0.2,0.4,0,0.8,0.4,1c0.2,0.1,0.4,0.1,0.7,0c0.3-0.1,0.5-0.3,0.7-0.6c0.8-1,1.4-2.1,1.9-3.2C969.7,443.5,969.7,443.1,969.7,442.7
			z M946,437.2c0.8,0,1.2-0.3,1.3-0.9c0.1-0.4,0.1-0.8-0.1-1.2c-0.1-0.5-0.4-0.9-0.7-1.2c-0.3-0.3-0.7-0.6-1.1-0.7
			c-0.9-0.2-2,0.2-2.5,1c-0.3,0.4-0.4,0.9-0.4,1.4c0,0.6,0.1,1.2,0.1,1.8c0,0.1,0,0.2,0.1,0.3c0.2,0.2,0.4,0.3,0.7,0.3
			c0.2-0.1,0.4-0.2,0.5-0.5c0-0.1,0.1-0.3,0.1-0.4c0-0.2,0-0.4-0.1-0.6c0-0.3,0-0.6,0.1-1c0.1-0.2,0.2-0.4,0.3-0.5
			c0.3-0.3,0.7-0.2,1,0.1c0.1,0.1,0.2,0.2,0.2,0.4c0.3,0.6,0.3,0.7,0.1,1.3c0,0,0,0.1,0,0.1C945.8,436.9,945.9,437.1,946,437.2z
			 M947.5,446.8c-0.4,0-0.8,0.1-1,0.3c-0.5,0.3-0.7,0.7-0.7,1.3c0,0.8,0.4,1.4,1.1,1.9c0.3,0.2,0.5,0.3,0.9,0.3
			c1,0.1,1.8-0.7,1.7-1.7c0-0.8-0.7-1.7-1.5-1.9C947.8,446.8,947.6,446.8,947.5,446.8z M956.5,451.5c0,0.8,0.4,1.5,1.2,1.7
			c0.5,0.2,1,0.2,1.4-0.1c0.7-0.4,1.1-1.2,0.9-2c-0.2-0.8-1.1-1.5-1.9-1.4C957.2,449.8,956.5,450.6,956.5,451.5z"/>
                            <path className="st18" d="M910.6,414.4c-0.1,0.2-0.3,0.3-0.5,0.4c-1.2,0.7-2.2,1.5-3.1,2.5c-0.7,0.8-1.3,1.7-1.6,2.7
			c-0.2,0.6-0.3,1.2-0.3,1.7c0,1.8,0.1,3.5,0.6,5.2c0.1,0.4,0.3,0.7,0.4,1.1c0,0.1,0.1,0.2,0.1,0.4c-0.1,0-0.2,0-0.3-0.1
			c-0.5-0.4-0.9-0.9-1.3-1.4c-0.5-0.7-1-1.5-1.3-2.3c-0.5-1.3-1-2.7-1.1-4.2c-0.1-0.8,0-1.6,0.1-2.4c0.3-1.2,0.9-2.2,1.7-3.1
			c0.5-0.5,1-1,1.5-1.5c0.7-0.7,1.5-1.4,2.2-2.1c1.3-1.4,2.1-3,2.7-4.8c0.2-0.7,0.4-1.4,0.6-2c0.5-1.5,1.2-2.9,2.3-4.1
			c0.4-0.5,0.8-0.9,1.3-1.4c0.6-0.6,1.4-1.1,2.3-1.4c0.3-0.1,0.5-0.1,0.8,0.1c0.2,0.2,0.5,0.2,0.8,0.2c1.5-0.1,2.9,0,4.4,0.3
			c1.1,0.2,2.2,0.6,3.3,1c0.3,0.1,0.5,0.2,0.8,0.4c1.2,0.6,2.1,1.5,2.8,2.7c0.6,1,1.1,2.1,1.4,3.3c0.5,1.5,1,3.1,1.3,4.7
			c0.2,1.2,0.4,2.4,0.6,3.5c0.1,0.8,0.2,1.5,0.3,2.3c0.2,1.4,0.2,2.9,0.1,4.3c0,0.8-0.3,1.6-0.5,2.3c-0.5,1.8-1.3,3.4-2.5,4.8
			c-0.7,0.9-1.5,1.9-2.4,2.7c-1.2,1.1-2.5,1.8-4,2.3c-0.1,0-0.2,0-0.4,0.1c0.1-0.1,0.1-0.2,0.1-0.2c0.1-0.1,0.3-0.2,0.4-0.4
			c1-0.8,1.9-1.8,2.5-2.9c0.6-1.1,0.9-2.2,1-3.4c0-2.1-0.5-3.9-1.7-5.6c-0.5-0.8-1.2-1.5-1.8-2.1c-0.1-0.1-0.3-0.2-0.4-0.5
			c0.1,0,0.1-0.1,0.2-0.1c0.2-0.1,0.5-0.2,0.7-0.3c0.7-0.3,1.1-0.9,1.1-1.6c0.1-0.9-0.1-1.7-0.6-2.5c-0.7-1.1-2-1.5-3-1.3
			c-0.4,0.1-0.8,0.2-1.1,0.4c-0.7,0.4-1,1.1-1,1.9c0,0.1,0,0.1,0,0.2c0.1,0.6,0,0.7-0.6,0.4c-0.9-0.4-1.9-0.7-2.9-0.9
			c-0.2,0-0.5-0.1-0.7-0.1c-0.3-0.1-0.3-0.1-0.3-0.4c0-0.1,0-0.3,0-0.4c0-0.6,0-1.2-0.2-1.7c-0.3-0.8-0.8-1.3-1.6-1.6
			c-0.3-0.1-0.7-0.2-1.1-0.2c-1-0.1-1.8,0.3-2.4,1.1c-0.6,0.9-0.8,1.8-0.3,2.8c0.1,0.3,0.3,0.6,0.5,0.9
			C910.5,414.2,910.6,414.3,910.6,414.4z"/>
                            <path className="st19" d="M956,499.4c-0.2-0.1-0.1-0.3-0.1-0.4c0.1-0.4,0.2-0.8,0.3-1.3c0.2-1.1,0.2-2.2-0.1-3.3
			c-0.3-0.8-0.7-1.6-1.3-2.2c-0.9-1.1-2.1-1.8-3.4-2.3c-0.5-0.2-1.1-0.3-1.6-0.4c-1-0.2-2,0-2.8,0.6c-0.3,0.2-0.6,0.4-0.9,0.6
			c-0.5,0.3-1,0.6-1.5,1.1c-0.3,0.3-0.6,0.3-1,0.3c-0.4-0.1-0.7-0.2-1.1-0.4c-0.8-0.5-1.6-1-2.5-1.6c-0.2-0.1-0.3-0.3-0.1-0.5
			c0.1-0.1,0.1-0.2,0.2-0.3c0.2-0.4,0.1-0.8-0.1-1.1c-0.3-0.3-0.7-0.4-1.1-0.2c-0.2,0.1-0.4,0.3-0.6,0.5c-0.4,0.3-0.8,0.6-1.3,0.8
			c-0.7,0.4-1.5,0.6-2.4,0.6c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.1-0.1c0-0.1,0.1-0.2,0.1-0.3c0.5-0.8,0.8-1.8,1.1-2.7
			c0.2-0.7,0.3-1.4,0.3-2.1c0.1-1-0.1-2-0.5-2.9c-0.1-0.3-0.1-0.6,0-0.9c0.1-0.3,0.1-0.6,0.2-0.9c0.1-1,0.2-2,0.4-2.9
			c0.2-1,0.6-1.8,1-2.7c0.1-0.1,0.2-0.3,0.3-0.4c0.3-0.3,0.5-0.6,0.8-1c0.8-1.2,1.8-2.2,2.9-3.1c0.6-0.5,1.2-1,1.8-1.6
			c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.2,0.5-0.2,0.7-0.1c0.4,0.2,0.7,0.4,1.1,0.5c0.2,0.1,0.4,0.2,0.7,0.3c1,0.5,2,0.7,3.1,0.8
			c0.2,0,0.5,0,0.7,0.1c0.2,0,0.3,0.2,0.2,0.4c-0.1,0.2-0.2,0.4-0.3,0.5c-0.6,1-1.2,1.9-1.6,3c-0.6,1.4-1.1,2.7-1.5,4.2
			c-0.3,1.5-0.4,3-0.1,4.5c0.1,0.6,0.3,1.1,0.6,1.6c0.9,1.7,2.3,3,4.2,3.6c0.8,0.3,1.5,0.4,2.3,0.5c0.8,0,1.5,0,2.3,0
			c1.4-0.1,2.8-0.5,4-1.1c0.8-0.4,1.5-0.8,2.2-1.3c0.4-0.3,0.8-0.5,1.2-0.8c0.2-0.1,0.5-0.2,0.7-0.3c0.3-0.1,0.6,0.1,0.7,0.4
			c0.1,0.2,0.1,0.4,0.1,0.7c0.1,1.3,0,2.6-0.3,3.9c-0.2,0.9-0.3,1.8-0.4,2.8c0,0.1,0,0.3,0,0.4c0,0.2-0.1,0.3-0.2,0.4
			c-0.3,0.2-0.5,0.5-0.7,0.8c-0.3,0.4-0.7,0.8-1.1,1.2c-0.9,0.9-1.9,1.8-2.9,2.6c-0.7,0.5-1.3,1.1-2,1.7
			C956.3,499.2,956.2,499.4,956,499.4z"/>
                            <path className="st18" d="M900.9,463.8c0-0.4,0-0.9,0-1.3c0-0.6,0.1-1.1,0.3-1.7c0.2-0.7,0.6-1.3,1.2-1.8c0.6-0.5,1.4-0.8,2.1-1.1
			c0.5-0.1,0.9-0.3,1.4-0.4c0.7-0.1,1.3-0.2,2,0c0.8,0.2,1.5,0.6,2.1,1.3c0.8,0.9,1.4,2,1.9,3.2c0.5,1.2,0.8,2.5,1,3.8
			c0.2,1.1,0.3,2.2,0.3,3.3c0.1,1.6-0.1,3.1-0.6,4.7c-0.2,0.6-0.4,1.2-0.8,1.7c-0.9,1.4-2.1,2.1-3.7,2.2c-0.7,0.1-1.4-0.1-2-0.6
			c-1-0.8-1.8-1.7-2.5-2.7c-0.3-0.5-0.5-1-0.6-1.5c-0.1-0.3-0.2-0.5-0.3-0.8c-0.1-0.4-0.4-0.8-0.7-1.1c-0.2-0.2-0.3-0.5-0.4-0.7
			c-0.1-0.5-0.2-0.9-0.3-1.4c-0.2-0.8-0.3-1.6-0.3-2.4C900.9,465.5,900.9,464.7,900.9,463.8C900.8,463.8,900.8,463.8,900.9,463.8z"
                            />
                            <path className="st20" d="M914.6,415.4c2.2,0,4.1,0.7,5.9,2c1.6,1.1,2.9,2.5,4,4.1c0.8,1.2,1.3,2.5,1.3,4c0.1,1.2-0.2,2.2-0.9,3.2
			c-0.4,0.6-0.9,1.2-1.5,1.7c-0.6,0.5-1.2,1-1.8,1.4c-0.3,0.2-0.7,0.4-1,0.7c-0.7,0.4-1.4,0.6-2.2,0.6c-0.2,0-0.3,0-0.5,0
			c-2.4-0.3-4.8-1-7-1.9c-0.3-0.1-0.6-0.3-1-0.4c-0.3-0.1-0.5-0.2-0.6-0.5c-1.2-1.4-1.9-2.9-2.2-4.7c-0.2-1.1-0.2-2.2-0.2-3.3
			c0-1.3,0.4-2.4,1.2-3.4c1.2-1.5,2.7-2.4,4.5-3.1C913.3,415.5,913.7,415.4,914.6,415.4z M914.1,428c0.1,0.1,0.2,0.2,0.2,0.3
			c0.3,0.5,0.8,0.9,1.3,1.1c0.8,0.3,1.6,0.3,2.4,0.2c0.5-0.1,1-0.3,1.4-0.5c0.7-0.5,1.1-1.1,1.2-2c0-0.2,0-0.3-0.1-0.5
			c-0.1-0.5-0.6-0.6-1-0.3c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.3-0.2,0.4c-0.1,0.3-0.4,0.5-0.7,0.6c-0.6,0.3-1.3,0.4-1.9,0.2
			c-0.4-0.1-0.7-0.3-0.9-0.8c-0.1-0.4-0.2-0.7-0.3-1.1c-0.1-0.6-0.1-0.6,0.5-0.6c0.6,0,1.1,0,1.7,0c0.6,0,1.1-0.1,1.7-0.4
			c2-0.9,2.7-3.2,1.2-5c-0.2-0.2-0.4-0.4-0.6-0.6c-0.8-0.7-1.8-1.2-2.8-1.5c-0.5-0.1-1-0.2-1.5-0.2c-0.7,0.1-1.3,0.1-2,0.2
			c-0.6,0.1-1.1,0.2-1.7,0.4c-0.7,0.3-1.3,0.8-1.6,1.5c-0.7,1.4-0.3,3.2,0.9,4.2c0.4,0.3,0.7,0.5,1.2,0.7c0.3,0.1,0.6,0.2,1,0.3
			c0.2,0.1,0.3,0.2,0.3,0.4c0,0.2-0.1,0.4-0.1,0.6c-0.1,0.3-0.3,0.4-0.5,0.5c-0.5,0.1-0.9,0.1-1.4,0c-0.7-0.1-1.1-0.5-1.2-1.2
			c-0.1-0.6-0.6-0.9-1.1-0.7c-0.2,0.1-0.3,0.2-0.4,0.3c-0.3,0.4-0.4,0.8-0.2,1.2c0.4,1.1,1.1,1.9,2.4,2.1c0.3,0,0.6,0.1,0.9,0.1
			c0.6,0,1.3-0.1,1.9-0.3C913.9,428,914,428,914.1,428z"/>
                            <path className="st18" d="M890,453.1c0,1.4-0.2,2.8-0.5,4.2c-0.3,1.1-0.7,2-1.4,2.9c-0.5,0.6-1.1,1.1-1.8,1.5c-0.6,0.3-1.3,0.4-2,0.3
			c-1.7-0.3-3.1-1.1-4.1-2.5c-0.3-0.4-0.5-0.9-0.7-1.4c-0.3-0.7-0.4-1.5-0.5-2.2c-0.1-1.2-0.1-2.4,0-3.6c0-0.3,0.1-0.6,0.4-0.8
			c0.6-0.4,0.8-1.1,1-1.7c0.2-0.5,0.3-1.1,0.5-1.6c0.3-0.9,0.8-1.6,1.4-2.2c1-0.9,2.1-1.1,3.3-0.9c1.3,0.2,2.2,0.9,2.8,2
			c0.6,0.9,0.9,2,1.2,3.1C890,451.1,890,452.1,890,453.1z"/>
                            <path className="st19" d="M949.2,491.8c0.2-0.2,0.4-0.2,0.6-0.2c1.3,0.3,2.5,0.9,3.4,1.9c0.9,1,1.2,2.2,1,3.5
			c-0.1,0.7-0.3,1.5-0.6,2.1c-0.6,1.1-1.4,1.9-2.7,2.2c-0.7,0.2-1.3,0.1-2,0c-1.5-0.2-2.7-1-3.5-2.4c-0.4-0.7-0.7-1.4-0.9-2.1
			c-0.2-0.9-0.1-1.7,0.4-2.5c0.4-0.6,0.8-1.3,1.3-1.8c0.2-0.2,0.4-0.3,0.6-0.4c0.4,0,0.7-0.2,1.1-0.4c0.2-0.1,0.3-0.2,0.5-0.2
			C948.8,491.5,948.8,491.5,949.2,491.8z"/>
                            <path className="st19" d="M933.7,484.3c-0.1,1.6-0.5,3.1-1.4,4.5c-0.2,0.3-0.4,0.5-0.6,0.7c-0.6,0.7-1.3,0.9-2.2,0.8
			c-1.1-0.1-2-0.6-2.8-1.3c-0.6-0.5-1.1-1.1-1.5-1.8c-0.4-0.6-0.6-1.2-0.7-1.9c-0.1-0.7,0.1-1.4,0.3-2c0.3-0.9,0.8-1.6,1.5-2.2
			c0.4-0.3,0.8-0.5,1.3-0.6c0.4-0.1,0.8-0.2,1.3-0.3c1.5-0.4,2.8,0.2,3.7,1.3C933.4,482.2,933.6,483.3,933.7,484.3z"/>
                            <path className="st18" d="M905.8,431.2c0,0.1,0.1,0.2,0.1,0.3c-0.1,1.8-0.4,3.5-1.2,5.1c-0.7,1.3-1.6,2.3-2.7,3.1
			c-0.4,0.3-0.8,0.6-1.2,0.8c-0.7,0.4-1.4,0.8-2.1,1.2c-0.2,0.1-0.5,0.2-0.8,0.2c-0.4-0.1-0.9-0.2-1.3-0.3c-0.2,0-0.3-0.1-0.5-0.2
			c-0.7-0.5-1.3-1-1.6-1.8c-0.5-1.1-0.2-2.1,0.6-2.8c0.7-0.6,1.4-1,2.2-1.4c0.8-0.3,1.7-0.6,2.6-1c0.9-0.4,1.8-0.7,2.7-1.1
			c0.9-0.4,1.7-1,2.5-1.6c0.1-0.1,0.3-0.3,0.4-0.4C905.6,431.3,905.7,431.2,905.8,431.2z"/>
                            <path className="st19" d="M941.8,466.8c-0.1,0.1-0.2,0.2-0.3,0.2c-1,0.6-1.8,1.3-2.7,2.1c-0.7,0.7-1.4,1.3-2,2.1c-0.5,0.6-1,1.3-1.3,2
			c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0.2-0.2,0.3-0.5,0.3c-0.2,0-0.3,0-0.4,0.1c-1,0.3-2,0-2.9-0.3c-0.4-0.2-0.6-0.4-0.7-0.8
			c-0.3-0.9-0.2-1.7,0.1-2.5c0.5-1.4,1.2-2.5,2.4-3.4c0.4-0.3,0.8-0.6,1.2-0.9c1.1-0.8,2.1-1.6,3.2-2.3c0.1-0.1,0.2-0.1,0.3-0.2
			c0.2-0.1,0.3-0.1,0.5,0c0.1,0.1,0.2,0.2,0.2,0.2c0.9,1,1.8,1.9,2.9,2.6C941.5,466.6,941.6,466.7,941.8,466.8z"/>
                            <path className="st19" d="M950.5,460.6c-1.6,0.1-3-0.5-4.4-1.4c-0.7-0.4-1.2-1.1-1.5-1.9c-0.1-0.5-0.2-1-0.1-1.5
			c0.1-0.7,0.4-1.2,1-1.5c0.4-0.2,0.8-0.4,1.1-0.5c0.2-0.1,0.4-0.1,0.6-0.1c0.4,0,0.8,0.1,1.2,0.1c1.3,0,2.6,0.3,3.8,0.7
			c1.2,0.4,2.1,1.2,2.8,2.2c0.4,0.5,0.4,1.2,0.4,1.8c0,0.2-0.1,0.4-0.3,0.4c-0.4,0-0.8,0.2-1.1,0.5c-0.2,0.1-0.5,0.3-0.7,0.5
			c-0.6,0.5-1.3,0.7-2.1,0.7C951,460.6,950.8,460.6,950.5,460.6z M952.8,457.6c0-0.6-0.5-1.2-1.1-1.2c-0.6,0-1.2,0.5-1.2,1.1
			c0,0.6,0.5,1.2,1.1,1.2C952.2,458.8,952.7,458.3,952.8,457.6z M948.2,457.7c0.6,0,1.2-0.5,1.2-1.1c0-0.6-0.5-1.2-1.2-1.2
			c-0.6,0-1.1,0.5-1.2,1.1C947.1,457.2,947.6,457.7,948.2,457.7z"/>
                            <path className="st18" d="M882.8,443.1c0.1-0.2,0.2-0.4,0.3-0.5c0.6-0.7,1.4-1.1,2.2-1.5c0.8-0.3,1.7-0.5,2.6-0.5
			c1.6,0,3.1,1.1,3.6,2.8c0,0.2,0.1,0.3,0.1,0.5c0.1,1,0.6,1.9,1,2.8c0.1,0.3,0.3,0.6,0.5,0.9c0.1,0.2,0.1,0.4,0.1,0.6
			c-0.1,0.2-0.1,0.5-0.2,0.7c-0.3,1-0.3,2-0.4,3c0,0.5,0,1,0.1,1.5c0.3,1.8,0.7,3.6,1.4,5.3c0.1,0.3,0.3,0.6,0.4,0.9
			c0.1,0.1,0.1,0.3,0.2,0.4c0,0.1-0.1,0.1-0.1,0.2c-0.8,0.5-1.5,1-2.3,1.4c-0.8,0.4-1.7,0.6-2.6,0.8c-0.1,0-0.2,0-0.2,0
			c0,0-0.1,0-0.1,0c-0.1-0.1,0-0.2,0.1-0.3c1.4-1.5,2.2-3.4,2.5-5.5c0.2-1.2,0.3-2.4,0.3-3.6c0-2.4-0.4-4.7-1.6-6.8
			c-0.6-1-1.3-1.9-2.2-2.5c-1.4-0.9-3-1.2-4.7-0.9c-0.2,0-0.5,0.1-0.7,0.2C882.9,443.1,882.9,443.1,882.8,443.1z"/>
                            <path className="st17" d="M977,466.1c1.1,0,2,0.3,2.6,1c0.8,0.8,1,1.9,0.5,2.9c-0.2,0.4-0.4,0.8-0.8,1.1c-0.9,0.9-2,1.5-3.1,2
			c-0.1,0-0.1,0-0.2,0.1c-0.7,0.2-0.8,0.2-1-0.5c-0.3-0.9-0.8-1.8-1.3-2.6c-0.3-0.5-0.7-0.9-1-1.3c-0.2-0.2-0.2-0.3,0-0.5
			c1-0.8,2-1.6,3.2-2C976.4,466.2,976.8,466.1,977,466.1z"/>
                            <path className="st17" d="M965.1,515.4c-0.1-0.1-0.2-0.2-0.2-0.2c-0.1-0.2-0.2-0.5-0.2-0.7c-0.1-0.4-0.1-0.8-0.1-1.1
			c0-1.6,0-3.2,0.1-4.8c0-0.3,0-0.6,0.1-0.9c0.2-1.5,0.4-2.9,0.4-4.4c0-0.1,0-0.3,0.1-0.4c0.2-0.1,0.2,0.1,0.3,0.2
			c0.4,0.8,0.8,1.7,1.1,2.5c0.4,1,0.8,2,1.2,3.1c0.2,0.6,0.5,1.2,0.9,1.7c0.6,0.9,1,1.8,1.3,2.8c0.2,0.5,0.3,1.1,0.5,1.6
			c0.1,0.2,0.1,0.4,0.2,0.6c0,0.1,0.1,0.3,0.1,0.4c0.1,0.6,0,0.8-0.6,1c-0.2,0.1-0.4,0.1-0.7,0.2c-0.1,0-0.2,0-0.2-0.1
			c0-0.1,0-0.1,0-0.1c0.2-0.7,0.2-1.3,0.2-2c0-0.4-0.1-0.7-0.3-1c-0.4-0.8-1.1-1.2-2.1-1.2c-0.9,0-1.4,0.5-1.8,1.3
			c-0.1,0.3-0.2,0.6-0.2,0.9C965.2,515,965.1,515.2,965.1,515.4z"/>
                            <path className="st18" d="M937.6,398.7c0,1.4-0.3,2.8-1,4.1c-0.3,0.6-0.7,1.1-1.2,1.5c-0.5,0.4-1,0.8-1.7,0.9c-0.5,0.1-0.6,0-0.8-0.4
			c-0.1-0.2-0.2-0.5-0.2-0.7c-0.1-0.2,0-0.4,0.2-0.5c1.2-0.6,2.1-1.5,2.9-2.6c0.4-0.6,0.6-1.3,0.6-2.1c0-1.3-0.3-2.5-0.9-3.6
			c-0.5-0.8-1.2-1.4-2.1-1.8c-0.5-0.2-1.1-0.3-1.6-0.3c-1.3,0-2.4,0.4-3.4,1.1c-0.2,0.2-0.4,0.4-0.6,0.6c-0.4,0.5-0.7,1.1-0.6,1.8
			c0,0.1,0,0.2,0,0.3c0,0.2,0,0.4-0.1,0.4c-0.2,0-0.2-0.2-0.3-0.3c-0.2-0.9-0.1-1.8,0.4-2.6c0.6-1,1.5-1.8,2.6-2.3
			c0.4-0.2,0.9-0.3,1.3-0.4c0.4-0.1,0.8-0.1,1.2-0.2c0.4,0,0.9-0.1,1.3,0c0.9,0.2,1.6,0.7,2.2,1.4c0.6,0.7,1,1.5,1.3,2.4
			C937.5,396.5,937.6,397.6,937.6,398.7z"/>
                            <path className="st18" d="M907.4,399.6c0.1-2.9,0.9-5.5,2.6-7.9c0.2-0.2,0.3-0.5,0.5-0.7c0.6-0.7,1.4-1,2.4-1c0.6,0,1.1,0.2,1.5,0.6
			c0.6,0.6,1,1.3,1.2,2.1c0.2,0.6,0.3,1.2,0.4,1.8c0.1,0.4,0.2,0.9,0.2,1.3c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.5,0.1
			c-0.1,0-0.1-0.1-0.1-0.2c-0.1-0.3-0.1-0.6-0.2-0.9c-0.1-0.5-0.3-1-0.6-1.5c-0.4-0.6-0.8-1.1-1.5-1.3c-0.8-0.3-1.5-0.2-2.1,0.4
			c-0.4,0.4-0.7,0.9-0.8,1.4c-0.2,0.5-0.3,1-0.4,1.5c-0.2,0.9-0.4,1.9-0.5,2.8c0,0.5,0,1,0.1,1.5c0.1,0.4,0.3,0.8,0.6,1.1
			c0.1,0.1,0.2,0.3,0.2,0.5c-0.1,0.2-0.2,0.5-0.5,0.6c-1,0.1-1.9-0.2-2.3-1.4c-0.1-0.2-0.1-0.4-0.1-0.6
			C907.4,400.1,907.4,399.9,907.4,399.6z"/>
                            <path className="st19" d="M934.5,398.8c0,0.6-0.2,1.1-0.5,1.5c-0.3,0.4-0.7,0.9-1.1,1.2c-0.2,0.2-0.4,0.3-0.7,0.4
			c-0.2,0.1-0.4,0.1-0.5-0.1c-0.1-0.1-0.1-0.2-0.2-0.3c-0.6-1-1.3-1.9-2.2-2.7c-0.2-0.2-0.4-0.4-0.4-0.7c-0.1-0.3-0.1-0.7-0.2-1
			c-0.1-0.5,0.1-1,0.5-1.3c0.7-0.5,1.5-0.8,2.4-0.8c0.9,0,1.6,0.4,2.1,1.2c0.3,0.5,0.5,1.1,0.6,1.8
			C934.5,398.2,934.5,398.5,934.5,398.8z"/>
                            <path className="st17" d="M972.6,463.8c0,0.4-0.1,0.8-0.3,1.1c-0.3,0.5-0.6,1-1.1,1.4c-0.2,0.2-0.3,0.2-0.5,0
			c-0.1-0.1-0.2-0.2-0.3-0.3c-0.3-0.3-0.6-0.5-0.8-0.8c-0.9-0.8-1.9-1.4-3.1-1.7c-0.6-0.2-0.6-0.3-0.4-0.8c0-0.1,0.1-0.1,0.1-0.2
			c0.6-1.4,1.7-2,3.2-2.2c0.8-0.1,1.5,0.2,2,0.8c0.5,0.6,0.9,1.3,1.1,2.2C972.5,463.6,972.5,463.7,972.6,463.8z"/>
                            <path className="st17" d="M956.4,461.5c0-0.2,0-0.5,0.1-0.7c0.1-0.6,0.3-1,0.7-1.4c0.9-0.8,1.9-1.3,3.1-1.4c1-0.1,1.8,0.2,2.3,1.1
			c0.3,0.5,0.5,1.1,0.6,1.7c0.1,0.4,0.1,0.8,0.1,1.2c0,0.1,0,0.2,0,0.3c0,0.2-0.2,0.3-0.3,0.2c-0.3-0.1-0.6-0.2-0.9-0.3
			c-0.5-0.2-1-0.2-1.5-0.2c-1.1,0-2.3,0.1-3.4,0.3c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0-0.3-0.1-0.3-0.2
			C956.4,462,956.4,461.8,956.4,461.5C956.4,461.5,956.4,461.5,956.4,461.5z"/>
                            <path className="st17" d="M997.7,490.7c-0.2,0-0.4-0.1-0.5-0.2c-0.7-0.3-1.3-0.6-1.9-1c-0.4-0.3-0.4-0.3-0.5-0.8
			c-0.6-0.3-1.1-0.7-1.7-1c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2-0.1-0.2-0.3-0.1-0.4c0.1-0.1,0.1-0.2,0.2-0.3c0.4-0.6,0.9-1.1,1.5-1.5
			c0.8-0.6,1.7-0.7,2.6-0.2c0.2,0.1,0.4,0.2,0.5,0.3c0.3,0.2,0.5,0.5,0.7,0.9c0.1,0.3,0.2,0.5,0.2,0.8c0.1,1.1,0,2.2-0.2,3.3
			C997.9,490.4,997.9,490.6,997.7,490.7z"/>
                            <path className="st17" d="M977.6,475.1c0.7,0,1.4,0.1,2.1,0.4c0.4,0.2,0.8,0.5,1,0.8c0.5,0.6,0.5,1.4,0.1,2c-0.3,0.5-0.8,0.8-1.2,1.1
			c-0.7,0.4-1.5,0.7-2.3,0.9c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2-0.1-0.3-0.2c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.9-0.2-1.7-0.3-2.6
			c-0.1-0.5-0.2-0.9-0.3-1.4c0-0.1,0-0.2,0-0.3c0-0.1,0.1-0.3,0.2-0.3c0.2,0,0.4-0.1,0.7-0.1C977.1,475.1,977.3,475.1,977.6,475.1z"
                            />
                            <path className="st17" d="M1011.3,500.2c-0.1-0.1-0.2-0.1-0.2-0.2c-1-1-2.1-1.9-3.2-2.7c-0.3-0.2-0.5-0.4-0.8-0.6
			c-0.3-0.3-0.3-0.4-0.2-0.7c0.6-1,1.5-1.6,2.6-1.7c0.8-0.1,1.5,0.2,2,0.9c0.4,0.5,0.6,1,0.7,1.6c0.1,0.8,0.1,1.6-0.2,2.4
			c-0.1,0.3-0.3,0.7-0.5,0.9C1011.4,500.2,1011.4,500.2,1011.3,500.2z"/>
                            <path className="st17" d="M981.8,486c-1.6,0-3-0.5-4.2-1.5c-0.4-0.3-0.6-0.6-0.6-1.1c0-0.2,0-0.5-0.1-0.7c0-0.3,0.1-0.4,0.4-0.5
			c0,0,0,0,0.1,0c0.5,0.1,1-0.2,1.4-0.3c0.6-0.2,1.2-0.3,1.8-0.3c0.9,0,1.4,0.3,1.8,1.2c0.2,0.5,0.4,1.1,0.4,1.7
			c0,0.4-0.1,0.9-0.2,1.3c0,0.2-0.1,0.3-0.3,0.3C982.1,486,981.9,486,981.8,486z"/>
                            <path className="st17" d="M1017.1,503.9c0,0.8-0.1,1.5-0.5,2.2c-0.1,0.2-0.3,0.4-0.5,0.6c-0.1,0.1-0.3,0.1-0.4,0
			c-0.1-0.1-0.1-0.2-0.2-0.3c-0.8-1.4-1.8-2.8-2.8-4.1c-0.3-0.4-0.3-0.6,0.1-0.8c0.5-0.3,1-0.5,1.6-0.6c0.9-0.1,1.6,0.2,2.1,1
			C1017,502.4,1017.1,503.1,1017.1,503.9z"/>
                            <path className="st17" d="M985.8,486c-0.2,0-0.5,0-0.8,0c-0.1,0-0.2,0-0.2,0c-0.2,0-0.3-0.1-0.2-0.4c0.1-0.2,0.1-0.3,0.2-0.5
			c0.4-0.7,0.9-1.2,1.5-1.7c0.5-0.4,1.1-0.7,1.8-0.7c0.8-0.1,1.4,0.2,1.8,0.8c0.4,0.5,0.5,1.1,0.6,1.8c0,0.2,0,0.5,0,0.7
			c0,0.3-0.1,0.4-0.4,0.4c-0.2,0-0.4-0.1-0.6-0.1C988.2,486.2,987,486,985.8,486z"/>
                            <path className="st17" d="M1000,491.8c0-0.1,0-0.1,0-0.1c0.5-0.5,1.1-1,1.8-1.1c1.1-0.3,2.3,0.4,2.6,1.5c0.2,0.7,0.3,1.5,0.2,2.2
			c0,0.1,0,0.1-0.1,0.2c0,0-0.1,0.1-0.1,0.1c0,0,0,0-0.1,0c-0.1,0-0.1,0-0.2-0.1C1003,493.5,1001.5,492.7,1000,491.8z"/>
                            <path className="st17" d="M1025.2,518.7c-0.1-0.1-0.2-0.1-0.3-0.1c-0.7-0.6-1.5-1.1-2.2-1.7c-0.4-0.3-0.8-0.8-1.1-1.2
			c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.2,0-0.3,0.2-0.4c0.3-0.1,0.6-0.2,0.9-0.2c0.4-0.1,0.7-0.1,1.1-0.1c0.6,0,1.1,0.3,1.4,0.9
			c0.1,0.2,0.2,0.3,0.2,0.5c0.2,0.8,0.3,1.6,0.2,2.4C1025.4,518.5,1025.3,518.6,1025.2,518.7z"/>
                            <path className="st19" d="M911.4,398.8c0-0.4,0.1-0.9,0.1-1.3c0.1-0.8,0.3-1.6,0.5-2.4c0.1-0.2,0.1-0.5,0.2-0.7
			c0.2-0.4,0.5-0.4,0.8-0.2c0.2,0.1,0.3,0.3,0.4,0.5c0.4,0.7,0.5,1.4,0.7,2.1c0,0.2,0,0.4-0.2,0.6c-0.7,0.6-1.3,1.2-1.9,1.9
			c-0.1,0.1-0.2,0.2-0.4,0.2c-0.2-0.1-0.2-0.3-0.2-0.4C911.4,399.1,911.4,399,911.4,398.8C911.4,398.8,911.4,398.8,911.4,398.8z"/>
                            <path className="st17" d="M1017.1,508.8c0.1,0,0.1-0.1,0.2-0.1c0.8-0.1,1.6,0,2.3,0.3c0.8,0.4,1.3,1.1,1.3,2c0,0.3,0,0.6,0,1
			c0,0.3-0.1,0.6-0.3,0.8c-0.2,0.2-0.3,0.2-0.5,0c0,0,0-0.1-0.1-0.1c-0.9-1.2-1.8-2.3-2.6-3.5
			C1017.2,509.2,1017.1,509.1,1017.1,508.8z"/>
                            <path className="st17" d="M968.1,517c-0.1,0-0.2,0-0.2,0c-0.5-0.1-0.9-0.3-1.4-0.5c-0.1-0.1-0.2-0.2-0.2-0.4c0-0.6,0-1.2,0.2-1.7
			c0.2-0.4,0.5-0.6,0.9-0.5c0.3,0.1,0.5,0.2,0.6,0.5c0.1,0.2,0.1,0.4,0.1,0.6C968.1,515.6,968.1,516.2,968.1,517
			C968.2,516.9,968.1,516.9,968.1,517z"/>
                            <path className="st17" d="M998.9,518.8c0,0.1,0,0.1,0,0.2c0,0.3-0.1,0.4-0.4,0.3c-0.3-0.1-0.7-0.2-1-0.3c-0.2-0.1-0.3-0.2-0.3-0.4
			c0-0.1,0-0.1-0.1-0.2c-0.2-0.6-0.2-1.2,0-1.8c0.2-0.6,0.6-0.7,1-0.2c0.2,0.2,0.3,0.5,0.4,0.7C998.8,517.7,998.8,518.3,998.9,518.8
			z"/>
                            <path className="st17" d="M976.4,519.3c0.1-0.3,0.1-0.7,0.2-1c0.1-0.3,0.4-0.4,0.6-0.2c0.2,0.1,0.4,0.3,0.5,0.6
			c0.1,0.4,0.2,0.8,0.2,1.2c0,0.2-0.1,0.3-0.3,0.3c-0.3,0-0.6-0.1-0.8-0.1c-0.2,0-0.3-0.2-0.3-0.4
			C976.4,519.5,976.4,519.4,976.4,519.3C976.4,519.3,976.4,519.3,976.4,519.3z"/>
                            <path className="st17" d="M982.7,519.7c0-0.1-0.1-0.2-0.1-0.2c0-0.7-0.1-1.4-0.1-2.1c0-0.2,0-0.3,0.2-0.4c0.2-0.1,0.3,0.1,0.4,0.2
			c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.3,0.3,0.7,0.4,1c0,0.1,0,0.3,0,0.4C983.7,519.2,983.2,519.6,982.7,519.7z"/>
                            <path className="st17" d="M1001.9,518.9c0-0.1-0.1-0.2-0.1-0.2c0-0.5,0-1,0-1.5c0-0.1,0-0.2,0.1-0.3c0-0.2,0.2-0.3,0.3-0.3
			c0.3-0.1,0.6,0,0.9,0.1c0.2,0.1,0.2,0.2,0.1,0.4c-0.3,0.6-0.6,1.2-1.1,1.7C1002,518.9,1002,518.9,1001.9,518.9z"/>
                            <path className="st15" d="M924.8,413.2c0.1,0.3,0.1,0.6,0.1,0.8c0,0.3-0.3,0.5-0.6,0.5c-0.1,0-0.3,0-0.5-0.1c-0.1-0.1-0.1-0.3-0.1-0.4
			c0-0.1-0.1-0.2-0.2-0.3c0.1-0.2,0.2-0.4,0.3-0.6C924.1,413.2,924.4,413.2,924.8,413.2z"/>
                            <path className="st15" d="M914.2,410.4c0,0.3,0,0.5,0,0.8c0,0.2-0.2,0.4-0.4,0.4c-0.1,0-0.2,0-0.2,0c-0.1-0.1-0.3-0.3-0.4-0.4
			c-0.2-0.1-0.2-0.3-0.1-0.5c0.1-0.2,0.2-0.4,0.5-0.4C913.8,410.3,913.9,410.3,914.2,410.4z"/>
                            <path d="M957.7,472.4c0.3,0.1,0.7,0.1,1,0.2c0.9,0.3,1.2,1.3,0.5,2c-0.4,0.5-1,0.6-1.6,0.3c-0.6-0.3-1-1-0.9-1.6
			C956.8,472.8,957.2,472.4,957.7,472.4z"/>
                            <path d="M951.3,480.7c0.5,0,0.9,0.5,0.8,1c-0.1,0.5-0.6,0.8-1,0.7c-0.4-0.1-0.7-0.7-0.5-1.2C950.7,480.9,951,480.7,951.3,480.7z"
                            />
                            <path d="M947.9,481.2c0-0.4,0.3-0.7,0.7-0.7c0.4,0,0.8,0.4,0.8,0.8c0,0.4-0.4,0.7-0.8,0.7C948.3,482,947.9,481.6,947.9,481.2z"/>
                            <path d="M969.7,442.7c0,0.4-0.1,0.7-0.2,1.1c-0.4,1.2-1.1,2.2-1.9,3.2c-0.2,0.2-0.4,0.4-0.7,0.6c-0.2,0.1-0.4,0.1-0.7,0
			c-0.4-0.2-0.6-0.6-0.4-1c0.1-0.2,0.2-0.3,0.3-0.5c0.5-0.7,0.9-1.4,1.3-2.2c0.1-0.2,0.2-0.4,0.2-0.6c0.2-0.5,0-1-0.2-1.5
			c-0.1-0.2-0.3-0.4-0.6-0.3c-0.4,0-0.7,0.1-1.1,0.1c-0.8,0.1-1.6,0.4-2.3,0.6c-0.1,0.1-0.3,0.1-0.4,0.1c-0.4,0-0.6-0.2-0.5-0.6
			c0-0.2,0.1-0.3,0.2-0.4c0.2-0.2,0.5-0.4,0.7-0.6c1-0.6,2-0.9,3.1-1.1c0.2,0,0.4,0,0.6,0c0.7,0,1.3,0.3,1.7,0.9
			C969.4,441.1,969.7,441.9,969.7,442.7z"/>
                            <path d="M946,437.2c-0.1-0.2-0.2-0.3-0.3-0.5c0,0,0-0.1,0-0.1c0.2-0.6,0.2-0.7-0.1-1.3c-0.1-0.1-0.1-0.3-0.2-0.4
			c-0.3-0.3-0.7-0.4-1-0.1c-0.2,0.1-0.3,0.3-0.3,0.5c-0.1,0.3-0.1,0.6-0.1,1c0,0.2,0.1,0.4,0.1,0.6c0,0.1,0,0.3-0.1,0.4
			c-0.1,0.2-0.2,0.4-0.5,0.5c-0.3,0.1-0.5-0.1-0.7-0.3c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.6-0.1-1.2-0.1-1.8c0-0.5,0.1-0.9,0.4-1.4
			c0.5-0.8,1.6-1.2,2.5-1c0.5,0.1,0.8,0.4,1.1,0.7c0.3,0.4,0.6,0.8,0.7,1.2c0.1,0.4,0.1,0.8,0.1,1.2
			C947.2,437,946.8,437.3,946,437.2z"/>
                            <path d="M947.5,446.8c0.1,0,0.3,0.1,0.5,0.1c0.8,0.3,1.4,1.1,1.5,1.9c0.1,1-0.7,1.7-1.7,1.7c-0.3,0-0.6-0.1-0.9-0.3
			c-0.7-0.5-1.1-1.1-1.1-1.9c0-0.6,0.2-1,0.7-1.3C946.7,446.9,947.2,446.8,947.5,446.8z"/>
                            <path d="M956.5,451.5c0-0.9,0.7-1.7,1.6-1.7c0.8,0,1.6,0.6,1.9,1.4c0.2,0.8-0.1,1.6-0.9,2c-0.5,0.3-1,0.3-1.4,0.1
			C957,453,956.5,452.3,956.5,451.5z"/>
                            <path d="M914.1,428c-0.1,0-0.2,0-0.3,0c-0.6,0.2-1.2,0.2-1.9,0.3c-0.3,0-0.6,0-0.9-0.1c-1.2-0.2-1.9-1-2.4-2.1
			c-0.2-0.4-0.1-0.8,0.2-1.2c0.1-0.1,0.3-0.3,0.4-0.3c0.5-0.2,1,0.1,1.1,0.7c0.1,0.7,0.5,1.1,1.2,1.2c0.5,0.1,0.9,0,1.4,0
			c0.3,0,0.5-0.2,0.5-0.5c0-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.3-0.3-0.4c-0.3-0.1-0.7-0.2-1-0.3c-0.4-0.2-0.8-0.4-1.2-0.7
			c-1.2-1-1.6-2.8-0.9-4.2c0.3-0.7,0.9-1.2,1.6-1.5c0.5-0.2,1.1-0.3,1.7-0.4c0.7-0.1,1.3-0.1,2-0.2c0.5,0,1,0,1.5,0.2
			c1.1,0.3,2,0.8,2.8,1.5c0.2,0.2,0.4,0.4,0.6,0.6c1.5,1.8,0.8,4.1-1.2,5c-0.5,0.2-1.1,0.3-1.7,0.4c-0.6,0-1.1,0-1.7,0
			c-0.6,0-0.6,0-0.5,0.6c0.1,0.4,0.2,0.7,0.3,1.1c0.1,0.4,0.4,0.6,0.9,0.8c0.7,0.2,1.3,0.1,1.9-0.2c0.3-0.1,0.5-0.3,0.7-0.6
			c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.2,0.3-0.3c0.4-0.3,0.8-0.2,1,0.3c0,0.2,0.1,0.3,0.1,0.5c0,0.9-0.4,1.5-1.2,2
			c-0.4,0.3-0.9,0.5-1.4,0.5c-0.8,0.1-1.6,0.1-2.4-0.2c-0.6-0.2-1-0.6-1.3-1.1C914.2,428.2,914.2,428.1,914.1,428z M916.3,423.4
			c0.5,0,0.9,0,1.4,0c0.4,0,0.8-0.1,1.1-0.4c0.5-0.4,0.6-1,0.3-1.5c-0.1-0.2-0.3-0.5-0.6-0.6c-0.3-0.2-0.6-0.4-0.9-0.6
			c-0.6-0.4-1.3-0.6-2.1-0.6c-0.4,0-0.7,0-1.1-0.1c-0.7-0.1-1.3,0.1-1.9,0.4c-0.5,0.3-0.8,0.8-0.7,1.4c0,0.5,0.3,0.9,0.7,1.2
			c0.4,0.2,0.8,0.4,1.2,0.5C914.6,423.4,915.5,423.5,916.3,423.4z"/>
                            <path d="M952.8,457.6c0,0.7-0.5,1.2-1.1,1.2c-0.6,0-1.1-0.6-1.1-1.2c0-0.6,0.6-1.2,1.2-1.1C952.3,456.5,952.8,457,952.8,457.6z"/>
                            <path d="M948.2,457.7c-0.6,0-1.2-0.5-1.2-1.2c0-0.6,0.6-1.1,1.2-1.1c0.6,0,1.2,0.6,1.2,1.2C949.4,457.3,948.9,457.7,948.2,457.7z"
                            />
                            <path className="st18" d="M916.3,423.4c-0.9,0-1.7,0-2.6-0.2c-0.4-0.1-0.8-0.2-1.2-0.5c-0.4-0.3-0.7-0.7-0.7-1.2
			c0-0.6,0.2-1.1,0.7-1.4c0.6-0.3,1.2-0.5,1.9-0.4c0.4,0.1,0.7,0.1,1.1,0.1c0.7,0,1.4,0.2,2.1,0.6c0.3,0.2,0.6,0.4,0.9,0.6
			c0.2,0.2,0.4,0.4,0.6,0.6c0.3,0.5,0.2,1.1-0.3,1.5c-0.3,0.3-0.7,0.4-1.1,0.4C917.3,423.4,916.8,423.4,916.3,423.4z"/>
	</g>
                        <g className="bloques white">
		<path d="M864.9,678.2c0,0.1-0.1,0.2-0.1,0.3c-0.1,1-0.2,1.9-0.2,2.9c0,0.4-0.1,0.8-0.1,1.3c0,0.2-0.1,0.4-0.2,0.6
			c-0.1,0.3-0.3,0.4-0.5,0.5c-0.2,0-0.4,0.1-0.6,0.1c-1.3,0.1-2.7,0.2-4.1,0.2c-0.8,0-1.6,0.1-2.5,0.1c-0.6,0-1.1,0.1-1.7,0.1
			c-1.3,0-2.6,0-3.9,0.1c-2.1,0.1-4.1,0.1-6.2,0.2c-2.2,0.1-4.4,0.1-6.6,0.2c-1.2,0-2.3,0-3.5,0.1c-0.7,0-1.4,0-2.1,0.1
			c-0.7,0.1-1.3-0.2-1.9-0.7c-1-0.9-1.9-1.9-2.6-3.1c-0.2-0.3-0.4-0.6-0.5-0.9c-0.1-0.1-0.1-0.2-0.3-0.2c0,0.1,0,0.1-0.1,0.2
			c0,0.1,0,0.2,0,0.3c0,1.2-0.1,2.5-0.3,3.7c0,0.3-0.1,0.6-0.3,0.9c-0.1,0.3-0.3,0.5-0.7,0.5c-0.3,0-0.6-0.1-0.7-0.4
			c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.1-0.2-0.3-0.2c-0.3,0-0.5,0-0.8,0c-1.3,0.1-2.5,0.1-3.8,0.1c-0.8,0-1.5,0-2.3,0
			c-3.1,0-6.1-0.1-9.2-0.1c-2.1,0-4.3-0.1-6.4-0.1c-1.3,0-2.5-0.1-3.8-0.1c-0.3,0-0.6,0-0.9,0.1c-0.4,0.1-0.7,0-1-0.2
			c-0.2-0.1-0.3-0.2-0.5-0.3c-0.8-0.6-1.5-1.3-2.1-2.1c-0.9-1.3-1.9-2.6-2.9-3.8c-0.9-1.1-1.6-2.2-2.3-3.4c-0.8-1.4-1.7-2.7-2.8-3.9
			c-0.3-0.3-0.5-0.6-0.7-0.9c-0.1-0.2-0.1-0.3-0.1-0.5c0-0.3,0-0.6,0.3-0.8c0.2-0.2,0.3-0.4,0.3-0.7c0-2.9,0-5.8,0-8.7
			c0-0.6,0-1.1,0-1.7c0-2.3,0.1-4.5,0.1-6.8c0-0.8,0.1-1.5,0-2.3c-0.1-0.5,0.2-0.9,0.7-1c0.2,0,0.4-0.1,0.7-0.1
			c0.3,0,0.4-0.1,0.4-0.4c0-1.4,0-2.7,0-4.1c0-2.3,0.1-4.7,0.1-7c0-0.3,0-0.6,0.2-0.9c0.1-0.3,0.3-0.5,0.6-0.6
			c0.2-0.1,0.3-0.2,0.4-0.3c0.2-0.2,0.4-0.3,0.6-0.2c0.7,0.1,1.5,0.1,2.2,0.1c1.4,0,2.8,0,4.2,0c0.6,0,1.3,0,1.9,0
			c0.1,0,0.2,0,0.3,0c0.2,0,0.3-0.1,0.3-0.3c0-0.1,0-0.1,0-0.2c0.1-1.2,0.2-2.5,0.2-3.7c0-0.4,0-0.8,0-1.3c0-1,0-2,0.1-2.9
			c0.1-0.9,0.1-1.9,0.2-2.8c0-0.2,0-0.5,0-0.7c-0.1-0.5,0.1-0.7,0.5-0.9c0.4-0.2,0.9-0.2,1.3-0.2c0.8,0,1.6,0.1,2.4,0.1
			c1,0.1,2,0.1,3-0.1c0.7-0.1,1.4-0.2,2.2-0.3c0.8-0.1,1.7-0.1,2.5-0.2c1.2-0.1,2.5-0.1,3.7-0.2c1.1-0.1,2.1-0.2,3.2-0.2
			c0.7,0,1.4,0,2.1,0c0.2,0,0.4,0.1,0.5-0.1c0.1-0.2-0.1-0.3-0.2-0.4c-0.2-0.2-0.4-0.4-0.6-0.7c-0.1-0.1-0.2-0.3-0.3-0.5
			c-0.1-0.2-0.1-0.5,0.1-0.7c0.2-0.3,0.3-0.6,0.2-1c-0.1-1.3-0.1-2.6-0.2-3.9c-0.1-0.7-0.1-1.4-0.1-2.2c-0.2-2-0.1-3.9-0.1-5.9
			c0-1.6-0.1-3.1-0.1-4.7c0-0.3,0-0.6,0-0.9c0-0.3,0-0.6,0-0.8c0-0.4,0.1-0.6,0.4-0.8c0.4-0.2,0.9-0.4,1.3-0.5
			c0.8-0.2,1.6-0.4,2.4-0.5c0.3-0.1,0.7-0.1,1-0.1c1.4,0,2.9-0.2,4.3-0.3c0.6,0,1.2-0.2,1.8-0.2c2.1-0.2,4.3-0.3,6.5-0.3
			c1.2,0,2.4,0,3.5,0.2c0.5,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0.1,1.2,0.1c0.2,0,0.4,0.1,0.6,0.1c0.8,0,1.6,0.2,2.3,0.5
			c1.5,0.6,3,1.1,4.5,1.7c1.4,0.5,2.6,1.2,3.8,2c0.4,0.3,0.7,0.6,1.1,0.9c0.3,0.3,0.6,0.5,1,0.7c0.3,0.2,0.6,0.5,0.7,0.9
			c0.1,0.4,0.2,0.8,0.2,1.2c0,2.4-0.1,4.8-0.1,7.2c-0.1,2.2-0.2,4.4-0.2,6.6c0,0.8,0,1.7-0.1,2.5c0,0.6,0,0.6,0.6,0.8
			c0.4,0.1,0.8,0.1,1.2,0.1c0.6,0,1.1,0.1,1.6,0.1c0.3,0,0.5,0,0.8,0c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0.2,0.6,0.4
			c0.1,0.1,0.2,0.3,0.4,0.4c0.8,0.6,1.5,1.3,2.3,2c0.4,0.3,0.8,0.7,1.1,1c0.6,0.5,1.4,0.9,2.1,1.2c0.9,0.4,1.7,0.9,2.5,1.5
			c0.3,0.2,0.7,0.4,1.1,0.6c0.2,0.1,0.4,0.1,0.6,0.1c0.7-0.1,1.4,0,2.1,0.3c0.3,0.1,0.5,0.3,0.7,0.4c0.2,0.1,0.4,0.3,0.6,0.5
			c0.7,0.9,1.5,1.6,2.3,2.3c0.8,0.7,1.5,1.3,2.3,1.9c0.5,0.3,0.9,0.7,1.3,1.1c0.5,0.5,1,1,1.6,1.4c0.7,0.6,1.3,1.2,1.9,1.9
			c0.2,0.3,0.4,0.6,0.6,0.9c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.2,0.2,0.5,0.2,0.8c0,0.2,0,0.5,0,0.7
			c0,1.1,0,2.2,0,3.3c0,0.4,0.1,0.8,0.2,1.2c0.1,0.2,0.2,0.4,0.4,0.4c0.3,0.1,0.6,0.2,0.8,0.4c0.1,0.1,0.3,0.2,0.4,0.3
			c0.5,0.3,0.9,0.7,1.3,1.1c0.8,0.9,1.7,1.8,2.6,2.6c1,0.9,1.9,1.8,2.8,2.8c0.5,0.6,1,1.1,1.6,1.6c1,0.8,2,1.7,2.8,2.7
			c0.2,0.3,0.5,0.5,0.9,0.5c0.6,0.1,1,0.5,1.1,1.1c0.1,0.4,0.1,0.8,0.1,1.2c0.1,1.6,0.1,3.1,0.2,4.7c0.1,1.3,0.1,2.5,0.2,3.8
			c0,0.9,0,1.8,0.1,2.6c0,1.2,0.1,2.4,0.1,3.5c0,0,0,0.1,0,0.1c0,1.6,0.1,3.3,0.1,4.9c0,0.2,0,0.5-0.1,0.7c-0.1,0.5-0.5,0.8-1,1
			c-0.1,0-0.3,0-0.4,0c-0.2,0-0.3,0-0.5,0c-1.5,0-3.1,0.1-4.6,0.1c-1.3,0-2.7,0.1-4,0.1c-1.2,0-2.3,0-3.5,0.1
			c-2.3,0-4.6,0.1-6.9,0.1c-2.3,0-4.5,0-6.8,0.1c-0.3,0-0.6,0-0.9,0c-0.5,0.1-0.9,0-1.3-0.2c-0.5-0.2-1-0.3-1.5-0.4
			c-0.8-0.1-1.6-0.4-2.3-0.9c-0.6-0.5-1.2-1-1.7-1.6c-0.4-0.5-0.8-1-1.2-1.4C865.3,678.4,865.2,678.2,864.9,678.2z M849.2,660.2
			c-0.8,0-1.5-0.1-2.1-0.1c-1.7,0-3.4-0.1-5.1-0.1c-1.2,0-2.4,0-3.5,0c-1,0-2.1-0.1-3.1-0.1c-0.4,0-0.8,0-1.2,0
			c-0.2,0-0.3,0.1-0.3,0.3c0,0.9,0.1,1.8,0.1,2.6c0,0.7,0,1.4,0,2c0,3.2-0.1,6.4-0.2,9.5c-0.1,1.3-0.1,2.7-0.1,4c0,0.3,0,0.6,0,0.9
			c0,1.1-0.1,2.3-0.1,3.4c0,0.5,0,0.5,0.5,0.5c1.2,0,2.4-0.1,3.6-0.1c0.1,0,0.2,0,0.3,0c1.7-0.1,3.3-0.2,5-0.2
			c0.5,0,1.1-0.1,1.6-0.1c0.9,0,1.7-0.1,2.6-0.1c1.3,0,2.6-0.1,3.9-0.1c1.8-0.1,3.5-0.2,5.3-0.2c0.3,0,0.6,0,0.9,0
			c1.7-0.1,3.4-0.1,5.1-0.2c0.1,0,0.3,0,0.4,0c0.1,0,0.2-0.1,0.2-0.2c0-0.1,0-0.2,0-0.3c0-0.3,0-0.5,0-0.8c0.1-1.5,0.1-2.9,0.2-4.4
			c0-0.3,0-0.5-0.2-0.7c0-0.1-0.1-0.1-0.1-0.2c-0.3-0.4-0.2-0.8,0.2-1.1c0,0,0.1,0,0.1-0.1c0.1-0.1,0.2-0.2,0.2-0.3
			c0-0.1,0-0.2,0-0.3c0.1-1.3,0.1-2.5,0.2-3.8c0.1-1.5,0.2-3,0.3-4.4c0.1-1.6,0.1-3.1,0.3-4.7c0-0.4-0.1-0.5-0.5-0.5
			c-0.6,0-1.3,0-1.9,0c-2,0-4-0.1-6-0.1C853.4,660.2,851.2,660.2,849.2,660.2z M825.4,683.7c0.1-0.1,0.1-0.2,0.1-0.3
			c0-0.2,0-0.3,0.1-0.5c0.1-1.7,0.2-3.4,0.2-5c0-0.5,0-1-0.3-1.4c-0.1-0.2-0.1-0.5,0-0.7c0.2-0.2,0.2-0.5,0.2-0.8c0-1.7,0-3.3,0.1-5
			c0-1.1,0-2.3,0-3.4c0-1.9,0-3.8,0.2-5.7c0-0.3,0-0.5,0.1-0.8c0-0.3-0.1-0.3-0.3-0.4c-0.1,0-0.3,0-0.4,0c-0.7,0-1.3,0-2,0
			c-1.4,0-2.9,0-4.3,0c-3.4,0-6.8,0-10.2,0.1c-2.5,0-5,0.1-7.5,0.1c-1.2,0-2.3,0-3.5,0c-0.6,0-0.6,0.1-0.6,0.6
			c0.1,1.4,0.2,2.7,0.3,4.1c0.1,1.8,0.2,3.6,0.3,5.5c0,0.6,0.1,1.3,0.1,1.9c0.1,1,0.1,2,0.2,3c0,0.6,0.1,1.3,0.1,1.9
			c0,0.8,0.1,1.7,0.2,2.5c0,0.8,0.1,1.6,0.1,2.4c0,0.5,0.1,0.6,0.6,0.6c0.8,0,1.7,0,2.5,0c0.9,0,1.7,0,2.6,0
			c2.6,0.1,5.1,0.1,7.7,0.2c1.8,0,3.7,0.1,5.5,0.1c1.8,0,3.6,0.2,5.4,0.2c0.4,0,0.8,0.1,1.2,0.1C824.5,683.4,825,683.5,825.4,683.7z
			 M886.6,680.6C886.6,680.6,886.6,680.6,886.6,680.6c1.1,0,2.2,0,3.4-0.1c3.2,0,6.4-0.1,9.5-0.1c0.6,0,0.6-0.1,0.6-0.6
			c0-1-0.1-2-0.1-3c0-0.1,0-0.2,0-0.3c-0.1-2-0.2-4-0.2-6c0-0.3,0-0.6,0-0.9c0-0.9-0.1-1.8-0.1-2.7c0-1.4-0.1-2.8-0.1-4.3
			c0-0.4,0-0.8,0-1.1c0-0.7-0.1-0.8-0.8-0.7c-0.7,0-1.5,0-2.2,0c-6,0-11.9,0-17.9,0c-1.8,0-3.5,0-5.3,0c-0.5,0-0.6,0.1-0.6,0.6
			c0.1,1.6,0.2,3.3,0.2,4.9c0,1,0.1,1.9,0.1,2.9c0,2,0.1,3.9,0.1,5.9c0,1.2,0,2.5,0,3.7c0,0.5,0,1.1,0,1.6c0,0.1,0,0.2,0,0.2
			c0,0.2,0.1,0.3,0.4,0.4c0.1,0,0.2,0,0.3,0c1.1,0,2.1-0.1,3.2-0.1c1.3,0,2.6-0.1,3.9-0.1C882.8,680.6,884.7,680.6,886.6,680.6z
			 M842,624.2C842,624.2,842,624.2,842,624.2c0.1,0,0.2,0,0.3,0c1.9,0,3.8-0.1,5.8-0.1c2,0,4,0,6,0c0.1,0,0.3,0,0.4,0
			c0.2,0,0.3-0.1,0.3-0.3c0-0.2,0-0.3,0-0.5c0-1.1,0-2.3,0.1-3.4c0-1.7,0.1-3.4,0.1-5.1c0-0.8,0-1.5,0-2.3c0-0.9,0-1.7,0-2.6
			c0-1.5,0.1-3,0.1-4.4c0-0.1,0-0.2,0-0.3c0-0.2-0.1-0.3-0.3-0.3c-0.2,0-0.3,0-0.5,0c-2.4,0-4.8,0-7.3,0.1c-0.7,0-1.4,0-2.2,0
			c-2,0-4,0.1-6,0.1c-3.1,0.1-6.3,0.1-9.4,0.1c-0.2,0-0.3,0-0.5,0c-0.2,0-0.3,0.1-0.3,0.3c0,0.1,0,0.3,0,0.4c0,0.6,0.1,1.2,0.1,1.8
			c0,0.5,0,1,0,1.6c0,1.1,0.1,2.2,0.1,3.3c0,2,0.1,3.9,0.1,5.9c0,1.8,0,3.6,0,5.4c0,0.5,0.1,0.6,0.6,0.6c0.6,0,1.2-0.1,1.8-0.1
			c2.1,0,4.3-0.1,6.4-0.1C839.4,624.2,840.7,624.2,842,624.2z M860.9,632.6C860.9,632.5,860.9,632.5,860.9,632.6
			c-2.1,0-4.2,0-6.3,0.1c-1.7,0-3.5,0.1-5.2,0.1c-0.5,0-0.6,0.1-0.6,0.6c0,1.5-0.1,3-0.1,4.5c0,1.3-0.1,2.6-0.1,4c0,0.1,0,0.3,0,0.4
			c0,0.9-0.1,1.9-0.1,2.8c0,2-0.1,4.1-0.3,6.1c0,0.2,0,0.3,0,0.5c0,0.2,0.1,0.3,0.4,0.4c0.1,0,0.2,0,0.3,0c1.4,0,2.8,0,4.2,0
			c1.2,0,2.5,0,3.7,0c0.2,0,0.5,0,0.7-0.1c0.2-0.1,0.4-0.1,0.6,0c0.2,0.1,0.4,0.1,0.6,0.1c2.8,0,5.6,0.1,8.4,0.1c1,0,2,0,3,0.1
			c1,0,2.1,0,3.1,0c0.4,0,0.4-0.1,0.5-0.4c0-0.1,0-0.2,0-0.3c0-0.8,0-1.6-0.1-2.4c0-1.1-0.1-2.3-0.1-3.4c0-1.8-0.1-3.6-0.1-5.4
			c0-1.6-0.1-3.2-0.1-4.8c0-0.9-0.1-1.8-0.1-2.6c0-0.1,0-0.1,0-0.2c0-0.2-0.1-0.3-0.3-0.3c-0.2,0-0.4,0-0.6,0
			c-1.7,0-3.4,0.1-5.1,0.1C865,632.5,862.9,632.5,860.9,632.6z M813.8,642.5C813.8,642.5,813.8,642.5,813.8,642.5
			c0,1.1-0.1,2.1-0.1,3.2c0,1.9-0.1,3.7-0.1,5.6c0,0.1,0,0.2,0,0.3c0,0.3,0.1,0.4,0.4,0.4c0.3,0,0.7,0,1-0.1
			c0.8-0.1,1.6-0.1,2.5-0.2c0.7,0,1.4-0.1,2.1-0.1c1.2-0.1,2.3-0.2,3.5-0.2c0.2,0,0.4,0,0.7,0c1.7-0.1,3.5-0.2,5.2-0.2
			c0.3,0,0.6,0,0.8,0c1.7-0.1,3.4-0.2,5-0.2c1,0,2-0.1,2.9-0.1c0.1,0,0.2,0,0.2,0c0.2,0,0.3-0.1,0.4-0.4c0-0.2,0-0.3,0-0.5
			c0-1,0.1-2,0.1-3c0-0.9,0.1-1.9,0.2-2.8c0-0.3,0-0.6,0.1-0.9c0.1-1.3,0.2-2.6,0.2-4c0.1-1.1,0.1-2.1,0.2-3.2
			c0.1-0.9,0.1-1.8,0.2-2.7c0-0.1,0-0.2,0-0.3c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.2,0-0.3,0c-0.5,0-1.1,0-1.6,0.1c-2.7,0-5.4,0.1-8,0.1
			c-1.3,0-2.6,0-3.9,0.1c-2.3,0-4.6,0.1-6.9,0.1c-1.2,0-2.4,0-3.5,0c-0.1,0-0.3,0-0.4,0c-0.3,0-0.4,0.1-0.4,0.5c0,0.1,0,0.2,0,0.3
			c0,0.8,0,1.6,0,2.5c0,0.7,0,1.4,0,2.2C813.8,639.8,813.8,641.2,813.8,642.5z M790.3,649.7c0,0.2,0.2,0.3,0.3,0.5
			c0.7,0.8,1.2,1.6,1.6,2.5c0.4,0.8,0.9,1.7,1.5,2.4c0.7,1,1.4,1.9,2.2,2.9c0,0.1,0.1,0.1,0.1,0.1c0.1,0.1,0.3,0.2,0.5,0.2
			c0.4,0,0.8-0.1,1.2-0.1c2.3,0,4.7-0.1,7-0.1c3.1,0,6.1-0.1,9.2-0.1c2.7,0,5.4-0.1,8.1-0.1c0.9,0,1.8,0,2.7,0c0.1,0,0.3,0,0.4,0
			c0.2,0,0.2-0.1,0.1-0.3c-0.1-0.1-0.1-0.2-0.2-0.2c-0.2-0.2-0.5-0.4-0.7-0.6c-0.4-0.3-0.8-0.6-1.2-1c-0.6-0.6-1.2-1.3-1.8-1.9
			c-0.2-0.3-0.5-0.4-0.8-0.4c-0.7,0-1.4,0.1-2,0.1c-1.6,0-3.2,0.1-4.8,0.1c-0.2,0-0.4,0-0.6,0.1c-0.5,0.1-1,0-1.4-0.3
			c-0.7-0.4-1.2-1-1.8-1.5c-0.9-0.9-1.7-1.9-2.4-2.9c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1-0.2-0.3-0.2-0.5-0.2c-0.3,0.1-0.6,0.2-0.9,0.2
			c-0.3,0-0.7,0-1,0.1c-0.3,0-0.5,0-0.8,0.1c-0.9,0.2-1.8,0.3-2.7,0.3c-0.8,0.1-1.6,0.1-2.5,0.1c-0.9,0-1.9,0-2.8,0.1
			c-1.2,0-2.4,0.1-3.5,0.1c-0.6,0-1.1,0-1.7,0C790.6,649.6,790.4,649.5,790.3,649.7z M837.4,630.6c-0.1-0.1-0.1-0.2-0.1-0.2
			c-0.8-0.7-1.6-1.6-2.5-2.2c-0.4-0.3-0.8-0.7-1.1-1.1c-0.3-0.5-0.3-0.5-1.1-0.5c-1.2,0-2.4,0-3.5,0c-0.2,0-0.5,0-0.7-0.1
			c-0.4-0.1-0.8-0.3-0.9-0.8c-0.1-0.3-0.3-0.5-0.5-0.6c-0.4-0.3-0.8-0.6-1.2-0.8c-0.7-0.4-1.4-0.9-2-1.4c-0.2-0.2-0.4-0.4-0.6-0.5
			c-0.1-0.1-0.3-0.2-0.5-0.2c-0.4,0.1-0.9,0.1-1.4,0.1c-1.5,0-3,0-4.5,0.2c-0.3,0-0.7,0.1-1,0.1c-1,0.1-2,0.1-3,0.2
			c-0.3,0-0.7,0.1-1,0.1c-1.5,0-2.9,0.2-4.4,0.4c-0.8,0.1-1.7,0.2-2.5,0.1c-0.4,0-0.8-0.1-1.2-0.1c-0.1,0-0.3,0-0.4,0
			c0,0-0.1,0.1-0.1,0.1c0.1,0.1,0.1,0.1,0.2,0.2c0.4,0.3,0.9,0.6,1.3,0.9c1.3,0.9,2.5,1.9,3.6,3c0.8,0.8,1.6,1.5,2.5,2.2
			c0.5,0.4,1,0.8,1.5,1.2c0.2,0.2,0.4,0.2,0.7,0.2c2.3-0.1,4.6-0.1,6.8-0.2c2.9,0,5.9-0.1,8.8-0.1c2.7,0,5.4-0.1,8.1-0.1
			c0.1,0,0.2,0,0.3,0C837.2,630.7,837.3,630.6,837.4,630.6z M812.6,633.6c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.5-0.3-0.6-0.5
			c-0.5-0.6-1.1-1-1.7-1.5c-0.9-0.6-1.7-1.2-2.5-2c-1-1-2.1-1.9-3.3-2.8c-0.9-0.7-1.8-1.4-2.7-2.1c0,0,0,0,0,0
			c-0.2-0.2-0.3-0.1-0.4,0.2c0,0.1,0,0.2,0,0.3c0,0.4,0,0.7,0,1.1c0,1,0,2.1-0.1,3.1c0,0.5-0.1,1-0.1,1.5c0,0.3-0.1,0.5-0.1,0.8
			c-0.1,1.5-0.3,2.9-0.6,4.4c0,0.1,0,0.1,0,0.2c0,0.2,0,0.4,0.2,0.5c0.4,0.3,0.8,0.7,1.1,1.2c0.9,1.2,1.9,2.5,2.8,3.8
			c0.9,1.3,1.7,2.6,2.6,3.9c1.1,1.8,2.3,3.5,3.6,5.1c0.3,0.3,0.6,0.7,0.9,1c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0,0.2,0,0.2-0.1
			c0-0.1,0-0.1,0-0.2c0-0.6,0.1-1.3,0.1-1.9c0-2,0.1-4.1,0.1-6.1c0-0.3,0-0.5,0-0.8c0-0.9,0.1-1.8,0.1-2.7c0.1-1.5,0.1-3.1,0.2-4.6
			C812.5,634.6,812.6,634.1,812.6,633.6z M885,658.7C885,658.7,885,658.7,885,658.7c3.9,0,7.7,0,11.6,0c0.1,0,0.3,0,0.4,0
			c0.1,0,0.2,0,0.2-0.1c0.1-0.1-0.1-0.2-0.1-0.3c0,0-0.1-0.1-0.1-0.1c-0.4-0.4-0.9-0.8-1.3-1.1c-0.8-0.6-1.5-1.3-2.2-2
			c-0.5-0.5-1-1-1.5-1.5c-1.2-1.1-2.5-2.2-3.6-3.5c-0.2-0.2-0.5-0.3-0.8-0.4c-0.5-0.1-0.9-0.1-1.4-0.2c-0.1,0-0.3,0-0.4,0
			c-0.8,0-1.5,0-2.3,0c-0.5,0-0.9,0.1-1.4,0.1c-1.3,0-2.5,0-3.8-0.1c-0.7-0.1-1.3-0.1-2-0.2c-0.1,0-0.2,0-0.4,0
			c-0.4,0-0.5,0-0.5,0.5c0,0.1,0,0.2,0,0.2c0,0.9,0,1.9,0,2.8c0,0.2,0,0.5-0.1,0.7c-0.1,0.5-0.5,0.8-1,0.9c-0.2,0-0.5,0-0.8,0
			c-1.5,0-3,0-4.5,0c-0.2,0-0.4-0.1-0.5,0.1c-0.1,0.2,0.1,0.3,0.3,0.5c0.6,0.6,1.2,1.2,1.7,1.9c0.4,0.6,0.9,1.1,1.4,1.6
			c0.5,0.5,0.5,0.5,1.3,0.5C877.3,658.7,881.2,658.7,885,658.7z M787.9,650.1c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.4,0,0.8,0.1,1.3
			c0.1,1.9,0.1,3.8,0.2,5.8c0,1.5,0.1,3,0.1,4.5c0,2.6,0.1,5.1,0,7.7c0,0.2,0.1,0.4,0.2,0.6c0.2,0.2,0.4,0.4,0.6,0.6
			c0.9,1,1.6,2.2,2.3,3.3c0.7,1.1,1.4,2.1,2.2,3.1c0.9,1.1,1.8,2.2,2.6,3.3c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.1,0.2,0
			c0.1-0.2,0-0.3,0-0.5c0-0.9-0.1-1.8-0.1-2.6c0-0.2,0-0.3,0-0.5c-0.1-1.5-0.2-3-0.2-4.5c0-0.3,0-0.5,0-0.8c-0.1-1-0.1-2-0.2-3
			c0-0.8-0.1-1.5-0.1-2.3c-0.1-1.3-0.2-2.6-0.2-4c0-0.9-0.1-1.8-0.1-2.7c0-0.3-0.1-0.6-0.3-0.8c-0.7-0.8-1.4-1.5-2-2.3
			c-0.9-1.1-1.7-2.2-2.4-3.4c-0.4-0.8-0.9-1.6-1.5-2.3c-0.3-0.4-0.6-0.7-0.9-1.1C788.2,650.2,788.1,650.1,787.9,650.1z M840.4,649.6
			c0,0.2,0,0.3,0,0.4c0,0.4,0,0.7-0.1,1.1c0,0.3-0.1,0.6-0.2,0.8c-0.1,0.4-0.4,0.6-0.9,0.7c-0.2,0-0.5,0.1-0.7,0.1
			c-0.4,0-0.8,0-1.2,0.1c-0.8,0-1.6,0.1-2.4,0.1c-1.4,0.1-2.8,0.1-4.2,0.2c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0.1-0.1,0.2
			c0.1,0.1,0.2,0.2,0.2,0.3c0.9,1,1.7,1.9,2.6,2.9c0.3,0.3,0.6,0.7,1,1c0.3,0.3,0.6,0.4,1,0.4c0.3,0,0.5,0,0.8,0
			c1.3,0,2.7,0.1,4,0.1c3.3,0,6.6,0.1,9.9,0.2c1.7,0.1,3.4,0.1,5.1,0.1c2.3,0,4.6,0,6.9,0.2c0.4,0,0.8,0,1.1,0.1c0.1,0,0.2,0,0.3,0
			c0.1,0,0.2,0,0.2-0.1c0,0,0-0.1-0.1-0.2c0-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.3-0.2-0.5-0.3c-0.9-0.6-1.8-1.3-2.6-2
			c-0.4-0.3-0.7-0.7-1.1-1c-0.8-0.7-0.7-0.7-1.9-0.7c-2.6,0-5.1-0.1-7.7-0.1c-0.5,0-0.9,0-1.4,0c-0.2,0-0.3,0-0.5,0
			c-0.3,0-0.7-0.2-0.9-0.4c-0.4-0.4-0.8-0.6-1.2-1c-0.1-0.1-0.2-0.1-0.3-0.2c-0.4-0.2-0.7-0.3-1.1-0.5c-0.5-0.2-1-0.4-1.5-0.7
			c-0.6-0.3-1.1-0.7-1.5-1.2C840.7,649.8,840.6,649.6,840.4,649.6z M854.2,602.6c-0.2-0.3-0.4-0.4-0.6-0.6c-0.6-0.4-1.2-0.8-1.8-1.1
			c-1-0.5-2-0.9-3.1-1.3c-1.2-0.5-2.4-0.9-3.6-1.4c-0.7-0.3-1.5-0.5-2.3-0.4c-0.4,0-0.7,0-1.1,0c-0.8-0.1-1.6-0.1-2.4-0.2
			c-0.7-0.1-1.4-0.1-2.1-0.1c-1.1,0.1-2.3,0.1-3.4,0.2c-0.5,0-1,0.1-1.5,0.1c-0.6,0.1-1.2,0.1-1.8,0.2c-0.2,0-0.3,0-0.5,0
			c-0.9,0.2-1.9,0.2-2.9,0.2c-1.2,0.1-2.5,0.1-3.7,0.2c-0.4,0-0.8,0.1-1.3,0.2c-0.1,0-0.2,0.1-0.2,0.1c0.1,0.2,0.3,0.3,0.4,0.5
			c1.2,1,2.4,2,3.5,3.1c0.3,0.3,0.5,0.5,0.7,0.8c0.2,0.2,0.4,0.3,0.7,0.3c1.8-0.1,3.7-0.1,5.5-0.2c1.5,0,3.1-0.1,4.6-0.1
			c1.7,0,3.3-0.1,5-0.1c2.3,0,4.5-0.1,6.8-0.1c1.5,0,3-0.1,4.4-0.1c0.1,0,0.3,0,0.4,0C854.1,602.7,854.2,602.7,854.2,602.6z
			 M861,654.3c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.3,0.2,0.4,0.3c0.7,0.5,1.4,1,2.1,1.6c0.5,0.3,0.9,0.7,1.4,1
			c0.4,0.3,0.6,0.8,0.5,1.3c0,0.2,0,0.3,0.1,0.5c0.2,0.3,0.2,0.7,0.1,1.1c0,0.4-0.1,0.8-0.1,1.2c-0.1,1.3-0.1,2.5-0.2,3.8
			c0,0.3-0.1,0.6-0.1,0.9c0,0.8-0.1,1.7-0.1,2.5c-0.1,1.7-0.2,3.3-0.2,5c0,0.5,0,1-0.1,1.6c0,0.3,0.1,0.5,0.3,0.7
			c0.1,0.1,0.1,0.1,0.2,0.2c0.5,0.6,1.1,1.2,1.6,1.8c0.4,0.4,0.7,0.8,1.1,1.2c0.4,0.4,0.7,0.7,1.2,1c0.5,0.3,1,0.4,1.6,0.4
			c0.2,0,0.3-0.1,0.3-0.3c0-0.1,0-0.3,0-0.4c-0.1-1.7-0.1-3.3-0.2-5c0-1.7-0.1-3.4-0.1-5.1c0-2.8,0-5.5,0-8.3c0-0.1,0-0.3,0-0.4
			c0-0.2-0.1-0.3-0.2-0.3c-0.1-0.1-0.2-0.1-0.3-0.2c-0.3-0.2-0.4-0.5-0.4-0.9c0-0.1,0-0.1,0-0.2c0.1-0.2,0-0.5-0.1-0.7
			c-0.4-0.5-0.8-1.1-1.2-1.6c-0.8-0.9-1.5-1.7-2.3-2.6c-0.2-0.2-0.4-0.3-0.8-0.3c-1.5,0-3,0-4.4-0.1
			C861.3,654.2,861.2,654.2,861,654.3z M823.2,653.5c0.1,0.3,0.1,0.3,0.3,0.4c0.6,0.6,1.3,1.2,2,1.7c0.4,0.3,0.7,0.6,1,0.9
			c0.4,0.5,0.7,1.1,0.9,1.6c0.1,0.3,0.2,0.6,0.1,1c-0.1,0.2-0.1,0.4-0.1,0.7c0,0.6,0.1,1.2,0.1,1.8c0,2.1,0,4.3,0,6.4
			c0,0.6,0,1.2,0,1.7c0,2.1,0,4.2-0.1,6.2c0,0.5,0.1,0.9,0.4,1.2c0.7,0.9,1.3,1.9,1.9,2.9c0.5,0.8,1.1,1.6,1.8,2.3
			c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.2,0,0.2-0.1c0-0.1,0-0.2,0-0.4c0-1.5,0.1-3,0.1-4.5c0-0.3,0-0.7,0-1c0-1.2,0.1-2.4,0.1-3.6
			c0-0.6,0-1.2,0-1.8c0.1-1.9,0.2-3.7,0.3-5.6c0.1-1.9,0.2-3.7,0.4-5.6c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.3-0.2-0.4
			c-0.1-0.2-0.2-0.4-0.4-0.6c-0.9-1-1.7-2.1-2.6-3.1c-0.4-0.5-0.9-1.1-1.3-1.7c-0.2-0.3-0.4-0.5-0.8-0.4c-0.1,0-0.1,0-0.2,0
			c-1.3,0.1-2.7,0.1-4,0.2C823.4,653.4,823.3,653.5,823.2,653.5z M846.1,651.2c0-0.2,0.1-0.3,0.1-0.4c0-0.5,0.1-1,0.1-1.6
			c0-0.7,0-1.4,0.1-2.1c0.1-1.6,0.2-3.1,0.2-4.7c0-0.3,0-0.6,0-0.9c0.1-1,0.1-2.1,0.2-3.1c0-0.7,0.1-1.3,0.1-2
			c0.1-1.2,0.2-2.4,0.2-3.6c0-0.2,0-0.4-0.1-0.6c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.4-0.2-0.6-0.5-0.7c-0.1,0-0.1-0.1-0.2-0.1
			c-0.5-0.2-0.9-0.4-1.3-0.7c-0.4-0.3-0.8-0.6-1.2-0.9c-1-0.7-2-1.4-2.9-2.1c-0.2-0.2-0.5-0.3-0.8-0.5c-0.1-0.1-0.3-0.1-0.4-0.2
			c-0.2-0.2-0.5-0.2-0.8-0.2c-1.1,0-2.2,0-3.3,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0.1-0.1,0.2c0.1,0.1,0.3,0.2,0.4,0.3
			c0.7,0.5,1.4,1,2,1.6c0.1,0.1,0.3,0.3,0.5,0.4c0.2,0.2,0.4,0.3,0.7,0.4c0.6,0.1,1,0.4,1.4,0.9c0.2,0.3,0.4,0.5,0.8,0.6
			c0.3,0.2,0.5,0.4,0.6,0.8c0,0.1,0,0.3,0,0.4c0,0.7,0,1.4-0.1,2.1c-0.1,0.9-0.2,1.9-0.2,2.8c0,1.6-0.2,3.2-0.3,4.8
			c-0.1,1.6-0.2,3.2-0.3,4.8c0,0.3,0.1,0.6,0.3,0.8c0.3,0.3,0.5,0.6,0.8,0.9c0.3,0.3,0.5,0.6,0.8,0.9c0.5,0.5,1.1,0.8,1.7,1.1
			c0.5,0.3,1.1,0.6,1.6,0.8C845.9,651.1,846,651.1,846.1,651.2z M870.2,630.1c0-0.1,0-0.1,0-0.1c-0.1-0.1-0.2-0.1-0.3-0.2
			c-0.9-0.6-1.8-1.1-2.7-1.5c-1-0.4-1.9-1-2.6-1.7c-0.4-0.4-0.8-0.8-1.2-1.2c-0.5-0.5-1-1-1.6-1.4c-0.3-0.3-0.7-0.4-1.1-0.4
			c-0.4,0-0.8-0.1-1.2-0.1c-0.6,0-1.3-0.1-1.9-0.2c-0.1,0-0.1,0-0.2,0c-0.3,0-0.4,0-0.4,0.4c0,0.1,0,0.2,0,0.3c0,0.4,0,0.8-0.1,1.1
			c-0.1,0.5-0.4,0.9-0.9,1c-0.2,0-0.3,0.1-0.5,0.1c-0.5,0-1,0.1-1.5,0.1c-1.6,0-3.1,0.1-4.7,0.1c-2.1,0-4.1,0-6.2,0.1
			c-0.2,0-0.3,0-0.5,0c-0.1,0-0.2,0-0.3,0.1c0,0.1,0.1,0.2,0.1,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.9,0.6,1.7,1.3,2.6,1.9
			c0.3,0.2,0.6,0.4,0.9,0.6c0.4,0.2,0.8,0.4,1.1,0.6c0.2,0.1,0.3,0.1,0.5,0.1c0.2,0,0.4,0,0.5,0.1c0.2,0.2,0.5,0.2,0.8,0.2
			c0.8,0,1.6-0.1,2.5-0.1c1.9,0,3.9-0.1,5.8-0.1c3.4,0,6.7,0,10.1-0.1c0.7,0,1.4,0,2,0C869.8,630.2,870,630.3,870.2,630.1z
			 M820.9,599.9c0,0.2,0,0.3,0,0.4c0.1,0.5,0.2,0.9,0.2,1.4c0.1,1.5,0.2,3,0.3,4.6c0,1.6,0.1,3.2,0.1,4.8c0,0.2,0,0.3,0,0.5
			c0.1,1,0.2,2,0.2,3c0,0.3,0,0.6,0,0.9c0,1,0.1,2,0,2.9c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.1,0.1,0.2,0.2c0.9,0.8,1.8,1.6,2.8,2.4
			c0.7,0.6,1.5,1.1,2.2,1.6c0.2,0.1,0.4,0.1,0.4-0.2c0-0.2,0-0.3,0-0.5c0-0.5,0-0.9,0-1.4c0-2,0-4,0-6c0-2.6,0-5.3,0.1-7.9
			c0-0.2,0-0.4,0-0.7c0-0.2-0.1-0.4-0.3-0.5c-0.3-0.2-0.6-0.4-0.8-0.6c-0.3-0.4-0.7-0.7-1-1.1c-1.2-1.4-2.4-2.6-3.8-3.7
			C821.3,600.2,821.2,600.1,820.9,599.9z M880.5,647.4C880.5,647.4,880.5,647.4,880.5,647.4c1.5,0,3,0,4.5-0.1c0.1,0,0.2,0,0.2,0
			c0.1,0,0.2-0.1,0.2-0.3c0-0.1,0-0.2,0-0.3c-0.2-0.7-0.2-1.4-0.2-2.1c0-0.8,0-1.6,0.1-2.5c0-0.1,0-0.3,0-0.4c0-0.2-0.1-0.3-0.3-0.3
			c-0.1,0-0.2,0-0.3,0c-0.5,0-0.9,0-1.4,0.1c-1,0.2-1.9,0.2-2.9,0.2c-0.7,0-1.5,0-2.2,0c-0.7,0-1.4,0-2.1,0.1c-0.1,0-0.2,0-0.3,0
			c-0.1,0-0.2,0.1-0.2,0.2c0,0.2,0,0.4,0,0.6c0,1.4,0,2.8,0.1,4.2c0,0.1,0,0.2,0,0.3c0,0.3,0.1,0.4,0.4,0.4c0.2,0,0.4,0,0.6,0
			C877.8,647.4,879.1,647.4,880.5,647.4z M791.1,636.1c0.1,0.1,0.1,0.2,0.1,0.3c0.2,0.3,0.5,0.6,0.8,1c0.7,0.9,1.5,1.7,2.1,2.6
			c0.2,0.4,0.5,0.7,0.7,1.1c0.1,0.2,0.3,0.4,0.6,0.3c0.2,0,0.4,0,0.6,0.2c0.3,0.2,0.6,0.3,1,0.3c0.5,0,1,0,1.6,0
			c1.2,0,2.5,0,3.7,0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.1-0.1,0.1-0.2c0-0.1-0.1-0.2-0.1-0.2c-0.2-0.3-0.4-0.6-0.6-0.9
			c-0.6-1-1.2-1.9-1.8-2.9c-0.2-0.3-0.3-0.5-0.7-0.6c-0.2,0-0.3-0.2-0.4-0.3c-0.1-0.2-0.1-0.3-0.1-0.5c-0.1-0.2-0.1-0.2-0.3-0.2
			c-0.6,0.2-1.1,0.2-1.7,0.2c-0.8,0-1.7,0-2.5-0.1c-0.5,0-1.1,0-1.6-0.1c-0.4,0-0.8-0.1-1.2-0.1
			C791.4,635.9,791.3,635.9,791.1,636.1z M884.6,639.9c0-0.1,0-0.1,0-0.2c-0.6-0.6-1.2-1.2-1.8-1.8c-0.5-0.4-0.9-0.8-1.4-1.2
			c-0.5-0.4-1-0.9-1.6-1.3c-1.1-0.8-2.2-1.7-3.1-2.7c-0.4-0.4-0.7-0.7-1-1.1c-0.1-0.1-0.2-0.2-0.4-0.3c0,0.1,0,0.2,0,0.3
			c0,0.3,0,0.7,0,1c0,2.3,0.1,4.5,0.1,6.8c0,0.1,0,0.3,0,0.4c0,0.2,0.1,0.3,0.3,0.3c0.3,0,0.5,0,0.8,0c1-0.1,1.9-0.1,2.9-0.1
			c0.5,0,1.1,0,1.6,0c0.3,0,0.7,0,1,0c0.8,0,1.5-0.1,2.3-0.1C884.4,639.9,884.5,639.9,884.6,639.9z M789.9,637.6
			c0,0.2-0.1,0.3-0.1,0.3c0,0.6,0,1.2,0,1.7c0,1.3,0,2.5,0,3.8c0,1.2,0,2.4-0.1,3.6c0,0.1,0,0.2,0,0.3c0,0.1,0.1,0.2,0.2,0.2
			c0.1,0,0.2,0,0.3,0c0.5,0,1,0,1.6,0c0.8,0,1.5,0,2.3,0c0.5,0,0.6-0.1,0.6-0.6c0-1.1-0.1-2.2-0.1-3.3c0-0.4-0.1-0.7-0.3-1
			c-0.1-0.2-0.2-0.3-0.3-0.5c-0.7-0.9-1.5-1.8-2.2-2.7c-0.5-0.6-1-1.2-1.6-1.8C790.1,637.7,790,637.7,789.9,637.6z M800,643.8
			c-1.3,0-2.3-0.1-3.3-0.1c-0.3,0-0.4,0.1-0.4,0.4c0,0,0,0.1,0,0.1c0,1-0.1,1.9-0.1,2.9c0,0.1,0,0.2,0,0.2c0,0.1,0.1,0.2,0.2,0.2
			c0.1,0,0.1,0,0.2,0c1.4-0.1,2.8,0,4.2-0.1c0.8-0.1,1.6-0.1,2.4-0.3c0.1,0,0.1,0,0.2,0c0.6-0.1,1.3-0.1,1.9-0.1c0.2,0,0.3,0,0.5,0
			c0.1,0,0.2-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c-0.3-0.5-0.7-1-1-1.6c-0.2-0.3-0.4-0.7-0.6-1c-0.1-0.2-0.3-0.3-0.5-0.3
			c-0.4,0.1-0.8,0.1-1.3,0.1C801.4,643.8,800.5,643.8,800,643.8z"/>
                            <path className="st21" d="M849.2,660.2c2,0,4.3,0.1,6.5,0.1c2,0,4,0.1,6,0.1c0.6,0,1.3,0,1.9,0c0.4,0,0.5,0.1,0.5,0.5
			c-0.2,1.6-0.2,3.1-0.3,4.7c-0.1,1.5-0.2,3-0.3,4.4c-0.1,1.3-0.1,2.5-0.2,3.8c0,0.1,0,0.2,0,0.3c0,0.1-0.1,0.2-0.2,0.3
			c0,0-0.1,0-0.1,0.1c-0.4,0.3-0.5,0.7-0.2,1.1c0,0.1,0.1,0.1,0.1,0.2c0.2,0.2,0.2,0.4,0.2,0.7c-0.1,1.5-0.1,2.9-0.2,4.4
			c0,0.3,0,0.5,0,0.8c0,0.1,0,0.2,0,0.3c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.3,0-0.4,0c-1.7,0.1-3.4,0.1-5.1,0.2c-0.3,0-0.6,0-0.9,0
			c-1.8,0-3.5,0.1-5.3,0.2c-1.3,0.1-2.6,0.1-3.9,0.1c-0.9,0-1.7,0.1-2.6,0.1c-0.5,0-1.1,0.1-1.6,0.1c-1.7,0.1-3.3,0.1-5,0.2
			c-0.1,0-0.2,0-0.3,0c-1.2,0-2.4,0.1-3.6,0.1c-0.5,0-0.6,0-0.5-0.5c0-1.1,0.1-2.3,0.1-3.4c0-0.3,0-0.6,0-0.9c0-1.3,0-2.7,0.1-4
			c0.1-3.2,0.2-6.4,0.2-9.5c0-0.7,0-1.4,0-2c0-0.9-0.1-1.8-0.1-2.6c0-0.3,0-0.3,0.3-0.3c0.4,0,0.8,0,1.2,0c1,0,2.1,0.1,3.1,0.1
			c1.2,0,2.4,0,3.5,0c1.7,0,3.4,0.1,5.1,0.1C847.7,660.1,848.3,660.1,849.2,660.2z"/>
                            <path className="st13" d="M825.4,683.7c-0.4-0.3-0.8-0.3-1.3-0.4c-0.4,0-0.8-0.1-1.2-0.1c-1.8-0.1-3.6-0.2-5.4-0.2
			c-1.8,0-3.7-0.1-5.5-0.1c-2.6-0.1-5.1-0.1-7.7-0.2c-0.9,0-1.7,0-2.6,0c-0.8,0-1.7,0-2.5,0c-0.5,0-0.6-0.1-0.6-0.6
			c0-0.8-0.1-1.6-0.1-2.4c0-0.8-0.1-1.7-0.2-2.5c0-0.6-0.1-1.3-0.1-1.9c-0.1-1-0.1-2-0.2-3c0-0.6-0.1-1.3-0.1-1.9
			c-0.1-1.8-0.2-3.6-0.3-5.5c-0.1-1.4-0.2-2.7-0.3-4.1c0-0.6,0-0.6,0.6-0.6c1.2,0,2.3,0,3.5,0c2.5,0,5-0.1,7.5-0.1
			c3.4,0,6.8,0,10.2-0.1c1.4,0,2.9,0,4.3,0c0.7,0,1.3,0,2,0c0.1,0,0.3,0,0.4,0c0.3,0,0.3,0.1,0.3,0.4c0,0.3,0,0.5-0.1,0.8
			c-0.1,1.9-0.2,3.8-0.2,5.7c0,1.1,0,2.3,0,3.4c0,1.7,0,3.3-0.1,5c0,0.3-0.1,0.5-0.2,0.8c-0.2,0.2-0.2,0.5,0,0.7
			c0.2,0.5,0.3,0.9,0.3,1.4c0,1.7,0,3.4-0.2,5c0,0.2,0,0.3-0.1,0.5C825.5,683.5,825.4,683.6,825.4,683.7z"/>
                            <path className="st22" d="M886.6,680.6c-1.9,0-3.8,0-5.8,0.1c-1.3,0-2.6,0.1-3.9,0.1c-1.1,0-2.1,0.1-3.2,0.1c-0.1,0-0.2,0-0.3,0
			c-0.2,0-0.3-0.1-0.4-0.4c0-0.1,0-0.2,0-0.2c0-0.5,0-1.1,0-1.6c0-1.2,0-2.5,0-3.7c0-2-0.1-3.9-0.1-5.9c0-1,0-1.9-0.1-2.9
			c-0.1-1.6-0.1-3.3-0.2-4.9c0-0.5,0.1-0.6,0.6-0.6c1.8,0,3.5,0,5.3,0c6,0,11.9,0,17.9,0c0.7,0,1.5,0,2.2,0c0.7,0,0.7,0,0.8,0.7
			c0,0.4,0,0.8,0,1.1c0,1.4,0.1,2.8,0.1,4.3c0,0.9,0.1,1.8,0.1,2.7c0,0.3,0,0.6,0,0.9c0,2,0.1,4,0.2,6c0,0.1,0,0.2,0,0.3
			c0,1,0.1,2,0.1,3c0,0.6,0,0.6-0.6,0.6c-3.2,0-6.4,0.1-9.5,0.1C888.9,680.6,887.8,680.6,886.6,680.6
			C886.6,680.6,886.6,680.6,886.6,680.6z"/>
                            <path className="st13" d="M842,624.2c-1.3,0-2.6,0-4,0.1c-2.1,0-4.3,0.1-6.4,0.1c-0.6,0-1.2,0-1.8,0.1c-0.5,0-0.6,0-0.6-0.6
			c0-1.8,0-3.6,0-5.4c0-2,0-3.9-0.1-5.9c0-1.1-0.1-2.2-0.1-3.3c0-0.5,0-1,0-1.6c0-0.6-0.1-1.2-0.1-1.8c0-0.1,0-0.3,0-0.4
			c0-0.2,0.1-0.3,0.3-0.3c0.2,0,0.3,0,0.5,0c3.1,0,6.3-0.1,9.4-0.1c2,0,4-0.1,6-0.1c0.7,0,1.4,0,2.2,0c2.4,0,4.8,0,7.3-0.1
			c0.2,0,0.3,0,0.5,0c0.2,0,0.3,0.1,0.3,0.3c0,0.1,0,0.2,0,0.3c0,1.5-0.1,3-0.1,4.4c0,0.9,0,1.7,0,2.6c0,0.8,0,1.5,0,2.3
			c0,1.7-0.1,3.4-0.1,5.1c0,1.1,0,2.3-0.1,3.4c0,0.2,0,0.3,0,0.5c0,0.2-0.1,0.3-0.3,0.3c-0.1,0-0.3,0-0.4,0c-2,0-4,0-6,0
			c-1.9,0-3.8,0.1-5.8,0.1C842.2,624.2,842.1,624.2,842,624.2C842,624.2,842,624.2,842,624.2z"/>
                            <path className="st14" d="M860.9,632.6c2,0,4.1-0.1,6.1-0.1c1.7,0,3.4-0.1,5.1-0.1c0.2,0,0.4,0,0.6,0c0.2,0,0.3,0.1,0.3,0.3
			c0,0.1,0,0.1,0,0.2c0,0.9,0.1,1.8,0.1,2.6c0,1.6,0.1,3.2,0.1,4.8c0,1.8,0.1,3.6,0.1,5.4c0,1.1,0.1,2.3,0.1,3.4
			c0,0.8,0,1.6,0.1,2.4c0,0.1,0,0.2,0,0.3c0,0.4-0.1,0.4-0.5,0.4c-1,0-2.1,0-3.1,0c-1,0-2-0.1-3-0.1c-2.8,0-5.6-0.1-8.4-0.1
			c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.6,0c-0.2,0.1-0.5,0.1-0.7,0.1c-1.2,0-2.5,0-3.7,0c-1.4,0-2.8,0-4.2,0
			c-0.1,0-0.2,0-0.3,0c-0.2,0-0.3-0.1-0.4-0.4c0-0.2,0-0.3,0-0.5c0.1-2,0.2-4.1,0.3-6.1c0-0.9,0.1-1.9,0.1-2.8c0-0.1,0-0.3,0-0.4
			c0-1.3,0.1-2.6,0.1-4c0-1.5,0.1-3,0.1-4.5c0-0.5,0.1-0.6,0.6-0.6c1.7,0,3.5-0.1,5.2-0.1C856.7,632.6,858.8,632.6,860.9,632.6
			C860.9,632.5,860.9,632.5,860.9,632.6z"/>
                            <path className="st18" d="M813.8,642.5c0-1.4,0-2.7,0-4.1c0-0.7,0-1.4,0-2.2c0-0.8,0-1.6,0-2.5c0-0.1,0-0.2,0-0.3
			c0-0.3,0.1-0.4,0.4-0.5c0.1,0,0.3,0,0.4,0c1.2,0,2.4,0,3.5,0c2.3,0,4.6-0.1,6.9-0.1c1.3,0,2.6,0,3.9-0.1c2.7,0,5.4-0.1,8-0.1
			c0.5,0,1.1,0,1.6-0.1c0.1,0,0.2,0,0.3,0c0.2,0,0.3,0.1,0.3,0.3c0,0.1,0,0.2,0,0.3c-0.1,0.9-0.1,1.8-0.2,2.7
			c-0.1,1.1-0.2,2.1-0.2,3.2c-0.1,1.3-0.2,2.6-0.2,4c0,0.3,0,0.6-0.1,0.9c-0.1,0.9-0.1,1.9-0.2,2.8c-0.1,1-0.1,2-0.1,3
			c0,0.2,0,0.3,0,0.5c0,0.2-0.1,0.3-0.4,0.4c-0.1,0-0.2,0-0.2,0c-1,0-2,0.1-2.9,0.1c-1.7,0.1-3.4,0.1-5,0.2c-0.3,0-0.6,0-0.8,0
			c-1.7,0-3.5,0.1-5.2,0.2c-0.2,0-0.4,0-0.7,0c-1.2,0-2.3,0.1-3.5,0.2c-0.7,0.1-1.4,0.1-2.1,0.1c-0.8,0-1.6,0.1-2.5,0.2
			c-0.3,0-0.7,0-1,0.1c-0.3,0-0.4-0.1-0.4-0.4c0-0.1,0-0.2,0-0.3c0-1.9,0.1-3.7,0.1-5.6C813.7,644.6,813.7,643.6,813.8,642.5
			C813.8,642.5,813.8,642.5,813.8,642.5z"/>
                            <path className="st23" d="M790.3,649.7c0.2-0.1,0.3-0.1,0.5-0.1c0.6,0,1.1,0,1.7,0c1.2,0,2.4-0.1,3.5-0.1c0.9,0,1.9-0.1,2.8-0.1
			c0.8,0,1.6,0,2.5-0.1c0.9-0.1,1.8-0.2,2.7-0.3c0.3,0,0.5-0.1,0.8-0.1c0.3,0,0.7,0,1-0.1c0.3,0,0.6-0.1,0.9-0.2
			c0.2-0.1,0.4,0,0.5,0.2c0.1,0.1,0.1,0.2,0.2,0.2c0.7,1,1.6,2,2.4,2.9c0.5,0.6,1.1,1.1,1.8,1.5c0.4,0.3,0.9,0.4,1.4,0.3
			c0.2,0,0.4,0,0.6-0.1c1.6,0,3.2-0.1,4.8-0.1c0.7,0,1.4,0,2-0.1c0.3,0,0.6,0.1,0.8,0.4c0.6,0.7,1.2,1.3,1.8,1.9
			c0.4,0.4,0.8,0.6,1.2,1c0.2,0.2,0.5,0.4,0.7,0.6c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.2,0.1,0.3-0.1,0.3c-0.1,0-0.3,0-0.4,0
			c-0.9,0-1.8,0-2.7,0c-2.7,0-5.4,0.1-8.1,0.1c-3.1,0-6.1,0.1-9.2,0.1c-2.3,0-4.7,0-7,0.1c-0.4,0-0.8,0-1.2,0.1
			c-0.2,0-0.3,0-0.5-0.2c0,0-0.1-0.1-0.1-0.1c-0.7-1-1.4-1.9-2.2-2.9c-0.6-0.8-1.1-1.6-1.5-2.4c-0.5-0.9-1-1.8-1.6-2.5
			C790.4,650,790.3,649.9,790.3,649.7z"/>
                            <path className="st18" d="M837.4,630.6c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3,0c-2.7,0-5.4,0.1-8.1,0.1c-2.9,0-5.9,0.1-8.8,0.1
			c-2.3,0-4.6,0.1-6.8,0.2c-0.2,0-0.5,0-0.7-0.2c-0.5-0.4-1-0.8-1.5-1.2c-0.9-0.7-1.7-1.4-2.5-2.2c-1.1-1.1-2.4-2.1-3.6-3
			c-0.4-0.3-0.9-0.6-1.3-0.9c-0.1-0.1-0.2-0.1-0.2-0.2c0,0,0-0.1,0.1-0.1c0.1,0,0.3,0,0.4,0c0.4,0,0.8,0.1,1.2,0.1
			c0.9,0.1,1.7,0,2.5-0.1c1.5-0.2,2.9-0.4,4.4-0.4c0.3,0,0.7,0,1-0.1c1-0.1,2-0.1,3-0.2c0.3,0,0.7-0.1,1-0.1c1.5-0.1,3-0.2,4.5-0.2
			c0.5,0,0.9,0,1.4-0.1c0.2-0.1,0.4,0,0.5,0.2c0.2,0.2,0.4,0.4,0.6,0.5c0.6,0.5,1.3,1,2,1.4c0.4,0.3,0.8,0.5,1.2,0.8
			c0.2,0.2,0.4,0.4,0.5,0.6c0.1,0.5,0.5,0.7,0.9,0.8c0.2,0,0.5,0.1,0.7,0.1c1.2,0,2.4,0,3.5,0c0.8,0,0.7,0.1,1.1,0.5
			c0.3,0.4,0.7,0.8,1.1,1.1c0.9,0.7,1.6,1.5,2.5,2.2C837.3,630.4,837.3,630.5,837.4,630.6z"/>
                            <path className="st24" d="M812.6,633.6c0,0.5-0.1,1-0.1,1.5c-0.1,1.5-0.1,3.1-0.2,4.6c0,0.9-0.1,1.8-0.1,2.7c0,0.3,0,0.5,0,0.8
			c0,2-0.1,4.1-0.1,6.1c0,0.6-0.1,1.3-0.1,1.9c0,0.1,0,0.1,0,0.2c0,0.1-0.1,0.1-0.2,0.1c-0.1-0.1-0.2-0.2-0.3-0.3
			c-0.3-0.3-0.6-0.7-0.9-1c-1.4-1.6-2.5-3.3-3.6-5.1c-0.8-1.3-1.7-2.6-2.6-3.9c-0.9-1.3-1.9-2.5-2.8-3.8c-0.3-0.4-0.7-0.8-1.1-1.2
			c-0.2-0.1-0.2-0.3-0.2-0.5c0-0.1,0-0.1,0-0.2c0.3-1.4,0.5-2.9,0.6-4.4c0-0.3,0.1-0.5,0.1-0.8c0-0.5,0.1-1,0.1-1.5
			c0-1,0-2.1,0.1-3.1c0-0.4,0-0.7,0-1.1c0-0.1,0-0.2,0-0.3c0-0.3,0.2-0.3,0.4-0.2c0,0,0,0,0,0c0.9,0.7,1.8,1.4,2.7,2.1
			c1.1,0.9,2.2,1.8,3.3,2.8c0.8,0.8,1.6,1.4,2.5,2c0.6,0.4,1.2,0.9,1.7,1.5c0.2,0.2,0.4,0.4,0.6,0.5
			C812.5,633.2,812.6,633.4,812.6,633.6z"/>
                            <path className="st25" d="M885,658.7c-3.9,0-7.7,0.1-11.6,0.1c-0.8,0-0.8,0-1.3-0.5c-0.5-0.5-1-1-1.4-1.6c-0.5-0.6-1.1-1.3-1.7-1.9
			c-0.1-0.1-0.4-0.2-0.3-0.5c0.1-0.2,0.3-0.1,0.5-0.1c1.5,0,3,0,4.5,0c0.3,0,0.5,0,0.8,0c0.5-0.1,0.9-0.4,1-0.9
			c0.1-0.2,0.1-0.5,0.1-0.7c0-0.9,0-1.9,0-2.8c0-0.1,0-0.2,0-0.2c0-0.4,0.1-0.5,0.5-0.5c0.1,0,0.2,0,0.4,0c0.7,0.1,1.3,0.1,2,0.2
			c1.3,0.1,2.5,0.1,3.8,0.1c0.5,0,0.9-0.1,1.4-0.1c0.8,0,1.5,0,2.3,0c0.1,0,0.3,0,0.4,0c0.5,0.1,0.9,0.1,1.4,0.2
			c0.3,0,0.6,0.1,0.8,0.4c1.1,1.3,2.4,2.3,3.6,3.5c0.5,0.5,1,1,1.5,1.5c0.7,0.7,1.4,1.4,2.2,2c0.5,0.4,0.9,0.7,1.3,1.1
			c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.2,0.2,0.1,0.3c0,0.1-0.2,0.1-0.2,0.1c-0.1,0-0.3,0-0.4,0C892.8,658.7,888.9,658.7,885,658.7
			C885,658.7,885,658.7,885,658.7z"/>
                            <path className="st13" d="M787.9,650.1c0.2,0,0.3,0.1,0.4,0.2c0.3,0.3,0.6,0.7,0.9,1.1c0.6,0.7,1.1,1.5,1.5,2.3
			c0.6,1.2,1.5,2.4,2.4,3.4c0.7,0.8,1.4,1.5,2,2.3c0.2,0.2,0.3,0.5,0.3,0.8c0,0.9,0.1,1.8,0.1,2.7c0,1.3,0.1,2.6,0.2,4
			c0.1,0.8,0.1,1.5,0.1,2.3c0.1,1,0.1,2,0.2,3c0,0.3,0,0.5,0,0.8c0,1.5,0.1,3,0.2,4.5c0,0.2,0,0.3,0,0.5c0,0.9,0.1,1.8,0.1,2.6
			c0,0.2,0,0.3,0,0.5c-0.1,0.1-0.2,0-0.2,0c-0.1-0.1-0.2-0.2-0.3-0.3c-0.8-1.2-1.7-2.3-2.6-3.3c-0.8-1-1.6-2-2.2-3.1
			c-0.7-1.2-1.4-2.3-2.3-3.3c-0.2-0.2-0.4-0.4-0.6-0.6c-0.2-0.2-0.2-0.3-0.2-0.6c0.1-2.6,0-5.1,0-7.7c0-1.5,0-3-0.1-4.5
			c0-1.9-0.1-3.8-0.2-5.8c0-0.4,0-0.8-0.1-1.3C787.8,650.4,787.7,650.2,787.9,650.1z"/>
                            <path className="st26" d="M840.4,649.6c0.3,0,0.4,0.2,0.5,0.3c0.5,0.5,1,0.9,1.5,1.2c0.5,0.3,1,0.5,1.5,0.7c0.4,0.2,0.7,0.3,1.1,0.5
			c0.1,0.1,0.2,0.1,0.3,0.2c0.4,0.3,0.8,0.6,1.2,1c0.3,0.2,0.6,0.4,0.9,0.4c0.2,0,0.3,0,0.5,0c0.5,0,0.9,0,1.4,0
			c2.6,0,5.1,0.1,7.7,0.1c1.2,0,1.1,0,1.9,0.7c0.4,0.3,0.8,0.6,1.1,1c0.8,0.8,1.7,1.4,2.6,2c0.2,0.1,0.3,0.2,0.5,0.3
			c0.1,0,0.1,0.1,0.2,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8,0-1.1-0.1
			c-2.3-0.1-4.6-0.1-6.9-0.2c-1.7,0-3.4,0-5.1-0.1c-3.3-0.1-6.6-0.2-9.9-0.2c-1.3,0-2.7-0.1-4-0.1c-0.3,0-0.5,0-0.8,0
			c-0.4,0-0.7-0.2-1-0.4c-0.3-0.3-0.6-0.6-1-1c-0.9-1-1.7-1.9-2.6-2.9c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1-0.1,0-0.2,0.1-0.2
			c0.1,0,0.2,0,0.3,0c1.4-0.1,2.8-0.1,4.2-0.2c0.8,0,1.6-0.1,2.4-0.1c0.4,0,0.8,0,1.2-0.1c0.2,0,0.5,0,0.7-0.1
			c0.4-0.1,0.7-0.3,0.9-0.7c0.1-0.3,0.2-0.5,0.2-0.8c0-0.4,0.1-0.7,0.1-1.1C840.3,649.9,840.3,649.8,840.4,649.6z"/>
                            <path className="st13" d="M854.2,602.6c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.3,0-0.4,0c-1.5,0-3,0.1-4.4,0.1c-2.3,0-4.5,0.1-6.8,0.1
			c-1.7,0-3.3,0.1-5,0.1c-1.5,0-3.1,0-4.6,0.1c-1.8,0-3.7,0.1-5.5,0.2c-0.3,0-0.5-0.1-0.7-0.3c-0.2-0.3-0.5-0.5-0.7-0.8
			c-1.1-1.1-2.2-2.2-3.5-3.1c-0.2-0.1-0.4-0.2-0.4-0.5c0.1,0,0.2-0.1,0.2-0.1c0.4-0.1,0.8-0.1,1.3-0.2c1.2-0.1,2.5-0.1,3.7-0.2
			c1,0,1.9-0.1,2.9-0.2c0.2,0,0.3,0,0.5,0c0.6-0.1,1.2-0.1,1.8-0.2c0.5,0,1-0.1,1.5-0.1c1.1-0.1,2.3-0.1,3.4-0.2
			c0.7,0,1.4,0,2.1,0.1c0.8,0.1,1.6,0.1,2.4,0.2c0.4,0,0.7,0,1.1,0c0.8-0.1,1.5,0.1,2.3,0.4c1.2,0.5,2.4,0.9,3.6,1.4
			c1,0.4,2.1,0.8,3.1,1.3c0.6,0.3,1.3,0.7,1.8,1.1C853.8,602.2,854.1,602.3,854.2,602.6z"/>
                            <path className="st27" d="M861,654.3c0.2,0,0.3,0,0.4,0c1.5,0,3,0,4.4,0.1c0.3,0,0.5,0.1,0.8,0.3c0.8,0.9,1.6,1.7,2.3,2.6
			c0.4,0.5,0.8,1.1,1.2,1.6c0.2,0.2,0.2,0.4,0.1,0.7c0,0.1,0,0.1,0,0.2c-0.1,0.4,0.1,0.6,0.4,0.9c0.1,0.1,0.2,0.1,0.3,0.2
			c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1,0,0.3,0,0.4c0,2.8,0,5.5,0,8.3c0,1.7,0.1,3.4,0.1,5.1c0,1.7,0.1,3.3,0.2,5c0,0.1,0,0.3,0,0.4
			c0,0.2-0.1,0.3-0.3,0.3c-0.6,0-1.1-0.1-1.6-0.4c-0.5-0.3-0.9-0.6-1.2-1c-0.4-0.4-0.7-0.8-1.1-1.2c-0.5-0.6-1.1-1.2-1.6-1.8
			c-0.1-0.1-0.1-0.1-0.2-0.2c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.5,0-1,0.1-1.6c0.1-1.7,0.2-3.3,0.2-5c0-0.8,0.1-1.7,0.1-2.5
			c0-0.3,0.1-0.6,0.1-0.9c0.1-1.3,0.1-2.5,0.2-3.8c0-0.4,0-0.8,0.1-1.2c0-0.4,0-0.7-0.1-1.1c-0.1-0.1-0.1-0.3-0.1-0.5
			c0.1-0.6-0.1-1-0.5-1.3c-0.4-0.4-0.9-0.7-1.4-1c-0.7-0.5-1.4-1-2.1-1.6c-0.1-0.1-0.3-0.2-0.4-0.3
			C861.1,654.5,861.1,654.4,861,654.3z"/>
                            <path className="st28" d="M823.2,653.5c0.1,0,0.2-0.1,0.3-0.1c1.3-0.1,2.7-0.1,4-0.2c0.1,0,0.1,0,0.2,0c0.4,0,0.6,0.1,0.8,0.4
			c0.4,0.6,0.8,1.1,1.3,1.7c0.9,1.1,1.7,2.1,2.6,3.1c0.2,0.2,0.3,0.4,0.4,0.6c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.1,0.3,0.1,0.4
			c-0.2,1.8-0.3,3.7-0.4,5.6c-0.1,1.9-0.2,3.7-0.3,5.6c0,0.6,0,1.2,0,1.8c0,1.2-0.1,2.4-0.1,3.6c0,0.3,0,0.7,0,1
			c0,1.5-0.1,3-0.1,4.5c0,0.1,0,0.3,0,0.4c0,0.1-0.1,0.1-0.2,0.1c-0.1-0.1-0.1-0.1-0.2-0.2c-0.7-0.7-1.3-1.4-1.8-2.3
			c-0.6-1-1.2-2-1.9-2.9c-0.3-0.4-0.4-0.8-0.4-1.2c0-2.1,0.1-4.2,0.1-6.2c0-0.6,0-1.2,0-1.7c0-2.1,0-4.3,0-6.4c0-0.6,0-1.2-0.1-1.8
			c0-0.2,0-0.4,0.1-0.7c0.1-0.3,0.1-0.7-0.1-1c-0.3-0.6-0.5-1.1-0.9-1.6c-0.3-0.3-0.6-0.6-1-0.9c-0.7-0.5-1.3-1.1-2-1.7
			C823.3,653.8,823.3,653.8,823.2,653.5z"/>
                            <path className="st29" d="M846.1,651.2c-0.1-0.1-0.2-0.1-0.3-0.1c-0.5-0.3-1.1-0.6-1.6-0.8c-0.6-0.3-1.2-0.6-1.7-1.1
			c-0.3-0.3-0.6-0.6-0.8-0.9c-0.3-0.3-0.5-0.6-0.8-0.9c-0.2-0.2-0.3-0.5-0.3-0.8c0.1-1.6,0.2-3.2,0.3-4.8c0.1-1.6,0.3-3.2,0.3-4.8
			c0-0.9,0.1-1.9,0.2-2.8c0-0.7,0-1.4,0.1-2.1c0-0.1,0-0.3,0-0.4c-0.1-0.4-0.2-0.7-0.6-0.8c-0.3-0.1-0.5-0.4-0.8-0.6
			c-0.3-0.5-0.8-0.8-1.4-0.9c-0.3-0.1-0.5-0.2-0.7-0.4c-0.2-0.1-0.3-0.3-0.5-0.4c-0.6-0.6-1.3-1.1-2-1.6c-0.1-0.1-0.3-0.2-0.4-0.3
			c-0.1-0.1,0-0.2,0.1-0.2c0.1,0,0.2,0,0.3,0c1.1,0,2.2,0,3.3,0c0.3,0,0.5,0.1,0.8,0.2c0.1,0.1,0.2,0.1,0.4,0.2
			c0.3,0.2,0.6,0.3,0.8,0.5c0.9,0.8,1.9,1.4,2.9,2.1c0.4,0.3,0.8,0.6,1.2,0.9c0.4,0.3,0.8,0.5,1.3,0.7c0.1,0,0.1,0.1,0.2,0.1
			c0.3,0.1,0.5,0.3,0.5,0.7c0,0.1,0.1,0.3,0.2,0.4c0.1,0.2,0.1,0.4,0.1,0.6c-0.1,1.2-0.2,2.4-0.2,3.6c0,0.7-0.1,1.3-0.1,2
			c-0.1,1-0.1,2.1-0.2,3.1c0,0.3,0,0.6,0,0.9c-0.1,1.6-0.2,3.1-0.2,4.7c0,0.7,0,1.4-0.1,2.1c0,0.5,0,1-0.1,1.6
			C846.2,650.9,846.1,651,846.1,651.2z"/>
                            <path className="st30" d="M870.2,630.1c-0.2,0.1-0.4,0.1-0.6,0.1c-0.7,0-1.4,0-2,0c-3.4,0-6.7,0-10.1,0.1c-1.9,0-3.9,0.1-5.8,0.1
			c-0.8,0-1.6,0-2.5,0.1c-0.3,0-0.6,0-0.8-0.2c-0.2-0.1-0.3-0.2-0.5-0.1c-0.2,0-0.3,0-0.5-0.1c-0.4-0.2-0.8-0.4-1.1-0.6
			c-0.3-0.2-0.6-0.4-0.9-0.6c-0.9-0.6-1.7-1.3-2.6-1.9c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.2-0.1-0.1-0.2c0-0.1,0.2-0.1,0.3-0.1
			c0.2,0,0.3,0,0.5,0c2.1,0,4.1,0,6.2-0.1c1.6,0,3.1-0.1,4.7-0.1c0.5,0,1,0,1.5-0.1c0.2,0,0.3,0,0.5-0.1c0.5-0.1,0.9-0.5,0.9-1
			c0.1-0.4,0.1-0.8,0.1-1.1c0-0.1,0-0.2,0-0.3c0-0.3,0.1-0.4,0.4-0.4c0.1,0,0.1,0,0.2,0c0.6,0.1,1.3,0.2,1.9,0.2
			c0.4,0,0.8,0.1,1.2,0.1c0.4,0,0.8,0.2,1.1,0.4c0.5,0.5,1,1,1.6,1.4c0.4,0.4,0.8,0.8,1.2,1.2c0.8,0.7,1.7,1.3,2.6,1.7
			c0.9,0.4,1.8,0.9,2.7,1.5c0.1,0.1,0.2,0.1,0.3,0.2C870.2,630,870.2,630.1,870.2,630.1z"/>
                            <path className="st31" d="M820.9,599.9c0.2,0.2,0.4,0.3,0.5,0.4c1.4,1.1,2.6,2.4,3.8,3.7c0.3,0.4,0.7,0.7,1,1.1
			c0.2,0.2,0.5,0.5,0.8,0.6c0.2,0.1,0.3,0.3,0.3,0.5c0,0.2,0,0.4,0,0.7c0,2.6-0.1,5.3-0.1,7.9c0,2,0,4,0,6c0,0.5,0,0.9,0,1.4
			c0,0.2,0,0.3,0,0.5c0,0.3-0.1,0.3-0.4,0.2c-0.8-0.5-1.5-1-2.2-1.6c-0.9-0.8-1.8-1.6-2.8-2.4c-0.1-0.1-0.1-0.1-0.2-0.2
			c-0.1-0.1-0.1-0.2-0.1-0.3c0.2-1,0-1.9,0-2.9c0-0.3,0-0.6,0-0.9c0-1-0.1-2-0.2-3c0-0.2,0-0.3,0-0.5c0-1.6-0.1-3.2-0.1-4.8
			c0-1.5-0.1-3.1-0.3-4.6c0-0.5-0.1-0.9-0.2-1.4C820.9,600.2,820.9,600.1,820.9,599.9z"/>
                            <path className="st11" d="M880.5,647.4c-1.3,0-2.7,0-4,0.1c-0.2,0-0.4,0-0.6,0c-0.3,0-0.4-0.1-0.4-0.4c0-0.1,0-0.2,0-0.3
			c0-1.4-0.1-2.8-0.1-4.2c0-0.2,0-0.4,0-0.6c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0,0.3,0c0.7,0,1.4,0,2.1-0.1c0.7,0,1.5,0,2.2,0
			c1,0,1.9-0.1,2.9-0.2c0.4-0.1,0.9-0.1,1.4-0.1c0.1,0,0.2,0,0.3,0c0.2,0,0.3,0.1,0.3,0.3c0,0.1,0,0.3,0,0.4c0,0.8,0,1.6-0.1,2.5
			c0,0.7,0,1.4,0.2,2.1c0,0.1,0,0.2,0,0.3c0,0.1-0.1,0.2-0.2,0.3c-0.1,0-0.2,0-0.2,0C883.5,647.3,882,647.4,880.5,647.4
			C880.5,647.4,880.5,647.4,880.5,647.4z"/>
                            <path className="st32" d="M791.1,636.1c0.2-0.1,0.3-0.1,0.4-0.1c0.4,0,0.8,0.1,1.2,0.1c0.5,0,1.1,0.1,1.6,0.1c0.8,0,1.7,0,2.5,0.1
			c0.6,0,1.1,0,1.7-0.2c0.2-0.1,0.3,0,0.3,0.2c0,0.2,0.1,0.3,0.1,0.5c0.1,0.2,0.2,0.3,0.4,0.3c0.4,0.1,0.5,0.3,0.7,0.6
			c0.6,1,1.2,1.9,1.8,2.9c0.2,0.3,0.4,0.6,0.6,0.9c0.1,0.1,0.1,0.2,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-0.1,0-0.2,0-0.3,0
			c-1.2,0-2.5,0-3.7-0.1c-0.5,0-1,0-1.6,0c-0.4,0-0.7-0.1-1-0.3c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0.1-0.5-0.1-0.6-0.3
			c-0.2-0.4-0.5-0.7-0.7-1.1c-0.6-0.9-1.4-1.8-2.1-2.6c-0.3-0.3-0.5-0.6-0.8-1C791.2,636.3,791.2,636.2,791.1,636.1z"/>
                            <path className="st11" d="M884.6,639.9c-0.1,0-0.2,0.1-0.3,0.1c-0.8,0-1.5,0.1-2.3,0.1c-0.3,0-0.7,0-1,0c-0.5,0-1.1,0-1.6,0
			c-1,0-2,0-2.9,0.1c-0.3,0-0.5,0-0.8,0c-0.2,0-0.3-0.1-0.3-0.3c0-0.1,0-0.3,0-0.4c0-2.3-0.1-4.5-0.1-6.8c0-0.3,0-0.7,0-1
			c0-0.1,0-0.1,0-0.3c0.2,0,0.3,0.2,0.4,0.3c0.3,0.4,0.7,0.7,1,1.1c1,1,2,1.8,3.1,2.7c0.5,0.4,1,0.9,1.6,1.3
			c0.5,0.4,0.9,0.8,1.4,1.2c0.6,0.6,1.2,1.2,1.8,1.8C884.6,639.7,884.6,639.8,884.6,639.9z"/>
                            <path className="st33" d="M789.9,637.6c0.1,0.1,0.2,0.2,0.3,0.2c0.5,0.6,1.1,1.2,1.6,1.8c0.8,0.9,1.5,1.8,2.2,2.7
			c0.1,0.1,0.2,0.3,0.3,0.5c0.2,0.3,0.3,0.6,0.3,1c0,1.1,0.1,2.2,0.1,3.3c0,0.6,0,0.6-0.6,0.6c-0.8,0-1.5,0-2.3,0c-0.5,0-1,0-1.6,0
			c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0-0.2,0-0.3c0-1.2,0-2.4,0.1-3.6c0-1.3,0-2.5,0-3.8c0-0.6,0-1.2,0-1.7
			C789.8,637.8,789.9,637.7,789.9,637.6z"/>
                            <path className="st32" d="M800,643.8c0.6,0,1.4,0,2.3,0c0.4,0,0.8,0,1.3-0.1c0.3,0,0.4,0,0.5,0.3c0.2,0.3,0.4,0.7,0.6,1
			c0.3,0.5,0.7,1,1,1.6c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.3c-0.2,0-0.3,0-0.5,0c-0.6,0-1.3,0-1.9,0.1c-0.1,0-0.1,0-0.2,0
			c-0.8,0.2-1.6,0.2-2.4,0.3c-1.4,0.1-2.8,0.1-4.2,0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0-0.2,0-0.2
			c0-1,0.1-1.9,0.1-2.9c0,0,0-0.1,0-0.1c0-0.3,0.1-0.4,0.4-0.4C797.6,643.7,798.7,643.7,800,643.8z"/>
	</g>
                        <g className="empleado white">
		<path className="st34" d="M939.8,563.7c-5.4,0-6.4,7.6-10.7,9.7c-5.5,2.8-11.2,4.4-16.2,8.4c-7,5.6-7.3,18.9-6.7,26.8
			c0.3,3.3-1.5,10.2,0.6,12.9c1.3,1.8,4.2-2.1,5.3-0.9c0.9,0.9-0.2,8.1-0.4,9.5c-0.8,7.6,1.5,14.7,2.3,22.1
			c1.3,11.8-8.3,23.4,0.6,34.4c2.8,3.4,9.5,4.9,13.5,6.1c14.7,4.5,30.3,1.3,44.9-1.1c3.7-0.6,8.6-1.5,11.8-3.4
			c0.7-0.4,5.6-3.6,6.1-3.2c1.6,1.2,2.1,5.7,2.9,7.6c3.1,7.7,4.8,13.8,5.7,22.1c0.3,2.6,0.8,5.3,0.8,8c0,0.8,0.6,3.9,1.5,4.4
			c3.4,1.7,7.9-0.3,11-1.3c7.3-2.4,4.2-9.1,4.2-14.7c0-7-0.1-11.6-1.1-18.6c-0.8-5.9-2.8-14-3.5-19.9c-2.6-20.7-0.7-42.7-4.8-63.2
			c-1.5-7.4-1.6-15.8-6.7-21.9c-8.2-9.8-23.9-15.9-36-18.3c-7-1.4-15.1-6.3-22.5-4.6c-1.9,0.4-4.7,2.4-6.3,3.2L939.8,563.7z"/>
                            <path className="st35" d="M916.2,688.5l-4.3,21.5l0.5,21.1l11.3,123.2l3.7,81.9c0,0,1,2,5.3,2.6c4.3,0.6,12.4-0.9,12.4-0.9
			s2.7-45.7,4.2-87.3c0.5-13-0.4-26.8,0.1-37.1c2-43.3,3.7-48.8,3.7-48.8l5.1,15.2l24.6,157.7c0,0,2.5,2.3,8.4,1.6c3.4-0.4,7-3,7-3
			l2.8-197.3l-2-28.6l-8.6-26c0,0-13.7,9.9-45.4,9.2C926.4,692.9,916.2,688.5,916.2,688.5z"/>
                            <path className="st35" d="M925.3,943c0.2,1.8-3,3.8-4.3,4.7c-5,3.6-11.2,8.1-17,10.1c-2.3,0.8-5,1.6-7,2.9c-0.3,0.2-2,1.1-1.6,1.8
			c0.6,1,2.7,0.9,3.7,0.9c2.9,0,5.9,0,8.8,0.1c9.9,0.4,20.6,1,30.5-0.8c3.2-0.6,8.7-0.5,9-4.6c0.2-1.9,0.7-9.2-2-9.8
			c-0.9-0.2-2.3,0.9-3.1,1c-3.1,0.5-6.6,0.7-9.6,0.2c-3-0.5-1.8-5-4.3-6.4C927.7,942.7,925.2,941.8,925.3,943z"/>
                            <path className="st35" d="M999.8,945.2c-0.9,0-2.3-1.5-3.2-1.9c-2.8-1.1-8.7,0-11.1,1.8c-3.2,2.6-3.7,7.1-3.9,10.9
			c-0.1,1.3-0.7,3.5,0.3,4.6c1.4,1.6,3.7,1.9,5.7,2.1c5.6,0.7,11.9-0.4,17.5-0.6c1.4-0.1,5.1,0.6,6.1-0.8c0.7-1.1-0.8-2.7-1.4-3.5
			c-2.4-2.9-4.4-6.1-6.8-9C1002.3,948,1000.8,945.2,999.8,945.2z"/>
                            <path className="st36" d="M913.9,685.9l-8.3,4.1l-23.2-59.8l29.1-10.1c0,0-0.1,10.8,0.1,11.8c0.1,1,2.2,25.9,2.2,25.9
			s-0.6,4.4-1.1,6.6c-0.9,3.4-2.6,5.8-2.7,9.8c-0.2,6.7,3.8,10.9,3.8,10.9L913.9,685.9z"/>
                            <path className="st37" d="M882.3,632.2l-2.2,0.9l6.5,18.2l17.8,41.3l1.5-1.2L882.3,632.2z"/>
                            <path className="st38" d="M880.4,635.5l-1.7,1l23.9,58.8l6.4-3l-4.9,0.1L880.4,635.5z"/>
                            <path className="st34" d="M939.8,563.7c-5.4,0-6.4,7.6-10.7,9.7c-5.5,2.8-11.2,4.4-16.2,8.4c-7,5.6-7.3,18.9-6.7,26.8
			c0.3,3.3-1.5,10.2,0.6,12.9c1.3,1.8,4.2-2.1,5.3-0.9c0.9,0.9-0.2,8.1-0.4,9.5c-0.8,7.6,1.5,14.7,2.3,22.1
			c1.3,11.8-8.3,23.4,0.6,34.4c2.8,3.4,9.5,4.9,13.5,6.1c14.7,4.5,30.3,1.3,44.9-1.1c3.7-0.6,8.6-1.5,11.8-3.4
			c0.7-0.4,5.6-3.6,6.1-3.2c1.6,1.2,2.1,5.7,2.9,7.6c3.1,7.7,4.8,13.8,5.7,22.1c0.3,2.6,0.8,5.3,0.8,8c0,0.8,0.6,3.9,1.5,4.4
			c3.4,1.7,7.9-0.3,11-1.3c7.3-2.4,4.2-9.1,4.2-14.7c0-7-0.1-11.6-1.1-18.6c-0.8-5.9-2.8-14-3.5-19.9c-2.6-20.7-0.7-42.7-4.8-63.2
			c-1.5-7.4-1.6-15.8-6.7-21.9c-8.2-9.8-23.9-15.9-36-18.3c-7-1.4-15.1-6.3-22.5-4.6c-1.9,0.4-4.7,2.4-6.3,3.2L939.8,563.7z"/>
                            <path className="st39" d="M990.8,631.2c-0.2-0.1-0.4,10.1-1.7,19.9c-0.8,5.6,2.9,10.2,3.7,15c1.7,10.4-3.4,18.5-3.7,18.7
			c-3.2,1.9-11.8,5.1-15.5,5.7c-14.6,2.4-26.3,3-44,0.6c-4-1.2-12-0.8-14.7-4.3c-8.9-11.1,0.7-22.6-0.6-34.4
			c-0.8-7.4-3.1-14.5-2.3-22.1c0.2-1.4,1.3-8.6,0.4-9.5c-1.1-1.1-4,2.7-5.3,0.9c-2.1-2.7-0.3-9.6-0.6-12.9
			c-0.7-7.9-0.3-21.2,6.7-26.8c5-4,10.1-5.6,15.7-8.4c4.2-2.1,5.8-9.7,11.2-9.7l-3.4,4.2c1.6-0.9,4.3-2.8,6.3-3.2
			c7.4-1.7,15.5,3.2,22.5,4.6c12.1,2.4,27.8,8.4,36,18.3c5.1,6.1,5.4,13.2,6.9,20.6c1.4,3.1,2.6,2.6,3.1,24.4c0,0-2.1,2.1-10.3,1.7
			C993.2,634.1,990.8,631.2,990.8,631.2"/>
                            <path className="st40" d="M916.2,688.5l-4.3,21.5l0.5,21.1l11.3,123.2l3.7,81.9c0,0,1,2,5.3,2.6c4.3,0.6,12.4-0.9,12.4-0.9
			s2.7-45.7,4.2-87.3c0.5-13-0.4-26.8,0.1-37.1c2-43.3,3.7-48.8,3.7-48.8l5.1,15.2l24.6,157.7c0,0,2.5,2.3,8.4,1.6c3.4-0.4,7-3,7-3
			l2.8-197.3l-2-28.6l-8.6-26c0,0-13.7,9.9-45.4,9.2C926.4,692.9,916.2,688.5,916.2,688.5z"/>
                            <path className="st35" d="M925.3,943c0.2,1.8-3,3.8-4.3,4.7c-5,3.6-11.2,8.1-17,10.1c-2.3,0.8-5,1.6-7,2.9c-0.3,0.2-2,1.1-1.6,1.8
			c0.6,1,2.7,0.9,3.7,0.9c2.9,0,5.9,0,8.8,0.1c9.9,0.4,20.6,1,30.5-0.8c3.2-0.6,8.7-0.5,9-4.6c0.2-1.9,0.7-9.2-2-9.8
			c-0.9-0.2-2.3,0.9-3.1,1c-3.1,0.5-6.6,0.7-9.6,0.2c-3-0.5-1.8-5-4.3-6.4C927.7,942.7,925.2,941.8,925.3,943z"/>
                            <path className="st35" d="M999.8,945.2c-0.9,0-2.3-1.5-3.2-1.9c-2.8-1.1-8.7,0-11.1,1.8c-3.2,2.6-3.7,7.1-3.9,10.9
			c-0.1,1.3-0.7,3.5,0.3,4.6c1.4,1.6,3.7,1.9,5.7,2.1c5.6,0.7,11.9-0.4,17.5-0.6c1.4-0.1,5.1,0.6,6.1-0.8c0.7-1.1-0.8-2.7-1.4-3.5
			c-2.4-2.9-4.4-6.1-6.8-9C1002.3,948,1000.8,945.2,999.8,945.2z"/>
                            <path className="st41" d="M913.9,685.9l-8.3,4.1l-23.2-59.8l29.1-10.1c0,0-0.1,10.8,0.1,11.8c0.1,1,2.2,25.9,2.2,25.9
			s-0.6,4.4-1.1,6.6c-0.9,3.4-2.6,5.8-2.7,9.8c-0.2,6.7,3.8,10.9,3.8,10.9L913.9,685.9z"/>
                            <path className="st37" d="M882.3,632.2l-2.2,0.9l6.5,18.2l17.8,41.3l1.5-1.2L882.3,632.2z"/>
                            <path className="st38" d="M880.4,635.5l-1.7,1l23.9,58.8l6.4-3l-4.9,0.1L880.4,635.5z"/>
                            <path className="st42" d="M916.2,688.5l-4.3,21.5l0.5,21.1l11.3,123.2l3.7,81.9c0,0,1,2,5.3,2.6c4.3,0.6,12.4-0.9,12.4-0.9
			s2.7-45.7,4.2-87.3c0.5-13-0.4-26.8,0.1-37.1c2-43.3,3.7-48.8,3.7-48.8l5.1,15.2l24.6,157.7c0,0,2.5,2.3,8.4,1.6c3.4-0.4,7-3,7-3
			l2.8-197.3l-2-28.6l-8.6-26c0,0-13.7,9.9-45.4,9.2C926.4,692.9,916.2,688.5,916.2,688.5z"/>
                            <path className="st43" d="M925.3,943c0.2,1.8-3,3.8-4.3,4.7c-5,3.6-11.2,8.1-17,10.1c-2.3,0.8-5,1.6-7,2.9c-0.3,0.2-2,1.1-1.6,1.8
			c0.6,1,2.7,0.9,3.7,0.9c2.9,0,5.9,0,8.8,0.1c9.9,0.4,20.6,1,30.5-0.8c3.2-0.6,8.7-0.5,9-4.6c0.2-1.9,0.7-9.2-2-9.8
			c-0.9-0.2-2.3,0.9-3.1,1c-3.1,0.5-6.6,0.7-9.6,0.2c-3-0.5-1.8-5-4.3-6.4C927.7,942.7,925.2,941.8,925.3,943z"/>
                            <path className="st43" d="M999.8,945.2c-0.9,0-2.3-1.5-3.2-1.9c-2.8-1.1-8.7,0-11.1,1.8c-3.2,2.6-3.7,7.1-3.9,10.9
			c-0.1,1.3-0.7,3.5,0.3,4.6c1.4,1.6,3.7,1.9,5.7,2.1c5.6,0.7,11.9-0.4,17.5-0.6c1.4-0.1,5.1,0.6,6.1-0.8c0.7-1.1-0.8-2.7-1.4-3.5
			c-2.4-2.9-4.4-6.1-6.8-9C1002.3,948,1000.8,945.2,999.8,945.2z"/>
                            <path className="st44" d="M913.9,685.9l-8.3,4.1l-23.2-59.8l29.1-10.1c0,0-0.1,10.8,0.1,11.8c0.1,1,2.2,25.9,2.2,25.9
			s-0.6,4.4-1.1,6.6c-0.9,3.4-2.6,5.8-2.7,9.8c-0.2,6.7,3.8,10.9,3.8,10.9L913.9,685.9z"/>
                            <path className="st44" d="M882.3,632.2l-2.2,0.9l6.5,18.2l17.8,41.3l1.5-1.2L882.3,632.2z"/>
                            <path className="st44" d="M880.4,635.5l-1.7,1l23.9,58.8l6.4-3l-4.9,0.1L880.4,635.5z"/>
                            <path className="st45" d="M882.2,651c0.2-1.2-1.5,0.6-1.5,1.8c0.1,1.5,1,2.4,1.1,3.9c0,1.4,1.8,1.8,2.2,3.1c0.2,0.7-0.8,1.5-0.3,2.3
			c0.5,0.9,2.1,1.2,2.5,2.1c0.2,0.5-0.5,1.1-0.2,1.8c0.8,1.5,3.4,2.5,4.9,3.2c0.3,0.1,1.5,0.6,1.7,0.2c1.4-2-3.2-3.5-2.8-4.7
			c0.2-0.8,1.7-0.5,2-1.4c0.5-1.4-0.8-1.8-1-2.9c-0.1-0.5,0.4-1,0.3-1.6c-0.1-0.4-0.7-0.8-0.6-1.2c0.3-1.6,1.4-1.7-0.5-3.5
			c-1.4-1.3-3.3-2.6-5.2-3.1c-0.7-0.2-1.2-0.3-1.9-0.1C882.7,651,882.1,651.3,882.2,651z"/>
                            <path className="st45" d="M1002.2,727.9c-0.1-0.1-0.4,1.2-0.5,1.5c-0.4,0.9-1.1,1.7-1.6,2.5c-2.7,4.3-2.7,7.7-2.3,12.5
			c0.1,0.7,0,4,0.7,4.1c0.9,0.2,1.4-2.2,1.6-2.7c1-2.4,0.9-5.1,1.9-7.6c0.4-0.9,0.8-2.4,2.1-2.2c1.8,0.3,0.6,3.4-0.1,4.1
			c-1.1,1.2-2,2.3-2.6,3.9c-1.1,2.8-1.2,8.6-0.4,11.5c0.3,1.3,0.2,2.9,1.5,3.8c2.5,1.7,5.1-6.3,5.6-7.5c3.3-8.7,7.2-16.1,7.2-25.6
			c0-0.8-2.8,0-3.1,0.1c-2.4,0.6-4.6,1-7,1.4C1004.4,727.8,1002.8,728.3,1002.2,727.9z"/>
                            <path className="st45" d="M930.1,938.6c0,0-3.1,3.5-2.7,4.2c1.1,1.8,5.5,7.6,7.4,7.7c2.1,0.1,9.3,0.2,10.6-2.1c0.9-1.6-0.6-3.5-0.9-5
			c-0.2-1,0.5-4-0.5-4.5c-1.9-0.9-3.3,0.3-5.2,0.3C935.8,939.2,933,938.9,930.1,938.6z"/>
                            <path className="st45" d="M984.6,939.6c-0.5-0.1-0.6,0.8-0.6,1.2c-0.2,1.1,0,2.3,0,3.5c-0.1,3.2,4.2-0.6,6.7-0.5
			c1.6,0.1,6.7,1.2,8.1,0.4c0.3-0.2-1.5-2.3-1.7-2.9c-0.2-0.7,0.3-3.1-0.4-3.2C992.6,937.9,988.9,940.5,984.6,939.6z"/>
                            <g>
			<path className="st46" d="M998.9,586.6c-0.3,0-0.6-0.1-0.8-0.3c-3.2-3.3-14.4-8.5-21.3-11c-0.6-0.2-0.9-0.8-0.7-1.4
				c0.2-0.6,0.8-0.9,1.4-0.7c5.5,2,18.2,7.4,22.2,11.5c0.4,0.4,0.4,1.1,0,1.5C999.4,586.5,999.2,586.6,998.9,586.6z"/>
		</g>
                            <g>
			<path className="st46" d="M913.7,656.8c-0.6,0-1.1-0.5-1.1-1.1c0-5.2-0.6-10.5-1.3-15.6c-0.4-3.6-0.9-7.4-1.1-11
				c-0.4-6.3,1.5-12.8,3.3-18.9c0.4-1.5,0.9-3,1.3-4.5c0.4-1.3,0.3-2.7,0.2-4.2c-0.1-1.5-0.1-3,0.2-4.6c0.1-0.6,0.7-0.9,1.3-0.8
				c0.6,0.1,0.9,0.7,0.8,1.3c-0.3,1.2-0.2,2.6-0.2,4c0.1,1.6,0.1,3.2-0.3,4.9c-0.4,1.5-0.8,3-1.3,4.5c-1.7,6-3.6,12.2-3.2,18.2
				c0.2,3.6,0.7,7.3,1.1,10.9c0.6,5.2,1.3,10.6,1.3,15.9C914.7,656.3,914.3,656.8,913.7,656.8z"/>
		</g>
                            <g>
			<path className="st46" d="M948.8,694.7c-10.8,0-22.3-1.5-32.4-5.3c-0.6-0.2-0.8-0.8-0.6-1.4c0.2-0.6,0.8-0.8,1.4-0.6
				c27,10.3,64,3.3,71.3-2.9c0.6-0.6,1-1.1,0.9-1.4c-0.5-2.6,0.6-4.5,1.5-6.2c0.8-1.4,1.5-2.8,1.4-4.5c-0.3-4.1-1.6-6.6-2.7-9.1
				c-1.5-3.1-2.9-6-2.3-11.6c1.4-11.8,3.3-33.2,0.9-43.2c-0.1-0.6,0.2-1.2,0.8-1.3c0.6-0.1,1.2,0.2,1.3,0.8c2.5,10.3,0.5,32-0.9,44
				c-0.6,4.9,0.7,7.4,2.1,10.4c1.2,2.5,2.5,5.3,2.9,9.8c0.2,2.3-0.8,4.1-1.7,5.7c-0.9,1.6-1.6,3-1.3,4.7c0.2,1.2-0.3,2.4-1.7,3.5
				C985,690.2,967.8,694.7,948.8,694.7z"/>
		</g>
                            <g>
			<path className="st46" d="M914.8,688.3c-0.4,0-0.8-0.2-1-0.6L913,686c-1.6-3.1-3.2-6.2-3.8-9.7c-0.4-2.3,0.5-4.7,1.2-6.9
				c0.3-0.7,0.5-1.5,0.7-2.1c0.5-1.9,0.7-3.8,1-5.8c0.1-0.9,0.2-1.8,0.3-2.7c0.1-0.6,0.6-1,1.2-0.9c0.6,0.1,1,0.6,0.9,1.2
				c-0.1,0.9-0.2,1.8-0.3,2.7c-0.2,2-0.4,4-1,6.1c-0.2,0.7-0.5,1.5-0.8,2.3c-0.7,2-1.4,4.1-1.1,5.8c0.6,3.1,2.1,6.1,3.6,9.1l0.8,1.6
				c0.3,0.5,0.1,1.2-0.5,1.5C915.1,688.2,914.9,688.3,914.8,688.3z"/>
		</g>
                            <g>
			<path className="st46" d="M916.1,781.3c-0.6,0-1.1-0.5-1.1-1c-0.2-5.2-0.9-12.8-1.8-21.5c-1.8-18.7-4.1-42-2-53.2
				c0.3-1.6,2.9-15.5,3.9-16.9c0.4-0.5,1-0.6,1.5-0.2c0.5,0.3,0.6,1,0.3,1.5c-0.5,1-2.3,9.2-3.5,16.1c-2,11,0.2,34.1,2,52.6
				c0.9,8.8,1.6,16.4,1.8,21.7C917.2,780.8,916.7,781.3,916.1,781.3C916.1,781.3,916.1,781.3,916.1,781.3z"/>
		</g>
                            <g>
			<path className="st46" d="M1000.5,731.5c-0.6,0-1.1-0.5-1.1-1.1c-0.3-10-0.8-16.6-1.5-19.5c-2-8.5-4.7-16.3-7.9-25.5
				c-0.2-0.6,0.1-1.2,0.7-1.4c0.6-0.2,1.2,0.1,1.4,0.7c3.3,9.3,6,17.1,8,25.8c0.7,3.1,1.2,9.8,1.5,20
				C1001.6,730.9,1001.1,731.4,1000.5,731.5C1000.5,731.5,1000.5,731.5,1000.5,731.5z"/>
		</g>
                            <g>
			<path className="st46" d="M1000.5,772.1C1000.5,772.1,1000.5,772.1,1000.5,772.1c-0.6,0-1.1-0.5-1.1-1.1c0.1-3.8,0.3-12.9,0.3-22.8
				c0-0.6,0.5-1.1,1.1-1.1c0,0,0,0,0,0c0.6,0,1.1,0.5,1.1,1.1c0,10-0.1,19-0.3,22.9C1001.6,771.7,1001.1,772.1,1000.5,772.1z"/>
		</g>
                            <g>
			<path className="st46" d="M906.5,622.7c-0.6,0-1-0.4-1.1-1c-0.3-3.1-0.3-4.6-0.3-6.3c0-1.2,0-2.5-0.1-4.7l-0.1-2.1
				c-0.4-8.2-0.9-17.6,4.3-24.6c4.5-6.1,10.6-8.5,17-11c1.1-0.4,2.3-0.9,3.4-1.4c0.3-0.2,1.2-1.3,1.8-2.2c1.8-2.5,4.4-5.9,7.7-6.9
				c0.6-0.2,1.2,0.2,1.4,0.7c0.2,0.6-0.2,1.2-0.7,1.4c-2.7,0.8-5,3.9-6.6,6.1c-1.2,1.5-1.9,2.6-2.8,2.9c-1.2,0.5-2.3,0.9-3.4,1.4
				c-6.1,2.4-11.9,4.7-16,10.3c-4.7,6.4-4.3,15.3-3.9,23.2l0.1,2.1c0.1,2.2,0.1,3.5,0.1,4.7c0,1.8,0,3.1,0.3,6.1
				C907.7,622.2,907.2,622.7,906.5,622.7C906.6,622.7,906.6,622.7,906.5,622.7z"/>
		</g>
                            <g>
			<path className="st46" d="M953.2,572.7c-0.8,0-1.6,0-2.4-0.1c-3.6-0.4-11.8-2.5-13.1-6.1c-0.4-1.1-0.1-2.2,0.8-3.2
				c0.4-0.4,1.1-0.4,1.5,0c0.4,0.4,0.4,1.1,0,1.5c-0.5,0.5-0.4,0.8-0.3,0.9c0.6,1.7,6.3,4.1,11.3,4.7c4.4,0.5,11.3-0.8,14.4-2.3
				c0.5-0.3,1.2,0,1.5,0.5c0.3,0.5,0,1.2-0.5,1.5C963.5,571.5,957.8,572.7,953.2,572.7z"/>
		</g>
                            <g>
			<path className="st46" d="M959.7,652.1c-0.3,0-0.6-0.1-0.8-0.3c-5.8-5.8-12.4-12-21-17.7c-0.5-0.3-0.6-1-0.3-1.5
				c0.3-0.5,1-0.6,1.5-0.3c8.7,5.8,15.4,12.1,21.3,18c0.4,0.4,0.4,1.1,0,1.5C960.3,652,960,652.1,959.7,652.1z"/>
		</g>
                            <g>
			<path className="st46" d="M878.9,637.2c-0.4,0-0.8-0.2-1-0.6c-0.3-0.5-0.1-1.1,0.4-1.4c0.1-0.1,0.2-0.1,2.1-0.8
				c0.6-0.2,1.2,0.1,1.4,0.7c0.2,0.6-0.1,1.2-0.7,1.4c-1,0.4-1.7,0.6-1.8,0.7C879.3,637.2,879.1,637.2,878.9,637.2z"/>
		</g>
                            <g>
			<path className="st46" d="M884.4,652c-0.4,0-0.8-0.3-1-0.7c-3.1-8-4.9-12.7-5.4-14.5c-0.2-0.6,0.2-1.2,0.7-1.3
				c0.6-0.2,1.2,0.2,1.3,0.7c0.5,1.7,2.3,6.5,5.3,14.3c0.2,0.6-0.1,1.2-0.6,1.4C884.7,652,884.6,652,884.4,652z"/>
		</g>
                            <g>
			<path className="st46" d="M902.5,696.9c-0.9,0-0.9,0-11.8-27c-0.2-0.6,0-1.2,0.6-1.4c0.6-0.2,1.2,0,1.4,0.6
				c4.6,11.6,9.1,22.4,10.3,25.4c1.6-0.7,5.3-2.8,7.7-4.2c1.6-0.9,3.1-1.7,3.9-2.2c0.5-0.3,1.2-0.1,1.5,0.5c0.3,0.5,0.1,1.2-0.5,1.5
				c-0.9,0.5-2.3,1.3-3.9,2.2C905.7,695.7,903.5,696.9,902.5,696.9z"/>
		</g>
                            <g>
			<path className="st46" d="M890.3,658.5c-0.3,0-0.6-0.1-1-0.2c-0.4-0.2-1.2-0.4-2-0.6c-2.4-0.7-4.3-1.2-5.4-1.9
				c-0.1,0-0.2-0.1-0.4-0.2c-2.2-1.5-2.1-3.1-1.8-3.8c0.5-1.4,2-2.2,3.2-2.1c1.2,0.1,3.9,1.4,8,4.1c1.6,1,1.8,2.5,1.3,3.5
				C891.9,658.1,891.2,658.5,890.3,658.5z M882.5,653.6c0.1,0,0.3,0.1,0.4,0.2c0.7,0.6,3.4,1.4,5.1,1.8c0.9,0.3,1.7,0.5,2.1,0.7
				c0.2-0.3,0-0.4-0.2-0.6c-4.6-3-6.6-3.8-7.1-3.8c-0.3,0-0.9,0.3-1,0.6C881.7,652.8,882,653.2,882.5,653.6z"/>
		</g>
                            <g>
			<path className="st46" d="M889.7,662.5c-0.2,0-0.4,0-0.7-0.1c-2.1-0.5-4.6-1.6-6.7-3.2c-0.9-0.6-2-2.2-1.6-3.5
				c0.1-0.4,0.5-1.3,1.8-1.5c0.6-0.1,1.1,0.3,1.2,0.9c0.1,0.6-0.3,1.1-0.9,1.2c-0.1,0-0.1,0-0.1,0c0.1,0,0.5,0.7,0.9,1.1
				c1.9,1.4,4.1,2.4,5.9,2.8c0,0,0.4,0.1,0.5-0.1c0.1-0.2,0-0.9-1.1-1.7c-0.5-0.3-0.6-1-0.3-1.5c0.3-0.5,1-0.6,1.5-0.3
				c2.1,1.5,2.3,3.3,1.8,4.3C891.5,662,890.7,662.5,889.7,662.5z"/>
		</g>
                            <g>
			<path className="st46" d="M888.9,665.3c-1.8,0-3.6-0.4-5.1-1.1c-1.1-0.5-1.7-1.6-1.6-2.9c0.1-1.2,0.8-2.2,2-2.6
				c0.6-0.2,1.2,0.1,1.4,0.7c0.2,0.6-0.1,1.2-0.7,1.4c-0.4,0.1-0.4,0.5-0.4,0.6c0,0.1,0,0.6,0.4,0.7c1.5,0.7,3.5,1,5.3,0.8
				c0.4,0,0.7-0.1,0.8-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.5-0.4-0.5-1.1-0.2-1.5c0.4-0.5,1.1-0.5,1.5-0.2c1.1,0.9,1.5,1.8,1.3,2.6
				c-0.4,1.4-2.3,1.6-2.9,1.7C889.9,665.3,889.4,665.3,888.9,665.3z"/>
		</g>
                            <g>
			<path className="st46" d="M891.2,670.5c-2.1,0-5-2.6-5.1-2.6c-0.8-0.7-1.8-1.8-1.5-3.1c0.1-0.5,0.5-1.3,1.8-1.7
				c0.6-0.2,1.2,0.2,1.4,0.7c0.2,0.6-0.1,1.2-0.7,1.4c-0.2,0.1-0.3,0.1-0.4,0.2c0.1,0,0.2,0.3,0.9,0.9c0.8,0.8,2.4,1.8,3.2,2
				c0-0.1-0.1-0.3-0.1-0.5c-0.1-0.2-1.1-1.2-1.6-1.7c-0.4-0.4-0.7-0.7-0.9-0.9c-0.4-0.5-0.3-1.1,0.1-1.5c0.5-0.4,1.1-0.3,1.5,0.1
				c0.1,0.2,0.4,0.4,0.8,0.8c1.3,1.3,2,2,2.2,2.6c0.5,1.5,0.3,2.5-0.6,3.1C891.9,670.4,891.6,670.5,891.2,670.5z"/>
		</g>
                            <g>
			<path className="st46" d="M880.4,634c-0.4,0-0.8-0.2-1-0.6c-0.3-0.5-0.1-1.2,0.4-1.4c0.1-0.1,0.2-0.1,2.3-0.9
				c0.6-0.2,1.2,0.1,1.4,0.7c0.2,0.6-0.1,1.2-0.7,1.4c-1.1,0.4-1.9,0.7-2.1,0.8C880.7,634,880.6,634,880.4,634z"/>
		</g>
                            <g>
			<path className="st46" d="M886.7,651.1c-0.4,0-0.8-0.3-1-0.7l-0.2-0.4c-2.7-6.9-5.7-14.7-6.3-16.6c-0.2-0.6,0.2-1.2,0.7-1.3
				c0.6-0.2,1.2,0.2,1.3,0.7c0.5,1.8,3.5,9.6,6.2,16.4l0.2,0.4c0.2,0.6-0.1,1.2-0.6,1.4C887,651.1,886.9,651.1,886.7,651.1z"/>
		</g>
                            <g>
			<path className="st46" d="M904,693.7c-0.8,0-0.8,0-8.4-18.9l-3.7-9.2c-0.2-0.6,0-1.2,0.6-1.4c0.6-0.2,1.2,0,1.4,0.6l3.7,9.2
				c3.1,7.6,6,14.9,7,17.3c1.6-0.7,5.3-2.8,7.7-4.2l1.5-0.9c0.5-0.3,1.2-0.1,1.5,0.4c0.3,0.5,0.1,1.2-0.4,1.5l-1.5,0.9
				C907.1,692.6,904.9,693.7,904,693.7z"/>
		</g>
                            <g>
			<path className="st46" d="M882.2,631.7c-0.4,0-0.8-0.2-1-0.6c-0.3-0.5-0.1-1.1,0.4-1.4c0.2-0.1,29.2-10.4,29.9-10.4
				c0.6,0,1.1,0.5,1.1,1.1c0,0.5-0.3,0.9-0.8,1.1c-1.8,0.5-28.1,9.7-29.2,10.2C882.5,631.7,882.4,631.7,882.2,631.7z"/>
		</g>
                            <g>
			<path className="st46" d="M890.1,652.7c-0.4,0-0.8-0.3-1-0.7c-2.5-6.3-7.2-18.6-7.8-20.7c-0.2-0.6,0.2-1.2,0.7-1.3
				c0.6-0.2,1.2,0.2,1.3,0.7c0.5,1.8,4.9,13.4,7.7,20.5c0.2,0.6-0.1,1.2-0.6,1.4C890.3,652.7,890.2,652.7,890.1,652.7z"/>
		</g>
                            <g>
			<path className="st46" d="M905.8,691.4c-0.8,0-0.8,0-6.2-13.7c-2.3-5.8-5-12.6-7.4-18.6c-0.2-0.6,0-1.2,0.6-1.4
				c0.6-0.2,1.2,0,1.4,0.6c2.4,6,5.1,12.9,7.4,18.6c2.2,5.4,4.1,10.2,4.8,12.1c1.6-0.7,5.3-2.8,6.8-3.7c0.5-0.3,1.2-0.1,1.5,0.4
				c0.3,0.5,0.1,1.2-0.4,1.5C908,690.8,906.6,691.4,905.8,691.4z"/>
		</g>
                            <g>
			<path className="st46" d="M963.7,813c-0.5,0-1-0.4-1.1-0.9c-9.4-62-19.9-79.3-27-82.9c-0.5-0.3-0.8-0.9-0.5-1.5
				c0.3-0.5,0.9-0.8,1.5-0.5c11,5.5,20.5,33.9,28.2,84.5c0.1,0.6-0.3,1.2-0.9,1.2C963.9,812.9,963.8,813,963.7,813z"/>
		</g>
                            <g>
			<path className="st46" d="M998.1,936.9c-0.6,0-1.1-0.5-1.1-1c-0.8-16.1,0.6-140.3,2.1-159.9c0-0.6,0.5-1,1.2-1c0.6,0,1,0.6,1,1.2
				c-1.5,19.5-2.9,143.6-2.1,159.6C999.2,936.4,998.7,936.9,998.1,936.9C998.1,936.9,998.1,936.9,998.1,936.9z"/>
		</g>
                            <g>
			<path className="st46" d="M983,938.2c-0.5,0-1-0.4-1.1-0.9c-1.4-8.1-11-66.8-14.1-89.4c-0.3-2.4-0.9-5.9-1.6-10
				c-1.5-8.3-3.3-18.6-3.3-23.8c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1c0,5,1.8,15.2,3.2,23.5c0.7,4.1,1.3,7.6,1.7,10
				c2.9,21.5,12.5,80.1,14.1,89.3c0.1,0.6-0.3,1.2-0.9,1.3C983.2,938.2,983.1,938.2,983,938.2z"/>
		</g>
                            <g>
			<path className="st46" d="M982.2,962.4c-0.2,0-0.5-0.1-0.7-0.2c-2.2-1.6-1.5-4.6-0.5-9.2c0.4-2,0.9-4.3,1.3-7c0-0.4,0.1-0.9,0.2-1.5
				c0.3-1.7,0.7-4.3,0.7-5.8c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1c0,1.6-0.5,4.2-0.8,6.1c-0.1,0.6-0.2,1.1-0.2,1.5
				c-0.4,2.7-0.9,5.1-1.3,7.1c-0.8,3.6-1.3,6.2-0.3,7c0.5,0.4,0.6,1,0.2,1.5C982.8,962.2,982.5,962.4,982.2,962.4z"/>
		</g>
                            <g>
			<path className="st46" d="M927.5,936.6c-0.6,0-1.1-0.5-1.1-1.1c0-14.3-2.6-87.9-10.6-148.8c-0.1-0.6,0.3-1.1,0.9-1.2
				c0.6-0.1,1.1,0.3,1.2,0.9c7.9,61,10.6,134.7,10.6,149.1C928.6,936.1,928.1,936.6,927.5,936.6z"/>
		</g>
                            <g>
			<path className="st46" d="M949.4,816.8c-0.6,0-1.1-0.5-1.1-1.1c-0.3-10.3,2.1-45.6,4.3-52.1c0.2-0.6,0.8-0.9,1.4-0.7
				c0.6,0.2,0.9,0.8,0.7,1.4c-2.1,6.4-4.5,41.2-4.2,51.4C950.5,816.2,950,816.7,949.4,816.8C949.4,816.8,949.4,816.8,949.4,816.8z"
            />
		</g>
                            <g>
			<path className="st46" d="M945.1,938.8c-0.6,0-1.1-0.5-1.1-1.1c0-15.2,1.1-32.2,2.2-50.1c1.4-22.1,2.8-44.9,2.1-64.1
				c0-0.6,0.4-1.1,1-1.1c0.6-0.1,1.1,0.4,1.1,1c0.7,19.2-0.7,42.1-2.1,64.3c-1.1,17.9-2.2,34.9-2.2,50
				C946.2,938.3,945.7,938.8,945.1,938.8z"/>
		</g>
                            <g>
			<path className="st46" d="M900.6,964.4c-5.2,0-5.6-1.1-5.7-1.4c-0.2-0.5-0.2-1.1,0.1-1.7c0.9-1.9,4.3-3.2,9-5c1.7-0.6,3.2-1.2,4-1.6
				l1.9-1.1c6.5-3.7,16.4-9.3,17.6-16c0.1-0.6,0.7-1,1.3-0.9c0.6,0.1,1,0.7,0.9,1.3c-1.4,7.8-11.8,13.7-18.7,17.6l-1.9,1.1
				c-0.9,0.5-2.5,1.1-4.3,1.8c-2.1,0.8-6.8,2.5-7.7,3.6c1.2,0.2,4.3,0.3,6.3,0.2c1-0.1,3.4-0.1,6.6-0.2c9-0.3,32.4-1.1,35.7-2.3
				c0.7-5.5-0.1-7.9-0.9-10.4c-0.8-2.5-1.7-5.1-1.2-10.6c0.1-0.6,0.6-1,1.2-1c0.6,0.1,1,0.6,1,1.2c-0.4,5.1,0.3,7.3,1.1,9.7
				c0.9,2.7,1.8,5.4,1,11.8c-0.2,1.7-2.1,2.7-37.7,3.8c-3.1,0.1-5.5,0.2-6.5,0.2C902.4,964.3,901.4,964.4,900.6,964.4z"/>
		</g>
                            <g>
			<path className="st46" d="M994,963.4c-6,0-11.5-0.3-12.4-1.2c-0.4-0.4-0.5-1.1-0.1-1.5c0.4-0.4,1.1-0.5,1.5-0.1
				c2.1,1.1,23.1,0.7,27.4-0.1c-1-1.8-5.6-7.2-7.2-9.1c-0.9-1-1.5-1.7-1.6-1.9c-1.1-1.6-1.9-2.7-2.6-3.7c-2.3-3.1-3.1-4.1-2.7-8.8
				c0-0.6,0.6-1,1.2-1c0.6,0,1,0.6,1,1.2c-0.3,3.9,0.1,4.4,2.3,7.4c0.7,1,1.6,2.2,2.7,3.8c0.1,0.2,0.7,0.9,1.5,1.8
				c6.3,7.4,8.2,10.1,7.8,11.5c-0.2,0.7-0.9,0.9-1.1,1C1010,962.9,1001.6,963.4,994,963.4z"/>
		</g>
                            <g>
			<path className="st46" d="M936.2,940.1c-3.7,0-7.5-0.8-9.6-3.4c-0.4-0.5-0.3-1.2,0.2-1.5c0.5-0.4,1.2-0.3,1.5,0.2
				c3.3,4.2,13,2.3,15.8,1.6c0.6-0.1,1.2,0.2,1.3,0.8c0.1,0.6-0.2,1.2-0.8,1.3C942.8,939.4,939.5,940.1,936.2,940.1z"/>
		</g>
                            <g>
			<path className="st46" d="M989.7,940.3c-2.5,0-4.9-0.6-7-1.7c-0.5-0.3-0.7-0.9-0.4-1.5c0.3-0.5,0.9-0.7,1.5-0.4
				c4.3,2.3,10.2,1.8,13.9-1.2c0.5-0.4,1.2-0.3,1.5,0.2c0.4,0.5,0.3,1.2-0.2,1.5C996.4,939.3,993,940.3,989.7,940.3z"/>
		</g>
                            <g>
			<path className="st46" d="M1016.8,715.4C1016.8,715.4,1016.8,715.4,1016.8,715.4c-0.7-0.1-1.1-0.6-1.1-1.2c1-12-1.2-23.4-3.4-34.4
				c-1.4-7-2.7-13.6-3-19.9c-0.3-5.6-0.2-11.3-0.2-16.8c0.1-7.4,0.2-15-0.7-22.4c-0.2-1.5-0.3-3-0.5-4.6c-0.9-8.9-1.7-18.1-6.7-25.5
				c-0.3-0.5-0.2-1.2,0.3-1.5c0.5-0.3,1.2-0.2,1.5,0.3c5.2,7.8,6.1,17.3,7,26.5c0.1,1.5,0.3,3,0.5,4.5c0.8,7.6,0.8,15.3,0.7,22.7
				c-0.1,5.5-0.1,11.1,0.2,16.7c0.3,6.1,1.6,12.7,3,19.6c2.2,11.2,4.4,22.7,3.4,35C1017.9,715,1017.4,715.4,1016.8,715.4z"/>
		</g>
                            <g>
			<path className="st46" d="M1011.1,746.3c-0.2,0-0.3,0-0.5-0.1c-0.5-0.3-0.8-0.9-0.5-1.4c3.6-7.7,3.7-11.3,4-16.8l0.1-2.3
				c0-0.6,0.5-1,1.2-1c0.6,0,1.1,0.6,1,1.2l-0.1,2.3c-0.3,5.5-0.5,9.5-4.2,17.6C1011.9,746,1011.5,746.3,1011.1,746.3z"/>
		</g>
                            <g>
			<path className="st46" d="M1005.2,728.4c-0.9,0-1.7,0-2.4,0c-0.7,0-1.3,0-1.8,0c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1
				c0.6,0,1.2,0,1.9,0c4.1,0.1,10.2,0.2,12.6-2.9c1.3-1.7,1.5-4.4,0.4-8c-0.2-0.6,0.2-1.2,0.7-1.4c0.6-0.2,1.2,0.2,1.4,0.7
				c1.3,4.3,1,7.6-0.8,10C1014.6,728,1009.4,728.4,1005.2,728.4z"/>
		</g>
                            <g>
			<path className="st46" d="M998.4,749.6c-0.4,0-0.7-0.1-1-0.4c-0.6-0.6-0.7-1.7-0.6-3c0-0.2,0-0.3,0-0.4l-0.1-1.4
				c-0.3-2.9-0.7-6.6,0.3-9.4c0.3-0.9,1-1.9,1.5-2.7c1-1.5,2-2.9,1.5-4.3c-0.2-0.6,0.1-1.2,0.7-1.4c0.6-0.2,1.2,0.1,1.4,0.7
				c0.7,2.3-0.6,4.3-1.8,6.1c-0.5,0.8-1.1,1.6-1.3,2.3c-0.8,2.3-0.5,5.7-0.2,8.4l0.1,1.4c0,0.1,0,0.3,0,0.6c0,0,0,0.1,0,0.1l0.1-0.2
				c0.9-1.5,1.1-3.3,1.4-5.2c0.1-1,0.2-1.9,0.4-2.8c0.2-0.9,1.4-3.5,3.3-3.2c0.6,0.1,1,0.6,0.9,1.2c-0.1,0.6-0.6,1-1.2,0.9
				c-0.1,0.1-0.7,1-0.8,1.6c-0.2,0.9-0.3,1.7-0.4,2.6c-0.3,2-0.5,4.1-1.7,6C1000.3,748.3,999.6,749.5,998.4,749.6
				C998.5,749.6,998.4,749.6,998.4,749.6z"/>
		</g>
                            <g>
			<path className="st46" d="M1003.1,760.8c0,0-0.1,0-0.1,0c-0.6,0-1.7-0.4-2.1-2.2c-0.4-1.8,0.7-4.4,1.6-6.6c0.4-0.9,0.7-1.6,0.9-2.2
				c1.6-5.8,1.7-12.2,0.5-12.7c-0.5-0.3-0.8-0.9-0.5-1.4c0.3-0.5,0.9-0.8,1.4-0.5c4.2,2,0.7,15.2,0.6,15.3c-0.2,0.6-0.6,1.5-1,2.5
				c-0.8,1.7-1.8,4.1-1.5,5.2c0.1,0.4,0.2,0.5,0.2,0.5c0.3-0.1,1.9-1.5,3.1-4.7c0.9-2.2,1.7-4.2,2.4-5.8c0.7-1.6,1.2-2.8,1.4-3.5
				c0.2-0.6,0.8-0.9,1.4-0.7c0.6,0.2,0.9,0.8,0.7,1.4c-0.3,0.9-0.8,2.1-1.5,3.7c-0.7,1.6-1.5,3.5-2.4,5.7
				C1007.2,757.5,1005.2,760.8,1003.1,760.8z"/>
		</g>
                            <g>
			<path className="st46" d="M1002.3,754.5c-0.2,0-0.3,0-0.5-0.1c-0.8-0.4-1.4-1.1-1.6-1.9c-0.7-2.4,1.1-5.6,2.4-8
				c0.3-0.5,0.5-0.9,0.7-1.3c0.3-0.5,0.9-0.8,1.5-0.5c0.5,0.3,0.8,0.9,0.5,1.5c-0.2,0.4-0.4,0.8-0.7,1.4c-0.9,1.7-2.7,4.9-2.2,6.4
				c0.1,0.3,0.2,0.5,0.5,0.6c0.5,0.3,0.7,0.9,0.5,1.5C1003.1,754.3,1002.7,754.5,1002.3,754.5z"/>
		</g>
                            <g>
			<path className="st46" d="M1001.3,744.8c-0.2,0-0.3,0-0.5-0.1c-0.5-0.3-0.8-0.9-0.5-1.5c1-2.1,1.9-3.5,3.6-5.2c0.4-0.4,1.1-0.4,1.5,0
				c0.4,0.4,0.4,1.1,0,1.5c-1.4,1.4-2.2,2.7-3.1,4.6C1002.1,744.6,1001.7,744.8,1001.3,744.8z"/>
		</g>
                            <g>
			<path className="st46" d="M938.9,948.1c-0.2,0-0.5-0.1-0.6-0.2c-0.5-0.4-0.6-1-0.2-1.5c0.3-0.4,0.7-0.7,1-1c0.5-0.4,0.7-0.6,0.7-1.1
				c0,0-0.2-0.3-0.4-0.5c-0.3-0.4-0.8-0.9-1.1-1.7c-0.2-0.6,0.1-1.2,0.6-1.4c0.6-0.2,1.2,0.1,1.4,0.6c0.2,0.4,0.4,0.7,0.7,1.1
				c0.4,0.5,0.9,1.1,0.9,2.1c-0.1,1.4-0.9,2.1-1.5,2.6c-0.2,0.2-0.4,0.4-0.6,0.6C939.5,948,939.2,948.1,938.9,948.1z"/>
		</g>
                            <g>
			<path className="st46" d="M935.9,951.1c-1.9,0-3.7-0.4-5.1-1.4c-0.8-0.6-1.3-1.8-1.9-3.1c-0.5-1.3-1.2-2.9-1.9-2.9
				c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1c2.2,0,3.2,2.4,3.9,4.3c0.3,0.9,0.8,1.9,1.1,2.2c2.7,2,9.6,0.3,12.6-1.2
				c0.5-0.3,1.2-0.1,1.5,0.5c0.3,0.5,0.1,1.2-0.5,1.5C943.7,949.7,939.6,951.1,935.9,951.1z"/>
		</g>
                            <g>
			<path className="st46" d="M984.1,947.1c-0.4,0-0.8-0.2-1-0.6c-0.3-0.5-0.1-1.2,0.5-1.5c6.4-3.2,9.5-4.1,16.2-1.1
				c0.5,0.2,0.8,0.9,0.6,1.4c-0.2,0.6-0.9,0.8-1.4,0.6c-5.9-2.6-8.4-1.9-14.3,1.1C984.4,947.1,984.3,947.1,984.1,947.1z"/>
		</g>
                            <path className="st47" d="M989.7,631.2c0,0,8.8,6.3,20.5,1.5"/>
                            <g>
			<g id="save-the-children-name-outline">
				<path className="st15" d="M939.3,609.8c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.1,0c0,0-0.1-0.1-0.1-0.1
					c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.3,0-0.4,0.1c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1,0,0.1,0,0.2
					c0,0.1,0.1,0.1,0.2,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.1,0.2,0.2
					c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.3,0,0.4c0,0.2-0.1,0.4-0.2,0.5c-0.1,0.2-0.3,0.3-0.4,0.4c-0.2,0.1-0.4,0.2-0.6,0.3
					c-0.2,0.1-0.5,0.1-0.7,0.1c-0.1,0-0.3,0-0.4-0.1c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.2-0.1-0.2-0.2
					l0.4-0.4c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0.1c0,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0.1,0.2,0.1
					c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.3,0,0.4-0.1c0.1-0.1,0.2-0.2,0.2-0.4c0-0.1,0-0.2,0-0.2c0-0.1-0.1-0.1-0.2-0.2
					c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.1-0.2-0.1-0.2-0.2c-0.1-0.1-0.1-0.2-0.1-0.3
					c0-0.2,0-0.3,0.1-0.5c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.3,0.4-0.4c0.2-0.1,0.3-0.2,0.5-0.3c0.2-0.1,0.4-0.1,0.6-0.1
					c0.1,0,0.3,0,0.4,0c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0.1,0.1,0.1,0.2,0.2L939.3,609.8z"/>
                <path className="st15" d="M941.2,613.1c-0.1,0-0.1,0-0.2,0c0,0-0.1-0.1-0.1-0.1l0-0.2c-0.1,0.1-0.2,0.1-0.3,0.1
					c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.4-0.1c-0.1,0-0.2-0.1-0.2-0.2
					c-0.1-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0.4-0.2,0.6-0.2
					c0.3-0.1,0.6-0.1,0.9-0.1l0-0.1c0-0.1,0-0.3,0-0.4c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0-0.2,0.1
					c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.1,0c0,0-0.1-0.1-0.1-0.1l-0.1-0.3c0.2-0.1,0.4-0.2,0.7-0.3
					c0.2-0.1,0.5-0.1,0.7-0.1c0.2,0,0.3,0,0.5,0.1c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.1,0.4c0,0.1,0,0.3,0,0.4l-0.5,1.7
					L941.2,613.1z M940.3,612.5c0.1,0,0.2,0,0.3,0c0.1,0,0.2-0.1,0.3-0.2l0.1-0.4c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2,0-0.3,0.1
					c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1,0.1-0.1,0.1c0,0.1,0,0.2,0,0.2C940.2,612.5,940.2,612.5,940.3,612.5z"/>
                <path className="st15" d="M943.8,613.2l-0.8,0l-0.4-2.8l0.7,0c0.1,0,0.1,0,0.1,0c0,0,0.1,0.1,0.1,0.1l0.1,1.3c0,0.1,0,0.2,0,0.3
					c0,0.1,0,0.2,0,0.3c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.2-0.3l0.8-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.2,0l0.7,0
					L943.8,613.2z"/>
                <path className="st15" d="M947.2,610.4c0.2,0,0.4,0,0.5,0.1c0.1,0.1,0.3,0.1,0.3,0.3c0.1,0.1,0.1,0.3,0.2,0.4c0,0.2,0,0.4-0.1,0.5
					c0,0.1,0,0.1-0.1,0.2c0,0,0,0.1-0.1,0.1c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0l-1.7-0.1c0,0.2,0,0.4,0.1,0.5c0.1,0.1,0.2,0.2,0.4,0.2
					c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.1,0.1l0.2,0.3
					c-0.1,0.1-0.2,0.2-0.4,0.2c-0.1,0.1-0.2,0.1-0.4,0.1c-0.1,0-0.2,0.1-0.4,0.1c-0.1,0-0.2,0-0.3,0c-0.2,0-0.4,0-0.6-0.1
					c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.2,0-0.4,0.1-0.7c0-0.2,0.1-0.4,0.2-0.5c0.2-0.3,0.6-0.6,1-0.7
					C946.8,610.4,947,610.4,947.2,610.4z M947.1,611c-0.1,0-0.3,0-0.4,0.1c-0.1,0.1-0.2,0.2-0.3,0.4l1.1,0c0-0.1,0-0.1,0-0.2
					c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.1-0.1-0.1C947.3,611,947.2,611,947.1,611L947.1,611z"/>
                <path className="st15" d="M950.5,613.4c-0.1,0-0.2,0-0.4-0.1c-0.2-0.1-0.3-0.2-0.3-0.4c0-0.1,0-0.2,0-0.3l0.4-1.4l-0.2,0
					c-0.1,0-0.1-0.1-0.1-0.2c0,0,0,0,0,0l0.1-0.3l0.5-0.1l0.4-0.7c0-0.1,0.1-0.1,0.2-0.1l0.5,0l-0.2,0.8l0.7,0l-0.2,0.6l-0.7,0
					l-0.4,1.4c0,0.1,0,0.1,0,0.2c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0
					c0,0,0,0,0,0.1l0.2,0.4c-0.1,0.1-0.3,0.1-0.4,0.2C950.8,613.4,950.6,613.4,950.5,613.4z"/>
                <path className="st15" d="M951.7,613.4l1.1-4l0.9,0l-0.4,1.5c0.1-0.1,0.3-0.1,0.4-0.2c0.2-0.1,0.3-0.1,0.5-0.1c0.1,0,0.3,0,0.4,0.1
					c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.3-0.1,0.4l-0.5,1.8l-0.9,0l0.5-1.8c0-0.1,0-0.2,0-0.3
					c-0.1-0.1-0.1-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.2,0.1-0.3,0.2l-0.6,2L951.7,613.4z"/>
                <path className="st15" d="M957.1,610.7c0.2,0,0.4,0,0.5,0.1c0.1,0.1,0.3,0.1,0.3,0.3c0.1,0.1,0.1,0.3,0.2,0.4c0,0.2,0,0.4-0.1,0.5
					c0,0.1,0,0.1-0.1,0.2c0,0,0,0.1-0.1,0.1c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0l-1.7-0.1c0,0.2,0,0.4,0.1,0.5c0.1,0.1,0.2,0.2,0.4,0.2
					c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.1,0.1l0.2,0.3
					c-0.1,0.1-0.2,0.2-0.4,0.2c-0.1,0.1-0.2,0.1-0.4,0.1c-0.1,0-0.2,0.1-0.4,0.1c-0.1,0-0.2,0-0.3,0c-0.2,0-0.4,0-0.6-0.1
					c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.2,0-0.4,0.1-0.7c0-0.2,0.1-0.4,0.2-0.5c0.2-0.3,0.6-0.6,1-0.7
					C956.6,610.7,956.9,610.7,957.1,610.7z M956.9,611.3c-0.1,0-0.3,0-0.4,0.1c-0.1,0.1-0.2,0.2-0.3,0.4l1.1,0c0-0.1,0-0.1,0-0.2
					c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.1-0.1-0.1C957.1,611.3,957,611.3,956.9,611.3z"/>
                <path className="st15" d="M962.4,612.7C962.5,612.7,962.5,612.7,962.4,612.7c0.1,0,0.1,0,0.1,0.1l0.3,0.4c-0.2,0.2-0.5,0.4-0.8,0.5
					c-0.3,0.1-0.6,0.1-1,0.1c-0.3,0-0.6-0.1-0.8-0.2c-0.2-0.1-0.4-0.3-0.5-0.5c-0.1-0.2-0.2-0.4-0.2-0.7c0-0.3,0-0.5,0.1-0.8
					c0.1-0.3,0.2-0.6,0.4-0.8c0.2-0.2,0.4-0.5,0.6-0.6c0.3-0.2,0.5-0.3,0.8-0.4c0.3-0.1,0.6-0.1,0.9-0.1c0.2,0,0.3,0,0.4,0.1
					c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.1,0.2,0.2l-0.5,0.4c0,0-0.1,0-0.1,0.1c0,0-0.1,0-0.1,0
					c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1-0.1c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.2,0-0.3,0
					c-0.2,0-0.3,0-0.5,0.1c-0.2,0.1-0.3,0.1-0.5,0.2c-0.1,0.1-0.3,0.3-0.4,0.4c-0.1,0.2-0.2,0.4-0.2,0.5c-0.1,0.2-0.1,0.4-0.1,0.6
					c0,0.2,0.1,0.3,0.1,0.4c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.3,0.1,0.4,0.1c0.1,0,0.2,0,0.2,0c0.1,0,0.1,0,0.2,0
					c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1C962.3,612.8,962.3,612.7,962.4,612.7C962.4,612.7,962.4,612.7,962.4,612.7z"/>
                <path className="st15" d="M963.1,613.8l1.1-4l0.9,0l-0.4,1.5c0.1-0.1,0.3-0.1,0.4-0.2c0.2-0.1,0.3-0.1,0.5-0.1c0.1,0,0.3,0,0.4,0.1
					c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.3-0.1,0.4l-0.5,1.8l-0.9,0l0.5-1.8c0-0.1,0-0.2,0-0.3
					c-0.1-0.1-0.1-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.2,0.1-0.3,0.2l-0.6,2L963.1,613.8z"/>
                <path className="st15" d="M968.5,610.4c0,0.1-0.1,0.1-0.1,0.2c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.2,0
					c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0.1-0.1,0.1-0.2
					c0-0.1,0.1-0.1,0.2-0.2c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0.1,0.2,0.1,0.2,0.3
					C968.5,610.2,968.5,610.3,968.5,610.4z M968.1,611.1l-0.8,2.8l-0.9,0l0.8-2.8L968.1,611.1z"/>
                <path className="st15" d="M970.2,610l-1.1,4l-0.9,0l1.1-4L970.2,610z"/>
                <path className="st15" d="M971.8,614.1c0,0-0.1,0-0.1,0c0,0,0-0.1,0-0.1l0-0.2c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0-0.2,0.1-0.2,0.1
					c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.3,0-0.4-0.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.1-0.3-0.1-0.5
					c0-0.2,0-0.4,0.1-0.6c0.1-0.2,0.1-0.4,0.3-0.6c0.1-0.2,0.2-0.3,0.4-0.5c0.1-0.1,0.3-0.2,0.5-0.3c0.2-0.1,0.4-0.1,0.5-0.1
					c0.1,0,0.3,0,0.4,0.1c0.1,0,0.2,0.1,0.2,0.2l0.4-1.4l0.9,0l-1.1,4L971.8,614.1z M971.1,613.5c0.1,0,0.1,0,0.2,0
					c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.1-0.1c0.1,0,0.1-0.1,0.1-0.1l0.3-1.2c0-0.1-0.1-0.1-0.2-0.2c-0.1,0-0.1,0-0.2,0
					c-0.1,0-0.2,0-0.2,0c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.1,0.3-0.2,0.4c0,0.1-0.1,0.2-0.1,0.4
					c0,0.1,0,0.2,0,0.2c0,0.1,0.1,0.1,0.1,0.1C971,613.4,971,613.5,971.1,613.5z"/>
                <path className="st15" d="M973.1,614.1l0.8-2.8l0.5,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1l0,0.3
					c0.1-0.1,0.3-0.3,0.5-0.4c0.2-0.1,0.3-0.1,0.5-0.1c0.1,0,0.2,0,0.3,0.1l-0.3,0.6c0,0,0,0.1-0.1,0.1c0,0-0.1,0-0.1,0
					c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4,0.1-0.6,0.3l-0.5,1.7L973.1,614.1z"/>
                <path className="st15" d="M977.4,611.4c0.2,0,0.4,0,0.5,0.1c0.1,0.1,0.3,0.1,0.3,0.3c0.1,0.1,0.1,0.3,0.2,0.4c0,0.2,0,0.4-0.1,0.5
					c0,0.1,0,0.1-0.1,0.2c0,0,0,0.1-0.1,0.1c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0l-1.7-0.1c0,0.2,0,0.4,0.1,0.5c0.1,0.1,0.2,0.2,0.4,0.2
					c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.1,0.1l0.2,0.3
					c-0.1,0.1-0.2,0.2-0.4,0.2c-0.1,0.1-0.2,0.1-0.4,0.1c-0.1,0-0.2,0.1-0.4,0.1c-0.1,0-0.2,0-0.3,0c-0.2,0-0.4,0-0.6-0.1
					c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.2,0-0.4,0.1-0.7c0-0.2,0.1-0.4,0.2-0.5c0.2-0.3,0.6-0.6,1-0.7
					C977,611.4,977.2,611.4,977.4,611.4z M977.3,612c-0.1,0-0.3,0-0.4,0.1c-0.1,0.1-0.2,0.2-0.3,0.4l1.1,0c0-0.1,0-0.1,0-0.2
					c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.1-0.1-0.1C977.4,612,977.3,612,977.3,612z"/>
                <path className="st15" d="M978.5,614.3l0.8-2.8l0.5,0c0,0,0.1,0,0.1,0c0,0,0,0.1,0,0.1l0,0.2c0.1,0,0.1-0.1,0.2-0.1
					c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.2,0,0.2-0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.3,0,0.4,0.1c0.1,0.1,0.2,0.1,0.3,0.2
					c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.3-0.1,0.4l-0.5,1.8l-0.9,0l0.5-1.8c0-0.1,0-0.2,0-0.3c-0.1-0.1-0.1-0.1-0.3-0.1
					c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.2,0.1-0.3,0.2l-0.6,2L978.5,614.3z"/>
			</g>
                                <g id="save-the-children-symbol">
				<path className="st15" d="M934,607.7c0.7-0.6,1-0.8,1-1c0-0.1-0.1-0.2-0.3-0.3c-0.2-0.1-0.4-0.1-0.6,0c-0.1,0.1-0.3,0.2-0.4,0.3
					l-0.2,0.2l0,0c-0.1-0.1-0.2-0.2-0.2-0.3c-0.6-0.7-1.5-1.2-2.5-1.3c-0.3-0.4-0.8-0.7-1.4-0.6c-0.5,0.1-1,0.3-1.3,0.7
					c-1.2,0.3-2.3,0.8-3.3,1.6l0,0c-0.2-0.1-0.5,0-0.8,0.1c-0.1,0-0.2,0.1-0.3,0.2c0,0.1,0,0.2,0.2,0.5l0,0c-1.1,1.1-1.8,2.4-2,3.8
					c-0.2,1.3,0.1,2.6,0.9,3.5c0.3,0.3,0.6,0.6,1,0.8c1.8,1,4.2,1,6.5,0l0,0c0.2-0.1,0.3-0.3,0.3-0.4l0-0.1
					c-0.1-0.7-0.5-2.8,0.6-4.7c0.5-0.8,1.3-1.6,2.1-2.3l0,0c0.4,0.9,0.4,2,0,3c-0.3,1-0.9,1.9-1.8,2.7c0,0-0.1,0.1,0,0.1l0,0.1
					c0,0.1,0.1,0.1,0.1,0.1c0,0,0,0,0.1,0l0,0C933.9,612.4,934.8,609.7,934,607.7z M925.6,612c-0.5,1-0.9,2.1-1,3.2l0,0
					c-0.4-0.2-0.7-0.5-1-0.8c-0.7-0.8-1.1-1.9-1-3.1c0.1-1.1,0.6-2.3,1.5-3.2l0,0l0,0l0,0c0.2,0.2,0.3,0.5,0.5,0.7
					c0.5,0.7,1,1.4,1.3,1.9C926.1,611,925.9,611.3,925.6,612L925.6,612z M932.8,607.4c-1.6,1.1-2.9,1.7-4,1.8
					c-0.8,0-1.6-0.2-2.4-0.8c-0.5-0.4-0.9-0.8-1.3-1.3l0,0l0,0c0.8-0.6,1.7-1,2.6-1.2l0,0l0,0l0,0c-0.2,0.3-0.3,0.7-0.3,1
					c-0.1,0.6,0.1,1.1,0.5,1.5c0.5,0.5,1.6,0.5,2.3-0.2c0,0,0.1-0.1,0.1-0.1c0.5-0.5,0.8-1.1,0.9-1.7c0-0.1,0-0.2,0-0.3l0,0
					c0.6,0.2,1,0.6,1.4,1C932.6,607.2,932.7,607.3,932.8,607.4L932.8,607.4z"/>
			</g>
		</g>
                            <path className="st48" d="M957.1,491.2c-6.9-4.7-15.5,0.5-21,4.8c-13.4,10.7-12.8,28.8-8.6,43.9c0.4,1.6,1.2,3.3,2.1,4.6
			c0.9,1.4,1.7,2.1,2.9,3.2c1.5,1.4,3.4,3.6,5.6,3.9c4.4,0.6,8.5-1.6,12.9-1.6c3.4,0,13-0.2,13.6,4.4c0.3,1.9-0.5,3.7-0.2,5.6
			c0.7,5.8,2.3,10.9,1,17.1c-1.7,7.9-5,15.5-4.4,23.8c0.3,4.1,1.9,8,3.4,11.7c0.3,0.6,1.6,2.8,2.4,2.8c0.9,0,0.2-4.9,0.1-5.4
			c-0.7-3.4,0-7.3,0.9-10.6c0.4-1.8,2-2.1,2.6-3.3c1.6-3.8,2.5-7.9,3.9-11.8c1.5-4.1,3.3-9.5,2.4-14c-0.8-4.2-4.6-8.7-7.1-12.1
			c-0.4-0.6-3.7-3.3-3.7-3.8c0-0.5,1-0.1,1.5-0.4c1.6-0.7,2.9-1.5,4.3-2.7c3.1-2.7,6.9-6.5,8.8-10.2c2.6-5.3,1.7-8.5,1.7-14.1
			c0-9.8,1.9-23-6.8-30c-3.6-2.9-8.6-5.3-13.2-6C960.9,490.9,957.9,491.8,957.1,491.2z"/>
                            <path className="st49" d="M944.2,506.2c-5.5,0-8.9,5.8-10.4,10.8c-0.2,0.8-0.7,6-1.4,6.5c-0.6,0.4-1.9-0.9-2.8-0.2
			c-3.6,3-2.5,9.6,1,12.2c0.7,0.5,2.1,0.8,2.6,1.3c1.8,1.8,2,6.2,3.1,8.6c1.3,2.9,4.5,5.2,5.4,8.2c0.7,2.6,0.3,5.6-0.1,8.2
			c-0.4,2.4-2.2,4.4-0.1,6.5c3.7,3.7,12.6,3.7,17.5,3.2c1.6-0.2,6.6-1,7-2.9c0.4-1.5-0.8-3.6-1-5.1c-0.7-4.2-1-8.2,0.8-12.2
			c1-2.2,2.5-4,3.5-6.2c1-2.2,1.4-5.1,2.9-7c0.8-1,1.7-0.2,2.8-0.7c2.5-1.3,4-6.1,4.3-8.7c0-0.4,0-1.1,0-1.5c0-4-4.1-0.5-5.2-2.4
			c-1.4-2.5-2.1-4.9-4.6-6.7c-3.9-2.8-9.5-3.5-13.6-6c-2.7-1.7-6.1-5.1-9.3-5.1L944.2,506.2z"/>
                            <g>
			<path className="st46" d="M953.3,558.8c0,0-0.1,0-0.1,0c-9.5-0.1-20.2-9.9-20.4-22.9c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1
				c0.2,11.6,10,20.7,18.3,20.8c0,0,0.1,0,0.1,0c3.5,0,7.5-2,10.8-5.3c4.3-4.4,6.8-10.2,6.7-16c0-0.6,0.5-1.1,1.1-1.1
				c0.5-0.1,1.1,0.5,1.1,1.1c0.1,6.4-2.6,12.8-7.3,17.6C961.9,556.7,957.4,558.8,953.3,558.8z"/>
		</g>
                            <g>
			<path className="st46" d="M932.9,537.2c-0.6,0-1.2-0.1-1.7-0.4c-1.9-0.9-3.5-2.9-4.2-5.3c-1.1-4-0.1-8,2.3-9.1c0.9-0.5,2-0.4,3,0.1
				c0.5,0.3,0.8,0.9,0.5,1.5c-0.3,0.5-0.9,0.8-1.5,0.5c-0.4-0.2-0.8-0.2-1.1-0.1c-1.2,0.6-2,3.4-1.1,6.5c0.6,2.1,1.9,3.5,3,4
				c0.4,0.2,0.9,0.3,1.3,0.1c0.5-0.3,1.2,0,1.4,0.5c0.3,0.5,0,1.2-0.5,1.5C933.8,537.1,933.4,537.2,932.9,537.2z"/>
		</g>
                            <g>
			<path className="st46" d="M973.7,538.6c-0.7,0-1.4-0.3-2-0.8c-0.4-0.4-0.5-1.1-0.1-1.5c0.4-0.4,1.1-0.5,1.5-0.1
				c0.2,0.2,0.5,0.3,0.8,0.2c0.7-0.1,1.9-1,2.9-3.1c1.4-2.9,1.2-5.9,0.3-6.8c-0.2-0.1-0.4-0.3-0.8-0.2c-0.6,0.2-1.2-0.2-1.3-0.8
				c-0.1-0.6,0.2-1.2,0.8-1.3c1.1-0.3,2.1,0,2.8,0.7c1.9,1.7,2,5.7,0.2,9.3c-1.1,2.3-2.8,3.9-4.4,4.3
				C974.1,538.6,973.9,538.6,973.7,538.6z"/>
		</g>
                            <g>
			<path className="st46" d="M939.7,567.2c-0.3,0-0.6-0.1-0.8-0.3c-0.4-0.4-0.4-1.1,0-1.5c1.3-1.3,1.6-6.4,1.9-12c0-0.6,0.5-1,1.1-1
				c0.6,0,1.1,0.5,1,1.1c-0.3,7.9-0.8,11.7-2.5,13.4C940.3,567.1,940,567.2,939.7,567.2z"/>
		</g>
                            <g>
			<path className="st46" d="M964.9,568.2c-2-5-2.6-6.6-1.1-15.6c0.1-0.6,0.7-1,1.3-0.9c0.6,0.1,1,0.7,0.9,1.3
				c-1.4,8.3-0.9,9.6,0.9,14.1l-0.9,0.6L964.9,568.2z"/>
		</g>
                            <g>
			<path className="st46" d="M932.9,533.3c-0.5,0-1-0.4-1.1-1c0-0.2-0.2-0.4-0.5-0.8c-0.3-0.4-0.7-0.8-0.9-1.5c-0.2-0.7,0-1.4,0.3-2
				c0.1-0.2,0.2-0.6,0.2-0.7c-0.3-0.5-0.2-1.2,0.3-1.5c0.5-0.3,1.2-0.2,1.5,0.3c0.7,1,0.2,2.1-0.1,2.7c-0.1,0.2-0.2,0.4-0.2,0.5
				c0.1,0.1,0.3,0.3,0.5,0.6c0.4,0.5,0.9,1.1,1,2c0.1,0.6-0.4,1.1-1,1.2C933,533.3,933,533.3,932.9,533.3z"/>
		</g>
                            <g>
			<path className="st46" d="M972.9,533.4c-0.3,0-0.6-0.1-0.8-0.4c-0.4-0.5-0.3-1.1,0.1-1.5l0.3-0.2c0.9-0.8,1-0.9,0.9-1.2
				c-0.1-0.6,0.2-1.2,0.8-1.3c0.6-0.2,1.2,0.2,1.3,0.8c0.4,1.7-0.7,2.6-1.6,3.4l-0.3,0.2C973.4,533.3,973.1,533.4,972.9,533.4z"/>
		</g>
                            <g>
			<path className="st34" d="M959,544.1c1.1,1-1.8,7.6-6.7,7.4c-2.9-0.1-6-4.1-6.1-7.4C946.2,543.4,958.7,543.9,959,544.1z"/>
                                <path className="st46" d="M952.5,552.5c-0.1,0-0.2,0-0.3,0c-3.7-0.2-7-4.9-7.1-8.5c0-0.4,0.3-0.9,0.7-1.1c0.6-0.3,12.9-0.6,13.9,0.3
				l0,0c0.8,0.7,0.8,2,0.1,3.6C958.7,549.6,956,552.5,952.5,552.5z M947.4,544.9c0.5,2.6,2.9,5.4,4.9,5.5c2,0.1,3.6-1.4,4.3-2.3
				c1-1.2,1.4-2.4,1.5-3C956,544.9,949.7,544.7,947.4,544.9z"/>
		</g>
                            <g>
			<path className="st46" d="M953.5,538.5c-0.1,0-0.1,0-0.2,0c-0.6-0.1-1-0.7-0.9-1.3c0.1-0.6,0.4-1,0.6-1.4c0.1-0.2,0.3-0.4,0.3-0.5
				c0-0.3-0.2-0.6-0.4-1.1c-0.2-0.4-0.4-0.8-0.6-1.3c-0.2-0.9-0.2-1.6-0.1-2.3c0-0.3,0-0.6,0-0.9c0-0.6,0.5-1.1,1.1-1.1
				c0.6,0,1.1,0.5,1.1,1.1c0,0.4,0,0.7-0.1,1.1c0,0.6-0.1,1.1,0,1.6c0.1,0.2,0.2,0.5,0.4,0.8c0.3,0.6,0.8,1.4,0.7,2.3
				c-0.1,0.6-0.4,1.1-0.6,1.5c-0.1,0.2-0.3,0.4-0.3,0.6C954.4,538.2,954,538.5,953.5,538.5z"/>
		</g>
                            <path className="st50" d="M941.6,529.6c0-0.9,0.6-1.5,1.2-1.5c0.7,0,1.3,0.7,1.3,1.5s-0.6,1.5-1.3,1.5
			C942.2,531.1,941.6,530.4,941.6,529.6z"/>
                            <path className="st50" d="M961.5,529.6c0-0.9,0.6-1.6,1.3-1.6s1.3,0.7,1.3,1.6c0,0.9-0.6,1.6-1.3,1.6S961.5,530.5,961.5,529.6z"/>
                            <g>
			<path className="st46" d="M940.3,524.3c-0.3,0-0.7-0.2-0.9-0.4c-0.4-0.5-0.2-1.2,0.2-1.5c0.1-0.1,3.5-2.5,7.1-0.3
				c0.5,0.3,0.7,1,0.4,1.5c-0.3,0.5-1,0.7-1.5,0.4c-2.4-1.4-4.7,0.2-4.7,0.2C940.7,524.3,940.5,524.3,940.3,524.3z"/>
		</g>
                            <g>
			<path className="st46" d="M966.7,524.1c-0.2,0-0.4-0.1-0.6-0.2c-0.1-0.1-2.3-1.6-4.7-0.2c-0.5,0.3-1.2,0.1-1.5-0.4
				c-0.3-0.5-0.1-1.2,0.4-1.5c3.6-2.2,7,0.2,7.1,0.3c0.5,0.4,0.6,1,0.2,1.5C967.3,524,967,524.1,966.7,524.1z"/>
		</g>
                            <g>
			<path className="st46" d="M938.8,553.1c-2.1,0-4.1-0.8-5.9-2.3c-6.6-5.6-9.5-20.9-9.1-29.8c0.5-9.3,3-16.5,7.6-22.1
				c5.1-6.1,18.2-13.9,25.9-8c0.5,0.4,0.6,1.1,0.2,1.5c-0.4,0.5-1,0.6-1.5,0.2c-6.5-5-18.5,2.4-22.9,7.6c-4.4,5.2-6.7,12-7.1,20.8
				c-0.4,8.2,2.3,23,8.3,28c1.8,1.6,3.8,2.1,6,1.6c0.6-0.1,1.2,0.2,1.3,0.8c0.1,0.6-0.2,1.2-0.8,1.3
				C940.1,553,939.4,553.1,938.8,553.1z"/>
		</g>
                            <g>
			<path className="st46" d="M966.2,555.4c-0.4,0-0.8-0.1-1.2-0.3c-0.5-0.3-0.7-1-0.4-1.5c0.3-0.5,1-0.7,1.5-0.4
				c0.2,0.1,1.4-0.2,3.8-2.1c5.1-4.2,12.1-13.4,11.5-21c-0.3-3.3-0.2-6.2-0.2-8.9c0.1-5.2,0.1-9.6-1.7-15.4c-1.9-6-5-9.4-11.2-12.3
				c-2.1-1-6.1-2.5-9.1-1.2c-0.6,0.2-1.2,0-1.4-0.6c-0.2-0.6,0-1.2,0.6-1.4c3.6-1.5,7.9-0.1,10.9,1.3c6.8,3.2,10.3,7,12.4,13.7
				c2,6.1,1.9,10.9,1.8,16.1c0,2.7-0.1,5.5,0.2,8.7c0.8,9.7-9,21-14.4,24.3C968,555,967,555.4,966.2,555.4z"/>
		</g>
                            <g>
			<path className="st46" d="M933.1,523.2c-0.6,0-1.1-0.5-1.1-1.1c0-4.3,1-7.3,2.4-10.6c0.2-0.5,0.9-0.8,1.4-0.5
				c0.5,0.2,0.8,0.9,0.5,1.4c-1.6,3.6-2.2,6.3-2.2,9.7C934.2,522.7,933.7,523.2,933.1,523.2z"/>
		</g>
                            <g>
			<path className="st46" d="M967.3,516.1c-4.3,0-9.6-1.5-13.5-3.9c0,0-0.2-0.1-0.5-0.3c-4.2-3-5.6-3.8-6-3.9c-2.5-1-5.7-0.6-6.9,1.6
				c-0.3,0.5-0.9,0.7-1.5,0.5c-0.5-0.3-0.7-0.9-0.5-1.5c1.8-3.4,6.3-3.9,9.6-2.6c0.8,0.3,2.7,1.5,6.4,4.2l0.4,0.3
				c4.7,2.9,11.9,4.4,15.7,3.1c0.6-0.2,1.2,0.1,1.4,0.7c0.2,0.6-0.1,1.2-0.7,1.4C970.1,516,968.8,516.1,967.3,516.1z"/>
		</g>
                            <g>
			<path className="st46" d="M975,526.7c-0.4,0-0.8-0.2-1-0.6c-3.2-7.2-9.3-8.3-17.7-9.9l-2-0.4c-0.6-0.1-1-0.7-0.9-1.3
				c0.1-0.6,0.7-1,1.3-0.9l2,0.4c8.7,1.6,15.6,2.9,19.3,11.1c0.2,0.6,0,1.2-0.5,1.4C975.3,526.7,975.2,526.7,975,526.7z"/>
		</g>
                            <g>
			<path className="st46" d="M971.6,595.1c-0.1,0-0.1,0-0.2,0c-0.6-0.1-1-0.7-0.9-1.3c0.5-2.7,1.3-5.4,2.2-8.3c1.5-4.6,3-9.3,2.6-13.6
				c-0.2-2.8-3-8.9-4.4-11.1c-2.2-3.5-2.3-3.6-4.2-5l-0.3-0.2c-0.5-0.4-0.6-1-0.2-1.5c0.4-0.5,1-0.6,1.5-0.2l0.3,0.2
				c2.1,1.6,2.4,1.8,4.7,5.6c1.5,2.3,4.5,8.8,4.7,12.1c0.3,4.7-1.2,9.7-2.7,14.4c-0.9,2.8-1.7,5.5-2.2,8
				C972.5,594.7,972.1,595.1,971.6,595.1z"/>
		</g>
                            <g>
			<path className="st46" d="M965.7,615.8c-0.3,0-0.7-0.2-0.9-0.5c-7.2-10.3-4.2-23.1-1.7-34.4c0.5-2.2,1-4.3,1.4-6.3
				c0.7-3.4,0.6-4.5,0.5-6.5c-0.1-1-0.1-2.2-0.1-4.1c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1c0,1.8,0.1,2.9,0.1,3.9
				c0.1,2,0.2,3.4-0.5,7c-0.4,2.1-0.9,4.2-1.4,6.3c-2.5,10.8-5.3,23.1,1.3,32.7c0.3,0.5,0.2,1.2-0.3,1.5
				C966.1,615.7,965.9,615.8,965.7,615.8z"/>
		</g>
                            <g>
			<path className="st46" d="M966.4,604.9c-0.6,0-1.1-0.5-1.1-1.1c0-4.1,1-7.8,2.4-12.2c0.2-0.6,0.8-0.9,1.4-0.7
				c0.6,0.2,0.9,0.8,0.7,1.4c-1.4,4.1-2.3,7.7-2.3,11.5C967.5,604.4,967,604.9,966.4,604.9z"/>
		</g>
                            <g>
			<path className="st46" d="M974,542.5c-0.1,0-0.2,0-0.2,0c-0.8-0.1-1.5-0.8-1.4-2c0.1-0.6,0.6-1,1.2-1c0.1,0,0.1,0,0.2,0
				c0-0.4,0-0.8,0-1.3c0,0-0.4-1-0.6-1.5c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1l0,0c0,0,0,0,0-0.1l0,0l0,0c-0.2-0.9,0.3-1.3,0.6-1.4
				c0.6-0.2,1.2,0.1,1.4,0.7l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0.1c0.8,2.1,0.8,2.1,0.8,2.3c0,0.9,0,1.8-0.2,2.6
				C975.6,541.9,974.8,542.5,974,542.5z"/>
		</g>
                            <g>
			<path className="st46" d="M932.2,540.4c-0.1,0-0.2,0-0.2,0c-1.3-0.3-2.2-0.9-2.7-1.8c-0.5-1-0.5-2.2,0.1-3.7c0.5-1.2,0.7-1.8,1.4-2
				c0.6-0.2,1.1,0.1,1.4,0.3c0.5,0.3,0.7,1,0.4,1.5c-0.2,0.3-0.6,0.5-0.9,0.5c-0.1,0.1-0.1,0.3-0.2,0.5c-0.3,0.8-0.4,1.5-0.2,1.8
				c0.2,0.4,0.8,0.6,1.2,0.7c0.6,0.1,1,0.7,0.8,1.3C933.1,540.1,932.7,540.4,932.2,540.4z"/>
		</g>
	</g>
                        <g className="navidad-2">
		<path className="st51" d="M354.3,480.2c-2.9-3.1-6.7-5.8-9.2-6.9c-2.3-1-5.1,1.8-2.9,3.7c2.6,2.2,5.8,4.8,9.3,6.9
			c3.5,4.5-2.9,6.5-7.2,5.1c-6.9-2.3-13.8-7.3-19.3-11.9c-2.3-1.9-5.6,1.3-3.3,3.3c3.1,2.7,4.9,5.8,6.1,9.7c-0.9,6.6-3.2,8.4-7,5.5
			c-1.9-1.1-3.6-2.4-5.3-3.8c-4.2-3.3-7.8-7.2-12.2-10.3c-2-1.4-4.3,1.3-3.2,3.2c2.5,4.6,9.9,17.1-0.4,15.1
			c-3.9-0.7-7.1-3.4-9.9-6.1c-3.9-3.7-7.3-7.7-11.5-11c-0.3-1-1.3-1.6-2.3-1.6c-2.1-0.7-3.8,2.2-2.2,3.9c0.1,3.8,0.4,7.5-0.4,11.3
			c-2.7,13.1-16.3-4.4-16.8-10.1c-0.2-2.2-3.1-3.3-4.4-1.2c-1.4,2.3-2.5,4.7-3.8,7c-1.7,4.2-5.4,4.5-11,0.9
			c-0.9-2.4-0.9-4.9-0.7-7.4c0.2-2.3-3.4-3.3-4.4-1.2c-2.4,5.4-6.4,13.3-13.5,11.9c-8.6-1.6-4.6-13.2-1.2-17.3
			c1.9-2.4-1-5.2-3.3-3.3c-5.9,4.7-11.1,10.3-17.2,14.9c-4.2,3.2-10.8,5.4-10.9-2.6c0-3.8,1.9-8.2,3.7-11.5c1-1.9-1.2-4.7-3.2-3.2
			c-5.8,4.1-11.1,8.6-17.4,11.9c-5.4,2.8-5-2.9-2.7-5.9c1.3-1.7,2.9-3.2,4.5-4.7c2.4-2.3,4.9-4.4,7.5-6.5c2.2-1.8-0.6-4.8-2.9-3.7
			c-9.8,4.8-19.2,8.6-29.5,6c15-11.5,31.9-21.4,40.7-38.6c-0.3,1.8,0.2,3.5,2.3,4.8c3.7,2.4,8.8,0.5,13.7-2.4c-1.4,4-1,7.5,4.2,8.6
			c6.2,1.3,12.3-1.7,16.8-6.3c0.6,1.9,1.8,3.4,4.3,4.1c3.7,1.1,7-1.4,9.5-4.6c0.8,3.2,2.6,5.9,5.9,6.8c7.7,2.1,9.2-10,10.3-14.7
			c0.6-3-3.9-4.2-4.6-1.3c-0.4,1.7-0.7,3.4-1.1,5.1c-0.1,3.1-2.1,3.9-6,2.4c-0.5-2.1-0.5-4.2,0-6.3c0.7-3-3.4-4-4.6-1.3
			c-1.4,3.4-3.1,6.3-6.2,8.4c-5.1,3.5-1.7-8-1.5-8.5c1-2.9-3.6-4.1-4.6-1.3c-1.7,5.3-6,9.1-10.6,11.8c-7.4,0.5-9.3-1.5-5.6-5.7
			c1.4-2.5,3.7-4.6,5.8-6.5c2.3-2.1-1-5.2-3.3-3.3c-4,3.2-8.2,6.1-12.6,8.6c-4.2,2.4-7.6,1.2-3.4-3.1c0.5-0.5,1-1,1.5-1.4
			c2.9-1.9,5.6-4,8.2-6.2c2-1.7-0.7-5.3-2.9-3.7c-1.2,0.9-4.4,3.1-7.6,5.8c-3.3,2.1-6.8,3.8-10.6,4.5c1.4-4.4,3.8-8,7.4-11.4
			c2.2-2.1-1.1-5.3-3.3-3.3c-5.1,4.3-10.4,8.1-16.8,7.3c6.7-6.1,13.9-11.7,20-18.5c7.7-8.4,13-18.7,18.1-28.8c2.1-1,4.3-2.4,6.3-4.1
			c-0.1,1.9,0.6,3.6,2.5,4.7c3.9,2.3,8.5-0.2,12.1-3.4c0.1,3.8,1.3,7.4,5.6,7.5c7.5,0.1,11.8-7.5,13.6-13.8c0.9-2.9-3.7-4.2-4.6-1.3
			c-0.9,3.1-2.1,5.7-4.4,8.2c-5.6,6.2-5.7-3.2-4.8-6.9c0.6-2.1-2.4-4.2-3.9-2.3c-2,2.6-3.9,4.6-6.6,6.5c-7.2,5.1-2.1-5.5-0.6-7.7
			c1.5-2.3-1.8-4.7-3.7-2.9c-3.3,3.3-6.9,6.4-10.7,9.1c-0.7,0.5-7.9,5.5-6.1-1.2c0.8-3.3,3.5-6.9,5.8-9.4c2-2.3-1.1-5.4-3.3-3.3
			c-4.3,3.9-8.1,8.1-13.7,9.2c2.7-4,8-6.1,11.7-8.9c4.6-3.5,8.2-8.3,11.7-12.8c6.1-7.9,10.6-16.4,14.6-25.4c0.1,0.4,0.2,0.8,0.4,1.2
			c0.7,1.7,2.5,2.3,4,1c4.9-4.2,7.8-9.6,11.3-14.9c1.7-2.5-2.4-4.9-4.1-2.4c-2.4,3.5-4.5,7.1-7.1,10.3c0.1-3.1,1-6,3-8.6
			c1.2-2.3-1.8-4.9-3.7-2.9c-3.9,4.1-8.9,8.6-14.8,9.2c7.3-8,16.6-13.7,23-22.7c6.9-9.8,13.1-21.2,16.9-32.8
			c6.6,12.3,9.7,26.3,17.3,38.1c7.7,12.1,16.7,23.6,27.9,32.7c-5.6,0.6-10.1-1.7-15.4-5c-1.9-1.2-4.6,1.3-3.2,3.2
			c2.1,2.9,4,5.7,5.3,9.1c1.6,4.4-12-5.1-11.2-4.4c-1.5-1.2-4-0.6-4,1.7c0,3,0,6,0,9c-2.9-1.4-5.1-3.4-6.6-6.1
			c-1.2-1.9-4.5-1.3-4.4,1.2c-2.9,7.6-5,7.6-6.3-0.1c0.7-3-3.8-4.2-4.6-1.3c-2.7,11.2,10.5,15.1,14.5,7c2.4,2.8,5.2,4.8,9.7,4.9
			c1.3,0,2.4-1.1,2.4-2.4c0-2.5,0-5,0-7.5c4,3,9.2,6,13.1,3.7c0.5-0.3,0.9-0.6,1.2-1c4.9,18.9,16.7,35.5,31.5,48.3
			c-8.5-0.3-16.9-2.6-21.5-10.5c-1.6-2.6-5.4-0.3-4.1,2.4c2.5,4.9,5.3,10.2,5.8,15.8c0.1,1.5-8.3-6.9-8.6-7.3
			c-1.2-1.7-2.3-3.6-3.4-5.4c-1.4-2.3-5-0.8-4.3,1.8c0.8,2.4,1.3,4.8,1.3,7.4c0.5,8.3-7.3,2-9.3-1.7c-1.4-2.5-4.7-0.7-4.3,1.8
			c0.5,3.2-0.4,11.4-5.4,7.1c-2.2-1.9-2.4-5.1-2.3-7.7c0.1-3-4.6-3-4.7,0c-0.2,5.4,2.8,14.4,9.9,14c4.4-0.3,6.3-3,7-6.3
			c2.6,3.1,5.9,5.3,9.7,3.1c2.8-1.7,3.9-4.7,4.1-8c3.4,4.4,7.6,8.2,11.9,6.1c4.1-2,3.7-6.8,2-11.7c1.1,0.6,2.2,1.1,3.4,1.5
			c-0.2,0.5-0.2,1.2,0.2,1.8c5.8,10.3,9.2,20.7,16.6,30.1c4.1,5.1,8.1,10.2,13.2,14.3c3.9,3.2,8.2,5.8,11.4,9.7
			c-3.8,0.6-7.6,0.1-11.4-1.1c-0.6-0.8-1.5-1-2.3-0.8c-6.6-2.5-12.9-6.7-18.2-10.3c-1.7-1.1-4.2,0.7-3.5,2.7c1.5,4,3.7,7.7,4.5,11.9
			c-5.1-0.8-8.1-4.8-11.5-8.7c-1.3-1.6-4.1-0.3-4,1.7c0.1,2.5,0.2,5,0.2,7.5c-2.7-1.3-5.4-2.6-8.1-3.9c-1.6-2.2-4.8-0.9-4.3,1.8
			c-3.8,3.9-6.6,3.8-8.4-0.2c-1.9-2.4-5.2,1-3.3,3.3c5.1,6.4,13.9,8.2,16,2c2.7,2.3,5.9,3.3,10.3,2.8c1.4-0.2,2.2-1,2.4-2.4
			c0.2-1.6,0.2-3.1,0.2-4.7c3.6,3.5,7.5,6,13.2,5.7c1.3-0.1,2.4-1,2.4-2.4c0-3.3-0.9-6.1-2.1-8.9c4.2,2.6,8.7,5.1,13.4,6.7
			c7.5,13.9,17.2,30.8,31,39.6C367.1,486.3,360.2,483.9,354.3,480.2z"/>
                            <g>
			<path className="st52" d="M266.6,293.8c-11.3,0-13.3,21.2,1.7,19.8C283.3,312.3,277.9,293.8,266.6,293.8z"/>
		</g>
                            <g>
			<path className="st32" d="M236.5,323.3c-11.3,0-13.3,21.2,1.7,19.8C253.3,341.7,247.8,323.3,236.5,323.3z"/>
		</g>
                            <g>
			<path className="st14" d="M282.8,348.9c-11.3,0-13.3,21.2,1.7,19.8C299.6,367.4,294.1,348.9,282.8,348.9z"/>
		</g>
                            <g>
			<path className="st52" d="M226.6,384.8c-11.3,0-13.3,21.2,1.7,19.8C243.3,403.3,237.9,384.8,226.6,384.8z"/>
		</g>
                            <g>
			<path className="st32" d="M284.8,406.6c-11.3,0-13.3,21.2,1.7,19.8C301.6,425.1,296.1,406.6,284.8,406.6z"/>
		</g>
                            <g>
			<path className="st32" d="M208.3,451.7c-11.3,0-13.3,21.2,1.7,19.8C225,470.2,219.5,451.7,208.3,451.7z"/>
		</g>
                            <g>
			<path className="st14" d="M265.8,451.7c-11.3,0-13.3,21.2,1.7,19.8C282.6,470.2,277.1,451.7,265.8,451.7z"/>
		</g>
                            <g>
			<path className="st52" d="M317.3,451.7c-11.3,0-13.3,21.2,1.7,19.8C334,470.2,328.6,451.7,317.3,451.7z"/>
		</g>
                            <path className="st53" d="M239.5,500.5l-4.8,32.2c0,0-6.2,17.1,27.4,12.3c33.5-4.8,32.2-15.1,32.2-15.1l-4.8-32.2l-13.7-14.4
			c0,0,3.4,17.1-5.5,19.2c-8.9,2.1-18.5-11.4-18.5-11.4L239.5,500.5z"/>
                            <g>
			<path className="st46" d="M257.1,244.6c-3.8,14.1-11.8,29.4-21.2,40.7c-6.7,8.1-16,13.2-22.4,21.7c-0.9,1.2,0.1,3.1,1.4,3.5
				c9.3,2.2,17.4-3.7,23.5-10.2c-1.2-1-2.5-1.9-3.7-2.9c-3,5.6-5.3,10.7-3,16.9c0.7,1.7,2.5,2.3,4,1c4.9-4.2,7.8-9.6,11.3-14.9
				c1.7-2.5-2.4-4.9-4.1-2.4c-3.3,4.9-6,10-10.6,13.9c1.3,0.3,2.6,0.7,3.9,1c-1.3-4.9-0.5-9.3,2.5-13.2c1.2-2.3-1.8-4.9-3.7-2.9
				c-4.8,5.1-11.3,10.8-18.9,9c0.5,1.2,0.9,2.3,1.4,3.5c7.5-9.8,18.5-15.8,25.7-26c7.9-11.1,14.8-24.4,18.3-37.6
				C262.5,242.9,257.9,241.7,257.1,244.6L257.1,244.6z"/>
		</g>
                            <g>
			<path className="st46" d="M229,306.9c-6,14.4-13.4,27.3-24.4,38.5c-5.8,6-15.2,8-17.3,16.9c-0.4,1.5,0.7,2.9,2.3,3
				c9.1,0.5,14.5-5.2,20.8-11c-1.1-1.1-2.2-2.2-3.3-3.3c-3.2,3.6-13.3,17.9-4.4,21.1c7.6,2.7,17.4-8.3,22.1-12.9
				c-1.2-1-2.5-1.9-3.7-2.9c-2.3,3.5-8.2,12.3-2.4,15.7c6.2,3.6,13.8-4.7,17.1-8.7c-1.3-0.8-2.6-1.5-4-2.3c-1.1,4.3-2.9,15,4.6,15
				c7.5,0.1,11.8-7.5,13.6-13.8c0.9-2.9-3.7-4.2-4.6-1.3c-0.9,3.1-2.1,5.7-4.4,8.2c-5.6,6.2-5.7-3.2-4.8-6.9
				c0.6-2.1-2.4-4.2-3.9-2.3c-2,2.6-3.9,4.6-6.6,6.5c-7.2,5.1-2.1-5.5-0.6-7.7c1.5-2.3-1.8-4.7-3.7-2.9c-3.3,3.3-6.9,6.4-10.7,9.1
				c-0.7,0.5-7.9,5.5-6.1-1.2c0.9-3.3,3.5-6.9,5.8-9.4c2-2.3-1.1-5.4-3.3-3.3c-5.3,4.8-9.7,10-17.5,9.6c0.8,1,1.5,2,2.3,3
				c1.5-6.4,8.6-8.7,13.3-12.3c4.6-3.5,8.2-8.3,11.7-12.8c7.3-9.3,12.2-19.6,16.7-30.4C234.8,305.3,230.2,304.1,229,306.9L229,306.9
				z"/>
		</g>
                            <g>
			<path className="st46" d="M257.4,245.7c8.8,13.4,11.5,29.5,20.2,43c8.7,13.5,18.9,26.4,32,35.9c0.2-1.4,0.4-2.9,0.6-4.3
				c-8,3-13.4,0.3-20.1-3.9c-1.9-1.2-4.6,1.3-3.2,3.2c2.1,2.9,4,5.7,5.3,9.1c1.6,4.4-12-5.1-11.2-4.4c-1.5-1.2-4-0.6-4,1.7
				c0,4.1,0,8.2,0,12.3c0.8-0.8,1.6-1.6,2.4-2.4c-4.1-1.3-7-3.6-8.9-7c-1.2-1.9-4.5-1.3-4.4,1.2c-2.9,7.6-5,7.6-6.3-0.1
				c0.7-3-3.8-4.2-4.6-1.3c-3.2,13.3,15.9,16.3,15.6,1.3c-1.5,0.4-2.9,0.8-4.4,1.2c3.4,5.2,6.4,9.2,13,9.4c1.3,0,2.4-1.1,2.4-2.4
				c0-4.1,0-8.2,0-12.3c-1.3,0.6-2.7,1.1-4,1.7c3.7,3,11.7,10.1,17.1,6.9c6.4-3.7-1.5-13.9-3.9-17.3c-1.1,1.1-2.2,2.2-3.2,3.2
				c8.1,5.1,14.5,7.8,23.7,4.3c2-0.7,2.3-3.1,0.6-4.3c-13.3-9.7-23.2-23.1-32-36.8c-8-12.5-10.3-27.9-18.5-40.4
				C259.8,240.8,255.7,243.2,257.4,245.7L257.4,245.7z"/>
		</g>
                            <g>
			<path className="st46" d="M294,323.3c3,25.5,18.8,47.6,38.8,62.9c0.4-1.5,0.8-2.9,1.2-4.4c-10.5,0.5-22.2-0.5-28.1-10.5
				c-1.6-2.6-5.4-0.3-4.1,2.4c2.5,4.9,5.3,10.2,5.8,15.8c0.1,1.5-8.3-6.9-8.6-7.3c-1.2-1.7-2.3-3.6-3.4-5.4c-1.4-2.3-5-0.8-4.3,1.8
				c0.8,2.4,1.3,4.8,1.3,7.4c0.5,8.3-7.3,2-9.3-1.7c-1.4-2.5-4.7-0.7-4.3,1.8c0.5,3.2-0.4,11.4-5.4,7.1c-2.2-1.9-2.4-5.1-2.3-7.7
				c0.1-3-4.6-3-4.7,0c-0.2,5.4,2.8,14.4,9.9,14c7.8-0.5,8-8.5,7.1-14.6c-1.4,0.6-2.9,1.2-4.3,1.8c2.4,4.3,7.6,13.3,14,9.6
				c6.1-3.6,4.1-13.6,2.6-18.9c-1.4,0.6-2.9,1.2-4.3,1.8c2.7,4.6,10.2,18.8,17.7,15.2c7.9-3.8-0.8-17.9-3.3-23
				c-1.4,0.8-2.7,1.6-4.1,2.4c6.8,11.4,19.8,13.4,32.2,12.8c2.5-0.1,3-3,1.2-4.4c-18.5-14.2-33.7-35.2-36.5-58.9
				C298.4,320.3,293.6,320.2,294,323.3L294,323.3z"/>
		</g>
                            <g>
			<path className="st46" d="M206,368.6c-5,10-10.3,19.5-17.5,28.1c-6.7,8-15.7,14.1-23.2,21.3c-1.4,1.4-0.5,3.3,1,3.9
				c9.9,4.2,18-1,25.6-7.5c-1.1-1.1-2.2-2.2-3.3-3.3c-5.1,4.8-8.2,9.9-9.5,16.8c-0.3,1.5,0.6,3,2.3,3c9.5-0.1,17.3-5.7,24.3-11.6
				c2-1.7-0.7-5.3-2.9-3.7c-3.4,2.5-22.2,15-13.2,20.7c7.5,4.7,20.3-7.3,25.6-11.5c-1.1-1.1-2.2-2.2-3.3-3.3
				c-3.7,3.4-16.1,18.6-4.4,21.1c10,2.1,19.8-7,22.8-16c1-2.9-3.6-4.1-4.6-1.3c-1.7,5-4,13.2,2.9,15.1c6.8,1.9,12.1-7.7,14-12.5
				c-1.5-0.4-3-0.8-4.6-1.3c-1.2,5.4-0.6,14.2,5.9,16c7.7,2.1,9.2-10,10.3-14.7c0.6-3-3.9-4.2-4.6-1.3c-0.4,1.7-0.7,3.4-1.1,5.1
				c-0.1,3.1-2.1,3.9-6,2.4c-0.5-2.1-0.5-4.2,0-6.3c0.7-3-3.4-4-4.6-1.3c-1.4,3.4-3.1,6.3-6.2,8.4c-5.1,3.5-1.7-8-1.5-8.5
				c-1.5-0.4-3-0.8-4.6-1.3c-1.7,5.3-6,9.1-10.6,11.8c-7.4,0.5-9.3-1.5-5.6-5.7c1.4-2.5,3.7-4.6,5.8-6.5c2.3-2.1-1-5.2-3.3-3.3
				c-4,3.2-8.2,6.1-12.6,8.6c-4.2,2.4-7.6,1.2-3.4-3.1c2.7-2.8,6.2-4.9,9.3-7.3c-1-1.2-1.9-2.5-2.9-3.7c-6,5-12.8,10.1-20.9,10.3
				c0.8,1,1.5,2,2.3,3c1.1-6.1,3.8-10.5,8.3-14.7c2.2-2.1-1.1-5.3-3.3-3.3c-6.1,5.2-12.6,9.7-21,6.2c0.3,1.3,0.7,2.6,1,4
				c7.6-7.3,16-13.6,23.2-21.3c7.8-8.5,13.2-18.9,18.3-29.1C211.5,368.3,207.4,365.9,206,368.6L206,368.6z"/>
		</g>
                            <g>
			<path className="st46" d="M314.8,386c5.8,10.3,9.2,20.7,16.6,30.1c4.1,5.1,8.1,10.2,13.2,14.3c4.8,3.9,10.1,6.9,13.3,12.4
				c0.5-1.2,0.9-2.3,1.4-3.5c-12.2,4.1-25.4-4.8-35.1-11.4c-1.7-1.1-4.2,0.7-3.5,2.7c1.8,4.8,4.7,9.1,4.7,14.4
				c0.8-0.8,1.6-1.6,2.4-2.4c-6.8,0.4-10.2-4.4-14.1-8.9c-1.3-1.6-4.1-0.3-4,1.7c0.1,3.7,0.4,7.3,0,11c0.8-0.8,1.6-1.6,2.4-2.4
				c-3.4-1.7-6.9-3.3-10.3-5c-1.6-2.2-4.8-0.9-4.3,1.8c-3.8,3.9-6.6,3.8-8.4-0.2c-1.9-2.4-5.2,1-3.3,3.3c6.5,8.1,18.6,8.8,16.3-4.3
				c-1.4,0.6-2.9,1.2-4.3,1.8c3.7,5.2,7.7,8.1,14.4,7.3c1.4-0.2,2.2-1,2.4-2.4c0.4-3.7,0.1-7.2,0-11c-1.3,0.6-2.7,1.1-4,1.7
				c4.9,5.7,9.5,10.7,17.4,10.3c1.3-0.1,2.4-1,2.4-2.4c-0.1-5.9-2.9-10.2-4.9-15.7c-1.2,0.9-2.3,1.8-3.5,2.7
				c10.8,7.3,25.2,16.4,38.8,11.9c1.4-0.5,2.2-2.1,1.4-3.5c-4.8-8.3-14.2-12.5-20.5-19.5c-5.4-6.1-10.2-12.6-14.4-19.5
				c-3.4-5.5-5-12.1-8.2-17.8C317.4,381,313.3,383.4,314.8,386L314.8,386z"/>
		</g>
                            <g>
			<path className="st46" d="M184.1,427.5c-9,19.2-28.3,29.2-44.1,41.9c-1.6,1.3-0.4,3.3,1,4c13.2,5.5,25.2,1,37.4-5
				c-1-1.2-1.9-2.5-2.9-3.7c-3.8,3-23.4,18-15.1,24.6c8.8,6.9,22.2-7.2,28.9-12c-1.1-1.1-2.2-2.2-3.2-3.2
				c-4.2,7.9-8.2,20.9,2.8,24.7c9.1,3.2,23.1-15.1,29-19.8c-1.1-1.1-2.2-2.2-3.3-3.3c-5.7,6.9-8.2,17-0.3,23.4
				c9.6,7.6,18.9-4.6,22.4-12.3c-1.5-0.4-2.9-0.8-4.4-1.2c-0.5,6.8,1.4,15.1,8.6,17.3c8,2.5,12.9-10.8,15.7-15.5
				c-1.5-0.4-2.9-0.8-4.4-1.2c0.8,8.8,6.7,18.2,16.1,19.2c11.8,1.2,10.7-14.9,10.6-21.9c-0.1-3-4.8-3-4.7,0
				c0.1,4.3,0.5,8.6-0.4,12.9c-2.7,13.1-16.3-4.4-16.8-10.1c-0.2-2.2-3.1-3.3-4.4-1.2c-1.4,2.3-2.5,4.7-3.8,7
				c-1.7,4.2-5.4,4.5-11,0.9c-0.9-2.4-0.9-4.9-0.7-7.4c0.2-2.3-3.4-3.3-4.4-1.2c-2.4,5.4-6.4,13.3-13.5,11.9
				c-8.6-1.6-4.6-13.2-1.2-17.3c1.9-2.4-1-5.2-3.3-3.3c-5.9,4.7-11.1,10.3-17.2,14.9c-4.2,3.2-10.8,5.4-10.9-2.6
				c0-3.8,1.9-8.2,3.7-11.5c1-1.9-1.2-4.7-3.2-3.2c-5.8,4.1-11.1,8.6-17.4,11.9c-5.4,2.8-5-2.9-2.7-5.9c1.3-1.7,2.9-3.2,4.5-4.7
				c2.4-2.3,4.9-4.4,7.5-6.5c2.2-1.8-0.6-4.8-2.9-3.7c-11.1,5.5-21.7,9.6-33.8,4.6c0.3,1.3,0.7,2.6,1,3.9
				c16.2-13.1,35.7-23.3,44.8-42.9C189.5,427.1,185.4,424.7,184.1,427.5L184.1,427.5z"/>
		</g>
                            <g>
			<path className="st46" d="M274.6,485.4c11.8,8.1,16.6,20,32.7,19.5c1,0,2.1-0.7,2.3-1.7c1.4-8-1.2-14-5-21c-1.1,1.1-2.2,2.2-3.2,3.2
				c5.9,4.1,17.6,19.8,26.3,15.7c10.5-4.9,4.2-18.5-2.3-24.2c-1.1,1.1-2.2,2.2-3.3,3.3c7.2,5.9,22.3,18.5,32.5,13.6
				c11.4-5.5-2.8-17.9-9.1-20.7c-2.3-1-5.1,1.8-2.9,3.7c9,7.8,25.7,19.1,35.5,6.8c0.9-1.1,1-2.9-0.5-3.7c-15-8-24.9-26.2-32.7-40.6
				c-1.5-2.7-5.5-0.3-4.1,2.4c8.1,15,18.7,34,34.4,42.3c-0.2-1.2-0.3-2.5-0.5-3.7c-7.9,9.8-21.9-0.9-28.8-6.8
				c-1,1.2-1.9,2.5-2.9,3.7c3,1.3,5.5,3.2,7.9,5.5c5.4,5.3-1.6,7.7-6.2,6.1c-6.9-2.3-13.8-7.3-19.3-11.9c-2.3-1.9-5.6,1.3-3.3,3.3
				c3.1,2.7,4.9,5.8,6.1,9.7c-0.9,6.6-3.2,8.4-7,5.5c-1.9-1.1-3.6-2.4-5.3-3.8c-4.2-3.3-7.8-7.2-12.2-10.3c-2-1.4-4.3,1.3-3.2,3.2
				c2.5,4.6,9.9,17.1-0.4,15.1c-3.9-0.7-7.1-3.4-9.9-6.1c-4.4-4.2-8.1-8.8-13.1-12.3C274.5,479.6,272.1,483.7,274.6,485.4
				L274.6,485.4z"/>
		</g>
                            <g>
			<path className="st46" d="M237.3,499.9c-2.1,10.7-2.2,21.6-4.8,32.2c-0.7,3,3.8,4.2,4.6,1.3c2.6-10.6,2.7-21.5,4.8-32.2
				C242.4,498.2,237.9,496.9,237.3,499.9L237.3,499.9z"/>
		</g>
                            <g>
			<path className="st46" d="M287.1,497.8c0.1,11.1,0.7,22.3,4.9,32.8c1.1,2.8,5.7,1.6,4.6-1.3c-4-10.1-4.7-20.8-4.7-31.5
				C291.8,494.7,287.1,494.7,287.1,497.8L287.1,497.8z"/>
		</g>
                            <g>
			<path className="st46" d="M266.6,292c-6.5,0.2-10.3,5.6-10.9,11.7c-0.6,6.7,4.4,11.6,10.8,11.9c7,0.4,13.4-4.4,12.7-11.7
				C278.6,297.3,273,292.2,266.6,292c-2.4-0.1-2.4,3.6,0,3.7c4.6,0.2,8,3.7,9,8.1c1.2,5.6-4.3,8.1-9,8.2c-9.8,0-9.1-15.9,0-16.2
				C269,295.6,269,291.9,266.6,292z"/>
		</g>
                            <g>
			<path className="st46" d="M236.5,321.4c-6.5,0.2-10.3,5.6-10.9,11.7c-0.6,6.7,4.4,11.6,10.8,11.9c7,0.4,13.4-4.4,12.7-11.7
				C248.6,326.8,243,321.6,236.5,321.4c-2.4-0.1-2.4,3.6,0,3.7c4.6,0.2,8,3.7,9,8.1c1.2,5.6-4.3,8.1-9,8.2c-9.8,0-9.1-15.9,0-16.2
				C238.9,325,238.9,321.3,236.5,321.4z"/>
		</g>
                            <g>
			<path className="st46" d="M282.8,347c-6.5,0.2-10.3,5.6-10.9,11.7c-0.6,6.7,4.4,11.6,10.8,11.9c7,0.4,13.4-4.4,12.7-11.7
				C294.9,352.4,289.3,347.3,282.8,347c-2.4-0.1-2.4,3.6,0,3.7c4.6,0.2,8,3.7,9,8.1c1.2,5.6-4.3,8.1-9,8.2c-9.8,0-9.1-15.9,0-16.2
				C285.2,350.6,285.2,346.9,282.8,347z"/>
		</g>
                            <g>
			<path className="st46" d="M226.6,383c-6.5,0.2-10.3,5.6-10.8,11.7c-0.6,6.7,4.4,11.6,10.8,11.9c7,0.4,13.4-4.4,12.7-11.7
				C238.6,388.4,233,383.2,226.6,383c-2.4-0.1-2.4,3.6,0,3.7c4.7,0.2,8,3.7,9,8.1c1.2,5.6-4.3,8.1-9,8.2c-9.8,0-9.1-15.9,0-16.2
				C228.9,386.6,229,382.9,226.6,383z"/>
		</g>
                            <g>
			<path className="st46" d="M284.8,404.8c-6.5,0.2-10.3,5.6-10.9,11.7c-0.6,6.7,4.4,11.6,10.8,11.9c7,0.4,13.4-4.4,12.7-11.7
				C296.9,410.1,291.3,405,284.8,404.8c-2.4-0.1-2.4,3.6,0,3.7c4.6,0.2,8,3.7,9,8.1c1.2,5.6-4.3,8.1-9,8.2c-9.8,0-9.1-15.9,0-16.2
				C287.2,408.4,287.2,404.7,284.8,404.8z"/>
		</g>
                            <g>
			<path className="st46" d="M208.3,449.8c-6.5,0.2-10.3,5.6-10.9,11.7c-0.6,6.7,4.4,11.6,10.8,11.9c7,0.4,13.4-4.4,12.7-11.7
				C220.3,455.2,214.7,450.1,208.3,449.8c-2.4-0.1-2.4,3.6,0,3.7c4.6,0.2,8,3.7,9,8.1c1.2,5.6-4.3,8.1-9,8.2c-9.8,0-9.1-15.9,0-16.2
				C210.6,453.4,210.6,449.7,208.3,449.8z"/>
		</g>
                            <g>
			<path className="st46" d="M265.8,449.8c-6.5,0.2-10.3,5.6-10.9,11.7c-0.6,6.7,4.4,11.6,10.8,11.9c7,0.4,13.4-4.4,12.7-11.7
				C277.9,455.2,272.3,450.1,265.8,449.8c-2.4-0.1-2.4,3.6,0,3.7c4.6,0.2,8,3.7,9,8.1c1.2,5.6-4.3,8.1-9,8.2c-9.8,0-9.1-15.9,0-16.2
				C268.2,453.4,268.2,449.7,265.8,449.8z"/>
		</g>
                            <g>
			<path className="st46" d="M317.3,449.8c-6.5,0.2-10.3,5.6-10.9,11.7c-0.6,6.7,4.4,11.6,10.8,11.9c7,0.4,13.4-4.4,12.7-11.7
				C329.3,455.2,323.7,450.1,317.3,449.8c-2.4-0.1-2.4,3.6,0,3.7c4.6,0.2,8,3.7,9,8.1c1.2,5.6-4.3,8.1-9,8.2c-9.8,0-9.1-15.9,0-16.2
				C319.6,453.4,319.7,449.7,317.3,449.8z"/>
		</g>
                            <g>
			<path className="st46" d="M246.7,505.8c-0.7,2.9-1.1,5.8-1.4,8.7c-0.3,2.4,3.4,2.3,3.7,0c0.3-2.6,0.7-5.2,1.3-7.7
				C250.8,504.5,247.2,503.5,246.7,505.8L246.7,505.8z"/>
		</g>
                            <g>
			<path className="st46" d="M243.9,520.3c0,2.2-0.3,4.3-0.3,6.5c0,2.4,3.7,2.4,3.7,0c0-2.2,0.3-4.3,0.3-6.5
				C247.6,518,243.9,518,243.9,520.3L243.9,520.3z"/>
		</g>
                            <g>
			<path className="st46" d="M252.5,512c-0.7,2.2-0.8,4.4-0.8,6.6c0,2.4,3.7,2.4,3.7,0c0-1.9,0-3.8,0.6-5.7
				C256.7,510.7,253.2,509.7,252.5,512L252.5,512z"/>
		</g>
                            <g>
			<g>
				<path className="st46" d="M285.1,411.9c0,0,0.1-0.1,0.4-0.3c0.3-0.1,0.5-0.4,1.1-0.4c0.3,0,0.6,0,0.9,0.1c0.3,0.1,0.6,0.1,0.8,0.3
					c0.6,0.2,1.1,0.7,1.5,1.1c0.4,0.5,0.8,1,0.9,1.6c0.2,0.6,0.2,1.1,0.2,1.6c0,0.5-0.2,0.9-0.4,1.1c-0.1,0.3-0.3,0.4-0.3,0.4
					s-0.1-0.1-0.3-0.3c-0.2-0.2-0.4-0.4-0.7-0.7c-0.1-0.1-0.3-0.3-0.4-0.4c-0.1-0.1-0.3-0.3-0.4-0.4c-0.2-0.3-0.6-0.5-0.8-0.9
					c-0.3-0.2-0.5-0.6-0.7-0.9c-0.1-0.1-0.2-0.3-0.3-0.5c-0.1-0.1-0.2-0.2-0.3-0.4c-0.1-0.3-0.6-0.5-0.8-0.7
					C285.2,412,285.1,411.9,285.1,411.9z"/>
			</g>
                                <g>
				<path className="st46" d="M317.6,458.4c0,0,0.1-0.1,0.3-0.3c0.2-0.1,0.4-0.4,0.9-0.4c0.2,0,0.5-0.1,0.8,0c0.3,0,0.5,0.1,0.8,0.2
					c0.5,0.2,0.9,0.5,1.3,0.9c0.2,0.2,0.3,0.5,0.4,0.7c0.1,0.2,0.2,0.5,0.2,0.8c0.1,0.5,0,1-0.1,1.4c-0.1,0.4-0.4,0.7-0.6,0.9
					c-0.2,0.2-0.3,0.3-0.3,0.3s-0.1-0.1-0.3-0.3c-0.2-0.2-0.3-0.4-0.6-0.6c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.3-0.2-0.3-0.3
					c-0.2-0.2-0.4-0.4-0.5-0.6c-0.2-0.2-0.3-0.4-0.5-0.6c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1-0.1-0.1-0.2-0.2-0.4
					c-0.1-0.3-0.5-0.5-0.6-0.7C317.6,458.6,317.6,458.4,317.6,458.4z"/>
			</g>
                                <g>
				<path className="st46" d="M227.2,390.3c0,0,0.1-0.1,0.4-0.2c0.2-0.1,0.5-0.3,0.9-0.3c0.4-0.1,0.9-0.1,1.4,0c0.5,0.1,0.9,0.3,1.4,0.6
					c0.4,0.3,0.8,0.7,1.1,1.2c0.3,0.4,0.5,1,0.5,1.5c0.1,0.4-0.1,0.9-0.3,1.1c-0.1,0.2-0.2,0.3-0.2,0.3s-0.1-0.1-0.4-0.2
					c-0.2-0.1-0.4-0.3-0.7-0.4c-0.1-0.1-0.3-0.1-0.4-0.2c-0.1-0.1-0.3-0.1-0.4-0.2c-0.1-0.1-0.3-0.1-0.4-0.2
					c-0.2-0.1-0.3-0.2-0.4-0.3c-0.3-0.2-0.6-0.4-0.9-0.7c-0.3-0.3-0.5-0.5-0.7-0.8c-0.2-0.3-0.4-0.6-0.6-0.8
					C227.3,390.5,227.2,390.3,227.2,390.3z"/>
			</g>
                                <g>
				<path className="st46" d="M282.3,353.7c0,0,0.2-0.1,0.5-0.2c0.3-0.1,0.9-0.1,1.5,0.1c1.2,0.3,2.5,1.4,3.2,2.8
					c0.7,1.4,0.9,2.9,0.7,4.1c-0.2,1.2-0.8,1.8-0.8,1.8s-0.5-0.7-1-1.5c-0.5-0.8-1.1-1.9-1.7-2.9c-0.3-0.5-0.5-1-0.8-1.5
					c-0.2-0.5-0.5-0.9-0.7-1.3c-0.3-0.4-0.4-0.7-0.7-1C282.4,353.9,282.3,353.7,282.3,353.7z"/>
			</g>
                                <g>
				<path className="st46" d="M265.9,457c0,0,0.1-0.1,0.4-0.3c0.1-0.1,0.3-0.2,0.4-0.2c0.2-0.1,0.4-0.1,0.7-0.1c0.3,0,0.6,0.1,1,0.2
					c0.3,0.1,0.5,0.3,0.8,0.5c0.3,0.2,0.5,0.4,0.7,0.7c0.2,0.3,0.4,0.5,0.5,0.8c0.1,0.3,0.2,0.6,0.3,0.9c0.1,0.3,0.1,0.6,0.1,0.9
					c0,0.6-0.1,1.1-0.2,1.5c-0.1,0.4-0.4,0.8-0.6,1c-0.2,0.2-0.3,0.3-0.3,0.3s-0.1-0.1-0.3-0.4c-0.2-0.2-0.3-0.5-0.6-0.8
					c-0.2-0.3-0.4-0.7-0.7-1c-0.2-0.4-0.4-0.7-0.5-1c-0.1-0.2-0.2-0.3-0.2-0.5c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.1-0.1-0.3-0.2-0.5
					c0-0.1-0.1-0.2-0.1-0.4c0-0.2-0.1-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.3C266,457.2,265.9,457,265.9,457z"/>
			</g>
                                <g>
				<path className="st46" d="M209.1,457.4c0,0,0-0.2,0.2-0.4c0.1-0.2,0.7-0.5,1.2-0.5c0.6,0,1.1,0.2,1.6,0.5c0.4,0.3,0.8,0.7,1.1,1.2
					c0.6,0.9,0.7,1.9,0.6,2.7c-0.1,0.8-0.5,1.4-0.5,1.4s-0.6-0.3-1.1-0.8c-0.6-0.4-1.1-1-1.5-1.6c-0.4-0.6-0.6-1.2-0.7-1.7
					c0-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.2-0.2-0.3C209.2,457.5,209.1,457.4,209.1,457.4z"/>
			</g>
                                <g>
				<path className="st46" d="M237.1,328c0,0,0.1-0.1,0.4-0.2c0.3-0.1,0.5-0.3,1-0.3c0.5,0,1.1,0.2,1.6,0.4c0.5,0.2,0.9,0.7,1.3,1.1
					c0.3,0.5,0.6,1,0.7,1.6c0.1,0.5,0.1,1.1,0,1.5c-0.1,0.4-0.4,0.8-0.5,1c-0.2,0.2-0.3,0.3-0.3,0.3s-0.1-0.1-0.3-0.3
					c-0.2-0.2-0.3-0.4-0.6-0.7c-0.1-0.1-0.3-0.2-0.4-0.4c-0.1-0.1-0.3-0.2-0.4-0.4c-0.2-0.3-0.5-0.5-0.6-0.8
					c-0.2-0.2-0.4-0.6-0.6-0.9c-0.1-0.1-0.2-0.3-0.2-0.5c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1-0.3-0.4-0.6-0.6-0.8
					C237.2,328.2,237.1,328,237.1,328z"/>
			</g>
                                <g>
				<path className="st46" d="M265.5,298.6c0,0,0.1-0.2,0.2-0.4c0.1-0.1,0.4-0.2,0.6-0.2c0.2,0,0.5,0,0.8,0c1.1,0.2,2.1,1,2.7,1.9
					c0.6,0.9,0.9,2,0.8,2.9c0,0.9-0.4,1.5-0.4,1.5s-0.6-0.3-1.2-0.8c-0.6-0.5-1.3-1.2-1.8-1.9c-0.2-0.4-0.5-0.8-0.6-1.1
					c-0.2-0.4-0.3-0.7-0.4-1c-0.1-0.2-0.1-0.3-0.2-0.4c0-0.1,0-0.2-0.2-0.3C265.7,298.7,265.5,298.6,265.5,298.6z"/>
			</g>
		</g>
	</g>
                        <g className="mesa white">
		<path className="st11" d="M319.6,632.7c-2.9,0-7.1,0-11.4,0.2c-4.7,0.2-9.3,0.4-14,0.7c-2.9,0.2-5.9,0.3-8.8,0.6c-4,0.4-8,0.8-12,1.2
			c-4.3,0.5-8.7,1-13,1.7c-7.6,1.1-15.2,2.5-22.7,4.1c-11.6,2.5-23.1,5.6-34.4,9.4c-6,2.1-11.9,4.4-17.7,7
			c-6.4,2.9-12.6,6.1-18.5,9.9c-4.4,2.8-8.6,6-12.4,9.6c-3,2.8-5.7,5.8-7.9,9.3c-1.7,2.6-3,5.3-3.9,8.2c-1.2,4-1.5,8.1-0.6,12.2
			c0.7,3.1,2,5.9,3.7,8.5c1.5,2.3,3.3,4.5,5.2,6.5c3.7,3.7,7.8,6.8,12.3,9.5c4.9,3.1,10.2,5.6,15.5,7.8c9.2,3.8,18.6,6.7,28.3,9
			c7.4,1.8,14.8,3.2,22.3,4.3c4.2,0.6,8.4,1.2,12.6,1.7c2.7,0.3,5.5,0.6,8.3,0.9c2.7,0.2,5.3,0.5,8,0.7c2.1,0.2,4.2,0.3,6.3,0.4
			c3.1,0.1,6.1,0.4,9.2,0.4c6.4,0.1,12.9,0.1,19.3,0.1c1.9,0,3.9-0.1,5.8-0.1c2.7-0.1,5.4-0.2,8-0.4c3.5-0.2,7-0.3,10.5-0.6
			c4.3-0.3,8.5-0.8,12.8-1.2c5.4-0.5,10.8-1.3,16.2-2.1c10.6-1.6,21.1-3.6,31.5-6.1c11.2-2.7,22.3-6,33.1-10.1
			c8.4-3.2,16.5-6.9,24.3-11.4c5.4-3.1,10.5-6.6,15.2-10.6c3.4-3,6.5-6.2,9.1-9.9c2-2.9,3.6-6,4.6-9.4c1.1-3.6,1.3-7.2,0.7-10.9
			c-0.5-2.9-1.6-5.7-3-8.2c-1.6-2.8-3.6-5.4-5.9-7.7c-3.9-4-8.4-7.3-13.2-10.2c-6.1-3.6-12.5-6.6-19.1-9.1
			c-10.4-4-21.2-6.8-32.1-9.1c-6.5-1.3-13-2.4-19.6-3.3c-3.7-0.5-7.4-0.9-11.1-1.3c-3.4-0.4-6.9-0.6-10.3-0.9
			c-3.5-0.3-7-0.5-10.5-0.7C333.9,632.8,327.4,632.7,319.6,632.7z"/>
                            <path className="st11" d="M464,703.1c-0.1,0-0.2,0-0.2,0c-0.2,0.2-0.4,0.5-0.5,0.7c-1.3,2.1-2.9,4.1-4.5,6c-3.3,3.7-7,7-10.9,9.9
			c-5.1,3.9-10.6,7.2-16.3,10.1c-9.2,4.8-18.9,8.7-28.7,12c-11.5,3.9-23.3,6.9-35.2,9.4c-9.5,2-19.1,3.6-28.8,4.8
			c-3.9,0.5-7.7,0.9-11.6,1.3c-3.6,0.4-7.2,0.7-10.9,1c-3.6,0.3-7.2,0.5-10.8,0.6c-4.3,0.2-8.6,0.4-12.8,0.4
			c-6.6,0.1-13.2,0.1-19.9-0.2c-5.1-0.2-10.3-0.4-15.4-0.8c-5.6-0.4-11.1-0.9-16.7-1.6c-8.4-1-16.8-2.3-25.1-4
			c-10.3-2.1-20.4-4.7-30.3-8.3c-7.1-2.6-14.1-5.6-20.7-9.4c-4-2.3-7.7-4.8-11.2-7.7c-4.5-3.8-8.5-8.1-11.2-13.3
			c-0.1-0.2-0.2-0.3-0.5-0.7c0.1,0.7,0.2,1.1,0.3,1.6c0.4,2.3,0.8,4.5,1.2,6.8c0.8,5,1.7,10.1,2.7,15.1c0.2,0.9,0.6,1.7,1.1,2.5
			c4.2,6,9.3,11,15.1,15.2c5,3.7,10.4,6.8,16,9.6c1.1,0.6,2.3,1,3.4,1.5c0.2,0.1,0.5,0.2,0.7,0.4c0.5,0.3,1,0.5,1.5,0.8
			c1.9,0.8,3.7,1.6,5.6,2.3c11.5,4.5,23.4,7.8,35.5,10.4c6.3,1.3,12.6,2.4,18.9,3.3c3.6,0.5,7.3,1,10.9,1.4c3.2,0.4,6.5,0.6,9.7,0.9
			c3.2,0.3,6.5,0.5,9.7,0.7c3.8,0.2,7.7,0.2,11.5,0.4c6.9,0.3,13.8,0,20.8-0.1c2.2,0,4.5-0.1,6.7-0.3c3.3-0.2,6.6-0.4,9.8-0.7
			c4.1-0.3,8.3-0.7,12.4-1.1c5.5-0.6,10.9-1.3,16.3-2.2c10.2-1.6,20.3-3.7,30.4-6.3c11.4-3,22.5-6.6,33.3-11.2
			c8.7-3.7,17.1-8,24.9-13.4c4.7-3.2,9.1-6.7,13.1-10.8c2.4-2.4,4.5-5,6.4-7.8c0.5-0.7,0.8-1.4,0.8-2.3c-0.1-2.7,0.2-5.5,0.6-8.1
			c0.8-5.7,1.8-11.5,2.7-17.2C463.9,704.1,463.9,703.6,464,703.1z"/>
                            <path className="st11" d="M180,772c0-0.7,0-1.4,0-2.1c-0.1-1.9,0-1.7-1.5-2.4c-6-2.9-11.7-6.1-17.1-10c-0.2-0.1-0.4-0.3-0.5-0.4
			c-0.2-0.1-0.4-0.1-0.6,0.1c-0.1,0.1-0.2,0.3-0.3,0.4c-1.8,2.4-3.5,4.8-4.8,7.5c-1.6,3.2-2.7,6.5-3.4,10.1
			c-0.8,4.6,0.3,8.7,3.3,12.4c1.7,2.1,3.7,3.9,6,5.3c0.8,0.5,1.7,0.9,2.7,1.4c1.1,0.5,2.3,0.8,3.5,0.8c2.4,0.1,4.4-0.6,6.1-2.3
			c1.5-1.5,2.5-3.3,3.4-5.2C178.9,782.6,180.1,777.5,180,772z"/>
                            <path className="st11" d="M237.7,798.1c-0.1,3,0.6,6.2,1.9,9.2c0.8,1.9,1.9,3.6,3.4,5c0.9,0.9,2,1.6,3.3,2.1c4.9,1.7,9.5,1.2,13.8-2
			c2.6-2,4.5-4.5,5.8-7.5c1.1-2.6,1.7-5.4,1.9-8.2c0.2-2.8-0.3-5.4-1-8.1c-0.1-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.5-0.1-0.8-0.1
			c-2.3-0.2-4.6-0.4-6.8-0.6c-2.9-0.3-5.8-0.6-8.8-0.9c-3-0.4-6-0.8-9-1.2c-0.3,0-0.5-0.1-0.8-0.1c-0.1,0-0.3,0.1-0.4,0.3
			c-0.1,0.2-0.2,0.4-0.2,0.6C238.3,789.9,237.7,793.8,237.7,798.1z"/>
                            <path className="st11" d="M453,745.8c0-0.3,0-0.7,0-1c0-0.3-0.3-0.3-0.5-0.2c-0.2,0.2-0.4,0.3-0.6,0.5c-2.6,2.4-5.4,4.6-8.3,6.6
			c-2.9,2-5.8,4-8.9,5.8c-2.5,1.4-5,2.7-7.5,4.1c-0.3,0.1-0.5,0.3-0.8,0.4c-0.2,0.1-0.2,0.4-0.1,0.6c0.6,0.6,1.3,0.9,2.2,1.1
			c1.3,0.2,2.6,0.1,3.9-0.1c4.9-1,9.4-3,13.4-6c2.1-1.6,3.9-3.6,5.3-5.8C452.3,750,452.9,747.9,453,745.8z"/>
                            <path d="M468.3,686.5c-0.2-4.5-1.6-8.7-3.8-12.7c-1.7-3-3.8-5.7-6.2-8.1c-3.5-3.6-7.4-6.6-11.5-9.2c-6-3.8-12.3-6.9-18.8-9.6
			c-9-3.6-18.2-6.4-27.6-8.7c-8-1.9-16-3.4-24.1-4.6c-5.6-0.8-11.3-1.5-17-2.1c-3.8-0.4-7.7-0.7-11.5-0.9c-4.4-0.3-8.9-0.5-13.4-0.7
			c-4.2-0.1-8.3-0.1-11.9-0.2c-10.9,0.1-15.5,0.1-26.6,0.9c-4.4,0.3-8.8,0.7-13.2,1c-5.6,0.5-11.3,1.2-16.9,1.9
			c-8.2,1.1-16.3,2.5-24.4,4.1c-13.4,2.7-26.7,6.1-39.6,10.6c-4.4,1.5-8.7,3.2-13,5c-7.3,3.1-14.4,6.6-21.2,10.7
			c-5.1,3.2-10,6.7-14.5,10.8c-3.4,3.2-6.6,6.7-9.1,10.6c-1.6,2.5-2.9,5.2-3.8,8.1c-0.8,2.7-1.3,5.5-1.2,8.3c0,1,0.2,2.1,0.1,3.1
			c-0.2,2.2,0,4.4,0.2,6.6c0.5,4,1,8.1,1.6,12.1c0.7,5,1.6,10,2.6,15c0.1,0.5,0.2,0.9,0.3,1.4c0.2,0.5,0.5,1,1,1.1
			c0.6,0.2,0.9,0.6,1.2,1.1c1.3,2,2.7,3.9,4.3,5.7c2.2,2.4,4.5,4.6,7.1,6.7c0.3,0.2,0.6,0.5,0.9,0.7c0.4,0.3,0.4,0.5,0,0.8
			c-1,0.9-1.8,1.9-2.6,3c-3.4,4.6-5.5,9.8-6.5,15.4c-0.8,4.6,0,9,2.7,12.9c2.3,3.5,5.4,6.3,9.1,8.4c1.9,1.1,4,1.7,6.2,1.8
			c3.1,0.2,5.8-0.8,8-3c1.3-1.3,2.4-2.8,3.3-4.5c1.4-2.6,2.5-5.4,3.2-8.4c0.9-3.6,1.1-7.3,0.9-11c-0.1-1.5,0-1.5,1.3-0.9
			c8.7,3.7,17.6,6.7,26.8,9.1c8.3,2.2,16.8,4,25.2,5.4c0.3,0.1,0.6,0.1,0.9,0.2c0.1,0,0.2,0.2,0.3,0.4c0,0.2,0,0.3-0.1,0.5
			c-1.1,3.8-1.7,7.7-2,11.6c-0.2,3.9,0.4,7.6,1.7,11.2c0.8,2,1.9,3.9,3.4,5.5c1.4,1.6,3.1,2.8,5.1,3.5c3,1,6.1,1.3,9.2,0.8
			c3.5-0.6,6.5-2.2,9.2-4.6c2.6-2.4,4.6-5.3,5.7-8.7c1.4-4.2,2.1-8.4,1.4-12.8c-0.1-0.9-0.4-1.9-0.5-2.8c-0.1-0.8-0.1-0.9,0.6-0.9
			c0.9,0,1.8,0.1,2.7,0.1c4.1,0.2,8.2,0.4,12.3,0.4c5.4,0.1,10.8,0.1,16.2,0c3.1,0,6.2-0.2,9.3-0.3c1.6-0.1,3.1-0.1,4.7-0.3
			c3.9-0.3,7.9-0.6,11.8-0.9c3.7-0.3,7.3-0.7,11-1.1c6.2-0.7,12.4-1.7,18.6-2.7c10.1-1.8,20.1-3.9,30-6.8c4.8-1.4,9.6-2.9,14.4-4.4
			c2.7-0.8,5.2-1.8,7.8-2.8c1.4-0.6,2.7-1.1,4.1-1.7c3.1-1.4,6.3-2.8,9.4-4.2c0.2-0.1,0.5-0.2,0.7-0.3c0.3-0.1,0.5-0.1,0.7,0.1
			c0.1,0.1,0.2,0.2,0.2,0.3c0.9,1,2.1,1.6,3.5,1.9c1.6,0.3,3.1,0.1,4.6-0.2c5.1-1.1,9.8-3.1,14-6.2c2.3-1.7,4.2-3.8,5.8-6.2
			c1.5-2.2,2.2-4.6,2.1-7.2c0-0.7-0.1-1.5-0.1-2.2c-0.1-0.6,0-1.1,0.5-1.5c0.4-0.3,0.7-0.7,1-1c2.5-2.6,4.7-5.4,6.6-8.5
			c0.2-0.3,0.4-0.7,0.6-1c0.3-0.5,0.4-1,0.2-1.5c-0.2-0.5-0.2-1-0.1-1.5c0.1-0.4,0.2-0.9,0.2-1.3c0.3-2.2,0.5-4.4,0.8-6.6
			c0.1-1.1,0.3-2.2,0.3-3.4c0-1.3,0.2-2.7,0.4-4c0.9-5.7,1.7-11.5,2.6-17.2c0.1-0.6,0.2-1.1,0.1-1.7c-0.1-0.6,0-1.2,0.1-1.8
			C468.2,690.6,468.4,688.6,468.3,686.5z M176.7,787.6c-0.9,1.9-1.9,3.7-3.4,5.2c-1.7,1.7-3.8,2.4-6.1,2.3c-1.2-0.1-2.4-0.3-3.5-0.8
			c-0.9-0.4-1.8-0.8-2.7-1.4c-2.3-1.4-4.3-3.2-6-5.3c-3-3.6-4.1-7.7-3.3-12.4c0.6-3.5,1.7-6.9,3.4-10.1c1.4-2.7,3-5.1,4.8-7.5
			c0.1-0.1,0.2-0.3,0.3-0.4c0.2-0.2,0.4-0.2,0.6-0.1c0.2,0.1,0.4,0.2,0.5,0.4c5.4,3.9,11.1,7.1,17.1,10c1.5,0.7,1.5,0.5,1.5,2.4
			c0,0.7,0,1.4,0,2.1C180.1,777.5,178.9,782.6,176.7,787.6z M267.6,796.6c-0.2,2.8-0.8,5.6-1.9,8.2c-1.3,3-3.2,5.6-5.8,7.5
			c-4.2,3.2-8.8,3.7-13.8,2c-1.2-0.4-2.3-1.2-3.3-2.1c-1.5-1.4-2.6-3.1-3.4-5c-1.2-3-2-6.2-1.9-9.2c0-4.3,0.6-8.2,1.7-12
			c0.1-0.2,0.1-0.4,0.2-0.6c0.1-0.1,0.3-0.2,0.4-0.3c0.3,0,0.5,0,0.8,0.1c3,0.4,6,0.8,9,1.2c2.9,0.4,5.8,0.7,8.8,0.9
			c2.3,0.2,4.6,0.4,6.8,0.6c0.3,0,0.5,0.1,0.8,0.1c0.3,0.1,0.5,0.2,0.5,0.5C267.3,791.2,267.8,793.9,267.6,796.6z M453,745.8
			c-0.1,2.2-0.6,4.2-1.8,6c-1.5,2.2-3.2,4.2-5.3,5.8c-4,2.9-8.5,5-13.4,6c-1.3,0.3-2.6,0.4-3.9,0.1c-0.8-0.1-1.6-0.4-2.2-1.1
			c-0.2-0.2-0.2-0.4,0.1-0.6c0.3-0.2,0.5-0.3,0.8-0.4c2.5-1.4,5-2.7,7.5-4.1c3.1-1.7,6-3.7,8.9-5.8c2.9-2,5.7-4.3,8.3-6.6
			c0.2-0.2,0.4-0.3,0.6-0.5c0.2-0.2,0.5-0.1,0.5,0.2C453,745.1,453,745.4,453,745.8z M463.8,704.6c-0.9,5.7-1.8,11.5-2.7,17.2
			c-0.4,2.7-0.7,5.4-0.6,8.1c0,0.9-0.3,1.6-0.8,2.3c-1.9,2.8-4.1,5.4-6.4,7.8c-4,4.1-8.4,7.6-13.1,10.8c-7.8,5.3-16.2,9.6-24.9,13.4
			c-10.8,4.6-22,8.2-33.3,11.2c-10,2.6-20.1,4.7-30.4,6.3c-5.4,0.9-10.9,1.6-16.3,2.2c-4.1,0.4-8.3,0.8-12.4,1.1
			c-3.3,0.3-6.6,0.5-9.8,0.7c-2.2,0.1-4.5,0.2-6.7,0.3c-6.9,0.1-13.8,0.4-20.8,0.1c-3.8-0.2-7.7-0.2-11.5-0.4
			c-3.2-0.2-6.5-0.4-9.7-0.7c-3.2-0.3-6.5-0.5-9.7-0.9c-3.7-0.4-7.3-0.8-10.9-1.4c-6.3-0.9-12.7-2-18.9-3.3
			c-12.1-2.5-24-5.8-35.5-10.4c-1.9-0.7-3.8-1.6-5.6-2.3c-0.5-0.2-1-0.5-1.5-0.8c-0.2-0.1-0.5-0.2-0.7-0.4c-1.1-0.5-2.3-1-3.4-1.5
			c-5.6-2.8-11-5.9-16-9.6c-5.8-4.3-11-9.3-15.1-15.2c-0.5-0.8-0.9-1.5-1.1-2.5c-1.1-5-1.9-10-2.7-15.1c-0.4-2.3-0.8-4.5-1.2-6.8
			c-0.1-0.4-0.1-0.8-0.3-1.6c0.3,0.4,0.4,0.5,0.5,0.7c2.8,5.3,6.7,9.5,11.2,13.3c3.5,2.9,7.3,5.4,11.2,7.7
			c6.6,3.8,13.5,6.8,20.7,9.4c9.9,3.6,20,6.2,30.3,8.3c8.3,1.7,16.7,3,25.1,4c5.5,0.6,11.1,1.2,16.7,1.6c5.1,0.4,10.3,0.6,15.4,0.8
			c6.6,0.2,13.2,0.2,19.9,0.2c4.3-0.1,8.6-0.2,12.8-0.4c3.6-0.2,7.2-0.4,10.8-0.6c3.6-0.3,7.2-0.6,10.9-1c3.9-0.4,7.7-0.8,11.6-1.3
			c9.7-1.2,19.3-2.8,28.8-4.8c11.9-2.5,23.7-5.5,35.2-9.4c9.9-3.3,19.5-7.2,28.7-12c5.7-3,11.2-6.3,16.3-10.1c4-3,7.7-6.2,10.9-9.9
			c1.7-1.9,3.2-3.9,4.5-6c0.2-0.3,0.4-0.5,0.5-0.7c0,0,0.1,0,0.2,0C463.9,703.6,463.9,704.1,463.8,704.6z M464.5,694.5
			c-1,3.4-2.6,6.5-4.6,9.4c-2.6,3.7-5.7,7-9.1,9.9c-4.7,4.1-9.8,7.5-15.2,10.6c-7.8,4.5-15.9,8.2-24.3,11.4
			c-10.8,4.1-21.9,7.4-33.1,10.1c-10.4,2.5-20.9,4.5-31.5,6.1c-5.4,0.8-10.8,1.5-16.2,2.1c-4.3,0.4-8.5,0.9-12.8,1.2
			c-3.5,0.3-7,0.4-10.5,0.6c-2.7,0.1-5.4,0.3-8,0.4c-1.9,0.1-3.9,0.1-5.8,0.1c-6.4,0-12.9,0-19.3-0.1c-3.1,0-6.1-0.3-9.2-0.4
			c-2.1-0.1-4.2-0.2-6.3-0.4c-2.7-0.2-5.3-0.4-8-0.7c-2.8-0.3-5.5-0.5-8.3-0.9c-4.2-0.5-8.4-1-12.6-1.7c-7.5-1.1-14.9-2.6-22.3-4.3
			c-9.6-2.3-19.1-5.2-28.3-9c-5.4-2.2-10.6-4.8-15.5-7.8c-4.4-2.7-8.6-5.8-12.3-9.5c-1.9-2-3.7-4.1-5.2-6.5c-1.7-2.6-3-5.5-3.7-8.5
			c-0.9-4.1-0.7-8.2,0.6-12.2c0.9-2.9,2.2-5.6,3.9-8.2c2.2-3.4,4.9-6.5,7.9-9.3c3.8-3.6,8-6.7,12.4-9.6c5.9-3.8,12.1-7,18.5-9.9
			c5.8-2.6,11.7-5,17.7-7c11.3-3.8,22.7-7,34.4-9.4c7.5-1.6,15.1-3,22.7-4.1c4.3-0.6,8.7-1.2,13-1.7c4-0.5,8-0.9,12-1.2
			c2.9-0.3,5.9-0.4,8.8-0.6c4.7-0.3,9.3-0.5,14-0.7c4.2-0.1,8.5-0.1,11.4-0.2c7.8,0,14.3,0.1,20.7,0.4c3.5,0.2,7,0.4,10.5,0.7
			c3.5,0.3,6.9,0.5,10.3,0.9c3.7,0.4,7.4,0.8,11.1,1.3c6.6,0.9,13.1,2,19.6,3.3c10.9,2.3,21.6,5.1,32.1,9.1c6.6,2.5,13,5.4,19.1,9.1
			c4.8,2.9,9.3,6.2,13.2,10.2c2.3,2.3,4.3,4.9,5.9,7.7c1.5,2.6,2.5,5.3,3,8.2C465.8,687.3,465.6,690.9,464.5,694.5z"/>
                            <g>
			<path className="st14" d="M440.3,677.4c-0.5-0.5-1.2-0.7-1.8-0.9c-1.5-0.6-3-1.1-4.5-1.7c-1.9-0.7-3.9-1.5-5.8-2.2
				c-0.8-0.3-1.6-0.6-2.4-0.9c-6-2.4-11.6-5.3-17.3-8.2c-1.3-0.6-2.6-1.3-3.9-1.8c-2.6-1-5.1-2.2-7.5-3.6c-1.3-0.7-2.5-1.5-3.8-2.2
				c-1.2-0.6-2.5-1.2-3.7-1.8c-0.5-0.2-0.9-0.2-1.3,0.2c-0.2,0.3-0.6,0.4-1,0.6c-0.9,0.4-1.9,0.8-2.9,1.2c-3.6,1.4-7.1,3-10.5,4.8
				c-1.9,1-3.9,1.9-5.8,2.8c-8.1,3.8-16.3,7.6-24.5,11.4c-2.4,1.1-4.6,2.4-6.7,3.9c-0.6,0.4-1.1,0.8-1.6,1.3
				c-0.3,0.2-0.2,0.4,0.1,0.6c0.4,0.3,0.9,0.6,1.3,0.8c1.1,0.5,2.2,1,3.3,1.4c3.7,1.4,7.3,3.1,10.9,4.8c5,2.4,10,4.8,15.1,7
				c3.8,1.7,7.5,3.6,11.1,5.7c2.2,1.3,4.3,2.7,6.4,3.9c2.7,1.6,5.5,3.1,8.5,4.1c0.5,0.1,0.8,0.2,1.2-0.1c1-0.6,1.9-1.2,2.9-1.8
				c2.4-1.3,4.7-2.8,6.9-4.3c6.4-4.3,12.9-8.5,19.1-12.9c2.8-2,5.6-3.9,8.4-5.9c2.9-2.1,5.8-4.1,8.9-5.8
				C439.8,677.8,440,677.6,440.3,677.4z"/>
                                <path className="st54" d="M442,676.7c-0.1-0.1-0.3-0.2-0.5-0.2c-1-0.4-1.9-0.8-2.9-1.2c-1.4-0.5-2.8-1-4.1-1.5
				c-1.9-0.7-3.7-1.5-5.6-2.1c-3.2-1.2-6.4-2.5-9.4-4c-3.7-1.8-7.4-3.7-11.1-5.6c-1-0.5-1.9-0.9-2.9-1.3c-2.7-1.1-5.3-2.3-7.9-3.7
				c-1.4-0.8-2.7-1.5-4.1-2.2c-1.3-0.7-2.7-1.3-4.2-1.7c-0.4-0.1-0.8-0.1-1.3-0.2c-0.2,0-0.4,0.1-0.5,0.3c-0.1,0.5-0.4,0.6-0.8,0.8
				c-1.2,0.5-2.5,1-3.7,1.5c-2.3,0.9-4.6,1.8-6.8,3c-4.2,2.3-8.6,4.2-13,6.3c-6.8,3.2-13.6,6.4-20.4,9.6c-2.2,1.1-4.3,2.3-6.4,3.8
				c-0.6,0.4-1.2,0.9-1.7,1.4c-0.2,0.2-0.3,0.4-0.5,0.6c-0.2,0.1-0.4,0.2-0.7,0.4c0.5,0.8,1.1,1.3,1.8,1.7c1.4,0.9,2.9,1.6,4.4,2.2
				c3.6,1.4,7,3,10.4,4.7c5.1,2.5,10.2,4.9,15.4,7.2c5.1,2.3,10.1,4.9,14.8,8c2.5,1.7,5.2,3.1,8,4.4c0.8,0.4,1.6,0.6,2.4,0.9
				c0.4,0.1,0.8,0.2,1.1,0.6c0.2,0.3,0.6,0.2,0.8,0.1c0.4-0.2,0.8-0.5,1.2-0.7c1.6-0.9,3.2-1.9,4.8-2.9c1.3-0.8,2.5-1.6,3.7-2.4
				c3.1-2.1,6.3-4.1,9.4-6.2c1.8-1.2,3.6-2.4,5.4-3.6c2.2-1.5,4.4-3,6.6-4.6c2-1.4,4-2.7,6-4.2c3.7-2.7,7.5-5.4,11.7-7.5
				c0.2-0.1,0.3-0.2,0.4-0.3C442.5,677.6,442.5,677.1,442,676.7z M439.5,678c-3.1,1.7-6,3.7-8.9,5.8c-2.8,2-5.6,3.9-8.4,5.9
				c-6.3,4.5-12.7,8.7-19.1,12.9c-2.3,1.5-4.6,3-6.9,4.3c-1,0.6-1.9,1.2-2.9,1.8c-0.4,0.3-0.8,0.2-1.2,0.1c-3-1-5.8-2.5-8.5-4.1
				c-2.2-1.3-4.3-2.7-6.4-3.9c-3.6-2.1-7.3-4-11.1-5.7c-5.1-2.2-10.1-4.6-15.1-7c-3.6-1.7-7.2-3.4-10.9-4.8
				c-1.1-0.4-2.2-0.9-3.3-1.4c-0.5-0.2-0.9-0.5-1.3-0.8c-0.3-0.2-0.3-0.4-0.1-0.6c0.5-0.4,1.1-0.9,1.6-1.3c2.1-1.5,4.4-2.8,6.7-3.9
				c8.1-3.8,16.3-7.6,24.5-11.4c2-0.9,3.9-1.8,5.8-2.8c3.4-1.8,6.9-3.4,10.5-4.8c1-0.4,1.9-0.8,2.9-1.2c0.4-0.2,0.7-0.3,1-0.6
				c0.4-0.4,0.8-0.5,1.3-0.2c1.2,0.6,2.5,1.1,3.7,1.8c1.3,0.7,2.6,1.4,3.8,2.2c2.4,1.4,4.9,2.5,7.5,3.6c1.3,0.5,2.6,1.2,3.9,1.8
				c5.7,2.9,11.4,5.9,17.3,8.2c0.8,0.3,1.6,0.6,2.4,0.9c1.9,0.7,3.9,1.5,5.8,2.2c1.5,0.6,3,1.1,4.5,1.7c0.6,0.2,1.2,0.4,1.8,0.9
				C440,677.6,439.8,677.8,439.5,678z"/>
		</g>
                            <g>
			<path d="M306.2,659.4c0-0.6,0.1-1.2,0.1-1.8c0-0.6,0.1-1.3,0.1-1.9c0.6-0.2,1.1-0.4,1.7-0.6c2.7-0.9,5.5-1.9,8.2-2.8
				c0.1,0,0.2-0.1,0.2-0.1c1.7-0.2,3.3-1.1,4.9-1.6c1.1-0.3,2.2-0.6,3.2-0.9c0.5-0.2,1.1-0.3,1.6-0.5c0.9-0.3,1.8-0.4,2.7-0.5
				c0.2,0,0.5,0,0.8,0.1c2.4,0.7,4.9,0.8,7.5,0.7c3.2-0.1,6.5-0.1,9.7-0.2c1.1,0,2.2-0.1,3.2-0.1c0.2,0,0.3,0,0.5,0
				c2.5,0.1,5,0.3,7.5,0.4c0.5,0,0.8,0.2,1.1,0.6c0.7,0.9,1,1.9,1.1,3c0,0.3-0.1,0.6-0.4,0.8c-0.5,0.4-1,0.7-1.5,1.1
				c-3.4,2.2-6.7,4.4-10.1,6.6c-1.9,1.3-4.1,1.9-6.3,2.7c-0.3,0.1-0.6,0.1-0.9,0.1c-1.8-0.1-3.5-0.2-5.3-0.2
				c-1.4-0.1-2.8-0.1-4.1-0.1c-1.8,0.1-3.6-0.2-5.4-0.3c-1.7-0.1-3.4-0.3-5.2-0.4c-2.5-0.1-4.9-0.3-7.4-0.7
				c-2.1-0.3-4.1-0.4-6.2-0.3c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8,0.5-1.1,0c-0.2-0.3-0.1-0.7-0.1-1.1
				C306.2,660.7,306.2,660.1,306.2,659.4z M335.2,654c1.2,0.2,2.3,0.3,3.5,0.5c1.2,0.1,2.5,0.3,3.7,0.4c0.8,0.1,1.4-0.3,1.9-1
				c-0.3-0.1-0.4-0.1-0.6-0.1c-2.4-0.3-4.8-0.4-7.1-1.2c-1.1-0.4-2,0.2-2.9,0.8C334.3,653.6,334.8,653.9,335.2,654
				c-0.4,0-0.9,0-1.4-0.2c-1.1-0.4-1.9,0.1-2.8,0.7c0,0,0,0.3,0.1,0.3c0.1,0.1,0.3,0.1,0.5,0.2c3.1,0.6,6.2,0.7,9.3,1
				c0.3,0,0.6,0,0.8-0.3c0-0.1,0.1-0.2,0.1-0.2c0-0.1-0.1-0.2-0.2-0.2c-1.1-0.1-2.1-0.5-3.2-0.6C337.4,654.6,336.3,654.6,335.2,654z
				 M339.6,650.4c-0.7,0-1.3,0-1.8,0c-1.1,0-2.2,0-3.4,0c-1.3,0-2.7-0.3-4-0.6c-0.4-0.1-0.7-0.4-1.1-0.4c-0.4,0-0.7,0.3-1.1,0.4
				c-0.2,0.1-0.3,0.1-0.5,0.1c-2.1,0.3-4,1-6,1.6c-0.5,0.2-1,0.4-1.5,0.7c-0.6,0.3-1.2,0.5-1.8,0.6c-0.9,0.1-1.8,0.4-2.6,0.7
				c-2.6,0.8-5.1,1.6-7.7,2.4c-0.4,0.1-0.9,0-1.2,0.4c0.3,0.5,0.8,0.1,1.2,0.2c0.2,0.1,0.5,0,0.8,0.1c2.1,0.2,4.1,0.4,6.2,0.3
				c1.3,0,2.6-0.1,3.9-0.1c2.6,0.2,5.3,0.4,7.9,0.6c1.1,0.1,2.1,0.3,3.2,0.4c1.6,0.2,3.3,0.3,4.9,0.4c1.6,0.1,3.3,0,4.9,0.1
				c1.5,0.2,2.7-0.2,3.9-0.9c1.8-1.1,3.7-2,5.6-3.1c2-1.1,3.9-2.3,6-3.3c0.2-0.1,0.4-0.2,0.6-0.3c0,0,0.1-0.1,0-0.1
				c0-0.1-0.1-0.2-0.1-0.2c-0.2-0.1-0.4-0.1-0.6-0.1c-2.1,0-4.1-0.2-6.2-0.1c-2.4,0.1-4.8,0.1-7.2,0.2c0.7,0.3,1.4,0.4,2.1,0.5
				c0.3,0,0.6,0,0.9,0c1.5,0,3,0.1,4.5,0.1c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.5,0.2,0.7c-0.1,0.2-0.3,0.3-0.5,0.4
				c-0.6,0.3-1.1,0.6-1.7,0.9c-1.9,0.9-3.8,2-5.4,3.3c-0.6,0.5-1.2,0.7-2,0.6c-1.5-0.2-3-0.3-4.5-0.5c-0.9-0.1-1.7-0.2-2.6-0.3
				c-1.1-0.2-2.1-0.3-3.2-0.5c-0.1,0-0.3,0-0.4-0.1c-0.1-0.1-0.3-0.2-0.4-0.3c0-0.1,0-0.3,0.2-0.4c0.2-0.2,0.4-0.3,0.6-0.4
				c0.8-0.4,1.6-0.9,2.4-1.3c0.7-0.4,1.4-0.6,2-1.2c0.1-0.1,0.4-0.2,0.6-0.2c0.2-0.1,0.5-0.1,0.7-0.2c0.6-0.4,1.3-0.4,2-0.5
				C338.5,650.9,339,650.8,339.6,650.4z M339.7,663.7c0.3,0,0.9,0,1.5,0c0.6,0,0.7-0.2,0.7-0.7c0-1-0.1-2-0.2-3
				c-0.1-0.7-0.1-0.8-0.8-0.8c-1.9-0.1-3.8-0.2-5.7-0.2c-2,0-4-0.2-5.9-0.5c-2.3-0.4-4.7-0.6-7.1-0.8c-1.6-0.1-3.1-0.1-4.7,0
				c-2.4,0.1-4.8,0.1-7.3-0.2c-0.9-0.1-1.7-0.2-2.6-0.2c-0.5,0-0.7,0.2-0.7,0.7c0,1.1,0.1,2.2,0.1,3.2c0,0.1,0,0.2,0,0.3
				c0.1,0.2,0.2,0.4,0.4,0.4c0.2,0,0.3,0,0.5,0c2.1-0.2,4.2-0.1,6.3,0.2c1.2,0.2,2.4,0.3,3.6,0.4c3.1,0.2,6.1,0.5,9.2,0.7
				c1.3,0.1,2.7,0.2,4,0.2c2.2,0,4.3,0,6.5,0.2C338.2,663.6,338.8,663.6,339.7,663.7z M347.4,661.3c-0.1-0.1-0.1-0.2-0.1-0.3
				c-0.7-0.4-0.8-1.1-0.8-1.8c0-0.8,0.2-1.6,0.9-2.2c0.3-0.2,0.6-0.4,0.9-0.2c0.3,0.1,0.4,0.4,0.5,0.7c0.1,0.7-0.1,1.4-0.3,2
				c-0.1,0.4-0.2,0.7-0.3,1.1c0.5,0.2,0.8-0.3,1.1-0.5c0.4-0.2,0.8-0.5,1.2-0.8c0.2-0.1,0.3-0.3,0.6-0.6c-1-0.4-1.1-1.1-1.1-2
				c0-0.4,0.1-0.8,0.2-1.3c0-0.2,0.2-0.4-0.1-0.6c-0.2,0.1-0.5,0.3-0.8,0.4c-1,0.5-2,1.1-3,1.6c-1.1,0.6-2.1,1.1-3.2,1.7
				c-0.7,0.3-1,0.8-0.9,1.5c0.1,0.9,0.2,1.9,0.3,2.8c0,0.3,0.2,0.4,0.5,0.3c1.3-0.5,2.6-1.1,3.9-1.7
				C347.2,661.6,347.3,661.4,347.4,661.3z M352.1,658.1c0.1,0,0.1,0.1,0.2,0.1c1.5-1,3-2.1,4.6-3.1c-0.1-0.1-0.1-0.2-0.2-0.3
				c-0.4-0.2-0.6-0.6-0.7-1.1c-0.1-0.5,0-1,0.3-1.5c0.1-0.2,0.3-0.3,0.5-0.3c0.8-0.1,1.3,0.3,1.3,1.1c0,0.3-0.1,0.7-0.1,1
				c1.3-0.4,1.5-0.7,1.1-1.8c-0.1-0.4-0.3-0.7-0.5-1.1c-0.2-0.4-0.4-0.5-0.9-0.3c-2.2,1-4.3,2-6.3,3.3c-0.2,0.1-0.4,0.2-0.4,0.5
				c1.1,0.8,1.4,1.3,1.1,2.6C352.3,657.5,352.2,657.8,352.1,658.1z M339.8,651.5c0.8,0.6,1,0.6,1.8,0.8c1.6,0.3,3.3,0.1,4.9,0.2
				c0.2,0,0.4,0,0.6-0.1c0.5-0.1,1-0.5,0.8-0.6c-0.3-0.3-0.6-0.2-1-0.2c-0.1,0-0.3,0-0.4,0c-1.3,0.1-2.7,0-4-0.2
				c-0.6-0.1-1.3-0.1-1.9-0.1C340.5,651.3,340.2,651.4,339.8,651.5z M341.6,652.9c-1.5-0.4-2.9-1.4-4.7-1c0.2,0.2,0.3,0.3,0.5,0.4
				C338.7,652.7,340.1,653,341.6,652.9z M350.8,656.9c0,0.5,0.2,0.9,0.4,0.8c0.3-0.1,0.4-0.4,0.5-0.7c0.1-0.6-0.1-1.1-0.4-1
				C351,656,350.8,656.3,350.8,656.9z M348.1,658.4c0-0.2,0-0.2,0-0.3c0-0.1-0.1-0.2-0.2-0.3c-0.1,0-0.2,0.1-0.3,0.2
				c-0.1,0.1-0.1,0.1-0.1,0.2c-0.3,0.9-0.2,1.8,0.1,1.6C348,659.5,347.9,658.8,348.1,658.4z M344.9,653.4c0.2,0,0.3,0,0.4,0
				c0.1,0,0.2-0.1,0.3-0.2c-0.1-0.1-0.2-0.3-0.2-0.3c-0.5,0-1,0-1.5-0.1c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.1,0.2
				C344.2,653.3,344.6,653.3,344.9,653.4z M357.4,653.3c0-0.1,0-0.1,0-0.2c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2
				c0,0.2,0,0.3,0,0.5c0,0.1,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.2-0.1C357.3,653.5,357.4,653.3,357.4,653.3z"/>
                                <g>
				<path d="M335.2,654c1.2,0.2,2.3,0.3,3.5,0.5c1.2,0.1,2.5,0.3,3.7,0.4c0.8,0.1,1.4-0.3,1.9-1c-0.3-0.1-0.4-0.1-0.6-0.1
					c-2.4-0.3-4.8-0.4-7.1-1.2c-1.1-0.4-2,0.2-2.9,0.8C334.3,653.6,334.8,653.9,335.2,654c-0.4,0-0.9,0-1.4-0.2
					c-1.1-0.4-1.9,0.1-2.8,0.7c0,0,0,0.3,0.1,0.3c0.1,0.1,0.3,0.1,0.5,0.2c3.1,0.6,6.2,0.7,9.3,1c0.3,0,0.6,0,0.8-0.3
					c0-0.1,0.1-0.2,0.1-0.2c0-0.1-0.1-0.2-0.2-0.2c-1.1-0.1-2.1-0.5-3.2-0.6C337.4,654.6,336.3,654.6,335.2,654z"/>
                                    <path className="st21" d="M341.7,655.4c-1.1-0.1-2.1-0.5-3.2-0.6c-1.1-0.1-2.2-0.1-3.2-0.7c0,0,0,0,0,0c0,0,0,0,0,0
					c-0.5-0.1-1,0-1.4-0.2c-1.1-0.4-1.9,0.1-2.8,0.7c0,0,0,0.3,0.1,0.3c0.1,0.1,0.3,0.1,0.5,0.2c3.1,0.6,6.2,0.7,9.3,1
					c0.3,0,0.6,0,0.8-0.3c0-0.1,0.1-0.2,0.1-0.2C341.8,655.5,341.7,655.4,341.7,655.4z"/>
                                    <path className="st32" d="M343.7,653.7c-2.4-0.3-4.8-0.4-7.1-1.2c-1.1-0.4-2,0.2-2.9,0.8c0.6,0.2,1,0.5,1.5,0.7c0.1,0,0.1,0,0.2,0
					c1.1,0.1,2.2,0.3,3.3,0.4c1.2,0.1,2.5,0.3,3.7,0.4c0.8,0.1,1.4-0.3,1.9-1C344,653.8,343.9,653.8,343.7,653.7z"/>
                                    <path className="st14" d="M339.6,650.4c-0.7,0-1.3,0-1.8,0c-1.1,0-2.2,0-3.4,0c-1.3,0-2.7-0.3-4-0.6c-0.4-0.1-0.7-0.4-1.1-0.4
					c-0.4,0-0.7,0.3-1.1,0.4c-0.2,0.1-0.3,0.1-0.5,0.1c-2.1,0.3-4,1-6,1.6c-0.5,0.2-1,0.4-1.5,0.7c-0.6,0.3-1.2,0.5-1.8,0.6
					c-0.9,0.1-1.8,0.4-2.6,0.7c-2.6,0.8-5.1,1.6-7.7,2.4c-0.4,0.1-0.9,0-1.2,0.4c0.3,0.5,0.8,0.1,1.2,0.2c0.2,0.1,0.5,0,0.8,0.1
					c2.1,0.2,4.1,0.4,6.2,0.3c1.3,0,2.6-0.1,3.9-0.1c2.6,0.2,5.3,0.4,7.9,0.6c1.1,0.1,2.1,0.3,3.2,0.4c1.6,0.2,3.3,0.3,4.9,0.4
					c1.6,0.1,3.3,0,4.9,0.1c1.5,0.2,2.7-0.2,3.9-0.9c1.8-1.1,3.7-2,5.6-3.1c2-1.1,3.9-2.3,6-3.3c0.2-0.1,0.4-0.2,0.6-0.3
					c0,0,0.1-0.1,0-0.1c0-0.1-0.1-0.2-0.1-0.2c-0.2-0.1-0.4-0.1-0.6-0.1c-2.1,0-4.1-0.2-6.2-0.1c-2.4,0.1-4.8,0.1-7.2,0.2
					c0.7,0.3,1.4,0.4,2.1,0.5c0.3,0,0.6,0,0.9,0c1.5,0,3,0.1,4.5,0.1c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.5,0.2,0.7
					c-0.1,0.2-0.3,0.3-0.5,0.4c-0.6,0.3-1.1,0.6-1.7,0.9c-1.9,0.9-3.8,2-5.4,3.3c-0.6,0.5-1.2,0.7-2,0.6c-1.5-0.2-3-0.3-4.5-0.5
					c-0.9-0.1-1.7-0.2-2.6-0.3c-1.1-0.2-2.1-0.3-3.2-0.5c-0.1,0-0.3,0-0.4-0.1c-0.1-0.1-0.3-0.2-0.4-0.3c0-0.1,0-0.3,0.2-0.4
					c0.2-0.2,0.4-0.3,0.6-0.4c0.8-0.4,1.6-0.9,2.4-1.3c0.7-0.4,1.4-0.6,2-1.2c0.1-0.1,0.4-0.2,0.6-0.2c0.2-0.1,0.5-0.1,0.7-0.2
					c0.6-0.4,1.3-0.4,2-0.5C338.5,650.9,339,650.8,339.6,650.4z"/>
                                    <path className="st14" d="M339.7,663.7c0.3,0,0.9,0,1.5,0c0.6,0,0.7-0.2,0.7-0.7c0-1-0.1-2-0.2-3c-0.1-0.7-0.1-0.8-0.8-0.8
					c-1.9-0.1-3.8-0.2-5.7-0.2c-2,0-4-0.2-5.9-0.5c-2.3-0.4-4.7-0.6-7.1-0.8c-1.6-0.1-3.1-0.1-4.7,0c-2.4,0.1-4.8,0.1-7.3-0.2
					c-0.9-0.1-1.7-0.2-2.6-0.2c-0.5,0-0.7,0.2-0.7,0.7c0,1.1,0.1,2.2,0.1,3.2c0,0.1,0,0.2,0,0.3c0.1,0.2,0.2,0.4,0.4,0.4
					c0.2,0,0.3,0,0.5,0c2.1-0.2,4.2-0.1,6.3,0.2c1.2,0.2,2.4,0.3,3.6,0.4c3.1,0.2,6.1,0.5,9.2,0.7c1.3,0.1,2.7,0.2,4,0.2
					c2.2,0,4.3,0,6.5,0.2C338.2,663.6,338.8,663.6,339.7,663.7z"/>
                                    <path className="st14" d="M347.4,661.3c-0.1-0.1-0.1-0.2-0.1-0.3c-0.7-0.4-0.8-1.1-0.8-1.8c0-0.8,0.2-1.6,0.9-2.2
					c0.3-0.2,0.6-0.4,0.9-0.2c0.3,0.1,0.4,0.4,0.5,0.7c0.1,0.7-0.1,1.4-0.3,2c-0.1,0.4-0.2,0.7-0.3,1.1c0.5,0.2,0.8-0.3,1.1-0.5
					c0.4-0.2,0.8-0.5,1.2-0.8c0.2-0.1,0.3-0.3,0.6-0.6c-1-0.4-1.1-1.1-1.1-2c0-0.4,0.1-0.8,0.2-1.3c0-0.2,0.2-0.4-0.1-0.6
					c-0.2,0.1-0.5,0.3-0.8,0.4c-1,0.5-2,1.1-3,1.6c-1.1,0.6-2.1,1.1-3.2,1.7c-0.7,0.3-1,0.8-0.9,1.5c0.1,0.9,0.2,1.9,0.3,2.8
					c0,0.3,0.2,0.4,0.5,0.3c1.3-0.5,2.6-1.1,3.9-1.7C347.2,661.6,347.3,661.4,347.4,661.3z"/>
                                    <path className="st14" d="M352.1,658.1c0.1,0,0.1,0.1,0.2,0.1c1.5-1,3-2.1,4.6-3.1c-0.1-0.1-0.1-0.2-0.2-0.3
					c-0.4-0.2-0.6-0.6-0.7-1.1c-0.1-0.5,0-1,0.3-1.5c0.1-0.2,0.3-0.3,0.5-0.3c0.8-0.1,1.3,0.3,1.3,1.1c0,0.3-0.1,0.7-0.1,1
					c1.3-0.4,1.5-0.7,1.1-1.8c-0.1-0.4-0.3-0.7-0.5-1.1c-0.2-0.4-0.4-0.5-0.9-0.3c-2.2,1-4.3,2-6.3,3.3c-0.2,0.1-0.4,0.2-0.4,0.5
					c1.1,0.8,1.4,1.3,1.1,2.6C352.3,657.5,352.2,657.8,352.1,658.1z"/>
                                    <path className="st13" d="M339.8,651.5c0.8,0.6,1,0.6,1.8,0.8c1.6,0.3,3.3,0.1,4.9,0.2c0.2,0,0.4,0,0.6-0.1c0.5-0.1,1-0.5,0.8-0.6
					c-0.3-0.3-0.6-0.2-1-0.2c-0.1,0-0.3,0-0.4,0c-1.3,0.1-2.7,0-4-0.2c-0.6-0.1-1.3-0.1-1.9-0.1
					C340.5,651.3,340.2,651.4,339.8,651.5z"/>
                                    <path className="st18" d="M341.6,652.9c-1.5-0.4-2.9-1.4-4.7-1c0.2,0.2,0.3,0.3,0.5,0.4C338.7,652.7,340.1,653,341.6,652.9z"/>
                                    <path className="st32" d="M350.8,656.9c0,0.5,0.2,0.9,0.4,0.8c0.3-0.1,0.4-0.4,0.5-0.7c0.1-0.6-0.1-1.1-0.4-1
					C351,656,350.8,656.3,350.8,656.9z"/>
                                    <path className="st21" d="M348.1,658.4c0-0.2,0-0.2,0-0.3c0-0.1-0.1-0.2-0.2-0.3c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.1,0.1-0.1,0.2
					c-0.3,0.9-0.2,1.8,0.1,1.6C348,659.5,347.9,658.8,348.1,658.4z"/>
                                    <path className="st18" d="M344.9,653.4c0.2,0,0.3,0,0.4,0c0.1,0,0.2-0.1,0.3-0.2c-0.1-0.1-0.2-0.3-0.2-0.3c-0.5,0-1,0-1.5-0.1
					c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.1,0.2C344.2,653.3,344.6,653.3,344.9,653.4z"/>
                                    <path className="st13" d="M357.4,653.3c0-0.1,0-0.1,0-0.2c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2c0,0.2,0,0.3,0,0.5
					c0,0.1,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.2-0.1C357.3,653.5,357.4,653.3,357.4,653.3z"/>
			</g>
		</g>
                            <g>
			<path d="M279.6,720.7c0.2-0.2,0.4-0.3,0.6-0.5c1-1,2.1-1.9,3.1-2.9c3.5-3.5,7.3-6.7,11.2-9.8c0.5-0.4,1.1-0.8,1.4-1.5
				c0.1-0.3,0.5-0.5,0.7-0.7c0.9-0.6,1.8-0.9,2.9-1.2c0.5-0.1,1-0.3,1.4-0.7c0.1-0.1,0.4-0.2,0.6-0.3c0.1,0,0.3,0.1,0.3,0.2
				c0.1,0.1,0,0.2,0.1,0.4c0,0,0,0.1-0.1,0.1c-0.8,0.4-0.8,1.2-1,1.9c-0.5,1.4-1.3,2.5-2.3,3.6c-0.1,0.2-0.4,0.3-0.5,0.4
				c-1.2,2-3.1,3.2-4.9,4.6c-0.4,0.3-0.8,0.6-1.3,0.9c-1.4,0.9-2.7,2-4,3.1c-1.4,1.3-2.9,2.5-4.3,3.7c-0.7,0.6-1.4,1.2-2,1.9
				c-1.8,1.9-3.9,3.5-5.8,5.2c-0.7,0.6-1.5,1-2.4,1.2c-0.5,0.1-1.1,0.1-1.7,0.1c-0.6-0.1-0.9-0.5-0.9-1.1c0-0.4,0.1-0.8,0.3-1.1
				c0.3-0.6,0.7-1.3,1.1-1.9c1.4-1.7,2.8-3.4,4.2-5.1c0.4-0.5,0.9-0.9,1.5-1.3c0.3-0.2,0.6-0.3,1-0.1c0.3,0.1,0.5,0.4,0.5,0.7
				C279.4,720.5,279.5,720.6,279.6,720.7z M278.5,725.2c0.5,0,0.9-0.3,1.2-0.7c1.8-2,3.8-3.6,5.8-5.4c2-1.8,4.1-3.5,6.3-5
				c1.2-0.8,2.3-1.7,3.4-2.6c0.5-0.4,0.9-0.8,1.3-1.3c0.4-0.4,0.7-1,0.8-1.6c0-0.2,0-0.5,0-0.8c-0.2-0.7-0.7-0.8-1.3-0.4
				c-0.1,0.1-0.2,0.2-0.3,0.2c-3.4,2.9-6.9,5.7-10.1,8.8c-2.4,2.2-4.8,4.3-7.3,6.4c-0.2,0.2-0.4,0.3-0.6,0.5
				c-0.4,0.3-0.4,0.8-0.3,1.2C277.6,725,278.1,725.2,278.5,725.2z M272.3,729.8c0.2,0,0.5-0.1,0.7-0.2c1.9-0.6,3.1-2.1,4.6-3.3
				c0.1-0.1,0-0.2,0-0.3c-0.1-0.1-0.2-0.2-0.3-0.3c-1-0.9-1-1.6-0.1-2.7c0.1-0.1,0.2-0.2,0.3-0.3c0.5-0.4,0.9-1,1-1.6
				c0.1-0.2,0.3-0.5,0-0.7c-0.1-0.1-0.4,0.1-0.6,0.2c-0.3,0.2-0.6,0.5-0.9,0.8c-1.8,1.8-3.3,3.8-4.8,5.9c-0.3,0.4-0.5,1-0.7,1.5
				C271.3,729.4,271.6,729.8,272.3,729.8z M298.6,708c0.6-0.9,1.4-1.7,1.4-2.9c0-0.3-1.6,0.1-2.8,0.8c0,0-0.1,0.1-0.1,0.1
				C297.5,706.6,298,707.2,298.6,708z"/>
                                <path className="st15" d="M278.5,725.2c0.5,0,0.9-0.3,1.2-0.7c1.8-2,3.8-3.6,5.8-5.4c2-1.8,4.1-3.5,6.3-5c1.2-0.8,2.3-1.7,3.4-2.6
				c0.5-0.4,0.9-0.8,1.3-1.3c0.4-0.4,0.7-1,0.8-1.6c0-0.2,0-0.5,0-0.8c-0.2-0.7-0.7-0.8-1.3-0.4c-0.1,0.1-0.2,0.2-0.3,0.2
				c-3.4,2.9-6.9,5.7-10.1,8.8c-2.4,2.2-4.8,4.3-7.3,6.4c-0.2,0.2-0.4,0.3-0.6,0.5c-0.4,0.3-0.4,0.8-0.3,1.2
				C277.6,725,278.1,725.2,278.5,725.2z"/>
                                <path className="st21" d="M272.3,729.8c0.2,0,0.5-0.1,0.7-0.2c1.9-0.6,3.1-2.1,4.6-3.3c0.1-0.1,0-0.2,0-0.3c-0.1-0.1-0.2-0.2-0.3-0.3
				c-1-0.9-1-1.6-0.1-2.7c0.1-0.1,0.2-0.2,0.3-0.3c0.5-0.4,0.9-1,1-1.6c0.1-0.2,0.3-0.5,0-0.7c-0.1-0.1-0.4,0.1-0.6,0.2
				c-0.3,0.2-0.6,0.5-0.9,0.8c-1.8,1.8-3.3,3.8-4.8,5.9c-0.3,0.4-0.5,1-0.7,1.5C271.3,729.4,271.6,729.8,272.3,729.8z"/>
                                <path className="st21" d="M298.6,708c0.6-0.9,1.4-1.7,1.4-2.9c0-0.3-1.6,0.1-2.8,0.8c0,0-0.1,0.1-0.1,0.1
				C297.5,706.6,298,707.2,298.6,708z"/>
		</g>
                            <g>
			<path d="M330.1,706.4c0.2,2.1,0,4.2-0.4,6.3c-0.4,2.4-0.7,4.9-1.1,7.3c-0.2,1.1-0.4,2.2-0.6,3.3c-0.2,1.7-0.3,3.4-0.5,5.2
				c0,0.1,0,0.2,0,0.3c0.1,0.9-0.4,1.4-1.2,1.7c-0.8,0.3-1.6,0.3-2.3-0.3c-0.2-0.2-0.4-0.5-0.4-0.8c-0.3-1-0.4-1.9-0.2-2.9
				c0.1-0.6,0.2-1.2,0.2-1.8c0-1.9,0.3-3.8,0.6-5.7c0.6-3.2,1.2-6.4,1.5-9.6c0.1-1.6,0.4-3.3,0.5-4.9c0.2-2.2,0.8-4.2,1.7-6.2
				c0.1-0.2,0.2-0.3,0.3-0.4c0.2-0.3,0.7-0.2,0.8,0.1c0.2,0.9,0.4,1.8,0.6,2.8c0.1,0.3,0,0.6,0.1,0.9c0.4,1,0.3,2.1,0.3,3.2
				C330.1,705.3,330.1,705.9,330.1,706.4z M326.8,727c0.1-0.7,0.2-1.5,0.3-2.3c0-0.5,0-1,0.1-1.4c0.4-2.6,0.9-5.2,1.3-7.8
				c0.2-1.5,0.4-3,0.6-4.5c0.2-2,0.3-4,0.2-6c0-0.6,0-1.3,0-1.9c0-0.1-0.1-0.4-0.2-0.4c-0.5-0.3-1.1-0.3-1.6,0
				c-0.2,0.1-0.3,0.3-0.3,0.5c-0.1,0.2,0,0.4,0,0.6c-0.1,1.7-0.2,3.4-0.3,5c-0.3,3.1-0.7,6.3-1.4,9.4c-0.6,2.8-0.9,5.5-1,8.4
				c0,0.4,0.1,0.5,0.5,0.5C325.5,727.1,326,727.1,326.8,727z M325.6,727.9c-0.7,0-1.1,0.3-1.1,0.8c0,0.8,0.4,1.2,1.1,1.2
				c0.6,0,1-0.3,1-0.8C326.5,728.4,326.1,727.9,325.6,727.9z M328.5,699.7c-0.1,0.1-0.2,0.1-0.2,0.1c-0.2,0.4-0.4,0.9-0.5,1.3
				c-0.2,0.4,0,0.6,0.5,0.6c0.5,0,0.6-0.1,0.6-0.6C328.8,700.6,328.9,700.1,328.5,699.7z"/>
                                <path className="st13" d="M326.8,727c0.1-0.7,0.2-1.5,0.3-2.3c0-0.5,0-1,0.1-1.4c0.4-2.6,0.9-5.2,1.3-7.8c0.2-1.5,0.4-3,0.6-4.5
				c0.2-2,0.3-4,0.2-6c0-0.6,0-1.3,0-1.9c0-0.1-0.1-0.4-0.2-0.4c-0.5-0.3-1.1-0.3-1.6,0c-0.2,0.1-0.3,0.3-0.3,0.5
				c-0.1,0.2,0,0.4,0,0.6c-0.1,1.7-0.2,3.4-0.3,5c-0.3,3.1-0.7,6.3-1.4,9.4c-0.6,2.8-0.9,5.5-1,8.4c0,0.4,0.1,0.5,0.5,0.5
				C325.5,727.1,326,727.1,326.8,727z"/>
                                <path className="st15" d="M325.6,727.9c-0.7,0-1.1,0.3-1.1,0.8c0,0.8,0.4,1.2,1.1,1.2c0.6,0,1-0.3,1-0.8
				C326.5,728.4,326.1,727.9,325.6,727.9z"/>
                                <path className="st15" d="M328.5,699.7c-0.1,0.1-0.2,0.1-0.2,0.1c-0.2,0.4-0.4,0.9-0.5,1.3c-0.2,0.4,0,0.6,0.5,0.6
				c0.5,0,0.6-0.1,0.6-0.6C328.8,700.6,328.9,700.1,328.5,699.7z"/>
		</g>
                            <g>
			<path d="M339.6,700.7c-0.1,1-0.2,1.8-0.3,2.6c-0.1,0.8,0,1.6-0.1,2.3c-0.2,2.1-0.5,4.3-0.7,6.4c-0.2,1.2-0.4,2.5-0.5,3.7
				c-0.2,1.4-0.3,2.8-0.5,4.3c-0.1,1.7-0.2,3.5-0.3,5.2c-0.1,0.9-0.1,1.9-0.3,2.8c-0.4,2.2-1.6,2.9-3.7,2.5c-1-0.2-1.4-0.9-1.1-1.9
				c0.8-2.6,1.4-5.3,1.9-7.9c0.4-2.1,0.6-4.3,0.9-6.4c0.2-1.5,0.5-3.1,0.6-4.6c0.2-1.3,0.3-2.7,0.4-4c0.1-1.2,0.3-2.4,0.8-3.5
				c0.4-0.9,0.8-1.9,1.2-2.9c0.1-0.3,0.2-0.7,0.4-0.9c0.1-0.2,0.3-0.3,0.5-0.3c0.3,0,0.5,0.2,0.5,0.5
				C339.4,699.2,339.5,700,339.6,700.7z M338.5,704.2c0-0.2,0-0.5-0.1-0.9c0-0.3-0.5-0.6-0.8-0.5c-0.2,0.1-0.5,0.3-0.5,0.5
				c-0.2,0.6-0.3,1.3-0.4,1.9c-0.1,0.7,0,1.5-0.1,2.2c-0.3,2.8-0.6,5.6-1,8.4c-0.5,3.3-1,6.7-1.9,9.9c-0.1,0.4-0.2,0.8-0.3,1.3
				c-0.1,0.4,0.1,0.6,0.5,0.5c0.7-0.1,1.4-0.2,2,0.2c0.3,0.2,0.5,0,0.5-0.3c0.1-1.2,0.2-2.5,0.3-3.7c0.1-1.9,0.2-3.7,0.3-5.6
				c0.1-0.9,0.2-1.7,0.3-2.6c0.5-2.7,0.6-5.4,0.9-8.1C338.3,706.3,338.4,705.3,338.5,704.2z M334,729.8c0.4-0.1,0.7-0.1,1-0.2
				c0.4-0.1,0.6-0.4,0.6-0.7c0-0.4-0.2-0.6-0.6-0.8c-0.7-0.3-1.7,0.1-2.1,0.8c-0.1,0.2,0,0.4,0.2,0.5
				C333.4,729.7,333.7,729.7,334,729.8z M338.4,700c-0.2,0.5-0.4,0.9-0.6,1.4c0,0.1,0,0.3,0.1,0.3c0.2,0.1,0.4,0.2,0.6,0.1
				c0.1,0,0.2-0.2,0.3-0.4C338.8,701,338.8,700.5,338.4,700z"/>
                                <path className="st32" d="M338.5,704.2c0-0.2,0-0.5-0.1-0.9c0-0.3-0.5-0.6-0.8-0.5c-0.2,0.1-0.5,0.3-0.5,0.5
				c-0.2,0.6-0.3,1.3-0.4,1.9c-0.1,0.7,0,1.5-0.1,2.2c-0.3,2.8-0.6,5.6-1,8.4c-0.5,3.3-1,6.7-1.9,9.9c-0.1,0.4-0.2,0.8-0.3,1.3
				c-0.1,0.4,0.1,0.6,0.5,0.5c0.7-0.1,1.4-0.2,2,0.2c0.3,0.2,0.5,0,0.5-0.3c0.1-1.2,0.2-2.5,0.3-3.7c0.1-1.9,0.2-3.7,0.3-5.6
				c0.1-0.9,0.2-1.7,0.3-2.6c0.5-2.7,0.6-5.4,0.9-8.1C338.3,706.3,338.4,705.3,338.5,704.2z"/>
                                <path className="st15" d="M334,729.8c0.4-0.1,0.7-0.1,1-0.2c0.4-0.1,0.6-0.4,0.6-0.7c0-0.4-0.2-0.6-0.6-0.8c-0.7-0.3-1.7,0.1-2.1,0.8
				c-0.1,0.2,0,0.4,0.2,0.5C333.4,729.7,333.7,729.7,334,729.8z"/>
                                <path className="st15" d="M338.4,700c-0.2,0.5-0.4,0.9-0.6,1.4c0,0.1,0,0.3,0.1,0.3c0.2,0.1,0.4,0.2,0.6,0.1c0.1,0,0.2-0.2,0.3-0.4
				C338.8,701,338.8,700.5,338.4,700z"/>
		</g>
                            <g>
			<path d="M346.1,707.1c0.1,1.3,0.2,2.7,0.3,4.1c0,0.3,0.1,0.6,0.1,0.9c0.1,4.3,0.2,8.6,0.3,13c0,0.4,0,0.8,0,1.2
				c0,0.3,0,0.5,0,0.8c-0.1,0.9-0.6,1.4-1.4,1.4c-0.9,0-1.5-0.6-1.5-1.5c-0.1-4.1-0.3-8.2-0.4-12.3c-0.1-2.6-0.1-5.2-0.1-7.8
				c0-1.3-0.2-2.7-0.2-4c0-1.6,0.1-3.1,0.5-4.6c0-0.2,0.1-0.3,0.2-0.5c0.1-0.1,0.3-0.3,0.4-0.3c0.1,0,0.3,0.1,0.4,0.3
				c0.3,0.8,0.7,1.6,0.9,2.4C346,702.4,346.5,704.7,346.1,707.1z M344.4,715.7C344.4,715.7,344.4,715.7,344.4,715.7
				c0.1,2.8,0.1,5.5,0.1,8.3c0,0.2,0,0.4,0,0.6c0,0.4,0.1,0.5,0.4,0.4c0.2,0,0.3,0,0.5,0c0.5,0,0.6-0.1,0.6-0.6
				c0-1.9-0.1-3.7-0.1-5.6c0-0.3,0-0.5,0-0.8c-0.3-1.5-0.2-3-0.2-4.5c0-0.7,0-1.4-0.1-2.1c-0.2-2-0.4-4-0.2-5.9c0.1-1-0.1-2-0.2-3
				c0-0.3,0-0.7-0.5-0.8c-0.3,0-0.5,0.3-0.4,0.9c0.1,1.8,0.2,3.5,0.2,5.3C344.4,710.6,344.4,713.1,344.4,715.7z M345.9,726.6
				c-0.1-0.1-0.2-0.4-0.4-0.6c-0.2-0.3-0.6-0.2-0.8,0c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.4,0.3,0.8,0.8,0.8
				C345.6,727.5,345.9,727.2,345.9,726.6z M344.1,700.9c0.4,0.1,0.8-0.2,0.7-0.6c-0.1-0.3-0.2-0.6-0.3-0.8c0-0.1-0.1-0.1-0.2-0.1
				c0,0-0.1,0-0.1,0c-0.1,0.4-0.2,0.8-0.3,1.2C343.9,700.7,344.1,700.8,344.1,700.9z"/>
                                <path className="st21" d="M344.4,715.7C344.4,715.7,344.4,715.7,344.4,715.7c0.1,2.8,0.1,5.5,0.1,8.3c0,0.2,0,0.4,0,0.6
				c0,0.4,0.1,0.5,0.4,0.4c0.2,0,0.3,0,0.5,0c0.5,0,0.6-0.1,0.6-0.6c0-1.9-0.1-3.7-0.1-5.6c0-0.3,0-0.5,0-0.8
				c-0.3-1.5-0.2-3-0.2-4.5c0-0.7,0-1.4-0.1-2.1c-0.2-2-0.4-4-0.2-5.9c0.1-1-0.1-2-0.2-3c0-0.3,0-0.7-0.5-0.8
				c-0.3,0-0.5,0.3-0.4,0.9c0.1,1.8,0.2,3.5,0.2,5.3C344.4,710.6,344.4,713.1,344.4,715.7z"/>
                                <path className="st15" d="M345.9,726.6c-0.1-0.1-0.2-0.4-0.4-0.6c-0.2-0.3-0.6-0.2-0.8,0c-0.1,0.2-0.2,0.4-0.3,0.6
				c-0.1,0.4,0.3,0.8,0.8,0.8C345.6,727.5,345.9,727.2,345.9,726.6z"/>
                                <path className="st15" d="M344.1,700.9c0.4,0.1,0.8-0.2,0.7-0.6c-0.1-0.3-0.2-0.6-0.3-0.8c0-0.1-0.1-0.1-0.2-0.1c0,0-0.1,0-0.1,0
				c-0.1,0.4-0.2,0.8-0.3,1.2C343.9,700.7,344.1,700.8,344.1,700.9z"/>
		</g>
                            <g>
			<path d="M269.6,645.7c1.3,0.2,2.7,0.3,4.1,0.5c1.4,0.2,1.7,1.2,0.8,2.2c-0.4,0.4-0.8,0.7-1.3,1c-1.3,0.7-2.5,1.4-4,1.7
				c-0.1,0-0.2,0.1-0.4,0.1c-2.1,0.8-4.3,1.4-6.6,1.8c-2.5,0.4-5,1-7.5,1.4c-0.6,0.1-1.1,0.2-1.7,0.3c-0.6,0.1-1.3,0.2-1.9,0.4
				c-0.8,0.3-1.7-0.1-2-0.9c-0.5-1-0.3-2.1,0.1-3.1c0.2-0.3,0.5-0.5,0.8-0.6c2.8-0.8,5.6-1.6,8.4-2.4c2-0.6,4.1-0.9,6.2-1.3
				c0.9-0.2,1.9-0.3,2.7-0.8C268.1,645.8,268.9,645.7,269.6,645.7z M269.1,648.6c0-0.6-0.4-1.3-0.9-1.7c-0.3-0.2-0.5-0.1-0.9,0
				c-1.1,0.3-2.2,0.6-3.3,0.9c-0.7,0.2-1.4,0.3-2.1,0.4c-3,0.8-6,1.6-9,2.4c-0.2,0-0.3,0.1-0.5,0.2c-0.4,0.2-0.5,0.3-0.3,0.6
				c0.4,0.6,0.7,1.3,0.6,2.1c0,0.3,0.2,0.4,0.5,0.3c0.5-0.1,1-0.2,1.5-0.3c2.4-0.5,4.9-1.1,7.3-1.5c1.9-0.4,3.7-0.8,5.5-1.4
				C268.6,650.3,269.1,649.6,269.1,648.6z M269.8,649.9c1.2-0.3,2.9-1.2,3.6-1.7c0.2-0.1,0.3-0.3,0.4-0.4c0.2-0.3,0.2-0.6-0.2-0.6
				c-1-0.2-2-0.3-3.1-0.5c-1.2-0.1-1.2-0.1-0.7,0.9C270.2,648.3,270.1,649,269.8,649.9z M249.8,652.8c0,0.2,0,0.5,0.1,0.7
				c0.1,0.3,0.2,0.6,0.6,0.7c0.3,0.1,0.8,0,1-0.4c0.1-0.2,0.2-0.4,0.3-0.5c0.1-0.5-0.4-1.4-0.9-1.6c-0.4-0.2-0.8,0-0.9,0.4
				C249.9,652.3,249.9,652.5,249.8,652.8z"/>
                                <path className="st14" d="M269.1,648.6c0-0.6-0.4-1.3-0.9-1.7c-0.3-0.2-0.5-0.1-0.9,0c-1.1,0.3-2.2,0.6-3.3,0.9
				c-0.7,0.2-1.4,0.3-2.1,0.4c-3,0.8-6,1.6-9,2.4c-0.2,0-0.3,0.1-0.5,0.2c-0.4,0.2-0.5,0.3-0.3,0.6c0.4,0.6,0.7,1.3,0.6,2.1
				c0,0.3,0.2,0.4,0.5,0.3c0.5-0.1,1-0.2,1.5-0.3c2.4-0.5,4.9-1.1,7.3-1.5c1.9-0.4,3.7-0.8,5.5-1.4
				C268.6,650.3,269.1,649.6,269.1,648.6z"/>
                                <path className="st14" d="M269.8,649.9c1.2-0.3,2.9-1.2,3.6-1.7c0.2-0.1,0.3-0.3,0.4-0.4c0.2-0.3,0.2-0.6-0.2-0.6
				c-1-0.2-2-0.3-3.1-0.5c-1.2-0.1-1.2-0.1-0.7,0.9C270.2,648.3,270.1,649,269.8,649.9z"/>
                                <path className="st14" d="M249.8,652.8c0,0.2,0,0.5,0.1,0.7c0.1,0.3,0.2,0.6,0.6,0.7c0.3,0.1,0.8,0,1-0.4c0.1-0.2,0.2-0.4,0.3-0.5
				c0.1-0.5-0.4-1.4-0.9-1.6c-0.4-0.2-0.8,0-0.9,0.4C249.9,652.3,249.9,652.5,249.8,652.8z"/>
		</g>
                            <g>
			<path d="M208.4,661.1c-0.1-0.4,0.1-0.8,0.5-1.1c2-1.8,4-3.6,6.4-4.8c0.9-0.5,1.8-1.1,2.7-1.7c1.7-1.1,3.4-2.2,5.4-2.9
				c0.4-0.1,0.7-0.3,1.1-0.3c0.5-0.1,0.9,0.2,0.9,0.8c0,0.3,0,0.6-0.1,0.8c-0.4,1.6-1.4,2.9-2.8,3.9c-0.9,0.6-1.7,1.3-2.5,2
				c-1.5,1.3-3.1,2.5-4.8,3.6c-0.5,0.3-1,0.6-1.5,1c-0.6,0.6-1.4,0.9-2.1,1.1C209.8,664.2,208.3,663.1,208.4,661.1z M213.5,661.3
				c0.3,0,0.4,0,0.4-0.1c2.1-1.4,4.2-2.9,6-4.7c0.9-0.8,1.1-1.5,0.3-2.7c-0.2-0.2-0.4-0.3-0.7-0.2c-0.2,0.1-0.4,0.2-0.6,0.3
				c-1.5,0.9-3,1.8-4.5,2.7c-0.8,0.5-1.6,1.1-2.4,1.6c-0.2,0.1-0.3,0.3-0.5,0.4c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0.2,0.2,0.3,0.2
				c0.1,0,0.3,0,0.4,0c0.9-0.1,1.2,0.5,1.3,1.1C213.6,660.6,213.5,660.9,213.5,661.3z M210.7,662.9c0.6,0,1-0.3,1.3-0.7
				c0.3-0.4,0.4-0.9,0.5-1.4c0.1-0.5-0.1-0.7-0.7-0.8c-0.2,0-0.5-0.2-0.7-0.2c-0.2,0-0.3,0-0.5,0c-0.5,0.2-0.9,0.6-1.2,1
				C208.8,662,209.9,663,210.7,662.9z M220.9,652.7c0.4,0.8,0.7,1.6,1.1,2.5c1.1-0.9,1.9-1.9,2.3-3.2c0.1-0.2,0-0.4-0.1-0.6
				c0-0.1-0.3-0.1-0.5,0c-0.9,0.3-1.7,0.7-2.6,1C221.1,652.5,221,652.6,220.9,652.7z"/>
                                <path className="st32" d="M213.5,661.3c0.3,0,0.4,0,0.4-0.1c2.1-1.4,4.2-2.9,6-4.7c0.9-0.8,1.1-1.5,0.3-2.7c-0.2-0.2-0.4-0.3-0.7-0.2
				c-0.2,0.1-0.4,0.2-0.6,0.3c-1.5,0.9-3,1.8-4.5,2.7c-0.8,0.5-1.6,1.1-2.4,1.6c-0.2,0.1-0.3,0.3-0.5,0.4c-0.1,0.1-0.1,0.2-0.1,0.3
				c0,0.1,0.2,0.2,0.3,0.2c0.1,0,0.3,0,0.4,0c0.9-0.1,1.2,0.5,1.3,1.1C213.6,660.6,213.5,660.9,213.5,661.3z"/>
                                <path className="st32" d="M210.7,662.9c0.6,0,1-0.3,1.3-0.7c0.3-0.4,0.4-0.9,0.5-1.4c0.1-0.5-0.1-0.7-0.7-0.8c-0.2,0-0.5-0.2-0.7-0.2
				c-0.2,0-0.3,0-0.5,0c-0.5,0.2-0.9,0.6-1.2,1C208.8,662,209.9,663,210.7,662.9z"/>
                                <path className="st32" d="M220.9,652.7c0.4,0.8,0.7,1.6,1.1,2.5c1.1-0.9,1.9-1.9,2.3-3.2c0.1-0.2,0-0.4-0.1-0.6c0-0.1-0.3-0.1-0.5,0
				c-0.9,0.3-1.7,0.7-2.6,1C221.1,652.5,221,652.6,220.9,652.7z"/>
		</g>
                            <g>
			<path d="M178.2,679.3c0.1-0.3,0.2-0.9,0.4-1.3c0.7-1.7,1.4-3.3,2.2-5c0.9-1.8,1.8-3.5,2.7-5.3c0.8-1.6,2.1-2.9,3.3-4.1
				c0.1-0.1,0.1-0.1,0.2-0.2c0.5-0.3,1-0.2,1.2,0.3c0.2,0.5,0.3,1,0.2,1.5c-0.2,1.1-0.4,2.2-0.9,3.2c-0.3,0.6-0.5,1.3-0.7,2
				c-0.4,1.6-0.9,3.2-1.3,4.7c-0.6,1.9-1.5,3.7-2.5,5.4c-0.1,0.2-0.3,0.4-0.5,0.6c-0.3,0.2-0.6,0.4-1,0.6c-1.3,0.9-2.7,0.4-3.1-1.2
				C178.3,680.2,178.3,679.9,178.2,679.3z M180.1,676.5c1.5,0.5,1.6,0.6,2.3,1.9c0,0,0,0.1,0.1,0.1c0.1,0.2,0.3,0.2,0.4,0
				c0.3-0.5,0.5-1,0.8-1.5c1-2.2,1.5-4.6,2.1-6.9c0.1-0.5,0.2-1,0.3-1.5c0.1-0.4-0.1-0.6-0.4-0.7c-0.8-0.2-1.1-0.2-1.5,0.6
				C182.7,671.2,181.5,673.8,180.1,676.5z M181.6,679.5c0-0.5-0.3-1.1-0.7-1.5c-0.2-0.2-0.6-0.3-0.8-0.1c-0.9,0.6-1.2,2.1-0.6,3
				c0.2,0.3,0.6,0.4,1,0.3C181.2,680.9,181.6,680.3,181.6,679.5z M187.3,665.4c0-0.2,0-0.3,0-0.3c0-0.1-0.1-0.3-0.1-0.3
				c-0.1,0-0.3,0-0.3,0.1c-0.6,0.5-1.1,1.1-1.6,1.7c-0.3,0.3-0.2,0.5,0.3,0.6c0.2,0,0.3,0,0.5,0.1c0.8,0.1,0.9,0.1,1.1-0.7
				C187.2,666.1,187.3,665.7,187.3,665.4z"/>
                                <path className="st13" d="M180.1,676.5c1.5,0.5,1.6,0.6,2.3,1.9c0,0,0,0.1,0.1,0.1c0.1,0.2,0.3,0.2,0.4,0c0.3-0.5,0.5-1,0.8-1.5
				c1-2.2,1.5-4.6,2.1-6.9c0.1-0.5,0.2-1,0.3-1.5c0.1-0.4-0.1-0.6-0.4-0.7c-0.8-0.2-1.1-0.2-1.5,0.6
				C182.7,671.2,181.5,673.8,180.1,676.5z"/>
                                <path className="st13" d="M181.6,679.5c0-0.5-0.3-1.1-0.7-1.5c-0.2-0.2-0.6-0.3-0.8-0.1c-0.9,0.6-1.2,2.1-0.6,3
				c0.2,0.3,0.6,0.4,1,0.3C181.2,680.9,181.6,680.3,181.6,679.5z"/>
                                <path className="st13" d="M187.3,665.4c0-0.2,0-0.3,0-0.3c0-0.1-0.1-0.3-0.1-0.3c-0.1,0-0.3,0-0.3,0.1c-0.6,0.5-1.1,1.1-1.6,1.7
				c-0.3,0.3-0.2,0.5,0.3,0.6c0.2,0,0.3,0,0.5,0.1c0.8,0.1,0.9,0.1,1.1-0.7C187.2,666.1,187.3,665.7,187.3,665.4z"/>
		</g>
                            <g>
			<path d="M194.8,665.1c-0.2,1.5-0.3,2.8-0.8,4.1c-0.1,0.4-0.2,0.8-0.2,1.1c-0.2,0.9-0.3,1.9-0.5,2.8c-0.4,1.4-0.8,2.8-1.3,4.2
				c-0.4,1.4-1,2.7-1.7,3.9c-0.1,0.2-0.2,0.5-0.4,0.7c-0.4,0.5-0.9,0.8-1.6,0.6c-0.7-0.2-1.1-0.7-1.2-1.4c-0.1-0.6,0-1.3,0.1-1.9
				c0.2-1.2,0.6-2.3,0.9-3.5c0.6-2.1,1.1-4.3,1.9-6.3c0.5-1.5,1.3-3,2.1-4.5c0.2-0.4,0.5-0.8,0.8-1.1c0.1-0.2,0.3-0.3,0.5-0.4
				c0.5-0.2,1,0,1.2,0.5C194.7,664.5,194.7,664.9,194.8,665.1z M190.4,679.2c0.1-0.2,0.2-0.3,0.2-0.4c0.7-2.4,1.4-4.7,2-7.1
				c0.2-0.9,0.1-1.9,0.1-2.8c0-0.3-0.2-0.4-0.5-0.5c-0.4,0-0.7,0.1-0.9,0.4c-0.2,0.4-0.3,0.8-0.4,1.2c-0.7,2.4-1.5,4.8-2.2,7.2
				c-0.1,0.3-0.3,0.6-0.2,0.9c0.1,0.4,0.5,0.1,0.8,0.2c0.2,0.1,0.4,0.2,0.6,0.4C190.1,678.8,190.2,678.9,190.4,679.2z M187.8,680.4
				c0.1,0.4,0.1,0.6,0.2,0.9c0.2,0.5,0.8,0.6,1.1,0.3c0.7-0.7,0.7-1.6,0-2.3c-0.3-0.3-0.7-0.3-0.9,0.1
				C188,679.7,187.9,680.1,187.8,680.4z M193.4,667.6c0.4-1,0.5-1.6,0.4-2.5c0-0.1-0.1-0.3-0.2-0.3c-0.1,0-0.2,0.1-0.3,0.2
				c-0.2,0.3-0.4,0.5-0.6,0.8C191.6,667.5,191.8,667.2,193.4,667.6z"/>
                                <path className="st18" d="M190.4,679.2c0.1-0.2,0.2-0.3,0.2-0.4c0.7-2.4,1.4-4.7,2-7.1c0.2-0.9,0.1-1.9,0.1-2.8
				c0-0.3-0.2-0.4-0.5-0.5c-0.4,0-0.7,0.1-0.9,0.4c-0.2,0.4-0.3,0.8-0.4,1.2c-0.7,2.4-1.5,4.8-2.2,7.2c-0.1,0.3-0.3,0.6-0.2,0.9
				c0.1,0.4,0.5,0.1,0.8,0.2c0.2,0.1,0.4,0.2,0.6,0.4C190.1,678.8,190.2,678.9,190.4,679.2z"/>
                                <path className="st18" d="M187.8,680.4c0.1,0.4,0.1,0.6,0.2,0.9c0.2,0.5,0.8,0.6,1.1,0.3c0.7-0.7,0.7-1.6,0-2.3
				c-0.3-0.3-0.7-0.3-0.9,0.1C188,679.7,187.9,680.1,187.8,680.4z"/>
                                <path className="st18" d="M193.4,667.6c0.4-1,0.5-1.6,0.4-2.5c0-0.1-0.1-0.3-0.2-0.3c-0.1,0-0.2,0.1-0.3,0.2
				c-0.2,0.3-0.4,0.5-0.6,0.8C191.6,667.5,191.8,667.2,193.4,667.6z"/>
		</g>
                            <path className="st54" d="M402.1,668.8c0.7,0.8,1.3,1.5,1.9,2.1c0.4,0.4,0.7,0.7,1.1,1.1c0.3,0.3,0.6,0.7,0.8,1
			c1.4,2.9,1.7,5.8-0.1,8.6c-0.6,0.9-1.3,1.6-2,2.4c-3.7,3.6-8.1,6.4-13.1,7.9c-1.9,0.6-3.7,1-5.7,1.3c-1.5,0.2-2.9,0-4.2-0.5
			c-1-0.4-2-0.8-2.9-1.4c-1.2-0.7-2.4-1.4-3.6-2.1c-0.4-0.2-0.7-0.4-1.1-0.6c-0.6-0.3-1.1-0.3-1.7,0.2c-0.2,0.2-0.6,0.4-0.8,0.6
			c0,0-0.2,0-0.2,0c-0.1-0.1-0.1-0.3-0.1-0.3c0.6-0.8,1.3-1.5,1.9-2.3c0,0,0.1-0.1,0.1-0.1c0.2,0,0.3-0.1,0.5,0
			c0.1,0,0.2,0.2,0.2,0.3c-0.1,0.4,0,0.6,0.4,0.8c1.5,0.8,3,1.6,4.4,2.4c0.8,0.4,1.6,0.9,2.4,1.2c1.7,0.8,3.6,1,5.5,0.6
			c5.8-1.1,11-3.3,15.5-7.1c0.6-0.5,1.2-1,1.7-1.6c0.4-0.4,0.9-0.9,1.3-1.3c2-2.2,2.2-4.8,1.3-7.5c-0.3-0.8-0.7-1.4-1.2-2
			c-0.9-0.9-1.7-1.8-2.6-2.7c-0.5-0.6-0.7-0.6-1.5-0.3c-0.4,0.1-0.8,0.6-1.3,0.2c0-0.5,0.5-0.7,0.9-0.9c0.8-0.5,1.7-0.9,2.5-1.4
			c0.4-0.2,0.8-0.1,0.9,0.1c0.1,0.4-0.2,0.5-0.4,0.7C402.5,668.4,402.3,668.6,402.1,668.8z"/>
                            <path className="st54" d="M392.9,669.9c0,1-0.8,1.9-1.9,1.9c-1,0-1.9-0.7-2.1-1.7c-0.1-0.6,0.1-1.1,0.6-1.4c0.5-0.2,1-0.4,1.5-0.5
			C392,668,392.9,668.9,392.9,669.9z"/>
                            <path className="st54" d="M375.8,677.4c0-0.9,0.1-1.4,0.7-1.7c0.6-0.3,1.3-0.2,1.8,0.2c0.7,0.6,1.1,1.5,0.9,2.4
			c-0.1,0.5-0.4,0.8-0.9,1c-1.1,0.3-1.9-0.1-2.3-1.1C375.8,677.9,375.8,677.6,375.8,677.4z"/>
                            <g>
			<g>
				<path d="M231.2,666.2c0.2,0,0.5,0.1,0.7,0.2c0.6,0.2,1.2,0.3,1.8,0.3c2.4,0,4.8,0,7.3,0c3.7,0,7.4,0.1,11.1,0.5
					c6.5,0.8,13,1.9,19.5,3.2c5.9,1.2,11.9,2.2,17.9,3.2c2.7,0.5,5.5,0.7,8.3,0.7c2.6,0.1,5.2,0.2,7.8,0.6c1.3,0.2,2.6,0.4,3.8,1
					c0.4,0.2,0.9,0.3,1.4,0.2c0.3-0.1,0.6,0,0.8,0.4c0.1,0.3,0,0.6-0.2,0.8c-0.2,0.3-0.5,0.5-0.7,0.7c-2.5,2.3-4.8,4.7-7.2,7.1
					c-3.4,3.4-6.8,6.7-10.2,10c-3.4,3.2-6.7,6.5-9.9,10c-3.7,4.1-7.5,8.3-11.2,12.5c-0.9,1-1.6,2-2.5,3c-0.6,0.7-0.8,0.8-1.7,0.4
					c-0.9-0.4-1.8-0.6-2.8-0.6c-2.1-0.1-4.1-0.4-6.2-0.8c-6.2-1.2-12.4-2.3-18.7-3.3c-2.4-0.4-4.8-0.9-7.1-1.4
					c-4.8-1-9.6-1.8-14.5-2.1c-4.9-0.3-9.8-0.9-14.7-1.5c-3.6-0.4-7.1-0.7-10.7-1.2c-2-0.3-4-0.7-6-1.1c-0.7-0.2-1.4-0.4-2.1-0.7
					c-0.6-0.3-0.7-0.8-0.3-1.4c0.3-0.3,0.6-0.7,0.9-0.9c1.9-1.7,3.7-3.5,5.6-5.2c6.1-5.6,12.3-11.1,18.7-16.3c3.7-3,7.4-5.9,11-8.9
					c3-2.4,5.9-4.9,8.9-7.4c0.2-0.1,0.3-0.3,0.5-0.4c0.3-0.2,0.3-0.5,0.1-0.8C230.3,666.5,230.7,666.2,231.2,666.2z M232.6,667.6
					c-0.6-0.1-1,0.2-1.4,0.5c-3.4,3.2-7.1,6.3-10.8,9.2c-9.1,7.2-18,14.6-26.6,22.4c-2.1,1.9-4.2,3.8-6.3,5.8
					c-0.4,0.4-0.9,0.8-1.3,1.3c-0.4,0.4-0.4,0.7,0.2,0.8c0.6,0.2,1.2,0.4,1.9,0.5c2.8,0.7,5.7,1,8.6,1.3c2.2,0.2,4.4,0.4,6.6,0.7
					c5.4,0.6,10.8,1.3,16.3,1.6c4.1,0.3,8.1,0.9,12.1,1.7c2.4,0.5,4.7,1,7.1,1.4c6.4,1.1,12.7,2.1,19,3.4c2.2,0.4,4.4,0.9,6.7,1
					c1.1,0.1,2.2,0.2,3.2,0.6c0.4,0.2,0.7,0,1-0.3c0.4-0.5,0.8-1.1,1.3-1.6c3.7-4.2,7.5-8.4,11.2-12.6c3.5-3.9,7.1-7.6,10.9-11.1
					c4-3.7,7.9-7.6,11.7-11.6c1.4-1.4,2.8-2.8,4.3-4.2c0.2-0.2,0.5-0.5,0.8-0.7c0.3-0.3,0.2-0.5-0.2-0.7c-0.8-0.3-1.6-0.6-2.5-0.7
					c-2.8-0.5-5.7-0.7-8.5-0.8c-2.6-0.1-5.2-0.2-7.8-0.6c-4.6-0.8-9.2-1.5-13.8-2.4c-2.9-0.5-5.9-1.1-8.8-1.7
					c-4.2-0.9-8.4-1.4-12.7-2.1c-3.9-0.6-7.7-0.9-11.6-1C239.7,667.8,236.1,668,232.6,667.6z"/>
                <path className="st15" d="M232.6,667.6c-0.6-0.1-1,0.2-1.4,0.5c-3.4,3.2-7.1,6.3-10.8,9.2c-9.1,7.2-18,14.6-26.6,22.4
					c-2.1,1.9-4.2,3.8-6.3,5.8c-0.4,0.4-0.9,0.8-1.3,1.3c-0.4,0.4-0.4,0.7,0.2,0.8c0.6,0.2,1.2,0.4,1.9,0.5c2.8,0.7,5.7,1,8.6,1.3
					c2.2,0.2,4.4,0.4,6.6,0.7c5.4,0.6,10.8,1.3,16.3,1.6c4.1,0.3,8.1,0.9,12.1,1.7c2.4,0.5,4.7,1,7.1,1.4c6.4,1.1,12.7,2.1,19,3.4
					c2.2,0.4,4.4,0.9,6.7,1c1.1,0.1,2.2,0.2,3.2,0.6c0.4,0.2,0.7,0,1-0.3c0.4-0.5,0.8-1.1,1.3-1.6c3.7-4.2,7.5-8.4,11.2-12.6
					c3.5-3.9,7.1-7.6,10.9-11.1c4-3.7,7.9-7.6,11.7-11.6c1.4-1.4,2.8-2.8,4.3-4.2c0.2-0.2,0.5-0.5,0.8-0.7c0.3-0.3,0.2-0.5-0.2-0.7
					c-0.8-0.3-1.6-0.6-2.5-0.7c-2.8-0.5-5.7-0.7-8.5-0.8c-2.6-0.1-5.2-0.2-7.8-0.6c-4.6-0.8-9.2-1.5-13.8-2.4
					c-2.9-0.5-5.9-1.1-8.8-1.7c-4.2-0.9-8.4-1.4-12.7-2.1c-3.9-0.6-7.7-0.9-11.6-1C239.7,667.8,236.1,668,232.6,667.6z"/>
			</g>
                                <g>
				<path className="st13" d="M248.7,685.9C248.7,685.9,248.7,685.9,248.7,685.9c-0.3,0-0.5,0-0.8,0c-1.2-0.2-2.4,0-3.6,0.3
					c-2.4,0.5-3.8,2-4.8,4.2c-0.3,0.7-0.2,1.3,0.3,1.9c0.5,0.7,1.1,1.1,1.8,1.4c1.2,0.5,2.5,1,3.8,1.3c1.8,0.4,3.7,0.7,5.5,0.4
					c1.4-0.2,2.9-0.5,4.3-0.9c1.1-0.3,2.2-0.8,3.1-1.6c1-0.9,1.1-1.6,0.5-2.8c-0.7-1.2-1.6-2.3-2.9-2.8c-1.3-0.5-2.6-0.9-4-1.2
					c-0.4-0.1-0.8-0.1-1.2-0.1C250.1,685.8,249.4,685.9,248.7,685.9z"/>
                                    <path className="st14" d="M255.1,705c1.9,0,3.4-0.9,4.6-2.3c1.1-1.3,1.3-2.7,0.9-4.3c-0.4-1.5-1.2-2.9-2-4.2
					c-0.2-0.3-0.4-0.3-0.7-0.1c-1.6,1-3.5,1.4-5.4,1.8c-0.2,0-0.3,0-0.5,0.1c-0.3,0-0.5,0.2-0.6,0.5c-0.2,0.6-0.5,1.1-0.7,1.7
					c-0.7,2.1-0.2,3.9,1,5.6c0.5,0.7,1.3,1.1,2.1,1.2C254.3,705,254.7,705,255.1,705z"/>
                                    <path className="st14" d="M251.6,677c-2.4-0.1-4.7,0.4-6.9,1.6c-0.6,0.4-1.2,0.8-1.8,1.3c-0.4,0.4-0.8,0.8-0.9,1.4
					c-0.1,0.4-0.1,0.7,0.3,0.9c0.5,0.4,1.1,0.7,1.6,1.1c1.8,1.1,3.6,1.9,5.7,1.8c0.7,0,1.3-0.2,1.8-0.6c0.2-0.2,0.5-0.4,0.7-0.6
					c1.1-0.8,2.2-1.7,3.3-2.5c0,0,0.1,0,0.1-0.1c1.5-1.1,1-2.7,0-3.5c-0.6-0.4-1.3-0.6-2.1-0.7C252.8,677,252.2,677,251.6,677z"/>
                                    <path className="st14" d="M259.1,688.6c0.2,0.4,0.4,0.8,0.6,1.2c0.5,1,0.5,1.9-0.1,2.8c-0.1,0.1-0.1,0.2-0.2,0.3
					c-0.1,0.3-0.1,0.5,0.2,0.6c0.1,0.1,0.2,0.1,0.3,0.2c1.6,0.6,3.2,1,4.9,0.8c2-0.2,3.8-0.9,5.3-2.2c0.7-0.6,1.3-1.4,1.6-2.3
					c0.3-0.9,0-1.6-0.7-2.2c-0.3-0.3-0.7-0.5-1.1-0.6c-0.9-0.4-1.8-0.4-2.8-0.4C264.3,686.8,261.7,687.7,259.1,688.6z"/>
                                    <path className="st14" d="M263.7,679.6c-2.4,0.3-4.6,0.7-6.8,1.8c-0.3,0.1-0.6,0.4-0.8,0.6c-1,0.7-1.9,1.5-2.9,2.2
					c-0.2,0.2-0.4,0.3-0.6,0.5c-0.1,0.1-0.1,0.4,0,0.4c0.3,0.1,0.6,0.2,0.9,0.3c1.6,0.5,3.2,1,4.6,2.1c0.3,0.2,0.6,0.2,0.9,0.1
					c1.2-0.4,2.3-0.7,3.5-1.1c1-0.3,1.9-0.8,2.7-1.5c1-0.8,1.4-1.9,1.3-3.1c-0.1-1.3-0.6-1.9-1.8-2.1
					C264.3,679.7,264,679.7,263.7,679.6z"/>
                                    <path className="st14" d="M242.9,702.9c0.3-0.1,0.9-0.1,1.5-0.3c2.1-0.8,3.8-2.2,5.1-4c0.4-0.6,0.6-1.2,0.8-1.9
					c0.1-0.2,0-0.4-0.3-0.4c-0.3,0-0.7,0-1,0c-2,0-4-0.3-5.9-1.1c-0.3-0.1-0.5-0.1-0.7,0.1c-0.9,0.8-1.8,1.5-2.7,2.3
					c-0.3,0.3-0.5,0.6-0.7,0.9c-0.4,0.5-0.4,1.1-0.2,1.7C239.1,701.7,240.8,703,242.9,702.9z"/>
                                    <path className="st14" d="M240.7,694.4c-0.4-0.4-0.7-0.6-0.9-0.8c-1.1-1-1.7-2.2-1.1-3.7c0,0,0-0.1,0-0.1c0.1-0.3-0.1-0.5-0.4-0.5
					c-1,0.1-2.1,0.1-3.1,0.2c-1.8,0.3-3.5,1-5,2c-0.9,0.6-1.5,1.3-1.7,2.3c-0.2,0.7,0,1.1,0.6,1.4c0.6,0.2,1.2,0.3,1.8,0.4
					c3,0.2,6-0.1,9-0.9C240.1,694.7,240.3,694.6,240.7,694.4z"/>
                                    <path className="st14" d="M244.3,685.1c0-0.2,0-0.3-0.1-0.3c-0.9-0.9-1.9-1.6-3-2c-2.1-0.8-4.3-0.9-6.5-0.6
					c-0.5,0.1-0.9,0.3-1.3,0.5c-0.7,0.5-0.9,1.3-0.5,2c0.2,0.4,0.6,0.8,0.9,1.1c1.5,1.2,3.4,1.7,5.2,2.2c0.3,0.1,0.5,0,0.7-0.2
					c0.1-0.2,0.3-0.3,0.4-0.5c0.9-1,2-1.7,3.3-2C243.8,685.3,244.1,685.2,244.3,685.1z"/>
                                    <path d="M271.3,686.9c-0.7-0.4-1.4-0.7-2.1-0.9c-0.9-0.2-1.9-0.3-2.8-0.2c-0.2,0-0.4,0.1-0.6-0.2c0.1-0.1,0.1-0.2,0.2-0.3
					c0.1-0.1,0.2-0.3,0.3-0.4c0.9-1.3,1.2-2.7,0.7-4.2c-0.2-0.9-0.8-1.4-1.6-1.7c-0.8-0.3-1.6-0.3-2.4-0.2c-1.5,0.2-2.9,0.5-4.3,0.9
					c-0.3,0.1-0.7,0.2-1,0.3c-0.2,0.1-0.3,0-0.4-0.2c0-0.2,0-0.3,0-0.5c-0.1-1.5-1-2.4-2.3-2.8c-0.7-0.2-1.3-0.3-2-0.4
					c-1.5-0.1-2.9,0-4.4,0.3c-2,0.4-3.9,1.1-5.6,2.4c-0.7,0.5-1.3,1.2-1.6,2c-0.1,0.3,0,0.7-0.4,0.8c-0.3,0.1-0.6,0-0.9-0.1
					c-0.1,0-0.3-0.1-0.4-0.1c-1.7-0.2-3.4-0.5-5-0.1c-0.6,0.1-1.2,0.3-1.7,0.6c-1.2,0.7-1.5,1.9-0.9,3.1c0.2,0.5,0.6,0.9,0.9,1.2
					c0.7,0.5,1.4,1,2.2,1.4c0.2,0.1,0.5,0.2,0.7,0.4c0,0,0.1,0.1,0.1,0.1c0,0.1-0.1,0.2-0.1,0.2c-0.2,0.1-0.4,0.1-0.6,0.1
					c-1.8,0.3-3.5,1-5.1,1.9c-0.7,0.4-1.3,0.9-1.7,1.5c-0.5,0.6-0.8,1.3-0.8,2.1c-0.1,0.9,0.3,1.5,1.1,1.8c0.6,0.2,1.2,0.4,1.9,0.5
					c2.5,0.3,5,0,7.5-0.4c0.5-0.1,1.1-0.2,1.9-0.3c-0.3,0.4-0.5,0.6-0.6,0.7c-0.3,0.3-0.7,0.7-1,1c-0.8,1-0.8,2.1-0.4,3.2
					c0.8,1.9,2.7,3.2,4.8,3.1c0.7,0,1.5-0.2,2.2-0.4c1.4-0.5,2.5-1.3,3.6-2.3c0.3-0.2,0.5-0.5,0.8-0.7c0.1-0.1,0.4-0.1,0.4,0.1
					c0.1,0.3,0.2,0.7,0.2,1c0.2,0.8,0.4,1.5,0.8,2.2c0.7,1.1,1.7,1.8,3,2.1c3.2,0.6,5.6-0.7,7.2-3.2c0.7-1,0.9-2.1,0.8-3.3
					c-0.1-1.1-0.5-2.1-1-3.1c-0.2-0.4-0.3-0.8-0.6-1.3c0.4,0,0.6,0.1,0.8,0.1c0.5,0.1,1.1,0.3,1.6,0.4c3.1,0.4,5.9-0.4,8.3-2.5
					c0.8-0.7,1.4-1.6,1.7-2.6C273,688.9,272.6,687.7,271.3,686.9z M263.7,679.6c0.3,0,0.6,0.1,0.9,0.1c1.3,0.3,1.8,0.8,1.8,2.1
					c0.1,1.2-0.3,2.3-1.3,3.1c-0.8,0.7-1.7,1.1-2.7,1.5c-1.1,0.4-2.3,0.7-3.5,1.1c-0.3,0.1-0.6,0.1-0.9-0.1c-1.3-1.1-3-1.6-4.6-2.1
					c-0.3-0.1-0.6-0.2-0.9-0.3c-0.2-0.1-0.2-0.3,0-0.4c0.2-0.2,0.4-0.3,0.6-0.5c1-0.7,1.9-1.5,2.9-2.2c0.3-0.2,0.5-0.4,0.8-0.6
					C259.1,680.4,261.3,679.9,263.7,679.6z M258.3,692.8c-0.9,0.8-1.9,1.3-3.1,1.6c-1.4,0.4-2.9,0.7-4.3,0.9
					c-1.9,0.3-3.7,0.1-5.5-0.4c-1.3-0.3-2.5-0.8-3.8-1.3c-0.7-0.3-1.3-0.8-1.8-1.4c-0.4-0.6-0.6-1.2-0.3-1.9
					c0.9-2.2,2.4-3.7,4.8-4.2c1.2-0.2,2.4-0.4,3.6-0.3c0.3,0,0.5,0,0.8,0c0,0,0,0,0,0c0.7,0,1.4,0,2.1,0c0.4,0,0.8,0,1.2,0.1
					c1.3,0.4,2.7,0.8,4,1.2c1.4,0.5,2.3,1.6,2.9,2.8C259.4,691.2,259.2,691.9,258.3,692.8z M242,681.2c0.2-0.5,0.5-1,0.9-1.4
					c0.6-0.5,1.2-0.9,1.8-1.3c2.1-1.2,4.4-1.7,6.9-1.6c0.6,0,1.2,0,1.8,0c0.7,0,1.4,0.3,2.1,0.7c1.1,0.7,1.6,2.4,0,3.5
					c0,0-0.1,0-0.1,0.1c-1.1,0.8-2.2,1.7-3.3,2.5c-0.2,0.2-0.5,0.4-0.7,0.6c-0.5,0.4-1.1,0.6-1.8,0.6c-2.1,0.1-4-0.7-5.7-1.8
					c-0.6-0.3-1.1-0.7-1.6-1.1C241.9,681.9,241.9,681.6,242,681.2z M233.9,685.9c-0.4-0.3-0.7-0.7-0.9-1.1c-0.4-0.7-0.3-1.6,0.5-2
					c0.4-0.2,0.9-0.4,1.3-0.5c2.2-0.4,4.4-0.2,6.5,0.6c1.1,0.4,2.1,1.1,3,2c0,0,0,0.1,0.1,0.3c-0.3,0.1-0.5,0.2-0.8,0.3
					c-1.3,0.3-2.4,1-3.3,2c-0.1,0.2-0.3,0.3-0.4,0.5c-0.2,0.2-0.4,0.3-0.7,0.2C237.3,687.6,235.5,687.1,233.9,685.9z M239.9,694.7
					c-2.9,0.7-5.9,1.1-9,0.9c-0.6,0-1.2-0.2-1.8-0.4c-0.6-0.2-0.8-0.7-0.6-1.4c0.3-1,0.9-1.8,1.7-2.3c1.5-1,3.2-1.7,5-2
					c1-0.2,2.1-0.2,3.1-0.2c0.3,0,0.5,0.2,0.4,0.5c0,0,0,0.1,0,0.1c-0.6,1.5,0,2.7,1.1,3.7c0.2,0.2,0.5,0.5,0.9,0.8
					C240.3,694.6,240.1,694.7,239.9,694.7z M250.2,696.7c-0.3,0.6-0.5,1.3-0.8,1.9c-1.2,1.9-2.9,3.3-5.1,4c-0.6,0.2-1.2,0.3-1.5,0.3
					c-2,0-3.7-1.2-4.2-2.9c-0.2-0.6-0.1-1.2,0.2-1.7c0.2-0.3,0.5-0.6,0.7-0.9c0.9-0.8,1.8-1.5,2.7-2.3c0.2-0.2,0.4-0.2,0.7-0.1
					c1.9,0.8,3.9,1.1,5.9,1.1c0.3,0,0.7,0,1,0C250.2,696.3,250.3,696.4,250.2,696.7z M260.6,698.5c0.4,1.6,0.1,3-0.9,4.3
					c-1.2,1.4-2.7,2.3-4.6,2.3c-0.4,0-0.8-0.1-1.2-0.1c-0.8-0.1-1.6-0.5-2.1-1.2c-1.2-1.7-1.8-3.6-1-5.6c0.2-0.6,0.5-1.1,0.7-1.7
					c0.1-0.3,0.3-0.4,0.6-0.5c0.2,0,0.3,0,0.5-0.1c1.9-0.3,3.7-0.7,5.4-1.8c0.3-0.2,0.5-0.1,0.7,0.1
					C259.4,695.6,260.2,697,260.6,698.5z M271.7,690c-0.3,0.9-0.8,1.7-1.6,2.3c-1.5,1.3-3.3,2-5.3,2.2c-1.7,0.2-3.3-0.2-4.9-0.8
					c-0.1,0-0.2-0.1-0.3-0.2c-0.3-0.2-0.4-0.4-0.2-0.6c0.1-0.1,0.1-0.2,0.2-0.3c0.6-0.9,0.6-1.9,0.1-2.8c-0.2-0.4-0.4-0.8-0.6-1.2
					c2.6-1,5.2-1.8,8-1.8c1,0,1.9,0,2.8,0.4c0.4,0.2,0.8,0.4,1.1,0.6C271.7,688.4,272,689.1,271.7,690z"/>
			</g>
                                <g>
				<path className="st17" d="M222.2,702c-0.1-0.1-0.1-0.2-0.2-0.2c-1.8-0.7-3.7-1.5-5.7-1.7c-0.4,0-0.7-0.2-1.1-0.3
					c0,0-0.1-0.1-0.1-0.2c0,0,0-0.1,0.1-0.1c0.2,0,0.3-0.1,0.5-0.1c1.5,0.1,3,0.2,4.4,0.7c0.5,0.2,1,0.4,1.6,0.6
					c0.3,0.1,0.7,0.1,1,0.2c0.1,0,0.2-0.1,0.2-0.1c0-0.1,0-0.2,0-0.2c-0.2-0.3-0.7-0.6-0.7-1.1c0-0.4-0.3-0.6-0.6-0.8
					c-1.7-0.8-3.5-1.6-5.4-2.1c-1.4-0.3-2.9-0.5-4.3-0.3c-2.6,0.3-5.1,1-7.5,1.8c-0.2,0.1-0.4,0.2-0.8,0.4c0.4,0.2,0.7,0.3,1,0.4
					c1.2,0.5,2.5,0.9,3.7,1.4c1.4,0.5,2.7,1.1,4.1,1.6c1.4,0.5,2.8,0.9,4.2,1.1c1.6,0.2,3.1,0,4.6-0.5
					C221.5,702.5,221.9,702.4,222.2,702z"/>
                                    <path d="M225.9,701.8c-0.3-0.1-0.6-0.3-0.9-0.4c-0.5-0.1-0.8-0.3-1-0.8c-0.4-1-0.9-1.9-2-2.3c-1.3-0.6-2.5-1.3-3.9-1.8
					c-1.6-0.7-3.4-1-5.5-1c-0.7,0.1-1.7,0.2-2.7,0.4c-2.8,0.5-5.5,1.5-8.2,2.3c-0.2,0.1-0.3,0.2-0.4,0.3c-0.2,0.2-0.2,0.5,0.1,0.7
					c0.2,0.1,0.3,0.1,0.5,0.2c1.8,0.4,3.5,1.1,5.1,1.7c1.5,0.6,2.9,1.2,4.4,1.8c1.5,0.6,3.1,1.1,4.7,1.3c2.6,0.4,5,0,7.1-1.6
					c0.4-0.3,0.7-0.3,1.2-0.3c0.5,0.1,0.9,0,1.4,0c0.1,0,0.2-0.1,0.2-0.2C226,701.9,226,701.8,225.9,701.8z M222.9,700.7
					c0,0.1-0.1,0.1-0.2,0.1c-0.3,0-0.7-0.1-1-0.2c-0.5-0.2-1.1-0.4-1.6-0.6c-1.4-0.5-2.9-0.7-4.4-0.7c-0.2,0-0.3,0.1-0.5,0.1
					c0,0-0.1,0.1-0.1,0.1c0,0.1,0,0.2,0.1,0.2c0.4,0.1,0.7,0.3,1.1,0.3c2,0.2,3.8,1,5.7,1.7c0.1,0,0.1,0.1,0.2,0.2
					c-0.3,0.4-0.7,0.5-1.1,0.7c-1.5,0.6-3,0.7-4.6,0.5c-1.5-0.2-2.9-0.6-4.2-1.1c-1.4-0.5-2.7-1.1-4.1-1.6c-1.2-0.5-2.5-1-3.7-1.4
					c-0.3-0.1-0.5-0.2-1-0.4c0.3-0.2,0.6-0.3,0.8-0.4c2.5-0.8,4.9-1.5,7.5-1.8c1.5-0.2,2.9,0,4.3,0.3c1.9,0.4,3.7,1.2,5.4,2.1
					c0.3,0.2,0.6,0.3,0.6,0.8c0.1,0.5,0.5,0.7,0.7,1.1C223,700.6,223,700.7,222.9,700.7z"/>
			</g>
		</g>
	</g>
                        <g className="abaco white">
		<path d="M599.7,588.9c0.1-0.3,0.2-0.5,0.3-0.8c0.5-1.1,1.2-1.9,2.4-2.4c0.3-0.1,0.6-0.2,1,0c0.2,0.1,0.4,0.2,0.6,0.2
			c0.8,0.1,1.4,0.5,1.9,1.1c0.2,0.3,0.4,0.7,0.7,1c0,0.1,0.1,0.1,0.2,0.2c0.1-0.1,0.2-0.3,0.3-0.4c0.7-1,1.6-1.6,2.7-2
			c0.5-0.1,0.9-0.2,1.3,0.1c0.1,0.1,0.3,0.1,0.4,0.1c1,0.1,1.8,0.6,2.4,1.5c0,0.1,0.1,0.1,0.1,0.2c0.2,0,0.3-0.1,0.3-0.3
			c0.4-0.6,1-1.1,1.6-1.5c0.6-0.3,1.3-0.5,2-0.2c0.2,0.1,0.5,0.1,0.7,0.2c1,0.2,1.8,0.7,2.4,1.5c0.1,0.2,0.2,0.4,0.4,0.5
			c0.2-0.1,0.3-0.2,0.3-0.4c0.3-0.6,0.7-1,1.2-1.4c0.6-0.5,1.3-0.8,2.1-0.7c0.4,0,0.8,0,1.1-0.1c1-0.1,1.9,0.3,2.4,1.2
			c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0.1,0.1c0.1-0.1,0.2-0.1,0.3-0.2c0.4-0.4,0.9-0.7,1.4-0.9c0.1,0,0.2,0,0.2-0.1
			c0.6-0.4,1.2-0.4,1.8-0.3c0.4,0.1,0.8,0.1,1.2,0.2c1,0.2,1.6,0.8,2,1.7c0,0.1,0.1,0.2,0.1,0.4c0.1,0.2,0.2,0.2,0.3,0.2
			c0.3,0,0.5,0,0.8,0c1.1,0,2.2,0.1,3.3,0.2c1.4,0.1,2.7,0.2,4.1,0.1c0.9-0.1,1.7-0.1,2.6-0.2c0.6,0,1.2-0.1,1.7-0.2
			c1.7-0.2,3.4-0.4,5.1-0.6c1.4-0.2,2.9-0.5,4.3-0.7c1.1-0.1,2.2-0.2,3.2-0.3c1.7-0.2,3.5-0.1,5.2-0.1c1.5,0,3,0,4.4-0.1
			c1.4-0.1,2.8-0.1,4.3-0.1c1.3,0,2.6-0.1,3.9-0.2c0.3,0,0.5,0,0.8,0c0.3,0,0.4-0.1,0.5-0.4c0-0.1,0-0.2,0-0.3c0-0.5,0.1-1,0.1-1.5
			c0.1-1.9,0.3-3.7,0.6-5.6c0.1-0.8,0.2-1.7,0.3-2.5c0.1-0.7,0.3-1.5,0.4-2.2c0.2-1,0.4-1.9,0.6-2.9c0.2-1,0.5-2.1,1-3
			c0.2-0.4,0.4-0.9,0.7-1.2c0.9-1.1,2.1-0.7,2.7,0c0.3,0.3,0.5,0.7,0.7,1.2c0.6,1.2,1,2.5,1.4,3.8c0.3,1.2,0.6,2.3,0.9,3.5
			c0.2,0.9,0.4,1.8,0.6,2.8c0.3,1.3,0.6,2.7,0.8,4c0.3,1.4,0.5,2.7,0.7,4.1c0.2,1.3,0.4,2.7,0.5,4c0.1,1,0.2,1.9,0.3,2.9
			c0,0.3,0.1,0.6,0.1,0.9c0.1,1,0.2,1.9,0.3,2.9c0.1,1.1,0.2,2.2,0.3,3.2c0,0.4,0.1,0.7,0.1,1.1c0.1,1.2,0.2,2.3,0.3,3.5
			c0.1,1.3,0.2,2.7,0.3,4c0,0.5,0.1,1,0.1,1.5c0,0.5,0.1,1,0.1,1.6c0,0.6,0.1,1.2,0.1,1.9c0,0.5,0.1,1,0.1,1.4
			c0.1,0.9,0.1,1.8,0.2,2.7c0,0.2,0,0.3,0,0.5c0,1.3,0.1,2.6,0.2,3.9c0,0.1,0,0.2,0,0.3c0.1,1.2,0.1,2.5,0.2,3.7
			c0.1,1.1,0.1,2.1,0.1,3.2c0,1.1,0.1,2.3,0.2,3.4c0,0.8,0,1.5,0,2.3c0.1,1.7,0.1,3.4,0.2,5.1c0,0.1,0,0.3,0,0.4c0,0.3,0,0.6,0,0.9
			c0.1,3,0.1,5.9,0.2,8.9c0.1,4.4,0.1,8.7,0.1,13.1c0,1,0,1.9,0,2.9c0,2.3-0.1,4.6-0.1,6.9c0,1.7-0.1,3.3-0.1,5c0,0.1,0,0.2,0,0.3
			c0,0.4,0.1,0.7,0.5,0.9c0.3,0.1,0.5,0.3,0.7,0.5c0.8,0.7,1.6,1.4,2.4,2.1c0.2,0.2,0.4,0.3,0.6,0.5c0.8,0.9,1.8,1.6,2.9,2.2
			c1,0.6,1.9,1.2,2.7,1.9c1.1,0.9,1.2,1.3,1.1,2.7c-0.1,0.6-0.1,1.1-0.1,1.7c-0.1,1.1-0.1,2.3-0.3,3.4c0,0.2-0.1,0.4-0.2,0.6
			c-0.1,0.4-0.4,0.7-0.8,0.8c-0.8,0.2-1.5,0.4-2.3,0.5c-0.9,0.2-1.9,0.2-2.9,0.3c-1,0.1-1.9,0.2-2.9,0.3c-1,0.1-2.1,0.2-3.1,0.3
			c-0.3,0-0.6,0.1-0.9,0.1c-1.6,0.2-3.1,0.4-4.7,0.6c-0.7,0.1-1.4,0.1-2.1,0.2c-1.3,0.1-2.7,0.2-4,0.4c-0.2,0-0.5,0-0.7,0
			c-0.9,0.1-1.7,0.1-2.6,0.2c-0.2,0-0.3,0-0.5,0c-1.1,0.1-2.3,0.2-3.4,0.2c-0.4,0-0.8,0-1.3,0c-1.9,0-3.7,0-5.6,0.1
			c-1.5,0.1-3,0.2-4.6,0.3c-1.7,0.2-3.5,0.3-5.2,0.3c-5.5,0-10.9,0-16.4,0c-1.3,0-2.6-0.1-3.9-0.2c-0.1,0-0.3,0-0.4,0
			c-2.1-0.1-4.3-0.1-6.4-0.2c-1.7,0-3.4-0.1-5.1-0.1c-1.6,0-3.3,0-4.9,0.2c0,0-0.1,0-0.1,0c-1.5,0-3,0.1-4.6,0.1
			c-5.2,0-10.5,0-15.7,0c-1.4,0-2.7,0-4.1-0.1c-1.4-0.1-2.8-0.1-4.2-0.1c-1.1,0-2.3,0-3.4-0.1c-1.1-0.1-2.3-0.2-3.4-0.3
			c-1.9-0.2-3.8-0.5-5.6-0.8c-0.7-0.1-1.4-0.2-2.2-0.3c-1-0.2-1.4-0.6-1.5-1.7c0-0.8,0.1-1.6,0.2-2.4c0-0.2,0-0.4,0.1-0.6
			c0-0.3,0-0.5-0.2-0.7c-0.3-0.4-0.4-0.8-0.1-1.3c0.1-0.2,0.3-0.4,0.4-0.5c0.6-0.7,1.4-1.2,2.1-1.8c1.7-1.3,3.4-2.7,5.1-4
			c1.1-0.9,2.2-1.8,3.3-2.7c0.6-0.5,1.2-0.9,1.8-1.4c0.1,0,0.1-0.1,0.2-0.1c0.2-0.1,0.4-0.3,0.3-0.6c0-0.4,0-0.8-0.1-1.2
			c-0.1-0.5-0.2-1-0.2-1.6c0-0.2,0-0.4-0.1-0.6c-0.1-0.6-0.2-1.2-0.2-1.8c0-0.1,0-0.3,0-0.4c-0.1-0.7-0.2-1.4-0.2-2.1
			c-0.1-1-0.2-2.1-0.3-3.1c-0.1-0.9-0.2-1.7-0.2-2.6c0-0.4-0.1-0.9-0.1-1.3c0-0.4-0.1-0.7-0.1-1.1c0-0.9-0.1-1.8-0.2-2.6
			c0-0.3-0.1-0.5-0.1-0.8c0-1-0.1-1.9-0.2-2.9c0-0.6-0.1-1.2-0.1-1.9c-0.1-1-0.1-2-0.2-2.9c0-0.1,0-0.3,0-0.4c0-1.5-0.1-2.9-0.2-4.4
			c-0.1-1.3,0-2.5-0.1-3.8c-0.1-1.3-0.1-2.6-0.1-3.9c0-1.1,0-2.1-0.1-3.2c-0.1-2.3-0.1-4.6-0.2-7c-0.1-2.9,0-5.8-0.1-8.7
			c0-1.5-0.1-3.1-0.1-4.6c0.1-2.6,0-5.3,0.1-7.9c0.1-1.6,0-3.3,0.1-4.9c0-1.4,0.1-2.7,0.2-4.1c0-0.1,0-0.2,0-0.3
			c0-1.4,0.1-2.7,0.1-4.1c0-0.8,0.1-1.5,0.2-2.3c0-0.3,0.1-0.6,0.1-0.9c0-0.9,0.1-1.8,0.2-2.7c0-0.1,0-0.2,0-0.2
			c0-1.4,0.3-2.8,0.3-4.2c0-0.3,0-0.5,0.1-0.8c0.1-0.6,0.1-1.2,0.2-1.8c0-0.1,0-0.1,0-0.2c0.1-0.8,0.2-1.7,0.3-2.5
			c0.1-0.9,0.3-1.8,0.4-2.7c0.3-2,0.7-4.1,1.5-6c0.2-0.4,0.4-0.9,0.7-1.3c0.4-0.6,1-0.9,1.7-0.9c0.4,0,0.7,0.1,1,0.4
			c0.5,0.5,0.9,1,1.2,1.6c0.5,1.2,0.9,2.5,1.2,3.7c0.5,1.9,0.9,3.9,1.3,5.9c0.3,1.6,0.5,3.1,0.8,4.7c0.1,0.8,0.3,1.6,0.4,2.4
			c0,0.2,0.1,0.5,0.1,0.7c0,0.1,0,0.2,0.1,0.3C599.7,588.9,599.7,588.9,599.7,588.9z M618.3,610.3c-0.1,0.1-0.2,0.1-0.2,0.1
			c-0.5,0.4-1.1,0.7-1.7,0.9c-1.3,0.4-2.6,0.4-3.9-0.1c-0.2-0.1-0.3-0.1-0.5-0.1c-0.4,0-0.8,0.1-1.2,0.2c-0.4,0.1-0.9,0.2-1.3,0.2
			c-1.7,0-3.4,0-5.1,0c-0.8,0-1.5,0.1-2.3-0.2c-0.3-0.1-0.4,0.1-0.4,0.3c0,0.1,0,0.2,0,0.2c0.1,1.4,0.2,2.7,0.3,4.1
			c0.1,1.1,0.1,2.2,0.2,3.3c0,0.1,0,0.1,0,0.2c0.1,1.5,0.2,3,0.2,4.5c0,0.3,0,0.6,0,0.9c0.1,1.4,0.1,2.8,0.2,4.2c0,0.2,0,0.4,0,0.6
			c0,0.2,0.1,0.3,0.3,0.3c0.1,0,0.2,0,0.3,0c1,0,1.9-0.1,2.9-0.1c1.5,0,3.1-0.1,4.6-0.2c0.2,0,0.4,0,0.6-0.1
			c0.6-0.1,1.3-0.2,1.9-0.2c1.4,0,2.8-0.1,4.3-0.2c0.2,0,0.3,0,0.5,0c0.6,0.1,0.6,0.1,1.1-0.3c0.8-0.7,1.7-1,2.8-0.7
			c0.1,0,0.2,0,0.3,0.1c0.2,0.1,0.4,0.1,0.6,0c0.9-0.2,1.8,0,2.6,0.5c0.1,0.1,0.2,0.1,0.3,0.1c0.3-0.2,0.5-0.4,0.8-0.6
			c0.9-0.6,1.8-0.8,2.8-0.5c0.2,0.1,0.3,0.1,0.5,0.1c0.4,0.1,0.8,0.2,1.1,0.5c0.1,0.1,0.3,0.2,0.4,0.3c0.3-0.1,0.4-0.3,0.5-0.4
			c0.5-0.5,1.1-0.7,1.8-0.8c0.2,0,0.4,0,0.6,0c0.8,0.3,1.6,0.6,2.2,1.3c0.1,0.1,0.2,0.2,0.3,0.2c0.1-0.1,0.2-0.1,0.2-0.2
			c0.4-0.5,1-0.9,1.6-1.2c0.2-0.1,0.4-0.2,0.6-0.2c1-0.1,1.9,0.2,2.6,1.1c0.1,0.1,0.2,0.2,0.3,0.3c0.1-0.1,0.2-0.2,0.3-0.3
			c0.3-0.4,0.8-0.7,1.2-1c0.7-0.4,1.4-0.4,2.1-0.1c0.1,0,0.2,0.1,0.4,0.1c0.9,0.3,1.6,0.7,2.1,1.5c0.1,0.1,0.2,0.2,0.2,0.3
			c0.1-0.1,0.2-0.2,0.3-0.2c0.2-0.2,0.4-0.4,0.7-0.5c0.5-0.4,1.1-0.6,1.8-0.5c0.6,0.1,1.3,0.3,1.9,0.3c0,0,0.1,0,0.1,0
			c0.4,0.2,0.8,0.4,1,0.8c0.1,0.1,0.2,0.4,0.4,0.4c0.1-0.1,0.2-0.2,0.2-0.3c0.4-0.6,0.9-1,1.5-1.3c0.7-0.3,1.5-0.3,2.1,0.2
			c0.2,0.1,0.4,0.2,0.5,0.3c0.3,0.2,0.5,0.3,0.8,0.5c0.3,0.3,0.6,0.3,1,0.2c0.3-0.1,0.6-0.1,0.9-0.2c0.4-0.1,0.7-0.1,1.1-0.1
			c0.6,0,1.2-0.1,1.7-0.1c2.4,0,4.9,0,7.3-0.1c1.2-0.1,2.4-0.1,3.6-0.2c0.1,0,0.2,0,0.4,0c0.8-0.1,1.7-0.1,2.5-0.2
			c0.3,0,0.7-0.1,1-0.1c0.2,0,0.3-0.2,0.3-0.3c0-0.2,0-0.4,0-0.6c0-1.5-0.1-3-0.1-4.4c0-3.2,0-6.3,0-9.5c0-0.4,0-0.8,0.1-1.2
			c0-0.2,0.1-0.4-0.1-0.6c-0.1,0.1-0.3,0.2-0.4,0.3c-1.7,1.2-3.8,1-5.2-0.3c-0.1-0.1-0.2-0.2-0.4-0.3c-0.1-0.1-0.2-0.1-0.4,0
			c-0.1,0.1-0.2,0.1-0.3,0.2c-0.6,0.4-1.2,0.6-1.9,0.7c-1.1,0.1-2-0.1-2.7-1.1c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1,0.1-0.2,0.2-0.4,0.3
			c-0.7,0.6-1.6,1-2.6,1.1c-1.3,0.1-2.3-0.3-3.1-1.3c-0.1-0.1-0.2-0.3-0.4-0.5c-0.2,0.1-0.3,0.3-0.5,0.4c-0.4,0.3-0.9,0.6-1.4,0.7
			c-1.4,0.3-2.6,0-3.6-1c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1,0.1-0.2,0.2-0.3,0.4c-0.3,0.4-0.6,0.7-1.1,1c-1.7,1-3.9,0.8-5.2-0.6
			c0,0,0,0,0,0c-0.2-0.2-0.4-0.2-0.6-0.2c-0.4,0-0.7,0.1-1.1,0.1c-2.2,0-4.5,0.1-6.7-0.1c-0.3,0-0.6-0.1-0.9-0.1
			c-0.4,0-0.7-0.1-1.1-0.1c-0.3,0-0.6,0.1-0.8,0.3c-0.7,0.8-1.5,1.2-2.6,1.5c-0.5,0.1-1,0.1-1.5,0c-0.8-0.2-1.5-0.6-2.1-1.2
			c-0.1-0.1-0.2-0.2-0.2-0.2c-0.3,0-0.3,0.2-0.5,0.3c-0.2,0.2-0.5,0.4-0.7,0.6c-1.7,1.1-4.4,0.8-5.5-0.6c-0.1-0.1-0.2-0.2-0.3-0.2
			c-0.5,0.4-1,0.7-1.6,0.9c-0.6,0.2-1.2,0.2-1.8,0.2c-0.6,0-1.2-0.2-1.8-0.4C619.2,611.1,618.8,610.7,618.3,610.3z M630.9,632.6
			c-0.2,0.1-0.3,0.2-0.4,0.3c-0.8,0.7-1.8,1.1-2.9,1c-0.7,0-1.3-0.3-1.9-0.8c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2,0.1-0.4,0.2-0.5,0.3
			c-0.9,0.5-1.8,0.8-2.9,0.6c-0.7-0.1-1.4-0.3-2-0.6c-0.4-0.2-0.7-0.3-1.1-0.2c-0.2,0.1-0.4,0.1-0.6,0.1c-1.1,0.1-2.3,0.3-3.4,0.3
			c-0.2,0-0.4,0-0.6,0c-1,0.1-2,0.2-3.1,0.2c-1.3,0.1-2.6,0.1-3.9,0.1c-1.2,0-2.4,0-3.6,0c-0.1,0-0.3,0-0.4,0
			c-0.3,0-0.4,0.1-0.4,0.4c0,0.3,0.1,0.6,0.1,0.9c0,2,0.1,4,0.1,6c0.1,3,0.2,6.1,0.2,9.1c0,0.9,0,0.9,0.9,0.9c3.1,0,6.2,0,9.3,0
			c0.5,0,0.9-0.1,1.4-0.1c0.4,0,0.9-0.1,1.3-0.1c0.3,0,0.6-0.1,0.8-0.3c0.3-0.3,0.6-0.5,1-0.7c0.6-0.3,2.2-0.5,2.9-0.3
			c0.9,0.2,1.8,0.5,2.7,1.1c0.1,0.1,0.3,0.1,0.4,0.2c0.1-0.1,0.2-0.1,0.3-0.2c0.5-0.6,1.2-0.8,2-0.9c0.4,0,0.8-0.1,1.2-0.2
			c0.2,0,0.5,0,0.7,0c0.8,0.1,1.5,0.4,2.1,0.9c0.3,0.3,0.3,0.3,0.7,0c0.3-0.2,0.5-0.4,0.8-0.6c0.5-0.3,1.1-0.5,1.6-0.7
			c0.9-0.4,1.8-0.2,2.5,0.4c0.3,0.3,0.6,0.6,0.8,0.9c0.1,0.1,0.2,0.2,0.3,0.3c0.1-0.1,0.2-0.2,0.3-0.3c0.6-0.7,1.4-1,2.3-1
			c0.4,0,0.7-0.1,1.1-0.2c0.7-0.3,1.4-0.2,2,0.2c0.3,0.2,0.5,0.4,0.8,0.6c0.1,0.1,0.2,0.2,0.3,0.2c0.2-0.1,0.3-0.2,0.4-0.3
			c0.5-0.4,1.2-0.7,1.9-0.8c0.6-0.1,1.2-0.1,1.9,0.1c0.3,0.1,0.6,0.3,0.8,0.5c0.2,0.2,0.4,0.3,0.7,0.2c0.2-0.1,0.4-0.1,0.6-0.1
			c0.7-0.1,1.5-0.1,2.2-0.2c0.5,0,1-0.1,1.5-0.1c0.9-0.1,1.8-0.1,2.7-0.2c0.7-0.1,1.5-0.2,2.2-0.3c0.5-0.1,0.9-0.2,1.4-0.2
			c0.4,0,0.9,0,1.3,0c0.5,0,0.9,0,1.3,0.2c0.2,0.1,0.4,0.1,0.6-0.1c0.1,0,0.1-0.1,0.2-0.1c0.5-0.3,1-0.5,1.6-0.5c0.2,0,0.5,0,0.7,0
			c0.7-0.1,1.3,0.2,1.7,0.8c0.1,0.1,0.2,0.2,0.3,0.3c0.2-0.1,0.3-0.2,0.4-0.3c0.5-0.4,1-0.7,1.6-0.9c0.5-0.2,1-0.2,1.4,0.2
			c0.2,0.2,0.5,0.2,0.7,0.3c0.3,0.1,0.5,0.2,0.8,0.4c0.6-0.5,1.2-0.7,1.9-0.9c0.7-0.2,1.3,0,1.8,0.4c0.1,0.1,0.2,0.1,0.2,0.2
			c0.2,0.1,0.4,0.2,0.6,0c0.1-0.1,0.2-0.2,0.4-0.3c0.9-0.5,1.8-0.8,2.9-0.5c0.1,0,0.2,0,0.3,0c0.1-0.3,0.1-0.6,0.1-0.9
			c-0.1-1.8-0.2-3.7-0.2-5.5c0-0.5,0-1,0-1.4c-0.1-1.4-0.1-2.8-0.2-4.1c0-1,0-2.1,0-3.1c0-0.2,0-0.3,0-0.5c0-0.4-0.1-0.5-0.5-0.5
			c-1,0-2,0-2.9,0c-0.4,0-0.8,0.1-1.2,0.1c-4.2,0-8.4,0-12.6,0c-0.8,0-1.5,0.1-2.3,0.1c-0.2,0-0.5,0.1-0.6,0.3
			c-0.1,0.1-0.1,0.1-0.2,0.2c-0.5,0.5-1.1,0.8-1.8,1c-1.3,0.4-2.6-0.1-3.1-1.2c0-0.1-0.1-0.1-0.2-0.2c-0.1,0.1-0.3,0.2-0.4,0.3
			c-0.9,0.8-2,1.1-3.2,1.2c-1,0-1.9-0.4-2.6-1.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2,0.2-0.3,0.3-0.5,0.4c-0.9,0.7-1.9,1.1-3.1,0.8
			c-0.6-0.1-1.2-0.5-1.7-0.9c-0.1-0.1-0.2-0.3-0.4-0.3c-0.4,0.3-0.7,0.6-1.1,0.8c-0.4,0.2-0.9,0.3-1.4,0.4c-0.5,0.1-0.9,0-1.4-0.2
			c-0.4-0.2-0.9-0.4-1.3-0.6c-0.1,0-0.2,0.1-0.3,0.2c-0.5,0.5-1.1,0.7-1.7,0.8c-0.7,0.1-1.4,0.1-2.1,0c-0.7-0.1-1.3-0.4-1.8-0.9
			C631.1,632.7,631,632.7,630.9,632.6z M694.9,659.1c0-1.9,0.1-4.9-0.1-7.8c-0.1-2.1,0-4.2-0.1-6.3c-0.1-2.1,0-4.1-0.2-6.2
			c-0.1-0.9-0.1-1.8-0.1-2.6c0-1.3,0-2.6-0.1-3.9c-0.1-1.4-0.1-2.8-0.2-4.1c0-0.1,0-0.2,0-0.3c-0.1-0.9-0.2-1.8-0.2-2.7
			c0-0.9,0-1.9-0.1-2.8c-0.1-1.1-0.1-2.1-0.2-3.2c0-0.1,0-0.3,0-0.4c-0.1-1.1-0.2-2.2-0.2-3.4c0-0.1,0-0.3,0-0.4
			c-0.1-1.1-0.2-2.2-0.2-3.3c0-0.2,0-0.3,0-0.5c-0.1-0.9-0.1-1.7-0.2-2.6c0-0.2,0-0.4,0-0.6c-0.1-1.3-0.2-2.5-0.3-3.8
			c-0.1-1.1-0.2-2.2-0.3-3.3c0-0.2-0.1-0.5-0.1-0.7c-0.1-1.1-0.2-2.2-0.3-3.2c-0.1-1-0.2-2.1-0.4-3.1c-0.2-1.4-0.3-2.9-0.5-4.3
			c-0.1-0.9-0.2-1.7-0.4-2.6c-0.1-1-0.3-2-0.4-3c-0.1-1-0.3-2-0.4-3c-0.1-1-0.3-2.1-0.5-3.1c-0.1-0.6-0.3-1.3-0.4-1.9
			c-0.4-1.9-0.9-3.8-1.5-5.7c-0.2-0.7-0.5-1.4-0.9-2.1c-0.1-0.2-0.3-0.4-0.4-0.6c-0.2-0.2-0.5-0.2-0.7,0.1c-0.2,0.2-0.4,0.5-0.5,0.8
			c-0.2,0.6-0.4,1.1-0.6,1.7c-0.5,1.7-0.8,3.5-1.1,5.3c-0.2,1.2-0.3,2.4-0.4,3.6c-0.1,0.5-0.1,1.1-0.2,1.6c-0.1,0.7-0.1,1.5-0.2,2.2
			c-0.1,0.7-0.1,1.4-0.2,2.1c-0.1,1.1-0.1,2.2-0.2,3.2c0,0.4-0.1,0.8-0.1,1.2c-0.1,1-0.1,1.9-0.2,2.9c0,0.7,0,1.3-0.1,2
			c-0.1,1-0.1,2.1-0.2,3.1c0,1,0,2-0.1,2.9c-0.1,1.7-0.2,3.4-0.2,5.1c0,6.6,0,13.2,0,19.9c0,1.1,0,2.2,0.1,3.3
			c0.1,1.5,0.1,3,0.1,4.5c0,1.6,0,3.3,0.1,4.9c0.1,1.7,0.1,3.3,0.2,5c0,0.3,0,0.5,0,0.8c0.1,1.1,0.1,2.1,0.2,3.2c0,1,0,2,0.1,2.9
			c0.1,1.7,0.2,3.3,0.3,5c0.1,1.4,0.2,2.7,0.2,4.1c0.1,1.5,0.2,3,0.3,4.4c0,0.7,0.1,1.4,0.1,2.1c0.1,1.4,0.2,2.9,0.4,4.3
			c0.1,0.7,0.1,1.4,0.2,2.1c0.1,0.6,0.1,1.1,0.2,1.7c0.1,1.1,0.2,2.3,0.3,3.4c0.1,1.2,0.4,2.4,0.4,3.6c0,0,0,0,0,0.1
			c0.1,1.1,0.2,2.1,0.4,3.2c0.1,0.5,0.1,0.5,0.6,0.6c0,0,0.1,0,0.1,0c1.4,0.2,2.8,0.3,4.2,0.3c1.3,0,2.6,0,3.9-0.1
			c0.5,0,0.6-0.1,0.6-0.5c0.1-0.4,0.1-0.8,0.1-1.2c0-0.9,0-1.7,0-2.6c0-0.3,0-0.5,0-0.8c0.1-1,0.1-2,0.2-2.9c0.1-1.3,0-2.7,0.1-4
			c0.1-2.2,0.1-4.5,0.2-6.7C694.9,664.1,694.9,662.1,694.9,659.1z M681.5,668c0-0.2,0-0.4,0-0.5c-0.1-1.2-0.2-2.4-0.3-3.6
			c-0.1-1.2-0.2-2.4-0.2-3.6c-0.1-1.5-0.2-3-0.3-4.4c0-0.8-0.1-1.6-0.1-2.4c0-0.1-0.1-0.2-0.1-0.4c-0.2,0.1-0.3,0.1-0.4,0.2
			c-1,0.5-2,0.5-3,0.2c-0.3-0.1-0.6-0.1-0.9,0.1c-0.4,0.2-0.9,0.3-1.3,0.3c-0.7,0-1.4-0.1-2.1-0.4c-0.3-0.1-0.5-0.1-0.8,0.1
			c-0.7,0.6-1.6,0.8-2.6,0.9c-0.8,0-1.6-0.1-2.2-0.7c-0.4-0.4-0.7-0.4-1.2-0.1c-1,0.5-2.1,0.5-3.2-0.1c-0.1-0.1-0.2-0.1-0.3-0.1
			c-0.1,0.1-0.2,0.2-0.4,0.3c-0.4,0.4-0.9,0.6-1.5,0.8c-1.2,0.4-3,0.1-3.8-1.4c-0.2-0.4-0.5-0.5-0.9-0.5c-1,0-2,0-3,0
			c-0.4,0-0.8,0-1.2,0.1c-0.6,0.1-1.2,0.1-1.7,0.1c-0.2,0-0.5,0-0.7,0c-0.3,0-0.5,0.1-0.7,0.4c-0.2,0.4-0.5,0.7-0.8,0.9
			c-0.5,0.4-1.1,0.6-1.8,0.7c-0.9,0.2-1.8,0-2.6-0.5c-0.1-0.1-0.2-0.1-0.4-0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.9,0.9-2,1.3-3.3,1.2
			c-1.1-0.1-2-0.4-2.7-1.2c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1,0.1-0.2,0.1-0.2,0.2c-0.4,0.6-1,0.9-1.7,1.1c-0.6,0.2-1.2,0.2-1.7,0.2
			c-1-0.1-2-0.3-2.8-1.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1,0.1-0.2,0.2-0.3,0.3c-0.4,0.4-0.8,0.7-1.3,0.9c-1.4,0.6-2.7,0.6-4-0.2
			c-0.1,0-0.1-0.1-0.2-0.1c-0.2-0.1-0.3-0.1-0.5,0c-0.1,0.1-0.2,0.2-0.3,0.2c-0.5,0.4-1.2,0.6-1.8,0.8c-1,0.2-2,0.1-3-0.2
			c-0.3-0.1-0.6-0.2-0.8-0.3c-0.3-0.1-0.6-0.4-1-0.5c-0.3-0.1-0.7,0-1.1,0c-1.2,0-2.4,0-3.6-0.1c-1.5-0.1-3,0-4.4-0.1
			c-0.9-0.1-1.8,0-2.6,0c-0.3,0-0.7,0-1,0c-0.7-0.1-0.7,0.1-0.7,0.7c0,3.3,0,6.6-0.1,9.8c0,0.8,0,1.5,0,2.3c0,0.2,0,0.5,0,0.7
			c0,0.6-0.1,1.3-0.1,1.9c0,0.1,0,0.2,0.1,0.4c0.1-0.1,0.2-0.1,0.3-0.1c0.2-0.1,0.3-0.2,0.5-0.3c0.6-0.2,1.2-0.5,1.8-0.7
			c0.4-0.1,0.8-0.1,1.2,0c1,0.3,2,0.7,2.7,1.5c0.1,0.1,0.3,0.2,0.4,0.4c0.3-0.1,0.4-0.3,0.6-0.5c0.5-0.5,1.1-0.9,1.8-1.2
			c0.7-0.3,1.4-0.5,2.2-0.3c0.2,0,0.5,0,0.7,0c0.9,0,1.7,0.2,2.3,0.9c0.1,0.1,0.2,0.2,0.4,0.3c0.1-0.1,0.2-0.2,0.2-0.3
			c0.7-0.8,1.5-1.3,2.5-1.7c0.7-0.3,1.5-0.3,2.2,0.1c0.1,0.1,0.3,0.1,0.4,0.2c0.8,0.3,1.5,0.9,2,1.7c0.1,0.1,0.2,0.3,0.3,0.4
			c0.1,0,0.1,0,0.1,0c0.2-0.1,0.4-0.3,0.6-0.4c1-0.7,2-1.1,3.2-1.1c0.3,0,0.6,0,0.9,0c1.1,0,2,0.5,2.6,1.5c0,0.1,0.1,0.1,0.2,0.2
			c0.1-0.1,0.2-0.2,0.2-0.3c0.3-0.5,0.7-1,1.1-1.3c0.8-0.7,1.7-1.1,2.6-1.2c0.5-0.1,1,0.1,1.2,0.5c0.2,0.3,0.4,0.5,0.7,0.7
			c0.4,0.2,0.8,0.5,1.1,0.9c0.2,0.3,0.4,0.4,0.8,0.3c0.4-0.1,0.9-0.2,1.4-0.2c1,0,2,0,2.9-0.1c0.4,0,0.7-0.1,1.1-0.1
			c1.3-0.1,2.5-0.3,3.8-0.4c0.2,0,0.5-0.1,0.7-0.1c0.5,0,0.9-0.2,1.4-0.4c0.7-0.5,1.6-0.7,2.4-0.8c0.2,0,0.3,0,0.5,0
			c0.4,0.1,0.8,0.1,1.2,0.2c0.7,0.1,1.3,0.3,1.8,0.7c0.1,0.1,0.2,0.1,0.3,0.2c0.3-0.1,0.3-0.3,0.5-0.5c0.5-0.6,1.1-1.1,1.8-1.3
			c0.7-0.2,1.3-0.3,2,0.1c0.2,0.1,0.4,0.2,0.6,0.2c0.7,0.2,1.4,0.5,1.9,1c0.2,0.1,0.3,0.3,0.6,0.4c0.1-0.1,0.2-0.2,0.3-0.3
			c0.8-0.7,1.7-1.2,2.8-1.3c0.5,0,0.9,0,1.3,0.3c0.5,0.3,1,0.6,1.4,1.1c0.2,0.2,0.3,0.2,0.6,0.2c0.2-0.1,0.5-0.1,0.7-0.1
			c0.9-0.1,1.9-0.2,2.8-0.3c0.7-0.1,1.5-0.1,2.2-0.2c0.6-0.1,1.2-0.1,1.7-0.2c0.5-0.1,1-0.1,1.5-0.2
			C681.3,668.1,681.4,668,681.5,668z M679.7,606.8c0-0.2,0.1-0.2,0.1-0.3c0-0.9,0.1-1.8,0.1-2.6c0-1.3,0-2.7,0-4c0-0.2,0-0.5,0-0.7
			c0.1-1.2,0.2-2.4,0.2-3.6c0-1.5,0.1-3,0.2-4.4c0-0.5,0-0.6-0.5-0.6c-0.1,0-0.1,0-0.2,0c-1.8,0-3.6,0.1-5.4,0.1c0,0-0.1,0-0.1,0
			c-1.2,0.1-2.5,0.1-3.7,0.2c-0.9,0.1-1.7,0.1-2.6,0.2c-0.5,0-1,0.1-1.5,0.1c-0.6,0.1-1.2,0.1-1.8,0.2c-0.2,0-0.4,0-0.6,0
			c-1.2,0.2-2.4,0.1-3.5,0.4c-0.1,0-0.2,0-0.2,0c-0.3,0-0.6,0.1-0.9,0.1c-0.2,0-0.4,0.1-0.6,0.1c-0.6,0.1-1.1,0.1-1.7,0.2
			c-0.3,0-0.6,0-0.9,0.1c-0.4,0-0.8,0.1-1.2,0.1c-0.6,0-1.1,0.1-1.7,0.1c-3.6,0-7.1,0-10.7,0c-0.2,0-0.5,0-0.7,0
			c-1.1-0.2-2.3-0.2-3.4-0.2c-0.5,0-1-0.1-1.4-0.2c-0.2-0.1-0.5-0.1-0.7-0.1c-0.3-0.1-0.6,0-0.7,0.3c-0.1,0.2-0.3,0.4-0.4,0.6
			c-0.9,1-2.7,1.6-4.1,1.3c-1.3-0.2-2.3-0.8-2.8-2.1c-0.1-0.1-0.2-0.2-0.2-0.4c-0.2,0.1-0.4,0.2-0.5,0.4c-0.7,0.5-1.6,0.8-2.5,1
			c-1.1,0.2-2.1,0-2.9-0.8c-0.1-0.1-0.2-0.1-0.3-0.2c-0.2,0.3-0.4,0.5-0.6,0.8c-0.8,1-1.8,1.5-3,1.6c-1.5,0-2.7-0.5-3.7-1.6
			c-0.1-0.1-0.2-0.3-0.4-0.4c-0.1,0.1-0.2,0.2-0.3,0.3c-0.4,0.5-0.9,0.8-1.5,1c-0.8,0.3-1.7,0.4-2.5,0.2c-1.3-0.3-2.4-0.8-3.2-2
			c0-0.1-0.1-0.1-0.2-0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.3,0.3-0.6,0.7-0.9,0.9c-1.4,1.1-3.4,1.2-4.6-0.2c-0.1-0.1-0.1-0.1-0.2-0.2
			c0,0.2,0,0.3,0,0.4c0,0.4,0.1,0.8,0.1,1.2c0,0.3,0.1,0.6,0.1,0.9c0,0.4,0.1,0.9,0.1,1.3c0.1,1.2,0.3,2.4,0.3,3.7
			c0,0.1,0,0.1,0,0.2c0.1,0.7,0.2,1.3,0.2,2c0,0.2,0,0.5,0,0.7c0.1,0.9,0.2,1.8,0.2,2.7c0,0.5,0.1,0.9,0.1,1.4
			c0,0.2,0.1,0.3,0.4,0.3c0.6,0,1.3,0,1.9,0.1c1.2,0.1,2.3,0.1,3.5,0c0.8,0,1.6-0.1,2.4-0.1c0.4,0,0.8,0,1.3,0
			c0.2,0,0.3-0.1,0.4-0.2c0-0.1,0.1-0.1,0.1-0.2c0.4-0.7,1-1.2,1.7-1.4c0.8-0.2,1.7-0.4,2.5-0.6c1-0.3,1.9,0.1,2.5,0.8
			c0.2,0.2,0.3,0.4,0.4,0.5c0.1,0.1,0.2,0.2,0.2,0.2c0.5-0.3,1-0.6,1.4-1c0.1-0.1,0.3-0.2,0.4-0.2c0.7-0.2,1.4-0.4,2.2-0.3
			c0.9,0.1,1.7,0.4,2.2,1.2c0.1,0.1,0.2,0.2,0.2,0.2c0.3,0,0.4-0.2,0.5-0.3c0.4-0.4,0.9-0.8,1.5-0.9c0.6-0.2,1.1-0.3,1.7-0.5
			c0.4-0.1,0.9-0.1,1.3,0.1c0.7,0.2,1.3,0.7,1.7,1.3c0.1,0.1,0.1,0.3,0.3,0.3c0,0,0.1,0,0.1-0.1c0.1-0.1,0.1-0.1,0.2-0.2
			c0.6-0.6,1.3-1,2.1-1.2c1.1-0.2,2.1-0.2,3,0.6c0.3,0.3,0.5,0.6,0.8,0.9c0.1,0.1,0.2,0.2,0.2,0.4c0.1,0.2,0.2,0.2,0.5,0.2
			c0.2-0.1,0.5-0.1,0.7-0.1c1,0,2,0,2.9,0c0.7,0,1.4,0,2.1,0c1.1-0.1,2.2-0.2,3.4-0.2c0.3,0,0.5,0,0.8,0c0.4,0,0.6-0.1,0.9-0.4
			c0.4-0.5,1-1,1.6-1.3c0.4-0.2,0.8-0.4,1.3-0.3c0.5,0.1,0.9,0.2,1.3,0.3c0.8,0.2,1.4,0.7,1.8,1.4c0.1,0.1,0.2,0.2,0.2,0.3
			c0.2,0,0.3-0.1,0.5-0.2c0.3-0.4,0.7-0.6,1.2-0.8c0.3-0.1,0.6-0.3,0.8-0.4c1.4-0.7,2.7-0.2,3.4,0.9c0.1,0.1,0.2,0.2,0.3,0.3
			c0.3-0.1,0.4-0.3,0.6-0.5c0.5-0.5,1.1-0.8,1.8-0.9c0.3-0.1,0.7,0,1,0.2c0.2,0.1,0.4,0.2,0.6,0.2c0.9,0.1,1.6,0.5,2.1,1.3
			c0.1,0.1,0.2,0.2,0.3,0.3c0.3-0.2,0.5-0.4,0.7-0.7c0.8-0.7,1.7-1.1,2.8-1.1c0.6,0,1.1,0.2,1.5,0.6c0.1,0.1,0.3,0.3,0.5,0.4
			c0.2-0.1,0.3-0.1,0.4-0.2c0.4-0.2,0.9-0.3,1.4-0.4c0.3,0,0.6-0.1,0.9-0.2c0.8-0.3,1.8,0,2.3,0.7
			C679.4,606.5,679.5,606.6,679.7,606.8z M601.1,650.6c0-1.7,0-3.8-0.1-6c-0.1-2.2,0-4.4-0.1-6.6c-0.1-1.7,0-3.3-0.1-5
			c-0.1-1-0.1-2-0.1-2.9c0-1.4,0-2.7-0.1-4.1c-0.1-1.4-0.1-2.7-0.2-4.1c0-0.1,0-0.2,0-0.4c-0.1-1.1-0.1-2.2-0.2-3.3
			c-0.1-1.1-0.1-2.3-0.2-3.4c0-0.6-0.1-1.1-0.1-1.7c-0.1-0.8-0.1-1.7-0.2-2.5c0-0.5-0.1-1.1-0.1-1.6c-0.1-0.7-0.1-1.5-0.2-2.2
			c-0.1-1.3-0.2-2.5-0.3-3.8c-0.1-1.1-0.2-2.3-0.4-3.4c0-0.3-0.1-0.6-0.1-0.9c0-0.6-0.1-1.2-0.2-1.7c0-0.2,0-0.5-0.1-0.7
			c-0.1-0.6-0.1-1.1-0.2-1.7c0-0.2,0-0.4-0.1-0.6c-0.1-1.1-0.2-2.3-0.3-3.4c-0.1-0.9-0.2-1.7-0.3-2.5c-0.1-0.9-0.2-1.8-0.3-2.7
			c-0.2-1.4-0.4-2.8-0.7-4.1c-0.3-2-0.8-4-1.3-6c-0.3-1-0.6-2-1.1-2.9c-0.1-0.2-0.2-0.4-0.4-0.6c-0.2-0.2-0.4-0.2-0.5,0
			c-0.1,0.2-0.2,0.4-0.3,0.5c-0.4,1-0.7,2.1-0.9,3.2c-0.2,1.1-0.4,2.3-0.6,3.5c-0.1,0.9-0.3,1.7-0.4,2.6c-0.1,1-0.2,1.9-0.3,2.9
			c-0.2,1.6-0.3,3.2-0.5,4.9c-0.1,1.2-0.2,2.5-0.2,3.7c-0.1,1.2-0.1,2.4-0.1,3.6c0,0.2,0,0.3,0,0.5c-0.1,1.2-0.2,2.5-0.2,3.7
			c-0.1,1.7,0,3.4-0.1,5.1c-0.1,2.8-0.1,5.6-0.1,8.3c-0.1,3.5,0,7.1,0,10.6c0,1.5,0,3,0.1,4.6c0.1,2,0.1,4,0.2,6.1
			c0,1.5,0,3,0.1,4.4c0.1,1.2,0.1,2.5,0.1,3.7c0.1,1.4,0.1,2.8,0.2,4.3c0.1,1.3,0.1,2.6,0.2,3.9c0.1,1.1,0.1,2.3,0.1,3.4
			c0,0.1,0,0.2,0,0.3c0.1,1.1,0.2,2.2,0.2,3.3c0,0.2,0,0.3,0,0.5c0.1,1.1,0.2,2.2,0.2,3.4c0,0.1,0,0.2,0,0.4
			c0.1,0.9,0.1,1.9,0.2,2.8c0,0.2,0,0.4,0,0.6c0.1,0.7,0.1,1.4,0.2,2.1c0.1,0.9,0.1,1.7,0.2,2.6c0.1,1.2,0.2,2.5,0.3,3.7
			c0,0.4,0.1,0.8,0.1,1.2c0.1,1.1,0.2,2.2,0.3,3.4c0.1,0.6,0.2,1.3,0.2,1.9c0.1,1.5,0.3,3.1,0.5,4.6c0,0.4,0.1,0.8,0.1,1.2
			c0.1,0.4,0.2,0.5,0.6,0.5c0.2,0,0.3,0,0.5,0c0.6,0,1.1-0.1,1.7-0.1c1,0,2,0,3.1,0c0.2,0,0.3,0,0.5,0c0.4,0,0.5-0.1,0.5-0.5
			c0-0.2,0-0.5,0-0.7c0-0.2-0.1-0.4,0-0.6c0-0.5,0.1-1,0.1-1.6c0.1-1,0.2-2,0.2-3.1c0-0.3,0-0.6,0.1-0.9c0.1-0.8,0.1-1.6,0.2-2.4
			c0-0.6,0-1.3,0.1-1.9c0.1-1.2,0.2-2.4,0.2-3.6c0-0.6,0-1.3,0-1.9c0-0.4,0-0.8,0-1.1c0.1-1.3,0.2-2.7,0.2-4c0.1-3.2,0-6.5,0.1-9.7
			C601.2,653.9,601.1,652.5,601.1,650.6z M684.3,686.6c-0.2-0.1-0.3-0.2-0.4-0.3c-0.5-0.3-0.7-0.7-0.7-1.2c-0.1-1-0.2-2-0.3-3
			c0-0.4-0.1-0.7-0.1-1.1c0-0.2-0.2-0.3-0.4-0.3c-0.3,0-0.6,0.1-0.9,0.2c-1.5,0.2-3.1,0.5-4.6,0.7c-1.2,0.1-2.3,0.2-3.5,0.3
			c-1.5,0.1-3,0.1-4.6,0.1c-0.6,0-1.1,0-1.7,0c-1.5,0.1-3,0.2-4.5,0.2c-2.7,0-5.4,0-8.1,0c-5.8,0-11.7-0.1-17.5,0.1
			c-1.2,0.1-2.3,0.1-3.5,0.1c-0.1,0-0.2,0-0.2,0c-1.3,0.1-2.6,0.2-3.9,0.2c-0.7,0-1.5-0.1-2.2-0.1c-1.8-0.1-3.7-0.1-5.5-0.1
			c-0.4,0-0.9,0-1.3,0c-1.1,0-2.2-0.1-3.3-0.2c-0.5,0-1-0.1-1.5-0.1c-0.8-0.1-1.7-0.2-2.5-0.3c-1.5-0.2-3-0.4-4.5-0.6
			c-0.6-0.1-1.1-0.2-1.7-0.3c-0.8-0.1-1.5-0.3-2.3-0.4c-0.6-0.1-1.1-0.1-1.7-0.2c-0.2,0-0.3,0.1-0.3,0.3c0,0,0,0.1,0,0.1
			c0,0.6,0,1.2,0,1.8c0,0.3,0,0.6,0,0.9c0,0.3,0,0.6-0.1,0.9c-0.1,0.8-0.1,1.6-0.2,2.4c0,0.4-0.1,0.8-0.2,1.2
			c-0.1,0.3-0.3,0.6-0.6,0.7c-0.2,0.1-0.5,0.1-0.7,0.1c-0.5,0-0.9,0-1.4,0c-1.9,0.2-3.7,0.2-5.6,0.2c-0.2,0-0.3,0-0.5,0
			c-0.9-0.1-1.2-0.2-1.3-1.2c0-0.1,0-0.1,0-0.2c-0.3-1.7-0.6-3.4-0.8-5.1c0-0.2-0.1-0.4-0.1-0.6c-0.1-0.4-0.3-0.5-0.6-0.2
			c-0.1,0-0.1,0.1-0.2,0.2c-1.1,0.9-2.2,1.8-3.3,2.8c-1.8,1.5-3.7,3-5.5,4.4c-0.4,0.3-0.9,0.6-1.3,1c-0.2,0.2-0.5,0.4-0.7,0.7
			c-0.1,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0.1c0.8,0.2,1.6,0.3,2.5,0.3c1.2,0.1,2.4,0.2,3.6,0.2c1.9,0.1,3.8,0,5.8,0.1
			c1,0.1,2,0.1,3,0.2c0.1,0,0.2,0,0.3,0c1,0.1,1.9,0.1,2.9,0.2c0.6,0,1.3,0,1.9,0c1.6,0.1,3.2,0.2,4.8,0.2c2.3,0.1,4.5,0,6.8,0.1
			c3.1,0.1,6.2,0.1,9.3,0.2c1.2,0,2.3,0,3.5,0c5.9,0,11.8,0.1,17.7-0.1c2.4-0.1,4.7,0,7.1-0.1c1.7-0.1,3.5,0,5.3-0.1
			c1.1-0.1,2.1-0.1,3.2-0.2c0.1,0,0.2,0,0.2,0c1.1-0.1,2.1-0.2,3.2-0.2c0.2,0,0.5,0,0.7-0.1c0.7-0.1,1.4-0.1,2.1-0.2
			c0.4,0,0.7-0.1,1.1-0.1c0.6,0,1.2-0.1,1.7-0.2c0.6,0,1.3-0.1,1.9-0.1c0.5,0,1-0.1,1.5-0.1c1.5-0.1,3.1-0.1,4.6-0.1
			c1.4,0,2.8,0,4.3,0.1c2.3,0.1,4.5,0,6.8,0c0.2,0,0.4,0,0.6,0c1.9-0.2,3.8-0.5,5.8-0.9c1-0.2,2.1-0.4,3.1-0.6
			c1.5-0.3,3-0.5,4.5-0.8c1.2-0.3,2.5-0.6,3.7-0.9c0.5-0.1,1-0.2,1.5-0.4c0.1,0,0.2-0.1,0.2-0.1c-0.1-0.3-0.3-0.4-0.4-0.5
			c-0.9-0.5-1.8-1.1-2.7-1.6c-0.8-0.5-1.5-1-2.2-1.6c-1-1-2.1-1.9-3.1-2.9c-0.1-0.1-0.2-0.1-0.3-0.1c0,0.1-0.1,0.3-0.1,0.4
			c0,0.4,0,0.7,0,1.1c0,0.7-0.1,1.5-0.1,2.2c0,0.2,0,0.5-0.1,0.7c-0.1,0.6-0.5,1-1.1,1.1c-0.3,0.1-0.7,0.1-1.1,0.1c-1.5,0-3,0-4.6,0
			c-0.2,0-0.5,0-0.7,0c-1-0.1-2-0.3-3-0.4C685.1,686.2,684.7,686.2,684.3,686.6z M602.8,675.4c0,0.1-0.1,0.2-0.1,0.3
			c0,0.2,0,0.3,0,0.5c0,0.8,0,1.6-0.1,2.4c0,0.1,0,0.3,0,0.4c0,0.3,0.1,0.4,0.4,0.4c0.5,0,0.9,0,1.4,0c0.6,0,1.2,0.1,1.9,0.2
			c0.8,0.1,1.6,0.2,2.4,0.3c0,0,0.1,0,0.1,0c0.4,0,0.7,0.1,1.1,0.1c0.2,0,0.5,0.1,0.7,0.1c1,0.1,1.9,0.2,2.9,0.3
			c0.9,0.2,1.8,0.2,2.7,0.3c0.5,0.1,0.9,0.1,1.4,0.1c1.6,0.1,3.3,0,4.9,0.1c3,0.1,6.1,0,9.1,0c0.2,0,0.5,0,0.7,0
			c1.2-0.1,2.5-0.2,3.7-0.2c0.1,0,0.3,0,0.4,0c1.1-0.1,2.2-0.2,3.3-0.2c3.4-0.1,6.8,0,10.3-0.1c5.3-0.1,10.6,0.1,16-0.1
			c1.3-0.1,2.6-0.1,3.9-0.1c1.5,0,2.9,0,4.4-0.1c0.7-0.1,1.4-0.1,2.1-0.2c0.3,0,0.5-0.1,0.8-0.1c1.6-0.2,3.1-0.4,4.7-0.7
			c0.2,0,0.4-0.1,0.5-0.2c0.2-0.1,0.3-0.2,0.3-0.4c0-0.1,0-0.2,0-0.3c-0.1-0.7-0.1-1.5-0.2-2.2c0-0.4-0.1-0.8-0.1-1.2
			c-0.1-0.7-0.1-1.3-0.2-2c-0.1-0.7-0.1-0.8-0.8-0.7c-1,0-2,0.1-2.9,0.2c-0.6,0.1-1.2,0.1-1.9,0.2c-1.1,0.1-2.3,0.1-3.4,0.1
			c-0.1,0-0.1,0-0.2,0.1c-0.1,0.2-0.2,0.4-0.2,0.6c-0.3,0.7-0.8,1.2-1.5,1.6c-1,0.5-2.1,0.6-3.2,0.3c-0.6-0.2-1.2-0.5-1.7-1
			c-0.3-0.3-0.3-0.3-0.7-0.1c-1.4,0.8-2.8,0.8-4.3,0.4c-0.5-0.1-1-0.3-1.5-0.6c-0.4-0.2-0.4-0.2-0.7,0.1c-0.2,0.2-0.4,0.5-0.6,0.7
			c-1.5,1.2-3.2,1.5-5,0.9c-0.8-0.3-1.5-0.7-2-1.4c-0.1-0.1-0.2-0.3-0.3-0.5c-0.1-0.2-0.3-0.3-0.6-0.3c-0.7,0-1.4,0-2.1,0
			c-1.9,0-3.8-0.1-5.8-0.1c-0.4,0-0.8,0-1.2-0.1c-0.3,0-0.6,0-0.8,0.3c-0.3,0.3-0.6,0.6-1,0.9c-0.9,0.6-1.9,0.8-3,0.7
			c-0.5,0-0.9-0.1-1.4-0.4c-0.5-0.3-1-0.7-1.3-1.1c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1,0-0.1,0-0.1,0c-0.1,0.1-0.2,0.2-0.2,0.3
			c-0.8,1.6-2.9,2.6-4.9,2.1c-0.8-0.2-1.6-0.5-2.3-1.1c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1,0.1-0.2,0.3-0.4,0.4c-0.1,0.1-0.2,0.3-0.4,0.4
			c-1.2,1-2.5,1.3-3.9,1c-0.9-0.2-1.7-0.6-2.4-1.2c-0.1-0.1-0.2-0.1-0.3-0.2c-0.2,0.1-0.3,0.3-0.4,0.4c-0.7,0.6-1.5,1-2.4,1.2
			c-1.6,0.3-4-0.3-5.1-1.4c-0.1-0.1-0.2-0.1-0.4-0.2c-0.2,0.2-0.4,0.4-0.6,0.6c-0.6,0.6-1.3,0.9-2.2,1.1c-1.3,0.2-2.4,0-3.6-0.6
			C603.2,675.6,603.1,675.4,602.8,675.4z M655.5,697.8c2.4,0,4.8,0.1,7.3-0.1c0.7-0.1,1.4-0.1,2.1-0.2c1.5-0.2,3-0.2,4.6-0.3
			c1.8-0.1,3.6-0.1,5.4-0.1c0.5,0,1,0,1.6,0c0.2,0,0.4,0,0.6,0c1.4-0.1,2.9-0.2,4.3-0.4c0.7-0.1,1.4,0,2.1-0.2
			c1.4-0.2,2.7-0.2,4.1-0.3c0.7-0.1,1.5-0.1,2.2-0.2c1.1-0.1,2.2-0.3,3.2-0.4c1-0.1,2-0.2,3-0.3c1.8-0.2,3.6-0.4,5.4-0.6
			c0.4,0,0.9-0.1,1.3-0.1c0.7-0.1,1.4-0.3,2.1-0.5c0.3-0.1,0.4-0.2,0.5-0.5c0.1-0.3,0.1-0.6,0.2-0.9c0-0.4,0.1-0.7,0.1-1.1
			c0-0.6,0.1-1.3,0.1-1.9c0-0.2-0.1-0.3-0.3-0.2c-0.1,0-0.1,0-0.2,0c-0.7,0.2-1.4,0.4-2.2,0.6c-1.9,0.5-3.8,0.9-5.7,1.3
			c-2.3,0.4-4.6,0.8-6.8,1.2c-2.3,0.4-4.6,0.7-6.9,0.7c-1.5,0-3,0-4.5,0c-0.2,0-0.4,0-0.6,0c-0.9,0-1.8-0.1-2.6,0
			c-1.5,0-3,0.2-4.4,0.2c-1.7,0-3.5,0.2-5.2,0.3c-1.3,0.1-2.7,0.2-4,0.3c-0.7,0.1-1.3,0.1-2,0.1c-0.1,0-0.3,0-0.4,0
			c-1.1,0.1-2.2,0.2-3.2,0.2c-0.2,0-0.4,0-0.6,0c-1,0.1-2,0.1-2.9,0.2c-0.8,0-1.6,0-2.4,0c-1.4,0.1-2.7,0.2-4.1,0.2
			c-3.7,0.1-7.4,0-11,0.1c-2.8,0.1-5.5,0-8.3-0.1c-1.5,0-3.1,0-4.6,0c-1.9,0-3.9,0-5.8-0.1c-1.5-0.1-2.9-0.1-4.4-0.1
			c-2,0-4,0-6.1-0.1c-2.3-0.1-4.6-0.1-6.8-0.2c-0.2,0-0.5,0-0.7,0c-1.1-0.1-2.2-0.1-3.3-0.2c-0.3,0-0.6,0-0.9-0.1
			c-0.8-0.1-1.5-0.1-2.3-0.2c-1.2,0-2.4,0-3.6-0.1c-1.4-0.1-2.8-0.1-4.2-0.1c-1.6,0-3.2-0.2-4.8-0.5c-0.1,0-0.2-0.1-0.4-0.1
			c-0.2,0-0.3,0.1-0.3,0.2c0,0.1,0,0.3,0,0.4c0,0.5-0.1,1-0.1,1.6c0,0.2,0,0.4,0,0.6c0,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.5,0.1,0.7,0.1
			c1,0.1,1.9,0.3,2.9,0.4c1,0.1,1.9,0.3,2.9,0.4c1,0.1,2,0.2,3,0.3c0.9,0.1,1.9,0.2,2.8,0.2c2,0.1,4.1,0,6.1,0.1c1,0.1,2,0.1,3,0
			c5.8,0,11.6,0,17.4,0c1.1,0,2.2-0.1,3.3-0.1c0.1,0,0.2,0,0.3,0c0.8-0.1,1.6-0.2,2.4-0.2c2.7,0,5.4-0.1,8.1,0.1
			c1.5,0.1,3,0.1,4.6,0.1c1.7,0.1,3.3,0.1,5,0.1c2.9,0.1,5.9,0,8.8,0.1C652.2,697.8,653.8,697.8,655.5,697.8z M636.2,589.9
			c0,0.3-0.1,0.5-0.1,0.7c0,0.2,0.1,0.3,0.3,0.4c0.3,0.1,0.7,0.1,1,0.1c0.9,0,1.8,0,2.7,0c0.3,0,0.7,0,1,0.1c0.5,0.1,1,0.1,1.6,0.1
			c3.2,0,6.4,0,9.6,0c0.4,0,0.8,0,1.1-0.1c0.8-0.1,1.7-0.2,2.5-0.2c0.4,0,0.8,0,1.2-0.1c0.8-0.1,1.7-0.1,2.5-0.4c0,0,0,0,0.1,0
			c0.4,0,0.8-0.1,1.2-0.2c0.1,0,0.3,0,0.4-0.1c0.5-0.1,1-0.1,1.5-0.2c0.3,0,0.5,0,0.8-0.1c1-0.1,1.9-0.2,2.9-0.3
			c0.4-0.1,0.9-0.1,1.3-0.2c0.2,0,0.5,0,0.7,0c0.9-0.1,1.7-0.1,2.6-0.2c0.6,0,1.1,0,1.7-0.1c1-0.1,2-0.2,2.9-0.2
			c1.1,0,2.2-0.1,3.3-0.1c0.3,0,0.5,0,0.8,0c0.1,0,0.3,0,0.4,0c0.2,0,0.3-0.2,0.3-0.3c0-0.1,0-0.2,0-0.2c0-0.3-0.1-0.5-0.4-0.4
			c-0.6,0-1.3,0.1-1.9,0.1c-1.2,0.1-2.3,0.2-3.5,0.2c-1.9,0-3.9,0-5.8,0.1c-1.9,0.1-3.9,0-5.8,0.1c-0.9,0.1-1.8,0.1-2.7,0.2
			c-0.5,0.1-1.1,0.1-1.6,0.2c-1.4,0.2-2.9,0.4-4.3,0.7c-0.7,0.1-1.4,0.2-2,0.3c-1,0.1-2,0.2-3.1,0.3c-1.6,0.2-3.1,0.2-4.7,0.3
			c-1.8,0.1-3.6,0-5.4-0.3c-0.8-0.1-1.7-0.2-2.5-0.2C636.5,589.8,636.4,589.9,636.2,589.9z M615.2,590.4c0,0.1,0,0.2,0,0.3
			c0,0.8,0.4,1.4,1,1.7c0.3,0.2,0.7,0.3,1,0.4c1.3,0.3,2.6-0.5,2.9-1.8c0.2-0.9,0-1.6-0.4-2.4c-0.2-0.4-0.6-0.7-1-0.9
			c-0.5-0.2-1-0.3-1.5-0.4c-0.3-0.1-0.5,0-0.8,0.2c-0.4,0.3-0.7,0.6-1,1.1C615.3,589.2,615.2,589.8,615.2,590.4z M621.6,674.6
			c0.2,0,0.3,0,0.4,0c0.6,0,1.1-0.2,1.6-0.6c0.4-0.3,0.6-0.7,0.6-1.2c0-0.2,0-0.5,0-0.7c-0.1-0.8-0.4-1.4-1.1-1.9
			c-0.5-0.4-1.1-0.5-1.8-0.5c-0.4,0-0.8,0.1-1.2,0.4c-0.2,0.2-0.4,0.4-0.6,0.6c-0.6,0.5-0.8,1.2-0.8,2c0,0.5,0.2,0.9,0.6,1.2
			C620.1,674.3,620.8,674.7,621.6,674.6z M634.4,589.8c0-0.4,0-0.8-0.1-1.1c-0.1-0.5-0.4-0.8-0.9-0.9c-0.3-0.1-0.6-0.2-0.9-0.2
			c-0.4,0-0.8,0-1.2,0c-0.1,0-0.3,0.1-0.4,0.2c-0.7,0.4-1.1,1-1.3,1.8c-0.2,0.6-0.3,1.3,0,2c0.3,0.8,0.8,1.2,1.6,1.3
			c0.7,0.1,1.3,0,1.9-0.3c0.7-0.3,1.1-0.8,1.2-1.5C634.3,590.5,634.4,590.1,634.4,589.8z M613.9,674.8c1,0,2.1-0.3,2.6-0.9
			c0.6-0.6,0.7-1.4,0.5-2.2c-0.2-0.5-0.6-0.9-1.1-1c-0.7-0.2-1.3-0.1-1.9,0.1c-0.4,0.1-0.7,0.2-1.1,0.2c-0.6,0-1.1,0.3-1.4,0.8
			c-0.2,0.2-0.3,0.5-0.5,0.8c-0.2,0.3-0.1,0.6,0.1,0.9c0.1,0.2,0.3,0.4,0.5,0.5C612.4,674.5,613.2,674.8,613.9,674.8z M610.3,592.5
			c0.4,0,0.6,0,0.9-0.1c0.8-0.1,1.6-0.6,1.6-1.7c0-0.4,0-0.9-0.1-1.3c-0.1-0.9-0.4-1.4-1.6-1.6c-0.4-0.1-0.7-0.1-1-0.3
			c-0.3-0.1-0.5-0.1-0.8,0.1c-0.9,0.7-1.5,1.6-1.6,2.9c0,0.7,0.2,1.1,0.8,1.5c0.2,0.2,0.5,0.3,0.8,0.4
			C609.7,592.5,610.1,592.5,610.3,592.5z M605.8,631.2C605.8,631.2,605.8,631.2,605.8,631.2c-0.8,0-1.5,0-2.3,0
			c-0.7,0-0.8,0.1-0.8,0.8c0,0,0,0.1,0,0.1c0,0.3,0.1,0.4,0.5,0.4c0.5,0,1,0,1.6-0.1c0.8-0.1,1.7-0.2,2.5-0.2c0.4,0,0.8,0,1.3,0
			c0.7,0,1.4-0.1,2.1-0.1c0.7,0,1.4-0.1,2.1-0.1c1.6,0,3.1-0.2,4.7-0.3c0.2,0,0.5-0.1,0.7-0.1c0.3-0.1,0.4-0.2,0.3-0.5
			c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.2-0.2-0.3-0.4-0.3c-0.3,0-0.5,0-0.8,0c-0.7,0-1.3,0.1-2,0.1c-1.2,0-2.5,0.1-3.7,0.2
			c-1.6,0.2-3.2,0.3-4.7,0.3C606.4,631.2,606.1,631.2,605.8,631.2z M654.9,673.9c0.2,0,0.5-0.1,0.9-0.2c0.5-0.2,1-0.5,1.4-0.9
			c0.4-0.6,0.5-1.2,0.2-1.8c-0.3-0.6-0.8-1-1.5-1c-0.2,0-0.3,0-0.5,0c-0.5,0-0.9,0-1.3-0.3c-0.2-0.1-0.3-0.1-0.5,0
			c-0.6,0.3-1.1,0.7-1.4,1.3c-0.2,0.3-0.2,0.5,0,0.8c0.1,0.2,0.2,0.4,0.2,0.6c-0.1,0.4,0.1,0.6,0.4,0.8
			C653.4,673.7,654,673.9,654.9,673.9z M622.9,590.1c0,0.1,0,0.2,0,0.2c0,1.1,0.6,1.5,1.7,1.4c0.7-0.1,1.3-0.3,1.9-0.7
			c0.5-0.3,0.8-0.7,1-1.3c0.1-0.5,0.1-1,0-1.5c-0.1-0.7-0.5-1.1-1.2-1.3c-0.8-0.2-1.5-0.1-2.1,0.5
			C623.2,588.1,623,589.1,622.9,590.1z M603.7,671.8c-0.1,0.3-0.2,0.6-0.5,0.8c-0.1,0-0.1,0.2-0.1,0.3c-0.1,0.4,0,0.7,0.3,1
			c0.3,0.3,0.6,0.5,1,0.7c0.7,0.3,1.5,0.4,2.3,0.3c1-0.1,1.7-0.7,2.1-1.6c0.2-0.5,0.1-0.9-0.3-1.3c-0.6-0.6-1.3-0.9-2.1-1.1
			c-0.2,0-0.4,0-0.6,0.2c-0.2,0.3-0.4,0.4-0.8,0.4c-0.1,0-0.1,0.1-0.2,0.1c-0.2,0.1-0.5,0.2-0.7,0.2
			C603.9,671.7,603.8,671.7,603.7,671.8z M631.8,672c0-0.7-0.3-1.1-0.9-1.4c-0.2-0.1-0.4-0.1-0.5-0.2c-0.4-0.1-0.8-0.1-1.2-0.2
			c-0.2,0-0.5,0-0.7,0c-0.7,0.3-1.3,0.6-1.7,1.2c-0.2,0.2-0.3,0.4-0.4,0.7c-0.1,0.3-0.1,0.6,0.1,0.8c0.1,0.2,0.3,0.4,0.5,0.5
			c0.4,0.3,0.9,0.5,1.4,0.7c1,0.3,2.5-0.2,3.1-1C631.6,672.9,631.8,672.5,631.8,672z M602.8,592.4c0.9,0,1.5-0.3,1.9-0.9
			c0.4-0.5,0.6-1.2,0.6-1.8c0.1-0.7-0.5-1.8-1.5-2c-0.3-0.1-0.6-0.2-0.9-0.4c-0.2-0.2-0.4-0.1-0.6,0.1c-0.5,0.5-0.9,1-1,1.7
			c-0.2,0.7-0.1,1.4,0,2.1c0.1,0.2,0.2,0.5,0.3,0.7C601.9,592.3,602.4,592.4,602.8,592.4z M662.6,672.9c0.4,0,0.8-0.1,1.2-0.2
			c0.3-0.1,0.6-0.3,0.8-0.4c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.5,0.1-0.9-0.2-1.3c-0.7-1-1.7-1.5-2.9-1.4c-0.2,0-0.5,0-0.7,0
			c-0.5-0.2-0.8,0-1,0.4c0,0.1-0.1,0.1-0.1,0.2c-0.7,1-0.4,2.1,0.7,2.7c0.1,0,0.2,0.1,0.2,0.1C661.3,672.9,662,672.9,662.6,672.9z
			 M679.7,629.5c-0.2,0-0.3,0-0.5,0c-1.1,0.1-2.2,0.2-3.2,0.3c-1,0.1-2.1,0.2-3.1,0.2c-1.4,0.1-2.8,0.1-4.1,0.1c-0.1,0-0.2,0-0.3,0
			c-0.7,0.1-1.4,0.1-2.1,0c-0.2,0-0.5,0-0.8,0c-1,0-1.9,0.1-2.9,0.1c-0.5,0-1,0.1-1.4,0.1c-0.2,0-0.4,0.1-0.5,0.2
			c-0.1,0.1-0.2,0.2-0.1,0.3c0,0.1,0.2,0.2,0.2,0.2c0.3,0,0.6,0,0.9,0c1-0.1,2-0.2,3-0.2c3.4,0,6.9,0,10.3,0c1.3,0,2.6-0.1,3.9-0.1
			c0.1,0,0.3,0,0.4,0c0.2,0,0.3-0.1,0.3-0.3C679.8,630.1,679.8,629.8,679.7,629.5z M666.7,671.5c0,0,0,0.2,0,0.3
			c0,0.4,0.1,0.7,0.4,1c0.7,0.8,2,0.9,2.9,0.6c0.6-0.2,1-0.6,0.9-1.2c0,0,0-0.1,0-0.1c0.1-1-0.2-1.8-1.1-2.3c-0.1,0-0.1-0.1-0.2-0.2
			c-0.4-0.4-1-0.5-1.5-0.4c-0.5,0.1-0.9,0.5-1.2,0.9c-0.3,0.4-0.4,0.8-0.3,1.2C666.7,671.3,666.7,671.4,666.7,671.5z M628.7,606.4
			c-0.2,0-0.5,0-0.7,0.1c-0.8,0.3-1.4,0.8-1.7,1.6c-0.4,0.9,0,1.8,0.9,2.1c0.8,0.3,1.6,0.3,2.3,0c1.2-0.5,1.6-1.3,1.2-2.5
			c-0.3-0.9-0.9-1.3-1.8-1.3C628.9,606.4,628.9,606.4,628.7,606.4z M635.1,610.3c0.7,0,1.1-0.1,1.5-0.4c0.6-0.4,0.9-1,0.6-1.7
			c-0.2-0.5-0.4-0.9-0.8-1.3c-0.3-0.3-0.6-0.5-1-0.6c-0.1,0-0.2,0-0.3,0c-0.8,0.2-1.4,0.7-1.8,1.4c-0.5,0.9-0.1,2,0.8,2.5
			C634.4,610.3,634.8,610.3,635.1,610.3z M652.4,610.3c0.4,0,0.8-0.1,1.2-0.3c1.3-0.6,1.3-2.1,0.8-2.8c-0.1-0.2-0.3-0.4-0.6-0.5
			c-0.3-0.1-0.7-0.2-1-0.3c-0.6-0.1-1.1,0-1.4,0.5c-0.2,0.3-0.4,0.5-0.6,0.8c-0.2,0.3-0.3,0.6-0.3,0.9c-0.1,0.6,0.2,1.1,0.7,1.4
			C651.5,610.2,651.9,610.3,652.4,610.3z M636.8,673.5c0.4-0.1,0.8-0.1,1.2-0.2c0.5-0.1,0.9-0.4,1.2-0.8c0.4-0.6,0.4-1.2-0.1-1.6
			c-0.6-0.6-1.3-0.8-2.1-0.7c-0.3,0-0.6,0.1-0.9,0.1c-0.5,0-0.8,0.2-1,0.6c-0.1,0.1-0.2,0.3-0.2,0.4c-0.3,0.6-0.1,1,0.3,1.5
			C635.4,673.3,636.1,673.5,636.8,673.5z M614.5,610C614.5,610,614.5,609.9,614.5,610c0.2,0,0.4,0,0.6,0c0.5,0,1.1-0.2,1.5-0.5
			c0.2-0.1,0.5-0.3,0.5-0.6c0.2-0.7,0.1-1.3-0.4-1.9c-0.2-0.2-0.4-0.4-0.7-0.3c-0.4,0-0.7,0.1-1.1,0.1c-0.3,0-0.6,0.1-0.9,0.1
			c-0.1,0-0.2,0-0.3,0c-0.9,0.2-1.3,0.9-1.1,1.6c0.1,0.2,0.1,0.5,0.2,0.7c0.1,0.3,0.3,0.5,0.7,0.6
			C613.8,609.9,614.2,609.9,614.5,610z M621.9,610.1c0.2,0,0.5,0,0.7,0c1.3-0.2,1.7-1.2,1.2-2.4c-0.2-0.5-0.6-0.8-1.2-0.8
			c-0.3,0-0.7,0-1,0c-0.6,0.1-1.2,0.3-1.6,0.8c-0.2,0.2-0.3,0.4-0.4,0.7c-0.2,0.6,0,1.1,0.6,1.4C620.7,610,621.3,610.1,621.9,610.1z
			 M620.4,654.3c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.6-0.1c0.2-0.1,0.5-0.2,0.7-0.4c0.5-0.5,0.5-1.1-0.1-1.5
			c-0.1-0.1-0.2-0.1-0.3-0.2c-1-0.4-1.9-0.9-3.1-0.6c0,0-0.1,0-0.1,0c-0.5,0-0.7,0.2-0.9,0.6c-0.2,0.3-0.2,0.7-0.2,1.1
			c0,0.3,0.2,0.5,0.4,0.7c0.2,0.1,0.3,0.2,0.5,0.2C619.1,654.2,619.8,654.2,620.4,654.3z M611.2,609.9c0.1-0.3,0-0.5-0.1-0.7
			c0-0.2-0.2-0.3-0.4-0.3c-0.5,0-1,0-1.4,0.1c-1,0.1-2.1,0.2-3.1,0.2c-0.3,0-0.6,0-0.9,0c-1.2,0-2.3-0.1-3.5-0.4c-0.1,0-0.2,0-0.2,0
			c-0.2,0.2-0.1,0.5-0.1,0.7c0,0.4,0.1,0.5,0.5,0.6c0.2,0,0.5,0.1,0.7,0.1c1.7,0,3.4,0.1,5.1,0.1c0.5,0,1,0,1.6,0
			C610.1,610.2,610.7,610.2,611.2,609.9z M676.8,610.7c0.1,0,0.3,0,0.5-0.1c0.2,0,0.3-0.1,0.5-0.1c0.6-0.2,0.9-0.7,0.9-1.4
			c0-0.5-0.2-1-0.4-1.4c-0.2-0.3-0.4-0.4-0.8-0.4c-0.6,0.1-1.2,0.1-1.8,0.4c-0.2,0.1-0.4,0.2-0.5,0.3c-0.1,0.1-0.2,0.2-0.3,0.3
			c-0.3,0.5-0.2,1.2,0.2,1.6C675.4,610.5,676.1,610.6,676.8,610.7z M644.9,632.1c0.8,0,1.7-0.5,2-1.2c0.2-0.3,0.2-0.6,0-1
			c-0.1-0.4-0.4-0.6-0.7-0.8c-0.5-0.3-1.1-0.5-1.6-0.7c-0.1-0.1-0.3,0-0.4,0.1c-0.6,0.4-1,0.8-1.2,1.5c-0.1,0.2-0.1,0.4,0,0.6
			c0.2,0.7,0.7,1.1,1.3,1.4C644.6,632,644.8,632.1,644.9,632.1z M664.7,610.2c0.5,0,0.9-0.1,1.3-0.3c0.6-0.2,1-0.6,1.1-1.2
			c0.1-0.5,0-0.9-0.4-1.3c-0.2-0.2-0.5-0.3-0.8-0.4c-0.3-0.1-0.6-0.2-0.8-0.3c-0.2-0.1-0.5-0.1-0.7,0.1c-0.6,0.4-1.1,1-1.2,1.8
			c-0.1,0.8,0.3,1.5,1.1,1.6C664.5,610.2,664.6,610.2,664.7,610.2z M639.7,653.8c0.5,0,1-0.1,1.4-0.3c0.3-0.2,0.6-0.4,0.8-0.8
			c0.3-0.5,0.2-1.2-0.3-1.6c-0.2-0.2-0.5-0.3-0.8-0.2c-0.4,0.1-0.7,0.2-1.1,0.2c-0.2,0-0.4,0-0.6,0c-0.5,0-0.9,0.2-1.1,0.7
			c-0.1,0.1-0.1,0.3-0.1,0.4c-0.1,0.5,0.1,0.9,0.6,1.2C638.8,653.6,639.2,653.8,639.7,653.8z M634.1,632.3c0.4-0.1,0.7-0.1,1.1-0.2
			c0.5-0.1,0.8-0.5,0.7-1c0-0.3-0.1-0.5-0.2-0.8c-0.3-0.7-0.9-1.1-1.6-1.3c-0.4-0.1-0.8-0.1-1.1,0.2c-0.5,0.5-0.8,1.1-0.8,1.8
			c0,0.6,0.2,0.9,0.8,1.1C633.3,632.2,633.7,632.2,634.1,632.3z M623.7,629.7c-0.1,0-0.2,0.1-0.2,0.2c-0.2,0.2-0.4,0.2-0.7,0.1
			c-0.1,0-0.2-0.1-0.2-0.1c-0.5-0.1-1-0.3-1.5-0.2c-0.8,0.1-1.1,0.8-0.7,1.6c0.3,0.5,0.7,0.7,1.2,0.9c0.6,0.2,1.3,0.2,1.9,0.1
			c0.7-0.1,1.1-0.5,1.2-1.2C624.7,630.1,624.5,629.8,623.7,629.7z M626.5,631.1c0,0.9,0.5,1.4,1.4,1.3c0.4,0,0.8-0.2,1.1-0.4
			c0.6-0.4,1-0.9,1-1.6c0.1-0.6-0.2-1.1-0.8-1.2c-0.3-0.1-0.5-0.2-0.8-0.2c-0.2,0-0.4,0-0.5,0C627.1,629.4,626.5,630.2,626.5,631.1z
			 M651,632.1c0.7,0,1.2-0.1,1.8-0.5c0.6-0.4,0.7-1.3,0.3-1.9c-0.3-0.4-0.9-0.4-1.3-0.6c-0.2-0.1-0.4-0.1-0.6,0
			c-0.8,0.2-1.4,0.6-1.7,1.4c-0.2,0.4-0.1,0.8,0.2,1.1c0.2,0.2,0.5,0.4,0.8,0.5C650.6,632.1,650.8,632.1,651,632.1z M626.5,651.2
			c0,0-0.2,0-0.4,0c-0.4,0-0.8,0.2-1,0.5c-0.1,0.1-0.2,0.3-0.2,0.4c-0.4,0.8-0.1,1.5,0.8,1.7c0,0,0.1,0,0.1,0
			c0.9,0.1,1.7-0.1,2.4-0.6c0.2-0.2,0.4-0.4,0.5-0.6c0.3-0.5,0.1-0.9-0.3-1.2c-0.1-0.1-0.2-0.1-0.3-0.2
			C627.5,651.2,627.1,651.1,626.5,651.2z M603.6,652.6c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1,0,0.4,0.1,0.4c0.1,0,0.2,0,0.2,0
			c0.5,0,1,0,1.6-0.1c0.6-0.1,1.2-0.1,1.8-0.1c0.9,0,1.8,0,2.8,0c0.3,0,0.5,0,0.8,0c1.5,0.1,3,0.1,4.6,0.1c0.1,0,0.3,0,0.4,0
			c0.1,0,0.2-0.1,0.2-0.2c0.2-0.5,0.1-0.8-0.5-0.7c-0.1,0-0.2,0-0.2,0c-0.5,0.1-1,0.1-1.6,0.1C610.2,652.6,606.9,652.6,603.6,652.6z
			 M658.9,609.8c1.2,0,2.1-1,2-2.1c-0.1-0.6-0.5-1.1-1.2-1.1c-1,0-1.8,0.5-2.3,1.4c-0.3,0.6-0.2,1.2,0.4,1.5
			C658.2,609.7,658.6,609.8,658.9,609.8z M639.5,632c0.3-0.1,0.6-0.1,0.8-0.2c0.5-0.1,0.7-0.5,0.8-1c0.1-0.7-0.4-1.7-1.1-2
			c-0.4-0.2-0.8-0.2-1.1,0c-0.5,0.3-0.9,0.7-1.1,1.3c-0.3,0.8,0.1,1.5,0.9,1.7C639,631.9,639.2,632,639.5,632z M633,653.6
			C633,653.6,633,653.6,633,653.6c0.1,0,0.2,0,0.3,0c0.4,0,0.7,0,1-0.2c0.3-0.1,0.5-0.3,0.5-0.6c0.1-0.4,0-0.8-0.1-1.2
			c-0.3-0.6-0.8-0.9-1.5-0.7c-0.3,0.1-0.6,0.2-0.8,0.3c-0.5,0.1-0.8,0.4-1.1,0.8c-0.4,0.7-0.2,1.3,0.6,1.5
			C632.3,653.5,632.6,653.5,633,653.6z M670.6,610.2c0.3-0.1,0.5-0.1,0.8-0.2c1-0.3,1.5-0.9,1.6-2c0-0.5-0.3-0.9-0.8-1
			c-0.1,0-0.1,0-0.2,0c-0.5-0.2-0.9,0-1.3,0.3c-0.5,0.4-0.8,0.9-1,1.5c-0.1,0.2-0.1,0.5-0.1,0.7c0,0.3,0.2,0.5,0.4,0.6
			C670.2,610.1,670.4,610.1,670.6,610.2z M673.5,671.3C673.5,671.3,673.5,671.3,673.5,671.3c0.1,0,0.2,0,0.3,0
			c1-0.1,2.1-0.2,3.1-0.3c0.8-0.1,1.7-0.2,2.5-0.3c0.6-0.1,1.2-0.2,1.7-0.1c0.1,0,0.3,0,0.4,0c0.2,0,0.3-0.1,0.3-0.3
			c0-0.4-0.1-0.5-0.5-0.5c0,0-0.1,0-0.1,0c-0.8,0.2-1.6,0.2-2.4,0.3c-1,0.1-2,0.2-3.1,0.2c-0.9,0-1.7,0-2.6,0
			c-0.7,0-0.7,0.2-0.5,0.7c0.1,0.2,0.2,0.4,0.4,0.4C673.2,671.3,673.4,671.3,673.5,671.3z M655.8,631.2c0,0.1,0.1,0.3,0.1,0.5
			c0.2,0.6,0.6,0.8,1.1,0.7c0.6-0.2,1.2-0.5,1.6-1c0.5-0.7,0.4-1.2-0.3-1.7c-0.2-0.2-0.5-0.3-0.6-0.5c-0.2-0.2-0.4-0.2-0.7-0.1
			C656.3,629.5,655.9,630.2,655.8,631.2z M669.3,652.6c0.5,0,1-0.1,1.4-0.4c0.3-0.2,0.5-0.4,0.5-0.8c0-0.2,0-0.4,0-0.6
			c0-0.4-0.1-0.6-0.6-0.7c-0.4-0.1-0.8-0.1-1.2-0.2c-0.3,0-0.5,0-0.7,0.2c-0.2,0.2-0.4,0.5-0.6,0.8c-0.2,0.2-0.2,0.5-0.2,0.8
			c0,0.4,0.2,0.7,0.6,0.8C668.8,652.5,669.1,652.5,669.3,652.6z M644.5,671.9c1.7,0,2.8,0,3.9,0c0.5,0,1-0.1,1.6-0.1
			c0.2,0,0.3-0.1,0.3-0.2c0.1-0.4,0-0.6-0.4-0.6c-0.2,0-0.4,0-0.6,0c-0.5,0-1.1,0.1-1.6,0.1c-1.1,0.1-2.3,0.1-3.4,0.2
			c-1,0-2,0-2.9,0c-0.1,0-0.2,0-0.2,0c-0.1,0-0.1,0.1-0.1,0.1c-0.1,0.2-0.1,0.3,0,0.5c0.1,0.1,0.2,0.1,0.3,0.1
			C642.6,672,643.8,672,644.5,671.9z M645.5,609.2C645.5,609.2,645.5,609.2,645.5,609.2c0.8,0,1.6,0,2.4,0c0.2,0,0.3,0,0.5,0
			c0.3,0,0.4-0.1,0.4-0.4c0-0.3-0.1-0.4-0.4-0.4c-0.8,0-1.6,0-2.4,0.1c-0.2,0-0.5,0-0.7,0c-0.7,0-1.4,0-2.1,0
			c-0.7,0-1.4-0.1-2.1-0.1c-0.4,0-0.8-0.1-1.2-0.1c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0.2,0,0.5,0.2,0.5
			c0.2,0,0.3,0,0.5,0c0.4,0,0.8,0,1.2,0.1c1.2,0.1,2.4,0.2,3.6,0.2C645,609.2,645.3,609.2,645.5,609.2z M659.6,652.7
			c0.9-0.1,1.6-0.4,1.9-1.3c0.2-0.7,0-1-0.7-1.1c0,0,0,0-0.1,0c-0.4,0-0.7,0-1-0.2c-0.1-0.1-0.3-0.1-0.4,0c-0.3,0.1-0.6,0.2-0.8,0.4
			c-0.2,0.2-0.3,0.4-0.1,0.6c0.1,0.2,0.2,0.4,0.2,0.6c0,0.4,0,0.5,0.4,0.7C659.2,652.7,659.4,652.7,659.6,652.7z M645.2,653
			c0.7,0,1.3-0.2,1.7-0.6c0.3-0.3,0.5-0.6,0.3-1c-0.1-0.4-0.4-0.8-0.9-0.8c-0.4,0-0.8,0-1.1,0.2c-0.4,0.2-0.7,0.5-0.9,0.8
			c-0.4,0.5-0.3,1,0.4,1.2C644.9,653,645.1,653,645.2,653z M663.4,651.2c0,0.6,0.2,0.9,0.8,0.9c0.4,0,0.7,0,1.1-0.2
			c0.6-0.3,0.8-0.9,0.6-1.5c-0.2-0.5-0.9-0.9-1.4-0.7C663.7,649.9,663.4,650.5,663.4,651.2z M673,650.9c0,0.2,0,0.3,0.1,0.5
			c0,0.2,0.2,0.4,0.4,0.5c0.6,0.3,1.2,0.4,1.8,0.2c0.2-0.1,0.3-0.2,0.3-0.5c0-0.2,0-0.5,0-0.7c0-0.3-0.1-0.5-0.3-0.6
			c-0.2-0.1-0.5-0.2-0.7-0.3c-0.3-0.2-0.6-0.1-0.9,0.1c-0.1,0.1-0.2,0.2-0.3,0.3C673.1,650.3,673,650.6,673,650.9z M677.6,651.2
			c0,0.1,0,0.2,0,0.2c0,0.4,0,0.4,0.4,0.5c0.7,0.2,1.2,0,1.8-0.3c0.2-0.1,0.3-0.3,0.3-0.6c0-0.6-0.2-1-0.6-1.4
			c-0.1-0.1-0.3-0.2-0.5-0.1c-0.4,0.1-0.8,0.3-1.1,0.6c-0.2,0.2-0.3,0.4-0.2,0.6C677.6,650.9,677.6,651.1,677.6,651.2z M649.3,651.5
			c0.2,0.2,0.4,0,0.6,0c0.2,0,0.4-0.1,0.7-0.1c0.2,0,0.4,0,0.6,0C650.6,651.2,650,651.3,649.3,651.5z M656.2,651
			c-0.3-0.1-0.5,0-0.8,0C655.6,651,655.9,651,656.2,651z"/>
                            <path className="st14" d="M694.9,659.1c0,3,0,5,0,7c0,2.2-0.1,4.5-0.2,6.7c-0.1,1.3-0.1,2.7-0.1,4c0,1-0.1,2-0.2,2.9
			c0,0.2,0,0.5,0,0.8c0,0.9,0,1.7,0,2.6c0,0.4,0,0.8-0.1,1.2c-0.1,0.4-0.2,0.5-0.6,0.5c-1.3,0.1-2.6,0.1-3.9,0.1
			c-1.4,0-2.8-0.1-4.2-0.3c0,0-0.1,0-0.1,0c-0.5-0.1-0.5-0.1-0.6-0.6c-0.1-1.1-0.2-2.1-0.4-3.2c0,0,0,0,0-0.1c0-1.2-0.3-2.4-0.4-3.6
			c-0.1-1.1-0.2-2.3-0.3-3.4c0-0.6-0.1-1.1-0.2-1.7c-0.1-0.7-0.1-1.4-0.2-2.1c-0.1-1.4-0.2-2.9-0.4-4.3c-0.1-0.7-0.1-1.4-0.1-2.1
			c-0.1-1.5-0.2-3-0.3-4.4c-0.1-1.4-0.2-2.7-0.2-4.1c-0.1-1.7-0.1-3.3-0.3-5c-0.1-1-0.1-2-0.1-2.9c0-1.1-0.1-2.1-0.2-3.2
			c0-0.3,0-0.5,0-0.8c0-1.7,0-3.3-0.2-5c-0.1-1.6-0.1-3.3-0.1-4.9c0-1.5-0.1-3-0.1-4.5c-0.1-1.1-0.1-2.2-0.1-3.3
			c0-6.6,0-13.2,0-19.9c0-1.7,0.1-3.4,0.2-5.1c0-1,0-2,0.1-2.9c0-1,0.1-2.1,0.2-3.1c0-0.7,0-1.3,0.1-2c0-1,0.1-1.9,0.2-2.9
			c0-0.4,0.1-0.8,0.1-1.2c0.1-1.1,0.1-2.2,0.2-3.2c0-0.7,0.1-1.4,0.2-2.1c0.1-0.7,0.1-1.5,0.2-2.2c0.1-0.5,0.1-1.1,0.2-1.6
			c0.1-1.2,0.3-2.4,0.4-3.6c0.3-1.8,0.6-3.6,1.1-5.3c0.2-0.6,0.4-1.2,0.6-1.7c0.1-0.3,0.3-0.6,0.5-0.8c0.2-0.3,0.5-0.3,0.7-0.1
			c0.2,0.2,0.3,0.4,0.4,0.6c0.4,0.7,0.7,1.3,0.9,2.1c0.6,1.9,1.1,3.8,1.5,5.7c0.1,0.6,0.3,1.3,0.4,1.9c0.2,1,0.3,2.1,0.5,3.1
			c0.1,1,0.3,2,0.4,3c0.1,1,0.3,2,0.4,3c0.1,0.9,0.2,1.7,0.4,2.6c0.2,1.4,0.3,2.9,0.5,4.3c0.1,1,0.2,2.1,0.4,3.1
			c0.1,1.1,0.2,2.2,0.3,3.2c0,0.2,0.1,0.5,0.1,0.7c0.1,1.1,0.2,2.2,0.3,3.3c0.1,1.3,0.2,2.5,0.3,3.8c0,0.2,0,0.4,0,0.6
			c0.1,0.9,0.1,1.7,0.2,2.6c0,0.2,0,0.3,0,0.5c0,1.1,0.1,2.2,0.2,3.3c0,0.1,0,0.3,0,0.4c0,1.1,0.1,2.3,0.2,3.4c0,0.1,0,0.2,0,0.4
			c0,1.1,0.1,2.1,0.2,3.2c0.1,0.9,0.1,1.9,0.1,2.8c0,0.9,0.1,1.8,0.2,2.7c0,0.1,0,0.2,0,0.3c0,1.4,0.1,2.8,0.2,4.1
			c0.1,1.3,0.1,2.6,0.1,3.9c0,0.9,0,1.8,0.1,2.6c0.1,2.1,0.1,4.1,0.2,6.2c0.1,2.1,0,4.2,0.1,6.3C695,654.2,694.9,657.1,694.9,659.1z
			"/>
                            <path className="st14" d="M601.1,650.6c0,1.9,0,3.3,0,4.7c-0.1,3.2,0,6.5-0.1,9.7c0,1.3-0.1,2.7-0.2,4c0,0.4,0,0.8,0,1.1
			c0,0.6,0,1.3,0,1.9c0,1.2-0.1,2.4-0.2,3.6c0,0.6,0,1.3-0.1,1.9c0,0.8-0.1,1.6-0.2,2.4c0,0.3-0.1,0.6-0.1,0.9c0,1-0.1,2-0.2,3.1
			c-0.1,0.5-0.1,1-0.1,1.6c0,0.2,0,0.4,0,0.6c0,0.2,0.1,0.5,0,0.7c0,0.4-0.1,0.5-0.5,0.5c-0.2,0-0.3,0-0.5,0c-1,0-2,0-3.1,0
			c-0.6,0-1.1,0.1-1.7,0.1c-0.2,0-0.3,0-0.5,0c-0.4,0-0.5-0.1-0.6-0.5c-0.1-0.4-0.1-0.8-0.1-1.2c-0.2-1.5-0.4-3.1-0.5-4.6
			c-0.1-0.6-0.1-1.3-0.2-1.9c-0.1-1.1-0.2-2.2-0.3-3.4c0-0.4-0.1-0.8-0.1-1.2c-0.1-1.2-0.2-2.5-0.3-3.7c-0.1-0.9-0.1-1.7-0.2-2.6
			c-0.1-0.7-0.1-1.4-0.2-2.1c0-0.2,0-0.4,0-0.6c-0.1-0.9-0.1-1.9-0.2-2.8c0-0.1,0-0.2,0-0.4c0-1.1-0.1-2.3-0.2-3.4
			c0-0.2,0-0.3,0-0.5c0-1.1-0.1-2.2-0.2-3.3c0-0.1,0-0.2,0-0.3c0-1.1-0.1-2.3-0.1-3.4c-0.1-1.3-0.2-2.6-0.2-3.9
			c-0.1-1.4-0.1-2.8-0.2-4.3c0-1.2,0-2.5-0.1-3.7c-0.1-1.5-0.1-3-0.1-4.4c-0.1-2-0.1-4.1-0.2-6.1c-0.1-1.5-0.1-3-0.1-4.6
			c0-3.6,0-7.1,0-10.6c0-2.8,0-5.6,0.1-8.3c0.1-1.7,0.1-3.4,0.1-5.1c0-1.2,0.1-2.5,0.2-3.7c0-0.2,0-0.3,0-0.5c0-1.2,0.1-2.4,0.1-3.6
			c0.1-1.2,0.1-2.5,0.2-3.7c0.1-1.6,0.3-3.2,0.5-4.9c0.1-1,0.2-1.9,0.3-2.9c0.1-0.9,0.2-1.7,0.4-2.6c0.2-1.2,0.4-2.3,0.6-3.5
			c0.2-1.1,0.5-2.2,0.9-3.2c0.1-0.2,0.2-0.4,0.3-0.5c0.1-0.2,0.4-0.2,0.5,0c0.2,0.2,0.3,0.4,0.4,0.6c0.4,0.9,0.8,1.9,1.1,2.9
			c0.6,2,1,4,1.3,6c0.2,1.4,0.5,2.8,0.7,4.1c0.1,0.9,0.2,1.8,0.3,2.7c0.1,0.8,0.2,1.7,0.3,2.5c0.1,1.1,0.2,2.3,0.3,3.4
			c0,0.2,0,0.4,0.1,0.6c0.1,0.6,0.1,1.1,0.2,1.7c0,0.2,0,0.5,0.1,0.7c0.1,0.6,0.2,1.2,0.2,1.7c0,0.3,0.1,0.6,0.1,0.9
			c0.1,1.1,0.3,2.3,0.4,3.4c0.1,1.3,0.2,2.5,0.3,3.8c0.1,0.7,0.1,1.5,0.2,2.2c0,0.5,0.1,1.1,0.1,1.6c0.1,0.8,0.1,1.7,0.2,2.5
			c0,0.6,0.1,1.1,0.1,1.7c0.1,1.1,0.1,2.3,0.2,3.4c0.1,1.1,0.1,2.2,0.2,3.3c0,0.1,0,0.2,0,0.4c0.1,1.4,0.1,2.7,0.2,4.1
			c0.1,1.4,0.1,2.7,0.1,4.1c0,1,0,2,0.1,2.9c0.1,1.7,0,3.3,0.1,5c0.1,2.2,0.1,4.4,0.1,6.6C601.2,646.8,601.1,649,601.1,650.6z"/>
                            <path className="st14" d="M684.3,686.6c0.4-0.5,0.8-0.4,1.3-0.3c1,0.1,2,0.3,3,0.4c0.2,0,0.5,0,0.7,0c1.5,0,3,0,4.6,0
			c0.4,0,0.7,0,1.1-0.1c0.6-0.1,1-0.5,1.1-1.1c0-0.2,0.1-0.5,0.1-0.7c0-0.7,0.1-1.5,0.1-2.2c0-0.4,0-0.7,0-1.1c0-0.1,0-0.3,0.1-0.4
			c0.2-0.1,0.2,0,0.3,0.1c1.1,1,2.1,1.9,3.1,2.9c0.7,0.6,1.4,1.1,2.2,1.6c0.9,0.5,1.8,1.1,2.7,1.6c0.2,0.1,0.4,0.2,0.4,0.5
			c-0.1,0-0.1,0.1-0.2,0.1c-0.5,0.1-1,0.3-1.5,0.4c-1.2,0.3-2.5,0.6-3.7,0.9c-1.5,0.3-3,0.5-4.5,0.8c-1,0.2-2.1,0.4-3.1,0.6
			c-1.9,0.4-3.8,0.7-5.8,0.9c-0.2,0-0.4,0-0.6,0c-2.3,0-4.5,0-6.8,0c-1.4,0-2.8-0.1-4.3-0.1c-1.5,0-3.1,0.1-4.6,0.1
			c-0.5,0-1,0.1-1.5,0.1c-0.6,0-1.3,0.1-1.9,0.1c-0.6,0-1.2,0.1-1.7,0.2c-0.4,0-0.7,0.1-1.1,0.1c-0.7,0.1-1.4,0.1-2.1,0.2
			c-0.2,0-0.5,0.1-0.7,0.1c-1.1,0-2.1,0.1-3.2,0.2c-0.1,0-0.2,0-0.2,0c-1.1,0-2.1,0.1-3.2,0.2c-1.8,0.1-3.5,0.1-5.3,0.1
			c-2.4,0.1-4.7,0-7.1,0.1c-5.9,0.2-11.8,0.1-17.7,0.1c-1.2,0-2.3,0-3.5,0c-3.1,0-6.2,0-9.3-0.2c-2.3-0.1-4.6,0-6.8-0.1
			c-1.6,0-3.2-0.1-4.8-0.2c-0.6,0-1.3,0-1.9,0c-1,0-1.9-0.1-2.9-0.2c-0.1,0-0.2,0-0.3,0c-1-0.1-2-0.1-3-0.2
			c-1.9-0.2-3.8-0.1-5.8-0.1c-1.2,0-2.4-0.1-3.6-0.2c-0.8,0-1.7-0.1-2.5-0.3c-0.2,0-0.4-0.1-0.5-0.1c-0.2-0.1-0.3-0.3-0.2-0.5
			c0.2-0.2,0.4-0.5,0.7-0.7c0.4-0.3,0.9-0.7,1.3-1c1.9-1.4,3.7-2.9,5.5-4.4c1.1-0.9,2.2-1.8,3.3-2.8c0.1-0.1,0.1-0.1,0.2-0.2
			c0.4-0.3,0.5-0.2,0.6,0.2c0,0.2,0.1,0.4,0.1,0.6c0.2,1.7,0.5,3.4,0.8,5.1c0,0.1,0,0.1,0,0.2c0.2,1,0.5,1.1,1.3,1.2
			c0.2,0,0.3,0,0.5,0c1.9,0,3.8,0,5.6-0.2c0.5,0,0.9,0,1.4,0c0.2,0,0.5-0.1,0.7-0.1c0.3-0.1,0.5-0.3,0.6-0.7
			c0.1-0.4,0.2-0.8,0.2-1.2c0.1-0.8,0.1-1.6,0.2-2.4c0-0.3,0-0.6,0.1-0.9c0-0.3,0-0.6,0-0.9c0-0.6,0-1.2,0-1.8c0,0,0-0.1,0-0.1
			c0-0.2,0.1-0.3,0.3-0.3c0.6,0,1.1,0.1,1.7,0.2c0.8,0.1,1.5,0.2,2.3,0.4c0.6,0.1,1.1,0.2,1.7,0.3c1.5,0.2,3,0.4,4.5,0.6
			c0.8,0.1,1.7,0.2,2.5,0.3c0.5,0.1,1,0.1,1.5,0.1c1.1,0.1,2.2,0.1,3.3,0.2c0.4,0,0.9,0,1.3,0c1.8,0,3.7,0.1,5.5,0.1
			c0.7,0,1.5,0.1,2.2,0.1c1.3,0,2.6-0.1,3.9-0.2c0.1,0,0.2,0,0.2,0c1.2,0,2.3-0.1,3.5-0.1c5.8-0.3,11.7-0.1,17.5-0.1
			c2.7,0,5.4,0,8.1,0c1.5,0,3-0.1,4.5-0.2c0.6,0,1.1,0,1.7,0c1.5,0,3,0,4.6-0.1c1.2-0.1,2.3-0.2,3.5-0.3c1.6-0.2,3.1-0.4,4.6-0.7
			c0.3,0,0.6-0.1,0.9-0.2c0.2,0,0.4,0,0.4,0.3c0.1,0.4,0.1,0.7,0.1,1.1c0.1,1,0.2,2,0.3,3c0,0.5,0.3,1,0.7,1.2
			C684.1,686.4,684.2,686.5,684.3,686.6z"/>
                            <path className="st14" d="M655.5,697.8c-1.6,0-3.3,0-4.9,0c-2.9-0.1-5.9,0-8.8-0.1c-1.7-0.1-3.3,0-5-0.1c-1.5-0.1-3-0.1-4.6-0.1
			c-2.7-0.1-5.4-0.1-8.1-0.1c-0.8,0-1.6,0.1-2.4,0.2c-0.1,0-0.2,0-0.3,0c-1.1,0-2.2,0.1-3.3,0.1c-5.8,0-11.6,0-17.4,0c-1,0-2,0-3,0
			c-2-0.1-4.1-0.1-6.1-0.1c-0.9,0-1.9-0.1-2.8-0.2c-1-0.1-2-0.2-3-0.3c-1-0.1-1.9-0.2-2.9-0.4c-1-0.1-1.9-0.3-2.9-0.4
			c-0.2,0-0.5-0.1-0.7-0.1c-0.2,0-0.3-0.2-0.4-0.4c0-0.2-0.1-0.4,0-0.6c0-0.5,0-1,0.1-1.6c0-0.1,0-0.3,0-0.4c0-0.2,0.1-0.3,0.3-0.2
			c0.1,0,0.2,0,0.4,0.1c1.6,0.3,3.2,0.5,4.8,0.5c1.4,0,2.8,0,4.2,0.1c1.2,0.1,2.4,0.1,3.6,0.1c0.8,0,1.5,0.1,2.3,0.2
			c0.3,0,0.6,0,0.9,0.1c1.1,0.1,2.2,0.1,3.3,0.2c0.2,0,0.5,0,0.7,0c2.3,0.1,4.6,0.1,6.8,0.2c2,0.1,4.1,0.1,6.1,0.1
			c1.5,0,2.9,0,4.4,0.1c1.9,0.1,3.9,0.1,5.8,0.1c1.5,0,3.1,0,4.6,0c2.8,0,5.5,0.1,8.3,0.1c3.7-0.1,7.4,0,11-0.1
			c1.4,0,2.7-0.1,4.1-0.2c0.8,0,1.6,0,2.4,0c1,0,2-0.1,2.9-0.2c0.2,0,0.4,0,0.6,0c1.1,0,2.2-0.1,3.2-0.2c0.1,0,0.3,0,0.4,0
			c0.7,0,1.3-0.1,2-0.1c1.3-0.1,2.7-0.3,4-0.3c1.7-0.1,3.5-0.3,5.2-0.3c1.5,0,3-0.1,4.4-0.2c0.9,0,1.8,0,2.6,0c0.2,0,0.4,0,0.6,0
			c1.5,0,3,0,4.5,0c2.3,0,4.6-0.3,6.9-0.7c2.3-0.4,4.6-0.8,6.8-1.2c1.9-0.3,3.9-0.7,5.7-1.3c0.7-0.2,1.4-0.4,2.2-0.6
			c0.1,0,0.1,0,0.2,0c0.2,0,0.3,0.1,0.3,0.2c0,0.6-0.1,1.3-0.1,1.9c0,0.4-0.1,0.7-0.1,1.1c0,0.3-0.1,0.6-0.2,0.9
			c-0.1,0.3-0.2,0.5-0.5,0.5c-0.7,0.2-1.4,0.3-2.1,0.5c-0.4,0.1-0.9,0.1-1.3,0.1c-1.8,0.2-3.6,0.4-5.4,0.6c-1,0.1-2,0.2-3,0.3
			c-1.1,0.1-2.2,0.3-3.2,0.4c-0.7,0.1-1.5,0.1-2.2,0.2c-1.4,0.2-2.8,0.1-4.1,0.3c-0.7,0.1-1.4,0.1-2.1,0.2c-1.4,0.1-2.9,0.2-4.3,0.4
			c-0.2,0-0.4,0-0.6,0c-0.5,0-1,0-1.6,0c-1.8,0-3.6,0-5.4,0.1c-1.5,0.1-3,0.1-4.6,0.3c-0.7,0.1-1.4,0.1-2.1,0.2
			C660.3,697.9,657.9,697.7,655.5,697.8z"/>
                            <path className="st15" d="M636.2,589.9c0.2,0,0.3,0,0.4,0c0.8,0.1,1.7,0.1,2.5,0.2c1.8,0.2,3.6,0.3,5.4,0.3c1.6-0.1,3.1-0.1,4.7-0.3
			c1-0.1,2-0.2,3.1-0.3c0.7-0.1,1.4-0.2,2-0.3c1.4-0.2,2.9-0.4,4.3-0.7c0.5-0.1,1.1-0.1,1.6-0.2c0.9-0.1,1.8-0.1,2.7-0.2
			c1.9-0.1,3.9,0,5.8-0.1c1.9-0.1,3.9-0.1,5.8-0.1c1.2,0,2.3-0.1,3.5-0.2c0.6,0,1.3-0.1,1.9-0.1c0.3,0,0.4,0.1,0.4,0.4
			c0,0.1,0,0.2,0,0.2c0,0.2-0.1,0.3-0.3,0.3c-0.1,0-0.2,0-0.4,0c-0.3,0-0.5,0-0.8,0c-1.1,0-2.2,0.1-3.3,0.1c-1,0-2,0.1-2.9,0.2
			c-0.6,0.1-1.1,0-1.7,0.1c-0.9,0.1-1.7,0.1-2.6,0.2c-0.2,0-0.5,0-0.7,0c-0.4,0-0.9,0.1-1.3,0.2c-0.9,0.1-1.9,0.2-2.9,0.3
			c-0.3,0-0.5,0-0.8,0.1c-0.5,0.1-1,0.1-1.5,0.2c-0.1,0-0.3,0-0.4,0.1c-0.4,0.1-0.8,0.2-1.2,0.2c0,0,0,0-0.1,0
			c-0.8,0.3-1.7,0.2-2.5,0.4c-0.4,0.1-0.8,0.1-1.2,0.1c-0.8,0-1.7,0.1-2.5,0.2c-0.4,0-0.7,0.1-1.1,0.1c-3.2,0-6.4,0-9.6,0
			c-0.5,0-1,0-1.6-0.1c-0.3-0.1-0.7-0.1-1-0.1c-0.9,0-1.8,0-2.7,0c-0.3,0-0.7-0.1-1-0.1c-0.2,0-0.3-0.1-0.3-0.4
			C636.1,590.4,636.2,590.2,636.2,589.9z"/>
                            <path className="st21" d="M615.2,590.4c0-0.6,0.1-1.2,0.4-1.8c0.2-0.4,0.5-0.8,1-1.1c0.2-0.2,0.5-0.2,0.8-0.2c0.5,0.1,1,0.3,1.5,0.4
			c0.5,0.2,0.8,0.5,1,0.9c0.4,0.8,0.6,1.5,0.4,2.4c-0.4,1.3-1.6,2.1-2.9,1.8c-0.4-0.1-0.7-0.2-1-0.4c-0.7-0.4-1-1-1-1.7
			C615.2,590.6,615.2,590.5,615.2,590.4z"/>
                            <path className="st14" d="M621.6,674.6c-0.8,0.1-1.5-0.3-2.2-0.8c-0.4-0.3-0.6-0.7-0.6-1.2c-0.1-0.8,0.2-1.5,0.8-2
			c0.2-0.2,0.4-0.4,0.6-0.6c0.3-0.3,0.7-0.4,1.2-0.4c0.6,0,1.2,0.2,1.8,0.5c0.7,0.5,1,1.1,1.1,1.9c0,0.2,0,0.5,0,0.7
			c0,0.5-0.3,0.9-0.6,1.2c-0.5,0.4-1,0.6-1.6,0.6C621.9,674.6,621.8,674.6,621.6,674.6z"/>
                            <path className="st21" d="M634.4,589.8c0,0.3-0.1,0.7-0.1,1.1c-0.1,0.7-0.5,1.2-1.2,1.5c-0.6,0.3-1.2,0.4-1.9,0.3
			c-0.8-0.1-1.3-0.5-1.6-1.3c-0.2-0.7-0.2-1.3,0-2c0.3-0.7,0.7-1.3,1.3-1.8c0.1-0.1,0.3-0.2,0.4-0.2c0.4,0,0.8,0,1.2,0
			c0.3,0,0.6,0.1,0.9,0.2c0.5,0.1,0.8,0.4,0.9,0.9C634.4,589,634.4,589.4,634.4,589.8z"/>
                            <path className="st21" d="M613.9,674.8c-0.7-0.1-1.5-0.3-2.3-0.8c-0.2-0.1-0.4-0.3-0.5-0.5c-0.2-0.3-0.2-0.6-0.1-0.9
			c0.1-0.3,0.3-0.6,0.5-0.8c0.3-0.5,0.8-0.8,1.4-0.8c0.4,0,0.7-0.1,1.1-0.2c0.6-0.2,1.2-0.3,1.9-0.1c0.5,0.2,0.9,0.5,1.1,1
			c0.3,0.8,0.2,1.5-0.5,2.2C616,674.5,614.9,674.8,613.9,674.8z"/>
                            <path className="st13" d="M610.3,592.5c-0.3,0-0.6-0.1-1-0.2c-0.3-0.1-0.5-0.2-0.8-0.4c-0.5-0.3-0.8-0.8-0.8-1.5
			c0.1-1.2,0.6-2.1,1.6-2.9c0.2-0.2,0.5-0.2,0.8-0.1c0.3,0.2,0.7,0.2,1,0.3c1.2,0.2,1.5,0.6,1.6,1.6c0.1,0.4,0.1,0.9,0.1,1.3
			c-0.1,1.1-0.9,1.6-1.6,1.7C610.9,592.5,610.7,592.5,610.3,592.5z"/>
                            <path className="st15" d="M605.8,631.2c0.3,0,0.6,0,0.9,0c1.6,0,3.2-0.1,4.7-0.3c1.2-0.1,2.5-0.2,3.7-0.2c0.7,0,1.3-0.1,2-0.1
			c0.3,0,0.5,0,0.8,0c0.2,0,0.3,0.1,0.4,0.3c0,0.1,0.1,0.2,0.1,0.3c0.1,0.3,0,0.4-0.3,0.5c-0.2,0.1-0.4,0.1-0.7,0.1
			c-1.6,0.1-3.1,0.3-4.7,0.3c-0.7,0-1.4,0.1-2.1,0.1c-0.7,0-1.4,0.1-2.1,0.1c-0.4,0-0.8,0-1.3,0c-0.8,0-1.7,0.1-2.5,0.2
			c-0.5,0.1-1,0.1-1.6,0.1c-0.3,0-0.4-0.1-0.5-0.4c0,0,0-0.1,0-0.1c0-0.7,0-0.8,0.8-0.8C604.3,631.2,605,631.2,605.8,631.2
			C605.8,631.2,605.8,631.2,605.8,631.2z"/>
                            <path className="st13" d="M654.9,673.9c-0.9,0-1.6-0.2-2.2-0.6c-0.3-0.2-0.5-0.4-0.4-0.8c0-0.2,0-0.4-0.2-0.6c-0.2-0.3-0.2-0.5,0-0.8
			c0.3-0.6,0.8-1,1.4-1.3c0.2-0.1,0.3-0.1,0.5,0c0.4,0.2,0.9,0.3,1.3,0.3c0.2,0,0.3,0,0.5,0c0.7,0,1.2,0.4,1.5,1
			c0.3,0.6,0.2,1.3-0.2,1.8c-0.4,0.5-0.8,0.7-1.4,0.9C655.5,673.9,655.1,673.9,654.9,673.9z"/>
                            <path className="st21" d="M622.9,590.1c0.1-1,0.4-1.9,1.2-2.7c0.6-0.5,1.3-0.7,2.1-0.5c0.7,0.2,1.1,0.6,1.2,1.3c0.1,0.5,0.1,1,0,1.5
			c-0.2,0.6-0.5,1-1,1.3c-0.6,0.3-1.2,0.6-1.9,0.7c-1.1,0.2-1.6-0.3-1.7-1.4C622.9,590.3,622.9,590.2,622.9,590.1z"/>
                            <path className="st21" d="M603.7,671.8c0.1,0,0.2,0,0.3,0c0.3,0,0.5-0.1,0.7-0.2c0.1,0,0.1-0.1,0.2-0.1c0.3,0,0.6-0.2,0.8-0.4
			c0.1-0.2,0.4-0.2,0.6-0.2c0.8,0.1,1.6,0.5,2.1,1.1c0.4,0.4,0.5,0.8,0.3,1.3c-0.4,0.9-1.1,1.5-2.1,1.6c-0.8,0.1-1.5,0-2.3-0.3
			c-0.4-0.2-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.3-1c0-0.1,0.1-0.2,0.1-0.3C603.5,672.4,603.6,672.1,603.7,671.8z"/>
                            <path className="st13" d="M631.8,672c0,0.4-0.1,0.8-0.4,1.2c-0.6,0.8-2.1,1.3-3.1,1c-0.5-0.2-1-0.3-1.4-0.7c-0.2-0.1-0.3-0.3-0.5-0.5
			c-0.2-0.3-0.2-0.6-0.1-0.8c0.1-0.2,0.3-0.5,0.4-0.7c0.5-0.6,1.1-0.9,1.7-1.2c0.2-0.1,0.4-0.1,0.7,0c0.4,0.1,0.8,0.1,1.2,0.2
			c0.2,0,0.4,0.1,0.5,0.2C631.5,670.9,631.8,671.3,631.8,672z"/>
                            <path className="st13" d="M602.8,592.4c-0.4,0-0.9-0.1-1.1-0.5c-0.1-0.2-0.2-0.4-0.3-0.7c-0.1-0.7-0.2-1.4,0-2.1
			c0.2-0.7,0.5-1.3,1-1.7c0.2-0.2,0.4-0.2,0.6-0.1c0.3,0.2,0.6,0.3,0.9,0.4c1,0.2,1.5,1.3,1.5,2c-0.1,0.7-0.2,1.3-0.6,1.8
			C604.2,592.1,603.6,592.4,602.8,592.4z"/>
                            <path className="st13" d="M662.6,672.9c-0.7-0.1-1.3-0.1-1.9-0.4c-0.1,0-0.2-0.1-0.2-0.1c-1-0.6-1.3-1.7-0.7-2.7
			c0-0.1,0.1-0.1,0.1-0.2c0.2-0.4,0.5-0.6,1-0.4c0.2,0.1,0.5,0.1,0.7,0c1.2-0.1,2.2,0.4,2.9,1.4c0.3,0.4,0.3,0.8,0.2,1.3
			c0,0.1-0.1,0.3-0.3,0.4c-0.3,0.2-0.5,0.3-0.8,0.4C663.4,672.9,663,672.9,662.6,672.9z"/>
                            <path className="st15" d="M679.7,629.5c0.1,0.3,0.1,0.6,0.1,0.9c0,0.2-0.1,0.3-0.3,0.3c-0.1,0-0.2,0-0.4,0c-1.3,0-2.6,0.1-3.9,0.1
			c-3.4,0-6.9,0-10.3,0c-1,0-2,0.1-3,0.2c-0.3,0-0.6,0-0.9,0c-0.1,0-0.2-0.1-0.2-0.2c-0.1-0.1,0-0.3,0.1-0.3
			c0.2-0.1,0.3-0.2,0.5-0.2c0.5-0.1,1-0.1,1.4-0.1c1,0,1.9-0.1,2.9-0.1c0.2,0,0.5,0,0.8,0c0.7,0.1,1.4,0.1,2.1,0c0.1,0,0.2,0,0.3,0
			c1.4,0,2.8-0.1,4.1-0.1c1-0.1,2.1-0.1,3.1-0.2c1.1-0.1,2.2-0.2,3.2-0.3C679.3,629.5,679.5,629.5,679.7,629.5z"/>
                            <path className="st13" d="M666.7,671.5c0-0.2,0-0.2,0-0.2c-0.1-0.5,0-0.9,0.3-1.2c0.3-0.4,0.7-0.7,1.2-0.9c0.5-0.1,1.1-0.1,1.5,0.4
			c0.1,0.1,0.1,0.1,0.2,0.2c0.9,0.5,1.2,1.3,1.1,2.3c0,0,0,0.1,0,0.1c0,0.7-0.4,1-0.9,1.2c-0.8,0.3-2.2,0.3-2.9-0.6
			c-0.3-0.3-0.4-0.6-0.4-1C666.7,671.7,666.7,671.5,666.7,671.5z"/>
                            <path className="st21" d="M628.7,606.4c0.2,0,0.2,0,0.3,0c0.9,0,1.5,0.4,1.8,1.3c0.4,1.2-0.1,2-1.2,2.5c-0.8,0.3-1.6,0.3-2.3,0
			c-0.9-0.4-1.3-1.2-0.9-2.1c0.3-0.8,0.9-1.3,1.7-1.6C628.3,606.4,628.6,606.4,628.7,606.4z"/>
                            <path className="st32" d="M635.1,610.3c-0.3,0-0.7,0-1-0.2c-0.9-0.5-1.3-1.5-0.8-2.5c0.4-0.7,1-1.2,1.8-1.4c0.1,0,0.2,0,0.3,0
			c0.4,0.1,0.7,0.3,1,0.6c0.4,0.4,0.6,0.8,0.8,1.3c0.2,0.7,0,1.3-0.6,1.7C636.2,610.2,635.8,610.3,635.1,610.3z"/>
                            <path className="st32" d="M652.4,610.3c-0.5,0-0.9-0.1-1.2-0.3c-0.6-0.3-0.8-0.8-0.7-1.4c0-0.3,0.1-0.6,0.3-0.9
			c0.2-0.3,0.4-0.5,0.6-0.8c0.4-0.5,0.9-0.6,1.4-0.5c0.3,0.1,0.7,0.2,1,0.3c0.2,0.1,0.4,0.3,0.6,0.5c0.5,0.7,0.5,2.2-0.8,2.8
			C653.2,610.2,652.8,610.3,652.4,610.3z"/>
                            <path className="st13" d="M636.8,673.5c-0.7,0-1.3-0.2-1.8-0.8c-0.4-0.4-0.5-0.9-0.3-1.5c0.1-0.2,0.1-0.3,0.2-0.4
			c0.2-0.4,0.5-0.6,1-0.6c0.3,0,0.6-0.1,0.9-0.1c0.8-0.1,1.5,0.1,2.1,0.7c0.5,0.5,0.5,1.1,0.1,1.6c-0.3,0.4-0.7,0.7-1.2,0.8
			C637.6,673.4,637.2,673.4,636.8,673.5z"/>
                            <path className="st21" d="M614.5,610c-0.3,0-0.7-0.1-1-0.1c-0.3-0.1-0.6-0.3-0.7-0.6c-0.1-0.2-0.1-0.5-0.2-0.7
			c-0.1-0.7,0.2-1.4,1.1-1.6c0.1,0,0.2,0,0.3,0c0.3,0,0.6-0.1,0.9-0.1c0.4,0,0.7-0.1,1.1-0.1c0.3,0,0.5,0.1,0.7,0.3
			c0.5,0.6,0.6,1.2,0.4,1.9c-0.1,0.3-0.3,0.4-0.5,0.6c-0.5,0.3-1,0.4-1.5,0.5C614.9,609.9,614.7,609.9,614.5,610
			C614.5,609.9,614.5,610,614.5,610z"/>
                            <path className="st13" d="M621.9,610.1c-0.6,0-1.2-0.1-1.8-0.4c-0.5-0.3-0.7-0.8-0.6-1.4c0.1-0.3,0.2-0.5,0.4-0.7
			c0.4-0.5,1-0.7,1.6-0.8c0.3,0,0.7,0,1,0c0.6,0,1,0.3,1.2,0.8c0.5,1.1,0,2.2-1.2,2.4C622.3,610.1,622.1,610.1,621.9,610.1z"/>
                            <path className="st14" d="M620.4,654.3c-0.6-0.1-1.3-0.1-1.9-0.3c-0.2-0.1-0.3-0.1-0.5-0.2c-0.2-0.1-0.4-0.4-0.4-0.7
			c0-0.4,0-0.7,0.2-1.1c0.2-0.4,0.5-0.7,0.9-0.6c0,0,0.1,0,0.1,0c1.1-0.3,2.1,0.2,3.1,0.6c0.1,0,0.2,0.1,0.3,0.2
			c0.6,0.4,0.6,1.1,0.1,1.5c-0.2,0.2-0.4,0.3-0.7,0.4c-0.2,0.1-0.4,0.1-0.6,0.1C620.8,654.2,620.6,654.3,620.4,654.3z"/>
                            <path className="st15" d="M611.2,609.9c-0.6,0.3-1.2,0.4-1.8,0.4c-0.5,0-1,0-1.6,0c-1.7,0-3.4-0.1-5.1-0.1c-0.2,0-0.5,0-0.7-0.1
			c-0.4-0.1-0.5-0.2-0.5-0.6c0-0.2-0.1-0.5,0.1-0.7c0.1,0,0.2,0,0.2,0c1.1,0.3,2.3,0.4,3.5,0.4c0.3,0,0.6,0,0.9,0
			c1,0,2.1-0.1,3.1-0.2c0.5,0,1,0,1.4-0.1c0.2,0,0.3,0.1,0.4,0.3C611.2,609.4,611.3,609.6,611.2,609.9z"/>
                            <path className="st13" d="M676.8,610.7c-0.7,0-1.4-0.2-1.8-0.7c-0.4-0.4-0.5-1.2-0.2-1.6c0.1-0.1,0.2-0.2,0.3-0.3
			c0.2-0.1,0.4-0.2,0.5-0.3c0.6-0.2,1.2-0.3,1.8-0.4c0.3,0,0.6,0.1,0.8,0.4c0.3,0.4,0.4,0.9,0.4,1.4c0,0.7-0.3,1.1-0.9,1.4
			c-0.2,0.1-0.3,0.1-0.5,0.1C677.1,610.6,676.9,610.7,676.8,610.7z"/>
                            <path className="st21" d="M644.9,632.1c-0.1,0-0.3,0-0.4-0.1c-0.6-0.3-1.1-0.7-1.3-1.4c-0.1-0.2-0.1-0.4,0-0.6
			c0.2-0.6,0.6-1.1,1.2-1.5c0.1-0.1,0.3-0.1,0.4-0.1c0.5,0.2,1.1,0.4,1.6,0.7c0.3,0.2,0.5,0.5,0.7,0.8c0.1,0.3,0.1,0.7,0,1
			C646.6,631.6,645.7,632.1,644.9,632.1z"/>
                            <path className="st13" d="M664.7,610.2c-0.1,0-0.2,0-0.3,0c-0.8-0.2-1.2-0.8-1.1-1.6c0.1-0.8,0.6-1.3,1.2-1.8c0.2-0.1,0.4-0.1,0.7-0.1
			c0.3,0.1,0.5,0.2,0.8,0.3c0.3,0.1,0.6,0.2,0.8,0.4c0.4,0.4,0.5,0.8,0.4,1.3c-0.2,0.6-0.6,1-1.1,1.2
			C665.6,610.1,665.2,610.2,664.7,610.2z"/>
                            <path className="st21" d="M639.7,653.8c-0.4,0-0.9-0.1-1.3-0.4c-0.5-0.3-0.7-0.7-0.6-1.2c0-0.1,0.1-0.3,0.1-0.4
			c0.2-0.5,0.6-0.7,1.1-0.7c0.2,0,0.4,0,0.6,0c0.4-0.1,0.7-0.1,1.1-0.2c0.3-0.1,0.5,0,0.8,0.2c0.5,0.4,0.6,1.1,0.3,1.6
			c-0.2,0.3-0.5,0.6-0.8,0.8C640.7,653.7,640.2,653.8,639.7,653.8z"/>
                            <path className="st21" d="M634.1,632.3c-0.4-0.1-0.8-0.1-1.1-0.2c-0.6-0.1-0.8-0.5-0.8-1.1c0-0.7,0.3-1.3,0.8-1.8
			c0.3-0.3,0.6-0.4,1.1-0.2c0.7,0.2,1.3,0.6,1.6,1.3c0.1,0.2,0.2,0.5,0.2,0.8c0.1,0.5-0.2,0.9-0.7,1
			C634.8,632.2,634.4,632.2,634.1,632.3z"/>
                            <path className="st14" d="M623.7,629.7c0.8,0.2,1,0.5,0.9,1.3c-0.1,0.7-0.5,1.1-1.2,1.2c-0.6,0.1-1.3,0.1-1.9-0.1
			c-0.5-0.2-0.9-0.4-1.2-0.9c-0.4-0.7-0.1-1.4,0.7-1.6c0.5-0.1,1,0.1,1.5,0.2c0.1,0,0.2,0.1,0.2,0.1c0.2,0.1,0.5,0.1,0.7-0.1
			C623.5,629.8,623.6,629.7,623.7,629.7z"/>
                            <path className="st14" d="M626.5,631.1c0-1,0.6-1.7,1.5-2.1c0.2-0.1,0.4-0.1,0.5,0c0.3,0,0.5,0.1,0.8,0.2c0.6,0.2,0.9,0.6,0.8,1.2
			c-0.1,0.7-0.5,1.2-1,1.6c-0.3,0.2-0.7,0.3-1.1,0.4C627,632.5,626.5,632,626.5,631.1z"/>
                            <path className="st21" d="M651,632.1c-0.2,0-0.4,0-0.6,0c-0.3,0-0.6-0.2-0.8-0.5c-0.3-0.3-0.3-0.7-0.2-1.1c0.3-0.8,0.9-1.2,1.7-1.4
			c0.2-0.1,0.4,0,0.6,0c0.4,0.2,0.9,0.2,1.3,0.6c0.5,0.6,0.4,1.4-0.3,1.9C652.2,632,651.6,632.2,651,632.1z"/>
                            <path className="st21" d="M626.5,651.2c0.6,0,1.1,0,1.5,0.2c0.1,0,0.2,0.1,0.3,0.2c0.5,0.3,0.6,0.7,0.3,1.2c-0.1,0.2-0.3,0.4-0.5,0.6
			c-0.7,0.6-1.5,0.7-2.4,0.6c0,0-0.1,0-0.1,0c-0.9-0.2-1.2-0.9-0.8-1.7c0.1-0.2,0.1-0.3,0.2-0.4c0.3-0.3,0.6-0.6,1-0.5
			C626.2,651.2,626.4,651.2,626.5,651.2z"/>
                            <path className="st15" d="M603.6,652.6c3.3,0,6.6,0,9.8,0c0.5,0,1-0.1,1.6-0.1c0.1,0,0.2,0,0.2,0c0.6-0.1,0.7,0.2,0.5,0.7
			c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2,0-0.4,0c-1.5,0-3,0-4.6-0.1c-0.3,0-0.5,0-0.8,0c-0.9,0-1.8,0-2.8,0c-0.6,0-1.2,0-1.8,0.1
			c-0.5,0.1-1,0-1.6,0.1c-0.1,0-0.2,0-0.2,0c-0.2,0-0.2-0.3-0.1-0.4C603.4,652.8,603.5,652.7,603.6,652.6z"/>
                            <path className="st32" d="M658.9,609.8c-0.3,0-0.7-0.1-1-0.3c-0.6-0.3-0.7-1-0.4-1.5c0.5-0.9,1.3-1.3,2.3-1.4c0.6,0,1.1,0.4,1.2,1.1
			C661.1,608.8,660.1,609.8,658.9,609.8z"/>
                            <path className="st21" d="M639.5,632c-0.3,0-0.5-0.1-0.7-0.1c-0.8-0.2-1.2-1-0.9-1.7c0.2-0.6,0.6-1,1.1-1.3c0.4-0.2,0.8-0.2,1.1,0
			c0.6,0.3,1.2,1.3,1.1,2c-0.1,0.5-0.3,0.8-0.8,1C640,631.9,639.8,632,639.5,632z"/>
                            <path className="st21" d="M633,653.6c-0.3,0-0.7-0.1-1-0.1c-0.8-0.2-1-0.8-0.6-1.5c0.2-0.4,0.6-0.7,1.1-0.8c0.3-0.1,0.6-0.2,0.8-0.3
			c0.7-0.2,1.2,0,1.5,0.7c0.2,0.4,0.2,0.8,0.1,1.2c-0.1,0.3-0.2,0.5-0.5,0.6c-0.3,0.1-0.7,0.2-1,0.2
			C633.2,653.5,633.1,653.5,633,653.6C633,653.6,633,653.6,633,653.6z"/>
                            <path className="st32" d="M670.6,610.2c-0.2,0-0.3-0.1-0.5-0.1c-0.3-0.1-0.4-0.3-0.4-0.6c0-0.2,0-0.5,0.1-0.7c0.2-0.6,0.5-1.1,1-1.5
			c0.4-0.3,0.8-0.5,1.3-0.3c0.1,0,0.1,0,0.2,0c0.5,0.1,0.8,0.5,0.8,1c-0.1,1-0.6,1.7-1.6,2C671.1,610.1,670.8,610.1,670.6,610.2z"/>
                            <path className="st15" d="M673.5,671.3c-0.1,0-0.3,0-0.4,0c-0.2,0-0.4-0.1-0.4-0.4c-0.2-0.5-0.2-0.7,0.5-0.7c0.9,0,1.7,0,2.6,0
			c1,0,2-0.1,3.1-0.2c0.8-0.1,1.6-0.1,2.4-0.3c0,0,0.1,0,0.1,0c0.4,0,0.5,0.2,0.5,0.5c0,0.2-0.1,0.3-0.3,0.3c-0.1,0-0.2,0-0.4,0
			c-0.6,0-1.2,0.1-1.7,0.1c-0.8,0.1-1.7,0.3-2.5,0.3c-1,0.1-2.1,0.2-3.1,0.3C673.7,671.3,673.6,671.3,673.5,671.3
			C673.5,671.3,673.5,671.3,673.5,671.3z"/>
                            <path className="st21" d="M655.8,631.2c0.1-0.9,0.4-1.7,1.2-2.2c0.3-0.1,0.4-0.2,0.7,0.1c0.2,0.2,0.4,0.3,0.6,0.5
			c0.7,0.5,0.7,1.1,0.3,1.7c-0.4,0.6-0.9,0.9-1.6,1c-0.6,0.1-1-0.1-1.1-0.7C655.9,631.5,655.9,631.3,655.8,631.2z"/>
                            <path className="st21" d="M669.3,652.6c-0.2,0-0.5,0-0.7-0.1c-0.4-0.1-0.6-0.4-0.6-0.8c0-0.3,0.1-0.5,0.2-0.8c0.2-0.3,0.4-0.5,0.6-0.8
			c0.2-0.2,0.4-0.3,0.7-0.2c0.4,0.1,0.8,0.1,1.2,0.2c0.4,0.1,0.5,0.2,0.6,0.7c0,0.2,0,0.4,0,0.6c0,0.4-0.2,0.6-0.5,0.8
			C670.3,652.5,669.8,652.6,669.3,652.6z"/>
                            <path className="st15" d="M644.5,671.9c-0.7,0-1.9,0-3.2,0.1c-0.1,0-0.3,0-0.3-0.1c-0.1-0.2-0.1-0.3,0-0.5c0,0,0.1-0.1,0.1-0.1
			c0.1,0,0.2,0,0.2,0c1,0,2,0.1,2.9,0c1.1,0,2.3,0,3.4-0.2c0.5-0.1,1.1-0.1,1.6-0.1c0.2,0,0.4,0,0.6,0c0.4,0,0.5,0.2,0.4,0.6
			c-0.1,0.2-0.2,0.2-0.3,0.2c-0.5,0-1,0.1-1.6,0.1C647.3,672,646.2,671.9,644.5,671.9z"/>
                            <path className="st15" d="M645.5,609.2c-0.3,0-0.5,0-0.8,0c-1.2,0-2.4-0.1-3.6-0.2c-0.4,0-0.8,0-1.2-0.1c-0.2,0-0.3,0-0.5,0
			c-0.2,0-0.3-0.3-0.2-0.5c0-0.1,0.1-0.1,0.2-0.1c0.1,0,0.3,0,0.4,0.1c0.4,0,0.8,0.1,1.2,0.1c0.7,0,1.4,0.1,2.1,0.1
			c0.7,0,1.4,0,2.1,0c0.2,0,0.5,0,0.7,0c0.8,0,1.6,0,2.4-0.1c0.3,0,0.4,0.1,0.4,0.4c0,0.3-0.1,0.4-0.4,0.4c-0.2,0-0.3,0-0.5,0
			C647.2,609.2,646.4,609.2,645.5,609.2C645.5,609.2,645.5,609.2,645.5,609.2z"/>
                            <path className="st21" d="M659.6,652.7c-0.2,0-0.4-0.1-0.7-0.2c-0.4-0.1-0.4-0.3-0.4-0.7c0-0.2,0-0.4-0.2-0.6
			c-0.2-0.3-0.2-0.4,0.1-0.6c0.2-0.2,0.5-0.3,0.8-0.4c0.1,0,0.3,0,0.4,0c0.3,0.2,0.7,0.2,1,0.2c0,0,0,0,0.1,0c0.7,0,1,0.4,0.7,1.1
			C661.2,652.3,660.5,652.7,659.6,652.7z"/>
                            <path className="st21" d="M645.2,653c-0.1,0-0.3,0-0.5-0.1c-0.6-0.2-0.8-0.7-0.4-1.2c0.3-0.3,0.6-0.6,0.9-0.8c0.4-0.2,0.7-0.3,1.1-0.2
			c0.5,0.1,0.8,0.4,0.9,0.8c0.1,0.4,0,0.7-0.3,1C646.5,652.9,646,653,645.2,653z"/>
                            <path className="st21" d="M663.4,651.2c0-0.8,0.4-1.3,1.1-1.5c0.5-0.2,1.3,0.2,1.4,0.7c0.2,0.6,0,1.3-0.6,1.5
			c-0.3,0.2-0.7,0.2-1.1,0.2C663.6,652.1,663.4,651.8,663.4,651.2z"/>
                            <path className="st21" d="M673,650.9c0-0.3,0.1-0.5,0.3-0.7c0.1-0.1,0.2-0.2,0.3-0.3c0.3-0.2,0.6-0.3,0.9-0.1c0.2,0.1,0.5,0.2,0.7,0.3
			c0.2,0.1,0.3,0.3,0.3,0.6c0,0.2,0,0.5,0,0.7c0,0.3-0.1,0.4-0.3,0.5c-0.6,0.2-1.2,0.1-1.8-0.2c-0.2-0.1-0.3-0.3-0.4-0.5
			C673,651.2,673,651,673,650.9z"/>
                            <path className="st21" d="M677.6,651.2c0-0.1,0-0.3-0.1-0.5c0-0.3,0-0.4,0.2-0.6c0.3-0.3,0.7-0.5,1.1-0.6c0.2-0.1,0.3,0,0.5,0.1
			c0.4,0.4,0.7,0.8,0.6,1.4c0,0.2-0.1,0.4-0.3,0.6c-0.5,0.4-1.1,0.5-1.8,0.3c-0.3-0.1-0.4-0.2-0.4-0.5
			C677.6,651.3,677.6,651.3,677.6,651.2z"/>
                            <path className="st15" d="M649.3,651.5c0.6-0.2,1.3-0.3,1.9-0.1c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4,0.1-0.7,0.1
			C649.8,651.5,649.5,651.7,649.3,651.5z"/>
                            <path className="st15" d="M656.2,651c-0.3,0.1-0.5,0.1-0.8,0C655.6,650.9,655.9,650.9,656.2,651z"/>
	</g>
                        <g className="tapetes white">
		<path d="M796,840.2c-6.1,0-12.2-0.4-18.3-1.2c-9.8-1.3-19.4-3.7-28.6-7.6c-5.5-2.3-10.7-5.2-15.5-8.8c-3.4-2.5-6.5-5.4-9.1-8.8
			c-1.8-2.3-3.3-4.7-4.5-7.4c-3.2-7.3-3.3-14.7-0.3-22.1c1.9-4.6,4.7-8.5,8.1-12.1c3.6-3.7,7.7-6.8,12-9.5c3.6-2.2,7.4-4.1,11.3-5.8
			c9.7-4.2,19.8-6.7,30.3-8.1c4.2-0.6,8.4-0.9,12.6-1.1c2.8-0.1,5.5-0.2,8.3-0.2c12,0.3,23.8,1.8,35.3,5.3c7.2,2.2,14,5.1,20.4,9
			c3.9,2.4,7.5,5.2,10.8,8.5c3.3,3.4,6,7.2,7.9,11.6c1.5,3.7,2.3,7.5,2.2,11.5c-0.1,3.9-1.1,7.6-2.7,11.2c-1.3,2.8-2.9,5.4-4.9,7.8
			c-2.4,3-5.1,5.6-8.1,8c-5.1,4.1-10.8,7.3-16.8,9.9c-7.4,3.3-15.1,5.6-23.1,7.2c-4.8,1-9.7,1.7-14.7,2.1
			C804.5,840,800.3,840.3,796,840.2z M800.3,749.2c-2.6,0.1-5.2,0-7.8,0.2c-5.2,0.3-10.3,0.9-15.4,1.7c-8,1.4-15.8,3.5-23.3,6.4
			c-4.9,1.9-9.6,4.3-14,7.1c-3.8,2.4-7.3,5.1-10.4,8.3c-3.2,3.3-5.9,6.8-7.8,11c-3.4,7.7-3.3,15.4,0.5,23c1.3,2.7,3.1,5.2,5.1,7.5
			c2.4,2.8,5.2,5.2,8.2,7.4c3.8,2.8,7.9,5.1,12.2,7c8.5,3.9,17.5,6.4,26.8,7.9c4.6,0.8,9.2,1.3,13.8,1.5c6.3,0.4,12.6,0.3,19-0.2
			c4.5-0.3,9-0.9,13.4-1.7c8.1-1.5,16-3.7,23.6-6.8c5.7-2.4,11.2-5.3,16.3-9c3.4-2.5,6.5-5.2,9.2-8.4c2.4-2.8,4.4-5.9,5.8-9.3
			c1.9-4.7,2.6-9.6,1.7-14.7c-0.6-3.5-1.9-6.7-3.8-9.7c-1.9-3.2-4.3-5.9-7-8.5c-3.7-3.5-7.9-6.3-12.4-8.7
			c-5.7-3.1-11.8-5.5-18.1-7.3C824.1,750.7,812.3,749.2,800.3,749.2z"/>
                            <path d="M579,859.3c-5.8,0-15.1-1-24.2-3.3c-6.9-1.7-13.5-4.2-19.7-7.6c-4.3-2.4-8.3-5.3-11.8-8.9c-2.7-2.7-4.9-5.8-6.6-9.2
			c-3.8-8.1-3.8-16.3-0.1-24.4c0.3-0.7,0.6-1.4,0.9-2.2c0.2-0.5,0.5-1.1,0.8-1.6c2.5-4.3,5.7-8.1,9.3-11.5c4.3-4,9-7.3,14.1-10.1
			c7.7-4.3,15.9-7.5,24.4-9.8c7.4-2,14.9-3.3,22.6-4c5.5-0.5,11.1-0.5,16.6-0.3c9.2,0.4,18.3,1.8,27.2,4.6
			c5.7,1.8,11.2,4.1,16.3,7.3c3.6,2.2,6.9,4.7,9.8,7.7c1.9,1.9,3.5,4,4.9,6.2c5.2,8.4,6,17.1,2.5,26.3c-1.6,4.3-4.1,8.2-7.1,11.7
			c-3.3,3.9-7.1,7.2-11.2,10.2c-6.2,4.4-12.8,7.9-19.9,10.6c-7.8,3-15.8,5.2-24,6.5C596.9,858.7,589.8,859.3,579,859.3z
			 M582.7,857.9c2.3-0.1,4.7-0.2,7-0.3c6.2-0.3,12.2-1.1,18.3-2.3c8.3-1.7,16.3-4.2,24.1-7.6c6.5-2.9,12.6-6.4,18.1-10.8
			c3.7-3,7.1-6.3,9.9-10.2c2.3-3.1,4.2-6.5,5.3-10.2c2.5-7.8,1.8-15.2-2.3-22.3c-1.5-2.6-3.4-5-5.5-7.1c-3.6-3.6-7.7-6.5-12.2-9
			c-5.8-3.1-11.9-5.3-18.2-6.9c-7.8-2-15.7-3-23.8-3.2c-4.9-0.1-9.8,0-14.6,0.4c-4.3,0.4-8.6,0.9-12.9,1.8c-8.4,1.6-16.6,4-24.5,7.4
			c-6.8,2.9-13.2,6.5-19,11.1c-4.3,3.4-8.2,7.3-11.3,11.9c-0.8,1.1-1.4,2.4-2.2,3.5c-0.9,1.2-1.4,2.7-1.9,4.1
			c-0.1,0.3-0.2,0.7-0.3,1c-1.4,4.7-1.8,9.4-0.9,14.3c0.7,3.5,2.1,6.8,4.1,9.8c2.1,3.2,4.7,6,7.6,8.4c3.1,2.7,6.6,4.8,10.2,6.7
			c5.1,2.6,10.5,4.6,16,6.1C563.3,856.7,572.9,857.8,582.7,857.9z"/>
                            <path className="st13" d="M800.3,749.2c12,0,23.8,1.5,35.3,4.8c6.3,1.8,12.3,4.2,18.1,7.3c4.5,2.4,8.6,5.3,12.4,8.7
			c2.7,2.5,5.1,5.3,7,8.5c1.8,3,3.1,6.2,3.8,9.7c0.9,5.1,0.3,10-1.7,14.7c-1.4,3.4-3.4,6.5-5.8,9.3c-2.7,3.2-5.8,6-9.2,8.4
			c-5,3.7-10.5,6.6-16.3,9c-7.6,3.2-15.5,5.3-23.6,6.8c-4.4,0.8-8.9,1.4-13.4,1.7c-6.3,0.4-12.6,0.5-19,0.2
			c-4.6-0.3-9.2-0.8-13.8-1.5c-9.3-1.5-18.2-4-26.8-7.9c-4.3-2-8.4-4.3-12.2-7c-3-2.2-5.7-4.6-8.2-7.4c-2-2.3-3.7-4.7-5.1-7.5
			c-3.7-7.6-3.9-15.3-0.5-23c1.8-4.2,4.6-7.8,7.8-11c3.1-3.2,6.7-5.9,10.4-8.3c4.4-2.8,9.1-5.2,14-7.1c7.6-2.9,15.3-5,23.3-6.4
			c5.1-0.9,10.3-1.5,15.4-1.7C795.1,749.3,797.7,749.3,800.3,749.2z M796.1,833.9c4.9,0,10.3-0.4,15.7-1c6.8-0.8,13.4-2.1,20-4.1
			c7-2.1,13.6-4.8,19.9-8.6c3.9-2.3,7.5-5,10.7-8.3c2.2-2.3,4.2-4.8,5.7-7.7c1.3-2.4,2.2-4.9,2.7-7.6c0.8-4.9,0-9.6-2.2-14
			c-1.5-3.1-3.5-5.7-5.9-8.2c-2.7-2.7-5.7-5-8.9-7.1c-4.2-2.7-8.6-4.8-13.3-6.5c-10.5-4-21.4-6-32.6-6.8c-7.9-0.5-15.9-0.4-23.8,0.5
			c-5.9,0.7-11.7,1.7-17.4,3.1c-7,1.8-13.7,4.2-20.1,7.5c-4.3,2.3-8.4,4.9-12.1,8.2c-0.4,0.4-0.9,0.8-1.3,1.3
			c-0.5,0.6-1,1.2-1.6,1.7c-2.9,2.9-5.2,6.2-6.7,10c-1.8,4.6-2.1,9.2-0.9,14c0.8,2.9,2.1,5.5,3.7,8c1.9,2.8,4.1,5.2,6.7,7.3
			c3.2,2.8,6.8,5,10.5,7c7.4,3.9,15.3,6.5,23.4,8.3C777.3,833,786.4,833.9,796.1,833.9z"/>
                            <path className="st13" d="M582.7,857.9c-9.7-0.1-19.4-1.2-28.8-3.7c-5.5-1.5-10.9-3.4-16-6.1c-3.7-1.9-7.1-4.1-10.2-6.7
			c-2.9-2.5-5.5-5.2-7.6-8.4c-2-3-3.4-6.3-4.1-9.8c-1-4.8-0.6-9.6,0.9-14.3c0.1-0.3,0.2-0.7,0.3-1c0.5-1.4,1-2.8,1.9-4.1
			c0.8-1.1,1.4-2.4,2.2-3.5c3.1-4.6,7-8.5,11.3-11.9c5.8-4.6,12.2-8.2,19-11.1c7.9-3.4,16.1-5.8,24.5-7.4c4.3-0.8,8.6-1.4,12.9-1.8
			c4.9-0.4,9.7-0.5,14.6-0.4c8,0.2,16,1.3,23.8,3.2c6.3,1.6,12.4,3.8,18.2,6.9c4.5,2.4,8.6,5.3,12.2,9c2.1,2.2,4,4.5,5.5,7.1
			c4,7.1,4.8,14.5,2.3,22.3c-1.2,3.7-3.1,7.1-5.3,10.2c-2.8,3.9-6.2,7.2-9.9,10.2c-5.5,4.4-11.6,7.9-18.1,10.8
			c-7.7,3.5-15.8,5.9-24.1,7.6c-6,1.2-12.1,2-18.3,2.3C587.4,857.7,585,857.8,582.7,857.9z M583.2,853.4c4.5-0.2,9.5-0.3,14.6-1
			c7.2-0.9,14.3-2.3,21.2-4.5c7.6-2.3,14.8-5.3,21.7-9.4c4.8-2.9,9.3-6.2,13.3-10.2c3.2-3.3,6-6.9,7.9-11.2c1.2-2.5,2-5.2,2.3-7.9
			c0.7-6-0.6-11.5-3.9-16.5c-1.5-2.4-3.3-4.4-5.3-6.4c-2.6-2.4-5.5-4.5-8.6-6.3c-5.4-3.1-11.1-5.3-17-6.9c-10-2.7-20.1-3.6-30.4-3.5
			c-1.3,0-2.5,0.1-3.8,0.2c-3.5,0.1-7,0.5-10.4,0.9c-1.6,0.2-3.1,0.5-4.6,0.9c-1.9,0.4-3.9,0.7-5.9,1.1c-8.5,1.8-16.7,4.5-24.6,8.3
			c-5.8,2.8-11.3,6.2-16.2,10.4c-3,2.6-5.7,5.4-8,8.6c-2,2.8-3.6,5.7-4.6,9c-1.1,3.4-1.5,6.8-1.2,10.3c0.4,4.1,1.9,7.8,4.1,11.3
			c2,3,4.4,5.6,7.2,7.8c2.8,2.3,5.8,4.2,8.9,5.8c4.7,2.4,9.6,4.2,14.7,5.6C563.8,852.4,573.2,853.3,583.2,853.4z"/>
                            <path d="M796.1,833.9c-9.7-0.1-18.8-0.9-27.8-2.9c-8.2-1.8-16-4.4-23.4-8.3c-3.7-2-7.3-4.2-10.5-7c-2.5-2.2-4.8-4.6-6.7-7.3
			c-1.7-2.5-3-5.1-3.7-8c-1.3-4.8-0.9-9.4,0.9-14c1.5-3.8,3.8-7.1,6.7-10c0.5-0.6,1-1.2,1.6-1.7c0.4-0.4,0.8-0.9,1.3-1.3
			c3.7-3.3,7.8-5.9,12.1-8.2c6.4-3.3,13.1-5.7,20.1-7.5c5.7-1.5,11.5-2.5,17.4-3.1c7.9-0.9,15.9-1,23.8-0.5
			c11.2,0.7,22.1,2.8,32.6,6.8c4.6,1.7,9.1,3.9,13.3,6.5c3.2,2,6.2,4.3,8.9,7.1c2.4,2.4,4.4,5.1,5.9,8.2c2.2,4.4,3,9.1,2.2,14
			c-0.5,2.7-1.4,5.2-2.7,7.6c-1.5,2.8-3.5,5.4-5.7,7.7c-3.2,3.3-6.8,6-10.7,8.3c-6.2,3.8-12.9,6.5-19.9,8.6
			c-6.5,1.9-13.2,3.3-20,4.1C806.4,833.6,801,833.9,796.1,833.9z M798.5,755.4c-4.8-0.1-9.6,0.2-14.4,0.7c-4.7,0.5-9.3,1.3-13.9,2.3
			c-7,1.6-13.7,3.7-20.2,6.8c-5.6,2.6-10.8,5.8-15.4,10c-0.2,0.2-0.5,0.4-0.7,0.6c-0.5,0.4-1,0.8-1.5,1.3c-1.3,1.5-2.6,3-3.7,4.7
			c-1.2,1.8-2.1,3.6-2.8,5.6c-1.9,5.2-1.8,10.3,0.3,15.4c1.2,2.9,2.8,5.4,4.8,7.8c2.6,3,5.7,5.6,9,7.8c4.2,2.8,8.6,5.1,13.3,6.9
			c11.5,4.4,23.5,6.6,35.8,7.1c7.7,0.3,15.4,0.1,23.1-0.9c7.4-1,14.7-2.5,21.8-4.9c5.4-1.8,10.6-4,15.5-6.8c4.3-2.5,8.3-5.4,11.8-9
			c2.4-2.4,4.4-5.1,5.8-8.2c1.2-2.4,1.9-4.9,2.1-7.6c0.4-4.5-0.6-8.7-2.8-12.7c-1.3-2.4-3-4.5-4.9-6.5c-2.9-3.1-6.3-5.6-9.9-7.8
			c-3.9-2.3-8-4.2-12.2-5.8c-9.2-3.5-18.8-5.4-28.5-6.3C806.9,755.5,802.7,755.3,798.5,755.4z"/>
                            <path d="M583.2,853.4c-10.1-0.1-19.4-1-28.6-3.5c-5.1-1.4-10-3.2-14.7-5.6c-3.2-1.6-6.2-3.5-8.9-5.8c-2.8-2.3-5.2-4.8-7.2-7.8
			c-2.3-3.4-3.7-7.2-4.1-11.3c-0.4-3.5,0-7,1.2-10.3c1.1-3.2,2.7-6.2,4.6-9c2.3-3.2,5-6,8-8.6c4.9-4.2,10.4-7.6,16.2-10.4
			c7.8-3.8,16.1-6.5,24.6-8.3c1.9-0.4,3.9-0.7,5.9-1.1c1.5-0.3,3-0.7,4.6-0.9c3.5-0.4,6.9-0.8,10.4-0.9c1.3,0,2.5-0.1,3.8-0.2
			c10.3-0.2,20.5,0.8,30.4,3.5c6,1.6,11.7,3.8,17,6.9c3.1,1.8,6,3.8,8.6,6.3c2,1.9,3.8,4,5.3,6.4c3.2,5.1,4.6,10.6,3.9,16.5
			c-0.3,2.8-1.2,5.4-2.3,7.9c-1.9,4.2-4.7,7.9-7.9,11.2c-3.9,4-8.4,7.4-13.3,10.2c-6.8,4.1-14.1,7.1-21.7,9.4
			c-6.9,2.1-14,3.5-21.2,4.5C592.8,853.1,587.7,853.2,583.2,853.4z M583.1,851.7c4.5,0,8.6-0.3,12.7-0.7c6.2-0.6,12.3-1.7,18.3-3.3
			c8.2-2.2,16.2-5.1,23.7-9.3c4.8-2.7,9.3-5.8,13.4-9.5c2.5-2.3,4.8-4.9,6.7-7.8c1.5-2.3,2.8-4.8,3.7-7.4c1.2-3.7,1.6-7.5,0.9-11.4
			c-0.7-3.6-2.2-6.9-4.3-9.9c-1.8-2.5-3.9-4.6-6.2-6.5c-3.7-3-7.7-5.3-12.1-7.2c-6.4-2.8-13.1-4.6-19.9-5.8c-3.8-0.6-7.6-1-11.5-1.3
			c-4.3-0.3-8.6-0.3-12.8-0.1c-3.6,0.2-7.2,0.6-10.8,1c-1.7,0.2-3.5,0.4-5.2,0.6c-3.5,0.6-6.9,1.4-10.2,2.3c-8,2.2-15.7,5.1-23,9.1
			c-4.4,2.4-8.6,5.3-12.5,8.6c-2.5,2.2-4.9,4.7-6.9,7.4c-1.9,2.5-3.4,5.3-4.5,8.2c-2.6,7.4-1.8,14.3,2.4,20.9
			c1.1,1.7,2.4,3.3,3.9,4.8c2.1,2.2,4.5,4,7,5.7c3.1,2,6.4,3.7,9.8,5.1c8.3,3.4,17,5.2,25.9,6.1
			C575.6,851.6,579.6,851.8,583.1,851.7z"/>
                            <path className="st14" d="M798.5,755.4c4.2-0.1,8.4,0.1,12.5,0.5c9.8,1,19.3,2.9,28.5,6.3c4.2,1.6,8.3,3.5,12.2,5.8
			c3.6,2.2,7,4.7,9.9,7.8c1.9,2,3.5,4.1,4.9,6.5c2.2,4,3.2,8.2,2.8,12.7c-0.2,2.7-1,5.2-2.1,7.6c-1.5,3.1-3.5,5.8-5.8,8.2
			c-3.5,3.6-7.4,6.5-11.8,9c-4.9,2.8-10.1,5-15.5,6.8c-7.1,2.3-14.4,3.9-21.8,4.9c-7.7,1-15.4,1.3-23.1,0.9
			c-12.3-0.6-24.3-2.7-35.8-7.1c-4.7-1.8-9.2-4-13.3-6.9c-3.3-2.3-6.3-4.8-9-7.8c-2-2.3-3.7-4.9-4.8-7.8c-2-5.1-2.1-10.2-0.3-15.4
			c0.7-2,1.7-3.9,2.8-5.6c1.1-1.7,2.3-3.2,3.7-4.7c0.4-0.5,1-0.9,1.5-1.3c0.2-0.2,0.5-0.4,0.7-0.6c4.6-4.2,9.8-7.3,15.4-10
			c6.5-3,13.2-5.2,20.2-6.8c4.6-1,9.2-1.8,13.9-2.3C788.9,755.6,793.8,755.3,798.5,755.4z"/>
                            <path className="st14" d="M583.1,851.7c-3.6,0.1-7.6-0.1-11.5-0.5c-8.9-0.9-17.6-2.7-25.9-6.1c-3.4-1.4-6.7-3-9.8-5.1
			c-2.5-1.6-4.9-3.5-7-5.7c-1.4-1.5-2.7-3-3.9-4.8c-4.2-6.6-5-13.6-2.4-20.9c1-3,2.6-5.7,4.5-8.2c2-2.7,4.3-5.2,6.9-7.4
			c3.8-3.4,8-6.2,12.5-8.6c7.3-4,15-6.9,23-9.1c3.4-0.9,6.8-1.7,10.2-2.3c1.7-0.3,3.5-0.5,5.2-0.6c3.6-0.4,7.2-0.8,10.8-1
			c4.3-0.2,8.5-0.2,12.8,0.1c3.9,0.3,7.7,0.6,11.5,1.3c6.9,1.1,13.5,2.9,19.9,5.8c4.3,1.9,8.4,4.2,12.1,7.2c2.3,1.9,4.4,4.1,6.2,6.5
			c2.1,3,3.6,6.2,4.3,9.9c0.7,3.9,0.3,7.7-0.9,11.4c-0.9,2.7-2.1,5.1-3.7,7.4c-1.9,2.9-4.2,5.4-6.7,7.8c-4,3.8-8.5,6.9-13.4,9.5
			c-7.5,4.1-15.4,7.1-23.7,9.3c-6,1.6-12.1,2.7-18.3,3.3C591.7,851.4,587.7,851.7,583.1,851.7z"/>
	</g>
                        <g className="pizarron white">
		<path d="M59.8,693.7c-0.4,0-0.7,0.2-1,0.4c-0.4,0.3-0.7,0.2-1-0.1c-0.3-0.5-0.8-0.6-1.3-0.8c-0.5-0.2-1-0.3-1.5-0.5
			c-0.6-0.3-1.3-0.5-1.9-0.7c-1-0.3-1.9-0.8-2.7-1.4c-0.4-0.3-0.4-0.3-0.2-0.8c0.1-0.2,0.1-0.4,0-0.6c-0.1-0.6-0.3-1.2-0.4-1.8
			c-0.2-1.1-0.3-2.3-0.5-3.4c-0.2-1.7-0.4-3.4-0.7-5.1c-0.4-1.9-0.7-3.9-1-5.8c-0.3-2.3-0.6-4.7-0.9-7c-0.1-0.9-0.2-1.8-0.3-2.7
			c-0.2-1.9-0.4-3.8-0.6-5.7c-0.1-1.5-0.3-3-0.4-4.5c-0.1-1.1-0.2-2.2-0.3-3.4c-0.2-1.9-0.4-3.7-0.6-5.5c-0.2-2.3-0.5-4.6-0.7-7
			c-0.2-1.7-0.4-3.4-0.6-5.1c-0.1-0.7-0.2-1.5-0.3-2.2c-0.1-1.1-0.3-2.1-0.4-3.2c-0.1-0.6-0.2-1.1-0.3-1.7c-0.2-1-0.3-1.9-0.5-2.9
			c-0.2-1.1-0.3-2.3-0.4-3.4c-0.2-1.5-0.3-2.9-0.6-4.4c-0.4-2.6-0.9-5.2-1.3-7.8c-0.3-1.4-0.5-2.9-0.8-4.3c-0.2-1.3-0.4-2.6-0.6-3.9
			c-0.2-1.5-0.4-3-0.6-4.5c0-0.1,0-0.2,0-0.2c-0.1-1.6-0.3-3.2-0.4-4.9c-0.1-1.6-0.1-3.3-0.2-4.9c-0.1-1.9-0.2-3.9-0.3-5.8
			c-0.1-1.2-0.3-2.3-0.4-3.5c-0.2-1.5-0.4-3-0.6-4.4c-0.2-1.3-0.4-2.6-0.7-3.9c-0.4-1.6-0.7-3.1-1-4.7c0-0.2,0-0.4-0.3-0.6
			c0,0.1-0.1,0.2-0.1,0.2c-0.1,1.1-0.3,2.2-0.4,3.4c-0.1,1.4-0.3,2.8-0.4,4.2c-0.1,1.6-0.3,3.2-0.4,4.8c-0.1,1.3-0.3,2.6-0.4,3.8
			c-0.1,1-0.2,2.1-0.3,3.1c0,0.3,0,0.5-0.1,0.8c-0.2,1.9-0.4,3.9-0.6,5.8c-0.1,1.3-0.3,2.7-0.4,4c-0.1,1.3-0.2,2.5-0.4,3.8
			c0,0.1,0,0.1,0,0.2c-0.2,1.1-0.3,2.3-0.5,3.4c-0.2,1.3-0.4,2.6-0.5,3.9c-0.2,1.5-0.4,2.9-0.6,4.4c-0.2,1.7-0.4,3.5-0.6,5.2
			c-0.3,2-0.5,4.1-0.8,6.1c-0.3,2.6-0.7,5.2-1,7.8c-0.3,2-0.6,4-0.8,6.1c-0.2,1.2-0.3,2.5-0.4,3.8c0,0.2-0.1,0.5-0.1,0.7
			c-0.1,0.6-0.1,1.3-0.2,1.9c-0.1,1.1-0.2,2.2-0.3,3.3c-0.1,1.3-0.2,2.5-0.4,3.8c-0.1,0.9-0.2,1.9-0.3,2.8c-0.2,1.6-0.3,3.1-0.5,4.7
			c-0.1,1.4-0.3,2.8-0.4,4.1c-0.1,1.2-0.3,2.3-0.4,3.5c-0.2,1.7-0.3,3.3-0.2,5c0,0.3,0,0.6,0,0.9c0,0.2,0,0.3,0,0.5
			c-0.1,0.4-0.3,0.6-0.8,0.5c-0.1,0-0.1,0-0.1,0c0,0-0.1,0,0,0c-0.8,0.2-1.3,0.8-1.9,1.3c-1.6,1.4-3.3,2.9-4.9,4.3
			c-0.7,0.6-1.5,1.2-2.2,1.9c-0.2,0.2-0.4,0.4-0.5,0.6c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0-0.1,0.1-0.2,0.1c-1,0.3-1.1,0.2-1.7-0.6
			c-0.2-0.3-0.4-0.5-0.7-0.5c-1.1-0.3-2.1-0.8-3.1-1.3c-0.9-0.4-1.7-0.9-2.6-1.4c-0.6-0.4-1.3-0.7-2-0.8c-0.2,0-0.4-0.1-0.6-0.2
			c-0.3-0.2-0.5-0.4-0.4-0.8c0.1-0.6,0.2-1.2,0.5-1.8c0.4-0.9,0.7-1.8,0.8-2.8c0.2-1.5,0.5-3,0.8-4.5c0.2-1.1,0.3-2.2,0.4-3.3
			c0.2-1.6,0.3-3.2,0.5-4.8c0.2-1.8,0.5-3.6,0.7-5.4c0.2-1.3,0.3-2.6,0.5-3.9c0-0.3,0.1-0.6,0.1-0.9c0.3-1.4,0.4-2.9,0.6-4.4
			c0.5-2.8,0.9-5.5,1.4-8.3c0.4-2.6,0.9-5.2,1.4-7.8c0.2-1.3,0.4-2.6,0.7-3.9c0.2-1.2,0.5-2.4,0.7-3.6c0.2-1.1,0.4-2.1,0.6-3.2
			c0-0.2,0.1-0.5,0.1-0.7c0.3-1.3,0.6-2.6,0.8-3.9c0.2-0.9,0.3-1.8,0.5-2.6c0.3-1.7,0.6-3.4,0.8-5.1c0.4-2.5,0.8-5,1.2-7.5
			c0.4-2.1,0.8-4.3,1.1-6.4c0.2-1.2,0.4-2.5,0.6-3.7c0.2-1.3,0.4-2.6,0.6-3.9c0.2-1.2,0.3-2.4,0.4-3.6c0.1-1.1,0.3-2.1,0.4-3.2
			c0.1-1.1,0.3-2.2,0.4-3.3c0-0.2,0.1-0.4,0.1-0.5c0.3-1.7,0.6-3.3,0.9-5c0.2-1.2,0.5-2.4,0.7-3.6c0.2-1.2,0.4-2.3,0.7-3.5
			c0-0.1,0-0.3,0.1-0.4c0.3-1.3,0.5-2.7,0.8-4c0.2-0.9,0.3-1.8,0.5-2.7c0.6-3.5,1.2-6.9,1.8-10.4c0.2-1.3,0.4-2.5,0.6-3.8
			c0.3-1.8,0.6-3.5,0.9-5.3c0.1-0.7,0.2-1.5,0.3-2.3c0.3-1.9,0.7-3.7,0.9-5.6c0-0.5,0.1-0.9,0.2-1.4c0.3-1.3,0.3-2.5,0.2-3.8
			c0-0.7,0-1.5,0.1-2.2c0.2-0.9,0.1-1.8,0-2.7c-0.1-0.6,0-1.2,0.1-1.8c0.1-0.4,0.3-0.7,0.6-0.9c0.3-0.2,0.6-0.4,0.8-0.6
			c1.4-0.9,2.8-1.7,4.1-2.6c1.4-1,2.9-1.8,4.4-2.7c2-1.1,4-2.2,6-3.3c2.2-1.2,4.4-2.5,6.6-3.8c1.7-1,3.4-2,5.2-2.9
			c2.7-1.5,5.5-2.9,8.2-4.3c1.4-0.7,2.7-1.5,4.1-2.2c1.2-0.6,2.4-1.1,3.5-1.7c1.7-0.9,3.4-1.7,5.1-2.6c2.4-1.1,4.8-2.3,7.3-3.2
			c1.9-0.6,3.7-1.3,5.6-2c1.3-0.5,2.6-1,3.9-1.5c1-0.4,2.1-0.8,3.1-1.3c1.2-0.5,2.5-1.1,3.7-1.6c1.3-0.5,2.6-1.1,3.9-1.6
			c1.8-0.8,3.7-1.5,5.5-2.4c1.7-0.8,3.4-1.5,5.2-2.1c0.4-0.2,0.9-0.3,1.3-0.6c0.5-0.3,1-0.4,1.5-0.3c1.3,0.2,2.5,0.4,3.7,0.9
			c0.8,0.3,1.6,0.5,2.5,0.8c0.4,0.2,0.8,0.4,1.2,0.5c0.5,0.2,0.9,0.5,1.4,0.6c0.4,0.2,0.7,0.5,0.8,0.9c0,0.1,0,0.2,0,0.2
			c0.4,3.1,1.1,6.2,1.9,9.2c0.6,2.3,1.2,4.5,1.9,6.8c0.4,1.4,0.8,2.9,1.1,4.3c0.4,1.6,0.6,3.1,0.9,4.7c0.3,1.5,0.6,2.9,0.8,4.4
			c0.4,2.3,0.7,4.6,1.1,6.8c0.2,1.4,0.5,2.8,0.7,4.3c0.2,1.3,0.4,2.6,0.6,3.9c0.2,1.2,0.4,2.4,0.6,3.6c0.3,1.7,0.7,3.3,1,5
			c0.3,1.5,0.7,3,1.1,4.5c0.6,2.3,1.1,4.7,1.7,7c0.4,1.4,0.7,2.8,1.1,4.3c0.4,1.4,0.7,2.9,1,4.3c0.3,1.5,0.7,2.9,1,4.4
			c0.4,1.6,0.8,3.2,1.1,4.8c0.5,2.2,1,4.5,1.4,6.7c0.3,1.5,0.6,3.1,0.9,4.6c0.3,1.3,0.5,2.6,0.8,3.9c0.3,1.6,0.6,3.2,1,4.8
			c0.5,2.3,1,4.5,1.4,6.8c0.3,1.5,0.6,3.1,0.9,4.6c0.2,1.3,0.5,2.5,0.7,3.8c0.3,1.7,0.5,3.3,0.8,5c0.3,2.1,0.7,4.2,1,6.4
			c0.3,1.6,0.5,3.3,0.8,4.9c0.4,2.7,0.8,5.4,1.2,8.2c0.2,1.5,0.5,3,0.7,4.5c0.4,2.2,0.7,4.4,1.1,6.6c0.3,1.4,0.6,2.8,0.8,4.2
			c0.3,1.5,0.6,2.9,0.9,4.4c0.3,1.4,0.6,2.9,0.9,4.3c0.3,1.5,0.6,3.1,0.9,4.6c0.1,0.3,0.1,0.7,0.1,1c0,0.4-0.1,0.7-0.5,1
			c-0.5,0.3-0.9,0.7-1.4,1c-0.8,0.5-1.6,0.9-2.4,1.4c-1.1,0.6-2.1,1.2-3.2,1.9c-0.8,0.5-1.5,1.1-2.2,1.6c-0.2,0.2-0.4,0.4-0.5,0.7
			c-0.3,0.7-0.7,0.9-1.4,0.8c-0.7-0.2-1.4-0.3-2-0.7c-0.9-0.5-1.8-1-2.8-1.5c-0.1-0.1-0.3-0.2-0.4-0.2c-0.5-0.3-0.9-0.5-1.4-0.6
			c-0.5-0.1-0.7-0.4-0.8-0.8c-0.1-0.6-0.2-1.3-0.4-1.9c-0.2-0.9-0.3-1.7-0.5-2.6c-0.3-1.6-0.7-3.1-1.1-4.7c-0.5-2.2-1-4.5-1.5-6.7
			c-0.6-2.6-1.2-5.3-1.8-7.9c-0.3-1.4-0.7-2.9-1-4.3c-0.2-1.1-0.4-2.3-0.5-3.4c-0.1-1.4-0.2-2.9-0.3-4.3c-0.1-1.2-0.1-2.4-0.2-3.5
			c0-0.8-0.1-1.6-0.2-2.3c-0.1-1.3-0.2-2.6-0.4-3.8c-0.1-0.9-0.2-1.7-0.4-2.6c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.2-0.2-0.2-0.4-0.2
			c-0.2,0.1-0.4,0.2-0.6,0.3c-1.7,1.1-3.4,2.1-5.1,3.3c-0.4,0.3-0.8,0.6-1.3,0.9c-2.8,2-5.6,4.1-8.5,6c-1.3,0.9-2.6,1.9-3.9,2.8
			c-1.5,1.1-3,2.2-4.5,3.4c-1.1,0.8-2.2,1.6-3.2,2.5c-2.1,1.7-4.3,3.4-6.5,5c-2.9,2.2-5.8,4.4-8.7,6.6c-1.4,1.1-2.8,2.3-4.3,3.4
			c-2,1.5-4.1,2.9-6.2,4.3c-1.2,0.8-2.5,1.5-3.8,2.2c-0.6,0.4-1.3,0.7-2,1c-0.4,0.2-0.8,0.2-1.2,0c-1.5-0.7-3-1.4-4.5-2
			c-0.4-0.2-0.8-0.3-1.2-0.5c-0.3-0.1-0.5,0.1-0.5,0.4c0,0.1,0,0.2,0,0.3c0.3,1.9,0.7,3.8,1,5.7c0.3,1.5,0.5,3,0.8,4.6
			c0.4,2.4,0.8,4.7,1.3,7.1c0.5,2.9,1.1,5.8,1.8,8.7c0.6,2.7,1.1,5.3,1.6,8c0.4,2.1,0.8,4.1,1.2,6.2c0.2,1.3,0.5,2.6,0.8,3.9
			c0,0.2,0.1,0.4,0.2,0.5c0.2,0.7-0.2,1.2-0.5,1.7c-0.1,0.1-0.2,0.1-0.3,0.2c-0.4,0-0.6,0.3-0.9,0.5c-0.7,0.6-1.4,1.3-2.1,1.9
			c-1.1,0.9-2.3,1.8-3.5,2.7c-0.7,0.6-1.5,1.1-2.3,1.7c-1.5,1.2-2.9,2.3-4.4,3.5C60,693.1,59.8,693.4,59.8,693.7z M90.4,616.9
			L90.4,616.9c0,0.3,0.2,0.6,0.1,0.9c-0.2,0.9-0.7,1.5-1.5,1.9c-0.7,0.3-1.2,0.2-1.7-0.4c-0.5-0.6-1.1-0.9-1.6-1.4
			c-0.3-0.2-0.5-0.2-0.8,0c-0.9,0.6-1.9,1.2-2.8,1.9c-0.1,0.1-0.2,0.2-0.3,0.4c0.1,0.1,0.2,0.2,0.2,0.3c0.3,0.2,0.6,0.4,0.9,0.6
			c0.5,0.3,0.7,0.8,0.6,1.3c-0.1,1.3-0.9,1.9-2,2.1c-0.6,0.1-1.1,0-1.6-0.4c-0.5-0.4-1-0.7-1.5-1.1c-0.3-0.2-0.6-0.2-1,0
			c-0.2,0.1-0.4,0.2-0.5,0.3c-0.5,0.3-1,0.7-1.5,1c-0.2,0.2-0.2,0.4,0,0.6c0.2,0.2,0.4,0.2,0.6,0.2c0.7,0.1,1,0.5,1.1,1.2
			c0,0.4,0,0.8-0.2,1.2c-0.4,1-1.5,1.5-2.4,1.2c-1-0.4-2-0.7-3-1.2c-0.4-0.2-0.8-0.1-1.2,0.1c-0.8,0.5-1.6,0.9-2.4,1.4
			c-0.5,0.3-1.1,0.6-1.6,0.9c-0.1,0.1-0.3,0.2-0.3,0.4c0.2,0.3,0.6,0.4,0.9,0.5c0.4,0.2,0.9,0.3,1.3,0.4c1,0.3,2.1,0.6,3.1,0.9
			c0.2,0.1,0.5,0.1,0.7,0.2c0.3,0.2,0.6,0.1,0.8-0.2c0.1-0.1,0.1-0.1,0.2-0.2c0.6-0.7,1.3-1.2,2.1-1.7c0.5-0.3,1-0.7,1.5-1
			c2.4-1.5,4.7-3,7-4.7c1.6-1.2,3.3-2.3,4.9-3.5c2.1-1.6,4.3-3.1,6.4-4.7c1.5-1.1,3-2.3,4.5-3.4c1.6-1.2,3.2-2.4,4.9-3.6
			c2.1-1.5,4.2-3,6.4-4.5c3.7-2.5,7.6-4.8,11.4-7.1c1.9-1.1,3.7-2.3,5.4-3.6c0.7-0.5,1.4-1,2.1-1.4c1.2-0.9,2.4-1.7,3.7-2.6
			c0.4-0.3,0.9-0.6,1.3-0.9c1.5-1,3-1.9,4.3-3.1c0.3-0.3,0.6-0.5,0.9-0.7c1.1-0.8,2.1-1.7,3.2-2.5c0.1-0.1,0.2-0.3,0.4-0.4
			c0-0.1,0-0.2-0.1-0.2c-0.8-0.7-1.7-1.3-2.6-1.8c-0.9-0.5-1.8-0.9-2.7-1.4c-0.5-0.3-1.1-0.2-1.6,0c-0.3,0.1-0.5,0.3-0.7,0.5
			c-1.2,1.1-2.5,2.3-3.7,3.4c-0.6,0.6-1.3,1.1-1.8,1.7c-0.7,0.7-1.3,1.5-1.9,2.2c-0.3,0.3-0.5,0.6-0.9,0.9c-1,0.9-2.1,1.8-3.1,2.7
			c-1.2,1-2.5,2-3.8,3c-2.5,1.9-5,3.8-7.5,5.7c-1.5,1.1-3,2.2-4.5,3.2c-1.7,1.2-3.5,2.4-5.2,3.6c-0.8,0.5-1.6,1.1-2.4,1.6
			c-0.1,0.1-0.3,0.2-0.2,0.4c0.1,0,0.3,0.1,0.4,0.1c0.5,0.2,0.8,0.6,0.7,1.2c-0.1,1.1-0.9,2.1-2,2.3c-0.5,0.1-1,0-1.4-0.4
			c-0.3-0.3-0.6-0.5-0.9-0.7c-0.4-0.3-0.8-0.4-1.2-0.1c-0.1,0-0.1,0.1-0.2,0.1c-1.1,0.7-2.2,1.4-3.3,2.1c-0.2,0.1-0.3,0.2-0.4,0.3
			c-0.1,0.1-0.1,0.2,0,0.4c0,0.1,0.1,0.2,0.2,0.2c0.3,0.4,0.7,0.7,1.2,0.9c0.5,0.2,0.6,0.7,0.4,1.1c-0.1,0.2-0.2,0.3-0.3,0.5
			c-0.4,0.6-1,0.9-1.7,1.1c-0.3,0.1-0.7,0-1-0.1C90.8,617.2,90.6,617.1,90.4,616.9z M123.5,469.1c-0.1,0-0.2,0-0.3,0
			c-1,0.1-2.1,0.3-3.1,0.4c-0.8,0.1-1.6,0.3-2.4,0.8c-0.4,0.3-0.8,0.5-1.2,0.7c-0.7,0.4-1.4,0.8-2.1,1.2c-0.6,0.3-1.2,0.6-1.8,1
			c-0.5,0.3-1.1,0.4-1.7,0.6c-2.9,1-5.7,2.1-8.4,3.6c-1.4,0.8-2.8,1.5-4.2,2.3c-1,0.5-2,1.1-3,1.6c-1.6,0.9-3.2,1.7-4.9,2.5
			c-2.8,1.4-5.6,2.7-8.3,4.1c-1.2,0.6-2.4,1.2-3.5,1.7c-1.3,0.6-2.7,1.2-4,1.9c-1.6,0.8-3.3,1.5-4.9,2.3c-2.2,1-4.5,1.9-6.7,2.9
			c-2.4,1.1-4.8,2.2-7.1,3.3c-1.7,0.8-3.3,1.6-4.9,2.4c-1.8,0.9-3.6,1.9-5.4,2.8c-1.2,0.6-2.3,1.2-3.5,1.8c-2.5,1.4-5,2.9-7.6,4.2
			c-0.2,0.1-0.4,0.3-0.6,0.5c-0.1,0.1-0.2,0.3-0.2,0.5c0.1,0.6,0.1,1.2,0.2,1.8c0.2,1.1,0.4,2.3,0.6,3.4c0.4,2.5,0.8,4.9,1.2,7.4
			c0.3,1.7,0.5,3.4,0.8,5c0.3,1.7,0.5,3.5,0.8,5.2c0.2,1.2,0.4,2.4,0.5,3.6c0.2,1.4,0.4,2.8,0.7,4.3c0.2,1.2,0.4,2.5,0.6,3.7
			c0.2,1,0.3,2,0.5,3c0.2,1.1,0.3,2.2,0.5,3.3c0.2,1.4,0.3,2.8,0.5,4.2c0.1,1,0.3,2.1,0.4,3.1c0.3,2.6,0.7,5.1,1,7.7
			c0.3,2.4,0.7,4.7,1,7.1c0.3,2.2,0.5,4.5,0.7,6.7c0.3,2.6,0.5,5.1,0.8,7.7c0.2,1.5,0.3,3.1,0.4,4.6c0.2,1.7,0.4,3.5,0.6,5.2
			c0.3,2.5,0.6,4.9,0.9,7.4c0.2,1.7,0.4,3.3,0.6,5c0.2,1.3,0.3,2.5,0.5,3.8c0.1,0.8,0.2,1.7,0.3,2.5c0.1,1.1,0.3,2.3,0.4,3.4
			c0.2,1.6,0.4,3.2,0.7,4.8c0.1,1.1,0.3,2.1,0.4,3.2c0.3,2,0.6,3.9,0.8,5.9c0.3,2,0.6,4,0.9,6c0.4,2.7,0.8,5.4,1.2,8.2
			c0.2,1.3,0.3,2.5,0.5,3.8c0.2,1.1,0.3,2.3,0.5,3.4c0.2,1.6,0.4,3.2,0.6,4.8c0.4,2.3,0.8,4.6,1.2,6.9c0.3,1.5,0.6,3.1,0.9,4.6
			c0.3,1.6,0.6,3.2,0.9,4.8c0.3,1.8,0.7,3.6,1,5.3c0.2,1.1,0.4,2.1,0.5,3.2c0.2,1.8,0.5,3.6,0.7,5.4c0,0.1,0.1,0.2,0.2,0.4
			c0.2-0.1,0.3-0.1,0.4-0.2c0.7-0.6,1.3-1.1,2-1.7c1.5-1.3,3.1-2.6,4.7-3.7c2.1-1.4,4-3.1,5.8-4.9c0.5-0.5,0.7-0.9,0.5-1.5
			c0-0.1,0-0.3-0.1-0.4c-0.3-1.3-0.5-2.7-0.8-4c-0.2-1-0.4-2-0.6-3c-0.1-0.6-0.2-1.2-0.3-1.9c-0.2-1.1-0.4-2.2-0.6-3.3
			c-0.5-2.3-1-4.6-1.5-6.9c-0.3-1.6-0.6-3.1-0.9-4.7c-0.3-1.7-0.6-3.4-0.9-5.1c-0.2-1.3-0.5-2.6-0.7-3.9c-0.2-1.1-0.4-2.3-0.6-3.4
			c-0.4-2.2-0.7-4.5-1.1-6.7c-0.1-0.4-0.1-0.8-0.2-1.2c-0.1-0.4-0.2-0.6-0.6-0.7c-0.4-0.1-0.8-0.3-1.2-0.4c-0.7-0.2-1-0.4-1-1.2
			c0-0.4-0.1-0.8-0.1-1.3c0-0.4,0-0.8,0-1.3c0-0.4,0.1-0.8,0-1.2c-0.1-0.6,0.3-0.9,0.9-0.7c0.1,0,0.1,0.1,0.2,0.1
			c0.5,0.2,0.9,0.4,1.4,0.6c0.3,0.1,0.5,0.1,0.8,0c1.4-0.9,2.9-1.7,4.3-2.6c0.2-0.1,0.4-0.2,0.5-0.5c-0.1-0.1-0.1-0.3-0.2-0.4
			c-0.4-0.5-0.3-1.1-0.1-1.6c0.2-0.4,0.5-0.7,0.9-1c0.3-0.2,0.7-0.3,1-0.2c0.6,0.1,1.2,0.3,1.7,0.6c0.4,0.2,0.7,0.2,1.1-0.1
			c0.7-0.5,1.3-0.9,2-1.4c0.1-0.1,0.2-0.2,0.3-0.3c-0.2-0.1-0.3-0.3-0.5-0.4c-0.4-0.2-0.5-0.6-0.5-1c0-0.2,0.1-0.4,0.2-0.6
			c0.4-0.8,1-1.2,1.9-1.4c0.4-0.1,0.7,0,1,0.2c0.3,0.2,0.6,0.3,1,0.5c0.4,0.2,0.7,0.2,1.1-0.1c0.9-0.6,1.9-1.2,2.8-1.9
			c0.1-0.1,0.2-0.2,0.3-0.3c-0.1-0.3-0.3-0.5-0.3-0.7c-0.1-0.4-0.1-0.7,0.1-1c0.3-0.3,0.5-0.6,0.9-0.9c0.5-0.5,1.1-0.5,1.6-0.1
			c0.3,0.2,0.6,0.4,0.9,0.5c0.1,0.1,0.3,0,0.5,0c-0.1-0.2-0.1-0.3-0.2-0.3c-0.1-0.2-0.3-0.3-0.4-0.4c-0.1-0.2-0.2-0.4-0.2-0.6
			c0.3-0.7,0.7-1.4,1.5-1.6c0.5-0.2,1.1-0.2,1.6,0.2c0.2,0.1,0.4,0.3,0.6,0.4c0.3,0.2,0.6,0.3,0.9,0c1.2-0.8,2.4-1.5,3.7-2.3
			c0.1-0.1,0.2-0.2,0.3-0.4c-0.1-0.1-0.2-0.1-0.3-0.2c-0.3-0.2-0.6-0.3-0.9-0.5c-0.6-0.3-0.7-0.5-0.4-1.1c0.3-0.7,0.8-1.2,1.5-1.5
			c0.7-0.4,1.5-0.4,2.2,0.1c0.3,0.2,0.7,0.4,1,0.6c0.4,0.3,0.8,0.3,1.2,0c0.2-0.1,0.3-0.2,0.5-0.3c1-0.6,1.9-1.3,2.9-1.9
			c2.5-1.8,5.1-3.5,7.6-5.3c2.6-1.8,5.1-3.7,7.6-5.6c2.5-1.9,5-3.8,7.3-5.9c1.4-1.2,2.6-2.5,3.9-3.8c1.4-1.5,2.8-2.9,4.3-4.2
			c0.7-0.6,1.4-1.2,2-1.8c0.3-0.4,0.7-0.6,1.2-0.7c0.3-0.1,0.5,0,0.7,0.2c0.3,0.4,0.7,0.6,1.2,0.7c1.8,0.6,3.5,1.6,5,2.7
			c0.4,0.3,0.8,0.7,1.5,0.7c0.3,0,0.5,0.3,0.5,0.6c0,0.2,0,0.3-0.1,0.5c-0.2,0.6-0.2,1.2-0.2,1.8c0,0.5,0,0.9,0,1.4
			c0,0.7-0.3,1.2-0.8,1.6c-0.5,0.4-0.9,0.7-1.4,1c-1.4,1-2.9,1.9-4.4,2.8c-0.4,0.3-0.6,0.6-0.5,1c0.2,1.6,0.4,3.1,0.6,4.7
			c0.2,1.9,0.4,3.7,0.6,5.6c0.2,1.8,0.4,3.5,0.5,5.3c0,0.1,0,0.3,0.1,0.4c0.2,1.4,0.5,2.9,0.7,4.3c0.3,2.4,0.9,4.8,1.4,7.2
			c0.3,1.4,0.6,2.8,0.9,4.2c0.3,1.4,0.6,2.9,1,4.3c0.6,2.5,1.3,5,2,7.5c0.6,2.2,1,4.4,1.3,6.7c0.2,1.1,0.3,2.3,0.4,3.4
			c0,0.1,0.1,0.3,0.2,0.5c0.3-0.2,0.5-0.3,0.7-0.5c1.1-0.9,2.3-1.7,3.5-2.5c1-0.6,2-1.1,3-1.7c0.6-0.4,1.2-0.8,1.9-1.2
			c0.3-0.2,0.5-0.5,0.4-0.9c-0.1-0.7-0.2-1.4-0.4-2.1c-0.3-1.6-0.6-3.1-1-4.7c-0.3-1.4-0.6-2.8-0.9-4.2c-0.3-1.4-0.5-2.7-0.8-4.1
			c-0.4-2.2-0.9-4.3-1.2-6.5c-0.4-2.2-0.8-4.5-1.1-6.7c-0.3-1.7-0.5-3.3-0.7-5c-0.2-1.5-0.4-2.9-0.7-4.3c-0.2-1.6-0.5-3.2-0.8-4.7
			c-0.2-1.4-0.4-2.8-0.7-4.3c-0.2-1.2-0.4-2.3-0.6-3.5c-0.2-1.4-0.4-2.9-0.7-4.3c-0.4-2.1-0.9-4.3-1.3-6.4c-0.3-1.6-0.7-3.2-1-4.8
			c-0.4-1.7-0.7-3.4-1-5.1c-0.4-2.1-0.8-4.2-1.2-6.2c-0.3-1.4-0.6-2.8-0.9-4.2c-0.3-1.7-0.7-3.3-1-5c-0.3-1.4-0.6-2.8-1-4.2
			c-0.4-1.5-0.7-3.1-1.1-4.6c-0.3-1.5-0.7-2.9-1.1-4.4c-0.4-1.5-0.7-2.9-1.1-4.4c-0.4-1.6-0.8-3.2-1.2-4.7c-0.4-1.4-0.7-2.8-1.1-4.3
			c-0.5-2.2-1.1-4.3-1.5-6.5c-0.5-2.2-0.9-4.4-1.2-6.6c-0.2-1.6-0.5-3.3-0.7-4.9c-0.2-1.5-0.5-3-0.7-4.4c-0.2-1.1-0.4-2.3-0.6-3.4
			c-0.3-1.7-0.6-3.5-0.9-5.2c-0.3-1.6-0.6-3.1-0.9-4.7c-0.3-1.3-0.6-2.7-0.9-4c-0.4-1.6-0.8-3.1-1.2-4.7c-0.4-1.4-0.7-2.8-1.1-4.2
			c-0.7-2.6-1.3-5.1-1.7-7.8c0-0.4-0.1-0.7-0.2-1.1C123.6,469.4,123.5,469.2,123.5,469.1z M26.3,521.2c-0.1,0-0.1,0-0.2,0
			c-0.1,0.6-0.3,1.3-0.4,1.9c-0.3,2.2-0.6,4.4-0.9,6.6c-0.1,1-0.3,2-0.5,3c-0.2,1.3-0.4,2.6-0.6,3.9c-0.2,1.1-0.4,2.2-0.5,3.3
			c-0.2,1.4-0.5,2.7-0.7,4.1c-0.1,0.3-0.2,0.7-0.2,1c-0.2,1.4-0.5,2.7-0.7,4.1c-0.2,1.1-0.4,2.3-0.6,3.4c-0.4,2.3-0.9,4.5-1.3,6.8
			c-0.4,2.2-0.8,4.3-1.2,6.5c-0.2,1-0.3,2-0.5,3c-0.2,1.1-0.3,2.1-0.5,3.2c-0.2,1.3-0.3,2.7-0.5,4c-0.3,2.1-0.6,4.2-0.9,6.3
			c-0.5,2.9-1,5.8-1.5,8.7c-0.2,1.2-0.4,2.3-0.6,3.5c-0.2,1.3-0.5,2.7-0.7,4c-0.2,1.1-0.4,2.2-0.5,3.3c-0.3,2.1-0.7,4.2-1.1,6.4
			c-0.2,1.2-0.5,2.5-0.8,3.7c-0.8,4.4-1.6,8.8-2.4,13.1c-0.4,2.1-0.8,4.2-1.1,6.3c0,0.1,0,0.3-0.1,0.4c-0.1,0.6-0.2,1.2-0.3,1.9
			c0,0.3-0.1,0.5-0.1,0.8c-0.2,1.2-0.3,2.4-0.5,3.6c-0.1,0.7-0.3,1.5-0.4,2.2c-0.2,1.9-0.5,3.7-0.7,5.6c-0.3,2.1-0.5,4.2-0.8,6.2
			c-0.1,0.5-0.2,1-0.2,1.6c-0.1,1.2-0.2,2.4-0.3,3.6c0,0.2-0.1,0.5-0.1,0.7c0,0.2-0.1,0.4-0.1,0.6c-0.2,1.2-0.3,2.3-0.5,3.5
			c-0.2,1.4-0.5,2.7-0.7,4.1c0,0.1,0,0.3-0.1,0.4c-0.2,1.2-0.5,2.2-1.1,3.3c-0.1,0.1-0.1,0.2-0.1,0.4c0.1,0,0.2,0.1,0.3,0.1
			c0.5,0.1,1,0.2,1.4,0.5c0.9,0.5,1.9,1,2.8,1.4c0.8,0.4,1.6,0.7,2.4,1.1c0.1,0,0.1,0.1,0.2,0.1c0.3,0.1,0.5,0,0.6-0.3
			c0-0.2,0-0.4,0-0.6c0.1-1,0.2-2.1,0.3-3.1c0.2-1.9,0.3-3.7,0.5-5.6c0.1-1.3,0.3-2.6,0.4-3.9c0.1-1.1,0.3-2.1,0.5-3.2
			c0.2-1.5,0.5-3,0.7-4.6c0.2-1,0.3-2,0.5-3c0.4-2.5,0.8-5,1.2-7.5c0.3-1.7,0.6-3.3,1-5c0.6-3.1,1.2-6.2,1.9-9.2
			c0.2-1.1,0.4-2.2,0.6-3.2c0.2-1.3,0.5-2.6,0.7-3.9c0.1-0.4,0.2-0.7,0.3-1.1c0.1-0.6,0.2-1.2,0.4-1.8c0.2-1.1,0.5-2.2,0.8-3.3
			c0.3-1.3,0.6-2.7,0.9-4c0.2-0.9,0.4-1.8,0.6-2.8c0.3-1.3,0.6-2.5,0.8-3.8c0.3-1.3,0.5-2.6,0.8-3.8c0.4-2,0.8-4,1.2-6
			c0.6-3.9,1.4-7.7,2.1-11.6c0.2-1,0.4-2,0.5-2.9c0.1-0.8,0.2-1.6,0.3-2.3c0.1-1,0.3-2,0.4-3c0.1-1.1,0.2-2.2,0.3-3.4
			c0.1-1.3,0.2-2.6,0.4-3.9c0.1-1,0.2-2,0.3-3c0.1-1.1,0.2-2.1,0.3-3.2c0-0.1,0-0.2,0-0.3c0.1-0.6,0.1-1.1,0.2-1.7
			c0-0.2,0.1-0.4,0.1-0.6c0.1-1.1,0.1-2.2,0.3-3.3c0.3-2.7,0.6-5.4,0.9-8.1c0.1-1.1,0.2-2.2,0.3-3.3c0.1-1,0.1-2,0.2-3
			c0.1-0.9,0.1-1.7,0.2-2.6c0.1-0.8,0.1-1.6-0.1-2.4c-0.4-1.6-0.7-3.3-1.2-4.9c-0.5-1.8-1-3.6-1.5-5.4c-0.2-0.9-0.5-1.8-0.7-2.7
			C26.4,521.5,26.3,521.4,26.3,521.2z M26.6,509.8c0,0.5,0.2,0.9,0.2,1.3c0.2,0.9,0.4,1.9,0.4,2.8c0.1,1,0,2.1,0.1,3.1
			c0,0.9,0.1,1.8,0.1,2.7c0,0.1,0,0.2,0.1,0.3c0.3,1.3,0.6,2.6,1,3.8c0.6,2.2,1.2,4.3,1.8,6.5c0.6,2.4,1.2,4.9,1.7,7.3
			c0.4,1.8,0.7,3.7,0.8,5.5c0.2,3.5,0.5,7,1,10.5c0,0.2,0.1,0.4,0.2,0.6c0.1,0.3,0.2,0.6,0.2,0.9c0,0.4,0,0.7,0.1,1.1
			c0.2,1.2,0.5,2.4,0.7,3.6c0.3,1.4,0.6,2.8,0.9,4.2c0.2,1,0.5,2.1,0.7,3.1c0.3,1.6,0.6,3.3,0.8,5c0.2,1.6,0.5,3.1,0.6,4.7
			c0.1,1,0.2,2,0.2,3.1c0.1,1.7,0.1,3.5,0.2,5.2c0,1.1,0.1,2.1,0.1,3.2c0.1,1.1,0.1,2.1,0.2,3.2c0,0.6,0.1,1.3,0.2,1.9
			c0.1,1.1,0.3,2.1,0.4,3.2c0.1,1.1,0.3,2.2,0.4,3.3c0.3,1.6,0.5,3.2,0.8,4.8c0.4,2.4,0.8,4.7,1.2,7.1c0.4,2.4,0.8,4.8,1,7.2
			c0.2,1.6,0.4,3.3,0.7,4.9c0.3,1.8,0.6,3.6,0.8,5.4c0.1,0.9,0.2,1.7,0.3,2.6c0.4,2.5,0.6,4.9,0.9,7.4c0.2,1.6,0.3,3.1,0.5,4.7
			c0.3,2.5,0.6,5,0.8,7.5c0.1,1.2,0.2,2.3,0.3,3.5c0.1,1.5,0.3,2.9,0.4,4.4c0.1,1.3,0.2,2.5,0.3,3.8c0.3,2.4,0.6,4.7,0.9,7.1
			c0.2,1.6,0.4,3.2,0.7,4.8c0.2,1,0.3,2,0.5,2.9c0.4,2,0.6,3.9,0.7,5.9c0.1,1.4,0.3,2.7,0.4,4.1c0.1,0.6,0.1,1.2,0.5,1.6
			c0.1,0.5,0.4,0.7,0.8,0.8c1.4,0.5,2.8,1,4.1,1.5c1,0.4,1.1,0.3,1-0.8c-0.2-1.2-0.3-2.3-0.5-3.5c-0.2-1.5-0.4-2.9-0.6-4.4
			c-0.2-1.2-0.5-2.4-0.7-3.5c-0.4-2.3-0.7-4.6-1.2-6.9c-0.4-1.7-0.7-3.4-1-5.2c-0.2-1.3-0.4-2.5-0.6-3.8c-0.2-1.5-0.5-3.1-0.7-4.6
			c-0.3-1.9-0.5-3.8-0.8-5.7c-0.2-1.8-0.5-3.6-0.7-5.5c-0.2-1.4-0.4-2.9-0.6-4.3c-0.2-1.3-0.4-2.6-0.6-3.9c-0.2-1.5-0.4-3-0.7-4.5
			c-0.3-2.3-0.7-4.6-1-6.9c-0.3-1.8-0.5-3.7-0.8-5.5c-0.1-1-0.2-2-0.4-3c-0.2-1.5-0.4-3-0.5-4.5c-0.1-1.1-0.3-2.2-0.4-3.3
			c-0.3-2.6-0.7-5.2-1-7.9c-0.2-1.4-0.4-2.8-0.5-4.2c-0.1-1.2-0.3-2.4-0.4-3.7c-0.2-1.6-0.4-3.2-0.5-4.8c-0.2-1.7-0.3-3.5-0.5-5.2
			c-0.1-1.4-0.2-2.7-0.4-4.1c-0.3-2.4-0.6-4.9-0.8-7.3c-0.3-2.4-0.6-4.7-0.9-7.1c-0.3-2-0.6-4-0.8-6.1c-0.1-0.8-0.2-1.5-0.3-2.3
			c-0.1-1-0.3-2.1-0.4-3.1c-0.1-1-0.2-2-0.4-3c-0.2-1.6-0.5-3.2-0.7-4.8c-0.3-2-0.6-4-0.9-6.1c-0.2-1.6-0.5-3.1-0.7-4.7
			c-0.3-2.4-0.7-4.7-1-7.1c-0.3-1.8-0.5-3.5-0.8-5.3c-0.3-1.8-0.6-3.7-0.9-5.5c-0.3-1.7-0.5-3.3-0.7-5c-0.1-0.8-0.2-1.6-0.3-2.3
			c-0.1-0.4-0.3-0.7-0.7-0.8c-0.5-0.2-1-0.4-1.5-0.6c-0.9-0.5-1.9-0.9-2.8-1.4C27.3,509.9,27,509.8,26.6,509.8z M31.2,542.3
			c-0.1,0.4-0.2,0.8-0.3,1.3c-0.1,1-0.2,2-0.3,3c-0.1,1-0.3,2-0.4,3c-0.2,1.5-0.3,3-0.5,4.5c0,0.2-0.1,0.4-0.1,0.6
			c-0.1,1.3-0.2,2.6-0.3,3.8c-0.1,1.1-0.2,2.1-0.3,3.2c-0.1,1.1-0.2,2.1-0.3,3.2c-0.1,1.2-0.3,2.4-0.4,3.7c-0.1,1.3-0.2,2.6-0.3,3.8
			c-0.2,1.9-0.5,3.8-0.8,5.7c-0.4,2.9-1,5.7-1.6,8.6c-0.2,1.2-0.5,2.5-0.7,3.7c-0.1,0.8-0.3,1.6-0.4,2.4c-0.2,1.7-0.5,3.3-0.9,4.9
			c0,0.2-0.1,0.4-0.1,0.6c-0.3,1.3-0.5,2.6-0.8,3.8c-0.3,1.3-0.6,2.6-0.9,3.8c-0.6,2.8-1.2,5.6-1.8,8.4c-0.9,3.7-1.6,7.4-2.3,11.1
			c-0.2,0.9-0.4,1.8-0.5,2.7c-0.3,1.3-0.5,2.6-0.8,3.9c-0.2,0.9-0.4,1.8-0.5,2.7c-0.2,1.2-0.5,2.3-0.7,3.5c-0.2,0.9-0.4,1.8-0.6,2.8
			c-0.2,1.3-0.5,2.5-0.7,3.8c-0.1,0.8-0.3,1.7-0.4,2.5c-0.2,1.2-0.4,2.4-0.6,3.6c-0.5,3-1,5.9-1.4,8.9c-0.2,1-0.3,2.1-0.4,3.1
			c-0.1,0.5-0.1,1-0.2,1.6c-0.1,1.1-0.2,2.3-0.3,3.4c-0.1,0.6-0.1,1.3-0.2,1.9c-0.1,1.1-0.2,2.2-0.3,3.3c0,0.1,0,0.2,0,0.2
			c0.1,0.4,0.2,0.6,0.6,0.7c0.1-0.2,0.3-0.3,0.4-0.5c0.4-0.4,0.9-0.9,1.3-1.3c1-0.9,2.1-1.8,3.2-2.7c1.1-0.9,2.2-1.9,3.2-3
			c0.4-0.4,0.7-0.7,1.2-0.9c0.4-0.1,0.6-0.4,0.6-0.9c0-0.4,0-0.8,0-1.3c0-1.2,0.1-2.4,0.1-3.5c0.1-1.3,0.2-2.5,0.3-3.8
			c0.2-1.7,0.3-3.5,0.5-5.2c0.1-1,0.2-2.1,0.3-3.1c0-0.1,0-0.2,0-0.3c0.1-0.6,0.1-1.3,0.2-1.9c0-0.2,0.1-0.5,0.1-0.7
			c0.1-1.2,0.2-2.5,0.3-3.7c0.1-1.2,0.2-2.4,0.3-3.6c0.1-1.1,0.2-2.2,0.3-3.4c0.1-1.1,0.3-2.3,0.4-3.4c0.1-0.8,0.2-1.7,0.3-2.5
			c0.1-1.1,0.3-2.3,0.4-3.4c0.3-2.3,0.6-4.5,0.9-6.8c0.3-2,0.5-4,0.8-6c0-0.2,0-0.3,0.1-0.5c0.2-1.4,0.3-2.8,0.5-4.1
			c0.1-1.1,0.3-2.2,0.4-3.3c0.2-1.3,0.3-2.6,0.5-3.9c0.4-2.6,0.7-5.3,1-8c0.2-1.9,0.4-3.8,0.6-5.7c0.1-1.4,0.3-2.8,0.4-4.2
			c0.2-1.8,0.4-3.5,0.5-5.3c0.1-1.2,0.2-2.4,0.3-3.6c0.1-1.3,0.2-2.7,0.4-4c0.1-1,0.2-2.1,0.3-3.1c0.2-1.7,0.4-3.4,0.6-5.1
			c0.2-1.8,0.5-3.5,0.7-5.3c0-0.4,0-0.8,0-1.2c-0.2-1.5-0.4-2.9-0.6-4.4c-0.1-1.1-0.2-2.2-0.3-3.3c-0.1-1.1-0.1-2.3-0.2-3.5
			C31.4,543.3,31.3,542.8,31.2,542.3z M27.5,508.8c1.3,0.4,2.5,1,3.7,1.6c0.2,0.1,0.4,0.2,0.7,0.3c0.3,0.1,0.6,0.1,0.9,0
			c0.1-0.1,0.3-0.1,0.4-0.2c1-0.5,2-1.1,3-1.7c1.6-0.9,3.2-1.9,4.9-2.9c2.6-1.5,5.3-2.9,8-4.3c1-0.5,2-1,3-1.5c1-0.5,2-1,3-1.5
			c1.7-0.8,3.4-1.6,5.1-2.3c1.7-0.8,3.4-1.5,5.1-2.3c1.8-0.8,3.6-1.6,5.4-2.4c2.6-1.2,5.3-2.5,7.9-3.7c1.7-0.8,3.5-1.6,5.2-2.5
			c3.6-1.8,7.2-3.5,10.8-5.5c2.3-1.2,4.6-2.5,6.9-3.7c1.7-0.9,3.5-1.7,5.3-2.5c1.3-0.5,2.5-1,3.8-1.5c0.7-0.2,1.4-0.5,2.1-0.6
			c0.5-0.1,0.9-0.2,1.3-0.5c1.2-0.7,2.5-1.5,3.7-2.2c0.6-0.4,1.3-0.6,2.1-0.7c0.6,0,1.1-0.1,1.7-0.2c0.1,0,0.2,0,0.2-0.2
			c-0.1-0.1-0.2-0.2-0.4-0.2c-1.1-0.4-2.1-0.8-3.2-1.1c-0.8-0.2-1.6-0.5-2.5-0.6c-0.4,0-0.8,0-1.1,0.2c-0.8,0.3-1.6,0.6-2.4,1
			c-0.6,0.3-1.3,0.5-1.9,0.8c-1.5,0.7-3,1.4-4.4,2c-1.1,0.5-2.2,0.9-3.3,1.4c-1.7,0.7-3.4,1.5-5,2.2c-2.4,1-4.8,2-7.2,3
			c-1.6,0.7-3.3,1.3-5,1.8c-1.1,0.4-2.2,0.7-3.3,1.2c-1.3,0.5-2.7,1.1-4,1.7c-1.6,0.7-3.2,1.5-4.7,2.3c-1.7,0.8-3.4,1.7-5.1,2.5
			c-0.3,0.1-0.5,0.2-0.8,0.4c-1.4,0.7-2.7,1.4-4.1,2.1c-3.3,1.7-6.6,3.3-9.8,5.1c-1.4,0.8-2.8,1.6-4.2,2.4c-1.3,0.7-2.6,1.5-4,2.2
			c-2,1.1-4,2.2-6,3.3c-0.5,0.3-1,0.5-1.4,0.8c-1.8,1-3.6,2-5.3,3.2c-0.6,0.4-1.2,0.8-1.8,1.2c-0.9,0.6-1.8,1.1-2.7,1.6
			C27.7,508.4,27.5,508.5,27.5,508.8z M136.7,592.8c-0.1,0-0.2,0-0.3,0.1c-0.3,0.2-0.6,0.4-0.9,0.6c-0.7,0.4-1.3,0.9-2,1.3
			c-0.3,0.2-0.4,0.5-0.3,0.8c0.1,0.4,0.1,0.7,0.2,1.1c0.2,1.4,0.4,2.8,0.5,4.2c0.1,1.4,0.3,2.7,0.4,4.1c0.1,1.4,0.2,2.8,0.3,4.2
			c0.1,1.1,0.1,2.3,0.2,3.4c0.2,2.5,0.7,5,1.3,7.5c0.2,0.9,0.4,1.7,0.6,2.6c0.4,1.5,0.7,3.1,1,4.6c0.3,1.5,0.6,3,0.9,4.5
			c0.3,1.5,0.6,2.9,0.9,4.4c0.4,1.7,0.8,3.4,1.1,5.1c0.2,0.8,0.3,1.6,0.5,2.4c0.1,0.6,0.4,1,1,1.3c0.6,0.2,1.1,0.5,1.7,0.8
			c0.7,0.3,1.4,0.7,2.1,1c0.3,0.2,0.5,0,0.5-0.3c0-0.1,0-0.3,0-0.4c-0.1-0.5-0.1-1.1-0.2-1.6c-0.3-1.6-0.5-3.2-0.8-4.8
			c-0.6-2.9-1.4-5.7-2.2-8.6c-0.4-1.4-0.8-2.9-1.1-4.3c-0.5-2.2-1-4.4-1.5-6.6c-0.4-1.7-0.7-3.4-1-5.1c-0.2-1.3-0.4-2.6-0.6-3.9
			c-0.3-1.5-0.4-3-0.6-4.4c-0.2-1.7-0.4-3.4-0.5-5.1c-0.1-0.7-0.1-1.4-0.2-2c-0.1-1.2-0.3-2.4-0.4-3.7c-0.1-0.8-0.2-1.6-0.3-2.4
			C136.9,593.2,136.9,593,136.7,592.8z M143.9,584.3c-0.2,0.2-0.4,0.3-0.5,0.4c-1.2,1-2.5,2-3.7,2.9c-1.1,0.8-2.2,1.5-3.2,2.2
			c-0.9,0.7-1.9,1.3-2.8,2c-0.7,0.6-1.4,1.1-2.3,1.4c-0.2,0.1-0.5,0.3-0.6,0.4c-1.2,1.2-2.7,2.1-4,3.2c-0.9,0.7-1.8,1.3-2.8,1.9
			c-2,1.3-4.1,2.5-6.1,3.7c-2,1.2-4,2.4-5.9,3.7c-1.7,1.1-3.4,2.3-5.1,3.5c-2.7,2-5.4,3.9-8,5.9c-2,1.5-3.9,3-5.9,4.4
			c-1.7,1.3-3.5,2.5-5.3,3.7c-2.7,2-5.5,3.9-8.3,5.7c-1.3,0.8-2.7,1.7-4,2.5c-0.4,0.3-0.8,0.6-1.2,1c-0.3,0.2-0.5,0.6-0.5,0.9
			c-0.1,0.7-0.1,1.4-0.1,2c0,0.1,0.1,0.2,0.1,0.4c0.2-0.1,0.4-0.1,0.5-0.2c0.4-0.2,0.7-0.5,1.1-0.7c2-1.1,3.9-2.3,5.7-3.6
			c0.1-0.1,0.3-0.2,0.4-0.3c1.2-0.9,2.5-1.8,3.7-2.7c1.5-1.2,3-2.4,4.5-3.6c2.2-1.7,4.5-3.4,6.7-5.1c1.9-1.4,3.8-2.9,5.7-4.3
			c1.7-1.3,3.4-2.7,5.1-4c1-0.8,2-1.5,3-2.2c1.7-1.3,3.4-2.6,5.2-3.8c1.5-1,2.9-2,4.3-3c1.6-1.2,3.3-2.4,4.9-3.5
			c1.1-0.8,2.2-1.5,3.3-2.2c1.5-1,3-1.9,4.5-2.9c0.2-0.1,0.4-0.2,0.6-0.3c0.5-0.2,1-0.4,1.3-0.8c0.1-0.2,0.3-0.3,0.5-0.4
			c0.7-0.4,1.4-0.9,2-1.3c2-1.4,4-2.7,6.1-4c0.3-0.2,0.6-0.5,0.9-0.7c0.1-0.1,0.3-0.3,0.3-0.4C144,585.5,144,585,143.9,584.3z
			 M64.8,631.6c0.1,0.6,0.2,1,0.2,1.4c0.1,0.5,0.3,0.9,0.9,1c0.2,0,0.3,0.1,0.5,0.1c0.4,0.1,0.8,0.2,1.1,0.4
			c0.8,0.4,1.5,0.8,2.3,1.1c0.2,0.1,0.5,0.2,0.7,0.4c0.3,0.2,0.6,0.3,0.9,0.4c0.1,0.1,0.3,0,0.5,0.1c0-0.2,0.1-0.3,0.1-0.5
			c0-0.5,0.1-0.9,0.1-1.4c0-0.5,0-0.6-0.5-0.8c-0.8-0.3-1.6-0.6-2.4-0.7c-1.2-0.3-2.4-0.7-3.6-1.2C65.4,631.8,65.2,631.7,64.8,631.6
			z M74.4,625.7c0-0.1-0.1-0.2-0.1-0.2c-1-0.7-2.1-1.1-3.3-1.5c-0.1,0-0.3,0-0.4,0.1c-0.3,0.1-0.5,0.3-0.6,0.6
			c-0.1,0.2-0.1,0.4,0.1,0.6c0.1,0.1,0.2,0.2,0.4,0.2c0.5,0.1,0.8,0.5,1.2,0.7c0.3,0.2,0.6,0.4,0.9,0.5c1,0.4,0.9,0.5,1.4-0.5
			C74.2,626.1,74.3,625.9,74.4,625.7z M94.3,608c0.4,0.1,0.6,0.2,0.9,0.4c0.7,0.3,1.5,0.5,2,1.1c0.2,0.3,0.6,0.3,0.9,0.3
			c0.3,0,0.9-0.4,0.9-0.8c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.3-0.2c-0.7-0.4-1.5-0.9-2.2-1.3c-0.1-0.1-0.2-0.1-0.4-0.1
			C95.4,607.2,94.8,607.4,94.3,608z M81.1,621.4c-0.1-0.1-0.2-0.2-0.3-0.2c-0.4-0.2-0.9-0.4-1.3-0.5c-0.3-0.1-0.6-0.2-0.8-0.5
			c-0.6-0.7-1.1-0.9-1.9-0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.1,0.2-0.1,0.4c0.2,0.1,0.3,0.2,0.5,0.2c0.5,0.1,1,0.4,1.5,0.7
			c0.5,0.3,0.9,0.6,1.4,0.9c0.2,0.1,0.3,0.2,0.5,0C80.5,622.1,80.8,621.8,81.1,621.4z M91.8,614.9c-0.1-0.2-0.1-0.3-0.1-0.4
			c-0.1-0.3-0.3-0.5-0.6-0.6c-0.3-0.1-0.6-0.3-0.8-0.4c-0.4-0.3-0.8-0.6-1.2-0.9c-0.2-0.2-0.5-0.2-0.7-0.1c-0.2,0.1-0.4,0.2-0.5,0.3
			c-0.2,0.2-0.2,0.4,0,0.5c0.2,0.1,0.4,0.3,0.5,0.3c0.5,0.1,0.8,0.5,1.1,0.9c0.2,0.4,0.5,0.7,0.9,1c0.2,0.2,0.4,0.2,0.6,0
			C91.2,615.4,91.5,615.1,91.8,614.9z M87.4,617.8c0.2-0.2,0.4-0.3,0.6-0.5c0.3-0.3,0.3-0.6-0.1-0.9c-0.1,0-0.1-0.1-0.2-0.1
			c-0.7-0.4-1.5-0.8-2.2-1.4c-0.2-0.1-0.3-0.1-0.5,0c-0.1,0.1-0.2,0.1-0.3,0.2c-0.5,0.4-0.5,0.7,0.1,0.9c0.6,0.2,1.1,0.6,1.5,0.9
			C86.6,617.2,87,617.5,87.4,617.8z M62.5,630.7c0,0.1-0.1,0.3-0.1,0.4c0,0.4,0.1,0.9,0.1,1.3c0,0.4,0.2,0.7,0.7,0.7
			c0.1,0,0.2,0,0.2,0.1c0.3,0,0.4-0.1,0.4-0.4c-0.1-0.5-0.2-1.1-0.3-1.6c0-0.2-0.1-0.3-0.3-0.5C63,630.6,62.8,630.6,62.5,630.7z
			 M76,626.7c0,0-0.1-0.1-0.2-0.1c-0.3,0-0.7,0.2-0.7,0.4c0,0.1,0,0.3,0,0.4c0,0.1,0.1,0.2,0.3,0.2C75.7,627.5,76,627.1,76,626.7z
			 M82.3,622.5c0-0.1-0.2-0.3-0.3-0.2c-0.4,0.1-0.7,0.4-0.9,0.7c0,0.1,0,0.2,0,0.2c0,0,0.1,0.1,0.2,0.1c0.4-0.1,0.7-0.3,0.9-0.6
			C82.3,622.6,82.3,622.5,82.3,622.5z M100.4,609.9c0,0-0.1-0.1-0.2-0.1c-0.4,0-0.7,0.3-0.8,0.7c0,0,0,0.1,0,0.1
			c0.1,0,0.1,0.1,0.2,0.1c0.3-0.1,0.6-0.3,0.7-0.6C100.4,610,100.4,610,100.4,609.9z M91.8,616.2c0,0,0.1,0.1,0.1,0.1
			c0.2,0,0.4-0.1,0.6-0.3c0,0,0-0.2-0.1-0.2C92.1,615.7,91.8,615.9,91.8,616.2z M89,618.2c0,0-0.1-0.1-0.1-0.1
			c-0.2,0-0.4,0.1-0.4,0.3c0,0,0,0.1,0.1,0.1C88.7,618.6,88.9,618.4,89,618.2z"/>
                            <path className="st14" d="M123.5,469.1c0.1,0.2,0.1,0.3,0.2,0.5c0.1,0.4,0.1,0.7,0.2,1.1c0.3,2.6,1,5.2,1.7,7.8
			c0.4,1.4,0.7,2.8,1.1,4.2c0.4,1.6,0.8,3.1,1.2,4.7c0.3,1.3,0.6,2.7,0.9,4c0.3,1.6,0.6,3.1,0.9,4.7c0.3,1.7,0.6,3.5,0.9,5.2
			c0.2,1.1,0.4,2.3,0.6,3.4c0.2,1.5,0.5,3,0.7,4.4c0.2,1.6,0.5,3.3,0.7,4.9c0.3,2.2,0.7,4.4,1.2,6.6c0.5,2.2,1,4.3,1.5,6.5
			c0.3,1.4,0.7,2.8,1.1,4.3c0.4,1.6,0.8,3.2,1.2,4.7c0.4,1.4,0.7,2.9,1.1,4.4c0.4,1.5,0.7,2.9,1.1,4.4c0.4,1.5,0.7,3.1,1.1,4.6
			c0.3,1.4,0.6,2.8,1,4.2c0.4,1.7,0.7,3.3,1,5c0.3,1.4,0.6,2.8,0.9,4.2c0.4,2.1,0.8,4.2,1.2,6.2c0.3,1.7,0.7,3.4,1,5.1
			c0.3,1.6,0.7,3.2,1,4.8c0.4,2.1,0.9,4.3,1.3,6.4c0.3,1.4,0.5,2.9,0.7,4.3c0.2,1.2,0.4,2.3,0.6,3.5c0.2,1.4,0.4,2.8,0.7,4.3
			c0.3,1.6,0.5,3.2,0.8,4.7c0.2,1.4,0.5,2.9,0.7,4.3c0.2,1.7,0.5,3.3,0.7,5c0.3,2.3,0.7,4.5,1.1,6.7c0.4,2.2,0.8,4.3,1.2,6.5
			c0.3,1.4,0.5,2.7,0.8,4.1c0.3,1.4,0.6,2.8,0.9,4.2c0.3,1.6,0.7,3.1,1,4.7c0.1,0.7,0.2,1.4,0.4,2.1c0.1,0.4-0.1,0.7-0.4,0.9
			c-0.6,0.4-1.2,0.8-1.9,1.2c-1,0.6-2,1.2-3,1.7c-1.2,0.7-2.4,1.6-3.5,2.5c-0.2,0.2-0.4,0.3-0.7,0.5c-0.1-0.2-0.2-0.3-0.2-0.5
			c-0.2-1.1-0.3-2.3-0.4-3.4c-0.3-2.3-0.8-4.5-1.3-6.7c-0.6-2.5-1.3-5-2-7.5c-0.4-1.4-0.7-2.9-1-4.3c-0.3-1.4-0.7-2.8-0.9-4.2
			c-0.5-2.4-1.1-4.8-1.4-7.2c-0.2-1.5-0.4-2.9-0.7-4.3c0-0.1,0-0.3-0.1-0.4c-0.2-1.8-0.4-3.5-0.5-5.3c-0.2-1.9-0.4-3.8-0.6-5.6
			c-0.2-1.6-0.4-3.1-0.6-4.7c-0.1-0.5,0.1-0.8,0.5-1c1.5-0.9,2.9-1.9,4.4-2.8c0.5-0.3,0.9-0.7,1.4-1c0.5-0.4,0.8-1,0.8-1.6
			c0-0.5,0-0.9,0-1.4c0-0.6,0-1.2,0.2-1.8c0-0.1,0.1-0.3,0.1-0.5c0-0.3-0.2-0.6-0.5-0.6c-0.6,0-1-0.3-1.5-0.7
			c-1.5-1.2-3.2-2.1-5-2.7c-0.4-0.1-0.9-0.3-1.2-0.7c-0.2-0.2-0.4-0.3-0.7-0.2c-0.4,0.2-0.8,0.4-1.2,0.7c-0.6,0.6-1.3,1.2-2,1.8
			c-1.5,1.3-3,2.7-4.3,4.2c-1.2,1.3-2.5,2.6-3.9,3.8c-2.4,2.1-4.8,4-7.3,5.9c-2.5,1.9-5,3.8-7.6,5.6c-2.5,1.8-5.1,3.6-7.6,5.3
			c-0.9,0.7-1.9,1.3-2.9,1.9c-0.2,0.1-0.3,0.2-0.5,0.3c-0.4,0.3-0.8,0.3-1.2,0c-0.3-0.2-0.7-0.4-1-0.6c-0.7-0.5-1.5-0.4-2.2-0.1
			c-0.7,0.3-1.2,0.8-1.5,1.5c-0.3,0.6-0.2,0.8,0.4,1.1c0.3,0.2,0.6,0.3,0.9,0.5c0.1,0,0.2,0.1,0.3,0.2c-0.2,0.2-0.2,0.3-0.3,0.4
			c-1.2,0.8-2.4,1.5-3.7,2.3c-0.3,0.2-0.6,0.2-0.9,0c-0.2-0.1-0.4-0.3-0.6-0.4c-0.5-0.4-1-0.4-1.6-0.2c-0.8,0.3-1.2,0.9-1.5,1.6
			c-0.1,0.2,0,0.4,0.2,0.6c0.1,0.2,0.3,0.3,0.4,0.4c0.1,0.1,0.1,0.2,0.2,0.3c-0.2,0-0.4,0-0.5,0c-0.3-0.1-0.6-0.3-0.9-0.5
			c-0.6-0.4-1.1-0.3-1.6,0.1c-0.3,0.3-0.6,0.6-0.9,0.9c-0.3,0.3-0.3,0.7-0.1,1c0.1,0.2,0.2,0.5,0.3,0.7c-0.1,0.1-0.2,0.3-0.3,0.3
			c-0.9,0.6-1.9,1.2-2.8,1.9c-0.4,0.2-0.7,0.3-1.1,0.1c-0.3-0.2-0.7-0.3-1-0.5c-0.3-0.2-0.7-0.3-1-0.2c-0.8,0.2-1.5,0.7-1.9,1.4
			c-0.1,0.2-0.2,0.4-0.2,0.6c-0.1,0.4,0,0.8,0.5,1c0.2,0.1,0.3,0.2,0.5,0.4c-0.1,0.1-0.2,0.2-0.3,0.3c-0.7,0.5-1.3,0.9-2,1.4
			c-0.4,0.3-0.7,0.3-1.1,0.1c-0.5-0.3-1.1-0.4-1.7-0.6c-0.4-0.1-0.7,0-1,0.2c-0.4,0.3-0.7,0.6-0.9,1c-0.2,0.5-0.3,1.1,0.1,1.6
			c0.1,0.1,0.1,0.3,0.2,0.4c-0.1,0.3-0.3,0.4-0.5,0.5c-1.4,0.9-2.9,1.7-4.3,2.6c-0.3,0.2-0.5,0.2-0.8,0c-0.4-0.2-0.9-0.4-1.4-0.6
			c-0.1,0-0.1-0.1-0.2-0.1c-0.6-0.2-0.9,0.1-0.9,0.7c0.1,0.4,0,0.8,0,1.2c0,0.4,0,0.8,0,1.3c0,0.4,0,0.8,0.1,1.3c0,0.8,0.3,1,1,1.2
			c0.4,0.1,0.8,0.2,1.2,0.4c0.4,0.1,0.5,0.4,0.6,0.7c0.1,0.4,0.1,0.8,0.2,1.2c0.4,2.2,0.7,4.5,1.1,6.7c0.2,1.1,0.4,2.3,0.6,3.4
			c0.2,1.3,0.5,2.6,0.7,3.9c0.3,1.7,0.6,3.4,0.9,5.1c0.3,1.6,0.6,3.1,0.9,4.7c0.5,2.3,1,4.6,1.5,6.9c0.2,1.1,0.4,2.2,0.6,3.3
			c0.1,0.6,0.2,1.2,0.3,1.9c0.2,1,0.4,2,0.6,3c0.3,1.3,0.5,2.7,0.8,4c0,0.1,0,0.3,0.1,0.4c0.1,0.6-0.1,1.1-0.5,1.5
			c-1.8,1.8-3.7,3.5-5.8,4.9c-1.6,1.1-3.2,2.4-4.7,3.7c-0.7,0.6-1.3,1.1-2,1.7c-0.1,0.1-0.2,0.1-0.4,0.2c-0.1-0.1-0.2-0.2-0.2-0.4
			c-0.2-1.8-0.4-3.6-0.7-5.4c-0.1-1.1-0.3-2.1-0.5-3.2c-0.3-1.8-0.6-3.6-1-5.3c-0.3-1.6-0.6-3.2-0.9-4.8c-0.3-1.5-0.6-3.1-0.9-4.6
			c-0.4-2.3-0.8-4.6-1.2-6.9c-0.2-1.6-0.4-3.2-0.6-4.8c-0.2-1.1-0.3-2.3-0.5-3.4c-0.2-1.3-0.3-2.5-0.5-3.8c-0.4-2.7-0.8-5.4-1.2-8.2
			c-0.3-2-0.6-4-0.9-6c-0.3-2-0.6-3.9-0.8-5.9c-0.1-1.1-0.3-2.1-0.4-3.2c-0.2-1.6-0.5-3.2-0.7-4.8c-0.2-1.1-0.3-2.3-0.4-3.4
			c-0.1-0.8-0.2-1.7-0.3-2.5c-0.2-1.3-0.3-2.5-0.5-3.8c-0.2-1.7-0.4-3.3-0.6-5c-0.3-2.5-0.6-4.9-0.9-7.4c-0.2-1.7-0.4-3.5-0.6-5.2
			c-0.2-1.5-0.3-3.1-0.4-4.6c-0.3-2.6-0.5-5.1-0.8-7.7c-0.2-2.2-0.5-4.5-0.7-6.7c-0.3-2.4-0.6-4.7-1-7.1c-0.3-2.6-0.7-5.1-1-7.7
			c-0.1-1-0.3-2.1-0.4-3.1c-0.2-1.4-0.3-2.8-0.5-4.2c-0.1-1.1-0.3-2.2-0.5-3.3c-0.1-1-0.3-2-0.5-3c-0.2-1.2-0.4-2.5-0.6-3.7
			c-0.2-1.4-0.4-2.8-0.7-4.3c-0.2-1.2-0.4-2.4-0.5-3.6c-0.3-1.7-0.5-3.5-0.8-5.2c-0.3-1.7-0.5-3.4-0.8-5c-0.4-2.5-0.8-4.9-1.2-7.4
			c-0.2-1.1-0.4-2.3-0.6-3.4c-0.1-0.6-0.1-1.2-0.2-1.8c0-0.2,0-0.4,0.2-0.5c0.2-0.2,0.4-0.3,0.6-0.5c2.6-1.3,5.1-2.7,7.6-4.2
			c1.1-0.6,2.3-1.2,3.5-1.8c1.8-0.9,3.6-1.9,5.4-2.8c1.6-0.8,3.3-1.6,4.9-2.4c2.4-1.1,4.8-2.2,7.1-3.3c2.2-1,4.5-1.9,6.7-2.9
			c1.7-0.7,3.3-1.5,4.9-2.3c1.3-0.6,2.7-1.2,4-1.9c1.2-0.6,2.4-1.1,3.5-1.7c2.8-1.4,5.6-2.7,8.3-4.1c1.6-0.8,3.3-1.7,4.9-2.5
			c1-0.5,2-1.1,3-1.6c1.4-0.8,2.8-1.5,4.2-2.3c2.7-1.4,5.5-2.6,8.4-3.6c0.6-0.2,1.2-0.3,1.7-0.6c0.6-0.3,1.2-0.6,1.8-1
			c0.7-0.4,1.4-0.8,2.1-1.2c0.4-0.2,0.8-0.4,1.2-0.7c0.7-0.5,1.5-0.7,2.4-0.8c1-0.1,2.1-0.3,3.1-0.4
			C123.3,469.1,123.3,469.1,123.5,469.1z M118.7,477.7c-0.1,0-0.3,0.1-0.5,0.2c-1,0.5-1.9,0.9-2.9,1.4c-0.7,0.4-1.5,0.8-2.2,1.2
			c-2.1,1.1-4.2,2.2-6.3,3.3c-2.2,1.2-4.5,2.4-6.7,3.6c-2.1,1.1-4.1,2.2-6.2,3.3c-2.4,1.3-4.8,2.6-7.2,4c-1.8,1-3.7,1.8-5.5,2.7
			c-1.3,0.6-2.6,1.2-3.9,1.8c-1.7,0.8-3.4,1.6-5.1,2.4c-2.2,1-4.4,2.1-6.6,3.1c-1.4,0.6-2.8,1.3-4.1,2c-1.7,0.9-3.4,1.7-5.1,2.6
			c-1.8,0.9-3.5,1.9-5.2,2.9c-2,1.1-4,2.2-6,3.3c-0.3,0.2-0.7,0.4-1.1,0.4c-0.4,0-0.6,0.2-0.6,0.6c0,0.2,0,0.5,0,0.7
			c0.1,0.6,0.1,1.2,0.2,1.8c0.2,1.8,0.4,3.6,0.6,5.5c0.3,1.7,0.4,3.3,0.7,5c0.2,1.2,0.3,2.4,0.5,3.7c0.1,1,0.2,1.9,0.3,2.9
			c0.1,0.9,0.2,1.9,0.3,2.8c0.1,1.2,0.3,2.5,0.4,3.7c0.1,0.8,0.2,1.7,0.3,2.5c0.2,1.2,0.3,2.3,0.5,3.5c0.2,1.6,0.5,3.1,0.8,4.7
			c0.3,1.8,0.6,3.6,0.9,5.4c0.2,1.1,0.4,2.2,0.6,3.3c0.3,1.4,0.5,2.8,0.8,4.3c0.4,1.9,0.7,3.7,1.1,5.5c0.2,1,0.4,2,0.6,3
			c0.3,1.4,0.7,2.9,0.9,4.3c0.4,2.2,0.7,4.4,1.1,6.7c0.5,2.8,0.9,5.6,1.4,8.4c0.3,2,0.8,4.1,1.1,6.1c0.2,1.6,0.4,3.2,0.6,4.8
			c0.2,1.6,0.3,3.1,0.4,4.7c0.1,1.3,0.2,2.5,0.3,3.8c0.1,1.2,0.1,2.4,0.2,3.5c0.1,1.9,0.4,3.7,0.5,5.6c0,0.2,0,0.5,0.1,0.7
			c0.1,0.3,0.3,0.6,0.4,0.9c0.3,0.4,0.6,0.4,1,0.1c0.2-0.1,0.3-0.3,0.4-0.4c0.8-0.8,1.6-1.5,2.5-2.2c2-1.5,4.2-2.7,6.3-4.1
			c1.7-1.1,3.4-2.1,5-3.2c4.3-2.9,8.6-5.8,13-8.6c1.5-1,3-1.9,4.5-3c1.7-1.1,3.3-2.3,5-3.5c0.3-0.2,0.7-0.5,1-0.7c1.4-1,2.7-2,4.1-3
			c2.5-1.8,5-3.6,7.4-5.6c3-2.4,5.9-4.8,8.9-7.2c2.4-1.9,4.7-3.9,7.2-5.7c1.7-1.2,3.4-2.5,5.2-3.7c1.1-0.8,2.3-1.6,3.4-2.4
			c0.8-0.5,1.5-1.2,2.3-1.6c0.1-0.1,0.2-0.1,0.3-0.2c0.4-0.4,0.5-0.8,0.1-1.3c-0.1-0.2-0.2-0.3-0.2-0.6c0-0.3-0.1-0.5-0.1-0.8
			c-0.1-2.3-0.5-4.5-0.8-6.7c-0.3-2-0.7-4.1-1.1-6.1c-0.3-1.4-0.6-2.9-0.8-4.3c-0.4-2.3-0.9-4.7-1.3-7c-0.3-1.4-0.6-2.8-0.9-4.2
			c-0.4-2.1-0.8-4.3-1.1-6.4c-0.3-1.6-0.5-3.2-0.7-4.7c-0.3-2.3-0.7-4.7-0.9-7c-0.2-2.3-0.5-4.6-0.7-7c-0.2-1.9-0.5-3.7-0.9-5.5
			c-0.3-1.3-0.6-2.6-0.9-3.8c-0.6-2.4-1.1-4.8-1.7-7.2c-0.3-1.3-0.7-2.7-1.1-4c-0.8-3-1.6-6-2.4-9c-0.1-0.3-0.2-0.7-0.2-1
			c-0.2-1-0.4-2-0.6-2.9c-0.1-0.3-0.1-0.6-0.3-0.9C119.6,477.9,119.2,477.7,118.7,477.7z"/>
                            <path className="st14" d="M26.3,521.2c0,0.1,0.1,0.3,0.1,0.4c0.2,0.9,0.5,1.8,0.7,2.7c0.5,1.8,1,3.6,1.5,5.4c0.4,1.6,0.8,3.3,1.2,4.9
			c0.2,0.8,0.2,1.6,0.1,2.4c-0.1,0.9-0.2,1.7-0.2,2.6c-0.1,1-0.1,2-0.2,3c-0.1,1.1-0.2,2.2-0.3,3.3c-0.3,2.7-0.6,5.4-0.9,8.1
			c-0.1,1.1-0.2,2.2-0.3,3.3c0,0.2-0.1,0.4-0.1,0.6c-0.1,0.6-0.1,1.1-0.2,1.7c0,0.1,0,0.2,0,0.3c-0.1,1.1-0.2,2.1-0.3,3.2
			c-0.1,1-0.2,2-0.3,3c-0.1,1.3-0.2,2.6-0.4,3.9c-0.1,1.1-0.2,2.2-0.3,3.4c-0.1,1-0.3,2-0.4,3c-0.1,0.8-0.2,1.6-0.3,2.3
			c-0.2,1-0.3,2-0.5,2.9c-0.8,3.9-1.5,7.7-2.1,11.6c-0.3,2-0.8,4-1.2,6c-0.3,1.3-0.5,2.6-0.8,3.8c-0.3,1.3-0.6,2.5-0.8,3.8
			c-0.2,0.9-0.4,1.8-0.6,2.8c-0.3,1.3-0.6,2.7-0.9,4c-0.2,1.1-0.5,2.2-0.8,3.3c-0.1,0.6-0.2,1.2-0.4,1.8c-0.1,0.4-0.2,0.7-0.3,1.1
			c-0.2,1.3-0.5,2.6-0.7,3.9c-0.2,1.1-0.4,2.2-0.6,3.2c-0.6,3.1-1.2,6.2-1.9,9.2c-0.3,1.7-0.7,3.3-1,5c-0.4,2.5-0.8,5-1.2,7.5
			c-0.2,1-0.3,2-0.5,3c-0.3,1.5-0.5,3-0.7,4.6c-0.2,1.1-0.4,2.1-0.5,3.2c-0.2,1.3-0.3,2.6-0.4,3.9c-0.2,1.9-0.4,3.7-0.5,5.6
			c-0.1,1-0.2,2.1-0.3,3.1c0,0.2,0,0.4,0,0.6c0,0.3-0.2,0.4-0.6,0.3c-0.1,0-0.1-0.1-0.2-0.1c-0.8-0.4-1.6-0.7-2.4-1.1
			c-0.9-0.5-1.9-0.9-2.8-1.4c-0.4-0.2-0.9-0.4-1.4-0.5c-0.1,0-0.2-0.1-0.3-0.1c0-0.1,0-0.3,0.1-0.4c0.6-1,0.9-2.1,1.1-3.3
			c0-0.1,0-0.3,0.1-0.4c0.2-1.4,0.5-2.7,0.7-4.1c0.2-1.2,0.3-2.3,0.5-3.5c0-0.2,0.1-0.4,0.1-0.6c0-0.2,0.1-0.5,0.1-0.7
			c0.1-1.2,0.2-2.4,0.3-3.6c0.1-0.5,0.1-1,0.2-1.6c0.3-2.1,0.5-4.2,0.8-6.2c0.2-1.9,0.5-3.7,0.7-5.6c0.1-0.8,0.2-1.5,0.4-2.2
			c0.2-1.2,0.3-2.4,0.5-3.6c0-0.3,0.1-0.5,0.1-0.8c0.1-0.6,0.2-1.2,0.3-1.9c0-0.1,0-0.3,0.1-0.4c0.4-2.1,0.8-4.2,1.1-6.3
			c0.8-4.4,1.6-8.8,2.4-13.1c0.2-1.2,0.5-2.5,0.8-3.7c0.4-2.1,0.8-4.2,1.1-6.4c0.1-1.1,0.4-2.2,0.5-3.3c0.2-1.3,0.5-2.7,0.7-4
			c0.2-1.2,0.4-2.3,0.6-3.5c0.5-2.9,1-5.8,1.5-8.7c0.3-2.1,0.6-4.2,0.9-6.3c0.2-1.3,0.3-2.7,0.5-4c0.1-1.1,0.3-2.1,0.5-3.2
			c0.2-1,0.3-2,0.5-3c0.4-2.2,0.8-4.3,1.2-6.5c0.4-2.3,0.9-4.5,1.3-6.8c0.2-1.1,0.4-2.3,0.6-3.4c0.2-1.4,0.5-2.7,0.7-4.1
			c0.1-0.3,0.2-0.7,0.2-1c0.2-1.4,0.5-2.7,0.7-4.1c0.2-1.1,0.4-2.2,0.5-3.3c0.2-1.3,0.4-2.6,0.6-3.9c0.1-1,0.3-2,0.5-3
			c0.3-2.2,0.6-4.4,0.9-6.6c0.1-0.6,0.2-1.3,0.4-1.9C26.2,521.2,26.2,521.2,26.3,521.2z"/>
                            <path className="st14" d="M26.6,509.8c0.4-0.1,0.6,0.1,0.9,0.2c0.9,0.5,1.9,0.9,2.8,1.4c0.5,0.2,1,0.5,1.5,0.6
			c0.4,0.1,0.7,0.4,0.7,0.8c0.1,0.8,0.3,1.5,0.3,2.3c0.2,1.7,0.4,3.3,0.7,5c0.3,1.8,0.6,3.7,0.9,5.5c0.3,1.8,0.5,3.5,0.8,5.3
			c0.3,2.4,0.7,4.7,1,7.1c0.2,1.6,0.5,3.1,0.7,4.7c0.3,2,0.6,4,0.9,6.1c0.2,1.6,0.5,3.2,0.7,4.8c0.1,1,0.2,2,0.4,3
			c0.1,1,0.3,2.1,0.4,3.1c0.1,0.8,0.2,1.5,0.3,2.3c0.3,2,0.6,4,0.8,6.1c0.3,2.4,0.6,4.7,0.9,7.1c0.3,2.4,0.6,4.9,0.8,7.3
			c0.1,1.4,0.3,2.7,0.4,4.1c0.2,1.7,0.3,3.5,0.5,5.2c0.2,1.6,0.3,3.2,0.5,4.8c0.1,1.2,0.3,2.4,0.4,3.7c0.2,1.4,0.3,2.8,0.5,4.2
			c0.3,2.6,0.7,5.3,1,7.9c0.1,1.1,0.3,2.2,0.4,3.3c0.2,1.5,0.4,3,0.5,4.5c0.1,1,0.2,2,0.4,3c0.2,1.8,0.5,3.7,0.8,5.5
			c0.3,2.3,0.7,4.6,1,6.9c0.2,1.5,0.4,3,0.7,4.5c0.2,1.3,0.4,2.6,0.6,3.9c0.2,1.4,0.4,2.9,0.6,4.3c0.2,1.8,0.5,3.6,0.7,5.5
			c0.3,1.9,0.5,3.8,0.8,5.7c0.2,1.5,0.5,3.1,0.7,4.6c0.2,1.3,0.4,2.5,0.6,3.8c0.3,1.7,0.7,3.4,1,5.2c0.5,2.3,0.8,4.6,1.2,6.9
			c0.2,1.2,0.5,2.4,0.7,3.5c0.2,1.4,0.4,2.9,0.6,4.4c0.2,1.2,0.3,2.3,0.5,3.5c0.2,1,0,1.2-1,0.8c-1.4-0.6-2.8-1-4.1-1.5
			c-0.4-0.1-0.8-0.3-0.8-0.8c-0.4-0.5-0.4-1-0.5-1.6c-0.1-1.4-0.3-2.7-0.4-4.1c-0.1-2-0.3-4-0.7-5.9c-0.2-1-0.4-2-0.5-2.9
			c-0.2-1.6-0.5-3.2-0.7-4.8c-0.3-2.4-0.6-4.7-0.9-7.1c-0.1-1.2-0.2-2.5-0.3-3.8c-0.1-1.5-0.3-2.9-0.4-4.4c-0.1-1.2-0.2-2.3-0.3-3.5
			c-0.2-2.5-0.5-5-0.8-7.5c-0.2-1.6-0.3-3.1-0.5-4.7c-0.2-2.5-0.5-5-0.9-7.4c-0.1-0.9-0.2-1.7-0.3-2.6c-0.2-1.8-0.5-3.6-0.8-5.4
			c-0.3-1.6-0.5-3.3-0.7-4.9c-0.2-2.4-0.6-4.8-1-7.2c-0.4-2.4-0.8-4.7-1.2-7.1c-0.3-1.6-0.6-3.2-0.8-4.8c-0.2-1.1-0.3-2.2-0.4-3.3
			c-0.1-1.1-0.3-2.1-0.4-3.2c-0.1-0.6-0.1-1.3-0.2-1.9c-0.1-1.1-0.2-2.1-0.2-3.2c-0.1-1.1-0.1-2.1-0.1-3.2c-0.1-1.7-0.1-3.5-0.2-5.2
			c0-1-0.1-2-0.2-3.1c-0.2-1.6-0.4-3.1-0.6-4.7c-0.2-1.7-0.5-3.3-0.8-5c-0.2-1.1-0.5-2.1-0.7-3.1c-0.3-1.4-0.6-2.8-0.9-4.2
			c-0.2-1.2-0.5-2.4-0.7-3.6c-0.1-0.4-0.1-0.7-0.1-1.1c0-0.3,0-0.6-0.2-0.9c-0.1-0.2-0.1-0.4-0.2-0.6c-0.5-3.5-0.8-7-1-10.5
			c-0.1-1.9-0.4-3.7-0.8-5.5c-0.6-2.4-1.1-4.9-1.7-7.3c-0.5-2.2-1.1-4.4-1.8-6.5c-0.4-1.3-0.7-2.5-1-3.8c0-0.1,0-0.2-0.1-0.3
			c0-0.9-0.1-1.8-0.1-2.7c0-1,0-2.1-0.1-3.1c-0.1-0.9-0.3-1.9-0.4-2.8C26.8,510.7,26.7,510.3,26.6,509.8z"/>
                            <path className="st14" d="M31.2,542.3c0.1,0.5,0.1,1,0.2,1.6c0.1,1.1,0.1,2.3,0.2,3.5c0.1,1.1,0.2,2.2,0.3,3.3
			c0.2,1.5,0.4,2.9,0.6,4.4c0,0.4,0.1,0.8,0,1.2c-0.2,1.8-0.5,3.5-0.7,5.3c-0.2,1.7-0.4,3.4-0.6,5.1c-0.1,1-0.2,2.1-0.3,3.1
			c-0.1,1.3-0.2,2.7-0.4,4c-0.1,1.2-0.2,2.4-0.3,3.6c-0.2,1.8-0.4,3.5-0.5,5.3c-0.1,1.4-0.3,2.8-0.4,4.2c-0.2,1.9-0.4,3.8-0.6,5.7
			c-0.3,2.7-0.6,5.3-1,8c-0.2,1.3-0.3,2.6-0.5,3.9c-0.1,1.1-0.3,2.2-0.4,3.3c-0.2,1.4-0.3,2.8-0.5,4.1c0,0.2,0,0.3-0.1,0.5
			c-0.3,2-0.5,4-0.8,6c-0.3,2.3-0.6,4.5-0.9,6.8c-0.2,1.1-0.3,2.3-0.4,3.4c-0.1,0.8-0.2,1.7-0.3,2.5c-0.1,1.1-0.3,2.3-0.4,3.4
			c-0.1,1.1-0.2,2.2-0.3,3.4c-0.1,1.2-0.2,2.4-0.3,3.6c-0.1,1.2-0.2,2.5-0.3,3.7c0,0.2-0.1,0.5-0.1,0.7c-0.1,0.6-0.1,1.3-0.2,1.9
			c0,0.1,0,0.2,0,0.3c-0.1,1-0.2,2.1-0.3,3.1c-0.2,1.7-0.3,3.5-0.5,5.2c-0.1,1.3-0.3,2.5-0.3,3.8c-0.1,1.2-0.1,2.4-0.1,3.5
			c0,0.4,0,0.8,0,1.3c0,0.4-0.2,0.7-0.6,0.9c-0.5,0.2-0.9,0.5-1.2,0.9c-1,1.1-2.1,2-3.2,3c-1.1,0.9-2.1,1.8-3.2,2.7
			c-0.5,0.4-0.9,0.9-1.3,1.3c-0.1,0.1-0.3,0.3-0.4,0.5c-0.4-0.1-0.6-0.4-0.6-0.7c0-0.1,0-0.2,0-0.2c0.1-1.1,0.2-2.2,0.3-3.3
			c0.1-0.6,0.1-1.3,0.2-1.9c0.1-1.1,0.2-2.3,0.3-3.4c0.1-0.5,0.1-1,0.2-1.6c0.1-1,0.2-2.1,0.4-3.1c0.5-3,0.9-6,1.4-8.9
			c0.2-1.2,0.4-2.4,0.6-3.6c0.1-0.8,0.3-1.7,0.4-2.5c0.2-1.3,0.4-2.5,0.7-3.8c0.2-0.9,0.4-1.9,0.6-2.8c0.2-1.2,0.5-2.3,0.7-3.5
			c0.2-0.9,0.4-1.8,0.5-2.7c0.3-1.3,0.5-2.6,0.8-3.9c0.2-0.9,0.4-1.8,0.5-2.7c0.7-3.7,1.5-7.4,2.3-11.1c0.7-2.8,1.2-5.6,1.8-8.4
			c0.3-1.3,0.6-2.6,0.9-3.8c0.3-1.3,0.5-2.6,0.8-3.8c0-0.2,0.1-0.4,0.1-0.6c0.4-1.6,0.6-3.3,0.9-4.9c0.1-0.8,0.3-1.6,0.4-2.4
			c0.2-1.2,0.5-2.5,0.7-3.7c0.6-2.9,1.2-5.7,1.6-8.6c0.3-1.9,0.5-3.8,0.8-5.7c0.2-1.3,0.2-2.6,0.3-3.8c0.1-1.2,0.3-2.4,0.4-3.7
			c0.1-1.1,0.2-2.1,0.3-3.2c0.1-1.1,0.2-2.1,0.3-3.2c0.1-1.3,0.2-2.6,0.3-3.8c0-0.2,0.1-0.4,0.1-0.6c0.2-1.5,0.3-3,0.5-4.5
			c0.1-1,0.2-2,0.4-3c0.1-1,0.2-2,0.3-3C31,543.1,31.1,542.7,31.2,542.3z"/>
                            <path className="st14" d="M27.5,508.8c0-0.3,0.2-0.4,0.4-0.5c0.9-0.5,1.8-1.1,2.7-1.6c0.6-0.4,1.2-0.8,1.8-1.2
			c1.7-1.2,3.5-2.2,5.3-3.2c0.5-0.3,1-0.5,1.4-0.8c2-1.1,4-2.2,6-3.3c1.3-0.7,2.6-1.5,4-2.2c1.4-0.8,2.8-1.6,4.2-2.4
			c3.2-1.8,6.5-3.4,9.8-5.1c1.4-0.7,2.7-1.4,4.1-2.1c0.3-0.1,0.5-0.2,0.8-0.4c1.7-0.8,3.4-1.7,5.1-2.5c1.6-0.8,3.2-1.5,4.7-2.3
			c1.3-0.6,2.6-1.2,4-1.7c1.1-0.4,2.2-0.8,3.3-1.2c1.7-0.5,3.4-1.2,5-1.8c2.4-1,4.8-2,7.2-3c1.7-0.7,3.4-1.5,5-2.2
			c1.1-0.5,2.2-0.9,3.3-1.4c1.5-0.7,3-1.4,4.4-2c0.6-0.3,1.3-0.5,1.9-0.8c0.8-0.3,1.6-0.6,2.4-1c0.4-0.2,0.7-0.2,1.1-0.2
			c0.9,0.1,1.7,0.4,2.5,0.6c1.1,0.3,2.1,0.7,3.2,1.1c0.1,0,0.3,0.1,0.4,0.2c0,0.2-0.1,0.2-0.2,0.2c-0.6,0.1-1.1,0.1-1.7,0.2
			c-0.8,0-1.4,0.3-2.1,0.7c-1.2,0.8-2.5,1.5-3.7,2.2c-0.4,0.2-0.9,0.4-1.3,0.5c-0.7,0.1-1.4,0.4-2.1,0.6c-1.3,0.5-2.6,0.9-3.8,1.5
			c-1.8,0.7-3.6,1.6-5.3,2.5c-2.3,1.2-4.6,2.5-6.9,3.7c-3.6,1.9-7.2,3.7-10.8,5.5c-1.7,0.8-3.5,1.6-5.2,2.5
			c-2.6,1.2-5.2,2.5-7.9,3.7c-1.8,0.8-3.6,1.6-5.4,2.4c-1.7,0.8-3.4,1.5-5.1,2.3c-1.7,0.8-3.4,1.6-5.1,2.3c-1,0.5-2,1-3,1.5
			c-1,0.5-2,1-3,1.5c-2.7,1.4-5.3,2.8-8,4.3c-1.6,0.9-3.2,1.9-4.9,2.9c-1,0.6-2,1.1-3,1.7c-0.1,0.1-0.3,0.1-0.4,0.2
			c-0.3,0.1-0.6,0.1-0.9,0c-0.2-0.1-0.4-0.2-0.7-0.3C30,509.8,28.8,509.2,27.5,508.8z"/>
                            <path className="st14" d="M90.4,616.9c0.2,0.1,0.4,0.3,0.7,0.4c0.3,0.2,0.6,0.2,1,0.1c0.7-0.2,1.2-0.6,1.7-1.1
			c0.1-0.1,0.2-0.3,0.3-0.5c0.2-0.5,0.1-0.9-0.4-1.1c-0.4-0.2-0.8-0.5-1.2-0.9c-0.1-0.1-0.2-0.2-0.2-0.2c0-0.1,0-0.3,0-0.4
			c0.1-0.1,0.3-0.2,0.4-0.3c1.1-0.7,2.2-1.4,3.3-2.1c0.1,0,0.1-0.1,0.2-0.1c0.4-0.3,0.8-0.3,1.2,0.1c0.3,0.2,0.6,0.5,0.9,0.7
			c0.4,0.4,0.8,0.5,1.4,0.4c1.1-0.3,1.9-1.2,2-2.3c0-0.6-0.2-1-0.7-1.2c-0.1-0.1-0.3-0.1-0.4-0.1c-0.1-0.2,0.1-0.3,0.2-0.4
			c0.8-0.5,1.6-1.1,2.4-1.6c1.7-1.2,3.5-2.4,5.2-3.6c1.5-1.1,3.1-2.1,4.5-3.2c2.5-1.9,5-3.8,7.5-5.7c1.3-1,2.5-2,3.8-3
			c1.1-0.9,2.1-1.8,3.1-2.7c0.3-0.3,0.6-0.6,0.9-0.9c0.6-0.7,1.3-1.5,1.9-2.2c0.6-0.6,1.2-1.1,1.8-1.7c1.2-1.1,2.5-2.3,3.7-3.4
			c0.2-0.2,0.4-0.4,0.7-0.5c0.5-0.3,1-0.3,1.6,0c0.9,0.5,1.8,0.9,2.7,1.4c1,0.5,1.8,1.1,2.6,1.8c0,0,0,0.1,0.1,0.2
			c-0.1,0.1-0.2,0.3-0.4,0.4c-1.1,0.8-2.1,1.7-3.2,2.5c-0.3,0.2-0.6,0.5-0.9,0.7c-1.3,1.2-2.9,2.1-4.3,3.1c-0.4,0.3-0.9,0.6-1.3,0.9
			c-1.2,0.9-2.4,1.7-3.7,2.6c-0.7,0.5-1.4,0.9-2.1,1.4c-1.7,1.3-3.6,2.5-5.4,3.6c-3.8,2.3-7.7,4.6-11.4,7.1c-2.1,1.5-4.3,3-6.4,4.5
			c-1.6,1.2-3.3,2.4-4.9,3.6c-1.5,1.1-3,2.3-4.5,3.4c-2.1,1.6-4.3,3.1-6.4,4.7c-1.6,1.2-3.3,2.4-4.9,3.5c-2.3,1.6-4.6,3.2-7,4.7
			c-0.5,0.3-1,0.6-1.5,1c-0.7,0.5-1.5,1-2.1,1.7c-0.1,0.1-0.1,0.1-0.2,0.2c-0.2,0.3-0.5,0.4-0.8,0.2c-0.2-0.1-0.4-0.2-0.7-0.2
			c-1-0.3-2.1-0.6-3.1-0.9c-0.5-0.1-0.9-0.3-1.3-0.4c-0.3-0.1-0.7-0.2-0.9-0.5c0-0.3,0.1-0.4,0.3-0.4c0.5-0.3,1.1-0.6,1.6-0.9
			c0.8-0.5,1.6-0.9,2.4-1.4c0.4-0.2,0.7-0.3,1.2-0.1c1,0.4,2,0.8,3,1.2c1,0.4,2-0.2,2.4-1.2c0.2-0.4,0.2-0.8,0.2-1.2
			c-0.1-0.7-0.4-1.1-1.1-1.2c-0.2,0-0.5-0.1-0.6-0.2c-0.2-0.2-0.2-0.4,0-0.6c0.5-0.4,1-0.7,1.5-1c0.2-0.1,0.4-0.2,0.5-0.3
			c0.3-0.2,0.6-0.2,1,0c0.5,0.4,1.1,0.7,1.5,1.1c0.5,0.4,1,0.6,1.6,0.4c1-0.2,1.8-0.9,2-2.1c0.1-0.6-0.1-1-0.6-1.3
			c-0.3-0.2-0.6-0.4-0.9-0.6c-0.1-0.1-0.1-0.1-0.2-0.3c0.1-0.1,0.2-0.3,0.3-0.4c0.9-0.6,1.9-1.2,2.8-1.9c0.3-0.2,0.5-0.2,0.8,0
			c0.6,0.4,1.2,0.8,1.6,1.4c0.5,0.6,1,0.7,1.7,0.4c0.8-0.4,1.3-1,1.5-1.9C90.6,617.6,90.4,617.2,90.4,616.9L90.4,616.9z"/>
                            <path className="st14" d="M136.7,592.8c0.2,0.2,0.2,0.4,0.3,0.6c0.1,0.8,0.2,1.6,0.3,2.4c0.1,1.2,0.3,2.4,0.4,3.7
			c0.1,0.7,0.1,1.4,0.2,2c0.2,1.7,0.3,3.4,0.5,5.1c0.2,1.5,0.3,3,0.6,4.4c0.3,1.3,0.4,2.6,0.6,3.9c0.3,1.7,0.7,3.4,1,5.1
			c0.5,2.2,1,4.4,1.5,6.6c0.3,1.5,0.7,2.9,1.1,4.3c0.7,2.9,1.6,5.7,2.2,8.6c0.3,1.6,0.5,3.2,0.8,4.8c0.1,0.5,0.1,1.1,0.2,1.6
			c0,0.1,0,0.3,0,0.4c0,0.4-0.2,0.5-0.5,0.3c-0.7-0.3-1.4-0.7-2.1-1c-0.6-0.3-1.1-0.6-1.7-0.8c-0.6-0.2-0.9-0.6-1-1.3
			c-0.1-0.8-0.3-1.6-0.5-2.4c-0.4-1.7-0.8-3.4-1.1-5.1c-0.3-1.5-0.6-2.9-0.9-4.4c-0.3-1.5-0.6-3-0.9-4.5c-0.3-1.5-0.7-3.1-1-4.6
			c-0.2-0.9-0.4-1.7-0.6-2.6c-0.6-2.5-1.1-5-1.3-7.5c-0.1-1.1-0.1-2.2-0.2-3.4c-0.1-1.4-0.2-2.8-0.3-4.2c-0.1-1.4-0.2-2.7-0.4-4.1
			c-0.2-1.4-0.4-2.8-0.5-4.2c0-0.4-0.1-0.7-0.2-1.1c-0.1-0.4,0-0.6,0.3-0.8c0.7-0.4,1.3-0.9,2-1.3c0.3-0.2,0.6-0.4,0.9-0.6
			C136.5,592.9,136.6,592.8,136.7,592.8z"/>
                            <path className="st14" d="M143.9,584.3c0.2,0.7,0.2,1.3,0,1.8c-0.1,0.2-0.2,0.3-0.3,0.4c-0.3,0.3-0.6,0.5-0.9,0.7
			c-2,1.3-4.1,2.6-6.1,4c-0.7,0.5-1.4,0.9-2,1.3c-0.2,0.1-0.4,0.2-0.5,0.4c-0.4,0.4-0.8,0.6-1.3,0.8c-0.2,0.1-0.4,0.2-0.6,0.3
			c-1.5,0.9-3,1.9-4.5,2.9c-1.1,0.7-2.2,1.4-3.3,2.2c-1.6,1.2-3.3,2.4-4.9,3.5c-1.4,1-2.9,2-4.3,3c-1.8,1.2-3.5,2.5-5.2,3.8
			c-1,0.8-2,1.5-3,2.2c-1.7,1.3-3.4,2.7-5.1,4c-1.9,1.5-3.8,2.9-5.7,4.3c-2.2,1.7-4.5,3.4-6.7,5.1c-1.5,1.2-3,2.4-4.5,3.6
			c-1.2,0.9-2.5,1.8-3.7,2.7c-0.1,0.1-0.3,0.2-0.4,0.3c-1.8,1.4-3.7,2.5-5.7,3.6c-0.4,0.2-0.7,0.5-1.1,0.7c-0.1,0.1-0.3,0.1-0.5,0.2
			c-0.1-0.2-0.1-0.3-0.1-0.4c0-0.7,0.1-1.4,0.1-2c0-0.4,0.2-0.7,0.5-0.9c0.4-0.3,0.8-0.7,1.2-1c1.3-0.9,2.6-1.7,4-2.5
			c2.8-1.8,5.6-3.7,8.3-5.7c1.7-1.3,3.5-2.5,5.3-3.7c2-1.5,3.9-2.9,5.9-4.4c2.7-2,5.3-4,8-5.9c1.7-1.2,3.4-2.4,5.1-3.5
			c2-1.3,3.9-2.5,5.9-3.7c2-1.2,4.1-2.5,6.1-3.7c1-0.6,1.9-1.2,2.8-1.9c1.3-1.1,2.8-2,4-3.2c0.2-0.2,0.4-0.4,0.6-0.4
			c0.9-0.3,1.6-0.8,2.3-1.4c0.9-0.7,1.9-1.3,2.8-2c1.1-0.7,2.2-1.4,3.2-2.2c1.3-0.9,2.5-1.9,3.7-2.9
			C143.5,584.5,143.6,584.4,143.9,584.3z"/>
                            <path className="st14" d="M64.8,631.6c0.3,0.1,0.6,0.2,0.8,0.3c1.1,0.5,2.3,0.9,3.6,1.2c0.8,0.2,1.6,0.5,2.4,0.7
			c0.5,0.2,0.5,0.3,0.5,0.8c0,0.5,0,0.9-0.1,1.4c0,0.1-0.1,0.3-0.1,0.5c-0.2,0-0.3,0-0.5-0.1c-0.3-0.1-0.6-0.3-0.9-0.4
			c-0.2-0.1-0.5-0.3-0.7-0.4c-0.8-0.3-1.6-0.7-2.3-1.1c-0.3-0.2-0.7-0.3-1.1-0.4c-0.1-0.1-0.3-0.1-0.5-0.1c-0.5-0.1-0.8-0.5-0.9-1
			C65,632.6,64.9,632.2,64.8,631.6z"/>
                            <path className="st32" d="M74.4,625.7c-0.1,0.2-0.2,0.4-0.3,0.6c-0.5,1-0.4,0.8-1.4,0.5c-0.3-0.1-0.6-0.3-0.9-0.5
			c-0.4-0.2-0.7-0.6-1.2-0.7c-0.1,0-0.3-0.2-0.4-0.2c-0.2-0.2-0.2-0.3-0.1-0.6c0.1-0.3,0.3-0.5,0.6-0.6c0.1-0.1,0.3-0.1,0.4-0.1
			c1.1,0.3,2.3,0.8,3.3,1.5C74.4,625.6,74.4,625.7,74.4,625.7z"/>
                            <path className="st21" d="M94.3,608c0.5-0.7,1-0.9,1.7-0.9c0.1,0,0.3,0,0.4,0.1c0.7,0.4,1.5,0.8,2.2,1.3c0.1,0.1,0.2,0.1,0.3,0.2
			c0.1,0.1,0.1,0.2,0.1,0.4c0,0.3-0.5,0.8-0.9,0.8c-0.3,0-0.7-0.1-0.9-0.3c-0.6-0.6-1.3-0.8-2-1.1C95,608.3,94.7,608.2,94.3,608z"/>
                            <path className="st21" d="M81.1,621.4c-0.3,0.3-0.6,0.7-1,0.9c-0.2,0.1-0.3,0.1-0.5,0c-0.5-0.3-0.9-0.6-1.4-0.9
			c-0.5-0.3-0.9-0.6-1.5-0.7c-0.1,0-0.3-0.1-0.5-0.2c0.1-0.1,0.1-0.3,0.1-0.4c0.1-0.1,0.2-0.2,0.3-0.3c0.9-0.7,1.3-0.5,1.9,0.2
			c0.2,0.3,0.5,0.4,0.8,0.5c0.4,0.1,0.9,0.3,1.3,0.5C80.9,621.3,81,621.4,81.1,621.4z"/>
                            <path className="st32" d="M91.8,614.9c-0.3,0.3-0.5,0.5-0.8,0.8c-0.2,0.2-0.4,0.2-0.6,0c-0.4-0.3-0.7-0.6-0.9-1
			c-0.2-0.5-0.5-0.8-1.1-0.9c-0.2,0-0.4-0.2-0.5-0.3c-0.2-0.1-0.2-0.4,0-0.5c0.2-0.1,0.3-0.2,0.5-0.3c0.3-0.1,0.5-0.1,0.7,0.1
			c0.4,0.3,0.8,0.6,1.2,0.9c0.3,0.2,0.5,0.3,0.8,0.4c0.3,0.1,0.5,0.3,0.6,0.6C91.7,614.6,91.7,614.7,91.8,614.9z"/>
                            <path className="st14" d="M87.4,617.8c-0.4-0.3-0.8-0.5-1.1-0.8c-0.5-0.4-1-0.7-1.5-0.9c-0.6-0.3-0.6-0.5-0.1-0.9
			c0.1-0.1,0.2-0.1,0.3-0.2c0.2-0.1,0.4-0.1,0.5,0c0.7,0.5,1.4,0.9,2.2,1.4c0.1,0,0.1,0.1,0.2,0.1c0.3,0.3,0.4,0.6,0.1,0.9
			C87.8,617.4,87.6,617.6,87.4,617.8z"/>
                            <path className="st14" d="M62.5,630.7c0.3-0.1,0.5,0,0.6,0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.5,0.2,1.1,0.3,1.6
			c0.1,0.3-0.1,0.4-0.4,0.4c-0.1,0-0.2,0-0.2-0.1c-0.4-0.1-0.6-0.3-0.7-0.7c0-0.4-0.1-0.9-0.1-1.3C62.4,630.9,62.5,630.8,62.5,630.7
			z"/>
                            <path className="st32" d="M76,626.7c0,0.4-0.3,0.8-0.7,0.9c-0.1,0-0.3-0.1-0.3-0.2c0-0.1,0-0.3,0-0.4c0.1-0.3,0.4-0.5,0.7-0.4
			C75.9,626.6,75.9,626.7,76,626.7z"/>
                            <path className="st21" d="M82.3,622.5c0,0,0,0.1-0.1,0.2c-0.2,0.3-0.5,0.5-0.9,0.6c-0.1,0-0.2,0-0.2-0.1c0,0-0.1-0.2,0-0.2
			c0.2-0.4,0.5-0.6,0.9-0.7C82.1,622.2,82.3,622.3,82.3,622.5z"/>
                            <path className="st21" d="M100.4,609.9c0,0.1,0,0.1,0,0.2c-0.2,0.3-0.4,0.5-0.7,0.6c-0.1,0-0.1,0-0.2-0.1c0,0,0-0.1,0-0.1
			c0.1-0.4,0.4-0.7,0.8-0.7C100.3,609.8,100.3,609.9,100.4,609.9z"/>
                            <path className="st32" d="M91.8,616.2c0-0.2,0.3-0.4,0.6-0.4c0,0,0.1,0.1,0.1,0.2c-0.1,0.2-0.3,0.4-0.6,0.3
			C91.9,616.3,91.8,616.2,91.8,616.2z"/>
                            <path className="st14" d="M89,618.2c0,0.2-0.2,0.4-0.4,0.3c0,0-0.1-0.1-0.1-0.1c0-0.2,0.2-0.4,0.4-0.3C88.9,618.1,89,618.2,89,618.2z"
                            />
                            <path className="st15" d="M90.4,616.9C90.4,616.9,90.4,616.9,90.4,616.9C90.4,616.9,90.4,616.9,90.4,616.9z"/>
                            <path d="M118.7,477.7c0.6,0,0.9,0.3,1.1,0.7c0.1,0.3,0.2,0.6,0.3,0.9c0.2,1,0.4,2,0.6,2.9c0.1,0.3,0.2,0.7,0.2,1
			c0.8,3,1.6,6,2.4,9c0.4,1.3,0.8,2.7,1.1,4c0.6,2.4,1.1,4.8,1.7,7.2c0.3,1.3,0.6,2.6,0.9,3.8c0.4,1.8,0.7,3.7,0.9,5.5
			c0.2,2.3,0.5,4.6,0.7,7c0.2,2.4,0.6,4.7,0.9,7c0.2,1.6,0.4,3.2,0.7,4.7c0.4,2.1,0.7,4.3,1.1,6.4c0.3,1.4,0.6,2.8,0.9,4.2
			c0.4,2.3,0.9,4.7,1.3,7c0.3,1.4,0.6,2.9,0.8,4.3c0.4,2,0.8,4.1,1.1,6.1c0.4,2.2,0.7,4.5,0.8,6.7c0,0.3,0.1,0.5,0.1,0.8
			c0,0.2,0.1,0.4,0.2,0.6c0.4,0.5,0.3,0.9-0.1,1.3c-0.1,0.1-0.2,0.2-0.3,0.2c-0.9,0.4-1.6,1-2.3,1.6c-1.1,0.8-2.3,1.5-3.4,2.4
			c-1.7,1.2-3.4,2.5-5.2,3.7c-2.5,1.8-4.8,3.8-7.2,5.7c-3,2.4-5.9,4.8-8.9,7.2c-2.4,1.9-4.9,3.8-7.4,5.6c-1.4,1-2.7,2-4.1,3
			c-0.3,0.2-0.7,0.5-1,0.7c-1.6,1.2-3.3,2.3-5,3.5c-1.5,1-3,2-4.5,3c-4.3,2.8-8.7,5.7-13,8.6c-1.6,1.1-3.3,2.1-5,3.2
			c-2.1,1.3-4.2,2.6-6.3,4.1c-0.9,0.6-1.7,1.4-2.5,2.2c-0.1,0.2-0.3,0.3-0.4,0.4c-0.4,0.3-0.7,0.3-1-0.1c-0.2-0.3-0.3-0.6-0.4-0.9
			c-0.1-0.2-0.1-0.5-0.1-0.7c0-1.9-0.3-3.8-0.5-5.6c-0.1-1.2-0.1-2.4-0.2-3.5c-0.1-1.3-0.2-2.5-0.3-3.8c-0.1-1.6-0.3-3.1-0.4-4.7
			c-0.2-1.6-0.3-3.2-0.6-4.8c-0.3-2-0.7-4.1-1.1-6.1c-0.5-2.8-0.9-5.6-1.4-8.4c-0.4-2.2-0.7-4.5-1.1-6.7c-0.3-1.4-0.6-2.9-0.9-4.3
			c-0.2-1-0.4-2-0.6-3c-0.4-1.8-0.7-3.7-1.1-5.5c-0.3-1.4-0.5-2.8-0.8-4.3c-0.2-1.1-0.4-2.2-0.6-3.3c-0.3-1.8-0.6-3.6-0.9-5.4
			c-0.3-1.6-0.5-3.1-0.8-4.7c-0.2-1.2-0.3-2.3-0.5-3.5c-0.1-0.8-0.2-1.7-0.3-2.5c-0.1-1.2-0.3-2.5-0.4-3.7c-0.1-0.9-0.2-1.9-0.3-2.8
			c-0.1-1-0.2-1.9-0.3-2.9c-0.1-1.2-0.3-2.4-0.5-3.7c-0.2-1.7-0.4-3.3-0.7-5c-0.3-1.8-0.4-3.6-0.6-5.5c-0.1-0.6-0.1-1.2-0.2-1.8
			c0-0.2,0-0.5,0-0.7c0.1-0.4,0.3-0.6,0.6-0.6c0.4,0,0.7-0.2,1.1-0.4c2-1.1,4-2.2,6-3.3c1.7-1,3.5-1.9,5.2-2.9
			c1.7-0.9,3.4-1.8,5.1-2.6c1.4-0.7,2.7-1.3,4.1-2c2.2-1,4.4-2.1,6.6-3.1c1.7-0.8,3.4-1.6,5.1-2.4c1.3-0.6,2.6-1.2,3.9-1.8
			c1.8-0.9,3.7-1.7,5.5-2.7c2.4-1.3,4.8-2.7,7.2-4c2-1.1,4.1-2.2,6.2-3.3c2.2-1.2,4.5-2.4,6.7-3.6c2.1-1.1,4.2-2.2,6.3-3.3
			c0.7-0.4,1.5-0.8,2.2-1.2c1-0.5,1.9-0.9,2.9-1.4C118.3,477.7,118.5,477.7,118.7,477.7z M60,622c0.2-0.2,0.4-0.3,0.5-0.4
			c0.3-0.3,0.6-0.6,0.9-0.8c1.7-1.3,3.4-2.5,5.2-3.6c1.4-0.9,2.8-1.7,4.1-2.6c2-1.3,3.9-2.6,5.8-3.9c3.2-2.1,6.4-4.3,9.7-6.3
			c3.4-2.2,6.6-4.5,9.9-6.8c2.3-1.6,4.5-3.3,6.7-4.9c2.6-1.9,5.2-3.9,7.7-6c1.4-1.1,2.7-2.3,4.1-3.4c2-1.6,4.1-3.2,6.1-4.9
			c2.7-2.2,5.6-4.3,8.4-6.3c1.9-1.3,3.7-2.6,5.6-3.9c0.4-0.3,0.5-0.5,0.4-1c0-0.1,0-0.2,0-0.3c-0.2-1.5-0.5-3-0.7-4.5
			c-0.2-1.5-0.5-3-0.8-4.5c-0.4-1.9-0.8-3.9-1.1-5.8c-0.4-2.2-0.8-4.3-1.2-6.5c-0.3-1.6-0.6-3.1-0.9-4.7c-0.4-1.9-0.7-3.8-1.1-5.6
			c-0.5-2.4-0.8-4.9-1.2-7.4c-0.2-1.4-0.4-2.9-0.6-4.3c-0.2-1.6-0.4-3.1-0.6-4.7c-0.3-2.8-0.5-5.6-0.9-8.4c-0.2-1.6-0.6-3.2-1-4.9
			c-0.3-1.5-0.7-2.9-1-4.4c-0.3-1.5-0.7-3-1-4.4c-0.7-2.8-1.5-5.6-2.2-8.4c-0.4-1.4-0.7-2.7-1.1-4.1c-0.3-0.9-0.5-1.9-0.9-2.8
			c-0.2-0.5-0.3-0.9-0.3-1.4c0-0.2,0-0.3,0-0.5c0-0.4-0.2-0.5-0.6-0.4c-0.5,0.2-1,0.5-1.5,0.7c-1.7,0.9-3.4,1.8-5.1,2.7
			c-2.1,1.1-4.3,2.2-6.4,3.4c-2.3,1.2-4.5,2.4-6.8,3.6c-1.7,0.9-3.5,1.9-5.2,2.8c-1.6,0.9-3.1,1.8-4.7,2.6c-1.7,0.9-3.4,1.8-5.2,2.6
			c-2.2,1.1-4.5,2.1-6.7,3.1c-1.2,0.6-2.5,1.1-3.7,1.7c-2.3,1.1-4.5,2.2-6.8,3.2c-2.9,1.4-5.9,2.8-8.7,4.3c-1.7,0.9-3.5,1.9-5.2,2.8
			c-1.8,1-3.6,2-5.5,2.9c-0.6,0.3-1.3,0.6-1.8,1.2c0,0.2,0,0.5,0,0.8c0.1,0.7,0.2,1.4,0.3,2.1c0.2,1.7,0.4,3.5,0.7,5.2
			c0.3,2.3,0.6,4.7,0.9,7c0.1,1.1,0.2,2.2,0.4,3.3c0.1,1.2,0.3,2.4,0.4,3.6c0.1,0.9,0.2,1.9,0.3,2.8c0.1,1.1,0.3,2.2,0.4,3.3
			c0.2,1.6,0.4,3.1,0.7,4.7c0.4,2.3,0.8,4.7,1.2,7c0.4,2.4,0.8,4.8,1.2,7.2c0.6,3,1.2,6,1.7,8.9c0.3,1.6,0.7,3.2,1,4.8
			c0.3,1.3,0.5,2.6,0.7,3.9c0.3,1.7,0.6,3.5,0.8,5.2c0.3,1.6,0.5,3.2,0.8,4.8c0.3,2,0.7,4,1.1,6c0.4,2.3,0.7,4.7,0.9,7.1
			c0.1,1.8,0.3,3.5,0.5,5.3c0.1,1,0.2,2,0.3,3c0.1,0.9,0.1,1.9,0.2,2.8c0.1,0.8,0.1,1.6,0.2,2.4c0.1,0.8,0.2,1.5,0.3,2.3
			C59.8,621,59.8,621.5,60,622z"/>
                            <path className="st15" d="M60,622c-0.2-0.6-0.2-1-0.2-1.4c-0.1-0.8-0.2-1.5-0.3-2.3c-0.1-0.8-0.2-1.6-0.2-2.4
			c-0.1-0.9-0.1-1.9-0.2-2.8c-0.1-1-0.2-2-0.3-3c-0.2-1.8-0.3-3.5-0.5-5.3c-0.2-2.4-0.4-4.7-0.9-7.1c-0.4-2-0.7-4-1.1-6
			c-0.3-1.6-0.5-3.2-0.8-4.8c-0.3-1.7-0.6-3.5-0.8-5.2c-0.2-1.3-0.4-2.6-0.7-3.9c-0.3-1.6-0.7-3.2-1-4.8c-0.6-3-1.2-6-1.7-8.9
			c-0.4-2.4-0.8-4.8-1.2-7.2c-0.4-2.3-0.8-4.6-1.2-7c-0.2-1.6-0.5-3.1-0.7-4.7c-0.2-1.1-0.3-2.2-0.4-3.3c-0.1-0.9-0.2-1.9-0.3-2.8
			c-0.1-1.2-0.3-2.4-0.4-3.6c-0.1-1.1-0.2-2.2-0.4-3.3c-0.3-2.3-0.6-4.7-0.9-7c-0.2-1.7-0.4-3.5-0.7-5.2c-0.1-0.7-0.2-1.4-0.3-2.1
			c0-0.3,0-0.5,0-0.8c0.6-0.5,1.2-0.8,1.8-1.2c1.8-1,3.6-2,5.5-2.9c1.7-0.9,3.5-1.8,5.2-2.8c2.9-1.6,5.8-3,8.7-4.3
			c2.3-1.1,4.5-2.2,6.8-3.2c1.2-0.6,2.5-1.1,3.7-1.7c2.2-1,4.5-2,6.7-3.1c1.7-0.8,3.5-1.7,5.2-2.6c1.6-0.8,3.2-1.8,4.7-2.6
			c1.7-0.9,3.4-1.9,5.2-2.8c2.3-1.2,4.5-2.4,6.8-3.6c2.1-1.1,4.3-2.2,6.4-3.4c1.7-0.9,3.4-1.8,5.1-2.7c0.5-0.3,1-0.5,1.5-0.7
			c0.3-0.2,0.6,0,0.6,0.4c0,0.2,0,0.3,0,0.5c0,0.5,0.1,0.9,0.3,1.4c0.3,0.9,0.6,1.8,0.9,2.8c0.4,1.4,0.7,2.7,1.1,4.1
			c0.7,2.8,1.5,5.6,2.2,8.4c0.4,1.5,0.7,3,1,4.4c0.3,1.5,0.7,2.9,1,4.4c0.4,1.6,0.7,3.2,1,4.9c0.4,2.8,0.6,5.6,0.9,8.4
			c0.2,1.6,0.4,3.1,0.6,4.7c0.2,1.4,0.4,2.9,0.6,4.3c0.3,2.5,0.7,4.9,1.2,7.4c0.4,1.9,0.7,3.8,1.1,5.6c0.3,1.6,0.6,3.1,0.9,4.7
			c0.4,2.2,0.8,4.3,1.2,6.5c0.4,1.9,0.8,3.9,1.1,5.8c0.3,1.5,0.5,3,0.8,4.5c0.2,1.5,0.5,3,0.7,4.5c0,0.1,0,0.2,0,0.3
			c0.1,0.4-0.1,0.7-0.4,1c-1.9,1.3-3.8,2.6-5.6,3.9c-2.9,2-5.7,4.1-8.4,6.3c-2,1.6-4.1,3.2-6.1,4.9c-1.4,1.1-2.7,2.2-4.1,3.4
			c-2.5,2.1-5.1,4.1-7.7,6c-2.3,1.6-4.5,3.3-6.7,4.9c-3.3,2.3-6.5,4.6-9.9,6.8c-3.2,2.1-6.4,4.2-9.7,6.3c-1.9,1.3-3.9,2.6-5.8,3.9
			c-1.4,0.9-2.8,1.7-4.1,2.6c-1.8,1.1-3.6,2.3-5.2,3.6c-0.3,0.3-0.6,0.6-0.9,0.8C60.3,621.8,60.2,621.9,60,622z M83.3,558.4
			c-0.2,0.1-0.4,0.2-0.6,0.3c-1,0.3-1.9,0.9-2.6,1.7c-1.4,1.7-1.7,3.5-0.8,5.5c0.3,0.6,0.7,1.2,1,1.8c0.3,0.5,0.7,1,1.1,1.5
			c0.4,0.5,0.7,1.1,0.8,1.7c0.2,1,0,1.8-0.8,2.5c-0.3,0.3-0.6,0.5-1,0.7c-0.4,0.2-0.8,0.5-1.2,0.7c-1.6,0.9-2.4,2.2-2.5,4
			c0,0.2,0,0.3,0,0.5c0,0.9,0,1.7-0.1,2.6c0,0.7,0,1.4,0.2,2.1c0,0.2,0.1,0.3,0.2,0.6c0.1-0.2,0.2-0.3,0.3-0.4
			c0.1-0.3,0.1-0.6,0.2-0.8c0.2-1.5,0.3-3.1,0.5-4.6c0.1-1,0.5-1.8,1.3-2.4c0.5-0.3,1-0.6,1.5-0.9c0.4-0.2,0.8-0.5,1.2-0.7
			c1.2-0.7,1.8-2.7,1.5-3.9c-0.3-1-0.7-1.8-1.3-2.6c-0.4-0.6-0.9-1.2-1.3-1.9c-0.3-0.4-0.5-0.9-0.7-1.3c-0.6-1.5-0.1-2.7,0.9-3.8
			c0.6-0.7,1.4-1.1,2.2-1.5c0.3-0.2,0.6-0.3,0.8-0.6c0.2-0.3,0.4-0.4,0.7-0.3c1.6,0.2,3.2,0.1,4.8-0.3c1.1-0.3,2.2-0.6,3.2-1
			c0.2-0.1,0.3-0.2,0.5,0c0,0.1-0.1,0.2-0.1,0.3c-0.2,0.4-0.4,0.7-0.5,1c-0.6,1.5-0.5,3.3,1.3,4.4c0.2,0.2,0.5,0.3,0.8,0.4
			c0.6,0.2,1.1,0.5,1.7,0.7c0.6,0.2,1.2,0.5,1.7,0.9c0.6,0.4,0.9,1,0.9,1.8c0,0.4,0,0.8-0.2,1.2c-0.3,0.8-0.7,1.6-1.1,2.3
			c-0.4,0.8-0.8,1.6-0.9,2.5c-0.1,0.6,0,1.2,0.2,1.8c0.1,0.4,0.3,0.7,0.5,1.1c0.6,1,1,2.1,1.3,3.2c0.1,0.5,0.4,1,0.6,1.5
			c0,0.1,0.2,0.2,0.3,0.3c0.3-0.3,0.3-0.6,0.3-0.9c-0.1-0.8-0.2-1.7-0.4-2.5c-0.1-0.6-0.4-1.1-0.6-1.6c-0.2-0.4-0.4-0.9-0.6-1.3
			c-0.2-0.4-0.3-0.9-0.2-1.4c0.1-0.5,0.2-1,0.4-1.4c0.2-0.5,0.5-0.9,0.7-1.4c0.5-0.7,0.8-1.5,1-2.4c0.3-1.4,0-2.6-1.1-3.6
			c-0.7-0.6-1.5-1.1-2.5-1.4c-0.7-0.2-1.4-0.6-2.1-0.9c-0.7-0.4-1.1-1.1-1-1.9c0-0.2,0.1-0.5,0.1-0.7c0.2-0.8,0.7-1.5,1.2-2.2
			c0.2-0.2,0.4-0.5,0.5-0.8c0-0.4,0.2-0.6,0.5-0.7c0.6-0.4,1.1-0.8,1.6-1.3c0.5-0.5,0.9-1,1.3-1.5c0.2-0.2,0.4-0.5,0.8-0.4
			c0.2,0,0.4-0.1,0.5-0.3c0.1-0.1,0.2-0.2,0.3-0.4c0.6-0.7,1.2-1.4,2-1.9c0.3-0.3,0.7-0.5,1.1-0.6c0.6-0.3,1.1-0.1,1.4,0.5
			c0.1,0.2,0.2,0.5,0.3,0.7c0.2,0.9,0.3,1.8,0.3,2.7c0,0.5,0,1,0,1.5c0,0.4,0.1,0.8,0.2,1.1c0.4,0.9,1.3,1.3,2.3,1
			c0.4-0.2,0.8-0.4,1.2-0.8c0.5-0.5,1-1.1,1.5-1.6c0.3-0.3,0.6-0.6,0.9-0.9c0.5-0.4,1-0.5,1.5-0.2c0.3,0.1,0.6,0.3,0.9,0.5
			c0.3,0.2,0.5,0.4,0.8,0.6c0.5,0.5,1.2,0.8,1.8,1.1c0.4,0.2,0.9,0.2,1.3,0.2c0.2,0,0.4-0.1,0.6-0.2c0.3-0.2,0.3-0.5-0.1-0.7
			c-0.2-0.1-0.3-0.1-0.5-0.2c-0.5-0.1-0.9-0.3-1.3-0.5c-0.4-0.3-0.8-0.6-1.2-0.9c-0.6-0.5-1.2-0.9-1.8-1.2c-1.1-0.5-2.1-0.2-3,0.5
			c-0.3,0.2-0.5,0.5-0.8,0.8c-0.5,0.6-1,1.1-1.6,1.6c-0.1,0.1-0.3,0.3-0.5,0.4c-0.6,0.3-1,0.2-1.1-0.5c0-0.2,0-0.5,0-0.7
			c-0.1-1.2-0.1-2.4-0.3-3.5c-0.1-0.6-0.3-1.1-0.6-1.6c-0.5-0.8-1.3-1.2-2.2-1c-0.6,0.1-1.1,0.3-1.5,0.6c-0.3,0.2-0.6,0.5-0.9,0.7
			c-0.1,0.1-0.2,0.1-0.4,0.3c0-0.5,0.1-0.8,0.3-1.1c0.3-0.8,0.6-1.7,0.8-2.6c0.4-1.6,0.4-3.2,0.1-4.8c0-0.3-0.1-0.5-0.1-0.8
			c0-0.2,0-0.4,0.1-0.6c0.2-0.3,0.2-0.6,0.2-0.9c-0.1-0.4-0.1-0.8-0.2-1.2c-0.1-0.9,0-1.8,0.4-2.6c0.3-0.8,0.8-0.9,1.5-0.5
			c0.5,0.3,1,0.6,1.5,1c0.6,0.3,1.2,0.7,1.8,1c0.8,0.4,1.7,0.4,2.6,0.2c1.1-0.3,2-1,2.7-1.9c0.7-0.9,1.3-1.9,1.5-3.1
			c0.1-0.3,0.2-0.6,0.3-0.9c0.3-0.9,0.4-1,1.4-0.9c0.6,0.1,1.2,0.1,1.8,0.1c0.4,0,0.7-0.1,1.1-0.2c0.2-0.1,0.3-0.3,0-0.5
			c-0.1-0.1-0.3-0.2-0.5-0.3c-1.1-0.4-2.2-0.6-3.4-0.6c-0.7,0-1.2,0.3-1.4,0.9c-0.2,0.4-0.3,0.9-0.5,1.3c-0.1,0.4-0.2,0.9-0.4,1.4
			c-0.5,1.2-1.2,2.2-2.3,2.9c-0.8,0.4-1.6,0.6-2.5,0.1c-0.7-0.3-1.4-0.7-2-1.1c-0.5-0.4-1.1-0.7-1.7-0.9c-1-0.3-1.7,0-2.3,0.9
			c-0.3,0.4-0.4,0.9-0.5,1.3c-0.1,0.2,0,0.4-0.3,0.6c-0.2-0.2-0.3-0.5-0.4-0.7c-0.4-1.1-0.9-2-1.7-2.9c-0.7-0.8-1.4-1.5-2.3-2.1
			c-0.5-0.3-0.9-0.7-1.3-1.1c-0.2-0.2-0.2-0.4-0.1-0.7c0.3-0.4,0.7-0.6,1.2-0.7c0.4,0,0.8,0,1.3-0.1c0.5,0,1-0.1,1.6-0.1
			c0.9-0.1,1.7-0.6,2.3-1.2c1-1,1-2.9-0.4-3.9c-0.4-0.3-0.8-0.5-1.1-0.7c-0.3-0.2-0.7-0.3-1-0.5c-1.1-0.6-1.7-1.6-1.7-2.9
			c0-1.3,0.5-2.5,1.3-3.4c0.8-0.9,1.8-1.6,2.9-2.2c0.6-0.3,1.2-0.5,1.8-0.6c0.4-0.1,0.7,0,0.9,0.3c0.1,0.1,0.2,0.3,0.3,0.4
			c0.1,0.1,0.2,0.1,0.3,0.2c0-0.2,0.1-0.3,0.1-0.4c0-0.2-0.1-0.4-0.2-0.6c-0.3-0.8-0.9-1.1-1.7-0.9c-0.4,0.1-0.9,0.2-1.3,0.3
			c-1.1,0.4-2.1,1-3,1.7c-1.5,1.3-2.4,2.9-2.6,4.9c-0.1,1.4,0.3,2.6,1.3,3.6c0.5,0.5,1.1,0.9,1.7,1.2c0.3,0.1,0.5,0.2,0.8,0.4
			c0.2,0.1,0.4,0.3,0.6,0.5c0.5,0.5,0.5,1,0.1,1.6c-0.2,0.4-0.6,0.7-1,0.8c-0.3,0.1-0.7,0.2-1.1,0.2c-0.7,0.1-1.4,0.1-2.1,0.1
			c-0.9,0.1-1.7,0.4-2.3,1.2c-0.1,0.1-0.2,0.3-0.4,0.4c-0.2-0.1-0.4-0.2-0.6-0.3c-0.5-0.3-1-0.7-1.5-1c-0.6-0.4-1.4-0.7-2.1-0.7
			c-0.8,0-1.5,0-2.3,0.1c-1,0.1-2,0.4-2.8,1c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.3-0.2-0.5
			c-0.1-0.3,0-0.6,0.2-0.8c0.3-0.5,0.6-0.9,0.9-1.4c0.4-0.5,0.8-1,1.2-1.5c0.6-0.7,0.9-1.5,1.2-2.4c0.2-0.6,0.2-1.2,0.1-1.9
			c-0.2-1-0.7-1.6-1.6-2c-0.6-0.3-1.2-0.5-1.9-0.5c-0.5,0-1,0-1.6-0.1c-0.9,0-1.5-0.5-1.8-1.3c-0.1-0.4-0.2-0.8-0.1-1.2
			c0.1-0.8,0.3-1.5,0.7-2.1c0.8-1.4,1.8-2.5,3.1-3.5c0.1-0.1,0.2-0.1,0.3-0.2c0.2-0.1,0.4-0.2,0.7,0c0.1,0.1,0.3,0.1,0.4,0.2
			c0.2-0.4,0-0.7-0.2-0.9c-0.2-0.2-0.5-0.3-0.8-0.3c-0.4,0-0.8,0.2-1.1,0.4c-2,1.4-3.5,3.1-4.2,5.5c-0.3,0.9-0.2,1.8,0.1,2.7
			c0.3,0.9,0.9,1.5,1.8,1.7c0.4,0.1,0.7,0.2,1.1,0.2c0.6,0.1,1.2,0.1,1.8,0.2c0.5,0,0.9,0.2,1.3,0.5c0.4,0.3,0.6,0.6,0.7,1.1
			c0,0.4,0,0.8-0.2,1.2c-0.2,0.6-0.5,1.1-0.9,1.6c-0.6,0.8-1.2,1.6-1.8,2.4c-0.4,0.6-0.7,1.2-0.8,1.9c-0.1,0.6,0,1.1,0.4,1.6
			c0.1,0.2,0.3,0.4,0.4,0.6c-0.2,0.4-0.5,0.6-0.8,0.8c-1.5,1.1-2.7,2.4-3.7,3.9c-0.1,0.2-0.3,0.3-0.4,0.5c-0.6-0.3-1.2-0.2-1.8-0.1
			c-0.3,0-0.7,0.1-1,0.1c-0.9,0-1.1-0.3-0.9-1.1c0.2-0.8,0.3-1.5,0.3-2.3c0.1-0.9-0.1-1.7-0.6-2.4c-0.6-1-1.5-1.5-2.6-1.5
			c-0.6,0-1.1,0.1-1.6,0.2c-0.9,0.3-1.8,0.6-2.8,0.8c-0.7,0.1-1.4,0-2-0.4c-1-0.5-1.5-1.4-1.8-2.4c-0.2-0.5-0.3-1.1-0.3-1.6
			c-0.1-1-0.1-2,0.2-3c0-0.1,0.1-0.3,0.1-0.5c0-0.3-0.4-0.4-0.6-0.3c-0.1,0.1-0.2,0.2-0.3,0.4c-0.1,0.2-0.2,0.5-0.3,0.7
			c-0.2,0.7-0.2,1.5-0.2,2.2c0,1.2,0.2,2.3,0.7,3.4c0.3,0.7,0.7,1.2,1.2,1.7c0.7,0.6,1.5,1,2.5,1.1c0.7,0.1,1.5,0,2.2-0.2
			c0.5-0.2,1-0.3,1.5-0.5c0.5-0.1,0.9-0.2,1.4-0.3c0.7-0.1,1.3,0.2,1.7,0.8c0.4,0.6,0.5,1.2,0.4,1.9c-0.1,0.6-0.2,1.1-0.2,1.7
			c0,0.3-0.1,0.6-0.1,0.9c-0.1,0.8,0.3,1.4,1.1,1.7c0.3,0.1,0.6,0.2,0.9,0.2c0.4,0,0.8,0,1.3-0.1c0.2,0,0.3-0.1,0.5-0.1
			c0.4,0,0.6,0.2,0.5,0.6c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.4-0.3,0.8-0.4,1.3c-0.4,1.3-0.7,2.6-0.8,4c0,0.5-0.1,1.1-0.2,1.6
			c0,0.1-0.1,0.1-0.1,0.2c-0.3,0-0.4-0.3-0.6-0.4c-0.9-0.7-1.8-1.4-3-1.7c-0.5-0.1-1-0.2-1.6,0c-0.4,0.1-0.9,0.3-1.3,0.5
			c-0.6,0.3-1.1,0.6-1.6,1c-0.9,0.6-1.7,1.1-2.7,1.4c-0.4,0.1-0.8,0.3-1.2,0.3c-0.6,0-1.1-0.2-1.5-0.6c-0.3-0.3-0.5-0.7-0.7-1.1
			c-0.3-0.6-0.5-1.2-0.7-1.8c-0.3-0.8-0.5-1.5-0.9-2.3c-0.6-1-1.3-1.3-2.4-1.1c-0.9,0.2-1.8,0.4-2.7,0.7c-0.4,0.1-0.7,0.2-1,0.4
			c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.1,0.3,0.1,0.4c0.1,0,0.3,0.1,0.4,0.1c0.7,0,1.5,0,2.2-0.2c0.5-0.1,1-0.2,1.5-0.3
			c0.8-0.2,1.1,0,1.5,0.7c0.2,0.4,0.4,0.8,0.5,1.2c0.2,0.6,0.4,1.3,0.7,1.9c0.2,0.7,0.6,1.3,1.1,1.8c0.9,1,2,1.3,3.2,1
			c0.6-0.1,1.1-0.4,1.6-0.6c1-0.4,1.8-1.1,2.7-1.6c0.4-0.2,0.7-0.4,1.1-0.7c1.1-0.6,2.1-0.5,3.1,0.1c0.6,0.4,1.2,0.8,1.8,1.2
			c0.2,0.2,0.3,0.4,0.4,0.7c0,0.5,0.2,0.9,0.4,1.2c0.2,0.3,0.3,0.5,0.3,0.9c0.1,0.8,0.2,1.7,0.4,2.5c0.2,1.1,0.5,2.3,0.9,3.3
			c0,0.1,0,0.2,0,0.4c-0.5,0-0.9-0.1-1.4-0.1c-1.5-0.1-2.8,0.4-3.7,1.6c-0.6,0.8-1,1.6-1.3,2.6c-0.2,0.8-0.5,1.6-1,2.3
			c-0.3,0.5-0.7,0.7-1.3,0.7c-0.7,0-1.2-0.1-1.7-0.6c-0.4-0.3-0.7-0.7-1.1-1c-0.3-0.3-0.6-0.6-1-0.8c-0.7-0.5-1.3-0.4-2,0.1
			c-0.2,0.1-0.4,0.3-0.5,0.5c-0.3,0.4-0.6,0.8-0.9,1.2c-0.6,0.8-1,1.8-1.4,2.7c-0.1,0.2-0.1,0.4,0.1,0.6c0.1-0.1,0.3-0.1,0.4-0.2
			c0.3-0.3,0.6-0.7,0.9-1c0.4-0.6,0.8-1.2,1.2-1.7c0.3-0.3,0.6-0.6,0.9-0.9c0.3-0.2,0.6-0.2,0.9,0c0.2,0.1,0.4,0.3,0.5,0.5
			c0.3,0.3,0.5,0.6,0.8,0.8c0.5,0.4,1,0.8,1.6,1c1.2,0.5,2.8,0.2,3.6-1.1c0.3-0.5,0.5-1,0.7-1.5c0.2-0.5,0.4-1.1,0.6-1.6
			c0.2-0.4,0.4-0.9,0.6-1.3c0.3-0.6,0.8-1.1,1.5-1.4c1.2-0.6,2.4-0.4,3.6,0.1c0.2,0.1,0.3,0.2,0.4,0.4c0.2,0.2,0.3,0.4,0.4,0.6
			c1.1,1.5,2.5,2.5,4.2,3.2C82.8,558,83,558,83.3,558.4z M87.5,598.9c0.1-0.1,0.2-0.2,0.2-0.3c0-0.1-0.1-0.3-0.2-0.2
			c-0.1,0-0.2,0.1-0.2,0.2C87.3,598.7,87.4,598.7,87.5,598.9z"/>
                            <path d="M83.3,558.4c-0.2-0.4-0.5-0.4-0.7-0.5c-1.7-0.7-3.1-1.7-4.2-3.2c-0.1-0.2-0.3-0.4-0.4-0.6c-0.1-0.1-0.3-0.3-0.4-0.4
			c-1.2-0.6-2.4-0.7-3.6-0.1c-0.6,0.3-1.1,0.8-1.5,1.4c-0.2,0.4-0.4,0.9-0.6,1.3c-0.2,0.5-0.3,1.1-0.6,1.6c-0.2,0.5-0.5,1-0.7,1.5
			c-0.8,1.3-2.4,1.6-3.6,1.1c-0.6-0.2-1.1-0.6-1.6-1c-0.3-0.3-0.6-0.6-0.8-0.8c-0.2-0.2-0.3-0.3-0.5-0.5c-0.3-0.2-0.6-0.2-0.9,0
			c-0.3,0.3-0.6,0.6-0.9,0.9c-0.4,0.6-0.8,1.2-1.2,1.7c-0.3,0.4-0.6,0.7-0.9,1c-0.1,0.1-0.3,0.1-0.4,0.2c-0.2-0.2-0.1-0.4-0.1-0.6
			c0.3-1,0.8-1.9,1.4-2.7c0.3-0.4,0.6-0.8,0.9-1.2c0.2-0.2,0.3-0.3,0.5-0.5c0.7-0.5,1.3-0.5,2-0.1c0.3,0.2,0.7,0.5,1,0.8
			c0.4,0.3,0.7,0.7,1.1,1c0.5,0.4,1.1,0.6,1.7,0.6c0.6,0,1-0.3,1.3-0.7c0.5-0.7,0.8-1.5,1-2.3c0.2-1,0.7-1.8,1.3-2.6
			c0.9-1.2,2.2-1.8,3.7-1.6c0.4,0,0.9,0.1,1.4,0.1c0-0.2,0-0.3,0-0.4c-0.4-1.1-0.6-2.2-0.9-3.3c-0.2-0.8-0.3-1.7-0.4-2.5
			c0-0.3-0.1-0.6-0.3-0.9c-0.3-0.4-0.4-0.8-0.4-1.2c0-0.3-0.1-0.5-0.4-0.7c-0.6-0.4-1.2-0.9-1.8-1.2c-1-0.6-2.1-0.7-3.1-0.1
			c-0.4,0.2-0.7,0.4-1.1,0.7c-0.9,0.6-1.7,1.2-2.7,1.6c-0.5,0.2-1.1,0.5-1.6,0.6c-1.3,0.3-2.4,0-3.2-1c-0.5-0.5-0.8-1.1-1.1-1.8
			c-0.2-0.6-0.4-1.3-0.7-1.9c-0.2-0.4-0.3-0.8-0.5-1.2c-0.4-0.7-0.7-0.9-1.5-0.7c-0.5,0.1-1,0.2-1.5,0.3c-0.7,0.2-1.5,0.2-2.2,0.2
			c-0.1,0-0.3,0-0.4-0.1c-0.2-0.1-0.2-0.3-0.1-0.4c0.1-0.1,0.2-0.2,0.3-0.2c0.3-0.1,0.7-0.3,1-0.4c0.9-0.2,1.8-0.5,2.7-0.7
			c1.1-0.2,1.9,0.1,2.4,1.1c0.4,0.7,0.7,1.5,0.9,2.3c0.2,0.6,0.4,1.2,0.7,1.8c0.2,0.4,0.4,0.7,0.7,1.1c0.4,0.5,0.9,0.6,1.5,0.6
			c0.4,0,0.8-0.1,1.2-0.3c1-0.4,1.8-0.9,2.7-1.4c0.5-0.3,1-0.7,1.6-1c0.4-0.2,0.9-0.4,1.3-0.5c0.5-0.2,1-0.1,1.6,0
			c1.2,0.2,2.1,0.9,3,1.7c0.2,0.2,0.3,0.4,0.6,0.4c0-0.1,0.1-0.1,0.1-0.2c0.1-0.5,0.1-1.1,0.2-1.6c0.1-1.4,0.4-2.7,0.8-4
			c0.1-0.4,0.3-0.8,0.4-1.3c0.1-0.2,0.1-0.4,0.2-0.6c0.1-0.4-0.1-0.6-0.5-0.6c-0.2,0-0.3,0-0.5,0.1c-0.4,0-0.8,0.1-1.3,0.1
			c-0.3,0-0.6-0.1-0.9-0.2c-0.8-0.3-1.1-0.9-1.1-1.7c0-0.3,0.1-0.6,0.1-0.9c0.1-0.6,0.2-1.1,0.2-1.7c0.1-0.7-0.1-1.3-0.4-1.9
			c-0.4-0.6-0.9-0.9-1.7-0.8c-0.5,0.1-0.9,0.1-1.4,0.3c-0.5,0.1-1,0.3-1.5,0.5c-0.7,0.2-1.4,0.3-2.2,0.2c-1-0.1-1.8-0.5-2.5-1.1
			c-0.5-0.5-0.9-1-1.2-1.7c-0.5-1.1-0.7-2.2-0.7-3.4c0-0.7,0-1.5,0.2-2.2c0.1-0.3,0.1-0.5,0.3-0.7c0.1-0.1,0.2-0.3,0.3-0.4
			c0.2-0.2,0.6,0,0.6,0.3c0,0.2,0,0.3-0.1,0.5c-0.3,1-0.3,2-0.2,3c0.1,0.5,0.2,1.1,0.3,1.6c0.3,1,0.9,1.9,1.8,2.4
			c0.6,0.3,1.3,0.5,2,0.4c1-0.1,1.9-0.5,2.8-0.8c0.5-0.2,1.1-0.3,1.6-0.2c1.1,0.1,2,0.6,2.6,1.5c0.5,0.7,0.6,1.5,0.6,2.4
			c-0.1,0.8-0.1,1.6-0.3,2.3c-0.2,0.8,0.1,1.1,0.9,1.1c0.3,0,0.7-0.1,1-0.1c0.6-0.1,1.2-0.2,1.8,0.1c0.2-0.2,0.3-0.3,0.4-0.5
			c1-1.5,2.3-2.8,3.7-3.9c0.3-0.2,0.6-0.4,0.8-0.8c-0.1-0.2-0.2-0.4-0.4-0.6c-0.3-0.5-0.4-1-0.4-1.6c0.1-0.7,0.3-1.4,0.8-1.9
			c0.6-0.8,1.2-1.6,1.8-2.4c0.4-0.5,0.6-1,0.9-1.6c0.1-0.4,0.2-0.8,0.2-1.2c0-0.5-0.3-0.8-0.7-1.1c-0.4-0.3-0.8-0.4-1.3-0.5
			c-0.6,0-1.2-0.1-1.8-0.2c-0.4,0-0.7-0.1-1.1-0.2c-0.9-0.3-1.5-0.9-1.8-1.7c-0.3-0.9-0.3-1.8-0.1-2.7c0.7-2.4,2.2-4.1,4.2-5.5
			c0.3-0.2,0.7-0.4,1.1-0.4c0.3,0,0.6,0.1,0.8,0.3c0.2,0.2,0.4,0.5,0.2,0.9c-0.2-0.1-0.3-0.1-0.4-0.2c-0.2-0.2-0.4-0.1-0.7,0
			c-0.1,0.1-0.2,0.1-0.3,0.2c-1.3,1-2.3,2.1-3.1,3.5c-0.4,0.7-0.6,1.4-0.7,2.1c-0.1,0.4-0.1,0.8,0.1,1.2c0.3,0.8,0.9,1.3,1.8,1.3
			c0.5,0,1,0,1.6,0.1c0.7,0,1.3,0.2,1.9,0.5c0.9,0.4,1.4,1.1,1.6,2c0.1,0.6,0.1,1.3-0.1,1.9c-0.3,0.8-0.7,1.7-1.2,2.4
			c-0.4,0.5-0.8,1-1.2,1.5c-0.3,0.4-0.6,0.9-0.9,1.4c-0.2,0.3-0.2,0.5-0.2,0.8c0,0.2,0.1,0.3,0.2,0.5c0,0.1,0.2,0.1,0.3,0.1
			c0,0,0.1,0,0.1-0.1c0.8-0.6,1.8-0.9,2.8-1c0.7-0.1,1.5-0.1,2.3-0.1c0.8,0,1.5,0.3,2.1,0.7c0.5,0.3,1,0.7,1.5,1
			c0.2,0.1,0.3,0.2,0.6,0.3c0.1-0.2,0.3-0.3,0.4-0.4c0.5-0.8,1.3-1.2,2.3-1.2c0.7-0.1,1.4-0.1,2.1-0.1c0.4,0,0.7-0.1,1.1-0.2
			c0.4-0.1,0.8-0.4,1-0.8c0.4-0.6,0.3-1.1-0.1-1.6c-0.2-0.2-0.4-0.3-0.6-0.5c-0.2-0.1-0.5-0.3-0.8-0.4c-0.7-0.3-1.2-0.7-1.7-1.2
			c-1-1-1.4-2.2-1.3-3.6c0.1-2,1.1-3.6,2.6-4.9c0.9-0.8,1.9-1.4,3-1.7c0.4-0.1,0.9-0.3,1.3-0.3c0.8-0.1,1.4,0.2,1.7,0.9
			c0.1,0.2,0.2,0.4,0.2,0.6c0,0.1,0,0.2-0.1,0.4c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.3-0.5-0.4-0.9-0.3
			c-0.6,0.2-1.2,0.4-1.8,0.6c-1.1,0.5-2.1,1.3-2.9,2.2c-0.9,1-1.4,2.1-1.3,3.4c0,1.3,0.6,2.3,1.7,2.9c0.3,0.2,0.7,0.3,1,0.5
			c0.4,0.2,0.8,0.4,1.1,0.7c1.4,0.9,1.4,2.8,0.4,3.9c-0.6,0.7-1.4,1.2-2.3,1.2c-0.5,0-1,0.1-1.6,0.1c-0.4,0-0.8,0-1.3,0.1
			c-0.5,0.1-0.9,0.3-1.2,0.7c-0.2,0.3-0.2,0.5,0.1,0.7c0.4,0.4,0.9,0.8,1.3,1.1c0.9,0.6,1.6,1.4,2.3,2.1c0.8,0.9,1.3,1.8,1.7,2.9
			c0.1,0.3,0.2,0.5,0.4,0.7c0.3-0.1,0.2-0.4,0.3-0.6c0.1-0.5,0.3-0.9,0.5-1.3c0.6-0.8,1.3-1.1,2.3-0.9c0.6,0.2,1.2,0.5,1.7,0.9
			c0.6,0.5,1.3,0.8,2,1.1c0.9,0.4,1.7,0.3,2.5-0.1c1.2-0.6,1.8-1.7,2.3-2.9c0.2-0.4,0.3-0.9,0.4-1.4c0.1-0.4,0.3-0.9,0.5-1.3
			c0.3-0.6,0.8-0.9,1.4-0.9c1.2,0,2.3,0.2,3.4,0.6c0.2,0.1,0.3,0.2,0.5,0.3c0.2,0.2,0.2,0.4,0,0.5c-0.3,0.1-0.7,0.2-1.1,0.2
			c-0.6,0-1.2,0-1.8-0.1c-0.9-0.1-1.1,0-1.4,0.9c-0.1,0.3-0.2,0.6-0.3,0.9c-0.3,1.2-0.8,2.2-1.5,3.1c-0.7,0.9-1.6,1.5-2.7,1.9
			c-0.9,0.3-1.8,0.2-2.6-0.2c-0.6-0.3-1.2-0.6-1.8-1c-0.5-0.3-1-0.7-1.5-1c-0.7-0.4-1.2-0.2-1.5,0.5c-0.3,0.8-0.5,1.7-0.4,2.6
			c0,0.4,0.1,0.8,0.2,1.2c0.1,0.3,0.1,0.6-0.2,0.9c-0.1,0.2-0.1,0.4-0.1,0.6c0.1,0.3,0.1,0.5,0.1,0.8c0.3,1.6,0.3,3.2-0.1,4.8
			c-0.2,0.9-0.4,1.7-0.8,2.6c-0.1,0.3-0.3,0.7-0.3,1.1c0.2-0.1,0.3-0.2,0.4-0.3c0.3-0.2,0.6-0.5,0.9-0.7c0.4-0.3,1-0.5,1.5-0.6
			c0.9-0.1,1.7,0.2,2.2,1c0.3,0.5,0.5,1,0.6,1.6c0.2,1.2,0.2,2.3,0.3,3.5c0,0.2,0,0.5,0,0.7c0.1,0.6,0.5,0.8,1.1,0.5
			c0.2-0.1,0.3-0.2,0.5-0.4c0.5-0.5,1.1-1.1,1.6-1.6c0.3-0.3,0.5-0.6,0.8-0.8c0.9-0.8,1.9-1,3-0.5c0.7,0.3,1.3,0.7,1.8,1.2
			c0.4,0.3,0.8,0.6,1.2,0.9c0.4,0.3,0.8,0.5,1.3,0.5c0.2,0,0.4,0.1,0.5,0.2c0.3,0.1,0.4,0.5,0.1,0.7c-0.2,0.1-0.4,0.2-0.6,0.2
			c-0.4,0.1-0.9,0-1.3-0.2c-0.6-0.3-1.3-0.6-1.8-1.1c-0.3-0.2-0.5-0.4-0.8-0.6c-0.3-0.2-0.6-0.4-0.9-0.5c-0.6-0.3-1.1-0.2-1.5,0.2
			c-0.3,0.3-0.6,0.6-0.9,0.9c-0.5,0.5-1,1.1-1.5,1.6c-0.3,0.3-0.7,0.6-1.2,0.8c-1,0.4-1.9,0-2.3-1c-0.2-0.4-0.2-0.7-0.2-1.1
			c0-0.5,0-1,0-1.5c0-0.9-0.1-1.8-0.3-2.7c-0.1-0.3-0.1-0.5-0.3-0.7c-0.3-0.6-0.8-0.8-1.4-0.5c-0.4,0.2-0.8,0.4-1.1,0.6
			c-0.7,0.6-1.4,1.2-2,1.9c-0.1,0.1-0.2,0.2-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.3c-0.4,0-0.6,0.2-0.8,0.4c-0.4,0.5-0.9,1-1.3,1.5
			c-0.5,0.5-1,0.9-1.6,1.3c-0.3,0.2-0.5,0.4-0.5,0.7c0,0.3-0.3,0.6-0.5,0.8c-0.6,0.6-1,1.3-1.2,2.2c-0.1,0.2-0.1,0.5-0.1,0.7
			c-0.1,0.8,0.3,1.5,1,1.9c0.7,0.4,1.3,0.7,2.1,0.9c0.9,0.3,1.7,0.7,2.5,1.4c1.1,0.9,1.4,2.2,1.1,3.6c-0.2,0.8-0.5,1.6-1,2.4
			c-0.3,0.4-0.5,0.9-0.7,1.4c-0.2,0.5-0.3,0.9-0.4,1.4c-0.1,0.5,0,0.9,0.2,1.4c0.2,0.5,0.4,0.9,0.6,1.3c0.3,0.5,0.5,1,0.6,1.6
			c0.2,0.8,0.3,1.6,0.4,2.5c0,0.3,0.1,0.6-0.3,0.9c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2-0.5-0.4-1-0.6-1.5c-0.3-1.1-0.7-2.2-1.3-3.2
			c-0.2-0.3-0.4-0.7-0.5-1.1c-0.2-0.6-0.2-1.2-0.2-1.8c0.1-0.9,0.4-1.7,0.9-2.5c0.4-0.8,0.9-1.5,1.1-2.3c0.1-0.4,0.2-0.8,0.2-1.2
			c0-0.7-0.3-1.3-0.9-1.8c-0.5-0.4-1.1-0.7-1.7-0.9c-0.6-0.2-1.1-0.4-1.7-0.7c-0.3-0.1-0.5-0.3-0.8-0.4c-1.8-1.1-1.9-2.9-1.3-4.4
			c0.2-0.4,0.4-0.7,0.5-1c0.1-0.1,0.1-0.2,0.1-0.3c-0.2-0.2-0.4,0-0.5,0c-1.1,0.4-2.1,0.7-3.2,1c-1.6,0.4-3.2,0.5-4.8,0.3
			c-0.3,0-0.6,0-0.7,0.3c-0.2,0.3-0.5,0.5-0.8,0.6c-0.8,0.4-1.6,0.9-2.2,1.5c-1,1.1-1.4,2.4-0.9,3.8c0.2,0.5,0.4,0.9,0.7,1.3
			c0.4,0.6,0.9,1.3,1.3,1.9c0.6,0.8,1,1.6,1.3,2.6c0.3,1.1-0.3,3.1-1.5,3.9c-0.4,0.2-0.8,0.5-1.2,0.7c-0.5,0.3-1,0.6-1.5,0.9
			c-0.8,0.6-1.3,1.4-1.3,2.4c-0.1,1.5-0.3,3.1-0.5,4.6c0,0.3-0.1,0.6-0.2,0.8c0,0.1-0.1,0.2-0.3,0.4c-0.1-0.2-0.2-0.4-0.2-0.6
			c-0.2-0.7-0.2-1.4-0.2-2.1c0-0.9,0-1.7,0.1-2.6c0-0.2,0-0.3,0-0.5c0.1-1.8,1-3.1,2.5-4c0.4-0.2,0.8-0.4,1.2-0.7
			c0.3-0.2,0.7-0.4,1-0.7c0.8-0.7,1-1.5,0.8-2.5c-0.1-0.6-0.4-1.2-0.8-1.7c-0.4-0.5-0.7-1-1.1-1.5c-0.4-0.6-0.8-1.1-1-1.8
			c-0.9-2-0.6-3.8,0.8-5.5c0.7-0.8,1.6-1.4,2.6-1.7C82.8,558.6,83,558.5,83.3,558.4z M100.7,542.5c-0.1-0.5-0.1-1.5-0.4-2.4
			c-0.4-1.7-0.9-3.3-1.6-4.9c-0.7-1.7-1.9-3-3.3-4.1c-0.2-0.1-0.4-0.2-0.6-0.3c-0.5-0.2-0.9-0.5-1.3-0.9c-0.2-0.2-0.3-0.4-0.5-0.6
			c-0.8-0.6-1.7-1.3-2.6-1.8c-1-0.6-2.1-0.7-3.2-0.2c-0.1,0-0.3,0.1-0.4,0.1c-0.3-0.1-0.6,0.1-0.8,0.2c-0.9,0.4-1.6,1-2.4,1.5
			c-1.2,1-2.4,2-3.4,3.1c-1.2,1.4-2.1,3-2.6,4.8c-0.4,1.2-0.7,2.4-0.7,3.7c-0.1,1.1-0.2,2.3-0.1,3.5c0,1.3,0.2,2.6,0.4,3.9
			c0.3,1.3,0.6,2.6,1.1,3.9c0.9,2.3,2.5,3.9,4.7,4.9c0.9,0.4,1.9,0.6,2.8,0.7c0.7,0.1,1.4-0.1,2.1-0.2c2-0.3,3.9-0.9,5.7-1.8
			c1.4-0.7,2.7-1.7,3.7-3c0.6-0.8,1.1-1.6,1.5-2.5c0.6-1.1,1-2.3,1.4-3.4C100.6,545.3,100.8,544.1,100.7,542.5z M75.4,544.2
			c0,0,0-0.1,0-0.1C75.4,544.1,75.3,544.1,75.4,544.2C75.3,544.1,75.3,544.2,75.4,544.2C75.4,544.2,75.4,544.2,75.4,544.2z"/>
                            <path d="M87.5,598.9c-0.1-0.2-0.2-0.2-0.2-0.3c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.3,0.1,0.2,0.2C87.7,598.7,87.6,598.8,87.5,598.9z"/>
                            <path className="st13" d="M100.7,542.5c0.1,1.7-0.1,2.8-0.5,4c-0.4,1.2-0.8,2.3-1.4,3.4c-0.4,0.9-1,1.7-1.5,2.5c-1,1.3-2.2,2.3-3.7,3
			c-1.8,0.9-3.7,1.5-5.7,1.8c-0.7,0.1-1.4,0.2-2.1,0.2c-1-0.1-1.9-0.2-2.8-0.7c-2.2-1-3.8-2.6-4.7-4.9c-0.5-1.2-0.8-2.5-1.1-3.9
			c-0.3-1.3-0.4-2.6-0.4-3.9c0-1.1,0.1-2.3,0.1-3.5c0.1-1.3,0.4-2.5,0.7-3.7c0.5-1.8,1.4-3.4,2.6-4.8c1-1.2,2.2-2.1,3.4-3.1
			c0.8-0.4,1.5-1.1,2.4-1.5c0.3-0.1,0.5-0.2,0.8-0.2c0.1,0,0.3,0,0.4-0.1c1.1-0.5,2.2-0.4,3.2,0.2c0.9,0.5,1.7,1.2,2.6,1.8
			c0.2,0.2,0.4,0.4,0.5,0.6c0.3,0.4,0.8,0.7,1.3,0.9c0.2,0.1,0.4,0.2,0.6,0.3c1.4,1.1,2.6,2.4,3.3,4.1c0.6,1.6,1.2,3.3,1.6,4.9
			C100.6,541,100.6,542,100.7,542.5z"/>
                            <path className="st15" d="M75.4,544.2C75.4,544.2,75.4,544.2,75.4,544.2C75.3,544.2,75.3,544.1,75.4,544.2c-0.1-0.1,0-0.1,0-0.1
			C75.4,544.1,75.4,544.1,75.4,544.2z"/>
	</g>
                        <g className="estante-2 white">
		<path d="M585.1,405.9c-0.2-0.3-0.4-0.6-0.7-1c0.4-0.4,0.8-0.8,1.2-1.1c0.2,0,0.4,0,0.6,0c0.9,0.2,0.9,0.2,2-0.1
			c0.1-1.6,0.1-3.2,0-4.9c-0.2-2.7-0.3-5.4-0.4-8.1c0-0.7-0.1-1.5,0.4-2.1c0.5-0.5,1.2-0.3,1.8-0.3c1.3,0,2.6-0.1,3.8-0.1
			c0.9,0,1.7-0.1,2.6-0.1c2.6,0,5.2,0.1,7.9,0c1.7,0,3.5-0.2,5.3-0.4c0-0.4,0.1-0.8,0.1-1.1c-0.1-1.8-0.3-3.5-0.5-5.3
			c-0.1-1.2-0.3-2.3-0.4-3.5c0-0.5,0-1,0-1.5c1.5-0.7,3.2-0.6,4.8-1.1c0-0.4,0.1-0.7,0.1-1.1c-0.1-1.5-0.3-2.9-0.4-4.4
			c-0.1-1.2-0.2-2.3,0.1-3.5c2.6,0.3,5.1-0.4,7.6-0.8c1.9-0.3,3.9-0.3,5.8-0.3c5.9,0.1,11.8,0.4,17.7,0.2c5.8-0.2,11.7-0.3,17.5-0.4
			c1.3,0,2.6,0,3.8,0c2.4,0,4.9,0,7.3-0.3c1.6-0.2,3.3-0.1,4.9-0.1c0.6,0,1.2,0.1,1.8,0.5c0.6,0.6,0.6,1.4,0.7,2.2
			c0.1,1.3,0.1,2.7,0.1,4.1c0,0.9,0,1.7,0.6,2.6c1.1,0,2.2-0.2,3.3-0.3c1.1-0.1,2.2-0.3,3.3,0.3c0.2,0.5,0.4,1.1,0.5,1.7
			c0.3,2.1,0.7,4.2,0.9,6.4c0.2,1.8,0.4,3.6,0.9,5.5c0.3,0.1,0.6,0.1,0.9,0.1c1.8,0.1,3.5,0.1,5.3,0.2c2,0.1,4,0.2,6,0.2
			c1.9,0,3.8-0.1,5.7-0.1c0.7,0,1.5-0.1,2.2-0.1c0.6,0,1.1,0.2,1.5,1c0.1,1.9,0.1,4-0.1,6.2c-0.1,2.1-0.4,4.2-0.3,6.5
			c0.4,0.1,0.7,0.1,1,0.1c4.9-0.1,9.7-0.2,14.7-0.2c0-0.4,0-0.7-0.1-1c-0.2-0.8-0.4-1.5-0.8-2.3c-1.2-2.7-1.7-5.5-2.2-8.3
			c-0.7-4-1.5-8-2.2-12c-0.3-1.6-0.4-3.2-0.3-4.8c0.2-0.3,0.4-0.6,0.7-0.9c1-0.2,2.1-0.4,3.3-0.6c-0.6-1.5-1.3-3-1.9-4.6
			c-0.6-1.6-1.1-3.3-1.5-4.9c-0.4-1.6-0.7-3.2-1.1-4.9c-0.5-0.1-1-0.1-1.5-0.2c-2.1-0.4-3.7-1.5-4.6-3.5c-0.8-1.6-1.2-3.4-1.3-5.1
			c-0.1-1.1,0.1-2.1,0.5-3.1c0.3-0.9,0.7-1.7,0.4-2.8c-0.2-0.4-0.6-0.9-0.9-1.5c0.2-0.2,0.4-0.5,0.6-0.7c0.3,0.2,0.6,0.3,0.8,0.4
			c0.9,0.8,1.7,1.6,2.5,2.4c0.9,0.9,1.9,1.7,2.7,2.7c1.9,2.2,2.7,4.8,2.3,7.7c-0.1,0.8-0.2,1.6-0.3,2.4c0.4,0.3,0.8,0.6,1.1,0.8
			c1.5,1.2,2.6,2.8,3.4,4.5c0.8,1.9,1.6,3.8,2.5,5.8c0.2,0.4,0.3,0.8,0.5,1.2c0.5-0.1,0.3-0.5,0.3-0.8c-0.1-2.5-0.2-5-0.3-7.5
			c0-1.9,0-3.8,0.1-5.8c-0.5-0.1-1.1-0.3-1.6-0.4c-0.7-0.5-0.7-1.1-0.6-1.7c0.4-3.1,0.2-6.3,0.4-9.4c0-0.7,0.1-1.5,0.2-2.2
			c0.1-0.5,0.4-0.9,1.1-1c0.3,0.2,0.7,0.6,1.1,0.9c0.3-0.1,0.6-0.2,0.9-0.2c1,0.3,1.9,0.5,2.9,0.8c0.3-0.4,0.6-0.7,0.8-1
			c0.2,0,0.4,0,0.5,0c0.1,0.1,0.3,0.2,0.3,0.3c0.2,0.5,0.4,0.9,0.4,1.4c0.2,2.7,0.4,5.4,0.6,8.1c0,0.8,0.1,1.6-0.3,2.4
			c-0.8,1-2.1,1-3.3,1.6c0,0.4,0,0.8,0.1,1.2c1,3.3,1.2,6.8,0.8,10.2c0,0.2,0,0.4,0,0.5c0.5-0.1,0.5-0.5,0.6-0.9
			c0.5-1.6,1-3.2,1.5-4.8c0.7-2.5,1.8-4.8,3.2-7c0.4-3,0.4-6,1.4-8.9c-1.7-2.8-2-5.9-2-9c0-1.2,0.2-2.3,0.7-3.4
			c1.2-2.8,3-5.2,5.2-7.3c0.2-0.2,0.6-0.4,0.9-0.5c1,0.4,1,0.4,1.2,2.1c0.3,0.1,0.6,0.3,1,0.5c0.2,0.5,0.5,1.1,0.7,1.7
			c0.3,0.1,0.6,0.2,1.1,0.3c2.1,3,2,6.4,1.5,9.7c-0.5,3.5-2.6,6.1-5.4,8.2c0.2,2.9,0.6,5.7,0.7,8.6c0.1,2.8-0.1,5.6-0.2,8.5
			c0.4,0,0.5-0.4,0.6-0.7c0.3-0.9,0.6-1.7,0.8-2.6c0.6-2.5,1.8-4.7,3-6.9c0.3-0.5,0.7-1,1-1.6c0.6-0.2,1.2-0.4,1.8-0.6
			c0.3-0.5,0.5-1,0.9-1.5c0.6-0.8,1.3-1.3,2.3-0.9c0.6,1.1,0.6,1.1-0.1,3.3c0.6,1.8,0.6,2.2-0.4,3.8c-0.5,0.8-1,1.5-1.5,2.3
			c-0.3,0.5-0.6,1.1-0.9,1.6c-0.1,0.3-0.2,0.6-0.3,0.9c-0.4,1.5-0.8,3.1-1.3,4.9c0.9-0.6,1.3-1.3,1.8-1.8c0.6-0.6,1.1-1.2,1.7-1.7
			c0.6-0.6,1.1-1.1,1.7-1.7c0.6-0.6,1.1-1.2,1.6-1.7c0.6-0.6,1.1-1.1,1.7-1.7c0.6-0.5,1.2-1,1.8-1.6c-0.1-0.3-0.2-0.6-0.3-0.9
			c-0.6-1.9-0.2-3.6,1.1-5.1c0.8-1,1.8-1.8,3-2.4c0.7-0.4,1.5-0.8,2.3-1.2c1.7-0.7,3-1.8,4.2-3.2c0.3-0.4,0.7-0.7,1-1.1
			c0.4,0,0.7,0,1,0c0.2,0.2,0.3,0.3,0.4,0.5c0.4,0.7-0.1,1.4-0.1,2.2c0.3,0.2,0.7,0.4,1.1,0.7c0,0.5,0.1,0.9,0.1,1.4
			c0.2,0.2,0.4,0.5,0.7,0.8c0.3,1.4-0.1,2.6-0.8,3.8c-0.9,1.5-1.9,2.9-2.9,4.3c-1,1.4-2.4,2.4-3.9,3.2c-1,0.5-2.1,0.9-3.3,0.8
			c-0.4,0-0.7,0-1.1,0.1c-0.7,2.5-2.1,4.6-3.4,6.7c-1.4,2.1-3.5,3.6-5.1,5.5c0.1,1.2,0.2,2.4,0.2,3.5c0.6,0.4,1.2,0.2,1.7,0.1
			c1.7-0.3,3.5-0.4,5.3-0.3c4.3,0,8.6,0,13,0.1c1.2,0,2.4-0.1,3.7-0.1c1.9-0.1,3.8-0.2,5.7-0.3c3-0.1,6.1-0.2,9.1-0.3
			c0.5,0,1,0,1.5,0c0.1,0.4,0.3,0.8,0.3,1.1c0,1,0,2.1,0,3.1c0,1.8,0.1,3.6,0.2,5.3c0.2,2.6,0.4,5.3,0.6,7.9
			c0.1,2.8,0.2,5.5,0.3,8.3c0,0.2,0.1,0.5,0.1,0.8c0.9,0.1,1.7,0.2,2.5,0.3c0.2,0.4,0.3,0.6,0.4,0.9c-0.3,0.4-0.6,0.8-0.9,1.3
			c0.1,1.8,0.3,3.7,0.4,5.6c0.6,10.3,1.2,20.6,1.8,30.9c0.6,9.7,1.1,19.5,1.6,29.2c0.4,7.8,0.9,15.7,1.3,23.5
			c0.4,7.8,0.8,15.6,1.2,23.4c0.2,4.6,0.5,9.2,0.8,13.8c0.1,1.3,0.2,2.7,0.7,3.8c-0.1,0.7-0.5,1-1,1.1c-0.5,0.1-1,0.1-1.5,0.1
			c-15.5,0.4-30.9,0.7-46.4,1c-14.6,0.3-29.1,0.7-43.7,1c-12.4,0.3-24.7,0.6-37.1,0.9c-8.3,0.2-16.6,0.4-24.8,0.6
			c-8.3,0.2-16.7,0.5-25,0.6c-9.8,0.2-19.6,0.4-29.4,0.6c-2,0-4-0.1-6-0.2c-0.2,0-0.5-0.1-0.6-0.2c-0.5-0.4-0.6-0.9-0.6-1.4
			c0-0.7,0-1.5,0-2.2c0-7.9,0.1-15.7,0.1-23.6c0-2.6-0.3-5.3-0.5-7.9c-0.3-3.9-0.6-7.8-1-11.7c-0.3-3.2-0.6-6.5-0.8-9.7
			c-0.3-3.2-0.5-6.4-0.7-9.6c-0.1-1.3-0.2-2.6-0.3-3.9c-0.5-5.9-0.5-11.8-0.7-17.7c-0.1-2.7-0.3-5.4-0.5-8.1
			c-0.1-2.4-0.3-4.8-0.3-7.2c0-8.1-0.6-16.2-1.3-24.3C585.2,409.6,585.2,407.8,585.1,405.9z M593.2,536.9c0.6,0.4,1.2,0.3,1.7,0.3
			c1.2-0.1,2.4-0.2,3.6-0.2c5.5-0.2,11.1-0.3,16.6-0.5c16.3-0.4,32.6-0.7,49-1.1c10.8-0.2,21.7-0.5,32.5-0.8
			c7.3-0.2,14.6-0.3,21.9-0.5c12.1-0.3,24.1-0.6,36.2-0.9c12.8-0.3,25.7-0.6,38.5-0.9c3.5-0.1,6.9-0.2,10.4-0.3
			c0.5,0,0.9-0.1,1.5-0.2c0-0.6,0-1.2,0-1.7c-0.2-3.1-0.4-6.2-0.6-9.4c-0.3-5.3-0.6-10.5-0.9-15.8c-0.4-6.7-0.9-13.5-1.3-20.2
			c-0.3-5.3-0.6-10.5-0.9-15.8c-0.4-7.5-0.9-15.1-1.4-22.6c-0.4-7.5-0.8-15-1.2-22.4c-0.4-6.8-0.7-13.6-1.1-20.4
			c0-0.3-0.1-0.6-0.2-0.9c-0.6,0-1.2-0.1-1.7-0.1c-2.9,0-5.7,0.1-8.6,0.2c-13.9,0.3-27.9,0.5-41.8,0.8c-10.8,0.2-21.7,0.4-32.5,0.6
			c-1,0-1.9,0.2-2.8,0.2c-0.9-0.1-1.8-0.2-2.6-0.2c-7.7,0.1-15.3,0.3-23,0.4c-23.6,0.4-47.3,0.8-70.9,1.2
			c-8.5,0.1-16.9,0.2-25.4,0.4c-0.5,0-1.1-0.1-1.7,0.3c0,0.7,0,1.4,0,2.1c0.1,0.9,0.2,1.7,0.3,2.6c1,8.2,1.5,16.5,1.4,24.8
			c0,1.3,0,2.6,0.1,3.9c0.2,2.9,0.5,5.9,0.6,8.8c0.3,6.5,0.5,13,0.8,19.5c0.2,4.4,0.5,8.7,0.9,13c0.4,4.7,0.8,9.4,1.2,14.1
			c0.2,2.3,0.4,4.5,0.6,6.8c0.4,3.8,0.8,7.6,0.7,11.4c0,4.3,0,8.6-0.1,12.9c0,3-0.1,6-0.1,9C593.1,535.8,593.1,536.4,593.2,536.9z
			 M728.8,401.4c0.6,0,1.1,0,1.7,0c4.4-0.1,8.8-0.2,13.1-0.2c2,0,4-0.1,6-0.1c0.4,0,0.8-0.1,1.2-0.2c0.1-0.3,0.2-0.4,0.3-0.6
			c0.7-3.2,1.5-6.5,2.2-9.7c0.1-0.5,0.2-1,0.2-1.5c0.6-5,1.1-10,1-15c0-0.4-0.1-0.8-0.1-1.4c-0.9,0.1-1.8,0.1-2.6,0.1
			c-6.3,0.2-12.6,0.6-19,0.3c-1.5-0.1-3-0.2-4.6-0.2c-1.8-0.1-3.6-0.1-5.5-0.1c-0.2,0-0.3,0.1-0.5,0.2c-0.1,1.4,0.3,2.8,0.5,4.2
			c0.8,4.2,2,8.4,2.5,12.6c0.5,3.8,1.9,7.4,3.1,10.9C728.5,400.9,728.6,401.1,728.8,401.4z M680.5,389.1c-0.6,0-1.1-0.1-1.5-0.1
			c-10.7,0.2-21.3-0.4-32-0.4c-3.2,0-6.4-0.3-9.7-0.4c-1.6-0.1-3.3-0.2-4.9-0.1c-4.6,0.3-9.1,0.6-13.7,0.9c-0.4,0-0.7,0.1-1.1,0.2
			c-0.1,1.3,0.3,2.4,0.4,3.5c0.1,1.2,0.4,2.3,0.6,3.5c0.2,1.2,0.4,2.4,0.5,3.6c0.1,1.1,0,2.3,0.5,3.5c0.8,0,1.4,0,2.1,0
			c11.3-0.2,22.6-0.4,34-0.6c8-0.1,15.9-0.3,23.9-0.4c0.5,0,1.1,0.2,1.6-0.4C680.9,397.7,680,393.5,680.5,389.1z M791.1,383.7
			c1.2,1,1.7,2.3,1.8,3.8c0.2,3.3,0.6,6.6,0.6,9.9c0,0.9,0,1.8,0.4,2.7c0.7,0.2,1.3,0.2,2.1-0.2c0-0.8,0.1-1.7,0.1-2.5
			c-0.2-2.9-0.4-5.9-0.6-8.8c-0.3-4.1-0.9-8.2-0.6-12.3c0-0.3-0.1-0.7-0.2-1c-0.9-0.3-1.7-0.1-2.4-0.1c-1.6,0.1-3.2,0.1-4.7,0.2
			c-1.2,0.1-2.4,0.1-3.6,0.1c-3.3,0.1-6.6,0.3-9.9,0.3c-4.2,0-8.4-0.1-12.6-0.1c-1.6,0-3.1,0-4.7,0.4c0,0.6,0,1,0,1.5
			c0.1,1.8,0.3,3.6,0.3,5.3c0.1,3.6,0.1,7.2,0,10.9c0,1.8-0.2,3.7-0.3,5.5c0,0.5-0.2,1,0.2,1.4c0.9,0.2,1.9,0.1,2.9-0.1
			c0-0.5,0-0.9,0-1.3c0-3.8,0-7.6-0.1-11.4c0-1.6,0.4-2.9,1.8-3.8c0.1-0.1,0.1-0.2,0.2-0.3c0-1.7,0-1.7,0.5-2.7
			c1.2-0.3,2.3-0.3,3.4,0.1c0.6,0.8,0.1,1.8,0.6,2.6c1.5,0.6,2.1,1.9,2.1,3.5c0.1,2.9,0.3,5.9,0,8.8c-0.1,1.1-0.1,2.2-0.1,3.3
			c0,0.3,0.1,0.7,0.2,1c2.1,0.2,3.9,0.2,5.2-0.2c0.3-0.6,0.1-1.2,0.1-1.8c0-3.3-0.1-6.6-0.1-9.9c0-1.4,0.2-2.7,1.2-3.8
			c0.2-0.3,0.5-0.5,0.7-0.7c0-1.1-0.4-2,0.1-3.1c1.2-0.3,2.4-0.3,3.5,0c0.5,0.9,0.4,2,0.6,3c0.9,0.9,1.5,2,1.7,3.3
			c0.4,2.2,0.8,4.5,0.7,6.8c0,1.7,0,3.4,0.1,5.2c0,0.3,0.1,0.7,0.2,1c1.3,0.1,2.5,0.2,3.7-0.1c0.1-1,0-1.9-0.1-2.8
			c-0.2-3.2-0.4-6.5-0.7-9.7c-0.1-1.4,0.2-2.7,1.4-3.6c0.1-0.1,0.1-0.2,0.2-0.3c0.1-1.1-0.2-2.2,0.2-3.3c1.2-0.3,2.3-0.3,3.3,0.1
			C791.2,381.5,790.8,382.6,791.1,383.7z M672.9,376.2c-0.3-0.1-0.4-0.2-0.5-0.2c-4.4,0.1-8.8,0.1-13.1,0.2c-4,0.1-7.9,0.2-11.9,0.3
			c-3,0-6-0.1-8.9-0.1c-0.8,0-1.6,0.1-2.4,0.1c-3.2,0-6.3-0.1-9.5-0.2c-0.3,0-0.7,0.1-1,0.2c-0.5,2.9,0.3,7.6,1.6,10.2
			c1.2,0.1,2.5,0,3.8-0.1c1.9,0,3.9-0.1,5.8,0c3.8,0.1,7.5,0.3,11.3,0.5c2.3,0.1,4.6,0,6.9,0.1c5.6,0.2,11.2,0.4,16.8,0.3
			c0.6,0,1.2,0.2,1.8-0.3C673.8,383.5,673.8,379.9,672.9,376.2z M589.5,403.9c0.6,0,1,0,1.5,0c2.9-0.1,5.8-0.1,8.8-0.2
			c3.7-0.1,7.4-0.2,11.1-0.3c0.4,0,0.8-0.2,1.1-0.2c-0.1-1.7-0.2-3.2-0.4-4.7c-0.1-1.5-0.2-3.1-0.4-4.6c-0.2-1.5,0-3.1-0.5-4.7
			c-1.8,0.2-3.6-0.5-5.4,0c-0.3,0.1-0.7,0.1-1.1,0.1c-2.7,0-5.4,0-8,0c-0.7,0-1.5,0-2.2,0c-1.3,0-2.7,0.1-4,0.1
			c-0.3,0-0.7,0.1-1,0.2C588.9,390.7,589.3,402,589.5,403.9z M710,389.6c-0.3-0.1-0.6-0.1-0.8-0.1c-3.1,0.2-6.2,0.3-9.3-0.1
			c-0.3,0-0.6,0-0.9,0c-1.2,0-2.4,0-3.6,0c-2.4-0.1-4.9-0.2-7.3-0.2c-0.3,0-0.7,0.1-1.1,0.2c0.1,1.5,0.2,2.9,0.3,4.3
			c0.1,1.4,0.3,2.8,0.5,4.2c0.3,1.4-0.1,2.8,0.5,4.2c0.3,0,0.6,0.1,0.9,0.1c6.4-0.1,12.8-0.2,19.2-0.3c0.3,0,0.7-0.1,1-0.2
			C709.8,399.5,710.2,391.8,710,389.6z M636,374.9c9.8,0.3,19.5,0.4,29.1-0.4c1.2-3.1,0.2-6.2-2.7-8.3c-4.4,0.1-9-0.1-13.6,0.1
			c-3.5,0.2-6.9,0.4-10.4,0.2c-0.6,0-1.2,0-1.7,0c-1.2,0.9-1.6,2.1-1.6,3.3C635,371.6,634.7,373.3,636,374.9z M634.4,374.9
			c-0.1-1.6-0.2-2.9-0.2-4.3c-0.1-1.4,0.5-2.7,1-4.1c-0.4,0-0.7-0.1-1-0.1c-3.2-0.1-6.4-0.1-9.7-0.2c-0.6,0-1.2,0-1.8,0.1
			c-2.7,0.3-5.4,0.7-8.1,1.1c-0.1,0-0.2,0.1-0.3,0.2c-0.1,1.3,0.2,6.2,0.5,7.4C621.3,375.1,627.8,375,634.4,374.9z M610.8,387.7
			c4.7,0.3,9.3-0.4,14-0.7c0.3,0,0.6-0.1,0.9-0.2c-0.4-1.8-0.8-3.4-1-5.1c-0.2-1.7-0.2-3.4-0.3-5.1c-0.6-0.4-1.2-0.2-1.8-0.2
			c-4.3-0.1-8.6,0.1-13,0.7c0.2,1.2,0.3,2.4,0.5,3.5c0.2,1.2,0.3,2.4,0.5,3.6C610.7,385.3,610.5,386.5,610.8,387.7z M675.1,387.3
			c0.3,0.1,0.5,0.1,0.8,0.1c3.9,0,7.9,0.1,11.8,0.1c0.3,0,0.5-0.1,0.9-0.3c-1.1-4.1-0.8-8.2-1.9-12.2c-0.9-0.3-1.8-0.1-2.6,0
			c-2.9,0.4-5.9,0.8-8.9,0.8c-0.3,0-0.7,0.1-1,0.2c0.3,1.9,0.5,3.7,0.7,5.5C675.1,383.5,674.8,385.4,675.1,387.3z M679,366
			c-0.6-0.1-1-0.2-1.4-0.2c-1.3,0-2.7-0.1-4,0.1c-2.7,0.3-5.5,0.4-8.2,0.4c-0.3,0-0.6-0.1-0.8,0.3c0.1,0.1,0.1,0.3,0.2,0.4
			c1.2,1.4,1.7,3,1.7,4.7c0,0.6,0,1.2,0,1.8c0,0.3,0.1,0.7,0.2,1.1c4.2,0.1,8.2-0.1,12.3-0.3c0.3,0,0.6-0.2,0.9-0.3
			C679,371.4,679.6,368.7,679,366z M760.8,400.6c1.8,0.3,4.9,0.2,6.3-0.1c0-0.4,0.1-0.8,0.1-1.2c0-1.2,0.1-2.3,0.1-3.5
			c0-2.8-0.1-5.5-0.1-8.3c0-0.7-0.2-1.5-0.9-2.1c-1.4-0.6-3-0.1-4.3,0c-0.8,0.5-1,1.1-1,1.7c0,0.5-0.1,1-0.1,1.5
			c-0.1,3.8-0.2,7.6-0.2,11.4C760.7,400.2,760.8,400.3,760.8,400.6z M778.8,385c-0.5,0-1-0.1-1.4-0.1c-1.3,0-2.1,0.5-2.4,1.8
			c-0.2,0.7-0.3,1.4-0.3,2.2c0.1,3.6,0.2,7.2,0.2,10.9c0,0.2,0.1,0.4,0.2,0.7c1.1,0.2,2.1,0.1,3.1,0c1-0.1,2.1,0.2,3-0.4
			c-0.1-2.7-0.2-5.4-0.3-8.1c-0.1-1.5-0.4-2.9-0.6-4.4C780.1,386.7,779.9,385.8,778.8,385z M743.6,371.3c0.1-0.4,0.2-0.7,0.2-1.1
			c0.2-1.3,0.3-2.7,0.5-4c0.8-4.7,0.5-9.4,0.4-14.1c-0.1-1.8-0.4-3.5-0.6-5.3c-0.1-0.9-0.4-1.8-0.7-2.7c-0.1-0.2-0.4-0.3-0.7-0.5
			c-0.3,0.4-0.6,0.7-0.7,1c-0.3,0.8-0.6,1.6-0.7,2.5c-0.3,1.3-0.5,2.6-0.6,4c0.6-0.4,1.1-0.8,1.6-1.1c0.3,0.2,0.5,0.4,0.7,0.5
			c0.2,1.3,0,5-0.5,5.9c-0.5,1-0.5,2-0.7,3c-0.7,3.4-1.3,6.9-2.4,10.2c-0.2,0.6-0.6,1.2-0.2,2C740.7,371.7,742.1,371.7,743.6,371.3z
			 M786.4,386.2c0.1,1.5,0.1,3,0.2,4.6c0.1,1.6,0.1,3.2,0.4,4.8c0.2,1.6,0,3.2,0.5,4.8c1,0,1.9,0.1,2.8,0c0.9,0,1.8,0.2,2.7-0.3
			c-0.1-0.5-0.1-1-0.2-1.5c-0.1-1.3-0.3-2.5-0.4-3.8c-0.2-2.5-0.4-5-0.7-7.5c-0.1-0.6-0.3-1.2-0.5-1.7c-0.4-0.8-1.2-1.2-2.1-1.1
			C788,384.6,787.2,385,786.4,386.2z M749.2,339.3c0.8-1.9,0.5-3.7,0.6-5.5c0-1.2-0.1-2.3-0.8-3.4c-0.6,0.6-1.1,1-1.7,1.5
			c-0.2-0.2-0.4-0.4-0.6-0.6c0.1-1.1,0.6-2.1,0.2-3.1c-0.6,0-1.3,0.8-1.9-0.2c0-0.4-0.1-1-0.1-1.8c-2,1.9-3.3,4-4.3,6.3
			c-0.7,1.6-0.6,4-0.1,5.7C743.4,338.2,746.4,337.7,749.2,339.3z M772.7,344.2c-1.3,1.4-2.8,2.1-4.3,2.8c-0.8,0.4-1.6,0.7-2.2,1.2
			c-0.8,0.6-1.5,1.2-2.2,1.9c-0.2,0.2-0.3,0.6-0.5,0.9c0.8,0.5,1.6,0.9,2.2,1.5c0.7,0.5,1.3,1.1,1.8,1.8c0.5,0.7,0.8,1.4,1.3,2.3
			c1.4-0.8,2.5-1.8,3.3-3c0.9-1.2,1.7-2.4,2.5-3.6c0.4-0.6,0.8-1.2,0.8-2.1c-0.4-0.3-0.7-0.5-1.1-0.8c-0.1-0.4-0.2-0.7-0.2-1
			c-0.3-0.2-0.6-0.4-1-0.6C772.9,345.2,772.8,344.9,772.7,344.2z M618.2,403.1c0.1-2-1.3-12.1-1.9-13.9c-1.3-0.1-2.7-0.2-4.1,0.2
			c-0.1,2.4,0.3,4.7,0.5,7c0.2,2.3,0.1,4.6,0.7,6.9C615.4,403.5,617,403.4,618.2,403.1z M754.3,370.9c0.7-0.8,1.4-1.6,2.2-2.3
			c2.4-2.1,4.1-4.8,5.6-7.6c0.5-1,0.9-2.1,1-3.3c0-0.3,0-0.6-0.1-0.9c-0.3,0.1-0.5,0.1-0.6,0.2c-0.7,0.6-1.5,1.2-2.2,1.9
			c-1,1-1.9,2-3,3c-2.2,2-4,4.3-5.8,6.6c-0.5,0.6-0.9,1.3-1.2,2c-0.1,0.2-0.1,0.5-0.1,0.9C751.6,371.3,752.9,371.3,754.3,370.9z
			 M686.7,402c0.1-1.6-0.1-3-0.3-4.4c-0.1-1.5-0.3-2.9-0.4-4.4c-0.1-1.4,0.1-2.8-0.3-4.2c-1.4-0.1-2.8-0.3-4.2,0.1
			c-0.1,2.4,0.7,11.5,1.2,13C684,402.2,685.3,402.2,686.7,402z M734.2,371.6c1.2,0,2.3,0,3.3,0c1.3-2.8,3.2-12,3.1-14.4
			c-0.2-0.1-0.4-0.2-0.6-0.3c-0.3-0.1-0.7-0.2-1.2-0.3c-0.2,0.5-0.5,1-0.7,1.5C736.8,362.5,735.1,366.8,734.2,371.6z M752.3,358.4
			c-0.8-0.5-1.5-0.8-2.3-0.9c-0.1,0.1-0.3,0.2-0.3,0.3c-1.6,4.4-2.8,8.9-4.6,13.2c0,0,0,0.1,0,0.2c0,0.1,0.1,0.1,0.1,0.2
			c1.1,0.1,2.2,0.1,3.4-0.1C750.3,367.1,751.2,362.8,752.3,358.4z M729.2,341.7c0,1-0.1,2,0,2.9c0,1-0.4,2,0,3
			c2.2,0.2,4.9,0.1,6.1-0.2c0-0.2,0.1-0.5,0.1-0.7c-0.1-1.5-0.2-3-0.4-4.6c-1-0.2-2-0.5-2.9-0.6
			C731.2,341.4,730.2,341.6,729.2,341.7z M716.4,348.3c0.3,1.3,0.6,2.7,1.1,4c0.5,1.3,1.1,2.6,2.4,3.5c0.1-0.9-0.3-1.3-0.6-1.8
			c-0.5-1-1.1-1.9-1.3-3c0-0.2-0.1-0.5,0.4-0.6c0.8,1.1,1.1,2.6,2.3,3.7c0.4-1.8-1.1-3.1-1-4.7c0.7,0.1,0.6,1,1.3,1.2
			c-0.6-2.7-2.3-4.9-5-6.7c-0.3,0.7-0.5,1.4-0.8,2c-0.3,0.8-0.5,1.7-0.4,2.5c0.1,1.7,0.6,3.4,1.4,4.9c0.1,0.2,0.2,0.6,0.6,0.4
			C716.4,352,715.5,350.3,716.4,348.3z M729,371.5c0.1-1-0.2-1.8-0.5-2.6c-1.1-2.6-2.2-5.2-3.3-7.8c-0.5-1.2-1.4-2.3-2.3-3.3
			c-0.2-0.2-0.4-0.5-0.8-0.2c0.6,2.9,1.5,5.8,2.5,8.6c0.4,1.1,0.8,2.2,1.3,3.2c0.3,0.8,0.8,1.5,1.6,2.1
			C727.9,371.6,728.4,371.5,729,371.5z M732.2,371.6c0.1-0.5,0.3-0.9,0.3-1.2c0.2-3.1,0.3-6.1,0.4-9.2c0.1-2.5-0.6-4.8-1.3-7.3
			c-0.7,0.9-0.7,1.9-0.7,2.8c0,3.6,0.1,7.2,0.2,10.9c0,1.2,0.1,2.4,0.1,3.7c0,0.1,0.1,0.2,0.2,0.4
			C731.5,371.5,731.8,371.6,732.2,371.6z M752.3,400.9c1.3,0.1,2.4,0.3,3.4-0.2c0.1-1.4,0.1-2.8,0.1-4.1c0-2.9-0.1-5.7-0.1-8.6
			c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.3,0c-0.2,2.7-1.1,5.2-1.8,7.8C753.1,397.3,752.7,399,752.3,400.9z M735.4,349
			c-2.2-0.1-4.2-0.2-6.3-0.3c-0.1,1.3-0.4,2.7-0.3,4.2c0.6,0,1,0,1.5,0c0.4-0.5,0.6-1.1,1.5-1.2c0.3,0.2,0.7,0.4,1.2,0.6
			c0.9-0.2,1.8-0.3,2.5-1c0-0.4,0.1-0.7,0.1-1.1C735.5,349.8,735.4,349.5,735.4,349z M744.3,340.6c-0.1-0.4-0.2-0.8-0.3-1.3
			c-1.2,0-2.3-0.3-3.4,0.1c0,1.2,0.2,2.3,0.9,3.4c0.6-0.2,1.1-0.4,1.6-0.6c0.3,0.1,0.5,0.2,0.7,0.3c0.8,0.5,0.9,1.5,1.5,2.2
			c1.7-1.3,2.7-2.6,3.3-4.2c-0.3-0.2-0.6-0.3-0.9-0.5c-0.5,0.5-0.7,1.3-1.4,1.4C745.4,341.5,744.8,341.3,744.3,340.6z M755.5,350
			c-0.1-0.1-0.2-0.1-0.3-0.2c-0.5,0.7-1,1.5-1.4,2.1c-0.6,0.2-1.2,0.4-1.7,0.6c-0.7,1.1-1.5,2.1-1.8,3.4c1,0.4,2,0.8,2.9,1.2
			c0.9-1.2,1.8-2.3,2.3-3.8c-0.2-0.5-0.4-0.9-0.6-1.3C755.1,351.4,755.3,350.7,755.5,350z M766.9,357.1c-0.3-2.1-1.6-3.6-3.8-4.5
			c-0.3,0.8,0,1.7,0.5,2.2c0.7,0.7,0.9,1.7,1.3,2.6C765.6,357.3,766.3,357.5,766.9,357.1z M741.5,356c0.2-1.4,0.5-2.6,0.4-3.9
			c-1.4,1-2.3,2.2-2.7,3.2C739.9,355.5,740.6,355.7,741.5,356z M787.9,381.3c0,0.8-0.4,1.4,0.2,2.1c0.6-0.1,1.2-0.2,1.7-0.3
			c0.2-0.7,0.2-1.3-0.3-1.8C789.1,381.3,788.6,381.3,787.9,381.3z M762.6,384.1c0.9-0.1,1.6-0.2,2.3-0.3c0.1-0.6,0.3-1.1-0.1-1.6
			c-0.6-0.1-1.2-0.3-2,0.1C762.7,382.8,762.7,383.3,762.6,384.1z M776.3,383.9c0.9-0.1,1.5-0.1,2.3-0.1c0-0.6-0.1-1.1-0.1-1.7
			c-0.8-0.2-1.5-0.3-2.2,0.1C776.3,382.7,776.3,383.2,776.3,383.9z M746.5,340.1c-0.1-0.2-0.2-0.3-0.3-0.5c-0.5-0.1-0.9-0.2-1.4,0.3
			c0.1,0.2,0.2,0.5,0.2,0.7C745.7,340.8,746.1,340.8,746.5,340.1z"/>
                            <path className="st14" d="M593.2,536.9c0-0.5-0.1-1.1-0.1-1.7c0-3,0-6,0.1-9c0-4.3,0-8.6,0.1-12.9c0-3.8-0.3-7.6-0.7-11.4
			c-0.2-2.3-0.4-4.5-0.6-6.8c-0.4-4.7-0.8-9.4-1.2-14.1c-0.3-4.3-0.6-8.7-0.9-13c-0.3-6.5-0.5-13-0.8-19.5c-0.1-2.9-0.4-5.9-0.6-8.8
			c-0.1-1.3-0.1-2.6-0.1-3.9c0.1-8.3-0.5-16.6-1.4-24.8c-0.1-0.9-0.2-1.7-0.3-2.6c0-0.7,0-1.4,0-2.1c0.6-0.5,1.1-0.3,1.7-0.3
			c8.5-0.1,16.9-0.2,25.4-0.4c23.6-0.4,47.3-0.8,70.9-1.2c7.7-0.1,15.3-0.3,23-0.4c0.8,0,1.7,0.1,2.6,0.2c0.9-0.1,1.8-0.2,2.8-0.2
			c10.8-0.2,21.7-0.4,32.5-0.6c13.9-0.2,27.9-0.5,41.8-0.8c2.9-0.1,5.7-0.1,8.6-0.2c0.5,0,1.1,0,1.7,0.1c0.1,0.4,0.2,0.7,0.2,0.9
			c0.4,6.8,0.7,13.6,1.1,20.4c0.4,7.5,0.8,15,1.2,22.4c0.4,7.5,0.9,15.1,1.4,22.6c0.3,5.3,0.6,10.5,0.9,15.8
			c0.4,6.7,0.9,13.5,1.3,20.2c0.3,5.3,0.6,10.5,0.9,15.8c0.2,3.1,0.4,6.2,0.6,9.4c0,0.5,0,1.1,0,1.7c-0.6,0.1-1,0.2-1.5,0.2
			c-3.5,0.1-6.9,0.2-10.4,0.3c-12.8,0.3-25.7,0.6-38.5,0.9c-12.1,0.3-24.1,0.6-36.2,0.9c-7.3,0.2-14.6,0.3-21.9,0.5
			c-10.8,0.3-21.7,0.5-32.5,0.8c-16.3,0.4-32.6,0.7-49,1.1c-5.5,0.1-11.1,0.3-16.6,0.5c-1.2,0-2.4,0.2-3.6,0.2
			C594.3,537.2,593.8,537.3,593.2,536.9z M789.5,407c-2.4,1.1-4.7,0.8-7,0.6c-1.3-0.1-2.7-0.3-4-0.2c-2.9,0.1-5.7,0.2-8.6,0.4
			c-8.3,0.4-16.5,0.8-24.8,1.1c-11.4,0.4-22.8,0.5-34.2,0.2c-2.9-0.1-5.8-0.1-8.8,0c-6.8,0.2-13.6,0.4-20.5,0.7
			c-8.8,0.4-17.5,0.7-26.3,0.6c-5.2,0-10.3,0.4-15.5,1c-4.7,0.5-9.3,1-14,1.2c-4.1,0.2-8.2,0.2-12.2,0.2c-6,0-11.9,0.1-17.9,0.1
			c-0.7,0-1.3,0-2.3,0c-0.3,0.1-0.8,0.4-1.4,0.7c0,0.5,0,1,0.1,1.4c0.4,0.2,0.8,0.4,1.1,0.5c0.1,0.2,0.1,0.4,0.1,0.6
			c0.4,5.5,0.9,11,0.9,16.5c0,2.7,0,5.4,0,8.1c0,1.5,0.1,3.1,0.2,4.6c0.1,2.5,0.3,4.9,0.4,7.4c0.1,2.5,0.1,5,0.2,7.5
			c0.1,2.6,0.2,5.1,0.3,7.8c0.2,0.2,0.5,0.5,0.6,0.7c1.4,0,2.6,0,3.9,0c4,0,8,0,12.1-0.3c3-0.2,6-0.4,8.9-0.6
			c5.5-0.2,11.1-0.4,16.6-0.6c3.8-0.1,7.5-0.3,11.3-0.5c5-0.3,10-0.6,15-0.9c5.2-0.3,10.5-0.6,15.7-0.9c7-0.4,14-0.7,21-0.5
			c14.7,0.4,29.5,0.2,44.2,0c7.2-0.1,14.5-0.3,21.7-0.4c1.6,0,3.2-0.1,4.7-0.1c1.7,0,3.4,0,5.1,0c1.5,0,3,0.1,4.6,0
			c1.6-0.1,3.3-0.4,4.9-0.3c0.4,0,0.8-0.1,1.2-0.2c1.1-0.3,2.3-0.5,3.4-0.3c0.4,0.1,0.8,0,1.1,0c0.8-0.5,0.8-1.2,0.8-1.9
			c-0.1-2.2-0.1-4.4-0.2-6.6c-0.1-2.6-0.3-5.3-0.3-7.9c-0.1-9-0.1-17.9-0.1-26.9c0-2.8-0.2-5.5-1-8.2c-0.2-0.6-0.4-1.3-0.2-1.9
			c0.6-0.4,1.5-0.3,1.5-1.3C791.6,407,790.3,407.6,789.5,407z M792.5,470.1c-1.9-0.2-3.7-0.3-5.6-0.1c-2.5,0.2-5.1,0.3-7.7,0.5
			c-1.2,0.1-2.3,0.1-3.5,0.1c-8.8,0.3-17.5,0.3-26.3,0c-2.7-0.1-5.5-0.4-8.2-0.5c-6.9-0.2-13.9-0.4-20.8-0.6
			c-7.7-0.2-15.4-0.1-23,0.5c-7.2,0.5-14.4,0.7-21.5,0.3c-2.2-0.1-4.5-0.1-6.8,0c-4.1,0.2-8.2,0.4-12.2,0.6
			c-2.8,0.2-5.6,0.4-8.4,0.6c-4.1,0.4-8.2,0.9-12.4,1.3c-1.9,0.2-3.8,0.2-5.7,0.4c-4.9,0.4-9.7,0.9-14.6,1c-3.2,0-6.3,0.1-9.5,0.1
			c-2.5,0-5,0.2-7.5-0.4c-0.5-0.1-1,0-1.5,0.6c0,0.4-0.1,1-0.1,1.6c0,1.7,0.1,3.4,0.1,5.2c0,1.4,0,2.8,0,4.2
			c0.2,3.9,0.5,7.8,0.6,11.8c0.1,4.2,0.1,8.4,0.2,12.5c0,2.1,0.2,4.3,0.4,6.4c0.4,4.1,1.1,8.2,1.1,12.3c0,1,0.3,2,0.9,2.9
			c1,0.3,1.9,0.2,2.8,0.2c7.6-0.1,15.2-0.3,22.8-0.4c11.7-0.3,23.4-0.6,35.1-0.9c7.9-0.2,15.7-0.4,23.6-0.6
			c11.2-0.3,22.4-0.6,33.6-0.9c11.8-0.3,23.5-0.6,35.3-0.9c7.8-0.2,15.6-0.4,23.4-0.6c5.3-0.1,10.6-0.3,15.9-0.4
			c0.3,0,0.6,0,0.9-0.1c0.4-0.1,0.8-0.3,1.3-0.4c0-0.7,0.1-1.4,0.1-2c-0.2-3.9-0.3-7.7-0.6-11.6c-0.4-4.6-0.6-9.2-0.5-13.8
			c0-2.8,0-5.7-0.2-8.5c-0.3-4.6-0.3-9.2-0.9-13.8c-0.2-1.6-0.1-3.2-0.1-4.8C793.1,471.3,793.3,470.7,792.5,470.1z"/>
                            <path className="st13" d="M728.8,401.4c-0.2-0.3-0.3-0.4-0.3-0.6c-1.2-3.6-2.6-7.1-3.1-10.9c-0.5-4.3-1.7-8.4-2.5-12.6
			c-0.3-1.4-0.6-2.8-0.5-4.2c0.2-0.1,0.4-0.2,0.5-0.2c1.8,0,3.7,0,5.5,0.1c1.5,0,3,0.1,4.6,0.2c6.3,0.3,12.7-0.1,19-0.3
			c0.8,0,1.7-0.1,2.6-0.1c0.1,0.5,0.1,0.9,0.1,1.4c0.1,5-0.4,10-1,15c-0.1,0.5-0.1,1-0.2,1.5c-0.7,3.2-1.5,6.5-2.2,9.7
			c0,0.2-0.1,0.3-0.3,0.6c-0.4,0.1-0.8,0.2-1.2,0.2c-2,0.1-4,0.1-6,0.1c-4.4,0.1-8.8,0.2-13.1,0.2
			C729.9,401.4,729.3,401.4,728.8,401.4z"/>
                            <path className="st11" d="M680.5,389.1c-0.5,4.5,0.5,8.6,0.8,12.8c-0.5,0.5-1.1,0.4-1.6,0.4c-8,0.2-15.9,0.3-23.9,0.4
			c-11.3,0.2-22.6,0.4-34,0.6c-0.7,0-1.3,0-2.1,0c-0.5-1.2-0.4-2.3-0.5-3.5c-0.1-1.2-0.3-2.4-0.5-3.6c-0.2-1.2-0.4-2.3-0.6-3.5
			c-0.1-1.1-0.6-2.3-0.4-3.5c0.4-0.1,0.7-0.2,1.1-0.2c4.6-0.3,9.1-0.7,13.7-0.9c1.6-0.1,3.3,0,4.9,0.1c3.2,0.1,6.4,0.4,9.7,0.4
			c10.7,0.1,21.3,0.6,32,0.4C679.4,389,679.8,389,680.5,389.1z M644.1,391.6c-1.5,0.9-2,1.7-2.2,2.9c-0.1,1.7,0.9,3,2.5,3.2
			c1.1,0.2,2.2-0.1,3.1-0.8c1.3-0.9,1.5-2.4,0.5-3.7c-0.6-0.8-1.4-1.3-2.4-1.5C645.1,391.7,644.6,391.7,644.1,391.6z M664.8,397.6
			c1.1,0,1.9-0.2,2.6-0.6c1.5-1,1.8-2.7,0.5-4c-0.4-0.4-0.8-0.7-1.3-0.9c-1.4-0.7-2.5-0.5-3.6,0.7c-0.3,0.4-0.6,0.9-0.8,1.4
			c-0.5,1.3,0.1,2.6,1.4,3.2C664.1,397.5,664.6,397.5,664.8,397.6z M655.1,397.2c1,0,1.8-0.2,2.4-0.7c1.7-1.3,1.6-3.5-0.2-4.6
			c-0.6-0.3-1.2-0.5-1.9-0.6c-1.4-0.4-3,0.8-3.3,2.4c-0.3,1.6,0.6,2.9,2.1,3.3C654.5,397.1,654.9,397.1,655.1,397.2z M635.7,391.2
			c-1.2-0.1-2.1,0.3-2.8,1.3c-0.8,1.3-0.5,3.1,0.6,4c1,0.9,2.5,0.9,3.7,0c0.5-0.4,0.9-0.8,1.2-1.3c0.7-1.2,0.4-2.6-0.7-3.5
			C637.1,391.3,636.4,391.1,635.7,391.2z M672.9,392.1c-0.4,0.5-0.9,0.9-1.2,1.5c-0.6,1-0.4,2.1,0.6,2.7c1.1,0.7,2.4,0.9,3.7,0.4
			c1.2-0.4,1.8-1.5,1.4-2.8c-0.3-1-0.9-1.7-1.9-2C674.6,391.6,673.9,391.6,672.9,392.1z M626.1,390.9c-1,0.5-1.5,1.4-1.7,2.4
			c-0.2,1.1,0.4,2.2,1.3,2.6c0.9,0.4,1.9,0.3,2.7-0.5c0.6-0.6,1-1.4,1.1-2.2c0.1-0.9-0.2-1.7-1.1-2.1
			C627.6,390.8,626.9,390.7,626.1,390.9z"/>
                            <path className="st11" d="M791.1,383.7c-0.3-1.1,0.1-2.2-0.6-3.1c-1.1-0.4-2.2-0.4-3.3-0.1c-0.4,1.1-0.1,2.2-0.2,3.3
			c0,0.1-0.1,0.3-0.2,0.3c-1.2,0.9-1.5,2.2-1.4,3.6c0.3,3.2,0.5,6.5,0.7,9.7c0.1,0.9,0.2,1.8,0.1,2.8c-1.2,0.3-2.4,0.2-3.7,0.1
			c-0.1-0.4-0.2-0.7-0.2-1c-0.1-1.7-0.1-3.4-0.1-5.2c0.1-2.3-0.4-4.5-0.7-6.8c-0.2-1.3-0.8-2.5-1.7-3.3c-0.2-1,0-2.1-0.6-3
			c-1.2-0.3-2.4-0.3-3.5,0c-0.5,1-0.1,2-0.1,3.1c-0.2,0.2-0.4,0.5-0.7,0.7c-0.9,1.1-1.2,2.4-1.2,3.8c0,3.3,0.1,6.6,0.1,9.9
			c0,0.6,0.2,1.2-0.1,1.8c-1.3,0.3-3.1,0.4-5.2,0.2c-0.1-0.3-0.2-0.6-0.2-1c0-1.1,0-2.2,0.1-3.3c0.3-2.9,0.1-5.9,0-8.8
			c-0.1-1.6-0.6-2.9-2.1-3.5c-0.5-0.8,0.1-1.8-0.6-2.6c-1.1-0.4-2.3-0.4-3.4-0.1c-0.5,1-0.5,1-0.5,2.7c-0.1,0.1-0.1,0.3-0.2,0.3
			c-1.4,0.9-1.8,2.2-1.8,3.8c0,3.8,0.1,7.6,0.1,11.4c0,0.4,0,0.8,0,1.3c-1.1,0.2-2,0.3-2.9,0.1c-0.5-0.5-0.3-1-0.2-1.4
			c0.1-1.8,0.3-3.7,0.3-5.5c0-3.6,0-7.2,0-10.9c0-1.8-0.2-3.6-0.3-5.3c0-0.5,0-1,0-1.5c1.6-0.4,3.2-0.4,4.7-0.4
			c4.2,0,8.4,0.2,12.6,0.1c3.3,0,6.6-0.2,9.9-0.3c1.2,0,2.4-0.1,3.6-0.1c1.6-0.1,3.2-0.1,4.7-0.2c0.8,0,1.6-0.2,2.4,0.1
			c0.1,0.3,0.2,0.7,0.2,1c-0.3,4.1,0.3,8.2,0.6,12.3c0.2,2.9,0.4,5.9,0.6,8.8c0,0.8,0,1.7-0.1,2.5c-0.7,0.4-1.4,0.4-2.1,0.2
			c-0.4-0.9-0.4-1.8-0.4-2.7c0.1-3.3-0.3-6.6-0.6-9.9C792.8,386,792.3,384.7,791.1,383.7z"/>
                            <path className="st21" d="M672.9,376.2c1,3.7,0.9,7.3,0.8,10.9c-0.6,0.5-1.2,0.3-1.8,0.3c-5.6,0.1-11.2-0.1-16.8-0.3
			c-2.3-0.1-4.6,0-6.9-0.1c-3.8-0.1-7.5-0.3-11.3-0.5c-1.9-0.1-3.9,0-5.8,0c-1.3,0-2.5,0.2-3.8,0.1c-1.3-2.6-2.1-7.3-1.6-10.2
			c0.3-0.1,0.6-0.2,1-0.2c3.2,0,6.3,0.1,9.5,0.2c0.8,0,1.6-0.1,2.4-0.1c3,0,6,0.1,8.9,0.1c4,0,7.9-0.2,11.9-0.3
			c4.4-0.1,8.8-0.1,13.1-0.2C672.5,376,672.6,376.1,672.9,376.2z"/>
                            <path className="st21" d="M589.5,403.9c-0.2-1.9-0.6-13.2-0.5-14.3c0.3-0.1,0.6-0.2,1-0.2c1.3-0.1,2.7-0.1,4-0.1c0.7,0,1.5,0,2.2,0
			c2.7,0,5.4,0,8,0c0.4,0,0.7,0,1.1-0.1c1.8-0.5,3.6,0.2,5.4,0c0.5,1.6,0.3,3.2,0.5,4.7c0.2,1.5,0.3,3.1,0.4,4.6
			c0.1,1.5,0.2,3.1,0.4,4.7c-0.4,0.1-0.8,0.2-1.1,0.2c-3.7,0.1-7.4,0.2-11.1,0.3c-2.9,0.1-5.8,0.1-8.8,0.2
			C590.6,403.9,590.1,403.9,589.5,403.9z"/>
                            <path className="st21" d="M710,389.6c0.2,2.2-0.2,9.9-0.7,12c-0.3,0.1-0.6,0.2-1,0.2c-6.4,0.1-12.8,0.2-19.2,0.3
			c-0.3,0-0.6-0.1-0.9-0.1c-0.6-1.4-0.2-2.8-0.5-4.2c-0.3-1.4-0.4-2.8-0.5-4.2c-0.1-1.4-0.2-2.8-0.3-4.3c0.4-0.1,0.8-0.2,1.1-0.2
			c2.4,0.1,4.9,0.2,7.3,0.2c1.2,0,2.4,0,3.6,0c0.3,0,0.6,0,0.9,0c3.1,0.4,6.2,0.3,9.3,0.1C709.4,389.4,709.6,389.5,710,389.6z"/>
                            <path className="st13" d="M636,374.9c-1.2-1.5-1-3.3-0.9-5c0.1-1.2,0.4-2.4,1.6-3.3c0.5,0,1.1,0,1.7,0c3.5,0.2,6.9,0,10.4-0.2
			c4.6-0.3,9.1-0.1,13.6-0.1c2.9,2.1,3.9,5.2,2.7,8.3C655.4,375.2,645.8,375.2,636,374.9z"/>
                            <path className="st11" d="M634.4,374.9c-6.6,0.1-13.1,0.1-19.7,0.2c-0.3-1.2-0.7-6.2-0.5-7.4c0.1-0.1,0.2-0.2,0.3-0.2
			c2.7-0.4,5.4-0.7,8.1-1.1c0.6-0.1,1.2-0.1,1.8-0.1c3.2,0,6.4,0.1,9.7,0.2c0.3,0,0.6,0,1,0.1c-0.4,1.4-1,2.7-1,4.1
			C634.2,372,634.3,373.3,634.4,374.9z"/>
                            <path className="st11" d="M610.8,387.7c-0.4-1.2-0.1-2.4-0.3-3.5c-0.2-1.2-0.3-2.4-0.5-3.6c-0.2-1.1-0.3-2.3-0.5-3.5
			c4.4-0.6,8.7-0.7,13-0.7c0.6,0,1.2-0.2,1.8,0.2c0.1,1.7,0.1,3.4,0.3,5.1c0.2,1.7,0.7,3.3,1,5.1c-0.3,0.1-0.6,0.2-0.9,0.2
			C620.2,387.3,615.5,388,610.8,387.7z"/>
                            <path className="st11" d="M675.1,387.3c-0.3-1.9,0-3.8-0.2-5.7c-0.2-1.8-0.5-3.6-0.7-5.5c0.4-0.1,0.7-0.2,1-0.2c3,0,5.9-0.4,8.9-0.8
			c0.8-0.1,1.7-0.3,2.6,0c1.1,4,0.8,8.1,1.9,12.2c-0.4,0.1-0.6,0.3-0.9,0.3c-3.9,0-7.9-0.1-11.8-0.1
			C675.6,387.5,675.4,387.4,675.1,387.3z"/>
                            <path className="st11" d="M679,366c0.6,2.7,0.1,5.4,0.8,8.1c-0.3,0.1-0.6,0.3-0.9,0.3c-4.1,0.2-8.1,0.3-12.3,0.3
			c-0.1-0.4-0.1-0.7-0.2-1.1c0-0.6,0-1.2,0-1.8c0-1.8-0.5-3.4-1.7-4.7c-0.1-0.1-0.1-0.3-0.2-0.4c0.2-0.4,0.6-0.3,0.8-0.3
			c2.7,0,5.5-0.1,8.2-0.4c1.3-0.1,2.7-0.1,4-0.1C678,365.8,678.4,365.9,679,366z"/>
                            <path className="st15" d="M760.8,400.6c0-0.3-0.1-0.5-0.1-0.6c0.1-3.8,0.1-7.6,0.2-11.4c0-0.5,0-1,0.1-1.5c0.1-0.6,0.3-1.2,1-1.7
			c1.3-0.1,2.9-0.6,4.3,0c0.7,0.7,0.9,1.4,0.9,2.1c0.1,2.8,0.1,5.5,0.1,8.3c0,1.2,0,2.3-0.1,3.5c0,0.4-0.1,0.8-0.1,1.2
			C765.7,400.8,762.6,400.9,760.8,400.6z"/>
                            <path className="st13" d="M778.8,385c1.1,0.7,1.3,1.7,1.5,2.7c0.3,1.4,0.5,2.9,0.6,4.4c0.2,2.7,0.2,5.4,0.3,8.1c-1,0.6-2,0.3-3,0.4
			c-1,0.1-2.1,0.1-3.1,0c-0.1-0.3-0.2-0.5-0.2-0.7c-0.1-3.6-0.2-7.2-0.2-10.9c0-0.7,0.1-1.5,0.3-2.2c0.4-1.3,1.1-1.7,2.4-1.8
			C777.9,385,778.3,385,778.8,385z"/>
                            <path className="st21" d="M743.6,371.3c-1.5,0.4-2.9,0.3-4.4,0.2c-0.3-0.8,0-1.4,0.2-2c1.1-3.3,1.7-6.8,2.4-10.2c0.2-1,0.2-2,0.7-3
			c0.5-0.9,0.6-4.6,0.5-5.9c-0.2-0.1-0.4-0.3-0.7-0.5c-0.5,0.4-1,0.7-1.6,1.1c0.1-1.5,0.3-2.8,0.6-4c0.2-0.8,0.5-1.7,0.7-2.5
			c0.1-0.3,0.4-0.6,0.7-1c0.3,0.2,0.6,0.3,0.7,0.5c0.3,0.9,0.6,1.8,0.7,2.7c0.3,1.8,0.6,3.5,0.6,5.3c0.2,4.7,0.5,9.4-0.4,14.1
			c-0.2,1.3-0.3,2.7-0.5,4C743.7,370.6,743.6,370.9,743.6,371.3z"/>
                            <path className="st15" d="M786.4,386.2c0.7-1.2,1.6-1.6,2.6-1.7c0.9-0.1,1.7,0.3,2.1,1.1c0.3,0.5,0.5,1.1,0.5,1.7
			c0.3,2.5,0.4,5,0.7,7.5c0.1,1.3,0.3,2.6,0.4,3.8c0.1,0.5,0.1,0.9,0.2,1.5c-0.9,0.5-1.8,0.3-2.7,0.3c-0.9,0-1.8,0-2.8,0
			c-0.4-1.6-0.3-3.2-0.5-4.8c-0.2-1.6-0.3-3.2-0.4-4.8C786.6,389.2,786.5,387.6,786.4,386.2z"/>
                            <path className="st11" d="M749.2,339.3c-2.8-1.6-5.8-1.1-8.6-1.1c-0.6-1.6-0.6-4.1,0.1-5.7c1-2.3,2.3-4.4,4.3-6.3
			c0.1,0.8,0.1,1.3,0.1,1.8c0.6,1,1.3,0.2,1.9,0.2c0.4,1.1-0.1,2.1-0.2,3.1c0.2,0.2,0.3,0.3,0.6,0.6c0.6-0.5,1.1-1,1.7-1.5
			c0.7,1.1,0.8,2.3,0.8,3.4C749.7,335.6,750,337.4,749.2,339.3z"/>
                            <path className="st11" d="M772.7,344.2c0.1,0.7,0.2,1,0.3,1.4c0.3,0.2,0.6,0.4,1,0.6c0.1,0.3,0.1,0.6,0.2,1c0.3,0.2,0.7,0.5,1.1,0.8
			c0,0.8-0.4,1.5-0.8,2.1c-0.8,1.2-1.6,2.5-2.5,3.6c-0.9,1.2-1.9,2.2-3.3,3c-0.4-0.8-0.8-1.6-1.3-2.3c-0.5-0.7-1.1-1.3-1.8-1.8
			c-0.7-0.5-1.4-0.9-2.2-1.5c0.2-0.3,0.3-0.7,0.5-0.9c0.7-0.7,1.4-1.3,2.2-1.9c0.7-0.5,1.5-0.9,2.2-1.2
			C769.9,346.3,771.4,345.6,772.7,344.2z"/>
                            <path className="st13" d="M618.2,403.1c-1.2,0.3-2.8,0.4-4.8,0.2c-0.6-2.2-0.4-4.6-0.7-6.9c-0.2-2.3-0.6-4.6-0.5-7
			c1.4-0.4,2.8-0.2,4.1-0.2C616.9,390.9,618.3,401.1,618.2,403.1z"/>
                            <path className="st13" d="M754.3,370.9c-1.4,0.4-2.7,0.4-4.2,0.4c0-0.4,0-0.7,0.1-0.9c0.4-0.7,0.7-1.4,1.2-2c1.8-2.3,3.6-4.6,5.8-6.6
			c1-0.9,2-2,3-3c0.7-0.7,1.5-1.3,2.2-1.9c0.1-0.1,0.3-0.1,0.6-0.2c0,0.3,0.1,0.6,0.1,0.9c-0.1,1.2-0.5,2.3-1,3.3
			c-1.5,2.8-3.2,5.5-5.6,7.6C755.7,369.3,755,370.1,754.3,370.9z"/>
                            <path className="st13" d="M686.7,402c-1.4,0.2-2.7,0.2-3.9,0.1c-0.5-1.4-1.4-10.6-1.2-13c1.3-0.4,2.7-0.1,4.2-0.1
			c0.4,1.4,0.2,2.8,0.3,4.2c0.2,1.5,0.3,2.9,0.4,4.4C686.6,399,686.8,400.4,686.7,402z"/>
                            <path className="st13" d="M734.2,371.6c0.9-4.8,2.5-9,3.9-13.4c0.2-0.5,0.4-1,0.7-1.5c0.5,0.1,0.8,0.1,1.2,0.3
			c0.2,0.1,0.4,0.2,0.6,0.3c0.1,2.3-1.9,11.5-3.1,14.4C736.5,371.6,735.5,371.6,734.2,371.6z"/>
                            <path className="st14" d="M752.3,358.4c-1.1,4.4-2,8.7-3.7,12.8c-1.2,0.3-2.3,0.2-3.4,0.1c-0.1-0.1-0.1-0.2-0.1-0.2
			c0-0.1-0.1-0.1,0-0.2c1.8-4.3,3-8.8,4.6-13.2c0-0.1,0.2-0.2,0.3-0.3C750.7,357.6,751.4,357.9,752.3,358.4z"/>
                            <path className="st11" d="M729.2,341.7c1-0.1,2-0.2,2.9-0.1c1,0.1,1.9,0.4,2.9,0.6c0.1,1.5,0.2,3,0.4,4.6c0,0.2-0.1,0.5-0.1,0.7
			c-1.2,0.3-3.9,0.4-6.1,0.2c-0.4-1,0-2,0-3C729.1,343.6,729.2,342.6,729.2,341.7z"/>
                            <path className="st11" d="M716.4,348.3c-0.9,1.9-0.1,3.6,0.5,5.4c-0.5,0.2-0.5-0.2-0.6-0.4c-0.8-1.5-1.2-3.2-1.4-4.9
			c-0.1-0.9,0.1-1.7,0.4-2.5c0.3-0.7,0.5-1.3,0.8-2c2.7,1.8,4.4,4.1,5,6.7c-0.7-0.2-0.6-1-1.3-1.2c0,1.6,1.4,2.8,1,4.7
			c-1.2-1.1-1.5-2.6-2.3-3.7c-0.5,0.1-0.4,0.3-0.4,0.6c0.2,1.1,0.7,2.1,1.3,3c0.3,0.5,0.7,1,0.6,1.8c-1.4-0.9-2-2.2-2.4-3.5
			C717,351.1,716.8,349.7,716.4,348.3z"/>
                            <path className="st32" d="M729,371.5c-0.6,0-1.1,0.1-1.5,0.1c-0.9-0.5-1.3-1.3-1.6-2.1c-0.5-1.1-0.9-2.1-1.3-3.2
			c-1-2.8-1.8-5.7-2.5-8.6c0.4-0.3,0.6,0,0.8,0.2c1,1,1.8,2,2.3,3.3c1.1,2.6,2.2,5.2,3.3,7.8C728.8,369.7,729,370.5,729,371.5z"/>
                            <path className="st55" d="M732.2,371.6c-0.4,0-0.6,0-0.8-0.1c-0.1-0.2-0.2-0.3-0.2-0.4c0-1.2-0.1-2.4-0.1-3.7
			c-0.1-3.6-0.1-7.2-0.2-10.9c0-0.9,0-1.8,0.7-2.8c0.8,2.5,1.4,4.9,1.3,7.3c-0.1,3.1-0.3,6.1-0.4,9.2
			C732.4,370.7,732.3,371.1,732.2,371.6z"/>
                            <path className="st11" d="M735.4,349c0,0.5,0.1,0.8,0.1,1.1c0,0.4,0,0.7-0.1,1.1c-0.7,0.7-1.6,0.9-2.5,1c-0.4-0.2-0.8-0.4-1.2-0.6
			c-0.8,0.1-1,0.7-1.5,1.2c-0.5,0-0.9,0-1.5,0c-0.1-1.5,0.2-2.8,0.3-4.2C731.2,348.7,733.2,348.8,735.4,349z"/>
                            <path className="st11" d="M744.3,340.6c0.6,0.6,1.1,0.9,1.9,0.7c0.8-0.1,0.9-0.9,1.4-1.4c0.3,0.2,0.6,0.4,0.9,0.5
			c-0.6,1.6-1.6,3-3.3,4.2c-0.5-0.7-0.6-1.7-1.5-2.2c-0.2-0.1-0.4-0.2-0.7-0.3c-0.5,0.2-1,0.3-1.6,0.6c-0.6-1-0.8-2.1-0.9-3.4
			c1.1-0.4,2.3-0.1,3.4-0.1C744.1,339.8,744.2,340.3,744.3,340.6z"/>
                            <path className="st11" d="M755.5,350c-0.2,0.7-0.4,1.4-0.6,2.1c0.2,0.4,0.4,0.9,0.6,1.3c-0.5,1.4-1.3,2.6-2.3,3.8
			c-1-0.4-1.9-0.8-2.9-1.2c0.3-1.3,1.1-2.3,1.8-3.4c0.5-0.2,1-0.4,1.7-0.6c0.4-0.6,0.9-1.4,1.4-2.1C755.3,349.9,755.4,350,755.5,350
			z"/>
                            <path className="st11" d="M766.9,357.1c-0.6,0.4-1.3,0.2-2,0.2c-0.4-0.9-0.6-1.8-1.3-2.6c-0.5-0.5-0.8-1.4-0.5-2.2
			C765.4,353.5,766.6,355.1,766.9,357.1z"/>
                            <path className="st11" d="M741.5,356c-0.9-0.2-1.6-0.4-2.3-0.6c0.4-1.1,1.3-2.2,2.7-3.2C742,353.4,741.7,354.6,741.5,356z"/>
                            <path className="st15" d="M787.9,381.3c0.7,0,1.2,0,1.6,0c0.5,0.6,0.5,1.1,0.3,1.8c-0.6,0.1-1.1,0.2-1.7,0.3
			C787.5,382.7,787.9,382.1,787.9,381.3z"/>
                            <path className="st15" d="M762.6,384.1c0.1-0.8,0.1-1.3,0.2-1.8c0.7-0.4,1.4-0.2,2-0.1c0.4,0.5,0.3,1.1,0.1,1.6
			C764.2,383.9,763.6,384,762.6,384.1z"/>
                            <path className="st13" d="M776.3,383.9c0-0.7,0-1.2,0-1.8c0.7-0.3,1.4-0.2,2.2-0.1c0,0.6,0.1,1.1,0.1,1.7
			C777.8,383.8,777.2,383.8,776.3,383.9z"/>
                            <path className="st11" d="M746.5,340.1c-0.4,0.7-0.8,0.6-1.4,0.4c-0.1-0.2-0.2-0.5-0.2-0.7c0.5-0.5,0.9-0.4,1.4-0.3
			C746.3,339.8,746.4,339.9,746.5,340.1z"/>
                            <path d="M789.5,407c0.8,0.5,2,0,2.5,1.2c0,1-0.9,0.9-1.5,1.3c-0.2,0.6,0,1.3,0.2,1.9c0.8,2.7,1,5.4,1,8.2c0,9,0,17.9,0.1,26.9
			c0,2.6,0.2,5.3,0.3,7.9c0.1,2.2,0.1,4.4,0.2,6.6c0,0.7,0,1.4-0.8,1.9c-0.3,0-0.7,0.1-1.1,0c-1.2-0.2-2.3,0-3.4,0.3
			c-0.4,0.1-0.8,0.2-1.2,0.2c-1.7,0-3.3,0.2-4.9,0.3c-1.5,0.1-3,0-4.6,0c-1.7,0-3.4,0-5.1,0c-1.6,0-3.2,0.1-4.7,0.1
			c-7.2,0.2-14.5,0.3-21.7,0.4c-14.7,0.2-29.5,0.5-44.2,0c-7-0.2-14,0.1-21,0.5c-5.2,0.3-10.5,0.6-15.7,0.9c-5,0.3-10,0.7-15,0.9
			c-3.8,0.2-7.5,0.4-11.3,0.5c-5.5,0.2-11.1,0.4-16.6,0.6c-3,0.1-6,0.3-8.9,0.6c-4,0.3-8,0.3-12.1,0.3c-1.2,0-2.4,0-3.9,0
			c-0.1-0.1-0.4-0.4-0.6-0.7c-0.1-2.7-0.2-5.2-0.3-7.8c-0.1-2.5-0.1-5-0.2-7.5c-0.1-2.5-0.3-4.9-0.4-7.4c-0.1-1.5-0.2-3.1-0.2-4.6
			c0-2.7-0.1-5.4,0-8.1c0-5.5-0.5-11-0.9-16.5c0-0.2-0.1-0.4-0.1-0.6c-0.3-0.1-0.6-0.3-1.1-0.5c0-0.4,0-0.9-0.1-1.4
			c0.6-0.3,1.2-0.5,1.4-0.7c1,0,1.6,0,2.3,0c6,0,11.9,0,17.9-0.1c4.1,0,8.2,0,12.2-0.2c4.7-0.3,9.3-0.8,14-1.2
			c5.2-0.5,10.3-1,15.5-1c8.8,0.1,17.5-0.2,26.3-0.6c6.8-0.3,13.6-0.5,20.5-0.7c2.9-0.1,5.8,0,8.8,0c11.4,0.3,22.8,0.1,34.2-0.2
			c8.3-0.3,16.6-0.7,24.8-1.1c2.9-0.1,5.7-0.3,8.6-0.4c1.3,0,2.7,0.2,4,0.2C784.8,407.8,787.1,408.1,789.5,407z M686.9,456.5
			c-0.1-1.4,0.6-2.5,1.2-3.6c0.2-0.4,0.3-0.8,0.5-1.2c1.4-2.7,2.6-5.4,4.1-8c1.8-3.1,3.6-6.1,5.2-9.3c0.4-0.7,0.8-1.4,1.2-2.1
			c1.1-2,2.2-3.9,3.2-6c1.5-3,3.5-5.8,5.1-8.6c0.8-0.3,1.4-0.6,2.1-0.9c0.3-1.6,0.1-3.2,0.6-4.6c0.6-0.6,1.3-0.9,2.1-1
			c2.6,0.9,5,2,7.5,1.9c1.1,0.7,1.9,1.2,2.5,1.5c1.6,0.3,2.9,0.1,4.1-0.2c0.7-0.2,1.3-0.3,1.9,0.2c0.6,0.6,0.6,1.3,0.6,2
			c0,1.4,0.1,2.7,0,4.1c-0.2,3.6-0.2,7.2,0.1,10.9c0,0.4,0.1,0.8,0.2,1.3c1.9,0.5,3.6,0.3,5-0.6c1,0.1,1.6,1.1,2.6,1.2
			c0.2-1,0.2-1.8,0.1-2.7c-0.1-1.3-0.3-2.6-0.3-3.9c-0.1-1.2-0.1-2.5-0.1-3.7c0-0.5,0.1-1,0.6-1.2c0.9-0.5,1.8,0.5,2.7,0
			c0.1-0.3,0.3-0.7,0.4-1.1c0.8-0.3,1.4,0.1,2,0.3c0.3,0.1,0.7,0.1,1,0.2c0-0.4,0.1-0.7,0.1-1c-0.1-1.9-0.2-3.8-0.3-5.7
			c0-0.6-0.3-1.2,0.2-1.7c2.4,0.1,4.7-0.9,7.2-0.5c0.9,0.6,1.9,1.1,3.1,1.6c0-1.3,0.2-2.4-0.2-3.6c-0.8,0-1.5,0-2.2,0
			c-4.7,0.1-9.4,0.4-14.1,0.4c-9.1,0-18.3,0.3-27.4,0c-7.1-0.2-14.1,0.2-21.2,0.4c-4.9,0.2-9.9,0.4-14.8,0.6
			c-5.9,0.1-11.8,0.2-17.7,0.3c-5.9,0-11.7,0.5-17.5,1.1c-3.9,0.4-7.9,0.7-11.8,0.9c-4.2,0.2-8.4,0.2-12.6,0.3
			c-0.6,0-1.2-0.2-1.7,0.2c0,0.2-0.1,0.3,0,0.4c0.5,2.5,1,4.9,1.2,7.4c0,0.2,0.2,0.4,0.4,0.7c1.4-1.1,2.9-1.4,4.5-1.4
			c0.3,0,0.7-0.1,1-0.2c0.2-0.7,0.3-1.3,0.5-1.7c0.7-0.7,1.5-1,2.4-1.2c1.4-0.3,2.7-0.6,4.1-1.1c1.9-0.6,3.7-1,5.7-0.7
			c0.2,0.2,0.4,0.4,0.6,0.6c0.9,0.2,1.7,0.3,2.5,0.5c0.9-0.5,1.8-1,2.7-1.4c0.9-0.4,1.6-1.3,2.8-1.3c0.1,0.2,0.3,0.4,0.3,0.6
			c0.2,1.2,0.3,2.4,0.4,3.7c0.1,2.9,0.1,5.8,0.2,8.7c0,0.5-0.1,1.1,0.5,1.6c1.8-0.9,2.9-2.6,4.6-3.7c1.1,0.1,2.2,0.3,3.5,0.5
			c0.3-0.1,0.8-0.2,1.1-0.3c0.8,0.2,1.4,0.5,2.1,0.6c1,0.1,2.1,0,3.1-0.1c0.3,0,0.7,0,1,0c0.6,1.7,0.9,3.4,0.9,5.1
			c0,2,0,4.1,0.1,6.1c0.3,4.9,0.6,9.8,1,14.7c0.1,1.4,0.1,2.7-0.4,3.9c0,0,0,0.1,0.1,0.2c0.4,0.3,0.9,0.3,1.3-0.2c0-0.3,0-0.7,0-1
			c-0.1-1.8-0.2-3.6-0.2-5.3c-0.1-5.6-0.4-11.2-1-16.7c-0.2-1.5-0.3-3.1-0.2-4.4c0.4-0.7,0.8-1.2,1.1-1.7c0.3-0.5,0.3-1.2,1-1.6
			c1.5,0.2,3-0.6,4.5-0.1c0.8,0.8,0.9,1.8,1,2.8c0.3,2.6,0.7,5.2,0.8,7.9c0.2,3.9,0.2,7.7,0.3,11.6c0,1.8,0,3.7,0,5.5
			c0,1.1,0,2.2,0.5,3.2c0.6,0,1.3,0.2,1.8-0.2c0-0.1,0.1-0.3,0.1-0.4c-0.3-2.5-0.6-5-0.8-7.5c-0.3-3.1-0.4-6.1-0.3-9.2
			c0.2-6.6,0.1-13.3-0.4-19.9c-0.1-1.2-0.3-2.4-0.5-3.6c-0.1-0.7,0-1.3,0.5-1.8c2.8-0.7,4.1-0.7,5.3-0.1c0.1,1,0.3,2,0.4,2.9
			c0.2,1.2,0.4,2.3,0.5,3.5c0.2,2,0.4,3.9,0.5,5.9c0.1,1.7,0.2,3.4,0.3,5.1c0.1,3.3,0.1,6.5,0.2,9.8c0.1,3.4,0.1,6.8,0.3,10.1
			c0.1,1.7,0.3,3.4,0.7,5c0.6,0.3,1.1,0.4,1.6-0.1c0-0.6,0.1-1.2,0-1.8c-0.2-1.7-0.5-3.4-0.8-5.1c-0.4-2-0.8-4-0.9-6
			c-0.1-5.4-0.3-10.8-0.3-16.2c0-3.9-0.1-7.9-0.6-11.8c0-0.4,0-0.8,0.1-1.2c1.2-1,2.6-0.7,3.8-1.1c0.8,0.3,0.8,0.8,0.9,1.4
			c0.1,1,0.1,2.1,0.4,3.1c0.7,2.4,0.9,4.8,1,7.3c0.1,3,0.2,6,0.5,9c0.4,3.7,0.8,7.4,1,11.2c0.1,1.6,0.3,3.3,0.5,4.9
			c0.2,1.6,0.4,3.2,0.7,4.7c0.1,0.5,0.2,1.1,0.3,1.6C685.4,456.8,686.1,456.8,686.9,456.5z M619.3,464.6c-3.8,1.4-4.1,1.4-5.6,0.9
			c-0.4-0.5-0.4-1.1-0.4-1.7c-0.5-4.7-0.9-9.4-1.4-14.1c-0.1-1.5-0.3-2.9-0.4-4.4c-0.1-5.8-0.7-11.5-1.4-17.2
			c-0.1-0.7-0.3-1.4,0-2.3c0.6-0.3,1.2-0.7,1.7-1c0.2-2.5,0.1-3.3-0.4-4.7c-0.5,0.2-1.1,0.4-1.7,0.6c-0.6,0.2-1.1,0.5-1.6,0.7
			c-0.1,0.3-0.1,0.5-0.1,0.7c0,3.6,0,7.1,0.1,10.7c0,1.3,0.1,2.7,0.3,4c0.3,2.9,0.6,5.9,0.9,8.8c0.3,3.3,0.7,6.6,1,9.9
			c0.2,2.6,0.4,5.1,0.5,7.7c0,0.5,0.1,1.1-0.4,1.7c-1.2,0.4-2.6,0.6-4,0.4c-0.3-0.4-0.6-0.7-0.9-1.2c-1.5,0.8-2.9,1.5-4.4,2.3
			c-0.1,0-0.1,0.2-0.1,0.4c0.6,0,1.2,0.1,1.8,0c2.4-0.1,4.9-0.2,7.3-0.3c2.4-0.1,4.8-0.5,7.3-0.6c4-0.1,8-0.4,12-0.5
			c7.5-0.3,15.1-0.5,22.6-1c9.3-0.7,18.6-1.2,27.9-1.7c6.6-0.4,13.3-0.7,19.9-0.5c14.7,0.4,29.4,0.2,44,0c9.6-0.2,19.1-0.4,28.7-0.6
			c1.3,0,2.7,0,4,0.1c1.7,0.1,3.4,0.1,5.1-0.1c1.6-0.2,3.1-0.4,4.7-0.3c0.7,0,1.4,0,2.1-0.6c-0.3-0.2-0.6-0.5-0.8-0.6
			c-1.3-0.6-2.6-1.2-4-1.8c-2-0.7-4-1.4-6-2.1c-1.5-0.6-3-0.5-4.5-0.2c-0.1,0.6-0.3,1.2-0.4,1.9c-0.8,0.1-1.5,0.1-2.2,0.3
			c-1.3,0.5-2.5,0.3-3.7-0.1c-1.8,1-3.7,0.8-5.6,1.2c-1.1-0.5-2.2-1-3.3-1.5c-0.2,0.1-0.3,0.2-0.5,0.3c-1.4,0.8-3,1.1-4.6,0.6
			c-1.3-0.4-2.4-1.1-3.6-1.7c-0.1,0-0.2,0-0.3,0.1c0,0.5,0,1.1,0,1.6c-1.2,1-2.6,0.4-3.9,0.5c-1.4,0.1-2.5-0.6-3.8-1
			c-0.2,0.7-0.3,1.3-0.5,1.8c-1.9,0.7-2.2,0.7-7.5,0.2c-0.8-0.4-1.6-0.8-2.4-1.1c-0.3,0.3-0.6,0.6-0.8,0.8c-1.8,0.4-3.9,0.5-5.5,0.1
			c-0.4-1.1-1.2-1.8-2.4-2.3c-0.4,0.6-0.9,1.1-1.6,1.2c-2,0.2-4,0.6-5.9,0.3c-0.6-0.6-1.2-1.2-1.8-1.7c-3.7,0.1-7.3,0.2-10.9,0.4
			c-0.6,1-1.1,1.8-1.7,2.6c-0.7,0.9-1.7,1-2.5,0.2c-0.7-0.7-1.4-1.5-1.8-2.5c-1-0.1-1.8-0.2-2.7,0.1c-0.9,0.8-1.1,2-1.9,2.4
			c-1.4,0-2.5-0.1-3.5-0.1c-0.3,0-0.7-0.2-1-0.4c0-0.5-0.1-0.9-0.1-1.3c0-0.2-0.1-0.3-0.2-0.5c-0.5,0-1,0-1.5,0
			c-0.3,0.4-0.5,0.9-0.8,1.3c-1.1,0.2-2.3,0.4-3.4,0.5c-0.5,0.1-1,0-1.5,0c-0.2,0-0.3-0.2-0.5-0.2c-0.2-0.4-0.4-0.9-0.6-1.4
			c-0.4,0-0.8,0-1.3,0c-0.1,0.6-0.2,1.1-0.3,1.7c-1.5,1-3.2,0.5-4.8,0.9c-0.4,0.1-0.8-0.2-1.2-0.2c-0.2-0.7-0.3-1.4-0.5-2
			c-0.7-0.3-1.3-0.2-2-0.1c-0.5,1.1-1,2.1-1.5,3.2c-1.9,0.3-3.7,0.6-5.3,0.4c-1-1-0.5-2.3-1.1-3.3c-0.8,0-1.6,0-2.3,0
			c-0.3,0.6-0.6,1.1-0.8,1.6c-1.5,0.7-4.8,1.5-6.2,1.5c-0.8-0.4-0.7-1.2-1.1-2c-0.7,0.7-1.2,1.2-1.8,1.8c-1.6,0.8-3.2,1.4-5.1,0.5
			c-0.2-0.4-0.5-0.9-0.8-1.4c-0.7,0.7-1.3,1.4-1.9,2c-1.7,0.2-3.4,0.4-5.1,0.6c-0.5,0.1-1,0-1.4-0.5c-0.1-0.7-0.3-1.5-0.5-2.5
			c-1.2,0.6-1.9,1.7-3,2.3c-1.8,0.3-3.7,0.2-5.6,0.1c-0.3,0-0.7-0.2-1.1-0.3c-0.1-0.5-0.2-1.1-0.4-1.7
			C621.9,462.2,620.7,463.3,619.3,464.6z M777,409.7c0.1,1,0.2,1.8,0.3,2.6c0.4,3.2,0.6,6.3,0.5,9.5c-0.1,1.2-0.2,2.4-0.3,3.7
			c0,3.4,0,6.7,0.1,10.1c0,1,0,2.1,0,3.1c0,4.6-0.1,9.2-0.2,13.8c0,0.6,0,1.2,0.1,1.9c0.4,0.2,0.8,0.4,1.2,0.5c1.5,0.5,3,1,4.5,1.5
			c2.2,0.7,4.2,1.5,6.1,2.8c0.2,0.2,0.5,0.3,0.8,0.4c0.4-0.7,0.2-1.4,0.2-2.1c-0.1-3.2-0.3-6.5-0.4-9.7c-0.1-5.6,0-11.3,0-16.9
			c0-4,0-8.1,0-12.1c0-3.1-0.6-6.1-1.2-9c-0.2-0.1-0.3-0.1-0.4-0.1c-1.7,0.4-3.5,0.3-5.3,0.2c-1.6-0.1-3.2-0.2-4.7-0.3
			C778,409.5,777.6,409.6,777,409.7z M595,414.7c-0.1,0.2-0.2,0.3-0.2,0.4c0,0.6,0.1,1.1,0.1,1.6c0.8,6.3,1.2,12.6,1,18.9
			c-0.1,3.1-0.1,6.3,0.2,9.4c0.1,1.4,0.2,2.8,0.3,4.2c0.2,5.4,0.3,10.8,0.5,16.2c0,0.3-0.1,0.6,0.3,0.8c1.4-0.8,2.8-1.6,4.2-2.5
			c1.4-0.8,2.9-1.5,4.2-2.4c0.2-1.2-0.1-2.3-0.2-3.4c-0.3-2.9-0.6-5.7-1-8.6c-0.3-2.7-0.6-5.5-0.9-8.2c-0.5-4-0.9-7.9-0.8-11.9
			c0-2.7-0.1-5.4-0.1-8.1c0-0.2,0.1-0.5,0.1-0.5c1.4-0.7,2.6-1.2,3.7-1.8c1-0.6,2.1-0.9,3.3-1c0.5,0,0.9-0.1,1.5-0.1
			c-0.1-0.7-0.2-1.3-0.3-1.9c-0.1-0.3-0.2-0.6-0.3-0.8c-1.5-0.3-8.7-0.5-14.6-0.4C595.7,414.5,595.3,414.6,595,414.7z M771.2,414.1
			c0.1,0.3,0.2,0.6,0.2,1c0.2,2.6,0.4,5.3,0.5,7.9c0.1,1.2,0,2.3,0.1,3.5c0.4,5.1,0.3,10.3,0.4,15.4c0,3.7,0.2,7.4,0.3,11
			c0,0.4,0.2,0.8,0.2,1.2c1.2,0.1,2.2,0.3,3.3-0.2c0-0.4,0.1-0.7,0.1-1c0.1-4.7,0.2-9.4,0.2-14.2c0-1.2,0-2.5-0.1-3.7
			c-0.2-3.5-0.4-7-0.2-10.5c0.3-4.6,0.2-9.2-0.3-13.8c0-0.4-0.1-0.7-0.1-1c-1.5-0.3-4-0.2-7.7,0.3c-0.1,0-0.1,0.2-0.3,0.4
			C769,411.5,770.4,412.5,771.2,414.1z M714.2,456.3c0-0.8,0-1.4,0-2c0.1-3.9,0.1-7.9,0.3-11.8c0.1-2.6,0.3-5.1,0.6-7.7
			c0.3-3.6,0.7-7.2,0.7-10.8c-0.1-3.3-0.2-6.5-0.2-9.8c0-0.1-0.1-0.2-0.2-0.4c-0.9-0.7-2-0.9-3-1.2c-0.5-0.1-1-0.1-1.4,0.5
			c-0.1,0.9-0.2,1.9-0.3,2.8c0,0.1,0.1,0.1,0.2,0.2c0.4-0.3,0.7-0.6,1-0.9c1.1,0.8,2,1.5,3.1,2.3c-1.2,3.3-2.9,6.3-4.6,9.1
			c-0.3,2.6-0.4,4.9-0.3,7c0.6,1.4,1.8,2.1,2.2,3.3c-0.8,1.4-1.5,2.8-2.2,4.1c0.6,1,0.6,2,0.6,3c0.1,3.6,0.2,7.1,0.3,10.6
			C712.3,455.8,712.6,456,714.2,456.3z M760.2,457.6c0-0.8,0-1.3,0-1.9c-0.1-3.7-0.3-7.5-0.2-11.2c0-6.3-0.4-12.6-1-18.9
			c-0.4-3.5-0.7-7-0.6-10.5c0-0.4,0-0.8,0-1.2c-1-0.8-1.9-1.5-2.8-2.2c-0.3-0.2-0.6-0.3-1-0.4c-0.2,1.5-0.1,2.9,0.4,4.1
			c0.4,1.1,0.5,2.2,0.6,3.4c0.4,4.8,0.3,9.7,0.3,14.5c-0.1,6.5,0.4,13,1.2,19.5c0.1,1.1,0.3,2.3,0.5,3.3
			C758.3,456.8,759.1,457.3,760.2,457.6z M616.2,425.9c0.2,1.3,0.5,2.4,0.6,3.5c0.4,3.9,0.9,7.8,1.2,11.7c0.6,6.5,1,13,1.6,19.4
			c0.1,0.7,0.2,1.3,0.3,2.1c1.5-1,2.6-2.1,3.5-3.3c0-0.4-0.1-0.7-0.1-1.1c-0.5-5.1-1.1-10.1-1.5-15.2c-0.4-5.3-0.8-10.6-1.6-15.9
			c-0.2-1-0.2-2-0.5-3c-0.2,0-0.4,0-0.5,0C618.3,424.7,617.3,425.3,616.2,425.9z M631.1,461c1.6-1.1,2.2-2.2,2.1-3.9
			c-0.2-4.8-0.4-9.5-1-14.3c-0.6-4.9-0.9-9.9-1.8-14.8c-0.3-1.7-0.5-3.4-0.5-5.1c0-0.9-0.2-1.8-0.4-2.7c0-0.2-0.3-0.4-0.4-0.6
			c-0.9,0.5-2,0.3-2.8,1c0.1,1,0.3,2,0.4,3.1c0.7,3.8,1.2,7.6,1.4,11.5c0,0.6,0.1,1.1,0.1,1.7c0.3,3.1,0.6,6.1,0.8,9.2
			c0.2,2.6,0.5,5.1,1,7.6c0.4,1.9,0.5,3.9,0.8,5.8C630.9,459.9,631,460.3,631.1,461z M640.2,417.1c-1.4,0.4-4.2,1.8-5,2.5
			c1.1,2.6,1.5,5.4,1.8,8.2c0.3,3.4,0.6,6.8,0.9,10.3c0.2,2.3,0.5,4.6,0.8,6.9c0.3,2.5,0.7,5,0.7,7.5c0,2.5,0.3,4.9,0.7,7.3
			c0.1,0.4,0,0.8,0.6,1.1c0.5-0.7,1-1.3,1.6-2c-0.1-1.6-0.3-3.2-0.4-4.9c-0.4-4-0.7-8.1-1.4-12.1c-0.5-3-0.6-6.1-0.4-9.1
			c0.4-0.7,0.7-1.3,0.9-1.8c-0.1-1.1-0.2-2-0.3-2.9c-0.1-0.9-0.2-1.8-0.2-2.8c0-0.9,0-1.8,0-2.8c0-0.9,0-1.8,0-2.8
			C640.3,418.9,640.4,418,640.2,417.1z M744.2,414.8c0.3,2.8,0.5,5.2,0.2,7.8c0.8,0.4,1.6,0.7,2.5,1.1c0.1,0.4,0.3,0.8,0.3,1.2
			c0.1,0.9,0.2,1.7,0.2,2.6c0.1,5.5,0.3,10.9,0.4,16.4c0.1,3.1,0.3,6.2,0.5,9.4c0,0.7,0.2,1.3,0.3,1.9c1,0.6,1.5,1.5,2.8,1.6
			c0-0.3,0-0.6-0.1-0.8c-0.2-1.6-0.5-3.3-0.6-4.9c-0.2-4-0.2-8-0.3-12c-0.1-2.5-0.2-5-0.3-7.5c-0.3-4.3-0.7-8.6-1.1-12.8
			c0-0.2-0.1-0.5-0.2-0.8C747.4,416.9,746,416,744.2,414.8z M768.7,457.6c0-0.6,0-0.9,0-1.2c-0.2-1.6-0.4-3.2-0.6-4.7
			c-0.3-2.6-0.8-5.2-0.9-7.9c-0.2-3.5-0.3-7-0.4-10.5c-0.2-3.6-0.4-7.2-0.6-10.9c-0.1-2.5-0.3-5-0.4-7.5c0-0.2-0.1-0.4-0.1-0.5
			c-1.3-1.4-2.7-2.8-4-4.2c-0.3,0.1-0.6,0.1-1.1,0.2c0.7,0.9,1.2,1.7,1.6,2.3c0.2,1.9,0.4,3.7,0.7,5.3c0.6,3.3,0.9,6.7,1.1,10.1
			c0.3,3.8,0.6,7.6,0.9,11.4c0.3,3.1,0.6,6.1,0.9,9.2c0.2,2.3,0.4,4.5,0.7,6.8c0.1,0.5,0.2,1.1,0.3,1.6
			C767.4,457.3,767.9,457.4,768.7,457.6z M737.7,424.1c-0.1,1.1,0,2.2,0.1,3.3c0.3,2.1,0.5,4.3,0.3,6.4c0,0.2,0,0.4,0.1,0.5
			c0.3,0.3,0.6,0.6,0.9,1.1c0.2,1.8,0.5,3.6,0.7,5.5c0.3,3.2,0.5,6.5,0.8,9.7c0.2,2.1,0.5,4.1,0.7,6.1c0.6,0.5,1,1,1.9,0.9
			c-0.3-5.3,0.1-10.7-0.4-16c-0.5-5.4-1.4-10.7-2-15.9C739.2,424.3,739.1,424.2,737.7,424.1z M628.4,453.5c-0.2-1.6-0.3-3.3-0.5-4.9
			c-0.1-1.6-0.2-3.2-0.4-4.8c-0.1-1.7-0.2-3.3-0.5-4.9c-0.2-1.6-0.1-3.3-0.6-4.9c-1.4,0-2.8-0.3-4.2,0.2c0,0.3,0,0.6,0,0.8
			c0.5,6.1,1.1,12.1,1.6,18.2c0,0.3,0.2,0.5,0.3,0.8C625.6,454,627,454.1,628.4,453.5z M755.4,450.1c0-0.7,0-1.2-0.1-1.6
			c-0.4-3.4-0.6-6.8-0.6-10.3c0-3.6,0-7.2-0.1-10.9c0-0.6-0.1-1.2-0.1-1.7c-1.5-0.5-2.2-0.5-3.7-0.3c-0.2,0.6,0,1.2,0,1.8
			c0.5,4.3,0.8,8.6,0.8,12.9c0,3,0.1,6,0.1,9c0,0.5-0.1,1,0.4,1.4c0.2,0,0.5,0.1,0.8,0.1C753.8,450.4,754.5,450.2,755.4,450.1z
			 M681.8,446.8c0-0.4,0.1-0.6,0.1-0.8c-0.2-3.1-0.4-6.1-0.7-9.2c-0.4-3.7-0.7-7.3-0.8-11c0-0.3-0.1-0.7-0.2-1
			c-0.9-0.1-1.8-0.1-2.6,0.1c-0.4,0.7-0.2,1.4-0.2,2c0.1,5.5,0.2,10.9,0.3,16.4c0,0.9,0.1,1.7,0.2,2.6c0,0.4,0.2,0.8,0.3,1.3
			C679.4,447.2,680.6,447,681.8,446.8z M724.1,457.7c0-0.6-0.1-1-0.1-1.3c-0.1-1.7-0.2-3.4-0.3-5.1c-0.1-1.6-0.1-3.2-0.2-4.8
			c-0.5-6.5-0.6-13-0.6-19.5c0-3.3-0.1-6.5-0.2-9.8c0-0.3-0.1-0.6-0.1-1c-0.4-0.2-0.8-0.5-1.3-0.8c0,1.3,0.1,2.5,0.1,3.6
			c-0.1,6.9,0,13.8-0.5,20.6c-0.1,1.5,0,3.1,0,4.6c0,1.6,0.1,3.2,0.2,4.8c0.1,1.8,0.2,3.7,0.3,5.5c0,0.6,0.2,1.2,0.3,1.9
			C722.6,456.7,723,457.4,724.1,457.7z M709.7,418.9c-0.2-0.3-0.4-0.5-0.5-0.7c-0.4,0.2-0.8,0.3-1,0.4c-2,3.3-4.2,6.4-5.8,9.9
			c-0.6,1.3-1.4,2.6-2.1,3.9c-0.8,1.4-1.5,2.8-2.3,4.2c-1.1,2-2.2,3.9-3.3,5.9c-0.7,1.2-1.4,2.3-2,3.5c-1.5,3.1-3,6.1-4.6,9.4
			c0.1,0.2-0.2,0.7,0.4,1c0.1-0.2,0.3-0.3,0.4-0.5c0.9-1.4,1.9-2.9,2.8-4.3c2.8-4.9,5.7-9.9,8.4-14.8c3-5.4,6-10.8,9-16.2
			C709.3,420,709.5,419.4,709.7,418.9z M674.2,448.5c0-0.4,0.1-0.6,0.1-0.8c-0.1-2.6-0.2-5.3-0.5-7.9c-0.3-3.4-0.7-6.7-1.1-10
			c0-0.3-0.1-0.6-0.2-0.9c-0.8,0.2-1.5,0.3-2.3,0.4c0,0.7,0,1.3,0,1.9c0,2.9,0,5.9,0,8.8c0,2.6,0,5.1,0,7.7c0,0.4,0.2,0.8,0.3,1.2
			C671.7,448.8,672.9,448.7,674.2,448.5z M653.7,431.4c0.3,1.2,0.6,2.1,0.8,3.1c0.7,4.1,1.2,8.1,1.2,12.3c0,3.4,0.6,6.7,1.5,10
			c0.1,0.2,0.2,0.4,0.3,0.7c0.6-0.8,0.9-1.5,1.2-2.2c-0.1-1.5-0.2-2.9-0.3-4.4c-0.2-2.6-0.3-5.3-0.6-7.9c-0.3-3.5-0.7-7.1-0.6-10.7
			c0-0.9-0.1-1.8-0.2-2.7c0-0.3,0-0.6-0.5-0.7C655.5,429.6,654.6,430.5,653.7,431.4z M617.6,453c-0.1-0.9-0.1-1.9-0.2-2.8
			c-0.3-3.3-0.5-6.6-0.8-9.9c0-0.6,0-1.2-0.5-1.7c-2.4-0.4-3.5,0.5-3.6,2.9c0,3.8,0.3,7.6,0.9,11.3c0.7,0.7,1.4,1.3,2.5,1.5
			C616.6,454,617.2,453.7,617.6,453z M760.2,425.1c0.1,1.1,0.1,1.9,0.2,2.6c0.6,5,1,10,0.9,15c0,2.1,0,4.2,0,6.3
			c0,0.5,0.1,0.9,0.1,1.4c1.5,0.6,1.7,0.6,3.1,0.2c0-0.2,0.1-0.5,0.1-0.7c-0.9-8-1.3-16-2-24c0-0.2-0.1-0.3-0.2-0.6
			C761.7,425.3,761.1,425.2,760.2,425.1z M645.3,432.3c0.1,1.2,0.2,2.2,0.3,3.2c0.1,1.2,0.2,2.3,0.4,3.5c0.8,4.6,1,9.2,1.4,13.9
			c0.2,2,0.5,4,0.7,6c0,0.3,0.2,0.5,0.3,0.9c0.8-0.4,1-1.1,1.5-1.7c0-2.9,0.1-5.7-0.3-8.6c-0.3-2.5-0.7-5-1-7.5
			c-0.4-2.8-0.8-5.6-1.3-8.3c-0.1-0.5-0.3-1.1-0.4-1.6c0-0.1-0.2-0.1-0.4-0.3C646.1,431.9,645.7,432.1,645.3,432.3z M649.6,439.4
			c0.2,4.8,1.4,9.4,1.5,13.9c0.6,0.8,1.2,1,2.1,1.1c0.5-0.2,1.2-0.2,1.7-1.1c-0.5-2.1-0.6-4.4-0.6-6.6c0-1.1,0-2.2-0.1-3.3
			c-0.1-1.3-0.2-2.6-0.3-3.7C652.5,438.4,651.1,438.8,649.6,439.4z M729.4,435.5c-0.3,1.3-0.5,5.1-0.3,7.6c0.1,2.4,0.3,4.9,0.5,7.3
			c0.2,2.5,0.3,5,0.8,7.4c0.8,0.4,1.4,0.9,2.3,0.8c-0.4-7.2-0.8-14.4-1.3-21.7C730.8,436.4,730.2,435.8,729.4,435.5z M739.3,451.6
			c-0.2-2.8-0.4-5.5-0.6-8.3c-0.4-0.4-0.7-0.8-1.1-1.2c-2.2-0.5-3.6,0.4-4.6,2.3c0.1,2.3,0.2,4.6,0.4,6.8
			C735.8,453.4,737.2,453.6,739.3,451.6z M767,414.9c-0.1,0.2-0.2,0.4-0.2,0.5c0,1,0.1,2,0.2,2.9c0.3,4.1,0.6,8.2,0.9,12.3
			c0,0.4,0.1,0.7,0.2,1.1c1-0.1,1.9-0.2,2.8-0.3c0.3-1.4,0-2.8,0-4.1c0-1.4-0.1-2.8-0.1-4.2c0-1.3-0.1-2.7-0.2-4
			c-0.1-1.4,0-2.8-0.5-4.2C769,414.9,768,414.9,767,414.9z M626.3,432.6c0.2-1.7-0.7-8.9-1.6-12.3c-1.3,0-2.6-0.3-3.9,0.5
			c0.2,1.7,0.3,3.4,0.5,5c0.2,2.1,0.4,4.1,0.7,6.2c0,0.3,0.2,0.7,0.3,1c1.3-0.1,2.5-0.2,3.7-0.3
			C626.1,432.8,626.2,432.7,626.3,432.6z M742.7,432.2c-0.2,0.8,0,1.4,0.1,2.1c0.2,1.3,0.4,2.5,0.6,3.8c0.5,3.8,0.8,7.6,0.8,11.4
			c0,0.5-0.1,1,0.3,1.4c0.7,0.1,1.4,0.1,2.2-0.1c-0.1-6.2-0.2-12.4-0.6-18.6C745,432.1,743.8,432,742.7,432.2z M724,416.4
			c0,0.7,0,1.2,0,1.7c0.1,1.5,0.2,3.1,0.2,4.6c0.1,2.5,0.1,5,0.1,7.5c0,0.3-0.1,0.7,0.4,1c0.8-0.8,1.9-0.8,3.1-0.6
			c0-0.4,0.1-0.7,0.1-1c0-2.1-0.1-4.2-0.1-6.3c0-2,0-3.9,0-5.9c0-0.5,0.1-1.1-0.3-1.6C726.4,415.8,725.3,416.3,724,416.4z
			 M703.1,456c0.9,0.3,1.7,0.1,2.5,0.1c0.8,0,1.6,0,2.4-0.1c0.8-0.1,1.7,0.1,2.4-0.2c-0.1-0.6-0.2-1.1-0.2-1.5
			c-0.3-3.6-0.5-7.2-0.4-10.8c0-0.2,0-0.5-0.4-0.6c-2.4,4-3.9,8.5-6.3,12.5C703,455.5,703.1,455.8,703.1,456z M632.3,431.8
			c0.6,5,1.1,9.8,1.6,14.6c0,0.1,0.1,0.2,0.2,0.3c0.5-0.2,0.9-0.4,1.4-0.6c0.5-0.2,1.1,0.3,1.7-0.3c0-0.7-0.2-1.5-0.3-2.3
			c-0.1-0.9-0.2-1.7-0.3-2.6c-0.1-0.8-0.1-1.6-0.2-2.4c-0.1-0.8-0.1-1.6-0.2-2.4c-0.1-0.9-0.2-1.7-0.3-2.6c-0.1-0.8,0-1.6-0.3-2.4
			c-0.6,0.4-1.1,0.7-1.6,1C633.6,432.2,633.1,432,632.3,431.8z M727.9,432c-0.5-0.5-1-0.8-1.5-0.6c-0.4,0.2-0.9,0.5-1.1,0.8
			c-0.4,0.5-0.6,1.1-1,1.7c0.2,3.7,0.4,7.6,0.6,11.4c0.8,1,1.2,2.3,2.8,1.7c0.4-0.6,0.2-1.2,0.2-1.8c0-1.8-0.1-3.7,0-5.5
			c0-2.1,0.1-4.3,0.1-6.4C728,432.9,728,432.4,727.9,432z M696.9,450.1c1.6-2.3,7.3-13.3,8.8-17c0.1-0.3,0.2-0.6,0.2-0.8
			c-0.2-0.1-0.3-0.2-0.4-0.2c-0.5,0.1-1.1,0.1-1.6,0.2c-3,5.3-6,10.6-9,15.7c0.1,0.7,0.1,1.3,0.2,1.9
			C695.8,450,696.3,450.1,696.9,450.1z M610.9,426.5c0.2,1.4,0.4,2.5,0.6,3.6c0.3,2.2,0.5,4.4,0.7,6.6c0,0.4,0.1,0.7,0.1,1.1
			c0,0.1,0,0.1,0.1,0.2c0,0,0.1,0,0,0c1.3-0.3,2.5-0.7,3.8-1c-0.2-3.7-0.7-7.4-1.2-11.1C613.6,426,612.3,426.2,610.9,426.5z
			 M697.1,457c1-2,1.9-3.8,2.8-5.6c0.9-1.7,1.8-3.5,2.7-5.2c1-1.8,2-3.5,2.9-5.3c0.9-1.8,2.1-3.4,3-5.3c-0.9-0.3-1.6-0.3-2.5-0.1
			c-3.2,6.5-6.4,13-10.3,19.1C696.1,455.5,696.3,456.2,697.1,457z M678.2,448.3c0.9,3.4,0.8,6.7,1.2,10.1c0,0.2,0.2,0.4,0.3,0.7
			c1.3,0.1,2.5-0.2,3.8-0.6c-0.2-1.2-0.3-2.4-0.5-3.5c-0.2-1.2-0.3-2.3-0.6-3.4c-0.2-1.1-0.1-2.3-0.7-3.4
			C680.7,448.2,679.6,448.2,678.2,448.3z M720.4,457.6c0.2-1-0.2-1.8-0.3-2.7c-0.1-1-0.1-2-0.1-2.9c-0.1-0.9,0.1-1.8-0.2-2.7
			c-1.3-0.2-2.6-0.4-4-0.7c-0.1,2.9-0.2,5.6-0.2,8.4c0,0.3,0.2,0.7,0.2,1C717.4,458,718.9,458,720.4,457.6z M675.5,459.3
			c-0.4-3.2-0.9-6.4-1.3-9.6c-1.3,0.2-2.5,0.3-3.7,0.5c-0.3,2.4,0.5,7.7,1.4,9.7C673,459.6,674.3,459.9,675.5,459.3z M613.7,454.5
			c-0.2,1.3,0.1,2.3,0.1,3.4c0,1.1,0.2,2.2,0.2,3.3c0.1,1.1,0.1,2.2,0.4,3.3c1.5,0,2.8-0.4,4-1.1c0-2.5-0.5-7.3-1-8.5
			C616.2,455.6,615,455.2,613.7,454.5z M645.2,441.6c-1.3,0.1-2.3-0.1-3.4,0.2c0,0.5,0,0.9,0,1.3c0.3,3.1,0.7,6.2,1.1,9.3
			c0,0.3,0.2,0.7,0.3,1c1,0,1.9,0,2.9,0C645.8,449.5,645.7,445.6,645.2,441.6z M771.8,456.9c-0.1-1.8-0.2-3.3-0.3-4.8
			c-0.1-3-0.2-6-0.3-9c0-0.3-0.2-0.7-0.2-1.1c-0.9,0.1-1.8,0.1-2.6,0.2c-0.1,0.5-0.2,0.8-0.1,1.2c0.1,1.3,0.1,2.6,0.3,3.8
			c0.4,3.1,0.9,6.2,1.4,9.2c0,0.2,0.2,0.3,0.3,0.5C770.7,457,771.2,457,771.8,456.9z M665.1,453.5c-0.1-3.4-0.2-6.6-0.3-9.8
			c0-0.1,0-0.1-0.1-0.2c0,0-0.1-0.1-0.3-0.1c-1.1,0.6-2.1,1.5-3.1,2.2c-0.1,2.7,0.1,8.5,0.5,9.9c0.1,0,0.1,0.1,0.2,0.1
			C663,454.9,664,454.3,665.1,453.5z M739.9,459.4c0.2-2.2-0.1-4.9-0.6-6.2c-2.4,0.7-2,1.2-5.8-0.3c0.2,2.1,0.3,4.2,0.4,6.3
			C736,459.7,737.9,459.9,739.9,459.4z M725.2,447.5c-0.1,0.4-0.2,0.6-0.2,0.9c0.2,3.5-0.1,7,0.6,10.5c1.2,0.1,2.3,0.2,3.4-0.1
			c-0.3-3.4-0.6-6.6-0.9-9.9c0-0.2-0.1-0.3-0.2-0.6c-0.5-0.1-0.9-0.1-1.4-0.2C726.1,448,725.7,447.7,725.2,447.5z M719.6,440.6
			c0.1-3.2,0.6-6.3,0.3-9.4c-0.9-0.1-1.8-0.2-2.6-0.3c-0.1,0-0.2,0.1-0.3,0.1c-0.4,1.7-0.9,7.9-0.8,9.6
			C717.3,441,718.3,440.7,719.6,440.6z M608.2,449.1c0.2-1.8-0.5-8.7-1-10.3c-0.9-0.1-1.9,0-2.9,0.3c0.4,3.5,0.6,7.1,1.1,10.6
			C606.5,449.7,607.4,449.5,608.2,449.1z M624.8,461.5c1.4,0.5,3.3,0.6,4.9,0.1c-0.1-1.1-0.2-2.3-0.4-3.4c-0.2-1.1-0.1-2.3-0.7-3.3
			c-1.5,0.1-2.8,0.2-4.3,0.2c0,1.1,0.2,2.2,0.3,3.2C624.7,459.3,624.7,460.4,624.8,461.5z M750.6,424.2c0.8,0,1.4,0,2,0
			c0.6,0,1.2,0,1.7,0c0.3-1.7,0.1-5.9-0.3-7.5c-1.4,0.2-2.9,0.1-4.2,1.1C750,419.9,750.3,422,750.6,424.2z M606.7,431.6
			c0.1-0.3,0.2-0.5,0.2-0.7c0-2.9,0-5.9,0-8.8c0-0.3-0.2-0.6-0.2-0.8c-2-0.2-2-0.2-3.1,0.3c0,0.6,0.1,1.3,0.1,2
			c0.1,2.3,0.1,4.5,0.2,6.8c0,0.5-0.1,1.1,0.3,1.6C605,432.1,605.8,431.8,606.7,431.6z M716.6,414.5c-0.2,1.5,0.1,2.9,0.2,4.4
			c0.1,1.5,0.1,3,0.4,4.6c0.5,0.1,0.9,0.1,1.3,0.1c0.5,0,0.9,0,1.4,0c0.1-0.3,0.2-0.4,0.2-0.6c0-1.9,0.1-3.8,0.1-5.7
			c0-1-0.3-1.9-0.8-2.8C718.5,414.4,717.6,414.3,716.6,414.5z M672.6,428.1c0-2.2-0.1-4.1-0.2-6.1c-0.1-2,0-4-0.7-6.1
			c-0.8,0.4-1.7,0.4-2.3,1c0.3,3.5,0.5,6.9,0.8,10.3c0,0.2,0,0.4,0.1,0.5c0,0.1,0.1,0.1,0.1,0.2c0,0,0.1,0.1,0.2,0.2
			C671,428.2,671.7,428.1,672.6,428.1z M738,435.7c-2,0.3-3.8,0.6-5.6,1.1c0.1,1.9,0.1,3.7,0.6,5.6c0.6-0.4,1.1-0.9,1.7-1.2
			c0.6-0.3,1.3-0.4,1.9-0.5c0.6-0.1,1.3,0.1,2,0.1C738.6,439.2,738.3,437.6,738,435.7z M639.1,461.9c-0.4-2.4-0.8-4.5-1.1-6.6
			c-0.7-0.1-1.4,0-1.9-0.2c-0.5-0.2-1-0.6-1.5-1c-0.1,0.3-0.2,0.5-0.2,0.6c0.1,2.3,0.3,4.6,0.4,6.9c0,0.2,0.2,0.4,0.3,0.7
			C636.4,462.2,637.7,462.1,639.1,461.9z M641.1,432.7c-0.2,2.3,0.1,6.4,0.6,8.1c1-0.1,2.1-0.2,3.1-0.4c0.1-0.3,0.2-0.4,0.2-0.5
			c-0.2-2-0.3-3.9-0.5-5.9c-0.1-0.6-0.1-1.2-0.8-1.6C642.8,432.5,641.9,432.4,641.1,432.7z M648.1,432c0.4,2.1,0.8,4,1.3,5.9
			c0,0.1,0.2,0.3,0.3,0.4c0.6-0.1,1.2-0.2,1.8-0.3c0.6,0,1.3,0,1.9,0.1c0.2-2-0.3-4.5-1.1-5.9C651,431.8,649.7,431.8,648.1,432z
			 M660.9,437.5c-0.1,1.7,0.3,5.9,0.6,7c0.7-0.2,1.1-0.8,1.6-1.1c0.6-0.4,1.1-0.7,1.8-1.1c-0.1-2.2-0.2-4.3-0.3-6.5
			c0-0.1-0.1-0.2-0.2-0.3c-0.1,0-0.3,0-0.3,0C662.9,436.1,661.9,436.8,660.9,437.5z M706.8,431.1c2-4.5,5-8.3,6.7-13
			c-0.5-0.3-0.8-0.6-1.2-0.8c-0.2,0.2-0.4,0.3-0.4,0.4c-2.3,4.4-4.7,8.8-7,13.3C705.5,431.1,706,431.1,706.8,431.1z M768.2,433
			c-0.3,1.6-0.2,6.7,0.2,8.1c0.9,0.1,1.9,0,2.8-0.5c0-2.6,0.1-5.1-0.1-7.7C770,432.4,769.1,432.7,768.2,433z M742.5,431.3
			c1.3,0,2.5,0.2,3.7-0.4c0-1.1,0.1-2.1,0-3.1c-0.1-1,0.1-2.1-0.4-3.1c-1.4,0-2.5,0.4-3.7,0.9C741.9,427.1,742.1,429.6,742.5,431.3z
			 M691.1,459.3c1.4-2.1,2.8-4.2,4.2-6.3c0.3-0.5,0.5-1,0.8-1.4c0-0.1-0.1-0.2-0.2-0.3c-0.4,0.1-0.9,0.2-1.4,0.3
			c-0.3-0.3-0.6-0.6-0.9-0.8c-0.1,0.1-0.2,0.1-0.2,0.1c-1.5,2.6-3.2,5-5,7.4c-0.1,0.2-0.1,0.4-0.2,0.7
			C689.2,459.1,690.1,459.2,691.1,459.3z M702,454.4c0.7-0.7,1.1-1.7,1.6-2.7c0.5-1,0.9-2,1.4-3c0.5-1.1,1-2.1,1.5-3.2
			c0.4-1,1.1-1.9,1.4-3c-0.4-0.3-0.7-0.5-1.1-0.7c-0.2,0.3-0.4,0.5-0.6,0.8c-1.7,3.1-3.4,6.2-5.1,9.3c-0.1,0.3-0.3,0.5-0.4,0.8
			c-0.1,0.3,0,0.7,0.1,1.1C701,454.2,701.4,454.4,702,454.4z M752,451.3c-0.1,1.9,0.2,3.6,0.6,5.2c0.1,0.5,0.3,1,0.8,1.2
			c1,0.1,2.1,0.1,3.1-0.5c-0.3-1.9-0.6-3.8-0.9-5.6c0-0.1-0.1-0.2-0.2-0.3C754.4,451.3,753.3,451.3,752,451.3z M655.2,454.8
			c-1.4,0.7-2.7,0.5-3.9,0.2c-0.3,2.2-0.2,4.3,0.3,5.8c1.5-0.3,3-0.4,4.4-1c0.1,0,0.1-0.2,0.3-0.4C656,458,655.6,456.6,655.2,454.8z
			 M759.2,414.3c-0.1,1.6,0.4,7.4,0.9,9.7c1.1,0.3,1.1,0.3,2.3-0.1c-0.1-3.3-0.9-6.5-1.2-9.7C760.4,414,759.8,413.9,759.2,414.3z
			 M646,454.4c-1-0.1-1.9-0.2-2.9,0c0.2,2.4,0.2,4.8,0.7,7c1.5,0.5,1.9,0.5,3.3-0.5C646.8,458.8,646.4,456.7,646,454.4z
			 M680.3,423.5c0-0.6,0.1-1,0-1.4c-0.4-2.4-0.9-4.8-1.3-7.2c-0.8-0.1-1.6-0.1-2.3,0.4c0.2,1.4,0.3,2.9,0.5,4.3
			c0.2,1.4,0,2.9,0.4,4.3C678.5,423.7,679.3,423.6,680.3,423.5z M716,441.5c-0.1,2.1-0.5,4.1-0.2,6.1c1.3,0.2,2.6,0.5,3.9,0.2
			c0.2-1.1,0.1-2,0-3c-0.1-1,0.2-2.1-0.3-3C718.3,441.7,717.2,441.6,716,441.5z M664.6,430.5c-0.2-0.1-0.3-0.2-0.4-0.2
			c-1.1-0.1-2.3-0.2-3.4-0.3c-0.3,0-0.5,0.1-0.8,0.2c0,2.6,0.4,5.1,0.9,6.5c0.6-0.4,1.2-0.9,1.7-1.3c0.6-0.4,1.2-0.8,1.9-1.2
			C664.6,433,664.7,431.7,664.6,430.5z M636.9,454.5c1-0.8,1.1-0.8,1-1.7c0-1.3-0.1-2.7-0.2-4c0-0.3-0.1-0.6-0.2-0.9
			c-0.3-0.8-0.9-1.2-1.9-1c-1.3,1.1-1.4,2.6-1.1,4.1C634.7,452.6,635.2,453.9,636.9,454.5z M609.2,456.5c-1.2,0-2.2-0.1-3.1,0.6
			c0.1,1.2,0.2,2.3,0.4,3.3c0.2,1.1,0.1,2.3,0.5,3.4c0.9,0,1.7,0.2,2.6-0.4C609.7,461.1,609.4,458.9,609.2,456.5z M765.5,457.5
			c-0.2-1.7-0.4-3.3-0.7-4.8c0-0.2-0.1-0.3-0.2-0.5c-0.9-0.1-1.8-0.2-2.7-0.3c-0.1,0-0.2,0.1-0.3,0.1c-0.3,1.9-0.2,4.5,0.4,5.9
			C763.1,458,764.2,457.9,765.5,457.5z M633.7,419.3c-0.9,0-1.7,0-2.5,0c-0.3,0-0.5,0.2-0.8,0.4c0.3,1.7,0.5,3.3,0.8,5
			c0,0.1,0.2,0.3,0.3,0.3c0.6-0.3,1.2-0.6,1.7-0.7c0.6-0.1,1.3,0.2,2,0.3C635,422.7,634.3,421.1,633.7,419.3z M665.8,460.6
			c-0.1-2-0.5-3.9-0.7-5.9c-1.4,0.5-2.3,1.4-3.4,2.3c0.2,1.3,0.1,2.7,0.4,4C663.4,461,664.6,461,665.8,460.6z M744.3,451.8
			c-0.2,1.9-0.1,3.6-0.1,5.3c0,0.3,0.2,0.7,0.3,1.1c0.9,0,1.6,0,2.4,0c0.2,0,0.5-0.1,0.7-0.2c-0.2-1.8-0.5-3.5-0.7-5.3
			c0-0.2-0.1-0.3-0.2-0.5c-0.1-0.1-0.2-0.2-0.3-0.2C745.8,451.9,745.2,451.9,744.3,451.8z M634.3,425.3c-0.6-0.4-1.2-0.4-1.7,0
			c-0.5,0.3-0.9,0.8-0.9,1.3c-0.1,1.6,0,3.1,1.3,4.2c0.3,0.3,0.7,0.4,1,0.6c0.9-0.6,1.2-1.3,1.2-2.2
			C635.2,427.8,635.2,426.4,634.3,425.3z M719.8,429.8c0.3-1.4,0.3-3.3,0.1-5c-0.4-0.1-0.8-0.2-1.2-0.3c-0.5-0.1-0.9-0.1-1.5-0.1
			c-0.4,0.9-0.2,1.9-0.2,2.7c0,0.9-0.3,1.8,0.2,2.8C718.1,429.9,718.9,430.2,719.8,429.8z M608.9,455.1c0-1.7-0.2-3.1-0.5-4.6
			c-1,0.1-1.8,0.2-2.8,0.3c-0.1,1.7,0,3.2,0.5,4.8C607.1,455.6,607.9,455.4,608.9,455.1z M748.9,416.9c1-0.7,2.1-1,3.3-1.2
			c0.2,0,0.4-0.2,0.6-0.3c-0.7-1.1-1.7-1.4-2.7-1.8c-3.5,0.1-3.5,0.1-4.7,0.9C746.6,415.2,747.7,416,748.9,416.9z M769.7,413.4
			c-1.1-1.3-2.3-2.3-3.4-3.3c-1.1,0.1-2.2-0.2-3.3,0.3c1,1.3,2.1,2.2,3.2,3.1c1,0.1,2,0.2,3.1,0.2
			C769.3,413.7,769.4,413.6,769.7,413.4z M604.3,438c1.1-0.1,2-0.2,2.8-0.6c-0.1-1.5,0-2.9-0.5-4.4c-0.9,0.2-1.9,0.1-2.7,0.7
			c0.1,1,0.1,2.1,0.2,3.1C604.1,437.1,604.2,437.5,604.3,438z M650.9,427.9c-2,1.2-2.3,1.5-3,2.7c0.8,0.2,1.6,0,2.3,0.1
			c0.8,0.1,1.7,0.2,2.6,0.3c0.5-0.8,1.5-1.3,1.9-2.4C653.5,428.3,652.3,428.1,650.9,427.9z M701.3,455.7c-0.6-0.4-1.1-0.7-1.6-1.1
			c-0.8,1.3-1.6,2.5-2.1,4c0.5,0.5,0.9,0.9,1.3,1.3C700.1,459.1,700.5,458.3,701.3,455.7z M646.8,427.7c-0.9,0.8-1.5,1.3-2.1,1.8
			c-0.6,0.6-1.4,0.9-2,1.8c0.8,0,1.4,0,2.1,0c0.9-0.4,1.7-0.9,2.3-1.7c0.4-0.5,1.1-0.9,1.4-1.7C647.8,427.8,647.2,427.7,646.8,427.7
			z M709.3,429.7c-0.1,0-0.3-0.1-0.4-0.1c-0.7,1.6-1.6,3.1-2.2,4.8c0.9,0.2,1.7,0.3,2.5-0.1C709.3,432.8,709.3,431.3,709.3,429.7z
			 M755.7,410.6c1.3,1,2.2,1.7,3.2,2.4c0.6-0.1,1.2-0.1,2-0.2c-0.5-1-1.1-1.8-1.8-2.4C758.1,410.4,757.1,410.4,755.7,410.6z
			 M673.7,422.2c0.3,4.6,0,9.2,1.2,13.6C674.7,431.3,674.8,426.7,673.7,422.2z M708.6,441.4c0.8-1.6,1.6-3,2.3-4.4
			c-0.4-0.3-0.6-0.5-1-0.9c-0.7,1.2-1.4,2.3-2.1,3.4c-0.2,0.4-0.4,0.8-0.6,1.2C707.7,441,708.1,441.2,708.6,441.4z M661.2,427.3
			c-0.2,0.6-0.3,1-0.4,1.4c1.4,0.3,2.7,0.6,4,0.2c0.2-0.7,0.4-1.4-0.2-2C663.5,427.1,662.5,427.2,661.2,427.3z M734.5,433.5
			c-1.1,0.2-1.9,0.4-2.8,0.5c-0.3,0.1-0.6,0.2-0.9,0.4c0.5,0.7,1.1,0.9,1.8,1.1c0.6-0.1,1.2-0.2,1.7-0.4c0.5-0.2,1.2,0,1.7-0.6
			C735.5,434.2,735,433.9,734.5,433.5z M616.1,425.2c0.9-0.6,1.7-1.1,2.6-1.7c0.1,0,0.1-0.2,0.1-0.4c-0.8-0.2-1.6-0.1-2.4-0.1
			c-0.8,0.4-1.6,0.7-2.1,1.5C615,424.7,615.5,424.9,616.1,425.2z M739.7,423.5c0.4,0.4,0.9,0.7,1.2,1c1.8-0.4,1.8-0.4,2.6-1
			c-0.7-0.6-1.5-1-2.3-1.3C740.7,422.7,740.2,423.1,739.7,423.5z M666.6,444.8c-0.5,3-0.2,10,0.3,11.7
			C666.8,452.6,666.7,448.7,666.6,444.8z M608.1,420.2c0.7-0.3,1.5-0.7,2.3-1c0.2-0.1,0.3-0.3,0.6-0.6c-1,0-1.7,0-2.6,0
			c-0.2,0.1-0.6,0.3-1,0.5c-0.4,0.2-0.8,0.3-1.1,0.8C607,420,607.5,420.1,608.1,420.2z M666.2,432.8c-0.4,1.5-0.4,5.2,0.1,7.7
			c0.1-0.4,0.3-0.6,0.3-0.8C666.6,437.4,666.4,435.2,666.2,432.8z M626.1,418.9c1.8,0.2,2.8-0.1,4-0.9c-0.8,0.1-1.5,0.2-2.1,0.4
			C627.3,418.5,626.7,418.7,626.1,418.9z"/>
                            <path d="M792.5,470.1c0.7,0.6,0.6,1.2,0.6,1.8c0,1.6-0.2,3.2,0.1,4.8c0.6,4.6,0.6,9.2,0.9,13.8c0.2,2.8,0.2,5.6,0.2,8.5
			c0,4.6,0.1,9.2,0.5,13.8c0.4,3.8,0.4,7.7,0.6,11.6c0,0.7-0.1,1.3-0.1,2c-0.5,0.2-0.9,0.3-1.3,0.4c-0.3,0.1-0.6,0-0.9,0.1
			c-5.3,0.1-10.6,0.3-15.9,0.4c-7.8,0.2-15.6,0.4-23.4,0.6c-11.8,0.3-23.5,0.6-35.3,0.9c-11.2,0.3-22.4,0.6-33.6,0.9
			c-7.9,0.2-15.7,0.4-23.6,0.6c-11.7,0.3-23.4,0.6-35.1,0.9c-7.6,0.2-15.2,0.3-22.8,0.4c-0.9,0-1.8,0.1-2.8-0.2
			c-0.6-0.9-0.8-1.9-0.9-2.9c0-4.1-0.8-8.2-1.1-12.3c-0.2-2.1-0.3-4.3-0.4-6.4c-0.1-4.2,0-8.4-0.2-12.5c-0.1-3.9-0.4-7.8-0.6-11.8
			c-0.1-1.4,0-2.8,0-4.2c0-1.7-0.1-3.4-0.1-5.2c0-0.6,0-1.2,0.1-1.6c0.5-0.6,1-0.7,1.5-0.6c2.5,0.6,5,0.5,7.5,0.4
			c3.2,0,6.3-0.1,9.5-0.1c4.9-0.1,9.7-0.6,14.6-1c1.9-0.2,3.8-0.2,5.7-0.4c4.1-0.4,8.2-0.9,12.4-1.3c2.8-0.3,5.6-0.5,8.4-0.6
			c4.1-0.2,8.2-0.5,12.2-0.6c2.2-0.1,4.5-0.2,6.8,0c7.2,0.4,14.4,0.2,21.5-0.3c7.7-0.6,15.3-0.7,23-0.5c6.9,0.2,13.9,0.4,20.8,0.6
			c2.7,0.1,5.5,0.4,8.2,0.5c8.8,0.3,17.5,0.3,26.3,0c1.2,0,2.3,0,3.5-0.1c2.6-0.1,5.1-0.2,7.7-0.5
			C788.8,469.8,790.7,469.9,792.5,470.1z M667.9,477.7c0.6,2.5,0.7,4.7,0.7,7c0,0.6,0,1.2,0,1.8c0.1,4.5,0.3,9.1,0.4,13.6
			c0.1,2.1,0.2,4.2,0.4,6.2c0.3,3.3,0.5,6.6,0.8,9.9c0,0.4,0.1,0.7,0.2,1.1c0.5,0,0.9,0,1.3,0c3.7-0.2,7.4-0.3,11.1-0.5
			c0.4,0,0.7,0,1.1-0.1c0.2,0,0.3-0.2,0.5-0.3c-0.1-0.5-0.1-0.9-0.2-1.3c-1-0.4-2.1-0.1-2.9-0.7c-0.5-1.3-0.6-3.1-0.2-4.9
			c0.6-0.1,1.2-0.1,1.8-0.2c0.1-0.5,0.2-1,0.3-1.5c0.7-0.1,1.3-0.3,2-0.4c0.1-0.8,0.2-1.5,0.3-2.2c0.7-0.2,1.3-0.4,1.9-0.6
			c-0.2-0.6-0.3-1-0.4-1.5c-2.2,0-4.3,0.3-6.4-0.2c-0.2-0.8-0.5-1.6-0.5-2.4c0.2-0.2,0.3-0.4,0.5-0.5c0.4-0.2,0.7-0.4,1.1-0.6
			c1.1-0.5,2.2-1.1,3.3-1.6c1-0.5,2-0.9,3-1.4c1-0.5,2.1-0.6,3.2-0.7c2.7,0,5.4-0.2,8-0.3c2.3-0.1,4.6-0.2,6.9-0.4
			c1.2-0.1,2.4,0,3.7-0.1c1-0.1,2-0.3,3.1-0.4c0.1,0,0.1,0,0.2-0.1c0,0,0-0.1,0.1-0.3c-0.5-1-1.4-1.8-2.2-2.8
			c-0.7-1-1.5-1.9-2.3-2.8c0.3-1.3,1.6-1.7,2.3-2.6c0.2-0.3,0.7-0.4,1.1-0.7c0.4,0.4,0.8,0.7,1.1,1c1.4,1.7,2.9,3.3,3.9,5.3
			c0.9,1.6,1.9,3.1,3.1,4.5c2.1,2.5,4,5.3,5.6,8.2c1.9,3.4,4,6.6,6,9.9c0.1,0.2,0.3,0.4,0.4,0.5c1.6,0.2,1.6,0.2,2.8-0.2
			c0-0.3,0-0.7,0-1.1c-0.3-2.4-0.4-4.9-0.9-7.3c-1-5.2-1-10.5-0.8-15.8c0.2-5.1,0.5-10.2-0.5-15.2c-0.1-0.6-0.2-1.2,0.1-1.9
			c1.6-0.7,3.3,0.6,5,0.1c0.1-0.4,0.2-0.7,0.3-1c-0.6-0.4-1.3-0.2-1.9-0.2c-1.8-0.1-3.7-0.1-5.5-0.1c-5.2-0.2-10.5-0.4-15.7-0.5
			c-6.1-0.1-12.2,0.1-18.3,0.6c-6.1,0.5-12.2,0.8-18.3,0.3c-5.4-0.4-10.8,0-16.2,0.3c-0.4,0-0.8,0.2-1.2,0.3c-0.1,1.8,0,3.5,0.1,5.2
			c0.8-0.1,1.4-0.7,2.3-0.3c0.3,0.4,0.6,0.8,0.9,1.2c0.7-0.1,1.3-0.1,1.8-0.1C666.8,478.6,667.3,478.2,667.9,477.7z M603.4,529.8
			c1,0,1.6,0,2.2,0c6-0.2,11.9-0.4,17.9-0.5c13-0.4,26.1-0.7,39.1-1c8-0.2,16.1-0.5,24.1-0.7c11.5-0.3,23-0.6,34.5-0.9
			c10.9-0.3,21.8-0.6,32.7-0.9c7.9-0.2,15.8-0.4,23.7-0.6c3.3-0.1,6.7-0.2,10-0.3c0.4,0,0.8,0.1,1.2-0.3c-0.7-0.9-0.7-0.9-2-1.5
			c-1.3-0.6-2.5-1.3-3.6-2.2c-0.1-0.1-0.2,0-0.3,0c-0.2,0.3-0.4,0.6-0.7,0.9c-1.2,0.2-2.3,0.6-3.4,0.1c-0.1-0.2-0.2-0.3-0.3-0.4
			c-0.5-1.2-0.6-2.5-0.6-3.8c0.1-5.8-0.1-11.5-0.6-17.3c-0.1-1-0.1-2-0.1-2.9c0-1.9,0.1-3.8-0.3-5.7c-0.1-0.6-0.1-1.2,0-1.8
			c0-0.9,0.1-1.7,0-2.6c0-1.2,0-2.5-0.5-3.6c-1.6-1.7-3.3-3.1-5.1-4.5c-0.2-0.2-0.5-0.2-0.8-0.3c0,0.9,0,1.6,0,2.1
			c2,2.1,3.9,4,5.6,5.8c0.6,1.5,0.5,2.8,0.5,4.1c0,3.4-0.1,6.9-0.1,10.3c0,3.1,0,6.3,0.2,9.4c0.3,3.5,0.5,7,0.7,10.5
			c0,0.5,0.2,1.1-0.2,1.6c-0.6,0.3-1.2,0.5-1.9,0.3c-1.2-0.3-2.4-0.7-3.7-1.1c-1.5,1.2-3.2,0.6-4.7,0.2c-2,0.7-3.8,0.9-5.6-0.2
			c-0.4-0.2-0.8-0.4-1.2-0.5c-0.1,0-0.2,0-0.3,0.1c-0.2,0.4-0.3,0.8-0.5,1.2c-1.3,0.3-2.6,0.2-3.8,0c-0.5-0.3-1-0.6-1.6-0.9
			c-0.2,0.5-0.4,0.8-0.5,1.2c-1.4,0.2-2.7,0.5-3.9-0.5c-1.1-1.4-1.1-1.4-1.5-1.3c-0.1,0.4-0.2,0.8-0.3,1.4c-1.6,0.4-3.3-0.1-5,0.4
			c-2.6-1.9-4.7-4.4-7.5-6c-0.6,0-1.2,0-1.9,0c0.3,1.6,2,2.3,2.2,3.9c-0.3,0.3-0.6,0.7-1,1c-1.3,1-2.7,0.9-3.7-0.3
			c-0.4-0.5-0.7-1-1.1-1.5c-0.7-1-1.3-2.1-1.9-3.1c-1.6-0.1-3.1-0.2-4.5,0c0,1.1,0,2,0,3c0,1,0.3,2.1-0.1,3.1
			c-0.2,0.1-0.4,0.2-0.6,0.2c-1.9,0.2-3.8,0.3-5.6,0.5c-4.1,0.3-8.3,0.6-12.4,0.6c-1.6,0-3.3,0-4.9,0.1c-3.7,0.1-7.3,0.2-11,0.3
			c-1.5,0-2.9,0-4.4,0c-0.4,0-0.8-0.2-1.1-0.2c-0.8-1.9-1-3.8-1.1-5.8c-4.3,0-8.6,0.4-12.9,0.5c-1,0.9-2,1.7-3,2.5
			c-0.9,0.8-1.9,1.6-2.8,2.3c-1,0.8-1.8,1.8-3,2.4c-0.7-0.1-1.5-0.2-2.2-0.4c-1.1-0.2-1.9-0.8-2.1-2c0-0.2-0.1-0.3-0.2-0.6
			c-1.1,1.1-2.2,2.2-3.3,3.2c-1.2,0-2.4,0.8-3.6-0.1c-0.1-0.5-0.1-0.9-0.2-1.4c-0.1-0.5,0.1-1-0.3-1.4c-1.4,0.8-2.2,2.4-3.5,3.3
			c-1.1,0.1-2.3,0.5-3.4-0.5c-0.1-0.9-0.1-1.8-0.2-2.7c0-0.3,0-0.6-0.5-0.7c-0.7,0.6-1.4,1.3-2.1,2c-0.7,0.7-1.4,1.3-2.2,2
			c-0.8,0-1.6,0-2.4,0c-0.8,0-1.7,0.2-2.5,0.3c-0.3-0.4-0.5-0.8-0.8-1.2c-1.2,0.6-2.1,1.7-3.4,1.9c-1.3,0.2-2.6,0.4-3.9,0
			c-0.6-0.6-0.4-1.3-0.5-2c0-0.3,0-0.6-0.4-0.7c-1.4,1.1-2.5,2.6-4.3,3c-1.4,0-1.7-1.3-2.5-2.1c-1.3,0.9-2.5,1.7-3.8,2.6
			c-1.5,0.6-3,0.6-4.4-0.7c0-0.4,0-0.9,0-1.4c0-0.3-0.1-0.5-0.3-1C606.3,527.1,604.9,528.2,603.4,529.8z M793.5,524.5
			c0-0.6,0-1.1,0-1.6c-0.2-2.8-0.4-5.5-0.6-8.3c-0.1-1.2-0.1-2.5-0.3-3.7c-0.4-3-0.5-6-0.4-9c0.1-4.4,0.2-8.7-0.2-13.1
			c-0.2-3-0.2-6-0.5-9c-0.2-2.6-0.4-5.1-0.2-7.7c0-0.3-0.1-0.5-0.2-0.8c-1,0.2-1.9,0.5-2.8,0.6c-3,0.3-6.1,0.4-9.1,0.7
			c-0.5,0-1.1-0.1-1.6,0.4c0.1,2.5,0.2,5.2,0.4,7.8c1,1,2,1.9,2.9,2.7c0.5,3,0.5,5.8,0.4,8.7c-0.1,6.1-0.1,12.1,0.1,18.2
			c0.1,2.5,0.4,4.9,0.8,7.3c0.6,0.6,1.2,1.1,1.8,1.7c0.9,0.9,1.9,1.5,3,2.1c1,0.5,2,1.1,3,1.8C791,524,792.1,524.4,793.5,524.5z
			 M611.8,476.9c-0.3-0.7-0.8-0.6-1.2-0.6c-3.7,0-7.4,0.1-11.1-0.4c-0.1,0-0.1,0-0.3,0.1c0,0.4-0.1,0.9-0.1,1.4
			c-0.1,2.9,0.3,5.7,0.2,8.6c0,0.1,0,0.2,0,0.4c0.4,4.2,0.6,8.3,0.7,12.5c0,4.6,0.2,9.2,0.3,13.8c0,0.6,0.1,1.2,0.1,1.8
			c0.3,4.6,1.2,9.3,1.1,13.9c0,0.2,0.2,0.4,0.3,0.7c1-1.1,1.9-2.1,2.9-3c1-1,2.1-1.9,3.2-2.8c0-0.8-0.1-1.6-0.1-2.4
			c-0.3-3.4-0.6-6.8-0.9-10.3c-0.3-3.2-0.5-6.5-0.9-9.7c-0.7-5.7-1-11.4-1.3-17.1c-0.1-1.2-0.1-2.4,0.1-3.7
			C606.7,478.6,609.5,478.3,611.8,476.9z M635.9,479.5c0,0.6-0.1,1.1-0.1,1.6c0.4,6.7,0.3,13.5,0.8,20.2c0.5,7.2,0.7,14.3,1,21.5
			c0,0.5-0.1,1.1,0.3,1.6c0.1,0,0.3,0,0.4-0.1c1-1,2.1-2,3.1-3c0-0.3,0-0.7,0-1c-0.2-1.9-0.3-3.8-0.5-5.7c-0.4-4-0.8-8.1-1.1-12.1
			c-0.1-0.9-0.3-1.8,0-2.6c2.5-1.4,4.2-3.3,6-5.1c0.1-1.6,0-3-0.2-4.5c-0.5-4.9-1.1-9.8-1-14.7c0-0.5,0.2-1.1-0.4-1.6
			c-0.5,0.1-1.1,0.1-1.6,0.2c-1.2,1-2.2,1.9-3.2,2.7C638.3,477.9,637.1,478.7,635.9,479.5z M660.6,487.9c0,1,0,2.1,0,3.2
			c0,2.9,0.1,5.8,0.1,8.6c0,1.7,0.1,3.3,0.1,5c0.1,2.9,0.3,5.9,0.4,8.8c0.1,3.2,0.1,6.4,0.1,9.6c0,0.4-0.2,0.9,0.2,1.2
			c1.5-0.9,6.6-5.3,7.8-6.8c-0.1-1.5-0.2-3.2-0.3-4.8c-0.3-3.4-0.7-6.8-0.9-10.3c-0.3-6.9-0.4-13.9-0.6-20.8c0-0.4,0-0.7-0.1-1.1
			c0-0.2-0.1-0.4-0.3-0.8C664.9,482.5,662.8,485.1,660.6,487.9z M743.4,521.9c-0.2-3-0.3-5.5-0.5-8c-0.2-4-0.5-8-0.6-11.9
			c-0.2-7.6-0.4-15.2-1.4-22.8c-0.1-0.6-0.1-1.2-0.2-1.8c-2.5-0.5-4.3-2.1-6.6-2.8c-0.1,0.2-0.2,0.3-0.2,0.4c0,0.4,0,0.7,0.1,1.1
			c0.8,4.1,0.6,8.3,0.5,12.5c-0.1,3.1-0.2,6.1-0.2,9.2c0,2.5,0.3,5,0.7,7.5c0.4,2.3,0.6,4.6,0.8,6.9c0.1,1.1,0.2,2.2,0.3,3.1
			c0.5,1,1.2,1.6,1.9,2.2c1.5,1.1,2.9,2.2,4.1,3.6C742.4,521.3,742.8,521.4,743.4,521.9z M752.8,488c1,0.9,1.9,1.8,2.9,2.7
			c0.7,0.6,1.4,1.2,2,1.8c0.7,0.6,1.1,1.5,1.1,2.4c0.1,1.2,0.2,2.4,0.2,3.7c0.3,4.5,0.8,9.1,0.5,13.6c0,0.8-0.1,1.6,0,2.4
			c0.1,1.8,0.3,3.7,0.4,5.5c0.9,0.5,1.7,1,2.6,1c0.3-0.6,0.1-1.3,0.1-1.9c-0.3-5.9-0.7-11.8-1-17.6c-0.1-2.3-0.1-4.5-0.3-6.8
			c-0.3-3.2-0.7-6.3-0.8-9.5c-0.1-2.5-0.2-5-0.3-7.5c0-0.4,0-0.7,0-1.1c-1.4-1.4-2.9-2.5-4.4-3.7c-1.4,0-2.8-0.3-4.2,0.1
			C751.6,478.1,752.6,482.9,752.8,488z M651.6,479.9c0.1,0.8,0.1,1.7,0.3,2.6c0.4,2.3,0.6,4.6,0.6,7c0,3.1-0.1,6.1-0.2,9.2
			c0,0.8,0,1.6,0,2.4c0.1,2.4,0.1,4.8,0.2,7.2c0.1,3.4,0.3,6.9,0.4,10.3c0.1,1.9,0.2,3.8,0.3,5.7c0,0.1,0.1,0.2,0.2,0.3
			c0.1,0,0.3,0,0.3,0c0.9-0.9,1.7-1.9,2.6-2.9c0-0.7-0.1-1.3-0.1-2c-0.2-4-0.5-8.1-0.6-12.1c-0.1-5.3-0.2-10.5-1-15.8
			c-0.2-1.5-0.3-2.9-0.1-4.4c0.4-0.4,0.9-0.7,1.2-1.2c0.9-1.1,1.8-2.2,2.6-3.3c0.7-0.9,1.2-1.8,1.8-2.6c0.2-3.9,0.1-6-0.2-7.1
			c-0.8,0-1.7,0-2.4,0C655.5,475.3,653.6,477.5,651.6,479.9z M631.5,524.4c0-0.4,0-0.8,0-1.2c-0.2-6-0.6-11.9-1.1-17.8
			c-0.3-3.8-0.7-7.6-0.9-11.4c-0.3-3.9-0.5-7.7-1.1-11.5c0-0.3-0.2-0.5-0.3-0.9c-1.1,1.1-2.2,2.1-3.3,3.2c0.1,0.7,0.3,1.5,0.4,2.3
			c0.3,2.3,0.4,4.5,0.8,6.8c0.7,4,1,8,1,12.1c0,3,0.3,5.9,0.6,8.8c0.4,3.7,0.6,7.3,0.9,11c0,0.3-0.1,0.6,0.4,0.8
			C629.7,526,630.6,525.3,631.5,524.4z M616,525.1c-0.1-3.4-0.4-6.5-0.9-9.6c-0.3-2-0.5-4-0.7-6c-0.4-4.3-0.8-8.7-1.4-13
			c-0.4-3-0.6-6-0.5-9c0-0.4,0.1-0.8,0.1-1.2c0.9-1.1,2.3-1.5,3.1-2.6c0-1.6-0.1-3.2-0.4-4.8c-1.6,0.2-3.6,1-5.1,1.8
			c0.1,0.8,0.1,1.8,0.2,2.7c0.2,1.8,0.4,3.7,0.5,5.5c0.1,3.4,0.2,6.8,0.5,10.1c0.1,1.4,0.2,2.8,0.3,4.2c0.2,3.2,0.3,6.5,0.5,9.7
			c0.3,4.3,0.7,8.6,1,12.9c0,0.3,0.1,0.5,0.3,1C614.5,526.2,615.3,525.7,616,525.1z M620.5,526.1c1.3-0.6,1.9-1.8,3-2.4
			c-0.1-0.9-0.1-1.7-0.2-2.6c-0.2-2.1-0.5-4.3-0.6-6.4c-0.2-3.8-0.3-7.6-0.4-11.4c-0.2-4-0.1-8.1-0.6-12.1c-0.2-1.7-0.5-3.4-0.7-5.1
			c0-0.4,0-0.7,0-0.9c1.8-1.6,3.5-3.1,5.2-4.6c0.2-0.1,0.4-0.3,0-0.6c-1.4,1.1-2.9,2-4.1,3.3c-1.3,1.3-3.1,1.7-4.4,3
			c0.1,0.6,0.3,1.2,0.4,1.8c0.6,2.1,1,4.3,1,6.5c0.1,2.9,0.2,5.9,0.4,8.8c0.2,3.6,0.4,7.1,0.6,10.7c0.1,2.5,0.3,5,0.4,7.5
			C620.7,522.9,620.5,524.4,620.5,526.1z M636.1,515.3c-0.5-8.1-1-16.2-1.4-24.2c0-0.1,0-0.1-0.1-0.1c0,0-0.1-0.1-0.2-0.1
			c-0.6,0.1-1.1,0.7-2,0.1c-0.3-0.5-0.6-1.2-1-2c-0.4,0.4-0.6,0.7-0.8,0.9c0,2.8,1.7,25.1,2,25.6c0.3,0.1,0.7,0.3,1,0.4
			c0.3,0.5,0.5,1,0.8,1.5C634.9,516.5,635.4,516,636.1,515.3z M716.5,519c-0.3-0.1-0.6-0.1-0.8-0.1c-2.1,0-4.1,0-6.2,0.1
			c-6,0.4-12,0.7-18,0.8c-0.5,0-0.9,0.1-1.5,0.1c0,1.2-0.1,2.4,0.4,3.5c1.3,0,2.6,0,3.8,0c4.8-0.1,9.6-0.3,14.4-0.3
			c2.3,0,4.6-0.2,6.9-0.5c0.3,0,0.7-0.2,1-0.3C716.6,521.2,716.7,520.1,716.5,519z M682.2,500.4c0,0.1,0,0.1,0.1,0.2
			c0.2,0,0.5,0,0.7,0c1.5-0.4,3-0.4,4.5-0.4c0.9,0,1.8,0,2.7-0.1c2.8-0.2,5.6-0.5,8.4-0.5c1.5,0,3-0.2,4.5-0.3
			c1.8-0.1,3.5-0.3,5.3-0.4c2.2-0.1,4.5,0,6.7,0c0.4,0,0.8,0.2,1.2-0.3c-0.3-0.5-0.7-1-1-1.4c-0.4-0.5-0.8-0.9-1.1-1.4
			c-0.7,0.1-1.3,0.1-1.9,0.2c-2.5,0.4-5.1,0.3-7.6,0.4c-1.4,0-2.8,0.1-4.2,0.2c-1.7,0.1-3.4,0.2-5.1,0.3c-1.8,0-3.5,0-5.6,0
			C687.4,497.9,684.8,499.1,682.2,500.4z M768,521.2c0-0.7-0.1-1-0.1-1.3c-0.4-4.2-0.3-8.3-0.1-12.5c0.1-3,0.1-6,0-9
			c-0.2-4.1-0.5-8.2-0.4-12.3c0-2.7-0.1-5.5-0.2-8.2c-0.6-0.5-1.2-1-2-0.9c0,0.4,0,0.7,0,1c0.1,3.8,0.3,7.6,0.4,11.4
			c0.1,4.5,0.1,8.9,0.3,13.4c0.2,5.6,0.4,11.1,0.9,16.7c0,0.3,0.1,0.6,0.2,0.9C767.1,520.6,767.4,520.8,768,521.2z M749.9,520.8
			c0.3-0.8,0.2-1.5,0.1-2.1c-0.2-1.9-0.5-3.8-0.6-5.7c-0.3-5.3-0.5-10.5-0.8-15.8c-0.3-3.9-0.4-7.8-0.2-11.8
			c0.1-1.8,0.1-3.7-0.3-5.5c-0.7-0.4-1-1.2-1.8-1.4c0.1,3.2,0.1,6.2,0.2,9.3c0,0.1,0,0.1,0,0.2c0.1,2.9,0.4,5.8,0.4,8.6
			c0,4.8,0.2,9.6,0.6,14.3c0.2,2.7,0.5,5.5,0.7,8.2C748.7,519.7,749.2,520.2,749.9,520.8z M712.2,510c-0.3-0.1-0.4-0.1-0.6-0.1
			c-5.8,0.1-11.7,0.1-17.5,0.2c-0.3,0-0.7,0.1-1,0.2c-0.8,1.1-0.9,2.2,0,3.3c0.6,0,1.2,0.1,1.9,0c5.6-0.4,11.3-0.5,16.9-0.4
			c0.5,0,1.1-0.1,1.6-0.1C713.9,511.7,712.7,511.1,712.2,510z M755.2,521.2c0.3-2.6-0.7-22.8-1.3-26.9c-0.7-0.8-1.4-1.6-2.1-2.4
			c0,0-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.3,0.1c0.2,4.6,0.6,9.3,0.9,14c0.3,4.7,0.7,9.4,1.3,14.1C754,520.3,754.5,520.7,755.2,521.2z
			 M770.8,486.8c0.1,2.2,0.1,4.1,0.2,6c0.2,3,0.2,6,0,9c-0.3,5-0.2,10,0.2,15.1c0.1,1.2,0.3,2.4,0.5,3.5c0.6,0.5,1.1,0.8,1.8,0.9
			c0-0.5,0.1-0.8,0.1-1.2c-0.1-3.1-0.1-6.1-0.3-9.2c-0.4-6.2-0.6-12.5-0.4-18.7c0-1.1,0.1-2.2,0.1-3.3
			C772.4,488.2,772,487.4,770.8,486.8z M646.6,524.4c1-0.8,1.6-1.6,2.3-2.3c0-0.3,0.1-0.5,0.1-0.8c-0.3-7.2-0.7-14.4-1-21.6
			c0-0.1,0-0.1-0.1-0.2c0,0-0.1-0.1-0.3-0.2c-1,0.7-1.9,1.5-2.8,2.5c0.1,0.7,0.1,1.4,0.2,2c0.2,2.7,0.6,5.4,0.7,8
			c0.1,3.9,0.5,7.8,0.7,11.7C646.4,523.7,646.5,523.9,646.6,524.4z M616.9,482.9c0.5-0.3,0.7-0.3,0.9-0.5c1.3-0.9,2.7-1.7,3.9-2.7
			c1.6-1.4,3.4-1.3,5.3-1.4c0.1,0.4,0.3,0.7,0.4,1c0.4-0.2,0.7-0.4,1.1-0.5c0.2-0.1,0.4-0.1,0.5-0.2c1.5-1,3-2.1,4.5-3.2
			c0,0,0.1-0.1,0.1-0.2c0-0.1,0-0.1-0.1-0.2c-0.1-0.1-0.2-0.2-0.3-0.2c-6.4,0.5-12.8,1.1-19.3,1.3c-0.2,0-0.3,0.1-0.5,0.2
			c0.1,0.2,0.1,0.3,0.2,0.5c1,0.1,2,0.1,3,0.2c0.5,0.9,0.1,1.8,0.2,2.6C616.8,480.8,616.9,481.7,616.9,482.9z M656.6,496.1
			c0,0.4,0,0.7,0,1.1c0.1,1.8,0.2,3.7,0.3,5.5c0.1,3.7,0.2,7.5,0.3,11.2c0,0.8,0.1,1.6,0.2,2.4c0,0.3,0.2,0.7,0.3,1
			c0.8-0.1,1.5-0.1,2.2-0.5c0.2-1.4-0.1-2.7-0.1-4.1c-0.1-1.4-0.2-2.8-0.3-4.2c-0.1-1.3-0.1-2.7-0.2-4c0-1.4,0-2.8-0.1-4.2
			c0-1.3,0.2-2.7-0.1-4.1C658.3,496.1,657.5,496.1,656.6,496.1z M743.4,503.3c-0.1,1.5,0.8,17,1,18.4c1,0.3,1.9,0.3,3-0.1
			c0-0.4,0-0.7,0-1c-0.5-5.5-0.9-11-1.4-16.5c0-0.3-0.2-0.6-0.3-0.9C744.9,503.3,744.2,503.3,743.4,503.3z M699.5,514.7
			c-0.4,0-0.7-0.1-1-0.1c-2.4,0.1-4.7,0.2-7.1,0.3c-1.4,0.1-2.8,0.1-4.2,0.2c-0.6,0-1.2,0-1.8,0.5c0.2,1,0.1,2,0.8,3
			c4.6-0.3,9.2,0.3,13.8-0.4C699,516.6,699,516.6,699.5,514.7z M681.4,502.4c0.5,0.1,0.8,0.2,1.2,0.2c2.4-0.1,4.7-0.2,7.1-0.3
			c0.2,0,0.4-0.1,0.5-0.1c1.1-0.1,2.2-0.1,3.3-0.2c0.8,0,1.7,0,2.5-0.1c4.9-0.7,9.8-0.6,14.7-0.5c1.3,0,2.5,0,3.8-0.2
			c1-0.1,1.9-0.2,3-0.4c-0.1-0.4-0.1-0.8-0.2-1c-0.2-0.1-0.4-0.1-0.5-0.1c-3.6,0-7.2-0.3-10.7,0.1c-1.2,0.2-2.4,0.3-3.6,0.3
			c-2.5,0.2-5.1,0.2-7.6,0.4c-3,0.2-5.9,0.5-8.9,0.4c-1.4,0-2.8,0.2-4.2,0.4c-0.1,0-0.2,0.1-0.3,0.2
			C681.4,501.9,681.4,502,681.4,502.4z M611,512.9c0.2-2.9-0.3-11.2-0.8-13.1c-1.1,0.1-2.2-0.2-3.3,0.2c0.3,7.1,0.7,11.5,1.2,13.1
			C609.1,513.3,610,513.2,611,512.9z M646.9,478.3c1.5-0.6,1.5-0.6,2.5-1.7c0.8-0.8,1.6-1.7,2.3-2.6c0.1-0.1,0.2-0.4,0.3-0.7
			c-2.3,0-4.5,0.3-6.7,0.7c-0.1,6.6,0.8,13.1,1.3,19.8c0.5-0.4,0.8-0.6,1.2-0.9C647.9,488.1,647,483.2,646.9,478.3z M765.5,473.3
			c1.5,1,3.3,1.5,4.6,2.7c0.2,0.7,0.3,1.3,0.5,1.9c1,0.5,1.9,0.4,2.6,0.3c0.3-0.3,0.4-0.5,0.7-0.8c0.7,0.6,1.3,1.1,1.9,1.6
			c0.2,0.2,0.5,0.2,0.9,0.3c0-1.2,0-2.3,0-3.3c0-1,0.1-2.1-0.3-3.1c-0.3,0-0.5-0.1-0.8-0.1c-3.3,0.1-6.5,0.2-9.8,0.3
			C765.7,473,765.7,473.1,765.5,473.3z M742.7,489.6c1,0,1.9,0.3,2.9-0.1c-0.2-3.7-0.3-7.4-0.5-11c0-0.5-0.2-1.1-0.3-1.7
			c-1.1-0.1-2.1-0.1-3.3,0.3C742,481.3,742.3,485.5,742.7,489.6z M614.9,499.3c-0.2,2.2,0.2,4.2,0.5,6.3c0.2,2.1,0.3,4.3,0.7,6.4
			c0.9,0.1,1.7,0.3,2.6,0.4c0.3-1.5,0.1-2.9,0-4.3c-0.1-1.5-0.2-2.9-0.4-4.4c-0.2-1.4-0.1-2.8-0.4-4.2c-0.3-0.1-0.6-0.2-0.8-0.2
			C616.4,499.3,615.8,499.3,614.9,499.3z M614,486.8c-0.5,0.6-0.4,1.1-0.4,1.7c0.2,2.3,0.4,4.6,0.6,6.9c0.1,0.8,0.2,1.7,0.4,2.6
			c0.5,0.1,0.9,0.1,1.3,0.1c0.6,0,1.2,0,1.9-0.1c0.1-3.9-0.2-7.6-1.5-11.3C615.6,486.5,614.8,486.4,614,486.8z M751.4,511.4
			c0-0.6,0-1.1,0-1.5c-0.2-3.2-0.5-6.5-0.6-9.7c-0.2-4.5-0.6-9.1-0.5-13.6c0-0.4,0-0.9-0.1-1.3c0-0.4-0.1-0.7-0.2-1.1
			c-0.4,0-0.6,0-0.9-0.1c-0.1,0.2-0.2,0.4-0.2,0.6c0,2.7-0.1,5.4,0,8.1c0.3,5.7,0.6,11.4,1,17.1c0,0.5,0.1,1,0.2,1.5
			c0,0.1,0.2,0.2,0.3,0.3C750.7,511.5,751.1,511.5,751.4,511.4z M707.3,514.4c0.6,1.7,0.6,1.7,0.5,3.3c0.8,0.1,9.5-0.1,10.7-0.2
			c0.3,0,0.7-0.2,1.3-0.3c-0.7-0.9-0.1-1.9-0.6-2.8c-1.3-0.5-2.6,0.2-3.8,0c-1.3-0.2-2.7,0-4,0C710,514.4,708.7,514.4,707.3,514.4z
			 M780.6,515c-0.7-7.9-0.3-15.8-0.3-23.6c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.1-0.2-0.2c-0.5-0.1-1.2-0.2-1.7,0.1
			c-0.2,7.9,1,15.7,0.9,23.6c0,0.1,0,0.1,0.1,0.2c0,0,0.1,0.1,0.2,0.2C779.8,515,780.2,515,780.6,515z M606.2,489.3
			c-0.2,2.5,0.2,7.6,0.7,9.3c1.7,0.3,2.1,0.3,3.3-0.2c0-2.3-0.4-7.8-0.7-9C608.5,489.1,607.4,489.1,606.2,489.3z M757.3,501.1
			c-1.3,0.4-1.7,1.2-1.7,2.3c0.1,1.5,0.1,3.1,0.2,4.6c0.1,2,0.2,4,0.3,6.1c0,0.3,0.1,0.6,0.2,0.9c0.4,0.2,0.8,0.3,1.1,0.4
			c1.1-0.4,1.2-1.1,1.1-2c-0.1-3.3-0.1-6.6-0.2-9.9c0-0.7-0.2-1.3-0.3-1.9C757.8,501.3,757.6,501.2,757.3,501.1z M764.3,488.8
			c-1,0.1-1.7,0.2-2.7,0.3c0.9,5.4,0.7,10.8,1.2,16.3c0.7-0.3,1.2-0.6,1.8-0.8C764.5,499.3,764.4,494.1,764.3,488.8z M688,503.4
			c-0.1,0.3-0.2,0.4-0.1,0.6c0,0.2,0.1,0.4,0.1,0.7c0.7,0.2,1.4,0,2-0.1c1.5-0.4,3.1-0.5,4.7-0.5c2.7,0,5.3-0.1,8-0.1
			c2.8,0,5.6-0.3,8.4,0c0.5,0.1,0.9-0.1,1.5-0.1c0-0.4,0-0.6,0-0.8c-0.6-0.6-1.2-0.5-1.8-0.5c-1.2,0-2.4,0-3.6-0.1
			c-3.2-0.2-6.4,0-9.6,0.3c-1.5,0.1-3,0.2-4.5,0.2C691.4,502.9,689.7,503.2,688,503.4z M694.8,507.1c1.7,0.1,3.2,0.3,4.8,0.3
			c1.6,0,3.2,0,4.7,0c1.5-0.1,3.1,0.2,4.7-0.3c0-0.8-0.1-1.5-0.4-2.2c-1.7,0.1-3.2,0.1-4.8,0.2c-1.5,0.1-3,0.1-4.6,0.2
			c-1.5,0.1-3.1-0.2-4.8,0.2C694.5,506,694.6,506.5,694.8,507.1z M745.7,502c0.1-0.3,0.2-0.4,0.2-0.5c0-3.3-0.1-6.6-0.2-9.9
			c0-0.2-0.1-0.4-0.2-0.7c-0.9-0.1-1.7-0.4-2.5-0.1c-0.2,1.8,0.1,9.8,0.4,11.3C744.1,502.2,744.9,502.3,745.7,502z M618.7,527.2
			c0.2-0.4,0.4-0.8,0.4-1.2c0.1-1.4,0.2-2.8,0.2-4.2c-0.1-2.6-0.3-5.1-0.5-7.7c0-0.2-0.1-0.3-0.2-0.5c-0.6-0.1-1.1-0.3-1.6-0.4
			c-0.3-0.1-0.6-0.1-0.9,0.2c0,0.3,0,0.6,0.1,1c0.5,2.9,0.8,5.8,0.9,8.7c0,0.7,0.1,1.5,0.3,2.2C617.6,526.1,617.8,526.8,618.7,527.2
			z M612.1,522.8c-0.2-2.9-0.4-5.6-0.8-8.4c-1.2-0.2-2.2-0.2-3.3,0c0.2,2.8,0.5,5.6,0.7,8.3C609.7,523.2,610.8,522.9,612.1,522.8z
			 M712,486.4c-0.8,0.8-1.6,1.3-2.2,2.1c0.6,0.7,1.2,1.3,1.8,1.9c0.6,0.6,1.1,1.3,1.7,1.9c0.5,0.7,1,1.4,1.6,2
			c0.5,0.7,0.9,1.4,1.7,1.9c0.6-0.4,1.1-0.8,1.6-1.1C716.4,492.1,714.6,489.1,712,486.4z M775.8,510.9c-0.1-1.1-0.2-2.1-0.2-3.1
			c0-3.3,0-6.6,0-9.9c0-1,0-2.1,0-3.1c0-0.2-0.1-0.3-0.2-0.5c-0.2,0-0.5-0.1-0.7,0c-0.2,0-0.4,0.1-0.7,0.2c0,0.3-0.1,0.6-0.1,0.9
			c-0.2,5.1,0.2,10.1,0.4,15.2c0,0.2,0.2,0.4,0.3,0.7C775,511.1,775.3,511,775.8,510.9z M723.3,508.7c-1.5-2.5-2.7-5-4.5-7
			c-1.7,0.2-3.3,0.4-4.8,0.6c-0.3,0.6-0.3,1,0.1,1.5c1,0.1,2.2-0.3,3.1,0.7c0.1,0.8,0.3,1.6,0.4,2.5c0.8,0.1,1.5,0.2,2.3,0.2
			c0.3,0.5,0.5,1,0.7,1.5C721.4,508.7,722.2,508.7,723.3,508.7z M726.6,511.3c0,0.3,0,0.3,0,0.4c1.6,2.9,3.3,5.8,5,8.7
			c0.3,0.4,0.8,0.8,1.2,1.1c0.1,0.1,0.4,0,0.5,0c0.5-0.2,1-0.4,1.3-1c-0.1-0.2-0.2-0.4-0.4-0.6c-1-1.1-1.8-2.4-2.6-3.8
			c-0.8-1.5-1.7-3-2.6-4.4c-0.3-0.5-0.6-0.9-1-1.4C727.5,510.7,727.1,511,726.6,511.3z M682,513.9c2.4,0.6,4.8,0.4,7.1-0.1
			c0-1.2-0.1-2.4-0.1-3.5c-1.3-0.3-4.9-0.1-7.3,0.5C681.7,511.8,681.7,512.8,682,513.9z M605.8,480.9c-0.3,1.8,0,5.8,0.6,7.2
			c1-0.1,2.1,0.1,3.2-0.4c0.2-1.2,0-2.3-0.1-3.5c-0.2-1.1,0.2-2.3-0.5-3.5C607.9,480.6,606.8,480.6,605.8,480.9z M656.8,486.3
			c1.4,0.1,2.5,0.2,3.6,0.3c1.8-1.8,3.1-4.1,4.8-6.1c0.1-0.1,0-0.2,0-0.5c-0.6,0-1.2,0-1.8,0c-0.6-0.1-1.2-0.2-1.8-0.3
			C660.1,481.8,658.5,483.9,656.8,486.3z M769.7,505c0.6-5.6,0.2-11.1,0-16.6c0-0.4-0.1-0.8-0.2-1.3c-0.4,0-0.7,0-1,0
			c-0.1,0.3-0.2,0.5-0.2,0.7c0.2,5.6,0.4,11.1,0.6,16.7c0,0.2,0.1,0.3,0.2,0.5C769.3,505,769.4,505,769.7,505z M644.4,517.4
			c0.2-4.6,0-7.9-0.6-9.3c-0.9-0.2-1.1-0.2-1.4,0.1c-0.4,0.3-0.6,0.7-0.7,1.2c0,0.2,0,0.5,0,0.7c0.1,2.1,0.3,4.1,0.4,6.2
			c0,0.3,0.1,0.6,0.2,1c0.4,0.2,0.8,0.4,1.3,0.7C644,517.8,644.3,517.6,644.4,517.4z M649.6,493.8c-0.3,1.5-0.3,10.3,0,14
			c0,0.2,0.1,0.4,0.2,0.8c0.4-0.1,0.7-0.2,1-0.3c0-2.4,0.1-4.7,0-7.1c-0.1-2.4,0.2-4.8,0-7.2C650.4,493.9,650,493.9,649.6,493.8z
			 M629.1,480c0.1,1.3,0.3,2.4,0.6,3.4c0.6,0,1.1-0.1,1.5-0.1c0.8,0.5,0.8,1.5,1.7,1.9c0.6-0.7,1.1-1.4,1.7-2.1
			c-0.1-1,0-2.1-0.2-3.2c-1,0-2-0.1-3-0.1C630.7,479.8,630,479.9,629.1,480z M770.7,521.3c-0.2-2.5-0.5-4.9-0.7-7.4
			c-0.2-2.5-0.3-5-0.4-7.5c-0.3,0-0.4,0-0.6,0c-0.6,2.4-0.5,12.4,0.2,15C769.7,521.7,770.1,521.6,770.7,521.3z M745.8,472.6
			c0.8,1.4,1.9,2,2.7,2.9c0.8,0.9,1.6,1.9,2.4,2.8c0.1,2.3,0.2,4.5,0.3,6.8c0,0.4,0,0.9,0.1,1.3c0.1,0.3,0.2,0.6,0.7,0.3
			c0-2.3-0.3-4.6-0.6-6.9c-0.3-2.3-0.1-4.6-0.7-6.9C749.1,472.6,747.6,472.7,745.8,472.6z M764.3,487c-0.1-2-0.2-4-0.3-6
			c-0.3-0.3-0.4-0.5-0.6-0.6c-0.2-0.1-0.4-0.2-0.6-0.3c-1,0.5-1,0.5-1.6,1.7c0.1,0.9,0.1,1.9,0.2,3c0.1,1.1,0,2.2,0.4,3.2
			C762.9,488.4,763.6,488,764.3,487z M722.2,510c-2.3-0.2-4.5-0.1-6,0.2c0.3,0.5,0.6,0.9,0.8,1.4c0.2,0.5,0.3,1,0.5,1.5
			c2.2,0.3,3.9,0.2,5-0.2C722.6,511.9,722.6,511,722.2,510z M706.9,516.7c0.1-0.9-0.5-1.3-1.1-1.5c-1.2-0.5-2.5-0.5-3.7-0.4
			c-1,0.1-1.9,0.5-2.1,1.8c0.3,0.3,0.6,0.6,0.9,0.8c1.4,0.7,2.9,0.8,4.4,0.6C706,517.8,706.6,517.5,706.9,516.7z M765.2,515.7
			c-0.1-3.2-0.2-6.2-0.4-9.3c0-0.2-0.2-0.4-0.3-0.7c-0.4,0.3-0.7,0.4-0.9,0.7c-0.5,0.7-0.7,1.5-0.6,2.4c0.1,1.4,0.2,2.8,0.2,4.2
			c0,0.7,0.1,1.5,0.2,2.2c0,0.2,0.1,0.3,0.2,0.6c0.2,0.1,0.5,0.2,0.8,0.4C764.6,516,764.8,515.9,765.2,515.7z M636.4,521.6
			c-0.1-1.4-0.1-2.8-0.2-4.2c0-0.1-0.1-0.2-0.3-0.3c-0.4,0.5-0.9,0.9-1.4,1.4c-0.3-0.1-0.6-0.1-0.9-0.2c-0.3-0.5-0.6-1-0.9-1.6
			c-0.4,1.2-0.3,3.7,0,4.2c0.4,0.2,0.9,0.4,1.1,0.5c0.6,0.6,0.4,1.4,1,1.9C635.6,523,635.9,522.2,636.4,521.6z M776.6,521.8
			c0-0.4,0-0.7,0-1c-0.1-1.6-0.2-3.3-0.4-4.9c-0.1-1.1-0.2-2.2-0.3-3.3c0-0.2-0.1-0.3-0.3-0.6c-0.4,0.1-0.8,0.1-1.1,0.2
			c-0.3,2.8-0.1,8.1,0.3,9.4C775.3,522.1,775.9,522.2,776.6,521.8z M634.6,488.8c0.1-1.2,0.2-2.5-0.2-3.8c-0.8,0.4-1,1.5-2,1.3
			c-0.8-0.3-0.9-1.2-1.4-1.8c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2,0.1-0.4,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.4,0.4c0,1.2,0.2,2.4,0.4,3.6
			c0.6-0.1,1-0.1,1.6-0.2c0.4,0.7,0.8,1.4,1.2,2.1C633.8,489.9,634.2,489.4,634.6,488.8z M750.3,512.6c-0.4,1.2,0,2.2,0.1,3.2
			c0.1,1.1,0.3,2.2,0.4,3.3c0.2,1-0.1,2.1,0.3,3.1c0.6,0.2,1.2,0.3,1.7-0.1c-0.4-3.1-0.8-6.1-1.2-9.1c0-0.1-0.1-0.1-0.1-0.1
			c0,0-0.1-0.1-0.2-0.2C751.1,512.6,750.7,512.6,750.3,512.6z M644.7,500.7c1.1-1.1,2.2-2.1,3.2-3.1c0.1-2,0.1-2-0.3-3.1
			c-1.7,1.5-3.3,3-5,4.5c-0.2,0.2-0.4,0.4-0.6,0.6c0,0,0,0.1,0,0.3C642.8,500.3,643.7,500.4,644.7,500.7z M659.1,492.7
			c0.2-1.6,0.2-3.2-0.1-4.7c-1.8-0.3-2-0.3-3.5,0.5c0.1,0.7,0.2,1.4,0.3,2.1c0.1,0.7,0.1,1.5,0.5,2.1
			C657.2,492.9,658.1,492.9,659.1,492.7z M755.6,500.7c0.9-0.3,1.6-0.5,2.4-0.7c-0.1-1.1-0.1-2-0.2-3c-0.1-0.9,0.1-1.9-0.4-2.9
			c-0.9,0.2-1.7,0.3-2.5,0.9C755.1,496.9,755.4,498.7,755.6,500.7z M645,525.9c0.4-2.5,0-4.7-0.3-7c-0.7,0-1.4,0-2,0
			c-0.1,1.5,0.2,5.7,0.6,7C643.7,525.9,644.2,525.9,645,525.9z M639.7,474.9c-0.1-0.1-0.1-0.2-0.2-0.2c-1.1,0-2.3,0.1-3.4,0.2
			c-1.3,1.3-2.9,2.1-4.4,3c-0.2,0.1-0.6,0.3-0.4,0.7c1.4,0,2.8,0,4.1,0C637,477.4,638.7,476.5,639.7,474.9z M719.6,501
			c1.1,1.6,2.2,3.3,3.2,4.8c1.1-0.2,1.1-0.2,1.7-1.1c-0.7-2.1-2.2-4.3-3.4-5.2C720.4,499.6,720,500.1,719.6,501z M742.8,473.8
			c0.2,0.7,0.8,0.9,1.2,1.3c0.5,0.4,1.1,0.7,1.7,1.1c0.1,0.4,0.2,0.7,0.3,1.2c0.6,0.6,1.3,1.3,2,2c0.7-0.4,1.4-0.5,1.9-1
			c-0.8-1.3-3.2-3.8-4.5-4.7c-0.5,0-1,0-1.5,0C743.6,473.6,743.2,473.7,742.8,473.8z M758.9,521.9c0.3-1.7,0-4.3-0.6-5.6
			c-0.7,0-1.3,0-2.1,0c-0.1,1.8,0,3.6,0.2,5.4C757.3,522,758,522,758.9,521.9z M644,506.8c-0.1-1.4-0.2-2.7-0.3-3.9
			c0-0.3-0.1-0.6-0.1-0.9c-0.8-0.5-1.7-0.7-2.6-0.7c-0.1,1.3,0.3,4.4,0.8,6.1C642.4,507.2,643.1,507,644,506.8z M649,485.7
			c-0.1,1.3,0.3,5.8,0.5,6.8c0.4,0.3,0.8,0.5,1.2,0.1c0.3-1.8,0.3-5.6-0.1-7C649.9,485.3,649.9,485.3,649,485.7z M716.2,505.1
			c-0.2-0.1-0.3-0.2-0.4-0.2c-1.9,0-3.9,0.1-5.8,0.1c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.7,0.2,1.3,0.4,1.9c1.8,0,3.6,0,5.3,0
			c0.2,0,0.4,0,0.5-0.1c0.1,0,0.2-0.1,0.3-0.2C716.5,506.3,716.5,505.7,716.2,505.1z M722.6,522.1c-0.1-1.4,0.2-2.5-0.4-3.7
			c-1,0.1-2-0.2-3.1,0.4c0.1,1.1-0.1,2.2,0.3,3.4c0.4,0,0.7,0.1,1.1,0C721.1,522.3,721.8,522.2,722.6,522.1z M723.7,509.5
			c-0.1,1.5,0.3,2.9-0.3,4.3c-0.7,0.5-1.6,0.4-2.3,0.6c-0.2,0-0.3,0.3-0.7,0.6c1,0,1.6,0,2.3,0c0.7,0,1.5,0,2.2,0
			c0.6,0,1.3,0.1,2-0.2C726,512.8,725,511.1,723.7,509.5z M612.1,527.8c0.2-1.3,0.2-2.4,0-3.5c-1.4-0.4-2-0.4-3.3-0.1
			c0.1,1.1,0,2.3,0.4,3.4C610.1,528.1,611,528.1,612.1,527.8z M687,519.9c-1.2-0.1-2.1-0.2-3.1,0.1c0.1,1.2,0.1,2.4,0.4,3.6
			c1.6,0.3,1.6,0.3,2.9-0.1C687.4,522.3,687.1,521.2,687,519.9z M651.4,518.5c0-0.6,0-0.9,0-1.2c-0.1-2-0.2-4-0.2-6
			c0-0.5-0.1-1-0.2-1.4c0-0.1-0.1-0.2-0.3-0.4c-0.3,0.1-0.6,0.2-0.8,0.3c-0.2,1.1-0.1,5.4,0.1,7.2c0,0.4,0.2,0.8,0.3,1.2
			C650.6,518.3,650.9,518.4,651.4,518.5z M779.9,484.5c-1,0-1,0-1.8,0.4c-0.2,1-0.1,3.4,0.3,5.1c0.6,0.2,1.2,0.2,1.8-0.1
			C780.5,487.9,780.4,486.3,779.9,484.5z M757.1,493c-0.9-0.7-1.6-1.2-2.2-1.7c-0.6-0.5-1.1-1.1-1.7-1.7c-0.5-0.5-1-1.2-1.8-1.5
			c-0.3,0.7-0.2,1.4-0.1,2.2c1.1,1,2,2.4,3.3,3.5C755.4,493.5,756.1,493.3,757.1,493z M702.7,508.7c-3-0.1-6.1-0.2-9.1-0.6
			c-1.2-0.1-8.2,0.3-9.4,0.6c0,0.1-0.1,0.2-0.1,0.3c0,0,0.1,0.1,0.1,0.1C690.3,509,696.5,508.8,702.7,508.7z M693.6,505.2
			c-1,0.1-1.8,0.2-2.5,0.3c-0.8,0.1-1.6,0.2-2.3,0.3c-0.8,0.1-1.6,0-2.4,0.4c0,0.4,0,0.7-0.1,1.3c2.6-0.1,5-0.5,7.6-0.7
			C693.7,506.3,693.6,505.8,693.6,505.2z M624.2,493.9c0.1-2.2-0.4-6.7-0.9-8.3c-0.4,0.1-0.7,0.1-1.1,0.2c0,1.6,0.6,6.7,1.1,8.5
			C623.7,494.1,624,494,624.2,493.9z M623.7,507.4c0.7-0.2,1.3-0.4,1.7-1c0-1.6,0.1-3.1-0.1-4.7c-0.5-0.5-0.9-1.1-1.6-1.3
			C623.4,501.6,623.4,504.9,623.7,507.4z M648.1,479.4c0.1,1,0.2,1.8,0.3,2.6c0.1,0.9,0,1.8,0.5,2.6c0.6-0.1,1.1-0.2,1.6-0.3
			c0.2-1.4-0.1-3.5-0.6-4.9C649.4,479.4,648.9,479.4,648.1,479.4z M774.7,486.8c-0.2-0.4-0.3-0.6-0.5-0.8c-1-1.1-2-2.2-3.1-3.3
			c-0.1-0.1-0.3-0.1-0.5-0.1c0,1.1-0.1,2.1,0.2,3.1c0.7,0.6,1.4,1.1,2.1,1.7C773.5,487.3,774,487.1,774.7,486.8z M768,477.6
			c0,0.5,0,1.1,0,1.6c0.1,1.5,0.2,2.9,0.3,4.4c0,0.7,0.1,1.3,0.1,2c0,0.1,0.1,0.2,0.3,0.3c0.2,0,0.5-0.1,0.8-0.1
			c0-2.5-0.1-4.9-0.1-7.3c0-0.2,0-0.5-0.1-0.7c-0.1-0.3-0.3-0.5-0.4-0.7c-0.2,0-0.4,0-0.5,0.1C768.3,477.3,768.2,477.5,768,477.6z
			 M651.9,525.5c0.2-2.1,0.1-4-0.4-6c-0.4,0-0.8,0-1.1-0.1c-0.1,0.2-0.2,0.3-0.2,0.4c0,1.8,0,3.6,0.1,5.5c0,0.1,0.1,0.2,0.3,0.3
			C650.9,525.6,651.3,525.6,651.9,525.5z M615.8,485.3c0.6-0.2,1-0.2,1.3-0.4c1.5-0.9,3.1-1.7,4.5-2.7c1.2-0.8,2.4-1.7,3.6-2.9
			c-0.5,0-0.8-0.1-1.1,0c-0.6,0.3-1.2,0.6-1.8,0.9c-2,1.4-4.1,2.9-6.1,4.3C616.2,484.7,616.2,484.8,615.8,485.3z M655.7,473.2
			c-0.7-0.2-1.1-0.1-1.5,0.1c-0.5,0.4-1,0.8-1.4,1.2c-0.9,0.9-1.7,1.8-2.5,2.6c-0.2,0.2-0.5,0.4-0.3,0.8c0.6,0,1.1,0.1,1.7,0.1
			C653.1,476.6,654.4,475.1,655.7,473.2z M763.4,517c0.3,1.6,0,3.1,0.4,4.6c0.7,0.1,1.3,0.1,2-0.4c-0.2-1.3-0.3-2.6-0.5-4
			c0-0.1-0.1-0.2-0.2-0.3C764.7,517,764.2,517,763.4,517z M761.1,479.9c0.6-0.2,1-0.4,1.4-0.6c0.4-0.2,0.9,0.3,1.4-0.1
			c0.1-1,0.1-2-0.3-3.1c-0.8,0.3-1.7,0.1-2.5,0.6C760.8,477.7,760.9,478.7,761.1,479.9z M773.9,493.2c0.6,0,1.1-0.1,1.5-0.1
			c0.3-1.8,0.3-3.7-0.1-5.1c-0.6-0.2-1,0.1-1.4,0.4C773.9,490,773.9,491.5,773.9,493.2z M725.3,505.6c-0.7,0.5-1.2,0.8-1.9,1.2
			c1,1.3,1.6,2.6,2.7,3.6c0.7-0.2,1.2-0.5,1.3-1.3C726.7,508.1,726,507,725.3,505.6z M626.1,514.8c-0.5-0.4-1-0.8-1.5-1.1
			c-0.1-0.1-0.3-0.1-0.5-0.2c-0.3,1.5-0.1,3.7,0.5,5.3c0.6-0.5,1.1-0.9,1.7-1.5C626.2,516.5,626.1,515.7,626.1,514.8z M779,483.3
			c-0.9-1.3-3.2-3.4-4.9-4.5c-0.3,0.1-0.8,0.1-1.2,0.2c0,0,0,0.1,0,0.2c1.5,1.3,2.9,2.6,4.5,4C777.9,483.3,778.4,483.3,779,483.3z
			 M760.2,475.5c1-0.2,2-0.2,3.1-0.6c-0.5-1-1.3-1.4-2.1-1.9c-0.6,0-1.2,0-1.8,0c-0.9,0-0.9,0-1.7,0.3
			C758.6,474.1,759.4,474.8,760.2,475.5z M781.5,520.8c-0.5-1.7-0.2-3.3-0.9-4.7c-0.4,0-0.8-0.1-1.3-0.1c0,1.7-0.5,3.4,0.4,5
			C780.3,520.9,780.7,520.9,781.5,520.8z M740.3,474c-1,0.2-2,0.4-3.1,0.6c0.5,0.6,1.1,0.8,1.6,1.1c0.5,0.3,1.1,0.6,1.5,0.8
			c0.9-0.2,1.7-0.5,2.7-0.7C742.2,474.8,741.3,474.4,740.3,474z M633.1,522.2c-0.2,0.2-0.5,0.3-0.5,0.4c-0.1,1.1-0.1,2.2,0.2,3.3
			c1.3,0,2.4,0,3.5,0c0.3-0.7,0.2-1.3-0.1-2c-0.8,0.5-1.6,0.9-2.4,0.1C633.6,523.4,633.3,522.8,633.1,522.2z M763,473.1
			c0.8,0.9,1.5,1.8,2.4,2.9c0.4,0.2,1.2,0.4,1.8,0.7c0.6-0.2,1.1-0.4,1.3-1c-1.4-1.4-3.5-1.5-5-2.6C763.4,473,763.2,473.1,763,473.1
			z M627.1,525c-0.8-0.5-1.5-0.9-2.2-1.4c-0.4,1.5-0.4,2,0,3.5c0.7,0.2,1.4,0.1,2.2-0.1C627.1,526.4,627.1,525.8,627.1,525z
			 M719.1,508.4c-1.1-0.4-2.3-0.2-3.4-0.3c-1.2-0.1-2.4,0-3.7,0c-1.1,0-2.2,0.3-3.6,0.5C713.5,509,717.4,508.9,719.1,508.4z
			 M720.6,498.4c-0.6-0.9-1-1.6-1.8-2c-0.8,0-1.4,0.2-1.6,1.1c0.6,0.7,1.2,1.5,1.8,2.3C719.5,499.3,720,498.9,720.6,498.4z
			 M659.8,518.2c-0.8-0.2-1.5-0.1-2.2,0.3c0,0.8-0.1,1.6,0.2,2.3c1.2,0.4,1.2,0.4,2.2-0.1C660,519.9,660.1,519,659.8,518.2z
			 M657.9,524.3c0.6,0.4,1.2,0.7,2,0.5c0.1-0.9,0.2-1.8-0.1-2.8c-0.7-0.1-1.4-0.3-2.1,0.1C657.6,522.9,657.5,523.6,657.9,524.3z
			 M626.3,518.5c-0.9,1-1.5,1.7-1.6,2.8c0.3,1,1,1.6,2.1,2C626.9,521.7,626.7,520.3,626.3,518.5z M690.2,510.3
			c-0.6,0.9-0.8,1.8-0.1,2.7c0.4,0.6,0.9,1,1.6,0.6c0-1.1,0.1-2.2,0.1-3.4C691.3,510.3,690.8,510.3,690.2,510.3z M625.7,512.8
			c0.1-2.5,0-3.5-0.5-4.7c-0.9,0.5-1.1,0.9-1.2,1.8C623.9,511,624.3,511.8,625.7,512.8z M625,499.7c0.2-1.6,0-3.1-0.5-4.7
			c-0.9,0.2-1,0.9-1,1.6C623.5,498.1,623.9,498.9,625,499.7z M713.6,510c0.5,1.2,1,2.1,1.5,3.2c0.4,0,0.8-0.1,1.3-0.2
			c0.1-1.5-0.9-2.2-1.7-3C714.2,510,714,510,713.6,510z M687.9,519.9c0.2,1.3,0.1,2.5,0.6,3.6c0.3,0,0.5,0,0.8-0.1
			c0.2-1.2,0-2.3-0.2-3.5C688.8,519.9,688.4,519.9,687.9,519.9z M750,479.9c-0.8-0.3-1.1,0-1.2,0.5c0.1,0.9,0.2,1.8,0.3,2.9
			c0.4-0.1,0.7-0.1,0.9-0.2C750,481.9,750,480.9,750,479.9z M656.4,493.9c0.1,0.4,0.2,0.8,0.2,1.2c0.9-0.1,1.8,0.2,2.6-0.3
			c-0.1-0.3-0.1-0.7-0.2-1C658.1,493.8,657.3,493.6,656.4,493.9z M739.9,472.3c0.1,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.2,0.2,0.3,0.2
			c1.1,0,2.2,0.1,3.3,0.1c0.2,0,0.5,0.1,0.5-0.4C742.8,472.2,741.4,472.2,739.9,472.3z M612.6,478.4c-1.4-0.5-2.5,0.2-3.9,0.8
			c0.5,0.2,0.8,0.4,1.1,0.5C610.7,479.2,611.6,478.8,612.6,478.4z M717.4,518.9c0,2.4,0,2.5,0.6,3.4c0.4-1.3,0.4-3.1,0-3.4
			C717.8,518.8,717.7,518.8,717.4,518.9z M626,482.1c1.3-0.8,1.3-0.8,1.9-1.8C627,480.7,626.2,481,626,482.1z"/>
                            <path d="M644.1,391.6c0.5,0,1.1,0,1.6,0.1c1,0.2,1.8,0.7,2.4,1.5c0.9,1.3,0.7,2.8-0.5,3.7c-0.9,0.7-2,1-3.1,0.8
			c-1.6-0.2-2.7-1.6-2.5-3.2C642,393.3,642.5,392.6,644.1,391.6z M647.3,395.3c0.2-1.3-0.7-1.8-1.7-2.3c-0.6,0-1.3,0-2.1,0
			c-0.2,0.4-0.5,0.9-0.5,1.3c-0.3,1.4,0.7,2.4,2.1,2.3C646,396.6,646.8,396.3,647.3,395.3z"/>
                            <path d="M664.8,397.6c-0.2,0-0.8-0.1-1.2-0.3c-1.3-0.5-1.8-1.9-1.4-3.2c0.2-0.5,0.5-1,0.8-1.4c1-1.2,2.2-1.4,3.6-0.7
			c0.5,0.2,0.9,0.6,1.3,0.9c1.3,1.3,1,3.1-0.5,4C666.7,397.4,666,397.6,664.8,397.6z M665,392.6c-1.4,0.8-1.4,0.8-1.8,2.4
			c0.3,1.1,1.1,1.4,2.1,1.3c1-0.1,1.9-0.4,2.1-1.6C667,393.5,666.1,393,665,392.6z"/>
                            <path d="M655.1,397.2c-0.2,0-0.6,0-0.9-0.1c-1.6-0.4-2.4-1.8-2.1-3.3c0.3-1.6,1.9-2.8,3.3-2.4c0.6,0.2,1.3,0.3,1.9,0.6
			c1.8,1,1.9,3.3,0.2,4.6C656.8,396.9,656.1,397.2,655.1,397.2z M657.5,394.2c-0.1-0.8-0.5-1.2-1.1-1.5c-0.7-0.4-1.5-0.6-2.3-0.3
			c-0.1,0.1-0.3,0.3-0.5,0.4c-0.1,0.1-0.2,0.3-0.3,0.5c-0.5,1.1,0,2.2,1.2,2.6c0.6,0.2,1.2,0.1,1.8-0.1
			C656.9,395.4,657.3,395,657.5,394.2z"/>
                            <path d="M635.7,391.2c0.8-0.1,1.4,0.1,2,0.6c1,0.9,1.4,2.3,0.7,3.5c-0.3,0.5-0.7,1-1.2,1.3c-1.2,0.9-2.8,0.8-3.7,0
			c-1.1-1-1.3-2.8-0.6-4C633.5,391.5,634.5,391.1,635.7,391.2z M635,391.8c-0.5,0.4-0.8,0.6-1.1,0.9c-0.7,1-0.5,2.4,0.4,3.1
			c0.8,0.6,2.1,0.3,2.8-0.7c0.7-0.9,0.5-1.9-0.4-2.5C636.2,392.3,635.6,392.1,635,391.8z"/>
                            <path d="M672.9,392.1c1-0.5,1.7-0.5,2.5-0.2c1,0.3,1.6,1,1.9,2c0.4,1.3-0.2,2.3-1.4,2.8c-1.3,0.5-2.5,0.3-3.7-0.4
			c-1-0.6-1.2-1.7-0.6-2.7C672,393,672.5,392.6,672.9,392.1z M676.1,395.1c0.2-0.6,0-1.1-0.4-1.5c-0.5-0.5-1.1-0.7-1.8-0.7
			c-0.8,0.4-1.4,0.9-1.5,1.8c0.3,0.8,0.9,1,1.6,1C674.8,395.8,675.5,395.8,676.1,395.1z"/>
                            <path d="M626.1,390.9c0.8-0.3,1.6-0.2,2.3,0.2c0.8,0.5,1.2,1.2,1.1,2.1c-0.1,0.9-0.5,1.6-1.1,2.2c-0.8,0.7-1.8,0.9-2.7,0.5
			c-0.9-0.5-1.4-1.6-1.3-2.6C624.5,392.3,625.1,391.5,626.1,390.9z M628.4,392.8c-0.9-0.9-0.9-0.9-2.3-0.8c-0.5,0.3-0.7,0.8-0.8,1.4
			c-0.1,0.9,0.2,1.6,0.9,1.8c0.6,0.2,1.2-0.1,1.7-0.7C628.3,394,628.5,393.5,628.4,392.8z"/>
                            <path className="st13" d="M686.9,456.5c-0.8,0.3-1.5,0.3-2.3,0.1c-0.1-0.5-0.2-1.1-0.3-1.6c-0.2-1.6-0.5-3.2-0.7-4.7
			c-0.2-1.6-0.4-3.3-0.5-4.9c-0.2-3.7-0.6-7.5-1-11.2c-0.3-3-0.5-6-0.5-9c-0.1-2.5-0.3-4.9-1-7.3c-0.3-1-0.3-2.1-0.4-3.1
			c-0.1-0.6-0.1-1.1-0.9-1.4c-1.1,0.4-2.6,0.1-3.8,1.1c0,0.4-0.1,0.8-0.1,1.2c0.4,3.9,0.6,7.8,0.6,11.8c0.1,5.4,0.2,10.8,0.3,16.2
			c0,2,0.5,4,0.9,6c0.3,1.7,0.6,3.4,0.8,5.1c0.1,0.6,0,1.2,0,1.8c-0.5,0.5-1,0.4-1.6,0.1c-0.4-1.6-0.6-3.3-0.7-5
			c-0.2-3.4-0.2-6.7-0.3-10.1c-0.1-3.3-0.1-6.5-0.2-9.8c0-1.7-0.1-3.4-0.3-5.1c-0.1-2-0.3-3.9-0.5-5.9c-0.1-1.2-0.3-2.3-0.5-3.5
			c-0.1-1-0.3-1.9-0.4-2.9c-1.2-0.6-2.5-0.6-5.3,0.1c-0.5,0.5-0.6,1.1-0.5,1.8c0.2,1.2,0.4,2.4,0.5,3.6c0.5,6.6,0.7,13.2,0.4,19.9
			c-0.1,3.1,0,6.1,0.3,9.2c0.3,2.5,0.6,5,0.8,7.5c0,0.1,0,0.2-0.1,0.4c-0.5,0.4-1.1,0.2-1.8,0.2c-0.5-1.1-0.5-2.2-0.5-3.2
			c0-1.8,0.1-3.7,0-5.5c-0.1-3.9-0.1-7.7-0.3-11.6c-0.1-2.6-0.5-5.3-0.8-7.9c-0.1-1-0.2-2-1-2.8c-1.5-0.4-3.1,0.3-4.5,0.1
			c-0.7,0.4-0.7,1.1-1,1.6c-0.3,0.5-0.6,1-1.1,1.7c-0.1,1.3,0,2.8,0.2,4.4c0.6,5.6,1,11.1,1,16.7c0,1.8,0.1,3.6,0.2,5.3
			c0,0.4,0,0.7,0,1c-0.4,0.5-0.9,0.4-1.3,0.2c0-0.1-0.1-0.2-0.1-0.2c0.6-1.3,0.5-2.6,0.4-3.9c-0.4-4.9-0.7-9.8-1-14.7
			c-0.1-2-0.1-4.1-0.1-6.1c0-1.7-0.3-3.4-0.9-5.1c-0.3,0-0.7-0.1-1,0c-1,0-2.1,0.1-3.1,0.1c-0.6,0-1.3-0.3-2.1-0.6
			c-0.3,0.1-0.8,0.2-1.1,0.3c-1.3-0.2-2.4-0.3-3.5-0.5c-1.7,1.1-2.9,2.8-4.6,3.7c-0.6-0.4-0.5-1-0.5-1.6c-0.1-2.9-0.1-5.8-0.2-8.7
			c0-1.2-0.2-2.4-0.4-3.7c0-0.2-0.2-0.4-0.3-0.6c-1.2,0-1.9,0.9-2.8,1.3c-0.9,0.4-1.8,1-2.7,1.4c-0.8-0.2-1.7-0.3-2.5-0.5
			c-0.2-0.2-0.4-0.4-0.6-0.6c-2-0.3-3.9,0.1-5.7,0.7c-1.3,0.4-2.7,0.7-4.1,1.1c-0.8,0.2-1.6,0.4-2.4,1.2c-0.1,0.5-0.3,1.1-0.5,1.7
			c-0.3,0.1-0.7,0.2-1,0.2c-1.6-0.1-3.1,0.3-4.5,1.4c-0.2-0.3-0.3-0.5-0.4-0.7c-0.2-2.5-0.6-5-1.2-7.4c0-0.1,0-0.2,0-0.4
			c0.5-0.4,1.1-0.2,1.7-0.2c4.2-0.1,8.4-0.1,12.6-0.3c4-0.2,7.9-0.5,11.8-0.9c5.8-0.6,11.6-1.1,17.5-1.1c5.9,0,11.8-0.1,17.7-0.3
			c4.9-0.1,9.9-0.4,14.8-0.6c7.1-0.2,14.1-0.6,21.2-0.4c9.1,0.3,18.3,0,27.4,0c4.7,0,9.4-0.3,14.1-0.4c0.7,0,1.5,0,2.2,0
			c0.4,1.2,0.2,2.3,0.2,3.6c-1.2-0.5-2.2-1-3.1-1.6c-2.5-0.4-4.8,0.6-7.2,0.5c-0.5,0.5-0.3,1.2-0.2,1.7c0.1,1.9,0.2,3.8,0.3,5.7
			c0,0.3,0,0.6-0.1,1c-0.4-0.1-0.7-0.1-1-0.2c-0.6-0.2-1.2-0.6-2-0.3c-0.1,0.3-0.3,0.7-0.4,1.1c-1,0.5-1.8-0.5-2.7,0
			c-0.5,0.3-0.6,0.7-0.6,1.2c0,1.2,0,2.5,0.1,3.7c0.1,1.3,0.2,2.6,0.3,3.9c0.1,0.8,0.1,1.7-0.1,2.7c-1-0.1-1.6-1.1-2.6-1.2
			c-1.5,0.9-3.2,1.1-5,0.6c-0.1-0.4-0.1-0.9-0.2-1.3c-0.3-3.6-0.3-7.2-0.1-10.9c0.1-1.3,0-2.7,0-4.1c0-0.7,0-1.5-0.6-2
			c-0.6-0.5-1.3-0.4-1.9-0.2c-1.2,0.3-2.5,0.6-4.1,0.2c-0.6-0.4-1.4-0.9-2.5-1.5c-2.5,0.1-4.9-1.1-7.5-1.9c-0.8,0-1.5,0.3-2.1,1
			c-0.6,1.4-0.4,3-0.6,4.6c-0.7,0.3-1.3,0.5-2.1,0.9c-1.7,2.8-3.6,5.6-5.1,8.6c-1,2-2.2,4-3.2,6c-0.4,0.7-0.8,1.4-1.2,2.1
			c-1.6,3.2-3.4,6.2-5.2,9.3c-1.5,2.6-2.8,5.3-4.1,8c-0.2,0.4-0.4,0.8-0.5,1.2C687.5,454,686.9,455.1,686.9,456.5z"/>
                            <path className="st14" d="M619.3,464.6c1.4-1.2,2.6-2.3,4-3.5c0.2,0.7,0.3,1.2,0.4,1.7c0.4,0.1,0.7,0.3,1.1,0.3
			c1.9,0.2,3.8,0.2,5.6-0.1c1.1-0.6,1.8-1.7,3-2.3c0.2,0.9,0.3,1.8,0.5,2.5c0.5,0.5,0.9,0.6,1.4,0.5c1.7-0.2,3.4-0.4,5.1-0.6
			c0.6-0.7,1.2-1.3,1.9-2c0.3,0.6,0.6,1,0.8,1.4c1.9,0.9,3.5,0.3,5.1-0.5c0.6-0.6,1.1-1.1,1.8-1.8c0.4,0.8,0.3,1.6,1.1,2
			c1.4,0,4.7-0.8,6.2-1.5c0.2-0.5,0.5-1,0.8-1.6c0.8,0,1.6,0,2.3,0c0.6,1,0.1,2.3,1.1,3.3c1.7,0.3,3.4,0,5.3-0.4
			c0.5-1.1,1-2.1,1.5-3.2c0.7-0.2,1.3-0.2,2,0.1c0.2,0.6,0.3,1.3,0.5,2c0.4,0.1,0.8,0.3,1.2,0.2c1.6-0.4,3.3,0.1,4.8-0.9
			c0.1-0.5,0.2-1.1,0.3-1.7c0.4,0,0.8,0,1.3,0c0.2,0.5,0.4,0.9,0.6,1.4c0.2,0.1,0.3,0.2,0.5,0.2c0.5,0,1,0.1,1.5,0
			c1.1-0.1,2.3-0.3,3.4-0.5c0.3-0.5,0.5-0.9,0.8-1.3c0.5,0,1,0,1.5,0c0.1,0.2,0.2,0.3,0.2,0.5c0.1,0.4,0.1,0.8,0.1,1.3
			c0.4,0.1,0.7,0.3,1,0.4c1.1,0.1,2.2,0.1,3.5,0.1c0.8-0.3,1-1.6,1.9-2.4c0.8-0.3,1.7-0.3,2.7-0.1c0.3,1,1,1.8,1.8,2.5
			c0.8,0.8,1.8,0.8,2.5-0.2c0.6-0.8,1.1-1.6,1.7-2.6c3.5-0.1,7.2-0.2,10.9-0.4c0.6,0.6,1.2,1.1,1.8,1.7c1.9,0.3,3.9-0.1,5.9-0.3
			c0.7-0.1,1.2-0.6,1.6-1.2c1.1,0.5,2,1.3,2.4,2.3c1.6,0.4,3.7,0.4,5.5-0.1c0.2-0.2,0.5-0.5,0.8-0.8c0.8,0.4,1.6,0.8,2.4,1.1
			c5.3,0.6,5.6,0.6,7.5-0.2c0.1-0.5,0.3-1.1,0.5-1.8c1.2,0.4,2.4,1.1,3.8,1c1.3-0.1,2.7,0.4,3.9-0.5c0-0.5,0-1.1,0-1.6
			c0.1,0,0.2-0.1,0.3-0.1c1.2,0.6,2.3,1.3,3.6,1.7c1.6,0.5,3.1,0.2,4.6-0.6c0.2-0.1,0.3-0.2,0.5-0.3c1.1,0.5,2.2,1,3.3,1.5
			c1.8-0.5,3.8-0.2,5.6-1.2c1.2,0.4,2.4,0.6,3.7,0.1c0.6-0.3,1.4-0.2,2.2-0.3c0.2-0.7,0.3-1.3,0.4-1.9c1.5-0.3,3-0.3,4.5,0.2
			c2,0.7,4,1.4,6,2.1c1.4,0.5,2.7,1.2,4,1.8c0.3,0.1,0.5,0.3,0.8,0.6c-0.7,0.5-1.4,0.6-2.1,0.6c-1.6-0.1-3.2,0-4.7,0.3
			c-1.7,0.2-3.4,0.3-5.1,0.1c-1.3-0.1-2.7-0.1-4-0.1c-9.6,0.2-19.1,0.4-28.7,0.6c-14.7,0.3-29.4,0.4-44,0
			c-6.6-0.2-13.3,0.1-19.9,0.5c-9.3,0.5-18.6,1-27.9,1.7c-7.5,0.5-15.1,0.8-22.6,1c-4,0.1-8,0.4-12,0.5c-2.4,0.1-4.8,0.5-7.3,0.6
			c-2.4,0.1-4.9,0.2-7.3,0.3c-0.6,0-1.2,0-1.8,0c0.1-0.2,0.1-0.4,0.1-0.4c1.4-0.8,2.9-1.5,4.4-2.3c0.3,0.4,0.6,0.8,0.9,1.2
			c1.5,0.3,2.8,0.1,4-0.4c0.5-0.6,0.4-1.2,0.4-1.7c-0.1-2.6-0.3-5.2-0.5-7.7c-0.3-3.3-0.6-6.6-1-9.9c-0.3-2.9-0.6-5.9-0.9-8.8
			c-0.1-1.3-0.2-2.7-0.3-4c-0.1-3.6-0.1-7.1-0.1-10.7c0-0.2,0.1-0.4,0.1-0.7c0.5-0.2,1-0.5,1.6-0.7c0.6-0.2,1.1-0.4,1.7-0.6
			c0.6,1.3,0.6,2.2,0.4,4.7c-0.6,0.3-1.2,0.7-1.7,1c-0.3,0.8-0.1,1.5,0,2.3c0.7,5.7,1.3,11.5,1.4,17.2c0,1.5,0.2,2.9,0.4,4.4
			c0.4,4.7,0.9,9.4,1.4,14.1c0.1,0.6,0,1.2,0.4,1.7C615.2,466,615.5,465.9,619.3,464.6z"/>
                            <path className="st56" d="M777,409.7c0.6-0.1,1-0.2,1.4-0.2c1.6,0.1,3.2,0.2,4.7,0.3c1.8,0.1,3.5,0.3,5.3-0.2c0.1,0,0.2,0.1,0.4,0.1
			c0.6,3,1.2,5.9,1.2,9c0,4,0,8.1,0,12.1c0,5.6-0.1,11.3,0,16.9c0,3.2,0.3,6.5,0.4,9.7c0,0.6,0.2,1.3-0.2,2.1
			c-0.3-0.2-0.6-0.3-0.8-0.4c-1.9-1.3-4-2.1-6.1-2.8c-1.5-0.5-3-1-4.5-1.5c-0.4-0.1-0.8-0.3-1.2-0.5c0-0.6-0.1-1.2-0.1-1.9
			c0-4.6,0.1-9.2,0.2-13.8c0-1,0-2.1,0-3.1c0-3.4-0.1-6.7-0.1-10.1c0-1.2,0.2-2.4,0.3-3.7c0.2-3.2,0-6.4-0.5-9.5
			C777.2,411.5,777.1,410.7,777,409.7z"/>
                            <path className="st56" d="M595,414.7c0.4,0,0.8-0.1,1.2-0.1c5.9-0.1,13.1,0.1,14.6,0.4c0.1,0.3,0.2,0.5,0.3,0.8
			c0.1,0.6,0.2,1.2,0.3,1.9c-0.6,0.1-1,0.1-1.5,0.1c-1.2,0-2.2,0.4-3.3,1c-1.1,0.7-2.4,1.2-3.7,1.8c0,0-0.1,0.3-0.1,0.5
			c0,2.7,0.1,5.4,0.1,8.1c-0.1,4,0.4,8,0.8,11.9c0.3,2.7,0.6,5.5,0.9,8.2c0.3,2.9,0.6,5.7,1,8.6c0.1,1.1,0.4,2.2,0.2,3.4
			c-1.3,0.9-2.8,1.6-4.2,2.4c-1.4,0.8-2.8,1.6-4.2,2.5c-0.4-0.2-0.3-0.5-0.3-0.8c-0.2-5.4-0.3-10.8-0.5-16.2c0-1.4-0.2-2.8-0.3-4.2
			c-0.3-3.1-0.3-6.3-0.2-9.4c0.2-6.3-0.2-12.6-1-18.9c-0.1-0.5-0.1-1.1-0.1-1.6C594.8,415,594.9,414.9,595,414.7z"/>
                            <path className="st13" d="M771.2,414.1c-0.8-1.6-2.2-2.5-3.4-3.6c0.2-0.2,0.2-0.4,0.3-0.4c3.7-0.5,6.2-0.6,7.7-0.3
			c0,0.3,0.1,0.7,0.1,1c0.4,4.6,0.6,9.2,0.3,13.8c-0.2,3.5,0,7,0.2,10.5c0.1,1.2,0.1,2.5,0.1,3.7c-0.1,4.7-0.1,9.4-0.2,14.2
			c0,0.3,0,0.6-0.1,1c-1,0.5-2.1,0.3-3.3,0.2c-0.1-0.4-0.2-0.8-0.2-1.2c-0.1-3.7-0.3-7.4-0.3-11c-0.1-5.2,0-10.3-0.4-15.4
			c-0.1-1.2,0-2.3-0.1-3.5c-0.2-2.6-0.3-5.3-0.5-7.9C771.4,414.7,771.3,414.3,771.2,414.1z"/>
                            <path className="st57" d="M714.2,456.3c-1.6-0.3-1.9-0.5-3.1-1.6c-0.1-3.5-0.2-7-0.3-10.6c0-1,0-2-0.6-3c0.7-1.3,1.4-2.6,2.2-4.1
			c-0.4-1.2-1.6-1.9-2.2-3.3c-0.1-2.1,0-4.4,0.3-7c1.6-2.8,3.4-5.8,4.6-9.1c-1.1-0.8-2-1.5-3.1-2.3c-0.3,0.3-0.7,0.6-1,0.9
			c-0.1-0.1-0.2-0.2-0.2-0.2c0.1-1,0.2-1.9,0.3-2.8c0.4-0.5,0.9-0.6,1.4-0.5c1,0.3,2.1,0.5,3,1.2c0.1,0.1,0.2,0.3,0.2,0.4
			c0.1,3.3,0.2,6.5,0.2,9.8c0.1,3.6-0.3,7.2-0.7,10.8c-0.2,2.6-0.5,5.1-0.6,7.7c-0.2,3.9-0.2,7.9-0.3,11.8
			C714.2,454.9,714.2,455.5,714.2,456.3z"/>
                            <path className="st58" d="M760.2,457.6c-1.1-0.3-1.9-0.8-2.7-1.5c-0.2-1-0.4-2.2-0.5-3.3c-0.8-6.5-1.3-12.9-1.2-19.5
			c0-4.8,0.1-9.7-0.3-14.5c-0.1-1.2-0.2-2.3-0.6-3.4c-0.5-1.2-0.6-2.6-0.4-4.1c0.4,0.1,0.7,0.2,1,0.4c0.9,0.7,1.8,1.4,2.8,2.2
			c0,0.4,0,0.8,0,1.2c-0.1,3.5,0.2,7,0.6,10.5c0.6,6.3,1.1,12.6,1,18.9c0,3.7,0.2,7.5,0.2,11.2C760.2,456.3,760.2,456.8,760.2,457.6
			z"/>
                            <path className="st59" d="M616.2,425.9c1-0.6,2-1.1,3-1.7c0.1,0,0.2,0,0.5,0c0.3,1,0.3,2,0.5,3c0.8,5.3,1.1,10.6,1.6,15.9
			c0.4,5.1,1,10.1,1.5,15.2c0,0.3,0,0.6,0.1,1.1c-0.9,1.2-2,2.3-3.5,3.3c-0.1-0.8-0.2-1.5-0.3-2.1c-0.5-6.5-1-13-1.6-19.4
			c-0.3-3.9-0.8-7.8-1.2-11.7C616.7,428.3,616.5,427.2,616.2,425.9z"/>
                            <path className="st60" d="M631.1,461c-0.1-0.7-0.2-1.1-0.3-1.5c-0.3-1.9-0.5-3.9-0.8-5.8c-0.5-2.5-0.8-5.1-1-7.6
			c-0.3-3.1-0.5-6.1-0.8-9.2c0-0.5-0.1-1.1-0.1-1.7c-0.1-3.9-0.7-7.7-1.4-11.5c-0.2-1-0.3-2-0.4-3.1c0.9-0.7,1.9-0.5,2.8-1
			c0.2,0.2,0.4,0.4,0.4,0.6c0.2,0.9,0.4,1.8,0.4,2.7c0,1.7,0.2,3.4,0.5,5.1c0.9,4.9,1.2,9.8,1.8,14.8c0.6,4.8,0.8,9.5,1,14.3
			C633.4,458.8,632.7,459.9,631.1,461z"/>
                            <path className="st61" d="M640.2,417.1c0.2,0.9,0.2,1.8,0.2,2.7c0,0.9,0,1.8,0,2.8c0,0.9,0,1.8,0,2.8c0,0.9,0.1,1.8,0.2,2.8
			c0.1,0.9,0.2,1.8,0.3,2.9c-0.2,0.5-0.6,1.1-0.9,1.8c-0.2,3-0.1,6.1,0.4,9.1c0.7,4,1,8,1.4,12.1c0.2,1.6,0.3,3.3,0.4,4.9
			c-0.6,0.7-1,1.3-1.6,2c-0.5-0.3-0.5-0.7-0.6-1.1c-0.4-2.4-0.7-4.9-0.7-7.3c0-2.5-0.4-5-0.7-7.5c-0.3-2.3-0.6-4.6-0.8-6.9
			c-0.3-3.4-0.6-6.8-0.9-10.3c-0.2-2.8-0.6-5.6-1.8-8.2C636,418.9,638.7,417.5,640.2,417.1z"/>
                            <path className="st62" d="M744.2,414.8c1.8,1.2,3.3,2.1,4.7,3c0.1,0.4,0.1,0.6,0.2,0.8c0.4,4.3,0.7,8.6,1.1,12.8
			c0.2,2.5,0.3,5,0.3,7.5c0.1,4,0.1,8,0.3,12c0.1,1.6,0.4,3.3,0.6,4.9c0,0.2,0,0.5,0.1,0.8c-1.2,0-1.8-1-2.8-1.6
			c-0.1-0.6-0.3-1.2-0.3-1.9c-0.2-3.1-0.4-6.2-0.5-9.4c-0.2-5.5-0.3-10.9-0.4-16.4c0-0.9-0.1-1.7-0.2-2.6c0-0.4-0.2-0.8-0.3-1.2
			c-0.9-0.4-1.7-0.8-2.5-1.1C744.7,420.1,744.4,417.6,744.2,414.8z"/>
                            <path className="st16" d="M768.7,457.6c-0.8-0.2-1.3-0.3-1.9-0.5c-0.1-0.6-0.3-1.1-0.3-1.6c-0.2-2.3-0.5-4.5-0.7-6.8
			c-0.3-3.1-0.6-6.1-0.9-9.2c-0.3-3.8-0.7-7.6-0.9-11.4c-0.2-3.4-0.5-6.7-1.1-10.1c-0.3-1.7-0.4-3.4-0.7-5.3c-0.4-0.6-1-1.4-1.6-2.3
			c0.5-0.1,0.8-0.2,1.1-0.2c1.4,1.4,2.7,2.8,4,4.2c0,0.1,0.1,0.3,0.1,0.5c0.1,2.5,0.3,5,0.4,7.5c0.2,3.6,0.4,7.2,0.6,10.9
			c0.2,3.5,0.2,7,0.4,10.5c0.2,2.6,0.6,5.2,0.9,7.9c0.2,1.6,0.4,3.2,0.6,4.7C768.7,456.7,768.7,457,768.7,457.6z"/>
                            <path className="st12" d="M737.7,424.1c1.4,0.1,1.6,0.2,3.1,1.7c0.6,5.2,1.6,10.5,2,15.9c0.5,5.3,0.1,10.7,0.4,16
			c-0.9,0.2-1.3-0.4-1.9-0.9c-0.2-2-0.5-4.1-0.7-6.1c-0.3-3.2-0.5-6.5-0.8-9.7c-0.2-1.9-0.5-3.8-0.7-5.5c-0.4-0.4-0.7-0.8-0.9-1.1
			c0-0.2-0.1-0.4-0.1-0.5c0.2-2.2,0-4.3-0.3-6.4C737.6,426.3,737.6,425.2,737.7,424.1z"/>
                            <path className="st21" d="M628.4,453.5c-1.4,0.6-2.7,0.5-4.1,0.5c-0.1-0.3-0.3-0.6-0.3-0.8c-0.6-6.1-1.1-12.1-1.6-18.2
			c0-0.2,0-0.5,0-0.8c1.4-0.5,2.8-0.2,4.2-0.2c0.5,1.6,0.3,3.3,0.6,4.9c0.2,1.6,0.3,3.3,0.5,4.9c0.1,1.6,0.2,3.2,0.4,4.8
			C628,450.3,628.2,451.9,628.4,453.5z"/>
                            <path className="st21" d="M755.4,450.1c-0.9,0.1-1.6,0.3-2.3,0.3c-0.3,0-0.6-0.1-0.8-0.1c-0.5-0.4-0.4-0.9-0.4-1.4c-0.1-3-0.1-6-0.1-9
			c0-4.3-0.3-8.6-0.8-12.9c-0.1-0.6-0.2-1.2,0-1.8c1.6-0.2,2.2-0.2,3.7,0.3c0,0.5,0.1,1.1,0.1,1.7c0,3.6,0.1,7.2,0.1,10.9
			c0,3.4,0.2,6.9,0.6,10.3C755.4,448.9,755.4,449.4,755.4,450.1z"/>
                            <path className="st29" d="M681.8,446.8c-1.2,0.2-2.4,0.5-3.7,0.4c-0.1-0.5-0.2-0.9-0.3-1.3c-0.1-0.9-0.2-1.7-0.2-2.6
			c-0.1-5.5-0.2-10.9-0.3-16.4c0-0.7-0.2-1.3,0.2-2c0.8-0.3,1.6-0.2,2.6-0.1c0.1,0.4,0.2,0.7,0.2,1c0,3.7,0.4,7.3,0.8,11
			c0.3,3,0.5,6.1,0.7,9.2C681.9,446.2,681.9,446.4,681.8,446.8z"/>
                            <path className="st63" d="M724.1,457.7c-1.1-0.3-1.5-1-2.3-1.3c-0.1-0.7-0.3-1.2-0.3-1.9c-0.1-1.8-0.2-3.7-0.3-5.5
			c-0.1-1.6-0.2-3.2-0.2-4.8c0-1.5-0.1-3.1,0-4.6c0.5-6.9,0.4-13.7,0.5-20.6c0-1.1-0.1-2.3-0.1-3.6c0.5,0.3,0.9,0.6,1.3,0.8
			c0,0.4,0.1,0.7,0.1,1c0.1,3.3,0.2,6.5,0.2,9.8c0,6.5,0.1,13,0.6,19.5c0.1,1.6,0.1,3.2,0.2,4.8c0.1,1.7,0.2,3.4,0.3,5.1
			C724,456.8,724,457.1,724.1,457.7z"/>
                            <path className="st64" d="M709.7,418.9c-0.3,0.5-0.5,1.1-0.7,1.5c-3,5.4-5.9,10.8-9,16.2c-2.8,5-5.6,9.9-8.4,14.8
			c-0.9,1.5-1.8,2.9-2.8,4.3c-0.1,0.2-0.3,0.3-0.4,0.5c-0.5-0.3-0.3-0.8-0.4-1c1.6-3.2,3.1-6.3,4.6-9.4c0.6-1.2,1.3-2.3,2-3.5
			c1.1-2,2.2-3.9,3.3-5.9c0.8-1.4,1.5-2.8,2.3-4.2c0.7-1.3,1.5-2.5,2.1-3.9c1.6-3.4,3.7-6.6,5.8-9.9c0.3-0.1,0.6-0.2,1-0.4
			C709.4,418.4,709.5,418.6,709.7,418.9z"/>
                            <path className="st11" d="M674.2,448.5c-1.3,0.2-2.4,0.3-3.7,0.5c-0.1-0.4-0.3-0.8-0.3-1.2c0-2.6,0-5.1,0-7.7c0-2.9,0-5.9,0-8.8
			c0-0.6,0-1.2,0-1.9c0.8-0.2,1.5-0.3,2.3-0.4c0.1,0.4,0.2,0.6,0.2,0.9c0.4,3.3,0.8,6.7,1.1,10c0.2,2.6,0.3,5.3,0.5,7.9
			C674.3,447.9,674.2,448.2,674.2,448.5z"/>
                            <path className="st63" d="M653.7,431.4c0.9-0.9,1.8-1.8,2.7-2.6c0.5,0.1,0.5,0.5,0.5,0.7c0.1,0.9,0.3,1.8,0.2,2.7
			c-0.1,3.6,0.3,7.1,0.6,10.7c0.2,2.6,0.4,5.3,0.6,7.9c0.1,1.5,0.2,2.9,0.3,4.4c-0.3,0.7-0.6,1.5-1.2,2.2c-0.1-0.3-0.2-0.5-0.3-0.7
			c-0.9-3.3-1.5-6.6-1.5-10c0-4.1-0.5-8.2-1.2-12.3C654.3,433.5,654,432.5,653.7,431.4z"/>
                            <path className="st15" d="M617.6,453c-0.4,0.7-1,1-1.6,1.2c-1.1-0.1-1.8-0.7-2.5-1.5c-0.7-3.8-1-7.5-0.9-11.3c0-2.3,1.2-3.2,3.6-2.9
			c0.5,0.4,0.5,1.1,0.5,1.7c0.3,3.3,0.5,6.6,0.8,9.9C617.4,451.1,617.5,452.1,617.6,453z"/>
                            <path className="st32" d="M760.2,425.1c0.9,0.1,1.6,0.1,2.2,0.2c0.1,0.3,0.2,0.4,0.2,0.6c0.7,8,1.1,16,2,24c0,0.2,0,0.5-0.1,0.7
			c-1.5,0.4-1.6,0.4-3.1-0.2c0-0.4-0.1-0.9-0.1-1.4c0-2.1,0-4.2,0-6.3c0.1-5-0.3-10-0.9-15C760.3,427,760.2,426.2,760.2,425.1z"/>
                            <path className="st57" d="M645.3,432.3c0.4-0.2,0.8-0.4,1.2-0.5c0.2,0.1,0.3,0.2,0.4,0.3c0.2,0.5,0.4,1,0.4,1.6
			c0.5,2.8,0.9,5.6,1.3,8.3c0.4,2.5,0.7,5,1,7.5c0.4,2.9,0.3,5.7,0.3,8.6c-0.5,0.6-0.8,1.3-1.5,1.7c-0.1-0.3-0.3-0.6-0.3-0.9
			c-0.3-2-0.6-4-0.7-6c-0.4-4.6-0.6-9.3-1.4-13.9c-0.2-1.1-0.3-2.3-0.4-3.5C645.5,434.5,645.4,433.5,645.3,432.3z"/>
                            <path className="st18" d="M649.6,439.4c1.5-0.6,2.8-1,4.3,0.3c0.1,1.2,0.2,2.4,0.3,3.7c0.1,1.1,0.1,2.2,0.1,3.3c0,2.3,0.1,4.5,0.6,6.6
			c-0.4,0.9-1.1,0.9-1.7,1.1c-0.8-0.1-1.5-0.3-2.1-1.1C651,448.8,649.8,444.2,649.6,439.4z"/>
                            <path className="st12" d="M729.4,435.5c0.8,0.3,1.4,0.9,2.1,1.4c0.4,7.2,0.8,14.4,1.3,21.7c-0.9,0.2-1.6-0.3-2.3-0.8
			c-0.6-2.4-0.6-4.9-0.8-7.4c-0.2-2.4-0.4-4.9-0.5-7.3C728.9,440.6,729.1,436.8,729.4,435.5z"/>
                            <path className="st21" d="M739.3,451.6c-2.2,2-3.5,1.9-5.8-0.5c-0.2-2.2-0.3-4.5-0.4-6.8c1-1.8,2.4-2.8,4.6-2.3
			c0.3,0.4,0.7,0.8,1.1,1.2C738.9,446,739.1,448.8,739.3,451.6z"/>
                            <path className="st14" d="M767,414.9c1,0,2,0,3.1,0c0.5,1.4,0.3,2.8,0.5,4.2c0.1,1.3,0.1,2.7,0.2,4c0,1.4,0.1,2.8,0.1,4.2
			c0,1.3,0.4,2.7,0,4.1c-0.9,0.1-1.8,0.2-2.8,0.3c-0.1-0.4-0.2-0.8-0.2-1.1c-0.3-4.1-0.6-8.2-0.9-12.3c-0.1-1-0.1-2-0.2-2.9
			C766.8,415.3,766.9,415.1,767,414.9z"/>
                            <path className="st11" d="M626.3,432.6c-0.1,0.1-0.2,0.2-0.3,0.2c-1.2,0.1-2.4,0.2-3.7,0.3c-0.1-0.4-0.3-0.7-0.3-1
			c-0.2-2.1-0.5-4.1-0.7-6.2c-0.2-1.6-0.3-3.3-0.5-5c1.3-0.8,2.6-0.5,3.9-0.5C625.6,423.8,626.6,431,626.3,432.6z"/>
                            <path className="st11" d="M742.7,432.2c1.1-0.2,2.2-0.1,3.5-0.1c0.4,6.2,0.4,12.4,0.6,18.6c-0.8,0.3-1.5,0.2-2.2,0.1
			c-0.5-0.4-0.3-0.9-0.3-1.4c0-3.8-0.2-7.6-0.8-11.4c-0.2-1.3-0.4-2.5-0.6-3.8C742.7,433.6,742.5,433,742.7,432.2z"/>
                            <path className="st18" d="M724,416.4c1.3-0.1,2.4-0.6,3.5-0.5c0.5,0.5,0.3,1.1,0.3,1.6c0,2,0,3.9,0,5.9c0,2.1,0.1,4.2,0.1,6.3
			c0,0.3,0,0.6-0.1,1c-1.2-0.3-2.2-0.2-3.1,0.6c-0.5-0.3-0.4-0.7-0.4-1c-0.1-2.5-0.1-5-0.1-7.5c0-1.5-0.1-3.1-0.2-4.6
			C724,417.6,724,417.1,724,416.4z"/>
                            <path className="st13" d="M703.1,456c0-0.2,0-0.4,0.1-0.6c2.3-4.1,3.8-8.6,6.3-12.5c0.4,0.1,0.4,0.3,0.4,0.6
			c-0.1,3.6,0.1,7.2,0.4,10.8c0,0.5,0.1,0.9,0.2,1.5c-0.8,0.4-1.6,0.2-2.4,0.2c-0.8,0.1-1.6,0.1-2.4,0.1
			C704.8,456.1,703.9,456.2,703.1,456z"/>
                            <path className="st15" d="M632.3,431.8c0.8,0.2,1.3,0.4,1.8,0.5c0.5-0.3,1-0.6,1.6-1c0.3,0.8,0.2,1.6,0.3,2.4c0.1,0.8,0.2,1.7,0.3,2.6
			c0.1,0.8,0.1,1.6,0.2,2.4c0.1,0.8,0.1,1.6,0.2,2.4c0.1,0.9,0.2,1.7,0.3,2.6c0.1,0.8,0.2,1.6,0.3,2.3c-0.6,0.6-1.2,0-1.7,0.3
			c-0.5,0.2-1,0.4-1.4,0.6c-0.1-0.1-0.2-0.2-0.2-0.3C633.4,441.6,632.9,436.8,632.3,431.8z"/>
                            <path className="st11" d="M727.9,432c0,0.4,0.1,0.9,0.1,1.4c0,2.1-0.1,4.3-0.1,6.4c0,1.8,0,3.7,0,5.5c0,0.6,0.2,1.2-0.2,1.8
			c-1.6,0.6-2-0.8-2.8-1.7c-0.2-3.8-0.4-7.7-0.6-11.4c0.4-0.7,0.6-1.3,1-1.7c0.3-0.4,0.7-0.7,1.1-0.8
			C727,431.2,727.5,431.5,727.9,432z"/>
                            <path className="st21" d="M696.9,450.1c-0.6,0-1.2-0.1-1.7-0.1c-0.1-0.6-0.1-1.2-0.2-1.9c2.9-5.2,5.9-10.4,9-15.7
			c0.5-0.1,1.1-0.2,1.6-0.2c0.1,0,0.2,0.1,0.4,0.2c-0.1,0.3-0.1,0.6-0.2,0.8C704.3,436.8,698.6,447.8,696.9,450.1z"/>
                            <path className="st13" d="M610.9,426.5c1.5-0.4,2.8-0.5,4.2-0.6c0.4,3.8,1,7.4,1.2,11.1c-1.3,0.3-2.5,0.7-3.8,1c0.1,0,0,0,0,0
			c0,0-0.1-0.1-0.1-0.2c0-0.4-0.1-0.7-0.1-1.1c-0.2-2.2-0.5-4.4-0.7-6.6C611.3,429,611.1,427.9,610.9,426.5z"/>
                            <path className="st58" d="M697.1,457c-0.9-0.8-1.1-1.5-1.3-2.4c3.9-6.1,7.1-12.6,10.3-19.1c0.9-0.2,1.6-0.1,2.5,0.1
			c-0.9,1.9-2.1,3.5-3,5.3c-0.9,1.8-2,3.5-2.9,5.3c-0.9,1.7-1.8,3.4-2.7,5.2C699,453.2,698.1,455,697.1,457z"/>
                            <path className="st14" d="M678.2,448.3c1.4-0.1,2.4-0.1,3.6-0.2c0.6,1.1,0.5,2.3,0.7,3.4c0.2,1.1,0.4,2.3,0.6,3.4
			c0.2,1.1,0.3,2.3,0.5,3.5c-1.3,0.4-2.5,0.6-3.8,0.6c-0.1-0.2-0.3-0.5-0.3-0.7C679.1,455,679.1,451.7,678.2,448.3z"/>
                            <path className="st15" d="M720.4,457.6c-1.5,0.4-3,0.4-4.6,0.4c-0.1-0.4-0.3-0.7-0.2-1c0-2.7,0.1-5.5,0.2-8.4c1.4,0.2,2.7,0.4,4,0.7
			c0.3,0.9,0.2,1.8,0.2,2.7c0.1,1,0.1,2,0.1,2.9C720.1,455.7,720.6,456.6,720.4,457.6z"/>
                            <path className="st15" d="M675.5,459.3c-1.3,0.5-2.5,0.3-3.6,0.5c-0.9-2-1.6-7.3-1.4-9.7c1.2-0.2,2.4-0.3,3.7-0.5
			C674.7,452.9,675.1,456.1,675.5,459.3z"/>
                            <path className="st13" d="M613.7,454.5c1.3,0.7,2.5,1.1,3.8,0.4c0.4,1.2,0.9,6,1,8.5c-1.2,0.6-2.5,1-4,1.1c-0.3-1.1-0.3-2.2-0.4-3.3
			c-0.1-1.1-0.2-2.2-0.2-3.3C613.8,456.8,613.5,455.7,613.7,454.5z"/>
                            <path className="st15" d="M645.2,441.6c0.5,4,0.6,7.8,0.8,11.7c-1,0-1.9,0-2.9,0c-0.1-0.3-0.2-0.6-0.3-1c-0.4-3.1-0.7-6.2-1.1-9.3
			c0-0.4,0-0.8,0-1.3C642.9,441.6,643.9,441.7,645.2,441.6z"/>
                            <path className="st14" d="M771.8,456.9c-0.6,0.1-1.2,0.1-1.7,0.2c-0.1-0.2-0.3-0.4-0.3-0.5c-0.5-3.1-0.9-6.2-1.4-9.2
			c-0.2-1.3-0.2-2.6-0.3-3.8c0-0.3,0.1-0.7,0.1-1.2c0.9-0.1,1.7-0.1,2.6-0.2c0.1,0.4,0.2,0.7,0.2,1.1c0.1,3,0.2,6,0.3,9
			C771.6,453.6,771.7,455.2,771.8,456.9z"/>
                            <path className="st15" d="M665.1,453.5c-1.1,0.7-2.1,1.4-3.2,2c0,0-0.1,0-0.2-0.1c-0.3-1.4-0.6-7.2-0.5-9.9c1-0.7,2-1.6,3.1-2.2
			c0.2,0.1,0.2,0.1,0.3,0.1c0,0,0.1,0.1,0.1,0.2C664.9,446.9,665,450.2,665.1,453.5z"/>
                            <path className="st11" d="M739.9,459.4c-2,0.4-3.9,0.3-5.9-0.2c-0.2-2.1-0.3-4.1-0.4-6.3c3.8,1.6,3.3,1,5.8,0.3
			C739.8,454.5,740.1,457.3,739.9,459.4z"/>
                            <path className="st18" d="M725.2,447.5c0.6,0.2,1,0.5,1.4,0.6c0.5,0.1,0.9,0.1,1.4,0.2c0.1,0.2,0.1,0.4,0.2,0.6
			c0.3,3.3,0.6,6.6,0.9,9.9c-1.1,0.3-2.2,0.2-3.4,0.1c-0.7-3.5-0.4-7-0.6-10.5C725,448.1,725.1,447.9,725.2,447.5z"/>
                            <path className="st21" d="M719.6,440.6c-1.3,0.2-2.4,0.4-3.4-0.1c-0.2-1.8,0.3-7.9,0.8-9.6c0.1,0,0.2-0.1,0.3-0.1
			c0.8,0.1,1.7,0.2,2.6,0.3C720.2,434.3,719.7,437.3,719.6,440.6z"/>
                            <path className="st32" d="M608.2,449.1c-0.8,0.4-1.8,0.7-2.8,0.7c-0.5-3.5-0.7-7-1.1-10.6c1-0.4,1.9-0.4,2.9-0.3
			C607.7,440.4,608.4,447.3,608.2,449.1z"/>
                            <path className="st11" d="M624.8,461.5c-0.1-1.1-0.1-2.2-0.2-3.2c-0.1-1-0.3-2-0.3-3.2c1.5-0.1,2.8-0.2,4.3-0.2c0.6,1,0.5,2.2,0.7,3.3
			c0.2,1.1,0.3,2.3,0.4,3.4C628.1,462.1,626.2,462,624.8,461.5z"/>
                            <path className="st11" d="M750.6,424.2c-0.3-2.3-0.6-4.3-0.7-6.4c1.3-1,2.8-0.9,4.2-1.1c0.4,1.5,0.6,5.8,0.3,7.5c-0.6,0-1.1,0-1.7,0
			C752.1,424.2,751.5,424.2,750.6,424.2z"/>
                            <path className="st15" d="M606.7,431.6c-0.9,0.1-1.7,0.5-2.6,0.4c-0.4-0.5-0.3-1.1-0.3-1.6c-0.1-2.3-0.1-4.5-0.2-6.8
			c0-0.7-0.1-1.3-0.1-2c1.1-0.5,1.1-0.5,3.1-0.3c0.1,0.2,0.2,0.5,0.2,0.8c0,2.9,0,5.9,0,8.8C606.8,431.1,606.7,431.3,606.7,431.6z"
                            />
                            <path className="st15" d="M716.6,414.5c1-0.2,1.9-0.1,2.8,0c0.5,0.9,0.8,1.8,0.8,2.8c0,1.9-0.1,3.8-0.1,5.7c0,0.2-0.1,0.3-0.2,0.6
			c-0.5,0-0.9,0-1.4,0c-0.4,0-0.8-0.1-1.3-0.1c-0.4-1.5-0.3-3.1-0.4-4.6C716.6,417.4,716.4,416,716.6,414.5z"/>
                            <path className="st15" d="M672.6,428.1c-0.9,0-1.5,0.1-2.1,0.1c-0.1-0.2-0.2-0.2-0.2-0.2c0,0-0.1-0.1-0.1-0.2c0-0.2-0.1-0.4-0.1-0.5
			c-0.3-3.4-0.5-6.8-0.8-10.3c0.7-0.5,1.5-0.6,2.3-1c0.8,2,0.6,4.1,0.7,6.1C672.5,424,672.6,425.9,672.6,428.1z"/>
                            <path className="st11" d="M738,435.7c0.3,1.8,0.6,3.4,0.6,5.2c-0.7,0-1.3-0.2-2-0.1c-0.7,0.1-1.3,0.2-1.9,0.5
			c-0.6,0.3-1.1,0.7-1.7,1.2c-0.4-1.9-0.5-3.7-0.6-5.6C734.2,436.4,736,436.1,738,435.7z"/>
                            <path className="st15" d="M639.1,461.9c-1.4,0.2-2.7,0.4-4,0.5c-0.1-0.3-0.3-0.5-0.3-0.7c-0.2-2.3-0.3-4.6-0.4-6.9
			c0-0.2,0.1-0.3,0.2-0.6c0.5,0.4,1,0.8,1.5,1c0.5,0.2,1.2,0.1,1.9,0.2C638.3,457.4,638.7,459.5,639.1,461.9z"/>
                            <path className="st14" d="M641.1,432.7c0.8-0.3,1.8-0.2,2.6-0.2c0.7,0.4,0.7,1,0.8,1.6c0.2,2,0.4,3.9,0.5,5.9c0,0.1-0.1,0.2-0.2,0.5
			c-1,0.1-2.1,0.2-3.1,0.4C641.1,439.1,640.8,435,641.1,432.7z"/>
                            <path className="st15" d="M648.1,432c1.6-0.2,2.9-0.3,4.2,0.2c0.8,1.4,1.3,3.9,1.1,5.9c-0.6,0-1.3-0.1-1.9-0.1c-0.6,0-1.2,0.2-1.8,0.3
			c-0.1-0.1-0.3-0.2-0.3-0.4C648.9,436,648.5,434.1,648.1,432z"/>
                            <path className="st32" d="M660.9,437.5c1-0.7,2.1-1.4,3.1-2c0.1-0.1,0.2,0,0.3,0c0.1,0.1,0.2,0.2,0.2,0.3c0.1,2.2,0.2,4.4,0.3,6.5
			c-0.7,0.4-1.2,0.8-1.8,1.1c-0.5,0.3-0.9,0.9-1.6,1.1C661.1,443.3,660.8,439.2,660.9,437.5z"/>
                            <path className="st15" d="M706.8,431.1c-0.7,0-1.3,0-2,0c2.4-4.5,4.7-8.9,7-13.3c0.1-0.1,0.3-0.2,0.4-0.4c0.4,0.3,0.8,0.5,1.2,0.8
			C711.8,422.7,708.8,426.6,706.8,431.1z"/>
                            <path className="st11" d="M768.2,433c0.9-0.3,1.8-0.5,2.9-0.1c0.2,2.6,0.2,5.1,0.1,7.7c-1,0.5-1.9,0.6-2.8,0.5
			C768,439.7,767.8,434.6,768.2,433z"/>
                            <path className="st21" d="M742.5,431.3c-0.5-1.7-0.6-4.2-0.4-5.7c1.1-0.5,2.3-0.9,3.7-0.9c0.5,1,0.3,2.1,0.4,3.1c0.1,1,0,2.1,0,3.1
			C745,431.5,743.8,431.2,742.5,431.3z"/>
                            <path className="st15" d="M691.1,459.3c-1-0.1-1.9-0.2-2.8-0.3c0.1-0.3,0-0.5,0.2-0.7c1.8-2.4,3.5-4.8,5-7.4c0,0,0.1-0.1,0.2-0.1
			c0.3,0.3,0.6,0.5,0.9,0.8c0.5-0.1,0.9-0.2,1.4-0.3c0.1,0.1,0.2,0.3,0.2,0.3c-0.2,0.5-0.5,1-0.8,1.4
			C694,455.1,692.6,457.2,691.1,459.3z"/>
                            <path className="st32" d="M702,454.4c-0.7,0.1-1.1-0.1-1.2-0.6c-0.1-0.3-0.1-0.7-0.1-1.1c0-0.3,0.2-0.6,0.4-0.8
			c1.7-3.1,3.4-6.2,5.1-9.3c0.1-0.3,0.3-0.5,0.6-0.8c0.4,0.3,0.7,0.5,1.1,0.7c-0.3,1.1-1,2-1.4,3c-0.5,1.1-1,2.1-1.5,3.2
			c-0.5,1-0.9,2-1.4,3C703.1,452.6,702.7,453.6,702,454.4z"/>
                            <path className="st11" d="M752,451.3c1.3,0,2.3,0,3.4,0c0.1,0.1,0.2,0.2,0.2,0.3c0.3,1.9,0.6,3.7,0.9,5.6c-1,0.6-2.1,0.6-3.1,0.5
			c-0.6-0.3-0.7-0.8-0.8-1.2C752.2,454.9,752,453.2,752,451.3z"/>
                            <path className="st15" d="M655.2,454.8c0.4,1.7,0.8,3.2,1.1,4.6c-0.2,0.2-0.2,0.4-0.3,0.4c-1.4,0.6-2.9,0.7-4.4,1
			c-0.5-1.5-0.6-3.6-0.3-5.8C652.5,455.3,653.8,455.5,655.2,454.8z"/>
                            <path className="st11" d="M759.2,414.3c0.6-0.3,1.2-0.3,2-0.1c0.3,3.3,1.1,6.5,1.2,9.7c-1.2,0.4-1.2,0.4-2.3,0.1
			C759.6,421.7,759,415.8,759.2,414.3z"/>
                            <path className="st14" d="M646,454.4c0.4,2.3,0.8,4.4,1.2,6.6c-1.4,0.9-1.8,1-3.3,0.5c-0.6-2.3-0.5-4.6-0.7-7
			C644.1,454.2,645,454.2,646,454.4z"/>
                            <path className="st14" d="M680.3,423.5c-1,0.1-1.8,0.3-2.8,0.4c-0.4-1.4-0.2-2.9-0.4-4.3c-0.2-1.4-0.3-2.9-0.5-4.3
			c0.8-0.5,1.5-0.5,2.3-0.4c0.5,2.5,0.9,4.8,1.3,7.2C680.4,422.4,680.3,422.9,680.3,423.5z"/>
                            <path className="st14" d="M716,441.5c1.2,0.1,2.2,0.2,3.3,0.3c0.5,1,0.3,2,0.3,3c0.1,1,0.2,1.9,0,3c-1.3,0.3-2.5,0-3.9-0.2
			C715.6,445.6,715.9,443.6,716,441.5z"/>
                            <path className="st15" d="M664.6,430.5c0,1.2,0,2.5-0.2,3.7c-0.7,0.4-1.3,0.8-1.9,1.2c-0.6,0.4-1.1,0.9-1.7,1.3
			c-0.5-1.4-0.8-3.9-0.9-6.5c0.3-0.1,0.5-0.2,0.8-0.2c1.1,0.1,2.3,0.2,3.4,0.3C664.3,430.3,664.4,430.4,664.6,430.5z"/>
                            <path className="st14" d="M636.9,454.5c-1.7-0.6-2.2-2-2.4-3.4c-0.3-1.5-0.2-3,1.1-4.1c1-0.3,1.6,0.1,1.9,1c0.1,0.3,0.2,0.6,0.2,0.9
			c0.1,1.3,0.2,2.7,0.2,4C637.9,453.7,637.8,453.7,636.9,454.5z"/>
                            <path className="st15" d="M609.2,456.5c0.2,2.4,0.5,4.7,0.4,6.9c-0.8,0.5-1.7,0.4-2.6,0.4c-0.4-1.1-0.3-2.3-0.5-3.4
			c-0.2-1.1-0.3-2.2-0.4-3.3C607,456.4,608,456.5,609.2,456.5z"/>
                            <path className="st11" d="M765.5,457.5c-1.3,0.5-2.4,0.5-3.5,0.4c-0.6-1.4-0.7-4-0.4-5.9c0.1,0,0.2-0.1,0.3-0.1
			c0.9,0.1,1.8,0.2,2.7,0.3c0.1,0.2,0.2,0.4,0.2,0.5C765.1,454.2,765.3,455.8,765.5,457.5z"/>
                            <path className="st15" d="M633.7,419.3c0.6,1.8,1.3,3.4,1.4,5.3c-0.8-0.1-1.4-0.3-2-0.3c-0.6,0.1-1.1,0.4-1.7,0.7
			c-0.1-0.1-0.2-0.2-0.3-0.3c-0.3-1.6-0.5-3.2-0.8-5c0.3-0.2,0.6-0.4,0.8-0.4C632,419.3,632.8,419.3,633.7,419.3z"/>
                            <path className="st32" d="M665.8,460.6c-1.3,0.4-2.4,0.4-3.7,0.4c-0.3-1.4-0.2-2.7-0.4-4c1.1-0.8,2-1.8,3.4-2.3
			C665.3,456.8,665.8,458.7,665.8,460.6z"/>
                            <path className="st15" d="M744.3,451.8c0.9,0.1,1.5,0.1,2.2,0.2c0.1,0,0.2,0.1,0.3,0.2c0.1,0.2,0.1,0.3,0.2,0.5
			c0.2,1.7,0.5,3.5,0.7,5.3c-0.3,0.1-0.5,0.2-0.7,0.2c-0.8,0-1.6,0-2.4,0c-0.1-0.4-0.3-0.7-0.3-1.1
			C744.2,455.4,744.1,453.7,744.3,451.8z"/>
                            <path className="st14" d="M634.3,425.3c0.9,1.1,1,2.5,1,3.9c0,0.9-0.3,1.7-1.2,2.2c-0.3-0.2-0.8-0.3-1-0.6c-1.3-1.1-1.4-2.7-1.3-4.2
			c0-0.6,0.4-1,0.9-1.3C633.1,425,633.6,425,634.3,425.3z"/>
                            <path className="st14" d="M719.8,429.8c-0.9,0.4-1.7,0.1-2.6,0.2c-0.5-1-0.2-1.9-0.2-2.8c0-0.9-0.1-1.8,0.2-2.7c0.5,0,1,0,1.5,0.1
			c0.4,0.1,0.8,0.2,1.2,0.3C720.1,426.5,720.1,428.4,719.8,429.8z"/>
                            <path className="st18" d="M608.9,455.1c-1,0.3-1.9,0.5-2.9,0.5c-0.4-1.6-0.5-3.1-0.5-4.8c1-0.1,1.8-0.2,2.8-0.3
			C608.8,452,608.9,453.5,608.9,455.1z"/>
                            <path className="st15" d="M748.9,416.9c-1.3-0.9-2.3-1.7-3.4-2.5c1.2-0.8,1.2-0.8,4.7-0.9c0.9,0.4,2,0.7,2.7,1.8
			c-0.2,0.1-0.4,0.3-0.6,0.3C751.1,415.9,750,416.2,748.9,416.9z"/>
                            <path className="st15" d="M769.7,413.4c-0.3,0.2-0.4,0.3-0.5,0.3c-1-0.1-2-0.2-3.1-0.2c-1.1-0.9-2.3-1.8-3.2-3.1
			c1.1-0.5,2.2-0.2,3.3-0.3C767.4,411.1,768.6,412.1,769.7,413.4z"/>
                            <path className="st18" d="M604.3,438c-0.1-0.5-0.2-0.9-0.2-1.2c-0.1-1-0.1-2.1-0.2-3.1c0.9-0.6,1.8-0.5,2.7-0.7
			c0.5,1.5,0.4,2.9,0.5,4.4C606.3,437.8,605.4,437.9,604.3,438z"/>
                            <path className="st15" d="M650.9,427.9c1.4,0.2,2.6,0.4,3.9,0.6c-0.4,1.1-1.4,1.6-1.9,2.4c-1-0.1-1.8-0.2-2.6-0.3
			c-0.8-0.1-1.5,0.1-2.3-0.1C648.6,429.4,648.9,429.1,650.9,427.9z"/>
                            <path className="st15" d="M701.3,455.7c-0.8,2.6-1.3,3.3-2.4,4.2c-0.4-0.4-0.8-0.9-1.3-1.3c0.5-1.4,1.3-2.7,2.1-4
			C700.3,455,700.8,455.4,701.3,455.7z"/>
                            <path className="st15" d="M646.8,427.7c0.4,0,1,0.1,1.8,0.2c-0.3,0.8-1,1.2-1.4,1.7c-0.6,0.8-1.5,1.3-2.3,1.7c-0.7,0-1.3,0-2.1,0
			c0.6-0.9,1.4-1.2,2-1.8C645.3,428.9,645.9,428.4,646.8,427.7z"/>
                            <path className="st13" d="M709.3,429.7c0,1.5,0,3,0,4.6c-0.8,0.4-1.6,0.3-2.5,0.1c0.5-1.8,1.4-3.3,2.2-4.8
			C709,429.7,709.2,429.7,709.3,429.7z"/>
                            <path className="st15" d="M755.7,410.6c1.4-0.3,2.4-0.2,3.4-0.2c0.7,0.6,1.3,1.4,1.8,2.4c-0.8,0.1-1.3,0.2-2,0.2
			C757.9,412.3,757,411.6,755.7,410.6z"/>
                            <path className="st11" d="M673.7,422.2c1.1,4.5,0.9,9.1,1.2,13.6C673.7,431.4,674,426.8,673.7,422.2z"/>
                            <path className="st15" d="M708.6,441.4c-0.6-0.3-0.9-0.4-1.4-0.7c0.2-0.5,0.4-0.9,0.6-1.2c0.6-1.1,1.3-2.2,2.1-3.4
			c0.4,0.3,0.7,0.6,1,0.9C710.2,438.5,709.4,439.9,708.6,441.4z"/>
                            <path className="st15" d="M661.2,427.3c1.2-0.1,2.3-0.2,3.4-0.3c0.6,0.7,0.4,1.3,0.2,2c-1.3,0.3-2.6,0-4-0.2
			C661,428.2,661.1,427.8,661.2,427.3z"/>
                            <path className="st15" d="M734.5,433.5c0.5,0.4,1,0.7,1.5,1.1c-0.5,0.6-1.2,0.4-1.7,0.6c-0.6,0.2-1.2,0.2-1.7,0.4
			c-0.7-0.2-1.3-0.4-1.8-1.1c0.3-0.1,0.6-0.3,0.9-0.4C732.6,433.9,733.5,433.7,734.5,433.5z"/>
                            <path className="st15" d="M616.1,425.2c-0.6-0.2-1.2-0.4-1.8-0.7c0.5-0.8,1.3-1.1,2.1-1.5c0.8-0.1,1.6-0.1,2.4,0.1
			c-0.1,0.2,0,0.4-0.1,0.4C617.9,424,617,424.6,616.1,425.2z"/>
                            <path className="st15" d="M739.7,423.5c0.5-0.4,1-0.8,1.5-1.3c0.8,0.2,1.6,0.7,2.3,1.3c-0.8,0.6-0.8,0.6-2.6,1
			C740.6,424.2,740.2,423.9,739.7,423.5z"/>
                            <path className="st32" d="M666.6,444.8c0.1,3.9,0.2,7.8,0.3,11.7C666.4,454.8,666.1,447.8,666.6,444.8z"/>
                            <path className="st15" d="M608.1,420.2c-0.6-0.1-1.1-0.2-1.8-0.3c0.3-0.5,0.8-0.6,1.1-0.8c0.4-0.2,0.7-0.4,1-0.5c0.9,0,1.6,0,2.6,0
			c-0.3,0.3-0.4,0.6-0.6,0.6C609.7,419.6,608.9,419.9,608.1,420.2z"/>
                            <path className="st32" d="M666.2,432.8c0.2,2.5,0.5,4.6,0.3,6.8c0,0.2-0.1,0.4-0.3,0.8C665.8,438,665.7,434.2,666.2,432.8z"/>
                            <path className="st15" d="M626.1,418.9c0.6-0.2,1.2-0.4,1.8-0.6c0.6-0.2,1.3-0.2,2.1-0.4C628.9,418.8,627.9,419.1,626.1,418.9z"/>
                            <path className="st13" d="M667.9,477.7c-0.6,0.5-1.1,0.9-1.6,1.3c-0.5,0-1.1,0.1-1.8,0.1c-0.3-0.4-0.7-0.9-0.9-1.2
			c-0.8-0.4-1.5,0.2-2.3,0.3c-0.1-1.7-0.2-3.4-0.1-5.2c0.4-0.1,0.8-0.2,1.2-0.3c5.4-0.3,10.8-0.6,16.2-0.3
			c6.1,0.4,12.2,0.1,18.3-0.3c6.1-0.5,12.2-0.7,18.3-0.6c5.2,0.1,10.5,0.3,15.7,0.5c1.8,0.1,3.7,0.1,5.5,0.1c0.6,0,1.2-0.1,1.9,0.2
			c-0.1,0.3-0.2,0.7-0.3,1c-1.7,0.6-3.4-0.8-5-0.1c-0.3,0.7-0.2,1.3-0.1,1.9c1,5.1,0.8,10.1,0.5,15.2c-0.3,5.3-0.3,10.5,0.8,15.8
			c0.5,2.4,0.6,4.9,0.9,7.3c0,0.4,0,0.7,0,1.1c-1.1,0.4-1.1,0.4-2.8,0.2c-0.1-0.2-0.3-0.3-0.4-0.5c-2-3.3-4.1-6.6-6-9.9
			c-1.6-2.9-3.5-5.6-5.6-8.2c-1.2-1.4-2.2-2.9-3.1-4.5c-1.1-2-2.5-3.6-3.9-5.3c-0.3-0.4-0.7-0.6-1.1-1c-0.4,0.3-0.8,0.4-1.1,0.7
			c-0.7,0.9-2,1.3-2.3,2.6c0.8,0.9,1.5,1.9,2.3,2.8c0.7,1,1.6,1.8,2.2,2.8c-0.1,0.2-0.1,0.3-0.1,0.3c0,0-0.1,0.1-0.2,0.1
			c-1,0.1-2,0.3-3.1,0.4c-1.2,0.1-2.4,0.1-3.7,0.1c-2.3,0.1-4.6,0.3-6.9,0.4c-2.7,0.1-5.4,0.2-8,0.3c-1.1,0-2.2,0.2-3.2,0.7
			c-1,0.5-2,0.9-3,1.4c-1.1,0.5-2.2,1.1-3.3,1.6c-0.4,0.2-0.8,0.4-1.1,0.6c-0.2,0.1-0.4,0.3-0.5,0.5c0,0.9,0.2,1.6,0.5,2.4
			c2.1,0.6,4.2,0.2,6.4,0.2c0.1,0.5,0.3,1,0.4,1.5c-0.7,0.2-1.2,0.4-1.9,0.6c-0.1,0.6-0.2,1.4-0.3,2.2c-0.6,0.1-1.3,0.3-2,0.4
			c-0.1,0.5-0.2,0.9-0.3,1.5c-0.6,0.1-1.2,0.1-1.8,0.2c-0.3,1.8-0.2,3.6,0.2,4.9c0.8,0.6,1.9,0.3,2.9,0.7c0.1,0.4,0.1,0.9,0.2,1.3
			c-0.2,0.1-0.4,0.3-0.5,0.3c-0.4,0.1-0.7,0.1-1.1,0.1c-3.7,0.2-7.4,0.3-11.1,0.5c-0.4,0-0.8,0-1.3,0c-0.1-0.5-0.2-0.8-0.2-1.1
			c-0.3-3.3-0.6-6.6-0.8-9.9c-0.2-2.1-0.3-4.2-0.4-6.2c-0.2-4.5-0.3-9.1-0.4-13.6c0-0.6-0.1-1.2,0-1.8
			C668.6,482.4,668.5,480.2,667.9,477.7z"/>
                            <path className="st14" d="M603.4,529.8c1.5-1.6,2.9-2.7,4.4-3.9c0.1,0.4,0.3,0.7,0.3,1c0,0.5,0,1,0,1.4c1.4,1.4,2.9,1.3,4.4,0.7
			c1.3-0.9,2.5-1.7,3.8-2.6c0.8,0.8,1.1,2.1,2.5,2.1c1.8-0.4,2.9-2,4.3-3c0.5,0.1,0.4,0.4,0.4,0.7c0.1,0.7-0.1,1.4,0.5,2
			c1.3,0.4,2.6,0.1,3.9,0c1.3-0.2,2.2-1.3,3.4-1.9c0.2,0.4,0.5,0.8,0.8,1.2c0.9-0.1,1.7-0.2,2.5-0.3c0.8,0,1.6,0,2.4,0
			c0.7-0.7,1.4-1.3,2.2-2c0.7-0.7,1.4-1.3,2.1-2c0.5,0.1,0.5,0.4,0.5,0.7c0.1,0.9,0.1,1.8,0.2,2.7c1.1,0.9,2.3,0.6,3.4,0.5
			c1.3-0.9,2.1-2.4,3.5-3.3c0.4,0.5,0.2,1,0.3,1.4c0.1,0.5,0.1,1,0.2,1.4c1.3,0.9,2.4,0.1,3.6,0.1c1.1-1.1,2.2-2.1,3.3-3.2
			c0.1,0.3,0.2,0.4,0.2,0.6c0.2,1.2,1.1,1.7,2.1,2c0.7,0.2,1.4,0.3,2.2,0.4c1.2-0.5,2-1.6,3-2.4c1-0.8,1.9-1.6,2.8-2.3
			c1-0.8,2-1.6,3-2.5c4.3-0.2,8.6-0.6,12.9-0.5c0.1,2,0.2,3.9,1.1,5.8c0.3,0.1,0.7,0.2,1.1,0.2c1.5,0,2.9,0,4.4,0
			c3.7-0.1,7.3-0.2,11-0.3c1.6,0,3.3-0.1,4.9-0.1c4.1,0,8.3-0.2,12.4-0.6c1.9-0.2,3.8-0.3,5.6-0.5c0.2,0,0.3-0.1,0.6-0.2
			c0.3-1,0-2,0.1-3.1c0-1,0-1.9,0-3c1.5-0.2,3-0.2,4.5,0c0.6,1,1.3,2.1,1.9,3.1c0.3,0.5,0.7,1,1.1,1.5c1.1,1.2,2.5,1.3,3.7,0.3
			c0.4-0.3,0.7-0.7,1-1c-0.2-1.6-2-2.3-2.2-3.9c0.7,0,1.3,0,1.9,0c2.8,1.6,4.9,4.1,7.5,6c1.7-0.5,3.4,0,5-0.4c0.1-0.5,0.2-1,0.3-1.4
			c0.4-0.1,0.4-0.1,1.5,1.3c1.1,0.9,2.5,0.7,3.9,0.5c0.2-0.4,0.3-0.8,0.5-1.2c0.5,0.3,1,0.6,1.6,0.9c1.3,0.2,2.5,0.3,3.8,0
			c0.2-0.4,0.3-0.8,0.5-1.2c0.1,0,0.2-0.1,0.3-0.1c0.4,0.2,0.8,0.3,1.2,0.5c1.8,1.1,3.6,0.9,5.6,0.2c1.5,0.4,3.2,1,4.7-0.2
			c1.3,0.4,2.5,0.7,3.7,1.1c0.7,0.2,1.3,0,1.9-0.3c0.4-0.5,0.3-1.1,0.2-1.6c-0.2-3.5-0.4-7-0.7-10.5c-0.3-3.1-0.3-6.3-0.2-9.4
			c0-3.4,0.1-6.9,0.1-10.3c0-1.3,0.1-2.6-0.5-4.1c-1.7-1.7-3.6-3.7-5.6-5.8c0-0.5,0-1.2,0-2.1c0.3,0.1,0.6,0.2,0.8,0.3
			c1.8,1.4,3.5,2.8,5.1,4.5c0.5,1.2,0.4,2.4,0.5,3.6c0,0.9,0,1.7,0,2.6c0,0.6-0.1,1.2,0,1.8c0.4,1.9,0.3,3.8,0.3,5.7
			c0,1,0,2,0.1,2.9c0.5,5.8,0.7,11.5,0.6,17.3c0,1.3,0,2.6,0.6,3.8c0,0.1,0.1,0.2,0.3,0.4c1.1,0.5,2.3,0.1,3.4-0.1
			c0.2-0.3,0.5-0.6,0.7-0.9c0.1,0,0.3,0,0.3,0c1.1,0.9,2.3,1.6,3.6,2.2c1.3,0.6,1.3,0.6,2,1.5c-0.3,0.4-0.8,0.2-1.2,0.3
			c-3.3,0.1-6.7,0.2-10,0.3c-7.9,0.2-15.8,0.4-23.7,0.6c-10.9,0.3-21.8,0.6-32.7,0.9c-11.5,0.3-23,0.6-34.5,0.9
			c-8,0.2-16.1,0.5-24.1,0.7c-13,0.4-26.1,0.7-39.1,1c-6,0.2-11.9,0.4-17.9,0.5C605,529.8,604.4,529.8,603.4,529.8z"/>
                            <path className="st56" d="M793.5,524.5c-1.4-0.1-2.5-0.5-3.5-1.2c-1-0.6-2-1.2-3-1.8c-1.1-0.6-2.1-1.2-3-2.1c-0.6-0.6-1.2-1.1-1.8-1.7
			c-0.4-2.4-0.8-4.8-0.8-7.3c-0.2-6.1-0.1-12.1-0.1-18.2c0-2.9,0.1-5.8-0.4-8.7c-0.9-0.8-1.9-1.7-2.9-2.7c-0.1-2.6-0.2-5.3-0.4-7.8
			c0.5-0.6,1.1-0.4,1.6-0.4c3-0.2,6.1-0.4,9.1-0.7c0.9-0.1,1.8-0.3,2.8-0.6c0.1,0.2,0.2,0.5,0.2,0.8c-0.2,2.6,0,5.1,0.2,7.7
			c0.3,3,0.2,6,0.5,9c0.3,4.4,0.3,8.7,0.2,13.1c-0.1,3,0,6,0.4,9c0.2,1.2,0.2,2.4,0.3,3.7c0.2,2.8,0.4,5.5,0.6,8.3
			C793.5,523.4,793.5,523.9,793.5,524.5z"/>
                            <path className="st56" d="M611.8,476.9c-2.3,1.4-5,1.7-7.1,3.3c-0.2,1.3-0.2,2.5-0.1,3.7c0.3,5.7,0.6,11.4,1.3,17.1
			c0.4,3.2,0.6,6.5,0.9,9.7c0.3,3.4,0.6,6.8,0.9,10.3c0.1,0.8,0.1,1.6,0.1,2.4c-1.1,1-2.2,1.9-3.2,2.8c-1,0.9-1.9,1.9-2.9,3
			c-0.1-0.3-0.3-0.5-0.3-0.7c0-4.7-0.8-9.3-1.1-13.9c0-0.6-0.1-1.2-0.1-1.8c-0.1-4.6-0.2-9.2-0.3-13.8c0-4.2-0.2-8.3-0.7-12.5
			c0-0.1,0-0.2,0-0.4c0.1-2.9-0.2-5.7-0.2-8.6c0-0.5,0.1-0.9,0.1-1.4c0.2,0,0.3-0.1,0.3-0.1c3.7,0.4,7.4,0.3,11.1,0.4
			C611,476.3,611.4,476.2,611.8,476.9z"/>
                            <path className="st14" d="M635.9,479.5c1.2-0.8,2.4-1.6,3.5-2.5c1.1-0.8,2-1.7,3.2-2.7c0.4,0,1-0.1,1.6-0.2c0.5,0.5,0.4,1.1,0.4,1.6
			c-0.1,4.9,0.5,9.8,1,14.7c0.2,1.5,0.3,2.9,0.2,4.5c-1.8,1.8-3.6,3.8-6,5.1c-0.2,0.8,0,1.7,0,2.6c0.4,4,0.8,8.1,1.1,12.1
			c0.2,1.9,0.3,3.8,0.5,5.7c0,0.3,0,0.6,0,1c-1,1-2.1,2-3.1,3c-0.1,0.1-0.2,0-0.4,0.1c-0.4-0.5-0.3-1-0.3-1.6
			c-0.3-7.2-0.5-14.4-1-21.5c-0.5-6.7-0.4-13.5-0.8-20.2C635.8,480.6,635.8,480.1,635.9,479.5z"/>
                            <path className="st64" d="M660.6,487.9c2.2-2.8,4.3-5.4,6.5-8.2c0.2,0.4,0.3,0.6,0.3,0.8c0.1,0.4,0.1,0.7,0.1,1.1
			c0.2,6.9,0.3,13.9,0.6,20.8c0.1,3.4,0.6,6.8,0.9,10.3c0.1,1.6,0.2,3.3,0.3,4.8c-1.2,1.5-6.2,5.9-7.8,6.8c-0.4-0.3-0.2-0.8-0.2-1.2
			c0-3.2,0-6.4-0.1-9.6c-0.1-2.9-0.3-5.9-0.4-8.8c-0.1-1.7-0.1-3.3-0.1-5c0-2.9-0.1-5.8-0.1-8.6C660.6,490,660.6,488.9,660.6,487.9z
			"/>
                            <path className="st32" d="M743.4,521.9c-0.7-0.5-1-0.6-1.2-0.9c-1.3-1.3-2.7-2.5-4.1-3.6c-0.7-0.6-1.4-1.2-1.9-2.2
			c-0.1-0.9-0.2-2-0.3-3.1c-0.3-2.3-0.5-4.6-0.8-6.9c-0.4-2.5-0.7-5-0.7-7.5c0-3.1,0.1-6.1,0.2-9.2c0.1-4.2,0.3-8.3-0.5-12.5
			c-0.1-0.4-0.1-0.7-0.1-1.1c0-0.1,0.1-0.2,0.2-0.4c2.2,0.8,4.1,2.3,6.6,2.8c0.1,0.6,0.1,1.2,0.2,1.8c1,7.6,1.2,15.1,1.4,22.8
			c0.1,4,0.4,8,0.6,11.9C743.1,516.4,743.3,518.9,743.4,521.9z M738.1,485.3c-1.1,0.3-1.7,1-2,1.9c-0.3,1.2-0.6,2.4-0.8,3.6
			c-0.5,4.5,0.7,8.7,1.6,13.1c0,0.2,0.1,0.3,0.2,0.5c0.3,0.7,0.8,1.1,1.5,1.1c0.9-0.2,1.2-0.8,1.4-1.6c0.2-0.8,0.3-1.7,0.3-2.5
			c0-1.5,0.1-3.1,0-4.6c-0.2-2.7-0.5-5.4-0.7-8C739.5,487.4,739.1,486.3,738.1,485.3z"/>
                            <path className="st16" d="M752.8,488c-0.2-5.1-1.2-9.9-1.2-14.9c1.4-0.4,2.8-0.1,4.2-0.1c1.5,1.2,3,2.3,4.4,3.7c0,0.4,0,0.7,0,1.1
			c0.1,2.5,0.2,5,0.3,7.5c0.1,3.2,0.5,6.4,0.8,9.5c0.2,2.3,0.2,4.5,0.3,6.8c0.3,5.9,0.7,11.8,1,17.6c0,0.6,0.3,1.2-0.1,1.9
			c-0.9-0.1-1.7-0.5-2.6-1c-0.1-1.8-0.3-3.6-0.4-5.5c-0.1-0.8,0-1.6,0-2.4c0.3-4.6-0.2-9.1-0.5-13.6c-0.1-1.2-0.2-2.4-0.2-3.7
			c-0.1-1-0.4-1.8-1.1-2.4c-0.7-0.6-1.4-1.2-2-1.8C754.7,489.8,753.8,489,752.8,488z"/>
                            <path className="st58" d="M651.6,479.9c2-2.3,3.9-4.6,5.9-6.9c0.7,0,1.6,0,2.4,0c0.4,1.2,0.5,3.3,0.2,7.1c-0.5,0.8-1.1,1.7-1.8,2.6
			c-0.8,1.1-1.7,2.2-2.6,3.3c-0.3,0.4-0.8,0.8-1.2,1.2c-0.2,1.4-0.1,2.9,0.1,4.4c0.8,5.2,0.9,10.5,1,15.8c0.1,4,0.4,8.1,0.6,12.1
			c0,0.7,0.1,1.3,0.1,2c-0.9,1-1.8,1.9-2.6,2.9c-0.1,0.1-0.2,0-0.3,0c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1-1.9-0.2-3.8-0.3-5.7
			c-0.2-3.4-0.3-6.9-0.4-10.3c-0.1-2.4-0.2-4.8-0.2-7.2c0-0.8,0-1.6,0-2.4c0.1-3.1,0.1-6.1,0.2-9.2c0-2.3-0.2-4.7-0.6-7
			C651.7,481.6,651.6,480.6,651.6,479.9z"/>
                            <path className="st65" d="M631.5,524.4c-0.8,1-1.7,1.7-2.7,2.2c-0.5-0.2-0.4-0.5-0.4-0.8c-0.3-3.7-0.6-7.3-0.9-11
			c-0.3-2.9-0.6-5.9-0.6-8.8c0-4.1-0.3-8.1-1-12.1c-0.4-2.2-0.5-4.5-0.8-6.8c-0.1-0.8-0.3-1.6-0.4-2.3c1.1-1.1,2.1-2.1,3.3-3.2
			c0.1,0.3,0.3,0.6,0.3,0.9c0.6,3.8,0.9,7.7,1.1,11.5c0.3,3.8,0.6,7.6,0.9,11.4c0.5,5.9,1,11.9,1.1,17.8
			C631.5,523.6,631.5,524,631.5,524.4z"/>
                            <path className="st66" d="M616,525.1c-0.8,0.6-1.5,1.1-2.4,1.7c-0.1-0.4-0.2-0.7-0.3-1c-0.3-4.3-0.7-8.6-1-12.9
			c-0.2-3.2-0.3-6.5-0.5-9.7c-0.1-1.4-0.2-2.8-0.3-4.2c-0.3-3.4-0.4-6.7-0.5-10.1c0-1.8-0.3-3.7-0.5-5.5c-0.1-0.9-0.1-1.8-0.2-2.7
			c1.5-0.9,3.5-1.6,5.1-1.8c0.3,1.6,0.3,3.1,0.4,4.8c-0.8,1.1-2.3,1.4-3.1,2.6c0,0.4-0.1,0.8-0.1,1.2c-0.1,3,0.1,6,0.5,9
			c0.5,4.3,0.9,8.7,1.4,13c0.2,2,0.4,4,0.7,6C615.6,518.6,615.9,521.8,616,525.1z"/>
                            <path className="st67" d="M620.5,526.1c0-1.7,0.1-3.2,0.1-4.6c-0.1-2.5-0.2-5-0.4-7.5c-0.2-3.6-0.4-7.1-0.6-10.7
			c-0.2-2.9-0.3-5.9-0.4-8.8c-0.1-2.2-0.4-4.4-1-6.5c-0.2-0.6-0.3-1.2-0.4-1.8c1.3-1.3,3.1-1.7,4.4-3c1.2-1.3,2.7-2.2,4.1-3.3
			c0.4,0.3,0.2,0.5,0,0.6c-1.7,1.5-3.4,3-5.2,4.6c0,0.2-0.1,0.6,0,0.9c0.2,1.7,0.4,3.4,0.7,5.1c0.5,4,0.5,8.1,0.6,12.1
			c0.1,3.8,0.2,7.6,0.4,11.4c0.1,2.1,0.4,4.3,0.6,6.4c0.1,0.9,0.1,1.7,0.2,2.6C622.4,524.2,621.8,525.4,620.5,526.1z"/>
                            <path className="st16" d="M636.1,515.3c-0.7,0.7-1.2,1.3-1.8,1.9c-0.3-0.5-0.6-1-0.8-1.5c-0.3-0.1-0.7-0.3-1-0.4
			c-0.3-0.5-2-22.7-2-25.6c0.2-0.2,0.5-0.5,0.8-0.9c0.4,0.8,0.8,1.5,1,2c0.9,0.6,1.5,0,2-0.1c0.1,0.1,0.2,0.1,0.2,0.1
			c0,0,0.1,0.1,0.1,0.1C635.1,499.1,635.6,507.2,636.1,515.3z"/>
                            <path className="st11" d="M716.5,519c0.2,1.1,0.1,2.2,0.1,3.4c-0.4,0.1-0.7,0.3-1,0.3c-2.3,0.3-4.6,0.4-6.9,0.5
			c-4.8,0-9.6,0.2-14.4,0.3c-1.3,0-2.5,0-3.8,0c-0.5-1.1-0.4-2.3-0.4-3.5c0.5-0.1,1-0.1,1.5-0.1c6-0.1,12-0.5,18-0.8
			c2.1-0.1,4.1-0.1,6.2-0.1C716,518.8,716.2,518.9,716.5,519z"/>
                            <path className="st68" d="M682.2,500.4c2.6-1.2,5.2-2.5,7.6-3.6c2.1,0,3.8,0,5.6,0c1.7,0,3.4-0.2,5.1-0.3c1.4-0.1,2.8-0.2,4.2-0.2
			c2.6,0,5.1,0,7.6-0.4c0.6-0.1,1.2-0.1,1.9-0.2c0.4,0.4,0.7,0.9,1.1,1.4c0.4,0.5,0.7,1,1,1.4c-0.4,0.5-0.9,0.3-1.2,0.3
			c-2.2,0-4.5-0.1-6.7,0c-1.8,0-3.5,0.3-5.3,0.4c-1.5,0.1-3,0.3-4.5,0.3c-2.8,0-5.6,0.3-8.4,0.5c-0.9,0.1-1.8,0.1-2.7,0.1
			c-1.5,0-3,0-4.5,0.4c-0.2,0.1-0.5,0-0.7,0C682.2,500.5,682.2,500.4,682.2,500.4z"/>
                            <path className="st62" d="M768,521.2c-0.6-0.4-0.9-0.6-1.2-0.8c-0.1-0.3-0.1-0.6-0.2-0.9c-0.6-5.6-0.7-11.1-0.9-16.7
			c-0.2-4.5-0.2-8.9-0.3-13.4c-0.1-3.8-0.2-7.6-0.4-11.4c0-0.3,0-0.6,0-1c0.9-0.1,1.4,0.4,2,0.9c0.1,2.7,0.2,5.5,0.2,8.2
			c-0.1,4.1,0.3,8.2,0.4,12.3c0.1,3,0.1,6,0,9c-0.1,4.2-0.3,8.3,0.1,12.5C767.9,520.2,767.9,520.5,768,521.2z"/>
                            <path className="st18" d="M749.9,520.8c-0.6-0.6-1.1-1.1-1.7-1.7c-0.2-2.7-0.5-5.4-0.7-8.2c-0.4-4.8-0.6-9.5-0.6-14.3
			c0-2.9-0.3-5.8-0.4-8.6c0-0.1,0-0.1,0-0.2c-0.1-3.1-0.1-6.1-0.2-9.3c0.8,0.3,1.1,1.1,1.8,1.4c0.3,1.9,0.4,3.7,0.3,5.5
			c-0.2,3.9-0.1,7.8,0.2,11.8c0.4,5.3,0.5,10.5,0.8,15.8c0.1,1.9,0.4,3.8,0.6,5.7C750,519.4,750.2,520,749.9,520.8z"/>
                            <path className="st32" d="M712.2,510c0.4,1,1.7,1.6,1.4,3.1c-0.5,0-1.1,0.1-1.6,0.1c-5.6-0.1-11.3,0.1-16.9,0.4c-0.7,0-1.3,0-1.9,0
			c-0.9-1.2-0.8-2.2,0-3.3c0.3-0.1,0.7-0.2,1-0.2c5.8-0.1,11.7-0.1,17.5-0.2C711.8,509.9,711.9,510,712.2,510z"/>
                            <path className="st58" d="M755.2,521.2c-0.7-0.5-1.1-0.9-1.7-1.3c-0.6-4.7-1.1-9.3-1.3-14.1c-0.3-4.7-0.7-9.4-0.9-14
			c0.2-0.1,0.3-0.1,0.3-0.1c0.1,0,0.1,0,0.2,0.1c0.7,0.8,1.4,1.7,2.1,2.4C754.5,498.4,755.5,518.6,755.2,521.2z"/>
                            <path className="st69" d="M770.8,486.8c1.2,0.6,1.6,1.4,2.2,2.1c0,1.1-0.1,2.2-0.1,3.3c-0.2,6.3,0,12.5,0.4,18.7
			c0.2,3.1,0.2,6.1,0.3,9.2c0,0.3-0.1,0.7-0.1,1.2c-0.7-0.1-1.2-0.4-1.8-0.9c-0.1-1.1-0.4-2.3-0.5-3.5c-0.4-5-0.6-10-0.2-15.1
			c0.2-3,0.2-6,0-9C770.8,490.9,770.9,489,770.8,486.8z"/>
                            <path className="st70" d="M646.6,524.4c-0.1-0.5-0.2-0.6-0.2-0.8c-0.2-3.9-0.7-7.8-0.7-11.7c0-2.7-0.4-5.4-0.7-8
			c-0.1-0.7-0.1-1.3-0.2-2c0.9-0.9,1.8-1.8,2.8-2.5c0.2,0.1,0.2,0.1,0.3,0.2c0,0,0.1,0.1,0.1,0.2c0.3,7.2,0.7,14.4,1,21.6
			c0,0.2,0,0.5-0.1,0.8C648.2,522.8,647.6,523.6,646.6,524.4z"/>
                            <path className="st13" d="M616.9,482.9c-0.1-1.2-0.1-2.1-0.2-3c-0.1-0.8,0.4-1.7-0.2-2.6c-1-0.1-2-0.1-3-0.2c-0.1-0.2-0.1-0.4-0.2-0.5
			c0.2-0.1,0.4-0.2,0.5-0.2c6.4-0.2,12.9-0.8,19.3-1.3c0.1,0,0.2,0.1,0.3,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.1-0.1,0.2
			c-1.5,1.1-3,2.1-4.5,3.2c-0.1,0.1-0.3,0.1-0.5,0.2c-0.3,0.1-0.7,0.3-1.1,0.5c-0.1-0.3-0.2-0.6-0.4-1c-1.8,0.1-3.7,0-5.3,1.4
			c-1.2,1-2.6,1.8-3.9,2.7C617.7,482.5,617.4,482.6,616.9,482.9z"/>
                            <path className="st13" d="M656.6,496.1c0.9,0,1.7,0,2.5,0c0.3,1.4,0,2.7,0.1,4.1c0,1.4,0,2.8,0.1,4.2c0,1.3,0.1,2.7,0.2,4
			c0.1,1.4,0.2,2.8,0.3,4.2c0.1,1.3,0.3,2.7,0.1,4.1c-0.6,0.5-1.4,0.5-2.2,0.5c-0.1-0.3-0.3-0.7-0.3-1c-0.1-0.8-0.1-1.6-0.2-2.4
			c-0.1-3.7-0.2-7.5-0.3-11.2c-0.1-1.8-0.2-3.7-0.3-5.5C656.6,496.8,656.6,496.5,656.6,496.1z"/>
                            <path className="st32" d="M743.4,503.3c0.8,0,1.5,0,2.3,0c0.1,0.3,0.2,0.6,0.3,0.9c0.5,5.5,0.9,11,1.4,16.5c0,0.3,0,0.6,0,1
			c-1,0.4-2,0.4-3,0.1C744.2,520.2,743.3,504.8,743.4,503.3z"/>
                            <path className="st15" d="M699.5,514.7c-0.5,1.8-0.5,1.8,0.5,3.5c-4.6,0.7-9.2,0.2-13.8,0.4c-0.7-1-0.6-2.1-0.8-3
			c0.5-0.6,1.2-0.5,1.8-0.5c1.4-0.1,2.8-0.2,4.2-0.2c2.4-0.1,4.7-0.2,7.1-0.3C698.8,514.6,699.1,514.7,699.5,514.7z"/>
                            <path className="st14" d="M681.4,502.4c0-0.3,0-0.5,0-0.7c0.1-0.1,0.2-0.2,0.3-0.2c1.4-0.1,2.8-0.4,4.2-0.4c3,0.1,5.9-0.2,8.9-0.4
			c2.5-0.2,5.1-0.3,7.6-0.4c1.2-0.1,2.4-0.2,3.6-0.3c3.6-0.5,7.2-0.1,10.7-0.1c0.1,0,0.2,0.1,0.5,0.1c0.1,0.3,0.1,0.6,0.2,1
			c-1.1,0.1-2,0.3-3,0.4c-1.3,0.2-2.5,0.2-3.8,0.2c-4.9-0.2-9.8-0.2-14.7,0.5c-0.8,0.1-1.7,0-2.5,0.1c-1.1,0-2.2,0.1-3.3,0.2
			c-0.2,0-0.4,0.1-0.5,0.1c-2.4,0.1-4.7,0.2-7.1,0.3C682.2,502.6,681.9,502.5,681.4,502.4z"/>
                            <path className="st32" d="M611,512.9c-1,0.3-2,0.4-2.9,0.2c-0.5-1.5-0.9-6-1.2-13.1c1.1-0.4,2.2-0.1,3.3-0.2
			C610.7,501.7,611.3,510,611,512.9z"/>
                            <path className="st13" d="M646.9,478.3c0.1,5,1,9.8,1,14.7c-0.4,0.3-0.7,0.5-1.2,0.9c-0.5-6.7-1.4-13.2-1.3-19.8
			c2.2-0.4,4.4-0.7,6.7-0.7c-0.1,0.3-0.2,0.5-0.3,0.7c-0.8,0.9-1.5,1.7-2.3,2.6C648.4,477.7,648.4,477.7,646.9,478.3z"/>
                            <path className="st13" d="M765.5,473.3c0.2-0.1,0.3-0.3,0.4-0.3c3.3-0.1,6.5-0.2,9.8-0.3c0.2,0,0.5,0.1,0.8,0.1c0.4,1,0.2,2.1,0.3,3.1
			c0,1,0,2.1,0,3.3c-0.4-0.1-0.7-0.2-0.9-0.3c-0.6-0.5-1.2-1-1.9-1.6c-0.3,0.3-0.4,0.5-0.7,0.8c-0.8,0.1-1.7,0.2-2.6-0.3
			c-0.1-0.6-0.3-1.2-0.5-1.9C768.8,474.7,767,474.3,765.5,473.3z"/>
                            <path className="st32" d="M742.7,489.6c-0.4-4.2-0.7-8.3-1.2-12.4c1.2-0.5,2.2-0.5,3.3-0.3c0.1,0.6,0.3,1.1,0.3,1.7
			c0.2,3.7,0.4,7.3,0.5,11C744.6,489.9,743.7,489.7,742.7,489.6z"/>
                            <path className="st13" d="M614.9,499.3c0.9,0,1.6,0,2.2,0c0.2,0,0.5,0.1,0.8,0.2c0.3,1.4,0.2,2.8,0.4,4.2c0.2,1.5,0.3,2.9,0.4,4.4
			c0.1,1.4,0.3,2.8,0,4.3c-0.9-0.1-1.7-0.3-2.6-0.4c-0.4-2.1-0.5-4.3-0.7-6.4C615.1,503.5,614.7,501.5,614.9,499.3z"/>
                            <path className="st11" d="M614,486.8c0.8-0.3,1.6-0.3,2.4,0c1.3,3.6,1.6,7.4,1.5,11.3c-0.8,0-1.3,0.1-1.9,0.1c-0.4,0-0.8-0.1-1.3-0.1
			c-0.1-0.9-0.3-1.7-0.4-2.6c-0.2-2.3-0.4-4.6-0.6-6.9C613.6,487.9,613.6,487.4,614,486.8z"/>
                            <path className="st18" d="M751.4,511.4c-0.4,0.1-0.7,0.2-1,0.2c-0.2-0.2-0.3-0.2-0.3-0.3c-0.1-0.5-0.2-1-0.2-1.5
			c-0.3-5.7-0.7-11.4-1-17.1c-0.1-2.7,0-5.4,0-8.1c0-0.2,0.1-0.3,0.2-0.6c0.3,0,0.6,0,0.9,0.1c0.1,0.4,0.2,0.7,0.2,1.1
			c0.1,0.4,0.1,0.9,0.1,1.3c-0.1,4.5,0.3,9.1,0.5,13.6c0.1,3.2,0.4,6.5,0.6,9.7C751.5,510.3,751.4,510.8,751.4,511.4z"/>
                            <path className="st15" d="M707.3,514.4c1.4,0,2.7,0,4,0c1.3,0,2.7-0.2,4,0c1.3,0.2,2.5-0.5,3.8,0c0.5,0.8-0.1,1.9,0.6,2.8
			c-0.6,0.1-0.9,0.3-1.3,0.3c-1.2,0.2-9.9,0.3-10.7,0.2C707.9,516.1,707.9,516.1,707.3,514.4z"/>
                            <path className="st32" d="M780.6,515c-0.5,0-0.8,0.1-1.1,0.1c-0.1-0.1-0.1-0.2-0.2-0.2c0-0.1-0.1-0.1-0.1-0.2
			c0.1-7.8-1.1-15.6-0.9-23.6c0.5-0.4,1.2-0.2,1.7-0.1c0.1,0.1,0.2,0.2,0.2,0.2c0,0.1,0,0.1,0,0.2C780.3,499.2,779.9,507,780.6,515z
			"/>
                            <path className="st15" d="M606.2,489.3c1.2-0.1,2.2-0.2,3.3,0c0.3,1.2,0.7,6.7,0.7,9c-1.1,0.5-1.6,0.6-3.3,0.2
			C606.4,496.9,606,491.7,606.2,489.3z"/>
                            <path className="st15" d="M757.3,501.1c0.2,0.1,0.4,0.3,0.7,0.5c0.1,0.6,0.2,1.3,0.3,1.9c0.1,3.3,0.2,6.6,0.2,9.9c0,0.8-0.1,1.6-1.1,2
			c-0.3-0.1-0.7-0.3-1.1-0.4c-0.1-0.3-0.2-0.6-0.2-0.9c-0.1-2-0.2-4-0.3-6.1c-0.1-1.5-0.2-3.1-0.2-4.6
			C755.6,502.2,756.1,501.4,757.3,501.1z"/>
                            <path className="st14" d="M764.3,488.8c0.1,5.4,0.2,10.5,0.3,15.8c-0.6,0.3-1.1,0.5-1.8,0.8c-0.5-5.5-0.3-10.9-1.2-16.3
			C762.6,489,763.3,488.9,764.3,488.8z"/>
                            <path className="st15" d="M688,503.4c1.7-0.1,3.3-0.5,5-0.4c1.5,0,3,0,4.5-0.2c3.2-0.3,6.4-0.5,9.6-0.3c1.2,0.1,2.4,0,3.6,0.1
			c0.6,0,1.2-0.1,1.8,0.5c0,0.2,0,0.5,0,0.8c-0.5,0-1,0.1-1.5,0.1c-2.8-0.3-5.6,0-8.4,0c-2.7,0-5.3,0.1-8,0.1
			c-1.6,0-3.2,0.1-4.7,0.5c-0.6,0.2-1.3,0.3-2,0.1c-0.1-0.2-0.1-0.5-0.1-0.7C687.9,503.8,688,503.6,688,503.4z"/>
                            <path className="st15" d="M694.8,507.1c-0.1-0.6-0.2-1.1-0.3-1.7c1.7-0.4,3.2-0.1,4.8-0.2c1.5-0.1,3-0.1,4.6-0.2
			c1.6-0.1,3.2-0.1,4.8-0.2c0.3,0.7,0.4,1.4,0.4,2.2c-1.6,0.6-3.1,0.3-4.7,0.3c-1.6,0.1-3.2,0.1-4.7,0
			C698,507.4,696.4,507.3,694.8,507.1z"/>
                            <path className="st15" d="M745.7,502c-0.8,0.2-1.6,0.1-2.3,0.1c-0.3-1.6-0.6-9.5-0.4-11.3c0.8-0.2,1.6,0,2.5,0.1
			c0.1,0.3,0.2,0.5,0.2,0.7c0.1,3.3,0.1,6.6,0.2,9.9C745.8,501.6,745.8,501.8,745.7,502z"/>
                            <path className="st11" d="M618.7,527.2c-0.9-0.4-1.1-1.1-1.3-1.8c-0.2-0.7-0.3-1.4-0.3-2.2c-0.1-2.9-0.5-5.8-0.9-8.7
			c-0.1-0.4-0.1-0.7-0.1-1c0.3-0.4,0.6-0.3,0.9-0.2c0.5,0.1,1.1,0.3,1.6,0.4c0.1,0.1,0.2,0.3,0.2,0.5c0.2,2.6,0.4,5.1,0.5,7.7
			c0.1,1.4-0.1,2.8-0.2,4.2C619.1,526.4,618.9,526.8,618.7,527.2z"/>
                            <path className="st15" d="M612.1,522.8c-1.3,0.1-2.3,0.4-3.4-0.1c-0.2-2.7-0.5-5.5-0.7-8.3c1.1-0.2,2.2-0.2,3.3,0
			C611.6,517.1,611.9,519.8,612.1,522.8z"/>
                            <path className="st13" d="M712,486.4c2.6,2.6,4.3,5.6,6,8.8c-0.5,0.4-1,0.7-1.6,1.1c-0.8-0.5-1.1-1.3-1.7-1.9c-0.5-0.7-1-1.4-1.6-2
			c-0.5-0.7-1.1-1.3-1.7-1.9c-0.6-0.6-1.1-1.3-1.8-1.9C710.4,487.8,711.2,487.2,712,486.4z"/>
                            <path className="st15" d="M775.8,510.9c-0.4,0.1-0.8,0.2-1.2,0.3c-0.1-0.2-0.3-0.4-0.3-0.7c-0.2-5.1-0.6-10.1-0.4-15.2
			c0-0.3,0.1-0.6,0.1-0.9c0.3-0.1,0.5-0.2,0.7-0.2c0.2,0,0.5,0,0.7,0c0.1,0.2,0.2,0.4,0.2,0.5c0,1,0,2.1,0,3.1c0,3.3,0,6.6,0,9.9
			C775.6,508.8,775.7,509.8,775.8,510.9z"/>
                            <path className="st13" d="M723.3,508.7c-1.1,0-1.8,0-2.7,0c-0.2-0.5-0.5-0.9-0.7-1.5c-0.7-0.1-1.4-0.1-2.3-0.2
			c-0.1-0.8-0.3-1.7-0.4-2.5c-0.9-1-2.1-0.6-3.1-0.7c-0.4-0.4-0.5-0.9-0.1-1.5c1.5-0.2,3.2-0.4,4.8-0.6
			C720.6,503.7,721.7,506.1,723.3,508.7z"/>
                            <path className="st13" d="M726.6,511.3c0.5-0.3,0.9-0.6,1.5-1c0.4,0.5,0.7,0.9,1,1.4c0.9,1.5,1.7,2.9,2.6,4.4c0.7,1.3,1.5,2.6,2.6,3.8
			c0.2,0.2,0.3,0.4,0.4,0.6c-0.2,0.7-0.7,0.8-1.3,1c-0.2,0-0.4,0-0.5,0c-0.4-0.3-0.9-0.6-1.2-1.1c-1.7-2.9-3.3-5.8-5-8.7
			C726.6,511.7,726.6,511.6,726.6,511.3z"/>
                            <path className="st32" d="M682,513.9c-0.3-1.1-0.3-2.1-0.3-3.1c2.5-0.6,6-0.8,7.3-0.5c0,1.2,0.1,2.3,0.1,3.5
			C686.8,514.2,684.4,514.4,682,513.9z"/>
                            <path className="st32" d="M605.8,480.9c1-0.3,2.1-0.3,3.2-0.2c0.7,1.1,0.3,2.3,0.5,3.5c0.2,1.1,0.4,2.3,0.1,3.5
			c-1.1,0.5-2.2,0.3-3.2,0.4C605.8,486.7,605.4,482.8,605.8,480.9z"/>
                            <path className="st15" d="M656.8,486.3c1.7-2.3,3.3-4.4,4.8-6.6c0.6,0.1,1.2,0.3,1.8,0.3c0.6,0.1,1.2,0,1.8,0c0,0.2,0.1,0.4,0,0.5
			c-1.7,2-3,4.3-4.8,6.1C659.3,486.5,658.2,486.4,656.8,486.3z"/>
                            <path className="st15" d="M769.7,505c-0.3,0-0.4,0-0.5,0c-0.1-0.2-0.2-0.3-0.2-0.5c-0.2-5.6-0.4-11.1-0.6-16.7c0-0.2,0.1-0.5,0.2-0.7
			c0.3,0,0.6,0,1,0c0.1,0.5,0.2,0.9,0.2,1.3C769.9,493.9,770.3,499.4,769.7,505z"/>
                            <path className="st13" d="M644.4,517.4c-0.1,0.1-0.3,0.4-0.6,0.7c-0.6-0.3-1-0.5-1.3-0.7c-0.1-0.4-0.1-0.7-0.2-1
			c-0.2-2.1-0.3-4.1-0.4-6.2c0-0.2,0-0.5,0-0.7c0-0.5,0.3-0.9,0.7-1.2c0.3-0.2,0.4-0.2,1.4-0.1C644.4,509.5,644.6,512.8,644.4,517.4
			z"/>
                            <path className="st32" d="M649.6,493.8c0.4,0.1,0.8,0.1,1.2,0.2c0.2,2.4,0,4.8,0,7.2c0.1,2.3,0,4.7,0,7.1c-0.3,0.1-0.6,0.2-1,0.3
			c-0.1-0.3-0.2-0.5-0.2-0.8C649.3,504.1,649.3,495.2,649.6,493.8z"/>
                            <path className="st16" d="M629.1,480c0.9-0.1,1.6-0.1,2.4-0.1c1,0,1.9,0,3,0.1c0.2,1.1,0.1,2.2,0.2,3.2c-0.6,0.7-1.1,1.4-1.7,2.1
			c-0.9-0.4-0.9-1.4-1.7-1.9c-0.4,0-0.9,0-1.5,0.1C629.3,482.4,629.1,481.3,629.1,480z"/>
                            <path className="st21" d="M770.7,521.3c-0.5,0.4-1,0.4-1.4,0.1c-0.7-2.6-0.8-12.6-0.2-15c0.2,0,0.3,0,0.6,0c0.1,2.5,0.2,5,0.4,7.5
			C770.2,516.3,770.4,518.7,770.7,521.3z"/>
                            <path className="st13" d="M745.8,472.6c1.8,0.1,3.3,0,4.9,0.3c0.6,2.3,0.4,4.7,0.7,6.9c0.3,2.3,0.6,4.6,0.6,6.9
			c-0.5,0.3-0.6,0-0.7-0.3c-0.1-0.4-0.1-0.9-0.1-1.3c-0.1-2.3-0.2-4.5-0.3-6.8c-0.8-1-1.6-1.9-2.4-2.8
			C747.7,474.6,746.5,474,745.8,472.6z"/>
                            <path className="st18" d="M764.3,487c-0.6,1-1.4,1.4-2.5,0.9c-0.4-1-0.3-2.1-0.4-3.2c-0.1-1-0.1-2.1-0.2-3c0.6-1.1,0.6-1.1,1.6-1.7
			c0.2,0.1,0.4,0.2,0.6,0.3c0.2,0.1,0.3,0.3,0.6,0.6C764.1,483,764.2,485.1,764.3,487z"/>
                            <path className="st32" d="M722.2,510c0.4,1,0.4,2,0.4,2.9c-1.2,0.4-2.8,0.5-5,0.2c-0.2-0.5-0.3-1-0.5-1.5c-0.2-0.5-0.5-0.9-0.8-1.4
			C717.7,509.8,719.9,509.8,722.2,510z"/>
                            <path className="st18" d="M706.9,516.7c-0.4,0.8-0.9,1.1-1.6,1.2c-1.5,0.2-3,0.1-4.4-0.6c-0.4-0.2-0.6-0.5-0.9-0.8
			c0.2-1.3,1.1-1.7,2.1-1.8c1.3-0.1,2.5,0,3.7,0.4C706.5,515.4,707,515.8,706.9,516.7z"/>
                            <path className="st18" d="M765.2,515.7c-0.3,0.2-0.5,0.3-0.8,0.4c-0.3-0.1-0.5-0.3-0.8-0.4c-0.1-0.2-0.2-0.4-0.2-0.6
			c-0.1-0.7-0.1-1.5-0.2-2.2c-0.1-1.4-0.1-2.8-0.2-4.2c-0.1-0.9,0.1-1.7,0.6-2.4c0.2-0.3,0.5-0.4,0.9-0.7c0.1,0.3,0.3,0.5,0.3,0.7
			C764.9,509.5,765,512.5,765.2,515.7z"/>
                            <path className="st18" d="M636.4,521.6c-0.5,0.6-0.8,1.3-1.5,1.7c-0.6-0.5-0.4-1.3-1-1.9c-0.3-0.1-0.7-0.3-1.1-0.5
			c-0.3-0.5-0.4-3.1,0-4.2c0.3,0.6,0.6,1.1,0.9,1.6c0.3,0.1,0.6,0.1,0.9,0.2c0.5-0.5,0.9-0.9,1.4-1.4c0.1,0.2,0.3,0.2,0.3,0.3
			C636.3,518.8,636.3,520.2,636.4,521.6z"/>
                            <path className="st11" d="M776.6,521.8c-0.8,0.3-1.3,0.2-1.8-0.2c-0.4-1.4-0.6-6.7-0.3-9.4c0.4-0.1,0.7-0.1,1.1-0.2
			c0.1,0.2,0.2,0.4,0.3,0.6c0.1,1.1,0.2,2.2,0.3,3.3c0.1,1.6,0.3,3.3,0.4,4.9C776.7,521.1,776.6,521.4,776.6,521.8z"/>
                            <path className="st18" d="M634.6,488.8c-0.4,0.6-0.9,1.1-1.6,1.4c-0.4-0.7-0.8-1.4-1.2-2.1c-0.5,0.1-1,0.1-1.6,0.2
			c-0.2-1.2-0.4-2.4-0.4-3.6c0.1-0.2,0.2-0.3,0.4-0.4c0.1-0.1,0.3-0.1,0.5-0.2c0.2,0.1,0.3,0.2,0.4,0.3c0.4,0.7,0.6,1.5,1.4,1.8
			c1,0.2,1.2-0.9,2-1.3C634.8,486.3,634.7,487.6,634.6,488.8z"/>
                            <path className="st15" d="M750.3,512.6c0.4,0,0.8,0,1.1,0c0.1,0.1,0.2,0.2,0.2,0.2c0,0,0.1,0.1,0.1,0.1c0.4,3,0.8,6,1.2,9.1
			c-0.5,0.4-1.1,0.3-1.7,0.1c-0.4-1-0.1-2-0.3-3.1c-0.2-1.1-0.3-2.2-0.4-3.3C750.2,514.8,749.9,513.8,750.3,512.6z"/>
                            <path className="st15" d="M644.7,500.7c-1-0.3-1.9-0.4-2.7-0.7c0-0.2,0-0.3,0-0.3c0.2-0.2,0.4-0.4,0.6-0.6c1.7-1.5,3.3-3,5-4.5
			c0.4,1.1,0.4,1.1,0.3,3.1C646.9,498.6,645.8,499.6,644.7,500.7z"/>
                            <path className="st13" d="M659.1,492.7c-1,0.2-1.9,0.2-2.8,0.1c-0.4-0.7-0.4-1.4-0.5-2.1c-0.1-0.7-0.2-1.4-0.3-2.1
			c1.5-0.8,1.7-0.8,3.5-0.5C659.3,489.5,659.2,491,659.1,492.7z"/>
                            <path className="st32" d="M755.6,500.7c-0.3-2-0.5-3.9-0.7-5.7c0.8-0.6,1.6-0.7,2.5-0.9c0.5,1,0.3,2,0.4,2.9c0.1,1,0.1,1.9,0.2,3
			C757.3,500.2,756.6,500.4,755.6,500.7z"/>
                            <path className="st13" d="M645,525.9c-0.7,0-1.3,0-1.8,0c-0.3-1.3-0.7-5.5-0.6-7c0.6,0,1.3,0,2,0C645,521.2,645.4,523.4,645,525.9z"/>
                            <path className="st15" d="M639.7,474.9c-1.1,1.6-2.7,2.5-4.2,3.6c-1.4,0-2.7,0-4.1,0c-0.2-0.4,0.2-0.5,0.4-0.7c1.5-0.9,3.1-1.7,4.4-3
			c1.1-0.1,2.3-0.1,3.4-0.2C639.6,474.7,639.6,474.8,639.7,474.9z"/>
                            <path className="st13" d="M719.6,501c0.3-0.9,0.8-1.3,1.5-1.5c1.3,0.9,2.8,3.2,3.4,5.2c-0.6,1-0.6,1-1.7,1.1
			C721.8,504.2,720.7,502.6,719.6,501z"/>
                            <path className="st15" d="M742.8,473.8c0.5-0.1,0.8-0.1,1.2-0.2c0.5,0,1.1,0,1.5,0c1.3,0.9,3.7,3.4,4.5,4.7c-0.5,0.5-1.3,0.6-1.9,1
			c-0.7-0.7-1.4-1.4-2-2c-0.1-0.4-0.2-0.8-0.3-1.2c-0.5-0.3-1.1-0.7-1.7-1.1C743.5,474.7,742.9,474.5,742.8,473.8z"/>
                            <path className="st32" d="M758.9,521.9c-0.9,0.1-1.6,0.2-2.5-0.2c-0.2-1.8-0.3-3.6-0.2-5.4c0.8,0,1.4,0,2.1,0
			C758.9,517.6,759.2,520.2,758.9,521.9z"/>
                            <path className="st13" d="M644,506.8c-0.9,0.2-1.6,0.4-2.3,0.6c-0.5-1.8-0.9-4.8-0.8-6.1c0.9,0,1.8,0.2,2.6,0.7c0,0.3,0.1,0.6,0.1,0.9
			C643.7,504.1,643.8,505.4,644,506.8z"/>
                            <path className="st15" d="M649,485.7c0.9-0.4,0.9-0.4,1.7-0.2c0.3,1.5,0.4,5.2,0.1,7c-0.4,0.4-0.9,0.3-1.2-0.1
			C649.3,491.5,648.9,487,649,485.7z"/>
                            <path className="st15" d="M716.2,505.1c0.2,0.6,0.3,1.2,0,1.8c-0.1,0.1-0.2,0.1-0.3,0.2c-0.2,0-0.4,0.1-0.5,0.1c-1.8,0-3.5,0-5.3,0
			c-0.3-0.7-0.5-1.3-0.4-1.9c0.1-0.1,0.2-0.2,0.3-0.2c1.9-0.1,3.9-0.1,5.8-0.1C715.9,504.9,716,505,716.2,505.1z"/>
                            <path className="st15" d="M722.6,522.1c-0.8,0.1-1.5,0.2-2.1,0.2c-0.4,0-0.7,0-1.1,0c-0.4-1.2-0.2-2.3-0.3-3.4
			c1.1-0.6,2.1-0.4,3.1-0.4C722.8,519.6,722.5,520.7,722.6,522.1z"/>
                            <path className="st13" d="M723.7,509.5c1.3,1.6,2.3,3.3,3.2,5.2c-0.7,0.4-1.3,0.2-2,0.2c-0.7,0-1.5,0-2.2,0c-0.7,0-1.3,0-2.3,0
			c0.4-0.4,0.5-0.6,0.7-0.6c0.8-0.2,1.6-0.1,2.3-0.6C724,512.4,723.5,511,723.7,509.5z"/>
                            <path className="st32" d="M612.1,527.8c-1.1,0.3-2,0.3-3-0.2c-0.3-1.1-0.3-2.3-0.4-3.4c1.4-0.4,2-0.3,3.3,0.1
			C612.3,525.4,612.3,526.5,612.1,527.8z"/>
                            <path className="st15" d="M687,519.9c0.1,1.3,0.4,2.4,0.2,3.5c-1.4,0.4-1.4,0.4-2.9,0.1c-0.3-1.1-0.3-2.3-0.4-3.6
			C684.9,519.7,685.9,519.8,687,519.9z"/>
                            <path className="st15" d="M651.4,518.5c-0.5-0.1-0.8-0.2-1.1-0.2c-0.1-0.4-0.3-0.8-0.3-1.2c-0.2-1.8-0.3-6.1-0.1-7.2
			c0.3-0.1,0.6-0.2,0.8-0.3c0.2,0.2,0.3,0.3,0.3,0.4c0.1,0.5,0.1,1,0.2,1.4c0.1,2,0.2,4,0.2,6C651.4,517.6,651.4,517.9,651.4,518.5z
			"/>
                            <path className="st32" d="M779.9,484.5c0.6,1.8,0.6,3.4,0.3,5.4c-0.6,0.2-1.2,0.3-1.8,0.1c-0.3-1.7-0.5-4-0.3-5.1
			C778.9,484.4,778.9,484.4,779.9,484.5z"/>
                            <path className="st15" d="M757.1,493c-1,0.3-1.7,0.5-2.4,0.7c-1.3-1-2.2-2.4-3.3-3.5c-0.1-0.8-0.2-1.4,0.1-2.2c0.8,0.3,1.3,1,1.8,1.5
			c0.6,0.5,1.1,1.1,1.7,1.7C755.5,491.8,756.2,492.3,757.1,493z"/>
                            <path className="st15" d="M702.7,508.7c-6.2,0.1-12.4,0.3-18.5,0.4c0,0-0.1-0.1-0.1-0.1c0-0.1,0.1-0.2,0.1-0.3
			c1.3-0.3,8.3-0.7,9.4-0.6C696.6,508.4,699.6,508.6,702.7,508.7z"/>
                            <path className="st15" d="M693.6,505.2c0.1,0.7,0.1,1.1,0.2,1.6c-2.5,0.2-4.9,0.6-7.6,0.7c0-0.6,0.1-0.9,0.1-1.3
			c0.8-0.5,1.6-0.4,2.4-0.4c0.8-0.1,1.6-0.2,2.3-0.3C691.8,505.3,692.6,505.3,693.6,505.2z"/>
                            <path className="st13" d="M624.2,493.9c-0.3,0.1-0.6,0.3-0.9,0.4c-0.5-1.8-1.1-6.9-1.1-8.5c0.4-0.1,0.7-0.1,1.1-0.2
			C623.8,487.2,624.3,491.7,624.2,493.9z"/>
                            <path className="st13" d="M623.7,507.4c-0.3-2.5-0.3-5.8,0-6.9c0.7,0.2,1.1,0.8,1.6,1.3c0.2,1.5,0.1,3.1,0.1,4.7
			C625,507,624.4,507.2,623.7,507.4z"/>
                            <path className="st32" d="M648.1,479.4c0.8,0,1.3,0,1.8,0c0.5,1.4,0.7,3.5,0.6,4.9c-0.5,0.1-1,0.2-1.6,0.3c-0.5-0.8-0.4-1.8-0.5-2.6
			C648.3,481.2,648.2,480.3,648.1,479.4z"/>
                            <path className="st15" d="M774.7,486.8c-0.7,0.3-1.2,0.4-1.8,0.6c-0.7-0.6-1.4-1.1-2.1-1.7c-0.3-1-0.3-2-0.2-3.1c0.2,0,0.4,0,0.5,0.1
			c1,1.1,2.1,2.2,3.1,3.3C774.4,486.2,774.5,486.4,774.7,486.8z"/>
                            <path className="st21" d="M768,477.6c0.2-0.1,0.3-0.2,0.5-0.3c0.1-0.1,0.2-0.1,0.5-0.1c0.1,0.2,0.3,0.4,0.4,0.7
			c0.1,0.2,0.1,0.5,0.1,0.7c0,2.4,0.1,4.8,0.1,7.3c-0.3,0.1-0.6,0.1-0.8,0.1c-0.1-0.2-0.3-0.2-0.3-0.3c-0.1-0.7-0.1-1.3-0.1-2
			c-0.1-1.5-0.2-2.9-0.3-4.4C768,478.7,768,478.1,768,477.6z"/>
                            <path className="st32" d="M651.9,525.5c-0.6,0.1-1,0.1-1.3,0.2c-0.1-0.2-0.3-0.2-0.3-0.3c0-1.8-0.1-3.6-0.1-5.5c0-0.1,0.1-0.2,0.2-0.4
			c0.3,0,0.7,0,1.1,0.1C652,521.4,652.1,523.4,651.9,525.5z"/>
                            <path className="st15" d="M615.8,485.3c0.3-0.4,0.4-0.5,0.5-0.6c2-1.5,4.1-2.9,6.1-4.3c0.5-0.4,1.2-0.7,1.8-0.9c0.2-0.1,0.6,0,1.1,0
			c-1.2,1.2-2.4,2-3.6,2.9c-1.5,1-3,1.8-4.5,2.7C616.8,485.1,616.5,485.1,615.8,485.3z"/>
                            <path className="st15" d="M655.7,473.2c-1.3,1.8-2.6,3.4-4,4.9c-0.6,0-1.1-0.1-1.7-0.1c-0.2-0.5,0.1-0.6,0.3-0.8
			c0.8-0.9,1.7-1.8,2.5-2.6c0.4-0.4,0.9-0.8,1.4-1.2C654.6,473.1,655,473.1,655.7,473.2z"/>
                            <path className="st14" d="M763.4,517c0.8,0,1.2,0,1.7,0c0.1,0.1,0.2,0.2,0.2,0.3c0.2,1.3,0.3,2.6,0.5,4c-0.7,0.5-1.3,0.5-2,0.4
			C763.5,520.2,763.7,518.6,763.4,517z"/>
                            <path className="st14" d="M761.1,479.9c-0.2-1.2-0.3-2.2-0.1-3.3c0.8-0.4,1.7-0.3,2.5-0.6c0.5,1.1,0.4,2.1,0.3,3.1
			c-0.5,0.5-1,0-1.4,0.1C762.1,479.5,761.7,479.6,761.1,479.9z"/>
                            <path className="st11" d="M773.9,493.2c0-1.8,0-3.3,0-4.8c0.4-0.4,0.8-0.6,1.4-0.4c0.4,1.4,0.4,3.3,0.1,5.1
			C775,493.1,774.6,493.2,773.9,493.2z"/>
                            <path className="st15" d="M725.3,505.6c0.8,1.3,1.5,2.5,2.1,3.6c-0.1,0.8-0.6,1.1-1.3,1.3c-1.1-1-1.8-2.4-2.7-3.6
			C724,506.4,724.6,506.1,725.3,505.6z"/>
                            <path className="st13" d="M626.1,514.8c0.1,1,0.1,1.7,0.2,2.5c-0.6,0.5-1.1,1-1.7,1.5c-0.6-1.6-0.8-3.9-0.5-5.3
			c0.2,0.1,0.3,0.1,0.5,0.2C625,514,625.5,514.3,626.1,514.8z"/>
                            <path className="st15" d="M779,483.3c-0.7,0-1.2,0-1.6,0c-1.5-1.4-3-2.7-4.5-4c0-0.1,0-0.2,0-0.2c0.4-0.1,0.8-0.2,1.2-0.2
			C775.9,479.9,778.2,482,779,483.3z"/>
                            <path className="st15" d="M760.2,475.5c-0.8-0.8-1.6-1.5-2.5-2.2c0.8-0.3,0.8-0.3,1.7-0.3c0.6,0,1.2,0,1.8,0c0.8,0.5,1.5,1,2.1,1.9
			C762.2,475.3,761.2,475.3,760.2,475.5z"/>
                            <path className="st32" d="M781.5,520.8c-0.8,0.1-1.2,0.1-1.7,0.1c-0.9-1.6-0.4-3.3-0.4-5c0.5,0,0.9,0.1,1.3,0.1
			C781.3,517.5,781,519.1,781.5,520.8z"/>
                            <path className="st15" d="M740.3,474c1,0.4,1.9,0.8,2.7,1.7c-1,0.3-1.8,0.5-2.7,0.7c-0.4-0.2-1-0.5-1.5-0.8c-0.5-0.3-1.1-0.4-1.6-1.1
			C738.3,474.4,739.3,474.2,740.3,474z"/>
                            <path className="st16" d="M633.1,522.2c0.3,0.7,0.5,1.3,0.7,1.8c0.9,0.7,1.6,0.4,2.4-0.1c0.3,0.7,0.4,1.3,0.1,2c-1.1,0-2.3,0-3.5,0
			c-0.4-1-0.3-2.2-0.2-3.3C632.6,522.5,632.8,522.4,633.1,522.2z"/>
                            <path className="st15" d="M763,473.1c0.2,0,0.4-0.1,0.5,0c1.5,1.1,3.6,1.2,5,2.6c-0.3,0.6-0.8,0.7-1.3,1c-0.7-0.3-1.4-0.5-1.8-0.7
			C764.5,474.9,763.8,474,763,473.1z"/>
                            <path className="st13" d="M627.1,525c0,0.8,0,1.4,0,2c-0.8,0.2-1.5,0.3-2.2,0.1c-0.4-1.5-0.4-2,0-3.5
			C625.6,524.1,626.3,524.5,627.1,525z"/>
                            <path className="st15" d="M719.1,508.4c-1.7,0.6-5.6,0.6-10.7,0.2c1.4-0.3,2.5-0.6,3.6-0.5c1.2,0,2.4,0,3.7,0
			C716.9,508.2,718,507.9,719.1,508.4z"/>
                            <path className="st15" d="M720.6,498.4c-0.6,0.5-1.1,0.9-1.7,1.4c-0.6-0.8-1.2-1.6-1.8-2.3c0.2-0.9,0.9-1.1,1.6-1.1
			C719.6,496.8,720,497.6,720.6,498.4z"/>
                            <path className="st15" d="M659.8,518.2c0.4,0.8,0.2,1.7,0.1,2.5c-1,0.4-1,0.4-2.2,0.1c-0.3-0.7-0.2-1.5-0.2-2.3
			C658.3,518.1,659,518,659.8,518.2z"/>
                            <path className="st13" d="M657.9,524.3c-0.4-0.7-0.3-1.4-0.2-2.2c0.7-0.4,1.4-0.2,2.1-0.1c0.3,0.9,0.3,1.8,0.1,2.8
			C659.1,525,658.5,524.8,657.9,524.3z"/>
                            <path className="st15" d="M626.3,518.5c0.4,1.8,0.6,3.2,0.5,4.8c-1.1-0.4-1.7-1-2.1-2C624.8,520.2,625.4,519.5,626.3,518.5z"/>
                            <path className="st18" d="M690.2,510.3c0.6,0,1.1,0,1.6,0c0,1.2-0.1,2.2-0.1,3.4c-0.7,0.4-1.2-0.1-1.6-0.6
			C689.4,512.1,689.6,511.2,690.2,510.3z"/>
                            <path className="st15" d="M625.7,512.8c-1.3-1-1.8-1.8-1.7-2.9c0-0.9,0.3-1.2,1.2-1.8C625.6,509.2,625.8,510.2,625.7,512.8z"/>
                            <path className="st15" d="M625,499.7c-1.2-0.7-1.5-1.6-1.5-3.1c0-0.7,0.1-1.3,1-1.6C625,496.5,625.2,498,625,499.7z"/>
                            <path className="st18" d="M713.6,510c0.4,0,0.7,0,1.1,0c0.7,0.8,1.8,1.5,1.7,3c-0.5,0.1-0.9,0.1-1.3,0.2
			C714.5,512.1,714.1,511.2,713.6,510z"/>
                            <path className="st15" d="M687.9,519.9c0.5,0,0.9,0,1.3,0c0.2,1.2,0.3,2.3,0.2,3.5c-0.3,0-0.5,0.1-0.8,0.1
			C688,522.4,688.1,521.2,687.9,519.9z"/>
                            <path className="st15" d="M750,479.9c0,1,0,2,0,3.2c-0.3,0.1-0.5,0.1-0.9,0.2c-0.1-1-0.2-2-0.3-2.9C748.9,479.8,749.2,479.6,750,479.9
			z"/>
                            <path className="st15" d="M656.4,493.9c1-0.2,1.8-0.1,2.7-0.1c0.1,0.4,0.1,0.7,0.2,1c-0.9,0.5-1.7,0.2-2.6,0.3
			C656.5,494.7,656.5,494.3,656.4,493.9z"/>
                            <path className="st15" d="M739.9,472.3c1.5-0.1,2.9-0.2,4.3,0.2c0,0.5-0.4,0.4-0.5,0.4c-1.1,0-2.2,0-3.3-0.1c-0.1,0-0.2-0.1-0.3-0.2
			C740,472.6,740,472.5,739.9,472.3z"/>
                            <path className="st15" d="M612.6,478.4c-0.9,0.4-1.9,0.8-2.9,1.3c-0.3-0.1-0.6-0.3-1.1-0.5C610.1,478.6,611.2,477.9,612.6,478.4z"/>
                            <path className="st15" d="M717.4,518.9c0.3,0,0.4-0.1,0.6-0.1c0.4,0.3,0.4,2.1,0,3.4C717.4,521.4,717.4,521.3,717.4,518.9z"/>
                            <path className="st15" d="M626,482.1c0.3-1,1-1.3,1.9-1.8C627.2,481.3,627.2,481.3,626,482.1z"/>
                            <path className="st32" d="M647.3,395.3c-0.5,0.9-1.3,1.3-2.3,1.3c-1.4,0.1-2.3-1-2.1-2.3c0.1-0.5,0.4-0.9,0.5-1.3c0.8,0,1.4,0,2.1,0
			C646.6,393.5,647.4,394,647.3,395.3z"/>
                            <path className="st32" d="M665,392.6c1.1,0.4,2.1,0.9,2.4,2.1c-0.2,1.2-1.1,1.5-2.1,1.6c-1,0.1-1.8-0.2-2.1-1.3
			C663.5,393.5,663.5,393.5,665,392.6z"/>
                            <path className="st32" d="M657.5,394.2c-0.1,0.8-0.6,1.3-1.2,1.6c-0.6,0.3-1.2,0.3-1.8,0.1c-1.2-0.3-1.7-1.4-1.2-2.6
			c0.1-0.2,0.2-0.3,0.3-0.5c0.2-0.2,0.3-0.3,0.5-0.4c0.9-0.3,1.6-0.1,2.3,0.3C656.9,393,657.3,393.4,657.5,394.2z"/>
                            <path className="st32" d="M635,391.8c0.7,0.3,1.3,0.5,1.7,0.8c0.9,0.6,1.1,1.6,0.4,2.5c-0.7,1-2,1.3-2.8,0.7c-0.9-0.7-1.1-2.1-0.4-3.1
			C634.1,392.4,634.5,392.2,635,391.8z"/>
                            <path className="st32" d="M676.1,395.1c-0.5,0.7-1.3,0.8-2.1,0.7c-0.7-0.1-1.3-0.3-1.6-1c0.1-0.9,0.7-1.5,1.5-1.8
			c0.7,0,1.4,0.2,1.8,0.7C676.1,394,676.3,394.5,676.1,395.1z"/>
                            <path className="st32" d="M628.4,392.8c0,0.7-0.2,1.2-0.5,1.7c-0.5,0.7-1.1,0.9-1.7,0.7c-0.6-0.2-1-1-0.9-1.8c0.1-0.6,0.3-1,0.8-1.4
			C627.5,391.9,627.5,391.9,628.4,392.8z"/>
                            <path d="M738.1,485.3c1,1,1.4,2.1,1.5,3.4c0.3,2.7,0.6,5.4,0.7,8c0.1,1.5,0.1,3.1,0,4.6c0,0.9-0.1,1.7-0.3,2.5
			c-0.2,0.7-0.5,1.4-1.4,1.6c-0.7,0-1.2-0.4-1.5-1.1c-0.1-0.2-0.2-0.3-0.2-0.5c-0.9-4.3-2.1-8.6-1.6-13.1c0.1-1.2,0.4-2.4,0.8-3.6
			C736.4,486.3,737,485.6,738.1,485.3z M738.6,504.4c0.2-0.3,0.4-0.5,0.5-0.7c0.1-0.6,0.3-1.2,0.3-1.8c0.2-4.4-0.2-8.8-0.8-13.2
			c-0.1-0.5-0.3-1.1-0.5-1.6c-0.1-0.3-0.2-0.6-0.8-0.5c-0.7,1-0.8,2.2-1,3.4c-0.6,4.7,0.6,9.1,1.6,13.6
			C738.1,503.8,738.3,504,738.6,504.4z"/>
                            <path className="st21" d="M738.6,504.4c-0.2-0.3-0.5-0.5-0.6-0.8c-1.1-4.5-2.2-8.9-1.6-13.6c0.2-1.2,0.3-2.4,1-3.4
			c0.5,0,0.7,0.3,0.8,0.5c0.2,0.5,0.4,1,0.5,1.6c0.6,4.4,0.9,8.8,0.8,13.2c0,0.6-0.2,1.2-0.3,1.8C739,503.9,738.8,504.1,738.6,504.4
			z"/>
	</g>
                        <g className="estante-3 white">
		<g>
			<path d="M477,284.1c0,0.8,0.1,1.5,0.1,2.1c0.3,20.6,0.5,41.2,0.8,61.8c0.2,18.7,0.5,37.4,0.7,56.1c0.3,19.9,0.5,39.8,0.7,59.7
				c0.2,17.9,0.4,35.8,0.6,53.7c0,0.9,0.2,1.9,0.3,2.8c0.1,0.8,0.2,1.6,0.2,2.4c0,5.8-0.1,11.6-0.5,17.4c0,0.7-0.1,1.5,0.1,2.2
				c0.1,0.3-0.1,0.8-0.1,1.3c-0.5,0.1-0.9,0.3-1.3,0.3c-0.9,0-1.7,0-2.6-0.1c-4.1-0.3-8.3-0.3-12.4-0.1c-7.4,0.4-14.7,0.2-22.1,0.4
				c-25.9,0.5-51.9,0.4-77.8,0.5c-8.2,0-16.3,0.2-24.5,0.3c-2.3,0-4.6-0.1-6.9,0c-1.4,0-2.8,0.2-4.2,0.3c-1,0.1-2.1,0.1-3.1,0.2
				c-0.2,0-0.3-0.1-0.4-0.2c-0.4-0.4-0.4-0.9-0.4-1.4c-0.1-2.6-0.2-5.1-0.2-7.7c-0.3-14.4-0.6-28.8-0.9-43.2
				c-0.2-11.8-0.5-23.5-0.7-35.3c-0.2-12.1-0.5-24.3-0.7-36.4c-0.2-11.9-0.4-23.9-0.7-35.8c-0.3-15.1-0.6-30.2-0.9-45.2
				c-0.3-16.6-0.6-33.2-1-49.7c0-0.2,0-0.4,0-0.6c-0.2-0.3-0.4-0.6-0.5-0.9c0.4-0.7,0.9-0.8,1.6-0.8c3.2-0.2,6.4-0.6,9.7-0.7
				c14.8-0.7,29.6-1,44.4-1.3c14.8-0.3,29.6-0.7,44.4-1c10.5-0.2,20.9-0.5,31.4-0.7c7.6-0.2,15.2-0.3,22.8-0.5
				C474.2,283.8,475.5,284,477,284.1z M321.2,289.9c0,0.4-0.1,0.8-0.1,1.2c0,0.9,0.1,1.8,0.1,2.6c0.3,11.8,0.5,23.5,0.7,35.3
				c0.3,15.7,0.5,31.4,0.8,47.1c0.3,14.5,0.6,29,0.9,43.5c0.3,14.1,0.6,28.3,0.8,42.4c0.2,12.1,0.5,24.2,0.7,36.2
				c0.3,14.6,0.5,29.2,0.8,43.7c0,0.3,0.1,0.7,0.2,1.1c1.4-0.2,2.6-0.4,3.9-0.5c2.9-0.1,5.8-0.3,8.8-0.3c5.7-0.1,11.5,0,17.2-0.1
				c14.1-0.2,28.3-0.5,42.4-0.6c13.5-0.2,26.9-0.3,40.4-0.3c9.1-0.1,18.3-0.1,27.4-0.1c3,0,6.1-0.1,9.1,0.2c0.6,0.1,1.2,0.2,1.9-0.2
				c0.1-0.4,0.1-0.7,0.2-1.1c0.4-5.8,0.5-11.6,0.6-17.4c0-1-0.2-1.8-0.8-2.6c-0.2-10.5-0.4-20.9-0.5-31.4
				c-0.2-12.6-0.3-25.3-0.5-37.9c-0.3-21.3-0.6-42.5-0.9-63.8c-0.3-25-0.6-50.1-1-75.1c-0.1-8-0.2-16-0.3-24c0-0.7,0.2-1.4-0.3-2
				c-1.3-0.3-2.5-0.1-3.8-0.1c-7.5,0.2-15,0.5-22.5,0.7c-10,0.3-20,0.6-30,0.9c-5.8,0.2-11.6,0.4-17.3,0.6
				c-5.9,0.2-11.8,0.3-17.7,0.5c-9.9,0.3-19.7,0.7-29.6,0.9c-10.1,0.2-20.2,0.3-30.3,0.4C322.1,289.8,321.7,289.8,321.2,289.9z"/>
            <path className="st14" d="M321.2,289.9c0.5,0,0.9-0.1,1.3-0.1c10.1-0.1,20.2-0.2,30.3-0.4c9.9-0.2,19.7-0.6,29.6-0.9
				c5.9-0.2,11.8-0.3,17.7-0.5c5.8-0.2,11.6-0.4,17.3-0.6c10-0.3,20-0.6,30-0.9c7.5-0.2,15-0.5,22.5-0.7c1.3,0,2.5-0.2,3.8,0.1
				c0.5,0.6,0.3,1.4,0.3,2c0.1,8,0.2,16,0.3,24c0.3,25,0.6,50.1,1,75.1c0.3,21.3,0.6,42.5,0.9,63.8c0.2,12.6,0.3,25.3,0.5,37.9
				c0.2,10.4,0.4,20.9,0.5,31.4c0.5,0.8,0.8,1.6,0.8,2.6c-0.1,5.8-0.2,11.6-0.6,17.4c0,0.4-0.1,0.7-0.2,1.1
				c-0.7,0.3-1.3,0.2-1.9,0.2c-3-0.3-6.1-0.2-9.1-0.2c-9.1,0.1-18.3,0.1-27.4,0.1c-13.5,0.1-26.9,0.2-40.4,0.3
				c-14.1,0.2-28.3,0.4-42.4,0.6c-5.7,0.1-11.5,0.1-17.2,0.1c-2.9,0-5.8,0.1-8.8,0.3c-1.3,0.1-2.5,0.3-3.9,0.5
				c-0.1-0.4-0.2-0.7-0.2-1.1c-0.3-14.6-0.5-29.2-0.8-43.7c-0.2-12.1-0.5-24.2-0.7-36.2c-0.3-14.1-0.5-28.3-0.8-42.4
				c-0.3-14.5-0.6-29-0.9-43.5c-0.3-15.7-0.5-31.4-0.8-47.1c-0.2-11.8-0.5-23.5-0.7-35.3c0-0.9-0.1-1.8-0.1-2.6
				C321.1,290.7,321.2,290.3,321.2,289.9z M327.4,353.7c1.1,0,2,0,2.9,0c0.6,0,1.2,0.1,1.8,0.2c0.8,0.1,1.7,0.2,2.5,0.3
				c4.1,0,8.3,0,12.4,0c10.9,0.1,21.8-0.1,32.7-0.5c7.2-0.3,14.4-0.2,21.6-0.1c6.5,0.1,13,0.2,19.5,0.1c2.5,0,5.1,0.2,7.6-0.5
				c0.4-0.1,0.8,0,1.3,0c2.5,0,5,0,7.5-0.1c4-0.1,8-0.1,12-1c0.2,0,0.4,0,0.5,0c5.5-0.2,10.9-0.7,16.4-0.6c0.6,0,1.2-0.1,1.8-0.1
				c0.7-0.1,1.3-0.1,2-0.2c0-0.7,0.1-1.3,0.1-1.9c0-2.8,0.1-5.6,0.1-8.4c0.1-9.9,0.1-19.8-0.6-29.6c-0.4-5.3-0.7-10.6-1.1-15.9
				c-0.1-1-0.2-2-0.4-3c-0.1-0.4-0.2-0.8-0.3-1.1c-0.6-0.5-1.3-0.4-1.9-0.4c-1.8,0-3.7-0.1-5.5,0c-7.5,0.2-15.1,0.3-22.6,0
				c-10.9-0.5-21.8,0.2-32.7,1c-5.3,0.4-10.7,0.8-16,0.9c-10,0.3-20,0.3-30,0.5c-4.2,0.1-8.4-0.1-12.6-0.3
				c-4.4-0.3-8.8-0.5-13.1-0.1c-2.5,0.2-4.9,0.8-7.3,1.3c-0.5,0.9-0.5,1.8-0.5,2.6c0,2,0,4,0,6c0.1,10.5,0.2,21,0.4,31.5
				c0.1,6.1,0.4,12.1,1,18.2C327.2,352.5,327.3,353,327.4,353.7z M327.8,358.2c0.2,3.6,0.4,7.2,0.6,10.7c0.2,3.6,0.4,7.1,0.5,10.7
				c0.1,3.6,0.2,7.3,0.3,10.9c0.1,3.6,0.2,7.1,0.3,10.7c0.1,3.6,0.2,7.1,0.3,10.6c-0.2,0.6-0.4,1-0.5,1.4c0.3,0.9,0.9,0.9,1.5,1
				c3.4,0.6,6.8,0.9,10.2,1c0.9,0,1.8-0.1,2.7-0.1c2.6,0.1,5.2,0.4,7.8,0.6c9.3,0.5,18.5,0.4,27.8-0.1c7.7-0.4,15.3-0.8,23-1.1
				c8.6-0.3,17.2-0.6,25.8-0.8c8.2-0.2,16.3-0.4,24.5-0.6c4.7-0.1,9.3-0.9,14-1.6c0.6-0.1,1.2-0.2,1.8-0.8c0-0.4,0-1,0-1.5
				c0-1.6,0-3.1,0-4.7c0-10.4,0.7-20.8,1.3-31.1c0.3-5.1,0.4-10.1,0.6-15.2c0-0.5-0.1-1.1-0.1-1.8c-1.5-0.1-3-0.3-4.4-0.2
				c-6.4,0.5-12.9,0.6-19.4,0.5c-1.7,0-3.4,0.1-5.1-0.5c-0.3-0.1-0.7-0.1-1.1-0.1c-0.9,0-1.8-0.1-2.7-0.1c-4.1-0.1-8.3-0.2-12.4-0.3
				c-2.4,0-4.8-0.1-7.1,0.1c-4.4,0.3-8.9,0.7-13.3,1c-4.7,0.3-9.4,0.7-14,0.9c-8.3,0.4-16.6,0.6-24.8,0.9
				c-9.8,0.3-19.6,0.2-29.4-0.6c-1.4-0.1-2.8-0.2-4.2-0.1C330.6,357.9,329.2,357.9,327.8,358.2z M331,476c1.3,0.5,2.4,0.3,3.5,0.4
				c6,0.4,11.9,0.5,17.9,0.6c11.3,0.2,22.6,0,34,0.5c9,0.5,18,0.4,27,0.6c3,0,6.1,0,9.1-0.1c8.9-0.4,17.9-0.4,26.8-1.1
				c4.7-0.4,9.3-0.9,14-1.3c2.1-0.2,4.2-0.4,6.3-0.6c0.3,0,0.7-0.1,1.1-0.2c0-0.7,0.1-1.2,0.1-1.8c0.2-9.1-0.3-18.3-0.8-27.4
				c-0.3-5.2-0.6-10.4-0.9-15.5c-0.2-4-0.4-8-0.6-12c0-0.6,0-1.3-0.3-1.8c-0.6-0.4-1.3-0.3-2-0.2c-2.5,0.3-5.1,0.5-7.6,1.1
				c-3.3,0.8-6.6,1.3-9.9,1.4c-2.8,0.1-5.6,0-8.4,0c-5.2,0-10.4,0-15.5-0.1c-5.1-0.1-10.1-0.3-15.2-0.3c-14.1-0.1-28.3-0.2-42.4-0.2
				c-10.7,0-21.3-0.1-32-0.4c-1.4,0-2.8,0-4.2,0.1c-0.3,0-0.6,0.2-0.9,0.3c-0.1,0.3-0.1,0.6-0.1,0.9
				C330.2,438.1,330.2,457.1,331,476z M470.4,479.7c-1,0-1.9,0-2.9-0.1c-2.1-0.3-4.1-0.1-6.2,0.2c-2.7,0.3-5.3,0.6-8,0.9
				c-2.7,0.3-5.4,0.6-8.2,1c-3.9,0.5-7.7,1.1-11.6,1.6c-4.6,0.5-9.2,0.5-13.9,0.4c-6.3-0.2-12.7-0.4-19-0.6
				c-12.7-0.4-25.3-0.4-38-0.2c-7.7,0.1-15.3,0.2-23-0.2c-2.4-0.1-4.9-0.2-7.3-0.3c-0.3,0-0.7,0.1-1.1,0.1c-0.1,0.4-0.2,0.7-0.2,1
				c0.3,6.8,0.5,13.5,0.9,20.3c0.3,5.5,0.7,11,0.7,16.5c0,4.6,0.1,9.3,0.1,13.9c0,0.3,0,0.6-0.1,0.8c-0.3,0.6-1.2,0.8-1,1.7
				c0.6,0.2,1.2,0.3,1.5,0.4c0.8-0.1,1.3-0.3,1.9-0.3c3.3-0.2,6.7-0.3,10-0.5c6.3-0.2,12.7-0.4,19-0.1c9.4,0.4,18.9,0.4,28.3,0.1
				c9.3-0.3,18.5-0.4,27.8,0c6.6,0.3,13.1,0.6,19.7,0.8c2.6,0.1,5.2,0.1,7.9,0c4.3-0.2,8.6-0.5,13-0.7c1.4-0.1,2.8,0.1,4.2,0.1
				c2.1,0,4.3,0.1,6.3-1.2c0.1-0.3,0.2-0.7,0.3-1.1c0.1-2.2,0.1-4.4,0.2-6.9c-0.1-0.2-0.3-0.7-0.4-1.1c-0.2-15-0.4-29.9-0.5-44.8
				c0-0.1,0-0.3,0-0.4c0-0.1,0-0.2,0-0.4c0-0.1,0-0.1-0.1-0.2C470.6,480,470.6,480,470.4,479.7z"/>
            <path d="M327.4,353.7c-0.1-0.7-0.2-1.2-0.3-1.7c-0.6-6-0.9-12.1-1-18.2c-0.2-10.5-0.3-21-0.4-31.5c0-2-0.1-4,0-6
				c0-0.9,0-1.8,0.5-2.6c2.4-0.5,4.8-1.1,7.3-1.3c4.4-0.4,8.8-0.2,13.1,0.1c4.2,0.3,8.4,0.4,12.6,0.3c10-0.1,20-0.2,30-0.5
				c5.4-0.1,10.7-0.5,16-0.9c10.9-0.8,21.7-1.5,32.7-1c7.5,0.3,15.1,0.2,22.6,0c1.8,0,3.7,0,5.5,0c0.6,0,1.2,0,1.9,0.4
				c0.1,0.3,0.3,0.7,0.3,1.1c0.2,1,0.3,2,0.4,3c0.4,5.3,0.8,10.6,1.1,15.9c0.7,9.9,0.7,19.8,0.6,29.6c0,2.8-0.1,5.6-0.1,8.4
				c0,0.6-0.1,1.2-0.1,1.9c-0.7,0.1-1.4,0.1-2,0.2c-0.6,0-1.2,0.1-1.8,0.1c-5.5-0.1-10.9,0.4-16.4,0.6c-0.2,0-0.4,0-0.5,0
				c-3.9,1-8,0.9-12,1c-2.5,0.1-5,0.1-7.5,0.1c-0.4,0-0.9-0.1-1.3,0c-2.5,0.8-5.1,0.5-7.6,0.5c-6.5,0.1-13-0.1-19.5-0.1
				c-7.2-0.1-14.4-0.2-21.6,0.1c-10.9,0.4-21.8,0.6-32.7,0.5c-4.1,0-8.3,0-12.4,0c-0.8,0-1.7-0.2-2.5-0.3c-0.6-0.1-1.2-0.1-1.8-0.2
				C329.4,353.7,328.5,353.7,327.4,353.7z M455.7,342.5c0.1-1.5,0-3,0-4.4c0-1.4,0-2.9,0-4.3s0-2.9,0-4.3c0-1.4,0-2.9,0-4.3
				c0-1.4,0.1-2.8,0.2-4.1c0.1-1.4,0.2-2.9,0.3-4.3c0.1-1.4,0.2-2.9,0.3-4.3c0.1-1.4,0.3-2.9,0.3-4.3c0-1.4,0.6-2.8,0.3-4.3
				c-0.4,0.1-0.7,0.1-1,0.2c-4.5,0.9-9,1.8-13.5,2.1c-5.6,0.4-11.2,0.6-16.8,0.6c-20.2-0.1-40.3-0.2-60.5-0.3
				c-3.1,0-6.2-0.1-9.3-0.3c-4.4-0.3-8.8-0.6-13.1-1.5c-0.3-0.1-0.6,0-0.9,0c0,0.4,0,0.7,0.1,1c0.4,2.3,0.8,4.5,1.2,6.8
				c0.1,0.7,0.3,1.5,0.3,2.2c0,2.2,0,4.4,0,6.6c-0.1,6.1,0.2,12.1,1,18.2c0.3,2,0.5,4.1,0.8,6.2c0.4,0.1,0.7,0.2,1.1,0.2
				c4,0.1,8,0.2,12.1-0.1c4.4-0.2,8.8-0.5,13.1-0.5c4.9-0.1,9.9,0,14.8,0c4.7,0,9.4-0.2,14.1-0.3c0.7,0,1.3-0.2,1.7-0.2
				c2.6,0.1,4.9,0.3,7.2,0.3c4.6,0,9.1-0.1,13.7-0.2c4.1,0,8.3,0.1,12.4-0.4c2.2-0.2,4.5-0.6,6.7-0.7c3.9-0.3,7.9-0.6,11.8-0.9
				C454.7,342.7,455.2,342.6,455.7,342.5z M342.4,302.8c1.8,0.1,3.9,0.3,6,0.4c4.8,0.2,9.6,0.5,14.4,0.6c9.6,0.1,19.2-0.1,28.9-0.1
				c14.6,0,29.2,0.1,43.9,0.1c6.8,0,13.6-0.6,20.4-1.5c0.8-0.1,1.6-0.2,2.3-0.7c0.2-0.5,0.5-1.1,0.6-1.7c0.2-1,0.7-1.8,1.4-2.6
				c1.1-1.3,2.2-2.5,3.3-3.8c0.2-0.2,0.4-0.4,0.2-0.9c-1,0-2.1,0-3.2,0c-3,0.1-6.1,0.3-9.1,0.3c-5.3,0.1-10.6,0-15.9,0
				c-5.5,0-11,0-16.4,0.2c-5.8,0.2-11.6,0.7-17.3,1c-5.7,0.3-11.3,0.6-17,0.7c-6.9,0.2-13.9,0.1-20.8,0.2
				c-6.9,0.1-13.9-0.1-20.8-0.6c-4.6-0.3-9.1-0.2-13.7,0.2c-0.3,0-0.6,0-0.7,0.5C332.9,298,337.3,300.5,342.4,302.8z M327.8,296.7
				c-0.1,0.6-0.2,0.9-0.2,1.2c0,0.8,0,1.6,0,2.4c0.2,12.9,0.4,25.8,0.6,38.7c0.1,3.2,0.3,6.4,0.5,9.6c0,0.7,0,1.5,0.4,2.3
				c3.9-1.1,7.8-2.1,11.6-3.3c1-0.3,1.9-0.7,2.9-1.1c0-0.4,0.1-0.6,0.1-0.9c-0.2-2.1-0.4-4.2-0.7-6.3c-0.5-4.2-0.9-8.3-1-12.5
				c0-2,0-4,0-6c0-2.6-0.2-5.2-0.5-7.9c-0.3-2.5-0.6-5-0.9-7.4c-0.1-0.4-0.1-0.8-0.2-1.2C335.9,302.6,331.9,300,327.8,296.7z
				 M332.3,351.7c0.7,0,1.1,0,1.6,0c2.3,0,4.5-0.1,6.8-0.1c1,0,1.9-0.1,2.9,0.4c0.2,0.1,0.6,0.1,0.9,0.1c0.9,0,1.8,0,2.7-0.2
				c1.1-0.2,2.2-0.1,3.3-0.1c8.1,0,16.2,0.1,24.3-0.3c9.6-0.5,19.2-0.6,28.9-0.4c5.2,0.1,10.4,0.1,15.5,0.1c1.6,0,3.3-0.1,4.9-0.1
				c0.5,0,1,0,1.4-0.2c0.6-0.3,1.3-0.3,2-0.3c8.8-0.4,17.6-0.7,26.5-1.1c0.5,0,1,0.1,1.5-0.5c0.1-1,0.1-2.2,0.2-3.4
				c0-0.3,0-0.6-0.1-0.9c-0.3,0-0.4-0.1-0.6-0.1c-2.7,0.4-5.3,0.3-8,0.4c-2.2,0.1-4.4,0.2-6.6,0.5c-6.1,0.8-12.3,1-18.4,0.9
				c-2.2-0.1-4.4,0-6.6,0c-3,0-6,0.1-9,0.1c-1.3,0-2.5-0.1-3.8,0c-5.6,0.2-11.2,0.3-16.8,0.4c-1.8,0-3.5,0-5.3,0.2
				c-3.2,0.3-6.3,0.3-9.5,0.1c-2.6-0.2-5.1-0.1-7.7,0c-5.2,0.2-10.5,0.6-15.7,0.3c-0.5,0-1.1,0-1.5,0c-0.4,0.4-0.7,0.7-1,1.1
				c-1-0.2-2,0.2-3,0.5c-1,0.3-2,0.7-3,1c-1.6,0.4-3.2,1-5,1C333.6,351,332.9,351.1,332.3,351.7z M459.2,303.1
				c-0.3,4.7-0.5,9.2-0.9,13.8c-0.3,3.8-0.8,7.6-1.1,11.4c-0.4,4.7-0.7,9.4-1,14c0,0.2,0.1,0.5,0.1,0.7c3.7,1.6,7.4,3.1,11,4.6
				c0.1,0,0.2,0,0.5-0.1c0.9-18.3,0.4-36.5-1.2-54.8c-0.3-0.1-0.5-0.1-0.8-0.2c-0.2,0.3-0.3,0.6-0.4,0.8c-1,1.6-1.8,3.3-2.9,4.7
				C461.2,299.7,460,301.2,459.2,303.1C459.2,303.1,459.2,303.2,459.2,303.1z M456.6,349.1c1.1,0.2,7,0.2,8.8,0
				c0.2,0,0.3-0.2,0.5-0.3c-0.2-0.2-0.3-0.3-0.4-0.4c-2.8-1.2-5.6-2.4-8.4-3.6c-0.2-0.1-0.4,0-0.7,0c0,0.7,0,1.3,0,1.9
				C456.4,347.5,456.4,348.3,456.6,349.1z"/>
            <path d="M327.8,358.2c1.5-0.3,2.9-0.3,4.2-0.4c1.4,0,2.8,0,4.2,0.1c9.8,0.8,19.6,0.9,29.4,0.6c8.3-0.3,16.6-0.5,24.8-0.9
				c4.7-0.2,9.4-0.6,14-0.9c4.4-0.3,8.9-0.8,13.3-1c2.4-0.1,4.7-0.1,7.1-0.1c4.1,0.1,8.3,0.2,12.4,0.3c0.9,0,1.8,0.1,2.7,0.1
				c0.4,0,0.8,0,1.1,0.1c1.7,0.6,3.4,0.5,5.1,0.5c6.5,0.1,12.9,0,19.4-0.5c1.4-0.1,2.9,0.1,4.4,0.2c0,0.7,0.1,1.2,0.1,1.8
				c-0.2,5.1-0.3,10.1-0.6,15.2c-0.6,10.4-1.3,20.7-1.3,31.1c0,1.6,0,3.1,0,4.7c0,0.6,0,1.1,0,1.5c-0.6,0.6-1.2,0.7-1.8,0.8
				c-4.6,0.7-9.3,1.5-14,1.6c-8.2,0.2-16.3,0.4-24.5,0.6c-8.6,0.2-17.2,0.5-25.8,0.8c-7.7,0.3-15.3,0.7-23,1.1
				c-9.3,0.4-18.5,0.6-27.8,0.1c-2.6-0.1-5.2-0.4-7.8-0.6c-0.9,0-1.8,0.2-2.7,0.1c-3.4-0.1-6.8-0.4-10.2-1c-0.6-0.1-1.2-0.1-1.5-1
				c0.1-0.4,0.3-0.8,0.5-1.4c-0.1-3.5-0.2-7-0.3-10.6c-0.1-3.6-0.2-7.1-0.3-10.7c-0.1-3.6-0.2-7.3-0.3-10.9
				c-0.1-3.6-0.3-7.1-0.5-10.7C328.2,365.4,328,361.9,327.8,358.2z M455,399.5c0.1-3,0.1-5.8-0.1-8.6c-0.4-4.1-0.8-8.2-0.9-12.3
				c-0.2-4.7-0.2-9.5-0.3-14.3c0-1.3-0.1-2.5-0.2-3.7c0-0.4-0.1-0.7-0.2-1.1c-0.3-0.1-0.5-0.1-0.8-0.1c-2.7,0-5.5,0-8.2,0
				c-0.3,0-0.6,0-0.9,0.1c-1.3,0.5-2.5,0.3-3.8,0.2c-3.9-0.3-7.9-0.7-11.8-0.9c-5.2-0.2-10.5-0.1-15.7,0.3
				c-6.4,0.5-12.8,0.9-19.1,1.1c-8.2,0.3-16.4,0.3-24.7,0.5c-4.5,0.1-9,0.1-13.5,0.1c-2.3,0-4.6-0.1-6.9-0.2c-0.4,0-0.8,0.1-1.2,0.1
				c0,0.2-0.1,0.4,0,0.5c0.6,3.6,0.8,7.3,1,11c0.3,8.1,0.3,16.3,0.1,24.4c0,1.4-0.2,2.7-0.3,4.1c0,0.2,0,0.5,0,0.8
				c0.3,0.1,0.6,0.2,0.9,0.2c3.6,0.2,7.3,0.5,10.9,0.5c6.9,0.1,13.8,0.1,20.6,0.1c7.3,0,14.6-0.1,21.9-0.1c3.5,0,6.9-0.2,10.4-0.2
				c0.9,0,1.8,0.1,2.7,0c9.3-0.2,18.6-0.4,27.9-0.6c3.2-0.1,6.4-0.3,9.6-1.2C453.2,399.9,454.1,399.7,455,399.5z M442,403
				c-4.2,0.1-8.3,0.2-12.3,0.3c-3,0.1-6.1,0.1-9.1,0.2c-3.7,0.1-7.4,0.2-11.1,0.3c-3.1,0.1-6.2,0-9.3,0.1c-3.6,0.1-7.2,0.2-10.8,0.2
				c-8,0-16,0-23.9,0c-2.3,0-4.5-0.1-6.8-0.2c-3.7-0.1-7.4-0.3-11.1-0.4c-0.3,0-0.6,0-0.9,0c-0.4,0.4-0.7,0.7-1.1,1
				c-0.4,0.3-0.9,0.6-1.4,0.9c-0.4,0.3-0.8,0.6-1.2,0.8c-0.5,0.3-0.9,0.6-1.4,1c-0.4,0.3-0.8,0.6-1.2,0.8c-0.5,0.3-0.9,0.6-1.4,0.9
				c-0.4,0.2-0.9,0.5-1.3,0.7c-0.5,0.3-1,0.5-1.5,0.8c-0.4,0.2-0.9,0.4-1.3,0.6c-0.5,0.2-1,0.4-1.5,0.7c-0.4,0.2-0.9,0.2-1.4,0.8
				c3.9-0.3,7.5-0.1,11.1,0.2c5.8,0.6,11.5,0.9,17.3,1c4.1,0,8.3,0.1,12.4-0.1c5.4-0.2,10.7-0.5,16.1-0.8c6.6-0.3,13.1-0.7,19.7-1
				c7.6-0.3,15.2-0.6,22.8-0.7c7-0.1,14-0.3,21-0.4c3.9-0.1,7.8-0.7,11.6-1.2c0.3,0,0.6,0,0.7-0.6c-2.7-2.3-5.4-4.7-8.2-7
				c-4.6,0.5-9.1,1-13.7,1.6C442.6,403.3,442.4,403.2,442,403z M346.2,402.2c0.1-1.2,0.4-2.5,0.4-3.7c0-2.4,0-4.9-0.1-7.3
				c-0.3-6.1-0.6-12.1-0.9-18.2c-0.1-3.9-0.1-7.7-0.2-11.6c0-0.3-0.1-0.6-0.1-1c-1.7-0.3-3.4-0.2-5.1-0.3c-1.8-0.2-3.5-0.3-5.3-0.4
				c-1.7-0.1-3.3-0.5-5.1-0.3c-0.2,0.8-0.1,1.4,0,2.1c0.3,5.5,0.7,11,0.9,16.5c0.3,8.6,0.6,17.2,0.9,25.9c0.1,2.1,0.1,4.1,0.2,6.2
				c0,0.3,0,0.6,0.3,0.8C336.8,408,341.3,405.2,346.2,402.2z M467.6,358.3c-1,0.1-1.9,0.3-2.8,0.3c-2.8,0.2-5.6,0.4-8.4,0.5
				c-0.5,0-0.9,0.1-1.4,0.2c0.1,0.9,0.2,1.6,0.2,2.3c0.1,0.7,0.2,1.5,0.2,2.2c0,2.6,0,5.1-0.1,7.7c-0.2,4.3-0.3,8.6,0,12.9
				c0.2,4.4,0.5,8.7,0.7,13.1c0,0.4,0.1,0.9,0.1,1.1c2.2,2.2,4.2,4.2,6.2,6.2c0.8,0.8,1.7,1.6,2.6,2.4c0.2,0.2,0.5,0.4,0.9,0.2
				c0-0.5,0.1-1,0.1-1.6c0-10.1,0.6-20.2,1.2-30.4c0.3-5.2,0.5-10.5,0.8-15.7C467.7,359.3,467.6,358.8,467.6,358.3z"/>
            <path d="M331,476c-0.7-19-0.8-37.9-0.9-56.9c0-0.3,0.1-0.6,0.1-0.9c0.3-0.1,0.6-0.3,0.9-0.3c1.4-0.1,2.8-0.1,4.2-0.1
				c10.7,0.3,21.3,0.3,32,0.4c14.1,0,28.3,0.1,42.4,0.2c5.1,0,10.1,0.2,15.2,0.3c5.2,0.1,10.4,0.1,15.5,0.1c2.8,0,5.6,0.1,8.4,0
				c3.4-0.1,6.7-0.6,9.9-1.4c2.5-0.6,5-0.9,7.6-1.1c0.6-0.1,1.3-0.2,2,0.2c0.3,0.6,0.2,1.2,0.3,1.8c0.2,4,0.4,8,0.6,12
				c0.3,5.2,0.6,10.4,0.9,15.5c0.5,9.1,1,18.2,0.8,27.4c0,0.6-0.1,1.1-0.1,1.8c-0.4,0.1-0.7,0.1-1.1,0.2c-2.1,0.2-4.2,0.4-6.3,0.6
				c-4.7,0.4-9.3,0.9-14,1.3c-8.9,0.8-17.9,0.7-26.8,1.1c-3,0.1-6.1,0.2-9.1,0.1c-9-0.1-18-0.1-27-0.6c-11.3-0.6-22.6-0.4-34-0.5
				c-6-0.1-11.9-0.1-17.9-0.6C333.3,476.4,332.2,476.6,331,476z M348.9,464.9c2.8,0,5.3,0.1,7.9,0.1c6.1-0.1,12.3-0.3,18.4-0.4
				c5.5,0,11.1,0.2,16.6,0.2c3.3,0,6.6-0.1,9.9-0.2c3.7-0.1,7.3-0.2,11-0.2c3-0.1,6.1-0.1,9.1-0.2c0.4,0,0.9,0,1.3,0.1
				c0.9,0.4,1.8,0.3,2.7,0.3c5.5,0,11.1-0.1,16.6-0.1c0.7,0,1.5,0,2.2-0.1c2.4-0.3,4.8-0.5,7.3-0.8c1-0.1,1.9-0.2,2.8-0.3
				c0.5-0.7,0.4-1.4,0.3-2.1c-0.1-5.6-0.3-11.1-0.4-16.7c-0.3-7.6-0.7-15.1-0.1-22.7c0-0.4,0-0.7-0.1-1.2c-0.5,0.1-0.8,0.1-1.2,0.1
				c-2.2,0.3-4.3,0.7-6.5,0.8c-7.5,0.1-15.1,0.1-22.6,0c-7.2-0.1-14.4-0.4-21.5-0.5c-12.7-0.3-25.4-0.5-38.2-0.7
				c-5.1-0.1-10.1-0.1-15.2-0.2c-0.5,0-1-0.1-1.4,0.3c0,0.2,0,0.4,0.1,0.6c0.9,3.6,1.3,7.3,1.2,11c-0.1,2.9-0.2,5.7-0.4,8.6
				c-0.3,3.6-0.6,7.1-0.6,10.7c0,3.4,0.3,6.7,0.6,10.1C348.7,462.5,348.8,463.6,348.9,464.9z M334.4,474.7c0.3,0.1,0.5,0.1,0.6,0.1
				c1.8,0,3.7-0.1,5.5-0.1c8.8,0.1,17.7,0.2,26.5,0.2c5.3,0,10.6,0,15.9,0.3c9.2,0.5,18.4,0.6,27.6,0.7c3.3,0,6.6,0,9.9,0
				c4.9-0.1,9.7-0.3,14.6-0.5c4.3-0.1,8.5-0.3,12.8-0.5c2.9-0.1,5.8-0.4,8.7-0.6c3.6-0.3,7.1-0.9,10.7-0.9c0.2,0,0.3-0.1,0.5-0.3
				c-0.5-0.9-1.3-1.4-2.1-1.9c-1.3-0.8-2.7-1.5-4-2.3c-1.8-1.1-3.7-2.2-5.5-3.3c-0.4-0.2-0.7-0.5-1.1-0.8c-1.5,0.1-3,0.2-4.5,0.3
				c-4.7,0.5-9.3,0.9-14,0.9c-0.9,0-1.7,0-2.6,0.1c-5.5,0.1-11,0.2-16.4,0.2c-0.6,0-1.3,0.1-1.8-0.2c-0.7-0.3-1.3-0.3-2-0.3
				c-2.9,0.1-5.8,0.4-8.8,0.3c-5.8-0.1-11.7,0.6-17.5,0c-8.1,0-16.2,0-24.3,0c-0.7,0-1.3,0-2,0.1c-4.1,0.3-8.3,0.4-12.4,0.1
				c-0.4,0-0.8,0.1-1.2,0.1c-0.8,0.6-1.4,1.1-2.1,1.6c-0.7,0.5-1.4,0.9-2.2,1.4c-0.7,0.4-1.4,0.8-2.1,1.2c-0.7,0.4-1.5,0.8-2.3,1.2
				c-0.8,0.4-1.5,0.8-2.3,1.2C336,473.7,335.2,474,334.4,474.7z M346.8,420.2c-1.8,0-3.3,0-4.9-0.1c-1.6-0.1-3.3-0.1-4.9-0.3
				c-1.6-0.1-3.1-0.4-4.6-0.5c-0.4,0.6-0.2,1.2-0.2,1.7c0,4.4,0.1,8.8,0.1,13.1c0.1,8.3,0.1,16.5,0.1,24.8c0,4.6,0.1,9.1,0.2,13.7
				c0,0.4-0.2,0.9,0.4,1.2c4.2-3.2,8.8-5.7,13.6-7.7c0.4-0.6,0.7-1.1,1.1-1.7c-0.1-1.2-0.1-2.5-0.2-3.8c-0.4-5.4-0.7-10.9-0.3-16.3
				c0.2-2.7,0.3-5.4,0.3-8.1c0-1.5,0-3,0-4.5c-0.1-2.6-0.3-5.1-0.4-7.7C347,422.8,346.9,421.7,346.8,420.2z M468.5,472.1
				c0.1-0.7,0.1-1,0.1-1.4c0-5.7-0.1-11.4-0.5-17.1c-0.3-4.5-0.6-9-0.9-13.5c-0.4-6.9-0.8-13.9-1.2-20.8c0-0.3-0.2-0.7-0.2-1
				c-2.9-0.1-7.9,0.9-10.1,2.1c0,0.6,0,1.2-0.1,1.8c0,2-0.1,4-0.1,6c-0.3,6.5-0.1,13,0.1,19.5c0.1,4.2,0.2,8.5,0.2,12.8
				c0,0.9,0,1.9,0,2.9C459.7,466.6,464.1,469,468.5,472.1z"/>
            <path d="M470.4,479.7c0.2,0.3,0.2,0.4,0.2,0.4c0,0.1,0,0.1,0.1,0.2c0,0.1,0,0.2,0,0.4c0,0.1,0,0.3,0,0.4
				c0.2,14.9,0.4,29.8,0.5,44.8c0.2,0.4,0.4,1,0.4,1.1c-0.1,2.6-0.1,4.7-0.2,6.9c0,0.4-0.2,0.8-0.3,1.1c-2,1.4-4.2,1.2-6.3,1.2
				c-1.4,0-2.8-0.1-4.2-0.1c-4.3,0.2-8.6,0.5-13,0.7c-2.6,0.1-5.2,0.1-7.9,0c-6.6-0.2-13.1-0.5-19.7-0.8c-9.3-0.4-18.5-0.3-27.8,0
				c-9.4,0.3-18.9,0.3-28.3-0.1c-6.3-0.3-12.7-0.1-19,0.1c-3.3,0.1-6.7,0.3-10,0.5c-0.5,0-1.1,0.2-1.9,0.3c-0.3-0.1-1-0.3-1.5-0.4
				c-0.1-0.9,0.7-1,1-1.7c0-0.2,0.1-0.5,0.1-0.8c0-4.6-0.1-9.3-0.1-13.9c0-5.5-0.4-11-0.7-16.5c-0.3-6.7-0.6-13.5-0.9-20.3
				c0-0.3,0.1-0.6,0.2-1c0.4,0,0.8-0.1,1.1-0.1c2.4,0.1,4.9,0.2,7.3,0.3c7.7,0.5,15.3,0.4,23,0.2c12.7-0.2,25.3-0.3,38,0.2
				c6.3,0.2,12.7,0.4,19,0.6c4.6,0.1,9.3,0.1,13.9-0.4c3.9-0.4,7.7-1.1,11.6-1.6c2.7-0.4,5.4-0.7,8.2-1c2.7-0.3,5.3-0.6,8-0.9
				c2.1-0.2,4.1-0.4,6.2-0.2C468.5,479.7,469.4,479.7,470.4,479.7z M350.5,484.8c0.1,1.2,0.1,2.2,0.2,3.2c0.5,4.9,0.6,9.9,0.5,14.8
				c0,2.8,0.2,5.6,0.4,8.4c0.2,2.7,0.6,5.5,0.5,8.2c0,0.4,0.1,0.7,0.1,1.2c0.5,0,0.9,0,1.3,0c0.9,0,1.7-0.1,2.6-0.2
				c3.8-0.2,7.7-0.3,11.5-0.4c3.1-0.1,6.2-0.3,9.3-0.4c2.6-0.1,5.2-0.2,7.8-0.4c1.9-0.1,3.8-0.3,5.6-0.4c1.9-0.1,3.8-0.4,5.6-0.4
				c6.1-0.1,12.2,0,18.3-0.1c3.6-0.1,7.2-0.3,10.8-0.4c1.3,0,2.7-0.2,4,0c1.1,0.1,2.2,0.2,3.3,0.2c2.4-0.1,4.9-0.2,7.3-0.4
				c3.8-0.4,7.6-0.7,11.5-0.6c0.6,0,1.2,0,2-0.1c0.8-11.4,1.1-22.6,0.3-34c-0.7-0.2-1.3-0.1-1.9,0c-2,0.3-4,0.5-6,0.9
				c-8.7,1.5-17.4,2.3-26.2,2.2c-6.8-0.1-13.6-0.4-20.4-0.6c-4.7-0.2-9.5-0.4-14.2-0.6c-10.7-0.4-21.4-0.1-32.1-0.1
				C351.8,484.8,351.3,484.8,350.5,484.8z M453.8,518.3c-1,0.1-1.8,0.2-2.7,0.3c-4.3,0.3-8.6,0.7-12.9,0.9c-3.3,0.2-6.7,0.2-10,0.3
				c-0.3,0-0.6-0.1-0.9-0.1c-0.6-0.1-1.2-0.2-1.8-0.1c-4.5,0.2-9,0.4-13.5,0.5c-4.4,0.1-8.8,0.1-13.1,0.1c-1.3,0-2.7,0-4,0
				c-0.6,0-1.2,0-1.8,0.2c-0.9,0.3-1.8,0.4-2.7,0.4c-2.1,0.1-4.1,0.1-6.2,0.2c-4,0.2-7.9,0.4-11.9,0.5c-5.2,0.2-10.3,0.3-15.5,0.4
				c-1.8,0-3.6,0-5.3,0c-1.4,0.8-2.7,1.5-3.4,2.9c-0.2,0.4-0.7,0.7-1.1,1c-0.5,0.4-1.1,0.8-1.7,1.2c-3,2.4-6,4.7-9.3,6.7
				c-0.2,0.1-0.4,0.3-0.2,0.7c1.3-0.1,2.7-0.2,4.1-0.3c8.1-0.4,16.2-0.4,24.3-0.1c8.1,0.3,16.2,0.5,24.3,0.1c4.3-0.2,8.6-0.3,13-0.4
				c9.1-0.3,18.1,0,27.2,0.3c3.5,0.1,6.9,0.2,10.4,0.5c4.3,0.3,8.5,0.2,12.8,0c3.4-0.2,6.8-0.3,10.2-0.4c1.7,0,3.2-0.3,4.9-0.6
				c-0.3-0.7-0.5-1.4-0.8-2C462,527,457.9,522.7,453.8,518.3z M350.8,520.7c0-0.4,0-0.8,0-1.2c-0.1-1.6-0.2-3.1-0.4-4.7
				c-0.5-4.2-0.8-8.3-0.8-12.5c0-5.4-0.2-10.7-0.3-16.1c0-0.4-0.1-0.8-0.2-1.4c-5.3,0-10.5-0.2-15.7-0.8c-0.1,0-0.2,0.1-0.4,0.1
				c-0.4,0.6-0.2,1.3-0.2,2c0.1,4.3,0.3,8.6,0.4,12.9c0.2,7.9,0.3,15.9,0.5,23.8c0.1,2.8,0.1,5.6,0.2,8.4c0,0.6,0.1,1.1,0.2,1.8
				c0.4-0.2,0.7-0.3,0.9-0.5c2.9-2.2,5.8-4.3,8.7-6.5C346,524.3,348.3,522.6,350.8,520.7z M454.3,482.7c0.1,0.9,0.2,1.6,0.2,2.3
				c0.2,2.2,0.5,4.5,0.6,6.7c0.2,7.1-0.1,14.1-0.6,21.2c-0.1,1.4,0,2.7,0,4.2c0.8,0.7,1.6,1.4,2.4,2.2c3,3.2,6,6.4,9,9.6
				c0.4,0.5,0.8,0.9,1.3,1.3c0.5,0.5,1.1,0.8,1.8,0.9c0.2-1.5,0.3-2.9,0.5-4.4c0-0.5,0-1,0-1.5c-0.5-13.3-0.7-26.6-0.8-40
				c0-0.8,0-1.5,0-2.3c0-0.4-0.1-0.7-0.2-1.2C463.7,481.6,459.1,482.2,454.3,482.7z"/>
            <path className="st61" d="M455.7,342.5c-0.6,0.1-1,0.2-1.5,0.2c-3.9,0.3-7.9,0.5-11.8,0.9c-2.2,0.2-4.5,0.5-6.7,0.7
				c-4.1,0.5-8.3,0.4-12.4,0.4c-4.6,0-9.1,0.2-13.7,0.2c-2.3,0-4.6-0.2-7.2-0.3c-0.4,0.1-1.1,0.2-1.7,0.2c-4.7,0.1-9.4,0.2-14.1,0.3
				c-4.9,0-9.9-0.1-14.8,0c-4.4,0.1-8.8,0.3-13.1,0.5c-4,0.2-8,0.2-12.1,0.1c-0.4,0-0.7-0.1-1.1-0.2c-0.3-2.1-0.5-4.1-0.8-6.2
				c-0.8-6-1.1-12.1-1-18.2c0-2.2,0-4.4,0-6.6c0-0.7-0.2-1.5-0.3-2.2c-0.4-2.3-0.8-4.6-1.2-6.8c-0.1-0.3-0.1-0.6-0.1-1
				c0.4,0,0.7-0.1,0.9,0c4.3,0.9,8.7,1.3,13.1,1.5c3.1,0.2,6.2,0.3,9.3,0.3c20.2,0.1,40.3,0.2,60.5,0.3c5.6,0,11.2-0.2,16.8-0.6
				c4.6-0.3,9.1-1.1,13.5-2.1c0.3-0.1,0.6-0.1,1-0.2c0.3,1.5-0.3,2.9-0.3,4.3c0,1.4-0.2,2.9-0.3,4.3c-0.1,1.4-0.2,2.9-0.3,4.3
				c-0.1,1.4-0.2,2.9-0.3,4.3c-0.1,1.4-0.1,2.7-0.2,4.1c0,1.4,0,2.9,0,4.3c0,1.4,0,2.9,0,4.3s0,2.9,0,4.3
				C455.8,339.6,455.8,341,455.7,342.5z"/>
            <path className="st29" d="M342.4,302.8c-5.1-2.3-9.5-4.8-13.8-7.6c0.1-0.5,0.4-0.5,0.7-0.5c4.6-0.4,9.1-0.5,13.7-0.2
				c6.9,0.4,13.9,0.7,20.8,0.6c6.9-0.1,13.9-0.1,20.8-0.2c5.7-0.1,11.3-0.4,17-0.7c5.8-0.3,11.5-0.8,17.3-1c5.5-0.2,11-0.2,16.4-0.2
				c5.3,0,10.6,0,15.9,0c3,0,6.1-0.2,9.1-0.3c1.1,0,2.2,0,3.2,0c0.3,0.4,0,0.6-0.2,0.9c-1.1,1.3-2.2,2.5-3.3,3.8
				c-0.7,0.8-1.2,1.6-1.4,2.6c-0.1,0.6-0.4,1.2-0.6,1.7c-0.8,0.5-1.6,0.6-2.3,0.7c-6.8,0.9-13.6,1.5-20.4,1.5
				c-14.6,0-29.2-0.1-43.9-0.1c-9.6,0-19.2,0.1-28.9,0.1c-4.8,0-9.6-0.4-14.4-0.6C346.3,303.1,344.1,302.9,342.4,302.8z"/>
            <path className="st29" d="M327.8,296.7c4,3.2,8,5.9,12.6,7.5c0.1,0.4,0.2,0.8,0.2,1.2c0.3,2.5,0.6,5,0.9,7.4c0.3,2.6,0.5,5.2,0.5,7.9
				c0,2,0,4,0,6c0,4.2,0.4,8.4,1,12.5c0.3,2.1,0.5,4.2,0.7,6.3c0,0.2,0,0.5-0.1,0.9c-1,0.4-1.9,0.8-2.9,1.1
				c-3.8,1.2-7.7,2.2-11.6,3.3c-0.4-0.8-0.4-1.6-0.4-2.3c-0.2-3.2-0.5-6.4-0.5-9.6c-0.2-12.9-0.4-25.8-0.6-38.7c0-0.8,0-1.6,0-2.4
				C327.7,297.7,327.8,297.4,327.8,296.7z"/>
            <path className="st71" d="M332.3,351.7c0.6-0.7,1.3-0.8,1.8-0.8c1.7,0,3.3-0.6,5-1c1-0.3,2-0.6,3-1c1-0.3,1.9-0.7,3-0.5
				c0.3-0.4,0.6-0.7,1-1.1c0.4,0,1,0,1.5,0c5.2,0.4,10.5-0.1,15.7-0.3c2.6-0.1,5.1-0.1,7.7,0c3.2,0.2,6.3,0.2,9.5-0.1
				c1.8-0.2,3.5-0.2,5.3-0.2c5.6-0.1,11.2-0.2,16.8-0.4c1.3,0,2.5,0,3.8,0c3,0,6,0,9-0.1c2.2,0,4.4-0.1,6.6,0
				c6.2,0.2,12.3-0.1,18.4-0.9c2.2-0.3,4.4-0.4,6.6-0.5c2.7-0.1,5.4,0,8-0.4c0.2,0,0.3,0,0.6,0.1c0,0.3,0.1,0.6,0.1,0.9
				c-0.1,1.2-0.1,2.4-0.2,3.4c-0.5,0.6-1,0.5-1.5,0.5c-8.8,0.4-17.6,0.7-26.5,1.1c-0.7,0-1.3,0-2,0.3c-0.4,0.2-0.9,0.2-1.4,0.2
				c-1.6,0.1-3.3,0.2-4.9,0.1c-5.2,0-10.4,0-15.5-0.1c-9.6-0.2-19.2-0.1-28.9,0.4c-8.1,0.4-16.2,0.3-24.3,0.3
				c-1.1,0-2.2-0.1-3.3,0.1c-0.9,0.2-1.8,0.2-2.7,0.2c-0.3,0-0.7,0.1-0.9-0.1c-0.9-0.5-1.9-0.4-2.9-0.4c-2.3,0-4.5,0.1-6.8,0.1
				C333.4,351.8,333,351.8,332.3,351.7z"/>
            <path className="st14" d="M459.2,303.1C459.2,303.2,459.2,303.1,459.2,303.1c0.8-1.9,2-3.5,3.2-5c1.2-1.4,2-3.1,2.9-4.7
				c0.2-0.3,0.3-0.5,0.4-0.8c0.3,0.1,0.5,0.1,0.8,0.2c1.6,18.3,2.1,36.5,1.2,54.8c-0.3,0-0.4,0.1-0.5,0.1c-3.7-1.5-7.3-3.1-11-4.6
				c0-0.2-0.1-0.4-0.1-0.7c0.3-4.7,0.6-9.4,1-14c0.3-3.8,0.8-7.6,1.1-11.4C458.7,312.4,458.9,307.8,459.2,303.1z"/>
            <path className="st71" d="M456.6,349.1c-0.2-0.7-0.3-1.5-0.3-2.3c0-0.6,0-1.2,0-1.9c0.3,0,0.5-0.1,0.7,0c2.8,1.2,5.6,2.4,8.4,3.6
				c0.2,0.1,0.3,0.2,0.4,0.4c-0.2,0.1-0.3,0.3-0.5,0.3C463.7,349.3,457.7,349.3,456.6,349.1z"/>
            <path className="st61" d="M455,399.5c-1,0.2-1.8,0.3-2.6,0.6c-3.1,0.9-6.3,1.1-9.6,1.2c-9.3,0.2-18.6,0.4-27.9,0.6
				c-0.9,0-1.8-0.1-2.7,0c-3.5,0.1-6.9,0.2-10.4,0.2c-7.3,0.1-14.6,0.1-21.9,0.1c-6.9,0-13.8,0-20.6-0.1c-3.7-0.1-7.3-0.3-10.9-0.5
				c-0.3,0-0.6-0.1-0.9-0.2c0-0.3,0-0.6,0-0.8c0.1-1.4,0.3-2.7,0.3-4.1c0.2-8.1,0.2-16.3-0.1-24.4c-0.1-3.7-0.3-7.4-1-11
				c0-0.1,0-0.2,0-0.5c0.4,0,0.8-0.1,1.2-0.1c2.3,0,4.6,0.2,6.9,0.2c4.5,0,9,0,13.5-0.1c8.2-0.1,16.4-0.2,24.7-0.5
				c6.4-0.2,12.8-0.6,19.1-1.1c5.2-0.4,10.5-0.6,15.7-0.3c3.9,0.2,7.9,0.6,11.8,0.9c1.3,0.1,2.5,0.3,3.8-0.2
				c0.3-0.1,0.6-0.1,0.9-0.1c2.7,0,5.5,0,8.2,0c0.2,0,0.5,0.1,0.8,0.1c0.1,0.4,0.2,0.7,0.2,1.1c0.1,1.2,0.1,2.5,0.2,3.7
				c0.1,4.8,0.1,9.5,0.3,14.3c0.2,4.1,0.6,8.2,0.9,12.3C455.2,393.7,455.1,396.5,455,399.5z"/>
            <path className="st71" d="M442,403c0.3,0.2,0.6,0.4,0.9,0.5c4.6-0.5,9.2-1.1,13.7-1.6c2.7,2.4,5.5,4.7,8.2,7
				c-0.1,0.5-0.4,0.6-0.7,0.6c-3.9,0.5-7.7,1.1-11.6,1.2c-7,0.2-14,0.4-21,0.4c-7.6,0.1-15.2,0.3-22.8,0.7c-6.6,0.3-13.1,0.6-19.7,1
				c-5.4,0.3-10.7,0.6-16.1,0.8c-4.1,0.1-8.3,0.1-12.4,0.1c-5.8,0-11.6-0.4-17.3-1c-3.6-0.4-7.3-0.6-11.1-0.2c0.5-0.6,1-0.6,1.4-0.8
				c0.5-0.2,1-0.4,1.5-0.7c0.4-0.2,0.9-0.4,1.3-0.6c0.5-0.2,1-0.5,1.5-0.8c0.4-0.2,0.9-0.5,1.3-0.7c0.5-0.3,0.9-0.6,1.4-0.9
				c0.4-0.3,0.8-0.5,1.2-0.8c0.5-0.3,0.9-0.6,1.4-1c0.4-0.3,0.8-0.6,1.2-0.8c0.5-0.3,0.9-0.6,1.4-0.9c0.4-0.3,0.7-0.7,1.1-1
				c0.3,0,0.6-0.1,0.9,0c3.7,0.1,7.4,0.3,11.1,0.4c2.3,0.1,4.5,0.2,6.8,0.2c8,0,16,0,23.9,0c3.6,0,7.2-0.1,10.8-0.2
				c3.1,0,6.2,0,9.3-0.1c3.7-0.1,7.4-0.2,11.1-0.3c3-0.1,6.1-0.1,9.1-0.2C433.8,403.1,437.8,403,442,403z"/>
            <path className="st29" d="M346.2,402.2c-4.8,3-9.4,5.8-14,8.6c-0.4-0.1-0.3-0.5-0.3-0.8c-0.1-2.1-0.1-4.1-0.2-6.2
				c-0.3-8.6-0.5-17.2-0.9-25.9c-0.2-5.5-0.6-11-0.9-16.5c0-0.7-0.1-1.4,0-2.1c1.8-0.2,3.4,0.2,5.1,0.3c1.8,0.1,3.5,0.2,5.3,0.4
				c1.7,0.1,3.4,0,5.1,0.3c0,0.4,0.1,0.7,0.1,1c0.1,3.9,0.1,7.8,0.2,11.6c0.2,6.1,0.6,12.1,0.9,18.2c0.1,2.4,0.1,4.9,0.1,7.3
				C346.6,399.7,346.3,401,346.2,402.2z"/>
            <path className="st14" d="M467.6,358.3c0,0.6,0.1,1,0.1,1.5c-0.2,5.2-0.5,10.5-0.8,15.7c-0.6,10.1-1.2,20.2-1.2,30.4
				c0,0.6,0,1.1-0.1,1.6c-0.4,0.3-0.7,0-0.9-0.2c-0.9-0.8-1.7-1.6-2.6-2.4c-2-2-4-4-6.2-6.2c0-0.2-0.1-0.6-0.1-1.1
				c-0.2-4.4-0.5-8.7-0.7-13.1c-0.2-4.3-0.1-8.6,0-12.9c0.1-2.6,0.1-5.1,0.1-7.7c0-0.7-0.1-1.5-0.2-2.2c-0.1-0.7-0.2-1.5-0.2-2.3
				c0.5-0.1,1-0.2,1.4-0.2c2.8-0.2,5.6-0.4,8.4-0.5C465.7,358.5,466.6,358.4,467.6,358.3z"/>
            <path className="st61" d="M348.9,464.9c-0.1-1.3-0.2-2.3-0.3-3.4c-0.3-3.4-0.6-6.7-0.6-10.1c0-3.6,0.4-7.1,0.6-10.7
				c0.2-2.9,0.4-5.7,0.4-8.6c0.1-3.7-0.3-7.4-1.2-11c0-0.2,0-0.4-0.1-0.6c0.4-0.4,0.9-0.3,1.4-0.3c5.1,0,10.1,0.1,15.2,0.2
				c12.7,0.2,25.4,0.4,38.2,0.7c7.2,0.1,14.4,0.4,21.5,0.5c7.5,0.1,15.1,0.1,22.6,0c2.2,0,4.3-0.5,6.5-0.8c0.3,0,0.7-0.1,1.2-0.1
				c0,0.4,0.1,0.8,0.1,1.2c-0.5,7.6-0.1,15.1,0.1,22.7c0.2,5.6,0.3,11.1,0.4,16.7c0,0.7,0.2,1.4-0.3,2.1c-0.9,0.1-1.9,0.2-2.8,0.3
				c-2.4,0.3-4.8,0.5-7.3,0.8c-0.7,0.1-1.5,0.1-2.2,0.1c-5.5,0.1-11.1,0.1-16.6,0.1c-0.9,0-1.8,0.1-2.7-0.3
				c-0.4-0.2-0.8-0.1-1.3-0.1c-3,0-6.1,0.1-9.1,0.2c-3.7,0.1-7.3,0.1-11,0.2c-3.3,0.1-6.6,0.2-9.9,0.2c-5.5,0-11.1-0.2-16.6-0.2
				c-6.1,0-12.3,0.3-18.4,0.4C354.2,465,351.7,464.9,348.9,464.9z"/>
            <path className="st71" d="M334.4,474.7c0.8-0.7,1.6-1,2.3-1.4c0.7-0.4,1.5-0.8,2.3-1.2c0.8-0.4,1.5-0.8,2.3-1.2
				c0.7-0.4,1.4-0.8,2.1-1.2c0.7-0.5,1.5-0.9,2.2-1.4c0.7-0.5,1.4-1,2.1-1.6c0.4,0,0.8-0.1,1.2-0.1c4.1,0.3,8.3,0.2,12.4-0.1
				c0.7,0,1.3-0.1,2-0.1c8.1,0,16.2,0,24.3,0c5.8,0.6,11.7-0.1,17.5,0c2.9,0,5.8-0.2,8.8-0.3c0.7,0,1.3,0,2,0.3
				c0.5,0.2,1.2,0.2,1.8,0.2c5.5-0.1,11-0.2,16.4-0.2c0.9,0,1.7-0.1,2.6-0.1c4.7,0,9.4-0.4,14-0.9c1.5-0.2,3-0.2,4.5-0.3
				c0.4,0.3,0.8,0.5,1.1,0.8c1.8,1.1,3.7,2.2,5.5,3.3c1.3,0.8,2.7,1.5,4,2.3c0.8,0.5,1.5,1,2.1,1.9c-0.2,0.1-0.4,0.3-0.5,0.3
				c-3.6,0-7.2,0.6-10.7,0.9c-2.9,0.2-5.8,0.5-8.7,0.6c-4.3,0.2-8.5,0.3-12.8,0.5c-4.9,0.2-9.7,0.3-14.6,0.5c-3.3,0.1-6.6,0.1-9.9,0
				c-9.2-0.1-18.4-0.2-27.6-0.7c-5.3-0.3-10.6-0.3-15.9-0.3c-8.8,0-17.7-0.1-26.5-0.2c-1.8,0-3.7,0.1-5.5,0.1
				C334.9,474.8,334.7,474.7,334.4,474.7z"/>
            <path className="st29" d="M346.8,420.2c0.1,1.4,0.2,2.6,0.3,3.8c0.2,2.6,0.3,5.1,0.4,7.7c0.1,1.5,0.1,3,0,4.5
				c-0.1,2.7-0.2,5.4-0.3,8.1c-0.4,5.4-0.1,10.9,0.3,16.3c0.1,1.3,0.1,2.6,0.2,3.8c-0.4,0.6-0.7,1.1-1.1,1.7
				c-4.8,2.1-9.4,4.6-13.6,7.7c-0.6-0.3-0.4-0.8-0.4-1.2c-0.1-4.6-0.2-9.1-0.2-13.7c-0.1-8.3-0.1-16.5-0.1-24.8
				c0-4.4-0.1-8.8-0.1-13.1c0-0.5-0.2-1.1,0.2-1.7c1.5,0.2,3.1,0.4,4.6,0.5c1.6,0.1,3.3,0.2,4.9,0.3
				C343.4,420.2,345,420.2,346.8,420.2z"/>
            <path className="st14" d="M468.5,472.1c-4.4-3.1-8.8-5.5-12.6-8.6c0-1,0-2,0-2.9c-0.1-4.3-0.1-8.5-0.2-12.8c-0.2-6.5-0.4-13-0.1-19.5
				c0.1-2,0.1-4,0.1-6c0-0.6,0-1.2,0.1-1.8c2.2-1.2,7.2-2.2,10.1-2.1c0.1,0.3,0.2,0.7,0.2,1c0.4,6.9,0.8,13.9,1.2,20.8
				c0.3,4.5,0.6,9,0.9,13.5c0.4,5.7,0.6,11.4,0.5,17.1C468.6,471.1,468.5,471.5,468.5,472.1z"/>
            <path className="st61" d="M350.5,484.8c0.8,0,1.3,0,1.8,0c10.7-0.1,21.4-0.3,32.1,0.1c4.7,0.2,9.5,0.4,14.2,0.6
				c6.8,0.2,13.6,0.5,20.4,0.6c8.8,0.1,17.5-0.7,26.2-2.2c2-0.3,4-0.6,6-0.9c0.6-0.1,1.2-0.2,1.9,0c0.9,11.3,0.5,22.6-0.3,34
				c-0.7,0-1.4,0.1-2,0.1c-3.8-0.1-7.7,0.3-11.5,0.6c-2.4,0.2-4.9,0.3-7.3,0.4c-1.1,0-2.2,0-3.3-0.2c-1.3-0.2-2.7,0-4,0
				c-3.6,0.1-7.2,0.4-10.8,0.4c-6.1,0.1-12.2,0.1-18.3,0.1c-1.9,0-3.8,0.3-5.6,0.4c-1.9,0.1-3.8,0.3-5.6,0.4
				c-2.6,0.1-5.2,0.2-7.8,0.4c-3.1,0.1-6.2,0.3-9.3,0.4c-3.8,0.2-7.7,0.3-11.5,0.4c-0.9,0-1.7,0.1-2.6,0.2c-0.4,0-0.8,0-1.3,0
				c-0.1-0.5-0.1-0.8-0.1-1.2c0.1-2.8-0.3-5.5-0.5-8.2c-0.2-2.8-0.4-5.6-0.4-8.4c0.1-4.9,0-9.9-0.5-14.8
				C350.6,487,350.6,486,350.5,484.8z"/>
            <path className="st71" d="M453.8,518.3c4.1,4.4,8.2,8.8,12.3,13.2c0.2,0.6,0.5,1.3,0.8,2c-1.6,0.3-3.2,0.6-4.9,0.6
				c-3.4,0-6.8,0.2-10.2,0.4c-4.3,0.2-8.5,0.3-12.8,0c-3.5-0.2-6.9-0.3-10.4-0.5c-9.1-0.4-18.1-0.6-27.2-0.3
				c-4.3,0.1-8.6,0.2-13,0.4c-8.1,0.4-16.2,0.2-24.3-0.1c-8.1-0.3-16.2-0.3-24.3,0.1c-1.4,0.1-2.8,0.2-4.1,0.3
				c-0.3-0.4,0-0.5,0.2-0.7c3.2-2,6.3-4.3,9.3-6.7c0.5-0.4,1.1-0.8,1.7-1.2c0.4-0.3,0.9-0.6,1.1-1c0.7-1.4,2-2.1,3.4-2.9
				c1.7,0,3.5,0,5.3,0c5.2-0.1,10.3-0.2,15.5-0.4c4-0.1,7.9-0.4,11.9-0.5c2.1-0.1,4.1-0.1,6.2-0.2c0.9,0,1.8-0.1,2.7-0.4
				c0.6-0.2,1.2-0.2,1.8-0.2c1.3,0,2.7,0,4,0c4.4,0,8.8,0,13.1-0.1c4.5-0.1,9-0.3,13.5-0.5c0.6,0,1.2,0.1,1.8,0.1
				c0.3,0,0.6,0.1,0.9,0.1c3.3-0.1,6.7-0.1,10-0.3c4.3-0.2,8.6-0.6,12.9-0.9C452,518.5,452.8,518.4,453.8,518.3z"/>
            <path className="st29" d="M350.8,520.7c-2.5,1.9-4.8,3.6-7.1,5.4c-2.9,2.2-5.8,4.4-8.7,6.5c-0.2,0.2-0.5,0.3-0.9,0.5
				c-0.1-0.7-0.2-1.2-0.2-1.8c-0.1-2.8-0.2-5.6-0.2-8.4c-0.2-7.9-0.3-15.9-0.5-23.8c-0.1-4.3-0.3-8.6-0.4-12.9c0-0.7-0.2-1.4,0.2-2
				c0.1-0.1,0.3-0.1,0.4-0.1c5.2,0.6,10.4,0.9,15.7,0.8c0.1,0.5,0.2,0.9,0.2,1.4c0.1,5.4,0.2,10.7,0.3,16.1c0,4.2,0.3,8.4,0.8,12.5
				c0.2,1.5,0.3,3.1,0.4,4.7C350.9,519.9,350.8,520.3,350.8,520.7z"/>
            <path className="st14" d="M454.3,482.7c4.8-0.5,9.4-1.2,14.1-0.8c0.1,0.5,0.1,0.8,0.2,1.2c0,0.7,0,1.5,0,2.3
				c0.1,13.3,0.3,26.6,0.8,40c0,0.5,0,1,0,1.5c-0.1,1.4-0.3,2.8-0.5,4.4c-0.7-0.1-1.3-0.4-1.8-0.9c-0.4-0.4-0.9-0.9-1.3-1.3
				c-3-3.2-6-6.4-9-9.6c-0.8-0.8-1.6-1.5-2.4-2.2c0-1.4-0.1-2.8,0-4.2c0.5-7.1,0.8-14.1,0.6-21.2c-0.1-2.2-0.4-4.5-0.6-6.7
				C454.4,484.3,454.4,483.6,454.3,482.7z"/>
		</g>
                            <g>
			<path d="M414,391.4c0-0.2-0.1-0.5-0.2-0.7c0.1-0.2,0.3-0.4,0.4-0.5c0-0.1,0-0.2,0-0.2c-0.3-1.4,0.3-2.3,1.7-2.5
				c0.6-0.1,1.1-0.1,1.7,0c0.2,0,0.3,0,0.5,0.1c0-0.2,0-0.4,0-0.5c0-0.8,0.6-1.5,1.4-1.5c0.4,0,0.9,0,1.4,0.1c0-0.1,0.1-0.2,0.2-0.4
				c0.3-0.7,0.9-1,1.6-1.1c0.2,0,0.5,0,0.7,0c0-0.2,0.1-0.3,0.1-0.5c0.2-0.9,1.2-1.5,2-1.5c0.5,0,0.8,0.2,1.3,0.9
				c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.1-0.2,0.2-0.3c0.9-1,2.3-1.2,3.4-0.3c0.2,0.1,0.3,0.3,0.5,0.4c0.5,0,1-0.2,1.5,0
				c0.5,0.2,0.8,0.6,1.2,1c0.2-0.1,0.3-0.2,0.5-0.2c0.8-0.4,1.5-0.3,2.2,0.4c0.2,0.2,0.4,0.5,0.6,0.7c0.2-0.1,0.5-0.2,0.7-0.2
				c0.1-0.1,0.1-0.3,0.1-0.4c0.1-0.5,0.4-0.8,0.8-1c0.8-0.4,1.5-0.5,2.3,0.1c0.7,0.5,1.1,1.3,1,2.2c0,0.2,0,0.3-0.1,0.5
				c0.3,0.2,0.6,0.4,0.9,0.7c0.2,0.3,0.4,0.6,0.6,0.9c0.3,0.1,0.3-0.1,0.5-0.3c0.5-0.5,1.1-0.5,1.6-0.3c0.4,0.2,0.6,0.5,0.7,0.9
				c0,0.3,0,0.6,0,1c0.1,0.1,0.2,0.2,0.3,0.3c0.5,0.6,0.9,1.2,1,2c0.2,1.5,0.1,3-0.2,4.5c-0.3,1.9-1,3.6-1.9,5.3
				c-0.5,0.9-1,1.7-1.8,2.4c-1,1-2.1,1.8-3.3,2.5c-1.9,1.1-4,1.6-6.2,1.7c-2,0.1-3.9-0.1-5.8-0.3c-0.4-0.1-0.9-0.1-1.4-0.1
				c-1-0.1-2.1-0.1-3.1-0.2c-1.6-0.2-3.1-0.5-4.6-1c-0.5-0.2-0.9-0.4-1.4-0.6c-1.3-0.7-2.3-1.7-3-3c-1.1-2.1-1.7-4.3-1.5-6.6
				c0-0.4,0-0.8,0.1-1.3C413.8,393.1,413.9,392.3,414,391.4z M414.8,391.7c0,0.4-0.1,0.6-0.1,0.8c0,1.3-0.1,2.5-0.1,3.8
				c0,2.2,0.9,4.2,2.2,6c0.3,0.4,0.6,0.7,1,0.9c0.8,0.6,1.6,1,2.6,1.3c1.7,0.5,3.4,0.8,5.2,0.9c1,0.1,1.9,0.1,2.9,0.2
				c2.1,0.3,4.2,0.4,6.3,0.3c0.3,0,0.6,0,0.8-0.1c2.4-0.3,4.5-1.3,6.4-2.8c1.3-1.1,2.4-2.3,3-3.8c0.1-0.2,0.2-0.4,0.3-0.6
				c0.9-2.3,1.5-4.6,1.3-7.1c0-0.3-0.1-0.7-0.2-1c-0.1-0.2-0.2-0.3-0.4-0.4c-0.6,0.3-1.1,0.7-1.5,1.1c-1.5,1.4-3.2,2.4-5.1,3.1
				c-0.3,0.1-0.6,0.2-0.9,0.4c-1.8,0.8-3.6,1.1-5.5,1.2c-2,0.1-4.1,0.1-6.1-0.2c-1.3-0.2-2.6-0.4-3.8-0.7c-2.6-0.7-5-1.6-7.4-2.8
				C415.4,391.9,415.1,391.9,414.8,391.7z M417.6,391.1c0.7-0.3,1.1-0.7,1.2-1.4c0-0.7-0.4-1.1-1-1.4c-0.8-0.4-1.6-0.4-2.4,0
				c-0.7,0.4-0.8,1-0.4,1.6c0.3,0.4,0.6,0.6,0.9,0.7C416.5,390.9,417,391,417.6,391.1z M425.6,389.4c0.7-0.2,1.3-0.4,1.8-0.7
				c0.4-0.2,0.7-0.7,0.7-1.2c-0.1-0.7-0.4-1.3-1-1.6c-0.6-0.3-1.3-0.2-1.8,0.3c-0.1,0.2-0.2,0.4-0.4,0.7c-0.2,0.2-0.3,0.4-0.5,0.7
				C424.9,388.1,425.3,388.6,425.6,389.4z M418.8,386.6c-0.2,0.5-0.2,0.9-0.1,1.2c0.3,0.5,0.6,1,0.9,1.5c0.5-0.2,1.1-0.2,1.6-0.5
				c0.4-0.6,0.2-1.2-0.1-1.7C420.5,386,419.7,385.9,418.8,386.6z M421.3,385.9c0.3,0.6,0.6,1.1,0.8,1.6c0.6-0.1,1.1-0.1,1.6-0.2
				c0.1-0.2,0.3-0.3,0.4-0.5c0.4-0.5,0.3-0.9,0-1.4c-0.4-0.5-1.4-0.8-2-0.6c-0.2,0.1-0.3,0.2-0.4,0.3
				C421.6,385.4,421.4,385.6,421.3,385.9z M434.7,386.3c0.5-0.1,1-0.2,1.5-0.3c0.2-0.2,0.3-0.5,0.5-0.7c-0.1-0.7-0.3-1-0.7-1.3
				c-0.3-0.3-0.7-0.3-1.1-0.2c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.6-0.4,1.1-0.5,1.6C434,386,434.4,386.2,434.7,386.3z M437.5,384.9
				c-0.3,0.5-0.5,1-0.8,1.5c0.2,0.2,0.5,0.5,0.7,0.7c0.4-0.1,0.8-0.3,1.1-0.3c0.4,0,0.7,0.1,1.1,0.1c0.4-0.6,0.1-1.1-0.2-1.5
				C438.9,384.8,438.2,384.7,437.5,384.9z M424.3,390.5c0.2-0.3,0.5-0.7,0.4-1.1c-0.1-0.5-0.4-0.9-0.9-1.1c-0.3-0.2-0.7-0.2-1.1-0.1
				c-0.4,0.1-0.7,0.3-0.8,0.7c0.5,0.5,0.9,1.1,1.1,1.8C423.5,390.9,423.9,390.6,424.3,390.5z M436,391.9c-0.4-0.1-0.7-0.1-1,0.2
				c-0.4,0.3-0.8,0.6-1,1.1c-0.2,0.5,0,0.9,0.5,1c0.5,0.2,1,0,1.5-0.2c0.4-0.2,0.6-0.6,0.7-1.1C436.7,392.4,436.4,392.1,436,391.9z
				 M419.6,390.8c0.2,0.5,0.5,0.9,1,1.2c0.3,0.2,0.6,0.2,0.9,0.1c0.9-0.8,0.8-1.7-0.4-2.5C420.2,389.6,419.8,389.9,419.6,390.8z
				 M432.5,388.5c0.4,0.2,0.8,0.1,1.1,0c0.4-0.1,0.7-0.3,0.7-0.7c0.1-0.4-0.1-0.8-0.5-1c-0.2-0.1-0.5-0.2-0.7-0.3c-0.3,0-0.7,0-1,0
				c-0.4,0.4-0.8,0.5-1,1.1c0.1,0.1,0.1,0.1,0.2,0.2c0.3,0,0.4,0,0.5,0C432.1,388.1,432.3,388.3,432.5,388.5z M441.3,385.3
				c0.3-0.5,0.1-0.9-0.1-1.2c-0.5-1-1.4-1.3-2.5-0.6c-0.2,0.1-0.3,0.3-0.2,0.6c0.3,0.2,0.7,0.3,1,0.6c0.3,0.3,0.6,0.6,0.8,0.9
				C440.8,385.6,441,385.4,441.3,385.3z M430.3,382.8c-0.5-0.3-0.9-0.5-1.4-0.4c-0.3,0-0.6,0.1-0.8,0.4c-0.2,0.2-0.5,0.4-0.6,0.8
				c0.3,0.1,0.6,0.2,0.9,0.3c0.3,0.1,0.5,0.4,0.7,0.6c0.5-0.1,1-0.2,1.5-0.3C430.7,383.6,430.6,383.2,430.3,382.8z M433.1,389.4
				c-0.2,0.2-0.4,0.4-0.6,0.6c-0.2,0.3-0.2,0.6,0.1,0.8c0.4,0.4,0.9,0.6,1.4,0.6c0.4,0,0.8-0.3,0.9-0.7c0.1-0.4,0.1-0.7-0.2-1
				C434.3,389.4,433.8,389.4,433.1,389.4z M431.1,384.3c0.3,0.5,0.7,1,1,1.5c0.3,0.1,0.6-0.1,0.8-0.4c0.2-0.2,0.4-0.5,0.4-0.8
				c0.1-0.7-0.4-1.2-1.2-1.4C431.3,383.4,431.2,383.6,431.1,384.3z M426.1,389.9c-0.3,0.6-0.1,1,0.3,1.4c0.4,0.3,0.8,0.5,1.3,0.3
				c0.5-0.1,0.8-0.8,0.7-1.2c-0.2-0.5-0.7-0.9-1.2-0.8C426.7,389.7,426.4,389.8,426.1,389.9z M425.7,382.7c-0.7-0.1-1.2,0.1-1.6,0.5
				c-0.3,0.3-0.4,0.6-0.2,1c0.2,0.2,0.4,0.3,0.6,0.5c0.2,0.2,0.4,0.5,0.6,0.8c0.1-0.1,0.3-0.1,0.4-0.2c0-0.4-0.1-0.7,0-1
				c0.1-0.3,0.4-0.6,0.6-0.9C426.2,383.1,426,382.9,425.7,382.7z M423.4,391.4c-0.3,0.2-0.5,0.5-0.4,0.9c0.1,0.4,0.3,0.7,0.6,0.9
				c0.4,0.3,0.9,0.3,1.4,0.1c0.3-0.2,0.5-0.5,0.4-0.9c-0.1-0.4-0.3-0.6-0.7-0.8C424.3,391.4,423.9,391.2,423.4,391.4z M430.9,392.1
				c-0.5,0.5-0.5,0.5-0.7,1.1c0.2,0.6,0.7,0.8,1.3,0.9c0.5,0.1,0.9-0.2,1-0.7c0.1-0.5,0-0.9-0.5-1.1
				C431.8,392.1,431.4,392,430.9,392.1z M430.1,388.2c-0.4,0.1-0.6,0.4-0.8,0.7c-0.1,0.3-0.1,0.7,0,1c0.2,0.5,0.8,0.9,1.3,0.7
				c0.4-0.1,0.7-0.7,0.6-1.3C431.1,388.7,430.7,388.3,430.1,388.2z M431,385.4c-0.5-0.1-1.1-0.2-1.6-0.4c-0.3,0.2-0.4,0.4-0.5,0.6
				c-0.1,0.4,0,0.8,0.3,1.2c0.3,0.3,0.6,0.4,1,0.2c0.3-0.1,0.6-0.4,0.7-0.7C431.2,386.2,431.2,385.9,431,385.4z M442.8,387.3
				c-0.1-0.5-0.4-0.8-0.8-1c-0.3,0-0.7-0.1-1-0.1c-0.4,0.3-0.5,0.6-0.5,1c0,0.3,0.2,0.6,0.5,0.8c0.4,0.2,0.8,0.2,1.2,0.1
				C442.5,388,442.7,387.8,442.8,387.3z M441.6,389.4c0,0.2-0.1,0.4,0,0.7c0,0.4,0.2,0.7,0.5,0.8c0.3,0.1,0.7,0.1,1-0.2
				c0.3-0.2,0.5-0.5,0.5-0.9c0-0.4-0.2-0.7-0.6-1C442.4,388.8,442,388.9,441.6,389.4z M437.8,390.8c-0.1-0.6-0.1-0.7-0.3-0.9
				c-0.2-0.2-0.5-0.4-0.8-0.5c-0.2-0.1-0.5-0.1-0.7,0c-0.2,0.1-0.3,0.2-0.4,0.4c0,0.4,0.1,0.8,0.1,1.2c0.5,0.1,0.9,0.3,1.2,0.4
				C437.4,391.3,437.6,391.1,437.8,390.8z M437.6,388.7c0.4,0.4,0.7,0.8,1.1,1.2c0.5-0.2,0.6-0.7,0.8-1c0.1-0.5,0-1-0.4-1.2
				c-0.4-0.3-0.9-0.3-1.3,0.1C437.7,388,437.7,388.4,437.6,388.7z M438.3,392.3c0.2,0.1,0.3,0.2,0.5,0.2c0.5,0,0.9-0.1,1.3-0.4
				c0.3-0.2,0.6-0.6,0.5-1c-0.1-0.5-0.4-0.7-0.8-0.8c-0.5,0.2-0.6,0.7-0.8,1.1C438.7,391.6,438.3,391.7,438.3,392.3z M436.8,388.4
				c0.3-0.4,0.1-0.8-0.2-1.1c-0.2-0.2-0.5-0.4-0.8-0.5c-0.2,0-0.4,0-0.6,0.1c0,0.4,0,0.7,0,1c-0.1,0.3-0.2,0.6-0.3,0.9
				c0.1,0.1,0.3,0.2,0.3,0.3C435.7,388.9,436.2,388.7,436.8,388.4z M426.8,393.5c0.4,0.5,0.9,0.6,1.5,0.5c0.4,0,0.7-0.2,0.8-0.6
				c-0.2-0.7-0.9-0.6-1.3-1C427.2,392.5,426.8,392.8,426.8,393.5z M444.6,388.9c0.4,0.1,0.6-0.1,0.7-0.4c0.2-0.4,0.3-0.7,0.1-1.1
				c-0.2-0.3-0.6-0.4-1-0.2C443.9,387.9,443.9,388.2,444.6,388.9z M430.1,391.7c-0.4-0.3-0.6-0.5-1.1-0.5c-0.1,0.2-0.3,0.5-0.4,0.8
				c0.3,0.2,0.6,0.3,0.9,0.4c0.1-0.1,0.2-0.2,0.3-0.3C429.9,392,430,391.9,430.1,391.7z M418.3,391.9c0.7,0.4,1,0.6,1.6,0.5
				c-0.3-0.4-0.6-0.8-1-1C418.6,391.4,418.4,391.5,418.3,391.9z M426.3,384.5c0.2,0.1,0.2,0.2,0.3,0.3c0.4,0.1,0.7,0.1,1.1,0
				c0.1,0,0.2-0.2,0.2-0.4c-0.3-0.3-0.8-0.2-1.2-0.3C426.6,384.3,426.5,384.4,426.3,384.5z M440.2,389.4c0.3,0.2,0.4,0.4,0.8,0.3
				c0.1-0.2,0.1-0.4,0.2-0.6c0,0,0-0.1-0.1-0.2c-0.2-0.1-0.4-0.3-0.7-0.2C440.4,388.9,440.3,389.1,440.2,389.4z M422.3,393.2
				c-0.1-0.2-0.2-0.3-0.2-0.4c-0.3,0-0.4,0-0.5,0.3C421.8,393.3,421.9,393.4,422.3,393.2z M437.4,393.2c0.1,0.1,0.2,0.2,0.3,0.3
				c0.1-0.1,0.2-0.2,0.4-0.3c-0.1-0.1-0.2-0.2-0.4-0.3C437.6,393.1,437.6,393.1,437.4,393.2z M443.3,388.5c0.2-0.2,0.2-0.3,0.1-0.5
				C443.2,388.1,443.2,388.1,443.3,388.5z M426,394.2c0-0.1,0.1-0.1,0.1-0.2c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.1,0.3
				C425.9,394.1,425.9,394.1,426,394.2z M442,391.4c-0.3-0.1-0.4,0-0.4,0.2c0.1,0,0.1,0.1,0.2,0.1
				C441.9,391.6,441.9,391.5,442,391.4z M429.6,394.4c0,0.1,0,0.1,0,0.2c0.1,0,0.2,0,0.4,0C429.9,394.2,429.8,394.2,429.6,394.4z
				 M444.1,389.7c0.1,0,0.1,0,0.2,0c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.3-0.2C444,389.5,444,389.6,444.1,389.7z"/>
                                <path d="M452.1,407.3c-0.6,0-1.3-0.2-2-0.6c-1.3-0.8-1.1-2.6-0.4-3.3c0.1-0.1,0.3-0.3,0.5-0.3c0.7-0.3,1.5-0.5,2.3-0.5
				c0.6,0,1,0.2,1.4,0.7c0.7,0.9,0.9,1.9,0.4,2.9C453.9,407,453.2,407.3,452.1,407.3z M450.8,403.5c-0.2,0.1-0.4,0.3-0.5,0.5
				c-0.6,0.9-0.3,1.7,0.5,2.1c0.4,0.2,0.8,0.3,1.2,0.4c0.8,0.1,1.5-0.4,1.6-1.3c0.2-1-0.6-1.8-1.5-1.8
				C451.7,403.4,451.3,403.5,450.8,403.5z"/>
                                <path d="M446.2,407.1c-0.1,0-0.4-0.1-0.7-0.1c-0.6-0.2-1.1-0.5-1.2-1.2c-0.1-0.7,0-1.3,0.5-1.7c0.7-0.6,1.5-0.7,2.4-0.5
				c1.3,0.4,1.6,1.9,0.6,2.9C447.5,406.8,447,407.1,446.2,407.1z M445.3,405.9c0.2,0.1,0.4,0.2,0.6,0.3c0.5,0.2,1,0,1.3-0.3
				c0.5-0.4,0.4-1-0.2-1.3c-0.4-0.2-0.8-0.3-1.2-0.2C445.1,404.9,445.1,405,445.3,405.9z"/>
                                <path d="M458.1,406.7c-0.7,0.7-1.5,0.9-2.3,0.5c-0.7-0.3-1-1-0.7-1.7c0.1-0.2,0.2-0.5,0.4-0.6c0.2-0.1,0.4-0.3,0.6-0.3
				c0.6,0,1.2,0.1,1.6,0.5C458.2,405.6,458.3,406.1,458.1,406.7z M457.4,406.4c0-0.7-0.2-0.9-1.1-1.1c-0.5,0.4-0.6,0.5-0.3,1.1
				C456.5,406.7,456.9,406.6,457.4,406.4z"/>
                                <path className="st32" d="M414.8,391.7c0.3,0.2,0.6,0.3,0.8,0.4c2.3,1.2,4.8,2.2,7.4,2.8c1.3,0.3,2.6,0.5,3.8,0.7
				c2,0.3,4.1,0.3,6.1,0.2c1.9-0.1,3.8-0.4,5.5-1.2c0.3-0.1,0.6-0.2,0.9-0.4c1.9-0.7,3.6-1.8,5.1-3.1c0.4-0.4,0.9-0.8,1.5-1.1
				c0.1,0.1,0.3,0.3,0.4,0.4c0.1,0.3,0.2,0.7,0.2,1c0.1,2.5-0.4,4.8-1.3,7.1c-0.1,0.2-0.2,0.4-0.3,0.6c-0.6,1.6-1.7,2.8-3,3.8
				c-1.9,1.5-4,2.5-6.4,2.8c-0.3,0-0.6,0.1-0.8,0.1c-2.1,0.1-4.2,0-6.3-0.3c-1-0.1-1.9-0.2-2.9-0.2c-1.8-0.1-3.5-0.4-5.2-0.9
				c-0.9-0.3-1.8-0.7-2.6-1.3c-0.4-0.3-0.7-0.6-1-0.9c-1.3-1.8-2.1-3.8-2.2-6c0-1.3,0.1-2.5,0.1-3.8
				C414.7,392.3,414.8,392.1,414.8,391.7z"/>
                                <path className="st72" d="M417.6,391.1c-0.5-0.1-1-0.2-1.6-0.4c-0.3-0.1-0.6-0.4-0.9-0.7c-0.5-0.6-0.3-1.2,0.4-1.6
				c0.8-0.4,1.6-0.4,2.4,0c0.6,0.3,1,0.7,1,1.4C418.7,390.4,418.3,390.8,417.6,391.1z"/>
                                <path className="st21" d="M425.6,389.4c-0.2-0.8-0.6-1.3-1.2-1.9c0.2-0.2,0.4-0.4,0.5-0.7c0.1-0.2,0.2-0.4,0.4-0.7
				c0.5-0.5,1.2-0.6,1.8-0.3c0.7,0.3,1,0.9,1,1.6c0,0.5-0.2,1-0.7,1.2C426.9,389,426.3,389.2,425.6,389.4z"/>
                                <path className="st17" d="M418.8,386.6c1-0.7,1.8-0.6,2.3,0.5c0.3,0.5,0.5,1.1,0.1,1.7c-0.5,0.3-1,0.3-1.6,0.5
				c-0.3-0.5-0.6-0.9-0.9-1.5C418.6,387.4,418.5,387,418.8,386.6z"/>
                                <path className="st72" d="M421.3,385.9c0.1-0.2,0.3-0.5,0.4-0.7c0.1-0.1,0.3-0.2,0.4-0.3c0.6-0.2,1.6,0,2,0.6c0.4,0.5,0.4,0.9,0,1.4
				c-0.1,0.2-0.3,0.3-0.4,0.5c-0.5,0.1-1.1,0.1-1.6,0.2C421.9,387,421.6,386.5,421.3,385.9z"/>
                                <path className="st72" d="M434.7,386.3c-0.3-0.2-0.6-0.3-1-0.5c0.2-0.5,0.3-1,0.5-1.6c0.1-0.1,0.3-0.2,0.6-0.3
				c0.4-0.1,0.8-0.1,1.1,0.2c0.4,0.3,0.5,0.6,0.7,1.3c-0.1,0.2-0.3,0.5-0.5,0.7C435.6,386.1,435.2,386.2,434.7,386.3z"/>
                                <path className="st17" d="M437.5,384.9c0.7-0.2,1.4-0.1,1.9,0.5c0.3,0.4,0.6,0.9,0.2,1.5c-0.4,0-0.7-0.1-1.1-0.1
				c-0.4,0-0.7,0.2-1.1,0.3c-0.2-0.2-0.5-0.5-0.7-0.7C437,385.9,437.2,385.4,437.5,384.9z"/>
                                <path className="st32" d="M424.3,390.5c-0.4,0.1-0.7,0.4-1.2,0.3c-0.2-0.7-0.6-1.3-1.1-1.8c0.1-0.5,0.4-0.6,0.8-0.7
				c0.4-0.1,0.7-0.1,1.1,0.1c0.4,0.3,0.7,0.6,0.9,1.1C424.7,389.8,424.5,390.1,424.3,390.5z"/>
                                <path className="st13" d="M436,391.9c0.4,0.2,0.6,0.5,0.7,1c0,0.5-0.3,0.9-0.7,1.1c-0.5,0.2-0.9,0.4-1.5,0.2c-0.5-0.1-0.7-0.6-0.5-1
				c0.2-0.5,0.6-0.8,1-1.1C435.3,391.8,435.7,391.8,436,391.9z"/>
                                <path className="st13" d="M419.6,390.8c0.2-0.9,0.6-1.2,1.6-1.1c1.2,0.8,1.3,1.6,0.4,2.5c-0.3,0.1-0.6,0-0.9-0.1
				C420.2,391.6,419.9,391.3,419.6,390.8z"/>
                                <path className="st11" d="M432.5,388.5c-0.2-0.2-0.4-0.5-0.6-0.7c-0.2,0-0.3,0-0.5,0c0,0-0.1-0.1-0.2-0.2c0.2-0.6,0.7-0.8,1-1.1
				c0.3,0,0.7,0,1,0c0.2,0,0.5,0.1,0.7,0.3c0.4,0.2,0.6,0.6,0.5,1c-0.1,0.4-0.4,0.6-0.7,0.7C433.2,388.7,432.9,388.7,432.5,388.5z"
                                />
                                <path className="st13" d="M441.3,385.3c-0.3,0.1-0.6,0.3-0.9,0.4c-0.3-0.3-0.5-0.7-0.8-0.9c-0.3-0.3-0.7-0.4-1-0.6
				c-0.1-0.3,0-0.5,0.2-0.6c1.1-0.7,1.9-0.4,2.5,0.6C441.5,384.4,441.6,384.8,441.3,385.3z"/>
                                <path className="st13" d="M430.3,382.8c0.3,0.4,0.5,0.8,0.2,1.3c-0.5,0.1-1,0.2-1.5,0.3c-0.2-0.2-0.4-0.4-0.7-0.6
				c-0.3-0.1-0.6-0.2-0.9-0.3c0-0.4,0.3-0.6,0.6-0.8c0.2-0.2,0.5-0.3,0.8-0.4C429.4,382.3,429.8,382.4,430.3,382.8z"/>
                                <path className="st14" d="M433.1,389.4c0.7,0,1.2,0,1.6,0.3c0.3,0.3,0.3,0.7,0.2,1c-0.1,0.4-0.5,0.7-0.9,0.7c-0.5,0-1-0.2-1.4-0.6
				c-0.2-0.2-0.3-0.6-0.1-0.8C432.7,389.8,432.9,389.6,433.1,389.4z"/>
                                <path className="st19" d="M431.1,384.3c0.1-0.8,0.2-0.9,1-1.1c0.9,0.3,1.3,0.8,1.2,1.4c0,0.3-0.2,0.6-0.4,0.8
				c-0.2,0.2-0.4,0.5-0.8,0.4C431.8,385.3,431.5,384.8,431.1,384.3z"/>
                                <path className="st11" d="M426.1,389.9c0.4-0.1,0.7-0.2,1-0.3c0.5-0.1,1.1,0.3,1.2,0.8c0.1,0.4-0.2,1.1-0.7,1.2
				c-0.5,0.2-0.9,0-1.3-0.3C426,390.9,425.7,390.5,426.1,389.9z"/>
                                <path className="st14" d="M425.7,382.7c0.3,0.2,0.4,0.4,0.4,0.7c-0.2,0.3-0.5,0.5-0.6,0.9c-0.1,0.3,0,0.7,0,1c-0.1,0-0.2,0.1-0.4,0.2
				c-0.2-0.3-0.4-0.5-0.6-0.8c-0.2-0.2-0.4-0.4-0.6-0.5c-0.2-0.4,0-0.7,0.2-1C424.5,382.8,425,382.6,425.7,382.7z"/>
                                <path className="st19" d="M423.4,391.4c0.5-0.2,0.9,0,1.3,0.2c0.4,0.2,0.6,0.4,0.7,0.8c0.1,0.4-0.1,0.6-0.4,0.9
				c-0.5,0.2-0.9,0.1-1.4-0.1c-0.3-0.2-0.5-0.5-0.6-0.9C422.9,391.9,423.1,391.6,423.4,391.4z"/>
                                <path className="st72" d="M430.9,392.1c0.4,0,0.8,0,1.2,0.2c0.5,0.3,0.6,0.7,0.5,1.1c-0.1,0.5-0.5,0.7-1,0.7
				c-0.6-0.1-1.1-0.3-1.3-0.9C430.4,392.6,430.4,392.6,430.9,392.1z"/>
                                <path className="st32" d="M430.1,388.2c0.6,0.2,1,0.6,1.1,1.2c0.1,0.5-0.2,1.1-0.6,1.3c-0.5,0.1-1-0.2-1.3-0.7c-0.1-0.3-0.1-0.6,0-1
				C429.5,388.5,429.7,388.3,430.1,388.2z"/>
                                <path className="st17" d="M431,385.4c0.2,0.4,0.2,0.7,0,1c-0.2,0.3-0.4,0.5-0.7,0.7c-0.4,0.1-0.7,0.1-1-0.2c-0.3-0.3-0.5-0.7-0.3-1.2
				c0.1-0.2,0.2-0.5,0.5-0.6C429.9,385.2,430.5,385.3,431,385.4z"/>
                                <path className="st32" d="M442.8,387.3c-0.1,0.5-0.4,0.7-0.7,0.8c-0.4,0.1-0.8,0.1-1.2-0.1c-0.3-0.2-0.5-0.4-0.5-0.8
				c0-0.4,0.1-0.7,0.5-1c0.3,0,0.7,0.1,1,0.1C442.4,386.6,442.7,386.9,442.8,387.3z"/>
                                <path className="st14" d="M441.6,389.4c0.4-0.4,0.9-0.6,1.4-0.5c0.4,0.2,0.6,0.5,0.6,1c-0.1,0.4-0.3,0.6-0.5,0.9
				c-0.3,0.2-0.6,0.3-1,0.2c-0.3-0.1-0.5-0.4-0.5-0.8C441.5,389.8,441.5,389.6,441.6,389.4z"/>
                                <path className="st17" d="M437.8,390.8c-0.2,0.4-0.4,0.6-0.9,0.6c-0.4-0.1-0.8-0.2-1.2-0.4c0-0.4-0.1-0.8-0.1-1.2
				c0.1-0.1,0.3-0.3,0.4-0.4c0.2-0.1,0.5-0.1,0.7,0c0.3,0.1,0.5,0.3,0.8,0.5C437.7,390.1,437.7,390.2,437.8,390.8z"/>
                                <path className="st19" d="M437.6,388.7c0.1-0.4,0.1-0.7,0.2-1c0.5-0.3,0.9-0.4,1.3-0.1c0.4,0.3,0.5,0.7,0.4,1.2
				c-0.3,0.3-0.4,0.8-0.8,1C438.3,389.5,438,389.1,437.6,388.7z"/>
                                <path className="st21" d="M438.3,392.3c-0.1-0.6,0.4-0.7,0.6-0.9c0.3-0.4,0.3-0.9,0.8-1.1c0.5,0,0.7,0.3,0.8,0.8
				c0.1,0.4-0.1,0.8-0.5,1c-0.4,0.3-0.8,0.4-1.3,0.4C438.7,392.5,438.5,392.4,438.3,392.3z"/>
                                <path className="st14" d="M436.8,388.4c-0.6,0.2-1.1,0.4-1.6,0.7c-0.1-0.1-0.2-0.2-0.3-0.3c0.1-0.3,0.3-0.6,0.3-0.9
				c0.1-0.3,0-0.6,0-1c0.2,0,0.4-0.1,0.6-0.1c0.3,0,0.6,0.2,0.8,0.5C436.8,387.7,437,388,436.8,388.4z"/>
                                <path className="st32" d="M426.8,393.5c0-0.7,0.4-1,1-1c0.4,0.4,1.1,0.3,1.3,1c-0.1,0.5-0.5,0.6-0.8,0.6
				C427.7,394.1,427.2,394,426.8,393.5z"/>
                                <path className="st32" d="M444.6,388.9c-0.7-0.6-0.8-0.9-0.2-1.7c0.4-0.1,0.7-0.1,1,0.2c0.3,0.4,0.1,0.8-0.1,1.1
				C445.2,388.8,445,388.9,444.6,388.9z"/>
                                <path className="st11" d="M430.1,391.7c-0.1,0.2-0.2,0.3-0.3,0.4c-0.1,0.1-0.2,0.2-0.3,0.3c-0.3-0.1-0.5-0.3-0.9-0.4
				c0.2-0.3,0.3-0.5,0.4-0.8C429.5,391.2,429.8,391.4,430.1,391.7z"/>
                                <path className="st19" d="M418.3,391.9c0.1-0.3,0.3-0.4,0.6-0.5c0.4,0.3,0.7,0.6,1,1C419.3,392.4,419,392.3,418.3,391.9z"/>
                                <path className="st14" d="M426.3,384.5c0.2-0.2,0.3-0.2,0.4-0.3c0.4,0.1,0.8,0,1.2,0.3c0.1,0.2,0,0.3-0.2,0.4c-0.4,0.1-0.7,0.1-1.1,0
				C426.5,384.8,426.5,384.7,426.3,384.5z"/>
                                <path className="st11" d="M440.2,389.4c0.1-0.2,0.1-0.4,0.2-0.6c0.3-0.1,0.5,0,0.7,0.2c0,0.1,0.1,0.1,0.1,0.2
				c-0.1,0.2-0.1,0.4-0.2,0.6C440.7,389.8,440.5,389.6,440.2,389.4z"/>
                                <path className="st21" d="M422.3,393.2c-0.4,0.2-0.6,0-0.8-0.2c0.1-0.3,0.3-0.3,0.5-0.3C422.2,392.9,422.2,393,422.3,393.2z"/>
                                <path className="st21" d="M437.4,393.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1,0.1,0.2,0.2,0.4,0.3c-0.1,0.1-0.2,0.2-0.4,0.3
				C437.6,393.5,437.6,393.4,437.4,393.2z"/>
                                <path className="st21" d="M443.3,388.5c-0.2-0.3-0.2-0.3,0.1-0.5C443.5,388.2,443.6,388.3,443.3,388.5z"/>
                                <path className="st21" d="M426,394.2c-0.1,0-0.2,0-0.3-0.1c-0.1-0.2,0-0.3,0.1-0.3c0.1,0,0.1,0.1,0.2,0.2
				C426.1,394.1,426.1,394.1,426,394.2z"/>
                                <path className="st21" d="M442,391.4c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1-0.1-0.2-0.1-0.2-0.1C441.6,391.3,441.7,391.3,442,391.4z"/>
                                <path className="st21" d="M429.6,394.4c0.2-0.2,0.3-0.1,0.4,0.2c-0.1,0-0.3,0-0.4,0C429.6,394.5,429.6,394.4,429.6,394.4z"/>
                                <path className="st21" d="M444.1,389.7c0-0.1,0-0.2-0.1-0.4c0.2,0.1,0.3,0.1,0.3,0.2c0,0.1,0,0.2-0.1,0.3
				C444.2,389.7,444.1,389.7,444.1,389.7z"/>
                                <path className="st13" d="M450.8,403.5c0.5,0,0.9-0.1,1.3-0.1c1-0.1,1.7,0.8,1.5,1.8c-0.1,0.8-0.8,1.3-1.6,1.3
				c-0.4,0-0.8-0.2-1.2-0.4c-0.8-0.4-1-1.2-0.5-2.1C450.5,403.8,450.7,403.7,450.8,403.5z"/>
                                <path className="st14" d="M445.3,405.9c-0.3-0.8-0.2-1,0.6-1.6c0.4-0.1,0.8-0.1,1.2,0.2c0.6,0.3,0.6,0.9,0.2,1.3
				c-0.4,0.4-0.8,0.5-1.3,0.3C445.8,406.1,445.6,406,445.3,405.9z"/>
                                <path className="st17" d="M457.4,406.4c-0.5,0.2-0.9,0.3-1.3,0.1c-0.3-0.6-0.3-0.7,0.3-1.1C457.2,405.5,457.4,405.7,457.4,406.4z"/>
		</g>
                            <g>
			<path className="st15" d="M415.7,321.5c0.5,0.1,0.9,0.3,1.2,0.6c0.4,0.4,0.8,0.8,1.2,1.2c0.2,0.2,0.4,0.4,0.5,0.7
				c0.7,1,1.5,1.7,2.5,2.4c0.2,0.2,0.5,0.3,0.6,0.6c-0.2,0.2-0.5,0.2-0.7,0.4c-0.2,0.2-0.4,0.4-0.7,0.6c-0.2-0.1-0.4-0.2-0.5-0.3
				c-0.4-0.4-0.8-0.8-1.2-1.2c-0.2-0.2-0.4-0.4-0.6-0.6c-0.5-0.6-1.2-1-2-1.1c-0.6,0-1-0.2-1.2-0.8c-0.3-0.6-0.3-1.3,0-1.9
				C415,321.8,415.3,321.6,415.7,321.5z"/>
                                <g>
				<path d="M437.1,363.7c-0.3-0.8-0.2-1.5-0.1-2.1c0.4-2.3,0.7-4.6,1-6.9c0.1-0.8,0.2-1.5,0.3-2.3c0-0.4,0-0.8,0.3-1.2
					c0.1-0.3,0.1-0.6,0-1c-0.5,0.1-1,0.2-1.5,0.3c-0.5,0-1,0-1.6,0c0,0.2-0.1,0.3-0.1,0.5c0,1.8-0.2,3.7-0.4,5.5
					c-0.1,1.2-0.2,2.4-0.3,3.6c-0.1,1.2-0.2,2.3-0.3,3.5c-0.1,0.2-0.3,0.4-0.4,0.7c0,0.2,0,0.4,0,0.6c0.1,0.9-0.2,1.8-0.6,2.6
					c-0.5,1.3-1.6,2-2.9,2.3c-1.1,0.2-2.1,0.2-3.2,0c-0.2,0-0.4-0.1-0.6-0.2c-0.9-0.3-1.2-1-0.8-1.9c0.1-0.3,0.2-0.5,0.4-0.7
					c0.8-1.3,1.8-2.4,2.9-3.5c0.2-0.2,0.5-0.4,0.8-0.6c0.4-0.7,0.4-1.6,0.5-2.4c0.3-1.6,0.5-3.2,0.7-4.8c0.2-1.4,0.2-2.9,0.1-4.3
					c0-0.2-0.1-0.3-0.1-0.5c-0.2,0-0.4,0-0.6,0c-0.9,0.1-1.7,0.2-2.6,0.1c-0.2,0-0.5,0-0.7-0.3c-0.1-1,0.2-1.9,0.6-2.9
					c0.2-0.4,0.2-0.9,0.3-1.3c0.1-1.2,0.3-2.4,0.4-3.5c0.1-0.9,0.2-1.7,0.2-2.4c-0.6-2.3-1.4-4.4-1.3-6.7c0-0.1,0-0.2-0.1-0.3
					c-0.6-0.3-1.1-0.7-1.7-1c-0.7-0.4-1.3-0.8-1.7-1.5c-0.8-0.4-1.5-1-2.3-1.3c-0.2-0.1-0.4-0.2-0.6-0.4c-0.4-0.3-0.8-0.7-1.2-1
					c-0.5-0.5-1-1-1.5-1.5c-0.2-0.2-0.4-0.4-0.6-0.6c-0.3-0.4-0.8-0.7-1.3-0.8c-0.2,0-0.4-0.1-0.6-0.1c-0.8-0.1-1.3-0.6-1.5-1.3
					c-0.2-0.6-0.3-1.3-0.1-1.9c0.3-1.3,1.5-1.9,2.7-1.3c0.4,0.2,0.7,0.4,1,0.7c0.5,0.5,1,1,1.4,1.6c0.8,1.1,1.8,2,2.9,2.8
					c1.1,0.7,2.1,1.4,3.1,2.2c0.8,0.6,1.6,0.9,2.5,1c0.6,0.1,1.2,0.1,1.9,0.2c0.7,0,1.5,0,2.2,0.3c0.1-0.2,0.2-0.3,0.3-0.4
					c0.3-1,0.4-2,0.5-3c-0.1-0.1-0.2-0.3-0.3-0.4c-0.4-0.4-0.9-0.8-1.3-1.2c-0.4-0.4-0.7-0.9-1.1-1.5c-0.1,0-0.3,0-0.4,0
					c-0.5,0.1-0.9,0-1.3-0.3c-0.5-0.3-0.8-0.7-0.9-1.4c0-0.9,0.2-1.7,0.8-2.5c0.1-0.1,0.2-0.3,0.3-0.4c-0.1-0.2-0.2-0.3-0.4-0.5
					c-0.3-0.3-0.4-0.7-0.5-1.1c-0.2-0.6,0-1.1,0.5-1.5c0.2-0.2,0.5-0.4,0.6-0.6c0.1-0.4-0.1-0.7-0.2-1c-0.2-0.3-0.4-0.7-0.6-1
					c-0.3-0.6-0.2-1.1,0.4-1.6c0.4-0.3,0.9-0.4,1.5-0.3c0.4,0,0.9,0.2,1.4,0.3c0.1-0.1,0.2-0.3,0.3-0.5c0.2-0.3,0.3-0.7,0.5-1
					c0.5-0.8,1.4-0.8,2-0.1c0.2,0.2,0.3,0.5,0.5,0.7c0.1,0.1,0.2,0.2,0.4,0.4c0.2-0.1,0.4-0.2,0.5-0.3c0.3-0.2,0.5-0.4,0.8-0.7
					c0.3-0.3,0.6-0.5,1.1-0.5c0.9,0.2,0.9,0.2,1.5,1.4c0.4,0,0.6-0.2,0.9-0.3c0.5-0.2,1.1-0.2,1.5,0.2c0,0,0,0,0.1,0.1
					c0,0.2,0.1,0.5,0.1,0.9c0.3,0.3,0.7,0.1,1,0c0.4-0.1,0.7-0.2,1.1,0c0.3,0.2,0.4,0.6,0.3,1c-0.1,0.4-0.2,0.7-0.3,1
					c-0.1,0.2-0.2,0.4-0.3,0.6c0.2,0.3,0.6,0.4,0.8,0.5c0.4,0.2,0.7,0.5,0.7,1c0,0.2-0.1,0.3-0.1,0.5c-0.1,0.2-0.2,0.3-0.3,0.5
					c0.1,0.2,0.2,0.4,0.3,0.6c0.2,0.4,0.1,0.7-0.1,1c-0.2,0.2-0.5,0.4-0.7,0.6c0.1,0.2,0.1,0.3,0.2,0.5c0.2,0.4,0.3,0.8,0.3,1.3
					c0,0.8-0.3,1.4-1,1.8c-0.5,0.3-1.1,0.6-1.9,0.5c0,0-0.1,0.2-0.1,0.2c-0.7,1-1.6,1.9-2.5,2.7c-0.3,0.2-0.6,0.4-0.9,0.7
					c-0.1,0.8,0.4,1.5,0.6,2.4c0.2-0.1,0.4-0.1,0.5-0.1c0.5-0.2,1.1-0.3,1.7-0.2c1,0.1,2,0.3,2.9,0.5c0.3,0,0.6,0.1,0.8,0.2
					c0.8,0.4,1.6,0.4,2.5,0.4c1.8,0,3.5,0.1,5.3,0c0.3,0,0.5,0,0.8,0c0.2-0.1,0.4-0.3,0.6-0.4c0.2-0.1,0.5-0.3,0.8-0.4
					c0.7-0.4,1.7,0,2,1c0.4,1.2,0.1,2.3-0.8,3.2c-0.6,0.7-1.5,0.8-2.3,0.4c-0.1-0.1-0.2-0.1-0.3-0.2c-0.6-0.4-1.2-0.6-1.9-0.5
					c-1.2,0.1-2.3,0.2-3.5,0.2c-0.2,0-0.4,0.1-0.6,0.1c-1,0-2,0.3-3,0.6c-0.3,0.1-0.5,0.2-0.8,0.3c-0.7,0.3-1,0.8-0.8,1.5
					c0.1,0.6,0.1,1.2,0.3,1.8c0.2,1.1,0.4,2.2,0.5,3.3c0,0.4,0.1,0.9,0.2,1.3c0.5,0.6,1.2,1,1.4,1.7c0.2,0.7,0.3,1.4,0.1,2.1
					c0.1,0.5,0.2,1,0.3,1.5c0.2,0,0.4,0.1,0.6,0.1c0.4,0,0.8-0.1,1.2-0.1c0.4,0,0.7,0,1.1,0c0.2,0,0.4,0.1,0.6,0.4
					c-0.1,0.5,0.4,0.8,0.3,1.3c-0.3,0.4-0.6,0.4-1,0.5c-1.6,0.1-3.2,0.4-4.8,0.3c-0.1,0-0.1,0-0.2,0.1c-0.2,0.7-0.1,1.5-0.2,2.3
					c-0.1,0.8-0.2,1.6-0.3,2.4c-0.1,0.8-0.2,1.6-0.4,2.4c-0.1,0.8-0.3,1.6-0.4,2.3c-0.2,0.8-0.3,1.5-0.5,2.2
					c0.1,0.4,0.4,0.6,0.6,0.8c0.6,0.6,1.3,1.2,1.8,1.9c0.4,0.5,0.8,1,1.1,1.6c0.6,1,0.5,2-0.1,3c-0.7,1.1-1.7,1.9-2.7,2.7
					c0,0,0,0-0.1,0c-0.8,0.5-1.4,0.4-1.9-0.3c-0.6-0.9-1.2-1.8-1.8-2.8c-0.4-0.7-0.6-1.5-0.8-2.2c0-0.1,0-0.2-0.1-0.3
					C436.5,365.1,436.4,364.3,437.1,363.7z M421.1,328.5c0.2,0.2,0.4,0.3,0.6,0.4c0.9,0.6,1.8,1.1,2.7,1.6c0.3,0.2,0.5,0.3,0.8,0.5
					c0.3,0.2,0.6,0.5,0.9,0.7c0.5,0.3,1,0.5,1.5,0.8c0.2,0.1,0.3,0.2,0.5,0.3c0,0.4,0.1,0.7,0.1,1c0.2,1,0.4,1.9,0.7,2.9
					c0.4,1.2,0.7,2.5,0.5,3.7c0,0.2,0,0.4,0.1,0.7c0.2-0.1,0.3-0.1,0.4-0.2c0.3-0.1,0.5-0.2,0.8-0.3c1.9-0.7,3.1-0.3,4.5,1.2
					c0,0,0,0,0.1,0.1c0.1,0.1,0.2,0.2,0.3,0.2c0.3-0.2,0.3-0.5,0.4-0.7c0.4-1,1.1-1.6,2.2-1.8c0.8-0.1,1.6,0,2.4,0.2
					c0.2,0.1,0.5,0.2,0.7,0.1c0.1-0.3,0-0.7,0-1c-0.1-0.6-0.2-1.3-0.3-1.9c-0.1-0.7-0.1-1.5-0.1-2.2c0-0.6,0.3-1,0.7-1.4
					c0.6-0.4,1.2-0.7,1.9-0.8c1.4-0.2,2.8-0.3,4.1-0.5c0.2,0,0.4-0.1,0.6-0.1c0.2-0.4,0.3-0.7,0-1.1c-0.8-0.4-1.7-0.6-2.6-0.4
					c-0.5,0.1-1.1,0.1-1.6-0.1c-0.6-0.2-1.2-0.3-1.8-0.5c-0.5-0.1-1.1-0.2-1.6-0.3c-0.5-0.1-1.1-0.2-1.7,0.1
					c-0.1,0.2-0.3,0.4-0.4,0.6c-0.2,0.3-0.3,0.5-0.5,0.8c-0.6,1-1.7,1.5-2.8,1.8c-0.1,0-0.3,0-0.4,0c-0.8,0.1-1.6-0.1-2.3-0.6
					c-0.4-0.5-0.8-1.1-1.2-1.7c-0.3-0.4-0.6-0.6-1.1-0.6c-0.5,0-1,0-1.4,0c-0.9,0-1.8-0.2-2.7-0.5c-0.3-0.1-0.7-0.3-0.9-0.6
					c-0.6-0.6-1.4-1-2.1-1.5c-0.3-0.2-0.8-0.3-1.2-0.2C421.6,327.7,421.3,328,421.1,328.5z M430.4,315.7c0,0.6,0,1.2,0,1.8
					c0,1-0.1,1.9-0.1,2.9c0,0.6,0.1,1.2,0.2,1.9c0.1,0.8,0.5,1.4,1.1,1.9c0.6,0.5,1.1,1,1.7,1.5c0.8,0.7,1.7,0.8,2.7,0.8
					c0.1,0,0.3,0,0.4-0.1c0.7-0.3,1.3-0.7,1.8-1.2c0.5-0.6,1.1-1.2,1.6-1.8c0.5-0.6,0.7-1.3,0.7-2c0-1.3,0-2.6,0-3.8
					c0-0.6,0-1.1,0-1.7c0-0.5-0.2-1-0.6-1.3c-0.2-0.2-0.4-0.4-0.7-0.5c-1.3-0.9-2.7-1.1-4.2-1c-0.3,0-0.7,0.1-1,0.2
					C432.5,313.6,431.3,314.4,430.4,315.7z M440,311.2c-0.3-0.2-0.5-0.1-0.7,0c-0.3,0.1-0.7,0.3-1,0.4c-0.3,0.1-0.6,0-0.8-0.3
					c0-0.3-0.1-0.6-0.2-0.9c-0.2-0.1-0.4-0.2-0.5-0.2c-0.4,0.1-0.6,0.4-0.9,0.6c-0.3,0.2-0.6,0.5-0.9,0.7c-0.6,0.3-1,0.2-1.4-0.3
					c-0.2-0.3-0.3-0.5-0.5-0.8c-0.1-0.2-0.3-0.4-0.6-0.3c-0.7,0.4-0.6,1.3-1.1,1.9c-0.4,0.2-0.8,0.1-1.2,0c-0.4-0.1-0.8-0.2-1.3-0.1
					c-0.4,0-0.6,0.2-0.8,0.6c0.3,0.8,1.1,1.4,1.1,2.4c0,0.5-0.4,0.8-0.7,1.1c-0.5,0.4-0.6,0.8-0.3,1.4c0.3,0.6,0.8,1,1.3,1.4
					c0.4-0.8,0.4-2.1,0.2-3.1c0.2-0.6,0.2-0.6,0.8-1.3c1.3-1.5,4-2.7,6.2-2.1c0.2,0.1,0.4,0.1,0.7,0.1c1.2,0.2,2.2,0.8,3.1,1.6
					c0.4,0.4,0.7,0.9,0.7,1.6c0,0.9,0,1.8,0.1,2.6c0,0.2,0,0.5,0,0.8c0.6,0,1-0.1,1.4-0.4c0.3-0.2,0.6-0.3,0.6-0.7
					c-0.2-0.3-0.4-0.6-0.6-0.9c0.2-0.4,0.4-0.7,0.7-1.1c-0.1-0.1-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.3
					c-0.3-0.1-0.5-0.3-0.6-0.8c0.1-0.2,0.2-0.5,0.4-0.8c0.1-0.2,0.2-0.5,0.3-0.7c0.1-0.2,0.1-0.4-0.2-0.6c-0.6,0-1.3,0.2-1.8,0
					C439.9,312.1,440.2,311.6,440,311.2z M440.5,370.9c0.1,0,0.2,0,0.3-0.1c1-0.7,1.9-1.5,2.6-2.5c0.1-0.1,0.1-0.2,0.2-0.3
					c0.2-0.5,0.3-0.9,0-1.4c-0.3-0.5-0.5-0.9-0.9-1.3c-0.6-0.7-1.2-1.4-1.8-2.1c-0.1-0.1-0.3-0.3-0.4-0.4c-0.1,0-0.1,0-0.1,0
					c-0.1,0.1-0.2,0.2-0.3,0.3c-0.5,0.9-1.1,1.4-2.4,1c-0.1,0-0.2,0-0.3,0c-0.1,0.3-0.1,0.7,0,1c0.1,0.3,0.1,0.6,0.2,0.9
					c0.3,0.9,0.6,1.8,1.2,2.6c0.4,0.6,0.8,1.2,1.2,1.8C440,370.6,440.2,370.8,440.5,370.9z M433.3,363.4c0.3-0.3,0.2-0.6,0.2-0.8
					c0-1.2,0.1-2.4,0.2-3.6c0.1-0.8,0.2-1.6,0.3-2.4c0.1-0.7,0.2-1.5,0.2-2.2c0.1-0.8,0.2-1.6,0.3-2.4c0.1-0.6,0.1-1.2,0.1-1.9
					c0-0.2,0-0.3-0.2-0.4c-0.4,0.1-0.7,0.2-1.1,0.3c-0.4,0-0.8-0.1-1.2-0.1c0,0.2-0.1,0.3-0.1,0.5c0,0.4,0,0.7,0.1,1.1
					c0.1,0.7,0.2,1.5,0,2.2c-0.1,0.2,0,0.4,0,0.6c0,0.6-0.1,1.2-0.2,1.9c-0.2,1.8-0.5,3.6-0.8,5.4c-0.1,0.4-0.1,0.8-0.2,1.1
					c0.2,0.3,0.5,0.4,0.8,0.5C432.3,363.1,432.8,363.4,433.3,363.4z M435.4,345.5c0-0.1,0.1-0.2,0.1-0.2c-0.2-1.3-0.8-2.4-1.8-3.3
					c-0.4-0.3-0.8-0.5-1.2-0.5c-0.7,0-1.3,0.1-2,0.5c-0.5,0.3-0.9,0.7-1.1,1.3c-0.1,0.4-0.2,0.8-0.3,1.2c-0.2,1.1-0.2,2.1-0.2,3.2
					c0,0.3,0,0.7,0,1c0.8,0.2,1.5,0.1,2.2-0.1c0.3-0.7,0.3-1.3,0.4-2c0.2-0.1,0.4-0.2,0.6-0.3c0.3-0.2,0.7-0.3,1-0.4
					C433.8,345.5,434.6,345.6,435.4,345.5z M438.3,363.4c0.5-0.1,0.7-0.3,0.9-0.6c0.2-0.3,0.3-0.6,0.4-0.9c0.1-0.3,0.1-0.7,0.3-1
					c0.1-0.1,0.1-0.3,0.1-0.4c0.2-1,0.4-1.9,0.6-2.9c0.1-0.7,0.2-1.4,0.3-2.1c0.1-1,0.3-1.9,0.4-2.9c0.1-0.6,0.1-1.3,0.2-1.9
					c0-0.3,0.1-0.7-0.2-1c-0.7,0.2-1.4,0.1-2.1,0.3c0,0.2-0.1,0.3-0.1,0.5c-0.1,1.6-0.1,3.2-0.3,4.8c-0.1,1-0.3,2-0.4,3
					c-0.2,1.2-0.4,2.4-0.6,3.6c0,0.3,0,0.7,0,1c0,0.1,0.1,0.2,0.1,0.3C438,363.2,438.2,363.3,438.3,363.4z M442.7,345
					c-0.2-0.3-0.4-0.6-0.5-0.8c-0.1-0.3-0.2-0.6-0.4-0.9c-0.1-0.2-0.2-0.5-0.3-0.7c-0.5-1.2-1.5-1.7-2.7-1.7c-0.1,0-0.1,0-0.2,0
					c-1,0-1.6,0.4-2,1.4c-0.2,0.6-0.4,1.1-0.5,1.7c0.3,1.2,0.4,2.4,0.4,3.6c0,0.1,0,0.2,0,0.3c0,0.2,0.1,0.3,0.1,0.5
					c0.4,0,0.8,0,1.2-0.1c0.1,0,0.1-0.1,0.2-0.2c-0.1-0.2-0.2-0.4-0.3-0.6c0-0.8,0.1-1.6,0.8-2c0.8-0.5,1.7-0.6,2.5-0.2
					c0.1,0,0.2,0,0.3,0c0.8,0.1,1.3,0.7,1.4,1.5c0,0.3,0,0.5,0,0.8c0,0.1,0.1,0.1,0.1,0.2c0.3,0,0.5,0.1,0.7-0.3
					C443.4,346.7,442.8,345.9,442.7,345z M426.4,368.4c0.1,0.3,0.3,0.4,0.5,0.5c1.4,0.4,2.7,0.4,4.1-0.2c0.6-0.3,1-0.7,1.3-1.2
					c0.5-0.9,0.6-1.9,0.7-2.9c0-0.1-0.1-0.2-0.1-0.3c-0.8-0.3-1.6-0.6-2.4-0.8c-0.1,0-0.3,0-0.5,0c-0.7,0.8-1.4,1.6-2,2.4
					c-0.4,0.5-0.8,1-1.1,1.5C426.7,367.7,426.4,368,426.4,368.4z M433.2,326.8c-0.3,1.2-0.5,2.4-0.8,3.7c0.3,0.5,0.5,1.1,0.8,1.8
					c1,0.5,2,0.3,2.9,0c0.7-0.6,1.1-1.3,1.4-2.2c-0.6-0.9-0.7-1.9-1.1-2.9c-0.6,0-1.1,0-1.7,0C434.3,327.1,433.8,326.9,433.2,326.8z
					 M415.7,321.5c-0.5,0.1-0.8,0.3-0.9,0.6c-0.3,0.6-0.3,1.3,0,1.9c0.2,0.5,0.7,0.7,1.2,0.8c0.8,0.1,1.5,0.4,2,1.1
					c0.2,0.2,0.4,0.4,0.6,0.6c0.4,0.4,0.8,0.8,1.2,1.2c0.1,0.1,0.3,0.2,0.5,0.3c0.3-0.2,0.5-0.4,0.7-0.6c0.2-0.2,0.5-0.1,0.7-0.4
					c-0.1-0.3-0.4-0.4-0.6-0.6c-1-0.6-1.8-1.4-2.5-2.4c-0.2-0.2-0.3-0.5-0.5-0.7c-0.4-0.4-0.8-0.8-1.2-1.2
					C416.6,321.8,416.2,321.6,415.7,321.5z M449,332.3c0.2,0,0.3,0.1,0.4,0.1c0.3,0,0.5,0,0.8,0c1,0,1.9,0.1,2.7,0.8
					c0.1,0.1,0.1,0.1,0.2,0.1c0.6,0.3,1,0.3,1.4-0.2c0.7-0.7,0.9-1.5,0.7-2.4c-0.1-0.5-0.4-0.8-0.9-0.9c-0.5,0.1-0.8,0.3-1.2,0.5
					c-0.5,0.3-1,0.5-1.5,0.4c-0.7,0-1.5,0-2.2,0.1c-0.2,0-0.3,0.1-0.5,0.1C449,331.3,449,331.8,449,332.3z M439.1,345.8
					c-0.5,0.6-0.6,1.4-0.5,2.3c0.3,0.2,0.6,0.5,0.9,0.8c0.3,0,0.6,0,0.9,0c0.8-0.1,1.4-0.7,1.5-1.5c0.1-0.6-0.1-0.9-0.7-1.2
					C440.5,345.7,439.8,345.7,439.1,345.8z M432,347c0.1,0.6,0,1.1,0.2,1.6c1.1,0.6,1.6,0.5,2.6-0.5c0-0.5,0.3-1.1,0-1.8
					c-0.8-0.2-1.6,0.2-2.3,0.2C432.3,346.7,432.2,346.9,432,347z M428.6,319.2c-0.8,0.7-0.9,1.5-0.8,2.4c0,0.4,0.4,0.6,0.7,0.8
					c0.4,0.1,0.7,0.2,1-0.2c0.1-0.6,0.1-1.3,0-1.9C429.3,320,429.1,319.5,428.6,319.2z M441.3,319.9c-0.1,0.8-0.1,1.6,0,2.3
					c0,0.1,0.1,0.1,0.1,0.2c0.5,0.1,0.8-0.2,1.1-0.4c0.2-0.2,0.4-0.4,0.5-0.8c0-0.4,0.1-0.8-0.1-1.2c-0.2-0.3-0.4-0.5-0.8-0.6
					C441.9,319.6,441.6,319.7,441.3,319.9z"/>
                                    <path className="st13" d="M421.1,328.5c0.1-0.5,0.4-0.8,0.8-0.8c0.4-0.1,0.8-0.1,1.2,0.2c0.7,0.5,1.5,0.8,2.1,1.5
					c0.2,0.3,0.6,0.4,0.9,0.6c0.8,0.3,1.7,0.5,2.7,0.5c0.5,0,1,0,1.4,0c0.5,0,0.8,0.2,1.1,0.6c0.4,0.6,0.8,1.2,1.2,1.7
					c0.7,0.5,1.5,0.7,2.3,0.6c0.1,0,0.3,0,0.4,0c1.1-0.4,2.1-0.8,2.8-1.8c0.2-0.3,0.3-0.5,0.5-0.8c0.1-0.2,0.3-0.5,0.4-0.6
					c0.6-0.2,1.1-0.2,1.7-0.1c0.5,0.1,1.1,0.2,1.6,0.3c0.6,0.1,1.2,0.3,1.8,0.5c0.5,0.2,1,0.2,1.6,0.1c0.9-0.2,1.8,0,2.6,0.4
					c0.2,0.4,0.2,0.8,0,1.1c-0.2,0-0.4,0.1-0.6,0.1c-1.4,0.2-2.8,0.3-4.1,0.5c-0.7,0.1-1.3,0.4-1.9,0.8c-0.5,0.3-0.8,0.8-0.7,1.4
					c0,0.7,0.1,1.5,0.1,2.2c0.1,0.6,0.2,1.3,0.3,1.9c0,0.3,0.1,0.7,0,1c-0.3,0.1-0.5,0-0.7-0.1c-0.8-0.2-1.6-0.3-2.4-0.2
					c-1.1,0.1-1.8,0.8-2.2,1.8c-0.1,0.2-0.1,0.5-0.4,0.7c-0.1-0.1-0.2-0.2-0.3-0.2c0,0,0,0-0.1-0.1c-1.3-1.5-2.5-1.9-4.5-1.2
					c-0.3,0.1-0.5,0.2-0.8,0.3c-0.1,0.1-0.3,0.1-0.4,0.2c0-0.3-0.1-0.5-0.1-0.7c0.2-1.3-0.1-2.5-0.5-3.7c-0.3-0.9-0.5-1.9-0.7-2.9
					c-0.1-0.3-0.1-0.6-0.1-1c-0.1-0.1-0.3-0.2-0.5-0.3c-0.5-0.3-1-0.5-1.5-0.8c-0.3-0.2-0.6-0.5-0.9-0.7c-0.2-0.2-0.5-0.4-0.8-0.5
					c-0.9-0.6-1.8-1.1-2.7-1.6C421.5,328.8,421.4,328.7,421.1,328.5z"/>
                                    <path className="st15" d="M430.4,315.7c0.9-1.3,2.1-2.2,3.6-2.6c0.3-0.1,0.7-0.1,1-0.2c1.5-0.1,2.9,0.1,4.2,1
					c0.2,0.2,0.5,0.3,0.7,0.5c0.4,0.4,0.6,0.8,0.6,1.3c0,0.6,0,1.1,0,1.7c0,1.3,0,2.6,0,3.8c0,0.8-0.2,1.5-0.7,2
					c-0.5,0.6-1.1,1.2-1.6,1.8c-0.5,0.5-1.2,0.9-1.8,1.2c-0.1,0.1-0.3,0.1-0.4,0.1c-1,0.1-1.9-0.1-2.7-0.8c-0.6-0.5-1.2-1-1.7-1.5
					c-0.5-0.5-0.9-1.1-1.1-1.9c-0.1-0.6-0.2-1.2-0.2-1.9c0-1,0.1-1.9,0.1-2.9C430.4,317,430.4,316.4,430.4,315.7z M437.4,323.3
					c0-0.3-0.1-0.5-0.5-0.5c-0.9,0.7-2,1.1-3.2,0.9c-0.6-0.1-0.8-0.9-1.4-0.9c-0.2,0.2-0.2,0.4-0.1,0.6c0.4,0.9,1.4,1.4,2.4,1.2
					c0.4-0.1,0.8-0.1,1.2-0.3C436.5,324.2,437.1,323.9,437.4,323.3z M433.1,319.1c-0.1,0.1-0.2,0.2-0.4,0.4c-0.2,0.3-0.2,0.6,0,0.9
					c0.2,0.3,0.5,0.4,0.8,0.4c0.3,0,0.6,0,0.9-0.3c0.2-0.4,0.3-0.9,0-1.3C434,318.9,433.6,318.9,433.1,319.1z M436.6,319.9
					c0,0.2,0,0.5,0,0.7c0.5,0.6,0.7,0.6,1.6,0.3c0.1-0.2,0.2-0.5,0.3-0.7c-0.1-0.4-0.1-0.7-0.3-0.9
					C437.4,319.1,436.9,319.3,436.6,319.9z"/>
                                    <path className="st54" d="M440,311.2c0.2,0.4-0.1,0.9,0.5,1.3c0.5,0.2,1.2,0,1.8,0c0.2,0.2,0.2,0.4,0.2,0.6
					c-0.1,0.2-0.2,0.5-0.3,0.7c-0.1,0.3-0.3,0.6-0.4,0.8c0.1,0.4,0.3,0.6,0.6,0.8c0.2,0.1,0.4,0.1,0.6,0.3c0.1,0.1,0.2,0.2,0.4,0.4
					c-0.2,0.4-0.4,0.7-0.7,1.1c0.2,0.3,0.4,0.6,0.6,0.9c0,0.4-0.4,0.5-0.6,0.7c-0.4,0.3-0.9,0.4-1.4,0.4c0-0.3,0-0.5,0-0.8
					c0-0.9,0-1.8-0.1-2.6c0-0.6-0.3-1.1-0.7-1.6c-0.9-0.8-1.9-1.4-3.1-1.6c-0.2,0-0.5-0.1-0.7-0.1c-2.2-0.5-5,0.6-6.2,2.1
					c-0.6,0.7-0.6,0.7-0.8,1.3c0.2,1,0.2,2.2-0.2,3.1c-0.5-0.4-1-0.9-1.3-1.4c-0.3-0.6-0.2-1,0.3-1.4c0.4-0.3,0.7-0.6,0.7-1.1
					c-0.1-0.9-0.9-1.6-1.1-2.4c0.1-0.4,0.4-0.6,0.8-0.6c0.4,0,0.8,0,1.3,0.1c0.4,0.1,0.8,0.2,1.2,0c0.5-0.6,0.4-1.5,1.1-1.9
					c0.3,0,0.5,0.1,0.6,0.3c0.2,0.3,0.3,0.5,0.5,0.8c0.4,0.5,0.8,0.6,1.4,0.3c0.3-0.2,0.6-0.4,0.9-0.7c0.3-0.2,0.5-0.5,0.9-0.6
					c0.1,0.1,0.3,0.1,0.5,0.2c0.1,0.3,0.1,0.6,0.2,0.9c0.2,0.3,0.5,0.4,0.8,0.3c0.3-0.1,0.7-0.3,1-0.4
					C439.5,311.1,439.7,311,440,311.2z"/>
                                    <path className="st54" d="M440.5,370.9c-0.3-0.1-0.5-0.3-0.6-0.5c-0.4-0.6-0.8-1.2-1.2-1.8c-0.6-0.8-0.9-1.7-1.2-2.6
					c-0.1-0.3-0.2-0.6-0.2-0.9c-0.1-0.3-0.1-0.6,0-1c0.1,0,0.2,0,0.3,0c1.3,0.3,2-0.2,2.4-1c0.1-0.1,0.2-0.2,0.3-0.3c0,0,0,0,0.1,0
					c0.1,0.1,0.3,0.2,0.4,0.4c0.6,0.7,1.2,1.4,1.8,2.1c0.3,0.4,0.6,0.9,0.9,1.3c0.3,0.4,0.2,0.9,0,1.4c-0.1,0.1-0.1,0.2-0.2,0.3
					c-0.7,1-1.6,1.9-2.6,2.5C440.7,370.8,440.6,370.9,440.5,370.9z"/>
                                    <path className="st15" d="M433.3,363.4c-0.6,0.1-1-0.2-1.5-0.4c-0.3-0.1-0.6-0.1-0.8-0.5c0-0.4,0.1-0.7,0.2-1.1
					c0.3-1.8,0.6-3.6,0.8-5.4c0.1-0.6,0.1-1.2,0.2-1.9c0-0.2,0-0.4,0-0.6c0.2-0.7,0.1-1.5,0-2.2c-0.1-0.4-0.1-0.7-0.1-1.1
					c0-0.2,0.1-0.3,0.1-0.5c0.4,0,0.8,0.1,1.2,0.1c0.4,0,0.8-0.2,1.1-0.3c0.2,0.1,0.2,0.3,0.2,0.4c0,0.6-0.1,1.2-0.1,1.9
					c-0.1,0.8-0.2,1.6-0.3,2.4c-0.1,0.7-0.2,1.5-0.2,2.2c-0.1,0.8-0.1,1.6-0.3,2.4c-0.2,1.2-0.2,2.4-0.2,3.6
					C433.5,362.8,433.6,363.1,433.3,363.4z"/>
                                    <path className="st32" d="M435.4,345.5c-0.8,0-1.6,0-2.4,0.3c-0.3,0.1-0.7,0.3-1,0.4c-0.2,0.1-0.4,0.2-0.6,0.3c0,0.7,0,1.3-0.4,2
					c-0.7,0.3-1.4,0.3-2.2,0.1c0-0.4,0-0.7,0-1c0-1.1,0-2.1,0.2-3.2c0.1-0.4,0.2-0.8,0.3-1.2c0.2-0.6,0.5-1,1.1-1.3
					c0.6-0.3,1.3-0.5,2-0.5c0.5,0,0.9,0.2,1.2,0.5c1,0.9,1.6,1.9,1.8,3.3C435.4,345.4,435.4,345.4,435.4,345.5z"/>
                                    <path className="st15" d="M438.3,363.4c-0.1-0.1-0.3-0.2-0.4-0.3c0-0.1-0.1-0.2-0.1-0.3c0-0.3,0-0.7,0-1c0.2-1.2,0.4-2.4,0.6-3.6
					c0.1-1,0.3-2,0.4-3c0.2-1.6,0.2-3.2,0.3-4.8c0-0.1,0-0.3,0.1-0.5c0.7-0.2,1.4-0.1,2.1-0.3c0.3,0.3,0.2,0.7,0.2,1
					c0,0.7-0.1,1.3-0.2,1.9c-0.1,1-0.2,1.9-0.4,2.9c-0.1,0.7-0.2,1.4-0.3,2.1c-0.2,1-0.4,1.9-0.6,2.9c0,0.1-0.1,0.3-0.1,0.4
					c-0.2,0.3-0.2,0.6-0.3,1c-0.1,0.3-0.2,0.6-0.4,0.9C439,363.1,438.8,363.3,438.3,363.4z"/>
                                    <path className="st32" d="M442.7,345c0.1,0.9,0.7,1.7,0.8,2.6c-0.2,0.3-0.4,0.3-0.7,0.3c0-0.1-0.1-0.2-0.1-0.2c0-0.3,0-0.5,0-0.8
					c-0.1-0.8-0.6-1.3-1.4-1.5c-0.1,0-0.2,0-0.3,0c-0.9-0.5-1.7-0.3-2.5,0.2c-0.6,0.4-0.7,1.2-0.8,2c0.1,0.2,0.2,0.4,0.3,0.6
					c-0.1,0.1-0.1,0.2-0.2,0.2c-0.4,0.1-0.8,0.2-1.2,0.1c0-0.2-0.1-0.3-0.1-0.5c0-0.1,0-0.2,0-0.3c0.1-1.2,0-2.4-0.4-3.6
					c0.2-0.6,0.3-1.1,0.5-1.7c0.4-1,1-1.4,2-1.4c0.1,0,0.1,0,0.2,0c1.2,0,2.2,0.5,2.7,1.7c0.1,0.2,0.2,0.5,0.3,0.7
					c0.1,0.3,0.2,0.6,0.4,0.9C442.3,344.4,442.5,344.6,442.7,345z"/>
                                    <path className="st54" d="M426.4,368.4c0-0.4,0.2-0.7,0.5-1c0.4-0.5,0.7-1,1.1-1.5c0.7-0.8,1.3-1.6,2-2.4c0.2,0,0.3,0,0.5,0
					c0.8,0.3,1.6,0.6,2.4,0.8c0,0.1,0.1,0.2,0.1,0.3c0,1-0.2,2-0.7,2.9c-0.3,0.6-0.7,1-1.3,1.2c-1.3,0.6-2.7,0.6-4.1,0.2
					C426.7,368.8,426.5,368.7,426.4,368.4z"/>
                                    <path className="st15" d="M433.2,326.8c0.6,0.1,1.1,0.3,1.6,0.4c0.5,0.1,1.1,0,1.7,0c0.4,0.9,0.5,2,1.1,2.9
					c-0.3,0.8-0.8,1.5-1.4,2.2c-1,0.3-1.9,0.5-2.9,0c-0.3-0.6-0.6-1.2-0.8-1.8C432.6,329.2,432.9,328,433.2,326.8z"/>
                                    <path className="st15" d="M449,332.3c0-0.5,0-0.9,0-1.4c0.2-0.1,0.3-0.1,0.5-0.1c0.7,0,1.5-0.1,2.2-0.1c0.6,0,1.1-0.1,1.5-0.4
					c0.3-0.2,0.7-0.5,1.2-0.5c0.5,0.1,0.8,0.4,0.9,0.9c0.2,0.9,0,1.7-0.7,2.4c-0.5,0.5-0.9,0.5-1.4,0.2c-0.1,0-0.1-0.1-0.2-0.1
					c-0.8-0.7-1.7-0.8-2.7-0.8c-0.3,0-0.5,0-0.8,0C449.3,332.3,449.2,332.3,449,332.3z"/>
                                    <path className="st15" d="M439.1,345.8c0.7-0.1,1.4-0.1,2.1,0.3c0.6,0.3,0.7,0.6,0.7,1.2c-0.1,0.8-0.6,1.4-1.5,1.5
					c-0.3,0-0.6,0-0.9,0c-0.3-0.3-0.6-0.5-0.9-0.8C438.5,347.2,438.6,346.4,439.1,345.8z"/>
                                    <path className="st15" d="M432,347c0.2-0.2,0.3-0.3,0.5-0.5c0.8,0,1.5-0.4,2.3-0.2c0.3,0.6,0,1.2,0,1.8c-1,1-1.5,1.1-2.6,0.5
					C431.9,348.2,432,347.6,432,347z"/>
                                    <path className="st15" d="M428.6,319.2c0.5,0.3,0.7,0.7,0.9,1.1c0,0.6,0.1,1.3,0,1.9c-0.3,0.3-0.7,0.3-1,0.2
					c-0.4-0.1-0.7-0.4-0.7-0.8C427.7,320.7,427.8,319.9,428.6,319.2z"/>
                                    <path className="st15" d="M441.3,319.9c0.3-0.1,0.6-0.3,0.8-0.4c0.4,0,0.6,0.3,0.8,0.6c0.2,0.4,0.2,0.8,0.1,1.2
					c0,0.3-0.2,0.6-0.5,0.8c-0.3,0.3-0.7,0.5-1.1,0.4c0-0.1-0.1-0.2-0.1-0.2C441.2,321.4,441.2,320.7,441.3,319.9z"/>
                                    <path d="M437.4,323.3c-0.4,0.6-1,0.9-1.6,1.1c-0.4,0.1-0.8,0.2-1.2,0.3c-1,0.2-2-0.4-2.4-1.2c-0.1-0.2-0.1-0.4,0.1-0.6
					c0.6,0,0.8,0.8,1.4,0.9c1.2,0.2,2.3-0.2,3.2-0.9C437.3,322.8,437.5,323,437.4,323.3z"/>
                                    <path d="M433.1,319.1c0.5-0.2,0.9-0.2,1.3,0.1c0.3,0.4,0.2,0.9,0,1.3c-0.3,0.3-0.6,0.3-0.9,0.3c-0.3,0-0.6-0.1-0.8-0.4
					c-0.2-0.3-0.2-0.6,0-0.9C432.9,319.3,433,319.2,433.1,319.1z"/>
                                    <path d="M436.6,319.9c0.4-0.6,0.9-0.8,1.5-0.6c0.2,0.3,0.3,0.5,0.3,0.9c-0.1,0.2-0.2,0.5-0.3,0.7c-0.9,0.4-1,0.3-1.6-0.3
					C436.6,320.4,436.6,320.1,436.6,319.9z"/>
			</g>
		</g>
                            <g>
			<path d="M403.1,350.1c-0.5,0.2-1,0.4-1.4,0.5c-0.7,0.3-1.3,0.1-1.9-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-1-0.1-2-0.1-3.1-0.2
				c-0.1,0-0.4,0.1-0.6,0.2c-0.4,0.2-0.8,0.2-1.2,0.2c-0.5,0-0.9,0.1-1.5,0.2c-1.3-0.6-2.6-1.5-3.4-2.9c-0.6-1-1-2.2-1.3-3.4
				c-0.1-0.6-0.2-1.1-0.2-1.7c-0.1-1.3,0.2-2.5,0.6-3.6c0.2-0.7,0.6-1.3,0.9-2c0.2-0.6,0.5-1.1,0.7-1.7c0.1-0.2,0.1-0.4,0.1-0.6
				c-0.1-0.1-0.1-0.1-0.2-0.2c-0.6-0.1-1.2-0.2-1.8-0.3c-0.6-0.1-1.2-0.3-1.8-0.1c-0.1,0-0.3,0-0.4,0c-0.6-0.2-1.1,0-1.5,0.3
				c-0.2,0.2-0.4,0.4-0.7,0.6c-0.2,0.2-0.3,0.3-0.5,0.5c-0.5,0.5-1.1,0.7-1.7,0.5c-0.4-0.1-0.4-0.6-0.7-0.9c-0.2,0-0.5,0-0.8,0
				c-0.5,0-1-0.1-1.4-0.4c-0.7-0.5-1.2-1.1-1.4-1.9c-0.2-0.7,0-1.2,0.4-1.7c0.4-0.4,0.9-0.6,1.3-0.7c1.1-0.3,2.1-0.7,3.2-1
				c1.7-0.5,3.4-1.1,5.1-1.6c0.8-0.2,1.6-0.4,2.3-0.6c0.5-0.1,1-0.1,1.4-0.1c0.6,0.4,1.3,0.4,1.9,0.2c0-0.4-0.1-0.7-0.2-1
				c-0.1-0.3-0.1-0.6-0.2-1c-0.2,0-0.3,0-0.4,0c-0.8,0.1-1.3-0.1-1.7-0.9c-0.3-0.5-0.4-1.1-0.6-1.6c-0.1-0.3-0.1-0.6-0.1-0.8
				c0-0.7,0.2-1,0.9-1.3c0.3,0.1,0.6,0.1,0.9,0.2c0.2-0.3,0.1-0.7,0.1-1c0-0.4,0-0.9,0.1-1.3c0.2-0.9,0.7-1.7,1.5-2.3
				c0.6-0.4,1.3-0.8,1.9-1.2c0.3-0.2,0.6-0.3,0.8-0.4c0-0.1,0-0.2,0-0.2c-0.3-0.5-0.3-1,0-1.5c0.1-0.2,0.2-0.5,0.3-0.6
				c-0.1-0.3-0.3-0.4-0.5-0.4c-0.3,0-0.5-0.1-0.8-0.1c-0.4,0-0.7-0.1-1-0.4c-0.3-0.5-0.1-1,0.2-1.4c0.1-0.2,0.2-0.4,0.5-0.3
				c0.1,0.5-0.5,0.9-0.3,1.4c0.2,0.3,0.4,0.3,0.7,0.3c0.3,0,0.6,0,0.9,0c0.3,0,0.6,0.1,0.8,0.4c0.2,0.3,0.1,0.6,0,0.9
				c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.1-0.1,0.3-0.1,0.5c0.6,0.2,1.1,0.2,1.7,0.1c0.1-0.3,0.2-0.6,0.2-1c0.3-0.4,0.6-0.8,0.9-1.2
				c-0.2-0.1-0.3-0.3-0.4-0.4c-0.3-0.1-0.7-0.2-1-0.4c-0.3-0.1-0.6-0.3-0.8-0.5c-0.4-0.3-0.4-0.8,0-1.1c0.2-0.2,0.5-0.4,0.9-0.3
				c-0.2,0.2-0.3,0.3-0.4,0.4c-0.1,0.1-0.1,0.2-0.2,0.4c0.2,0.1,0.3,0.3,0.5,0.4c0.3,0.1,0.7,0.2,1,0.3c0.3,0.1,0.5,0.2,0.8,0.4
				c0.4,0.3,0.5,0.7,0.2,1.1c-0.1,0.2-0.3,0.5-0.5,0.7c-0.2,0.2-0.5,0.4-0.4,0.8c0.3,0.4,0.7,0.3,1.1,0.2c0.4-1,0.4-1,1.5-1.3
				c0.4-0.1,0.7-0.2,0.8-0.7c0-0.3-0.2-0.6-0.4-0.8c-0.2-0.2-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5,0-0.7c0.2-0.3,0.4-0.4,0.7-0.3
				c0.2,0.2,0,0.4,0.1,0.8c0.1,0.1,0.2,0.3,0.4,0.5c0.8,1.1,0.2,2.1-0.8,2.4c-0.3,0.1-0.6,0.2-0.9,0.3c0,0.2,0,0.3,0,0.4
				c0.2,0,0.3,0.1,0.5,0.1c2.2,0.2,3.8,1.6,4.4,3.7c0.2,0.6,0.1,1.3,0.1,2c0,0.3,0,0.6,0,0.9c0.2,0.1,0.4,0.2,0.6,0.3
				c0.5,0.2,0.8,0.6,0.9,1.2c0.2,1.2-0.2,2-1.2,2.5c-0.4,0.2-0.8,0.3-1.2,0.4c-0.1,0.5-0.2,1.1-0.3,1.7c0.2,0.1,0.3,0.1,0.4,0.2
				c0.5,0,0.9,0.1,1.4,0.1c1.2-0.1,2.4,0.2,3.5,0.7c0.6,0.3,1.3,0.6,1.9,0.9c1.2,0.7,2.4,1.4,3.6,2.1c0.3,0.2,0.6,0.4,0.9,0.7
				c0.3,0.3,0.5,0.6,0.6,1c0.2,0.9-0.2,1.5-1,1.9c-0.3,0.1-0.5,0.2-0.8,0.3c-0.4,0.1-0.9,0.2-1.4,0.3c-0.2,0.2-0.3,0.4-0.4,0.6
				c-0.4,0.5-1.1,0.5-1.5,0c-0.2-0.2-0.3-0.5-0.4-0.8c-0.5-1.2-0.6-1.3-1.9-1.5c-0.8-0.1-1.7-0.1-2.5-0.1c-0.1,0-0.2,0-0.4,0.1
				c0.1,0.2,0.1,0.3,0.2,0.4c1.1,1.2,1.8,2.7,2.3,4.2c0,0.1,0.1,0.2,0.1,0.3c0.9,1.7,1,3.5,0.7,5.4c-0.2,1.2-0.5,2.3-1.1,3.3
				c-0.3,0.5-0.6,1-1,1.4C406.8,349.2,405.2,350.1,403.1,350.1z M404.6,348.8c0,0,0.1,0.1,0.1,0.1c0.3-0.1,0.5-0.2,0.8-0.4
				c1.2-0.7,2.1-1.7,2.7-2.9c1.1-2.1,1.3-4.4,0.7-6.7c-0.1-0.2-0.3-0.3-0.4-0.4c-0.1-0.3-0.2-0.7-0.2-1c-0.4-1.4-1.2-2.7-2-3.9
				c-0.3-0.4-0.6-0.8-1-1c0,0-0.1-0.1-0.1-0.2c0.1-0.3,0.3-0.5,0.6-0.6c0.3-0.1,0.6-0.1,0.8-0.4c0.4-1.1,0.8-2.7,0.8-3.8
				c-0.2-0.1-0.4-0.2-0.6-0.2c-0.9-0.1-1.7-0.3-2.6-0.3c-0.5,0.6-0.9,1.1-1.4,1.7c0,0.4,0,0.8,0.1,1.4c-0.5,1.4-1.6,2.3-3.2,2.6
				c-0.4,0.1-0.8,0.1-1.2,0.1c-1,0-2-0.1-2.9-0.6c-1-0.5-1.6-1.3-1.6-2.5c0-0.4,0-0.7,0-1.1c0-0.2,0-0.3,0-0.5
				c-0.1-0.1-0.2-0.2-0.2-0.2c-0.7-0.1-1.5-0.2-2.2-0.3c-0.2,0-0.3,0-0.6,0.1c-0.1,0.6-0.1,1.2-0.1,1.8c0,1.4-0.1,2.7,0.4,4
				c0,0.1,0,0.1,0,0.2c0,0.1-0.1,0.2-0.1,0.3c-0.2,1.4-0.7,2.6-1.4,3.8c-0.5,0.9-0.8,1.9-0.8,3c-0.1,1.9,0.3,3.7,1,5.4
				c0.3,0.6,0.6,1.2,1.1,1.6c0.1,0.1,0.2,0.2,0.3,0c-0.1-0.3-0.2-0.7-0.3-1c-0.3-1-0.5-2-0.5-3c-0.1-1,0.1-2.1,0.6-3
				c0.4-0.6,0.9-1,1.6-1.1c0.4-0.1,0.8-0.2,1.2-0.3c0.2-0.1,0.5-0.2,0.7-0.3c0.9-0.4,1.5-0.2,2,0.6c0.2,0.4,0.4,0.8,0.5,1.2
				c0.4,1.3,0.6,2.6,0.7,3.9c0.1,1.1,0,2.2-0.5,3.3c-0.1,0.1,0,0.3-0.1,0.4c0.7,0.2,0.7,0.2,1.3,0c0.2-0.3,0.1-0.6,0.1-0.9
				c0-0.9,0-1.8,0-2.7c0-1.1,0.3-2.2,0.6-3.3c0.2-0.6,0.4-1.1,0.8-1.5c0.6-0.7,1.3-1.1,2.3-0.9c0.5,0.1,1,0.2,1.5,0.1
				c0.1,0,0.3-0.1,0.4-0.1c0.9-0.1,1.4,0.1,1.7,1c0.1,0.2,0.2,0.4,0.2,0.7c0.6,2.7,0.1,5.1-1.5,7.3
				C404.8,348.5,404.7,348.7,404.6,348.8z M400.1,314.6C400.1,314.5,400.1,314.5,400.1,314.6c-0.7,0-1.3,0-2,0.1c-1.6,0.3-3,1-4.4,2
				c-0.5,0.4-0.9,0.9-1.1,1.5c-0.3,0.9-0.4,1.8-0.2,2.7c0.2,0.7,0.3,1.4,0.4,2c0,0.2,0.1,0.4,0.2,0.6c0.4,0.7,0.6,1.4,0.7,2.1
				c0.3,1.4,1.1,2.4,2.2,3.2c0.9,0.6,1.8,0.8,2.9,0.6c0.9-0.2,1.8-0.5,2.8-0.7c0.3-0.1,0.5-0.2,0.8-0.4c1.4-0.8,2-2.1,2-3.7
				c0-1.1,0.2-2.2,0.6-3.2c0.1-0.1,0.1-0.3,0.1-0.4c0.5-1.9,0.2-3.7-1.2-5.2c-0.6-0.7-1.4-1.1-2.3-1.1
				C401.1,314.5,400.6,314.6,400.1,314.6z M400.9,349.8c0.1,0,0.3,0,0.4-0.1c0.7-0.2,1.4-0.6,1.9-1.1c2.1-2,2.7-4.5,2.2-7.4
				c-0.1-0.3-0.2-0.6-0.4-0.7c-0.1,0-0.2-0.1-0.2-0.1c-0.9,0.1-1.7,0.2-2.6-0.1c-0.4-0.1-0.8,0.1-1,0.3c-0.2,0.2-0.4,0.5-0.5,0.8
				c-0.2,0.5-0.4,1.1-0.5,1.6c-0.2,1.1-0.3,2.2-0.5,3.3c-0.1,0.7-0.1,1.3,0,2C400.1,348.9,400.3,349.5,400.9,349.8z M395.5,340.1
				c-0.2,0.1-0.4,0.1-0.5,0.2c-0.5,0.3-1.1,0.5-1.6,0.5c-0.9,0.1-1.3,0.6-1.6,1.4c-0.2,0.8-0.3,1.6-0.2,2.4c0.2,1.4,0.6,2.8,1,4.2
				c0.4,0.4,0.9,0.5,1.4,0.7c0.7,0.2,1.4,0,1.9-0.5c0.4-0.4,0.7-0.8,0.9-1.4c0.2-0.5,0.2-1,0.3-1.5c0-1-0.2-2-0.3-3
				c-0.1-0.6-0.3-1.2-0.4-1.8C396.2,340.9,396,340.4,395.5,340.1z M413.2,333.9c0.5-0.3,0.9-0.3,1.3-0.4c0.4,0,0.7-0.1,1.1-0.3
				c0.3-0.1,0.5-0.3,0.6-0.7c-0.1-0.6-0.5-0.9-1-1.2c-1.5-0.9-2.9-1.8-4.4-2.6c-0.7-0.4-1.4-0.6-2.1-0.9c-0.1-0.1-0.3,0-0.4,0
				c-0.1,0.7-0.1,1.4-0.2,2c-0.1,0.6-0.3,1.3-0.4,1.9c0.3,0.2,0.6,0.2,0.9,0.2c0.5,0,1,0,1.5,0.1c1.1,0.1,1.8,0.6,2.2,1.7
				c0.1,0.2,0.1,0.4,0.2,0.6c0.1,0.1,0.2,0.2,0.3,0.3C412.8,334.3,413,334.1,413.2,333.9z M390.4,327.7c-0.8,0-1.5,0.3-2.2,0.5
				c-0.7,0.2-1.4,0.4-2.1,0.7c-0.7,0.2-1.4,0.3-2,0.8c0.2,0.7,0.3,1.4,0.5,2c0.2,0.6,0.5,1,1.1,1.3c0.2,0,0.5,0,0.8,0
				c0.3,0,0.6,0,0.9,0c0.4,0.2,0.9,0.4,1.4,0.5c0.5,0.1,1,0.3,1.5,0.1c-0.1-0.6-0.1-1.3-0.2-1.9c0-0.6,0-1.3,0-1.9
				C390.1,329.1,390.2,328.5,390.4,327.7z M384.4,333.3c-0.7-0.9-0.9-2-1-3.1c0-0.1-0.1-0.2-0.1-0.4c-0.2,0-0.4,0.1-0.5,0.1
				c-1.1,0.3-2.2,0.7-3.3,1c-0.3,0.1-0.5,0.2-0.8,0.3c-0.3,0.2-0.6,0.4-0.7,0.9c0.1,0.8,0.7,1.4,1.4,1.8c0.3,0.2,0.7,0.3,1.1,0.2
				c0.2,0,0.3-0.1,0.5-0.1c0.3-0.1,0.6,0,0.9,0.4c0.1,0.1,0,0.5,0.3,0.7c0.3,0,0.5-0.1,0.7-0.3C383.3,334.4,383.9,333.9,384.4,333.3
				z M402.2,329.4c-0.2,0-0.5,0.1-0.7,0.1c-0.4,0.1-0.8,0.2-1.2,0.4c-0.9,0.3-1.9,0.4-2.9,0.3c-0.7-0.1-1.3-0.3-1.9-0.6
				c-0.2-0.1-0.4-0.3-0.6-0.4c-0.1-0.1-0.2-0.1-0.4-0.2c-0.2,0.5-0.1,0.9-0.1,1.3c0.3,0.3,0.5,0.5,0.8,0.7c0.4,0.4,0.8,0.7,1.4,0.9
				c0.7,0.3,1.5,0.3,2.3,0.3c0.9,0,1.7-0.4,2.4-1c0.2-0.2,0.5-0.5,0.8-0.7C402.3,330.2,402.5,329.8,402.2,329.4z M392.5,324.1
				c0-0.4-0.3-0.8-0.4-1.2c-0.1-0.4-0.2-0.9-0.3-1.3c-0.2-0.3-0.5-0.6-0.8-0.6c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.1,0.1-0.1,0.1
				c-0.2,1.1,0.3,2.1,0.9,2.9C391.8,324.6,392.1,324.5,392.5,324.1z M405,323.9c0.5,0.2,0.8,0,1.2-0.2c0.3-0.2,0.5-0.5,0.6-0.8
				c0.1-0.4,0.1-0.9-0.2-1.2c-0.2-0.2-0.4-0.4-0.7-0.4C405.3,321.8,404.9,322.9,405,323.9z"/>
                                <path className="st72" d="M404.6,348.8c0.1-0.2,0.2-0.3,0.3-0.5c1.6-2.2,2.1-4.6,1.5-7.3c0-0.2-0.1-0.4-0.2-0.7
				c-0.4-0.8-0.8-1.1-1.7-1c-0.1,0-0.3,0.1-0.4,0.1c-0.5,0.1-1,0-1.5-0.1c-0.9-0.2-1.7,0.1-2.3,0.9c-0.4,0.5-0.6,1-0.8,1.5
				c-0.3,1.1-0.5,2.2-0.6,3.3c0,0.9,0,1.8,0,2.7c0,0.3,0.1,0.6-0.1,0.9c-0.6,0.2-0.6,0.2-1.3,0c0-0.1,0-0.3,0.1-0.4
				c0.5-1.1,0.6-2.2,0.5-3.3c-0.1-1.3-0.3-2.6-0.7-3.9c-0.1-0.4-0.3-0.8-0.5-1.2c-0.5-0.8-1.1-1-2-0.6c-0.2,0.1-0.5,0.3-0.7,0.3
				c-0.4,0.1-0.8,0.2-1.2,0.3c-0.7,0.1-1.2,0.5-1.6,1.1c-0.5,0.9-0.7,2-0.6,3c0,1,0.2,2,0.5,3c0.1,0.4,0.2,0.7,0.3,1
				c-0.1,0.2-0.2,0.1-0.3,0c-0.5-0.5-0.8-1-1.1-1.6c-0.8-1.7-1.1-3.5-1-5.4c0.1-1,0.3-2.1,0.8-3c0.6-1.2,1.2-2.4,1.4-3.8
				c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2,0-0.2c-0.5-1.3-0.4-2.7-0.4-4c0-0.6,0.1-1.2,0.1-1.8c0.2,0,0.4-0.1,0.6-0.1
				c0.7,0.1,1.5,0.2,2.2,0.3c0.1,0,0.1,0.1,0.2,0.2c0,0.1,0,0.3,0,0.5c0,0.4,0,0.7,0,1.1c0,1.2,0.6,1.9,1.6,2.5
				c0.9,0.5,1.9,0.6,2.9,0.6c0.4,0,0.8,0,1.2-0.1c1.6-0.3,2.7-1.2,3.2-2.6c0-0.6-0.1-1-0.1-1.4c0.5-0.6,0.9-1.1,1.4-1.7
				c0.9,0,1.7,0.2,2.6,0.3c0.2,0,0.4,0.2,0.6,0.2c-0.1,1.1-0.4,2.8-0.8,3.8c-0.2,0.3-0.6,0.3-0.8,0.4c-0.3,0.1-0.6,0.3-0.6,0.6
				c0.1,0.1,0.1,0.2,0.1,0.2c0.5,0.2,0.8,0.6,1,1c0.9,1.2,1.6,2.5,2,3.9c0.1,0.3,0.2,0.6,0.2,1c0.1,0.1,0.2,0.3,0.4,0.4
				c0.6,2.3,0.4,4.6-0.7,6.7c-0.6,1.2-1.6,2.2-2.7,2.9c-0.2,0.1-0.5,0.2-0.8,0.4C404.7,348.9,404.6,348.9,404.6,348.8z"/>
                                <path className="st15" d="M400.1,314.6c0.5,0,1,0,1.6,0c0.9,0.1,1.7,0.5,2.3,1.1c1.4,1.5,1.7,3.3,1.2,5.2c0,0.1-0.1,0.3-0.1,0.4
				c-0.4,1-0.6,2.1-0.6,3.2c0,1.6-0.7,2.8-2,3.7c-0.2,0.2-0.5,0.3-0.8,0.4c-0.9,0.3-1.8,0.5-2.8,0.7c-1,0.2-2,0-2.9-0.6
				c-1.1-0.8-1.9-1.8-2.2-3.2c-0.1-0.7-0.4-1.5-0.7-2.1c-0.1-0.2-0.1-0.4-0.2-0.6c-0.1-0.7-0.2-1.4-0.4-2c-0.2-0.9-0.1-1.8,0.2-2.7
				c0.2-0.6,0.6-1.1,1.1-1.5c1.3-0.9,2.7-1.7,4.4-2C398.7,314.5,399.4,314.5,400.1,314.6C400.1,314.5,400.1,314.5,400.1,314.6z
				 M401.5,325.3c-0.1,0-0.2-0.1-0.3-0.1c-0.2,0.1-0.5,0.2-0.7,0.3c-0.8,0.4-1.6,0.5-2.5,0.2c-0.3-0.1-0.7-0.2-1-0.2
				c-0.1,0-0.3,0-0.3,0.2c0.6,0.8,1.8,1.4,2.8,1.2c0.6-0.1,1.2-0.3,1.6-0.7C401.2,325.9,401.4,325.7,401.5,325.3z M397.7,323.3
				c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0.1,0.2c0.4,0.5,1,0.6,1.5,0.5c0.6-0.1,1-0.4,1.2-1c0-0.1,0-0.2,0-0.3c-0.4-0.1-0.7,0.1-1,0.1
				c-0.3,0-0.6,0.1-0.9,0.1C398.4,323.2,398,323.1,397.7,323.3z M401.5,320.1c-0.4-0.2-0.7-0.2-1,0c-0.2,0.2-0.3,0.5-0.2,0.8
				c0.1,0.2,0.5,0.4,0.7,0.2C401.4,320.8,401.7,320.6,401.5,320.1z M396.3,320.2c-0.3,0.1-0.5,0.3-0.4,0.5c0,0.2,0.1,0.4,0.2,0.5
				c0.2,0.2,0.6,0.2,0.8,0c0.2-0.2,0.2-0.6,0-0.8C396.8,320.3,396.6,320.2,396.3,320.2z"/>
                                <path className="st15" d="M400.9,349.8c-0.6-0.3-0.8-0.9-1.3-1.3c-0.1-0.7-0.1-1.4,0-2c0.1-1.1,0.3-2.2,0.5-3.3
				c0.1-0.6,0.3-1.1,0.5-1.6c0.1-0.3,0.3-0.5,0.5-0.8c0.3-0.3,0.6-0.4,1-0.3c0.9,0.2,1.7,0.2,2.6,0.1c0.1,0,0.2,0,0.2,0.1
				c0.3,0.2,0.4,0.5,0.4,0.7c0.6,2.8-0.1,5.3-2.2,7.4c-0.5,0.5-1.2,0.9-1.9,1.1C401.2,349.8,401,349.8,400.9,349.8z"/>
                                <path className="st15" d="M395.5,340.1c0.5,0.3,0.7,0.7,0.8,1.1c0.2,0.6,0.3,1.2,0.4,1.8c0.2,1,0.4,2,0.3,3c0,0.5-0.1,1-0.3,1.5
				c-0.2,0.5-0.5,1-0.9,1.4c-0.6,0.5-1.2,0.7-1.9,0.5c-0.5-0.1-1-0.3-1.4-0.7c-0.5-1.3-0.8-2.7-1-4.2c-0.1-0.8-0.1-1.6,0.2-2.4
				c0.2-0.8,0.7-1.3,1.6-1.4c0.6,0,1.1-0.2,1.6-0.5C395.1,340.2,395.3,340.2,395.5,340.1z"/>
                                <path className="st15" d="M413.2,333.9c-0.2,0.2-0.4,0.4-0.6,0.7c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.2-0.1-0.4-0.2-0.6
				c-0.3-1.1-1.1-1.6-2.2-1.7c-0.5-0.1-1-0.1-1.5-0.1c-0.3,0-0.6,0-0.9-0.2c0.1-0.6,0.3-1.2,0.4-1.9c0.1-0.6,0.1-1.3,0.2-2
				c0.1,0,0.3,0,0.4,0c0.7,0.3,1.5,0.5,2.1,0.9c1.5,0.8,3,1.7,4.4,2.6c0.5,0.3,0.9,0.6,1,1.2c-0.1,0.4-0.3,0.6-0.6,0.7
				c-0.4,0.1-0.7,0.2-1.1,0.3C414.1,333.5,413.7,333.6,413.2,333.9z"/>
                                <path className="st15" d="M390.4,327.7c-0.1,0.8-0.2,1.4-0.3,2c0,0.6,0,1.3,0,1.9c0,0.6,0.1,1.2,0.2,1.9c-0.5,0.2-1.1,0-1.5-0.1
				c-0.5-0.1-0.9-0.3-1.4-0.5c-0.3,0-0.6,0-0.9,0c-0.3,0-0.6,0-0.8,0c-0.7-0.3-1-0.8-1.1-1.3c-0.2-0.7-0.3-1.3-0.5-2
				c0.6-0.4,1.4-0.5,2-0.8c0.7-0.2,1.4-0.5,2.1-0.7C388.8,328,389.5,327.8,390.4,327.7z"/>
                                <path className="st15" d="M384.4,333.3c-0.6,0.6-1.1,1.1-1.6,1.5c-0.2,0.2-0.4,0.3-0.7,0.3c-0.3-0.2-0.2-0.5-0.3-0.7
				c-0.3-0.4-0.5-0.5-0.9-0.4c-0.2,0-0.3,0.1-0.5,0.1c-0.4,0.1-0.7,0-1.1-0.2c-0.7-0.4-1.3-1-1.4-1.8c0-0.5,0.3-0.7,0.7-0.9
				c0.3-0.1,0.5-0.2,0.8-0.3c1.1-0.3,2.2-0.7,3.3-1c0.2,0,0.3-0.1,0.5-0.1c0.1,0.2,0.1,0.3,0.1,0.4
				C383.5,331.3,383.8,332.4,384.4,333.3z"/>
                                <path className="st15" d="M402.2,329.4c0.3,0.4,0.1,0.8,0,1.1c-0.3,0.3-0.5,0.5-0.8,0.7c-0.7,0.7-1.5,1-2.4,1c-0.8,0-1.6,0-2.3-0.3
				c-0.5-0.2-1-0.4-1.4-0.9c-0.2-0.3-0.5-0.5-0.8-0.7c-0.1-0.4-0.1-0.8,0.1-1.3c0.2,0.1,0.3,0.1,0.4,0.2c0.2,0.1,0.4,0.3,0.6,0.4
				c0.6,0.3,1.2,0.6,1.9,0.6c1,0.1,2,0,2.9-0.3c0.4-0.1,0.8-0.3,1.2-0.4C401.7,329.4,402,329.4,402.2,329.4z"/>
                                <path className="st15" d="M392.5,324.1c-0.4,0.4-0.7,0.4-1,0.1c-0.7-0.9-1.1-1.8-0.9-2.9c0,0,0,0,0.1-0.1c0.1,0,0.2-0.1,0.3-0.1
				c0.4,0,0.6,0.3,0.8,0.6c0.1,0.4,0.2,0.8,0.3,1.3C392.2,323.4,392.5,323.7,392.5,324.1z"/>
                                <path className="st15" d="M405,323.9c-0.1-1,0.3-2,0.8-2.7c0.3,0,0.5,0.1,0.7,0.4c0.3,0.4,0.2,0.8,0.2,1.2c0,0.4-0.3,0.6-0.6,0.8
				C405.8,323.9,405.5,324.1,405,323.9z"/>
                                <path d="M401.5,325.3c0,0.4-0.3,0.6-0.5,0.8c-0.5,0.4-1,0.6-1.6,0.7c-1,0.2-2.2-0.4-2.8-1.2c0-0.2,0.2-0.2,0.3-0.2
				c0.3,0.1,0.7,0.1,1,0.2c0.9,0.3,1.7,0.2,2.5-0.2c0.2-0.1,0.5-0.2,0.7-0.3C401.2,325.2,401.3,325.3,401.5,325.3z"/>
                                <path d="M397.7,323.3c0.3-0.2,0.7-0.1,1-0.2c0.3,0,0.6,0,0.9-0.1c0.3,0,0.6-0.3,1-0.1c0,0.1,0.1,0.2,0,0.3
				c-0.2,0.6-0.6,0.9-1.2,1c-0.6,0.1-1.1,0-1.5-0.5c0,0-0.1-0.1-0.1-0.2C397.7,323.5,397.7,323.4,397.7,323.3z"/>
                                <path d="M401.5,320.1c0.1,0.5-0.1,0.7-0.4,0.9c-0.3,0.2-0.6,0.1-0.7-0.2c-0.1-0.2-0.1-0.6,0.2-0.8
				C400.8,319.8,401.1,319.8,401.5,320.1z"/>
                                <path d="M396.3,320.2c0.2,0,0.4,0.1,0.6,0.3c0.2,0.2,0.2,0.6,0,0.8c-0.2,0.2-0.6,0.2-0.8,0c-0.1-0.1-0.2-0.3-0.2-0.5
				C395.9,320.5,396.1,320.3,396.3,320.2z"/>
		</g>
                            <g>
			<path d="M388.7,510c0,0.1,0.1,0.3,0.1,0.4c0.3,0.6,0.4,1.2,0.4,1.8c0,0.5,0,1,0,1.5c0,0.4-0.1,0.7-0.1,1.1c0,0.2-0.1,0.4-0.1,0.6
				c0,1-0.3,1.9-0.8,2.8c-0.3,0.6-0.5,1.1-0.8,1.7c-0.2,0.5-0.3,1-0.2,1.5c0.1,0.4,0.1,0.7,0.2,1.1c0.1,0.7,0.1,1.4-0.1,2
				c-0.2,0.7-0.5,1.2-1.1,1.6c-1.1,0.7-2.3,1-3.6,0.9c-0.6-0.1-1-0.4-1.1-1c-0.1-0.6-0.2-1.2-0.2-1.9c0-1,0-2,0-3
				c0-0.3,0-0.6-0.1-0.9c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0.1-0.2,0.1-0.2,0.2c0,0.5,0,1.1,0,1.6c0,0.7,0.1,1.3,0.1,2
				c0,1.2-0.6,2-1.7,2.5c-0.6,0.3-1.3,0.4-2,0.4c-0.8,0-1.6,0.1-2.4-0.3c-0.3-0.1-0.7-0.3-1-0.7c0-0.4-0.1-0.9-0.1-1.5
				c-0.2,0.1-0.4,0.1-0.5,0.2c-1.1,0.7-2.3,0.9-3.5,0.7c-0.6-0.1-1.2-0.3-1.7-0.7c-0.6-0.4-0.9-1-0.9-1.8c0-0.4,0.1-0.8,0.4-1.1
				c0.1-0.1,0.3-0.3,0.4-0.4c1.3-1,2.6-2,3.8-3c0.1-0.1,0.2-0.2,0.3-0.3c-0.1-0.1-0.2-0.2-0.3-0.2c-0.4,0-0.7,0-1.1,0.1
				c-1.1,0.2-2.1,0.3-3.2,0.1c-0.4-0.1-0.8-0.2-1-0.6c0-0.2,0-0.5,0.1-0.8c-0.3,0-0.5,0-0.7,0c-1.5,0-3.1-0.1-4.5-0.5
				c-1.1-0.3-2.2-0.8-3.1-1.5c-0.2-0.2-0.4-0.4-0.6-0.6c-0.3-0.5-0.3-1,0-1.5c0.4-0.7,0.9-1.3,1.4-1.9c0.1,0,0.3,0,0.4,0
				c0.5,0.5,1,1,1.5,1.4c0.3,0.3,0.7,0.5,1.1,0.6c1.2,0.3,2.5-0.3,3-1.4c0.3-0.7,0.5-1.4,0.5-2.1c0-0.3,0.1-0.7,0.2-1
				c0.3-0.9,0.8-1.8,1.4-2.5c0.5-0.6,1.1-1.1,2-1.2c1.4-0.6,2.9-0.5,4.4-0.5c0.5,0,1,0.1,1.4,0.3c1.4,0.6,2.5,1.4,3.3,2.7
				c0.2,0.4,0.5,0.8,0.7,1.2c0.2,0,0.4-0.1,0.5-0.1c0.3-0.1,0.6-0.2,1-0.3c2.3-0.7,4.4-0.3,6.5,0.8c0.3,0.1,0.5,0.3,0.8,0.5
				c0.2,0.2,0.5,0.3,0.8,0.3c1.2-0.7,2-1.8,2.3-3.2c0-0.2,0.1-0.4,0.1-0.7c0.2-0.8,0.2-0.9-0.4-1.7c-0.1,0-0.2,0-0.4-0.1
				c-0.1,0-0.3,0-0.4-0.1c-0.7-0.5-1.1-0.9-1.6-1.7c0-0.1,0.1-0.2,0.1-0.3c0.6-0.1,0.7,0.5,1.2,0.6c0.3-0.4,0-0.9,0.3-1.3
				c0.1,0,0.2,0,0.4-0.1c0,0.5,0,1,0.4,1.4c0.6-0.3,0.6-1.1,1.3-1.3c0.2,0.4,0,0.7-0.2,1c-0.2,0.3-0.3,0.6-0.5,0.9
				c0.1,0.2,0.2,0.3,0.2,0.5c0.5,0.7,0.5,1.4,0.4,2.2c-0.1,0.4-0.2,0.8-0.3,1.2c-0.3,1-0.9,1.9-1.7,2.6
				C389.3,509.3,388.9,509.5,388.7,510z M358,513c0,0.4,0.2,0.7,0.5,0.8c0.9,0.7,1.9,1.2,3,1.5c0.7,0.2,1.4,0.3,2.2,0.4
				c0.5,0.1,1.1,0.1,1.6,0.1c0.5,0,1-0.2,1.4-0.5c0.2-0.2,0.5-0.3,0.7-0.5c0.2-0.1,0.4-0.2,0.6,0c0.1,0.3,0.1,0.5-0.1,0.7
				c-0.2,0.2-0.3,0.5-0.5,0.7c-0.1,0.2-0.4,0.4-0.3,0.8c0,0,0.1,0.1,0.1,0.1c0.1,0,0.2,0.1,0.3,0.1c0.9,0.1,1.7,0.1,2.6-0.2
				c0.9-0.3,1.8-0.2,2.6,0.5c0.2,0.2,0.5,0.4,0.8,0.5c0.3,0.2,0.6,0.2,1,0.4c0,0.3,0.1,0.5,0.1,0.8c0,0.6,0,1.1,0,1.7
				c0,0.9,0,1.8-0.2,2.7c-0.1,0.5-0.1,1.1,0,1.7c0.3,0.3,0.7,0.4,1.1,0.4c-0.1-0.3-0.1-0.5-0.2-0.6c0.2-0.3,0.4-0.4,0.6-0.3
				c0.5,0.3,0.2,0.8,0.4,1.1c0.4,0.1,0.9,0.2,1.2-0.1c0-0.3-0.1-0.5-0.1-0.7c0-0.1,0.1-0.2,0.1-0.3c0.2,0,0.4,0,0.6,0
				c0.2,0.2,0.3,0.5,0.4,0.7c0.6-0.2,1-0.5,1.1-1.1c0.1-0.5,0.1-1,0.1-1.4c0-0.8-0.1-1.7-0.1-2.5c0-0.4,0-0.7,0.3-1.1
				c0.1,0,0.2-0.1,0.3-0.1c0.9-0.4,1.6,0.1,1.6,0.9c0,0.5,0.1,0.9,0.1,1.4c0,0.8,0,1.5,0,2.3c0,0.7,0,1.4,0.1,2.1
				c0.3,0.3,0.5,0.4,0.8,0.2c0.1-0.3,0.2-0.7,0.2-1c0.6-0.1,0.6-0.1,1,0.8c0.4-0.1,0.7-0.2,1-0.5c0-0.2-0.1-0.5-0.1-0.7
				c0-0.2,0.1-0.3,0.1-0.5c0-0.1,0.1-0.1,0.2-0.1c0.2,0.1,0.4,0.2,0.7,0.4c0.1-0.3,0.2-0.5,0.2-0.7c0-0.7,0-1.4-0.1-2
				c-0.2-0.6-0.2-1.3,0.1-1.9c0.3-0.6,0.5-1.3,0.8-1.9c0.4-0.8,0.7-1.5,0.9-2.4c0.2-0.9,0.4-1.9,0.4-2.9c-0.1-1.8-0.8-3.1-2.4-4
				c-0.9-0.5-1.9-0.8-3-0.9c-0.5-0.1-1,0-1.4,0.1c-0.7,0.1-1.4,0.4-2,0.6c-0.3,0.1-0.6,0.2-0.8,0.2c-0.4-0.1-0.6-0.3-0.8-0.6
				c-0.2-0.4-0.4-0.8-0.7-1.2c-0.3-0.4-0.6-0.8-0.9-1.1c-1.7-1.5-3.7-1.8-5.9-1.4c-0.7,0.1-1.5,0.4-2.3,0.6c-0.4,0.3-0.7,0.6-1,1.1
				c-0.3,0.5-0.6,1-0.9,1.6c-0.2,0.4-0.3,0.7-0.3,1.1c0,0.6-0.2,1.2-0.4,1.8c-0.3,1-0.9,1.6-1.8,2.1c-1.2,0.7-2.4,0.6-3.5-0.2
				c-0.3-0.2-0.6-0.5-0.9-0.8c-0.1-0.1-0.3-0.2-0.5-0.3C358.7,512,358.2,512.4,358,513z M367.7,522.6c0.4,0.1,0.8,0.1,0.9,0.6
				c-0.1,0.2-0.2,0.4-0.3,0.5c0.3,0.3,0.7,0.5,1.1,0.6c0.1,0,0.3,0,0.5,0c0.1-0.4,0.1-0.9,0.7-0.9c0.1,0.3,0.1,0.6,0.2,0.9
				c0.4,0.2,0.7,0,1-0.1c0.8-0.3,1.4-0.8,2-1.4c0.2-0.8,0.3-2.9,0.1-3.7c-0.4-0.2-0.8-0.5-1.2-0.7c-0.1,0.1-0.3,0.1-0.4,0.2
				c-1.4,1.1-2.7,2.2-4.1,3.3C367.9,522.1,367.7,522.3,367.7,522.6z"/>
                                <path className="st11" d="M358,513c0.2-0.6,0.7-0.9,1-1.5c0.2,0.1,0.3,0.2,0.5,0.3c0.3,0.2,0.6,0.5,0.9,0.8c1.1,0.8,2.3,0.9,3.5,0.2
				c0.9-0.5,1.5-1.1,1.8-2.1c0.2-0.6,0.4-1.2,0.4-1.8c0-0.4,0.1-0.8,0.3-1.1c0.3-0.5,0.6-1,0.9-1.6c0.3-0.4,0.6-0.8,1-1.1
				c0.8-0.2,1.5-0.5,2.3-0.6c2.2-0.4,4.2-0.1,5.9,1.4c0.4,0.3,0.7,0.7,0.9,1.1c0.3,0.4,0.5,0.8,0.7,1.2c0.2,0.3,0.3,0.5,0.8,0.6
				c0.2-0.1,0.5-0.2,0.8-0.2c0.7-0.2,1.4-0.4,2-0.6c0.5-0.1,1-0.1,1.4-0.1c1,0.1,2.1,0.4,3,0.9c1.6,0.9,2.4,2.2,2.4,4
				c0,1-0.2,1.9-0.4,2.9c-0.2,0.8-0.5,1.6-0.9,2.4c-0.3,0.6-0.6,1.2-0.8,1.9c-0.3,0.6-0.2,1.3-0.1,1.9c0.2,0.7,0.2,1.3,0.1,2
				c0,0.2-0.1,0.4-0.2,0.7c-0.3-0.2-0.5-0.3-0.7-0.4c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.5c0,0.2,0.1,0.4,0.1,0.7
				c-0.3,0.3-0.6,0.4-1,0.5c-0.4-0.9-0.4-0.9-1-0.8c-0.1,0.3-0.2,0.6-0.2,1c-0.3,0.2-0.6,0.1-0.8-0.2c-0.1-0.7-0.1-1.4-0.1-2.1
				c0-0.8,0-1.5,0-2.3c0-0.5-0.1-0.9-0.1-1.4c-0.1-0.8-0.7-1.4-1.6-0.9c-0.1,0-0.2,0.1-0.3,0.1c-0.3,0.3-0.4,0.7-0.3,1.1
				c0,0.8,0.1,1.7,0.1,2.5c0,0.5,0,1-0.1,1.4c-0.1,0.6-0.5,0.9-1.1,1.1c-0.1-0.2-0.3-0.4-0.4-0.7c-0.2,0-0.4,0-0.6,0
				c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0.1,0.4,0.1,0.7c-0.4,0.3-0.8,0.2-1.2,0.1c-0.2-0.4,0-0.9-0.4-1.1c-0.3-0.1-0.5,0-0.6,0.3
				c0,0.2,0.1,0.4,0.2,0.6c-0.5,0.1-0.8-0.1-1.1-0.4c-0.1-0.5-0.1-1.1,0-1.7c0.2-0.9,0.2-1.8,0.2-2.7c0-0.6,0-1.1,0-1.7
				c0-0.3,0-0.5-0.1-0.8c-0.3-0.1-0.7-0.2-1-0.4c-0.3-0.1-0.5-0.3-0.8-0.5c-0.8-0.7-1.6-0.8-2.6-0.5c-0.9,0.2-1.7,0.2-2.6,0.2
				c-0.1,0-0.2-0.1-0.3-0.1c0,0-0.1-0.1-0.1-0.1c0-0.3,0.2-0.5,0.3-0.8c0.2-0.2,0.3-0.5,0.5-0.7c0.1-0.2,0.2-0.5,0.1-0.7
				c-0.2-0.2-0.4-0.2-0.6,0c-0.2,0.1-0.5,0.3-0.7,0.5c-0.4,0.3-0.9,0.5-1.4,0.5c-0.5,0-1.1-0.1-1.6-0.1c-0.7-0.1-1.5-0.2-2.2-0.4
				c-1.1-0.2-2.1-0.8-3-1.5C358.2,513.6,358,513.4,358,513z M372.9,507.5c-0.1,0.3,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.6,0.1
				c0.8,0.1,1.4,0.6,1.6,1.3c0.4,0.9,0.5,1.9,0.3,2.9c-0.1,0.4-0.2,0.8-0.5,1.2c-0.5,0.8-1,1-2,0.8c-0.5-0.5-0.5-0.5-0.9-0.6
				c0,0.2-0.1,0.3-0.1,0.5c0.1,0.8,0.6,1.2,1.4,1.2c0.7,0,1.4-0.3,1.9-0.9c0.4-0.4,0.7-0.9,0.8-1.5c0.3-0.9,0.2-1.8,0.1-2.8
				c-0.1-0.5-0.2-1-0.5-1.4C375.3,507.6,374.2,507.1,372.9,507.5z M369.1,511.3c-0.2,0.4-0.1,0.7,0.2,0.9c0.2,0.1,0.5,0.1,0.7,0
				c0.2-0.2,0.3-0.4,0.2-0.7C369.9,511.2,369.5,511.2,369.1,511.3z"/>
                                <path className="st11" d="M367.7,522.6c0-0.4,0.2-0.5,0.3-0.7c1.4-1.1,2.7-2.2,4.1-3.3c0.1-0.1,0.2-0.1,0.4-0.2
				c0.4,0.2,0.8,0.5,1.2,0.7c0.2,0.8,0.1,3-0.1,3.7c-0.6,0.6-1.1,1.1-2,1.4c-0.3,0.1-0.6,0.3-1,0.1c-0.1-0.3-0.1-0.6-0.2-0.9
				c-0.6,0-0.6,0.5-0.7,0.9c-0.2,0-0.3,0-0.5,0c-0.4-0.1-0.8-0.2-1.1-0.6c0.1-0.2,0.2-0.4,0.3-0.5
				C368.5,522.8,368.1,522.7,367.7,522.6z"/>
                                <path d="M372.9,507.5c1.2-0.3,2.4,0.1,3,1.2c0.3,0.4,0.4,0.9,0.5,1.4c0.2,0.9,0.2,1.9-0.1,2.8c-0.2,0.6-0.5,1-0.8,1.5
				c-0.5,0.6-1.1,0.8-1.9,0.9c-0.8,0-1.3-0.4-1.4-1.2c0-0.1,0.1-0.3,0.1-0.5c0.4,0.1,0.4,0.1,0.9,0.6c1,0.2,1.5,0,2-0.8
				c0.2-0.4,0.4-0.8,0.5-1.2c0.2-1,0-2-0.3-2.9c-0.3-0.7-0.9-1.2-1.6-1.3c-0.2,0-0.4-0.1-0.6-0.1C373,507.9,372.9,507.7,372.9,507.5
				z"/>
                                <path d="M369.1,511.3c0.4-0.1,0.8-0.2,1.1,0.1c0.2,0.3,0.1,0.5-0.2,0.7c-0.2,0.2-0.5,0.2-0.7,0C369.1,512,369,511.7,369.1,511.3z
				"/>
		</g>
                            <g>
			<path d="M361,328.5c0.4,0,0.6,0.2,0.9,0.5c1,1,2.1,2,3.1,3.1c0.7,0.7,1.3,1.4,1.8,2.2c0.3,0.6,0.9,0.9,1.5,1.2
				c0.3,0.1,0.6,0.2,0.9,0.4c0.4,0.2,0.8,0.5,1.1,0.9c0.6,0.8,0.5,2-0.7,2.5c-0.3,0.1-0.7,0.2-1,0.2c-0.1,0.3,0.1,0.4,0.2,0.6
				c0.2,0.4,0.4,0.9,0.4,1.4c0,1-0.8,1.6-1.8,1.5c-0.6-0.1-1.1-0.4-1.4-0.9c-0.2-0.3-0.4-0.7-0.5-1.1c-0.3-0.7-0.5-1.3-0.8-2
				c-0.4-1.1-1.2-2-2.1-2.8c-0.1-0.1-0.2-0.1-0.3-0.1c-0.2,0.2-0.1,0.4-0.1,0.6c0.4,1.6,0.9,3.2,1.5,4.8c0.7,1.7,1.3,3.4,1.8,5.1
				c0.3,1.2,0.5,2.3,0.4,3.5c0,0.2,0,0.5-0.2,0.7c-0.1,0-0.1,0.1-0.2,0.1c-0.9,0-1.8-0.1-2.8-0.1c-0.9-0.1-1.8-0.2-2.8-0.3
				c-0.9-0.1-1.8-0.1-2.8,0c-0.5,0-0.9,0-1.4,0.1c-0.5,0.4-1.1,0.7-1.7,0.8c-0.9,0.2-1.7,0.1-2.5-0.2c-0.7-0.3-1.5-0.5-2.2-0.8
				c-0.5-0.2-1-0.5-1.4-0.9c-0.1-0.1-0.3-0.3-0.5-0.4c-0.2,0-0.4,0.1-0.6,0.1c-1.2,0.4-2.4,0.7-3.6,0.9c-0.4,0.1-0.8,0.1-1.3,0.2
				c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.6,0.4c-0.6,0.3-1.1,0.2-1.6-0.3c-0.8-0.9-1.6-1.8-2.2-2.9
				c-0.3-0.6-0.5-1.2-0.6-1.8c-0.2-0.7-0.1-1.3,0.2-1.9c0.3-0.6,0.7-1,1.4-1c0.4,0,0.8,0.1,1.2,0.1c0.2,0,0.3,0.1,0.5,0.1
				c1.5,0,3,0,4.5-0.3c0-0.5,0.1-1,0.1-1.6c-0.2-0.1-0.3-0.1-0.4-0.1c-0.1,0-0.3,0-0.4,0c-0.9-0.1-1.3-0.5-1.6-1.4
				c0-0.5,0.3-1,0.7-1.3c0.5-0.4,1-0.7,1.5-1.1c0.7-0.4,1.4-0.8,2.1-1.3c0.5-0.3,0.9-0.7,1.3-1c0.5-0.5,1-1,1.4-1.5
				c1-1,1.9-2.1,2.9-3.2c-0.1-0.1-0.1-0.2-0.2-0.2c-1.4-0.6-2.6-1.4-3.5-2.6c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2,0.1-0.2,0.2-0.2,0.4
				c0,0.6,0,1.2-0.2,1.7c-0.4,1.6-1.9,2.7-3.6,2.8c-0.3,0-0.6-0.1-1,0.1c0,0.8,0,1.6-0.4,2.3c-0.4,0.8-1,1.1-1.8,1.4
				c0,0.2,0,0.4-0.1,0.6c-0.1,1.4-0.7,2.5-2,3.3c-1,0.6-2.1,1.1-3.1,1.5c-0.5,0.2-1,0.2-1.5,0.1c-0.4-0.3-0.5-0.6-0.3-1
				c0.2-0.4,0.3-0.7,0.5-1.1c0.6-1,1.4-2,2.2-2.9c0.7-0.7,1.4-1.4,2.4-1.7c-0.2-1.9,0.3-3.3,2.2-4c0-0.2,0-0.3,0-0.5
				c-0.3-1.4,0.2-2.5,1.2-3.4c0.3-0.3,0.6-0.5,1-0.7c0.1-0.1,0.2-0.2,0.3-0.3c-0.2-0.2-0.3-0.3-0.5-0.4c-1.3-0.8-2-2-2.3-3.5
				c-0.3-1.4-0.1-2.8,0.4-4.2c1-2.6,2.8-4.5,5.4-5.7c1.2-0.6,2.4-0.8,3.7-0.7c0.1,0,0.3,0,0.4,0c0,0,0.1,0,0.2-0.1
				c0,0,0.1-0.1,0.1-0.1c0.4-0.9,1.2-1.6,2-2.1c1.4-0.9,2.9-1.3,4.5-1.4c2.7-0.1,5,0.8,6.8,2.7c1.9,2,2.6,4.5,2.1,7.3
				c-0.2,1.1-0.7,2.1-1.4,3c-0.1,0.1-0.2,0.2-0.2,0.3c0.1,0.2,0.2,0.2,0.4,0.2c0.2,0,0.3,0,0.5,0c1.6-0.1,2.7,1.5,2.6,2.8
				c0,0.2,0,0.4-0.1,0.6c0.2,0,0.4,0.1,0.6,0.1c1.1,0.1,1.7,0.7,1.9,1.7c0.1,0.4,0,0.9,0,1.4c0.1,0.1,0.2,0.1,0.3,0.2
				c1,0.1,1.6,0.8,1.9,1.7c0.2,0.5,0.2,1,0.2,1.5c0,0.2,0,0.5,0,0.6c0.1,0.1,0.1,0.2,0.2,0.2c1,0.5,1.7,1.3,2,2.3
				c0.3,1.1,0.6,2.2,0.9,3.2c0.1,0.3,0.2,0.6,0.2,0.8c0.1,0.5,0.1,0.6-0.1,1.1c-0.4,0.3-0.8,0.2-1.1,0c-0.5-0.3-1-0.6-1.4-1
				c-0.9-0.8-1.5-1.7-2-2.8c-0.2-0.5-0.4-1.1-0.3-1.7c-0.2,0-0.4,0-0.5,0c-0.8,0-1.3-0.4-1.5-1.1c-0.3-0.8-0.4-1.6-0.2-2.5
				c0.1-0.3,0.2-0.6,0.1-1c-0.1,0-0.3,0-0.4,0c-1.2,0.1-2.1-0.5-2.3-1.6c-0.1-0.4-0.1-0.8-0.1-1.2c0-0.2,0-0.3,0-0.5
				c-0.6-0.2-1.3,0-1.9-0.4c-0.5-0.4-0.9-0.8-1.2-1.4c-0.1,0.1-0.2,0.2-0.3,0.2c-0.4,1.4-1.2,2.6-2.2,3.6
				C361.2,328.4,361.1,328.4,361,328.5c-0.5,0.3-0.9,0.6-1.3,1c0,0.1,0,0.2,0,0.4c0.1,1.3-0.5,2.3-1.5,3.1c-0.5,0.4-1.1,0.6-1.8,0.5
				c-1.3-0.1-2.2-1.1-2.1-2.4c0-0.1,0-0.2,0-0.4c-0.6-0.3-1.2-0.3-1.8-0.4c-0.4,0.5-0.7,1-1.1,1.4c-0.5,0.7-1.1,1.3-1.6,1.9
				c-0.1,0.2-0.2,0.3-0.3,0.5c0.1,0.9,0.5,1.8,1,2.3c0.1,0,0.2,0,0.2-0.1c0.4-0.5,0.9-0.9,1.3-1.4c0.2-0.2,0.3-0.3,0.5-0.5
				c0.1-0.1,0.3-0.1,0.4,0.1c-0.2,0.4-0.4,0.9-0.6,1.4c-0.9,2-2,3.9-3.3,5.7c-0.6,0.8-1.2,1.5-2.1,1.8c0,0-0.1,0.1-0.1,0.1
				c-0.1,0.8,0.1,1.6,1.1,4.1c0.2,0.2,0.4,0.4,0.5,0.6c0.3,0.4,0.7,0.7,1.2,0.8c0.7,0.2,1.3,0.5,2,0.7c0.1,0,0.2,0,0.4,0
				c-0.2-0.3-0.3-0.4-0.4-0.6c-0.8-1.1-1.5-2.2-1.8-3.5c-0.1-0.3-0.1-0.6-0.1-0.9c0.1-0.9,0.6-1.6,1.4-1.8c0.4-0.1,0.8-0.1,1.2,0
				c0.7,0.2,1.3,0.6,1.9,1.1c1.3,1.2,2,2.8,2,4.6c0,0.3-0.1,0.5,0.1,0.8c0.2,0,0.4,0,0.6,0c1.4-0.3,2.8-0.2,4.1,0
				c1.1,0.1,2.2,0.2,3.3,0.2c0.2,0,0.3-0.1,0.6-0.1c0-0.2,0-0.5,0-0.7c0-1.1-0.3-2.1-0.7-3c-0.6-1.7-1.2-3.3-1.8-5
				c-0.5-1.5-1-3.1-1.3-4.7c-0.1-0.5-0.2-1,0-1.4c0.2-0.3,0.5-0.3,0.8-0.2c0.2,0.1,0.5,0.3,0.8,0.5c0.7-0.7,1.5-1.3,1.4-2.5
				c-0.2-0.2-0.4-0.4-0.6-0.6c-0.4-0.4-0.7-0.8-1.1-1.2C361.8,329.9,361.3,329.3,361,328.5z M351.4,311.8c-1,0.1-2,0.3-3,0.6
				c-2.8,1-4.9,2.8-6.1,5.6c-0.5,1.2-0.7,2.4-0.5,3.6c0.4,2.5,2.4,3.5,4.4,3.6c0.2,0,0.5,0,0.7-0.2c0-0.1,0-0.2,0-0.3
				c-0.1-0.4-0.2-0.8-0.3-1.2c-0.2-1.1-0.2-2.3,0-3.4c0.3-1.7,1.2-3.1,2.3-4.4c0.5-0.6,1.2-1.1,1.9-1.5c0.2-0.1,0.5-0.2,0.6-0.3
				c0.3-0.3,0.2-0.7,0.6-0.8c0.5-0.1,0.6,0.4,1.1,0.6c0.2,0,0.5,0,0.9,0c0.9,0,1.7,0.1,2.6,0.4c1.9,0.6,3.5,1.7,4.8,3.1
				c0.5,0.5,0.9,1,1.3,1.4c0.1,0.5,0.2,0.8,0.4,1.2c0.1,0.4,0.1,0.8,0.5,1c0.6-0.4,0.8-0.6,1.2-1.1c0.9-1.4,1.2-3,0.9-4.6
				c-0.7-3.7-3.2-5.6-6.1-6.2c-2.5-0.5-4.8,0-6.8,1.6C352.3,310.9,351.9,311.4,351.4,311.8z M352.4,315c-0.4,0.9-0.5,1.8-0.8,2.7
				c-0.3,0.8-0.6,1.7-0.9,2.5c-0.4,0.8-0.8,1.7-1.2,2.5c-0.4,0.8-1,1.6-1.4,2.3c0.7,1.9,1.9,3.1,3.7,3.9c0.8,0.4,1.6,0.5,2.5,0.7
				c2.8,0.4,6.3-0.8,7.8-4.3c0.8-1.8,0.7-3.5,0-5.3c-0.1-0.3-0.3-0.6-0.6-0.8c-0.1,0-0.3-0.1-0.5-0.1c-0.9-0.2-1.9-0.3-2.8-0.5
				c-2-0.5-3.9-1.4-5.2-3.1C352.8,315.3,352.7,315.2,352.4,315z M348.8,335c-0.2,0.1-0.4,0.2-0.5,0.4c-0.5,0.6-1.2,1-1.9,1.4
				c-0.6,0.4-1.3,0.8-1.9,1.2c-0.4,0.2-0.8,0.5-1.1,0.8c-0.3,0.2-0.5,0.4-0.5,0.8c0.2,0.4,0.2,0.4,0.4,0.5c0.4,0.2,0.8,0.2,1.3,0.1
				c0.3-0.1,0.7-0.2,1,0c0.2,0.2,0.2,0.5,0.1,0.8c-0.1,0.2-0.2,0.5-0.3,0.7c-0.1,0.3-0.1,0.5,0.1,0.7c0.1,0,0.2,0.1,0.3,0
				c1.4-0.3,1.6-0.1,2.3-1.7c0.5-1.1,1-2.1,1.6-3.1c0.1-0.1,0.1-0.2,0.1-0.3c-0.2-0.4-0.4-0.8-0.6-1.2
				C349,335.9,349.1,335.4,348.8,335z M365,333.4c-0.3,0.4-0.5,0.8-0.8,1.1c-0.3,0.3-0.6,0.6-1,0.9c0.2,0.2,0.3,0.4,0.4,0.6
				c0.9,0.9,1.5,2,1.9,3.2c0.2,0.6,0.5,1.2,0.7,1.9c0.1,0.2,0.2,0.5,0.4,0.7c0.3,0.4,0.8,0.6,1.3,0.4c0.4-0.3,0.5-0.8,0.2-1.2
				c-0.3-0.4-0.6-0.9-0.8-1.3c-0.1-0.2-0.3-0.3-0.4-0.5c-0.2-0.2-0.2-0.5,0-0.7c0.3-0.1,0.6,0,0.9,0c0.5,0.1,0.9,0.1,1.4-0.1
				c0.3-0.1,0.5-0.3,0.5-0.7c0-0.1-0.1-0.2-0.1-0.3c-0.3-0.4-0.7-0.7-1.1-0.9c-1-0.4-1.7-1-2.3-1.9
				C365.9,334.1,365.5,333.7,365,333.4z M346.8,347.7c-0.1-0.5-0.3-0.9-0.4-1.4c-0.1-0.4-0.2-0.9-0.3-1.3c-0.1-0.4,0-0.9-0.2-1.3
				c-0.3-0.1-0.6-0.1-0.9-0.1c-1.1,0.2-2.2,0.2-3.4,0.3c-0.2,0-0.3,0-0.5,0.1c0,0.4,0.3,0.6,0.4,0.9c0.7,1.1,1.2,2.3,1,3.7
				c0,0.1,0,0.3,0.1,0.4C343.5,348.9,345.8,348.3,346.8,347.7z M351.5,343.7c-0.9,0.3-1.2,0.7-1.1,1.6c0.1,0.4,0.1,0.7,0.3,1.1
				c0.3,0.7,0.7,1.4,1,2.1c0.4,0.8,1.1,1.2,1.8,1.5c0.5,0.2,0.9,0.1,1.2-0.3c0.3-0.4,0.4-0.8,0.4-1.3c-0.1-1.3-0.6-2.4-1.5-3.4
				C353.2,344.3,352.5,343.8,351.5,343.7z M340.1,350c0.2-0.1,0.4-0.1,0.6-0.1c0.6-0.4,1-1,1.1-1.8c0.1-1.6-1.3-3.7-2.6-4.3
				c-0.4-0.2-0.8-0.2-1.2-0.1c-0.6,0.4-0.8,1-0.7,1.6c0.2,1.3,0.6,2.6,1.7,3.6c0.2,0.2,0.4,0.4,0.5,0.6
				C339.7,349.6,339.8,349.8,340.1,350z M346.4,326.4c-0.7-0.2-1.3-0.2-1.9,0.1c-0.7,0.4-1.3,0.9-1.8,1.5c-0.3,0.3-0.5,0.7-0.5,1.2
				c-0.1,1.1,0.6,1.9,1.7,2c2.2,0.1,3.8-2.5,2.8-4.5C346.6,326.6,346.5,326.5,346.4,326.4z M347.8,323.9c0.2-0.2,0.3-0.3,0.4-0.5
				c0.5-1.1,1.1-2.1,1.6-3.3c0.5-1.3,1-2.7,1.4-4.1c0.1-0.3,0.2-0.5,0-0.8c-0.3-0.1-0.6,0-1,0.1c-1.2,1.2-2.1,2.6-2.6,4.3
				c-0.4,1.3-0.3,2.6-0.1,3.9C347.6,323.6,347.7,323.7,347.8,323.9z M334.9,340.7c0.2,0,0.3,0,0.3,0c1.1-0.4,2.2-0.9,3.2-1.5
				c1.2-0.8,1.7-1.9,1.5-3.3c-0.2-0.3-0.4-0.5-0.7-0.7c-1.7,1.3-2.9,3-4,4.7C335.1,340.1,334.9,340.3,334.9,340.7z M353.7,314.9
				c0.3,0.5,0.7,0.8,1,1.1c1.5,1.2,3.3,1.7,5.2,2c0.1,0,0.3,0,0.5-0.1C359.5,316.2,356,314.6,353.7,314.9z M372,333.8
				c0.9,2.5,2.2,4.2,3.5,4.6c0-0.1,0.1-0.2,0.1-0.2c-0.3-1.1-0.6-2.2-1-3.3c-0.2-0.7-0.6-1.3-1.2-1.7c-0.1-0.1-0.3-0.2-0.5-0.3
				C372.6,333.2,372.3,333.5,372,333.8z M364.1,322.2c0,0.1-0.1,0.3-0.1,0.4c0.1,0.7,0.3,1.3,0.8,1.8c0.2,0.2,0.4,0.4,0.7,0.5
				c0.5,0.3,1.2,0.2,1.6-0.3c0.4-0.4,0.5-1,0.2-1.5c-0.2-0.4-0.5-0.7-0.9-0.9C365.6,322.1,364.9,322,364.1,322.2z M354.9,330.7
				c0,0.8,0.3,1.3,0.9,1.7c0.5,0.3,1.1,0.2,1.6,0c0.9-0.4,1.3-1.2,1.5-2.1c0-0.1-0.1-0.2-0.1-0.3c-0.7,0.2-1.3,0.4-1.9,0.5
				C356.3,330.6,355.6,330.7,354.9,330.7z M371,333c0.5-0.1,0.7-0.4,0.9-0.7c0.3-0.6,0.4-1.1,0.3-1.8c-0.1-0.5-0.3-1-0.7-1.3
				c-0.3-0.2-0.5-0.5-1-0.3c-0.1,0.1-0.2,0.2-0.2,0.4c-0.2,0.9-0.2,1.9,0,2.8c0,0.2,0.1,0.4,0.2,0.6C370.7,332.9,370.9,333,371,333z
				 M340.6,335c0.8-0.1,1.3-0.6,1.5-1.5c0.1-0.5,0.1-1-0.1-1.5c-0.1-0.3-0.2-0.6-0.5-0.6c-0.6,0.3-0.8,0.5-1.2,1
				c-0.3,0.4-0.5,0.9-0.5,1.5C339.9,334.3,340.1,334.7,340.6,335z M368.7,328.7c0.7-0.2,1.1-0.6,1.2-1.3c0.1-0.6-0.1-1.1-0.7-1.4
				c-1-0.1-1.1-0.1-1.7,0.3C367.3,327.5,367.7,328.3,368.7,328.7z"/>
                                <path className="st21" d="M361,328.5c0.3,0.8,0.8,1.4,1.3,2c0.4,0.4,0.8,0.8,1.1,1.2c0.2,0.2,0.4,0.4,0.6,0.6
				c0.1,1.2-0.8,1.8-1.4,2.5c-0.3-0.2-0.6-0.3-0.8-0.5c-0.3-0.2-0.6-0.1-0.8,0.2c-0.2,0.4-0.1,0.9,0,1.4c0.3,1.6,0.8,3.1,1.3,4.7
				c0.6,1.7,1.2,3.3,1.8,5c0.3,1,0.6,2,0.7,3c0,0.2,0,0.4,0,0.7c-0.2,0-0.4,0.1-0.6,0.1c-1.1,0.1-2.2,0-3.3-0.2
				c-1.4-0.2-2.8-0.2-4.1,0c-0.2,0-0.4,0-0.6,0c-0.2-0.3-0.1-0.5-0.1-0.8c-0.1-1.8-0.7-3.3-2-4.6c-0.5-0.5-1.2-0.9-1.9-1.1
				c-0.4-0.1-0.8-0.1-1.2,0c-0.8,0.2-1.3,0.9-1.4,1.8c0,0.3,0,0.6,0.1,0.9c0.3,1.3,1,2.5,1.8,3.5c0.1,0.2,0.3,0.4,0.4,0.6
				c-0.2,0-0.3,0-0.4,0c-0.7-0.2-1.3-0.5-2-0.7c-0.5-0.2-0.9-0.5-1.2-0.8c-0.2-0.2-0.4-0.4-0.5-0.6c-1-2.5-1.2-3.2-1.1-4.1
				c0,0,0.1-0.1,0.1-0.1c1-0.3,1.6-1,2.1-1.8c1.3-1.8,2.4-3.7,3.3-5.7c0.2-0.5,0.4-0.9,0.6-1.4c-0.1-0.2-0.3-0.1-0.4-0.1
				c-0.2,0.1-0.4,0.3-0.5,0.5c-0.4,0.5-0.9,0.9-1.3,1.4c-0.1,0.1-0.1,0.1-0.2,0.1c-0.5-0.4-0.9-1.3-1-2.3c0.1-0.1,0.2-0.3,0.3-0.5
				c0.5-0.6,1.1-1.3,1.6-1.9c0.4-0.4,0.7-0.9,1.1-1.4c0.6,0,1.2,0.1,1.8,0.4c0,0.1,0,0.2,0,0.4c-0.1,1.3,0.8,2.4,2.1,2.4
				c0.7,0,1.3-0.1,1.8-0.5c1-0.8,1.6-1.8,1.5-3.1c0-0.1,0-0.2,0-0.4C360.1,329.1,360.6,328.8,361,328.5
				C361.1,328.5,361,328.5,361,328.5z"/>
                                <path className="st19" d="M351.4,311.8c0.5-0.4,0.9-0.9,1.3-1.2c2-1.6,4.3-2.2,6.8-1.6c2.9,0.6,5.5,2.6,6.1,6.2
				c0.3,1.6,0,3.2-0.9,4.6c-0.4,0.6-0.6,0.8-1.2,1.1c-0.4-0.2-0.4-0.7-0.5-1c-0.2-0.4-0.2-0.8-0.4-1.2c-0.4-0.4-0.8-0.9-1.3-1.4
				c-1.4-1.4-2.9-2.5-4.8-3.1c-0.8-0.2-1.7-0.4-2.6-0.4c-0.3,0-0.7,0-0.9,0c-0.5-0.1-0.6-0.7-1.1-0.6c-0.4,0.1-0.4,0.5-0.6,0.8
				c-0.2,0.1-0.4,0.2-0.6,0.3c-0.7,0.4-1.4,0.8-1.9,1.5c-1.1,1.3-2,2.7-2.3,4.4c-0.2,1.1-0.2,2.3,0,3.4c0.1,0.4,0.2,0.8,0.3,1.2
				c0,0.1,0,0.2,0,0.3c-0.2,0.2-0.4,0.2-0.7,0.2c-2-0.1-4-1.1-4.4-3.6c-0.2-1.3,0-2.5,0.5-3.6c1.2-2.8,3.3-4.6,6.1-5.6
				C349.4,312.1,350.4,311.9,351.4,311.8z"/>
                                <path className="st15" d="M352.4,315c0.2,0.2,0.4,0.3,0.5,0.5c1.4,1.7,3.2,2.6,5.2,3.1c0.9,0.2,1.9,0.3,2.8,0.5
				c0.2,0,0.3,0.1,0.5,0.1c0.3,0.2,0.5,0.5,0.6,0.8c0.8,1.8,0.8,3.5,0,5.3c-1.5,3.5-5.1,4.8-7.8,4.3c-0.9-0.1-1.7-0.3-2.5-0.7
				c-1.7-0.8-3-2-3.7-3.9c0.5-0.8,1-1.5,1.4-2.3c0.4-0.8,0.9-1.6,1.2-2.5c0.3-0.8,0.6-1.7,0.9-2.5C351.9,316.8,352,315.9,352.4,315z
				 M351.2,323.5c-0.2,0.2-0.4,0.4-0.6,0.6c0.1,0.3,0.2,0.6,0.4,0.9c0.1,0.2,0.3,0.4,0.5,0.6c0.7,0.5,1.6,0.2,2.1-0.6
				c0.3-0.5,0.3-0.9,0-1.4c-0.3-0.4-0.8-0.6-1.3-0.4C352,323.3,351.7,323.4,351.2,323.5z M357.6,321c-0.1,0.1-0.3,0.3-0.5,0.6
				c-0.5,0.7-0.2,1.7,0.6,2c0.5,0.2,1.2,0,1.5-0.5c0.4-0.5,0.4-1,0.2-1.5c-0.2-0.5-0.7-0.7-1.4-0.6
				C357.9,320.8,357.8,320.9,357.6,321z M358.7,325.5c-0.1,0-0.3-0.1-0.4-0.1c-0.2,0.2-0.5,0.4-0.7,0.5c-0.8,0.6-1.6,1-2.6,1
				c-0.2,0-0.3,0-0.5,0.1c-0.1,0-0.2,0.1-0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.4,0.2,0.8,0.2,1.2,0.1c1-0.2,2-0.7,2.7-1.4
				C358.6,326.1,358.7,325.9,358.7,325.5z"/>
                                <path className="st15" d="M348.8,335c0.4,0.4,0.3,0.9,0.5,1.2c0.2,0.4,0.4,0.8,0.6,1.2c0,0.1,0,0.2-0.1,0.3c-0.7,1-1.2,2-1.6,3.1
				c-0.7,1.6-0.9,1.4-2.3,1.7c-0.1,0-0.2,0-0.3,0c-0.3-0.2-0.2-0.5-0.1-0.7c0.1-0.2,0.2-0.5,0.3-0.7c0.1-0.3,0.2-0.6-0.1-0.8
				c-0.3-0.2-0.7-0.1-1,0c-0.4,0.1-0.9,0.1-1.3-0.1c-0.2-0.1-0.2-0.1-0.4-0.5c0-0.4,0.2-0.6,0.5-0.8c0.4-0.3,0.7-0.6,1.1-0.8
				c0.6-0.4,1.3-0.8,1.9-1.2c0.7-0.4,1.3-0.9,1.9-1.4C348.4,335.3,348.6,335.2,348.8,335z"/>
                                <path className="st15" d="M365,333.4c0.5,0.4,0.9,0.7,1.2,1.2c0.6,0.9,1.4,1.5,2.3,1.9c0.5,0.2,0.9,0.5,1.1,0.9
				c0.1,0.1,0.1,0.2,0.1,0.3c0,0.4-0.2,0.5-0.5,0.7c-0.4,0.2-0.9,0.2-1.4,0.1c-0.3-0.1-0.6-0.2-0.9,0c-0.2,0.2-0.2,0.5,0,0.7
				c0.1,0.2,0.3,0.3,0.4,0.5c0.3,0.4,0.6,0.9,0.8,1.3c0.2,0.4,0.2,0.8-0.2,1.2c-0.5,0.2-0.9,0-1.3-0.4c-0.2-0.2-0.3-0.4-0.4-0.7
				c-0.3-0.6-0.5-1.2-0.7-1.9c-0.4-1.2-1-2.3-1.9-3.2c-0.2-0.2-0.3-0.4-0.4-0.6c0.4-0.3,0.7-0.6,1-0.9
				C364.4,334.1,364.7,333.8,365,333.4z"/>
                                <path className="st15" d="M346.8,347.7c-1,0.6-3.4,1.2-4.2,1.2c0-0.1-0.1-0.3-0.1-0.4c0.2-1.4-0.2-2.6-1-3.7
				c-0.2-0.3-0.4-0.5-0.4-0.9c0.2,0,0.3-0.1,0.5-0.1c1.1,0,2.2,0,3.4-0.3c0.3-0.1,0.6-0.1,0.9,0.1c0.3,0.4,0.1,0.9,0.2,1.3
				c0.1,0.4,0.2,0.9,0.3,1.3C346.6,346.8,346.7,347.2,346.8,347.7z"/>
                                <path className="st15" d="M351.5,343.7c1,0.1,1.7,0.6,2.3,1.3c0.9,0.9,1.3,2.1,1.5,3.4c0,0.5,0,0.9-0.4,1.3c-0.4,0.4-0.8,0.5-1.2,0.3
				c-0.7-0.3-1.5-0.7-1.8-1.5c-0.3-0.7-0.7-1.4-1-2.1c-0.2-0.3-0.2-0.7-0.3-1.1C350.3,344.4,350.6,344,351.5,343.7z"/>
                                <path className="st15" d="M340.1,350c-0.2-0.2-0.4-0.4-0.6-0.6c-0.2-0.2-0.3-0.4-0.5-0.6c-1.1-0.9-1.5-2.2-1.7-3.6
				c-0.1-0.6,0.1-1.2,0.7-1.6c0.4-0.1,0.8-0.1,1.2,0.1c1.3,0.5,2.8,2.7,2.6,4.3c-0.1,0.8-0.5,1.4-1.1,1.8
				C340.5,349.9,340.3,349.9,340.1,350z"/>
                                <path className="st18" d="M346.4,326.4c0.1,0.1,0.2,0.2,0.2,0.3c1,2-0.6,4.6-2.8,4.5c-1.1,0-1.8-0.9-1.7-2c0.1-0.4,0.2-0.8,0.5-1.2
				c0.5-0.6,1.1-1.2,1.8-1.5C345.1,326.2,345.7,326.2,346.4,326.4z"/>
                                <path className="st18" d="M347.8,323.9c-0.1-0.2-0.2-0.3-0.2-0.4c-0.2-1.3-0.3-2.6,0.1-3.9c0.5-1.7,1.4-3.1,2.6-4.3
				c0.4-0.1,0.7-0.2,1-0.1c0.2,0.3,0.1,0.6,0,0.8c-0.5,1.4-0.9,2.7-1.4,4.1c-0.5,1.1-1.1,2.2-1.6,3.3
				C348.1,323.5,348,323.7,347.8,323.9z"/>
                                <path className="st18" d="M334.9,340.7c-0.1-0.4,0.2-0.7,0.3-0.9c1.1-1.7,2.3-3.4,4-4.7c0.3,0.2,0.5,0.4,0.7,0.7
				c0.3,1.4-0.2,2.5-1.5,3.3c-1,0.6-2.1,1.1-3.2,1.5C335.2,340.7,335.1,340.7,334.9,340.7z"/>
                                <path className="st18" d="M353.7,314.9c2.2-0.3,5.8,1.4,6.7,3.1c-0.2,0-0.3,0.1-0.5,0.1c-1.9-0.3-3.6-0.8-5.2-2
				C354.4,315.7,354,315.4,353.7,314.9z"/>
                                <path className="st18" d="M372,333.8c0.3-0.3,0.6-0.6,0.9-0.9c0.2,0.1,0.3,0.2,0.5,0.3c0.6,0.5,1,1,1.2,1.7c0.3,1.1,0.6,2.2,1,3.3
				c0,0.1,0,0.2-0.1,0.2C374.3,338,373,336.3,372,333.8z"/>
                                <path className="st18" d="M364.1,322.2c0.8-0.3,1.5-0.2,2.2,0.1c0.4,0.2,0.7,0.5,0.9,0.9c0.3,0.6,0.2,1.1-0.2,1.5
				c-0.4,0.4-1.1,0.5-1.6,0.3c-0.2-0.1-0.5-0.3-0.7-0.5c-0.4-0.5-0.7-1.1-0.8-1.8C364,322.5,364.1,322.4,364.1,322.2z"/>
                                <path className="st15" d="M354.9,330.7c0.7-0.1,1.3-0.2,2-0.3c0.6-0.1,1.2-0.3,1.9-0.5c0,0.1,0.1,0.2,0.1,0.3
				c-0.2,0.9-0.6,1.7-1.5,2.1c-0.5,0.3-1.1,0.4-1.6,0C355.3,332,354.9,331.5,354.9,330.7z"/>
                                <path className="st18" d="M371,333c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.2-0.2-0.4-0.2-0.6c-0.2-0.9-0.1-1.9,0-2.8c0-0.1,0.1-0.2,0.2-0.4
				c0.4-0.1,0.7,0.1,1,0.3c0.4,0.3,0.6,0.8,0.7,1.3c0.1,0.6,0,1.2-0.3,1.8C371.7,332.7,371.5,333,371,333z"/>
                                <path className="st18" d="M340.6,335c-0.5-0.2-0.7-0.7-0.7-1.2c0-0.5,0.2-1,0.5-1.5c0.3-0.5,0.6-0.7,1.2-1c0.3,0.1,0.4,0.4,0.5,0.6
				c0.2,0.5,0.2,1,0.1,1.5C341.9,334.4,341.5,334.8,340.6,335z"/>
                                <path className="st18" d="M368.7,328.7c-1.1-0.4-1.4-1.2-1.2-2.5c0.6-0.4,0.7-0.4,1.7-0.3c0.6,0.3,0.8,0.8,0.7,1.4
				C369.8,328,369.4,328.5,368.7,328.7z"/>
                                <path d="M351.2,323.5c0.4-0.1,0.7-0.2,1-0.3c0.5-0.2,1,0,1.3,0.4c0.3,0.4,0.3,0.9,0,1.4c-0.4,0.8-1.3,1-2.1,0.6
				c-0.2-0.1-0.4-0.3-0.5-0.6c-0.2-0.3-0.2-0.6-0.4-0.9C350.9,323.9,351.1,323.7,351.2,323.5z M352.3,323.7c0,0.3,0,0.6,0,0.8
				c0.1,0.1,0.2,0.2,0.4,0.4c0.1-0.1,0.3-0.3,0.4-0.4c0-0.3,0.1-0.6-0.1-0.9c-0.1,0-0.2,0-0.4,0
				C352.6,323.6,352.5,323.6,352.3,323.7z"/>
                                <path d="M357.6,321c0.3-0.1,0.3-0.1,0.4-0.1c0.7-0.1,1.1,0.1,1.4,0.6c0.3,0.5,0.2,1.1-0.2,1.5c-0.4,0.5-1,0.7-1.5,0.5
				c-0.8-0.2-1.1-1.3-0.6-2C357.2,321.3,357.5,321.1,357.6,321z M358.2,321.4c-0.1,0.3-0.1,0.6,0.1,0.9c0.1,0,0.2,0,0.4,0
				c0.1,0,0.2-0.1,0.3-0.1c0.1-0.3,0.1-0.6,0-0.9C358.5,321.3,358.5,321.3,358.2,321.4z"/>
                                <path d="M358.7,325.5c0.1,0.4-0.1,0.6-0.2,0.7c-0.8,0.7-1.7,1.2-2.7,1.4c-0.4,0.1-0.8,0.1-1.2-0.1c-0.1,0-0.2-0.1-0.3-0.2
				c0.1-0.1,0.2-0.2,0.3-0.2c0.2,0,0.3-0.1,0.5-0.1c1,0,1.8-0.4,2.6-1c0.2-0.2,0.4-0.4,0.7-0.5C358.4,325.4,358.5,325.5,358.7,325.5
				z"/>
                                <path className="st15" d="M352.3,323.7c0.2,0,0.3-0.1,0.3-0.1c0.1,0,0.2,0,0.4,0c0.2,0.3,0.1,0.6,0.1,0.9c-0.1,0.1-0.2,0.2-0.4,0.4
				c-0.1-0.1-0.3-0.3-0.4-0.4C352.3,324.2,352.3,324,352.3,323.7z"/>
                                <path className="st15" d="M358.2,321.4c0.3-0.1,0.3-0.1,0.7,0c0.1,0.3,0.1,0.6,0,0.9c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.4,0
				C358.1,322,358.1,321.7,358.2,321.4z"/>
		</g>
                            <g>
			<path d="M441.6,462.2c-0.1,0.7-0.2,1.2-0.4,1.8c-0.5,1.7-1.6,2.9-3.1,3.8c-0.5,0.3-1.1,0.5-1.8,0.6c-1.2,0.1-2.4,0-3.5-0.5
				c-1.4-0.6-2.1-1.7-2.6-3.1c-0.1-0.2-0.2-0.5-0.2-0.7c0,0-0.1-0.1-0.1-0.1c-1.5,0-3.1,0-4.6-0.2c-1.5-0.2-3-0.4-4.4-0.9
				c-0.9,0.3-1.8,0.5-2.6,0.8c-0.2,0.4-0.3,0.8-0.4,1.2c-0.6,1.4-1.6,2.5-3.1,3.1c-0.5,0.2-1,0.3-1.6,0.3c-1.1,0.1-2.1-0.1-3.1-0.5
				c-0.5-0.2-0.9-0.5-1.3-0.8c-1.1-0.9-1.9-2-2.1-3.5c0-0.1,0-0.1-0.1-0.2c-0.1,0-0.3-0.1-0.4-0.2c-1.1-0.2-2.1-0.3-3.2-0.5
				c-0.6-0.1-1.1-0.3-1.6-0.7c-0.7-0.6-0.9-1.1-0.7-2c0.2-0.9,0.5-1.8,0.7-2.7c0-0.2,0.1-0.4,0.1-0.6c0.1-1.3,0.2-2.5,0.3-3.8
				c0.1-2.2,0.6-4.4,1.4-6.5c0.7-1.8,1.8-3.3,3.2-4.7c1-0.9,2.1-1.6,3.4-1.9c0.4-0.1,0.9-0.3,1.3-0.4c0.4-0.1,0.7-0.2,1.1-0.2
				c1.5-0.1,2.9,0.1,4.4,0.3c1.3,0.3,2.6,0.5,3.8,0.7c0.1,0,0.3,0,0.4,0c0.2-0.6,0-1.1-0.4-1.6c-0.1-0.2-0.3-0.3-0.5-0.4
				c-0.4-0.4-0.9-0.7-1.3-1.1c-0.3-0.2-0.5-0.5-0.6-0.9c0-0.6,0.4-0.9,0.9-1.1c1.2-0.6,2.4-0.7,3.7-0.1c0.6,0.3,1.2,0.7,1.8,1.1
				c1,0.7,2,1.3,3.2,1.8c1.5,0.6,3.1,1,4.7,1.1c1.5,0.1,2.9,0.1,4.4-0.1c0.7-0.1,1.5-0.2,2.2-0.3c1-0.2,2-0.1,3-0.1
				c0.9,0,1.7,0.4,2.3,1.1c0.7,0.7,1.2,1.4,1.6,2.3c0.4,0.9,0.4,1.8-0.2,2.7c-0.2,0.3-0.5,0.6-0.7,1c-1,1.4-1.5,3-1.5,4.7
				c0,0.5,0.1,1,0.3,1.5c0.5,1.4,0.9,2.8,1.2,4.2c0.1,0.6,0.2,1.2,0.2,1.8c0,1.6-0.8,2.7-2,3.7C442.5,461.5,442.1,461.8,441.6,462.2
				z M418.5,462.4c0.1,0,0.2,0.1,0.2,0c0.9-0.2,1.7-0.5,2.4-1c0.2-0.1,0.3-0.3,0.4-0.4c0.3-0.6,0.6-1.2,0.5-1.8c-0.1-1-0.2-2-0.3-3
				c-0.2-1.7-0.4-3.5-0.4-5.2c0-0.9,0-1.8,0-2.6c0-1.2-0.2-2.3-0.4-3.5c-0.2-1.1-0.3-2.1-0.3-3.2c0-0.2,0-0.4,0-0.7
				c-0.2-0.1-0.4-0.1-0.5-0.2c-0.6-0.1-1.2-0.2-1.8-0.3c-1.5-0.3-2.9-0.6-4.4-0.8c-1.2-0.2-2.3-0.2-3.4,0.2
				c-0.4,0.1-0.7,0.3-1.1,0.4c-0.8,0.3-1.5,0.7-2.1,1.3c-1.6,1.5-2.9,3.2-3.6,5.3c-0.8,2.3-1.1,4.7-1.2,7.1
				c-0.1,1.8-0.3,3.6-0.9,5.3c-0.1,0.4-0.2,0.8-0.2,1c0.1,0.5,0.3,0.7,0.5,0.8c0.2,0.1,0.5,0.3,0.8,0.4c1.1,0.3,2.3,0.5,3.4,0.5
				c0.1,0,0.2-0.1,0.4-0.1c0.1-0.3,0.1-0.5,0.2-0.8c0.3-1.7,1.2-3,2.6-4c1.5-1.1,3.1-1.4,4.9-0.8c0.8,0.3,1.5,0.7,2.2,1.1
				c0.7,0.4,1.1,1,1.5,1.7c0.3,0.8,0.5,1.5,0.5,2.4C418.4,461.9,418.3,462.2,418.5,462.4z M441.7,460.9c0.1,0,0.2,0,0.3-0.1
				c0.9-0.5,1.5-1.2,1.9-2.1c0.3-0.6,0.4-1.2,0.2-1.9c-0.3-1.6-0.7-3.3-1.3-4.8c-0.2-0.6-0.4-1.2-0.4-1.8c0-2,0.5-3.9,1.7-5.5
				c0.2-0.3,0.4-0.6,0.6-0.8c0.4-0.6,0.4-1.2,0.1-1.8c-0.4-0.7-0.8-1.4-1.4-1.9c-0.5-0.5-1.1-0.8-1.8-0.8c-1,0-1.9,0-2.9,0.1
				c-1.1,0.2-2.2,0.3-3.3,0.4c-3.1,0.2-6-0.2-8.9-1.5c-1.1-0.5-2.1-1.2-3.1-1.9c-0.5-0.4-1-0.7-1.5-0.9c-0.9-0.4-1.8-0.3-2.7,0.2
				c-0.1,0.1-0.2,0.2-0.4,0.4c0.2,0.2,0.4,0.4,0.6,0.6c0.4,0.4,0.9,0.7,1.3,1.1c0.5,0.4,0.8,0.9,0.8,1.5c0.1,0.7,0.2,1.4,0.1,2
				c-0.1,1.3,0.1,2.7,0.3,4c0.1,1,0.3,2,0.3,3c0.1,1.6,0,3.3,0.1,4.9c0.1,1.2,0.2,2.4,0.4,3.6c0.1,1.2,0.3,2.4,0.2,3.6
				c0,0.1,0.1,0.2,0.1,0.3c0.3,0,0.6-0.1,0.9-0.1c0.4,0,0.7-0.1,1.1,0c1.3,0.2,2.7,0.4,4,0.5c0.3,0,0.6,0.1,0.9-0.1
				c0.1-0.3,0.3-0.7,0.5-1.1c0.1-0.4,0.5-0.7,0.5-1.2c-1.4-0.2-2.8-0.4-4.2-0.6c-0.2-0.2-0.4-0.4-0.5-0.6c-0.7-1.1-1.2-2.4-1.4-3.7
				c-0.1-0.7-0.2-1.4-0.3-2.1c-0.1-1.1-0.3-2.2-0.4-3.4c-0.1-1.7,0-3.5,0-5.2c0-0.2,0-0.4,0.1-0.6c0.3-0.3,0.6-0.3,1-0.2
				c0.5,0.1,0.9,0.2,1.4,0.2c2.7,0.1,5.5,0.1,8.2,0.2c0.7,0,1.3,0,2,0.1c0.9,0,1.8,0.2,2.6,0.6c0.6,0.2,1,0.7,1.1,1.3
				c0.1,0.5,0.1,1,0.1,1.4c-0.1,0.8-0.2,1.6-0.3,2.4c-0.1,0.6-0.2,1.3-0.3,1.9c-0.1,0.8-0.3,1.7-0.4,2.5c-0.2,1.1-0.4,2.3-0.6,3.4
				c0,0.1,0,0.3,0,0.4c0.2,0.1,0.3,0.3,0.5,0.4c0.4,0.4,0.9,0.9,1.3,1.4c0.4,0.5,0.8,1.1,0.8,1.8
				C441.5,460.5,441.6,460.7,441.7,460.9z M438.1,456.2c0.1-0.2,0.1-0.3,0.1-0.3c0.2-1,0.3-2.1,0.5-3.1c0.1-0.8,0.2-1.6,0.3-2.4
				c0.2-1.2,0.4-2.5,0.6-3.7c0.1-0.5,0.1-1,0.1-1.5c0-0.4-0.1-0.7-0.4-1c-0.2-0.1-0.4-0.2-0.7-0.3c-1.4-0.4-2.8-0.5-4.3-0.5
				c-0.1,0-0.1,0.1-0.2,0.1c0,0.1-0.1,0.3-0.1,0.4c0,1,0,2,0.1,3.1c0.2,2.3,0.2,4.5,0.2,6.8c0,0.5,0,1.1,0.1,1.6
				c0,0.2,0.1,0.3,0.1,0.4c0.7,0,1.3,0,1.8,0C436.8,455.9,437.4,456.1,438.1,456.2z M429.8,457.6c0.2-0.3,0.1-0.6,0.1-0.8
				c0-0.6-0.1-1.1-0.1-1.7c-0.1-1.1-0.2-2.1-0.3-3.2c-0.1-1.3-0.3-2.5-0.4-3.8c-0.1-1.2-0.2-2.5-0.3-3.7c0-0.4,0-0.7-0.4-1.1
				c-0.3,0-0.7-0.1-1.1-0.1c-0.8,0-1.6,0-2.4-0.2c0,0-0.1,0-0.2,0.1c-0.1,0.3,0,0.6,0,0.9c0.1,0.9,0.1,1.9,0.2,2.8
				c0,0.3,0,0.6,0.1,0.9c0.2,1.8,0.5,3.6,0.7,5.4c0.1,1.2,0.5,2.4,1.1,3.4c0.1,0.3,0.3,0.5,0.5,0.8
				C428.1,457.3,428.9,457.6,429.8,457.6z M433.6,456.2c0-0.2,0-0.3,0-0.3c-0.1-1-0.2-2.1-0.2-3.1c-0.1-2.8-0.1-5.7-0.1-8.5
				c0-0.2,0.1-0.5-0.1-0.7c-0.4-0.1-2.6-0.2-3.5-0.2c-0.1,0-0.2,0.1-0.3,0.1c0,0.2,0,0.3,0,0.5c0.1,0.6,0.2,1.3,0.3,1.9
				c0.1,1.2,0.2,2.4,0.3,3.6c0,0.3,0.1,0.6,0.1,0.9c0.1,1.5,0.4,3,0.4,4.6c0,0.8,0.1,1.5,0.2,2.3c0,0.2,0.1,0.3,0.2,0.5
				c0.3,0,0.6,0,0.9,0C432.4,457.2,433,456.7,433.6,456.2z M407.4,463.7c0.2,0.3,0.4,0.6,0.5,0.8c0.3,0.6,0.8,1.1,1.3,1.6
				c0.5,0.5,1,0.8,1.7,0.9c0.9,0.3,1.8,0.2,2.8,0.1c0.1,0,0.2-0.1,0.3-0.1c1.2-0.4,2-1.1,2.6-2.1c0.8-1.3,1-2.8,0.7-4.3
				c-0.2-1.2-0.9-2.2-2-2.8c-0.6-0.3-1.2-0.6-1.8-0.8c-0.9-0.3-1.7-0.2-2.6,0c-1.5,0.7-2.7,1.8-3.2,3.4
				C407.3,461.6,407.3,462.7,407.4,463.7z M436,456.6c-0.9,0-1.5,0.1-2.2,0.7c-1.1,0.8-2,1.9-2.6,3.1c-0.4,0.8-0.5,1.7-0.3,2.6
				c0.1,0.2,0.1,0.4,0.1,0.6c0.1,1.3,0.6,2.4,1.7,3.1c1.2,0.8,2.5,1.1,4,0.8c1.8-0.4,3.4-2.5,3.7-3.9c0.2-0.8,0.3-1.6,0.2-2.4
				c0-0.5-0.1-1.1-0.4-1.5c-0.5-0.8-1.2-1.5-2-2.1c-0.3-0.2-0.6-0.3-0.8-0.4C437,456.9,436.6,456.8,436,456.6z M422.1,462.2
				c0.2,0.1,0.3,0.1,0.4,0.1c1.8,0.4,3.6,0.6,5.4,0.6c0.5,0,1-0.1,1.5-0.1c0.2,0,0.3-0.1,0.4-0.1c0.1-0.3,0.1-0.5-0.1-0.8
				c-0.2,0-0.4-0.1-0.6-0.1c-0.9-0.1-1.7-0.2-2.6-0.4c-1-0.2-2-0.2-3-0.1c-0.4,0-0.7,0.2-1.1,0.3
				C422.3,461.6,422.1,461.8,422.1,462.2z"/>
                                <path className="st13" d="M418.5,462.4c-0.2-0.3-0.1-0.6-0.1-0.8c0-0.8-0.2-1.6-0.5-2.4c-0.3-0.7-0.8-1.3-1.5-1.7
				c-0.7-0.4-1.4-0.8-2.2-1.1c-1.8-0.7-3.4-0.3-4.9,0.8c-1.4,1-2.3,2.3-2.6,4c0,0.3-0.1,0.5-0.2,0.8c-0.2,0-0.3,0.1-0.4,0.1
				c-1.2,0-2.3-0.3-3.4-0.5c-0.3-0.1-0.5-0.2-0.8-0.4c-0.2-0.1-0.4-0.4-0.5-0.8c0.1-0.2,0.1-0.6,0.2-1c0.6-1.7,0.8-3.5,0.9-5.3
				c0.1-2.4,0.4-4.8,1.2-7.1c0.7-2.1,1.9-3.8,3.6-5.3c0.6-0.6,1.3-1,2.1-1.3c0.4-0.1,0.7-0.3,1.1-0.4c1.1-0.4,2.3-0.4,3.4-0.2
				c1.5,0.2,3,0.5,4.4,0.8c0.6,0.1,1.2,0.2,1.8,0.3c0.2,0,0.3,0.1,0.5,0.2c0,0.2,0.1,0.4,0,0.7c0,1.1,0.1,2.1,0.3,3.2
				c0.2,1.1,0.4,2.3,0.4,3.5c0,0.9,0,1.8,0,2.6c0,1.8,0.2,3.5,0.4,5.2c0.1,1,0.2,2,0.3,3c0.1,0.7-0.2,1.3-0.5,1.8
				c-0.1,0.2-0.2,0.3-0.4,0.4c-0.7,0.5-1.5,0.8-2.4,1C418.7,462.5,418.6,462.5,418.5,462.4z M417.8,452.4c0.2-0.4,0.3-0.8,0.3-1.3
				c0.1-1.2,0.3-2.5,0.4-3.7c0-0.4,0.1-0.8,0.1-1.3c0-0.9-0.4-1.5-1.2-1.7c-0.3-0.1-0.6-0.2-0.9-0.2c-0.8-0.2-1.7-0.3-2.5-0.3
				c-0.9,0-1.8-0.1-2.6-0.1c-0.3,0-0.6,0-0.9,0.1c-1.4,0.4-2.5,1.3-3.1,2.7c-0.1,0.2-0.2,0.4-0.3,0.5c-0.7,1.4-1.1,2.9-1.6,4.4
				c-0.1,0.4-0.2,0.8,0.1,1.2c0.4,0.3,0.9,0.4,1.4,0.4c0.9,0,1.9,0,2.8,0c2.3,0,4.7,0,7-0.2C417.1,452.8,417.4,452.7,417.8,452.4z"
                                />
                                <path className="st18" d="M441.7,460.9c-0.1-0.2-0.2-0.4-0.2-0.5c-0.1-0.7-0.4-1.3-0.8-1.8c-0.4-0.5-0.8-0.9-1.3-1.4
				c-0.2-0.2-0.3-0.3-0.5-0.4c0-0.2,0-0.3,0-0.4c0.2-1.1,0.4-2.3,0.6-3.4c0.1-0.8,0.3-1.7,0.4-2.5c0.1-0.6,0.2-1.3,0.3-1.9
				c0.1-0.8,0.2-1.6,0.3-2.4c0-0.5,0-1-0.1-1.4c-0.1-0.7-0.5-1.1-1.1-1.3c-0.9-0.3-1.7-0.6-2.6-0.6c-0.7,0-1.3,0-2-0.1
				c-2.7-0.1-5.5-0.1-8.2-0.2c-0.5,0-1,0-1.4-0.2c-0.3-0.1-0.7-0.1-1,0.2c0,0.2-0.1,0.4-0.1,0.6c0,1.7,0,3.5,0,5.2
				c0,1.1,0.2,2.3,0.4,3.4c0.1,0.7,0.2,1.4,0.3,2.1c0.2,1.3,0.7,2.6,1.4,3.7c0.1,0.2,0.3,0.4,0.5,0.6c1.4,0.3,2.8,0.4,4.2,0.6
				c0,0.5-0.3,0.8-0.5,1.2c-0.1,0.4-0.3,0.8-0.5,1.1c-0.3,0.2-0.6,0.1-0.9,0.1c-1.3-0.2-2.7-0.4-4-0.5c-0.4,0-0.7,0-1.1,0
				c-0.3,0-0.6,0.1-0.9,0.1c0-0.1-0.1-0.2-0.1-0.3c0.1-1.2-0.1-2.4-0.2-3.6c-0.1-1.2-0.3-2.4-0.4-3.6c-0.1-1.6,0-3.3-0.1-4.9
				c0-1-0.2-2-0.3-3c-0.2-1.3-0.4-2.6-0.3-4c0.1-0.7,0-1.4-0.1-2c-0.1-0.6-0.4-1.1-0.8-1.5c-0.4-0.4-0.9-0.7-1.3-1.1
				c-0.2-0.2-0.3-0.4-0.6-0.6c0.1-0.2,0.2-0.3,0.4-0.4c0.9-0.5,1.8-0.5,2.7-0.2c0.5,0.2,1,0.6,1.5,0.9c1,0.7,2,1.4,3.1,1.9
				c2.8,1.3,5.8,1.7,8.9,1.5c1.1-0.1,2.2-0.3,3.3-0.4c1-0.1,1.9-0.1,2.9-0.1c0.7,0,1.3,0.3,1.8,0.8c0.6,0.6,1,1.2,1.4,1.9
				c0.3,0.6,0.3,1.2-0.1,1.8c-0.2,0.3-0.4,0.6-0.6,0.8c-1.2,1.6-1.7,3.5-1.7,5.5c0,0.6,0.2,1.2,0.4,1.8c0.6,1.6,1,3.2,1.3,4.8
				c0.1,0.7,0,1.3-0.2,1.9c-0.4,0.9-1,1.6-1.9,2.1C441.9,460.9,441.8,460.9,441.7,460.9z"/>
                                <path className="st15" d="M438.1,456.2c-0.6-0.1-1.2-0.3-1.8-0.4c-0.6-0.1-1.2,0-1.8,0c0-0.1-0.1-0.3-0.1-0.4c0-0.5-0.1-1.1-0.1-1.6
				c0-2.3,0-4.5-0.2-6.8c-0.1-1,0-2-0.1-3.1c0-0.1,0-0.3,0.1-0.4c0.1,0,0.2-0.1,0.2-0.1c1.5-0.1,2.9,0.1,4.3,0.5
				c0.3,0.1,0.5,0.2,0.7,0.3c0.3,0.3,0.5,0.6,0.4,1c0,0.5,0,1-0.1,1.5c-0.2,1.2-0.4,2.5-0.6,3.7c-0.1,0.8-0.2,1.6-0.3,2.4
				c-0.2,1-0.3,2.1-0.5,3.1C438.2,456,438.2,456,438.1,456.2z"/>
                                <path className="st15" d="M429.8,457.6c-0.9,0-1.7-0.3-2.6-0.3c-0.2-0.3-0.4-0.5-0.5-0.8c-0.6-1.1-1-2.2-1.1-3.4
				c-0.2-1.8-0.5-3.6-0.7-5.4c0-0.3,0-0.6-0.1-0.9c-0.1-0.9-0.1-1.9-0.2-2.8c0-0.3-0.1-0.6,0-0.9c0.1,0,0.2-0.1,0.2-0.1
				c0.8,0.2,1.6,0.2,2.4,0.2c0.4,0,0.8,0,1.1,0.1c0.3,0.4,0.3,0.7,0.4,1.1c0.1,1.2,0.2,2.5,0.3,3.7c0.1,1.3,0.3,2.5,0.4,3.8
				c0.1,1.1,0.2,2.1,0.3,3.2c0.1,0.6,0.1,1.1,0.1,1.7C429.9,457,430,457.3,429.8,457.6z"/>
                                <path className="st18" d="M433.6,456.2c-0.6,0.5-1.2,1.1-1.8,1.6c-0.3,0-0.6,0-0.9,0c-0.1-0.2-0.1-0.3-0.2-0.5
				c-0.1-0.8-0.2-1.5-0.2-2.3c0-1.5-0.3-3-0.4-4.6c0-0.3-0.1-0.6-0.1-0.9c-0.1-1.2-0.2-2.4-0.3-3.6c-0.1-0.6-0.2-1.3-0.3-1.9
				c0-0.2,0-0.3,0-0.5c0.1,0,0.2-0.1,0.3-0.1c0.8-0.1,3,0.1,3.5,0.2c0.2,0.2,0.1,0.5,0.1,0.7c0,2.8,0,5.7,0.1,8.5
				c0,1,0.2,2.1,0.2,3.1C433.6,455.9,433.6,456,433.6,456.2z"/>
                                <path className="st54" d="M407.4,463.7c-0.1-1-0.2-2.1,0.2-3.2c0.6-1.6,1.7-2.7,3.2-3.4c0.9-0.2,1.7-0.3,2.6,0
				c0.6,0.2,1.2,0.5,1.8,0.8c1.2,0.6,1.8,1.5,2,2.8c0.3,1.5,0.1,2.9-0.7,4.3c-0.6,1-1.5,1.8-2.6,2.1c-0.1,0-0.2,0.1-0.3,0.1
				c-0.9,0.1-1.9,0.1-2.8-0.1c-0.6-0.2-1.2-0.5-1.7-0.9c-0.5-0.5-0.9-1-1.3-1.6C407.8,464.3,407.6,464.1,407.4,463.7z M411.1,458.4
				c-0.5,0.5-1,0.9-1.4,1.3c-0.4,0.4-0.6,0.9-0.7,1.4c-0.2,0.7-0.2,1.3-0.2,2c0,1.1,0.5,1.9,1.4,2.4c0.7,0.4,1.5,0.5,2.3,0.5
				c0.9,0,1.7-0.4,2.3-1.1c0.6-0.7,1-1.5,1.1-2.4c0.1-0.9,0.1-1.7-0.5-2.4C414.2,458.8,412.8,458.1,411.1,458.4z"/>
                                <path className="st54" d="M436,456.6c0.6,0.2,1,0.3,1.4,0.5c0.3,0.1,0.6,0.2,0.8,0.4c0.8,0.5,1.5,1.2,2,2.1c0.3,0.5,0.4,1,0.4,1.5
				c0,0.8-0.1,1.6-0.2,2.4c-0.3,1.4-2,3.5-3.7,3.9c-1.4,0.3-2.8,0.1-4-0.8c-1.1-0.7-1.5-1.8-1.7-3.1c0-0.2,0-0.4-0.1-0.6
				c-0.3-0.9-0.1-1.8,0.3-2.6c0.6-1.3,1.5-2.3,2.6-3.1C434.4,456.7,435.1,456.5,436,456.6z M439.7,461.7c0.2-1.5-0.6-2.5-2-3.2
				c-0.5-0.2-1-0.4-1.5-0.5c-1.1-0.2-2.1,0.1-2.7,1.1c0,0.1-0.1,0.1-0.1,0.2c-0.7,1.3-1,2.7-0.8,4.2c0.2,1.4,1,2.2,2.3,2.4
				c0.8,0.1,1.6,0,2.4-0.2c0.9-0.2,1.5-0.8,1.9-1.7C439.5,463.5,439.7,462.7,439.7,461.7z"/>
                                <path className="st15" d="M422.1,462.2c0-0.4,0.2-0.6,0.4-0.6c0.3-0.1,0.7-0.2,1.1-0.3c1-0.1,2-0.1,3,0.1c0.9,0.2,1.7,0.2,2.6,0.4
				c0.2,0,0.4,0.1,0.6,0.1c0.2,0.3,0.2,0.5,0.1,0.8c-0.1,0-0.3,0.1-0.4,0.1c-0.5,0.1-1,0.1-1.5,0.1c-1.8,0-3.6-0.2-5.4-0.6
				C422.4,462.3,422.2,462.2,422.1,462.2z"/>
                                <path d="M417.8,452.4c-0.3,0.4-0.7,0.4-1.1,0.5c-2.3,0.2-4.7,0.2-7,0.2c-0.9,0-1.9,0-2.8,0c-0.5,0-1-0.1-1.4-0.4
				c-0.3-0.4-0.2-0.8-0.1-1.2c0.5-1.5,0.9-3,1.6-4.4c0.1-0.2,0.2-0.4,0.3-0.5c0.6-1.4,1.7-2.2,3.1-2.7c0.3-0.1,0.6-0.1,0.9-0.1
				c0.9,0,1.8,0.1,2.6,0.1c0.9,0,1.7,0.1,2.5,0.3c0.3,0.1,0.6,0.1,0.9,0.2c0.8,0.3,1.2,0.9,1.2,1.7c0,0.4,0,0.8-0.1,1.3
				c-0.1,1.2-0.3,2.5-0.4,3.7C418,451.6,417.9,452,417.8,452.4z M417.6,445.6c-0.3-0.3-0.6-0.4-0.9-0.5c-0.6-0.2-1.2-0.2-1.8-0.3
				c-1.4-0.2-2.8-0.2-4.2-0.2c-0.5,0-0.9,0.2-1.3,0.4c-0.5,0.3-0.9,0.8-1.2,1.3c-0.2,0.4-0.4,0.8-0.6,1.1c-0.6,1.3-1,2.8-1.5,4.1
				c0,0.1,0,0.3,0,0.4c0.3,0,0.5,0.1,0.7,0.1c0.7,0,1.3,0,2,0c2.5,0,5-0.1,7.5-0.1c0.3,0,0.6,0,0.8-0.2
				C417.4,450.5,417.8,446.7,417.6,445.6z"/>
                                <path d="M411.1,458.4c1.7-0.3,3.1,0.4,4.2,1.7c0.6,0.7,0.7,1.5,0.5,2.4c-0.1,0.9-0.5,1.7-1.1,2.4c-0.6,0.7-1.4,1.1-2.3,1.1
				c-0.8,0-1.6,0-2.3-0.5c-0.9-0.5-1.4-1.3-1.4-2.4c0-0.7,0-1.4,0.2-2c0.1-0.5,0.4-1,0.7-1.4C410.1,459.3,410.5,458.9,411.1,458.4z
				 M412.2,465.3c0.9,0,1.5-0.4,2.1-1c0.5-0.7,0.8-1.5,0.8-2.4c0-0.5-0.1-1-0.4-1.3c-0.5-0.5-1.1-1-1.7-1.2c-0.5-0.2-1-0.3-1.5-0.1
				c-0.8,0.4-1.4,1-1.7,1.8c-0.1,0.5-0.2,0.9-0.2,1.4c-0.1,1.5,0.5,2.2,1.9,2.6C411.6,465.3,411.9,465.3,412.2,465.3z"/>
                                <path d="M439.7,461.7c0,0.9-0.2,1.7-0.5,2.5c-0.4,0.9-1,1.4-1.9,1.7c-0.8,0.2-1.6,0.3-2.4,0.2c-1.3-0.2-2.2-1.1-2.3-2.4
				c-0.2-1.5,0.1-2.9,0.8-4.2c0-0.1,0.1-0.1,0.1-0.2c0.6-1,1.6-1.4,2.7-1.1c0.5,0.1,1,0.3,1.5,0.5
				C439.1,459.2,439.9,460.3,439.7,461.7z M434.7,459.1c-0.4,0.3-0.7,0.8-0.9,1.3c-0.3,0.6-0.3,1.3-0.4,1.9c-0.1,0.5,0,1,0.1,1.4
				c0.2,1,1,1.5,1.7,1.5c0.4,0,0.9,0,1.4,0c1-0.1,1.8-0.6,2-1.7c0.1-0.5,0.2-1,0.3-1.5c0.2-1.2-0.3-2-1.3-2.6
				c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.9-0.3-1.3-0.2C435.4,459.1,435.1,459.1,434.7,459.1z"/>
                                <path className="st15" d="M417.6,445.6c0.2,1-0.2,4.9-0.5,6.2c-0.2,0.2-0.5,0.2-0.8,0.2c-2.5,0-5,0.1-7.5,0.1c-0.7,0-1.3,0-2,0
				c-0.2,0-0.4-0.1-0.7-0.1c0-0.2,0-0.3,0-0.4c0.5-1.4,0.9-2.8,1.5-4.1c0.2-0.4,0.4-0.8,0.6-1.1c0.3-0.5,0.7-1,1.2-1.3
				c0.4-0.2,0.8-0.4,1.3-0.4c1.4,0,2.8,0,4.2,0.2c0.6,0.1,1.2,0.1,1.8,0.3C417,445.2,417.3,445.3,417.6,445.6z"/>
                                <path className="st15" d="M412.2,465.3c-0.3,0-0.6,0-0.8-0.1c-1.3-0.5-2-1.1-1.9-2.6c0-0.5,0.1-1,0.2-1.4c0.3-0.9,0.9-1.5,1.7-1.8
				c0.5-0.2,1-0.1,1.5,0.1c0.7,0.2,1.2,0.7,1.7,1.2c0.3,0.4,0.5,0.9,0.4,1.3c0,0.9-0.3,1.7-0.8,2.4
				C413.7,464.9,413,465.3,412.2,465.3z"/>
                                <path className="st15" d="M434.7,459.1c0.4,0,0.7,0,1,0c0.5-0.1,0.9,0,1.3,0.2c0.2,0.1,0.4,0.2,0.5,0.3c1,0.6,1.5,1.4,1.3,2.6
				c-0.1,0.5-0.2,1-0.3,1.5c-0.3,1-1,1.5-2,1.7c-0.4,0.1-0.9,0.1-1.4,0c-0.7-0.1-1.5-0.6-1.7-1.5c-0.1-0.5-0.2-0.9-0.1-1.4
				c0.1-0.6,0.2-1.3,0.4-1.9C434,459.9,434.3,459.4,434.7,459.1z"/>
		</g>
                            <g>
			<path d="M432,499.9c-0.3,0-0.4,0.1-0.6,0.1c-0.5,0.1-0.9,0.3-1.4,0.3c-1.2,0.2-2.4,0-3.5-0.3c-0.9-0.2-1.6-0.8-1.9-1.7
				c-0.4-1.3-0.1-2.9,1.2-3.7c0.6-0.3,1.2-0.6,1.8-0.7c1.6-0.3,3.2-0.6,4.8-0.5c0.4,0,0.4,0,0.9-0.4c-0.1-0.1-0.1-0.2-0.2-0.3
				c-0.5-0.6-0.6-1.3-0.8-2c-0.1-0.2,0-0.4,0.1-0.7c0.2-0.1,0.5-0.2,0.6-0.3c1,0.5,1,0.8,0.4,1.8c0.2,0.8,0.5,1.2,1.3,1.2
				c0.5,0,1,0.1,1.4,0.1c0.7,0,1.2-0.3,1.7-0.8c-0.1-0.2-0.2-0.5-0.4-0.7c0.1-0.4,0.2-0.7,0.5-1c0.2,0,0.4,0,0.6,0
				c0.4,0.3,0.6,0.7,0.4,1.2c-0.2,0.6-0.5,1.1-1,1.5c-0.2,0.2-0.4,0.3-0.6,0.5c0,0.1,0,0.2,0,0.2c0.4,0.5,0.7,1,0.9,1.6
				c0.2,0.6,0.7,1,1.2,1.3c0.3,0.2,0.5,0.3,0.6,0.7c0,0.4-0.2,0.6-0.5,0.7c-0.3,0.1-0.6,0.2-0.9,0.3c-0.4,0.1-0.8,0.2-1.1,0.2
				c-0.3,0.2-0.2,0.5-0.2,0.8c0,1,0,2.1,0.1,3.1c0.2,2.1,0.1,4.2,0,6.4c0,0.2,0,0.4,0,0.6c0,0.1,0,0.1,0.1,0.2
				c0.3,0.2,0.6,0.3,1,0.3c1.1,0.2,2.3,0.3,3.4,0.6c0.6,0.1,1.3,0.3,1.9,0.5c0.4,0.2,0.9,0.4,1.3,0.7c0.3,0.1,0.5,0.3,0.8,0.4
				c1.4-0.2,2.1-1.1,2.6-2.2c0.3-0.7,0.4-1.5,0.3-2.3c0-0.4-0.1-0.7-0.3-1.1c-0.2-0.2-0.5-0.4-0.7-0.6c-0.2,0-0.5-0.1-0.7-0.1
				c-0.4-0.2-0.8-0.4-1-0.9c0.1-0.1,0.1-0.1,0.2-0.2c0.2,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.4,0.1,0.5,0.2c0.2-0.2,0.1-0.3,0.1-0.4
				c-0.2-0.4-0.3-0.7-0.5-1c0.1-0.3,0.2-0.4,0.5-0.3c0.3,0.2,0.2,0.8,0.6,0.9c0.3-0.2,0-0.6,0.3-0.8c0.2-0.1,0.3,0,0.3,0.1
				c0.1,0.2,0.1,0.5,0.2,0.7c0,0.3,0,0.6,0,0.8c0.4,0.6,0.8,1.2,0.9,1.9c0,0,0,0.1,0,0.1c0.1,1.6-0.1,3.1-1.2,4.3
				c-0.4,0.5-1,0.9-1.7,1.1c-0.2,0-0.3,0.1-0.5,0.2c0,0.2,0,0.3,0,0.5c0.4,1.3,0.5,2.6,0.3,3.9c-0.2,1.3,0,2.6,0.3,3.9
				c0.2,0.7,0.1,1.4-0.1,2.1c-0.1,0.4-0.3,0.7-0.6,1c-0.3,0.4-0.8,0.7-1.3,0.7c-1.2,0-2.3,0-3.4-0.4c-0.8-0.2-1.3-0.7-1.5-1.6
				c-0.1-0.4-0.1-0.8-0.1-1.3c0-0.5,0.1-1-0.1-1.4c-0.3-0.2-0.7-0.1-1,0c-0.9,0.2-1.7,0.1-2.6,0.1c-0.1,0-0.3,0-0.4,0
				c-0.1,0-0.3,0-0.4,0c-0.3,0.2-0.4,0.5-0.5,0.7c-0.2,0.5-0.3,1-0.6,1.4c-0.2,0.4-0.4,0.9-0.7,1.2c-0.4,0.5-1,0.8-1.6,0.7
				c-1-0.1-1.8-0.4-2.5-1.1c-0.3-0.3-0.5-0.7-0.5-1.2c0-0.3,0-0.7,0-1c0.2-1.4,0.5-2.7,0.8-4.1c0.2-0.8,0.4-1.5,0.7-2.3
				c0.1-0.3,0.1-0.5,0.2-0.8c0.1-0.8,0.2-1.6,0.4-2.4c0.3-1.5,0.6-3,0.8-4.5c0.1-0.6,0.1-1.3,0.1-2c0-1.5,0-3-0.1-4.5
				C432.1,500.4,432,500.2,432,499.9z M441.5,516.3c0.8,0.3,1.1,0.6,1.2,1.2c0,0.2,0.1,0.3,0.1,0.5c0.2,1.2-0.1,2.1-1,2.9
				c-0.3,0.2-0.5,0.4-0.7,0.7c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.9,0.3,1.5,1.2,1.7c0.1,0,0.1,0,0.2,0c0.4,0.1,0.9,0.1,1.3,0.2
				c0.1,0,0.2,0,0.3-0.1c0-0.1,0-0.3-0.1-0.4c-0.3-0.5-0.2-1,0-1.6c0.2-0.4,0.4-0.8,0.6-1.1c0.2-0.3,0.4-0.5,0.7-0.8
				c0.1-0.1,0.1-0.1,0.2-0.2c0.2,0,0.5-0.1,0.7-0.1c0-0.8,0-1.5,0-2.2c-0.1,0-0.2-0.1-0.3-0.1c-0.6-0.1-0.8-0.4-0.9-1
				c0-0.6-0.3-1-0.9-1.2c-1-0.5-1.5-2-0.5-3.1c0.1-0.2,0.3-0.3,0.5-0.5c-0.3-0.4-0.8-0.5-1.2-0.5c-1.1-0.2-2.2-0.4-3.3-0.6
				c-0.8-0.1-1.6-0.2-2.3-0.6c-0.2-0.3-0.2-0.6-0.2-0.9c0-0.7,0-1.4,0-2c0-0.2-0.1-0.3-0.1-0.5c-0.4,0-0.8,0-1.1,0
				c-0.7-0.3-0.8-0.8-0.8-1.4c0-0.5-0.1-1-0.4-1.5c-0.4-0.6-0.3-1.3,0.2-1.9c0.3-0.3,0.7-0.6,1.1-0.7c0.3-0.1,0.5-0.1,0.8-0.2
				c0.1-0.3,0.1-0.7,0.1-1c0-0.5,0-1.1,0-1.6c0-0.3,0.1-0.6,0.4-0.8c0.1,0,0.4-0.1,0.6-0.1c0.3,0,0.6-0.1,0.9-0.2
				c0.1,0,0.2-0.1,0.3-0.2c0-0.1,0-0.1,0-0.2c0,0-0.1-0.1-0.1-0.1c-0.7-0.5-1.3-1.2-1.6-2.1c0-0.1-0.1-0.1-0.1-0.2
				c-0.4-0.7-1.8-1.5-2.8-1.2c-0.3,0.1-0.7,0.2-1,0.3c-0.4,0.2-0.9,0.3-1.4,0.3c-0.8,0-1.6,0.1-2.4,0.3c-0.2,0-0.4,0.1-0.5,0.4
				c0.2,0.5,0.4,1,0.5,1.5c0.1,0.5,0.2,1,0.3,1.5c0.1,0.5-0.1,1.1,0.1,1.6c0.3,0.1,0.6,0,0.9-0.1c0.7-0.2,1.4-0.4,2-0.6
				c0.1,0,0.2,0,0.3,0c0,0.1,0.1,0.3,0.1,0.5c-0.2,0.2-0.5,0.4-0.7,0.6c0,0.1,0,0.3,0,0.5c0.1,1.2,0.2,2.3,0.2,3.5
				c0,1.4,0.1,2.8-0.3,4.2c-0.1,0.3-0.1,0.6,0,0.9c0.2,0.1,0.3,0.1,0.5,0.2c0.8,0.3,1.1,0.9,1,1.8c0,0.3-0.1,0.6-0.2,0.9
				c-0.1,0.3-0.2,0.6-0.2,0.9c0.1,0.5,0.3,1,0.4,1.4c-0.3,1.2-1.2,1.9-2.4,2c-0.2,0-0.5-0.1-0.8,0.2c-0.1,0.5-0.2,1-0.3,1.5
				c-0.1,0.5-0.4,1.1-0.2,1.6c0.1,0,0.1,0.1,0.2,0.1c1.1,0.5,1.5,1.3,1.5,2.5c0,0.6-0.1,1.1-0.3,1.7c0.4,0.2,0.7,0.2,1,0.1
				c0.4-0.2,0.6-0.6,0.8-0.9c0.2-0.4,0.3-0.8,0.5-1.2c0.2-0.4,0.3-0.8,0.5-1.3c0.2-0.5,0.6-0.8,1.1-0.8c0.2,0,0.4,0,0.6-0.1
				c0-0.6,0-1.1,0-1.6c0-1,0.7-1.5,1.7-1.2c0.4,0.1,0.7,0.2,1.1,0.3c0.5,0.1,0.9,0.1,1.3-0.1C440.7,516.6,441.1,516.5,441.5,516.3z
				 M428.3,494.7c-0.8,0-1.5,0.2-2.1,0.6c-0.8,0.5-1.1,1.2-1.1,2.1c0,0.8,0.4,1.3,1.1,1.7c0.3,0.1,0.6,0.2,0.9,0.3
				c0.5,0.1,0.9,0.1,1.4,0.2c0.2,0,0.4-0.1,0.6-0.1C429.5,497.7,429.1,496.2,428.3,494.7z M441.1,520.5c0.8-0.5,1.2-1.1,1.2-2
				c0-0.6-0.1-1.2-0.8-1.6c-0.2,0.1-0.5,0.2-0.7,0.3c-0.5,0.2-1,0.3-1.5,0.2c-0.4-0.1-0.8-0.1-1.2-0.2c-0.3-0.1-0.6-0.1-0.9,0
				c-0.6,0.7-0.6,1.3-0.1,2.2c0.7,0.1,1.5,0.1,2.2-0.1c0.4-0.1,0.7-0.2,0.9-0.1C441,519.6,440.9,520.1,441.1,520.5z M431.3,515.3
				c0.1,0,0.3,0.1,0.4,0.1c0.3-0.1,0.7-0.2,0.9-0.4c0.7-0.4,0.8-0.8,0.6-1.6c-0.2-0.6-0.2-1.2,0.1-1.8c0.1-0.3,0.2-0.5,0.3-0.8
				c0.1-0.4-0.1-0.7-0.3-0.9c-0.2-0.2-0.5-0.4-0.8-0.3c-0.3,0.9-0.4,1.8-0.6,2.7c-0.2,0.9-0.3,1.8-0.6,2.7
				C431.2,515.1,431.3,515.2,431.3,515.3z M444.6,512.3c-0.1,0.1-0.2,0.1-0.3,0.2c-1.1,0.7-1.1,2,0.1,2.5c0.1,0,0.1,0,0.2,0.1
				c0.2,0.2,0.4,0.4,0.6,0.5c0.1,0.3,0.1,0.5,0.1,0.7c0.1,0.2,0.1,0.5,0.3,0.6c0.1-0.1,0.2-0.1,0.3-0.2c0-0.4,0-0.7,0-1.1
				c0-0.4,0-0.7-0.1-1.1c-0.1-0.5-0.3-1-0.5-1.4C445.3,512.7,445.1,512.5,444.6,512.3z M434.9,503.2c0.1,0.2,0.1,0.4,0.2,0.5
				c0.3,0.5,0.5,1,0.4,1.6c0,0.2,0,0.5,0.2,0.8c0.4,0.3,0.8,0.1,1.1-0.2c0.2-1,0.1-3.2-0.2-4C435.8,501.9,435.3,502.2,434.9,503.2z
				 M445.6,523.9c0.5-0.4,0.7-0.9,0.7-1.4c0-0.7,0.1-1.4-0.4-2.1c-0.8,0.5-1.3,1.2-1.6,2.1c-0.2,0.4-0.1,0.9,0.4,1.2
				C445,523.7,445.3,523.8,445.6,523.9z M431,523.2c0.2-0.1,0.3-0.2,0.4-0.4c0-0.3,0-0.6,0-0.9c0-0.2,0-0.4,0-0.6
				c0-0.4-0.2-0.6-0.4-0.9c-0.2-0.2-0.4-0.4-0.8-0.4c-0.2,0.6-0.3,1.2-0.3,1.8C429.9,522.5,430.2,523,431,523.2z"/>
                                <path className="st14" d="M441.5,516.3c-0.4,0.2-0.8,0.3-1.1,0.5c-0.4,0.2-0.9,0.3-1.3,0.1c-0.4-0.1-0.7-0.2-1.1-0.3
				c-1-0.2-1.6,0.3-1.7,1.2c0,0.5,0,1,0,1.6c-0.2,0-0.4,0.1-0.6,0.1c-0.6,0-1,0.3-1.1,0.8c-0.2,0.4-0.3,0.8-0.5,1.3
				c-0.2,0.4-0.3,0.8-0.5,1.2c-0.2,0.4-0.4,0.7-0.8,0.9c-0.3,0.1-0.6,0.1-1-0.1c0.1-0.5,0.3-1.1,0.3-1.7c0-1.1-0.4-2-1.5-2.5
				c-0.1,0-0.1-0.1-0.2-0.1c-0.2-0.6,0.1-1.1,0.2-1.6c0.1-0.5,0.2-1.1,0.3-1.5c0.3-0.3,0.5-0.2,0.8-0.2c1.2-0.1,2-0.7,2.4-2
				c-0.1-0.4-0.2-0.9-0.4-1.4c0.1-0.3,0.2-0.6,0.2-0.9c0.1-0.3,0.2-0.6,0.2-0.9c0.1-0.8-0.3-1.4-1-1.8c-0.2-0.1-0.3-0.1-0.5-0.2
				c-0.2-0.3-0.1-0.6,0-0.9c0.3-1.4,0.3-2.8,0.3-4.2c0-1.2-0.1-2.3-0.2-3.5c0-0.2,0-0.3,0-0.5c0.2-0.2,0.5-0.4,0.7-0.6
				c0-0.2-0.1-0.3-0.1-0.5c-0.1,0-0.2-0.1-0.3,0c-0.7,0.2-1.4,0.4-2,0.6c-0.3,0.1-0.5,0.1-0.9,0.1c-0.2-0.5,0-1.1-0.1-1.6
				c-0.1-0.5-0.2-1-0.3-1.5c-0.1-0.5-0.3-1-0.5-1.5c0.1-0.3,0.3-0.4,0.5-0.4c0.8-0.1,1.6-0.3,2.4-0.3c0.5,0,0.9-0.1,1.4-0.3
				c0.3-0.2,0.6-0.2,1-0.3c1-0.3,2.4,0.4,2.8,1.2c0,0.1,0.1,0.1,0.1,0.2c0.3,0.9,0.8,1.5,1.6,2.1c0,0,0.1,0.1,0.1,0.1
				c0,0,0,0.1,0,0.2c-0.1,0.1-0.2,0.1-0.3,0.2c-0.3,0.1-0.6,0.1-0.9,0.2c-0.2,0-0.5,0.1-0.6,0.1c-0.3,0.3-0.4,0.5-0.4,0.8
				c0,0.5,0,1.1,0,1.6c0,0.3,0.1,0.7-0.1,1c-0.3,0.1-0.5,0.2-0.8,0.2c-0.4,0.1-0.8,0.4-1.1,0.7c-0.5,0.5-0.6,1.2-0.2,1.9
				c0.3,0.5,0.4,0.9,0.4,1.5c0,0.6,0.2,1.1,0.8,1.4c0.3,0,0.7,0,1.1,0c0.1,0.2,0.1,0.3,0.1,0.5c0,0.7,0,1.4,0,2c0,0.3,0,0.6,0.2,0.9
				c0.7,0.4,1.5,0.5,2.3,0.6c1.1,0.2,2.2,0.3,3.3,0.6c0.4,0.1,0.8,0.1,1.2,0.5c-0.2,0.2-0.3,0.3-0.5,0.5c-1,1.1-0.6,2.6,0.5,3.1
				c0.5,0.3,0.8,0.6,0.9,1.2c0,0.5,0.3,0.9,0.9,1c0.1,0,0.2,0.1,0.3,0.1c0,0.7,0,1.4,0,2.2c-0.2,0-0.5,0.1-0.7,0.1
				c-0.1,0-0.1,0.1-0.2,0.2c-0.2,0.3-0.5,0.5-0.7,0.8c-0.2,0.4-0.4,0.7-0.6,1.1c-0.2,0.5-0.3,1,0,1.6c0.1,0.1,0.1,0.3,0.1,0.4
				c-0.1,0-0.2,0.1-0.3,0.1c-0.4-0.1-0.9-0.1-1.3-0.2c-0.1,0-0.1,0-0.2,0c-0.9-0.3-1.2-0.8-1.2-1.7c0-0.2,0.1-0.4,0.2-0.6
				c0.2-0.2,0.5-0.4,0.7-0.7c0.9-0.8,1.2-1.7,1-2.9c0-0.2,0-0.3-0.1-0.5C442.6,516.9,442.3,516.6,441.5,516.3z M435.9,513.9
				c0.2,0.3,0.4,0.6,0.5,0.9c0.3,0.4,0.6,0.7,1.1,0.7c0.5,0,1,0,1.5,0c0.7-0.1,1.2-0.5,1.4-1.2c0.1-0.3,0.1-0.6-0.2-0.9
				c-0.1-0.1-0.2-0.3-0.4-0.3c-0.6-0.3-1.1-0.7-1.6-1.1c-0.2-0.2-0.4-0.3-0.7-0.3c-0.7-0.2-1.4,0.1-1.6,0.8
				c-0.1,0.2-0.1,0.3-0.1,0.5C435.9,513.3,435.9,513.5,435.9,513.9z M433.4,496.2c0.1,0.3,0.3,0.5,0.6,0.4c0.3,0,0.5-0.2,0.5-0.6
				c-0.1-0.3-0.3-0.5-0.6-0.4C433.6,495.7,433.4,495.9,433.4,496.2z"/>
                                <path className="st18" d="M428.3,494.7c0.8,1.5,1.2,3.1,0.8,4.8c-0.2,0-0.4,0.1-0.6,0.1c-0.5,0-1-0.1-1.4-0.2
				c-0.3-0.1-0.6-0.2-0.9-0.3c-0.7-0.4-1-0.9-1.1-1.7c0-0.9,0.3-1.6,1.1-2.1C426.8,494.9,427.5,494.7,428.3,494.7z M426.7,497.1
				c0,0.3,0.1,0.5,0.5,0.6c0.1-0.1,0.1-0.1,0.2-0.2C427.1,497,427.1,497,426.7,497.1z"/>
                                <path className="st18" d="M441.1,520.5c-0.2-0.5-0.1-1-0.8-1.2c-0.2-0.1-0.6,0-0.9,0.1c-0.7,0.2-1.5,0.2-2.2,0.1
				c-0.5-0.9-0.5-1.5,0.1-2.2c0.3-0.1,0.6-0.1,0.9,0c0.4,0.1,0.8,0.2,1.2,0.2c0.5,0.1,1,0,1.5-0.2c0.2-0.1,0.5-0.2,0.7-0.3
				c0.7,0.3,0.7,1,0.8,1.6C442.3,519.4,441.9,520.1,441.1,520.5z"/>
                                <path className="st18" d="M431.3,515.3c0-0.1,0-0.2,0-0.2c0.4-0.9,0.5-1.8,0.6-2.7c0.2-0.9,0.2-1.9,0.6-2.7c0.4-0.1,0.6,0.1,0.8,0.3
				c0.2,0.3,0.4,0.6,0.3,0.9c-0.1,0.3-0.2,0.5-0.3,0.8c-0.3,0.6-0.3,1.2-0.1,1.8c0.2,0.8,0.1,1.1-0.6,1.6c-0.3,0.2-0.6,0.3-0.9,0.4
				C431.6,515.4,431.4,515.3,431.3,515.3z"/>
                                <path className="st18" d="M444.6,512.3c0.5,0.1,0.7,0.4,0.9,0.8c0.2,0.5,0.4,1,0.5,1.4c0.1,0.4,0.1,0.7,0.1,1.1c0,0.4,0,0.7,0,1.1
				c-0.1,0.1-0.3,0.1-0.3,0.2c-0.3-0.2-0.3-0.4-0.3-0.6c-0.1-0.2-0.1-0.4-0.1-0.7c-0.2-0.1-0.4-0.3-0.6-0.5c0,0-0.1,0-0.2-0.1
				c-1.2-0.5-1.3-1.8-0.1-2.5C444.4,512.4,444.5,512.4,444.6,512.3z"/>
                                <path className="st18" d="M434.9,503.2c0.4-0.9,0.9-1.3,1.8-1.4c0.2,0.8,0.3,3,0.2,4c-0.3,0.4-0.7,0.5-1.1,0.2
				c-0.2-0.3-0.2-0.5-0.2-0.8c0-0.6-0.1-1.1-0.4-1.6C435,503.5,435,503.3,434.9,503.2z"/>
                                <path className="st18" d="M445.6,523.9c-0.4-0.1-0.7-0.1-0.9-0.2c-0.5-0.3-0.5-0.8-0.4-1.2c0.3-0.8,0.8-1.5,1.6-2.1
				c0.5,0.7,0.3,1.4,0.4,2.1C446.3,523,446.1,523.5,445.6,523.9z"/>
                                <path className="st18" d="M431,523.2c-0.7-0.2-1.1-0.7-1.1-1.4c0-0.6,0.1-1.2,0.3-1.8c0.4-0.1,0.6,0.2,0.8,0.4
				c0.2,0.3,0.4,0.5,0.4,0.9c0,0.2,0,0.4,0,0.6c0,0.3,0,0.6,0,0.9C431.2,523,431.1,523.1,431,523.2z"/>
                                <path d="M435.9,513.9c0-0.3,0-0.6,0-0.9c0-0.2,0-0.3,0.1-0.5c0.2-0.7,0.9-1.1,1.6-0.8c0.2,0.1,0.5,0.2,0.7,0.3
				c0.5,0.4,1,0.8,1.6,1.1c0.1,0.1,0.3,0.2,0.4,0.3c0.2,0.3,0.3,0.5,0.2,0.9c-0.2,0.7-0.7,1.1-1.4,1.2c-0.5,0.1-1,0.1-1.5,0
				c-0.5,0-0.9-0.3-1.1-0.7C436.2,514.5,436.1,514.2,435.9,513.9z M439.7,513.7c-0.3-0.2-0.6-0.3-1-0.6c-0.3-0.2-0.6-0.5-1-0.7
				c-0.4-0.2-0.7-0.3-1.1-0.1c-0.2,0.3-0.4,0.7-0.3,1.1c0.3,0.4,0.5,1,1,1.3c0.6,0.1,1.1,0.1,1.7,0.1c0.3,0,0.5-0.2,0.6-0.4
				C439.9,514.2,439.9,514,439.7,513.7z"/>
                                <path d="M433.4,496.2c0-0.3,0.2-0.5,0.5-0.6c0.3,0,0.5,0.1,0.6,0.4c0,0.3-0.2,0.5-0.5,0.6C433.7,496.7,433.5,496.5,433.4,496.2z"
                                />
                                <path d="M426.7,497.1c0.4-0.1,0.4-0.1,0.7,0.3c-0.1,0.1-0.1,0.2-0.2,0.2C426.8,497.6,426.7,497.4,426.7,497.1z"/>
                                <path className="st18" d="M439.7,513.7c0.2,0.3,0.1,0.6,0,0.8c-0.2,0.2-0.4,0.3-0.6,0.4c-0.6,0-1.1,0.1-1.7-0.1
				c-0.5-0.3-0.7-0.9-1-1.3c-0.1-0.5,0.1-0.8,0.3-1.1c0.4-0.2,0.8-0.2,1.1,0.1c0.3,0.2,0.6,0.5,1,0.7
				C439.1,513.3,439.4,513.5,439.7,513.7z"/>
		</g>
                            <g>
			<path d="M386.2,466.9c-0.1,0.2-0.2,0.4-0.3,0.6c-0.9,1.8-2.7,2.6-4.5,2.2c-0.4-0.1-0.8-0.2-1.2-0.3c-1.3-0.4-2.4-1-3-2.3
				c-0.3,0-0.5,0-0.8,0c-1.6,0-3.2,0-4.8,0c-1.2,0-2.3-0.1-3.5-0.1c-0.1,0-0.2,0-0.4,0c-0.1,0.2-0.2,0.4-0.3,0.6
				c-0.6,1.2-1.6,2-2.9,2.2c-0.8,0.2-1.6,0.2-2.4,0c-1.4-0.3-2.3-1.1-3-2.3c-0.1-0.2-0.2-0.5-0.3-0.7c-0.4-0.2-0.8-0.1-1.1,0
				c-0.6,0.2-1.3,0.1-1.9,0c-0.5-0.1-1-0.1-1.5-0.1c-0.7,0-1.2-0.3-1.5-0.9c-0.1-0.2-0.2-0.4-0.2-0.6c-0.3-1.1-0.2-2.2,0.1-3.3
				c0.5-1.9,1.7-3.1,3.5-3.8c0.8-0.3,1.6-0.5,2.3-0.7c0.9-0.2,1.6-0.6,2.1-1.4c1.3-2.1,3-3.7,4.8-5.3c2.1-1.9,4.6-3,7.3-3.5
				c2.1-0.4,4.2-0.5,6.3-0.4c1.4,0,2.7,0.5,3.7,1.5c0.4,0.3,0.7,0.7,1.1,1.1c0.5,0.5,0.8,1.1,0.9,1.8c0.1,0.4,0.2,0.9,0.3,1.3
				c0.1,0.4,0.2,0.8,0.4,1.2c0.4,1,0.8,2,1.1,2.9c0.5,1.6,1.5,2.9,2.6,4c0.2,0.2,0.3,0.3,0.5,0.5c1,1,1.3,2.3,1.1,3.7
				c-0.1,0.6-0.3,1.1-0.7,1.6c-0.3,0.5-0.9,0.7-1.5,0.7c-0.6,0-1.2-0.1-1.9-0.1C386.6,466.8,386.4,466.8,386.2,466.9z M354.1,466.1
				c1.5,0,3,0.2,4.6-0.1c0-0.2,0.1-0.4,0.1-0.6c0.1-1.7,1.2-3.1,2.9-3.6c1.3-0.4,2.6-0.5,3.9-0.1c1.4,0.4,2.3,1.6,2.5,3
				c0,0.3,0,0.7,0,1c0,0.2,0.1,0.3,0.1,0.5c0.3,0.2,0.6,0.1,1,0.1c1.4,0,2.8,0,4.3,0c0.9,0,1.9,0,2.8,0c0.3,0,0.7,0,1-0.3
				c0-0.2,0-0.3,0.1-0.5c0.1-0.4,0.1-0.8,0.3-1.2c0.3-0.8,0.8-1.6,1.4-2.2c1.2-1.4,2.7-1.8,4.5-1.3c1.2,0.4,2.1,1.1,2.6,2.3
				c0.3,0.6,0.4,1.3,0.5,2c0,0.3-0.1,0.6,0.2,0.8c1,0.3,2,0,3-0.3c0.7-0.8,0.8-1.8,0.4-2.8c-0.2-0.6-0.7-1.1-1.1-1.5
				c-1.4-1.3-2.5-2.9-3.1-4.8c-0.3-0.9-0.7-1.7-1-2.5c-0.2-0.5-0.3-1.1-0.5-1.6c-0.1-0.4-0.2-0.8-0.3-1.2c-0.1-0.3-0.2-0.7-0.4-0.9
				c-0.7-0.8-1.4-1.6-2.3-2.1c-0.5-0.3-1.1-0.5-1.7-0.5c-2.4-0.1-4.8,0-7.1,0.5c-2.4,0.5-4.5,1.5-6.3,3c-1.8,1.6-3.5,3.2-4.8,5.3
				c-0.1,0.1-0.1,0.2-0.2,0.3c-0.5,0.7-1.2,1.1-2,1.4c-0.4,0.1-0.8,0.2-1.2,0.3c-1,0.3-1.9,0.6-2.8,1.2c-0.5,0.3-0.9,0.7-1.2,1.2
				c-0.7,1.3-1,2.7-0.6,4.1C353.4,465.4,353.5,465.8,354.1,466.1z M380.6,461.6c-1.4,0.9-2.3,2.1-2.7,3.7c-0.1,0.3-0.1,0.7,0,1
				c0.2,0.7,0.6,1.2,1.2,1.6c0.9,0.6,1.9,0.9,3,0.9c0.4,0,0.8,0,1.1-0.1c1.1-0.4,1.9-1.2,2.1-2.4c0.2-0.9,0.1-1.8-0.3-2.7
				c-0.5-1.2-1.5-1.9-2.8-2C381.7,461.6,381.2,461.6,380.6,461.6z M361.8,462.5c0,0-0.1,0-0.1-0.1c-0.4,0.2-0.7,0.4-1.1,0.7
				c-0.8,0.7-1.2,1.5-1.2,2.5c0.1,2,1.7,3.5,3.4,3.5c0.4,0,0.8,0,1.2,0c1.3-0.2,2.3-0.9,2.8-2.2c0.2-0.7,0.3-1.5,0.2-2.3
				c-0.2-1-0.8-1.7-1.7-2c-0.8-0.3-1.6-0.3-2.4-0.2C362.5,462.4,362.2,462.4,361.8,462.5z"/>
                                <path className="st32" d="M354.1,466.1c-0.6-0.2-0.7-0.7-0.8-1.1c-0.3-1.5,0-2.8,0.6-4.1c0.3-0.5,0.7-0.9,1.2-1.2
				c0.8-0.6,1.8-0.9,2.8-1.2c0.4-0.1,0.8-0.2,1.2-0.3c0.8-0.2,1.5-0.6,2-1.4c0.1-0.1,0.1-0.2,0.2-0.3c1.3-2,3-3.7,4.8-5.3
				c1.8-1.6,3.9-2.6,6.3-3c2.4-0.5,4.7-0.6,7.1-0.5c0.6,0,1.2,0.2,1.7,0.5c0.9,0.5,1.7,1.3,2.3,2.1c0.2,0.2,0.3,0.6,0.4,0.9
				c0.1,0.4,0.2,0.8,0.3,1.2c0.1,0.5,0.3,1.1,0.5,1.6c0.3,0.9,0.7,1.7,1,2.5c0.6,1.9,1.7,3.4,3.1,4.8c0.5,0.4,0.9,0.9,1.1,1.5
				c0.4,1,0.3,1.9-0.4,2.8c-1,0.3-2,0.7-3,0.3c-0.2-0.3-0.1-0.6-0.2-0.8c0-0.7-0.2-1.3-0.5-2c-0.5-1.2-1.4-1.9-2.6-2.3
				c-1.7-0.5-3.2-0.1-4.5,1.3c-0.6,0.7-1.1,1.4-1.4,2.2c-0.1,0.4-0.2,0.8-0.3,1.2c0,0.2,0,0.3-0.1,0.5c-0.3,0.3-0.6,0.3-1,0.3
				c-0.9,0-1.9,0-2.8,0c-1.4,0-2.8,0-4.3,0c-0.3,0-0.6,0-1-0.1c0-0.1-0.1-0.3-0.1-0.5c0-0.3,0-0.7,0-1c-0.1-1.5-1.1-2.6-2.5-3
				c-1.3-0.4-2.6-0.3-3.9,0.1c-1.7,0.5-2.7,1.9-2.9,3.6c0,0.2,0,0.4-0.1,0.6C357.1,466.3,355.5,466,354.1,466.1z M370,451
				c-0.1,0-0.2,0.1-0.3,0.1c-0.4,0.2-0.8,0.5-1.2,0.7c-1.5,0.8-2.7,1.8-3.7,3.2c-0.2,0.3-0.4,0.6-0.5,1c-0.3,0.7-0.1,1.3,0.6,1.7
				c0.1,0.1,0.2,0.2,0.4,0.2c0.4,0.1,0.9,0.2,1.3,0.2c1,0,2.1-0.1,3.1-0.2c0.7-0.1,1.2-0.5,1.6-1c0.4-0.5,0.4-1,0.3-1.6
				c-0.2-1.1-0.4-2.2-0.6-3.3C371,451.5,370.7,451.1,370,451z"/>
                                <path className="st54" d="M380.6,461.6c0.5,0,1,0,1.5,0c1.3,0.1,2.3,0.8,2.8,2c0.4,0.8,0.5,1.8,0.3,2.7c-0.2,1.2-1,2-2.1,2.4
				c-0.3,0.1-0.7,0.1-1.1,0.1c-1.1,0-2.1-0.4-3-0.9c-0.6-0.4-1-0.9-1.2-1.6c-0.1-0.3-0.1-0.7,0-1
				C378.3,463.7,379.2,462.5,380.6,461.6z M381,462.9c-0.3,0.4-0.6,0.7-0.8,1.1c-0.5,0.7-0.5,1.5-0.1,2.3c0.4,0.6,1,0.9,1.6,0.9
				c0.7,0,1.2-0.4,1.4-1c0.3-0.7,0.4-1.4,0.2-2.1c-0.3-0.8-0.8-1.2-1.7-1.2C381.4,462.9,381.2,462.9,381,462.9z"/>
                                <path className="st54" d="M361.8,462.5c0.3,0,0.7-0.1,1-0.1c0.8-0.1,1.6-0.1,2.4,0.2c0.9,0.4,1.5,1,1.7,2c0.2,0.8,0.1,1.5-0.2,2.3
				c-0.4,1.3-1.4,2-2.8,2.2c-0.4,0.1-0.8,0-1.2,0c-1.7,0-3.3-1.4-3.4-3.5c0-1,0.4-1.9,1.2-2.5c0.3-0.3,0.7-0.5,1.1-0.7
				C361.7,462.4,361.8,462.4,361.8,462.5z M362.7,463.4c-0.3,0.3-0.6,0.5-0.9,0.9c-0.2,0.2-0.3,0.4-0.3,0.7
				c-0.1,0.6-0.1,1.2,0.2,1.7c0.4,0.7,0.9,1,1.7,1.1c0.8,0,1.5-0.6,1.7-1.3c0.2-0.6,0.2-1.3,0-1.9c-0.2-0.7-0.8-1.1-1.5-1.2
				C363.4,463.3,363.1,463.4,362.7,463.4z"/>
                                <path d="M370,451c0.7,0.1,0.9,0.4,1,0.9c0.2,1.1,0.4,2.2,0.6,3.3c0.1,0.6,0.1,1.1-0.3,1.6c-0.4,0.5-0.9,0.9-1.6,1
				c-1,0.1-2.1,0.2-3.1,0.2c-0.4,0-0.9-0.1-1.3-0.2c-0.1,0-0.3-0.1-0.4-0.2c-0.7-0.4-0.9-1-0.6-1.7c0.1-0.3,0.3-0.7,0.5-1
				c1-1.4,2.2-2.4,3.7-3.2c0.4-0.2,0.8-0.5,1.2-0.7C369.8,451.1,369.9,451.1,370,451z M370,451.8c-0.2,0.1-0.4,0.2-0.6,0.3
				c-0.6,0.4-1.1,0.7-1.7,1.1c-0.9,0.6-1.6,1.4-2.2,2.2c-0.2,0.3-0.5,0.7-0.5,1.1c0.4,0.5,0.4,0.5,0.7,0.6c0.3,0.1,0.6,0.1,0.8,0.1
				c0.9,0,1.8-0.1,2.7-0.1c0.7-0.1,1.3-0.4,1.7-1.1c0-0.2,0-0.5,0-0.7c-0.2-1-0.4-2.1-0.6-3.1c0-0.1-0.1-0.2-0.1-0.2
				C370.2,452,370.1,451.9,370,451.8z"/>
                                <path d="M381,462.9c0.2,0,0.4-0.1,0.6-0.1c0.9,0,1.4,0.4,1.7,1.2c0.3,0.7,0.2,1.4-0.2,2.1c-0.3,0.6-0.7,1-1.4,1
				c-0.7,0-1.3-0.3-1.6-0.9c-0.4-0.8-0.4-1.6,0.1-2.3C380.4,463.6,380.7,463.3,381,462.9z M381.7,466.5c0.4,0,0.6-0.2,0.7-0.5
				c0.2-0.3,0.3-0.7,0.3-1.1c0-0.3-0.2-0.7-0.3-0.9c-0.1-0.2-0.4-0.3-0.8-0.3c-0.1,0.1-0.4,0.2-0.6,0.3c-0.5,0.4-0.6,0.9-0.5,1.5
				c0.1,0.6,0.5,0.9,1,1C381.6,466.5,381.7,466.5,381.7,466.5z"/>
                                <path d="M362.7,463.4c0.3,0,0.6-0.1,0.9-0.1c0.7,0,1.3,0.5,1.5,1.2c0.2,0.6,0.2,1.3,0,1.9c-0.2,0.7-0.9,1.3-1.7,1.3
				c-0.8,0-1.4-0.4-1.7-1.1c-0.3-0.6-0.3-1.1-0.2-1.7c0-0.2,0.2-0.5,0.3-0.7C362.1,463.9,362.4,463.7,362.7,463.4z M362.2,464.9
				c-0.2,1.4,0.1,1.8,1.1,2c0.8-0.3,1.1-0.5,1.1-1.3c0-0.7,0.1-1.3-0.7-1.6C362.8,464.3,362.7,464.4,362.2,464.9z"/>
                                <path className="st15" d="M370,451.8c0.1,0.1,0.2,0.1,0.2,0.2c0,0.1,0.1,0.1,0.1,0.2c0.2,1,0.4,2.1,0.6,3.1c0,0.2,0,0.5,0,0.7
				c-0.4,0.7-1,1-1.7,1.1c-0.9,0.1-1.8,0.1-2.7,0.1c-0.3,0-0.6,0-0.8-0.1c-0.3-0.1-0.4-0.1-0.7-0.6c0-0.4,0.3-0.8,0.5-1.1
				c0.6-0.9,1.3-1.6,2.2-2.2c0.6-0.4,1.1-0.8,1.7-1.1C369.5,452,369.8,451.9,370,451.8z"/>
                                <path className="st15" d="M381.7,466.5c-0.1,0-0.1,0-0.1,0c-0.5-0.1-0.9-0.4-1-1c-0.1-0.6,0-1.1,0.5-1.5c0.2-0.2,0.4-0.3,0.6-0.3
				c0.4,0,0.6,0.1,0.8,0.3c0.2,0.3,0.3,0.6,0.3,0.9c0.1,0.4-0.1,0.8-0.3,1.1C382.3,466.3,382.1,466.4,381.7,466.5z"/>
                                <path className="st15" d="M362.2,464.9c0.5-0.6,0.6-0.7,1.5-0.8c0.8,0.3,0.8,0.9,0.7,1.6c0,0.8-0.3,1-1.1,1.3
				C362.3,466.7,362.1,466.3,362.2,464.9z"/>
		</g>
                            <g>
			<path d="M350,395.4c0.1-0.1,0.3-0.4,0.5-0.5c0.6-0.4,1.1-0.9,1.7-1.3c1.3-0.9,2.7-1.7,4-2.6c0.2-0.1,0.4-0.3,0.5-0.4
				c0-1-0.5-6.7-0.7-7.8c0-0.3,0.2-0.6,0.5-0.8c0.5-0.5,1.1-0.9,1.6-1.4c0.9-0.6,1.8-1.3,2.6-1.9c0.1-0.5,0.1-3.4,0-4.1
				c-0.2-0.1-0.4-0.2-0.6-0.3c0-0.1,0-0.2,0-0.2c0.8-0.7,1.7-1.2,2.7-1.6c0.9-0.4,1.8-0.8,2.5-1.1c1.5-0.2,2.9-0.4,4.2-0.5
				c0.5-0.1,1-0.1,1.5-0.1c1-0.1,2.1-0.1,3.1-0.2c0.4,0,0.7-0.1,1.1-0.1c1.5,0,3.1-0.2,4.6-0.5c0.7-0.1,1.5-0.2,2.2-0.3
				c0.5,0,1.1-0.2,1.6-0.2c0.6-0.1,1.3-0.1,1.9,0c0.8,0.1,1.6,0.3,2.3,0.5c1.1,0.3,2.1,0.8,3.1,1.4c0.5,0.3,0.8,0.8,0.6,1.4
				c-0.2,0.9,0.1,1.7,0.2,2.5c0.1,0.1,0.1,0.2,0.2,0.2c1.2,0.4,2.3,1.1,3.3,1.9c0.7,0.5,1.4,1,2.1,1.5c0.8,0.5,1.3,1.3,1.6,2.2
				c0.4,1.2,0.6,2.5,0.7,3.7c0.1,0.9,0.2,1.7,0.3,2.6c0.6,0.5,1.3,0.9,1.9,1.4c0.6,0.5,1.2,1.1,1.8,1.6c0.6,0.5,1.2,1,1.6,1.5
				c0.4,1.1,0.4,2.1,0.4,3.1c0,2.1,0,4.3,0.1,6.4c0,0.5,0.1,1,0.1,1.5c0,0.8,0.1,1.7,0.1,2.6c0.1,0.1,0.3,0.3,0.4,0.5
				c0,0.2,0,0.3,0,0.5c-0.3,0.4-0.7,0.4-1.1,0.4c-1.4,0-2.7,0.1-4.1,0c-2.1,0-4.2,0-6.3,0.1c-1.4,0.1-2.8,0.2-4.2,0.3
				c-2.7,0.2-5.4,0.2-8.2,0.1c-2.6-0.1-5.2-0.2-7.8-0.2c-3.2,0-6.4,0.2-9.5,0.3c-1.1,0.1-2.2,0.1-3.3,0.2c-1.7,0.1-3.5,0-5.2-0.2
				c-1.2-0.1-2.3-0.2-3.5-0.1c-0.5,0-1,0-1.4,0.5c-0.2-0.1-0.3-0.2-0.4-0.2c-0.2-0.8-0.4-1.5-0.5-2.3c-0.6-2.8-0.9-5.5-0.8-8.4
				C350,396.7,350,396.1,350,395.4z M404.3,394.1c-0.3-0.2-0.6-0.1-0.9-0.1c-2.8,0-5.5,0.3-8.2,0.4c-2.4,0.1-4.7,0.4-7.1,0.6
				c-1.8,0.1-3.6,0.2-5.3,0.3c-3,0.1-5.9,0.2-8.9,0.2c-2.4,0-4.8,0.2-7.1,0.4c-2.6,0.2-5.3,0.4-7.9,0.4c-2.3,0-4.6,0.2-6.9,0.3
				c-0.1,0-0.1,0-0.2,0c-0.2,0-0.3,0.1-0.4,0.1c-0.2,1-0.1,3.2,0.3,7.1c0.1,0.9,0.2,1.9,0.6,2.9c0.3,0,0.6-0.1,0.9-0.1
				c0.7-0.1,1.4-0.2,2.1-0.1c1.2,0.1,2.4,0.2,3.6,0.3c1,0.1,2.1,0.1,3.1,0.1c3.2-0.2,6.3-0.4,9.5-0.5c0.4,0,0.7,0,1.1,0
				c2.5-0.2,5-0.2,7.6,0c3.4,0.2,6.7,0.2,10.1,0.1c0.5,0,1-0.1,1.4-0.1c2.7-0.2,5.4-0.4,8.1-0.3c0.7,0,1.3,0,2,0c0.7,0,1.5,0,2.2,0
				c0.2,0,0.4-0.1,0.5-0.1c0.3-0.2,0.4-0.5,0.4-0.8c0-0.3,0-0.6,0-0.8c-0.1-2.6-0.2-5.2-0.3-7.7c0-0.6,0-1.1,0-1.7
				C404.4,394.5,404.4,394.3,404.3,394.1z M376.3,382.2c-0.5-0.1-0.9,0-1.3,0c-1.7,0.2-3.4,0.4-5.1,0.6c-1.6,0.2-3.2,0.4-4.7,0.6
				c-0.2,0-0.5,0-0.6,0.3c0.1,0.1,0.2,0.3,0.3,0.4c0.6,0.7,1.2,1.3,1.8,2c0.8,0.9,1.5,1.8,2.3,2.7c0.5,0.5,1,1.1,1.6,1.5
				c0.5,0.4,1.2,0.8,1.8,1.2c0.3,0,0.6,0,0.8,0c1.7-0.1,3.4-0.1,5.1-0.2c1,0,2,0,3-0.1c0.2,0,0.4-0.1,0.5-0.1c0-0.1,0-0.2,0-0.2
				C380,388,378.1,385.1,376.3,382.2z M351.8,395.3c0.4,0.2,0.7,0.1,1,0.1c1.7-0.2,3.4-0.2,5.1-0.3c3-0.1,5.9-0.3,8.9-0.5
				c2.2-0.1,4.4-0.3,6.5-0.4c2.4-0.1,4.7-0.1,7.1-0.1c0.2,0,0.5,0,0.7,0c2.4-0.2,4.8-0.3,7.2-0.5c0.2,0,0.4,0,0.6,0
				c1.3-0.1,2.7-0.3,4-0.4c1.8-0.1,3.5-0.2,5.3-0.3c0.2,0,0.3,0,0.5,0c1.4-0.2,2.8-0.2,4.2-0.2c0.3,0,0.5,0,0.8,0
				c0.1,0,0.2-0.1,0.4-0.2c-0.2-0.2-0.3-0.3-0.5-0.5c-0.7-0.6-1.3-1.2-2-1.8c-0.4-0.3-0.7-0.7-1.1-1c-0.1-0.1-0.2-0.1-0.4-0.2
				c-0.2,0.5-0.1,0.9-0.1,1.3c0.2,0.2,0.3,0.4,0.5,0.6c-0.1,0.2-0.1,0.3-0.2,0.5c-0.3,0.1-0.6,0.1-0.9,0.2c-0.3,0-0.6,0-0.9,0
				c-2.9,0-5.8,0.1-8.7,0.2c-2.5,0.2-5.1,0.4-7.6,0.4c-0.2,0-0.4,0-0.6,0c-4.1,0-8.3,0.1-12.4,0.5c-1,0.1-2,0.2-3,0.3
				c-1.3,0.1-2.5,0.3-3.8,0.4c-1.4,0.1-2.7,0.1-4.1,0.1c-0.4,0-0.9,0-1.2-0.3c-0.2-0.4-0.1-1-0.5-1.3c-0.1,0-0.2,0-0.3,0.1
				c-1.4,1.1-3,2-4.5,3C352,395.1,351.9,395.2,351.8,395.3z M361.8,381.6c-0.2-0.1-0.3-0.1-0.5-0.2c-0.2-0.2-0.3-0.4-0.3-0.7
				c0-0.2-0.1-0.4-0.1-0.7c-0.2,0.1-0.3,0.1-0.4,0.1c-1,0.9-2.3,1.5-3.3,2.4c0,0,0,0.1,0,0.2c0.2,0,0.4,0.1,0.6,0.1
				c1.3,0,2.6,0,3.8,0c0.5,0,0.9,0,1.4-0.1c0.9-0.1,1.8-0.2,2.7-0.4c0.7-0.1,1.5-0.2,2.2-0.3c1.1-0.1,2.2-0.3,3.3-0.4
				c1.5-0.2,3-0.4,4.5-0.6c1.8-0.2,3.7-0.3,5.5-0.5c0.9-0.1,1.9-0.2,2.8-0.3c3.9-0.2,7.7-0.6,11.6-0.5c0.1,0,0.2,0,0.3,0
				c0.1,0,0.2-0.1,0.3-0.2c-1.2-1.1-2.4-1.9-3.7-2.7c-0.1-0.1-0.2-0.1-0.3-0.1c-0.2,0.3,0.1,0.7-0.3,1c-0.1,0-0.2,0-0.3,0
				c-0.9,0-1.9,0-2.8,0c-1.8,0.1-3.5,0.2-5.3,0.3c-1.6,0.2-3.2,0.2-4.8,0.3c-1,0.1-2,0.2-2.9,0.4c-1.4,0.2-2.8,0.4-4.2,0.6
				c-0.9,0.1-1.8,0.1-2.6,0.3c-1.8,0.3-3.6,0.5-5.5,0.8c-0.4,0.1-0.9,0.2-1.3,0.2C362.2,381.1,362,381.3,361.8,381.6z M392.2,380.9
				c-0.2,0-0.4,0-0.6,0c-1.8,0.1-3.5,0.2-5.3,0.3c-0.8,0-1.6,0.1-2.5,0.2c-0.2,0-0.5,0-0.7,0.3c0.1,0.1,0.1,0.3,0.3,0.4
				c1.4,1.5,2.5,3.2,3.6,4.9c0.9,1.3,1.8,2.6,3,3.7c0.1,0,0.3,0,0.5,0c1.8-0.1,3.6-0.1,5.3-0.2c0.1,0,0.2-0.1,0.4-0.2
				C395,387.2,393.5,384.2,392.2,380.9z M384.7,370.2c-0.8,0.1-1.6,0.2-2.4,0.4c-1.9,0.4-3.8,0.6-5.8,0.7c-0.7,0-1.3,0.1-1.9,0.1
				c-0.7,0-1.4,0.1-2,0.1c-1.3,0.1-2.7,0.2-4,0.3c-0.6,0.1-1.3,0.1-1.9,0.2c-0.3,0-0.5,0-0.7,0.1c-1.2,0.5-2.3,0.9-3.5,1.4
				c-0.1,0-0.1,0.1-0.2,0.2c0.4,0.2,0.7,0.2,1,0.2c1.1,0.1,2.1,0.1,3.2,0.1c2.1,0.1,4.2,0,6.3-0.2c1.2-0.1,2.3-0.3,3.5-0.4
				c2.5-0.2,4.9-0.6,7.3-1c1-0.2,2-0.3,3-0.4c0.9-0.1,1.9-0.1,2.8-0.2c0.1,0,0.2-0.1,0.4-0.1c-0.2-0.2-0.2-0.3-0.4-0.3
				c-0.5-0.2-1-0.4-1.5-0.6C386.8,370.8,385.7,370.5,384.7,370.2z M377.4,382.1c0.1,0.2,0.2,0.4,0.3,0.5c1,1.4,1.9,2.9,2.8,4.5
				c0.5,0.8,0.9,1.6,1.4,2.4c0.2,0.4,0.5,0.8,0.8,1.1c0.3,0.4,0.8,0.6,1.3,0.6c1.4-0.1,2.7-0.2,4.1-0.2c0.1,0,0.2-0.1,0.4-0.2
				c-0.1-0.2-0.2-0.3-0.3-0.4c-0.6-0.5-1-1.1-1.4-1.8c-1.5-2.3-2.9-4.5-4.4-6.8c0-0.1-0.1-0.1-0.2-0.2c-1.4,0-2.8,0.3-4.2,0.3
				C377.8,381.9,377.7,382,377.4,382.1z M370.2,391.5c-0.1-0.1-0.1-0.2-0.2-0.2c-0.8-0.6-1.5-1.3-2.1-2c-0.8-1-1.7-2-2.5-3
				c-0.7-0.8-1.3-1.7-2-2.6c-0.3,0-0.5,0-0.8,0c-0.8,0-1.6,0.1-2.4,0.1c-0.3,0-0.5,0-0.8,0c-0.1,0-0.2,0.1-0.4,0.2
				c2,3.1,4.5,5.6,7.1,8.2c1.3-0.1,2.6-0.3,3.9-0.4C370.1,391.6,370.1,391.6,370.2,391.5z M364.7,392.1c-0.4-0.6-1.1-1-1.6-1.6
				c-0.5-0.5-1-1-1.5-1.5c-0.5-0.5-1-1.1-1.4-1.7c-0.4-0.6-0.8-1.2-1.2-1.8c-0.4-0.6-0.8-1.2-1.2-1.9c-0.3-0.1-0.7-0.2-1,0.1
				c0.1,0.7,0.1,1.3,0.2,1.9c0.1,1.4,0.3,2.8,0.4,4.1c0.1,0.9,0.2,1.7,0.3,2.6c0,0.1,0.1,0.1,0.1,0.2c0.7,0.1,4.6,0,5.8-0.2
				C364,392.3,364.3,392.3,364.7,392.1z M369.7,378.8c0.7,0.2,1.8,0.1,5-0.5c0.2,0,0.5-0.1,0.7-0.4c-0.4-0.4-0.6-0.9-0.7-1.5
				c0-0.6,0.3-1.1,0.5-1.6c-0.1,0-0.2-0.1-0.2-0.1c-2.1,0.1-4.1,0.4-6.2,0.3c0,0-0.1,0-0.1,0.1c0,0,0,0-0.1,0.1
				c0.1,0.1,0.2,0.2,0.3,0.3c0.2,0.2,0.3,0.3,0.5,0.5c0.5,0.7,0.9,1.5,0.4,2.4C369.7,378.6,369.7,378.7,369.7,378.8z M362.1,380.1
				c0.1,0,0.3,0,0.4,0c1.1-0.2,2.1-0.4,3.2-0.6c0.1,0,0.2-0.1,0.2-0.1c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
				c-0.9-0.8-1.2-2.1-0.3-3.3c0.1-0.1,0.1-0.2,0.2-0.3c-0.6-0.2-3.2-0.5-4-0.4c-0.2,0.2-0.1,0.4-0.1,0.6c0.1,1.3,0.2,2.6,0.3,3.9
				C361.8,379.6,361.9,379.9,362.1,380.1z M393,381c0.1,0.2,0.1,0.3,0.2,0.5c0.2,0.4,0.4,0.7,0.6,1.1c1.2,2.3,2.3,4.6,3.2,6.9
				c0.1,0.4,0.2,0.8,0.6,1c0.4,0.1,0.9,0.1,1.3-0.1c0.1-0.8,0.1-1.5,0-2.3c-0.5-0.2-0.8-0.7-1-1.1c-1.1-1.8-2.1-3.7-3.2-5.5
				c-0.1-0.2-0.3-0.4-0.4-0.6C393.9,380.8,393.5,380.7,393,381z M384.1,377.2c-0.1-0.2-0.2-0.4-0.3-0.6c-0.4-0.8-0.4-1.6,0.1-2.3
				c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.2,0.1-0.3c-0.1,0-0.2-0.1-0.3-0.1c-1.9,0.3-3.7,0.5-5.6,0.8c0,0-0.1,0.1-0.1,0.1
				c1.6,1.4,1.3,1.3,1,3C380.1,377.7,383.1,377.5,384.1,377.2z M387.5,373.4c0.3,0.4,0.6,0.7,0.7,1.1c0.2,0.4,0.2,0.8,0.3,1.2
				c0,0.4,0,0.8,0,1.3c0.3,0.2,0.6,0,0.9,0c0.3,0,0.6,0,0.8,0c0.3,0,0.6,0.1,0.9-0.2c-0.2-1.2-0.4-2.3-0.6-3.5
				c-0.6-0.2-1.8-0.2-2.7-0.1C387.8,373.2,387.7,373.3,387.5,373.4z M395.2,381c0.2,0.3,0.3,0.4,0.4,0.6c0.7,1.1,1.4,2.2,2.1,3.3
				c0.3,0.4,0.5,0.8,0.7,1.2c0.1,0.1,0.2,0.2,0.3,0c0-0.1,0-0.2,0-0.4c-0.1-0.9-0.2-1.8-0.4-2.8c-0.1-0.5-0.2-1-0.4-1.6
				c-0.1-0.2-0.2-0.4-0.4-0.5C396.7,380.7,395.9,380.8,395.2,381z M365.8,376.2c-0.2,0.3-0.3,0.7-0.3,1.1c0.3,1.4,2,2.1,3.1,1.2
				c0.3-0.2,0.5-0.6,0.4-1c-0.2-0.7-0.6-1.2-1.2-1.4C367.2,375.8,366.5,375.8,365.8,376.2z M385.9,374c-0.5,0-0.9,0.3-1.2,0.7
				c-0.4,0.5-0.4,1.1,0.1,1.6c0.6,0.6,1.4,0.8,2.2,0.6c0.4-0.1,0.6-0.4,0.7-0.8c0.1-0.9-0.1-2-1.4-2.1
				C386.1,374.1,386,374.1,385.9,374z M376.1,375.4c-0.1,0.1-0.2,0.2-0.3,0.3c-0.5,0.7-0.4,1.4,0.4,1.8c0.4,0.2,0.9,0.3,1.4,0.1
				c0.5-0.2,0.9-0.5,1-1.1c-0.2-0.7-0.7-0.9-1.3-1C377,375.3,376.6,375.4,376.1,375.4z"/>
                                <path className="st13" d="M404.3,394.1c0,0.2,0.1,0.4,0.1,0.6c0,0.6,0,1.1,0,1.7c0.1,2.6,0.2,5.2,0.3,7.7c0,0.3,0,0.6,0,0.8
				c0,0.3-0.1,0.6-0.4,0.8c-0.1,0-0.3,0.1-0.5,0.1c-0.7,0-1.5,0-2.2,0c-0.7,0-1.3,0-2,0c-2.7-0.1-5.4,0.1-8.1,0.3
				c-0.5,0-1,0.1-1.4,0.1c-3.4,0.1-6.7,0.1-10.1-0.1c-2.5-0.1-5-0.1-7.6,0c-0.4,0-0.7,0-1.1,0c-3.2,0-6.3,0.3-9.5,0.5
				c-1,0.1-2.1,0.1-3.1-0.1c-1.2-0.1-2.4-0.2-3.6-0.3c-0.7,0-1.4,0-2.1,0.1c-0.3,0.1-0.5,0.1-0.9,0.1c-0.4-1-0.4-1.9-0.6-2.9
				c-0.5-3.8-0.5-6.1-0.3-7.1c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.1,0,0.2,0c2.3-0.1,4.6-0.3,6.9-0.3c2.6,0,5.3-0.3,7.9-0.4
				c2.4-0.1,4.7-0.3,7.1-0.4c3,0,5.9-0.1,8.9-0.2c1.8-0.1,3.6-0.2,5.3-0.3c2.4-0.2,4.7-0.5,7.1-0.6c2.7-0.2,5.5-0.5,8.2-0.4
				C403.7,394,404,393.9,404.3,394.1z M400,396c-0.6-0.2-1.3,0-1.9,0c-1.2,0.1-2.3,0.3-3.5,0.4c-1.4,0.1-2.7,0.2-4.1,0.3
				c-0.7,0.1-1.3,0.2-2,0.2c-0.3,0-0.7,0.2-0.9,0.4c-0.2,0.3-0.3,0.7-0.3,1c0,1.2,0,2.3,0,3.5c0,0.2,0.1,0.3,0.1,0.5
				c0.3,0.2,0.6,0.4,0.8,0.6c1.5,0.2,4.5,0.2,6.2,0c1-0.1,2.1-0.2,3.1-0.3c0.4,0,0.8-0.1,1.2-0.1c0.4,0,0.8,0,1.2-0.1
				c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.4,0.2-0.8,0.2-1.3c0-1.4,0-2.8,0-4.2C400.4,396.4,400.3,396.2,400,396z M370.4,397.1
				c-0.1,0.1-0.2,0.3-0.2,0.4c-0.1,0.4-0.2,0.7-0.2,1.1c0,1.4,0,2.9,0,4.3c0,0.3,0.1,0.7,0.4,1c0.1,0,0.3,0.1,0.4,0.2
				c0.3,0.1,0.6,0.1,0.8,0.1c3.3,0.2,6.7-0.1,10-0.5c0.1,0,0.2-0.1,0.2-0.1c0.4-0.3,0.4-0.7,0.4-1c0-0.9,0.1-1.9,0.1-2.8
				c0-0.5,0-1,0-1.4c0-0.4-0.1-0.7-0.3-1c-0.3-0.3-0.7-0.2-1-0.2c-1.7-0.1-3.4-0.2-5.2-0.3C374,396.7,372.2,396.5,370.4,397.1z
				 M356.4,404.4c0.2,0.3,0.4,0.4,0.8,0.4c0.7,0,1.4,0,2,0c2,0.1,3.9-0.3,5.9-0.5c0.4,0,0.7-0.1,1-0.4c0.2-0.4,0.1-0.8,0-1.2
				c-0.1-1.3-0.1-2.6-0.2-3.9c0-0.3,0-0.6-0.1-0.9c0-0.3-0.2-0.5-0.5-0.6c-0.3-0.1-0.7,0-1.1,0.1c-1.3,0.2-2.5,0.5-3.8,0.5
				c-0.8,0-1.7,0.1-2.5,0.2c-0.5,0-1.1,0.1-1.6,0.4c0,0.2-0.1,0.4-0.1,0.7C356.2,400.5,356.3,403.7,356.4,404.4z"/>
                                <path className="st32" d="M376.3,382.2c1.8,2.9,3.6,5.8,5.4,8.7c0,0,0,0.1,0,0.2c-0.2,0-0.4,0.1-0.5,0.1c-1,0-2,0-3,0.1
				c-1.7,0.1-3.4,0.1-5.1,0.2c-0.3,0-0.6,0-0.8,0c-0.6-0.4-1.2-0.7-1.8-1.2c-0.6-0.5-1.1-1-1.6-1.5c-0.8-0.9-1.6-1.8-2.3-2.7
				c-0.6-0.7-1.2-1.3-1.8-2c-0.1-0.1-0.2-0.3-0.3-0.4c0.2-0.3,0.4-0.3,0.6-0.3c1.6-0.2,3.2-0.4,4.7-0.6c1.7-0.2,3.4-0.4,5.1-0.6
				C375.4,382.1,375.8,382,376.3,382.2z"/>
                                <path className="st73" d="M351.8,395.3c0.1-0.1,0.1-0.2,0.2-0.3c1.5-1,3.1-1.9,4.5-3c0.1,0,0.2,0,0.3-0.1c0.4,0.3,0.3,0.9,0.5,1.3
				c0.3,0.4,0.8,0.4,1.2,0.3c1.4,0,2.7,0,4.1-0.1c1.3-0.1,2.5-0.3,3.8-0.4c1-0.1,2-0.2,3-0.3c4.1-0.4,8.3-0.5,12.4-0.5
				c0.2,0,0.4,0,0.6,0c2.6,0,5.1-0.2,7.6-0.4c2.9-0.2,5.8-0.3,8.7-0.2c0.3,0,0.6,0,0.9,0c0.3,0,0.6-0.1,0.9-0.2
				c0.1-0.2,0.1-0.4,0.2-0.5c-0.2-0.2-0.3-0.4-0.5-0.6c0-0.4-0.1-0.8,0.1-1.3c0.1,0.1,0.3,0.1,0.4,0.2c0.4,0.3,0.7,0.6,1.1,1
				c0.7,0.6,1.4,1.2,2,1.8c0.1,0.1,0.3,0.3,0.5,0.5c-0.2,0.1-0.3,0.2-0.4,0.2c-0.3,0-0.5,0-0.8,0c-1.4,0-2.8,0-4.2,0.2
				c-0.2,0-0.3,0-0.5,0c-1.8,0.1-3.5,0.2-5.3,0.3c-1.3,0.1-2.7,0.2-4,0.4c-0.2,0-0.4,0-0.6,0c-2.4,0.2-4.8,0.3-7.2,0.5
				c-0.2,0-0.5,0-0.7,0c-2.4,0-4.7,0-7.1,0.1c-2.2,0.1-4.4,0.2-6.5,0.4c-3,0.2-5.9,0.3-8.9,0.5c-1.7,0.1-3.4,0.1-5.1,0.3
				C352.5,395.4,352.2,395.5,351.8,395.3z"/>
                                <path className="st66" d="M361.8,381.6c0.2-0.2,0.3-0.4,0.5-0.6c0.4-0.1,0.9-0.2,1.3-0.2c1.8-0.3,3.6-0.6,5.5-0.8
				c0.9-0.1,1.8-0.1,2.6-0.3c1.4-0.2,2.8-0.4,4.2-0.6c1-0.1,2-0.3,2.9-0.4c1.6-0.1,3.2-0.1,4.8-0.3c1.7-0.2,3.5-0.3,5.3-0.3
				c0.9,0,1.9,0,2.8,0c0.1,0,0.3,0,0.3,0c0.4-0.3,0.1-0.7,0.3-1c0.1,0,0.2,0,0.3,0.1c1.2,0.9,2.5,1.7,3.7,2.7
				c-0.2,0.1-0.3,0.1-0.3,0.2c-0.1,0-0.2,0-0.3,0c-3.9-0.1-7.8,0.3-11.6,0.5c-0.9,0.1-1.9,0.2-2.8,0.3c-1.8,0.2-3.7,0.3-5.5,0.5
				c-1.5,0.2-3,0.4-4.5,0.6c-1.1,0.1-2.2,0.3-3.3,0.4c-0.7,0.1-1.5,0.2-2.2,0.3c-0.9,0.1-1.8,0.2-2.7,0.4c-0.4,0.1-0.9,0.1-1.4,0.1
				c-1.3,0-2.6,0-3.8,0c-0.2,0-0.4-0.1-0.6-0.1c0-0.1,0-0.2,0-0.2c1-1,2.2-1.5,3.3-2.4c0.1-0.1,0.2-0.1,0.4-0.1
				c0,0.3,0.1,0.5,0.1,0.7c0,0.3,0.1,0.5,0.3,0.7C361.5,381.5,361.7,381.5,361.8,381.6z"/>
                                <path className="st32" d="M392.2,380.9c1.3,3.2,2.8,6.2,4.1,9.4c-0.1,0.1-0.3,0.2-0.4,0.2c-1.8,0.1-3.6,0.1-5.3,0.2
				c-0.2,0-0.4,0-0.5,0c-1.2-1.1-2.1-2.4-3-3.7c-1.1-1.7-2.2-3.4-3.6-4.9c-0.1-0.1-0.2-0.3-0.3-0.4c0.2-0.3,0.5-0.3,0.7-0.3
				c0.8-0.1,1.6-0.1,2.5-0.2c1.8-0.1,3.5-0.2,5.3-0.3C391.8,380.9,392,380.9,392.2,380.9z"/>
                                <path className="st74" d="M384.7,370.2c1,0.3,2.1,0.6,3.1,0.9c0.5,0.2,1,0.4,1.5,0.6c0.1,0,0.2,0.2,0.4,0.3c-0.2,0.1-0.3,0.1-0.4,0.1
				c-0.9,0.1-1.9,0.1-2.8,0.2c-1,0.1-2,0.2-3,0.4c-2.4,0.4-4.9,0.7-7.3,1c-1.2,0.1-2.3,0.3-3.5,0.4c-2.1,0.2-4.2,0.3-6.3,0.2
				c-1.1,0-2.2-0.1-3.2-0.1c-0.3,0-0.7,0-1-0.2c0.1-0.1,0.2-0.2,0.2-0.2c1.1-0.5,2.3-1,3.5-1.4c0.2-0.1,0.5-0.1,0.7-0.1
				c0.6-0.1,1.3-0.1,1.9-0.2c1.3-0.1,2.7-0.2,4-0.3c0.7-0.1,1.4-0.1,2-0.1c0.6,0,1.3-0.1,1.9-0.1c2-0.1,3.9-0.3,5.8-0.7
				C383.1,370.5,383.9,370.4,384.7,370.2z"/>
                                <path className="st14" d="M377.4,382.1c0.2-0.1,0.4-0.1,0.5-0.2c1.4-0.1,2.8-0.3,4.2-0.3c0.1,0.1,0.2,0.1,0.2,0.2
				c1.5,2.3,2.9,4.5,4.4,6.8c0.4,0.6,0.8,1.3,1.4,1.8c0.1,0.1,0.2,0.2,0.3,0.4c-0.2,0.1-0.3,0.2-0.4,0.2c-1.4,0.1-2.7,0.2-4.1,0.2
				c-0.6,0-1-0.2-1.3-0.6c-0.3-0.4-0.5-0.7-0.8-1.1c-0.5-0.8-0.9-1.6-1.4-2.4c-0.9-1.5-1.7-3.1-2.8-4.5
				C377.6,382.4,377.6,382.3,377.4,382.1z"/>
                                <path className="st14" d="M370.2,391.5c-0.1,0.1-0.1,0.1-0.1,0.1c-1.3,0.1-2.6,0.3-3.9,0.4c-2.6-2.5-5.1-5.1-7.1-8.2
				c0.1-0.1,0.3-0.2,0.4-0.2c0.3,0,0.5,0,0.8,0c0.8,0,1.6-0.1,2.4-0.1c0.3,0,0.6,0,0.8,0c0.7,0.9,1.3,1.7,2,2.6c0.8,1,1.6,2,2.5,3
				c0.6,0.8,1.3,1.5,2.1,2C370.1,391.3,370.1,391.4,370.2,391.5z"/>
                                <path className="st32" d="M364.7,392.1c-0.3,0.2-0.7,0.2-1,0.3c-1.1,0.2-5.1,0.3-5.8,0.2c0-0.1-0.1-0.1-0.1-0.2
				c-0.1-0.9-0.2-1.7-0.3-2.6c-0.2-1.4-0.3-2.8-0.4-4.1c-0.1-0.6-0.1-1.3-0.2-1.9c0.4-0.3,0.7-0.2,1-0.1c0.4,0.7,0.8,1.3,1.2,1.9
				c0.4,0.6,0.8,1.2,1.2,1.8c0.4,0.6,0.9,1.2,1.4,1.7c0.5,0.5,1,1,1.5,1.5C363.6,391.1,364.2,391.5,364.7,392.1z"/>
                                <path className="st13" d="M369.7,378.8c0-0.1,0-0.2,0.1-0.3c0.4-0.9,0.1-1.7-0.4-2.4c-0.1-0.2-0.3-0.3-0.5-0.5
				c-0.1-0.1-0.2-0.2-0.3-0.3c0-0.1,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c2.1,0,4.1-0.2,6.2-0.3c0,0,0.1,0,0.2,0.1
				c-0.2,0.5-0.5,1-0.5,1.6c0,0.6,0.3,1.1,0.7,1.5c-0.2,0.3-0.5,0.3-0.7,0.4C371.4,378.9,370.4,379,369.7,378.8z"/>
                                <path className="st13" d="M362.1,380.1c-0.2-0.2-0.3-0.5-0.3-0.8c-0.1-1.3-0.2-2.6-0.3-3.9c0-0.2-0.1-0.5,0.1-0.6
				c0.8,0,3.4,0.2,4,0.4c-0.1,0.1-0.1,0.2-0.2,0.3c-0.9,1.2-0.6,2.5,0.3,3.3c0.1,0.1,0.2,0.2,0.3,0.3c0,0.1,0,0.1,0,0.1
				c0,0,0,0.1,0,0.1c-0.1,0-0.1,0.1-0.2,0.1c-1.1,0.2-2.1,0.4-3.2,0.6C362.4,380.2,362.3,380.1,362.1,380.1z"/>
                                <path className="st14" d="M393,381c0.5-0.2,0.9-0.1,1.3-0.1c0.1,0.2,0.3,0.4,0.4,0.6c1.1,1.8,2.1,3.7,3.2,5.5c0.3,0.4,0.5,0.9,1,1.1
				c0.1,0.8,0.1,1.5,0,2.3c-0.5,0.2-0.9,0.1-1.3,0.1c-0.4-0.3-0.5-0.7-0.6-1c-1-2.4-2.1-4.7-3.2-6.9c-0.2-0.4-0.4-0.7-0.6-1.1
				C393.1,381.3,393.1,381.1,393,381z"/>
                                <path className="st13" d="M384.1,377.2c-1,0.3-4,0.5-4.8,0.3c0.3-1.7,0.5-1.6-1-3c0,0,0.1-0.1,0.1-0.1c1.9-0.3,3.7-0.5,5.6-0.8
				c0.1,0,0.2,0,0.3,0.1c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.1-0.2,0.2-0.3,0.3c-0.5,0.8-0.5,1.5-0.1,2.3C383.9,376.8,384,377,384.1,377.2
				z"/>
                                <path className="st13" d="M387.5,373.4c0.2-0.1,0.3-0.1,0.4-0.2c0.8-0.1,2.1-0.1,2.7,0.1c0.2,1.1,0.4,2.3,0.6,3.5
				c-0.3,0.2-0.6,0.2-0.9,0.2c-0.3,0-0.6,0-0.8,0c-0.3,0-0.6,0.1-0.9,0c0-0.4,0-0.8,0-1.3c0-0.4-0.1-0.9-0.3-1.2
				C388.1,374.1,387.8,373.8,387.5,373.4z"/>
                                <path className="st32" d="M395.2,381c0.7-0.2,1.5-0.2,2.4-0.1c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0.5,0.3,1,0.4,1.6
				c0.2,0.9,0.3,1.8,0.4,2.8c0,0.1,0,0.3,0,0.4c-0.2,0.1-0.2,0.1-0.3,0c-0.2-0.4-0.5-0.8-0.7-1.2c-0.7-1.1-1.4-2.2-2.1-3.3
				C395.5,381.4,395.4,381.2,395.2,381z"/>
                                <path className="st14" d="M365.8,376.2c0.7-0.3,1.4-0.3,2-0.1c0.7,0.3,1.1,0.7,1.2,1.4c0.1,0.4,0,0.7-0.4,1c-1.2,0.8-2.9,0.2-3.1-1.2
				C365.5,376.8,365.6,376.5,365.8,376.2z"/>
                                <path className="st14" d="M385.9,374c0.2,0,0.3,0,0.4,0.1c1.3,0.1,1.5,1.2,1.4,2.1c-0.1,0.4-0.3,0.7-0.7,0.8c-0.8,0.2-1.6,0-2.2-0.6
				c-0.5-0.5-0.5-1.1-0.1-1.6C385,374.4,385.3,374,385.9,374z"/>
                                <path className="st14" d="M376.1,375.4c0.4,0,0.8,0,1.2,0c0.6,0.1,1.1,0.3,1.3,1c-0.1,0.6-0.5,0.9-1,1.1c-0.5,0.2-0.9,0.1-1.4-0.1
				c-0.7-0.4-0.9-1.1-0.4-1.8C375.9,375.5,376,375.5,376.1,375.4z"/>
                                <path d="M400,396c0.3,0.2,0.4,0.4,0.4,0.6c0,1.4,0,2.8,0,4.2c0,0.4-0.1,0.8-0.2,1.3c0,0.2-0.2,0.3-0.3,0.5c-0.4,0-0.8,0-1.2,0.1
				c-0.4,0-0.8,0.1-1.2,0.1c-1,0.1-2.1,0.2-3.1,0.3c-1.7,0.2-4.7,0.2-6.2,0c-0.3-0.2-0.5-0.4-0.8-0.6c0-0.2-0.1-0.4-0.1-0.5
				c0-1.2,0-2.3,0-3.5c0-0.4,0.1-0.7,0.3-1c0.2-0.2,0.6-0.4,0.9-0.4c0.7-0.1,1.3-0.2,2-0.2c1.4-0.1,2.7-0.2,4.1-0.3
				c1.2-0.1,2.3-0.3,3.5-0.4C398.8,396,399.4,395.8,400,396z M393.5,397.8c-0.2,0-0.4-0.1-0.6,0c-1.3,0-2.6,0.1-3.9,0.1
				c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0.7-0.1,1.4,0,2.1c0,0.7-0.1,1.3,0.2,2c0.5,0,1,0,1.5,0c1,0,2-0.1,3.1-0.1c0.2,0,0.4-0.1,0.5-0.1
				C393.8,401.1,393.7,399,393.5,397.8z M399.1,401.4c0.3-1.1,0.3-3.2,0-4c-0.1,0-0.3,0-0.4,0c-1.4,0.2-2.8,0.3-4.2,0.5
				c-0.2,0.5,0,0.9,0,1.4c0,0.4,0.1,0.8,0.1,1.3c0.1,0.4-0.1,0.9,0.2,1.3c0.1,0,0.2,0.1,0.3,0c1.1-0.1,2.3-0.2,3.4-0.2
				c0.1,0,0.3-0.1,0.4-0.1C399.1,401.5,399.1,401.4,399.1,401.4z"/>
                                <path d="M370.4,397.1c1.9-0.6,3.7-0.4,5.5-0.3c1.7,0.1,3.4,0.2,5.2,0.3c0.3,0,0.7,0,1,0.2c0.3,0.3,0.3,0.6,0.3,1c0,0.5,0,1,0,1.4
				c0,0.9-0.1,1.9-0.1,2.8c0,0.4,0,0.7-0.4,1c-0.1,0-0.1,0.1-0.2,0.1c-3.3,0.4-6.6,0.7-10,0.5c-0.3,0-0.6-0.1-0.8-0.1
				c-0.2,0-0.3-0.1-0.4-0.2c-0.3-0.3-0.4-0.6-0.4-1c0-1.4,0-2.9,0-4.3c0-0.4,0.1-0.7,0.2-1.1C370.2,397.3,370.3,397.2,370.4,397.1z
				 M371.2,402.8c0.7,0.2,1.4,0.2,2.1,0.3c0.7,0,1.3,0.1,2,0.1c0.7,0,1.4,0,2,0c0.6,0,1.3-0.1,1.9-0.2c0.7-0.1,1.3-0.1,2-0.4
				c0-1.3,0.1-2.6,0.1-3.8c0-0.2-0.1-0.3-0.1-0.5c-0.1-0.1-0.1-0.1-0.2-0.1c-3.2-0.2-6.5-0.5-9.7-0.7c-0.1,0-0.1,0-0.2,0.1
				c0,0.1-0.1,0.2-0.1,0.3C371.1,399.5,371.2,401.1,371.2,402.8z"/>
                                <path d="M356.4,404.4c-0.1-0.7-0.2-3.9-0.1-5.4c0-0.2,0.1-0.4,0.1-0.7c0.5-0.3,1-0.4,1.6-0.4c0.8-0.1,1.7-0.1,2.5-0.2
				c1.3,0,2.5-0.3,3.8-0.5c0.4-0.1,0.8-0.1,1.1-0.1c0.3,0.1,0.4,0.4,0.5,0.6c0,0.3,0.1,0.6,0.1,0.9c0.1,1.3,0.1,2.6,0.2,3.9
				c0,0.4,0.1,0.8,0,1.2c-0.2,0.3-0.6,0.4-1,0.4c-2,0.3-3.9,0.6-5.9,0.5c-0.7,0-1.4,0-2,0C356.9,404.9,356.6,404.8,356.4,404.4z
				 M364.8,398.3c-0.6,0-1.2,0.1-1.9,0.2c-0.6,0.1-1.3,0.2-1.9,0.2c-0.6,0.1-1.2,0.1-1.9,0.1c-0.6,0.1-1.3-0.1-1.9,0.1
				c0,0.2,0,0.4,0,0.6c0,0.5,0.1,1,0.1,1.5c0,0.6,0,1.3,0.1,2c0,0.2,0.1,0.4,0.1,0.6c0.7,0.2,1.3,0.1,2,0.1c0.6,0,1.2,0,1.9-0.1
				c0.6-0.1,1.3-0.1,1.9-0.2c0.6-0.1,1.3,0,1.9-0.4c0-0.1,0.1-0.2,0.1-0.2c-0.1-1.4-0.1-2.8-0.2-4.2c0-0.1,0-0.2-0.1-0.2
				C364.9,398.5,364.9,398.4,364.8,398.3z"/>
                                <path className="st15" d="M393.5,397.8c0.2,1.2,0.3,3.3,0.3,4c-0.2,0-0.3,0.1-0.5,0.1c-1,0.1-2,0.1-3.1,0.1c-0.5,0-1,0-1.5,0
				c-0.3-0.7-0.1-1.4-0.2-2c0-0.7-0.1-1.4,0-2.1c0.1,0,0.3-0.1,0.4-0.1c1.3,0,2.6-0.1,3.9-0.1C393.1,397.8,393.3,397.8,393.5,397.8z
				 M392.7,399.6c0.1-0.6,0-0.8-0.3-0.9c-0.3-0.1-0.6,0-0.7,0.3c-0.1,0.2-0.1,0.5,0.1,0.7c0.1,0.1,0.3,0.2,0.4,0.1
				C392.4,399.8,392.6,399.7,392.7,399.6z M389.9,400.9c-0.2,0.1-0.4,0.1-0.5,0.2c-0.2,0.3-0.2,0.5,0.1,0.7c0.2,0.1,0.4,0.2,0.5,0
				c0.1-0.1,0.2-0.2,0.3-0.4C390.2,401.3,390.1,401.1,389.9,400.9z M390.2,399.6c0.2-0.3,0.1-0.6-0.1-0.8c-0.2-0.1-0.4-0.1-0.6,0.1
				C389.4,399.5,389.5,399.7,390.2,399.6z M391.4,401.1c0.1,0.5,0.2,0.7,0.4,0.7c0.2,0,0.3-0.1,0.4-0.2c0.1-0.2,0.1-0.4-0.1-0.5
				c-0.1-0.1-0.2-0.1-0.3-0.1C391.8,400.9,391.6,401,391.4,401.1z"/>
                                <path className="st15" d="M399.1,401.4c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.3,0.1-0.4,0.1c-1.1,0.1-2.3,0.2-3.4,0.2c-0.1,0-0.2,0-0.3,0
				c-0.3-0.4-0.1-0.9-0.2-1.3c-0.1-0.4-0.1-0.8-0.1-1.3c0-0.4-0.2-0.9,0-1.4c1.4-0.2,2.8-0.3,4.2-0.5c0.1,0,0.3,0,0.4,0
				C399.5,398.2,399.4,400.3,399.1,401.4z M398.4,400.3c-0.3-0.1-0.5-0.1-0.8,0.1c-0.2,0.2-0.2,0.4,0,0.6c0.3,0.2,0.6,0.2,0.8-0.1
				C398.7,400.8,398.7,400.5,398.4,400.3z M398.4,398.6c-0.6,0-0.8,0.1-0.9,0.4c0.1,0.1,0.1,0.2,0.2,0.3c0.5-0.1,0.5-0.1,0.6-0.2
				C398.4,399,398.4,398.8,398.4,398.6z M395.3,399.1c0.1,0.1,0.2,0.2,0.4,0.3c0.1-0.1,0.2-0.3,0.3-0.4
				C395.6,398.7,395.6,398.7,395.3,399.1z M395.7,401.1c0.2,0,0.3,0,0.5,0c0-0.2,0-0.3-0.1-0.5c-0.2,0-0.3,0-0.4,0.1
				c0,0.1-0.1,0.1-0.1,0.2C395.6,400.8,395.7,400.9,395.7,401.1z"/>
                                <path className="st15" d="M371.2,402.8c-0.1-1.7-0.1-3.4-0.1-5c0-0.1,0.1-0.2,0.1-0.3c0.1,0,0.1-0.1,0.2-0.1c3.2,0.2,6.5,0.5,9.7,0.7
				c0.1,0,0.1,0.1,0.2,0.1c0,0.1,0.1,0.3,0.1,0.5c0,1.3-0.1,2.5-0.1,3.8c-0.7,0.3-1.4,0.3-2,0.4c-0.6,0.1-1.3,0.2-1.9,0.2
				c-0.7,0-1.4,0-2,0c-0.7,0-1.3,0-2-0.1C372.6,403.1,372,403,371.2,402.8z M379.1,399.9c0.2-0.1,0.3-0.2,0.4-0.4
				c0.1-0.1,0-0.2,0-0.3c-0.3-0.4-0.6-0.4-0.9-0.2C378.5,399.5,378.5,399.5,379.1,399.9z M379.1,401.7c0-0.3-0.2-0.4-0.4-0.4
				c-0.3,0-0.5,0.2-0.5,0.5C378.6,402.3,378.8,402.2,379.1,401.7z M375.3,401.4c0,0.4,0,0.4,0.4,0.7c0.4-0.2,0.4-0.2,0.3-0.7
				C375.8,401.2,375.6,401.2,375.3,401.4z M376,398.8c-0.2,0-0.3,0-0.6,0.1c0,0.2,0,0.4,0,0.6c0.2,0,0.4,0.1,0.5,0.1
				C376.3,399.4,376.3,399.1,376,398.8z M372.8,401.9c0.1-0.1,0.2-0.2,0.2-0.4c0-0.2-0.1-0.4-0.5-0.4c-0.1,0.1-0.2,0.2-0.3,0.3
				C372.3,401.7,372.5,401.8,372.8,401.9z M372.6,398.8c0,0.2-0.1,0.3-0.1,0.5c0.2,0.3,0.4,0.3,0.7,0c0-0.1,0-0.3,0-0.5
				C373,398.8,372.8,398.8,372.6,398.8z"/>
                                <path className="st15" d="M364.8,398.3c0.1,0.1,0.1,0.2,0.1,0.2c0,0.1,0,0.2,0.1,0.2c0.1,1.4,0.1,2.8,0.2,4.2c0,0.1,0,0.1-0.1,0.2
				c-0.6,0.3-1.2,0.2-1.9,0.4c-0.6,0.1-1.3,0.2-1.9,0.2c-0.6,0.1-1.2,0.1-1.9,0.1c-0.6,0-1.3,0.1-2-0.1c0-0.2-0.1-0.4-0.1-0.6
				c0-0.6,0-1.3-0.1-2c0-0.5-0.1-1-0.1-1.5c0-0.2,0-0.4,0-0.6c0.7-0.2,1.3-0.1,1.9-0.1c0.6-0.1,1.2-0.1,1.9-0.1
				c0.6-0.1,1.3-0.2,1.9-0.2C363.5,398.5,364.1,398.3,364.8,398.3z M360,401.1c0.2-0.2,0.2-0.5,0.1-0.7c-0.1-0.1-0.2-0.3-0.4-0.3
				c-0.2-0.1-0.5-0.1-0.7-0.1c-0.1,0-0.2,0.2-0.3,0.2c0.1,0.4,0.2,0.7,0.6,0.9C359.4,401.3,359.7,401.2,360,401.1z M363.9,401.5
				c-0.4-0.1-0.7-0.1-1,0.1c-0.2,0.4-0.1,0.6,0.3,0.9c0.3,0.1,0.6,0.1,0.8-0.1C364.2,402,364.2,401.8,363.9,401.5z"/>
                                <path d="M392.7,399.6c-0.1,0.1-0.3,0.2-0.5,0.2c-0.1,0-0.3-0.1-0.4-0.1c-0.2-0.2-0.3-0.4-0.1-0.7c0.1-0.3,0.4-0.4,0.7-0.3
				C392.7,398.8,392.8,399,392.7,399.6z"/>
                                <path d="M389.9,400.9c0.2,0.2,0.3,0.4,0.4,0.5c-0.1,0.1-0.2,0.3-0.3,0.4c-0.2,0.1-0.4,0.1-0.5,0c-0.2-0.2-0.2-0.4-0.1-0.7
				C389.6,401,389.7,401,389.9,400.9z"/>
                                <path d="M390.2,399.6c-0.7,0.1-0.8,0-0.7-0.7c0.2-0.2,0.4-0.2,0.6-0.1C390.3,399,390.4,399.2,390.2,399.6z"/>
                                <path d="M391.4,401.1c0.2-0.1,0.3-0.2,0.5-0.2c0.1,0,0.2,0,0.3,0.1c0.2,0.1,0.2,0.4,0.1,0.5c-0.1,0.2-0.2,0.2-0.4,0.2
				C391.6,401.7,391.5,401.6,391.4,401.1z"/>
                                <path d="M398.4,400.3c0.2,0.2,0.2,0.5,0,0.7c-0.3,0.3-0.5,0.2-0.8,0.1c-0.2-0.2-0.2-0.5,0-0.6
				C397.9,400.2,398.2,400.1,398.4,400.3z"/>
                                <path d="M398.4,398.6c0,0.2,0,0.4,0,0.5c-0.1,0.2-0.1,0.2-0.6,0.2c-0.1-0.1-0.2-0.2-0.2-0.3C397.6,398.7,397.8,398.6,398.4,398.6
				z"/>
                                <path d="M395.3,399.1c0.3-0.4,0.3-0.4,0.7-0.1c-0.1,0.1-0.2,0.2-0.3,0.4C395.5,399.3,395.4,399.2,395.3,399.1z"/>
                                <path d="M395.7,401.1c0-0.2-0.1-0.3-0.1-0.3c0,0,0-0.1,0.1-0.2c0.1,0,0.2,0,0.4-0.1c0,0.2,0,0.3,0.1,0.5
				C396,401.1,395.9,401.1,395.7,401.1z"/>
                                <path d="M379.1,399.9c-0.5-0.4-0.5-0.4-0.4-0.9c0.3-0.2,0.6-0.2,0.9,0.2c0,0.1,0,0.2,0,0.3C379.4,399.7,379.2,399.8,379.1,399.9z
				"/>
                                <path d="M379.1,401.7c-0.3,0.6-0.5,0.6-0.9,0c0-0.3,0.2-0.5,0.5-0.5C378.9,401.3,379.1,401.4,379.1,401.7z"/>
                                <path d="M375.3,401.4c0.2-0.2,0.5-0.2,0.7,0c0.1,0.5,0.1,0.5-0.3,0.7C375.3,401.9,375.3,401.9,375.3,401.4z"/>
                                <path d="M376,398.8c0.2,0.4,0.2,0.6-0.1,0.8c-0.1,0-0.3-0.1-0.5-0.1c0-0.2,0-0.4,0-0.6C375.7,398.8,375.9,398.8,376,398.8z"/>
                                <path d="M372.8,401.9c-0.4,0-0.5-0.2-0.5-0.5c0.1-0.1,0.2-0.2,0.3-0.3c0.3,0,0.5,0.2,0.5,0.4C373,401.6,372.9,401.7,372.8,401.9z
				"/>
                                <path d="M372.6,398.8c0.2,0,0.4,0,0.6,0c0,0.2,0,0.4,0,0.5c-0.3,0.2-0.5,0.2-0.7,0C372.6,399.1,372.6,399,372.6,398.8z"/>
                                <path d="M360,401.1c-0.3,0.2-0.5,0.2-0.8,0.1c-0.3-0.2-0.5-0.5-0.6-0.9c0.1-0.1,0.2-0.2,0.3-0.2c0.2,0,0.5,0,0.7,0.1
				c0.1,0,0.3,0.2,0.4,0.3C360.1,400.6,360.1,400.8,360,401.1z"/>
                                <path d="M363.9,401.5c0.3,0.3,0.3,0.5,0.1,0.8c-0.3,0.2-0.5,0.2-0.8,0.1c-0.3-0.2-0.5-0.5-0.3-0.9
				C363.2,401.4,363.5,401.4,363.9,401.5z"/>
		</g>
                            <g>
			<path d="M405.8,515.1c0.6,0.2,1.1,0.1,1.6,0c1-0.2,2.1-0.4,3.1-0.2c0.1,0,0.2,0,0.3,0c1.1,0,2,0.4,3,0.7c0.8,0.2,1.4,0.8,2,1.4
				c0.9,1.1,1.5,2.4,1.5,3.9c0.3,0.5,0.7,0.9,0.8,1.5c0,0.1-0.1,0.2-0.1,0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.1,0.2-0.3,0.5-0.4,0.7
				c-0.2,0.1-0.3,0-0.4-0.2c0-0.2-0.1-0.4-0.1-0.6c-0.3,0.1-0.5,0.2-0.7,0.3c-0.3-0.1-0.3-0.3-0.2-0.4c0.1-0.2,0.2-0.4,0.4-0.6
				c0.2-0.2,0.3-0.5,0.5-0.7c0.1-1.3-0.5-2.3-1.3-3.3c-0.7-0.9-1.4-1.4-2.4-1.5c0,0.1,0,0.2,0,0.3c0.1,0.2,0.2,0.5,0.3,0.7
				c0.4,0.8,0.6,1.6,0.6,2.5c0,0.8,0,1.5-0.2,2.3c-0.3,1-0.4,1.9-0.5,2.9c-0.1,0.7-0.4,0.9-1.1,0.9c-0.6,0-1.2-0.3-1.4-0.9
				c-0.1-0.3-0.2-0.5-0.3-0.8c-0.2-0.6-0.7-0.9-1.3-1.1c-1-0.3-2-0.1-2.9,0.3c-0.4,0.2-0.6,0.5-0.6,0.9c-0.1,0.5-0.1,1-0.2,1.4
				c-0.2,0.8-0.5,1.1-1.3,1.1c-0.5,0-1,0-1.4,0c-0.6,0-1-0.3-1.2-0.9c-0.2-0.5-0.2-0.9-0.4-1.4c-0.2-0.2-0.5-0.2-0.8-0.1
				c0,0.1-0.1,0.3-0.1,0.5c-0.1,0.4-0.2,0.8-0.4,1.1c-0.2,0.6-0.7,0.9-1.3,0.9c-0.2,0-0.5,0-0.7,0c-0.8-0.1-1.4-0.6-1.5-1.5
				c0-0.4,0-0.9,0.1-1.3c0-0.3,0.2-0.5,0-0.9c-1.5-0.8-2-2.1-1.9-3.9c-0.2,0-0.4-0.1-0.5-0.1c-0.4,0-0.9,0-1.3,0
				c-0.7,0-1.3-0.4-1.5-1c-0.2-0.4-0.3-0.8-0.4-1.3c-0.1-0.7,0.1-1.3,0.7-1.8c0.5-0.4,1-0.8,1.6-1.2c0.1-0.1,0.2-0.1,0.3-0.3
				c-0.1-0.3-0.1-0.5-0.2-0.8c-0.1-0.2-0.1-0.5-0.2-0.7c0-0.5,0.1-1,0.7-1.3c0.3,0,0.7,0,1.1,0c0.3,0.2,0.7,0.4,0.9,0.5
				c0.4,0,0.6-0.2,0.7-0.4c0.3-0.4,0.6-0.7,0.8-1.1c0.7-1.2,1.7-1.8,3-1.9c0.3,0,0.6,0,0.9,0.1c0.8,0.2,1.1,0.6,1.2,1.4
				c0,0.3,0,0.6,0,0.8c0,0.2,0,0.4,0,0.6c0.2,0.1,0.3,0.1,0.5,0.1c0.4,0,0.9,0,1.3,0c1.5-0.1,2.7,1.5,2.1,3.1
				c-0.1,0.2-0.2,0.4-0.3,0.6C405.8,514.8,405.8,515,405.8,515.1z M399,522.3c0.1,0.2,0.2,0.3,0.4,0.5c0.5,0.8,1.4,1,2.3,1
				c0.5,0,0.8-0.3,1-0.7c0.1-0.3,0.2-0.5,0.2-0.8c0.2-0.7,0.6-1.2,1.4-1.3c0.5-0.1,1-0.3,1.5-0.6c0.9-0.6,1.6-2.1,0.4-3.4
				c-0.2-0.2-0.4-0.5-0.7-0.7c-0.5-0.5-0.5-1-0.3-1.6c0.1-0.3,0.2-0.5,0.3-0.8c0.4-1.1-0.2-2.1-1.4-2.1c-0.5,0-1,0-1.5,0
				c-0.4,0-0.7-0.2-0.9-0.6c0-0.2,0-0.5,0-0.7c0-0.3,0-0.6,0-0.9c0-0.4-0.3-0.7-0.7-0.8c-0.3-0.1-0.6-0.1-0.8,0
				c-1,0.2-1.8,0.7-2.4,1.6c-0.2,0.4-0.5,0.7-0.7,1c-0.3,0.3-0.5,0.7-1,0.7c-0.5,0.1-0.8-0.1-1.2-0.4c-0.3-0.2-0.7-0.3-1,0
				c-0.2,0.4-0.1,0.7,0,1.1c0.1,0.3,0.2,0.6,0.3,0.9c0,0.2,0,0.3,0,0.4c-0.5,0.4-1,0.7-1.4,1.1c-0.4,0.3-1,0.6-1.2,1.2
				c-0.1,0.6,0.1,1.1,0.3,1.6c0.2,0.4,0.5,0.6,1,0.7c0.4,0,0.7,0,1.1,0c0.3,0,0.6,0.1,0.8,0.1c0.3,0.1,0.5,0.3,0.5,0.6
				c-0.3,1.6,0.1,2.5,1.3,3.2C397.3,522.5,398.1,521.9,399,522.3z M401.8,524.6c-0.1,0.3,0,0.6,0.1,0.9c0.2,0.4,0.4,0.6,0.8,0.6
				c0.5,0,1,0.1,1.4-0.1c0.3-0.2,0.4-0.4,0.4-0.7c0.1-0.4,0.2-0.9,0.2-1.3c0.1-0.7,0.5-1.1,1.1-1.4c1-0.5,2.1-0.6,3.2-0.4
				c0.9,0.1,1.6,0.6,2,1.5c0.1,0.3,0.3,0.6,0.4,0.9c0,0.1,0.1,0.1,0.1,0.1c0.3,0.1,0.6,0.2,0.8,0c0-0.1,0.1-0.3,0.2-0.4
				c0.1-0.2,0.1-0.5,0.1-0.8c0.1-0.4,0.1-0.8,0.2-1.3c0.2-0.8,0.3-1.7,0.3-2.5c0-1.2-0.5-2.3-1.1-3.2c-0.4-0.6-0.9-0.9-1.6-1
				c-0.9-0.2-1.8,0-2.6,0.1c-0.5,0.1-0.9,0.2-1.5,0.3c0.2,0.2,0.3,0.4,0.4,0.6c1.1,1.4,0.6,3.1-0.4,4c-0.4,0.4-0.9,0.6-1.4,0.8
				c-0.4,0.1-0.8,0.2-1.1,0.3c-0.3,0.3-0.4,0.6-0.5,0.9C403.3,523.6,402.8,524.2,401.8,524.6C401.9,524.5,401.9,524.5,401.8,524.6z
				 M397.1,523.4c0,0.8-0.5,1.6,0,2.4c0.7,0.4,0.7,0.4,1.5,0.2c0.4-0.5,0.7-1.2,0.7-2.1c-0.3-0.3-0.5-0.7-0.8-1
				C397.9,522.8,397.5,522.9,397.1,523.4z"/>
                                <path className="st18" d="M399,522.3c-0.9-0.4-1.7,0.2-2.5,0.2c-1.2-0.7-1.6-1.6-1.3-3.2c0-0.3-0.2-0.5-0.5-0.6
				c-0.3-0.1-0.6-0.1-0.8-0.1c-0.4,0-0.7,0-1.1,0c-0.5,0-0.8-0.2-1-0.7c-0.2-0.5-0.5-1-0.3-1.6c0.2-0.6,0.8-0.8,1.2-1.2
				c0.4-0.3,0.9-0.7,1.4-1.1c0-0.1,0-0.2,0-0.4c-0.1-0.3-0.2-0.6-0.3-0.9c-0.1-0.4-0.2-0.7,0-1.1c0.4-0.3,0.7-0.2,1,0
				c0.4,0.2,0.7,0.5,1.2,0.4c0.5-0.1,0.7-0.4,1-0.7c0.3-0.3,0.5-0.7,0.7-1c0.5-0.9,1.3-1.4,2.4-1.6c0.3,0,0.6,0,0.8,0
				c0.4,0.1,0.7,0.4,0.7,0.8c0,0.3,0,0.6,0,0.9c0,0.3,0,0.5,0,0.7c0.2,0.4,0.5,0.6,0.9,0.6c0.5,0,1,0,1.5,0c1.2,0,1.8,1,1.4,2.1
				c-0.1,0.3-0.2,0.5-0.3,0.8c-0.2,0.6-0.1,1.2,0.3,1.6c0.2,0.2,0.4,0.4,0.7,0.7c1.2,1.3,0.5,2.8-0.4,3.4c-0.5,0.3-1,0.5-1.5,0.6
				c-0.8,0.1-1.2,0.6-1.4,1.3c-0.1,0.3-0.1,0.5-0.2,0.8c-0.2,0.4-0.5,0.7-1,0.7c-0.9,0-1.7-0.2-2.3-1
				C399.2,522.6,399.1,522.4,399,522.3z M396.4,514.9c-0.1,0.3-0.2,0.6-0.2,0.9c-0.3,1.6,0.2,2.9,1.4,3.9c1.4,1.2,3.8,0.8,5-0.3
				c0.2-0.2,0.4-0.5,0.5-0.7c0.3-0.7,0.6-1.5,0.5-2.3c-0.1-0.7,0-1.4,0.4-2.1c0,0,0-0.1,0-0.1c0.2-0.7,0-1.2-0.7-1.4
				c-0.8-0.3-1.6-0.3-2.4,0.1c-0.2,0.1-0.4,0.3-0.7,0.4c-0.6,0.3-1.1,0.3-1.6-0.2c-0.2-0.2-0.5-0.4-0.7-0.6
				c-0.6-0.4-1.3-0.1-1.6,0.5C396.3,513.7,396.3,514.3,396.4,514.9z"/>
                                <path className="st14" d="M401.8,524.6C401.9,524.5,401.9,524.5,401.8,524.6c1-0.3,1.5-1,1.7-1.9c0.1-0.3,0.2-0.7,0.5-0.9
				c0.3-0.1,0.7-0.2,1.1-0.3c0.5-0.2,1-0.4,1.4-0.8c1-0.8,1.5-2.6,0.4-4c-0.1-0.2-0.3-0.3-0.4-0.6c0.5-0.1,1-0.3,1.5-0.3
				c0.9-0.1,1.7-0.3,2.6-0.1c0.7,0.1,1.2,0.4,1.6,1c0.7,1,1.1,2,1.1,3.2c0,0.9-0.2,1.7-0.3,2.5c-0.1,0.4-0.1,0.8-0.2,1.3
				c0,0.3-0.1,0.5-0.1,0.8c0,0.2-0.1,0.3-0.2,0.4c-0.3,0.2-0.5,0.1-0.8,0c0,0-0.1-0.1-0.1-0.1c-0.1-0.3-0.3-0.6-0.4-0.9
				c-0.4-0.9-1.1-1.4-2-1.5c-1.1-0.2-2.2-0.1-3.2,0.4c-0.6,0.3-1,0.7-1.1,1.4c0,0.4-0.2,0.9-0.2,1.3c0,0.3-0.1,0.5-0.4,0.7
				c-0.4,0.2-0.9,0.1-1.4,0.1c-0.4,0-0.7-0.2-0.8-0.6C401.8,525.2,401.7,524.9,401.8,524.6z"/>
                                <path className="st14" d="M397.1,523.4c0.4-0.5,0.8-0.5,1.3-0.5c0.3,0.3,0.6,0.7,0.8,1c0,0.9-0.2,1.6-0.7,2.1
				c-0.8,0.2-0.8,0.2-1.5-0.2C396.6,525,397.1,524.2,397.1,523.4z"/>
                                <path d="M396.4,514.9c-0.1-0.6-0.1-1.2,0.1-1.7c0.3-0.7,0.9-0.9,1.6-0.5c0.3,0.2,0.5,0.4,0.7,0.6c0.5,0.4,1,0.5,1.6,0.2
				c0.2-0.1,0.4-0.3,0.7-0.4c0.8-0.5,1.6-0.4,2.4-0.1c0.7,0.2,0.9,0.8,0.7,1.4c0,0,0,0.1,0,0.1c-0.3,0.7-0.4,1.3-0.4,2.1
				c0.1,0.8-0.2,1.6-0.5,2.3c-0.1,0.3-0.3,0.5-0.5,0.7c-1.2,1.1-3.6,1.5-5,0.3c-1.3-1-1.7-2.3-1.4-3.9
				C396.3,515.5,396.4,515.2,396.4,514.9z M397.1,514.9c0,0.1-0.1,0.2-0.1,0.3c-0.3,0.9-0.3,1.7,0.1,2.6c0.7,1.7,3,2.7,5,1.1
				c0.2-0.2,0.4-0.4,0.5-0.7c0.3-0.6,0.5-1.2,0.4-1.9c0-0.8,0-1.5,0.4-2.2c0-0.1,0-0.2,0-0.2c-0.1-0.2-0.3-0.3-0.5-0.4
				c-0.5-0.1-1.1-0.1-1.5,0.3c-0.4,0.3-0.8,0.4-1.2,0.6c-0.5,0.2-0.9,0.1-1.4-0.2c-0.3-0.2-0.6-0.5-0.9-0.7
				c-0.1-0.1-0.3-0.1-0.4-0.2C396.9,513.7,397.1,514.3,397.1,514.9z"/>
                                <path className="st14" d="M397.1,514.9c0-0.6-0.2-1.2,0.4-1.7c0.1,0.1,0.3,0.1,0.4,0.2c0.3,0.2,0.6,0.5,0.9,0.7
				c0.4,0.3,0.9,0.4,1.4,0.2c0.4-0.2,0.9-0.3,1.2-0.6c0.5-0.3,1-0.4,1.5-0.3c0.2,0,0.4,0.1,0.5,0.4c0,0.1,0,0.2,0,0.2
				c-0.3,0.7-0.4,1.5-0.4,2.2c0,0.7-0.1,1.3-0.4,1.9c-0.1,0.2-0.3,0.5-0.5,0.7c-2,1.6-4.2,0.6-5-1.1c-0.4-0.8-0.4-1.7-0.1-2.6
				C397,515.1,397.1,515,397.1,514.9z M399.6,518.4c0.2-0.3,0.6-0.5,0.7-0.9c-0.4-0.4-0.5-0.3-1.1,0.2
				C399,518,399.1,518.2,399.6,518.4z M399,516.6c0-0.2-0.1-0.4-0.1-0.6c-0.2,0-0.3,0-0.4,0c-0.2,0.4-0.2,0.4-0.1,0.6
				c0,0,0.1,0.1,0.2,0.1C398.7,516.8,398.8,516.7,399,516.6z M400.3,516.5c0.4,0.3,0.7,0.3,0.9-0.1c-0.1-0.4-0.4-0.5-0.7-0.3
				C400.5,516.2,400.4,516.3,400.3,516.5z"/>
                                <path d="M399.6,518.4c-0.4-0.2-0.5-0.4-0.5-0.7c0.7-0.5,0.7-0.5,1.1-0.2C400.2,517.9,399.7,518.1,399.6,518.4z"/>
                                <path d="M399,516.6c-0.2,0.1-0.3,0.1-0.4,0.2c-0.1-0.1-0.2-0.1-0.2-0.1c-0.1-0.3-0.1-0.3,0.1-0.6c0.1,0,0.2,0,0.4,0
				C399,516.3,399,516.4,399,516.6z"/>
                                <path d="M400.3,516.5c0.1-0.2,0.1-0.3,0.2-0.4c0.4-0.1,0.6,0,0.7,0.3C401,516.8,400.7,516.8,400.3,516.5z"/>
		</g>
	</g>
                        <g className="navidad">
		<path className="st21" d="M689.3,122.2c0.1-0.7,0.8-0.7,1.4-0.7c1.3,0,1.5,0.6,1.4,1.6c-0.9-0.2-1.9-0.2-2.9,0
			C689.3,122.7,689.3,122.4,689.3,122.2z"/>
                            <path className="st21" d="M694.6,126.5c-1.6-2.2-4.9-3.1-7-1c-1.3,1.3-2,3.8-1,5.5c1.3,2.4,5.2,2.7,7.4,1.4
			C696.4,131.1,695.9,128.6,694.6,126.5z M689.8,130.7c-0.1,0.1-0.3,0.1-0.6,0.1c-0.2,0-0.6,0-0.9-0.2c-0.2-0.1-0.3-0.2-0.4-0.4
			c-0.1-0.1-0.2-0.3-0.3-0.5c-0.1-0.2-0.1-0.3-0.1-0.5c0-0.2,0-0.3,0-0.5c0.1-0.3,0.1-0.6,0.3-0.8c0.1-0.2,0.3-0.4,0.4-0.4
			c0.1-0.1,0.2-0.1,0.2-0.1s0,0.1,0.1,0.2c0,0.1,0.1,0.3,0.1,0.5c0.1,0.4,0.2,0.9,0.3,1.2c0,0.1,0.1,0.2,0.1,0.2
			c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.1,0.2,0.1
			S689.9,130.6,689.8,130.7z"/>
                            <path d="M696.1,125.9c0-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.1c-0.6-0.8-1.4-1.5-2.3-2c0.1-0.7,0.2-1.5,0-2.2
			c-0.3-1-1.2-1.4-2.1-1.5c0-5.3,0-10.6,0-15.8c0-0.7-1-0.7-1,0c0,5.3,0,10.5,0,15.8c-0.9,0-1.9,0.4-2.4,1.2
			c-0.5,0.7-0.4,1.7-0.4,2.6c-0.1,0.1-0.3,0.1-0.4,0.2c-2.4,1.5-3.3,5.2-2.1,7.7c1.5,3.3,6.2,3.9,9.3,2.5
			C697.8,132.4,697.9,128.8,696.1,125.9z M689.3,122.2c0.1-0.7,0.8-0.7,1.4-0.7c1.3,0,1.5,0.6,1.4,1.6c-0.9-0.2-1.9-0.2-2.9,0
			C689.3,122.7,689.3,122.4,689.3,122.2z M694,132.5c-2.2,1.3-6,0.9-7.4-1.4c-1-1.7-0.3-4.2,1-5.5c2.1-2.1,5.4-1.2,7,1
			C695.9,128.6,696.4,131.1,694,132.5z"/>
	</g>
                        <g className="navidad">
		<path className="st21" d="M360.3,160.2c0.1-0.7,0.8-0.7,1.4-0.7c1.3,0,1.5,0.6,1.4,1.6c-0.9-0.2-1.9-0.2-2.9,0
			C360.3,160.7,360.3,160.4,360.3,160.2z"/>
                            <path className="st21" d="M365.6,164.5c-1.6-2.2-4.9-3.1-7-1c-1.3,1.3-2,3.8-1,5.5c1.3,2.4,5.2,2.7,7.4,1.4
			C367.4,169.1,366.9,166.6,365.6,164.5z M360.8,168.7c-0.1,0.1-0.3,0.1-0.6,0.1c-0.2,0-0.6,0-0.9-0.2c-0.2-0.1-0.3-0.2-0.4-0.4
			c-0.1-0.1-0.2-0.3-0.3-0.5c-0.1-0.2-0.1-0.3-0.1-0.5c0-0.2,0-0.3,0-0.5c0.1-0.3,0.1-0.6,0.3-0.8c0.1-0.2,0.3-0.4,0.4-0.4
			c0.1-0.1,0.2-0.1,0.2-0.1s0,0.1,0.1,0.2c0,0.1,0.1,0.3,0.1,0.5c0.1,0.4,0.2,0.9,0.3,1.2c0,0.1,0.1,0.2,0.1,0.2
			c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.1,0.2,0.1
			S360.9,168.6,360.8,168.7z"/>
                            <path d="M367.1,163.9c0-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.1c-0.6-0.8-1.4-1.5-2.3-2c0.1-0.7,0.2-1.5,0-2.2
			c-0.3-1-1.2-1.4-2.1-1.5c0-5.3,0-10.6,0-15.8c0-0.7-1-0.7-1,0c0,5.3,0,10.5,0,15.8c-0.9,0-1.9,0.4-2.4,1.2
			c-0.5,0.7-0.4,1.7-0.4,2.6c-0.1,0.1-0.3,0.1-0.4,0.2c-2.4,1.5-3.3,5.2-2.1,7.7c1.5,3.3,6.2,3.9,9.3,2.5
			C368.8,170.4,368.9,166.8,367.1,163.9z M360.3,160.2c0.1-0.7,0.8-0.7,1.4-0.7c1.3,0,1.5,0.6,1.4,1.6c-0.9-0.2-1.9-0.2-2.9,0
			C360.3,160.7,360.3,160.4,360.3,160.2z M365,170.5c-2.2,1.3-6,0.9-7.4-1.4c-1-1.7-0.3-4.2,1-5.5c2.1-2.1,5.4-1.2,7,1
			C366.9,166.6,367.4,169.1,365,170.5z"/>
	</g>
                        <g className="navidad">
		<path className="st32" d="M640.1,104.3c0.1-0.7,0.8-0.7,1.4-0.7c1.3,0,1.5,0.6,1.4,1.6c-0.9-0.2-1.9-0.2-2.9,0
			C640,104.8,640,104.5,640.1,104.3z"/>
                            <path className="st32" d="M645.4,108.6c-1.6-2.2-4.9-3.1-7-1c-1.3,1.3-2,3.8-1,5.5c1.3,2.4,5.2,2.7,7.4,1.4
			C647.1,113.2,646.7,110.7,645.4,108.6z M640.5,112.8c-0.1,0.1-0.3,0.1-0.6,0.1c-0.2,0-0.6,0-0.9-0.2c-0.2-0.1-0.3-0.2-0.4-0.4
			c-0.1-0.1-0.2-0.3-0.3-0.5c-0.1-0.2-0.1-0.3-0.1-0.5c0-0.2,0-0.3,0-0.5c0.1-0.3,0.1-0.6,0.3-0.8c0.1-0.2,0.3-0.4,0.4-0.4
			c0.1-0.1,0.2-0.1,0.2-0.1s0,0.1,0.1,0.2c0,0.1,0.1,0.3,0.1,0.5c0.1,0.4,0.2,0.9,0.3,1.2c0,0.1,0.1,0.2,0.1,0.2
			c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.1,0.2,0.1
			S640.6,112.7,640.5,112.8z"/>
                            <path d="M646.8,108c0-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.1c-0.6-0.8-1.4-1.5-2.3-2c0.1-0.7,0.2-1.5,0-2.2
			c-0.3-1-1.2-1.4-2.1-1.5c0-5.3,0-10.6,0-15.8c0-0.7-1-0.7-1,0c0,5.3,0,10.5,0,15.8c-0.9,0-1.9,0.4-2.4,1.2
			c-0.5,0.7-0.4,1.7-0.4,2.6c-0.1,0.1-0.3,0.1-0.4,0.2c-2.4,1.5-3.3,5.2-2.1,7.7c1.5,3.3,6.2,3.9,9.3,2.5
			C648.5,114.5,648.6,110.9,646.8,108z M640.1,104.3c0.1-0.7,0.8-0.7,1.4-0.7c1.3,0,1.5,0.6,1.4,1.6c-0.9-0.2-1.9-0.2-2.9,0
			C640,104.8,640,104.5,640.1,104.3z M644.7,114.6c-2.2,1.3-6,0.9-7.4-1.4c-1-1.7-0.3-4.2,1-5.5c2.1-2.1,5.4-1.2,7,1
			C646.7,110.7,647.1,113.2,644.7,114.6z"/>
	</g>
                        <g className="navidad">
		<path className="st32" d="M304.1,189.3c0.1-0.7,0.8-0.7,1.4-0.7c1.3,0,1.5,0.6,1.4,1.6c-0.9-0.2-1.9-0.2-2.9,0
			C304,189.8,304,189.5,304.1,189.3z"/>
                            <path className="st32" d="M309.4,193.6c-1.6-2.2-4.9-3.1-7-1c-1.3,1.3-2,3.8-1,5.5c1.3,2.4,5.2,2.7,7.4,1.4
			C311.1,198.2,310.7,195.7,309.4,193.6z M304.5,197.8c-0.1,0.1-0.3,0.1-0.6,0.1c-0.2,0-0.6,0-0.9-0.2c-0.2-0.1-0.3-0.2-0.4-0.4
			c-0.1-0.1-0.2-0.3-0.3-0.5c-0.1-0.2-0.1-0.3-0.1-0.5c0-0.2,0-0.3,0-0.5c0.1-0.3,0.1-0.6,0.3-0.8c0.1-0.2,0.3-0.4,0.4-0.4
			c0.1-0.1,0.2-0.1,0.2-0.1s0,0.1,0.1,0.2c0,0.1,0.1,0.3,0.1,0.5c0.1,0.4,0.2,0.9,0.3,1.2c0,0.1,0.1,0.2,0.1,0.2
			c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.1,0.2,0.1
			S304.6,197.7,304.5,197.8z"/>
                            <path d="M310.8,193c0-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.1c-0.6-0.8-1.4-1.5-2.3-2c0.1-0.7,0.2-1.5,0-2.2
			c-0.3-1-1.2-1.4-2.1-1.5c0-5.3,0-10.6,0-15.8c0-0.7-1-0.7-1,0c0,5.3,0,10.5,0,15.8c-0.9,0-1.9,0.4-2.4,1.2
			c-0.5,0.7-0.4,1.7-0.4,2.6c-0.1,0.1-0.3,0.1-0.4,0.2c-2.4,1.5-3.3,5.2-2.1,7.7c1.5,3.3,6.2,3.9,9.3,2.5
			C312.5,199.5,312.6,195.9,310.8,193z M304.1,189.3c0.1-0.7,0.8-0.7,1.4-0.7c1.3,0,1.5,0.6,1.4,1.6c-0.9-0.2-1.9-0.2-2.9,0
			C304,189.8,304,189.5,304.1,189.3z M308.7,199.6c-2.2,1.3-6,0.9-7.4-1.4c-1-1.7-0.3-4.2,1-5.5c2.1-2.1,5.4-1.2,7,1
			C310.7,195.7,311.1,198.2,308.7,199.6z"/>
	</g>
                        <g className="navidad">
		<path className="st14" d="M574.4,75.2c0.1-0.7,0.8-0.7,1.4-0.7c1.3,0,1.5,0.6,1.4,1.6c-0.9-0.2-1.9-0.2-2.9,0
			C574.4,75.7,574.4,75.4,574.4,75.2z"/>
                            <path className="st14" d="M579.7,79.5c-1.6-2.2-4.9-3.1-7-1c-1.3,1.3-2,3.8-1,5.5c1.3,2.4,5.2,2.7,7.4,1.4
			C581.5,84.1,581,81.6,579.7,79.5z M574.9,83.7c-0.1,0.1-0.3,0.1-0.6,0.1c-0.2,0-0.6,0-0.9-0.2c-0.2-0.1-0.3-0.2-0.4-0.4
			c-0.1-0.1-0.2-0.3-0.3-0.5c-0.1-0.2-0.1-0.3-0.1-0.5c0-0.2,0-0.3,0-0.5c0.1-0.3,0.1-0.6,0.3-0.8c0.1-0.2,0.3-0.4,0.4-0.4
			c0.1-0.1,0.2-0.1,0.2-0.1s0,0.1,0.1,0.2c0,0.1,0.1,0.3,0.1,0.5c0.1,0.4,0.2,0.9,0.3,1.2c0,0.1,0.1,0.2,0.1,0.2
			c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.1,0.2,0.1
			S575,83.6,574.9,83.7z"/>
                            <path d="M581.2,78.9c0-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.1c-0.6-0.8-1.4-1.5-2.3-2c0.1-0.7,0.2-1.5,0-2.2
			c-0.3-1-1.2-1.4-2.1-1.5c0-5.3,0-10.6,0-15.8c0-0.7-1-0.7-1,0c0,5.3,0,10.5,0,15.8c-0.9,0-1.9,0.4-2.4,1.2
			c-0.5,0.7-0.4,1.7-0.4,2.6c-0.1,0.1-0.3,0.1-0.4,0.2c-2.4,1.5-3.3,5.2-2.1,7.7c1.5,3.3,6.2,3.9,9.3,2.5
			C582.9,85.4,583,81.8,581.2,78.9z M574.4,75.2c0.1-0.7,0.8-0.7,1.4-0.7c1.3,0,1.5,0.6,1.4,1.6c-0.9-0.2-1.9-0.2-2.9,0
			C574.4,75.7,574.4,75.4,574.4,75.2z M579.1,85.5c-2.2,1.3-6,0.9-7.4-1.4c-1-1.7-0.3-4.2,1-5.5c2.1-2.1,5.4-1.2,7,1
			C581,81.6,581.5,84.1,579.1,85.5z"/>
	</g>
                        <g className="navidad">
		<path className="st14" d="M260.4,213.2c0.1-0.7,0.8-0.7,1.4-0.7c1.3,0,1.5,0.6,1.4,1.6c-0.9-0.2-1.9-0.2-2.9,0
			C260.4,213.7,260.4,213.4,260.4,213.2z"/>
                            <path className="st14" d="M265.7,217.5c-1.6-2.2-4.9-3.1-7-1c-1.3,1.3-2,3.8-1,5.5c1.3,2.4,5.2,2.7,7.4,1.4
			C267.5,222.1,267,219.6,265.7,217.5z M260.9,221.7c-0.1,0.1-0.3,0.1-0.6,0.1c-0.2,0-0.6,0-0.9-0.2c-0.2-0.1-0.3-0.2-0.4-0.4
			c-0.1-0.1-0.2-0.3-0.3-0.5c-0.1-0.2-0.1-0.3-0.1-0.5c0-0.2,0-0.3,0-0.5c0.1-0.3,0.1-0.6,0.3-0.8c0.1-0.2,0.3-0.4,0.4-0.4
			c0.1-0.1,0.2-0.1,0.2-0.1s0,0.1,0.1,0.2c0,0.1,0.1,0.3,0.1,0.5c0.1,0.4,0.2,0.9,0.3,1.2c0,0.1,0.1,0.2,0.1,0.2
			c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.1,0.2,0.1
			S261,221.6,260.9,221.7z"/>
                            <path d="M267.2,216.9c0-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.1c-0.6-0.8-1.4-1.5-2.3-2c0.1-0.7,0.2-1.5,0-2.2
			c-0.3-1-1.2-1.4-2.1-1.5c0-5.3,0-10.6,0-15.8c0-0.7-1-0.7-1,0c0,5.3,0,10.5,0,15.8c-0.9,0-1.9,0.4-2.4,1.2
			c-0.5,0.7-0.4,1.7-0.4,2.6c-0.1,0.1-0.3,0.1-0.4,0.2c-2.4,1.5-3.3,5.2-2.1,7.7c1.5,3.3,6.2,3.9,9.3,2.5
			C268.9,223.4,269,219.8,267.2,216.9z M260.4,213.2c0.1-0.7,0.8-0.7,1.4-0.7c1.3,0,1.5,0.6,1.4,1.6c-0.9-0.2-1.9-0.2-2.9,0
			C260.4,213.7,260.4,213.4,260.4,213.2z M265.1,223.5c-2.2,1.3-6,0.9-7.4-1.4c-1-1.7-0.3-4.2,1-5.5c2.1-2.1,5.4-1.2,7,1
			C267,219.6,267.5,222.1,265.1,223.5z"/>
	</g>
                        <g className="navidad">
		<path className="st13" d="M514.8,65.2c0.1-0.7,0.8-0.7,1.4-0.7c1.3,0,1.5,0.6,1.4,1.6c-0.9-0.2-1.9-0.2-2.9,0
			C514.7,65.7,514.7,65.4,514.8,65.2z"/>
                            <path className="st13" d="M520.1,69.5c-1.6-2.2-4.9-3.1-7-1c-1.3,1.3-2,3.8-1,5.5c1.3,2.4,5.2,2.7,7.4,1.4
			C521.8,74.1,521.4,71.6,520.1,69.5z M515.2,73.7c-0.1,0.1-0.3,0.1-0.6,0.1c-0.2,0-0.6,0-0.9-0.2c-0.2-0.1-0.3-0.2-0.4-0.4
			c-0.1-0.1-0.2-0.3-0.3-0.5c-0.1-0.2-0.1-0.3-0.1-0.5c0-0.2,0-0.3,0-0.5c0.1-0.3,0.1-0.6,0.3-0.8c0.1-0.2,0.3-0.4,0.4-0.4
			c0.1-0.1,0.2-0.1,0.2-0.1s0,0.1,0.1,0.2c0,0.1,0.1,0.3,0.1,0.5c0.1,0.4,0.2,0.9,0.3,1.2c0,0.1,0.1,0.2,0.1,0.2
			c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.1,0.2,0.1
			S515.4,73.6,515.2,73.7z"/>
                            <path d="M521.6,68.9c0-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.1c-0.6-0.8-1.4-1.5-2.3-2c0.1-0.7,0.2-1.5,0-2.2
			c-0.3-1-1.2-1.4-2.1-1.5c0-5.3,0-10.6,0-15.8c0-0.7-1-0.7-1,0c0,5.3,0,10.5,0,15.8c-0.9,0-1.9,0.4-2.4,1.2
			c-0.5,0.7-0.4,1.7-0.4,2.6c-0.1,0.1-0.3,0.1-0.4,0.2c-2.4,1.5-3.3,5.2-2.1,7.7c1.5,3.3,6.2,3.9,9.3,2.5
			C523.3,75.4,523.3,71.8,521.6,68.9z M514.8,65.2c0.1-0.7,0.8-0.7,1.4-0.7c1.3,0,1.5,0.6,1.4,1.6c-0.9-0.2-1.9-0.2-2.9,0
			C514.7,65.7,514.7,65.4,514.8,65.2z M519.4,75.5c-2.2,1.3-6,0.9-7.4-1.4c-1-1.7-0.3-4.2,1-5.5c2.1-2.1,5.4-1.2,7,1
			C521.4,71.6,521.8,74.1,519.4,75.5z"/>
	</g>
                        <g className="navidad">
		<path className="st13" d="M198.8,233.2c0.1-0.7,0.8-0.7,1.4-0.7c1.3,0,1.5,0.6,1.4,1.6c-0.9-0.2-1.9-0.2-2.9,0
			C198.7,233.7,198.7,233.4,198.8,233.2z"/>
                            <path className="st13" d="M204.1,237.5c-1.6-2.2-4.9-3.1-7-1c-1.3,1.3-2,3.8-1,5.5c1.3,2.4,5.2,2.7,7.4,1.4
			C205.8,242.1,205.4,239.6,204.1,237.5z M199.2,241.7c-0.1,0.1-0.3,0.1-0.6,0.1c-0.2,0-0.6,0-0.9-0.2c-0.2-0.1-0.3-0.2-0.4-0.4
			c-0.1-0.1-0.2-0.3-0.3-0.5c-0.1-0.2-0.1-0.3-0.1-0.5c0-0.2,0-0.3,0-0.5c0.1-0.3,0.1-0.6,0.3-0.8c0.1-0.2,0.3-0.4,0.4-0.4
			c0.1-0.1,0.2-0.1,0.2-0.1s0,0.1,0.1,0.2c0,0.1,0.1,0.3,0.1,0.5c0.1,0.4,0.2,0.9,0.3,1.2c0,0.1,0.1,0.2,0.1,0.2
			c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.1,0.2,0.1
			S199.4,241.6,199.2,241.7z"/>
                            <path d="M205.6,236.9c0-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.1c-0.6-0.8-1.4-1.5-2.3-2c0.1-0.7,0.2-1.5,0-2.2
			c-0.3-1-1.2-1.4-2.1-1.5c0-5.3,0-10.6,0-15.8c0-0.7-1-0.7-1,0c0,5.3,0,10.5,0,15.8c-0.9,0-1.9,0.4-2.4,1.2
			c-0.5,0.7-0.4,1.7-0.4,2.6c-0.1,0.1-0.3,0.1-0.4,0.2c-2.4,1.5-3.3,5.2-2.1,7.7c1.5,3.3,6.2,3.9,9.3,2.5
			C207.3,243.4,207.3,239.8,205.6,236.9z M198.8,233.2c0.1-0.7,0.8-0.7,1.4-0.7c1.3,0,1.5,0.6,1.4,1.6c-0.9-0.2-1.9-0.2-2.9,0
			C198.7,233.7,198.7,233.4,198.8,233.2z M203.4,243.5c-2.2,1.3-6,0.9-7.4-1.4c-1-1.7-0.3-4.2,1-5.5c2.1-2.1,5.4-1.2,7,1
			C205.4,239.6,205.8,242.1,203.4,243.5z"/>
	</g>
                        <g className="navidad">
		<path className="st18" d="M751.6,175.6c0.1-0.7,0.8-0.7,1.4-0.7c1.3,0,1.5,0.6,1.4,1.6c-0.9-0.2-1.9-0.2-2.9,0
			C751.5,176.2,751.5,175.9,751.6,175.6z"/>
                            <path className="st18" d="M756.9,180c-1.6-2.2-4.9-3.1-7-1c-1.3,1.3-2,3.8-1,5.5c1.3,2.4,5.2,2.7,7.4,1.4
			C758.6,184.5,758.2,182,756.9,180z M752,184.1c-0.1,0.1-0.3,0.1-0.6,0.1c-0.2,0-0.6,0-0.9-0.2c-0.2-0.1-0.3-0.2-0.4-0.4
			c-0.1-0.1-0.2-0.3-0.3-0.5c-0.1-0.2-0.1-0.3-0.1-0.5c0-0.2,0-0.3,0-0.5c0.1-0.3,0.1-0.6,0.3-0.8c0.1-0.2,0.3-0.4,0.4-0.4
			c0.1-0.1,0.2-0.1,0.2-0.1s0,0.1,0.1,0.2c0,0.1,0.1,0.3,0.1,0.5c0.1,0.4,0.2,0.9,0.3,1.2c0,0.1,0.1,0.2,0.1,0.2
			c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.1,0.2,0.1
			S752.2,184.1,752,184.1z"/>
                            <path d="M758.4,179.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.1c-0.6-0.8-1.4-1.5-2.3-2c0.1-0.7,0.2-1.5,0-2.2
			c-0.3-1-1.2-1.4-2.1-1.5c0-5.3,0-10.6,0-15.8c0-0.7-1-0.7-1,0c0,5.3,0,10.5,0,15.8c-0.9,0-1.9,0.4-2.4,1.2
			c-0.5,0.7-0.4,1.7-0.4,2.6c-0.1,0.1-0.3,0.1-0.4,0.2c-2.4,1.5-3.3,5.2-2.1,7.7c1.5,3.3,6.2,3.9,9.3,2.5
			C760.1,185.9,760.1,182.2,758.4,179.3z M751.6,175.6c0.1-0.7,0.8-0.7,1.4-0.7c1.3,0,1.5,0.6,1.4,1.6c-0.9-0.2-1.9-0.2-2.9,0
			C751.5,176.2,751.5,175.9,751.6,175.6z M756.2,185.9c-2.2,1.3-6,0.9-7.4-1.4c-1-1.7-0.3-4.2,1-5.5c2.1-2.1,5.4-1.2,7,1
			C758.2,182,758.6,184.5,756.2,185.9z"/>
	</g>
                        <g className="navidad">
		<path className="st18" d="M402.6,127.6c0.1-0.7,0.8-0.7,1.4-0.7c1.3,0,1.5,0.6,1.4,1.6c-0.9-0.2-1.9-0.2-2.9,0
			C402.5,128.2,402.5,127.9,402.6,127.6z"/>
                            <path className="st18" d="M407.9,132c-1.6-2.2-4.9-3.1-7-1c-1.3,1.3-2,3.8-1,5.5c1.3,2.4,5.2,2.7,7.4,1.4
			C409.6,136.5,409.2,134,407.9,132z M403,136.1c-0.1,0.1-0.3,0.1-0.6,0.1c-0.2,0-0.6,0-0.9-0.2c-0.2-0.1-0.3-0.2-0.4-0.4
			c-0.1-0.1-0.2-0.3-0.3-0.5c-0.1-0.2-0.1-0.3-0.1-0.5c0-0.2,0-0.3,0-0.5c0.1-0.3,0.1-0.6,0.3-0.8c0.1-0.2,0.3-0.4,0.4-0.4
			c0.1-0.1,0.2-0.1,0.2-0.1s0,0.1,0.1,0.2c0,0.1,0.1,0.3,0.1,0.5c0.1,0.4,0.2,0.9,0.3,1.2c0,0.1,0.1,0.2,0.1,0.2
			c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.1,0.2,0.1
			S403.2,136.1,403,136.1z"/>
                            <path d="M409.4,131.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.1c-0.6-0.8-1.4-1.5-2.3-2c0.1-0.7,0.2-1.5,0-2.2
			c-0.3-1-1.2-1.4-2.1-1.5c0-5.3,0-10.6,0-15.8c0-0.7-1-0.7-1,0c0,5.3,0,10.5,0,15.8c-0.9,0-1.9,0.4-2.4,1.2
			c-0.5,0.7-0.4,1.7-0.4,2.6c-0.1,0.1-0.3,0.1-0.4,0.2c-2.4,1.5-3.3,5.2-2.1,7.7c1.5,3.3,6.2,3.9,9.3,2.5
			C411.1,137.9,411.1,134.2,409.4,131.3z M402.6,127.6c0.1-0.7,0.8-0.7,1.4-0.7c1.3,0,1.5,0.6,1.4,1.6c-0.9-0.2-1.9-0.2-2.9,0
			C402.5,128.2,402.5,127.9,402.6,127.6z M407.2,137.9c-2.2,1.3-6,0.9-7.4-1.4c-1-1.7-0.3-4.2,1-5.5c2.1-2.1,5.4-1.2,7,1
			C409.2,134,409.6,136.5,407.2,137.9z"/>
	</g>
                        <g className="navidad">
		<path className="st11" d="M444,102.2c0.1-0.7,0.8-0.7,1.4-0.7c1.3,0,1.5,0.6,1.4,1.6c-0.9-0.2-1.9-0.2-2.9,0
			C444,102.7,444,102.4,444,102.2z"/>
                            <path className="st11" d="M449.4,106.5c-1.6-2.2-4.9-3.1-7-1c-1.3,1.3-2,3.8-1,5.5c1.3,2.4,5.2,2.7,7.4,1.4
			C451.1,111.1,450.7,108.6,449.4,106.5z M444.5,110.7c-0.1,0.1-0.3,0.1-0.6,0.1c-0.2,0-0.6,0-0.9-0.2c-0.2-0.1-0.3-0.2-0.4-0.4
			c-0.1-0.1-0.2-0.3-0.3-0.5c-0.1-0.2-0.1-0.3-0.1-0.5c0-0.2,0-0.3,0-0.5c0.1-0.3,0.1-0.6,0.3-0.8c0.1-0.2,0.3-0.4,0.4-0.4
			c0.1-0.1,0.2-0.1,0.2-0.1s0,0.1,0.1,0.2c0,0.1,0.1,0.3,0.1,0.5c0.1,0.4,0.2,0.9,0.3,1.2c0,0.1,0.1,0.2,0.1,0.2
			c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.1,0.2,0.1
			S444.6,110.6,444.5,110.7z"/>
                            <path d="M450.8,105.9c0-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.1c-0.6-0.8-1.4-1.5-2.3-2c0.1-0.7,0.2-1.5,0-2.2
			c-0.3-1-1.2-1.4-2.1-1.5c0-5.3,0-10.6,0-15.8c0-0.7-1-0.7-1,0c0,5.3,0,10.5,0,15.8c-0.9,0-1.9,0.4-2.4,1.2
			c-0.5,0.7-0.4,1.7-0.4,2.6c-0.1,0.1-0.3,0.1-0.4,0.2c-2.4,1.5-3.3,5.2-2.1,7.7c1.5,3.3,6.2,3.9,9.3,2.5
			C452.5,112.4,452.6,108.8,450.8,105.9z M444,102.2c0.1-0.7,0.8-0.7,1.4-0.7c1.3,0,1.5,0.6,1.4,1.6c-0.9-0.2-1.9-0.2-2.9,0
			C444,102.7,444,102.4,444,102.2z M448.7,112.5c-2.2,1.3-6,0.9-7.4-1.4c-1-1.7-0.3-4.2,1-5.5c2.1-2.1,5.4-1.2,7,1
			C450.7,108.6,451.1,111.1,448.7,112.5z"/>
	</g>
                        <g className="navidad">
		<path className="st11" d="M116,264.2c0.1-0.7,0.8-0.7,1.4-0.7c1.3,0,1.5,0.6,1.4,1.6c-0.9-0.2-1.9-0.2-2.9,0
			C116,264.7,116,264.4,116,264.2z"/>
                            <path className="st11" d="M121.4,268.5c-1.6-2.2-4.9-3.1-7-1c-1.3,1.3-2,3.8-1,5.5c1.3,2.4,5.2,2.7,7.4,1.4
			C123.1,273.1,122.7,270.6,121.4,268.5z M116.5,272.7c-0.1,0.1-0.3,0.1-0.6,0.1c-0.2,0-0.6,0-0.9-0.2c-0.2-0.1-0.3-0.2-0.4-0.4
			c-0.1-0.1-0.2-0.3-0.3-0.5c-0.1-0.2-0.1-0.3-0.1-0.5c0-0.2,0-0.3,0-0.5c0.1-0.3,0.1-0.6,0.3-0.8c0.1-0.2,0.3-0.4,0.4-0.4
			c0.1-0.1,0.2-0.1,0.2-0.1s0,0.1,0.1,0.2c0,0.1,0.1,0.3,0.1,0.5c0.1,0.4,0.2,0.9,0.3,1.2c0,0.1,0.1,0.2,0.1,0.2
			c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.1,0.2,0.1
			S116.6,272.6,116.5,272.7z"/>
                            <path d="M122.8,267.9c0-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.1c-0.6-0.8-1.4-1.5-2.3-2c0.1-0.7,0.2-1.5,0-2.2
			c-0.3-1-1.2-1.4-2.1-1.5c0-5.3,0-10.6,0-15.8c0-0.7-1-0.7-1,0c0,5.3,0,10.5,0,15.8c-0.9,0-1.9,0.4-2.4,1.2
			c-0.5,0.7-0.4,1.7-0.4,2.6c-0.1,0.1-0.3,0.1-0.4,0.2c-2.4,1.5-3.3,5.2-2.1,7.7c1.5,3.3,6.2,3.9,9.3,2.5
			C124.5,274.4,124.6,270.8,122.8,267.9z M116,264.2c0.1-0.7,0.8-0.7,1.4-0.7c1.3,0,1.5,0.6,1.4,1.6c-0.9-0.2-1.9-0.2-2.9,0
			C116,264.7,116,264.4,116,264.2z M120.7,274.5c-2.2,1.3-6,0.9-7.4-1.4c-1-1.7-0.3-4.2,1-5.5c2.1-2.1,5.4-1.2,7,1
			C122.7,270.6,123.1,273.1,120.7,274.5z"/>
	</g>
                        <g className="navidad">
		<path className="st21" d="M884.3,219.2c0.1-0.7,0.8-0.7,1.4-0.7c1.3,0,1.5,0.6,1.4,1.6c-0.9-0.2-1.9-0.2-2.9,0
			C884.3,219.7,884.3,219.4,884.3,219.2z"/>
                            <path className="st21" d="M889.6,223.5c-1.6-2.2-4.9-3.1-7-1c-1.3,1.3-2,3.8-1,5.5c1.3,2.4,5.2,2.7,7.4,1.4
			C891.4,228.1,890.9,225.6,889.6,223.5z M884.8,227.7c-0.1,0.1-0.3,0.1-0.6,0.1c-0.2,0-0.6,0-0.9-0.2c-0.2-0.1-0.3-0.2-0.4-0.4
			c-0.1-0.1-0.2-0.3-0.3-0.5c-0.1-0.2-0.1-0.3-0.1-0.5c0-0.2,0-0.3,0-0.5c0.1-0.3,0.1-0.6,0.3-0.8c0.1-0.2,0.3-0.4,0.4-0.4
			c0.1-0.1,0.2-0.1,0.2-0.1s0,0.1,0.1,0.2c0,0.1,0.1,0.3,0.1,0.5c0.1,0.4,0.2,0.9,0.3,1.2c0,0.1,0.1,0.2,0.1,0.2
			c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.1,0.2,0.1
			S884.9,227.6,884.8,227.7z"/>
                            <path d="M891.1,222.9c0-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.1c-0.6-0.8-1.4-1.5-2.3-2c0.1-0.7,0.2-1.5,0-2.2
			c-0.3-1-1.2-1.4-2.1-1.5c0-5.3,0-10.6,0-15.8c0-0.7-1-0.7-1,0c0,5.3,0,10.5,0,15.8c-0.9,0-1.9,0.4-2.4,1.2
			c-0.5,0.7-0.4,1.7-0.4,2.6c-0.1,0.1-0.3,0.1-0.4,0.2c-2.4,1.5-3.3,5.2-2.1,7.7c1.5,3.3,6.2,3.9,9.3,2.5
			C892.8,229.4,892.9,225.8,891.1,222.9z M884.3,219.2c0.1-0.7,0.8-0.7,1.4-0.7c1.3,0,1.5,0.6,1.4,1.6c-0.9-0.2-1.9-0.2-2.9,0
			C884.3,219.7,884.3,219.4,884.3,219.2z M889,229.5c-2.2,1.3-6,0.9-7.4-1.4c-1-1.7-0.3-4.2,1-5.5c2.1-2.1,5.4-1.2,7,1
			C890.9,225.6,891.4,228.1,889,229.5z"/>
	</g>
                        <g className="navidad">
		<path className="st32" d="M981.1,213.3c0.1-0.7,0.8-0.7,1.4-0.7c1.3,0,1.5,0.6,1.4,1.6c-0.9-0.2-1.9-0.2-2.9,0
			C981,213.8,981,213.5,981.1,213.3z"/>
                            <path className="st32" d="M986.4,217.6c-1.6-2.2-4.9-3.1-7-1c-1.3,1.3-2,3.8-1,5.5c1.3,2.4,5.2,2.7,7.4,1.4
			C988.1,222.2,987.7,219.7,986.4,217.6z M981.5,221.8c-0.1,0.1-0.3,0.1-0.6,0.1c-0.2,0-0.6,0-0.9-0.2c-0.2-0.1-0.3-0.2-0.4-0.4
			c-0.1-0.1-0.2-0.3-0.3-0.5c-0.1-0.2-0.1-0.3-0.1-0.5c0-0.2,0-0.3,0-0.5c0.1-0.3,0.1-0.6,0.3-0.8c0.1-0.2,0.3-0.4,0.4-0.4
			c0.1-0.1,0.2-0.1,0.2-0.1s0,0.1,0.1,0.2c0,0.1,0.1,0.3,0.1,0.5c0.1,0.4,0.2,0.9,0.3,1.2c0,0.1,0.1,0.2,0.1,0.2
			c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.1,0.2,0.1
			S981.6,221.7,981.5,221.8z"/>
                            <path d="M987.8,217c0-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.1c-0.6-0.8-1.4-1.5-2.3-2c0.1-0.7,0.2-1.5,0-2.2
			c-0.3-1-1.2-1.4-2.1-1.5c0-5.3,0-10.6,0-15.8c0-0.7-1-0.7-1,0c0,5.3,0,10.5,0,15.8c-0.9,0-1.9,0.4-2.4,1.2
			c-0.5,0.7-0.4,1.7-0.4,2.6c-0.1,0.1-0.3,0.1-0.4,0.2c-2.4,1.5-3.3,5.2-2.1,7.7c1.5,3.3,6.2,3.9,9.3,2.5
			C989.5,223.5,989.6,219.9,987.8,217z M981.1,213.3c0.1-0.7,0.8-0.7,1.4-0.7c1.3,0,1.5,0.6,1.4,1.6c-0.9-0.2-1.9-0.2-2.9,0
			C981,213.8,981,213.5,981.1,213.3z M985.7,223.6c-2.2,1.3-6,0.9-7.4-1.4c-1-1.7-0.3-4.2,1-5.5c2.1-2.1,5.4-1.2,7,1
			C987.7,219.7,988.1,222.2,985.7,223.6z"/>
	</g>
                        <g className="navidad">
		<path d="M794.6,206.6c1.6-3.2-0.4-4.9-3.2-5.4c0.5-0.5,0.5-1.1,0-1.7c-1.7-2.2-4.3-2.6-7-2.3c0.3-0.7,0.2-1.5-0.6-2.3
			c-1.7-1.7-3.9-1.6-6-0.8c0.5-2.1-0.3-4.1-3.3-4.8c-3.8-0.9-7.5,1.1-10.3,3.7c-0.2-0.2-0.5-0.4-0.9-0.4c-2.7,0-5.2,0.7-7.4,1.9
			c0-0.3,0.1-0.7,0.1-1c0-0.9-0.8-1.3-1.6-1.2c-2.5,0.4-4.8,1-6.8,2.2c-0.2-0.6-0.6-1.2-1.6-1.7c-4.1-1.8-8.6-0.3-12.3,2.2
			c0-0.3,0.1-0.5,0.1-0.8c0.1-0.7-0.3-1.6-1.2-1.6c-3,0.2-5.7,1.1-7.9,2.8c-0.9-2.9-6-2.1-8.8,1c-3.1-3.7-8.4-4.7-9.8-0.3
			c-3-3-5.6-3.9-8.1-0.5c-0.6-1.5-1.7-2.7-3.5-3.2c-2.5-0.7-4.1,1.4-4.8,3.6c-4.2-3.1-10.4-5.4-9.7,0.9c-2.8-0.8-5.5-1.7-8.4-1.6
			c-0.8,0-1.3,0.8-1.2,1.6c0.3,1.8,1,3.2,2.1,4.3c-0.6,0.1-1.2,0.2-1.8,0.4c-1.6,0.4-3.3,0.9-4.5,2c-1.2,1.1-1.3,3.2-0.2,4.5
			c0.7,0.8,1.6,1.2,2.6,1.3c-1.8,1.8-3.1,4-3.2,6.9c0,0.8,0.8,1.4,1.6,1.2c1.2-0.4,2.3-0.9,3.3-1.5c0.1,0.5,0.3,0.9,0.8,1.4
			c2,2,5.1,0.5,7.6-1.4c-0.1,1,0.1,1.9,1,2.5c2.2,1.6,4.3,0,6.2-1.7c0.2,3.5,2.8,4.1,5.9,2.2c0.1,0.8,0.6,1.5,1.7,1.8
			c1.8,0.5,3.4,0,4.8-0.9c0.1,0.5,0.3,0.9,0.6,1.3c1.9,2.6,4.5,0.7,6.4-1.4c0.7,1.8,2.3,3.1,4.6,2.7c1.5-0.2,2.3-1,2.8-1.9
			c1.3,0.9,2.9,1.2,4.7,0.6c1-0.3,1.7-0.9,2.1-1.6c3.6,2.4,9.1,4.1,9.5,0.2c3.6,1.9,9,2,10.3-0.8c2.6,1.6,5.9,1.1,7.6-1.6
			c2.9,2.4,7.1,2.6,8.1,0c2.7,2,6.2,2.5,9.5,1c1.8-0.9,2.6-2,2.5-3.1c4.6,1.8,9.5,4.7,13.9,2.1c1.3-0.8,1.5-1.7,1.2-2.5
			c1.8,0.2,3.6,0.1,5.3-0.4c1-0.3,1.4-0.8,1.4-1.3c2.9,0.7,6.3,0.9,7.8-1.1C797.8,209.8,796.5,208,794.6,206.6z"/>
                            <path className="st17" d="M791.8,210.8c-2.1,0-4.3-0.5-6.2-1.2c-1.4-0.5-2.1,1.5-0.9,2.3c0.1,0.2,0.3,0.3,0.4,0.4c-0.6,0-1.3,0-1.9,0
			c-1.7,0-3.4-0.2-5-0.5c-1.5-0.2-2.2,1.9-0.7,2.4c-1.3,1.5-2.6,1.7-4.1,0.8c-0.9-0.4-1.8-0.7-2.8-1c-2.1-0.7-4-1.6-6.1-2.1
			c-0.3-0.1-0.5,0-0.7,0c0,0,0,0,0,0c-1.5-0.6-2.1,1.8-0.7,2.4c3.2,1.2-3.6,2.7-2.8,2.6c-2.6,0.2-4.9-1.7-6.2-3.7
			c-0.9-1.4-2.8-0.2-2.1,1.2c2.5,5.5-4.1,1-4.7,0.2c-0.6-0.8-1.9-0.8-2.3,0.3c-2.3,3.3-4.4,3.4-6.3,0.4c-0.7-1.2-2.8-0.4-2.3,1
			c1.6,4.2-7.1,2.2-8.5-0.2c-0.7-1.2-2.7-0.4-2.3,1c1.7,5.1-4.6,1.2-6.2-0.1c0,0-0.1,0-0.1-0.1c-0.3-1-0.9-2-1.7-2.8
			c-1.2-1.1-2.9,0.6-1.7,1.7c1.1,1.1,1.9,2.5,0,3.1c-1.7,0.6-2.9-0.5-3.6-1.9c-0.1-0.5-0.3-0.9-0.5-1.3c-0.7-1.2-2.7-0.4-2.3,1
			c0.1,0.5,0.3,0.9,0.5,1.3c0.7,2.8-3.5,2.7-3.1-1.3c0.1-1.3-1.7-1.6-2.3-0.6c-0.3,0.5-3.2,5.1-4.2,3.6c-1.1-1.5,0.9-3.4,2-4.1
			c1.3-0.8,0.1-3-1.2-2.1c-0.4,0.3-0.8,0.6-1.2,0.9c-0.4,0-0.8,0.2-1,0.6c-0.5,0.7-0.9,1.3-1.4,2c-1.2,1.7-2.3,1.6-3.3-0.4
			c0.6-1.2-0.9-2.6-1.9-1.5c-2.1,2.2-5.2,4.1-4-0.9c0.2-1-0.9-2.1-1.8-1.4c-0.9,0.6-1.7,1.3-2.5,2.1c-2.7,2.5-2.6-0.4-1.5-2.2
			c0.6-1-0.7-2.5-1.7-1.7c-1.3,1-2.5,1.9-3.8,2.8c-1.1,0.8-2.2,1.5-3.5,2c0-0.5,0-1,0-1.5c0.4-0.4,0.7-0.7,1.1-0.9
			c0.6-0.3,1.1-0.6,1.7-0.8c1.5-0.6,0.9-2.9-0.7-2.4c-0.5,0.1-1.1,0.4-1.8,0.8c-2.1,1.1-3.9,2.4-6,3.4c0.8-2.5,2.9-4.1,5.1-5.5
			c1.3-0.9,0.1-3-1.2-2.1c-0.1,0.1-0.2,0.2-0.3,0.2c0,0-0.1,0-0.1,0c-0.8,0.1-4.7,0.5-3.9-1.3c0.5-1,2.3-1.3,3.3-1.5
			c0.8-0.2,2.9-0.4,4.1-1c1,0.4,2,0.5,3.2,0.5c1.6,0,1.6-2.5,0-2.5c-2.6,0-4.3-1.1-5.1-3.2c2.7,0.3,5.3,1.3,8,2
			c0.9,0.2,1.8-0.6,1.5-1.5c-1.7-6.3,5.1-0.8,6.9,0.8c0.8,0.8,2.1,0.2,2.1-0.9c2.5-2.6,3.9-2.8,4.2-0.6c0.2,0.7,0.4,1.3,0.5,2
			c0.3,1.6,2.6,0.9,2.4-0.7c0,0,0,0,0,0c1-2.1,2.5-4.2,4.7-2.1c0.9,0.9,1.7,1.9,2.5,2.9c0.7,0.8,2.1,0.1,2.1-0.9
			c-0.2-5.8,5-2.8,6.6,0.3c0.4,0.8,1.7,0.8,2.1,0c0.4-0.9,1-1.7,1.9-2.3c0.7-0.5,4.8-2.5,3.1,1.4c-0.5,1.2,1,2.7,1.9,1.5
			c2-2.5,4.3-4,7.3-4.5c-0.2,0.8-0.4,1.6-1,2.6c-0.8,1.2,0.9,2.4,1.9,1.5c1.6-1.5,3.3-2.6,5.2-3.6c1.2-0.6,2.4-1,3.6-1.3
			c3.4,1.8,3.6,3.3,0.7,4.4c-1.3,0.9-0.1,3,1.2,2.1c0.7-0.5,2-1.5,3.1-2.7c0.4,0.1,0.7,0,1.1-0.3c1.6-1.5,3.5-2.3,5.5-2.8
			c-0.4,0.8-0.9,1.5-1.3,2.3c-1.3,0.9,0,3.1,1.2,2.1c2.9-2.1,5.8-3.9,9.3-4.4c-0.1,0.4-0.2,0.8-0.4,1.2c-0.5,1.4,1.5,2,2.3,1
			c0.8-1.1,1.7-2,2.6-2.9c0.9-0.8,1.9-1.4,2.9-2c2.9-1.9,4-0.4,3.5,4.6c-1.2,0.9-0.2,2.9,1.2,2.1c0.4-0.2,0.8-0.5,1.1-0.8
			c0.2-0.1,0.4-0.3,0.7-0.4c0.4-0.2,0.8-0.4,1.1-0.7c1.8-1.7,2.7-1.4,2.6,0.7c-1.5,0.6-0.9,2.7,0.7,2.4c2.5-0.6,6.1-1.7,8.4-0.2
			c-0.8,0.6-1.6,1.1-2.6,1.5c-1.3,0.6-0.4,2.7,1,2.3c0.4-0.2,6.4-1.6,5.5,1.3c-0.7-0.3-1.3-0.6-1.9-0.8c-1.5-0.5-2.1,1.8-0.7,2.4
			C791.2,207.4,795.6,210.8,791.8,210.8z"/>
                            <g>
			<path d="M698.4,207c-2.1-2.3-4.8-3.7-7.9-4.1c-0.7-0.1-1.3,0.5-0.9,1.2c0.9,1.6,0.7,2.5,2.4,3.5c0.2-0.5,0.4-1,0.6-1.5
				c-2.7-0.4-5.3-0.6-7.9,0c-0.5,0.1-0.7,0.7-0.5,1.2c1.6,3.4,6.2,2.6,9.3,2.5c1,0,1-1.6,0-1.6c-2.4,0-6.6,0.9-7.9-1.7
				c-0.2,0.4-0.3,0.8-0.5,1.2c2.4-0.6,4.7-0.4,7.1,0c0.8,0.1,1.5-1,0.6-1.5c-1.2-0.7-1.3-1.8-1.9-2.9c-0.3,0.4-0.6,0.8-0.9,1.2
				c2.8,0.4,5.2,1.6,7.2,3.7C697.9,208.9,699.1,207.7,698.4,207L698.4,207z"/>
		</g>
                            <g>
			<path d="M702.8,206.9c1.8-2.9,5.2-4.7,8.6-4.2c-0.2-0.4-0.5-0.8-0.7-1.2c-0.9,1.8-2,2.9-3.6,4c-0.9,0.6-0.1,1.8,0.8,1.4
				c2.6-1.3,5.7-2.9,8.5-1.1c-0.1-0.4-0.1-0.8-0.2-1.3c-1.1,0.9-2.1,1.8-3.4,2.4c0.3,0.5,0.5,0.9,0.8,1.4c2.6-1.6,6-2,8.8-0.7
				c-0.1-0.4-0.1-0.8-0.2-1.3c-0.7,0.4-1.3,0.9-2,1.3c-0.9,0.5-0.1,1.9,0.8,1.4c1.2-0.7,1.2-0.7,2.3-1.6c0.4-0.3,0.3-1.1-0.2-1.3
				c-3.4-1.6-7.3-1.2-10.5,0.7c-0.9,0.6-0.1,1.8,0.8,1.4c1.5-0.6,2.5-1.6,3.7-2.6c0.4-0.3,0.2-1-0.2-1.3c-3.3-2.2-6.9-0.5-10.1,1.1
				c0.3,0.5,0.5,0.9,0.8,1.4c1.9-1.2,3.2-2.6,4.2-4.6c0.3-0.6-0.1-1.1-0.7-1.2c-3.9-0.5-8,1.7-10,5
				C700.9,207,702.2,207.8,702.8,206.9L702.8,206.9z"/>
		</g>
                            <g>
			<path d="M732.1,208.3c1.7-2.5,5.2-2.7,7.9-2.5c-0.2-0.4-0.5-0.8-0.7-1.2c-0.5,0.6-2.3,3.5-3.2,3.5c0.1,0.5,0.3,1,0.4,1.5
				c3.4-1.9,6.8-2.3,10.7-2c-0.2-0.4-0.5-0.8-0.7-1.2c-0.9,1.4-2,1.9-3.5,2.5c-0.9,0.4-0.5,2,0.4,1.5c1.8-0.8,3.3-1.5,4.4-3.2
				c0.3-0.5-0.1-1.2-0.7-1.2c-4.1-0.3-7.8,0.2-11.5,2.3c-0.7,0.4-0.4,1.5,0.4,1.5c2,0,3.6-2.9,4.6-4.3c0.4-0.5-0.2-1.2-0.7-1.2
				c-3.4-0.3-7.2,0.3-9.3,3.3C730.1,208.3,731.5,209.1,732.1,208.3L732.1,208.3z"/>
		</g>
                            <g>
			<path d="M756.4,205.8c1.7-3,5.3-5.1,8.7-3.9c-0.1-0.4-0.2-0.9-0.4-1.3c-1.1,1.2-2,2.4-3.6,3c-1,0.4-0.6,1.9,0.4,1.6
				c3.5-1,6.8-1.9,10.5-1.4c-0.2-0.5-0.4-0.9-0.6-1.4c-1,1.1-2.2,1.8-3.6,2.3c-1,0.3-0.6,1.8,0.4,1.6c0.8-0.2,1.7-0.4,2.5-0.5
				c0.9-0.2,1.7-0.2,2.6-0.2c2-0.3,2.2,0.4,0.7,2.1c-1,0.1-1,1.7,0,1.6c1.8-0.2,2.8-0.8,4.1-2c0.3-0.2,0.3-0.7,0.1-1
				c-2.3-3.4-7.2-2.3-10.6-1.4c0.1,0.5,0.3,1,0.4,1.6c1.8-0.6,2.9-1.4,4.3-2.7c0.6-0.5,0.1-1.3-0.6-1.4c-3.8-0.5-7.3,0.4-10.9,1.5
				c0.1,0.5,0.3,1,0.4,1.6c1.9-0.7,2.9-1.9,4.3-3.4c0.4-0.5,0.2-1.2-0.4-1.3c-4.1-1.4-8.6,1-10.6,4.7
				C754.5,205.9,755.9,206.7,756.4,205.8L756.4,205.8z"/>
		</g>
	</g>
                        <g className="navidad">
		<path d="M683.6,205.6c1.6-3.2-0.4-4.9-3.2-5.4c0.5-0.5,0.5-1.1,0-1.7c-1.7-2.2-4.3-2.6-7-2.3c0.3-0.7,0.2-1.5-0.6-2.3
			c-1.7-1.7-3.9-1.6-6-0.8c0.5-2.1-0.3-4.1-3.3-4.8c-3.8-0.9-7.5,1.1-10.3,3.7c-0.2-0.2-0.5-0.4-0.9-0.4c-2.7,0-5.2,0.7-7.4,1.9
			c0-0.3,0.1-0.7,0.1-1c0-0.9-0.8-1.3-1.6-1.2c-2.5,0.4-4.8,1-6.8,2.2c-0.2-0.6-0.6-1.2-1.6-1.7c-4.1-1.8-8.6-0.3-12.3,2.2
			c0-0.3,0.1-0.5,0.1-0.8c0.1-0.7-0.3-1.6-1.2-1.6c-3,0.2-5.7,1.1-7.9,2.8c-0.9-2.9-6-2.1-8.8,1c-3.1-3.7-8.4-4.7-9.8-0.3
			c-3-3-5.6-3.9-8.1-0.5c-0.6-1.5-1.7-2.7-3.5-3.2c-2.5-0.7-4.1,1.4-4.8,3.6c-4.2-3.1-10.4-5.4-9.7,0.9c-2.8-0.8-5.5-1.7-8.4-1.6
			c-0.8,0-1.3,0.8-1.2,1.6c0.3,1.8,1,3.2,2.1,4.3c-0.6,0.1-1.2,0.2-1.8,0.4c-1.6,0.4-3.3,0.9-4.5,2c-1.2,1.1-1.3,3.2-0.2,4.5
			c0.7,0.8,1.6,1.2,2.6,1.3c-1.8,1.8-3.1,4-3.2,6.9c0,0.8,0.8,1.4,1.6,1.2c1.2-0.4,2.3-0.9,3.3-1.5c0.1,0.5,0.3,0.9,0.8,1.4
			c2,2,5.1,0.5,7.6-1.4c-0.1,1,0.1,1.9,1,2.5c2.2,1.6,4.3,0,6.2-1.7c0.2,3.5,2.8,4.1,5.9,2.2c0.1,0.8,0.6,1.5,1.7,1.8
			c1.8,0.5,3.4,0,4.8-0.9c0.1,0.5,0.3,0.9,0.6,1.3c1.9,2.6,4.5,0.7,6.4-1.4c0.7,1.8,2.3,3.1,4.6,2.7c1.5-0.2,2.3-1,2.8-1.9
			c1.3,0.9,2.9,1.2,4.7,0.6c1-0.3,1.7-0.9,2.1-1.6c3.6,2.4,9.1,4.1,9.5,0.2c3.6,1.9,9,2,10.3-0.8c2.6,1.6,5.9,1.1,7.6-1.6
			c2.9,2.4,7.1,2.6,8.1,0c2.7,2,6.2,2.5,9.5,1c1.8-0.9,2.6-2,2.5-3.1c4.6,1.8,9.5,4.7,13.9,2.1c1.3-0.8,1.5-1.7,1.2-2.5
			c1.8,0.2,3.6,0.1,5.3-0.4c1-0.3,1.4-0.8,1.4-1.3c2.9,0.7,6.3,0.9,7.8-1.1C686.8,208.8,685.5,207,683.6,205.6z"/>
                            <path className="st17" d="M680.8,209.8c-2.1,0-4.3-0.5-6.2-1.2c-1.4-0.5-2.1,1.5-0.9,2.3c0.1,0.2,0.3,0.3,0.4,0.4c-0.6,0-1.3,0-1.9,0
			c-1.7,0-3.4-0.2-5-0.5c-1.5-0.2-2.2,1.9-0.7,2.4c-1.3,1.5-2.6,1.7-4.1,0.8c-0.9-0.4-1.8-0.7-2.8-1c-2.1-0.7-4-1.6-6.1-2.1
			c-0.3-0.1-0.5,0-0.7,0c0,0,0,0,0,0c-1.5-0.6-2.1,1.8-0.7,2.4c3.2,1.2-3.6,2.7-2.8,2.6c-2.6,0.2-4.9-1.7-6.2-3.7
			c-0.9-1.4-2.8-0.2-2.1,1.2c2.5,5.5-4.1,1-4.7,0.2c-0.6-0.8-1.9-0.8-2.3,0.3c-2.3,3.3-4.4,3.4-6.3,0.4c-0.7-1.2-2.8-0.4-2.3,1
			c1.6,4.2-7.1,2.2-8.5-0.2c-0.7-1.2-2.7-0.4-2.3,1c1.7,5.1-4.6,1.2-6.2-0.1c0,0-0.1,0-0.1-0.1c-0.3-1-0.9-2-1.7-2.8
			c-1.2-1.1-2.9,0.6-1.7,1.7c1.1,1.1,1.9,2.5,0,3.1c-1.7,0.6-2.9-0.5-3.6-1.9c-0.1-0.5-0.3-0.9-0.5-1.3c-0.7-1.2-2.7-0.4-2.3,1
			c0.1,0.5,0.3,0.9,0.5,1.3c0.7,2.8-3.5,2.7-3.1-1.3c0.1-1.3-1.7-1.6-2.3-0.6c-0.3,0.5-3.2,5.1-4.2,3.6c-1.1-1.5,0.9-3.4,2-4.1
			c1.3-0.8,0.1-3-1.2-2.1c-0.4,0.3-0.8,0.6-1.2,0.9c-0.4,0-0.8,0.2-1,0.6c-0.5,0.7-0.9,1.3-1.4,2c-1.2,1.7-2.3,1.6-3.3-0.4
			c0.6-1.2-0.9-2.6-1.9-1.5c-2.1,2.2-5.2,4.1-4-0.9c0.2-1-0.9-2.1-1.8-1.4c-0.9,0.6-1.7,1.3-2.5,2.1c-2.7,2.5-2.6-0.4-1.5-2.2
			c0.6-1-0.7-2.5-1.7-1.7c-1.3,1-2.5,1.9-3.8,2.8c-1.1,0.8-2.2,1.5-3.5,2c0-0.5,0-1,0-1.5c0.4-0.4,0.7-0.7,1.1-0.9
			c0.6-0.3,1.1-0.6,1.7-0.8c1.5-0.6,0.9-2.9-0.7-2.4c-0.5,0.1-1.1,0.4-1.8,0.8c-2.1,1.1-3.9,2.4-6,3.4c0.8-2.5,2.9-4.1,5.1-5.5
			c1.3-0.9,0.1-3-1.2-2.1c-0.1,0.1-0.2,0.2-0.3,0.2c0,0-0.1,0-0.1,0c-0.8,0.1-4.7,0.5-3.9-1.3c0.5-1,2.3-1.3,3.3-1.5
			c0.8-0.2,2.9-0.4,4.1-1c1,0.4,2,0.5,3.2,0.5c1.6,0,1.6-2.5,0-2.5c-2.6,0-4.3-1.1-5.1-3.2c2.7,0.3,5.3,1.3,8,2
			c0.9,0.2,1.8-0.6,1.5-1.5c-1.7-6.3,5.1-0.8,6.9,0.8c0.8,0.8,2.1,0.2,2.1-0.9c2.5-2.6,3.9-2.8,4.2-0.6c0.2,0.7,0.4,1.3,0.5,2
			c0.3,1.6,2.6,0.9,2.4-0.7c0,0,0,0,0,0c1-2.1,2.5-4.2,4.7-2.1c0.9,0.9,1.7,1.9,2.5,2.9c0.7,0.8,2.1,0.1,2.1-0.9
			c-0.2-5.8,5-2.8,6.6,0.3c0.4,0.8,1.7,0.8,2.1,0c0.4-0.9,1-1.7,1.9-2.3c0.7-0.5,4.8-2.5,3.1,1.4c-0.5,1.2,1,2.7,1.9,1.5
			c2-2.5,4.3-4,7.3-4.5c-0.2,0.8-0.4,1.6-1,2.6c-0.8,1.2,0.9,2.4,1.9,1.5c1.6-1.5,3.3-2.6,5.2-3.6c1.2-0.6,2.4-1,3.6-1.3
			c3.4,1.8,3.6,3.3,0.7,4.4c-1.3,0.9-0.1,3,1.2,2.1c0.7-0.5,2-1.5,3.1-2.7c0.4,0.1,0.7,0,1.1-0.3c1.6-1.5,3.5-2.3,5.5-2.8
			c-0.4,0.8-0.9,1.5-1.3,2.3c-1.3,0.9,0,3.1,1.2,2.1c2.9-2.1,5.8-3.9,9.3-4.4c-0.1,0.4-0.2,0.8-0.4,1.2c-0.5,1.4,1.5,2,2.3,1
			c0.8-1.1,1.7-2,2.6-2.9c0.9-0.8,1.9-1.4,2.9-2c2.9-1.9,4-0.4,3.5,4.6c-1.2,0.9-0.2,2.9,1.2,2.1c0.4-0.2,0.8-0.5,1.1-0.8
			c0.2-0.1,0.4-0.3,0.7-0.4c0.4-0.2,0.8-0.4,1.1-0.7c1.8-1.7,2.7-1.4,2.6,0.7c-1.5,0.6-0.9,2.7,0.7,2.4c2.5-0.6,6.1-1.7,8.4-0.2
			c-0.8,0.6-1.6,1.1-2.6,1.5c-1.3,0.6-0.4,2.7,1,2.3c0.4-0.2,6.4-1.6,5.5,1.3c-0.7-0.3-1.3-0.6-1.9-0.8c-1.5-0.5-2.1,1.8-0.7,2.4
			C680.2,206.4,684.6,209.8,680.8,209.8z"/>
                            <g>
			<path d="M587.4,206c-2.1-2.3-4.8-3.7-7.9-4.1c-0.7-0.1-1.3,0.5-0.9,1.2c0.9,1.6,0.7,2.5,2.4,3.5c0.2-0.5,0.4-1,0.6-1.5
				c-2.7-0.4-5.3-0.6-7.9,0c-0.5,0.1-0.7,0.7-0.5,1.2c1.6,3.4,6.2,2.6,9.3,2.5c1,0,1-1.6,0-1.6c-2.4,0-6.6,0.9-7.9-1.7
				c-0.2,0.4-0.3,0.8-0.5,1.2c2.4-0.6,4.7-0.4,7.1,0c0.8,0.1,1.5-1,0.6-1.5c-1.2-0.7-1.3-1.8-1.9-2.9c-0.3,0.4-0.6,0.8-0.9,1.2
				c2.8,0.4,5.2,1.6,7.2,3.7C586.9,207.9,588.1,206.7,587.4,206L587.4,206z"/>
		</g>
                            <g>
			<path d="M591.8,205.9c1.8-2.9,5.2-4.7,8.6-4.2c-0.2-0.4-0.5-0.8-0.7-1.2c-0.9,1.8-2,2.9-3.6,4c-0.9,0.6-0.1,1.8,0.8,1.4
				c2.6-1.3,5.7-2.9,8.5-1.1c-0.1-0.4-0.1-0.8-0.2-1.3c-1.1,0.9-2.1,1.8-3.4,2.4c0.3,0.5,0.5,0.9,0.8,1.4c2.6-1.6,6-2,8.8-0.7
				c-0.1-0.4-0.1-0.8-0.2-1.3c-0.7,0.4-1.3,0.9-2,1.3c-0.9,0.5-0.1,1.9,0.8,1.4c1.2-0.7,1.2-0.7,2.3-1.6c0.4-0.3,0.3-1.1-0.2-1.3
				c-3.4-1.6-7.3-1.2-10.5,0.7c-0.9,0.6-0.1,1.8,0.8,1.4c1.5-0.6,2.5-1.6,3.7-2.6c0.4-0.3,0.2-1-0.2-1.3c-3.3-2.2-6.9-0.5-10.1,1.1
				c0.3,0.5,0.5,0.9,0.8,1.4c1.9-1.2,3.2-2.6,4.2-4.6c0.3-0.6-0.1-1.1-0.7-1.2c-3.9-0.5-8,1.7-10,5
				C589.9,206,591.2,206.8,591.8,205.9L591.8,205.9z"/>
		</g>
                            <g>
			<path d="M621.1,207.3c1.7-2.5,5.2-2.7,7.9-2.5c-0.2-0.4-0.5-0.8-0.7-1.2c-0.5,0.6-2.3,3.5-3.2,3.5c0.1,0.5,0.3,1,0.4,1.5
				c3.4-1.9,6.8-2.3,10.7-2c-0.2-0.4-0.5-0.8-0.7-1.2c-0.9,1.4-2,1.9-3.5,2.5c-0.9,0.4-0.5,2,0.4,1.5c1.8-0.8,3.3-1.5,4.4-3.2
				c0.3-0.5-0.1-1.2-0.7-1.2c-4.1-0.3-7.8,0.2-11.5,2.3c-0.7,0.4-0.4,1.5,0.4,1.5c2,0,3.6-2.9,4.6-4.3c0.4-0.5-0.2-1.2-0.7-1.2
				c-3.4-0.3-7.2,0.3-9.3,3.3C619.1,207.3,620.5,208.1,621.1,207.3L621.1,207.3z"/>
		</g>
                            <g>
			<path d="M645.4,204.8c1.7-3,5.3-5.1,8.7-3.9c-0.1-0.4-0.2-0.9-0.4-1.3c-1.1,1.2-2,2.4-3.6,3c-1,0.4-0.6,1.9,0.4,1.6
				c3.5-1,6.8-1.9,10.5-1.4c-0.2-0.5-0.4-0.9-0.6-1.4c-1,1.1-2.2,1.8-3.6,2.3c-1,0.3-0.6,1.8,0.4,1.6c0.8-0.2,1.7-0.4,2.5-0.5
				c0.9-0.2,1.7-0.2,2.6-0.2c2-0.3,2.2,0.4,0.7,2.1c-1,0.1-1,1.7,0,1.6c1.8-0.2,2.8-0.8,4.1-2c0.3-0.2,0.3-0.7,0.1-1
				c-2.3-3.4-7.2-2.3-10.6-1.4c0.1,0.5,0.3,1,0.4,1.6c1.8-0.6,2.9-1.4,4.3-2.7c0.6-0.5,0.1-1.3-0.6-1.4c-3.8-0.5-7.3,0.4-10.9,1.5
				c0.1,0.5,0.3,1,0.4,1.6c1.9-0.7,2.9-1.9,4.3-3.4c0.4-0.5,0.2-1.2-0.4-1.3c-4.1-1.4-8.6,1-10.6,4.7
				C643.5,204.9,644.9,205.7,645.4,204.8L645.4,204.8z"/>
		</g>
	</g>
                        <g className="navidad">
		<path d="M529.6,212.6c1.6-3.2-0.4-4.9-3.2-5.4c0.5-0.5,0.5-1.1,0-1.7c-1.7-2.2-4.3-2.6-7-2.3c0.3-0.7,0.2-1.5-0.6-2.3
			c-1.7-1.7-3.9-1.6-6-0.8c0.5-2.1-0.3-4.1-3.3-4.8c-3.8-0.9-7.5,1.1-10.3,3.7c-0.2-0.2-0.5-0.4-0.9-0.4c-2.7,0-5.2,0.7-7.4,1.9
			c0-0.3,0.1-0.7,0.1-1c0-0.9-0.8-1.3-1.6-1.2c-2.5,0.4-4.8,1-6.8,2.2c-0.2-0.6-0.6-1.2-1.6-1.7c-4.1-1.8-8.6-0.3-12.3,2.2
			c0-0.3,0.1-0.5,0.1-0.8c0.1-0.7-0.3-1.6-1.2-1.6c-3,0.2-5.7,1.1-7.9,2.8c-0.9-2.9-6-2.1-8.8,1c-3.1-3.7-8.4-4.7-9.8-0.3
			c-3-3-5.6-3.9-8.1-0.5c-0.6-1.5-1.7-2.7-3.5-3.2c-2.5-0.7-4.1,1.4-4.8,3.6c-4.2-3.1-10.4-5.4-9.7,0.9c-2.8-0.8-5.5-1.7-8.4-1.6
			c-0.8,0-1.3,0.8-1.2,1.6c0.3,1.8,1,3.2,2.1,4.3c-0.6,0.1-1.2,0.2-1.8,0.4c-1.6,0.4-3.3,0.9-4.5,2c-1.2,1.1-1.3,3.2-0.2,4.5
			c0.7,0.8,1.6,1.2,2.6,1.3c-1.8,1.8-3.1,4-3.2,6.9c0,0.8,0.8,1.4,1.6,1.2c1.2-0.4,2.3-0.9,3.3-1.5c0.1,0.5,0.3,0.9,0.8,1.4
			c2,2,5.1,0.5,7.6-1.4c-0.1,1,0.1,1.9,1,2.5c2.2,1.6,4.3,0,6.2-1.7c0.2,3.5,2.8,4.1,5.9,2.2c0.1,0.8,0.6,1.5,1.7,1.8
			c1.8,0.5,3.4,0,4.8-0.9c0.1,0.5,0.3,0.9,0.6,1.3c1.9,2.6,4.5,0.7,6.4-1.4c0.7,1.8,2.3,3.1,4.6,2.7c1.5-0.2,2.3-1,2.8-1.9
			c1.3,0.9,2.9,1.2,4.7,0.6c1-0.3,1.7-0.9,2.1-1.6c3.6,2.4,9.1,4.1,9.5,0.2c3.6,1.9,9,2,10.3-0.8c2.6,1.6,5.9,1.1,7.6-1.6
			c2.9,2.4,7.1,2.6,8.1,0c2.7,2,6.2,2.5,9.5,1c1.8-0.9,2.6-2,2.5-3.1c4.6,1.8,9.5,4.7,13.9,2.1c1.3-0.8,1.5-1.7,1.2-2.5
			c1.8,0.2,3.6,0.1,5.3-0.4c1-0.3,1.4-0.8,1.4-1.3c2.9,0.7,6.3,0.9,7.8-1.1C532.8,215.8,531.5,214,529.6,212.6z"/>
                            <path className="st17" d="M526.8,216.8c-2.1,0-4.3-0.5-6.2-1.2c-1.4-0.5-2.1,1.5-0.9,2.3c0.1,0.2,0.3,0.3,0.4,0.4c-0.6,0-1.3,0-1.9,0
			c-1.7,0-3.4-0.2-5-0.5c-1.5-0.2-2.2,1.9-0.7,2.4c-1.3,1.5-2.6,1.7-4.1,0.8c-0.9-0.4-1.8-0.7-2.8-1c-2.1-0.7-4-1.6-6.1-2.1
			c-0.3-0.1-0.5,0-0.7,0c0,0,0,0,0,0c-1.5-0.6-2.1,1.8-0.7,2.4c3.2,1.2-3.6,2.7-2.8,2.6c-2.6,0.2-4.9-1.7-6.2-3.7
			c-0.9-1.4-2.8-0.2-2.1,1.2c2.5,5.5-4.1,1-4.7,0.2c-0.6-0.8-1.9-0.8-2.3,0.3c-2.3,3.3-4.4,3.4-6.3,0.4c-0.7-1.2-2.8-0.4-2.3,1
			c1.6,4.2-7.1,2.2-8.5-0.2c-0.7-1.2-2.7-0.4-2.3,1c1.7,5.1-4.6,1.2-6.2-0.1c0,0-0.1,0-0.1-0.1c-0.3-1-0.9-2-1.7-2.8
			c-1.2-1.1-2.9,0.6-1.7,1.7c1.1,1.1,1.9,2.5,0,3.1c-1.7,0.6-2.9-0.5-3.6-1.9c-0.1-0.5-0.3-0.9-0.5-1.3c-0.7-1.2-2.7-0.4-2.3,1
			c0.1,0.5,0.3,0.9,0.5,1.3c0.7,2.8-3.5,2.7-3.1-1.3c0.1-1.3-1.7-1.6-2.3-0.6c-0.3,0.5-3.2,5.1-4.2,3.6c-1.1-1.5,0.9-3.4,2-4.1
			c1.3-0.8,0.1-3-1.2-2.1c-0.4,0.3-0.8,0.6-1.2,0.9c-0.4,0-0.8,0.2-1,0.6c-0.5,0.7-0.9,1.3-1.4,2c-1.2,1.7-2.3,1.6-3.3-0.4
			c0.6-1.2-0.9-2.6-1.9-1.5c-2.1,2.2-5.2,4.1-4-0.9c0.2-1-0.9-2.1-1.8-1.4c-0.9,0.6-1.7,1.3-2.5,2.1c-2.7,2.5-2.6-0.4-1.5-2.2
			c0.6-1-0.7-2.5-1.7-1.7c-1.3,1-2.5,1.9-3.8,2.8c-1.1,0.8-2.2,1.5-3.5,2c0-0.5,0-1,0-1.5c0.4-0.4,0.7-0.7,1.1-0.9
			c0.6-0.3,1.1-0.6,1.7-0.8c1.5-0.6,0.9-2.9-0.7-2.4c-0.5,0.1-1.1,0.4-1.8,0.8c-2.1,1.1-3.9,2.4-6,3.4c0.8-2.5,2.9-4.1,5.1-5.5
			c1.3-0.9,0.1-3-1.2-2.1c-0.1,0.1-0.2,0.2-0.3,0.2c0,0-0.1,0-0.1,0c-0.8,0.1-4.7,0.5-3.9-1.3c0.5-1,2.3-1.3,3.3-1.5
			c0.8-0.2,2.9-0.4,4.1-1c1,0.4,2,0.5,3.2,0.5c1.6,0,1.6-2.5,0-2.5c-2.6,0-4.3-1.1-5.1-3.2c2.7,0.3,5.3,1.3,8,2
			c0.9,0.2,1.8-0.6,1.5-1.5c-1.7-6.3,5.1-0.8,6.9,0.8c0.8,0.8,2.1,0.2,2.1-0.9c2.5-2.6,3.9-2.8,4.2-0.6c0.2,0.7,0.4,1.3,0.5,2
			c0.3,1.6,2.6,0.9,2.4-0.7c0,0,0,0,0,0c1-2.1,2.5-4.2,4.7-2.1c0.9,0.9,1.7,1.9,2.5,2.9c0.7,0.8,2.1,0.1,2.1-0.9
			c-0.2-5.8,5-2.8,6.6,0.3c0.4,0.8,1.7,0.8,2.1,0c0.4-0.9,1-1.7,1.9-2.3c0.7-0.5,4.8-2.5,3.1,1.4c-0.5,1.2,1,2.7,1.9,1.5
			c2-2.5,4.3-4,7.3-4.5c-0.2,0.8-0.4,1.6-1,2.6c-0.8,1.2,0.9,2.4,1.9,1.5c1.6-1.5,3.3-2.6,5.2-3.6c1.2-0.6,2.4-1,3.6-1.3
			c3.4,1.8,3.6,3.3,0.7,4.4c-1.3,0.9-0.1,3,1.2,2.1c0.7-0.5,2-1.5,3.1-2.7c0.4,0.1,0.7,0,1.1-0.3c1.6-1.5,3.5-2.3,5.5-2.8
			c-0.4,0.8-0.9,1.5-1.3,2.3c-1.3,0.9,0,3.1,1.2,2.1c2.9-2.1,5.8-3.9,9.3-4.4c-0.1,0.4-0.2,0.8-0.4,1.2c-0.5,1.4,1.5,2,2.3,1
			c0.8-1.1,1.7-2,2.6-2.9c0.9-0.8,1.9-1.4,2.9-2c2.9-1.9,4-0.4,3.5,4.6c-1.2,0.9-0.2,2.9,1.2,2.1c0.4-0.2,0.8-0.5,1.1-0.8
			c0.2-0.1,0.4-0.3,0.7-0.4c0.4-0.2,0.8-0.4,1.1-0.7c1.8-1.7,2.7-1.4,2.6,0.7c-1.5,0.6-0.9,2.7,0.7,2.4c2.5-0.6,6.1-1.7,8.4-0.2
			c-0.8,0.6-1.6,1.1-2.6,1.5c-1.3,0.6-0.4,2.7,1,2.3c0.4-0.2,6.4-1.6,5.5,1.3c-0.7-0.3-1.3-0.6-1.9-0.8c-1.5-0.5-2.1,1.8-0.7,2.4
			C526.2,213.4,530.6,216.8,526.8,216.8z"/>
                            <g>
			<path d="M433.4,213c-2.1-2.3-4.8-3.7-7.9-4.1c-0.7-0.1-1.3,0.5-0.9,1.2c0.9,1.6,0.7,2.5,2.4,3.5c0.2-0.5,0.4-1,0.6-1.5
				c-2.7-0.4-5.3-0.6-7.9,0c-0.5,0.1-0.7,0.7-0.5,1.2c1.6,3.4,6.2,2.6,9.3,2.5c1,0,1-1.6,0-1.6c-2.4,0-6.6,0.9-7.9-1.7
				c-0.2,0.4-0.3,0.8-0.5,1.2c2.4-0.6,4.7-0.4,7.1,0c0.8,0.1,1.5-1,0.6-1.5c-1.2-0.7-1.3-1.8-1.9-2.9c-0.3,0.4-0.6,0.8-0.9,1.2
				c2.8,0.4,5.2,1.6,7.2,3.7C432.9,214.9,434.1,213.7,433.4,213L433.4,213z"/>
		</g>
                            <g>
			<path d="M437.8,212.9c1.8-2.9,5.2-4.7,8.6-4.2c-0.2-0.4-0.5-0.8-0.7-1.2c-0.9,1.8-2,2.9-3.6,4c-0.9,0.6-0.1,1.8,0.8,1.4
				c2.6-1.3,5.7-2.9,8.5-1.1c-0.1-0.4-0.1-0.8-0.2-1.3c-1.1,0.9-2.1,1.8-3.4,2.4c0.3,0.5,0.5,0.9,0.8,1.4c2.6-1.6,6-2,8.8-0.7
				c-0.1-0.4-0.1-0.8-0.2-1.3c-0.7,0.4-1.3,0.9-2,1.3c-0.9,0.5-0.1,1.9,0.8,1.4c1.2-0.7,1.2-0.7,2.3-1.6c0.4-0.3,0.3-1.1-0.2-1.3
				c-3.4-1.6-7.3-1.2-10.5,0.7c-0.9,0.6-0.1,1.8,0.8,1.4c1.5-0.6,2.5-1.6,3.7-2.6c0.4-0.3,0.2-1-0.2-1.3c-3.3-2.2-6.9-0.5-10.1,1.1
				c0.3,0.5,0.5,0.9,0.8,1.4c1.9-1.2,3.2-2.6,4.2-4.6c0.3-0.6-0.1-1.1-0.7-1.2c-3.9-0.5-8,1.7-10,5
				C435.9,213,437.2,213.8,437.8,212.9L437.8,212.9z"/>
		</g>
                            <g>
			<path d="M467.1,214.3c1.7-2.5,5.2-2.7,7.9-2.5c-0.2-0.4-0.5-0.8-0.7-1.2c-0.5,0.6-2.3,3.5-3.2,3.5c0.1,0.5,0.3,1,0.4,1.5
				c3.4-1.9,6.8-2.3,10.7-2c-0.2-0.4-0.5-0.8-0.7-1.2c-0.9,1.4-2,1.9-3.5,2.5c-0.9,0.4-0.5,2,0.4,1.5c1.8-0.8,3.3-1.5,4.4-3.2
				c0.3-0.5-0.1-1.2-0.7-1.2c-4.1-0.3-7.8,0.2-11.5,2.3c-0.7,0.4-0.4,1.5,0.4,1.5c2,0,3.6-2.9,4.6-4.3c0.4-0.5-0.2-1.2-0.7-1.2
				c-3.4-0.3-7.2,0.3-9.3,3.3C465.1,214.3,466.5,215.1,467.1,214.3L467.1,214.3z"/>
		</g>
                            <g>
			<path d="M491.4,211.8c1.7-3,5.3-5.1,8.7-3.9c-0.1-0.4-0.2-0.9-0.4-1.3c-1.1,1.2-2,2.4-3.6,3c-1,0.4-0.6,1.9,0.4,1.6
				c3.5-1,6.8-1.9,10.5-1.4c-0.2-0.5-0.4-0.9-0.6-1.4c-1,1.1-2.2,1.8-3.6,2.3c-1,0.3-0.6,1.8,0.4,1.6c0.8-0.2,1.7-0.4,2.5-0.5
				c0.9-0.2,1.7-0.2,2.6-0.2c2-0.3,2.2,0.4,0.7,2.1c-1,0.1-1,1.7,0,1.6c1.8-0.2,2.8-0.8,4.1-2c0.3-0.2,0.3-0.7,0.1-1
				c-2.3-3.4-7.2-2.3-10.6-1.4c0.1,0.5,0.3,1,0.4,1.6c1.8-0.6,2.9-1.4,4.3-2.7c0.6-0.5,0.1-1.3-0.6-1.4c-3.8-0.5-7.3,0.4-10.9,1.5
				c0.1,0.5,0.3,1,0.4,1.6c1.9-0.7,2.9-1.9,4.3-3.4c0.4-0.5,0.2-1.2-0.4-1.3c-4.1-1.4-8.6,1-10.6,4.7
				C489.5,211.9,490.9,212.7,491.4,211.8L491.4,211.8z"/>
		</g>
	</g>
                        <g className="navidad">
		<path d="M418.6,211.6c1.6-3.2-0.4-4.9-3.2-5.4c0.5-0.5,0.5-1.1,0-1.7c-1.7-2.2-4.3-2.6-7-2.3c0.3-0.7,0.2-1.5-0.6-2.3
			c-1.7-1.7-3.9-1.6-6-0.8c0.5-2.1-0.3-4.1-3.3-4.8c-3.8-0.9-7.5,1.1-10.3,3.7c-0.2-0.2-0.5-0.4-0.9-0.4c-2.7,0-5.2,0.7-7.4,1.9
			c0-0.3,0.1-0.7,0.1-1c0-0.9-0.8-1.3-1.6-1.2c-2.5,0.4-4.8,1-6.8,2.2c-0.2-0.6-0.6-1.2-1.6-1.7c-4.1-1.8-8.6-0.3-12.3,2.2
			c0-0.3,0.1-0.5,0.1-0.8c0.1-0.7-0.3-1.6-1.2-1.6c-3,0.2-5.7,1.1-7.9,2.8c-0.9-2.9-6-2.1-8.8,1c-3.1-3.7-8.4-4.7-9.8-0.3
			c-3-3-5.6-3.9-8.1-0.5c-0.6-1.5-1.7-2.7-3.5-3.2c-2.5-0.7-4.1,1.4-4.8,3.6c-4.2-3.1-10.4-5.4-9.7,0.9c-2.8-0.8-5.5-1.7-8.4-1.6
			c-0.8,0-1.3,0.8-1.2,1.6c0.3,1.8,1,3.2,2.1,4.3c-0.6,0.1-1.2,0.2-1.8,0.4c-1.6,0.4-3.3,0.9-4.5,2c-1.2,1.1-1.3,3.2-0.2,4.5
			c0.7,0.8,1.6,1.2,2.6,1.3c-1.8,1.8-3.1,4-3.2,6.9c0,0.8,0.8,1.4,1.6,1.2c1.2-0.4,2.3-0.9,3.3-1.5c0.1,0.5,0.3,0.9,0.8,1.4
			c2,2,5.1,0.5,7.6-1.4c-0.1,1,0.1,1.9,1,2.5c2.2,1.6,4.3,0,6.2-1.7c0.2,3.5,2.8,4.1,5.9,2.2c0.1,0.8,0.6,1.5,1.7,1.8
			c1.8,0.5,3.4,0,4.8-0.9c0.1,0.5,0.3,0.9,0.6,1.3c1.9,2.6,4.5,0.7,6.4-1.4c0.7,1.8,2.3,3.1,4.6,2.7c1.5-0.2,2.3-1,2.8-1.9
			c1.3,0.9,2.9,1.2,4.7,0.6c1-0.3,1.7-0.9,2.1-1.6c3.6,2.4,9.1,4.1,9.5,0.2c3.6,1.9,9,2,10.3-0.8c2.6,1.6,5.9,1.1,7.6-1.6
			c2.9,2.4,7.1,2.6,8.1,0c2.7,2,6.2,2.5,9.5,1c1.8-0.9,2.6-2,2.5-3.1c4.6,1.8,9.5,4.7,13.9,2.1c1.3-0.8,1.5-1.7,1.2-2.5
			c1.8,0.2,3.6,0.1,5.3-0.4c1-0.3,1.4-0.8,1.4-1.3c2.9,0.7,6.3,0.9,7.8-1.1C421.8,214.8,420.5,213,418.6,211.6z"/>
                            <path className="st17" d="M415.8,215.8c-2.1,0-4.3-0.5-6.2-1.2c-1.4-0.5-2.1,1.5-0.9,2.3c0.1,0.2,0.3,0.3,0.4,0.4c-0.6,0-1.3,0-1.9,0
			c-1.7,0-3.4-0.2-5-0.5c-1.5-0.2-2.2,1.9-0.7,2.4c-1.3,1.5-2.6,1.7-4.1,0.8c-0.9-0.4-1.8-0.7-2.8-1c-2.1-0.7-4-1.6-6.1-2.1
			c-0.3-0.1-0.5,0-0.7,0c0,0,0,0,0,0c-1.5-0.6-2.1,1.8-0.7,2.4c3.2,1.2-3.6,2.7-2.8,2.6c-2.6,0.2-4.9-1.7-6.2-3.7
			c-0.9-1.4-2.8-0.2-2.1,1.2c2.5,5.5-4.1,1-4.7,0.2c-0.6-0.8-1.9-0.8-2.3,0.3c-2.3,3.3-4.4,3.4-6.3,0.4c-0.7-1.2-2.8-0.4-2.3,1
			c1.6,4.2-7.1,2.2-8.5-0.2c-0.7-1.2-2.7-0.4-2.3,1c1.7,5.1-4.6,1.2-6.2-0.1c0,0-0.1,0-0.1-0.1c-0.3-1-0.9-2-1.7-2.8
			c-1.2-1.1-2.9,0.6-1.7,1.7c1.1,1.1,1.9,2.5,0,3.1c-1.7,0.6-2.9-0.5-3.6-1.9c-0.1-0.5-0.3-0.9-0.5-1.3c-0.7-1.2-2.7-0.4-2.3,1
			c0.1,0.5,0.3,0.9,0.5,1.3c0.7,2.8-3.5,2.7-3.1-1.3c0.1-1.3-1.7-1.6-2.3-0.6c-0.3,0.5-3.2,5.1-4.2,3.6c-1.1-1.5,0.9-3.4,2-4.1
			c1.3-0.8,0.1-3-1.2-2.1c-0.4,0.3-0.8,0.6-1.2,0.9c-0.4,0-0.8,0.2-1,0.6c-0.5,0.7-0.9,1.3-1.4,2c-1.2,1.7-2.3,1.6-3.3-0.4
			c0.6-1.2-0.9-2.6-1.9-1.5c-2.1,2.2-5.2,4.1-4-0.9c0.2-1-0.9-2.1-1.8-1.4c-0.9,0.6-1.7,1.3-2.5,2.1c-2.7,2.5-2.6-0.4-1.5-2.2
			c0.6-1-0.7-2.5-1.7-1.7c-1.3,1-2.5,1.9-3.8,2.8c-1.1,0.8-2.2,1.5-3.5,2c0-0.5,0-1,0-1.5c0.4-0.4,0.7-0.7,1.1-0.9
			c0.6-0.3,1.1-0.6,1.7-0.8c1.5-0.6,0.9-2.9-0.7-2.4c-0.5,0.1-1.1,0.4-1.8,0.8c-2.1,1.1-3.9,2.4-6,3.4c0.8-2.5,2.9-4.1,5.1-5.5
			c1.3-0.9,0.1-3-1.2-2.1c-0.1,0.1-0.2,0.2-0.3,0.2c0,0-0.1,0-0.1,0c-0.8,0.1-4.7,0.5-3.9-1.3c0.5-1,2.3-1.3,3.3-1.5
			c0.8-0.2,2.9-0.4,4.1-1c1,0.4,2,0.5,3.2,0.5c1.6,0,1.6-2.5,0-2.5c-2.6,0-4.3-1.1-5.1-3.2c2.7,0.3,5.3,1.3,8,2
			c0.9,0.2,1.8-0.6,1.5-1.5c-1.7-6.3,5.1-0.8,6.9,0.8c0.8,0.8,2.1,0.2,2.1-0.9c2.5-2.6,3.9-2.8,4.2-0.6c0.2,0.7,0.4,1.3,0.5,2
			c0.3,1.6,2.6,0.9,2.4-0.7c0,0,0,0,0,0c1-2.1,2.5-4.2,4.7-2.1c0.9,0.9,1.7,1.9,2.5,2.9c0.7,0.8,2.1,0.1,2.1-0.9
			c-0.2-5.8,5-2.8,6.6,0.3c0.4,0.8,1.7,0.8,2.1,0c0.4-0.9,1-1.7,1.9-2.3c0.7-0.5,4.8-2.5,3.1,1.4c-0.5,1.2,1,2.7,1.9,1.5
			c2-2.5,4.3-4,7.3-4.5c-0.2,0.8-0.4,1.6-1,2.6c-0.8,1.2,0.9,2.4,1.9,1.5c1.6-1.5,3.3-2.6,5.2-3.6c1.2-0.6,2.4-1,3.6-1.3
			c3.4,1.8,3.6,3.3,0.7,4.4c-1.3,0.9-0.1,3,1.2,2.1c0.7-0.5,2-1.5,3.1-2.7c0.4,0.1,0.7,0,1.1-0.3c1.6-1.5,3.5-2.3,5.5-2.8
			c-0.4,0.8-0.9,1.5-1.3,2.3c-1.3,0.9,0,3.1,1.2,2.1c2.9-2.1,5.8-3.9,9.3-4.4c-0.1,0.4-0.2,0.8-0.4,1.2c-0.5,1.4,1.5,2,2.3,1
			c0.8-1.1,1.7-2,2.6-2.9c0.9-0.8,1.9-1.4,2.9-2c2.9-1.9,4-0.4,3.5,4.6c-1.2,0.9-0.2,2.9,1.2,2.1c0.4-0.2,0.8-0.5,1.1-0.8
			c0.2-0.1,0.4-0.3,0.7-0.4c0.4-0.2,0.8-0.4,1.1-0.7c1.8-1.7,2.7-1.4,2.6,0.7c-1.5,0.6-0.9,2.7,0.7,2.4c2.5-0.6,6.1-1.7,8.4-0.2
			c-0.8,0.6-1.6,1.1-2.6,1.5c-1.3,0.6-0.4,2.7,1,2.3c0.4-0.2,6.4-1.6,5.5,1.3c-0.7-0.3-1.3-0.6-1.9-0.8c-1.5-0.5-2.1,1.8-0.7,2.4
			C415.2,212.4,419.6,215.8,415.8,215.8z"/>
                            <g>
			<path d="M322.4,212c-2.1-2.3-4.8-3.7-7.9-4.1c-0.7-0.1-1.3,0.5-0.9,1.2c0.9,1.6,0.7,2.5,2.4,3.5c0.2-0.5,0.4-1,0.6-1.5
				c-2.7-0.4-5.3-0.6-7.9,0c-0.5,0.1-0.7,0.7-0.5,1.2c1.6,3.4,6.2,2.6,9.3,2.5c1,0,1-1.6,0-1.6c-2.4,0-6.6,0.9-7.9-1.7
				c-0.2,0.4-0.3,0.8-0.5,1.2c2.4-0.6,4.7-0.4,7.1,0c0.8,0.1,1.5-1,0.6-1.5c-1.2-0.7-1.3-1.8-1.9-2.9c-0.3,0.4-0.6,0.8-0.9,1.2
				c2.8,0.4,5.2,1.6,7.2,3.7C321.9,213.9,323.1,212.7,322.4,212L322.4,212z"/>
		</g>
                            <g>
			<path d="M326.8,211.9c1.8-2.9,5.2-4.7,8.6-4.2c-0.2-0.4-0.5-0.8-0.7-1.2c-0.9,1.8-2,2.9-3.6,4c-0.9,0.6-0.1,1.8,0.8,1.4
				c2.6-1.3,5.7-2.9,8.5-1.1c-0.1-0.4-0.1-0.8-0.2-1.3c-1.1,0.9-2.1,1.8-3.4,2.4c0.3,0.5,0.5,0.9,0.8,1.4c2.6-1.6,6-2,8.8-0.7
				c-0.1-0.4-0.1-0.8-0.2-1.3c-0.7,0.4-1.3,0.9-2,1.3c-0.9,0.5-0.1,1.9,0.8,1.4c1.2-0.7,1.2-0.7,2.3-1.6c0.4-0.3,0.3-1.1-0.2-1.3
				c-3.4-1.6-7.3-1.2-10.5,0.7c-0.9,0.6-0.1,1.8,0.8,1.4c1.5-0.6,2.5-1.6,3.7-2.6c0.4-0.3,0.2-1-0.2-1.3c-3.3-2.2-6.9-0.5-10.1,1.1
				c0.3,0.5,0.5,0.9,0.8,1.4c1.9-1.2,3.2-2.6,4.2-4.6c0.3-0.6-0.1-1.1-0.7-1.2c-3.9-0.5-8,1.7-10,5
				C324.9,212,326.2,212.8,326.8,211.9L326.8,211.9z"/>
		</g>
                            <g>
			<path d="M356.1,213.3c1.7-2.5,5.2-2.7,7.9-2.5c-0.2-0.4-0.5-0.8-0.7-1.2c-0.5,0.6-2.3,3.5-3.2,3.5c0.1,0.5,0.3,1,0.4,1.5
				c3.4-1.9,6.8-2.3,10.7-2c-0.2-0.4-0.5-0.8-0.7-1.2c-0.9,1.4-2,1.9-3.5,2.5c-0.9,0.4-0.5,2,0.4,1.5c1.8-0.8,3.3-1.5,4.4-3.2
				c0.3-0.5-0.1-1.2-0.7-1.2c-4.1-0.3-7.8,0.2-11.5,2.3c-0.7,0.4-0.4,1.5,0.4,1.5c2,0,3.6-2.9,4.6-4.3c0.4-0.5-0.2-1.2-0.7-1.2
				c-3.4-0.3-7.2,0.3-9.3,3.3C354.1,213.3,355.5,214.1,356.1,213.3L356.1,213.3z"/>
		</g>
                            <g>
			<path d="M380.4,210.8c1.7-3,5.3-5.1,8.7-3.9c-0.1-0.4-0.2-0.9-0.4-1.3c-1.1,1.2-2,2.4-3.6,3c-1,0.4-0.6,1.9,0.4,1.6
				c3.5-1,6.8-1.9,10.5-1.4c-0.2-0.5-0.4-0.9-0.6-1.4c-1,1.1-2.2,1.8-3.6,2.3c-1,0.3-0.6,1.8,0.4,1.6c0.8-0.2,1.7-0.4,2.5-0.5
				c0.9-0.2,1.7-0.2,2.6-0.2c2-0.3,2.2,0.4,0.7,2.1c-1,0.1-1,1.7,0,1.6c1.8-0.2,2.8-0.8,4.1-2c0.3-0.2,0.3-0.7,0.1-1
				c-2.3-3.4-7.2-2.3-10.6-1.4c0.1,0.5,0.3,1,0.4,1.6c1.8-0.6,2.9-1.4,4.3-2.7c0.6-0.5,0.1-1.3-0.6-1.4c-3.8-0.5-7.3,0.4-10.9,1.5
				c0.1,0.5,0.3,1,0.4,1.6c1.9-0.7,2.9-1.9,4.3-3.4c0.4-0.5,0.2-1.2-0.4-1.3c-4.1-1.4-8.6,1-10.6,4.7
				C378.5,210.9,379.9,211.7,380.4,210.8L380.4,210.8z"/>
		</g>
	</g>
                        <g className="navidad">
		<path d="M377.6,556.6c1.6-3.2-0.4-4.9-3.2-5.4c0.5-0.5,0.5-1.1,0-1.7c-1.7-2.2-4.3-2.6-7-2.3c0.3-0.7,0.2-1.5-0.6-2.3
			c-1.7-1.7-3.9-1.6-6-0.8c0.5-2.1-0.3-4.1-3.3-4.8c-3.8-0.9-7.5,1.1-10.3,3.7c-0.2-0.2-0.5-0.4-0.9-0.4c-2.7,0-5.2,0.7-7.4,1.9
			c0-0.3,0.1-0.7,0.1-1c0-0.9-0.8-1.3-1.6-1.2c-2.5,0.4-4.8,1-6.8,2.2c-0.2-0.6-0.6-1.2-1.6-1.7c-4.1-1.8-8.6-0.3-12.3,2.2
			c0-0.3,0.1-0.5,0.1-0.8c0.1-0.7-0.3-1.6-1.2-1.6c-3,0.2-5.7,1.1-7.9,2.8c-0.9-2.9-6-2.1-8.8,1c-3.1-3.7-8.4-4.7-9.8-0.3
			c-3-3-5.6-3.9-8.1-0.5c-0.6-1.5-1.7-2.7-3.5-3.2c-2.5-0.7-4.1,1.4-4.8,3.6c-4.2-3.1-10.4-5.4-9.7,0.9c-2.8-0.8-5.5-1.7-8.4-1.6
			c-0.8,0-1.3,0.8-1.2,1.6c0.3,1.8,1,3.2,2.1,4.3c-0.6,0.1-1.2,0.2-1.8,0.4c-1.6,0.4-3.3,0.9-4.5,2c-1.2,1.1-1.3,3.2-0.2,4.5
			c0.7,0.8,1.6,1.2,2.6,1.3c-1.8,1.8-3.1,4-3.2,6.9c0,0.8,0.8,1.4,1.6,1.2c1.2-0.4,2.3-0.9,3.3-1.5c0.1,0.5,0.3,0.9,0.8,1.4
			c2,2,5.1,0.5,7.6-1.4c-0.1,1,0.1,1.9,1,2.5c2.2,1.6,4.3,0,6.2-1.7c0.2,3.5,2.8,4.1,5.9,2.2c0.1,0.8,0.6,1.5,1.7,1.8
			c1.8,0.5,3.4,0,4.8-0.9c0.1,0.5,0.3,0.9,0.6,1.3c1.9,2.6,4.5,0.7,6.4-1.4c0.7,1.8,2.3,3.1,4.6,2.7c1.5-0.2,2.3-1,2.8-1.9
			c1.3,0.9,2.9,1.2,4.7,0.6c1-0.3,1.7-0.9,2.1-1.6c3.6,2.4,9.1,4.1,9.5,0.2c3.6,1.9,9,2,10.3-0.8c2.6,1.6,5.9,1.1,7.6-1.6
			c2.9,2.4,7.1,2.6,8.1,0c2.7,2,6.2,2.5,9.5,1c1.8-0.9,2.6-2,2.5-3.1c4.6,1.8,9.5,4.7,13.9,2.1c1.3-0.8,1.5-1.7,1.2-2.5
			c1.8,0.2,3.6,0.1,5.3-0.4c1-0.3,1.4-0.8,1.4-1.3c2.9,0.7,6.3,0.9,7.8-1.1C380.8,559.8,379.5,558,377.6,556.6z"/>
                            <path className="st17" d="M374.8,560.8c-2.1,0-4.3-0.5-6.2-1.2c-1.4-0.5-2.1,1.5-0.9,2.3c0.1,0.2,0.3,0.3,0.4,0.4c-0.6,0-1.3,0-1.9,0
			c-1.7,0-3.4-0.2-5-0.5c-1.5-0.2-2.2,1.9-0.7,2.4c-1.3,1.5-2.6,1.7-4.1,0.8c-0.9-0.4-1.8-0.7-2.8-1c-2.1-0.7-4-1.6-6.1-2.1
			c-0.3-0.1-0.5,0-0.7,0c0,0,0,0,0,0c-1.5-0.6-2.1,1.8-0.7,2.4c3.2,1.2-3.6,2.7-2.8,2.6c-2.6,0.2-4.9-1.7-6.2-3.7
			c-0.9-1.4-2.8-0.2-2.1,1.2c2.5,5.5-4.1,1-4.7,0.2c-0.6-0.8-1.9-0.8-2.3,0.3c-2.3,3.3-4.4,3.4-6.3,0.4c-0.7-1.2-2.8-0.4-2.3,1
			c1.6,4.2-7.1,2.2-8.5-0.2c-0.7-1.2-2.7-0.4-2.3,1c1.7,5.1-4.6,1.2-6.2-0.1c0,0-0.1,0-0.1-0.1c-0.3-1-0.9-2-1.7-2.8
			c-1.2-1.1-2.9,0.6-1.7,1.7c1.1,1.1,1.9,2.5,0,3.1c-1.7,0.6-2.9-0.5-3.6-1.9c-0.1-0.5-0.3-0.9-0.5-1.3c-0.7-1.2-2.7-0.4-2.3,1
			c0.1,0.5,0.3,0.9,0.5,1.3c0.7,2.8-3.5,2.7-3.1-1.3c0.1-1.3-1.7-1.6-2.3-0.6c-0.3,0.5-3.2,5.1-4.2,3.6c-1.1-1.5,0.9-3.4,2-4.1
			c1.3-0.8,0.1-3-1.2-2.1c-0.4,0.3-0.8,0.6-1.2,0.9c-0.4,0-0.8,0.2-1,0.6c-0.5,0.7-0.9,1.3-1.4,2c-1.2,1.7-2.3,1.6-3.3-0.4
			c0.6-1.2-0.9-2.6-1.9-1.5c-2.1,2.2-5.2,4.1-4-0.9c0.2-1-0.9-2.1-1.8-1.4c-0.9,0.6-1.7,1.3-2.5,2.1c-2.7,2.5-2.6-0.4-1.5-2.2
			c0.6-1-0.7-2.5-1.7-1.7c-1.3,1-2.5,1.9-3.8,2.8c-1.1,0.8-2.2,1.5-3.5,2c0-0.5,0-1,0-1.5c0.4-0.4,0.7-0.7,1.1-0.9
			c0.6-0.3,1.1-0.6,1.7-0.8c1.5-0.6,0.9-2.9-0.7-2.4c-0.5,0.1-1.1,0.4-1.8,0.8c-2.1,1.1-3.9,2.4-6,3.4c0.8-2.5,2.9-4.1,5.1-5.5
			c1.3-0.9,0.1-3-1.2-2.1c-0.1,0.1-0.2,0.2-0.3,0.2c0,0-0.1,0-0.1,0c-0.8,0.1-4.7,0.5-3.9-1.3c0.5-1,2.3-1.3,3.3-1.5
			c0.8-0.2,2.9-0.4,4.1-1c1,0.4,2,0.5,3.2,0.5c1.6,0,1.6-2.5,0-2.5c-2.6,0-4.3-1.1-5.1-3.2c2.7,0.3,5.3,1.3,8,2
			c0.9,0.2,1.8-0.6,1.5-1.5c-1.7-6.3,5.1-0.8,6.9,0.8c0.8,0.8,2.1,0.2,2.1-0.9c2.5-2.6,3.9-2.8,4.2-0.6c0.2,0.7,0.4,1.3,0.5,2
			c0.3,1.6,2.6,0.9,2.4-0.7c0,0,0,0,0,0c1-2.1,2.5-4.2,4.7-2.1c0.9,0.9,1.7,1.9,2.5,2.9c0.7,0.8,2.1,0.1,2.1-0.9
			c-0.2-5.8,5-2.8,6.6,0.3c0.4,0.8,1.7,0.8,2.1,0c0.4-0.9,1-1.7,1.9-2.3c0.7-0.5,4.8-2.5,3.1,1.4c-0.5,1.2,1,2.7,1.9,1.5
			c2-2.5,4.3-4,7.3-4.5c-0.2,0.8-0.4,1.6-1,2.6c-0.8,1.2,0.9,2.4,1.9,1.5c1.6-1.5,3.3-2.6,5.2-3.6c1.2-0.6,2.4-1,3.6-1.3
			c3.4,1.8,3.6,3.3,0.7,4.4c-1.3,0.9-0.1,3,1.2,2.1c0.7-0.5,2-1.5,3.1-2.7c0.4,0.1,0.7,0,1.1-0.3c1.6-1.5,3.5-2.3,5.5-2.8
			c-0.4,0.8-0.9,1.5-1.3,2.3c-1.3,0.9,0,3.1,1.2,2.1c2.9-2.1,5.8-3.9,9.3-4.4c-0.1,0.4-0.2,0.8-0.4,1.2c-0.5,1.4,1.5,2,2.3,1
			c0.8-1.1,1.7-2,2.6-2.9c0.9-0.8,1.9-1.4,2.9-2c2.9-1.9,4-0.4,3.5,4.6c-1.2,0.9-0.2,2.9,1.2,2.1c0.4-0.2,0.8-0.5,1.1-0.8
			c0.2-0.1,0.4-0.3,0.7-0.4c0.4-0.2,0.8-0.4,1.1-0.7c1.8-1.7,2.7-1.4,2.6,0.7c-1.5,0.6-0.9,2.7,0.7,2.4c2.5-0.6,6.1-1.7,8.4-0.2
			c-0.8,0.6-1.6,1.1-2.6,1.5c-1.3,0.6-0.4,2.7,1,2.3c0.4-0.2,6.4-1.6,5.5,1.3c-0.7-0.3-1.3-0.6-1.9-0.8c-1.5-0.5-2.1,1.8-0.7,2.4
			C374.2,557.4,378.6,560.8,374.8,560.8z"/>
                            <g>
			<path d="M281.4,557c-2.1-2.3-4.8-3.7-7.9-4.1c-0.7-0.1-1.3,0.5-0.9,1.2c0.9,1.6,0.7,2.5,2.4,3.5c0.2-0.5,0.4-1,0.6-1.5
				c-2.7-0.4-5.3-0.6-7.9,0c-0.5,0.1-0.7,0.7-0.5,1.2c1.6,3.4,6.2,2.6,9.3,2.5c1,0,1-1.6,0-1.6c-2.4,0-6.6,0.9-7.9-1.7
				c-0.2,0.4-0.3,0.8-0.5,1.2c2.4-0.6,4.7-0.4,7.1,0c0.8,0.1,1.5-1,0.6-1.5c-1.2-0.7-1.3-1.8-1.9-2.9c-0.3,0.4-0.6,0.8-0.9,1.2
				c2.8,0.4,5.2,1.6,7.2,3.7C280.9,558.9,282.1,557.7,281.4,557L281.4,557z"/>
		</g>
                            <g>
			<path d="M285.8,556.9c1.8-2.9,5.2-4.7,8.6-4.2c-0.2-0.4-0.5-0.8-0.7-1.2c-0.9,1.8-2,2.9-3.6,4c-0.9,0.6-0.1,1.8,0.8,1.4
				c2.6-1.3,5.7-2.9,8.5-1.1c-0.1-0.4-0.1-0.8-0.2-1.3c-1.1,0.9-2.1,1.8-3.4,2.4c0.3,0.5,0.5,0.9,0.8,1.4c2.6-1.6,6-2,8.8-0.7
				c-0.1-0.4-0.1-0.8-0.2-1.3c-0.7,0.4-1.3,0.9-2,1.3c-0.9,0.5-0.1,1.9,0.8,1.4c1.2-0.7,1.2-0.7,2.3-1.6c0.4-0.3,0.3-1.1-0.2-1.3
				c-3.4-1.6-7.3-1.2-10.5,0.7c-0.9,0.6-0.1,1.8,0.8,1.4c1.5-0.6,2.5-1.6,3.7-2.6c0.4-0.3,0.2-1-0.2-1.3c-3.3-2.2-6.9-0.5-10.1,1.1
				c0.3,0.5,0.5,0.9,0.8,1.4c1.9-1.2,3.2-2.6,4.2-4.6c0.3-0.6-0.1-1.1-0.7-1.2c-3.9-0.5-8,1.7-10,5
				C283.9,557,285.2,557.8,285.8,556.9L285.8,556.9z"/>
		</g>
                            <g>
			<path d="M315.1,558.3c1.7-2.5,5.2-2.7,7.9-2.5c-0.2-0.4-0.5-0.8-0.7-1.2c-0.5,0.6-2.3,3.5-3.2,3.5c0.1,0.5,0.3,1,0.4,1.5
				c3.4-1.9,6.8-2.3,10.7-2c-0.2-0.4-0.5-0.8-0.7-1.2c-0.9,1.4-2,1.9-3.5,2.5c-0.9,0.4-0.5,2,0.4,1.5c1.8-0.8,3.3-1.5,4.4-3.2
				c0.3-0.5-0.1-1.2-0.7-1.2c-4.1-0.3-7.8,0.2-11.5,2.3c-0.7,0.4-0.4,1.5,0.4,1.5c2,0,3.6-2.9,4.6-4.3c0.4-0.5-0.2-1.2-0.7-1.2
				c-3.4-0.3-7.2,0.3-9.3,3.3C313.1,558.3,314.5,559.1,315.1,558.3L315.1,558.3z"/>
		</g>
                            <g>
			<path d="M339.4,555.8c1.7-3,5.3-5.1,8.7-3.9c-0.1-0.4-0.2-0.9-0.4-1.3c-1.1,1.2-2,2.4-3.6,3c-1,0.4-0.6,1.9,0.4,1.6
				c3.5-1,6.8-1.9,10.5-1.4c-0.2-0.5-0.4-0.9-0.6-1.4c-1,1.1-2.2,1.8-3.6,2.3c-1,0.3-0.6,1.8,0.4,1.6c0.8-0.2,1.7-0.4,2.5-0.5
				c0.9-0.2,1.7-0.2,2.6-0.2c2-0.3,2.2,0.4,0.7,2.1c-1,0.1-1,1.7,0,1.6c1.8-0.2,2.8-0.8,4.1-2c0.3-0.2,0.3-0.7,0.1-1
				c-2.3-3.4-7.2-2.3-10.6-1.4c0.1,0.5,0.3,1,0.4,1.6c1.8-0.6,2.9-1.4,4.3-2.7c0.6-0.5,0.1-1.3-0.6-1.4c-3.8-0.5-7.3,0.4-10.9,1.5
				c0.1,0.5,0.3,1,0.4,1.6c1.9-0.7,2.9-1.9,4.3-3.4c0.4-0.5,0.2-1.2-0.4-1.3c-4.1-1.4-8.6,1-10.6,4.7
				C337.5,555.9,338.9,556.7,339.4,555.8L339.4,555.8z"/>
		</g>
	</g>
                        <g className="navidad">
		<path d="M364.6,573.6c1.6-3.2-0.4-4.9-3.2-5.4c0.5-0.5,0.5-1.1,0-1.7c-1.7-2.2-4.3-2.6-7-2.3c0.3-0.7,0.2-1.5-0.6-2.3
			c-1.7-1.7-3.9-1.6-6-0.8c0.5-2.1-0.3-4.1-3.3-4.8c-3.8-0.9-7.5,1.1-10.3,3.7c-0.2-0.2-0.5-0.4-0.9-0.4c-2.7,0-5.2,0.7-7.4,1.9
			c0-0.3,0.1-0.7,0.1-1c0-0.9-0.8-1.3-1.6-1.2c-2.5,0.4-4.8,1-6.8,2.2c-0.2-0.6-0.6-1.2-1.6-1.7c-4.1-1.8-8.6-0.3-12.3,2.2
			c0-0.3,0.1-0.5,0.1-0.8c0.1-0.7-0.3-1.6-1.2-1.6c-3,0.2-5.7,1.1-7.9,2.8c-0.9-2.9-6-2.1-8.8,1c-3.1-3.7-8.4-4.7-9.8-0.3
			c-3-3-5.6-3.9-8.1-0.5c-0.6-1.5-1.7-2.7-3.5-3.2c-2.5-0.7-4.1,1.4-4.8,3.6c-4.2-3.1-10.4-5.4-9.7,0.9c-2.8-0.8-5.5-1.7-8.4-1.6
			c-0.8,0-1.3,0.8-1.2,1.6c0.3,1.8,1,3.2,2.1,4.3c-0.6,0.1-1.2,0.2-1.8,0.4c-1.6,0.4-3.3,0.9-4.5,2c-1.2,1.1-1.3,3.2-0.2,4.5
			c0.7,0.8,1.6,1.2,2.6,1.3c-1.8,1.8-3.1,4-3.2,6.9c0,0.8,0.8,1.4,1.6,1.2c1.2-0.4,2.3-0.9,3.3-1.5c0.1,0.5,0.3,0.9,0.8,1.4
			c2,2,5.1,0.5,7.6-1.4c-0.1,1,0.1,1.9,1,2.5c2.2,1.6,4.3,0,6.2-1.7c0.2,3.5,2.8,4.1,5.9,2.2c0.1,0.8,0.6,1.5,1.7,1.8
			c1.8,0.5,3.4,0,4.8-0.9c0.1,0.5,0.3,0.9,0.6,1.3c1.9,2.6,4.5,0.7,6.4-1.4c0.7,1.8,2.3,3.1,4.6,2.7c1.5-0.2,2.3-1,2.8-1.9
			c1.3,0.9,2.9,1.2,4.7,0.6c1-0.3,1.7-0.9,2.1-1.6c3.6,2.4,9.1,4.1,9.5,0.2c3.6,1.9,9,2,10.3-0.8c2.6,1.6,5.9,1.1,7.6-1.6
			c2.9,2.4,7.1,2.6,8.1,0c2.7,2,6.2,2.5,9.5,1c1.8-0.9,2.6-2,2.5-3.1c4.6,1.8,9.5,4.7,13.9,2.1c1.3-0.8,1.5-1.7,1.2-2.5
			c1.8,0.2,3.6,0.1,5.3-0.4c1-0.3,1.4-0.8,1.4-1.3c2.9,0.7,6.3,0.9,7.8-1.1C367.8,576.8,366.5,575,364.6,573.6z"/>
                            <path className="st17" d="M361.8,577.8c-2.1,0-4.3-0.5-6.2-1.2c-1.4-0.5-2.1,1.5-0.9,2.3c0.1,0.2,0.3,0.3,0.4,0.4c-0.6,0-1.3,0-1.9,0
			c-1.7,0-3.4-0.2-5-0.5c-1.5-0.2-2.2,1.9-0.7,2.4c-1.3,1.5-2.6,1.7-4.1,0.8c-0.9-0.4-1.8-0.7-2.8-1c-2.1-0.7-4-1.6-6.1-2.1
			c-0.3-0.1-0.5,0-0.7,0c0,0,0,0,0,0c-1.5-0.6-2.1,1.8-0.7,2.4c3.2,1.2-3.6,2.7-2.8,2.6c-2.6,0.2-4.9-1.7-6.2-3.7
			c-0.9-1.4-2.8-0.2-2.1,1.2c2.5,5.5-4.1,1-4.7,0.2c-0.6-0.8-1.9-0.8-2.3,0.3c-2.3,3.3-4.4,3.4-6.3,0.4c-0.7-1.2-2.8-0.4-2.3,1
			c1.6,4.2-7.1,2.2-8.5-0.2c-0.7-1.2-2.7-0.4-2.3,1c1.7,5.1-4.6,1.2-6.2-0.1c0,0-0.1,0-0.1-0.1c-0.3-1-0.9-2-1.7-2.8
			c-1.2-1.1-2.9,0.6-1.7,1.7c1.1,1.1,1.9,2.5,0,3.1c-1.7,0.6-2.9-0.5-3.6-1.9c-0.1-0.5-0.3-0.9-0.5-1.3c-0.7-1.2-2.7-0.4-2.3,1
			c0.1,0.5,0.3,0.9,0.5,1.3c0.7,2.8-3.5,2.7-3.1-1.3c0.1-1.3-1.7-1.6-2.3-0.6c-0.3,0.5-3.2,5.1-4.2,3.6c-1.1-1.5,0.9-3.4,2-4.1
			c1.3-0.8,0.1-3-1.2-2.1c-0.4,0.3-0.8,0.6-1.2,0.9c-0.4,0-0.8,0.2-1,0.6c-0.5,0.7-0.9,1.3-1.4,2c-1.2,1.7-2.3,1.6-3.3-0.4
			c0.6-1.2-0.9-2.6-1.9-1.5c-2.1,2.2-5.2,4.1-4-0.9c0.2-1-0.9-2.1-1.8-1.4c-0.9,0.6-1.7,1.3-2.5,2.1c-2.7,2.5-2.6-0.4-1.5-2.2
			c0.6-1-0.7-2.5-1.7-1.7c-1.3,1-2.5,1.9-3.8,2.8c-1.1,0.8-2.2,1.5-3.5,2c0-0.5,0-1,0-1.5c0.4-0.4,0.7-0.7,1.1-0.9
			c0.6-0.3,1.1-0.6,1.7-0.8c1.5-0.6,0.9-2.9-0.7-2.4c-0.5,0.1-1.1,0.4-1.8,0.8c-2.1,1.1-3.9,2.4-6,3.4c0.8-2.5,2.9-4.1,5.1-5.5
			c1.3-0.9,0.1-3-1.2-2.1c-0.1,0.1-0.2,0.2-0.3,0.2c0,0-0.1,0-0.1,0c-0.8,0.1-4.7,0.5-3.9-1.3c0.5-1,2.3-1.3,3.3-1.5
			c0.8-0.2,2.9-0.4,4.1-1c1,0.4,2,0.5,3.2,0.5c1.6,0,1.6-2.5,0-2.5c-2.6,0-4.3-1.1-5.1-3.2c2.7,0.3,5.3,1.3,8,2
			c0.9,0.2,1.8-0.6,1.5-1.5c-1.7-6.3,5.1-0.8,6.9,0.8c0.8,0.8,2.1,0.2,2.1-0.9c2.5-2.6,3.9-2.8,4.2-0.6c0.2,0.7,0.4,1.3,0.5,2
			c0.3,1.6,2.6,0.9,2.4-0.7c0,0,0,0,0,0c1-2.1,2.5-4.2,4.7-2.1c0.9,0.9,1.7,1.9,2.5,2.9c0.7,0.8,2.1,0.1,2.1-0.9
			c-0.2-5.8,5-2.8,6.6,0.3c0.4,0.8,1.7,0.8,2.1,0c0.4-0.9,1-1.7,1.9-2.3c0.7-0.5,4.8-2.5,3.1,1.4c-0.5,1.2,1,2.7,1.9,1.5
			c2-2.5,4.3-4,7.3-4.5c-0.2,0.8-0.4,1.6-1,2.6c-0.8,1.2,0.9,2.4,1.9,1.5c1.6-1.5,3.3-2.6,5.2-3.6c1.2-0.6,2.4-1,3.6-1.3
			c3.4,1.8,3.6,3.3,0.7,4.4c-1.3,0.9-0.1,3,1.2,2.1c0.7-0.5,2-1.5,3.1-2.7c0.4,0.1,0.7,0,1.1-0.3c1.6-1.5,3.5-2.3,5.5-2.8
			c-0.4,0.8-0.9,1.5-1.3,2.3c-1.3,0.9,0,3.1,1.2,2.1c2.9-2.1,5.8-3.9,9.3-4.4c-0.1,0.4-0.2,0.8-0.4,1.2c-0.5,1.4,1.5,2,2.3,1
			c0.8-1.1,1.7-2,2.6-2.9c0.9-0.8,1.9-1.4,2.9-2c2.9-1.9,4-0.4,3.5,4.6c-1.2,0.9-0.2,2.9,1.2,2.1c0.4-0.2,0.8-0.5,1.1-0.8
			c0.2-0.1,0.4-0.3,0.7-0.4c0.4-0.2,0.8-0.4,1.1-0.7c1.8-1.7,2.7-1.4,2.6,0.7c-1.5,0.6-0.9,2.7,0.7,2.4c2.5-0.6,6.1-1.7,8.4-0.2
			c-0.8,0.6-1.6,1.1-2.6,1.5c-1.3,0.6-0.4,2.7,1,2.3c0.4-0.2,6.4-1.6,5.5,1.3c-0.7-0.3-1.3-0.6-1.9-0.8c-1.5-0.5-2.1,1.8-0.7,2.4
			C361.2,574.4,365.6,577.8,361.8,577.8z"/>
                            <g>
			<path d="M268.4,574c-2.1-2.3-4.8-3.7-7.9-4.1c-0.7-0.1-1.3,0.5-0.9,1.2c0.9,1.6,0.7,2.5,2.4,3.5c0.2-0.5,0.4-1,0.6-1.5
				c-2.7-0.4-5.3-0.6-7.9,0c-0.5,0.1-0.7,0.7-0.5,1.2c1.6,3.4,6.2,2.6,9.3,2.5c1,0,1-1.6,0-1.6c-2.4,0-6.6,0.9-7.9-1.7
				c-0.2,0.4-0.3,0.8-0.5,1.2c2.4-0.6,4.7-0.4,7.1,0c0.8,0.1,1.5-1,0.6-1.5c-1.2-0.7-1.3-1.8-1.9-2.9c-0.3,0.4-0.6,0.8-0.9,1.2
				c2.8,0.4,5.2,1.6,7.2,3.7C267.9,575.9,269.1,574.7,268.4,574L268.4,574z"/>
		</g>
                            <g>
			<path d="M272.8,573.9c1.8-2.9,5.2-4.7,8.6-4.2c-0.2-0.4-0.5-0.8-0.7-1.2c-0.9,1.8-2,2.9-3.6,4c-0.9,0.6-0.1,1.8,0.8,1.4
				c2.6-1.3,5.7-2.9,8.5-1.1c-0.1-0.4-0.1-0.8-0.2-1.3c-1.1,0.9-2.1,1.8-3.4,2.4c0.3,0.5,0.5,0.9,0.8,1.4c2.6-1.6,6-2,8.8-0.7
				c-0.1-0.4-0.1-0.8-0.2-1.3c-0.7,0.4-1.3,0.9-2,1.3c-0.9,0.5-0.1,1.9,0.8,1.4c1.2-0.7,1.2-0.7,2.3-1.6c0.4-0.3,0.3-1.1-0.2-1.3
				c-3.4-1.6-7.3-1.2-10.5,0.7c-0.9,0.6-0.1,1.8,0.8,1.4c1.5-0.6,2.5-1.6,3.7-2.6c0.4-0.3,0.2-1-0.2-1.3c-3.3-2.2-6.9-0.5-10.1,1.1
				c0.3,0.5,0.5,0.9,0.8,1.4c1.9-1.2,3.2-2.6,4.2-4.6c0.3-0.6-0.1-1.1-0.7-1.2c-3.9-0.5-8,1.7-10,5
				C270.9,574,272.2,574.8,272.8,573.9L272.8,573.9z"/>
		</g>
                            <g>
			<path d="M302.1,575.3c1.7-2.5,5.2-2.7,7.9-2.5c-0.2-0.4-0.5-0.8-0.7-1.2c-0.5,0.6-2.3,3.5-3.2,3.5c0.1,0.5,0.3,1,0.4,1.5
				c3.4-1.9,6.8-2.3,10.7-2c-0.2-0.4-0.5-0.8-0.7-1.2c-0.9,1.4-2,1.9-3.5,2.5c-0.9,0.4-0.5,2,0.4,1.5c1.8-0.8,3.3-1.5,4.4-3.2
				c0.3-0.5-0.1-1.2-0.7-1.2c-4.1-0.3-7.8,0.2-11.5,2.3c-0.7,0.4-0.4,1.5,0.4,1.5c2,0,3.6-2.9,4.6-4.3c0.4-0.5-0.2-1.2-0.7-1.2
				c-3.4-0.3-7.2,0.3-9.3,3.3C300.1,575.3,301.5,576.1,302.1,575.3L302.1,575.3z"/>
		</g>
                            <g>
			<path d="M326.4,572.8c1.7-3,5.3-5.1,8.7-3.9c-0.1-0.4-0.2-0.9-0.4-1.3c-1.1,1.2-2,2.4-3.6,3c-1,0.4-0.6,1.9,0.4,1.6
				c3.5-1,6.8-1.9,10.5-1.4c-0.2-0.5-0.4-0.9-0.6-1.4c-1,1.1-2.2,1.8-3.6,2.3c-1,0.3-0.6,1.8,0.4,1.6c0.8-0.2,1.7-0.4,2.5-0.5
				c0.9-0.2,1.7-0.2,2.6-0.2c2-0.3,2.2,0.4,0.7,2.1c-1,0.1-1,1.7,0,1.6c1.8-0.2,2.8-0.8,4.1-2c0.3-0.2,0.3-0.7,0.1-1
				c-2.3-3.4-7.2-2.3-10.6-1.4c0.1,0.5,0.3,1,0.4,1.6c1.8-0.6,2.9-1.4,4.3-2.7c0.6-0.5,0.1-1.3-0.6-1.4c-3.8-0.5-7.3,0.4-10.9,1.5
				c0.1,0.5,0.3,1,0.4,1.6c1.9-0.7,2.9-1.9,4.3-3.4c0.4-0.5,0.2-1.2-0.4-1.3c-4.1-1.4-8.6,1-10.6,4.7
				C324.5,572.9,325.9,573.7,326.4,572.8L326.4,572.8z"/>
		</g>
	</g>
                        <g className="navidad">
		<path d="M295.6,565.6c1.6-3.2-0.4-4.9-3.2-5.4c0.5-0.5,0.5-1.1,0-1.7c-1.7-2.2-4.3-2.6-7-2.3c0.3-0.7,0.2-1.5-0.6-2.3
			c-1.7-1.7-3.9-1.6-6-0.8c0.5-2.1-0.3-4.1-3.3-4.8c-3.8-0.9-7.5,1.1-10.3,3.7c-0.2-0.2-0.5-0.4-0.9-0.4c-2.7,0-5.2,0.7-7.4,1.9
			c0-0.3,0.1-0.7,0.1-1c0-0.9-0.8-1.3-1.6-1.2c-2.5,0.4-4.8,1-6.8,2.2c-0.2-0.6-0.6-1.2-1.6-1.7c-4.1-1.8-8.6-0.3-12.3,2.2
			c0-0.3,0.1-0.5,0.1-0.8c0.1-0.7-0.3-1.6-1.2-1.6c-3,0.2-5.7,1.1-7.9,2.8c-0.9-2.9-6-2.1-8.8,1c-3.1-3.7-8.4-4.7-9.8-0.3
			c-3-3-5.6-3.9-8.1-0.5c-0.6-1.5-1.7-2.7-3.5-3.2c-2.5-0.7-4.1,1.4-4.8,3.6c-4.2-3.1-10.4-5.4-9.7,0.9c-2.8-0.8-5.5-1.7-8.4-1.6
			c-0.8,0-1.3,0.8-1.2,1.6c0.3,1.8,1,3.2,2.1,4.3c-0.6,0.1-1.2,0.2-1.8,0.4c-1.6,0.4-3.3,0.9-4.5,2c-1.2,1.1-1.3,3.2-0.2,4.5
			c0.7,0.8,1.6,1.2,2.6,1.3c-1.8,1.8-3.1,4-3.2,6.9c0,0.8,0.8,1.4,1.6,1.2c1.2-0.4,2.3-0.9,3.3-1.5c0.1,0.5,0.3,0.9,0.8,1.4
			c2,2,5.1,0.5,7.6-1.4c-0.1,1,0.1,1.9,1,2.5c2.2,1.6,4.3,0,6.2-1.7c0.2,3.5,2.8,4.1,5.9,2.2c0.1,0.8,0.6,1.5,1.7,1.8
			c1.8,0.5,3.4,0,4.8-0.9c0.1,0.5,0.3,0.9,0.6,1.3c1.9,2.6,4.5,0.7,6.4-1.4c0.7,1.8,2.3,3.1,4.6,2.7c1.5-0.2,2.3-1,2.8-1.9
			c1.3,0.9,2.9,1.2,4.7,0.6c1-0.3,1.7-0.9,2.1-1.6c3.6,2.4,9.1,4.1,9.5,0.2c3.6,1.9,9,2,10.3-0.8c2.6,1.6,5.9,1.1,7.6-1.6
			c2.9,2.4,7.1,2.6,8.1,0c2.7,2,6.2,2.5,9.5,1c1.8-0.9,2.6-2,2.5-3.1c4.6,1.8,9.5,4.7,13.9,2.1c1.3-0.8,1.5-1.7,1.2-2.5
			c1.8,0.2,3.6,0.1,5.3-0.4c1-0.3,1.4-0.8,1.4-1.3c2.9,0.7,6.3,0.9,7.8-1.1C298.8,568.8,297.5,567,295.6,565.6z"/>
                            <path className="st17" d="M292.8,569.8c-2.1,0-4.3-0.5-6.2-1.2c-1.4-0.5-2.1,1.5-0.9,2.3c0.1,0.2,0.3,0.3,0.4,0.4c-0.6,0-1.3,0-1.9,0
			c-1.7,0-3.4-0.2-5-0.5c-1.5-0.2-2.2,1.9-0.7,2.4c-1.3,1.5-2.6,1.7-4.1,0.8c-0.9-0.4-1.8-0.7-2.8-1c-2.1-0.7-4-1.6-6.1-2.1
			c-0.3-0.1-0.5,0-0.7,0c0,0,0,0,0,0c-1.5-0.6-2.1,1.8-0.7,2.4c3.2,1.2-3.6,2.7-2.8,2.6c-2.6,0.2-4.9-1.7-6.2-3.7
			c-0.9-1.4-2.8-0.2-2.1,1.2c2.5,5.5-4.1,1-4.7,0.2c-0.6-0.8-1.9-0.8-2.3,0.3c-2.3,3.3-4.4,3.4-6.3,0.4c-0.7-1.2-2.8-0.4-2.3,1
			c1.6,4.2-7.1,2.2-8.5-0.2c-0.7-1.2-2.7-0.4-2.3,1c1.7,5.1-4.6,1.2-6.2-0.1c0,0-0.1,0-0.1-0.1c-0.3-1-0.9-2-1.7-2.8
			c-1.2-1.1-2.9,0.6-1.7,1.7c1.1,1.1,1.9,2.5,0,3.1c-1.7,0.6-2.9-0.5-3.6-1.9c-0.1-0.5-0.3-0.9-0.5-1.3c-0.7-1.2-2.7-0.4-2.3,1
			c0.1,0.5,0.3,0.9,0.5,1.3c0.7,2.8-3.5,2.7-3.1-1.3c0.1-1.3-1.7-1.6-2.3-0.6c-0.3,0.5-3.2,5.1-4.2,3.6c-1.1-1.5,0.9-3.4,2-4.1
			c1.3-0.8,0.1-3-1.2-2.1c-0.4,0.3-0.8,0.6-1.2,0.9c-0.4,0-0.8,0.2-1,0.6c-0.5,0.7-0.9,1.3-1.4,2c-1.2,1.7-2.3,1.6-3.3-0.4
			c0.6-1.2-0.9-2.6-1.9-1.5c-2.1,2.2-5.2,4.1-4-0.9c0.2-1-0.9-2.1-1.8-1.4c-0.9,0.6-1.7,1.3-2.5,2.1c-2.7,2.5-2.6-0.4-1.5-2.2
			c0.6-1-0.7-2.5-1.7-1.7c-1.3,1-2.5,1.9-3.8,2.8c-1.1,0.8-2.2,1.5-3.5,2c0-0.5,0-1,0-1.5c0.4-0.4,0.7-0.7,1.1-0.9
			c0.6-0.3,1.1-0.6,1.7-0.8c1.5-0.6,0.9-2.9-0.7-2.4c-0.5,0.1-1.1,0.4-1.8,0.8c-2.1,1.1-3.9,2.4-6,3.4c0.8-2.5,2.9-4.1,5.1-5.5
			c1.3-0.9,0.1-3-1.2-2.1c-0.1,0.1-0.2,0.2-0.3,0.2c0,0-0.1,0-0.1,0c-0.8,0.1-4.7,0.5-3.9-1.3c0.5-1,2.3-1.3,3.3-1.5
			c0.8-0.2,2.9-0.4,4.1-1c1,0.4,2,0.5,3.2,0.5c1.6,0,1.6-2.5,0-2.5c-2.6,0-4.3-1.1-5.1-3.2c2.7,0.3,5.3,1.3,8,2
			c0.9,0.2,1.8-0.6,1.5-1.5c-1.7-6.3,5.1-0.8,6.9,0.8c0.8,0.8,2.1,0.2,2.1-0.9c2.5-2.6,3.9-2.8,4.2-0.6c0.2,0.7,0.4,1.3,0.5,2
			c0.3,1.6,2.6,0.9,2.4-0.7c0,0,0,0,0,0c1-2.1,2.5-4.2,4.7-2.1c0.9,0.9,1.7,1.9,2.5,2.9c0.7,0.8,2.1,0.1,2.1-0.9
			c-0.2-5.8,5-2.8,6.6,0.3c0.4,0.8,1.7,0.8,2.1,0c0.4-0.9,1-1.7,1.9-2.3c0.7-0.5,4.8-2.5,3.1,1.4c-0.5,1.2,1,2.7,1.9,1.5
			c2-2.5,4.3-4,7.3-4.5c-0.2,0.8-0.4,1.6-1,2.6c-0.8,1.2,0.9,2.4,1.9,1.5c1.6-1.5,3.3-2.6,5.2-3.6c1.2-0.6,2.4-1,3.6-1.3
			c3.4,1.8,3.6,3.3,0.7,4.4c-1.3,0.9-0.1,3,1.2,2.1c0.7-0.5,2-1.5,3.1-2.7c0.4,0.1,0.7,0,1.1-0.3c1.6-1.5,3.5-2.3,5.5-2.8
			c-0.4,0.8-0.9,1.5-1.3,2.3c-1.3,0.9,0,3.1,1.2,2.1c2.9-2.1,5.8-3.9,9.3-4.4c-0.1,0.4-0.2,0.8-0.4,1.2c-0.5,1.4,1.5,2,2.3,1
			c0.8-1.1,1.7-2,2.6-2.9c0.9-0.8,1.9-1.4,2.9-2c2.9-1.9,4-0.4,3.5,4.6c-1.2,0.9-0.2,2.9,1.2,2.1c0.4-0.2,0.8-0.5,1.1-0.8
			c0.2-0.1,0.4-0.3,0.7-0.4c0.4-0.2,0.8-0.4,1.1-0.7c1.8-1.7,2.7-1.4,2.6,0.7c-1.5,0.6-0.9,2.7,0.7,2.4c2.5-0.6,6.1-1.7,8.4-0.2
			c-0.8,0.6-1.6,1.1-2.6,1.5c-1.3,0.6-0.4,2.7,1,2.3c0.4-0.2,6.4-1.6,5.5,1.3c-0.7-0.3-1.3-0.6-1.9-0.8c-1.5-0.5-2.1,1.8-0.7,2.4
			C292.2,566.4,296.6,569.8,292.8,569.8z"/>
                            <g>
			<path d="M199.4,566c-2.1-2.3-4.8-3.7-7.9-4.1c-0.7-0.1-1.3,0.5-0.9,1.2c0.9,1.6,0.7,2.5,2.4,3.5c0.2-0.5,0.4-1,0.6-1.5
				c-2.7-0.4-5.3-0.6-7.9,0c-0.5,0.1-0.7,0.7-0.5,1.2c1.6,3.4,6.2,2.6,9.3,2.5c1,0,1-1.6,0-1.6c-2.4,0-6.6,0.9-7.9-1.7
				c-0.2,0.4-0.3,0.8-0.5,1.2c2.4-0.6,4.7-0.4,7.1,0c0.8,0.1,1.5-1,0.6-1.5c-1.2-0.7-1.3-1.8-1.9-2.9c-0.3,0.4-0.6,0.8-0.9,1.2
				c2.8,0.4,5.2,1.6,7.2,3.7C198.9,567.9,200.1,566.7,199.4,566L199.4,566z"/>
		</g>
                            <g>
			<path d="M203.8,565.9c1.8-2.9,5.2-4.7,8.6-4.2c-0.2-0.4-0.5-0.8-0.7-1.2c-0.9,1.8-2,2.9-3.6,4c-0.9,0.6-0.1,1.8,0.8,1.4
				c2.6-1.3,5.7-2.9,8.5-1.1c-0.1-0.4-0.1-0.8-0.2-1.3c-1.1,0.9-2.1,1.8-3.4,2.4c0.3,0.5,0.5,0.9,0.8,1.4c2.6-1.6,6-2,8.8-0.7
				c-0.1-0.4-0.1-0.8-0.2-1.3c-0.7,0.4-1.3,0.9-2,1.3c-0.9,0.5-0.1,1.9,0.8,1.4c1.2-0.7,1.2-0.7,2.3-1.6c0.4-0.3,0.3-1.1-0.2-1.3
				c-3.4-1.6-7.3-1.2-10.5,0.7c-0.9,0.6-0.1,1.8,0.8,1.4c1.5-0.6,2.5-1.6,3.7-2.6c0.4-0.3,0.2-1-0.2-1.3c-3.3-2.2-6.9-0.5-10.1,1.1
				c0.3,0.5,0.5,0.9,0.8,1.4c1.9-1.2,3.2-2.6,4.2-4.6c0.3-0.6-0.1-1.1-0.7-1.2c-3.9-0.5-8,1.7-10,5
				C201.9,566,203.2,566.8,203.8,565.9L203.8,565.9z"/>
		</g>
                            <g>
			<path d="M233.1,567.3c1.7-2.5,5.2-2.7,7.9-2.5c-0.2-0.4-0.5-0.8-0.7-1.2c-0.5,0.6-2.3,3.5-3.2,3.5c0.1,0.5,0.3,1,0.4,1.5
				c3.4-1.9,6.8-2.3,10.7-2c-0.2-0.4-0.5-0.8-0.7-1.2c-0.9,1.4-2,1.9-3.5,2.5c-0.9,0.4-0.5,2,0.4,1.5c1.8-0.8,3.3-1.5,4.4-3.2
				c0.3-0.5-0.1-1.2-0.7-1.2c-4.1-0.3-7.8,0.2-11.5,2.3c-0.7,0.4-0.4,1.5,0.4,1.5c2,0,3.6-2.9,4.6-4.3c0.4-0.5-0.2-1.2-0.7-1.2
				c-3.4-0.3-7.2,0.3-9.3,3.3C231.1,567.3,232.5,568.1,233.1,567.3L233.1,567.3z"/>
		</g>
                            <g>
			<path d="M257.4,564.8c1.7-3,5.3-5.1,8.7-3.9c-0.1-0.4-0.2-0.9-0.4-1.3c-1.1,1.2-2,2.4-3.6,3c-1,0.4-0.6,1.9,0.4,1.6
				c3.5-1,6.8-1.9,10.5-1.4c-0.2-0.5-0.4-0.9-0.6-1.4c-1,1.1-2.2,1.8-3.6,2.3c-1,0.3-0.6,1.8,0.4,1.6c0.8-0.2,1.7-0.4,2.5-0.5
				c0.9-0.2,1.7-0.2,2.6-0.2c2-0.3,2.2,0.4,0.7,2.1c-1,0.1-1,1.7,0,1.6c1.8-0.2,2.8-0.8,4.1-2c0.3-0.2,0.3-0.7,0.1-1
				c-2.3-3.4-7.2-2.3-10.6-1.4c0.1,0.5,0.3,1,0.4,1.6c1.8-0.6,2.9-1.4,4.3-2.7c0.6-0.5,0.1-1.3-0.6-1.4c-3.8-0.5-7.3,0.4-10.9,1.5
				c0.1,0.5,0.3,1,0.4,1.6c1.9-0.7,2.9-1.9,4.3-3.4c0.4-0.5,0.2-1.2-0.4-1.3c-4.1-1.4-8.6,1-10.6,4.7
				C255.5,564.9,256.9,565.7,257.4,564.8L257.4,564.8z"/>
		</g>
	</g>
                        <g className="oso-nieve white">
		                <g>
			<path className="st13" d="M815.8,515.8c3.8-1,7.5-1.9,11.3-2.9c-1.5,1.2-3,2.4-4.6,3.5c-1.5,1-3,1.9-4.7,2.3
				C815.7,519.2,815.7,517.3,815.8,515.8z"/>
            <path className="st18" d="M826.6,503c-6.3-3.7-15.7-2.3-22.1,0.2c-5.6,2.1-14.3,7.7-16.2,14.3c-6-10,8.1-17.2,16.2-19.9
				c4.1-1.3,8.4-2.3,12.7-2.2C822.1,495.5,825,498.9,826.6,503z"/>
            <path className="st21" d="M780.1,512c0.2-6.3,2-11.3,6.5-15.7c8.8-8.5,22.6-10.6,31.9-2.8c-5.4-0.9-11.7,1.2-16.6,2.9
				c-5.3,1.8-11.2,4.5-14.5,9.2c-3.2,4.6-2.9,9.7,0,13.9c-0.2,0.2-0.3,0.4-0.3,0.6c-0.5,3.6-1.5,7.2-0.9,10.8c0-0.1-0.1-0.1-0.2-0.2
				C780.6,526.3,779.9,518.5,780.1,512z"/>
            <path className="st11" d="M786,536.8c-1-0.3-1.3-1.4-1.1-2.3c0.3-1.2,1.4-1.5,2.4-1.3c1.2,0.3,1.9,1.1,1.5,2.3
				C788.5,536.7,787.1,537.1,786,536.8z"/>
            <path className="st11" d="M813.8,577.6c-9.6,1.1-21.2,0.5-28-7.3c-4.3-5-4.8-12.8-3.3-19c1-4,3.4-7.4,6.2-10.4c1.9-2.1,4.9-5.9,8-5.5
				c0.4,0.1,0.7-0.1,0.8-0.4c1.5,0.6,3,0.9,4.5,0.8c1.3-0.1,1.3-2,0-2c-5.9,0.3-13.9-6.9-12.6-13.2c0.2-0.2,0.3-0.4,0.3-0.7
				c0-0.1,0-0.3,0-0.4c0-0.1,0-0.1,0.1-0.2c0.1-0.3,0.1-0.5,0-0.7c1.2-6.4,10.4-11.9,15.8-13.9c5.5-2.1,14-3.3,19.6-0.2
				c-0.1,0.3-0.1,0.7,0.3,1c1.6,1.2,2.3,3,2.9,4.9c0,0,0,0.1,0,0.1c-1,0.3-2,0.5-2.9,0.8c-1.5-3.2-6.1-2-5.5,1.4
				c-1.9,0.5-3.7,1-5.6,1.4c-0.5,0.1-0.7,0.5-0.7,0.8c0,0,0,0.1,0,0.1c-0.5,4.5,1.9,6.9,6.2,4.9c3.2-1.4,6.1-3.6,8.8-6
				c1.3,7.8-0.6,18.4-10.4,18c-1.3,0-1.3,1.9,0,2c2.3,0.1,4.2-0.3,5.8-1.1c10.2,2.4,15.7,14.2,16.2,23.8
				C841.4,570.7,825.3,576.2,813.8,577.6z"/>
            <path d="M804.6,510.3c-3.7-0.4-4.7,6.1,0.3,6.3C809.9,516.7,808.3,510.7,804.6,510.3z"/>
            <path d="M821.5,540.8c-3.7-0.4-4.8,7,0.8,6.8C827.9,547.4,825.3,541.2,821.5,540.8z"/>
            <path d="M823.4,552c-4.3,0.4-4.3,7.5,0.8,6.8C829.2,558.1,827.7,551.6,823.4,552z"/>
            <path d="M823.4,562.3c-4.2,0.3-4.8,7,0.8,6.8C829.7,568.9,827.6,562,823.4,562.3z"/>
            <path d="M826.4,531.4c4.7-4.1,5.5-12.4,4.2-19c0.2-0.2,0.5-0.5,0.7-0.7c0.7-0.7,0.1-1.7-0.8-1.7c0,0,0-0.1,0-0.1
				c-0.4-1.2-0.9-2.3-1.5-3.4c0.3-0.2,0.5-0.6,0.4-1.1c-1.1-4.4-3.1-8.3-6.7-10.4c0,0,0-0.1-0.1-0.1c-9.9-12.1-26.6-9.8-37.4,0
				c-4.8,4.4-6.6,9.7-7.1,16c-0.5,7.1,0.4,16.1,6.2,21.1c-0.6,0.4-1,1.1-1.3,1.9c-0.7,1.9,0.3,4.1,2.2,4.8c0.8,0.3,1.6,0.3,2.4,0.1
				c-1.8,2.1-3.4,4.4-4.4,6c-4,6.4-4.4,14.6-1.9,21.6c4.5,12.3,19.2,14.2,30.5,13.3c12.6-1,29.9-6.5,30.6-21.4
				C843,547.9,836.7,534.9,826.4,531.4z M826.6,503c-6.3-3.7-15.7-2.3-22.1,0.2c-5.6,2.1-14.3,7.7-16.2,14.3
				c-6-10,8.1-17.2,16.2-19.9c4.1-1.3,8.4-2.3,12.7-2.2C822.1,495.5,825,498.9,826.6,503z M788.3,531.5c-0.5-2-0.4-4-0.1-6
				c1.1,3.2,3.6,6.2,6.6,8.1c-1.3,0.3-2.6,1.1-3.9,2.1C791.3,533.8,790.1,532.1,788.3,531.5z M780.1,512c0.2-6.3,2-11.3,6.5-15.7
				c8.8-8.5,22.6-10.6,31.9-2.8c-5.4-0.9-11.7,1.2-16.6,2.9c-5.3,1.8-11.2,4.5-14.5,9.2c-3.2,4.6-2.9,9.7,0,13.9
				c-0.2,0.2-0.3,0.4-0.3,0.6c-0.5,3.6-1.5,7.2-0.9,10.8c0-0.1-0.1-0.1-0.2-0.2C780.6,526.3,779.9,518.5,780.1,512z M786,536.8
				c-1-0.3-1.3-1.4-1.1-2.3c0.3-1.2,1.4-1.5,2.4-1.3c1.2,0.3,1.9,1.1,1.5,2.3C788.5,536.7,787.1,537.1,786,536.8z M813.8,577.6
				c-9.6,1.1-21.2,0.5-28-7.3c-4.3-5-4.8-12.8-3.3-19c1-4,3.4-7.4,6.2-10.4c1.9-2.1,4.9-5.9,8-5.5c0.4,0.1,0.7-0.1,0.8-0.4
				c1.5,0.6,3,0.9,4.5,0.8c1.3-0.1,1.3-2,0-2c-5.9,0.3-13.9-6.9-12.6-13.2c0.2-0.2,0.3-0.4,0.3-0.7c0-0.1,0-0.3,0-0.4
				c0-0.1,0-0.1,0.1-0.2c0.1-0.3,0.1-0.5,0-0.7c1.2-6.4,10.4-11.9,15.8-13.9c5.5-2.1,14-3.3,19.6-0.2c-0.1,0.3-0.1,0.7,0.3,1
				c1.6,1.2,2.3,3,2.9,4.9c0,0,0,0.1,0,0.1c-1,0.3-2,0.5-2.9,0.8c-1.5-3.2-6.1-2-5.5,1.4c-1.9,0.5-3.7,1-5.6,1.4
				c-0.5,0.1-0.7,0.5-0.7,0.8c0,0,0,0.1,0,0.1c-0.5,4.5,1.9,6.9,6.2,4.9c3.2-1.4,6.1-3.6,8.8-6c1.3,7.8-0.6,18.4-10.4,18
				c-1.3,0-1.3,1.9,0,2c2.3,0.1,4.2-0.3,5.8-1.1c10.2,2.4,15.7,14.2,16.2,23.8C841.4,570.7,825.3,576.2,813.8,577.6z M827,512.9
				c-1.5,1.2-3,2.4-4.6,3.5c-1.5,1-3,1.9-4.7,2.3c-2,0.5-2-1.4-1.9-2.9C819.5,514.9,823.3,513.9,827,512.9z"/>
            <path d="M789.7,548.1c-0.1,0.1-0.3,0.3-0.4,0.4c-0.1,0.2-0.3,0.3-0.4,0.5c-0.3,0.4-0.6,0.8-0.9,1.2c-0.3,0.5-0.5,1-0.8,1.5
				c-0.2,0.5-0.4,1.1-0.6,1.7c-0.2,0.6-0.2,1.2-0.3,1.8c0,0.6-0.1,1.2-0.1,1.9c0,0.6,0.1,1.2,0.2,1.8c0.1,0.6,0.2,1.1,0.4,1.6
				c0.1,0.5,0.3,1,0.5,1.4c0.2,0.4,0.4,0.8,0.6,1.1c0.2,0.3,0.3,0.6,0.5,0.8c0.3,0.4,0.5,0.7,0.5,0.7s-0.1-0.3-0.2-0.8
				c-0.1-0.2-0.2-0.6-0.2-0.9c-0.1-0.3-0.2-0.7-0.3-1.2c-0.1-0.4-0.2-0.9-0.2-1.4c-0.1-0.5-0.1-1-0.2-1.5c0-0.5,0-1.1,0-1.6
				c0-0.6,0.1-1.1,0.1-1.7c0.1-0.6,0.1-1.1,0.3-1.7c0.1-0.5,0.2-1.1,0.4-1.6c0.2-0.5,0.3-1,0.5-1.5c0.2-0.4,0.4-0.9,0.6-1.3
				c0.2-0.4,0.4-0.7,0.6-1c0.2-0.3,0.3-0.6,0.5-0.8c0.3-0.4,0.5-0.7,0.5-0.7s-0.3,0.2-0.7,0.5C790.2,547.6,789.9,547.8,789.7,548.1z
				"/>
            <path d="M796.4,540.8c-0.1,0-0.3,0-0.4,0c-0.1,0-0.3,0.1-0.4,0.1c-0.3,0.1-0.5,0.3-0.7,0.5c-0.1,0.1-0.2,0.2-0.3,0.4
				c-0.1,0.1-0.1,0.3-0.2,0.4c-0.1,0.3-0.1,0.5-0.1,0.8c0,0.2,0.1,0.4,0.1,0.5c0.1,0.1,0.1,0.2,0.1,0.2s0.1,0,0.2-0.1
				c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.1,0.3-0.2,0.4-0.4c0.1-0.1,0.2-0.3,0.4-0.4c0.1-0.1,0.1-0.2,0.2-0.2
				c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.2,0.3-0.3,0.3-0.4c0.1-0.1,0.1-0.2,0.1-0.2s-0.1-0.1-0.2-0.1
				C796.7,540.9,796.6,540.8,796.4,540.8z"/>
		</g>
                            <g>
			<path className="st18" d="M777.3,498.9c0-1,1.1-5.3,1.8-2.9c0.5,1.6,0.6,3.8,2.4,4.5c2.5,0.9,4.5-1.4,5.9-3c1.1-0.8,1.8-0.6,2,0.7
				c-0.2,0.5-0.4,0.9-0.7,1.4c-0.6,1-1.4,1.9-2.3,2.7c-1.8,1.6-4.1,2.8-6.4,3.4c-1.1-1-2.3-1.9-3.6-2.7
				C777.1,501.7,777.3,500.4,777.3,498.9z"/>
                                <path className="st18" d="M748.9,502.5c-2-1.1-3.9-2.2-5.4-3.9c-1.1-1.1-1.4-2.5-1.1-4c0.3-1.1,1.2-2.9,2.5-2.1
				c2,1.1,3.7,5.5,6.5,4.5c0.6-0.2,1-0.8,0.9-1.5c-0.1-0.4-0.2-0.8-0.2-1.2c1.4-0.3,2,0.2,1.8,1.4c0.1,0.7,0,1.5,0,2.3
				c0,0.9,0.1,1.6,0.4,2.4C752.4,500.9,750.6,501.7,748.9,502.5C749,502.6,748.9,502.6,748.9,502.5z"/>
                                <path className="st18" d="M733.9,507.8c2.9-1.1,6.7-1.9,9.6-0.8c-1.3,1.5-2.4,3.1-3.2,4.9c-0.3,0.8-0.7,1.5-0.9,2.4
				C736.8,513.3,735.6,510.1,733.9,507.8z"/>
                                <path className="st18" d="M750,536c-2-3.5-0.4-8.1,3.7-8.9c0.1,0,0.2-0.1,0.3-0.1c0.8,1.7,2.1,2.8,3.8,3.2c-0.5,2.5-0.8,5.1-0.1,7.5
				c-2.6-0.3-5.1-0.7-7.5-1.3C750.1,536.3,750.1,536.1,750,536z"/>
                                <path className="st18" d="M768.7,537.6c-0.1,0.1-0.2,0.2-0.2,0.4c-2.9,0.2-5.8,0.2-8.8,0c0,0,0,0,0,0c-1.1-2.3-0.6-5-0.1-7.4
				c0.8,0,1.7,0,2.5-0.2c1.5-0.3,3.5-1.6,4.3-3.2C770.3,529.2,771.8,534,768.7,537.6z"/>
                                <path className="st21" d="M760.1,539.9c6.4,0.5,13.2,0.4,19.3-1.5c1.4,7.7-12.6,7.1-17.4,6.9c-3.5-0.1-6.9-1-10.4-1.5
				c-1.2-0.2-2.4-0.4-3.6-0.7c-1.8-0.3-3.1-1.3-3.9-3.1c-0.2-1-0.1-2,0.4-2.9C749.3,539.1,754.9,539.5,760.1,539.9z"/>
                                <path className="st21" d="M774.9,554.4c-0.3,1-0.7,1.9-1.1,2.9c-1.7-0.6-3.5-1-5.3-1.4c0.9-2.7,1.5-5.5,2.2-8.2
				c1.9-0.2,3.8-0.5,5.4-1C776.2,549.3,775.7,551.8,774.9,554.4z"/>
                                <path className="st18" d="M730.9,559.1c3.1,0.9,4.6,10.2,1.8,12c-0.1,0.1-0.2,0.1-0.3,0.2c-0.4-0.1-0.9-0.3-1.3-0.5
				C728.1,569.4,725.6,558.7,730.9,559.1z"/>
                                <path className="st18" d="M756.3,565c0.8-0.1,1.6-0.3,2.5-0.4c0.6,0.1,1.1,0.4,1.7,0.9c3.7,3.4,3.2,11.5-2.4,11.8
				c-0.4-0.1-0.9-0.3-1.3-0.5C750.9,574.3,752.8,567.1,756.3,565z"/>
                                <path className="st11" d="M761.8,578.1c3.2-2.3,3.6-7.3,1.6-11.4c-0.5-1-1.1-1.8-1.8-2.4c0.9,0,1.8,0.1,2.7,0.3
				c1.2,0.4,1.7-1.5,0.5-1.8c-2.4-0.7-5-0.2-7.4,0.2c-0.3,0-0.6,0.1-0.9,0.1c-0.3,0-0.5,0.1-0.8,0.1c-0.3,0-0.6,0.2-0.7,0.4
				c-2,1-3.7,3.1-4.1,5.9c-4.2,2-10.5,3.2-15.7,2.5c2.2-3,1.7-9.1-0.6-12.6c2.3-0.2,4.6-0.6,6.3,0.4c0.4,1.8,1.3,3.3,3.3,4.2
				c1.4,0.6,2.7-1.5,1.2-2.1c-1.1-0.5-1.7-1.3-2.1-2.4c0.1-0.3,0.1-0.7-0.2-1c-0.4-3.1,1.2-7.3,1.8-9.6c0.3-1.5-2-2.2-2.4-0.7
				c-0.5,2.4-1.7,5.8-1.8,8.9c-0.7-0.2-1.4-0.3-2.2-0.3c-0.1-3.8,1.4-10.4,4.5-13.2c2.7,2.5,8.1,2.7,11.1,3.2
				c2.9,0.5,8.6,1.2,14,1.1c-0.7,2.9-1.3,5.8-2.4,8.6c-0.2,0.6,0.3,1.4,0.9,1.5c2.2,0.5,4.4,1,6.5,1.7c-0.1,0.4-0.2,0.9-0.2,1.3
				c-0.4,3.6,1.9,7.7,5.8,7.5C776,574.7,768.6,578.5,761.8,578.1z"/>
                                <path className="st11" d="M784.9,554.4c0,2.5-0.8,5-1.8,7.3c-0.5,1-1.5,2.6-2.8,3.5c-0.3,0-0.5,0.1-0.7,0.4c-1.2,0.6-2.4,0.5-3.6-1.1
				c-2-2.8,0.2-6.7,1.2-9.4c1.1-3.1,1.5-6.2,1.3-9.5c1.2-0.7,2.2-1.6,2.8-2.7C783.8,546.4,784.9,550.1,784.9,554.4z"/>
                                <path className="st11" d="M778.9,535.2c-0.2,0.3-0.3,0.6-0.3,0.8c-2.3,0.7-4.9,1.3-7.6,1.6c2.7-4.5,0.8-10.4-4.1-12.5c0,0,0,0,0,0
				c-0.1-0.4-0.2-0.7-0.5-1.1c-2.1-3.1-12-3-12.6,1.2c-0.2-0.1-0.4-0.1-0.6-0.1c-4.9,0.9-7,6.2-5.4,10.5c-0.7-0.2-1.4-0.5-2.1-0.7
				c-0.1-0.2-0.4-0.3-0.6-0.3c-0.1,0-0.2,0-0.2,0c-0.1,0-0.2-0.1-0.4-0.1c0,0-0.1-0.1-0.1-0.1c-5.4-5.3-4.5-13.9-2.3-20.4
				c2.6-7.8,10.6-11.5,18.2-12.4c7.4-0.8,14.4,2,19.5,7.3C787.1,516.8,784.9,527.5,778.9,535.2z"/>
                                <path className="st18" d="M791.3,513.2c-1.4,1-3.4,2.2-5.2,2.3c-0.4-1.6-1.1-3.1-2-4.7c-0.3-0.5-0.6-1.1-1-1.6c1.2-1,3.6-1.1,5-1.2
				c1.9-0.2,4.4-0.4,6.2,0.5C796.2,509.5,791.9,512.8,791.3,513.2z"/>
                                <path d="M796.8,507.8c-1.3-2.4-4.6-2.3-6.9-2.3c-1.2,0-2.5,0.1-3.9,0.3c2.8-1.8,5.2-4.4,6-7.4c0.5-1.9-1-3-2.5-3.6
				c-2.1-0.9-3.7,0.9-4.8,2.3c-3.2,3.7-2.6-3-4.8-4c-1.3-0.6-3.1,0.3-3.9,1.4c-1.6,2.2-0.6,5.1-1.7,7.4c-3.9-1.9-8.3-2.7-12.6-2.7
				c-1.7,0-3.4,0.2-5.1,0.6c-1.3-3.2,0.9-7.5-3.4-9.1c-2.3-0.9-3.2,1.4-3.4,3.4c-1.1-1-2.8-3-3.3-3.4c-1.7-1.2-3.4-1-4.9,0.5
				c-4.9,5.3,0.5,10.2,5.1,13c-0.5,0.3-0.9,0.7-1.3,1.1c-4.2-2.3-10.1-0.7-14.1,1.1c-0.7,0.3-0.8,1.4-0.2,1.9
				c2.8,2.6,3.8,7.2,7.6,8.5c-1.6,6.6-1.1,14.3,3.5,19.2c-0.8,1.2-0.9,3.2-0.6,4.9c0,0.3,0.1,0.5,0.2,0.7
				c-3.9,3.4-5.8,10.6-5.6,15.3c0,0,0,0.1,0,0.1c-1.3,0.2-2.2,0.4-4,0c-0.9-0.2-2.3-0.7-3.2-0.4c-4.5,1.4-3.7,6.4-3,10.2
				c2,10.5,16.7,8.8,24.8,5.5c0.4,3.4,2.9,5.5,6,6.7c0.1,0.3,0.4,0.4,0.8,0.4c0.2,0,0.4,0,0.5,0c2.1,0.6,4.4,0.7,6.5,0.6
				c7.2-0.6,14.3-5,16.5-12c3-1.6,4.8-5.9,5.7-8.8c2-6.5,0-13.4-4-18.7c-0.2-0.2-0.3-0.3-0.5-0.4c0-1-0.2-2.2-0.7-3.5
				c0-0.1-0.1-0.2-0.1-0.3c0,0,0-0.1-0.1-0.1c4.1-5.6,6.3-11.8,5.3-18.2C790.7,517.3,799.3,512.4,796.8,507.8z M777.3,498.9
				c0-1,1.1-5.3,1.8-2.9c0.5,1.6,0.6,3.8,2.4,4.5c2.5,0.9,4.5-1.4,5.9-3c1.1-0.8,1.8-0.6,2,0.7c-0.2,0.5-0.4,0.9-0.7,1.4
				c-0.6,1-1.4,1.9-2.3,2.7c-1.8,1.6-4.1,2.8-6.4,3.4c-1.1-1-2.3-1.9-3.6-2.7C777.1,501.7,777.3,500.4,777.3,498.9z M743.5,498.6
				c-1.1-1.1-1.4-2.5-1.1-4c0.3-1.1,1.2-2.9,2.5-2.1c2,1.1,3.7,5.5,6.5,4.5c0.6-0.2,1-0.8,0.9-1.5c-0.1-0.4-0.2-0.8-0.2-1.2
				c1.4-0.3,2,0.2,1.8,1.4c0.1,0.7,0,1.5,0,2.3c0,0.9,0.1,1.6,0.4,2.4c-1.8,0.6-3.6,1.3-5.2,2.3c0,0-0.1,0-0.1-0.1
				C746.9,501.4,745.1,500.3,743.5,498.6z M739.4,514.3c-2.6-1-3.8-4.1-5.4-6.5c2.9-1.1,6.7-1.9,9.6-0.8c-1.3,1.5-2.4,3.1-3.2,4.9
				C740,512.7,739.7,513.5,739.4,514.3z M732.7,571.1c-0.1,0.1-0.2,0.1-0.3,0.2c-0.4-0.1-0.9-0.3-1.3-0.5c-3-1.4-5.5-12.1-0.2-11.7
				C734,560,735.5,569.3,732.7,571.1z M758,577.3c-0.4-0.1-0.9-0.3-1.3-0.5c-5.7-2.5-3.8-9.8-0.4-11.8c0.8-0.1,1.6-0.3,2.5-0.4
				c0.6,0.1,1.1,0.4,1.7,0.9C764.1,568.9,763.6,577,758,577.3z M761.8,578.1c3.2-2.3,3.6-7.3,1.6-11.4c-0.5-1-1.1-1.8-1.8-2.4
				c0.9,0,1.8,0.1,2.7,0.3c1.2,0.4,1.7-1.5,0.5-1.8c-2.4-0.7-5-0.2-7.4,0.2c-0.3,0-0.6,0.1-0.9,0.1c-0.3,0-0.5,0.1-0.8,0.1
				c-0.3,0-0.6,0.2-0.7,0.4c-2,1-3.7,3.1-4.1,5.9c-4.2,2-10.5,3.2-15.7,2.5c2.2-3,1.7-9.1-0.6-12.6c2.3-0.2,4.6-0.6,6.3,0.4
				c0.4,1.8,1.3,3.3,3.3,4.2c1.4,0.6,2.7-1.5,1.2-2.1c-1.1-0.5-1.7-1.3-2.1-2.4c0.1-0.3,0.1-0.7-0.2-1c-0.4-3.1,1.2-7.3,1.8-9.6
				c0.3-1.5-2-2.2-2.4-0.7c-0.5,2.4-1.7,5.8-1.8,8.9c-0.7-0.2-1.4-0.3-2.2-0.3c-0.1-3.8,1.4-10.4,4.5-13.2c2.7,2.5,8.1,2.7,11.1,3.2
				c2.9,0.5,8.6,1.2,14,1.1c-0.7,2.9-1.3,5.8-2.4,8.6c-0.2,0.6,0.3,1.4,0.9,1.5c2.2,0.5,4.4,1,6.5,1.7c-0.1,0.4-0.2,0.9-0.2,1.3
				c-0.4,3.6,1.9,7.7,5.8,7.5C776,574.7,768.6,578.5,761.8,578.1z M757.7,537.8c-2.6-0.3-5.1-0.7-7.5-1.3c0-0.1,0-0.3-0.1-0.4
				c-2-3.5-0.4-8.1,3.7-8.9c0.1,0,0.2-0.1,0.3-0.1c0.8,1.7,2.1,2.8,3.8,3.2C757.3,532.7,756.9,535.3,757.7,537.8z M760.1,539.9
				c6.4,0.5,13.2,0.4,19.3-1.5c1.4,7.7-12.6,7.1-17.4,6.9c-3.5-0.1-6.9-1-10.4-1.5c-1.2-0.2-2.4-0.4-3.6-0.7
				c-1.8-0.3-3.1-1.3-3.9-3.1c-0.2-1-0.1-2,0.4-2.9C749.3,539.1,754.9,539.5,760.1,539.9z M759.7,530.5c0.8,0,1.7,0,2.5-0.2
				c1.5-0.3,3.5-1.6,4.3-3.2c3.7,2.1,5.3,6.9,2.2,10.4c-0.1,0.1-0.2,0.2-0.2,0.4c-2.9,0.2-5.8,0.2-8.8,0c0,0,0,0,0,0
				C758.7,535.7,759.2,532.9,759.7,530.5z M776.2,546.6c0,2.7-0.4,5.2-1.3,7.8c-0.3,1-0.7,1.9-1.1,2.9c-1.7-0.6-3.5-1-5.3-1.4
				c0.9-2.7,1.5-5.5,2.2-8.2C772.7,547.5,774.6,547.2,776.2,546.6z M781.4,542.9c2.3,3.5,3.5,7.2,3.5,11.5c0,2.5-0.8,5-1.8,7.3
				c-0.5,1-1.5,2.6-2.8,3.5c-0.3,0-0.5,0.1-0.7,0.4c-1.2,0.6-2.4,0.5-3.6-1.1c-2-2.8,0.2-6.7,1.2-9.4c1.1-3.1,1.5-6.2,1.3-9.5
				C779.8,544.9,780.8,544,781.4,542.9z M778.9,535.2c-0.2,0.3-0.3,0.6-0.3,0.8c-2.3,0.7-4.9,1.3-7.6,1.6c2.7-4.5,0.8-10.4-4.1-12.5
				c0,0,0,0,0,0c-0.1-0.4-0.2-0.7-0.5-1.1c-2.1-3.1-12-3-12.6,1.2c-0.2-0.1-0.4-0.1-0.6-0.1c-4.9,0.9-7,6.2-5.4,10.5
				c-0.7-0.2-1.4-0.5-2.1-0.7c-0.1-0.2-0.4-0.3-0.6-0.3c-0.1,0-0.2,0-0.2,0c-0.1,0-0.2-0.1-0.4-0.1c0,0-0.1-0.1-0.1-0.1
				c-5.4-5.3-4.5-13.9-2.3-20.4c2.6-7.8,10.6-11.5,18.2-12.4c7.4-0.8,14.4,2,19.5,7.3C787.1,516.8,784.9,527.5,778.9,535.2z
				 M791.3,513.2c-1.4,1-3.4,2.2-5.2,2.3c-0.4-1.6-1.1-3.1-2-4.7c-0.3-0.5-0.6-1.1-1-1.6c1.2-1,3.6-1.1,5-1.2
				c1.9-0.2,4.4-0.4,6.2,0.5C796.2,509.5,791.9,512.8,791.3,513.2z"/>
                                <path d="M750.1,516.4c-4.6,0.4-2.7,7.1,1.5,6.1C755.7,521.4,754.7,516,750.1,516.4z"/>
                                <path d="M772.6,517.7c-4.6,0.4-2.7,7.1,1.5,6.1C778.3,522.7,777.2,517.3,772.6,517.7z"/>
                                <path d="M744.1,527.6c0-0.2,0-0.4-0.1-0.6c-0.1-0.2-0.1-0.4-0.1-0.6c-0.1-0.4-0.1-0.9-0.2-1.3c-0.1-0.5-0.2-0.9-0.2-1.4
				c-0.1-0.4-0.2-0.9-0.3-1.2c-0.1-0.4-0.2-0.7-0.3-0.9c-0.1-0.2-0.1-0.4-0.1-0.4s-0.1,0.1-0.2,0.3c-0.1,0.2-0.3,0.5-0.5,0.9
				c-0.1,0.4-0.3,0.9-0.3,1.4c0,0.3-0.1,0.5,0,0.8c0,0.3,0,0.5,0.1,0.8c0.1,0.3,0.1,0.5,0.2,0.8c0.1,0.3,0.2,0.5,0.3,0.7
				c0.1,0.2,0.2,0.5,0.4,0.7c0.1,0.2,0.3,0.4,0.4,0.5c0.3,0.3,0.6,0.5,0.8,0.7c0.2,0.1,0.3,0.2,0.3,0.2s0-0.2,0-0.4
				C744.2,528.3,744.1,528,744.1,527.6z"/>
                                <path d="M744.2,514.8c0,0-0.4,0.1-0.7,0.3c-0.3,0.2-0.6,0.5-0.7,0.9c-0.1,0.2-0.1,0.5-0.1,0.7c0,0.2,0.1,0.4,0.2,0.6
				c0.2,0.4,0.5,0.5,0.5,0.5s0.3-0.2,0.6-0.5c0.1-0.1,0.1-0.1,0.2-0.2c0-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.3,0.2-0.4
				c0.1-0.4,0.1-0.8,0-1.1C744.4,515.1,744.2,514.8,744.2,514.8z"/>
                                <path className="st21" d="M753.7,553.4c0,0-1.2-3.2-4.1-2.3c-3,0.9-0.8,5.7,2.7,7.4c3.5,1.6,8.6-3.6,6-6.4
				C755.8,549.5,753.7,553.4,753.7,553.4z"/>
		</g>
	</g>
                        <g className="luces-2">
                            <path className="st32" d="M586.1,156.6c-1.7-1.9-3-4.6-0.9-6.7c-0.2,0.8,0,1.6,0.1,2.4c0.1,0.6,1,0.5,0.9,0c-0.1-0.7-0.3-1.5-0.1-2.2
		c0.1-0.2,0-0.4-0.2-0.5c0.1,0,0.2,0,0.3-0.1c-0.1,0.1,0,0.3,0.1,0.5C588.4,151.7,587.3,154.6,586.1,156.6z"/>
                            <path className="st14" d="M569.1,154.1c-1.4-2.1-2.4-5,0-6.8c-0.3,0.8-0.2,1.6-0.3,2.4c0,0.6,0.9,0.5,0.9,0c0-0.6-0.1-1.4,0.2-2.1
		c0.1-0.2,0-0.4-0.1-0.5c0.1,0,0.2,0,0.4,0c-0.1,0.2-0.1,0.4,0.1,0.5C572.1,149.6,570.6,152.3,569.1,154.1z"/>
                            <path className="st32" d="M557.6,154.8c-2.3-1.2-4.5-3.2-3.3-5.9c0.1,0.8,0.6,1.5,1,2.2c0.3,0.5,1,0,0.7-0.5c-0.3-0.6-0.7-1.2-0.8-1.9
		c0-0.2-0.2-0.4-0.4-0.4c0.1-0.1,0.2-0.1,0.3-0.2c0,0.2,0.1,0.3,0.3,0.4C558,149.3,557.9,152.5,557.6,154.8z"/>
                            <path className="st13" d="M541.6,159.7c-1.7-1.9-3.1-4.5-1-6.7c-0.2,0.8,0,1.6,0.1,2.4c0.1,0.6,1,0.5,0.9,0c-0.1-0.7-0.3-1.5-0.1-2.2
		c0.1-0.2-0.1-0.4-0.2-0.5c0.1,0,0.2,0,0.3-0.1c-0.1,0.1,0,0.3,0.1,0.4C543.8,154.8,542.8,157.7,541.6,159.7z"/>
                            <path className="st21" d="M522.6,154.4c-1.2-2.3-1.9-5.2,0.7-6.7c-0.4,0.7-0.4,1.5-0.5,2.3c-0.1,0.6,0.8,0.8,0.9,0.2
		c0.1-0.7,0.1-1.5,0.4-2.1c0.1-0.2,0-0.4-0.1-0.5c0.1,0,0.2,0,0.3,0c-0.1,0.1-0.1,0.3,0,0.5C526,150.2,524.2,152.7,522.6,154.4z"/>
                            <polygon points="525.9,159.2 525.9,159.2 525.9,159.2 	"/>
                            <path className="st32" d="M507.7,158.8c-1.7-1.9-3-4.6-0.9-6.7c-0.2,0.8,0,1.6,0.1,2.4c0.1,0.6,1,0.5,0.9,0c-0.1-0.7-0.3-1.5-0.1-2.2
		c0.1-0.2,0-0.4-0.2-0.5c0.1,0,0.2,0,0.3-0.1c-0.1,0.1,0,0.3,0.1,0.5C510,153.9,508.9,156.8,507.7,158.8z"/>
                            <path className="st14" d="M490.7,156.3c-1.4-2.1-2.4-5,0-6.8c-0.3,0.8-0.2,1.6-0.3,2.4c0,0.6,0.9,0.5,0.9,0c0-0.6-0.1-1.4,0.2-2.1
		c0.1-0.2,0-0.4-0.1-0.5c0.1,0,0.2,0,0.4,0c-0.1,0.2-0.1,0.4,0.1,0.5C493.7,151.8,492.2,154.5,490.7,156.3z"/>
                            <path className="st32" d="M479.2,157c-2.3-1.2-4.5-3.2-3.3-5.9c0.1,0.8,0.6,1.5,1,2.2c0.3,0.5,1,0,0.7-0.5c-0.3-0.6-0.7-1.2-0.8-1.9
		c0-0.2-0.2-0.4-0.4-0.4c0.1-0.1,0.2-0.1,0.3-0.2c0,0.2,0.1,0.3,0.3,0.4C479.6,151.5,479.5,154.7,479.2,157z"/>
                            <path className="st13" d="M463.2,161.9c-1.7-1.9-3.1-4.5-1-6.7c-0.2,0.8,0,1.6,0.1,2.4c0.1,0.6,1,0.5,0.9,0c-0.1-0.7-0.3-1.5-0.1-2.2
		c0.1-0.2-0.1-0.4-0.2-0.5c0.1,0,0.2,0,0.3-0.1c-0.1,0.1,0,0.3,0.1,0.4C465.4,157,464.4,159.9,463.2,161.9z"/>
                            <path className="st21" d="M444.2,156.6c-1.2-2.3-1.9-5.2,0.7-6.7c-0.4,0.7-0.4,1.5-0.5,2.3c-0.1,0.6,0.8,0.8,0.9,0.2
		c0.1-0.7,0.1-1.5,0.4-2.1c0.1-0.2,0-0.4-0.1-0.5c0.1,0,0.2,0,0.3,0c-0.1,0.1-0.1,0.3,0,0.5C447.6,152.4,445.8,154.9,444.2,156.6z"
                            />
                            <polygon points="447.5,161.4 447.5,161.4 447.5,161.4 	"/>
                            <path className="st32" d="M430.6,160.4c-1.7-1.9-3-4.6-0.9-6.7c-0.2,0.8,0,1.6,0.1,2.4c0.1,0.6,1,0.5,0.9,0c-0.1-0.7-0.3-1.5-0.1-2.2
		c0.1-0.2,0-0.4-0.2-0.5c0.1,0,0.2,0,0.3-0.1c-0.1,0.1,0,0.3,0.1,0.5C432.9,155.5,431.8,158.4,430.6,160.4z"/>
                            <path className="st14" d="M413.6,157.9c-1.4-2.1-2.4-5,0-6.8c-0.3,0.8-0.2,1.6-0.3,2.4c0,0.6,0.9,0.5,0.9,0c0-0.6-0.1-1.4,0.2-2.1
		c0.1-0.2,0-0.4-0.1-0.5c0.1,0,0.2,0,0.4,0c-0.1,0.2-0.1,0.4,0.1,0.5C416.6,153.4,415,156.1,413.6,157.9z"/>
                            <path className="st32" d="M402,158.6c-2.3-1.2-4.5-3.2-3.3-5.9c0.1,0.8,0.6,1.5,1,2.2c0.3,0.5,1,0,0.7-0.5c-0.3-0.6-0.7-1.2-0.8-1.9
		c0-0.2-0.2-0.4-0.4-0.4c0.1-0.1,0.2-0.1,0.3-0.2c0,0.2,0.1,0.3,0.3,0.4C402.4,153.1,402.4,156.3,402,158.6z"/>
                            <path className="st13" d="M386.1,163.5c-1.7-1.9-3.1-4.5-1-6.7c-0.2,0.8,0,1.6,0.1,2.4c0.1,0.6,1,0.5,0.9,0c-0.1-0.7-0.3-1.5-0.1-2.2
		c0.1-0.2-0.1-0.4-0.2-0.5c0.1,0,0.2,0,0.3-0.1c-0.1,0.1,0,0.3,0.1,0.4C388.3,158.5,387.2,161.4,386.1,163.5z"/>
                            <path className="st21" d="M367,158.1c-1.2-2.3-1.9-5.2,0.7-6.7c-0.4,0.7-0.4,1.5-0.5,2.3c-0.1,0.6,0.8,0.8,0.9,0.2
		c0.1-0.7,0.1-1.5,0.4-2.1c0.1-0.2,0-0.4-0.1-0.5c0.1,0,0.2,0,0.3,0c-0.1,0.1-0.1,0.3,0,0.5C370.4,153.9,368.7,156.5,367,158.1z"/>
                            <polygon points="370.4,163 370.4,163 370.4,163 	"/>
                            <path className="st32" d="M352.2,162.6c-1.7-1.9-3-4.6-0.9-6.7c-0.2,0.8,0,1.6,0.1,2.4c0.1,0.6,1,0.5,0.9,0c-0.1-0.7-0.3-1.5-0.1-2.2
		c0.1-0.2,0-0.4-0.2-0.5c0.1,0,0.2,0,0.3-0.1c-0.1,0.1,0,0.3,0.1,0.5C354.5,157.7,353.4,160.6,352.2,162.6z"/>
                            <path className="st14" d="M335.2,160.1c-1.4-2.1-2.4-5,0-6.8c-0.3,0.8-0.2,1.6-0.3,2.4c0,0.6,0.9,0.5,0.9,0c0-0.6-0.1-1.4,0.2-2.1
		c0.1-0.2,0-0.4-0.1-0.5c0.1,0,0.2,0,0.4,0c-0.1,0.2-0.1,0.4,0.1,0.5C338.2,155.6,336.6,158.3,335.2,160.1z"/>
                            <path className="st32" d="M323.6,160.8c-2.3-1.2-4.5-3.2-3.3-5.9c0.1,0.8,0.6,1.5,1,2.2c0.3,0.5,1,0,0.7-0.5c-0.3-0.6-0.7-1.2-0.8-1.9
		c0-0.2-0.2-0.4-0.4-0.4c0.1-0.1,0.2-0.1,0.3-0.2c0,0.2,0.1,0.3,0.3,0.4C324,155.3,324,158.5,323.6,160.8z"/>
                            <path className="st13" d="M307.7,165.7c-1.7-1.9-3.1-4.5-1-6.7c-0.2,0.8,0,1.6,0.1,2.4c0.1,0.6,1,0.5,0.9,0c-0.1-0.7-0.3-1.5-0.1-2.2
		c0.1-0.2-0.1-0.4-0.2-0.5c0.1,0,0.2,0,0.3-0.1c-0.1,0.1,0,0.3,0.1,0.4C309.9,160.7,308.8,163.6,307.7,165.7z"/>
                            <path className="st21" d="M288.6,160.3c-1.2-2.3-1.9-5.2,0.7-6.7c-0.4,0.7-0.4,1.5-0.5,2.3c-0.1,0.6,0.8,0.8,0.9,0.2
		c0.1-0.7,0.1-1.5,0.4-2.1c0.1-0.2,0-0.4-0.1-0.5c0.1,0,0.2,0,0.3,0c-0.1,0.1-0.1,0.3,0,0.5C292,156.1,290.3,158.7,288.6,160.3z"/>
                            <polygon points="292,165.2 292,165.2 292,165.2 	"/>
                            <path className="st13" d="M773.2,154.9c-1.7-1.9-3.1-4.5-1-6.7c-0.2,0.8,0,1.6,0.1,2.4c0.1,0.6,1,0.5,0.9,0c-0.1-0.7-0.3-1.5-0.1-2.2
		c0.1-0.2-0.1-0.4-0.2-0.5c0.1,0,0.2,0,0.3-0.1c-0.1,0.1,0,0.3,0.1,0.4C775.4,150,774.4,152.9,773.2,154.9z"/>
                            <path className="st21" d="M754.2,149.6c-1.2-2.3-1.9-5.2,0.7-6.7c-0.4,0.7-0.4,1.5-0.5,2.3c-0.1,0.6,0.8,0.8,0.9,0.2
		c0.1-0.7,0.1-1.5,0.4-2.1c0.1-0.2,0-0.4-0.1-0.5c0.1,0,0.2,0,0.3,0c-0.1,0.1-0.1,0.3,0,0.5C757.6,145.4,755.8,147.9,754.2,149.6z"
                            />
                            <polygon points="757.5,154.4 757.5,154.4 757.5,154.4 	"/>
                            <path className="st32" d="M740.6,153.4c-1.7-1.9-3-4.6-0.9-6.7c-0.2,0.8,0,1.6,0.1,2.4c0.1,0.6,1,0.5,0.9,0c-0.1-0.7-0.3-1.5-0.1-2.2
		c0.1-0.2,0-0.4-0.2-0.5c0.1,0,0.2,0,0.3-0.1c-0.1,0.1,0,0.3,0.1,0.5C742.9,148.5,741.8,151.4,740.6,153.4z"/>
                            <path className="st14" d="M723.6,150.9c-1.4-2.1-2.4-5,0-6.8c-0.3,0.8-0.2,1.6-0.3,2.4c0,0.6,0.9,0.5,0.9,0c0-0.6-0.1-1.4,0.2-2.1
		c0.1-0.2,0-0.4-0.1-0.5c0.1,0,0.2,0,0.4,0c-0.1,0.2-0.1,0.4,0.1,0.5C726.6,146.4,725,149.1,723.6,150.9z"/>
                            <path className="st32" d="M712,151.6c-2.3-1.2-4.5-3.2-3.3-5.9c0.1,0.8,0.6,1.5,1,2.2c0.3,0.5,1,0,0.7-0.5c-0.3-0.6-0.7-1.2-0.8-1.9
		c0-0.2-0.2-0.4-0.4-0.4c0.1-0.1,0.2-0.1,0.3-0.2c0,0.2,0.1,0.3,0.3,0.4C712.4,146.1,712.4,149.3,712,151.6z"/>
                            <path className="st13" d="M696.1,156.5c-1.7-1.9-3.1-4.5-1-6.7c-0.2,0.8,0,1.6,0.1,2.4c0.1,0.6,1,0.5,0.9,0c-0.1-0.7-0.3-1.5-0.1-2.2
		c0.1-0.2-0.1-0.4-0.2-0.5c0.1,0,0.2,0,0.3-0.1c-0.1,0.1,0,0.3,0.1,0.4C698.3,151.5,697.2,154.4,696.1,156.5z"/>
                            <path className="st21" d="M677,151.1c-1.2-2.3-1.9-5.2,0.7-6.7c-0.4,0.7-0.4,1.5-0.5,2.3c-0.1,0.6,0.8,0.8,0.9,0.2
		c0.1-0.7,0.1-1.5,0.4-2.1c0.1-0.2,0-0.4-0.1-0.5c0.1,0,0.2,0,0.3,0c-0.1,0.1-0.1,0.3,0,0.5C680.4,146.9,678.7,149.5,677,151.1z"/>
                            <polygon points="680.4,156 680.4,156 680.4,156 	"/>
                            <path d="M774,147.7c0.1-0.1,0.2-0.1,0.2-0.3c0-0.2,0-0.4,0.1-0.7c0,0,0,0,0-0.1c2.5-0.3,5-1.2,7.4-2.3c-0.1-0.5-0.2-1-0.2-1.5
		c-1.5,0.7-3.1,1.4-4.7,1.9c-4,1.3-7.6,0.4-11.2-1.6c-3-1.7-5.7-3.3-9.3-3.6c-2.4-0.2-4.8,0.1-6.9,1c-0.2,0-0.4,0-0.5,0.1
		c-1.7,1.2-3.8,2-5.5,2.5c-4.1,1.3-8.4,1.5-12.5,0.3c-3.1-0.9-5.9-2.4-9.1-3.1c-3.7-0.8-7.3-0.2-10.8,1c-3.9,1.4-7.5,3.6-11.4,4.9
		c-4,1.3-7.6,0.4-11.2-1.6c-3-1.7-5.7-3.3-9.3-3.6c-3.1-0.2-6,0.3-8.6,1.9c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0.2-0.4,0.3-0.7,0.4
		c-1.5,0.9-3.2,1.5-4.6,1.9c-4.1,1.3-8.4,1.5-12.5,0.3c-3.1-0.9-5.9-2.4-9.1-3.1c-3.7-0.8-7.3-0.2-10.8,1c-3.9,1.4-7.5,3.6-11.4,4.9
		c-4,1.3-7.6,0.4-11.2-1.6c-3-1.7-5.7-3.3-9.3-3.6c-1.3-0.1-2.6,0-3.9,0.2c-0.1,0-0.1,0-0.2,0c-0.2,0.1-0.4,0.1-0.7,0.2
		c-0.6,0.1-1.2,0.2-1.8,0.2c-0.5,0.3-1,0.6-1.5,0.9c-0.1,0.1-0.3,0.1-0.4,0.2c-1.2,0.6-2.4,1-3.4,1.3c-4.1,1.3-8.4,1.5-12.5,0.3
		c-3.1-0.9-5.9-2.4-9.1-3.1c-3.7-0.8-7.3-0.2-10.8,1c-3.9,1.4-7.5,3.6-11.4,4.9c-4,1.3-7.6,0.4-11.2-1.6c-3-1.7-5.7-3.3-9.3-3.6
		c-3.1-0.2-6,0.3-8.6,1.9c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0.2-0.4,0.3-0.7,0.4c-1.5,0.9-3.2,1.5-4.6,1.9c-4.1,1.3-8.4,1.5-12.5,0.3
		c-3.1-0.9-5.9-2.4-9.1-3.1c-3.7-0.8-7.3-0.2-10.8,1c-3.9,1.4-7.5,3.6-11.4,4.9c-4,1.3-7.6,0.4-11.2-1.6c-3-1.7-5.7-3.3-9.3-3.6
		c-2.4-0.2-4.8,0.1-6.9,1c-0.2,0-0.4,0-0.5,0.1c-1.7,1.2-3.8,2-5.5,2.5c-4.1,1.3-8.4,1.5-12.5,0.3c-3.1-0.9-5.9-2.4-9.1-3.1
		c-3.7-0.8-7.3-0.2-10.8,1c-3.9,1.4-7.5,3.6-11.4,4.9c-4,1.3-7.6,0.4-11.2-1.6c-3-1.7-5.7-3.3-9.3-3.6c-3.1-0.2-6,0.3-8.6,1.9
		c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0.2-0.4,0.3-0.7,0.4c-1.5,0.9-3.2,1.5-4.6,1.9c-4.1,1.3-8.4,1.5-12.5,0.3c-3.1-0.9-5.9-2.4-9.1-3.1
		c-3.7-0.8-7.3-0.2-10.8,1c-3.9,1.4-7.5,3.6-11.4,4.9c-4,1.3-7.6,0.4-11.2-1.6c-3-1.7-5.7-3.3-9.3-3.6c-3.4-0.2-6.7,0.5-9.5,2.4
		c-0.7,0.5,0,1.5,0.7,1c2-1.4,4.2-2.1,6.5-2.2c-0.1,0.4-0.2,0.9-0.2,1.3c0,0.1,0,0.2,0.1,0.2c-2.6,2-1.7,5.5-0.2,8.1
		c0.2,0.3,0.5,0.2,0.7,0.1c2-1.9,4.3-5.1,2.3-7.9c0.1,0,0.2-0.1,0.3-0.2c0.1-0.2,0.2-0.4,0.2-0.7c0-0.1,0.1-0.2,0.1-0.3
		c0,0,0,0,0-0.1c0.1-0.1,0.2-0.2,0.2-0.4c0,0,0,0,0,0c0.8,0.1,1.5,0.3,2.3,0.5c3.7,1.1,6.6,4,10.5,4.9c0.2,0,0.4,0.1,0.5,0.1
		c0,0.4,0,0.9,0.2,1.3c0,0.1,0.1,0.2,0.2,0.2c-2,2.6-0.2,5.8,1.9,7.9c0.2,0.2,0.6,0.1,0.7-0.1c1.5-2.4,2.9-6.1,0.3-8.2
		c0.1-0.1,0.2-0.1,0.2-0.3c0-0.2,0-0.4,0.1-0.7c0,0,0,0,0-0.1c3.4-0.3,6.6-1.9,9.8-3.4c0.1,0.3,0.3,0.7,0.5,0.9
		c0.1,0.1,0.2,0.1,0.3,0.2c-0.9,3.1,1.7,5.5,4.5,6.7c0.3,0.1,0.6,0,0.6-0.3c0.6-2.8,0.6-6.7-2.6-7.8c0.1-0.1,0.1-0.3,0.1-0.4
		c0-0.2-0.1-0.4-0.2-0.6c0-0.1,0-0.1-0.1-0.2c1.9-0.9,3.9-1.6,5.9-1.9c2.3-0.4,4.5-0.1,6.7,0.5c0,0-0.1,0.1-0.1,0.2
		c-0.1,0.5-0.2,1-0.1,1.5c0,0.1,0.1,0.2,0.1,0.3c-2.3,2.3-1.1,5.6,0.6,8c0.2,0.2,0.5,0.3,0.7,0.1c1.9-2.2,3.8-5.5,1.6-8.1
		c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.2,0.1-0.4,0.1-0.6c0,0,0-0.1,0-0.1c3.6,1.2,7.2,2.7,11.2,2.7c0.4,0,0.8,0,1.3-0.1
		c0,0.3,0.1,0.6,0.1,0.8c0,0.1,0.1,0.2,0.2,0.2c-2,2.6-0.3,5.8,1.8,7.9c0.2,0.2,0.6,0.1,0.7-0.1c1.5-2.4,2.9-6,0.4-8.2
		c0.1-0.1,0.2-0.2,0.2-0.3c0-0.2,0.1-0.4,0.1-0.7c0-0.1,0-0.1,0-0.2c2.4-0.5,4.9-1.3,6.9-2.5c0,0,0.1,0,0.1-0.1
		c2-1.4,4.2-2.1,6.5-2.2c-0.1,0.4-0.2,0.9-0.2,1.3c0,0.1,0,0.2,0.1,0.2c-2.6,2-1.7,5.5-0.2,8.1c0.2,0.3,0.5,0.2,0.7,0.1
		c2-1.9,4.3-5.1,2.3-7.9c0.1,0,0.2-0.1,0.3-0.2c0.1-0.2,0.2-0.4,0.2-0.7c0-0.1,0.1-0.2,0.1-0.3c0,0,0,0,0-0.1
		c0.1-0.1,0.2-0.2,0.2-0.4c0,0,0,0,0,0c0.8,0.1,1.5,0.3,2.3,0.5c3.7,1.1,6.6,4,10.5,4.9c0.2,0,0.4,0.1,0.5,0.1c0,0.4,0,0.9,0.2,1.3
		c0,0.1,0.1,0.2,0.2,0.2c-2,2.6-0.2,5.8,1.9,7.9c0.2,0.2,0.6,0.1,0.7-0.1c1.5-2.4,2.9-6.1,0.3-8.2c0.1-0.1,0.2-0.1,0.2-0.3
		c0-0.2,0-0.4,0.1-0.7c0,0,0,0,0-0.1c3.4-0.3,6.6-1.9,9.8-3.4c0.1,0.3,0.3,0.7,0.5,0.9c0.1,0.1,0.2,0.1,0.3,0.2
		c-0.9,3.1,1.7,5.5,4.5,6.7c0.3,0.1,0.6,0,0.6-0.3c0.6-2.8,0.6-6.7-2.6-7.8c0.1-0.1,0.1-0.3,0.1-0.4c0-0.2-0.1-0.4-0.2-0.6
		c0-0.1,0-0.1-0.1-0.2c1.9-0.9,3.9-1.6,5.9-1.9c2.3-0.4,4.5-0.1,6.7,0.5c0,0-0.1,0.1-0.1,0.2c-0.1,0.5-0.2,1-0.1,1.5
		c0,0.1,0.1,0.2,0.1,0.3c-2.3,2.3-1.1,5.6,0.6,8c0.2,0.2,0.5,0.3,0.7,0.1c1.9-2.2,3.8-5.5,1.6-8.1c0.1-0.1,0.2-0.2,0.2-0.3
		c0.1-0.2,0.1-0.4,0.1-0.6c0,0,0-0.1,0-0.1c3.6,1.2,7.2,2.7,11.2,2.7c0.4,0,0.8,0,1.3-0.1c0,0.3,0.1,0.6,0.1,0.8
		c0,0.1,0.1,0.2,0.2,0.2c-2,2.6-0.3,5.8,1.8,7.9c0.2,0.2,0.6,0.1,0.7-0.1c1.5-2.4,2.9-6,0.4-8.2c0.1-0.1,0.2-0.2,0.2-0.3
		c0-0.2,0.1-0.4,0.1-0.7c0-0.1,0-0.1,0-0.2c2.7-0.5,5.5-1.5,7.6-3c1.5-0.7,3-1.1,4.6-1.2c-0.1,0.4-0.2,0.9-0.2,1.3
		c0,0.1,0,0.2,0.1,0.2c-2.6,2-1.7,5.5-0.2,8.1c0.2,0.3,0.5,0.2,0.7,0.1c2-1.9,4.3-5.1,2.3-7.9c0.1,0,0.2-0.1,0.3-0.2
		c0.1-0.2,0.2-0.4,0.2-0.7c0-0.1,0.1-0.2,0.1-0.3c0,0,0,0,0-0.1c0.1-0.1,0.2-0.2,0.2-0.4c0,0,0,0,0,0c0.8,0.1,1.5,0.3,2.3,0.5
		c3.7,1.1,6.6,4,10.5,4.9c0.2,0,0.4,0.1,0.5,0.1c0,0.4,0,0.9,0.2,1.3c0,0.1,0.1,0.2,0.2,0.2c-2,2.6-0.2,5.8,1.9,7.9
		c0.2,0.2,0.6,0.1,0.7-0.1c1.5-2.4,2.9-6.1,0.3-8.2c0.1-0.1,0.2-0.1,0.2-0.3c0-0.2,0-0.4,0.1-0.7c0,0,0,0,0-0.1
		c3.4-0.3,6.6-1.9,9.8-3.4c0.1,0.3,0.3,0.7,0.5,0.9c0.1,0.1,0.2,0.1,0.3,0.2c-0.9,3.1,1.7,5.5,4.5,6.7c0.3,0.1,0.6,0,0.6-0.3
		c0.6-2.8,0.6-6.7-2.6-7.8c0.1-0.1,0.1-0.3,0.1-0.4c0-0.2-0.1-0.4-0.2-0.6c0-0.1,0-0.1-0.1-0.2c1.9-0.9,3.9-1.6,5.9-1.9
		c2.3-0.4,4.5-0.1,6.7,0.5c0,0-0.1,0.1-0.1,0.2c-0.1,0.5-0.2,1-0.1,1.5c0,0.1,0.1,0.2,0.1,0.3c-2.3,2.3-1.1,5.6,0.6,8
		c0.2,0.2,0.5,0.3,0.7,0.1c1.9-2.2,3.8-5.5,1.6-8.1c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.2,0.1-0.4,0.1-0.6c0,0,0-0.1,0-0.1
		c3.6,1.2,7.2,2.7,11.2,2.7c0.4,0,0.8,0,1.3-0.1c0,0.3,0.1,0.6,0.1,0.8c0,0.1,0.1,0.2,0.2,0.2c-2,2.6-0.3,5.8,1.8,7.9
		c0.2,0.2,0.6,0.1,0.7-0.1c1.5-2.4,2.9-6,0.4-8.2c0.1-0.1,0.2-0.2,0.2-0.3c0-0.2,0.1-0.4,0.1-0.7c0-0.1,0-0.1,0-0.2
		c2.4-0.5,4.9-1.3,6.9-2.5c0,0,0.1,0,0.1-0.1c2-1.4,4.2-2.1,6.5-2.2c-0.1,0.4-0.2,0.9-0.2,1.3c0,0.1,0,0.2,0.1,0.2
		c-2.6,2-1.7,5.5-0.2,8.1c0.2,0.3,0.5,0.2,0.7,0.1c2-1.9,4.3-5.1,2.3-7.9c0.1,0,0.2-0.1,0.3-0.2c0.1-0.2,0.2-0.4,0.2-0.7
		c0-0.1,0.1-0.2,0.1-0.3c0,0,0,0,0-0.1c0.1-0.1,0.2-0.2,0.2-0.4c0,0,0,0,0,0c0.8,0.1,1.5,0.3,2.3,0.5c3.7,1.1,6.6,4,10.5,4.9
		c0.2,0,0.4,0.1,0.5,0.1c0,0.4,0,0.9,0.2,1.3c0,0.1,0.1,0.2,0.2,0.2c-2,2.6-0.2,5.8,1.9,7.9c0.2,0.2,0.6,0.1,0.7-0.1
		c1.5-2.4,2.9-6.1,0.3-8.2c0.1-0.1,0.2-0.1,0.2-0.3c0-0.2,0-0.4,0.1-0.7c0,0,0,0,0-0.1c3.4-0.3,6.6-1.9,9.8-3.4
		c0.1,0.3,0.3,0.7,0.5,0.9c0.1,0.1,0.2,0.1,0.3,0.2c-0.9,3.1,1.7,5.5,4.5,6.7c0.3,0.1,0.6,0,0.6-0.3c0.6-2.8,0.6-6.7-2.6-7.8
		c0.1-0.1,0.1-0.3,0.1-0.4c0-0.2-0.1-0.4-0.2-0.6c0-0.1,0-0.1-0.1-0.2c1.9-0.9,3.9-1.6,5.9-1.9c2.3-0.4,4.5-0.1,6.7,0.5
		c0,0-0.1,0.1-0.1,0.2c-0.1,0.5-0.2,1-0.1,1.5c0,0.1,0.1,0.2,0.1,0.3c-2.3,2.3-1.1,5.6,0.6,8c0.2,0.2,0.5,0.3,0.7,0.1
		c1.9-2.2,3.8-5.5,1.6-8.1c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.2,0.1-0.4,0.1-0.6c0,0,0-0.1,0-0.1c3.6,1.2,7.2,2.7,11.2,2.7
		c0.4,0,0.8,0,1.3-0.1c0,0.3,0.1,0.6,0.1,0.8c0,0.1,0.1,0.2,0.2,0.2c-2,2.6-0.3,5.8,1.8,7.9c0.2,0.2,0.6,0.1,0.7-0.1
		c1.5-2.4,2.9-6,0.4-8.2c0.1-0.1,0.2-0.2,0.2-0.3c0-0.2,0.1-0.4,0.1-0.7c0-0.1,0-0.1,0-0.2c1.5-0.3,3-0.7,4.4-1.3
		c0.1,0,0.2,0,0.3-0.1c0,0,0,0,0,0c0.9-0.4,1.8-0.8,2.6-1.3c1.3-0.5,2.6-0.8,3.9-0.9c-0.1,0.4-0.2,0.9-0.2,1.3c0,0.1,0,0.2,0.1,0.2
		c-2.6,2-1.7,5.5-0.2,8.1c0.2,0.3,0.5,0.2,0.7,0.1c2-1.9,4.3-5.1,2.3-7.9c0.1,0,0.2-0.1,0.3-0.2c0.1-0.2,0.2-0.4,0.2-0.7
		c0-0.1,0.1-0.2,0.1-0.3c0,0,0,0,0-0.1c0.1-0.1,0.2-0.2,0.2-0.4c0,0,0,0,0,0c0.8,0.1,1.5,0.3,2.3,0.5c3.7,1.1,6.6,4,10.5,4.9
		c0.2,0,0.4,0.1,0.5,0.1c0,0.4,0,0.9,0.2,1.3c0,0.1,0.1,0.2,0.2,0.2c-2,2.6-0.2,5.8,1.9,7.9c0.2,0.2,0.6,0.1,0.7-0.1
		c1.5-2.4,2.9-6.1,0.3-8.2c0.1-0.1,0.2-0.1,0.2-0.3c0-0.2,0-0.4,0.1-0.7c0,0,0,0,0-0.1c3.4-0.3,6.6-1.9,9.8-3.4
		c0.1,0.3,0.3,0.7,0.5,0.9c0.1,0.1,0.2,0.1,0.3,0.2c-0.9,3.1,1.7,5.5,4.5,6.7c0.3,0.1,0.6,0,0.6-0.3c0.6-2.8,0.6-6.7-2.6-7.8
		c0.1-0.1,0.1-0.3,0.1-0.4c0-0.2-0.1-0.4-0.2-0.6c0-0.1,0-0.1-0.1-0.2c1.9-0.9,3.9-1.6,5.9-1.9c2.3-0.4,4.5-0.1,6.7,0.5
		c0,0-0.1,0.1-0.1,0.2c-0.1,0.5-0.2,1-0.1,1.5c0,0.1,0.1,0.2,0.1,0.3c-2.3,2.3-1.1,5.6,0.6,8c0.2,0.2,0.5,0.3,0.7,0.1
		c1.9-2.2,3.8-5.5,1.6-8.1c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.2,0.1-0.4,0.1-0.6c0,0,0-0.1,0-0.1c3.6,1.2,7.2,2.7,11.2,2.7
		c0.4,0,0.8,0,1.3-0.1c0,0.3,0.1,0.6,0.1,0.8c0,0.1,0.1,0.2,0.2,0.2c-2,2.6-0.3,5.8,1.8,7.9c0.2,0.2,0.6,0.1,0.7-0.1
		c1.5-2.4,2.9-6,0.4-8.2c0.1-0.1,0.2-0.2,0.2-0.3c0-0.2,0.1-0.4,0.1-0.7c0-0.1,0-0.1,0-0.2c2.4-0.5,4.9-1.3,6.9-2.5
		c0,0,0.1,0,0.1-0.1c2-1.4,4.2-2.1,6.5-2.2c-0.1,0.4-0.2,0.9-0.2,1.3c0,0.1,0,0.2,0.1,0.2c-2.6,2-1.7,5.5-0.2,8.1
		c0.2,0.3,0.5,0.2,0.7,0.1c2-1.9,4.3-5.1,2.3-7.9c0.1,0,0.2-0.1,0.3-0.2c0.1-0.2,0.2-0.4,0.2-0.7c0-0.1,0.1-0.2,0.1-0.3
		c0,0,0,0,0-0.1c0.1-0.1,0.2-0.2,0.2-0.4c0,0,0,0,0,0c0.8,0.1,1.5,0.3,2.3,0.5c3.7,1.1,6.6,4,10.5,4.9c0.2,0,0.4,0.1,0.5,0.1
		c0,0.4,0,0.9,0.2,1.3c0,0.1,0.1,0.2,0.2,0.2c-2,2.6-0.2,5.8,1.9,7.9c0.2,0.2,0.6,0.1,0.7-0.1c1.5-2.4,2.9-6.1,0.3-8.2
		c0.1-0.1,0.2-0.1,0.2-0.3c0-0.2,0-0.4,0.1-0.7c0,0,0,0,0-0.1c3.4-0.3,6.6-1.9,9.8-3.4c0.1,0.3,0.3,0.7,0.5,0.9
		c0.1,0.1,0.2,0.1,0.3,0.2c-0.9,3.1,1.7,5.5,4.5,6.7c0.3,0.1,0.6,0,0.6-0.3c0.6-2.8,0.6-6.7-2.6-7.8c0.1-0.1,0.1-0.3,0.1-0.4
		c0-0.2-0.1-0.4-0.2-0.6c0-0.1,0-0.1-0.1-0.2c1.9-0.9,3.9-1.6,5.9-1.9c2.3-0.4,4.5-0.1,6.7,0.5c0,0-0.1,0.1-0.1,0.2
		c-0.1,0.5-0.2,1-0.1,1.5c0,0.1,0.1,0.2,0.1,0.3c-2.3,2.3-1.1,5.6,0.6,8c0.2,0.2,0.5,0.3,0.7,0.1c1.9-2.2,3.8-5.5,1.6-8.1
		c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.2,0.1-0.4,0.1-0.6c0,0,0-0.1,0-0.1c3.6,1.2,7.2,2.7,11.2,2.7c0.4,0,0.8,0,1.3-0.1
		c0,0.3,0.1,0.6,0.1,0.8c0,0.1,0.1,0.2,0.2,0.2c-2,2.6-0.3,5.8,1.8,7.9c0.2,0.2,0.6,0.1,0.7-0.1c1.5-2.4,2.9-6,0.4-8.2
		c0.1-0.1,0.2-0.2,0.2-0.3c0-0.2,0.1-0.4,0.1-0.7c0-0.1,0-0.1,0-0.2c2.7-0.5,5.5-1.5,7.6-3c1.5-0.7,3-1.1,4.6-1.2
		c-0.1,0.4-0.2,0.9-0.2,1.3c0,0.1,0,0.2,0.1,0.2c-2.6,2-1.7,5.5-0.2,8.1c0.2,0.3,0.5,0.2,0.7,0.1c2-1.9,4.3-5.1,2.3-7.9
		c0.1,0,0.2-0.1,0.3-0.2c0.1-0.2,0.2-0.4,0.2-0.7c0-0.1,0.1-0.2,0.1-0.3c0,0,0,0,0-0.1c0.1-0.1,0.2-0.2,0.2-0.4c0,0,0,0,0,0
		c0.8,0.1,1.5,0.3,2.3,0.5c3.7,1.1,6.6,4,10.5,4.9c0.2,0,0.4,0.1,0.5,0.1c0,0.4,0,0.9,0.2,1.3c0,0.1,0.1,0.2,0.2,0.2
		c-2,2.6-0.2,5.8,1.9,7.9c0.2,0.2,0.6,0.1,0.7-0.1C775.2,153.5,776.6,149.8,774,147.7z M288.6,160.3c-1.2-2.3-1.9-5.2,0.7-6.7
		c-0.4,0.7-0.4,1.5-0.5,2.3c-0.1,0.6,0.8,0.8,0.9,0.2c0.1-0.7,0.1-1.5,0.4-2.1c0.1-0.2,0-0.4-0.1-0.5c0.1,0,0.2,0,0.3,0
		c-0.1,0.1-0.1,0.3,0,0.5C292,156.1,290.3,158.7,288.6,160.3z M307.7,165.7c-1.7-1.9-3.1-4.5-1-6.7c-0.2,0.8,0,1.6,0.1,2.4
		c0.1,0.6,1,0.5,0.9,0c-0.1-0.7-0.3-1.5-0.1-2.2c0.1-0.2-0.1-0.4-0.2-0.5c0.1,0,0.2,0,0.3-0.1c-0.1,0.1,0,0.3,0.1,0.4
		C309.9,160.7,308.8,163.6,307.7,165.7z M323.6,160.8c-2.3-1.2-4.5-3.2-3.3-5.9c0.1,0.8,0.6,1.5,1,2.2c0.3,0.5,1,0,0.7-0.5
		c-0.3-0.6-0.7-1.2-0.8-1.9c0-0.2-0.2-0.4-0.4-0.4c0.1-0.1,0.2-0.1,0.3-0.2c0,0.2,0.1,0.3,0.3,0.4C324,155.3,324,158.5,323.6,160.8z
		 M335.2,160.1c-1.4-2.1-2.4-5,0-6.8c-0.3,0.8-0.2,1.6-0.3,2.4c0,0.6,0.9,0.5,0.9,0c0-0.6-0.1-1.4,0.2-2.1c0.1-0.2,0-0.4-0.1-0.5
		c0.1,0,0.2,0,0.4,0c-0.1,0.2-0.1,0.4,0.1,0.5C338.2,155.6,336.6,158.3,335.2,160.1z M352.2,162.6c-1.7-1.9-3-4.6-0.9-6.7
		c-0.2,0.8,0,1.6,0.1,2.4c0.1,0.6,1,0.5,0.9,0c-0.1-0.7-0.3-1.5-0.1-2.2c0.1-0.2,0-0.4-0.2-0.5c0.1,0,0.2,0,0.3-0.1
		c-0.1,0.1,0,0.3,0.1,0.5C354.5,157.7,353.4,160.6,352.2,162.6z M367,158.1c-1.2-2.3-1.9-5.2,0.7-6.7c-0.4,0.7-0.4,1.5-0.5,2.3
		c-0.1,0.6,0.8,0.8,0.9,0.2c0.1-0.7,0.1-1.5,0.4-2.1c0.1-0.2,0-0.4-0.1-0.5c0.1,0,0.2,0,0.3,0c-0.1,0.1-0.1,0.3,0,0.5
		C370.4,153.9,368.7,156.5,367,158.1z M386.1,163.5c-1.7-1.9-3.1-4.5-1-6.7c-0.2,0.8,0,1.6,0.1,2.4c0.1,0.6,1,0.5,0.9,0
		c-0.1-0.7-0.3-1.5-0.1-2.2c0.1-0.2-0.1-0.4-0.2-0.5c0.1,0,0.2,0,0.3-0.1c-0.1,0.1,0,0.3,0.1,0.4
		C388.3,158.5,387.2,161.4,386.1,163.5z M402,158.6c-2.3-1.2-4.5-3.2-3.3-5.9c0.1,0.8,0.6,1.5,1,2.2c0.3,0.5,1,0,0.7-0.5
		c-0.3-0.6-0.7-1.2-0.8-1.9c0-0.2-0.2-0.4-0.4-0.4c0.1-0.1,0.2-0.1,0.3-0.2c0,0.2,0.1,0.3,0.3,0.4
		C402.4,153.1,402.4,156.3,402,158.6z M413.6,157.9c-1.4-2.1-2.4-5,0-6.8c-0.3,0.8-0.2,1.6-0.3,2.4c0,0.6,0.9,0.5,0.9,0
		c0-0.6-0.1-1.4,0.2-2.1c0.1-0.2,0-0.4-0.1-0.5c0.1,0,0.2,0,0.4,0c-0.1,0.2-0.1,0.4,0.1,0.5C416.6,153.4,415,156.1,413.6,157.9z
		 M430.6,160.4c-1.7-1.9-3-4.6-0.9-6.7c-0.2,0.8,0,1.6,0.1,2.4c0.1,0.6,1,0.5,0.9,0c-0.1-0.7-0.3-1.5-0.1-2.2c0.1-0.2,0-0.4-0.2-0.5
		c0.1,0,0.2,0,0.3-0.1c-0.1,0.1,0,0.3,0.1,0.5C432.9,155.5,431.8,158.4,430.6,160.4z M444.2,156.6c-1.2-2.3-1.9-5.2,0.7-6.7
		c-0.4,0.7-0.4,1.5-0.5,2.3c-0.1,0.6,0.8,0.8,0.9,0.2c0.1-0.7,0.1-1.5,0.4-2.1c0.1-0.2,0-0.4-0.1-0.5c0.1,0,0.2,0,0.3,0
		c-0.1,0.1-0.1,0.3,0,0.5C447.6,152.4,445.8,154.9,444.2,156.6z M463.2,161.9c-1.7-1.9-3.1-4.5-1-6.7c-0.2,0.8,0,1.6,0.1,2.4
		c0.1,0.6,1,0.5,0.9,0c-0.1-0.7-0.3-1.5-0.1-2.2c0.1-0.2-0.1-0.4-0.2-0.5c0.1,0,0.2,0,0.3-0.1c-0.1,0.1,0,0.3,0.1,0.4
		C465.4,157,464.4,159.9,463.2,161.9z M479.2,157c-2.3-1.2-4.5-3.2-3.3-5.9c0.1,0.8,0.6,1.5,1,2.2c0.3,0.5,1,0,0.7-0.5
		c-0.3-0.6-0.7-1.2-0.8-1.9c0-0.2-0.2-0.4-0.4-0.4c0.1-0.1,0.2-0.1,0.3-0.2c0,0.2,0.1,0.3,0.3,0.4
		C479.6,151.5,479.5,154.7,479.2,157z M490.7,156.3c-1.4-2.1-2.4-5,0-6.8c-0.3,0.8-0.2,1.6-0.3,2.4c0,0.6,0.9,0.5,0.9,0
		c0-0.6-0.1-1.4,0.2-2.1c0.1-0.2,0-0.4-0.1-0.5c0.1,0,0.2,0,0.4,0c-0.1,0.2-0.1,0.4,0.1,0.5C493.7,151.8,492.2,154.5,490.7,156.3z
		 M507.7,158.8c-1.7-1.9-3-4.6-0.9-6.7c-0.2,0.8,0,1.6,0.1,2.4c0.1,0.6,1,0.5,0.9,0c-0.1-0.7-0.3-1.5-0.1-2.2c0.1-0.2,0-0.4-0.2-0.5
		c0.1,0,0.2,0,0.3-0.1c-0.1,0.1,0,0.3,0.1,0.5C510,153.9,508.9,156.8,507.7,158.8z M522.6,154.4c-1.2-2.3-1.9-5.2,0.7-6.7
		c-0.4,0.7-0.4,1.5-0.5,2.3c-0.1,0.6,0.8,0.8,0.9,0.2c0.1-0.7,0.1-1.5,0.4-2.1c0.1-0.2,0-0.4-0.1-0.5c0.1,0,0.2,0,0.3,0
		c-0.1,0.1-0.1,0.3,0,0.5C526,150.2,524.2,152.7,522.6,154.4z M541.6,159.7c-1.7-1.9-3.1-4.5-1-6.7c-0.2,0.8,0,1.6,0.1,2.4
		c0.1,0.6,1,0.5,0.9,0c-0.1-0.7-0.3-1.5-0.1-2.2c0.1-0.2-0.1-0.4-0.2-0.5c0.1,0,0.2,0,0.3-0.1c-0.1,0.1,0,0.3,0.1,0.4
		C543.8,154.8,542.8,157.7,541.6,159.7z M557.6,154.8c-2.3-1.2-4.5-3.2-3.3-5.9c0.1,0.8,0.6,1.5,1,2.2c0.3,0.5,1,0,0.7-0.5
		c-0.3-0.6-0.7-1.2-0.8-1.9c0-0.2-0.2-0.4-0.4-0.4c0.1-0.1,0.2-0.1,0.3-0.2c0,0.2,0.1,0.3,0.3,0.4
		C558,149.3,557.9,152.5,557.6,154.8z M569.1,154.1c-1.4-2.1-2.4-5,0-6.8c-0.3,0.8-0.2,1.6-0.3,2.4c0,0.6,0.9,0.5,0.9,0
		c0-0.6-0.1-1.4,0.2-2.1c0.1-0.2,0-0.4-0.1-0.5c0.1,0,0.2,0,0.4,0c-0.1,0.2-0.1,0.4,0.1,0.5C572.1,149.6,570.6,152.3,569.1,154.1z
		 M586.1,156.6c-1.7-1.9-3-4.6-0.9-6.7c-0.2,0.8,0,1.6,0.1,2.4c0.1,0.6,1,0.5,0.9,0c-0.1-0.7-0.3-1.5-0.1-2.2c0.1-0.2,0-0.4-0.2-0.5
		c0.1,0,0.2,0,0.3-0.1c-0.1,0.1,0,0.3,0.1,0.5C588.4,151.7,587.3,154.6,586.1,156.6z M598.6,153.3c-1.2-2.3-1.9-5.2,0.7-6.7
		c-0.4,0.7-0.4,1.5-0.5,2.3c-0.1,0.6,0.8,0.8,0.9,0.2c0.1-0.7,0.1-1.5,0.4-2.1c0.1-0.2,0-0.4-0.1-0.5c0.1,0,0.2,0,0.3,0
		c-0.1,0.1-0.1,0.3,0,0.5C602,149.1,600.3,151.7,598.6,153.3z M617.7,158.7c-1.7-1.9-3.1-4.5-1-6.7c-0.2,0.8,0,1.6,0.1,2.4
		c0.1,0.6,1,0.5,0.9,0c-0.1-0.7-0.3-1.5-0.1-2.2c0.1-0.2-0.1-0.4-0.2-0.5c0.1,0,0.2,0,0.3-0.1c-0.1,0.1,0,0.3,0.1,0.4
		C619.9,153.7,618.8,156.6,617.7,158.7z M633.6,153.8c-2.3-1.2-4.5-3.2-3.3-5.9c0.1,0.8,0.6,1.5,1,2.2c0.3,0.5,1,0,0.7-0.5
		c-0.3-0.6-0.7-1.2-0.8-1.9c0-0.2-0.2-0.4-0.4-0.4c0.1-0.1,0.2-0.1,0.3-0.2c0,0.2,0.1,0.3,0.3,0.4C634,148.3,634,151.5,633.6,153.8z
		 M645.2,153.1c-1.4-2.1-2.4-5,0-6.8c-0.3,0.8-0.2,1.6-0.3,2.4c0,0.6,0.9,0.5,0.9,0c0-0.6-0.1-1.4,0.2-2.1c0.1-0.2,0-0.4-0.1-0.5
		c0.1,0,0.2,0,0.4,0c-0.1,0.2-0.1,0.4,0.1,0.5C648.2,148.6,646.6,151.3,645.2,153.1z M662.2,155.6c-1.7-1.9-3-4.6-0.9-6.7
		c-0.2,0.8,0,1.6,0.1,2.4c0.1,0.6,1,0.5,0.9,0c-0.1-0.7-0.3-1.5-0.1-2.2c0.1-0.2,0-0.4-0.2-0.5c0.1,0,0.2,0,0.3-0.1
		c-0.1,0.1,0,0.3,0.1,0.5C664.5,150.7,663.4,153.6,662.2,155.6z M677,151.1c-1.2-2.3-1.9-5.2,0.7-6.7c-0.4,0.7-0.4,1.5-0.5,2.3
		c-0.1,0.6,0.8,0.8,0.9,0.2c0.1-0.7,0.1-1.5,0.4-2.1c0.1-0.2,0-0.4-0.1-0.5c0.1,0,0.2,0,0.3,0c-0.1,0.1-0.1,0.3,0,0.5
		C680.4,146.9,678.7,149.5,677,151.1z M696.1,156.5c-1.7-1.9-3.1-4.5-1-6.7c-0.2,0.8,0,1.6,0.1,2.4c0.1,0.6,1,0.5,0.9,0
		c-0.1-0.7-0.3-1.5-0.1-2.2c0.1-0.2-0.1-0.4-0.2-0.5c0.1,0,0.2,0,0.3-0.1c-0.1,0.1,0,0.3,0.1,0.4
		C698.3,151.5,697.2,154.4,696.1,156.5z M712,151.6c-2.3-1.2-4.5-3.2-3.3-5.9c0.1,0.8,0.6,1.5,1,2.2c0.3,0.5,1,0,0.7-0.5
		c-0.3-0.6-0.7-1.2-0.8-1.9c0-0.2-0.2-0.4-0.4-0.4c0.1-0.1,0.2-0.1,0.3-0.2c0,0.2,0.1,0.3,0.3,0.4
		C712.4,146.1,712.4,149.3,712,151.6z M723.6,150.9c-1.4-2.1-2.4-5,0-6.8c-0.3,0.8-0.2,1.6-0.3,2.4c0,0.6,0.9,0.5,0.9,0
		c0-0.6-0.1-1.4,0.2-2.1c0.1-0.2,0-0.4-0.1-0.5c0.1,0,0.2,0,0.4,0c-0.1,0.2-0.1,0.4,0.1,0.5C726.6,146.4,725,149.1,723.6,150.9z
		 M740.6,153.4c-1.7-1.9-3-4.6-0.9-6.7c-0.2,0.8,0,1.6,0.1,2.4c0.1,0.6,1,0.5,0.9,0c-0.1-0.7-0.3-1.5-0.1-2.2c0.1-0.2,0-0.4-0.2-0.5
		c0.1,0,0.2,0,0.3-0.1c-0.1,0.1,0,0.3,0.1,0.5C742.9,148.5,741.8,151.4,740.6,153.4z M754.2,149.6c-1.2-2.3-1.9-5.2,0.7-6.7
		c-0.4,0.7-0.4,1.5-0.5,2.3c-0.1,0.6,0.8,0.8,0.9,0.2c0.1-0.7,0.1-1.5,0.4-2.1c0.1-0.2,0-0.4-0.1-0.5c0.1,0,0.2,0,0.3,0
		c-0.1,0.1-0.1,0.3,0,0.5C757.6,145.4,755.8,147.9,754.2,149.6z M773.2,154.9c-1.7-1.9-3.1-4.5-1-6.7c-0.2,0.8,0,1.6,0.1,2.4
		c0.1,0.6,1,0.5,0.9,0c-0.1-0.7-0.3-1.5-0.1-2.2c0.1-0.2-0.1-0.4-0.2-0.5c0.1,0,0.2,0,0.3-0.1c-0.1,0.1,0,0.3,0.1,0.4
		C775.4,150,774.4,152.9,773.2,154.9z"/>
                            <path className="st32" d="M662.2,155.6c-1.7-1.9-3-4.6-0.9-6.7c-0.2,0.8,0,1.6,0.1,2.4c0.1,0.6,1,0.5,0.9,0c-0.1-0.7-0.3-1.5-0.1-2.2
		c0.1-0.2,0-0.4-0.2-0.5c0.1,0,0.2,0,0.3-0.1c-0.1,0.1,0,0.3,0.1,0.5C664.5,150.7,663.4,153.6,662.2,155.6z"/>
                            <path className="st14" d="M645.2,153.1c-1.4-2.1-2.4-5,0-6.8c-0.3,0.8-0.2,1.6-0.3,2.4c0,0.6,0.9,0.5,0.9,0c0-0.6-0.1-1.4,0.2-2.1
		c0.1-0.2,0-0.4-0.1-0.5c0.1,0,0.2,0,0.4,0c-0.1,0.2-0.1,0.4,0.1,0.5C648.2,148.6,646.6,151.3,645.2,153.1z"/>
                            <path className="st32" d="M633.6,153.8c-2.3-1.2-4.5-3.2-3.3-5.9c0.1,0.8,0.6,1.5,1,2.2c0.3,0.5,1,0,0.7-0.5c-0.3-0.6-0.7-1.2-0.8-1.9
		c0-0.2-0.2-0.4-0.4-0.4c0.1-0.1,0.2-0.1,0.3-0.2c0,0.2,0.1,0.3,0.3,0.4C634,148.3,634,151.5,633.6,153.8z"/>
                            <path className="st13" d="M617.7,158.7c-1.7-1.9-3.1-4.5-1-6.7c-0.2,0.8,0,1.6,0.1,2.4c0.1,0.6,1,0.5,0.9,0c-0.1-0.7-0.3-1.5-0.1-2.2
		c0.1-0.2-0.1-0.4-0.2-0.5c0.1,0,0.2,0,0.3-0.1c-0.1,0.1,0,0.3,0.1,0.4C619.9,153.7,618.8,156.6,617.7,158.7z"/>
                            <path className="st21" d="M598.6,153.3c-1.2-2.3-1.9-5.2,0.7-6.7c-0.4,0.7-0.4,1.5-0.5,2.3c-0.1,0.6,0.8,0.8,0.9,0.2
		c0.1-0.7,0.1-1.5,0.4-2.1c0.1-0.2,0-0.4-0.1-0.5c0.1,0,0.2,0,0.3,0c-0.1,0.1-0.1,0.3,0,0.5C602,149.1,600.3,151.7,598.6,153.3z"/>
                            <polygon points="602,158.2 602,158.2 602,158.2 	"/>
                        </g>
                        <g className="calceta">
		<path d="M958.3,280.2c1.1-0.2,2-0.6,2.8-1.4c2.5-2.5,2.4-5.8,3.6-8.9c0.1-0.2,0.1-0.4,0.1-0.6c0.8-5.8-8.5-9.5-12.7-10.7
			c-7.3-2.1-14.1-1.8-21.4-2.1c0-0.1,0-0.2,0-0.3c-0.6-2.6-1.9-5.3-3.9-7.1c-1.9-1.7-4.9-1.8-6.5,0.3c-2.7,3.5,1.2,10.1,5,11.4
			c-0.8,3.4-1.2,7.3,1.7,9.3c-1.9,5.4-3.3,11-4.5,16.7c-1,4.7-2.7,10.6-1.8,15.4c3,16.1,20.9,19.8,34.8,21.9
			c11.6,1.7,27-0.7,27-15.7c0-5.2-5.1-9.1-9.8-10.1c-3.3-0.7-7.8-0.6-10.7-2.3C956.4,292.3,957.8,285.8,958.3,280.2z"/>
                            <path className="st21" d="M966.3,321.6c-1,0.1-1.9,0.2-2.9,0.1c-0.4-3.5,0.2-7.3,1-10.7c0.7-3,1.7-8.7,4.7-10.5c1.4,0.2,3,0.5,4.4,0.9
			c-1.3,1.2-3.3,3.4-5,7.5C966.4,313.7,966.2,319,966.3,321.6z"/>
                            <path className="st21" d="M961,321.7c-0.7,0-1.3-0.1-2-0.2c-1-6.8,0.9-16.6,5.2-21.9c0.6,0.2,1.3,0.3,2,0.5c-1.7,2-2.7,4.9-3.3,7.3
			C961.6,311.8,960.6,316.9,961,321.7z"/>
                            <path className="st32" d="M955.3,280.1c-0.2,2.2-0.3,4.2-0.2,6.1c-3.1,0.6-4.9-5.4-7.6-6.5c-2.5-1-7.4,5.5-8.9,1.1
			c-0.8-2.2-1.8-4-4.2-4.3c-2.8-0.4-3.6,3.5-6.6,2.7c-0.1,0-0.3,0-0.4,0c0.7-2.6,1.5-5.1,2.3-7.6c5.3,2.6,11.9,3.7,17.3,5.6
			C949.1,277.9,952.3,279.6,955.3,280.1z"/>
                            <path className="st32" d="M955.9,292.1c-3,0.2-5.7,0.3-7.6-2.7c-0.6-0.9-1.1-1.8-2.1-2.4c-2.3-1.3-9.5,2.4-10.3-0.4
			c-0.6-1.8-1.1-3.3-3.1-3.4c-1.5-0.1-5.6,2.1-6.9,2.3c0.3-1.4,0.6-2.8,1-4.2c0.1,0.1,0.2,0.1,0.3,0.2c3.1,0.8,7.8-3.8,9.2,0
			c0.6,1.7,1.5,3.3,3.6,3.3c1.9,0,3.9-1,5.5-1.9c2.7-1.6,3.9,2.2,5,3.9c1.4,2,2.8,2.6,4.8,2C955.4,289.9,955.6,291,955.9,292.1z"/>
                            <path className="st15" d="M927.6,256.4c-0.6,0-1.2,0.5-1.4,1.1c0,0.1-0.1,0.2-0.1,0.4c-2-0.8-4.3-4.5-3.5-6.5c0.7-1.8,2.8,0.5,3.3,1.2
			C926.7,253.8,927.3,255,927.6,256.4z"/>
                            <path className="st21" d="M934,259.2c-0.2,0.2-0.3,0.4-0.3,0.8c0.2,2.4-0.1,6.8-3,7.4c-0.3,0.1-0.4,0.2-0.6,0.3
			c-1.6-1.4-2.5-3.2-2-5.3c0.7-3.3,0.7-2.8,3.8-3.1C932.7,259.2,933.3,259.2,934,259.2z"/>
                            <path className="st21" d="M939,259.3c0,0.1-0.1,0.3-0.1,0.4c0,2.9,0.7,9.7-3.5,9.9c-0.5,0-0.8,0.3-1,0.6c-0.7-0.3-1.4-0.7-2-1
			c3.2-1.5,3.9-6.1,3.7-9.3c0-0.4-0.2-0.6-0.4-0.8C936.8,259.1,937.9,259.2,939,259.3z"/>
                            <path className="st21" d="M946,260.3c-0.1,0.1-0.1,0.3-0.1,0.5c-0.1,3.3-1,8.7-4.1,10.7c0,0-0.1,0-0.1,0c-0.7,0-1,0.5-1.1,1
			c-1-0.3-2-0.6-3-0.9c3.9-1.8,3.7-7.9,3.7-11.8c0-0.1,0-0.1,0-0.2C942.5,259.7,944.1,259.9,946,260.3z"/>
                            <path className="st21" d="M952.2,261.7c-0.1,0.2-0.2,0.3-0.2,0.6c0,2.7-0.8,11-4.5,11.2c-0.5,0-0.8,0.3-0.9,0.6
			c-1-0.3-2.1-0.5-3.3-0.9c3.6-2.6,4.8-8.4,4.9-12.5c0,0,0,0,0,0C949.5,261,950.9,261.3,952.2,261.7z"/>
                            <path className="st21" d="M957.5,263.6c0,0.1-0.1,0.1-0.1,0.2c-0.2,3.4-0.1,10.1-4,11.5c-0.4,0.2-0.7,0.5-0.7,0.8
			c-1-0.4-1.9-0.8-2.8-1.1c3.6-2.3,4.5-9,4.5-12.7C955.5,262.7,956.6,263.1,957.5,263.6z"/>
                            <path className="st21" d="M955.3,276.9c3.7-2.3,4.2-7.8,4.4-12.1c1.6,1.2,2.5,2.6,2.1,4.2c-0.6,1.7-0.7,3.5-1.3,5.1
			C959.5,277,957.5,277.4,955.3,276.9z"/>
                            <path className="st32" d="M961.9,298.8c-4.2,5.8-6.1,15.2-5.2,22.4c-0.8-0.1-1.6-0.3-2.4-0.4c-5.2-0.9-11.5-2.1-17-4.4
			c4.2-8.7-0.6-25.1-12.6-25.2c0.2-1.1,0.4-2.2,0.6-3.3c1.9,0.1,4.4-1.5,5.8-1.9c3.2-0.8,2.4,4.9,5.6,5.1c2.2,0.2,3-0.5,4.9-1.4
			c4.5-2.1,3.8,0.8,6,2.8c2.8,2.6,5.4,2.2,9,1.9c0.1,0,0.2-0.1,0.3-0.1C957.9,296.2,959.6,297.7,961.9,298.8z"/>
	</g>
                        <g className="navidad">
		<path d="M484.1,237.7c-3.6,3.7-2.1,7.5,1.8,9.4c-0.9,2.5,0.3,4.8,2.7,5.3c-0.5,2.3,0.4,5,2.9,5.9c0.6,0.2,1.2,0.3,1.8,0.3
			c-2.8,5.8,3.5,11.7,8.5,9.1c0.6,1.2,2.1,1.6,3.7,1.7c0,0.2,0.1,0.4,0.3,0.6c3.6,3.8,9.5,4.8,14.4,3.4c0.1,0,0.2-0.1,0.2-0.1
			c0.3,1.3,1.2,2.6,1.9,3.7c1.9,3,4.5,3.2,7.7,2.3c0.9-0.2,1.5-0.5,2.3-0.9c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.3,0.1,0.6,0.1
			c1.5,0.3,3.3,1,4.8,0.5c1.9-0.6,3.2-1.6,4.1-3c2,1.2,5.2,1,7.3-0.2c0.2,0.2,0.4,0.4,0.8,0.4c4.4-0.1,12.5-0.7,14.8-5.5
			c4.6,1.6,9.9-2,10.7-6.6c1.6,0.2,3.4-1.4,4.2-2.8c0-0.1,0.1-0.2,0.1-0.2c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0.1,0,0.1,0.1,0.2,0.1
			c0.4,0.2,0.8,0.3,1.2-0.1c3.7-3.9,4.6-8.7,3.7-13.5c0,0,0.1,0,0.1,0c3.8-0.8,6.7-5.6,4.4-8.4c1.9-1.7,1.6-4.4-0.3-6.2
			c0.8-0.7,1.2-1.6,1.2-2.7c0.2-0.1,0.3-0.2,0.4-0.4c0.5,0.2,1.2,0.1,1.3-0.6c0.7-4.3,2-12.7-2.7-15.5c-0.1-0.1-0.3-0.2-0.5-0.2
			c0,0,0,0,0,0c1.9-4.6-1.4-8.8-5.6-10.4c0-2.3-1-4.6-2.8-5.7c-1.2-3.3-3.9-5.5-7.4-5.5c0.4-4.3-2.7-5.9-6.5-5.8
			c0-1.3-0.3-2.6-1.4-3.5c-1.4-1.2-3.4-1.2-5.1-0.8c-0.2-3.1-1-6.1-1.7-8.6c0.1-1.1-0.2-2.2-1.2-3.1c-4.3-4-13.7,1.4-16.7,5
			c-0.1,0.1-0.1,0.2-0.2,0.2c-0.4-0.4-0.8-0.7-1.4-0.9c-2.9-1.1-6.3-1-9.3-0.4c-0.1,0-0.3,0-0.4,0.1c0,0,0,0,0,0
			c0-0.3-0.2-0.7-0.6-0.9c-3.8-2.1-13.1-6.8-16.9-2.7c-0.3,0.4-0.6,0.7-0.7,1.2c-0.1,0.1-0.2,0.2-0.2,0.3c-1.4,2.6-1.9,6.6-1.3,10.5
			c-0.1,0-0.2-0.1-0.3-0.1c-2.8-0.1-3.6,2.3-3.6,4.7c-1.2-0.2-2.3,0.3-3.1,1.4c-0.5,0.7-0.8,1.5-0.8,2.3c-4.8-0.5-6.6,7.4-6.9,11.6
			c-2.6-1.1-6-0.6-7.3,2.4c-1.7,3.8,0,6.7,2.5,9.4c-3.7,1.7-6.2,6.6-3.5,9.7c-0.6,0.6-1,1.4-1.1,2.2c-0.5-0.1-1,0.1-1.2,0.7
			c-1,3.1-1.3,7.4-0.2,10.9C484.2,237.6,484.1,237.7,484.1,237.7z"/>
                            <path className="st14" d="M492.4,256.5c-1.7-0.5-2.2-2.6-1.6-4c0.5-0.2,0.7-0.5,0.7-0.9c0.4-0.3,1-0.4,1.7-0.3
			c1.6,0.5,2.8,1.7,2.7,3.4C495.8,256.5,493.8,256.9,492.4,256.5z"/>
                            <path className="st21" d="M508.8,269C508.8,269,508.8,269,508.8,269c1.1,0,2.1-0.1,3-0.3c-0.2,0.9-0.3,1.8-0.3,2.7
			c-1.2-0.5-2.3-1.2-3.4-2.1C508.3,269.2,508.5,269.1,508.8,269z"/>
                            <path className="st21" d="M513.1,271.8c0-0.9,0.1-1.9,0.3-2.8c0.1-0.4,0-0.6-0.3-0.8c0.5-0.2,1.1-0.4,1.6-0.7c-0.1,1.5,0.1,3,0.7,4.4
			C514.7,272,513.9,272,513.1,271.8z"/>
                            <path className="st21" d="M517.2,271.8c0-0.1,0-0.2-0.1-0.3c-0.6-1.4-0.9-3-0.6-4.5c0-0.2,0-0.3-0.1-0.5c0.6-0.4,1.2-0.8,1.8-1.3
			c0,2.1,0,4.1,0.7,6.1C518.4,271.5,517.8,271.6,517.2,271.8z"/>
                            <path className="st15" d="M517.4,245.6c0-0.2,0-0.3,0-0.5c-1.5-4.8-4.7-7.1-8.5-8.4c3.3-1.5,2.8-6.5,0.3-9c0,0,0,0,0.1,0
			c0.6,0.3,1.6,0.1,1.5-0.9c0-0.4,0-0.8-0.1-1.1c0.2-0.6,0.3-1.3,0.2-2c0.3,0,0.5-0.2,0.7-0.5c1.8-3.7,1.1-7.2-0.2-10.7
			c4.1,0.9,6.3-3.7,6.1-7.3c5.3,1.3,9.2,4.9,12.4,9.4c0.6,0.8,1.8,0.9,2.3,0c1-1.8,1.9-3.7,2.6-5.5c0.1,1.9,0.3,3.8,0.7,5.7
			c0.2,1,1.6,1.3,2.2,0.6c3.7-3.8,7.4-7.9,13-8.3c1.8-0.1,2-2.9,2.1-4.3c0.2-3.8-2.5-7.6-5.6-10.6c1.6,0.9,3.3,1.8,5.2,2.1
			c-0.9,1.9,1,3.8,3,4.9c-1.2,2-0.6,4.6,1.2,5.9c0.1,0.3,0.4,0.6,0.8,0.7c0.3,1.8,1.2,2.9,2.4,3.4c-0.4,1.5,1,3.2,2.7,4.2
			c-1,1.5-0.9,3.6,0.6,4.8c-0.1,0.1-0.2,0.3-0.3,0.5c-0.8,3.2,0.1,5,1.9,6c-0.6,0.5-0.9,1.2-1,2.1c-0.1,1,0.1,1.8,0.6,2.5
			c-0.7,0.9-0.9,2.1-0.5,3.3c-0.2-0.1-0.4-0.1-0.6-0.2c-0.7-0.2-1.3,0.3-1.3,1c0,1.5-0.2,3-0.5,4.5c0,0.1,0,0.1,0,0.1
			c-2.1,0-2.6,2.4-2.7,4.5c-2.2,0.2-4.1,1-4.7,3.2c0,0,0,0,0,0.1c0,0.2-0.1,0.5-0.1,0.7c-0.4-0.3-0.9-0.4-1.4,0
			c-1.3,1.1-1.9,2.5-2,4c-1.9,0-2.3,1.6-2.3,3.2c-2.3-0.6-4.5-0.4-6.4,1.1c-2-1.3-5.3-1.5-6.7,0.1c0-0.4-0.1-0.7-0.4-1
			c-2.6-1.8-5.5-2-8-0.5c-0.3-2.7-3-3.6-5.8-3.4C521,248,519.4,246.2,517.4,245.6z"/>
                            <path className="st21" d="M486.2,235.8c0.3-0.1,0.5-0.4,0.5-0.7c0,0.2,0.2-0.3,0.3-0.4c0.2-0.3,0.5-0.6,0.7-0.8c0.5-0.5,1-1,1.5-1.4
			c0.6,0.3,1.1,0.7,1.8,0.9c-1.6,1-3.1,2.3-4.1,3.9C486.5,236.9,486.3,236.4,486.2,235.8z"/>
                            <path className="st21" d="M485.9,229.7c0.5,0.7,1.2,1.3,1.8,1.8c-0.5,0.4-1,0.9-1.4,1.3c-0.2,0.2-0.4,0.4-0.6,0.7
			C485.6,232.3,485.7,231,485.9,229.7z"/>
                            <path className="st21" d="M492.9,234.3c0.5,0.2,0.9,0.3,1.4,0.5c-2.3,1.3-4.3,3-5.8,5.2c-0.3-0.3-0.5-0.6-0.7-0.9
			C488.7,236.9,491,235.4,492.9,234.3z"/>
                            <path className="st21" d="M495.9,235.9c0.3-0.1,0.4-0.4,0.4-0.6c7.7,1.9,16.4,2.3,19,10c-6.4-1.5-12.9-1.2-19.4-2
			c-2.4-0.3-4.4-1.1-6-2.2C491.4,238.8,493.5,237.1,495.9,235.9z"/>
                            <path className="st21" d="M497.8,198.3c1.6,0,3.3-0.3,4.9-0.4c0.2,0.6,0.4,1.2,0.6,1.7c-2,0.1-4,0.5-6,0.9
			C497.5,199.8,497.6,199.1,497.8,198.3z"/>
                            <path className="st14" d="M510.9,198.2c1.6,0.5,2.8,1.7,2.7,3.4c-0.1,1.8-2.1,2.2-3.5,1.8C507.3,202.6,507.6,197.4,510.9,198.2z"/>
                            <path className="st21" d="M512.4,176.8c0.5,0.7,1.1,1.3,1.8,1.6c3.6,2,8.4,0.8,12.4,0.8c-0.3,2.2,0,4.6,0.2,6.1c0.1,1,0.3,2.2,0.8,3.3
			c-2.8,0.9-5.7,2.6-8.2,4.8C514,192.2,511.6,182.9,512.4,176.8z"/>
                            <path d="M527.5,175.7c0.1-0.1,0.2-0.1,0.3-0.2c0,0,0,0,0,0c-0.2,0.3-0.4,0.7-0.6,1.1c-0.1-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.5,0-0.7,0
			C526.5,176.3,527,176,527.5,175.7z"/>
                            <path className="st21" d="M539.6,189.7c2.7,1.7,5.1,3.7,7.2,6.1c1.1,1.2,5.8,8,2.1,8.8c-4.6,1-8,3.9-11.2,7.1
			c-0.4-3.3-0.5-6.7-0.9-9.9c-0.5-3.8-2.3-6.8-3.5-10.3c1.3,0,2.5-0.2,3.6-0.4C538,190.7,538.9,190.3,539.6,189.7z"/>
                            <path className="st21" d="M520.2,196.3c0.7,0,1.1-0.5,1.1-1c2.6-2.2,5.7-3.8,8.3-4.4c0.2,0.1,0.5,0.2,0.7,0.2c0,0.1,0,0.2,0.1,0.3
			c1.1,3.3,2.4,6.1,3.2,9.3c0,0.1-0.1,0.3-0.1,0.4c0,3.8-1.1,7.1-2.8,10.3c-3.8-4.8-8.4-8.3-14.5-9.2
			C516.9,200.1,518.4,198.1,520.2,196.3z"/>
                            <path className="st14" d="M509.2,205.3c1.7,0.6,3.6,0.3,4.9-0.7c0.2,0.1,0.4,0.2,0.6,0.2c0,0,0.1,0,0.1,0c0,0.1,0,0.2,0,0.3
			c0.1,2.2-0.7,5.8-3.6,4.7c-0.4-0.2-0.8-0.1-1,0.1c0-0.1-0.1-0.2-0.1-0.2c-0.7-1.6-1.8-3.5-2.8-5.6
			C507.8,204.5,508.4,205,509.2,205.3z"/>
                            <path className="st21" d="M497.1,202.6c0-0.1,0-0.2,0-0.3c2.3-0.4,4.6-0.9,7-1c0.2,0.3,0.3,0.7,0.5,1c-2.6,0.2-5,0.9-7.3,2.3
			C497.2,204,497.1,203.3,497.1,202.6z"/>
                            <path className="st21" d="M505.5,204.1c3.1,6,6.9,11.9,4.5,17.6c-0.1-0.1-0.2-0.1-0.4-0.1c-1.5-3.5-4.3-6.4-7-9.3
			c-1.6-1.7-3.5-3.7-4.5-5.9C500.3,204.7,502.8,204.1,505.5,204.1z"/>
                            <path className="st21" d="M563.5,199.9c0.3,2,0.6,4.1,0.9,6.1c-0.8,0.5-1.6,0.9-2.3,1c0,0-0.1,0-0.1,0c0.4-0.1,0.7-0.3,0.7-0.8
			c0-1.8,0-3.5,0-5.3C562.9,200.6,563.2,200.3,563.5,199.9z"/>
                            <path className="st21" d="M561.9,207.1c-0.1,0-0.2,0-0.3,0.1c-1,0.1-1.8-0.3-2.2-1.5c0.7-0.3,1-1.1,0.7-1.8c0.2-0.2,0.5-0.5,0.7-0.8
			c0,1.1,0,2.1,0,3.2C560.9,206.9,561.4,207.2,561.9,207.1z"/>
                            <path className="st21" d="M565.7,238.8c0.7-1,1.6-2,2.7-2.6c0.2-0.1,0.3-0.3,0.4-0.5c0.5,0.1,1,0.3,1.5,0.4c-0.1,0.1-0.3,0.1-0.4,0.3
			c-0.8,1.1-1.7,2.1-2.6,3.1C566.8,239.3,566.3,239,565.7,238.8z"/>
                            <path className="st21" d="M563.5,238.2c0.2-1.2,0.3-2.4,0.4-3.6c0.9,0.2,1.8,0.5,2.8,0.7c-1.1,0.8-2,1.9-2.7,3
			C563.8,238.3,563.6,238.2,563.5,238.2z"/>
                            <path className="st14" d="M572.7,232.9c-2.7-0.8-2.5-6,0.8-5.2c1.6,0.5,2.8,1.7,2.7,3.4C576.1,232.9,574.1,233.3,572.7,232.9z"/>
                            <path className="st17" d="M570.6,234.1c-1.5-0.4-2.9-0.7-4.4-1.1c0.3-0.4,0.5-0.9,0.1-1.5c-0.4-0.6,0-0.8,0.6-1c1.4-0.3,1.1-2.2,0-2.6
			c-0.4-0.2-0.6-0.5-0.6-1c0-0.2,0-0.3,0-0.4c0.9,0.2,1.4-0.5,1.5-1.2c1.2,0.2,2.5,0.2,3.9,0.3C568.4,226.6,567.7,231.8,570.6,234.1
			z"/>
                            <path className="st21" d="M569.5,223.1c-0.4,0-0.8-0.1-1.1-0.2c0.3-1.3,0.7-2.5,1-3.7c0.7-0.2,1.4-0.3,2.1-0.5c-0.8,1.3-1.4,2.8-2,4.2
			C569.5,222.9,569.5,223,569.5,223.1z"/>
                            <path className="st21" d="M566.7,222.4c-1.3-0.5-2.1-1.3-2-2.6c1-0.1,1.9-0.2,2.8-0.3C567.3,220.5,567,221.4,566.7,222.4z"/>
                            <path className="st21" d="M558.1,257.4c-1.4,1.1-2.7,2.4-3.6,3.9c-0.1-0.5-0.2-1.1-0.4-1.7c0.9-1.3,1.9-2.4,3.1-3.4
			C557.5,256.6,557.8,257,558.1,257.4z"/>
                            <path d="M553.9,248.1c0,0.8,0.2,1.5,0.4,2.2c-0.7,0.5-1.3,1.2-1.8,2C552.4,250.6,552.7,249.1,553.9,248.1z"/>
                            <path className="st21" d="M553.5,257.5c-0.2-0.8-0.4-1.6-0.6-2.3c0.2-0.1,0.3-0.3,0.4-0.5c0.3-1,0.8-1.9,1.6-2.6
			c0.4,0.9,0.9,1.8,1.4,2.6C555.2,255.5,554.3,256.5,553.5,257.5z"/>
                            <path className="st21" d="M533.7,255.8c0.4,0.3,0.7,0.2,1,0c0,0.1-0.1,0.2-0.1,0.2c-3.8,2.3-5.6,6.5-8.3,9.9c-1.6,1.9-3.6,3.5-5.8,4.6
			c-0.8-2.1-0.5-4.4-0.5-6.6c0-0.2,0-0.3-0.1-0.4C523.6,259.4,527.8,251.7,533.7,255.8z"/>
                            <path className="st17" d="M548.9,272.6c-0.5,1.1-1.8,1.5-2.9,1.5c-0.5,0-2.6,0-2.8-0.8c0-0.3,0.1-0.5,0.1-0.8c0.2-1.7-2.4-1.7-2.7,0
			c-0.1,0.2-0.2,0.4-0.2,0.7c0,0.1,0,0.1,0,0.2c-1.1,5.3-6,1.1-8.9,2.4c-1,0.5-2.1,0.9-3.2,1.2c-0.8,0.2-6.5-2.6-5.4-4.9
			c5.2-3.3,7.2-10.5,12.1-13.9c0.8,0.4,2,0.1,2-1.1c0-1.5,3.3-0.5,3.7,0.2c0.5,0.7,1.5,0.9,2.1,0.3c1.8-2,3.8-1.9,6.1-0.8
			c0.9,0.4,2,0,2-1.1c1,5.9,2.5,11.3-0.2,16.7C550.3,271.8,549.3,271.7,548.9,272.6z"/>
                            <path className="st21" d="M560.8,271.9c-2.4,1.5-5.5,2-8.5,2.2c1.7-3,2.3-6.2,2.3-9.6c0.1-0.1,0.3-0.2,0.3-0.4
			c0.9-2.1,2.3-3.9,4.2-5.3c0.6,0.8,1.1,1.5,1.6,2.2C563.3,264.6,565.3,269,560.8,271.9z"/>
                            <path className="st17" d="M577.6,259.9c-0.3,0.5-0.6,0.9-1,1.3c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1-0.1-0.2-0.2-0.2-0.2
			c-0.4-0.6-1.4-0.5-1.9,0c-0.1,0.1-0.1,0.2-0.2,0.3c-0.4,0.2-0.7,0.6-0.7,1.1c0.1,3.8-3.9,6.7-7.5,5.5c0.5-5.2-4.2-9.2-7.2-13.6
			c-0.1-0.2-0.2-0.4-0.4-0.6c-1.2-1.9-2.1-3.9-2.2-6.1c0.4-0.2,0.6-0.5,0.7-1.1c0.1-1.9,2-1.8,3.5-1.8c0.7,0,1.3-0.6,1.3-1.3
			c0-0.7,0-1.4,0-2c0-0.3,0.1-0.6,0.2-1c0,0,0-0.1,0-0.1c0,0,0.1,0,0.3,0.1c0.6,0.2,1.1,0,1.4-0.5c0.2,0,0.4,0.1,0.5,0.1
			c0.1,0,0.2,0,0.3,0.1c4,1.1,7.8,4.7,10.1,7.8c2,2.6,3.4,5.2,4.4,8.3c0.3,0.9,0.4,1.9,0.6,2.8C578.7,259.1,578,259.2,577.6,259.9z"
                            />
                            <path className="st21" d="M581.1,258.5c-0.6-1.7-0.7-3.8-1.4-5.3c-1.2-2.8-2.9-5.2-4.8-7.5c-1.6-1.9-3.7-3.8-5.9-5.2
			c0.8-1,1.7-2.1,2.6-3.1c0.2-0.3,0.2-0.6,0-0.8c2.7,0.8,5.2,1.8,7.4,3.7C584.2,244.6,585,253.1,581.1,258.5z"/>
                            <path className="st17" d="M584.6,245C584.6,245,584.6,245,584.6,245c-0.2-0.5-0.4-1-0.6-1.5c-2-4.6-5.7-6.9-9.9-8.4
			c1.9-0.1,3.6-1.2,4.1-3.3c0.7-2.9-1.5-5.4-4.1-6.2c-0.1,0-0.1,0-0.2,0c5.9-0.2,12.4-0.9,14.5,1.6c-0.6,0.2-1,0.8-0.7,1.5
			c0.4,1.4,0,2.3-1.3,3c-0.9,0.4-0.9,1.9,0,2.3c1,0.5,3.9,2.6,0.7,3.8c-1.3,0.5-1.2,2.1,0,2.6C589.9,241.5,585.9,244.7,584.6,245z"
                            />
                            <path className="st21" d="M590.7,220.8c0.2,2,0.1,4.2-0.2,6.2c-2.5-4.3-12.5-3.3-19.3-3.8c0.6-1.6,1.3-3.1,2.3-4.5
			c0.2-0.2,0.2-0.5,0.1-0.7c2.5-0.7,5.1-1.5,7.8-2C586.3,215.1,590.1,214.8,590.7,220.8z"/>
                            <path className="st17" d="M586.6,212.8c0,0.1-0.1,0.2-0.1,0.3c-1.9,0.1-3.9,0.7-5.6,1c-5.1,0.8-9.3,2.9-14.3,3.5c0-0.6-0.2-1.1-1-1.2
			c-1.1-0.2-1.9-1.8-0.4-2c1.4-0.2,2-2.3,0.4-2.6c-0.8-0.1-1.5-0.4-2.2-0.8c-0.6-0.3-1.5-0.9-0.7-1.3c0.2-0.1,0.4-0.3,0.5-0.4
			c0.7-0.2,1.4-0.5,2.1-0.9c2.9-1.7,7.3-8,11.4-9.1c0.3,0.3,0.7,0.4,1.2,0.3c2.5-0.6,3.1,2.5,3,4.4c-0.7-0.6-1.5-1-2.4-1.3
			c-5.8-1.4-7.2,7.3-2.2,9.1c2.6,0.9,5.6-0.2,6.3-3c0.3-1.2,0.1-2.3-0.4-3.3C585.4,206.5,588,209.3,586.6,212.8z"/>
                            <path className="st14" d="M578,204.6c1.6,0.5,2.8,1.7,2.7,3.4c-0.1,1.8-2.1,2.2-3.5,1.8C574.4,209,574.7,203.8,578,204.6z"/>
                            <path className="st21" d="M578.2,196.8c-0.3,0-0.6,0.1-1,0.1c-0.3,0.1-0.5,0.2-0.7,0.4c-1.5,0.5-3,1.4-4.3,2.3
			c-0.9,0.6-3.6,3.4-6.3,5.4c-0.3-2.2-0.6-4.3-0.9-6.5c0-0.1-0.1-0.2-0.1-0.2c0.8-0.9,1.8-1.8,2.8-2.5
			C571.4,193.4,576,193.3,578.2,196.8z"/>
                            <path className="st17" d="M564.1,184.8c0.5,0.6,0.2,2,0.1,2.7c-0.1,0.9,0.9,1.4,1.7,1.3c2.6-0.4,5.5-0.2,4.9,3.2c0,0.1,0,0.2,0,0.3
			c-4.5,1.2-6.9,4.4-9.6,7.5c0,0,0,0,0,0c-0.9,1-1.8,2.1-2.9,3.1c-1.2-0.5-1.2-2.2,0-3c1-0.6,0.8-2.1-0.3-2.4
			c-0.9-0.3-3.9-1.6-2.7-2.6c0.5-0.4,0.5-0.8,0.4-1.2c1.3-0.7,2.4-1.7,3.1-2.9c1.1-1.7,1.6-3.9,1.7-6.1c0,0,0,0,0,0
			C561.5,184.1,563.3,183.8,564.1,184.8z"/>
                            <path className="st21" d="M557,176.9c0.8,3.5,1.1,7.3,0.2,10.8c-2,8.2-10.4,0.3-15.2,0c-0.2,0-0.4,0-0.6,0.1c-0.1,0-0.1-0.1-0.2-0.1
			c1.2-2.5,1.2-5.8,0.9-8.5c0,0,0,0,0.1,0c4.7,0.9,9.5,1.5,13.7-1.3C556.3,177.5,556.6,177.2,557,176.9z"/>
                            <path className="st21" d="M554,175.9c-0.9,0.6-1.9,0.9-2.9,1.1c1-1.8,2.1-3.5,3.4-5.2c0.1-0.1,0.1-0.2,0.2-0.3
			C556.5,172,556,174.5,554,175.9z"/>
                            <path className="st21" d="M552.4,171.6c-1.2,1.6-2.2,3.2-3.1,5c-0.1,0.2-0.1,0.4-0.1,0.6c-0.7,0-1.4,0-2.1-0.1
			c0.6-1.3,1.5-2.5,2.5-3.6c0.3-0.3,0.3-0.8,0.1-1.1C550.7,172.1,551.6,171.9,552.4,171.6z"/>
                            <path className="st21" d="M547.4,173.5c-0.8,1-1.5,2.2-2,3.4c-0.7-0.1-1.5-0.2-2.2-0.4C544.3,175.3,545.8,174.3,547.4,173.5z"/>
                            <path className="st21" d="M536.8,176.4c2.1,0.3,2.5,1.2,2.7,3.2c0.2,1.7,0,3.5-0.3,5.3c-0.4,2.1-0.9,3-3,3.5c-1.8,0.5-3.8,0.4-5.6-0.1
			c-0.8-0.2-1-2.4-1.1-3.1c-0.2-1.4-0.9-7.9,0.9-8.6C532.4,176.2,534.6,176.1,536.8,176.4z"/>
                            <path className="st21" d="M523.1,176.7c-0.7,0-1.4,0.1-2.1,0.1c1.1-1.1,2.3-2.1,3.8-2.7c0,0,0,0,0,0c0.4,0.2,0.8,0.4,1.2,0.6
			C524.9,175.2,524,175.8,523.1,176.7z"/>
                            <path className="st21" d="M518.7,176.8c0,0-0.1,0-0.1,0c-0.8,0-1.8-0.2-2.6-0.5c1.1-1.6,2.7-2.6,4.3-3.6c0.2-0.1,0.3-0.2,0.3-0.4
			c0.7,0.2,1.5,0.5,2.2,0.8C521.2,174,519.8,175.3,518.7,176.8z"/>
                            <path className="st21" d="M514.8,171.8c1.1-0.3,2.3-0.3,3.6-0.1c-1.5,1-2.9,2-3.9,3.5c-0.2-0.2-0.3-0.5-0.4-0.8
			C513.7,173.4,513.6,172.2,514.8,171.8z"/>
                            <path className="st17" d="M504.7,191.7c-0.1-0.1-0.1-0.5-0.1-0.8c0-0.1,0.2-0.6,0.2-0.8c0.1-0.3,0.2-0.4,0.4-0.5c0,0,0,0,0.1,0
			c0,0,0,0,0.1,0c-0.1,0-0.1,0,0.1,0c0,0,0.3,0.2,0.3,0.2c0.1,0.1,0.3,0.1,0.4,0.2c0.6,1.3,2.8,0.6,2.5-1c-0.2-0.9-0.8-4.1,0.8-4
			c0.3,0,0.5-0.1,0.7-0.2c1.1,4.7,3.5,8.9,7.1,10.7c-0.9,1-1.7,2.2-2.4,3.3c-0.8-1.2-2.1-2.2-3.5-2.6c-3.9-0.9-5.8,2.6-5.1,5.6
			c-1.3-2.7-2.2-5.6-2.3-8.2c0.1,0,0.2,0,0.2-0.1C504.9,193.2,505,192.4,504.7,191.7z"/>
                            <path className="st21" d="M502,192.9c0,1.1,0.1,2.2,0.3,3.3c-1.3,0.1-2.6,0.3-3.9,0.4C499.2,194.4,500.4,192.7,502,192.9z"/>
                            <path className="st17" d="M492.8,216.2c1.2,0,1.8-1.4,0.9-2.3c-0.7-0.7-1.3-1.3-2-2c-2.9-2.5-2-4.8,2.8-6.9c0.2,0.2,0.5,0.3,0.7,0.3
			c0.7,2.4,2.5,4.6,4,6.4c3.1,3.7,7.1,7,8.7,11.5c0,0.1,0,0.2,0.1,0.3c0.1,0.2,0.1,0.4,0.2,0.7c0.1,1.2-1.2,1.9-2.1,2.2
			c-1.1,0.4-1.4,2.3,0,2.6c2.1,0.5,3.7,4.9,0.9,5.4c-0.8,0.1-1.1,0.8-0.9,1.4c-5.6-1.4-12-1.8-16.5-5.2c0,0,0,0,0,0
			c-0.2-0.1-0.4-0.3-0.5-0.4c0.2-0.4,0.3-0.8,0-1.3c-1.3-1.2-1.1-2.4,0.3-3.5c1.1-0.3,1.3-1.9,0.3-2.4
			C485.7,220.7,490.3,216.3,492.8,216.2z"/>
                            <path className="st17" d="M488.2,242.8c7.3,4.4,16.6,2.8,24.8,4.2c0.1,0.8,1,1.6,2,1.1c1.8-0.9,4.1,1.1,3,3c-0.6,1,0.4,2.2,1.5,2
			c1.6-0.4,5.1-1.1,4.5,1.6c-0.1,0.4,0,0.7,0.1,0.9c-4.5,4.4-7.6,10.6-14.3,11.3c-0.4-0.5-1-0.7-1.7-0.4c-1.2,0.5-4.6,1.3-3.8-1
			c0.4-1.2-1.4-2.3-2.2-1.3c-1.5,1.9-3.4,2.5-5.3,0.9c-1.5-1.3-2-3.5-1.1-5.2c0.3-0.6,0.2-1.1-0.2-1.5c1.1-0.5,1.9-1.5,2.3-2.8
			c0.7-2.9-1.5-5.4-4.1-6.2c-1.6-0.4-2.8,0-3.7,0.7c-0.1,0-0.2,0-0.3,0c-2.2,0.3-1.6-1.8-0.9-2.8c0.5-0.7,0-1.7-0.8-2
			c-2.8-0.9-4.5-2.6-2.6-5C486.2,241.2,487,242.1,488.2,242.8z"/>
	</g>
                        <g className="navidad">
		<path d="M140.4,351.2c-2.1-5-5.4-9.8-10-12.5c2.4-0.4,4.5-1.2,5.9-2.4c4.5-3.9,2.3-14.2,0.2-18.9c0.2-3.1-5.9-2.5-7.5-2
			c-5.1,1.4-9.1,6.2-13.1,9.3c0,0,0,0,0,0c-3.2-1.7-7.1-1.3-10.4,0.2c-4.6-2.8-10.1-3.6-15.4-3.6c-1.9,0-7.7,0-7.7,3.1
			c0,0.4,0.1,0.7,0.2,1.1c-0.1,5.5-3.1,18.5,4.6,19.5c1.7,0.2,3.6,0,5.4-0.4c-3.9,4.9-6.2,11.8-7.3,17.2c-2.7,2-4.1,5.1-1.8,8.1
			c2.6,3.3,7.2,4.5,11.3,4.5c-0.2,0.6-0.3,1.2-0.4,1.9c-0.3,3.3,3.6,5.2,6.4,5.4c3.3,0.2,4.9-2.6,5.5-5.8c0,0,0,0,0,0
			c4.2,0.1,8.4,0.8,11.9-2.1c0.4-0.3,0.8-0.7,1-1.1c2.2,0.6,5-0.7,6.9-1.4c0,0,0,0,0.1,0c0.7,2.1,2.3,3.5,4.6,3.3
			c2.9-0.2,5.2-3.4,5.6-6.1c0.1-0.7,0.1-1.3-0.1-2c2.7-1.4,5.5-3.5,6.8-6.3c0.3-0.6,0.3-1.2,0.2-1.7
			C145.4,355,144.1,352,140.4,351.2z M126,319.6c1.4-1,2.9-1.7,4.5-2.1c0.5-0.1,3.3,0.2,3.5-0.2c-0.3,0.6-1.1,1-1.6,1.4
			c-3.3,2.3-7.2,4-11.3,4.9C122.7,322.2,124.3,320.8,126,319.6z M128.9,323.4c1.6-0.7,4.4-1.9,6.1-3.4c1.5,4.3,2.1,9.9,0.2,13.8
			c-1.2,2.4-6,2.3-8.1,2.4c-2.6,0.1-5.7,0.1-8-1.2c0,0-0.1,0-0.1-0.1c0.1-0.5,0.1-1.1,0.2-1.6c0.2-2.2,0.4-4.8-0.8-6.7
			C121.9,326.1,125.5,324.9,128.9,323.4z M106,327.4c0.1,0,0.1-0.1,0.2-0.1c0,0,0,0,0,0c2.9-1.5,7.1-2.1,9.7,0.4
			c1.5,1.4,0.6,5.2,0.5,7c-0.3,2.5-1,3.5-3.5,3.8c-2.5,0.3-5.2,0.3-7.7,0.1C105.4,334.7,107.1,331.2,106,327.4z M86.6,324
			c1.8-0.4,3.7-0.4,5.5-0.3c2.9,0.1,5.9,0.6,8.6,1.6c-4.7,0.4-9.5,1-14.1-0.2C84.7,324.6,85.2,324.4,86.6,324z M90.8,342.2
			c-4.4,1-5.5-1-6-5.2c-0.3-3.1,0.1-6.3,0.3-9.5c4.5,1.8,13.8,0.6,18.3,0.2c1.2,2.9,0.1,6.1-0.5,9.2c0,0,0,0-0.1,0
			C99.3,339.4,95,341.1,90.8,342.2z M103,340.8c0.1,0,0.2,0,0.2-0.1c0.2,0.1,0.4,0.1,0.6,0.2c2.2,0.3,5,0.6,7.6,0.4
			c0.1,0.1,0.2,0.2,0.3,0.3c7,4.5,6.2,15.9,5.6,23.3c0,0,0,0.1,0,0.1c-3.2-2.5-8.4-3.4-11.8-4c-5.4-1.1-11.8-1.8-17.3-0.2
			C90.1,352.9,94.3,342.1,103,340.8z M95.9,371.8c-3.2,0.1-6.6-0.5-9.1-2.5c-0.1,0-0.1-0.1-0.2-0.1c2.7-2.6,8.2-2.4,12.1-2.1
			c-0.6,1.7-1.6,3.2-2.5,4.8C96,371.8,95.9,371.8,95.9,371.8z M104.3,369.1c0.4,2.7,0.6,9.8-3.3,10c-1.3,0.1-2.9-0.7-3.6-1.7
			c-0.9-1.1,0.1-2.9,0.7-4c1-2,2.5-3.8,3.1-6c0,0,0-0.1,0-0.1c1.2,0.1,2.6,0.2,4,0.4C104.6,367.9,104.2,368.4,104.3,369.1z
			 M115.9,372.4c-2.7,1.7-6.1,1-9.1,0.9c0.1-1.9,0-3.6-0.1-4.9c0-0.2-0.1-0.4-0.2-0.5c3.6,0.7,7.1,1.9,9.3,4.3
			C115.8,372.3,115.9,372.4,115.9,372.4C115.9,372.4,115.9,372.4,115.9,372.4z M117.7,370.7c0-0.1-0.1-0.2-0.2-0.2
			c-3.8-4.3-10.8-5.1-16.1-5.7c-4.6-0.5-12.3-1.1-16.3,2.3c-0.3-1.3,0.6-2.6,2.1-3.6c0.2-0.1,0.3-0.3,0.4-0.4
			c5.1-1.8,11-1.1,16.2-0.3c4,0.6,11.7,1.7,13.9,5.7c0.1,0.2,0.2,0.3,0.4,0.4C118.1,369.5,118,370.1,117.7,370.7z M114.7,340.7
			c0.5-0.1,0.9-0.3,1.3-0.5c1.3-0.6,2-1.7,2.4-2.9c1.6,0.8,3.7,1.3,6,1.5c0.1,0,0.2,0.1,0.3,0.1c6.4,1.4,10.8,6.7,13.3,12.5
			c-6.7,1-12.9,4.1-17.8,8.7C120.3,353.2,119.6,345.3,114.7,340.7z M125.7,368.9c-1,0.3-2,0.7-3,1c-0.6,0.2-1.4,0.5-2.2,0.4
			c0.1-0.6,0.1-1.2,0.1-1.9c1.3-1.7,2.8-3.1,4.5-4.3c0.7,1.3,0.8,3,0.9,4.7C125.9,368.8,125.8,368.8,125.7,368.9z M134.1,368.9
			c-0.5,2-3.6,4.8-5,2.1c-1.3-2.5-0.3-5.7-1.7-8.2c1-0.6,2.1-1.1,3.2-1.5c0,0.4,0.2,0.8,0.6,1.1C133,363.7,134.7,366.4,134.1,368.9z
			 M140,360.9c-1.1,1.3-2.7,2.4-4.2,3.2c-0.8-1.5-2-2.8-3.2-3.7c0.9-0.3,1.8-0.7,2.6-0.9C136.8,358.9,143,357.4,140,360.9z
			 M141.8,356.2c-0.4-0.2-0.9-0.3-1.4-0.3c-7-0.1-15.6,4.2-20.6,9.5c0-0.1-0.1-0.2-0.1-0.3c0.1-0.7,0.1-1.4,0.1-2.1
			c5-5.2,11.7-8.9,19-9.7c0.2,0.1,0.4,0.2,0.6,0.2C141.2,353.7,142.1,354.7,141.8,356.2z"/>
                            <path className="st21" d="M126,319.6c1.4-1,2.9-1.7,4.5-2.1c0.5-0.1,3.3,0.2,3.5-0.2c-0.3,0.6-1.1,1-1.6,1.4c-3.3,2.3-7.2,4-11.3,4.9
			C122.7,322.2,124.3,320.8,126,319.6z"/>
                            <path className="st21" d="M128.9,323.4c1.6-0.7,4.4-1.9,6.1-3.4c1.5,4.3,2.1,9.9,0.2,13.8c-1.2,2.4-6,2.3-8.1,2.4
			c-2.6,0.1-5.7,0.1-8-1.2c0,0-0.1,0-0.1-0.1c0.1-0.5,0.1-1.1,0.2-1.6c0.2-2.2,0.4-4.8-0.8-6.7C121.9,326.1,125.5,324.9,128.9,323.4
			z"/>
                            <path className="st21" d="M106,327.4c0.1,0,0.1-0.1,0.2-0.1c0,0,0,0,0,0c2.9-1.5,7.1-2.1,9.7,0.4c1.5,1.4,0.6,5.2,0.5,7
			c-0.3,2.5-1,3.5-3.5,3.8c-2.5,0.3-5.2,0.3-7.7,0.1C105.4,334.7,107.1,331.2,106,327.4z"/>
                            <path className="st21" d="M86.6,324c1.8-0.4,3.7-0.4,5.5-0.3c2.9,0.1,5.9,0.6,8.6,1.6c-4.7,0.4-9.5,1-14.1-0.2
			C84.7,324.6,85.2,324.4,86.6,324z"/>
                            <path className="st21" d="M90.8,342.2c-4.4,1-5.5-1-6-5.2c-0.3-3.1,0.1-6.3,0.3-9.5c4.5,1.8,13.8,0.6,18.3,0.2
			c1.2,2.9,0.1,6.1-0.5,9.2c0,0,0,0-0.1,0C99.3,339.4,95,341.1,90.8,342.2z"/>
                            <path className="st14" d="M103,340.8c0.1,0,0.2,0,0.2-0.1c0.2,0.1,0.4,0.1,0.6,0.2c2.2,0.3,5,0.6,7.6,0.4c0.1,0.1,0.2,0.2,0.3,0.3
			c7,4.5,6.2,15.9,5.6,23.3c0,0,0,0.1,0,0.1c-3.2-2.5-8.4-3.4-11.8-4c-5.4-1.1-11.8-1.8-17.3-0.2C90.1,352.9,94.3,342.1,103,340.8z"
                            />
                            <path className="st14" d="M95.9,371.8c-3.2,0.1-6.6-0.5-9.1-2.5c-0.1,0-0.1-0.1-0.2-0.1c2.7-2.6,8.2-2.4,12.1-2.1
			c-0.6,1.7-1.6,3.2-2.5,4.8C96,371.8,95.9,371.8,95.9,371.8z"/>
                            <path className="st14" d="M104.3,369.1c0.4,2.7,0.6,9.8-3.3,10c-1.3,0.1-2.9-0.7-3.6-1.7c-0.9-1.1,0.1-2.9,0.7-4c1-2,2.5-3.8,3.1-6
			c0,0,0-0.1,0-0.1c1.2,0.1,2.6,0.2,4,0.4C104.6,367.9,104.2,368.4,104.3,369.1z"/>
                            <path className="st14" d="M115.9,372.4c-2.7,1.7-6.1,1-9.1,0.9c0.1-1.9,0-3.6-0.1-4.9c0-0.2-0.1-0.4-0.2-0.5c3.6,0.7,7.1,1.9,9.3,4.3
			C115.8,372.3,115.9,372.4,115.9,372.4C115.9,372.4,115.9,372.4,115.9,372.4z"/>
                            <path className="st14" d="M117.7,370.7c0-0.1-0.1-0.2-0.2-0.2c-3.8-4.3-10.8-5.1-16.1-5.7c-4.6-0.5-12.3-1.1-16.3,2.3
			c-0.3-1.3,0.6-2.6,2.1-3.6c0.2-0.1,0.3-0.3,0.4-0.4c5.1-1.8,11-1.1,16.2-0.3c4,0.6,11.7,1.7,13.9,5.7c0.1,0.2,0.2,0.3,0.4,0.4
			C118.1,369.5,118,370.1,117.7,370.7z"/>
                            <path className="st14" d="M114.7,340.7c0.5-0.1,0.9-0.3,1.3-0.5c1.3-0.6,2-1.7,2.4-2.9c1.6,0.8,3.7,1.3,6,1.5c0.1,0,0.2,0.1,0.3,0.1
			c6.4,1.4,10.8,6.7,13.3,12.5c-6.7,1-12.9,4.1-17.8,8.7C120.3,353.2,119.6,345.3,114.7,340.7z"/>
                            <path className="st14" d="M125.7,368.9c-1,0.3-2,0.7-3,1c-0.6,0.2-1.4,0.5-2.2,0.4c0.1-0.6,0.1-1.2,0.1-1.9c1.3-1.7,2.8-3.1,4.5-4.3
			c0.7,1.3,0.8,3,0.9,4.7C125.9,368.8,125.8,368.8,125.7,368.9z"/>
                            <path className="st14" d="M134.1,368.9c-0.5,2-3.6,4.8-5,2.1c-1.3-2.5-0.3-5.7-1.7-8.2c1-0.6,2.1-1.1,3.2-1.5c0,0.4,0.2,0.8,0.6,1.1
			C133,363.7,134.7,366.4,134.1,368.9z"/>
                            <path className="st14" d="M140,360.9c-1.1,1.3-2.7,2.4-4.2,3.2c-0.8-1.5-2-2.8-3.2-3.7c0.9-0.3,1.8-0.7,2.6-1
			C136.8,358.9,143,357.4,140,360.9z"/>
                            <path className="st14" d="M141.8,356.2c-0.4-0.2-0.9-0.3-1.4-0.3c-7-0.1-15.6,4.2-20.6,9.5c0-0.1-0.1-0.2-0.1-0.3
			c0.1-0.7,0.1-1.4,0.1-2.1c5-5.2,11.7-8.9,19-9.7c0.2,0.1,0.4,0.2,0.6,0.2C141.2,353.7,142.1,354.7,141.8,356.2z"/>
	</g>
                        <g className="kit-deportivo white">
                            <g className="deportes-1">
                                <path d="M461.9,510.6c0-0.6,0-1,0-1.5c0-3.9-0.3-7.9-0.7-11.8c-0.3-3.1-0.4-6.2-0.6-9.3c-0.1-1.1-0.2-2.3-0.2-3.3
			c-0.4-0.4-1.1-0.1-1.2-0.8c0.3-0.4,0.7-0.4,1.2-0.5c0.9-0.1,1.8-0.2,2.7-0.4c0.3,0,0.5-0.2,0.8-0.3c0-0.2,0-0.3,0-0.4
			c-1-4.3-0.5-8.4,1.3-12.4c0.7-1.6,1.6-3.2,2.5-4.7c1-1.7,2.3-3.1,3.9-4.2c5.2-3.9,10.9-5.9,17.4-5.9c0.4,0,0.7,0,1.1,0
			c0.1-0.2,0.3-0.3,0.3-0.4c0.8-1.6,2-2.8,3.4-3.9c1.8-1.3,3.7-2.3,5.8-3c6.9-2.2,13.8-2.5,20.7-0.4c1.3,0.4,2.5,0.9,3.8,1.5
			c1.6,0.8,3,1.8,4.2,3.1c0.2,0.2,0.4,0.4,0.5,0.6c0.6,0,1-0.3,1.3-0.6c3.7-2.3,7.8-3.5,12.1-3.7c9.8-0.3,18.9,5.2,23.3,14
			c1,2.1,1.8,4.2,2.2,6.5c0.1,0.6,0.3,1.1,0.4,1.7c0.1,0.3,0.1,0.6,0.1,0.8c0,0.4,0,0.8,0,1.2c0.1,2.6-0.2,5.2-0.8,7.7
			c-0.1,0.3-0.1,0.7-0.2,1.1c0.4,0,0.6,0.1,0.9,0.1c0.9,0,1.8-0.1,2.7-0.1c0.3,0,0.6,0,0.9,0c0.6,0.1,0.8,0.4,0.8,1
			c-0.1,2.6,0.2,5.2,0.5,7.7c0.4,3.2,0.8,6.4,1,9.6c0.4,6.5,0.7,13,1.1,19.4c0.1,2.5,0.4,5.1,0.6,7.6c0.2,2.8,0.3,5.6,0.4,8.4
			c0,0.6,0.1,1.2,0.1,1.9c0.3,0.2,0.5,0.4,0.7,0.7c0.5,0.5,0.3,1.2-0.3,1.5c-0.4,0.1-0.9,0.2-1.3,0.2c-1.4,0-2.8,0.1-4.2,0.1
			c-0.3,0-0.7,0-1.1,0.1c-4.8,8.9-12.3,13.7-22.4,14.1c-10,0.3-17.9-3.9-23.4-12.2c-1.2-0.2-3-0.1-4.1,0.2c0,0.3,0.1,0.5,0.2,0.8
			c2,7,2.5,14.1,1.5,21.3c-2.9,19.2-15.9,34.2-32.9,39.7c-5.8,1.9-11.8,2.7-17.9,2.1c-13.6-1.3-24.7-7.4-33-18.3
			c-5.6-7.3-8.7-15.6-9.6-24.7c-1.3-13.9,2.5-26.3,11.3-37c5.7-6.9,12.8-11.7,21.3-14.6C461.1,510.9,461.4,510.8,461.9,510.6z
			 M522.8,539.4c0-0.2,0-0.3,0-0.4c-0.1-0.3-0.3-0.7-0.4-1c-1.9-4.8-2.2-9.7-0.7-14.6c2.5-8.2,8-13.5,16.1-16.1
			c0.5-0.2,1-0.3,1.5-0.5c0.5-0.2,1.1-0.4,1.6-0.5c0.7-0.2,1.4-0.2,2.1-0.3c6.1-0.7,11.9,0.5,17.1,3.7c9.3,5.5,14,16.5,10.7,27.1
			c-0.1,0.3-0.1,0.5-0.2,0.8c1.3,0.2,3.3,0.1,3.9-0.3c-0.2-1.2-0.4-2.4-0.5-3.5c-0.2-2.6-0.4-5.2-0.6-7.7c-0.6-6.5-0.8-13-1.2-19.5
			c-0.3-4.2-0.6-8.4-0.9-12.6c-0.1-1.7-0.4-3.4-0.6-5.1c-0.1-1.5-0.1-2.9-0.2-4.4c0-0.3-0.1-0.6-0.2-1c-0.3,0-0.4,0-0.6,0.1
			c-0.7,0.3-1.4,0.4-2.2,0.4c-1.1,0-2.2,0-3.3-0.3c-0.3-0.1-0.6-0.1-1-0.2c-3.7-0.3-7.5-0.2-11.2,0.1c-1.7,0.1-3.4,0.2-5.2,0.2
			c-2.1,0-4.3,0-6.4,0c-6.2,0.2-12.5,0.4-18.7,0.3c-8-0.1-16,0.1-24,0.4c-4,0.1-8,0.1-12.1,0.1c-1.8,0-3.7-0.2-5.5-0.4
			c-1.8-0.2-3.6-0.5-5.4-0.7c-1.8-0.2-3.7-0.2-5.5,0c-1.9,0.3-3.7,0.6-5.6,0.9c-0.8,0.1-1.7,0.2-2.6,0.3c-0.2,0.7,0.1,1.3,0.1,2
			c0.2,2.1,0.4,4.2,0.7,6.4c0.4,3.5,0.7,6.9,1,10.4c0.2,1.7,0.2,3.4,0.3,5.2c0,0.4-0.1,0.9,0.2,1.2c0.2,0,0.2,0,0.3,0
			c0.4-0.1,0.7-0.1,1.1-0.2c5.7-1.4,11.4-1.6,17.1-0.7c10.8,1.6,19.8,6.5,27.2,14.4c4.1,4.4,7.2,9.5,9.4,15.1c0.2,0.4,0.2,1,0.7,1.3
			C520.4,539.6,521.6,539.6,522.8,539.4z M487.8,602.1c0.2-0.8,0.3-1.5,0.5-2.2c3.3-10.2,5.3-20.7,6-31.4c0.4-6.5,0.3-13-0.4-19.4
			c-0.6-5.2-1.5-10.3-3-15.3c-1.3-4.3-2.9-8.4-5.2-12.2c-1.4-2.4-3-4.6-5-6.5c-2-2-4.3-3.5-7-4.5c-0.8-0.3-1.4-0.2-2.1,0.3
			c-1.4,1-2.5,2.1-3.4,3.5c-0.9,1.5-1.8,2.9-2.6,4.5c-1.6,3.3-2.7,6.8-3.6,10.3c-2.2,8.7-3.1,17.5-3.3,26.4
			c-0.2,8.3,0.3,16.6,1.8,24.9c0.9,4.7,2,9.4,3.8,13.9c0.8,2,1.7,4,2.9,5.8c0.8,1.2,1.7,2.3,2.8,3.3c0.5,0.4,1.1,0.7,1.8,0.7
			c3.7,0.1,7.5,0,11.1-0.8C484.7,603,486.3,602.6,487.8,602.1z M469.6,510.6c-0.5-0.2-0.9,0-1.3,0c-1.5,0.2-2.9,0.7-4.4,0.6
			c-0.2,0-0.3,0.1-0.5,0.1c-0.9,0.3-1.9,0.6-2.8,0.9c-7,2.6-13.1,6.5-18.1,12c-9.5,10.3-13.8,22.5-13,36.5c0.6,9.8,4,18.6,10,26.3
			c6.9,8.7,15.9,14.2,26.9,16.4c0.4,0.1,0.8,0.2,1.1,0.1c0-0.1,0-0.2,0-0.2c-0.1-0.2-0.2-0.4-0.3-0.5c-1.6-1.9-2.8-4.1-3.8-6.3
			c-1.3-2.8-2.2-5.7-3-8.6c-2.1-7.9-3.1-16-3.4-24.1c-0.3-7.4,0-14.8,0.9-22.1c0.6-4.4,1.4-8.8,2.5-13.1c1-3.8,2.2-7.6,4-11.1
			c1.2-2.4,2.7-4.6,4.6-6.5C469.4,511,469.4,510.8,469.6,510.6z M477.4,510.1c0,0.1,0,0.2-0.1,0.3c0.3,0.2,0.6,0.4,0.9,0.6
			c1.4,0.9,2.7,1.9,3.9,3.1c1.6,1.6,3,3.4,4.3,5.2c2.1,3.2,3.7,6.6,4.9,10.2c2.9,8.2,4.2,16.7,4.6,25.3c0.2,4.7,0.1,9.4-0.2,14.1
			c-0.3,5-0.9,9.9-1.8,14.8c-1,5.6-2.3,11.2-4.1,16.6c-0.1,0.3-0.1,0.6-0.2,1.1c0.6-0.2,1-0.4,1.4-0.5c6.4-2.6,12-6.5,16.6-11.6
			c6.5-7.2,10.5-15.5,12-25.1c0.7-4.6,0.8-9.2,0.2-13.9c-0.3-2-0.8-3.9-1.1-5.9c-0.4-2.1-1.5-3.9-2-5.9c-6-14.8-19.3-26-36.1-28.1
			C479.6,510.2,478.5,510.2,477.4,510.1z M510.6,470.4c0.3-0.2,0.4-0.4,0.5-0.5c0.8-0.9,1.9-1.6,3-2c1.2-0.4,2.4-0.7,3.6-1.1
			c0.4-0.1,0.8-0.2,1.2-0.3c0.3-1.5,0.9-2.8,1.6-4c0.1-0.1,0.1-0.3,0.2-0.4c0.5-0.9,0.9-1.8,1.4-2.6c1.1-1.8,2.5-3.3,4.1-4.7
			c0.3-0.3,0.7-0.4,0.8-1c-0.3-0.4-0.7-0.8-1.1-1.2c-1.3-1.1-2.8-2-4.4-2.6c-1.8-0.7-3.7-1.4-5.7-1.3c-0.3,0-0.6-0.1-0.9-0.1
			c-5-0.9-9.9-0.8-14.7,0.8c-1.8,0.6-3.6,1.4-5.2,2.4c-1.2,0.8-2.2,1.7-3.1,2.8c-0.2,0.2-0.3,0.5-0.4,0.8c0.2,0.1,0.4,0.2,0.5,0.2
			c5,0.9,9.4,3.1,13.1,6.7c0.5,0.5,1.1,1.1,1.5,1.7c1.3,1.8,2.4,3.8,3.5,5.8C510.3,469.8,510.4,470,510.6,470.4z M530.1,545.6
			c0.8-1.2,1.5-2.3,2.2-3.4c0.7-1.1,1.7-2,2.2-3.3c-0.3-0.3-0.6-0.6-0.8-1c-2-2.4-3.1-5.1-2.6-8.3c0.2-1.4,0.3-2.9,0.5-4.3
			c0.5-2.9,1.1-5.7,2.6-8.3c0.1-0.2,0.2-0.4,0.3-0.7c-0.3-0.5-0.7-1-1-1.5c-0.3-0.5-0.5-0.9-0.8-1.4c-0.3-0.5-0.4-1.1-0.9-1.4
			C518.9,521.3,520.7,538.3,530.1,545.6z M532.5,529.3c6.1,4,18.4,5.2,27.9,0.3c0.7-1.5,1-3.3,0.8-4.6c-0.5-0.2-0.9-0.4-1.4-0.6
			c-5.3-1.8-10.7-2.5-16.2-1.6c-3,0.5-6.1,1-9,1.9c-0.5,0.1-0.9,0.3-1.4,0.5C532.8,526.7,532.5,528,532.5,529.3z M562.5,513.9
			c-1,0.6-2.9,2.5-3.3,3.1c0.2,0.2,0.4,0.4,0.6,0.6c0.2,0.2,0.4,0.4,0.5,0.7c2.2,2.8,3,5.9,2.1,9.5c-0.6,2.3-1.5,4.4-2.8,6.4
			c-0.5,0.8-1.1,1.6-1.8,2.2c-0.2,0.1-0.3,0.4-0.4,0.5c1.1,1.9,2.1,3.8,3.2,5.7c0.5,0.8,1.1,1.6,1.7,2.4c0.1,0.2,0.3,0.3,0.4,0.4
			C571.2,538.6,573.4,523.4,562.5,513.9z M505.6,465.7c-3.1,2.6-6,5.4-9.5,7c-1,2.5-2,4.8-2.9,7.1c0.7,0.9,1.4,1.9,2,2.7
			c1.2,0,2.2,0,3.2,0c1,0,2.1,0,3.1-0.1c1,0,2-0.1,3-0.1c1,0,2.1,0,3.1-0.1c1-0.1,2,0.1,3-0.2C511,477.3,509.3,470.7,505.6,465.7z
			 M546.9,477.7c1-0.7,1.8-1.5,2.8-2.2c0.9-0.7,1.9-1.3,2.8-2c0.9-0.7,1.8-1.4,2.5-2.1c-0.9-2-1.7-3.9-2.6-5.8
			c-0.7-1.4-1.4-2.7-2.2-4c-0.1-0.2-0.3-0.4-0.5-0.6c-0.7,0.3-1.4,0.5-2.1,0.8c-2.7,1.4-5.3,2.7-8,4.2c-0.2,1.6-0.4,3.2-0.6,4.6
			c1.3,2,2.4,3.8,3.5,5.6c0.7,0.2,1.4,0.4,2.1,0.7C545.5,477,546.2,477.4,546.9,477.7z M504.6,464.5c-3.2-4.6-12.8-8.6-17.7-7.2
			c-0.2,0.8-0.1,1.7-0.2,2.6c0,0.9,0,1.9,0,2.8c0,0.9-0.1,1.8-0.2,2.7c1.8,0.6,3.3,1.7,4.7,2.8c1.5,1.1,2.9,2.3,4.3,3.4
			C497.7,470.4,503.6,465.8,504.6,464.5z M551.5,452.4c-4.3-2.8-15.9-2.3-19.4,0.9c2.9,3.5,5.9,6.9,8.2,10.9c3.1-1.9,6.1-4,9.5-4.9
			C550.4,456.9,550.9,454.6,551.5,452.4z M467.7,469c-0.7,0.9-1,1.9-1.4,2.8c-1.3,3-1.7,6.1-1.4,9.3c0,0.5,0,1,0.4,1.4
			c2.2-0.5,4.5-1.1,6.8-1c2.3,0.1,4.6,0.3,7.1,0.4c0.4-0.5,1.1-0.8,1.4-1.5c-0.2-0.4-0.5-0.7-0.7-1.1c-0.4-1.2-0.7-2.4-1-3.7
			c-0.2-0.9-0.3-1.8-0.4-2.6C475,471,471.3,470.2,467.7,469z M485.4,465.9c0-2.9,0-5.7,0-8.5c-0.4,0-0.8,0.1-1.2,0.1
			c-4.5,0.7-8.4,2.6-11.9,5.3c-1.7,1.3-2.9,2.9-3.9,4.7c-0.1,0.1-0.1,0.3-0.2,0.5c1.9,0.5,3.7,1,5.4,1.6c1.8,0.6,3.5,1.5,5.4,2.2
			C480.9,469.5,483.4,467.9,485.4,465.9z M560.8,523c0-0.2,0-0.4,0-0.5c-0.7-1.7-1.6-3.2-2.8-4.6c-0.1-0.2-0.4-0.3-0.6-0.4
			c-1.4-0.6-2.8-1.2-4.3-1.6c-4.8-1.2-9.6-1.1-14.4-0.4c-0.9,0.1-1.9,0.3-2.7,0.8c-0.4,0.9-0.8,1.9-1.3,2.8
			c-0.6,1.2-0.9,2.5-1.2,3.7c0,0.2,0,0.4,0,0.7c0.2,0,0.4,0,0.5,0c3.4-1.1,6.8-1.7,10.3-2.2c4.2-0.6,8.4-0.4,12.6,0.7
			C558.2,522.3,559.5,522.7,560.8,523z M550.8,459.8c0.5,0.6,0.9,1.1,1.4,1.7c1.1,1.5,2,3.1,2.7,4.8c0.6,1.6,1.4,3.1,2.3,4.6
			c2.9-0.2,5.7-0.4,8.4-0.8c0.1-2.1-1.7-6.7-3.8-9.8c-2.3-3.3-6.2-6.6-8.6-7.3c-0.1,0.1-0.2,0.1-0.2,0.2
			C552.3,455.5,551.5,457.6,550.8,459.8z M520.4,466.2c0.4,0,0.7,0,0.9,0c2-0.3,4.1-0.2,6.1-0.2c3.4-0.1,6.5,0.9,9.3,2.8
			c0.3,0.2,0.6,0.3,0.9,0.5c0.1-0.3,0.2-0.5,0.3-0.7c0.1-0.7,0.2-1.4,0.3-2.1c0.3-2.2,0.4-2.3-0.9-4.1c-1.9-2.7-3.9-5.5-5.9-8.2
			c-0.1-0.2-0.3-0.3-0.5-0.5C526,456.7,522.7,460.9,520.4,466.2z M565,481.5c1-2,1.5-7.3,0.9-9.3c-3.2,0.1-6.3,0.6-9.5,0
			c-0.1,0.1-0.3,0.2-0.3,0.2c-1.7,1.8-3.7,3.4-5.8,4.7c-0.8,0.5-1.5,1.1-2.1,1.9c0.2,0.9,0.4,1.8,0.9,2.7c1.8-0.1,3.6-0.3,5.4-0.4
			c1.8-0.1,3.5-0.1,5.3-0.1C561.4,481.3,563.2,481.4,565,481.5z M481.4,479.4c3.5,0.2,6.9,0.3,10.3-0.4c0.8-2.2,2-4.1,2.5-6.4
			c-0.7-0.5-1.4-1-2.1-1.6c-1.6-1.2-3.3-2.5-4.9-3.7c-0.3-0.2-0.5-0.3-0.9-0.5c-2.2,2.2-4.7,3.9-6.8,6.3
			C480.2,475.2,480.8,477.3,481.4,479.4z M532.4,531c-0.2,1.1,0.1,2,0.4,2.9c0.6,1.4,1.5,2.6,2.5,3.6c0.5,0.5,1.1,0.8,1.8,1
			c3.2,0.8,6.5,1,9.8,0.9c3.9-0.1,7.2-1.7,10.2-4.2c0.9-0.7,1.8-2.2,2.1-3.3c-0.4,0.1-0.7,0.2-1,0.3c-5.5,2-11.2,2.8-17.1,1.9
			c-2.9-0.4-5.7-1.3-8.2-2.8C532.9,531.2,532.7,531.2,532.4,531z M556.3,537.7c-1.1,0.6-2,1.2-2.9,1.6c-0.9,0.5-1.9,0.9-2.8,1.3
			c0,0.2,0,0.3,0,0.3c0.9,2.9,1.5,5.8,2,8.7c0.1,0.3,0.2,0.7,0.3,1c2.6-0.3,7.6-2.9,8.9-4.5C559.9,543.4,558.2,540.6,556.3,537.7z
			 M535.8,539.7c-1.3,2.4-3.3,4.3-4.6,6.8c1.6,1.9,7.2,4.5,9.9,4.6c0.3-3.3,0.7-6.6,1.1-9.9C540,540.7,538,540.2,535.8,539.7z
			 M532.2,468.9c-0.8-0.5-1.6-0.6-2.4-0.7c-4-0.4-7.9-0.3-11.9,0.5c-0.2,0-0.4,0.1-0.9,0.3c1.5,2.2,2.4,4.8,4.5,6.6
			c2.7-0.7,5.6-1,8.2-1.8C530.6,472,531.4,470.5,532.2,468.9z M550.9,551.2c-0.5-3.4-1-6.8-1.4-10.2c-2.1,0-4.1,0.1-6,0.1
			c-0.3,3.5-0.5,6.8-0.8,10.2C544.2,551.9,548.6,551.8,550.9,551.2z M530.6,475.4c-3.1,0.5-5.9,1-8.8,1.4c-0.7,0.8-1.4,1.7-2.1,2.5
			c-0.7,0.9-1.6,1.6-2.3,2.7c1.4,0.1,2.7,0,3.9,0c1.3,0,2.5,0,3.8,0c1.3,0,2.6,0,3.9-0.1c1.3,0,2.5,0,3.8-0.2
			c0.1-0.4,0.2-0.8,0.3-1.1C532.3,478.9,531.4,477.2,530.6,475.4z M520.2,476.2c-2.1-2-2.9-4.7-4.3-7.1c-1.5,0.4-2.9,1.1-3.9,2.2
			c-0.4,0.5-0.6,1-0.5,1.6c0,0.2,0.1,0.5,0.2,0.7c0.5,2.5,1,5,1,7.6c0,0.3,0.1,0.5,0.1,0.9c1,0,2,0,2.9,0
			C517.2,480.1,518.6,478.2,520.2,476.2z M540.7,477.1c-1-1.9-2-3.7-3.3-5.2c-1-1.1-2.3-1.9-3.7-2.6c-0.9,1.6-1.8,3.1-2.8,4.6
			c1.8,1.5,2.3,3.6,3.4,5.4C536.5,478.7,538.6,478.3,540.7,477.1z M561.4,513.1c-0.1-0.2-0.1-0.2-0.1-0.3c-0.1-0.1-0.2-0.2-0.3-0.3
			c-2.7-2-5.6-3.4-8.8-4.2c-0.2-0.1-0.5,0-0.7,0c-0.2,1.8-0.4,3.5-0.6,5.3c1.3,0.3,2.5,0.5,3.7,0.9c1.2,0.4,2.4,0.9,3.6,1.3
			C559.2,515,560.3,514.1,561.4,513.1z M542,508.2c-1.1,0.1-2.1,0-3,0.4c-1.6,0.5-3.1,1.3-4.6,2c-0.4,0.2-0.7,0.5-1.1,0.7
			c0.5,1.1,1.9,3.1,2.5,3.7c1.3-0.3,2.5-0.7,3.7-1c1.3-0.3,2.6-0.2,3.9-0.6C542.9,511.6,542.5,510,542,508.2z M550.2,508.2
			c-0.3-0.1-0.4-0.2-0.5-0.2c-2-0.3-4-0.3-6-0.1c-0.2,0-0.4,0.2-0.6,0.3c0.6,1.8,1.2,3.5,1.8,5.2c1.3,0,2.5,0.1,3.8,0.1
			c0.2,0,0.3,0,0.6-0.1C549.7,511.7,550,510,550.2,508.2z M492.2,480.6c-1.8,0.2-3.5,0.5-5.2,0.6c-1.7,0.1-3.5,0-5.3-0.1
			c-0.2,0.3-0.6,0.6-0.7,1.1c1.4,0.3,2.7,0.2,4.1,0.3c1.4,0.1,2.8,0.1,4.2,0.1c1.3,0,2.7,0.1,4.1-0.1
			C493.1,481.8,492.6,481.2,492.2,480.6z M541.2,478.8c-2.4,0.7-4.7,1.4-7,2.1c0,0.3,0,0.6,0,0.8c1.2,0.2,6.7-0.1,7.7-0.3
			C541.8,480.6,541.6,479.7,541.2,478.8z M547.3,481.6c-0.1-0.8-0.2-1.5-0.2-2.2c-1.3-0.5-2.4-1-3.8-1.1c0.3,1.2,0.5,2.2,0.8,3.3
			C545.1,481.8,546.2,481.8,547.3,481.6z"/>
                                <path className="st14" d="M522.8,539.4c-1.2,0.2-2.3,0.1-3.4,0.2c-0.5-0.4-0.6-0.9-0.7-1.3c-2.2-5.6-5.3-10.7-9.4-15.1
			c-7.4-8-16.4-12.8-27.2-14.4c-5.7-0.9-11.5-0.6-17.1,0.7c-0.4,0.1-0.7,0.2-1.1,0.2c-0.1,0-0.2,0-0.3,0c-0.3-0.4-0.2-0.8-0.2-1.2
			c-0.1-1.7-0.1-3.4-0.3-5.2c-0.3-3.5-0.7-6.9-1-10.4c-0.2-2.1-0.5-4.2-0.7-6.4c-0.1-0.6-0.4-1.3-0.1-2c0.9-0.1,1.7-0.1,2.6-0.3
			c1.9-0.3,3.7-0.7,5.6-0.9c1.8-0.3,3.7-0.2,5.5,0c1.8,0.2,3.6,0.5,5.4,0.7c1.8,0.2,3.7,0.4,5.5,0.4c4,0,8,0,12.1-0.1
			c8-0.3,16-0.5,24-0.4c6.2,0.1,12.5-0.2,18.7-0.3c2.1-0.1,4.3,0,6.4,0c1.7,0,3.4-0.1,5.2-0.2c3.7-0.3,7.5-0.4,11.2-0.1
			c0.3,0,0.7,0.1,1,0.2c1.1,0.3,2.2,0.3,3.3,0.3c0.7,0,1.5,0,2.2-0.4c0.1-0.1,0.3-0.1,0.6-0.1c0.1,0.4,0.1,0.7,0.2,1
			c0.1,1.5,0.1,3,0.2,4.4c0.2,1.7,0.4,3.4,0.6,5.1c0.3,4.2,0.7,8.4,0.9,12.6c0.4,6.5,0.6,13,1.2,19.5c0.2,2.6,0.4,5.2,0.6,7.7
			c0.1,1.2,0.3,2.4,0.5,3.5c-0.7,0.3-2.7,0.5-3.9,0.3c0-0.3,0.1-0.6,0.2-0.8c3.3-10.6-1.4-21.5-10.7-27.1c-5.3-3.2-11-4.4-17.1-3.7
			c-0.7,0.1-1.4,0.2-2.1,0.3c-0.6,0.1-1.1,0.4-1.6,0.5c-0.5,0.2-1,0.3-1.5,0.5c-8.1,2.6-13.6,7.9-16.1,16.1
			c-1.5,4.9-1.2,9.8,0.7,14.6c0.1,0.3,0.3,0.7,0.4,1C522.8,539.1,522.7,539.2,522.8,539.4z"/>
                                <path className="st13" d="M487.8,602.1c-1.5,0.6-3.1,0.9-4.7,1.3c-3.7,0.8-7.4,0.9-11.1,0.8c-0.7,0-1.3-0.3-1.8-0.7
			c-1.1-0.9-2-2.1-2.8-3.3c-1.2-1.8-2.1-3.8-2.9-5.8c-1.8-4.5-3-9.1-3.8-13.9c-1.5-8.2-2-16.5-1.8-24.9c0.2-8.9,1.1-17.8,3.3-26.4
			c0.9-3.6,2-7,3.6-10.3c0.8-1.5,1.6-3,2.6-4.5c0.9-1.4,2-2.6,3.4-3.5c0.7-0.5,1.3-0.6,2.1-0.3c2.6,1,4.9,2.6,7,4.5
			c2,1.9,3.6,4.1,5,6.5c2.3,3.8,3.9,7.9,5.2,12.2c1.5,5,2.4,10.1,3,15.3c0.7,6.5,0.8,12.9,0.4,19.4c-0.7,10.7-2.7,21.2-6,31.4
			C488.1,600.5,488,601.2,487.8,602.1z"/>
                                <path className="st13" d="M469.6,510.6c-0.1,0.2-0.2,0.3-0.3,0.4c-2,1.8-3.4,4.1-4.6,6.5c-1.8,3.5-3,7.3-4,11.1
			c-1.1,4.3-1.9,8.7-2.5,13.1c-1,7.3-1.2,14.7-0.9,22.1c0.3,8.1,1.3,16.2,3.4,24.1c0.8,2.9,1.7,5.8,3,8.6c1,2.3,2.2,4.4,3.8,6.3
			c0.1,0.2,0.2,0.3,0.3,0.5c0,0,0,0.1,0,0.2c-0.4,0.1-0.8,0-1.1-0.1c-11-2.1-19.9-7.6-26.9-16.4c-6.1-7.7-9.4-16.5-10-26.3
			c-0.9-14,3.5-26.2,13-36.5c5-5.5,11.1-9.4,18.1-12c0.9-0.3,1.9-0.6,2.8-0.9c0.2-0.1,0.3-0.1,0.5-0.1c1.5,0.1,2.9-0.4,4.4-0.6
			C468.7,510.6,469.1,510.4,469.6,510.6z"/>
                                <path className="st13" d="M477.4,510.1c1.1,0.1,2.2,0.1,3.3,0.3c16.8,2.1,30.1,13.4,36.1,28.1c0.5,2,1.6,3.9,2,5.9
			c0.4,2,0.8,3.9,1.1,5.9c0.6,4.6,0.6,9.2-0.2,13.9c-1.5,9.5-5.5,17.9-12,25.1c-4.6,5.1-10.2,9-16.6,11.6c-0.4,0.2-0.8,0.3-1.4,0.5
			c0.1-0.5,0.1-0.8,0.2-1.1c1.7-5.4,3.1-11,4.1-16.6c0.9-4.9,1.5-9.8,1.8-14.8c0.3-4.7,0.4-9.4,0.2-14.1c-0.4-8.6-1.7-17.1-4.6-25.3
			c-1.3-3.6-2.9-7-4.9-10.2c-1.2-1.9-2.6-3.7-4.3-5.2c-1.2-1.2-2.5-2.2-3.9-3.1c-0.3-0.2-0.6-0.4-0.9-0.6
			C477.4,510.2,477.4,510.2,477.4,510.1z"/>
                                <path className="st32" d="M510.6,470.4c-0.2-0.4-0.4-0.6-0.5-0.9c-1-2-2.1-4-3.5-5.8c-0.5-0.6-1-1.2-1.5-1.7c-3.7-3.6-8-5.8-13.1-6.7
			c-0.1,0-0.3-0.1-0.5-0.2c0.2-0.3,0.3-0.6,0.4-0.8c0.9-1.1,1.9-2,3.1-2.8c1.6-1.1,3.4-1.8,5.2-2.4c4.8-1.6,9.7-1.7,14.7-0.8
			c0.3,0.1,0.6,0.1,0.9,0.1c2-0.1,3.9,0.6,5.7,1.3c1.6,0.7,3.1,1.5,4.4,2.6c0.4,0.4,0.8,0.8,1.1,1.2c-0.1,0.6-0.5,0.8-0.8,1
			c-1.6,1.4-3,3-4.1,4.7c-0.5,0.8-0.9,1.7-1.4,2.6c-0.1,0.1-0.1,0.3-0.2,0.4c-0.8,1.2-1.4,2.5-1.6,4c-0.4,0.1-0.8,0.2-1.2,0.3
			c-1.2,0.4-2.5,0.7-3.6,1.1c-1.2,0.4-2.2,1.1-3,2C511,470.1,510.9,470.2,510.6,470.4z"/>
                                <path className="st11" d="M530.1,545.6c-9.4-7.3-11.2-24.3,1.7-33.4c0.5,0.3,0.6,0.9,0.9,1.4c0.3,0.4,0.6,0.9,0.8,1.4
			c0.3,0.5,0.6,1,1,1.5c-0.1,0.2-0.1,0.5-0.3,0.7c-1.5,2.5-2.2,5.4-2.6,8.3c-0.2,1.4-0.3,2.9-0.5,4.3c-0.5,3.2,0.6,5.9,2.6,8.3
			c0.3,0.3,0.5,0.6,0.8,1c-0.6,1.2-1.5,2.2-2.2,3.3C531.6,543.3,530.9,544.4,530.1,545.6z"/>
                                <path className="st11" d="M532.5,529.3c0-1.3,0.2-2.6,0.6-4c0.4-0.2,0.9-0.3,1.4-0.5c3-0.9,6-1.4,9-1.9c5.6-1,11-0.3,16.2,1.6
			c0.5,0.2,1,0.4,1.4,0.6c0.2,1.2-0.1,3.1-0.8,4.6C550.9,534.5,538.6,533.3,532.5,529.3z"/>
                                <path className="st11" d="M562.5,513.9c10.9,9.5,8.7,24.7,0.3,31.4c-0.1-0.1-0.3-0.2-0.4-0.4c-0.6-0.8-1.2-1.6-1.7-2.4
			c-1.1-1.8-2.1-3.7-3.2-5.7c0.1-0.1,0.2-0.3,0.4-0.5c0.7-0.6,1.3-1.4,1.8-2.2c1.2-2,2.2-4.1,2.8-6.4c0.9-3.5,0.1-6.7-2.1-9.5
			c-0.2-0.2-0.4-0.4-0.5-0.7c-0.2-0.2-0.4-0.4-0.6-0.6C559.6,516.4,561.6,514.6,562.5,513.9z"/>
                                <path className="st15" d="M505.6,465.7c3.7,5,5.4,11.6,4.9,16.3c-1,0.3-2,0.1-3,0.2c-1,0.1-2.1,0.1-3.1,0.1c-1,0-2,0.1-3,0.1
			c-1,0-2.1,0.1-3.1,0.1c-1,0-2,0-3.2,0c-0.6-0.8-1.3-1.8-2-2.7c1-2.3,1.9-4.6,2.9-7.1C499.5,471.1,502.5,468.3,505.6,465.7z"/>
                                <path className="st11" d="M546.9,477.7c-0.8-0.3-1.5-0.6-2.2-0.9c-0.7-0.3-1.4-0.5-2.1-0.7c-1.1-1.8-2.2-3.6-3.5-5.6
			c0.2-1.4,0.4-3,0.6-4.6c2.7-1.4,5.3-2.8,8-4.2c0.6-0.3,1.4-0.5,2.1-0.8c0.2,0.2,0.4,0.4,0.5,0.6c0.7,1.3,1.5,2.6,2.2,4
			c0.9,1.9,1.7,3.8,2.6,5.8c-0.7,0.8-1.6,1.4-2.5,2.1c-0.9,0.7-1.9,1.3-2.8,2C548.8,476.2,547.9,476.9,546.9,477.7z"/>
                                <path className="st15" d="M504.6,464.5c-1,1.3-6.9,5.9-9.2,7.1c-1.4-1.1-2.8-2.3-4.3-3.4c-1.4-1.1-2.9-2.1-4.7-2.8
			c0.1-0.9,0.2-1.8,0.2-2.7c0-0.9,0-1.9,0-2.8c0-0.9-0.1-1.8,0.2-2.6C491.8,456,501.4,459.9,504.6,464.5z"/>
                                <path className="st15" d="M551.5,452.4c-0.6,2.2-1.1,4.5-1.7,6.8c-3.4,0.9-6.3,3-9.5,4.9c-2.4-3.9-5.3-7.4-8.2-10.9
			C535.6,450.1,547.2,449.5,551.5,452.4z"/>
                                <path className="st15" d="M467.7,469c3.7,1.2,7.3,2,10.7,4c0.1,0.8,0.2,1.7,0.4,2.6c0.3,1.2,0.6,2.5,1,3.7c0.1,0.4,0.4,0.7,0.7,1.1
			c-0.3,0.6-1,1-1.4,1.5c-2.4-0.2-4.7-0.4-7.1-0.4c-2.3-0.1-4.5,0.5-6.8,1c-0.4-0.4-0.4-1-0.4-1.4c-0.3-3.2,0.1-6.3,1.4-9.3
			C466.6,470.9,467,469.9,467.7,469z"/>
                                <path className="st15" d="M485.4,465.9c-2,2-4.5,3.6-6.3,5.9c-1.9-0.8-3.6-1.6-5.4-2.2c-1.8-0.6-3.6-1.1-5.4-1.6
			c0.1-0.2,0.1-0.4,0.2-0.5c1-1.8,2.2-3.4,3.9-4.7c3.6-2.7,7.5-4.6,11.9-5.3c0.3-0.1,0.7-0.1,1.2-0.1
			C485.4,460.2,485.4,463,485.4,465.9z"/>
                                <path className="st11" d="M560.8,523c-1.4-0.4-2.6-0.7-3.8-1c-4.1-1.1-8.3-1.3-12.6-0.7c-3.5,0.5-7,1.1-10.3,2.2c-0.1,0-0.2,0-0.5,0
			c0-0.2,0-0.5,0-0.7c0.2-1.3,0.6-2.5,1.2-3.7c0.4-0.9,0.8-1.9,1.3-2.8c0.9-0.5,1.8-0.6,2.7-0.8c4.8-0.8,9.6-0.8,14.4,0.4
			c1.5,0.4,2.9,0.9,4.3,1.6c0.2,0.1,0.4,0.3,0.6,0.4c1.1,1.4,2.1,2.9,2.8,4.6C560.9,522.7,560.8,522.8,560.8,523z"/>
                                <path className="st15" d="M550.8,459.8c0.7-2.2,1.4-4.4,2.2-6.5c0-0.1,0.1-0.1,0.2-0.2c2.4,0.7,6.3,4,8.6,7.3c2.1,3.1,4,7.7,3.8,9.8
			c-2.8,0.4-5.6,0.6-8.4,0.8c-0.9-1.5-1.6-3-2.3-4.6c-0.7-1.7-1.6-3.3-2.7-4.8C551.8,460.9,551.3,460.4,550.8,459.8z"/>
                                <path className="st15" d="M520.4,466.2c2.3-5.3,5.6-9.5,10.5-12.5c0.2,0.2,0.4,0.3,0.5,0.5c2,2.7,3.9,5.5,5.9,8.2
			c1.3,1.8,1.3,1.8,0.9,4.1c-0.1,0.7-0.2,1.4-0.3,2.1c0,0.2-0.2,0.4-0.3,0.7c-0.3-0.2-0.6-0.3-0.9-0.5c-2.8-1.9-5.9-2.8-9.3-2.8
			c-2.1,0-4.1-0.1-6.1,0.2C521.1,466.2,520.8,466.2,520.4,466.2z"/>
                                <path className="st15" d="M565,481.5c-1.7-0.1-3.5-0.2-5.3-0.2c-1.8,0-3.5,0-5.3,0.1c-1.8,0.1-3.6,0.2-5.4,0.4
			c-0.5-0.9-0.7-1.8-0.9-2.7c0.6-0.8,1.3-1.4,2.1-1.9c2.1-1.3,4.1-2.9,5.8-4.7c0.1-0.1,0.2-0.1,0.3-0.2c3.1,0.6,6.3,0.1,9.5,0
			C566.5,474.2,566,479.5,565,481.5z"/>
                                <path className="st11" d="M481.4,479.4c-0.7-2.1-1.3-4.2-1.9-6.2c2.1-2.4,4.6-4.1,6.8-6.3c0.3,0.2,0.6,0.3,0.9,0.5
			c1.6,1.2,3.3,2.5,4.9,3.7c0.7,0.5,1.4,1,2.1,1.6c-0.5,2.2-1.7,4.2-2.5,6.4C488.4,479.6,485,479.6,481.4,479.4z"/>
                                <path className="st11" d="M532.4,531c0.3,0.1,0.5,0.2,0.6,0.3c2.5,1.5,5.3,2.4,8.2,2.8c5.9,0.9,11.5,0.1,17.1-1.9
			c0.3-0.1,0.6-0.2,1-0.3c-0.4,1.2-1.2,2.6-2.1,3.3c-2.9,2.4-6.3,4.1-10.2,4.2c-3.3,0.1-6.6-0.1-9.8-0.9c-0.7-0.2-1.3-0.5-1.8-1
			c-1-1.1-1.9-2.3-2.5-3.6C532.5,533,532.3,532.1,532.4,531z"/>
                                <path className="st11" d="M556.3,537.7c1.8,2.9,3.6,5.7,5.4,8.4c-1.2,1.6-6.3,4.3-8.9,4.5c-0.1-0.3-0.2-0.7-0.3-1
			c-0.5-2.9-1.1-5.9-2-8.7c0-0.1,0-0.2,0-0.3c0.9-0.4,1.9-0.8,2.8-1.3C554.3,538.9,555.2,538.4,556.3,537.7z"/>
                                <path className="st11" d="M535.8,539.7c2.1,0.5,4.2,1,6.4,1.5c-0.3,3.3-0.8,6.6-1.1,9.9c-2.7-0.1-8.3-2.7-9.9-4.6
			C532.5,544,534.5,542.1,535.8,539.7z"/>
                                <path className="st15" d="M532.2,468.9c-0.8,1.6-1.6,3.2-2.4,4.8c-2.7,0.8-5.5,1.1-8.2,1.8c-2.1-1.8-2.9-4.4-4.5-6.6
			c0.4-0.1,0.6-0.2,0.9-0.3c3.9-0.8,7.9-0.9,11.9-0.5C530.6,468.3,531.4,468.4,532.2,468.9z"/>
                                <path className="st11" d="M550.9,551.2c-2.2,0.6-6.7,0.7-8.2,0.1c0.3-3.3,0.5-6.7,0.8-10.2c2,0,4-0.1,6-0.1
			C549.9,544.4,550.4,547.8,550.9,551.2z"/>
                                <path className="st11" d="M530.6,475.4c0.8,1.7,1.7,3.5,2.6,5.4c-0.1,0.3-0.2,0.6-0.3,1.1c-1.3,0.3-2.6,0.2-3.8,0.2
			c-1.3,0.1-2.6,0-3.9,0.1c-1.3,0-2.5,0-3.8,0c-1.3,0-2.5,0-3.9,0c0.6-1.2,1.6-1.8,2.3-2.7c0.7-0.9,1.4-1.7,2.1-2.5
			C524.6,476.4,527.5,475.9,530.6,475.4z"/>
                                <path className="st15" d="M520.2,476.2c-1.5,2-3,3.9-4.5,5.9c-0.9,0-1.9,0-2.9,0c-0.1-0.3-0.2-0.6-0.1-0.9c0.1-2.6-0.4-5.1-1-7.6
			c-0.1-0.2-0.1-0.5-0.2-0.7c-0.1-0.6,0.1-1.1,0.5-1.6c1.1-1.2,2.4-1.8,3.9-2.2C517.3,471.5,518,474.2,520.2,476.2z"/>
                                <path className="st15" d="M540.7,477.1c-2.1,1.1-4.2,1.6-6.3,2.3c-1.1-1.8-1.7-4-3.4-5.4c0.9-1.5,1.8-3,2.8-4.6
			c1.4,0.7,2.7,1.5,3.7,2.6C538.8,473.5,539.7,475.3,540.7,477.1z"/>
                                <path className="st11" d="M561.4,513.1c-1.1,0.9-2.2,1.9-3.3,2.8c-1.2-0.5-2.4-1-3.6-1.3c-1.2-0.4-2.4-0.6-3.7-0.9
			c0.2-1.7,0.4-3.5,0.6-5.3c0.3,0,0.5,0,0.7,0c3.2,0.8,6.2,2.2,8.8,4.2c0.1,0.1,0.2,0.2,0.3,0.3
			C561.3,512.9,561.3,512.9,561.4,513.1z"/>
                                <path className="st11" d="M542,508.2c0.5,1.8,0.9,3.4,1.4,5.1c-1.3,0.5-2.7,0.3-3.9,0.6c-1.2,0.3-2.4,0.7-3.7,1
			c-0.6-0.6-2-2.7-2.5-3.7c0.4-0.2,0.7-0.5,1.1-0.7c1.5-0.7,3-1.4,4.6-2C539.9,508.2,540.9,508.3,542,508.2z"/>
                                <path className="st11" d="M550.2,508.2c-0.2,1.8-0.5,3.5-0.9,5.2c-0.2,0-0.4,0.1-0.6,0.1c-1.2,0-2.4-0.1-3.8-0.1
			c-0.7-1.7-1.2-3.5-1.8-5.2c0.2-0.1,0.4-0.2,0.6-0.3c2-0.2,4-0.2,6,0.1C549.8,508,549.9,508.1,550.2,508.2z"/>
                                <path className="st15" d="M492.2,480.6c0.4,0.6,0.9,1.2,1.1,2c-1.4,0.2-2.8,0.1-4.1,0.1c-1.4,0-2.8,0-4.2-0.1c-1.3-0.1-2.7,0-4.1-0.3
			c0-0.6,0.4-0.8,0.7-1.1c1.8,0,3.5,0.2,5.3,0.1C488.7,481.1,490.4,480.8,492.2,480.6z"/>
                                <path className="st15" d="M541.2,478.8c0.4,0.9,0.6,1.8,0.7,2.7c-0.9,0.2-6.4,0.5-7.7,0.3c0-0.3,0-0.6,0-0.8
			C536.5,480.3,538.8,479.6,541.2,478.8z"/>
                                <path className="st15" d="M547.3,481.6c-1.1,0.2-2.2,0.2-3.3,0c-0.2-1.1-0.5-2.1-0.8-3.3c1.4,0.1,2.6,0.6,3.8,1.1
			C547.2,480.1,547.2,480.8,547.3,481.6z"/>
                            </g>
                            <g className="deportes-2">
                                <path d="M620.6,547.2c-0.5,0.3-1,0.6-1.5,0.8c-0.5,0.2-1,0.4-1.5,0.6c-2.7,1.3-5.5,1.9-8.4,2.4c-2.6,0.4-5.2,0.1-7.7-0.4
			c-2-0.4-3.6-2.4-3.7-4.4c-0.5-6.9-1-13.7-1.2-20.6c-0.1-3.9-0.2-7.7-0.3-11.6c-0.1-1.8-0.2-3.7-0.3-5.5c-0.1-1.2-0.1-2.4-0.2-3.6
			c0-0.4-0.2-0.9-0.3-1.4c0.2-0.8,0.8-1.4,1.6-1.9c1.7-1,3.6-1.5,5.6-1.6c0.4,0,0.8,0,1.3,0c0.1-0.3,0.1-0.6,0.1-0.8
			c-0.3-5.1-0.4-10.2-1-15.3c-0.2-1.8-0.3-3.5-0.5-5.3c0-0.4-0.1-0.8-0.1-1.1c-0.2-0.1-0.3-0.1-0.4-0.1c-1.9,0.2-3.6-0.3-5.3-1
			c-0.7-0.3-1.5-0.6-2.3-0.9c-1.8-0.6-3.2-1.6-4.4-3.1c-3.8-4.8-6.5-10.1-7.5-16.2c-0.3-1.9-0.7-3.9-0.9-5.9
			c-0.4-4.4,0.4-8.7,2.7-12.6c1.2-2.1,3-3.7,5.2-4.8c3.5-1.7,7.1-2.4,10.9-1.6c1.7,0.4,3.3,1.1,4.7,2.1c1.1,0.8,2.2,1.8,3.2,2.7
			c0.3,0.2,0.5,0.5,0.9,0.8c0.2-0.2,0.3-0.4,0.4-0.6c0.8-1.4,1.9-2.7,3.2-3.8c3-2.5,6.5-3.2,10.3-2.4c1.7,0.4,3.3,1.1,4.8,1.8
			c1.3,0.6,2.3,1.5,3.3,2.6c0.8,0.9,1.3,2,1.6,3.2c0.3,1.5,0.7,3,1,4.4c0.4,2.2,0.8,4.4,1.2,6.6c0.5,2.6,0.6,5.1,0.5,7.7
			c-0.1,2.6-0.4,5.2-1.1,7.8c-0.7,2.7-1.8,5.3-3.3,7.7c-0.7,1.1-1.3,2.2-1.9,3.3c-0.8,1.4-1.8,2.7-2.9,3.8c-1,0.9-2,1.9-3,2.8
			c-1.6,1.4-3.4,2.4-5.5,2.8c-0.5,0.1-1,0-1.5,0c-0.1,0.2-0.3,0.5-0.3,0.7c-0.5,1.9-1,3.7-1.5,5.6c-0.6,2.2-1.2,4.4-1.8,6.7
			c-0.1,0.5-0.3,0.9-0.4,1.5c1.3,0.2,2.5,0.3,3.7,0.5c1.2,0.2,2.3,0.7,3.4,1c0.2-0.2,0.4-0.5,0.6-0.7c0.3-0.3,0.7-0.2,0.9,0.1
			c0.2,0.4,0.3,0.8,0.3,1.1c0,10.9,0,21.7,0,32.6c0,2.4,0.1,4.8,0.2,7.2c0,1.3,0,2.6,0,3.9C621.7,545.8,621.4,546.7,620.6,547.2
			C620.6,547.2,620.6,547.2,620.6,547.2z M630,471.1c0.2-0.1,0.5-0.2,0.6-0.4c0.2-0.2,0.3-0.6,0.5-0.9c0.6-1.1,1-2.2,1.7-3.3
			c0.5-0.8,0.9-1.6,1.2-2.6c1.1-4.3,1.4-8.7,0.8-13.1c-0.6-4.2-1.1-8.4-2.6-12.4c-0.7-1.7-1.5-3.3-3-4.4c-1.8-1.4-3.9-2.2-6.1-2.8
			c-3.1-0.8-6-0.2-8.6,1.6c-1.7,1.1-3,2.6-4.1,4.2c-0.1,0.1-0.1,0.2-0.1,0.4c0.3,0.4,0.6,0.9,0.9,1.3c0.3,0.4,0.4,1,0.9,1.2
			c0.1-0.1,0.2-0.1,0.3-0.2c2.1-2.2,4.6-3.2,7.5-3.6c2-0.3,4-0.1,5.8,1c0.6,0.4,1.3,0.7,1.9,1.1c1.8,1,3.2,2.5,4.3,4.1
			c0.5,0.7,0.8,1.5,1,2.4c1,4.1,1.4,8.2,1.2,12.4c-0.1,1.4-0.3,2.9-0.7,4.3c-0.3,1-0.6,2-0.9,3c0.2,0.3,0.3,0.5,0.6,0.9
			c-0.4,0.2-0.8,0.4-1.1,0.5c-0.7,1.5-1.5,3-2.2,4.5C629.7,470.7,630.3,470.7,630,471.1c-0.2,0.7-0.9,0.4-1.4,0.8
			c-0.3,0.4-0.7,0.9-1,1.4c0.1,0.4,0.2,0.7,0.2,1.1c-0.4,0.1-0.8,0.2-1.1,0.3c-0.1,0.2-0.3,0.3-0.4,0.4c-0.7,0.9-1.4,1.8-2.2,2.7
			c-1.3,1.4-2.8,2.1-4.7,2c-0.5,0-1,0-1.5,0.1c-0.4,1.3-0.8,2.6-1.2,3.9c0.9,0.1,1.6-0.1,2.3-0.4c1.4-0.5,2.6-1.3,3.6-2.3
			c1.2-1.1,2.4-2.2,3.5-3.4c0.8-0.8,1.4-1.7,2-2.7c0.6-0.9,1-1.8,1.5-2.7C629.9,471.9,630.1,471.6,630,471.1z M596.6,504.5
			c0,0.4-0.1,0.6,0,0.9c0.1,1.2,0.2,2.5,0.3,3.7c0.2,1.8,0.4,3.7,0.5,5.5c0.2,3.7,0.3,7.4,0.4,11.1c0.2,6.5,0.6,13,1.1,19.5
			c0,0.3,0,0.7,0.1,1c0.4,1.5,1.2,2.8,2.8,3.1c2.4,0.5,4.8,0.7,7.2,0.4c3.5-0.4,6.8-1.6,10-3.1c0.2-0.1,0.4-0.2,0.6-0.4
			c0.5-0.3,0.7-0.7,0.7-1.2c0-0.8,0.1-1.6,0-2.5c-0.3-6-0.3-12.1-0.3-18.1c0-6.6,0-13.2,0-19.8c0-0.4,0.1-0.9-0.1-1.4
			c-0.5,0.1-0.8,0.5-1.2,0.7c-1.4,1.1-2.9,2-4.7,2.5c-3.1,0.8-6.2,1.1-9.3,0.5c-2.6-0.5-5.1-1.1-7.5-2.3
			C597.1,504.6,596.9,504.6,596.6,504.5z M605.1,475.3c0.2-0.2,0.4-0.3,0.6-0.4c1.5-1.3,2.7-2.9,3.7-4.6c0.9-1.6,1.6-3.3,2.2-5.1
			c0.7-2.3,1.3-4.7,1.9-7c0.8-2.9,0.8-5.8,0.1-8.8c-0.7-3.1-1.6-6-3.2-8.8c-0.6-1-1.2-2-2.1-2.7c-1.3-1.1-2.5-2.2-3.9-3.2
			c-1.9-1.4-4-2.1-6.3-2.2c-2.5-0.1-4.8,0.5-7.1,1.5c-2.7,1.2-4.6,3.2-5.8,5.8c-1.3,2.6-1.8,5.4-2,8.3c-0.1,2.3,0.4,4.6,0.7,6.9
			c1,6.1,3.6,11.5,7.3,16.4c1,1.3,2.3,2.3,3.9,2.9c1.1,0.4,2.1,0.8,3.2,1.3c1,0.4,1.9,0.7,3,0.8c0.5,0,1.1,0.1,1.5-0.2
			c0.2-0.5,0.3-0.9,0.5-1.3c0.3-0.5,0.7-0.5,1.1-0.2C604.7,474.7,604.8,475,605.1,475.3z M609.8,499.2c0.1-0.2,0.1-0.3,0.2-0.4
			c1.3-4.7,2.4-9.5,3.3-14.3c0.1-0.3,0-0.6,0.1-1c-0.4,0-0.7-0.1-1-0.1c-1.2-0.1-2.3-0.6-3.2-1.5c-0.5-0.6-1-1.2-1.4-1.9
			c-0.7-1.2-1.2-2.4-1.3-3.9c-0.4,0.2-0.6,0.3-0.9,0.4c0.1,0.7,0.3,1.3,0.4,2c0.6,2.9,0.8,5.9,0.9,8.8c0.2,3.9,0.8,7.8,1.3,11.6
			c0,0.2,0.1,0.4,0.2,0.7C609.1,499.6,609.1,499.6,609.8,499.2z M607.3,505.8c0-0.2,0.1-0.4,0.1-0.5c0-1.6,0-3.3-0.2-4.9
			c-0.2-2.4-0.6-4.7-0.9-7.1c-0.3-3-0.5-6-0.8-9c-0.2-2-0.5-4-0.8-6c-0.1-0.5-0.2-1-0.4-1.4c-0.1-0.2-0.3-0.3-0.6-0.6
			c0.2,2.1,0.2,4.1,0.5,6c0.5,3.5,0.8,7,0.9,10.5c0.2,4.3,0.4,8.5,0.6,12.8C606.2,505.9,606.7,506,607.3,505.8z M614.2,479.5
			c-0.5-0.1-0.8-0.2-1.1-0.2c-0.7,0-1.2-0.4-1.6-0.9c-0.3-0.4-0.6-0.8-0.8-1.3c-0.4-0.9-0.7-1.9-1-2.9c-0.1-0.4-0.2-0.9-0.6-1.3
			c-0.7,0.8-1.4,1.6-2,2.3c0.2,0.6,0.3,1.1,0.5,1.6c0.4,1.3,1,2.5,1.8,3.6c0.7,1,1.7,1.6,2.9,1.9c0.5,0.1,1,0,1.3-0.5
			C613.9,481.1,614,480.4,614.2,479.5z M610.9,505.5c1.7,0.6,6.7-1.6,8-3.8c-2.1-1-4.3-1.5-6.7-1.4
			C611.7,502.1,611.3,503.8,610.9,505.5z M604.2,501c-0.5,0-1.1,0-1.6,0c-1.5,0.1-3,0.4-4.4,1c-0.5,0.2-1.1,0.4-1.4,1.1
			c2,1.2,4.3,1.6,6.5,2.2c0.5,0.1,1,0.4,1.5,0.2C604.8,503.9,604.5,501.8,604.2,501z M619.1,456.4c-1.2-0.6-2.6-0.9-3.5-0.9
			c-0.4,0.5-0.4,1.1-0.5,1.7c-0.1,0.6-0.3,1.1-0.4,1.7c-0.1,0.6-0.4,1.1-0.4,1.7c1.2,0.5,3.1,0.9,4,0.8
			C618.6,459.8,619,458.1,619.1,456.4z M614,461.6c-0.5,1.1-0.8,3.5-0.6,4.9c0.6,0.2,1.2,0.5,1.8,0.7c0.6,0.2,1.3,0.6,2,0.5
			c0.4-1.6,0.7-3.2,0.8-4.9C616.6,462.4,615.4,462,614,461.6z M610.1,504.3c0.1-0.5,0.3-0.9,0.4-1.4c0.6-2.1,1.2-4.3,1.8-6.4
			c0.4-1.6,0.9-3.2,1.3-4.8c0.6-3,1.4-5.9,2.3-8.8c0.5-1.5,0.9-3,1.2-4.5c0.2-0.7,0.2-1.5,0.3-2.2c0-0.2-0.1-0.4-0.2-0.6
			c-0.8,0.7-0.9,0.9-1.2,1.9c-0.3,1.2-0.5,2.5-0.8,3.7c-0.7,3.5-1.4,7-2.2,10.5c-0.7,3.2-1.6,6.3-2.5,9.4
			C610.2,502.2,609.9,503.2,610.1,504.3z M614.4,478.3c0.3-1.3,0.8-2.4,1.5-3.5c0.3-0.4,0.5-1,0.2-1.7c-0.6-0.2-1.3-0.3-1.9-0.5
			c-0.7-0.1-1.3-0.5-2.1-0.4c-0.4,1.1-0.6,2.2-0.8,3.3c0,0.2,0,0.4,0.1,0.6c0.5,1.2,1.1,2.2,2.6,2.1
			C614.1,478.4,614.2,478.3,614.4,478.3z M612.4,440.7c0.5,2.2,1.5,4,1.9,5.9c0.4,0.1,0.7,0.3,1,0.4c0.3,0.1,0.6,0.2,0.9,0.3
			c0.5-1.3,1.1-4.7,0.9-5.6C615.7,441.2,614.2,441,612.4,440.7z M627.3,456.8c0.4-1.9,0.7-3.7,0.9-5.4c-1.1-0.6-2.3-0.8-3.2-0.7
			c-0.5,1.8-0.9,4.7-0.7,5.6c0.5,0.1,1.1,0.2,1.6,0.3C626.2,456.7,626.7,456.8,627.3,456.8z M619.2,455.2c0.4-1,0.4-2,0.6-2.9
			c0.1-0.9,0.5-1.8,0.2-2.9c-0.9-0.2-1.9-0.5-2.9-0.7c-0.6,1.9-1,3.8-1.2,5.8C617,454.9,618,455,619.2,455.2z M630.1,464.4
			c0.4-1,0.5-2,0.7-2.9c0.2-1,0.5-1.9,0.4-2.9c-1-0.4-2.2-0.5-3.1-0.4c-0.2,0.9-0.3,1.8-0.5,2.7c-0.1,0.9-0.5,1.8-0.3,2.9
			C628.2,464,629.1,464.2,630.1,464.4z M628.2,450.2c0.6-1.9,0.8-4.7,0.5-5.9c-0.9-0.3-1.8-0.3-2.8-0.3c-0.3,1.8-0.7,3.6-0.8,5.4
			C626.2,449.6,627.1,449.9,628.2,450.2z M629.2,450.4c0.9,0.5,1.8,0.9,2.9,0.8c0.2-0.8-0.1-1.7,0.6-2.3c-0.2-1.4-0.4-2.7-0.9-4
			c-0.7-0.2-1.2-0.6-2-0.3C629.6,446.5,629.4,448.3,629.2,450.4z M626.1,463.5c0.5-1.7,1-4.6,0.9-5.5c-1-0.1-2-0.6-3.1-0.3
			c-0.1,1.7-0.6,3.3-0.5,5.1C624.2,462.9,625.1,463.2,626.1,463.5z M623.9,450.4c-1-0.4-1.9-0.6-2.8-0.6c-0.4,1.4-0.9,4.7-0.8,5.7
			c0.8,0.2,1.7,0.5,2.7,0.5C623.4,454.1,623.6,452.3,623.9,450.4z M617.3,447.7c1.1,0.3,2,0.6,3,0.6c0.1-1,0.3-1.8,0.4-2.7
			c0.1-0.9,0.4-1.8,0.2-2.8c-0.9-0.3-1.7-0.7-2.6-0.7C618,444,617.7,445.8,617.3,447.7z M631.3,457.5c0.4-1.3,0.7-4.1,0.6-5.2
			c-0.9-0.2-1.8-0.5-2.8-0.7c-0.4,1.4-0.8,4.3-0.7,5.4C629.3,457.2,630.3,457.3,631.3,457.5z M625,443.7c-1-0.2-1.9-0.4-2.8-0.6
			c-0.4,1-0.9,4.2-0.8,5.5c1,0.4,1.9,0.6,2.8,0.5C624.4,447.3,624.8,445.5,625,443.7z M619.1,463c-0.4,1.1-0.9,4.1-0.9,5
			c0.9,0.3,1.8,0.5,2.8,0.5c0.4-0.9,0.9-3.8,0.8-5C621,463.2,620,463,619.1,463z M629.8,465.4c-0.9-0.2-1.9-0.4-2.8-0.5
			c-0.5,1-1.1,3.9-1,5c0.7,0.3,1.5,0.6,2.3,0.6C629.4,469.1,630,466.9,629.8,465.4z M620.2,456.5c-0.5,1.3-0.9,4.5-0.8,5.4
			c0.9,0.2,1.8,0.5,2.8,0.4c0.3-1.7,0.6-3.4,0.7-5.1C621.9,456.9,621.1,456.7,620.2,456.5z M625,469.5c0.4-1.7,0.7-3.3,0.9-4.8
			c-1.1-0.6-1.9-0.8-2.9-0.8c-0.3,1.6-0.6,3.2-0.8,4.9C623.1,469,624,469.2,625,469.5z M617,468.8c-1.3-0.5-2.5-0.9-3.6-1.4
			c-0.1,0-0.1,0-0.3,0.1c-0.2,1.2-0.5,2.4-0.7,3.6c1.3,0.5,3.1,0.9,4.1,0.8C616.7,471,616.8,469.9,617,468.8z M625.2,442.6
			c0.2-1.3,0.4-2.6,0.6-3.8c-1.3-1-1.9-1.2-3.1-1.3c-0.3,1.4-0.5,2.8-0.5,4.3C623.2,442.2,624.1,442.5,625.2,442.6z M618.8,437.5
			c0,1.2-0.4,2.3-0.1,3.5c0.9,0.2,1.7,0.6,2.5,0.6c0.4-1.6,0.7-3.4,0.5-4.2C620.8,437.2,619.9,437.2,618.8,437.5z M620.5,473
			c0.2-1.2,0.3-2.2,0.5-3.2c-1.1-0.4-2-0.6-3.1-0.7c-0.3,1.1-0.5,2.1-0.5,3.2C618.4,472.6,619.4,472.8,620.5,473z M620.5,478.8
			c1.9-0.5,2.9-1.6,3.9-2.8c-0.1-0.5-0.2-0.9-0.4-1.4c-0.9-0.2-1.7-0.3-2.7-0.2c-0.2,1.2-0.5,2.3-0.7,3.5
			C620.6,478.1,620.6,478.3,620.5,478.8z M621.6,473.2c0.9,0.1,1.8,0.5,2.8,0.2c0.1-0.9,0.5-1.7,0.3-2.6c-1-0.5-1.5-0.7-2.6-0.7
			C621.7,471,621.6,472,621.6,473.2z M619.4,478.9c0.7-2.4,1-3.9,0.8-4.9c-1-0.2-1.9-0.5-3-0.5c0,0.2-0.1,0.3-0.1,0.6
			c1.2,0.2,1.4,1.1,1.4,2c0,0.4-0.1,0.8-0.1,1.2c0,0.4-0.1,0.9-0.1,1.4C618.6,478.8,619,478.8,619.4,478.9z M626.4,439.2
			c0,1.2-0.3,2.4-0.2,3.5c1,0.2,1.9,0.6,2.8,0.4c0.2-1,0.2-1,0-2.2C628.1,440,627.6,439.7,626.4,439.2z M617.7,437.8
			c-1.6,0.2-3.4,1.1-4.1,2.1c1.2,0.2,2.3,0.4,3.5,0.7c0.1,0,0.2,0,0.3,0C617.8,439.7,617.8,439.3,617.7,437.8z M611.3,472.1
			c-0.2-0.3-0.4-0.5-0.7-0.8c0.4-0.1,0.7-0.2,1-0.3c0.6-1.9,0.7-2.7,0.5-3.6c-0.4,0.8-0.7,1.5-1,2.3c-0.4,0.8-0.8,1.5-1.2,2.3
			c0.5,0.5,0.4,1.3,1,2C611,473.2,611.2,472.6,611.3,472.1z M632.1,458.8c-0.1,0.1-0.2,0.2-0.2,0.2c-0.3,1.6-0.7,3.3-1,4.9
			c0,0.1,0.1,0.3,0.2,0.4c1.1-1.5,1.8-3.9,1.7-5.4C632.6,459,632.4,458.9,632.1,458.8z M608.9,506c0.1-0.3,0.1-0.5,0.1-0.6
			c-0.1-1.3,0.1-2.6,0.4-3.9c0.1-0.3,0.1-0.6,0.1-1c0-0.1-0.1-0.1-0.1-0.2c-0.4-0.1-0.7-0.1-0.9,0.2c0,1.4,0,2.8,0,4.1
			c0,0.3,0,0.7,0,1C608.5,505.7,608.7,505.8,608.9,506z M633,452.8c-0.4,0.1-0.4,0.4-0.4,0.7c-0.1,1.1-0.2,2.2-0.3,3.3
			c0,0.3,0,0.6,0,0.9c0.2,0.1,0.3,0.2,0.5,0.2c0.1,0,0.2,0,0.3,0C633.4,456.5,633.3,453.8,633,452.8z M627.6,471.6
			c-0.7-0.4-1.3-0.5-1.9-0.7c-0.3,0.8-0.5,1.6-0.4,2.4c0.5,0.3,0.9,0.2,1.2-0.3C626.8,472.7,627.2,472.2,627.6,471.6z M614.7,447.7
			c0.3,1.2,0.4,2.2,0.8,3.2c0.3-0.8,0.6-1.6,0.6-2.5C615.7,448.1,615.3,447.9,614.7,447.7z M631.1,443.7c-0.1-0.7-0.5-1-0.8-1.4
			c-0.1-0.1-0.2,0-0.3-0.1c0,0.4,0,0.7,0,1.1C630.4,443.5,630.7,443.6,631.1,443.7z"/>
                                <path d="M567.7,478.4c-0.4-1.7-0.9-3.6-1.4-5.5c-0.8-3.1-1.6-6.1-2.4-9.2c-0.4-1.6-0.9-3.2-1.3-4.8c-0.4-1.4-0.7-2.8-1-4.2
			c-0.1-0.3-0.1-0.6-0.1-0.9c0-1.5,0.9-2.6,2.4-2.8c0.6-0.1,1.2-0.1,1.8,0c0.9,0,1.1,0.2,1.3,1.1c0.6,2.3,1.2,4.6,1.8,6.9
			c1.4,5.3,2.7,10.7,4.1,16c0.1,0.4,0.2,0.8,0.3,1.1c0.1,0.1,0.2,0.2,0.2,0.2c1.1,0.2,1.7,1,2,2.1c1.1,3.8,2.2,7.5,3.3,11.2
			c1.3,4.7,2.5,9.5,4,14.1c2.1,6.3,3.8,12.8,5.5,19.2c1,3.9,2.2,7.8,3.2,11.8c0.6,2.3,1.2,4.7,1.8,7.1c0.3,1.1,0.1,2.1-0.5,3.1
			c-0.7,1-1.3,2.1-2,3.1c-0.1,0.1-0.2,0.3-0.3,0.4c-0.8,1.1-1.8,1.6-3.2,1.3c-1-0.2-2-0.2-2.9-0.3c-1.9-0.1-2.3-0.4-2.8-2.3
			c-0.5-2-1-4.1-1.6-6.1c-1.9-7.4-3.7-14.8-5.7-22.1c-2.2-7.9-3.8-15.9-5.6-23.9c-0.7-3.1-1.1-6.2-1.6-9.4c-0.1-0.5-0.2-1-0.5-1.5
			c-0.4-0.6-0.5-1.3-0.6-2c-0.1-0.2,0.1-0.6,0.2-0.8C566.6,480.5,567.1,479.6,567.7,478.4z M588.5,548.2c0-1-0.3-2-0.6-2.9
			c-1.8-7.2-4-14.3-6.2-21.4c-1.6-5.1-3-10.2-4.3-15.3c-1.5-5.9-3.1-11.8-4.3-17.8c-0.8-3.9-1.8-7.7-2.8-11.5c0-0.1-0.2-0.3-0.3-0.5
			c-0.2,0.1-0.4,0.2-0.5,0.4c-0.7,0.6-1.2,1.4-1.6,2.2c-0.4,0.7-0.5,1.4-0.3,2.3c0.7,2.9,1.2,5.9,1.9,8.8c0.9,4.2,1.9,8.3,2.9,12.5
			c0.8,3.4,1.6,6.9,2.4,10.3c1.4,5.4,2.8,10.7,4.2,16.1c1.2,4.9,2.4,9.8,3.6,14.7c0.2,0.6,0.2,1.3,0.7,1.8c1.5,0.2,2.9,0.3,4.4,0.5
			c0.1,0,0.2,0,0.4,0C588.3,548.4,588.3,548.3,588.5,548.2z M589.4,547.7c0.1-0.2,0.2-0.3,0.2-0.5c0.6-1,1.2-2,1.9-3
			c0.4-0.6,0.4-1.2,0.2-1.9c-0.7-2.6-1.4-5.1-2.2-7.7c-0.9-3.1-1.8-6.2-2.7-9.4c-1.3-4.8-2.4-9.6-3.9-14.3c-2-6.6-3.8-13.2-5.5-19.9
			c-1-3.7-2.1-7.3-3.1-10.9c-0.2-0.6-0.5-1.2-0.7-1.8c-1-0.4-1.9-0.5-2.9,0c0.1,0.4,0.1,0.8,0.2,1.2c0.1,0.6,0.3,1.2,0.5,1.8
			c1,2.8,1.7,5.7,2.3,8.6c0.5,2.3,0.9,4.7,1.4,7c1,4.1,2.1,8.2,3.1,12.3c1.2,5,2.6,10,4.2,14.9c2.4,7.3,4.3,14.7,6.2,22.1
			C588.8,546.8,588.9,547.2,589.4,547.7z M572,476.2c0.1-0.8-0.2-1.7-0.5-2.5c-0.8-3.1-1.7-6.1-2.5-9.2c-1-3.7-1.9-7.3-2.8-11
			c-0.1-0.4-0.3-0.8-0.4-1.3c-0.6,0-1.1,0-1.7,0c-0.9,0.1-1.5,0.7-1.4,1.7c0,0.2,0,0.3,0.1,0.5c0.4,1.6,0.8,3.2,1.2,4.8
			c0.3,1.2,0.7,2.5,1,3.7c0.8,3.1,1.6,6.2,2.4,9.3c0.4,1.5,0.9,3,1.1,4.6c0,0.2,0.1,0.4,0.2,0.6C569.8,477,570.8,476.7,572,476.2z"
                                />
                                <path d="M620.6,547.2C620.6,547.2,620.6,547.2,620.6,547.2c0.4-0.1,0.8-0.3,1.2-0.5c0.9-0.4,1.2-0.3,1.7,0.6
			c0.3,0.5,0.4,1.1,0.6,1.7c0.1,0.2,0.1,0.4,0.1,0.6c0.4,0,0.7,0,1,0.1c0.4,0,0.7,0,1.1,0.1c1.3,0.4,1.7,1.5,0.9,2.6
			c-0.2,0.3-0.4,0.5-0.6,0.7c0.1,0.5,0.5,0.5,0.8,0.7c1.6,1,1.6,2.3,0,3.2c-0.2,0.1-0.4,0.2-0.7,0.4c0.1,0.4,0.3,0.7,0.4,1
			c0.2,0.7,0,1.3-0.7,1.5c-0.5,0.2-1,0.3-1.4,0.2c-1.7-0.1-3.4-0.1-5-0.3c-1.3-0.1-2.7-0.4-4-0.2c-0.5,0-1.1-0.1-1.6-0.2
			c-0.7-0.1-1.1-0.6-1.2-1.3c-0.2-1.5,0-2.9,1.2-4.1c0.3-0.3,0.6-0.7,0.9-1c0.9-1.3,1.9-2.3,3.1-3.4c0.6-0.5,1.1-1.1,1.7-1.6
			C620.2,547.9,620.4,547.5,620.6,547.2z M616.2,553.7c0.5,0.7,1.2,0.4,1.9,0.5c1.7-1.6,3.5-3.1,4.8-5.1c-0.1-0.5-0.2-0.9-0.7-1.2
			c-0.2,0.1-0.4,0.2-0.5,0.3c-1.6,1.5-3.2,3.1-4.8,4.6C616.7,553.1,616.5,553.4,616.2,553.7z M614.2,558.4c0.9,0.4,1.8,0.3,2.7,0.1
			c0.9-0.2,1.4-1,1.4-1.9c0-0.7-0.2-1-0.9-1.1c-0.7-0.1-1.4-0.3-2.2-0.4C614.2,555.6,613.8,556.7,614.2,558.4z M627.2,555.4
			c-0.8-0.9-1.9-1-2.9-1.4c-1.5,0.6-2.9,1.2-4.3,1.8c-0.2,0.1-0.3,0.3-0.4,0.5c0.2,0.1,0.2,0.2,0.3,0.2c2.1-0.1,4.2-0.1,6.3-0.3
			C626.6,556.2,627.1,556,627.2,555.4z M619,555c0.4-0.1,0.6-0.1,0.7-0.1c1.8-0.7,3.5-1.5,5.3-2.3c0.5-0.2,1.1-0.6,1.3-1.3
			c-0.2-0.1-0.4-0.3-0.5-0.3c-1-0.1-2-0.1-3.1,0.1c-0.6,0.6-1.2,1.2-1.8,1.9C620.4,553.6,619.5,554,619,555z M619.4,557.4
			c-0.1,0.4-0.2,0.6-0.4,1c2.1,0.3,4.1,0.6,6.1,0.6c0.2,0,0.4-0.1,0.8-0.2c-0.6-0.8-1.1-1.4-2-1.3c-1,0-2,0-2.9,0
			C620.5,557.4,620,557.4,619.4,557.4z"/>
                                <path d="M593.3,563.2c-0.5-0.1-1.1-0.1-1.6-0.2c-2.7-0.5-4.2-3.1-3.4-5.7c0.3-1,0.8-1.8,1.5-2.5c0.4-0.4,0.8-0.8,1.2-1.2
			c0.2-0.2,0.5-0.4,0.8-0.5c2.6-0.8,4.9-0.3,6.7,1.8c0.9,0.9,1.1,2,0.8,3.2C598.9,561.1,596.4,563.2,593.3,563.2z M593.9,554.2
			c-0.6,0.1-1.3,0.1-1.8,0.3c-1.4,0.8-2.3,2-2.6,3.5c-0.3,1.5,0.5,3,1.9,3.5c3.7,1.2,5.9-0.8,6.7-3.7c0.2-0.8,0-1.5-0.6-2
			C596.5,554.9,595.4,554.1,593.9,554.2z"/>
                                <path d="M583.3,556.2c0,0.6,0,1.2-0.2,1.8c-0.4,2.1-1.9,3.1-3.9,2.6c-1.9-0.4-3.1-1.5-3.7-3.4c-0.4-1.4-0.4-2.8,0.1-4.1
			c0.4-1,1-1.8,2-2.2c0.6-0.2,1.2-0.2,1.8,0.1c0.3,0.2,0.7,0.3,1,0.4c1.4,0.2,2.7,1.5,2.8,3.3C583.2,555.3,583.2,555.8,583.3,556.2
			C583.2,556.2,583.3,556.2,583.3,556.2z M577.1,552.9c-0.9,1.7-0.9,3.4,0,5c0.6,1.2,1.7,1.6,3,1.6c0.7,0,1.3-0.4,1.5-1.1
			c0.4-1.3,0.5-2.6,0.2-3.9c-0.2-0.9-0.9-1.5-1.8-1.6C579.1,552.8,578.2,552.9,577.1,552.9z"/>
                                <path className="st32" d="M596.6,504.5c0.3,0.1,0.5,0.1,0.6,0.2c2.4,1.1,4.9,1.8,7.5,2.3c3.1,0.6,6.2,0.3,9.3-0.5
			c1.7-0.5,3.2-1.4,4.7-2.5c0.3-0.3,0.6-0.6,1.2-0.7c0.2,0.5,0.1,0.9,0.1,1.4c0,6.6,0,13.2,0,19.8c0,6,0,12.1,0.3,18.1
			c0,0.8,0,1.6,0,2.5c0,0.5-0.3,0.9-0.7,1.2c-0.2,0.1-0.4,0.3-0.6,0.4c-3.2,1.5-6.5,2.7-10,3.1c-2.4,0.3-4.9,0.1-7.2-0.4
			c-1.6-0.4-2.4-1.6-2.8-3.1c-0.1-0.3-0.1-0.7-0.1-1c-0.5-6.5-0.9-13-1.1-19.5c-0.1-3.7-0.2-7.4-0.4-11.1c-0.1-1.8-0.3-3.7-0.5-5.5
			c-0.1-1.2-0.2-2.5-0.3-3.7C596.5,505.2,596.6,504.9,596.6,504.5z"/>
                                <path className="st32" d="M605.1,475.3c-0.3-0.3-0.4-0.5-0.6-0.7c-0.4-0.4-0.9-0.3-1.1,0.2c-0.2,0.4-0.3,0.8-0.5,1.3
			c-0.4,0.3-1,0.2-1.5,0.2c-1-0.1-2-0.4-3-0.8c-1.1-0.5-2.1-0.9-3.2-1.3c-1.6-0.6-2.9-1.5-3.9-2.9c-3.8-4.9-6.4-10.3-7.3-16.4
			c-0.4-2.3-0.9-4.5-0.7-6.9c0.1-2.9,0.7-5.7,2-8.3c1.3-2.6,3.2-4.6,5.8-5.8c2.3-1,4.6-1.6,7.1-1.5c2.3,0.1,4.5,0.8,6.3,2.2
			c1.3,1,2.6,2.1,3.9,3.2c0.9,0.8,1.5,1.7,2.1,2.7c1.6,2.7,2.5,5.7,3.2,8.8c0.7,2.9,0.7,5.9-0.1,8.8c-0.6,2.3-1.3,4.7-1.9,7
			c-0.5,1.8-1.3,3.5-2.2,5.1c-1,1.7-2.2,3.3-3.7,4.6C605.5,475,605.4,475.1,605.1,475.3z M610.1,449c-0.1-0.3-0.2-0.5-0.2-0.8
			c-0.3-2.1-1.4-3.8-2.6-5.5c-0.2-0.3-0.4-0.6-0.6-0.9c-0.1-0.1-0.2-0.3-0.3-0.4c-1.1-0.8-2.2-1.7-3.4-2.5c-1.5-0.9-3.1-1.6-4.9-1.8
			c-2.4-0.3-4.7,0.1-6.9,0.9c-0.2,0.1-0.4,0.2-0.6,0.4c-0.6,0.6-1.3,1.1-1.9,1.8c-1.1,1.1-1.7,2.4-2,3.9c-0.3,2-0.3,4.1-0.1,6.1
			c0.1,1,0.2,2,0.3,3.1c0.1,0.7,0.4,1.4,0.6,2.1c0.2,0.8,0.4,1.5,0.5,2.3c0.1,0.7,0.4,1.4,0.5,2.1c0.9,4.5,3.3,7.9,6.8,10.7
			c0.6,0.5,1.3,0.9,2,1.2c1.8,0.9,3.7,0.8,5.4-0.2c0.9-0.5,1.7-1.1,2.4-1.7c2-1.7,3.1-4,3.8-6.5c0.7-2.6,1.1-5.2,1.5-7.9
			c0.2-1.7,0.4-3.3,0-5c0.2-0.4,0.4-0.7,0.6-1C610.7,449.3,610.4,449.1,610.1,449z"/>
                                <path className="st32" d="M630,471.2c0.3-0.4-0.3-0.5-0.3-0.8c0.7-1.5,1.5-3,2.2-4.5c0.3-0.2,0.7-0.3,1.1-0.5
			c-0.2-0.4-0.4-0.6-0.6-0.9c0.3-1,0.6-2,0.9-3c0.4-1.4,0.6-2.8,0.7-4.3c0.2-4.2-0.2-8.3-1.2-12.4c-0.2-0.8-0.5-1.7-1-2.4
			c-1.1-1.7-2.5-3.1-4.3-4.1c-0.6-0.4-1.3-0.7-1.9-1.1c-1.8-1.1-3.8-1.3-5.8-1c-2.9,0.4-5.5,1.4-7.5,3.6c-0.1,0.1-0.1,0.1-0.3,0.2
			c-0.5-0.2-0.6-0.8-0.9-1.2c-0.3-0.4-0.6-0.9-0.9-1.3c0-0.2,0.1-0.3,0.1-0.4c1.2-1.6,2.5-3.1,4.1-4.2c2.6-1.7,5.5-2.4,8.6-1.6
			c2.2,0.6,4.3,1.4,6.1,2.8c1.5,1.1,2.4,2.7,3,4.4c1.5,4,2.1,8.2,2.6,12.4c0.6,4.4,0.3,8.8-0.8,13.1c-0.2,0.9-0.7,1.7-1.2,2.6
			c-0.6,1.1-1.1,2.2-1.7,3.3c-0.1,0.3-0.3,0.6-0.5,0.9C630.5,470.9,630.2,471,630,471.2C630,471.1,630,471.2,630,471.2z"/>
                                <path className="st32" d="M607.3,505.8c-0.6,0.2-1.1,0.1-1.5-0.1c-0.2-4.3-0.4-8.6-0.6-12.8c-0.2-3.5-0.5-7-0.9-10.5
			c-0.3-1.9-0.3-3.8-0.5-6c0.3,0.3,0.6,0.4,0.6,0.6c0.2,0.5,0.3,0.9,0.4,1.4c0.3,2,0.6,4,0.8,6c0.3,3,0.5,6,0.8,9
			c0.2,2.4,0.6,4.7,0.9,7.1c0.2,1.6,0.1,3.3,0.2,4.9C607.4,505.4,607.3,505.6,607.3,505.8z"/>
                                <path className="st32" d="M630,471.1c0.1,0.4-0.1,0.8-0.3,1.2c-0.5,0.9-1,1.8-1.5,2.7c-0.6,0.9-1.2,1.9-2,2.7
			c-1.1,1.2-2.3,2.3-3.5,3.4c-1.1,1-2.3,1.8-3.6,2.3c-0.7,0.3-1.4,0.4-2.3,0.4c0.4-1.3,0.8-2.6,1.2-3.9c0.5,0,1-0.1,1.5-0.1
			c1.9,0.1,3.5-0.6,4.7-2c0.8-0.9,1.5-1.8,2.2-2.7c0.1-0.1,0.2-0.2,0.4-0.4c0.3-0.1,0.7-0.2,1.1-0.3c-0.1-0.4-0.1-0.6-0.2-1.1
			c0.3-0.5,0.7-1.1,1-1.4C629.2,471.5,629.8,471.8,630,471.1C630,471.2,630,471.1,630,471.1z"/>
                                <path className="st32" d="M614.2,479.5c-0.2,0.9-0.3,1.7-0.4,2.4c-0.4,0.5-0.8,0.6-1.3,0.5c-1.2-0.3-2.2-0.9-2.9-1.9
			c-0.8-1.1-1.4-2.3-1.8-3.6c-0.2-0.5-0.3-1-0.5-1.6c0.6-0.7,1.3-1.5,2-2.3c0.4,0.4,0.4,0.9,0.6,1.3c0.3,1,0.6,1.9,1,2.9
			c0.2,0.4,0.5,0.9,0.8,1.3c0.4,0.5,0.9,0.8,1.6,0.9C613.4,479.3,613.7,479.4,614.2,479.5z"/>
                                <path className="st27" d="M610.9,505.5c0.4-1.7,0.9-3.5,1.3-5.2c2.4-0.1,4.6,0.5,6.7,1.4C617.6,503.9,612.6,506.1,610.9,505.5z"/>
                                <path className="st27" d="M604.2,501c0.3,0.8,0.6,2.9,0.6,4.5c-0.5,0.2-1,0-1.5-0.2c-2.2-0.6-4.5-1-6.5-2.2c0.3-0.7,0.9-0.9,1.4-1.1
			c1.4-0.6,2.9-0.9,4.4-1C603.1,500.9,603.7,501,604.2,501z"/>
                                <path className="st15" d="M619.1,456.4c-0.1,1.7-0.5,3.4-0.8,5.1c-1,0.1-2.8-0.3-4-0.8c0-0.6,0.3-1.2,0.4-1.7c0.1-0.6,0.3-1.1,0.4-1.7
			c0.2-0.6,0.1-1.2,0.5-1.7C616.4,455.5,617.8,455.8,619.1,456.4z"/>
                                <path className="st15" d="M614,461.6c1.3,0.4,2.6,0.7,4,1.1c-0.1,1.7-0.4,3.3-0.8,4.9c-0.8,0.1-1.4-0.2-2-0.5
			c-0.6-0.2-1.2-0.5-1.8-0.7C613.2,465.1,613.5,462.7,614,461.6z"/>
                                <path className="st32" d="M610.1,504.3c-0.1-1.1,0.1-2.2,0.4-3.2c0.8-3.1,1.7-6.3,2.5-9.4c0.8-3.5,1.5-7,2.2-10.5
			c0.3-1.2,0.5-2.5,0.8-3.7c0.2-1,0.4-1.2,1.2-1.9c0.1,0.2,0.2,0.4,0.2,0.6c-0.1,0.7-0.1,1.5-0.3,2.2c-0.4,1.5-0.7,3-1.2,4.5
			c-0.9,2.9-1.7,5.8-2.3,8.8c-0.3,1.6-0.8,3.2-1.3,4.8c-0.6,2.1-1.2,4.3-1.8,6.4C610.3,503.4,610.2,503.9,610.1,504.3z"/>
                                <path className="st15" d="M614.4,478.3c-0.2,0-0.3,0-0.4,0c-1.5,0-2.2-0.9-2.6-2.1c-0.1-0.2-0.2-0.4-0.1-0.6c0.2-1.1,0.3-2.3,0.8-3.3
			c0.8-0.1,1.4,0.3,2.1,0.4c0.6,0.1,1.3,0.3,1.9,0.5c0.3,0.6,0.1,1.2-0.2,1.7C615.2,475.9,614.8,477.1,614.4,478.3z"/>
                                <path className="st15" d="M612.4,440.7c1.8,0.2,3.3,0.4,4.7,1c0.1,0.9-0.4,4.3-0.9,5.6c-0.3-0.1-0.6-0.2-0.9-0.3
			c-0.3-0.1-0.6-0.2-1-0.4C614,444.7,612.9,442.9,612.4,440.7z"/>
                                <path className="st15" d="M627.3,456.8c-0.6-0.1-1-0.1-1.5-0.2c-0.6-0.1-1.1-0.2-1.6-0.3c-0.1-0.9,0.3-3.9,0.7-5.6
			c0.9-0.1,2.1,0.2,3.2,0.7C628,453.2,627.7,454.9,627.3,456.8z"/>
                                <path className="st15" d="M619.2,455.2c-1.1-0.3-2.2-0.3-3.3-0.7c0.2-2,0.6-3.8,1.2-5.8c1,0.2,2,0.5,2.9,0.7c0.2,1.1-0.1,2-0.2,2.9
			C619.6,453.3,619.6,454.2,619.2,455.2z"/>
                                <path className="st15" d="M630.1,464.4c-1-0.2-1.9-0.4-2.9-0.6c-0.2-1.1,0.2-1.9,0.3-2.9c0.1-0.9,0.3-1.8,0.5-2.7
			c0.9-0.2,2.1,0,3.1,0.4c0.2,1-0.2,1.9-0.4,2.9C630.6,462.4,630.5,463.3,630.1,464.4z"/>
                                <path className="st15" d="M628.2,450.2c-1.1-0.3-2.1-0.5-3.1-0.8c0.2-1.9,0.5-3.6,0.8-5.4c1,0,1.9,0,2.8,0.3
			C629,445.5,628.8,448.3,628.2,450.2z"/>
                                <path className="st15" d="M629.2,450.4c0.2-2.1,0.4-3.9,0.6-5.8c0.8-0.2,1.3,0.1,2,0.3c0.5,1.2,0.7,2.6,0.9,4
			c-0.7,0.6-0.4,1.5-0.6,2.3C631.1,451.3,630.1,450.9,629.2,450.4z"/>
                                <path className="st15" d="M626.1,463.5c-1-0.3-1.8-0.5-2.8-0.8c0-1.7,0.5-3.4,0.5-5.1c1.2-0.2,2.2,0.2,3.1,0.3
			C627.1,458.8,626.6,461.8,626.1,463.5z"/>
                                <path className="st15" d="M623.9,450.4c-0.3,1.9-0.6,3.7-0.9,5.6c-1,0-1.9-0.3-2.7-0.5c-0.1-0.9,0.3-4.3,0.8-5.7
			C622.1,449.8,623,450,623.9,450.4z"/>
                                <path className="st15" d="M617.3,447.7c0.4-1.9,0.7-3.7,1-5.6c1,0,1.8,0.4,2.6,0.7c0.2,1-0.2,1.9-0.2,2.8c-0.1,0.9-0.3,1.8-0.4,2.7
			C619.3,448.4,618.4,448.1,617.3,447.7z"/>
                                <path className="st15" d="M631.3,457.5c-1-0.2-2-0.3-2.9-0.5c-0.1-1,0.3-4,0.7-5.4c0.9,0.2,1.9,0.5,2.8,0.7
			C632.1,453.4,631.7,456.2,631.3,457.5z"/>
                                <path className="st15" d="M625,443.7c-0.2,1.9-0.5,3.6-0.9,5.3c-0.9,0.1-1.8-0.1-2.8-0.5c0-1.3,0.4-4.5,0.8-5.5
			C623.1,443.2,624,443.4,625,443.7z"/>
                                <path className="st15" d="M619.1,463c1,0,1.9,0.2,2.8,0.5c0.1,1.2-0.4,4.1-0.8,5c-1,0-1.9-0.2-2.8-0.5
			C618.2,467.1,618.7,464.1,619.1,463z"/>
                                <path className="st15" d="M629.8,465.4c0.2,1.5-0.4,3.7-1.5,5c-0.8,0.1-1.6-0.3-2.3-0.6c-0.1-1.1,0.5-3.9,1-5
			C627.9,465,628.9,465.2,629.8,465.4z"/>
                                <path className="st15" d="M620.2,456.5c0.9,0.2,1.8,0.4,2.7,0.6c-0.1,1.8-0.4,3.5-0.7,5.1c-1.1,0.1-1.9-0.2-2.8-0.4
			C619.2,461,619.7,457.8,620.2,456.5z"/>
                                <path className="st15" d="M625,469.5c-1-0.3-1.9-0.5-2.8-0.7c0.2-1.7,0.5-3.3,0.8-4.9c1.1,0,1.8,0.2,2.9,0.8
			C625.7,466.2,625.4,467.7,625,469.5z"/>
                                <path className="st15" d="M617,468.8c-0.2,1.1-0.4,2.1-0.6,3.1c-1,0.1-2.8-0.2-4.1-0.8c0.2-1.2,0.5-2.4,0.7-3.6c0.2,0,0.3-0.1,0.3-0.1
			C614.6,467.9,615.7,468.3,617,468.8z"/>
                                <path className="st15" d="M625.2,442.6c-1.1-0.1-2-0.3-3-0.7c0-1.5,0.2-2.9,0.5-4.3c1.2,0,1.8,0.3,3.1,1.3
			C625.6,440,625.4,441.2,625.2,442.6z"/>
                                <path className="st15" d="M618.8,437.5c1.1-0.3,2-0.4,3-0.1c0.1,0.8-0.1,2.6-0.5,4.2c-0.9,0-1.6-0.4-2.5-0.6
			C618.4,439.9,618.8,438.8,618.8,437.5z"/>
                                <path className="st15" d="M620.5,473c-1.2-0.2-2.2-0.4-3.2-0.6c0-1.2,0.3-2.2,0.5-3.2c1.1,0,2.1,0.3,3.1,0.7
			C620.8,470.8,620.7,471.8,620.5,473z"/>
                                <path className="st15" d="M620.5,478.8c0.1-0.5,0.1-0.7,0.2-0.9c0.2-1.2,0.5-2.3,0.7-3.5c0.9-0.1,1.8,0,2.7,0.2
			c0.1,0.4,0.2,0.9,0.4,1.4C623.4,477.2,622.4,478.3,620.5,478.8z"/>
                                <path className="st15" d="M621.6,473.2c0.1-1.2,0.2-2.2,0.5-3.2c1.1,0.1,1.7,0.2,2.6,0.7c0.2,0.9-0.2,1.7-0.3,2.6
			C623.4,473.7,622.5,473.3,621.6,473.2z"/>
                                <path className="st15" d="M619.4,478.9c-0.4-0.1-0.7-0.1-1.2-0.2c0.1-0.5,0.1-1,0.1-1.4c0-0.4,0.1-0.8,0.1-1.2c0-0.9-0.2-1.8-1.4-2
			c0-0.2,0.1-0.4,0.1-0.6c1.1,0,2,0.3,3,0.5C620.4,475,620.1,476.5,619.4,478.9z"/>
                                <path className="st15" d="M626.4,439.2c1.2,0.4,1.7,0.8,2.6,1.7c0.2,1.2,0.2,1.2,0,2.2c-0.9,0.2-1.8-0.2-2.8-0.4
			C626.1,441.6,626.4,440.5,626.4,439.2z"/>
                                <path className="st15" d="M617.7,437.8c0.1,1.5,0.1,1.8-0.3,2.7c-0.1,0-0.2,0.1-0.3,0c-1.2-0.2-2.3-0.4-3.5-0.7
			C614.3,439,616.2,438,617.7,437.8z"/>
                                <path className="st15" d="M611.3,472.1c-0.1,0.6-0.3,1.1-0.5,1.9c-0.6-0.6-0.5-1.4-1-2c0.4-0.8,0.8-1.5,1.2-2.3c0.4-0.7,0.7-1.5,1-2.3
			c0.3,0.9,0.1,1.7-0.5,3.6c-0.3,0.1-0.6,0.2-1,0.3C610.9,471.6,611.1,471.8,611.3,472.1z"/>
                                <path className="st15" d="M632.1,458.8c0.2,0.1,0.5,0.2,0.7,0.2c0.1,1.5-0.7,3.9-1.7,5.4c-0.1-0.2-0.2-0.3-0.2-0.4
			c0.3-1.6,0.6-3.3,1-4.9C631.9,459,632,458.9,632.1,458.8z"/>
                                <path className="st27" d="M608.9,506c-0.2-0.2-0.3-0.3-0.4-0.4c0-0.3,0-0.7,0-1c0-1.3,0-2.7,0-4.1c0.2-0.2,0.5-0.3,0.9-0.2
			c0,0.1,0.1,0.2,0.1,0.2c0,0.3-0.1,0.6-0.1,1c-0.2,1.3-0.5,2.6-0.4,3.9C609,505.6,608.9,505.7,608.9,506z"/>
                                <path className="st15" d="M633,452.8c0.4,1,0.4,3.7,0,5.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.3-0.1-0.5-0.2c0-0.3,0-0.6,0-0.9
			c0.1-1.1,0.2-2.2,0.3-3.3C632.6,453.2,632.6,452.9,633,452.8z"/>
                                <path className="st15" d="M627.6,471.6c-0.4,0.6-0.7,1-1.1,1.5c-0.3,0.4-0.7,0.5-1.2,0.3c-0.1-0.8,0.1-1.6,0.4-2.4
			C626.3,471.1,626.9,471.2,627.6,471.6z"/>
                                <path className="st15" d="M614.7,447.7c0.6,0.3,1,0.5,1.4,0.7c0,0.9-0.3,1.7-0.6,2.5C615.1,449.9,614.9,448.9,614.7,447.7z"/>
                                <path className="st15" d="M631.1,443.7c-0.5-0.1-0.8-0.2-1.2-0.3c0-0.4,0-0.7,0-1.1c0.1,0,0.3,0,0.3,0.1
			C630.7,442.7,631,443,631.1,443.7z"/>
                                <path className="st14" d="M588.5,548.2c-0.2,0.1-0.3,0.1-0.4,0.1c-0.1,0-0.2,0-0.4,0c-1.5-0.2-2.9-0.3-4.4-0.5
			c-0.5-0.5-0.5-1.2-0.7-1.8c-1.2-4.9-2.4-9.8-3.6-14.7c-1.4-5.4-2.8-10.7-4.2-16.1c-0.9-3.4-1.6-6.9-2.4-10.3
			c-1-4.2-2-8.3-2.9-12.5c-0.7-2.9-1.2-5.9-1.9-8.8c-0.2-0.8-0.1-1.5,0.3-2.3c0.4-0.8,0.9-1.6,1.6-2.2c0.1-0.1,0.3-0.2,0.5-0.4
			c0.1,0.2,0.2,0.3,0.3,0.5c1,3.8,2.1,7.6,2.8,11.5c1.2,6,2.9,11.9,4.3,17.8c1.3,5.1,2.8,10.2,4.3,15.3c2.2,7.1,4.4,14.2,6.2,21.4
			C588.2,546.3,588.5,547.2,588.5,548.2z"/>
                                <path className="st14" d="M589.4,547.7c-0.5-0.5-0.5-1-0.6-1.4c-1.9-7.4-3.8-14.8-6.2-22.1c-1.6-4.9-3-9.9-4.2-14.9
			c-1-4.1-2.1-8.2-3.1-12.3c-0.6-2.3-0.9-4.7-1.4-7c-0.6-2.9-1.3-5.8-2.3-8.6c-0.2-0.6-0.4-1.2-0.5-1.8c-0.1-0.4-0.1-0.8-0.2-1.2
			c1-0.5,1.9-0.4,2.9,0c0.2,0.6,0.5,1.1,0.7,1.8c1.1,3.6,2.2,7.3,3.1,10.9c1.7,6.6,3.5,13.3,5.5,19.9c1.5,4.7,2.6,9.5,3.9,14.3
			c0.9,3.1,1.8,6.3,2.7,9.4c0.7,2.6,1.4,5.1,2.2,7.7c0.2,0.7,0.1,1.3-0.2,1.9c-0.6,1-1.3,2-1.9,3
			C589.5,547.4,589.4,547.5,589.4,547.7z"/>
                                <path className="st32" d="M572,476.2c-1.2,0.4-2.2,0.8-3.3,1.2c-0.1-0.2-0.2-0.4-0.2-0.6c-0.2-1.6-0.7-3-1.1-4.6
			c-0.8-3.1-1.6-6.2-2.4-9.3c-0.3-1.2-0.7-2.5-1-3.7c-0.4-1.6-0.8-3.2-1.2-4.8c0-0.2-0.1-0.3-0.1-0.5c-0.1-0.9,0.5-1.6,1.4-1.7
			c0.5-0.1,1.1,0,1.7,0c0.1,0.5,0.3,0.9,0.4,1.3c0.9,3.7,1.8,7.3,2.8,11c0.8,3.1,1.7,6.1,2.5,9.2C571.8,474.6,572.1,475.4,572,476.2
			z"/>
                                <path className="st15" d="M616.2,553.7c0.2-0.3,0.4-0.6,0.7-0.9c1.6-1.5,3.2-3.1,4.8-4.6c0.1-0.1,0.3-0.2,0.5-0.3
			c0.5,0.3,0.6,0.7,0.7,1.2c-1.2,2-3.1,3.5-4.8,5.1C617.5,554.2,616.8,554.4,616.2,553.7z"/>
                                <path className="st13" d="M614.2,558.4c-0.4-1.7,0-2.8,1-3.3c0.8,0.1,1.5,0.3,2.2,0.4c0.7,0.1,0.9,0.4,0.9,1.1c0,0.9-0.5,1.7-1.4,1.9
			C616,558.7,615.1,558.8,614.2,558.4z"/>
                                <path className="st15" d="M627.2,555.4c-0.1,0.6-0.6,0.8-1.1,0.8c-2.1,0.1-4.2,0.2-6.3,0.3c-0.1,0-0.1-0.1-0.3-0.2
			c0.1-0.2,0.2-0.4,0.4-0.5c1.4-0.6,2.9-1.2,4.3-1.8C625.3,554.4,626.5,554.4,627.2,555.4z"/>
                                <path className="st15" d="M619,555c0.5-1,1.3-1.4,1.9-2.1c0.6-0.6,1.2-1.3,1.8-1.9c1-0.1,2.1-0.2,3.1-0.1c0.2,0,0.3,0.2,0.5,0.3
			c-0.2,0.7-0.7,1.1-1.3,1.3c-1.8,0.8-3.5,1.5-5.3,2.3C619.6,554.9,619.4,554.9,619,555z"/>
                                <path className="st15" d="M619.4,557.4c0.6,0,1.1,0,1.6,0c1,0,2,0.1,2.9,0c0.9,0,1.4,0.5,2,1.3c-0.4,0.1-0.6,0.2-0.8,0.2
			c-2,0-4-0.3-6.1-0.6C619.2,558.1,619.3,557.8,619.4,557.4z"/>
                                <path className="st13" d="M593.9,554.2c1.5-0.1,2.6,0.7,3.6,1.6c0.6,0.6,0.8,1.3,0.6,2c-0.8,2.9-3,4.9-6.7,3.7c-1.4-0.4-2.2-2-1.9-3.5
			c0.3-1.6,1.3-2.8,2.6-3.5C592.6,554.3,593.3,554.3,593.9,554.2z"/>
                                <path className="st13" d="M577.1,552.9c1,0,1.9-0.1,2.8,0c0.9,0.1,1.6,0.7,1.8,1.6c0.3,1.3,0.2,2.6-0.2,3.9c-0.2,0.7-0.8,1.1-1.5,1.1
			c-1.3,0-2.4-0.5-3-1.6C576.3,556.2,576.3,554.5,577.1,552.9z"/>
                                <path d="M610.1,449c0.3,0.2,0.6,0.3,0.9,0.5c-0.2,0.4-0.4,0.7-0.6,1c0.3,1.6,0.2,3.3,0,5c-0.3,2.7-0.7,5.3-1.5,7.9
			c-0.7,2.5-1.8,4.8-3.8,6.5c-0.7,0.6-1.6,1.2-2.4,1.7c-1.8,1-3.6,1-5.4,0.2c-0.7-0.3-1.4-0.7-2-1.2c-3.5-2.8-6-6.2-6.8-10.7
			c-0.1-0.7-0.4-1.4-0.5-2.1c-0.1-0.8-0.3-1.5-0.5-2.3c-0.2-0.7-0.4-1.4-0.6-2.1c-0.2-1-0.3-2-0.3-3.1c-0.1-2.1-0.2-4.1,0.1-6.1
			c0.2-1.5,0.9-2.8,2-3.9c0.6-0.6,1.3-1.2,1.9-1.8c0.2-0.2,0.4-0.3,0.6-0.4c2.2-0.8,4.5-1.2,6.9-0.9c1.8,0.2,3.4,0.9,4.9,1.8
			c1.2,0.7,2.3,1.6,3.4,2.5c0.1,0.1,0.2,0.2,0.3,0.4c0.2,0.3,0.4,0.6,0.6,0.9c1.2,1.7,2.3,3.4,2.6,5.5
			C609.9,448.4,610,448.7,610.1,449z M597.3,438.2c-0.6,0-1,0-1.5,0c-1.6,0-3.1,0.5-4.5,1c-0.6,0.2-1,0.5-1.3,1.1
			c0.1,0.8,0.1,1.6,0.5,2.5c1-0.5,2.1-0.6,3-1.1c0.1-0.9-0.5-1.7-0.2-2.5c0.5,0.2,0.6,0.7,0.7,1c0.1,0.4,0.2,0.9,0.5,1.3
			c1.2-0.3,2.3-0.6,3.4-1.3C597.7,439.5,597.5,438.9,597.3,438.2z M602.9,466.5c-1.1,0.4-2.1,0.7-3.1,1.3c0.1,0.5,0.3,1,0.4,1.4
			c0.1,0.3,0.2,0.5,0.2,0.8c0,0.3-0.1,0.6-0.5,0.6c-0.8-0.6-0.5-1.7-1.2-2.5c-1,0.3-2,0.6-3,1c2.4,2.6,5.7,2.5,8,0.2
			C603.4,468.5,603.2,467.6,602.9,466.5z M600.7,448.6c1.2-0.4,2.3-0.8,3.3-1.4c0-1.2-0.5-2.9-1-3.7c-1,0.4-2.1,0.7-3.1,1.3
			C600.1,446.1,600.3,447.3,600.7,448.6z M588.2,453.3c0,0.6,0.2,1.3,0.4,1.9c0.2,0.6,0.2,1.4,0.7,1.9c1-0.4,2.1-0.6,3.1-1.1
			c-0.3-1.2-0.6-2.3-0.9-3.4C590.4,452.6,589.4,452.8,588.2,453.3z M595.5,446.5c0.3,1.3,0.4,2.4,0.9,3.5c1.2-0.1,2.5-0.6,3.3-1.2
			c-0.2-1.1-0.4-2.3-0.8-3.6C597.7,445.6,596.7,446,595.5,446.5z M606.5,459.6c-0.1-1.2-0.3-2.3-0.7-3.5c-1.2,0.4-2.3,1-3.2,1.5
			c-0.1,1.3,0.2,2.8,0.6,3.5C604.3,460.8,605.3,460.2,606.5,459.6z M591.4,447.9c0.3,1.2,0.3,2.3,0.9,3.3c1.6-0.2,2.5-0.5,3.2-1
			c-0.3-1.1-0.3-2.2-0.9-3.3C593.5,447.2,592.5,447.5,591.4,447.9z M604.9,451.4c-0.1-1.1-0.2-2.1-0.7-3.1c-1.1,0.5-2.3,0.8-3.3,1.3
			c0.1,1.2,0.2,2.2,0.6,3.2C602.8,452.4,603.9,451.9,604.9,451.4z M596.3,454.7c-0.1-1.2-0.3-2.3-0.7-3.4c-1.1,0.2-2.1,0.4-3.2,0.9
			c0.3,1.2,0.4,2.3,1,3.5C594.4,455.5,595.3,455.2,596.3,454.7z M599.9,450c-1.9,0.4-2.7,0.7-3.3,1.3c0.3,1,0.3,2.1,0.8,3.1
			c1.1-0.4,2.1-0.7,3.1-1.3C600.3,452.1,600.2,451.1,599.9,450z M590.6,443.7c0,1.2,0.2,2.2,0.7,3.3c1.1-0.2,2.1-0.6,3.2-1.1
			c-0.2-1.1-0.4-2.1-0.8-3.2C592.6,442.9,591.6,443.2,590.6,443.7z M601.4,458.2c-1.1,0.4-2.1,0.7-3.1,1.3c0.2,1.1,0.2,2.2,0.7,3.4
			c1.1-0.3,2.1-0.6,3-1.2C601.9,460.5,601.8,459.4,601.4,458.2z M594.6,442.4c0.2,1.2,0.4,2.2,0.9,3.2c1.1-0.4,2.2-0.7,3.2-1.3
			c-0.1-1.3-0.3-2.2-0.8-3C596.9,441.6,595.8,442,594.6,442.4z M589.3,458c0.3,1.2,0.3,2.3,0.9,3.4c1.1-0.4,2.1-0.7,3-1.2
			c-0.1-1.5-0.3-2.2-0.8-3.1C591.4,457.3,590.4,457.6,589.3,458z M595.1,464.2c1-0.3,1.9-0.5,2.8-1.1c-0.1-0.6-0.1-1.1-0.3-1.7
			c-0.1-0.5,0.1-1.2-0.5-1.6c-0.9,0.3-1.9,0.5-2.8,1.1C594.5,462.1,594.6,463.1,595.1,464.2z M603.7,465.1c1.2-0.6,2.5-1,3.5-1.7
			c-0.1-0.9-0.2-1.8-0.6-2.6c-1.2,0.4-2.9,1.2-3.6,1.9C603.3,463.3,603.2,464.1,603.7,465.1z M602.4,456.7c1.1-0.5,2.2-1,3.2-1.6
			c0-0.9-0.2-1.8-0.5-2.7c-1.2,0.4-2.2,0.9-3.3,1.4C601.8,454.8,601.9,455.8,602.4,456.7z M599.7,444c1.2-0.5,2.3-0.8,3.2-1.4
			c-0.1-0.5-0.2-0.9-0.3-1.4c-0.1-0.4-0.2-0.8-0.3-1.2c-1-0.2-2.4,0.2-3.4,1C599.2,441.9,599.4,442.9,599.7,444z M587.7,449
			c-0.1,1.4,0,2.4,0.4,3.3c0.7-0.2,1.5-0.4,2.2-0.6c0.3-0.1,0.5-0.2,0.8-0.3c-0.1-1.1-0.3-2.2-0.7-3.3
			C589.5,448.5,588.6,448.8,587.7,449z M598.2,458.5c1.1-0.5,2.1-0.7,3-1.3c0-1-0.2-2-0.6-3c-1.1,0.4-2.1,0.7-3.1,1.2
			C597.6,456.5,597.8,457.4,598.2,458.5z M590.4,462.3c0.3,0.6,0.5,1.2,0.8,1.7c0.3,0.5,0.5,1.1,1.1,1.4c0.6-0.3,1.2-0.6,1.9-0.9
			c-0.3-1.1-0.3-2.2-0.8-3.3C592.4,461.4,591.4,461.8,590.4,462.3z M594.2,459.8c1-0.2,2-0.5,2.8-1c0-1.2-0.2-2.1-0.7-2.9
			c-1,0.3-1.9,0.5-2.9,1.1C593.7,457.8,593.8,458.8,594.2,459.8z M608.9,449.7c-0.1-1-0.3-2-0.8-2.8c-1,0.3-1.8,0.6-2.7,1.1
			c0.1,1,0.2,2,0.7,3C607,450.5,607.9,450.1,608.9,449.7z M602.1,462.7c-1.1,0.4-2.1,0.7-3.1,1.2c0,1,0.1,1.9,0.6,2.8
			c1.1-0.3,2-0.7,3-1.2C602.5,464.5,602.3,463.7,602.1,462.7z M606.6,454.7c1.1-0.2,1.9-0.6,2.7-1c0.1-1,0.2-2-0.3-3
			c-1.1,0.2-2,0.7-2.9,1.3c0,0.3,0,0.5,0,0.8C606.3,453.4,606.4,453.9,606.6,454.7z M604.1,443c0.2,1.7,0.6,3,1.1,3.8
			c0.8-0.4,1.7-0.5,2.4-1.1c-0.6-1.2-1.3-2.3-2.2-3.2C605,442.7,604.6,442.9,604.1,443z M598,464.2c-1.1,0.4-2,0.6-2.8,1.1
			c0.1,0.9,0.2,1.8,0.6,2.7c0.9-0.3,1.8-0.6,2.7-1C598.4,466.1,598.2,465.2,598,464.2z M587.5,448.2c1.1-0.1,1.9-0.5,2.8-1
			c-0.3-1.1-0.3-2.1-0.7-3c-0.7,0.1-1.4,0.4-2,0.9c0,0.4-0.1,0.9-0.1,1.3C587.5,447,587.5,447.5,587.5,448.2z M606.9,455.6
			c0,1.4,0.2,2.7,0.7,3.5c0.4-0.2,0.8-0.4,1.1-0.6c0.1-1.1,0.3-2.1,0.4-3.1c0-0.1-0.1-0.3-0.1-0.5c-0.2,0.1-0.4,0.1-0.6,0.1
			C608,455.2,607.5,455.4,606.9,455.6z M607.2,464.7c-1.2,0.3-2.7,1-3.4,1.6c0.1,0.8,0.2,1.6,0.7,2.5
			C605.8,467.6,606.7,466.4,607.2,464.7z M594.3,465.6c-0.3,0.1-0.5,0.1-0.7,0.2c-0.3,0.1-0.5,0.2-0.7,0.4c0.6,1.1,1.2,1.8,2,2
			c-0.1-0.6-0.2-1.1-0.3-1.7C594.5,466.2,594.4,466,594.3,465.6z M589,441.2c-0.5,0.8-1.2,1.4-1,2.5c0.5-0.3,1-0.5,1.4-0.7
			C589.4,442.4,589.4,441.8,589,441.2z M598.7,439.8c1.1-0.2,1.1-0.2,1.7-0.8c-0.7-0.3-1.3-0.6-2.1-0.6
			C598.3,439.1,598.3,439.1,598.7,439.8z M608.4,459.8c-0.3,0.1-0.5,0.2-0.7,0.3c-0.1,0.5-0.1,1,0.3,1.4
			C608.4,461,608.5,460.5,608.4,459.8z M603.6,440.8c0,0.7,0,1.1,0.4,1.4c0.2-0.1,0.4-0.3,0.8-0.5
			C604.4,441.4,604.1,441.2,603.6,440.8z"/>
                                <path className="st15" d="M597.3,438.2c0.2,0.7,0.4,1.3,0.6,1.9c-1.1,0.7-2.2,1-3.4,1.3c-0.3-0.4-0.4-0.9-0.5-1.3
			c-0.1-0.4-0.2-0.8-0.7-1c-0.3,0.9,0.3,1.6,0.2,2.5c-0.9,0.5-2,0.6-3,1.1c-0.4-0.9-0.4-1.7-0.5-2.5c0.3-0.6,0.8-0.9,1.3-1.1
			c1.5-0.5,3-1,4.5-1C596.2,438.2,596.7,438.2,597.3,438.2z"/>
                                <path className="st15" d="M602.9,466.5c0.3,1,0.5,2,0.8,2.8c-2.2,2.2-5.5,2.4-8-0.2c1-0.4,2-0.7,3-1c0.7,0.7,0.4,1.9,1.2,2.5
			c0.4,0,0.6-0.2,0.5-0.6c0-0.3-0.2-0.5-0.2-0.8c-0.1-0.5-0.3-0.9-0.4-1.4C600.8,467.3,601.8,467,602.9,466.5z"/>
                                <path className="st15" d="M600.7,448.6c-0.4-1.3-0.6-2.5-0.8-3.7c1-0.6,2.1-0.9,3.1-1.3c0.5,0.7,1,2.4,1,3.7
			C603,447.8,601.9,448.1,600.7,448.6z"/>
                                <path className="st15" d="M588.2,453.3c1.3-0.6,2.3-0.8,3.3-0.8c0.3,1.1,0.6,2.2,0.9,3.4c-1,0.6-2.1,0.7-3.1,1.1
			c-0.6-0.5-0.5-1.3-0.7-1.9C588.4,454.6,588.1,454,588.2,453.3z"/>
                                <path className="st15" d="M595.5,446.5c1.2-0.5,2.2-0.9,3.4-1.3c0.4,1.3,0.6,2.5,0.8,3.6c-0.9,0.6-2.1,1.1-3.3,1.2
			C595.9,448.9,595.9,447.8,595.5,446.5z"/>
                                <path className="st15" d="M606.5,459.6c-1.2,0.6-2.2,1.2-3.3,1.6c-0.4-0.7-0.7-2.3-0.6-3.5c1-0.6,2-1.1,3.2-1.5
			C606.2,457.3,606.4,458.4,606.5,459.6z"/>
                                <path className="st15" d="M591.4,447.9c1.1-0.4,2.1-0.7,3.2-1c0.6,1.1,0.6,2.3,0.9,3.3c-0.7,0.5-1.6,0.8-3.2,1
			C591.7,450.2,591.7,449.1,591.4,447.9z"/>
                                <path className="st15" d="M604.9,451.4c-1.1,0.6-2.2,1-3.4,1.4c-0.4-1-0.5-2.1-0.6-3.2c1.1-0.5,2.2-0.9,3.3-1.3
			C604.7,449.3,604.8,450.3,604.9,451.4z"/>
                                <path className="st15" d="M596.3,454.7c-0.9,0.5-1.9,0.8-2.9,1c-0.5-1.1-0.6-2.3-1-3.5c1.1-0.5,2.1-0.7,3.2-0.9
			C596,452.5,596.2,453.6,596.3,454.7z"/>
                                <path className="st15" d="M599.9,450c0.4,1,0.5,2,0.6,3.1c-1,0.6-2,0.9-3.1,1.3c-0.5-1-0.5-2.1-0.8-3.1
			C597.2,450.8,598,450.4,599.9,450z"/>
                                <path className="st15" d="M590.6,443.7c1-0.4,2-0.8,3.1-1c0.4,1.1,0.6,2.1,0.8,3.2c-1.1,0.5-2.1,0.8-3.2,1.1
			C590.8,445.9,590.6,444.9,590.6,443.7z"/>
                                <path className="st15" d="M601.4,458.2c0.4,1.2,0.5,2.3,0.6,3.4c-0.9,0.6-1.9,0.9-3,1.2c-0.4-1.1-0.5-2.2-0.7-3.4
			C599.2,458.9,600.3,458.6,601.4,458.2z"/>
                                <path className="st15" d="M594.6,442.4c1.2-0.4,2.3-0.8,3.3-1.1c0.5,0.9,0.7,1.8,0.8,3c-1,0.5-2.1,0.9-3.2,1.3
			C595,444.6,594.8,443.5,594.6,442.4z"/>
                                <path className="st15" d="M589.3,458c1.2-0.3,2.2-0.6,3.2-0.9c0.5,0.9,0.7,1.6,0.8,3.1c-0.9,0.6-2,0.9-3,1.2
			C589.6,460.3,589.6,459.2,589.3,458z"/>
                                <path className="st15" d="M595.1,464.2c-0.5-1.1-0.6-2.2-0.7-3.3c0.9-0.6,1.9-0.8,2.8-1.1c0.6,0.5,0.4,1.1,0.5,1.6
			c0.1,0.5,0.2,1.1,0.3,1.7C597,463.7,596.1,464,595.1,464.2z"/>
                                <path className="st15" d="M603.7,465.1c-0.5-0.9-0.4-1.8-0.6-2.5c0.7-0.6,2.4-1.5,3.6-1.9c0.4,0.8,0.5,1.7,0.6,2.6
			C606.2,464.1,604.9,464.5,603.7,465.1z"/>
                                <path className="st15" d="M602.4,456.7c-0.5-0.9-0.5-1.9-0.6-2.9c1.1-0.5,2.2-1,3.3-1.4c0.4,0.9,0.5,1.8,0.5,2.7
			C604.6,455.7,603.5,456.2,602.4,456.7z"/>
                                <path className="st15" d="M599.7,444c-0.3-1.1-0.5-2-0.8-2.9c1-0.8,2.4-1.2,3.4-1c0.1,0.4,0.2,0.8,0.3,1.2c0.1,0.4,0.2,0.9,0.3,1.4
			C602,443.2,600.9,443.5,599.7,444z"/>
                                <path className="st15" d="M587.7,449c0.9-0.3,1.8-0.6,2.7-0.9c0.4,1.1,0.7,2.2,0.7,3.3c-0.3,0.1-0.6,0.2-0.8,0.3
			c-0.7,0.2-1.5,0.4-2.2,0.6C587.7,451.5,587.5,450.4,587.7,449z"/>
                                <path className="st15" d="M598.2,458.5c-0.4-1.1-0.6-2-0.7-3c1-0.6,2-0.9,3.1-1.2c0.4,1,0.6,1.9,0.6,3
			C600.3,457.8,599.3,458,598.2,458.5z"/>
                                <path className="st15" d="M590.4,462.3c1-0.5,2-0.9,3.1-1.1c0.4,1.1,0.5,2.2,0.8,3.3c-0.7,0.3-1.3,0.6-1.9,0.9
			c-0.6-0.3-0.8-0.9-1.1-1.4C590.9,463.5,590.7,462.9,590.4,462.3z"/>
                                <path className="st15" d="M594.2,459.8c-0.4-1-0.5-2-0.7-2.9c0.9-0.6,1.9-0.8,2.9-1.1c0.4,0.8,0.7,1.8,0.7,2.9
			C596.2,459.3,595.2,459.6,594.2,459.8z"/>
                                <path className="st15" d="M608.9,449.7c-1,0.4-1.9,0.8-2.9,1.3c-0.5-1-0.6-2-0.7-3c0.9-0.5,1.7-0.8,2.7-1.1
			C608.6,447.7,608.7,448.6,608.9,449.7z"/>
                                <path className="st15" d="M602.1,462.7c0.2,1,0.4,1.8,0.5,2.7c-1,0.6-2,0.9-3,1.2c-0.4-0.9-0.5-1.8-0.6-2.8
			C600,463.4,601.1,463.1,602.1,462.7z"/>
                                <path className="st15" d="M606.6,454.7c-0.2-0.7-0.3-1.3-0.4-1.9c0-0.2,0-0.5,0-0.8c0.9-0.6,1.8-1.1,2.9-1.3c0.5,1,0.4,1.9,0.3,3
			C608.5,454.1,607.7,454.5,606.6,454.7z"/>
                                <path className="st15" d="M604.1,443c0.4-0.2,0.8-0.4,1.3-0.6c0.9,1,1.7,2,2.2,3.2c-0.8,0.6-1.7,0.7-2.4,1.1
			C604.7,446.1,604.4,444.7,604.1,443z"/>
                                <path className="st15" d="M598,464.2c0.2,1,0.4,1.9,0.5,2.8c-0.9,0.5-1.8,0.7-2.7,1c-0.4-0.9-0.5-1.8-0.6-2.7
			C596.1,464.8,597,464.6,598,464.2z"/>
                                <path className="st15" d="M587.5,448.2c0-0.7,0-1.2,0-1.8c0-0.4,0.1-0.9,0.1-1.3c0.6-0.5,1.2-0.8,2-0.9c0.4,0.9,0.5,2,0.7,3
			C589.4,447.7,588.6,448.1,587.5,448.2z"/>
                                <path className="st15" d="M606.9,455.6c0.5-0.2,1-0.4,1.5-0.5c0.2-0.1,0.4-0.1,0.6-0.1c0,0.2,0.1,0.4,0.1,0.5c-0.1,1-0.2,2-0.4,3.1
			c-0.4,0.2-0.8,0.4-1.1,0.6C607.2,458.2,606.9,456.9,606.9,455.6z"/>
                                <path className="st15" d="M607.2,464.7c-0.5,1.7-1.4,2.9-2.7,4.1c-0.5-0.8-0.6-1.7-0.7-2.5C604.6,465.6,606.1,464.9,607.2,464.7z"/>
                                <path className="st15" d="M594.3,465.6c0.1,0.3,0.2,0.6,0.3,0.8c0.1,0.5,0.2,1.1,0.3,1.7c-0.8-0.1-1.5-0.8-2-2
			c0.2-0.1,0.5-0.3,0.7-0.4C593.8,465.8,594,465.7,594.3,465.6z"/>
                                <path className="st15" d="M589,441.2c0.4,0.6,0.4,1.2,0.4,1.8c-0.4,0.2-0.9,0.4-1.4,0.7C587.8,442.6,588.4,442,589,441.2z"/>
                                <path className="st15" d="M598.7,439.8c-0.4-0.6-0.4-0.6-0.4-1.4c0.7,0,1.4,0.3,2.1,0.6C599.8,439.5,599.8,439.5,598.7,439.8z"/>
                                <path className="st15" d="M608.4,459.8c0.1,0.7,0,1.2-0.4,1.7c-0.4-0.4-0.4-0.9-0.3-1.4C607.9,460,608.1,459.9,608.4,459.8z"/>
                                <path className="st15" d="M603.6,440.8c0.5,0.4,0.8,0.6,1.2,0.9c-0.3,0.2-0.5,0.3-0.8,0.5C603.6,441.9,603.5,441.5,603.6,440.8z"/>
                            </g>
                        </g>
                        <g className="navidad">
		<path d="M286.2,504.5c-1.5-0.7-3.1-1.5-4.4-2.5c-0.3-0.2-0.5-0.2-0.8-0.1c-0.3-2.6-3.8-3.9-6-5.4c-0.1-0.1-0.2-0.2-0.4-0.2
			c-0.5-0.3-1,0-1.2,0.4c0,0,0,0,0,0.1c-1.1-0.8-2.3-1.6-3.5-2.3c-0.3-3,0.7-9.6-3-10.5c-0.7-1-2-1.2-3.6-1c0-0.1,0-0.3-0.1-0.5
			c-1.1-2.6-2.4-6-5.2-7.1c-1.3-0.9-3.3-0.8-4.5,0.3c-1.5,1.4-2.3,3.4-2.8,5.5c-0.9-3.5-2.6-6.3-5.9-6.3c-2.3,0-4.6,1.1-4.6,3.1
			c-1.1,1.2-1.9,2.8-2,4.5c-2.5,0-4.8,0.8-6.4,3.1c-0.4,0.6-0.4,1.1-0.2,1.5c-0.3,2.1-1.6,4.7-0.7,6.8c0.4,1,1.1,1.6,1.9,1.9
			c-3.4,0.8-6.8,1.9-9.8,3.5c-1.8,1-1.3,2.1-1.3,3.9c0,3.1,0.1,6.1,0.9,9.1c0.1,0.4,0.5,0.7,0.9,0.7c0.1,0,0.2,0,0.3,0
			c1,9.9,0.4,20.2,0.4,30.1c0,3.5-0.6,7.6,0.3,11c1.1,4.4,11,2.6,13.8,2.6c0.2,0,0.3,0,0.4-0.1c0.2,0.2,0.4,0.3,0.7,0.3
			c3.9,0,7.7-0.2,11.6,0c6.3,0.1,12.6,0.2,18.9,0.2c0.1,0,0.2,0,0.2,0c0,0,0,0,0,0c0,0,0,0,0,0c2.2-0.3,4.3-0.8,6.6-0.9
			c0.1,0,0.3,0,0.4-0.1c1.2-0.2,2.3-0.4,3.5-0.6c0.4-0.1,0.7-0.5,0.7-0.9c0.7-12.6,1.4-25.3,2.3-37.9c0.5,0.3,1.4,0.2,1.5-0.5
			c0.5-3.6,1-7.1,1.5-10.7C286.7,505.2,286.7,504.7,286.2,504.5z"/>
                            <path className="st32" d="M242.6,477.8c0.2-0.3,0.6-0.6,1.4-0.9c4.1-1.2,5.4,5.8,5.8,11c-0.7-1.4-1.6-2.7-2.5-4
			c-0.9-1.2-4.4-3.6-4.8-5.3C242.7,478.3,242.7,478,242.6,477.8z"/>
                            <path className="st32" d="M241,480.3l3.7,3.7c0.4,0.4,0.8,0.8,1.2,1.3c-1.7-1.2-3.7-2.1-5.7-2.5C240.2,481.8,240.5,481,241,480.3z"/>
                            <path className="st32" d="M234.7,485.6c1.7-1.2,3.5-1.2,5.5-0.9c2.9,0.5,5.8,2.3,7.6,4.7c-3.8-1.7-7.9-1.5-12.1-2.3
			C234.4,487,233,486.8,234.7,485.6z"/>
                            <path className="st13" d="M237.6,534.4c0,5.1,0.1,10.3,0.4,15.4c-0.6-0.1-1.6,0.1-2.8,1.5c-1.4,1.6-1.1,3-0.8,3.7
			c-1.8,0-3.6,0.1-5.5-0.1c-2.4-0.2-2.8-1.4-2.8-3.4c0-0.2,0-0.3,0-0.5c1.7-0.6,4.9-2.3,5.1-6.2c0.2-3.9-3.4-4.2-5.1-4.2
			c0-0.5,0-0.9,0-1.4c0-5.7,0.2-11.6,0.1-17.4c1.1-0.1,2.7-0.6,4.4-3c1.9-2.8,1.6-5.6,1.3-7c1.8-0.4,3.7-0.8,5.5-1
			c0.4,0,0.6-0.3,0.8-0.6c-0.1,4.9-0.3,9.8-0.4,14.8c-0.5,0-5.4,0-5.2,4.8C232.8,534.3,236.7,534.4,237.6,534.4z"/>
                            <path className="st13" d="M233.1,509.7c-2.9,0.6-5.9,1.3-8.9,1.5c-0.5-2.3-0.6-4.7-0.6-7c0-2.3-0.5-2.7,1.5-3.7c0.1,0,0.2-0.1,0.3-0.1
			c1.3,3.1,6.7,1.6,6.7-2.3c2.1-0.5,4.1-0.9,6.2-1.2c0,2,0,4,0,6.1C235.3,502.9,233.2,505,233.1,509.7z"/>
                            <path className="st32" d="M239.2,493.9c-0.1,0-0.2,0-0.2,0.1c-0.3,0.1-0.5,0.2-0.6,0.5c-0.1-0.1-0.3-0.1-0.5-0.1
			c-1.6,0-5.1,0.7-5.3-1.7c-0.1-0.7,0.3-1.5,0.4-2.2c0.2-0.7,0.3-1.4,0.4-2c0.8,0.4,1.8,0.6,2.3,0.7c4.7,0.8,9.6,0.1,13.6,3.4
			c0,0,0.1,0.1,0.1,0.1c0,0,0,0.1,0,0.1c-1.3,0.1-2.5,0.3-3.8,0.4c-0.2-0.2-0.6-0.2-0.9,0c0,0,0,0-0.1,0.1
			C242.8,493.5,241,493.7,239.2,493.9z"/>
                            <path className="st32" d="M240.2,499.5c1.6-1.3,2.9-2.9,4.4-4.3c0.8-0.1,1.5-0.2,2.3-0.3c-2.4,2.1-4.4,4.6-6.7,6.7
			C240.2,500.9,240.2,500.2,240.2,499.5z"/>
                            <path className="st32" d="M240.2,497.7c0-0.7,0-1.3,0-2c0.8-0.1,1.5-0.2,2.3-0.3C241.7,496.2,241,497,240.2,497.7z"/>
                            <path className="st32" d="M250,555.1c-3.2-0.1-6.4,0-9.7,0c-1.4-15.9-0.4-31.8-0.2-47.8c3.4-3.1,6.8-6.2,10.2-9.4
			C249.5,517,247.7,536.2,250,555.1z"/>
                            <path className="st32" d="M250.4,496.4c-0.2,0-0.4,0-0.6,0.2c-3.2,3-6.5,5.9-9.7,8.9c0-0.7,0-1.3,0-2c3.2-2.6,5.6-6.1,9-8.5
			c0.1-0.1,0.2-0.2,0.2-0.2c0.1,0,0.2,0,0.3,0c0.2,0.2,0.5,0.3,0.8,0.2C250.5,495.4,250.4,495.9,250.4,496.4z"/>
                            <path className="st13" d="M268.8,496.1c1.5,0.9,2.9,1.9,4.4,2.8c-0.3,3.3-0.6,6.7-0.9,10c-0.6-0.3-1.2-0.6-1.8-0.9
			c0.5-3.1-1.2-3.8-2.3-4C268.4,501.4,268.6,498.7,268.8,496.1z"/>
                            <path className="st32" d="M267.3,486.5c0.7,1.4,0.7,4.8,0.8,7c-0.2,0-0.3,0-0.5,0c-0.4-0.1-0.8-0.2-1.3-0.2c-4.5-0.3-9.1-0.6-13.7-0.3
			c-0.1,0-0.2,0-0.3,0.1c-0.1-0.2-0.2-0.3-0.3-0.4c0,0,0,0,0-0.1c2.8-0.8,5.8-1.2,8.6-2.1C262.6,489.9,266.5,488.8,267.3,486.5z"/>
                            <path className="st13" d="M258.4,494.8c2.2,0.1,4.4,0.2,6.6,0.3c0.4,0,1.3,0.1,1.9,0.2c-0.1,2.5-0.3,5-0.5,7.5c-3.8-0.6-5.5,2.6-6.1,4
			c-2.8,0.1-5.6,0.2-8.4,0.3c0.1-2,0.2-4.1,0.3-6.1C254,501,257.8,500.2,258.4,494.8z"/>
                            <path d="M252.4,495.2c0-0.1,0-0.2,0-0.3c0.1,0,0.1,0,0.2,0c0.9-0.1,1.9-0.1,2.8-0.1L252.4,495.2z"/>
                            <path className="st32" d="M263.8,484.9c2-0.2,1.8,1.1,0.5,2c-3.4,2.2-7.6,2.5-11.5,3.5c1.3-1.9,4.5-3.2,6.3-4.1
			C260.6,485.6,262.2,485.1,263.8,484.9z"/>
                            <path className="st32" d="M259.3,478.8c1,1.4,1.6,3.3,2.2,4.6c-2.7,0.8-5.8,2.5-8.1,4.2c1.1-1.4,2.4-2.7,3.5-4.2
			C257.7,482.3,259,480.5,259.3,478.8z"/>
                            <path className="st32" d="M252,484.9c0.4-2.2,0.9-4.4,1.9-6.5c0.6-1.2,1.7-2.1,3.1-1.5c1,0.5,0.4,1.9,0.1,2.6
			c-1.2,2.8-3.4,4.9-5.3,7.2c0-0.4-0.1-0.9-0.1-1.3C251.7,485.4,251.9,485.2,252,484.9z"/>
                            <path className="st13" d="M264.4,508.8c0,4-0.1,8-0.3,12c-1.4,0.4-4,1.4-4.1,4.3c-0.1,2.9,2.3,4.6,3.7,5.3c-0.3,5.2-0.5,10.4-0.7,15.6
			c-1.2,0.1-4.9,0.5-4.6,3.9c0.2,2.7,2.7,3.9,4.4,4.5c0,0.3,0,0.6,0,0.9c-3.7,0-7.3-0.1-11-0.1c-0.6-5.2-0.9-10.4-1.1-15.6
			c1.6,0.1,6.6,0,6.8-5.5c0.2-5.2-4.7-4.6-6.8-4.1c0.1-3.7,0.2-7.4,0.4-11.1c2-0.2,6.9-1,7-5.3c0.1-4.2-4.4-4.5-6.5-4.4
			c0,0,0-0.1,0-0.1c4.2,0,8.5-0.3,12.7-0.5C264.4,508.6,264.4,508.7,264.4,508.8z"/>
                            <path className="st13" d="M268.2,537.4c0.1,3.1,1.5,3.3,2,3.3c-0.3,4.9-0.7,9.7-1.1,14.6c-1.4,0-2.8,0-4.3-0.1c0-0.2,0-0.4,0-0.6
			c0.7-0.2,2.2-0.9,2.9-4.1c0.6-2.7-1.3-3.9-2.7-4.3c0.2-5.2,0.4-10.5,0.7-15.7c1.3-0.4,3.4-1.5,3.5-4.9c0.1-3.5-1.8-4.6-3-4.9
			c0.2-4,0.3-8,0.3-12c0-0.1,0-0.2-0.1-0.3c0.3,0,0.5,0.1,1.2,0.5c0.5,0.2,1,0.5,1.5,0.7c-0.4,0.6-1.6,3,0.1,4.4
			c1.2,0.9,2.1,1.1,2.7,1c-0.4,6.2-0.8,12.3-1.2,18.5C269.6,534,268.1,535,268.2,537.4z"/>
                            <path className="st32" d="M275.8,554.5c-1.3,0.1-2.6,0.3-3.9,0.5c1.5-1.2,2.8-2.8,4.1-4.2C275.9,552.1,275.9,553.3,275.8,554.5z"/>
                            <path className="st32" d="M276.1,548.7c-1.7,1.7-3.1,3.7-5,5.3c0.1-0.8,0.1-1.6,0.2-2.5c0.2,0,0.3-0.1,0.5-0.2
			c1.4-1.6,3.1-3.1,4.4-4.8C276.1,547.2,276.1,547.9,276.1,548.7z"/>
                            <path className="st32" d="M276.4,543.7c-1.2,2.3-3.1,4-4.9,5.9c0.1-0.9,0.1-1.8,0.2-2.8c1.6-1.8,3.3-3.4,4.8-5.3
			C276.4,542.3,276.4,543,276.4,543.7z"/>
                            <path className="st32" d="M276.6,539.5c-0.2,0-0.3,0.1-0.4,0.3c-1.3,1.7-2.9,3.2-4.3,4.8c1.1-15.2,1.7-30.4,3.2-45.6
			c0,0,0-0.1,0.1-0.1c1.4,0.9,2.7,1.9,4.1,2.8c0,0.6-0.1,1.1-0.1,1.7c-0.4,5.5-0.7,11-1.1,16.4C277.5,526.4,277,533,276.6,539.5z"/>
                            <path d="M279.5,553.9c-0.1,0-0.3,0.1-0.5,0.1c0.2-0.2,0.4-0.4,0.5-0.6C279.5,553.6,279.5,553.7,279.5,553.9z"/>
                            <path className="st13" d="M280.3,540.5c-0.2,2.7-0.3,5.4-0.5,8.1c-0.4-1-1.2-1.2-1.7-1.1c0.2-4.6,0.5-9.2,0.9-13.8
			c0.5,0.7,1.2,1.1,1.7,1.3C280.5,536.7,280.4,538.6,280.3,540.5z"/>
                            <path className="st13" d="M281.1,527.6c-0.5,0.1-1.2,0.2-1.8,0.7c0.2-3.3,0.5-6.6,0.7-10c0.1-1.1,0.2-2.2,0.2-3.4
			c0.6,0.1,1.1,0.3,1.6,0.6C281.6,519.5,281.3,523.5,281.1,527.6z"/>
                            <path className="st13" d="M283.6,514.2c-0.2-0.1-0.4-0.2-0.6-0.2c-0.7-0.4-1.5-0.8-2.3-0.9c-0.1,0-0.2,0-0.3,0
			c0.2-3.1,0.4-6.2,0.6-9.3c0.3,0.2,0.7,0.5,1.1,0.7c0,1.2,0.3,4.1,2.1,5.5C284,511.4,283.8,512.8,283.6,514.2z"/>
	</g>
                        <g className="navidad">
		<path d="M234.2,511.1c0-0.7-0.6-1.3-1.3-1.3c-2.1,0-4.2,0-6.2,0c0.1-2.9,0.4-6-1.3-8.4c0,0,0,0,0,0c-1-3.7-6-3-8.6-2
			c-3.4,1.3-7,3.9-9.4,6.9c-1.9-6.5-11.6-8.1-17.2-5.3c-0.6,0.3-1,0.6-1.3,1c-0.4,0.1-0.7,0.4-0.9,0.9c-0.6,2.5-1,5.6,0.1,8
			c-2.3,0.1-4.7,0.2-7,0.2c-0.7,0-1.3,0.6-1.3,1.3c0,4.6-0.2,9.2-0.4,13.9c0,0.7,0.6,1.3,1.3,1.3c0.2,0,0.4,0,0.5,0
			c0,8.7-1,17.9,0.6,26.5c0.4,2.1,1.7,2.5,3.6,2.7c4.6,0.6,9.4-0.1,14-0.2c1.2,1.3,3.8,1.3,5.7,1.5c1.6,0.1,6,0.2,8-1.3
			c0.2,0.2,0.5,0.3,0.8,0.3c4.8,0,9.7-0.3,14.5-0.5c2.4-0.1,4.2,0.3,4.6-2.4c1.3-9.2,0.3-19,0.2-28.3c0.6-0.1,0.9-0.6,1-1.2
			C234.5,520,234.3,515.6,234.2,511.1z"/>
                            <path className="st21" d="M224.3,508.4c-0.7-1-1.4-1.9-2.1-2.8c0.7-0.3,1.3-0.6,1.9-1C224.3,505.8,224.3,507.1,224.3,508.4z"/>
                            <path className="st21" d="M222.3,509.9c-0.7,0-1.4,0-2.1,0c0,0,0,0,0,0c-1.1-0.9-2.3-1.7-3.4-2.7c1-0.2,2-0.5,3-0.8
			c0.1,0.2,0.1,0.3,0.3,0.5C220.8,507.8,221.5,508.9,222.3,509.9z"/>
                            <path className="st15" d="M216.4,502.3c1.6-0.9,3.9-1.8,5.8-0.9c1.5,0.8,0.3,1.3-0.6,1.7c-2.5,1.2-5.4,1.6-8,2.3
			c-0.8,0.2-1.8,0.4-2.6,0.8C212.6,504.6,214.5,503.3,216.4,502.3z"/>
                            <path className="st21" d="M189.9,506.2c0.3,0.3,0.6,0.5,1,0.7c1.1,1.2,2.2,2.5,3.4,3.7c-1.1,0-2.2,0.1-3.4,0.1c0,0,0-0.1-0.1-0.1
			C189.9,509.5,189.7,507.9,189.9,506.2z"/>
                            <path className="st11" d="M198.7,540.5c0,4.5-0.1,8.9-0.1,13.4c-3,0-6,0.2-9.1,0.3c-1.7,0-3.5,0.1-5.2-0.3c-0.4-0.1-0.5-3.2-0.5-3.5
			c-0.7-7.7-0.2-15.5-0.2-23.2c5.1-0.3,10.2-0.5,15.2-0.3C198.8,531.5,198.8,536,198.7,540.5z"/>
                            <path className="st11" d="M198.9,524.5c-5.6-0.3-11.3,0-17,0.3c0.2-3.8,0.3-7.5,0.3-11.3c5.6-0.1,11.2-0.6,16.7-0.4
			C199,516.9,198.9,520.7,198.9,524.5z"/>
                            <path className="st21" d="M197.5,510.6c-0.1-0.2-0.1-0.4-0.3-0.5c-0.6-0.7-1.3-1.3-1.9-2c0.7,0,1.4,0,2.1,0c0.1,0.1,0.1,0.2,0.2,0.3
			c0.8,0.8,1.5,1.6,2.2,2.4C199,510.6,198.2,510.6,197.5,510.6z"/>
                            <path className="st15" d="M193.7,505.4c-0.4-0.1-0.8-0.3-1.2-0.4c-0.1-0.1-0.2-0.2-0.2-0.3c-0.3-0.3-0.6-0.4-0.9-0.4
			c-1.2-1,1.9-1.8,2.5-1.9c1.6-0.3,3.3-0.2,4.9,0.1c2.6,0.5,5.1,1.8,6.1,4.1C201.8,504.6,197.2,506.2,193.7,505.4z"/>
                            <path className="st21" d="M205.4,511.9c-1.3,1.2-2.7,2.4-4,3.7c0-1.1,0-2.2,0-3.3C201.8,512.1,203.7,512,205.4,511.9z"/>
                            <path className="st21" d="M204,509.2c-0.7,0.1-1.3,0.2-1.9,0.4c-0.5-0.6-0.9-1.1-1.4-1.7C202,507.9,203.2,508.2,204,509.2z"/>
                            <path className="st21" d="M204.9,555.3c2.2-2.1,4.4-4.3,6.6-6.4c0,1.8,0.1,3.6,0.1,5.4C211.7,555.4,206.8,555.4,204.9,555.3z"/>
                            <path className="st21" d="M211.5,545.5c-3.3,3.2-6.6,6.4-9.9,9.5c-0.3-0.2-0.5-0.6-0.5-1.5c0-1,0-2.1,0-3.1c0.1-0.1,0.3-0.2,0.4-0.3
			c3.4-4.1,6.8-8.2,10.2-12.4C211.6,540.3,211.5,542.9,211.5,545.5z"/>
                            <path className="st21" d="M211.8,533.6c-3.6,4.3-7.1,8.6-10.7,13c0-2,0-3.9,0.1-5.9c0.2-0.1,0.4-0.2,0.5-0.4
			c3.2-4.2,6.7-8.2,10.3-12.1C212,530,211.9,531.8,211.8,533.6z"/>
                            <path className="st21" d="M212.2,524.3C212.2,524.3,212.2,524.3,212.2,524.3c-3.7,4.1-7.5,8.3-10.9,12.6c0-2.4,0.1-4.7,0.1-7.1
			c0.1-0.1,0.3-0.2,0.4-0.3c3.5-3.8,7.1-7.6,10.6-11.5C212.3,520.1,212.2,522.2,212.2,524.3z"/>
                            <path className="st21" d="M212.4,514.2c-3.7,4-7.3,7.9-11,11.9c0-2.4,0-4.7,0.1-7.1c2.4-2.4,4.9-4.6,7.4-6.8c0.1-0.1,0.2-0.2,0.3-0.3
			c0.9,0,1.7,0.1,2.6,0.1c0.3,0,0.5,0,0.8,0.1C212.4,512.8,212.4,513.5,212.4,514.2z"/>
                            <path className="st21" d="M210.5,509.2c0.6-0.8,2.6-1.1,3.6-1.3c0,0.2,0.2,0.4,0.3,0.6c0.5,0.5,1.1,1,1.6,1.4c-0.7,0-1.4,0-2.1,0
			C213.2,509.6,211.9,509.3,210.5,509.2z"/>
                            <path className="st11" d="M230.8,550.1c0,1.2-0.1,2.5-0.3,3.7c-0.1,0-0.3,0-0.5,0c-0.9,0-1.7,0.1-2.6,0.1c-4.4,0.2-8.9,0.4-13.3,0.5
			c-0.2-2.7-0.1-5.4,0-8c0.1-0.3,0.1-0.5,0-0.8c0-1.2,0.1-2.5,0.1-3.7c0.1-5.5,0.3-10.9,0.4-16.4c5.3,0,10.7,0.1,16,0.2
			C230.8,533.8,231,541.9,230.8,550.1z"/>
                            <path className="st11" d="M231.8,523.1c-5.7,0-11.3-0.2-17-0.2c0.1-3.5,0.2-7.1,0.2-10.6c5.6,0,11.2,0,16.8,0
			C231.8,516,231.9,519.6,231.8,523.1z"/>
	</g>
                        <g className="navidad">
		<path d="M334.8,521.4c-0.6-1.6-2.1-2.9-3.6-4c0.3-0.1,0.6-0.2,0.9-0.3c0.5-0.2,0.7-0.8,0.3-1.2c-1-1.2-2.2-1.9-3.5-2.6
			c0.6,0,1.1-0.1,1.7-0.2c0.9-0.1,1-1.1,0.2-1.5c-6-2.4-11.6-6.2-15.1-11.7c-0.4-0.9-1.3-1.6-2.2-2c0.3-1.6,0.7-3.2,1-4.8
			c0.3-1.3-0.3-0.6,0.7-1.2c0.9-0.7,1.8-1.3,2.8-2c0.4-0.3,0.5-1,0-1.3c-0.7-0.4-1.4-0.8-2.1-1.2c-0.3-0.2-0.7-0.4-1-0.6
			c-0.1,0-0.1-0.1-0.2-0.1c0,0,0-0.1,0-0.1c-0.3-1.8-0.7-3.6-1-5.3c-0.1-0.7-1.3-0.7-1.4,0c-0.5,2-1,3.9-1.6,5.9
			c-0.2,0.7-2.3,2.3-3.4,3.3c-0.2,0.1-0.3,0.2-0.4,0.4c-0.2,0.4,0.1,0.9,0.5,1c0.1,0,0.1,0,0.2,0c1.1,0.1,2.2,0.3,3.3,0.4
			c0.2,1.7,0.4,3.4,0.6,5c-1.5-0.4-3.1-0.5-4.3-0.6c-7.1-1-14.4-1-21.6-1.7c-0.4,0-0.7,0.2-0.7,0.6c-2.9,5.3-8.9,10-14.6,11.9
			c-0.6,0.2-0.7,1-0.2,1.4c0.5,0.3,1.1,0.5,1.6,0.7c-1.9,3.3-4.8,5.5-7.6,8c-1.2,1.1-2.7,2.7-2.4,4.4c0.3,1.5,1.8,1.3,3.5,0.5
			c-1.8,1.5-5.3,4.1-3.6,6.4c1,1.3,2.4,1.2,3.7,0.7c0,6.1,0.2,12.3,0.4,18.4c0.2,4.3-1.3,8.8,4,10c4.4,1,8.7,2.8,12.9,4.3
			c2,0.7,4.1,1.4,6.1,2.2c0.6,0.2,1.6,0.8,2.2,0.6c1-0.3,3.9-2.5,3.9-3.7c0-2.7-0.1-5.3-0.1-8c0-1.2,0-2.5,0-3.7
			c0.1-0.1,0.2-0.1,0.2-0.2c0.4-0.7,1-1.2,1.6-1.7c0.1,0.3,0.2,0.5,0.3,0.8c0.1,0.2,0.3,0.3,0.4,0.3c0,0.4,0,0.8,0.2,1.1
			c0,2.4,0,4.8,0.1,7.2c0,0.5,0.4,0.9,1,1c8.1,0.6,16.3,1.2,24.4,1.8c0.1,0,0.3,0,0.4-0.1c0.1,0,0.3-0.1,0.4-0.2
			c2.2-1.8,4.4-3.5,6.6-5.3c0.7-0.6,1.5-1.2,2.2-1.7c0.8-0.7,0.5-4.3,0.4-5.2c0-0.2-0.1-0.4-0.2-0.5c0.1-0.1,0.2-0.3,0.2-0.4
			c0-0.7-0.1-1.4-0.3-2.1c0.3-0.1,0.6-0.4,0.6-0.8c0.1-0.2,0.2-0.4,0.1-0.7c-0.3-0.9-0.8-1.7-1.4-2.4c0.1-0.1,0.1-0.2,0.1-0.4
			c0.2-5.4,0.5-10.8,0.6-16.1c0-0.1,0-0.2,0-0.3C334.2,523.6,335.5,523.1,334.8,521.4z"/>
                            <path className="st14" d="M311.9,487.9c0.3-1,0.5-2,0.8-3c0.2,0.8,0.3,1.6,0.5,2.4c0.2,0.8,1.6,1.5,2.8,2.1c-1.1,0.7-2.5,1.5-2.7,2.4
			c-0.2,1-0.4,2-0.6,2.9c-0.1-1-0.3-2-0.4-3c-0.1-0.4-0.3-0.7-0.7-0.7c-0.7-0.1-1.5-0.2-2.2-0.3
			C310.4,489.8,311.7,488.8,311.9,487.9z"/>
                            <path d="M266.7,522c-0.1-0.1-0.2-0.1-0.3-0.1c0.1-0.1,0.2-0.1,0.4-0.2C266.8,521.8,266.8,521.9,266.7,522z"/>
                            <path className="st14" d="M267.6,546.8c-0.2-4.8-0.3-9.5-0.3-14.3c0.2,0.2,0.3,0.4,0.6,0.5c0.9,0.5,1.7-0.1,2.4-1
			c0.3,1.1,0.8,1.6,1.6,1.7c0.3,7.6,0.3,15.3,0,22.8c-0.5-0.2-1-0.3-1.5-0.4C266,555,267.7,550.1,267.6,546.8z"/>
                            <path className="st14" d="M273.3,557c0.4-7.8,0.4-15.8,0-23.7c0.4-0.2,0.9-0.5,1.3-0.9c0.1,2,0.7,4.2,2.6,4.9c1.3,0.5,2.1-0.9,2.7-2.4
			c0.3,1,0.8,1.9,1.7,2.2c-0.1,7.5-0.3,15-0.5,22.5c-2-0.7-4.1-1.4-6.1-2.1C274.4,557.4,273.8,557.2,273.3,557z"/>
                            <path className="st14" d="M286.6,561.7c-1.4-0.5-2.7-1-4.1-1.4c0,0,0,0,0-0.1c0.2-7.6,0.5-15.2,0.5-22.8c1-0.2,1.7-0.7,2.2-1.5
			c0.4,0.9,1,1.6,1.9,1.7c1.1,0.2,1.6-0.6,2-1.5c0,8.8-0.1,17.6-0.2,26.5C288.2,562.2,287.4,561.9,286.6,561.7z"/>
                            <path className="st14" d="M291.6,562.2c-0.3,0.2-0.5,0.4-0.8,0.6c0.1-8.6,0.2-17.2,0.2-25.8c0.2,0,0.5,0,0.7-0.1
			c0.3-0.1,0.6-0.2,0.9-0.5c0,4.3,0.1,8.7,0.1,13c0,3.1,0.1,6.1,0.1,9.2c0,0.8,0,1.6,0,2.4c0,0.2,0,0.3,0,0.5
			C292.9,560.6,292.3,561.6,291.6,562.2z"/>
                            <path className="st14" d="M294.7,547.5c0-4.1-0.1-8.3-0.1-12.4c0-0.4-0.2-0.6-0.4-0.8c0.1-0.4,0.2-0.7,0.3-1.1
			c0.5,0.5,1.1,0.7,2.1,0.3c2.4-1.1,3.2-4.9,3.4-7.8c1.4,0.5,2.9-3.2,3.7-5.7c0.8,0.9,1.7,0.3,2.4-1c0,3.8,0,7.5,0,11.3
			c-3.5,1.2-5.8,4.6-5.9,8.6c0,0,0,0,0,0c-2.1-0.7-4.4,1.8-4.5,3.8c0,0.2,0,0.3,0.1,0.4c-0.4,0.6-0.6,1.4-0.7,2.2
			c0,0.5,0.7,1,1.1,0.6c0.2-0.1,0.4-0.2,0.6-0.3c0,0,0,0.1,0,0.1c0,0,0,0,0,0C296,546.3,295.3,546.8,294.7,547.5z"/>
                            <path className="st14" d="M312.9,507c4.1,5.2,8.3,11,13.9,14.6c0,0,0,0,0,0c-0.1,5.4,0.1,10.8-0.3,16.1c0,0.2,0,0.3,0.1,0.4
			c-0.3,0.1-0.6,0.1-0.8,0.2c-0.1,0-0.2,0.1-0.2,0.1c-2-1.8-5.3-2.5-8.3-2.2c-0.2-2.5-2-4.3-4.2-5.4c-0.1-0.1-0.2-0.3-0.4-0.3
			c-0.2,0-0.4-0.1-0.5-0.1c-0.7-0.3-1.3-0.5-2-0.6c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c-0.8-0.1-1.5-0.1-2.2,0c0-4.9,0-9.7,0-14.6
			c0-0.1,0-0.2-0.1-0.3c0,0,0,0,0-0.1C309.3,516.3,311.9,510.4,312.9,507z"/>
                            <path className="st15" d="M315.6,537.3c0,4.8-6.5,5.2-8.9,1.9c-1.4-1.8-0.7-4.7,0.7-6.2c1-1.1,2.6-1.2,4-1
			C313.8,532.9,315.9,534.6,315.6,537.3z"/>
                            <path className="st13" d="M306.6,531.8c-0.2,0.1-0.3,0.3-0.5,0.4c-2,2.2-2.1,5.8-0.4,8.1c3.1,4.2,10.7,2.9,11.3-2.4
			c2.1-0.3,4.1-0.2,6,0.7c4.1,1.8,3.5,8.7,0.1,10.9c-3.1,2-8.6,1.1-11.8,0.2c-4.2-1.1-7.8-3.8-9.4-8
			C300.5,537.5,302.8,533.1,306.6,531.8z"/>
                            <path className="st11" d="M299,545c0.6-0.6-0.2-1.5-0.8-1.2c-0.2-0.1-0.4-0.1-0.6,0c-0.2,0.1-0.4,0.2-0.6,0.2c0.2-0.4,0.6-0.8,1-1
			c0.4-0.2,0.6-0.5,0.5-0.9c-0.1-0.4-0.5-0.7-0.9-0.5c0.5-0.8,1.4-1.4,2.2-1.2c0.2,0.1,0.3,0.1,0.4,0c0.5,6.3,7.1,10,12.6,11.2
			c3.1,0.7,6.3,0.6,9.4-0.1c3.2-0.7,4.4-3.3,5-6.2c0.5-2.2,0.1-4-0.8-5.4c1.9-0.5,3.6,0.5,4.7,2c-0.6-0.1-1.3,0-1.9,0
			c-1,0-1,1.5,0,1.5c0.2,0,0.3,0,0.5,0c0.5,0.3,0.9,0.7,1.2,1.2c-0.8-0.4-1.7-0.5-2.6,0c-0.2,0.1-0.4,0.3-0.4,0.5
			c-0.4,0.4-0.3,1.4,0.5,1.3c0.7-0.1,1.3,0.1,1.7,0.6c-0.8-0.1-1.7,0.1-2.5,0.5c-0.7,0.4-0.3,1.3,0.3,1.4c-0.1,0.3,0,0.6,0.4,0.7
			c0.4,0.2,0.7,0.5,0.8,0.9c-0.7-0.1-1.4-0.3-2.2-0.3c-0.5,0-0.8,0.5-0.7,0.9c0.1,0.5,0.1,1,0.1,1.4c-0.2-0.2-0.4-0.5-0.6-0.8
			c0,0,0,0,0,0c0-0.4-0.2-0.7-0.6-0.7c-0.5-0.2-1.3,0.4-1,1.1c0,0,0,0,0,0c-0.1,0.8-0.1,1.5-0.3,2.2c0,0,0,0,0-0.1c0,0,0,0,0,0
			c0-0.5-0.3-0.8-0.7-0.9c-0.1-0.2-0.2-0.3-0.4-0.5c0.1-0.9-1.3-1.3-1.4-0.3c-0.2,1-0.7,1.8-1.3,2.6c-0.1-0.4-0.2-0.8-0.3-1.2
			c0,0,0,0,0-0.1c0.2-0.8-0.9-1.2-1.3-0.6c-0.2,0.1-0.4,0.4-0.3,0.7c-0.4,0.7-1.1,1.3-1.8,1.5c0.3-0.6,0.6-1.2,0.8-1.9
			c0.3-0.9-1.1-1.3-1.4-0.4c-0.2,0.7-0.6,1.4-1,2c-0.1-0.4-0.1-0.8,0-1.2c0.1-0.5-0.2-0.8-0.6-0.9c-0.3-0.2-0.6-0.2-1,0
			c-0.5,0.5-0.9,1-1.2,1.6c-0.2-0.4-0.4-0.7-0.4-1.2c0-0.5-0.4-0.8-0.9-0.7c-1,0.2-2,0.7-2.6,1.5c0-0.6,0.2-1.2,0.6-1.7
			c0.6-0.8-0.7-1.5-1.3-0.8c-0.3,0.4-0.5,0.9-0.7,1.5c-0.3-0.6-0.3-1.3,0-1.8c0.5-0.8-0.8-1.6-1.3-0.8c0,0,0,0.1-0.1,0.1
			c-1,0.4-1.7,1-2.3,1.8c-0.1-0.8,0.1-1.5,0.3-2.3c0.2-0.9-1.2-1.3-1.4-0.4c-0.2,0.7-0.3,1.4-0.3,2.1c-0.4-0.6-0.4-1.7,0-2
			c0.6-0.5,0-1.5-0.7-1.2c-0.6,0.2-1.2,0.5-1.8,0.9c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.7,0.7-1.7,1.2-1.9c0.8-0.5,0.1-1.8-0.8-1.3
			c-0.4,0.2-0.9,0.7-1.2,1.2C297.9,546.3,298.5,545.4,299,545z"/>
                            <path className="st11" d="M299.4,551c0.1-0.1,0.2-0.2,0.4-0.3c0.1,1.2,0.7,2.5,1.8,2.8c0.1,0,0.1,0,0.2,0c0,0.1,0.1,0.2,0.1,0.3
			c-0.1,0.7,0.7,1.3,1.2,0.7c0.6-0.7,1.1-1.5,1.9-2.1c0.1,0.7,0.4,1.4,0.8,2c0.2,0.2,0.5,0.3,0.8,0.2c0,0.3,0.1,0.6,0.1,0.9
			c0.1,0.3,0.3,0.5,0.6,0.5c0.3,0.5,1.2,0.6,1.4-0.1c0.2-0.8,0.9-1.3,1.6-1.6c0.3,0.8,0.8,1.6,1.4,2.2c0.4,0.4,1.1,0.2,1.2-0.3
			c0.1-0.3,0.2-0.6,0.4-0.9c0.1,0.5,0.2,0.9,0.2,1.4c0,0.7,0.8,1,1.3,0.5c0.1-0.1,0.3-0.3,0.4-0.4c0.1,0,0.2,0.1,0.3,0.1
			c1.2,0.1,2.3-0.5,3.2-1.3c0,0.4,0.1,0.7,0.1,1.1c0,0.6,0.8,1,1.3,0.5c0.7-0.7,1.4-1.5,1.9-2.3c0,1,0,2.1,0,3.1
			c-7.5-0.6-15-1.1-22.5-1.7C299.5,554.5,299.4,552.8,299.4,551z"/>
                            <path className="st11" d="M323.9,556.2c0.3,0.1,0.6-0.1,0.8-0.4c0.5-0.7,0.7-1.5,0.8-2.3c0.2,0.3,0.5,0.5,0.8,0.8c0.3,0.3,0.7,0.3,1,0
			c-1.1,0.9-2.3,1.8-3.4,2.7C323.9,556.8,323.9,556.5,323.9,556.2z"/>
                            <path className="st11" d="M328.5,553.5c-0.4,0.3-0.7,0.6-1.1,0.9c0.1-0.1,0.2-0.2,0.2-0.3c0.3-0.8,0.3-1.7,0.3-2.5
			c0.7,0.1,1.4,0.3,2.1,0.3c0.3,0,0.6-0.2,0.7-0.5c0.3-1.2-0.1-2.4-1.2-3c0.6-0.1,1.3,0,1.8,0.6c0,0,0,0,0,0c0,0.2,0,0.4,0,0.6
			c0,0.5-0.1,1.1-0.1,1.6c0,0.1,0,0.3,0,0.3C330.2,552.1,329.3,552.8,328.5,553.5z"/>
                            <path className="st14" d="M330.8,523.9c0,5-0.3,10-0.5,15.1c-0.6-0.4-1.3-0.6-2-0.7c0.1-0.1,0.1-0.3,0.1-0.4c0.3-5,0.2-10,0.3-15
			c0.1,0,0.1,0.1,0.2,0.1c0.3,0.2,1.1,0.4,2,0.5C330.8,523.6,330.8,523.7,330.8,523.9z"/>
                            <path className="st11" d="M329.6,521.6c-0.4-0.2-0.8-0.4-1.2-0.6c-0.1-0.1-0.2-0.2-0.4-0.2c-2.1-1.2-3.9-3-5.5-4.7
			c-3.3-3.5-6.3-7.3-9.3-11.1c0-0.1-0.1-0.2-0.2-0.3c-0.3-0.3-0.6-0.2-0.9,0c-0.2,0.1-0.4,0.3-0.4,0.6c0,0.9-0.3,1.8-0.6,2.7
			c-0.4,1.4-1,2.7-1.7,4c-0.5,1.1-1.1,2.2-1.5,0.3c-0.2-0.9-1.4-0.6-1.5,0.2c0,1.1-0.3,2.1-0.5,3.2c-0.1,0.5-1,4.4-1.7,2.2
			c-0.2-0.7-1.2-0.8-1.4,0c-0.2,0.8-0.4,1.6-0.7,2.5c-0.2,0.5-1.4,4.8-2,2.9c-0.3-0.8-1.4-0.7-1.5,0.2c-0.1,2.5-0.5,4.8-1.6,7.2
			c-1.5,3.2-2.1,0-2.2-2c0-1-1.5-1-1.5,0c0,0.2,0,0.5,0,0.8c0,0,0,0,0,0.1c0,1.7-0.2,3.2-1,4.7c-1.3,2.9-2.7-0.4-2.9-1.9
			c-0.2-0.8-1.5-0.6-1.5,0.2c0.1,0.9,0,1.8-0.3,2.7c-0.5-0.1-0.9-0.3-1.4-0.4c-0.2-0.7-0.3-1.4-0.4-2c0-0.2,0-0.5,0-0.7
			c-0.1-1-1.5-1-1.5,0c0,0.8-0.1,1.5-0.3,2.3c-0.5,0.8-0.9,1.2-1.3,1.4c-0.2-0.3-0.7-0.4-1-0.2c-0.3-0.3-0.5-0.7-0.7-1.3
			c-0.3-1-0.4-1.9-0.5-2.9c-0.1-0.8-1.3-1.1-1.5-0.2c-0.3,1.4-0.6,2.9-1.2,4.3c-1.1-0.2-1.7-0.8-1.8-1.8c-0.3-1-0.2-2-0.1-3
			c0.1-0.6-0.9-1-1.3-0.5c-0.7,0.8-1.4,1.5-2.3,2.2c-0.2,0-0.5,0-0.6,0.1c-0.7-0.4-0.4-3-0.4-3c0.1-0.7-1.1-1-1.4-0.4
			c-0.4,0.9-0.8,1.7-1.5,2.5c-0.5-0.4-0.6-0.9-0.4-1.4c0.1-1.1,0.3-2.1,0.6-3.2c0.2-0.7-0.7-1.3-1.2-0.7c-0.3,0.3-0.5,0.5-0.8,0.8
			c-0.1,0.1-0.2,0.2-0.2,0.3c-0.6-0.2-1.3,0.1-1.3,0.9c0,0,0,0.1,0,0.1c-0.9,0.3-1.5-0.4-1.8-2.1c0.9-1.1,2.1-2,3.2-3
			c0.1,0.5,0.5,0.8,1.2,1c1.2,0.3,2.7-0.6,4-1.8c-0.5,1.5-0.4,2.9,1,3.2c1.8,0.3,3.4-0.9,4.7-2.4c-0.1,1.3,0.2,2.4,1.4,2.6
			c1.4,0.2,2.6-0.5,3.6-1.5c-0.1,1.2,0.1,2.3,1,2.8c1.3,0.8,2.7-0.1,3.7-1.2c0.2,0.9,0.6,1.7,1.6,2.1c1.5,0.5,2.5-0.7,3.1-2.2
			c0.3,0.5,0.8,0.7,1.6,0.5c1.7-0.4,2.1-3,2.1-4.4c0-1-1.5-1-1.5,0c0,0.9-0.2,2-0.8,2.7c-0.6,0.7-0.6-1.5-0.6-1.9c0-1-1.4-0.9-1.5,0
			c-0.1,0.8-1.2,5-2.4,3.3c-0.4-0.5-0.3-1.2-0.3-1.8c0-0.1,0-0.2,0-0.4c0-0.1,0.1-0.1,0.1-0.2c0.2-0.3,0.1-0.5,0-0.7
			c0-0.2,0-0.3,0-0.2c0.1-0.9-1.3-1.3-1.4-0.4c0,0.3-0.1,0.6-0.1,0.9c-0.8,1-3,3.7-3.1,1.3c-0.1-1.1,0.4-2.5,0.8-3.5
			c0.3-0.8-0.9-1.3-1.4-0.6c-0.9,1.5-2,3.1-3.7,3.8c-1.6,0.6,0-4.2,0.1-4.5c0.3-0.8-0.9-1.2-1.4-0.6c-1,1.5-2.1,2.8-3.4,4
			c-1.2,1.1-2.3,0.5-1.8-1c0.3-1.1,1-2.2,1.7-3.1c0.5-0.7-0.6-1.4-1.2-0.9c-0.9,0.8-1.8,1.7-2.7,2.5c-0.7,0.6-1.4,1.2-2.2,1.7
			c-0.7-0.6-0.7-1.2-0.1-1.7c0.4-0.5,0.7-1,1.2-1.4c0.7-0.7-0.4-1.8-1.1-1.1c-1.2,1.3-2.7,2.2-4.3,2.8c-2.8,1.1-0.5-1.6,0.4-2.5
			c3-2.9,6.2-5,8.1-8.8c1.2,0,2.3-0.1,3.5-0.5c-0.4,0.7-0.8,1.5-1.1,2.3c-0.1,0.4,0.2,1,0.7,0.9c1.5-0.1,2.9-0.6,4.2-1.3
			c-0.4,1.1-0.3,2.1,1.1,2.9c1.7,0.9,3.6-0.7,5.2-2.2c-0.2,1.3,0,2.7,1.1,3.4c1,0.6,1.9-0.1,2.7-1.1c0,1.6,0.3,3.2,1.7,3.1
			c1.8,0,2.6-1.5,3.1-3.1c1.2,1.9,3,2.8,4.5,0.1c2.1,1,2.3-2.2,2.3-4.2c0-1-1.5-1-1.5,0c0,0.1,0,3.1-0.6,2.6c-0.3-0.3-1-0.3-1.2,0.2
			c-1.3,3-3-0.4-3.2-2.1c-0.1-0.9-1.5-1-1.5,0c0,1.3-0.2,2.5-0.6,3.8c-1.3,0.4-1.8,0.1-1.5-0.9c0-0.8,0.1-1.6,0.3-2.4
			c0.1-0.8-1-1.4-1.4-0.6c-0.3,0.7-0.7,1.3-1.1,1.9c-0.6,0.1-1.2,0.2-1.7,0.3c0-1,0.2-2,0.5-2.9c0.2-0.8-0.7-1.2-1.2-0.7
			c-0.6,0.5-1.2,1-1.7,1.6c-0.6,0.6-1.3,1.1-2,1.6c-1.6-0.8-1.8-1.7-0.6-2.6c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0,0.1-0.1,0.2-0.1
			c0.5-0.6-0.1-1.6-0.9-1.2c-1.6,0.9-3.1,2-4.8,2.4c0.6-1.1,1.4-2.1,2.4-2.9c0.6-0.3,1.2-0.7,1.8-1.1c0.8-0.6,0.1-1.7-0.8-1.3
			c-0.7,0.3-1.3,0.7-1.9,1.2c-2,1.1-4.4,1.9-6.6,1.5c5.4-2.3,10.7-6.7,13.4-11.7c5.6,0.5,11.2,0.8,16.7,1.2c1.9,0.1,6,0.1,8.9,1
			c0,0.1,0,0.2,0,0.3c0,0,0,0,0,0c-3.4,2.7-7.6,11.5-8.5,13c-0.5,0.8,0.8,1.6,1.3,0.8c0.8-1.3,8.1-14.1,9.2-13
			c0.1,0.1,0.2,0.2,0.3,0.2c0.2,0.2,0.3,0.4,0.4,0.6c0,0.1,0.1,0.1,0.1,0.2c3.2,4.9,7.9,8.4,13.2,10.9c-0.6,0-1.2,0-1.9,0
			c-0.7,0-1.1,1.1-0.4,1.4c1.8,0.9,3.6,1.5,5,2.7c-0.7,0.1-1.4,0.1-2-0.2c-0.2-0.1-0.3-0.1-0.5-0.2c-0.9-0.3-1.3,1.1-0.4,1.4
			c1,0.4,1.9,1,2.7,1.6c0.7,0.5,1.4,1.1,2.1,1.7c0.1,0.2,0.3,0.3,0.4,0.5C332,522.4,330.9,522.7,329.6,521.6z"/>
                            <path d="M313.6,547.2c0.6,0.5,1.4,1,2.5,1.3c0.3,0.1,0.5,0.1,0.8,0.1c0.3,0,0.6,0,0.8,0c0.3-0.1,0.5-0.1,0.7-0.2
			c0.2-0.1,0.4-0.2,0.6-0.4c0.2-0.1,0.3-0.3,0.4-0.4c0.1-0.1,0.2-0.3,0.3-0.3c0.1-0.2,0.2-0.3,0.2-0.3s-0.1,0-0.4,0.1
			c-0.1,0-0.2,0.1-0.4,0.1c-0.1,0-0.3,0.1-0.5,0.1c-0.7,0.1-1.4,0.1-2.3-0.2c-0.8-0.2-1.8-0.5-2.5-0.6c-0.8-0.1-1.3-0.1-1.3-0.1
			S313.1,546.8,313.6,547.2z"/>
                            <path d="M322.4,545.6c0.3,0.1,0.5,0,0.7-0.2c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.1,0.1-0.3,0.1-0.4c0-0.3-0.2-0.5-0.2-0.5s-0.3,0-0.5,0
			c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0.1-0.2,0.3-0.3,0.6c0,0.2,0,0.5,0,0.5S322.2,545.5,322.4,545.6z"/>
                            <path d="M308.2,536.3c0,0.6-0.3,0.9-0.9,0.9c-0.6,0-0.6,1,0,1c1.1,0,1.8-0.7,1.9-1.9C309.2,535.6,308.2,535.7,308.2,536.3z"/>
                            <path d="M311,535.3c0.8-0.2,1.3-0.9,1.3-1.7c0-0.6-1-0.6-1,0c0,0.4-0.3,0.7-0.6,0.8C310.1,534.5,310.4,535.4,311,535.3z"/>
                            <path d="M310.6,539.1c0.9,0.5,2,0.4,2.8-0.3c0.3-0.3,0.5-0.7,0.5-1.1c0-0.6-0.5-1-0.9-1.3c-0.5-0.3-1,0.5-0.5,0.9
			c0.4,0.2,0.6,0.5,0.2,0.8c-0.5,0.3-1.1,0.4-1.6,0.1C310.6,537.9,310.1,538.8,310.6,539.1z"/>
                            <path d="M290.6,498.6c-0.4-0.1-0.8-0.1-1.2-0.1c-0.4,0-0.8,0.1-1.2,0.2c-0.4,0.1-0.7,0.3-1.1,0.4c-0.3,0.2-0.7,0.5-1,0.7
			c-0.3,0.3-0.5,0.6-0.7,0.9c-0.2,0.4-0.3,0.7-0.4,1.1l0,0.1c0,0.1,0,0.1,0,0.1c0,0,0,0.1,0.1,0.1c0.1,0,0.1,0,0.1,0
			c0.1,0,0.1-0.1,0.2-0.1c0-0.1-0.2-0.1-0.2-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0-0.1l0,0.1c0.3-0.2,0.6-0.4,0.8-0.7
			c0.1-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.3-0.2,0.4-0.3c0.3-0.1,0.6-0.3,0.8-0.4c0.3-0.1,0.6-0.2,0.9-0.3c0.3-0.1,0.6-0.1,0.9-0.2
			c0.3,0,0.6-0.1,1-0.1c0.3,0,0.7-0.1,1-0.1c0.3,0,0.7,0,1.1-0.1c-0.3-0.2-0.6-0.4-1-0.6C291.4,498.8,291,498.7,290.6,498.6z"/>
                            <path d="M296.5,499.4c-0.3-0.1-0.5,0-0.8,0.2c-0.2,0.1-0.4,0.3-0.4,0.3s0.1,0.3,0.2,0.5c0.2,0.2,0.4,0.4,0.6,0.5
			c0.3,0,0.5,0,0.8-0.2c0.2-0.1,0.4-0.4,0.4-0.4s-0.1-0.3-0.2-0.5C297,499.6,296.7,499.4,296.5,499.4z"/>
	</g>
                        <g className="luces-3">
		<path className="st11" d="M812,211.2c0.1-0.7,0.8-0.7,1.4-0.7c1.3,0,1.5,0.6,1.4,1.6c-0.9-0.2-1.9-0.2-2.9,0
			C812,211.7,812,211.4,812,211.2z"/>
                            <path className="st11" d="M817.4,215.5c-1.6-2.2-4.9-3.1-7-1c-1.3,1.3-2,3.8-1,5.5c1.3,2.4,5.2,2.7,7.4,1.4
			C819.1,220.1,818.7,217.6,817.4,215.5z M812.5,219.7c-0.1,0.1-0.3,0.1-0.6,0.1c-0.2,0-0.6,0-0.9-0.2c-0.2-0.1-0.3-0.2-0.4-0.4
			c-0.1-0.1-0.2-0.3-0.3-0.5c-0.1-0.2-0.1-0.3-0.1-0.5c0-0.2,0-0.3,0-0.5c0.1-0.3,0.1-0.6,0.3-0.8c0.1-0.2,0.3-0.4,0.4-0.4
			c0.1-0.1,0.2-0.1,0.2-0.1s0,0.1,0.1,0.2c0,0.1,0.1,0.3,0.1,0.5c0.1,0.4,0.2,0.9,0.3,1.2c0,0.1,0.1,0.2,0.1,0.2
			c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.1,0.2,0.1
			S812.6,219.6,812.5,219.7z"/>
                            <path d="M818.8,214.9c0-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.1c-0.6-0.8-1.4-1.5-2.3-2c0.1-0.7,0.2-1.5,0-2.2
			c-0.3-1-1.2-1.4-2.1-1.5c0-5.3,0-10.6,0-15.8c0-0.7-1-0.7-1,0c0,5.3,0,10.5,0,15.8c-0.9,0-1.9,0.4-2.4,1.2
			c-0.5,0.7-0.4,1.7-0.4,2.6c-0.1,0.1-0.3,0.1-0.4,0.2c-2.4,1.5-3.3,5.2-2.1,7.7c1.5,3.3,6.2,3.9,9.3,2.5
			C820.5,221.4,820.6,217.8,818.8,214.9z M812,211.2c0.1-0.7,0.8-0.7,1.4-0.7c1.3,0,1.5,0.6,1.4,1.6c-0.9-0.2-1.9-0.2-2.9,0
			C812,211.7,812,211.4,812,211.2z M816.7,221.5c-2.2,1.3-6,0.9-7.4-1.4c-1-1.7-0.3-4.2,1-5.5c2.1-2.1,5.4-1.2,7,1
			C818.7,217.6,819.1,220.1,816.7,221.5z"/>
	</g>
</g>
</svg>

            </div>
        );
    }
}