import React from "react";
import cargador from "../img/cargador_blanco.gif"

export default class Loading extends React.Component{
    render() {
        return (
            <div className="cargando_container">
                <img src={cargador} alt=""/>
                <h2 className="legend-42">Por favor espera</h2>
            </div>
        );
    }
}