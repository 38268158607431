import React from "react";
const url = new URLSearchParams(window.location.search);

export default class Success extends React.Component{

    invoice(evt){
        if (url.get("lang").localeCompare("en")==0){
            window.location.href = "https://apoyo.savethechildren.mx/invoice?email="+document.getElementById("email").value
        }else{

            fetch("https://api.savethechildrenmx.org/events_api/getRegimen.php", {
                method: 'POST',
                body: ''
            }).then(resp => {
                return resp.text()
            }).then(txt => {
                var data = JSON.parse(txt);
                data.forEach(element => {
                    var option = document.createElement("option")

                    option.value = element.regimen_id
                    option.text = element.nombre

                    document.getElementById("regimen").appendChild(option)
                })

                document.querySelector("#invoicing_email").value = document.getElementById("email").value
                document.getElementById("regimen").parentElement.classList.add("is-valid")
                document.querySelector(".invoicing-container").classList.remove("hide")
            })

        }
    }

    finish(){
        window.location.href="https://apoyo.savethechildren.mx/gracias-por-iluminar"
    }
    render() {
        return (
            <div className="success-container hide">
                <div className="success-card">
                    <div className="check">
                        &#10003;
                    </div>
                    <h3 className="legend-43">Compra exitosa</h3>
                    <h2 className="legend-44">Gracias por ayudar a la niñez</h2>
                    <button className="legend-45" onClick={this.invoice}>Quiero recibo</button>
                    <button className="legend-46" onClick={this.finish}>Finalizar Compra</button>
                </div>
            </div>
        );
    }
}