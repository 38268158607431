import React from "react";

export default class Info extends React.Component {
    close(){
        document.querySelector(".info_container").classList.add("hide")
    }
    render() {
        return (
            <div className="info_container hide">
                <div className="container">
                    <div className="tools">
                        <div className="close" onClick={this.close}>
                            Cerrar <span className="cross">X</span>
                        </div>
                    </div>
                    <div className="instrucciones_header">

                    </div>
                    <div className="slides">
                        <div className="slide-1">
                            <p></p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}